import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import AttachmentsControl, {
  ATTACH
} from 'components/common/attachments-control';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import styles from '../components.module.scss';

const UploadFilesMessage = ({
  fileList,
  onChange,
  attachmentActionsDeps,
  className,
  maxSizeFile,
  maxSizeFileList
}) => {
  const { t } = useTranslation('CommonChat');

  return (
    <AttachmentsControl
      fileList={fileList}
      onChange={onChange}
      actionsDeps={attachmentActionsDeps}
      maxSizeFile={maxSizeFile}
      maxSizeFileList={maxSizeFileList}
    >
      <Tooltip
        title={t('AttachFilesTip')}
        mouseEnterDelay={0.5}
        modalType={ATTACH}
      >
        <Button
          type="text"
          mood="none"
          className={classnames(styles.toolbarBtn, className)}
          data-qa="qa-aycewo49dy31hgd"
        >
          <Icon type="paper-clip" size={16} data-qa="qa-u1029hhq3r2z84k" />
        </Button>
      </Tooltip>
    </AttachmentsControl>
  );
};

UploadFilesMessage.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  attachmentActionsDeps: PropTypes.shape({
    statusId: PropTypes.number,
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    orderId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    isPrivate: PropTypes.bool,
    isFromOtherWorkspace: PropTypes.bool
  }),
  className: PropTypes.string
};

UploadFilesMessage.defaultProps = {
  fileList: [],
  onChange: () => {},
  attachmentActionsDeps: {},
  className: undefined
};

export default UploadFilesMessage;
