import memoize from 'lodash/memoize';
import differenceWith from 'lodash/differenceWith';

import {
  TYPE_ORDER_STATUS,
  TYPE_CONTACT,
  TYPE_ASSET,
  TYPE_CHANNEL_CHAT,
  TYPE_TASK
} from 'constants/index';

export const transformDefaultRelations = memoize(relations =>
  relations.reduce(
    (acc, curr) => {
      const commonRelation = {
        value: curr.objectId,
        relationId: curr.id,
        label: { title: curr.relation.name, ...curr.relation }
      };

      if (curr.type === TYPE_ORDER_STATUS) {
        acc[TYPE_ORDER_STATUS].push({
          ...commonRelation,
          label: {
            ...commonRelation.label,
            orderId: curr.relation.id,
            id: curr.objectId // orderStatusId
          }
        });

        return acc;
      }

      if (curr.type === TYPE_CONTACT) {
        acc[TYPE_CONTACT].push({
          ...commonRelation,
          label: {
            firstName: curr.relation.name,
            ...curr.relation
          }
        });

        return acc;
      }

      acc[curr.type] = [...(acc[curr.type] || []), commonRelation];
      return acc;
    },
    {
      [TYPE_ORDER_STATUS]: [],
      [TYPE_TASK]: [],
      [TYPE_ASSET]: [],
      [TYPE_CONTACT]: [],
      [TYPE_CHANNEL_CHAT]: []
    }
  )
);

const convertToArray = value =>
  Object.keys(value).reduce((acc, curr) => {
    value[curr].forEach(rel => acc.push({ ...rel, type: curr }));

    return acc;
  }, []);

const convertReturned = (relations, idFiled) => {
  const result = {};

  relations.forEach(r => {
    result[r.type] = [...(result[r.type] || []), r[idFiled]];
  });

  return Object.keys(result).map(key => ({
    relType: key,
    relationIds: result[key]
  }));
};

export const getDiffRelations = (defaultValue, newValue, isArray) => {
  const defaultRelations = isArray
    ? defaultValue
    : convertToArray(defaultValue);
  const newRelations = isArray ? newValue : convertToArray(newValue);

  const addedRelations = differenceWith(
    newRelations,
    defaultRelations,
    (rel1, rel2) => rel1.value === rel2.value
  );

  const deletedRelations = differenceWith(
    defaultRelations,
    newRelations,
    (rel1, rel2) => rel1.value === rel2.value
  );

  return {
    deleted: convertReturned(deletedRelations, 'relationId'),
    added: convertReturned(addedRelations, 'value')
  };
};
