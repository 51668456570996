import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  PAYMENT_ACCOUNT_STATUS_BLOCKED,
  PAYMENT_ACCOUNT_STATUS_EXPIRED
} from 'constants/billing';

import BottomBanner from 'components/common/bottom-banner';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import { getActiveWorkspace } from 'store/workspace';

import useRoutesService from 'services/routes';

import styles from './payment-failed-banner.module.scss';

const ACCOUNT_FAILED_STATUSES = [
  PAYMENT_ACCOUNT_STATUS_EXPIRED,
  PAYMENT_ACCOUNT_STATUS_BLOCKED
];

const PaymentFailedBanner = () => {
  const workspace = useSelector(getActiveWorkspace);

  const [visible, setVisible] = useState(false);

  const routes = useRoutesService();

  const { t } = useTranslation('Billing');

  const { account, user } = workspace;

  const dataByStatus = {
    [PAYMENT_ACCOUNT_STATUS_EXPIRED]: {
      textKey: 'PaymentFailedDesc',
      actionText: t('PayBtn'),
      date: account.activeTo
    },
    [PAYMENT_ACCOUNT_STATUS_BLOCKED]: {
      textKey: 'AccessBlockedDesc',
      actionText: t('PayOffDebtBtn'),
      date: account.blockedTo
    }
  };

  const hasPaymentAccess = checkPaymentAccessByRoles(user.roles);
  const data = dataByStatus[account.status];

  useEffect(() => {
    setVisible(ACCOUNT_FAILED_STATUSES.includes(account.status));
  }, [account.status]);

  if (!data) {
    return null;
  }

  return (
    <BottomBanner visible={visible} className={styles.root}>
      <Typography.Paragraph size="small" color="white" className={styles.text}>
        <Trans
          i18nKey={data.textKey}
          ns="Billing"
          components={{
            bold: <Typography.Text weight="bold" size="small" color="white" />
          }}
          values={{
            date: data.date
          }}
        />
      </Typography.Paragraph>

      {hasPaymentAccess && (
        <Typography.Text
          weight="semibold"
          size="small"
          color="white"
          className={styles.action}
          onClick={() => routes.toBilling()}
        >
          {data.actionText}
        </Typography.Text>
      )}

      <Button
        type="text"
        mood="none"
        size="small"
        className={styles.closeBtn}
        onClick={() => setVisible(false)}
      >
        <Icon type="close" size={16} />
      </Button>
    </BottomBanner>
  );
};

export default PaymentFailedBanner;
