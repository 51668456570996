import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import CustomSelect, {
  CountrySelect
} from 'components/common/controls/custom-select';
import Typography from 'components/common/typography';

import {
  searchExternalCompanyLocal,
  fetchWorksapceVatNumber
} from 'store/workspace';

import VatNumberOption from './option';

import styles from './vat-number.module.scss';

const { Paragraph } = Typography;

const getCompany = company => ({ value: company.vatNumber, label: company });

const VatNumberInput = forwardRef(
  (
    {
      className,
      countrySelectClassName,
      companySelectClassName,
      companyWrapClassName,
      inputGroupClassName,
      value,
      defaultValue,
      inviteData,
      onChange,
      placeholder,
      isRegistration,
      defaultCountry,
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [countryValue, setCountryValue] = useState(null);
    const [vatNumberValue, setVatNumberValue] = useState(
      defaultValue || value || ''
    );

    const { t } = useTranslation(['AccountAlmostReady', 'EditLegalInfo']);

    const {
      hasValidation,
      vatName = t('EnterCompanyRegistrationNumber', { ns: 'EditLegalInfo' })
    } = get(countryValue, 'label', {});

    const findExternal = query =>
      dispatch(searchExternalCompanyLocal(query)).then(e => ({
        entries: e.map(getCompany)
      }));

    const findVatNumber = query =>
      dispatch(fetchWorksapceVatNumber(query))
        .then(e => ({
          entries: [getCompany(e)]
        }))
        .catch(error => ({
          entries:
            error.response.status === 409
              ? [getCompany(error.response.data || {})]
              : []
        }));

    const findContactsCompanies = query =>
      dispatch(searchExternalCompanyLocal(query))
        .then(get('entries'))
        .then(data => ({
          entries: data.map(getCompany)
        }));

    const fetchCompanies = async (search, country) => {
      let res = {};

      if (country.hasValidation) {
        if (search.length === 9) {
          res = await findVatNumber({
            vatNumber: search,
            country: country.id
          });
        }

        if (!(res.entries || []).length) {
          res = await findExternal({
            query: search,
            country: country.id
          });
        }

        return res;
      }

      return findContactsCompanies({
        country: country.id,
        vatNumber: search,
        errorHandle: false
      });
    };

    const handleSearch = async ({ search } = {}) => {
      if (!(search || '').length) {
        return { entries: [], totalItems: 0 };
      }

      try {
        setIsLoading(true);

        return await fetchCompanies(search, (countryValue || {}).label);
      } finally {
        setIsLoading(false);
      }
    };

    const handleChange = ({ label } = {}) => {
      if (label) {
        onChange(
          {
            ...label,
            isStatusLiquidation: label.isActive === false,
            country: (countryValue || {}).value
          },
          { countryValue, hasValidation }
        );
      } else {
        onCancelChange();
      }
    };

    const onCancelChange = () => {
      setVatNumberValue('');
      onChange(undefined);
    };

    useEffect(() => {
      if (!isEmpty(inviteData) && !isEmpty(countryValue)) {
        (async () => {
          const data = await handleSearch({ search: inviteData.vatNumber });

          handleChange(get(data, 'entries.0'));
        })();
      }
      //  eslint-disable-next-line
    }, [inviteData, countryValue]);

    useEffect(() => {
      const isInitialCountry =
        get(countryValue, 'label.id') === get(defaultCountry, 'id') ||
        get(countryValue, 'label.alpha2Code', '').toLowerCase() ===
          get(defaultCountry, 'code', '').toLowerCase();
      const isInitialVatNumber = defaultValue === vatNumberValue;

      const shouldReset =
        vatNumberValue && !(isInitialVatNumber && isInitialCountry);

      if (shouldReset) {
        setVatNumberValue('');
      } else if (isInitialCountry && defaultValue) {
        setVatNumberValue(defaultValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryValue]);

    const hasVatNumber = !!Object.keys(value || {}).length;
    const vatNumberTitle = hasVatNumber && value.title;
    const isStatusLiquidation =
      hasVatNumber && value.isActive === false && !value.isEmpty;

    const showInfo = !isLoading && hasVatNumber && value.exists;

    const enterCompanyName = t('EnterCompanyName', {
      vatName: vatName
        .split(' ')
        .slice(1)
        .join(' ')
    });

    const getCommonValue = () => {
      if (hasVatNumber) {
        if (value.isEmpty) {
          return {};
        }

        return {
          value: value.vatNumber,
          label: value
        };
      }

      return undefined;
    };

    const getDefaultValue = () => {
      if (defaultValue) {
        return {
          value: defaultValue,
          label: {
            vatNumber: defaultValue,
            title: defaultValue
          },
          isEmpty: true
        };
      }

      return {};
    };

    return (
      <div
        className={classnames(styles.root, className)}
        ref={ref}
        data-qa="qa-a7hu5hwedsrdqo7"
      >
        <div
          className={classnames(styles.inputGroup, inputGroupClassName)}
          data-qa="qa-wcxud2hkteiq3ak"
        >
          <CountrySelect
            valueText={t('Country')}
            defaultCountry={defaultCountry}
            value={countryValue}
            onChange={setCountryValue}
            className={classnames(styles.countrySelect, countrySelectClassName)}
            data-qa="qa-3609i4yhq6oimfj"
            params={{ isRegistration }}
            isSearchable={!isRegistration}
            isClearable={false}
          />

          <CustomSelect
            fetchData={handleSearch}
            isAsync
            isSearchable
            isClearable
            placeholder={placeholder || enterCompanyName}
            defaultValue={getDefaultValue()}
            value={getCommonValue()}
            className={classnames(styles.companySelect, companySelectClassName)}
            data-qa="qa-20puz4tjyp73w2e"
            rootClassName={classnames(styles.ellipsis, companyWrapClassName)}
            Option={VatNumberOption}
            optionProps={{
              showId: false
            }}
            onChange={handleChange}
            disabled={!countryValue}
            valueText={enterCompanyName}
            {...props}
          />
        </div>

        {showInfo && (
          <Paragraph color="black-55" size="small">
            {t('CompanyAlreadyRegistered', { vatNumberTitle })}
          </Paragraph>
        )}

        {isStatusLiquidation && (
          <Paragraph color="red" size="small">
            {t('CompanyError')}
          </Paragraph>
        )}
      </div>
    );
  }
);

VatNumberInput.propTypes = {
  className: PropTypes.string,
  countrySelectClassName: PropTypes.string,
  companySelectClassName: PropTypes.string,
  companyWrapClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultCountry: PropTypes.object,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

VatNumberInput.defaultProps = {
  className: undefined,
  countrySelectClassName: undefined,
  companySelectClassName: undefined,
  companyWrapClassName: undefined,
  inputGroupClassName: undefined,
  defaultValue: '',
  defaultCountry: {},
  value: undefined,
  placeholder: null,
  onChange: () => {}
};

export default VatNumberInput;
