import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DETAILS_ASSET, MODAL, SELECTED_ASSET } from 'constants/index';

import { getRouterUrlQuery } from 'store/router';

export const useAssetDetailsVisible = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];
  const id = query[SELECTED_ASSET];

  useEffect(() => {
    setVisible(!!(modal === DETAILS_ASSET && id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return visible;
};

export default useAssetDetailsVisible;
