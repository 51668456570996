import { setFilterSearchPublic, fetchPublicIdeas } from './actions';
import { publishIdea } from '../active/actions';
import handleActions from '../../../utils/redux-actions';
import { setActiveId } from '../../workspace';
import { ITEMS_PER_PAGE } from '../../../constants/pagination';

const initialFilter = {
  search: ''
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  hasMore: true,
  filter: initialFilter,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE * 2,

  entries: []
};

export default handleActions(
  {
    [setActiveId]: () => initialState,

    [fetchPublicIdeas.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;

      return state;
    },

    [fetchPublicIdeas.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchPublicIdeas.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchPublicIdeas.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.isLoaded = true;
      state.totalItems = payload.totalItems;
      state.entries = [...state.entries, ...payload.entries];
      state.hasMore = state.entries.length < payload.totalItems;

      return state;
    },

    [setFilterSearchPublic]: (state, { payload }) => {
      state.filter.search = payload;
      state.entries = [];

      return state;
    },

    [publishIdea.SUCCEEDED]: (state, { payload }) => {
      state.entries = [...state.entries, { ...payload }];

      return state;
    }
  },
  initialState
);
