import React from 'react';

export const ChatIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.57129 0.766602C1.19629 0.766602 0.0712891 1.8916 0.0712891 3.2666V18.2666L3.82129 14.5166H15.0713C16.4463 14.5166 17.5713 13.3916 17.5713 12.0166V3.2666C17.5713 1.8916 16.4463 0.766602 15.0713 0.766602H2.57129ZM20.0713 7.0166V12.0166C20.0713 14.7741 17.8288 17.0166 15.0713 17.0166H7.57129V18.2666C7.57129 19.6416 8.69629 20.7666 10.0713 20.7666H21.3213L25.0713 24.5166V9.5166C25.0713 8.1416 23.9463 7.0166 22.5713 7.0166H20.0713Z"
      fill="white"
    />
  </svg>
);

export default ChatIcon;
