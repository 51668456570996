import themeVariables from 'theme';
import styles from './styles.module.scss';
import ListDotIcon from './icons/list-dot.svg';
import ListNumberIcon from './icons/list-number.svg';
import BlockBoldIcon from './icons/block-bold.svg';
import BlockItalicIcon from './icons/block-italic.svg';
import ColorPickerIcon from './icons/color-picker.svg';
import EmojiIcon from './icons/emoji.svg';
import LinkIcon from './icons/link.svg';
import Unlink from './icons/unlink.svg';
import HistoryRedoIcon from './icons/history-redo.svg';
import HistoryUndoIcon from './icons/history-undo.svg';

const toolbar = {
  options: ['history', 'emoji', 'link', 'list', 'inline', 'blockType'],
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    className: styles.rightLine,
    dropdownClassName: undefined
  },
  list: {
    inDropdown: true,
    className: styles.rightLine,
    options: ['unordered', 'ordered'],
    unordered: {
      icon: ListDotIcon
    },
    ordered: {
      icon: ListNumberIcon
    }
  },
  inline: {
    inDropdown: true,
    options: ['bold', 'italic'],
    bold: {
      icon: BlockBoldIcon
    },
    italic: {
      icon: BlockItalicIcon
    }
  },
  colorPicker: {
    icon: ColorPickerIcon,
    colors: [
      themeVariables.color.black,
      themeVariables.color['black-55'],
      themeVariables.color['brand-40'],
      themeVariables.color['red-60'],
      themeVariables.color.yellow,
      'transparent'
    ]
  },
  emoji: {
    icon: EmojiIcon,
    className: styles.rightLine
  },
  link: {
    inDropdown: true,
    link: {
      icon: LinkIcon
    },
    unlink: {
      icon: Unlink
    }
  },
  history: {
    redo: {
      icon: HistoryRedoIcon
    },
    undo: {
      icon: HistoryUndoIcon
    }
  }
};

export default toolbar;
