export const getTimeByEstimate = estimate => {
  const hours = estimate > 0 ? Math.floor(estimate / 60) : undefined;
  const minutes = estimate > 0 ? estimate % 60 : undefined;

  return { hours, minutes };
};

export const getHoursByTime = time => (time / 60).toFixed(1);

export default getTimeByEstimate;
