import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET } from 'constants/index';

import SubscribersDrawer from 'components/common/subscriptions/subscribers-drawer';

import { changeManageSubscribers } from 'store/subscriptions';

const AssetSubscribersDrawer = ({
  data,
  visible,
  onClose,
  changeManageSubscribersCallback
}) => {
  const { t } = useTranslation('FileSubscribers');
  const dispatch = useDispatch();

  const { asset } = data;

  const onSubmit = ({ added, deleted }) => {
    dispatch(
      changeManageSubscribers({
        entityId: asset.id,
        entityType: TYPE_ASSET,
        added,
        deleted
      })
    ).then(res => {
      changeManageSubscribersCallback(res);
    });
  };

  return (
    <SubscribersDrawer
      visible={visible}
      onClose={onClose}
      submit={onSubmit}
      canManageSubscribers={asset.canManageSubscribers}
      id={String(asset.id)}
      title={t('AssetSubscribersHeading')}
      description={t('AssetSubscribersDesc')}
    />
  );
};

AssetSubscribersDrawer.propTypes = {
  data: PropTypes.shape({
    asset: PropTypes.shape({
      canManageSubscribers: PropTypes.bool.isRequired,
      category: PropTypes.object,
      id: PropTypes.number.isRequired,
      inventoryNumber: PropTypes.string,
      isSubscribed: PropTypes.bool.isRequired,
      permissions: PropTypes.shape({
        deleteAssetCategory: PropTypes.bool.isRequired,
        editAsset: PropTypes.bool.isRequired
      }).isRequired,
      responsible: PropTypes.shape({
        id: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
        phone: PropTypes.string.isRequired
      }).isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  changeManageSubscribersCallback: PropTypes.func
};

AssetSubscribersDrawer.defaultProps = {
  data: {
    asset: {}
  },
  changeManageSubscribersCallback: () => {}
};

export default AssetSubscribersDrawer;
