import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const { Option } = Select;

const StatusSelect = ({
  className,
  isActive,
  allowChange,
  isLoading,
  values,
  ...selectProps
}) => (
  <Select
    className={classnames(className, styles.root)}
    data-qa="qa-2qeldogsa4plfh3"
    disabled={!isActive || !allowChange}
    size="small"
    loading={isLoading}
    {...selectProps}
  >
    {(values || []).map(status => (
      <Option key={status.id} value={status.id}>
        {status.label}
      </Option>
    ))}
  </Select>
);

StatusSelect.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  values: PropTypes.array
};

StatusSelect.defaultProps = {
  className: undefined,
  isActive: false,
  isLoading: false,
  values: []
};

export default StatusSelect;
