export default api => ({
  subscribe: ({ workspaceId, entityId, entityType }) =>
    api.post(`v1/${workspaceId}/subscriptions/subscribe/`, {
      entity_id: entityId,
      entity_type: entityType
    }),

  unsubscribe: ({ workspaceId, entityId, entityType }) =>
    api.post(`v1/${workspaceId}/subscriptions/unsubscribe/`, {
      entity_id: entityId,
      entity_type: entityType
    }),

  fetchSubscribers: ({ workspaceId, entityId }) =>
    api.get(`v1/${workspaceId}/subscriptions/${entityId}/subscribers/`),

  manageSubscribers: ({
    workspaceId,
    entityId,
    entityType,
    added,
    deleted,
    created
  }) =>
    api.post(`v1/${workspaceId}/subscriptions/manage-subscribers/`, {
      entity_id: entityId,
      entity_type: entityType,
      add_subscribers: added,
      remove_subscribers: deleted,
      created
    }),

  manageBulkSubscribers: ({ workspaceId, data }) =>
    api.post(
      `v1/${workspaceId}/subscriptions/bulk-manage-subscribers/`,
      data.map(sub => ({
        entity_id: sub.entityId,
        entity_type: sub.entityType,
        add_subscribers: sub.added,
        remove_subscribers: sub.deleted,
        created: sub.withNewAttachment || false
      }))
    )
});
