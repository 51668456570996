import { createSelector } from 'reselect';

import { TYPE_EMPLOYEE } from 'constants/index';

const getOperator = state => state.operator;

export const getSendingMessageData = createSelector(
  getOperator,
  operator => operator.sendingMessageData
);

export const getSendingDraftMessages = createSelector(
  getOperator,
  operator => operator.sendingDraftMessages
);

export const getIsNeedToReconnect = createSelector(
  getOperator,
  operator => operator.isNeedToReconnect
);

export const getOperatorIsReady = createSelector(
  getOperator,
  operator => operator.isReady
);

export const getEntityChat = createSelector(
  getOperator,
  operator => ({ entityId, entityType }) =>
    operator.entities[entityType].entityChats[entityId] || {}
);

export const getEntityChatHasNewMessages = createSelector(
  getOperator,
  operator => ({ entityType }) => operator.entities[entityType].hasNewMessages
);

export const getEmployeeEntityChatsUnreadedCount = createSelector(
  getOperator,
  operator => operator.entities[TYPE_EMPLOYEE].unreadedCount
);

export const getNotificationChatDisplay = createSelector(
  getOperator,
  operator => ({ view }) => operator.notificationChatDisplay[view]
);

export const getChatEntities = createSelector(
  getOperator,
  operator => operator.chatEntities
);

export const getCollapsedWidgetPanel = createSelector(
  getOperator,
  operator => operator.entities[TYPE_EMPLOYEE].collapsedWidgetPanel
);

export const getCollapsedFilterPanel = createSelector(
  getOperator,
  operator => operator.entities[TYPE_EMPLOYEE].collapsedFilterPanel
);

export const getCollapsedInBellFilterPanel = createSelector(
  getOperator,
  operator => operator.entities[TYPE_EMPLOYEE].collapsedInBellFilterPanel
);

export const getFilterNotifications = createSelector(
  getOperator,
  operator => operator.entities[TYPE_EMPLOYEE].filter
);
