import React, { useState } from 'react';
import { Spin } from 'antd';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormInputNumber, FormTextarea } from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import { convertToMinutes } from 'utils/convert-to-minutes';
import getTimeByEstimate from 'utils/get-time-by-estimate';
import { showNoticeMessage } from 'services/notice';

import styles from './estimate.module.scss';

export const EstimateForm = ({
  defaultValues,
  showComments,
  onSubmit,
  onCancel,
  onlySave,
  submitStyle,
  saveMessage,
  skip
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { control, reset, ...methods } = useForm({
    defaultValues: {
      ...defaultValues,
      ...getTimeByEstimate(defaultValues.estimation)
    }
  });

  const { t } = useTranslation(['Task', 'Common']);

  const hours = useWatch({ name: 'hours', control }) || '';
  const minutes = useWatch({ name: 'minutes', control }) || '';

  const getHasValues = () =>
    ((hours || minutes).length > 0 && (+minutes > 0 || +hours > 0)) ||
    defaultValues.estimation > 0;

  const getDifferenceLabor = (previous, current) => current - previous;

  const visibleComments = showComments && getHasValues();

  const handleSubmit = async newValues => {
    try {
      setIsLoading(true);

      const time = convertToMinutes(newValues);
      const prevTime = convertToMinutes(
        getTimeByEstimate(defaultValues.estimation)
      );
      const diff = getDifferenceLabor(prevTime, time);

      await onSubmit({
        value: time,
        diff,
        description: newValues.description
      });

      if (saveMessage && getHasValues()) {
        showNoticeMessage({ customContent: saveMessage });
      }

      reset({ hours: '', minutes: '' });
    } catch ({ response }) {
      if (response.status === 428) {
        setError(response.data.detail);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider reset={reset} control={control} {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 8
          }}
        >
          <FormInputNumber
            name="hours"
            label={t('Hours')}
            placeholder="000"
            style={{ width: '100%' }}
            maxLength={3}
          />

          <FormInputNumber
            name="minutes"
            label={t('Minutes')}
            placeholder="000"
            maxLength={3}
            style={{ width: '100%' }}
            autoFocus
          />
        </div>

        {visibleComments && (
          <FormTextarea
            name="description"
            label={t('TrackComment')}
            autoSize={{
              minRows: 1,
              maxRows: 4
            }}
            placeholder={t('EnterComment')}
          />
        )}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: onlySave ? '1fr' : '1fr 1fr',
            gap: 8
          }}
        >
          {!onlySave && (
            <Button type="secondary" onClick={onCancel}>
              {t('CancelBtn')}
            </Button>
          )}

          <Spin spinning={isLoading}>
            <div className={styles.buttonsWrap}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!getHasValues()}
                style={{ width: '100%', ...submitStyle }}
              >
                {t('SaveBtn')}
              </Button>

              {skip.allow && (
                <Button
                  type="secondary"
                  style={{ width: '100%' }}
                  onClick={skip.click}
                >
                  {t('Skip', { ns: 'Common' })}
                </Button>
              )}
            </div>
          </Spin>
        </div>

        {error && (
          <Typography.Text className={styles.error} size="small" color="red">
            {error}
          </Typography.Text>
        )}
      </form>
    </FormProvider>
  );
};

EstimateForm.propTypes = {
  defaultValues: PropTypes.shape({
    estimation: PropTypes.number,
    description: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showComments: PropTypes.bool,
  onlySave: PropTypes.bool,
  submitStyle: PropTypes.object,
  saveMessage: PropTypes.string,
  skip: PropTypes.shape({
    allow: PropTypes.bool,
    click: PropTypes.func
  })
};

EstimateForm.defaultProps = {
  defaultValues: {},
  showComments: false,
  onlySave: false,
  submitStyle: {},
  onSubmit: () => {},
  onCancel: () => {},
  saveMessage: null,
  skip: {
    allow: false,
    click: () => {}
  }
};

export default EstimateForm;
