import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from '../components.module.scss';

const Private = ({ isDisabled, tooltip, value, onChange }) => {
  const { t } = useTranslation('Task');

  return (
    <Tooltip
      title={isDisabled || tooltip}
      mouseEnterDelay={0.5}
      placement="top"
    >
      <Checkbox
        className={styles.isPrivate}
        type="checkbox"
        checked={value}
        disabled={isDisabled}
        onChange={e => onChange(e.target.checked)}
      >
        <span className={styles.private} data-qa="qa-zatf2ouhxkumz05">
          {t('PrivateCommentChckbx')}
        </span>
      </Checkbox>
    </Tooltip>
  );
};

Private.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Private;
