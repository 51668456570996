import { useState, useEffect } from 'react';

import { MODAL, EDIT_TASK, SELECTED_TASK } from '../../constants';
import { useQueryParam } from '../common';

export const useTaskEditor = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);
  const task = Number(useQueryParam(SELECTED_TASK));

  useEffect(() => {
    setVisible(!!(modal === EDIT_TASK && task));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useTaskEditor;
