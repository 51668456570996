import moment from 'moment';

import { TYPE_TASK } from 'constants/tasks';

export const getSubtaskValue = ({ subtask = {}, task, isSubTask = false }) => {
  // если нет ответственного, то указывается ответственный родительской задачи
  const responsible = (subtask.responsible || {}).value || task.responsible.id;
  const valueRelations = (task.relations || []).map(val => ({
    relationType: val.type,
    relationId: val.objectId
  }));

  const results = {
    parent: task.id,
    kind: task.kind || TYPE_TASK,
    title: subtask.title,
    responsible,
    relations: isSubTask ? undefined : valueRelations,
    members: task.members
  };

  if (task.dateEnd) {
    results.dateEnd = task.dateEnd;
  }

  if (task.dateStart && moment(task.dateStart).isAfter(moment())) {
    results.dateStart = task.dateStart;
  }

  return results;
};

export default getSubtaskValue;
