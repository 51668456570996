import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getWebsiteFormLink } from 'components/contacts-view/views/integrations/utils';

import CommonModal from '../common';

export const WebsiteFormCreationSuccessedModal = ({
  visible,
  data,
  onClose
}) => {
  const { t } = useTranslation('ChannelCreated');

  return (
    <CommonModal
      visible={visible}
      codeCopyMessage={t('LinkCopied', { ns: 'Toast' })}
      description={t('FormCreatedDesc')}
      link={getWebsiteFormLink(data.uuid)}
      onClose={onClose}
      title={t('FormCreatedHeading')}
    />
  );
};

WebsiteFormCreationSuccessedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    config: PropTypes.shape({
      uuid: PropTypes.string
    })
  })
};

WebsiteFormCreationSuccessedModal.defaultProps = {
  data: {
    config: {}
  }
};

export default WebsiteFormCreationSuccessedModal;
