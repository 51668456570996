import qs from 'qs';

import { CALENDAR } from 'constants/api';

import makeCancelableRequest from 'utils/make-cancalable-request';

export default api => ({
  // ASSETS
  createAssetRecord: ({ workspaceId, record }) =>
    api.post(`/v1/${workspaceId}/${CALENDAR}/asset/`, {
      asset: record.assetId,
      kind: record.kind,
      objId: record.objectId,
      dateStart: record.dateStart,
      dateEnd: record.dateEnd
    }),

  updateAssetRecord: ({ workspaceId, recordId, record }) =>
    api.patch(`/v1/${workspaceId}/${CALENDAR}/asset/${recordId}/`, {
      asset: record.assetId,
      dateStart: record.dateStart,
      dateEnd: record.dateEnd
    }),

  deleteAssetRecord: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/${CALENDAR}/asset/${id}/`),

  fetchAssetBookingDateRange: ({ workspaceId, params }) =>
    api.get(`/v1/${workspaceId}/${CALENDAR}/asset/booking-date-range/`, {
      params: {
        limit: params.limit,
        offset: params.offset,
        date_start: params.dateStart,
        date_end: params.dateEnd,
        asset: params.assetId,
        record: params.recordId
      }
    }),

  fetchAssetList: ({ workspaceId, params }) =>
    api.get(`/v1/${workspaceId}/${CALENDAR}/asset/list/`, {
      params: {
        limit: params.limit,
        offset: params.offset,
        date_start: params.dateStart,
        date_end: params.dateEnd,
        search: params.search
      }
    }),

  fetchAssetRecords: makeCancelableRequest(
    api,
    (
      fetch,
      { workspaceId, limit, offset, asset, taskResponsible, orderResponsible }
    ) =>
      fetch('get', `/v1/${workspaceId}/${CALENDAR}/assets-records/`, {
        params: {
          limit,
          offset,
          asset,
          task_responsible: taskResponsible,
          order_responsible: orderResponsible
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchAssetResources: makeCancelableRequest(
    api,
    (fetch, { workspaceId, limit, offset, asset, category }) =>
      fetch('get', `/v1/${workspaceId}/${CALENDAR}/assets-to-calendar/`, {
        params: {
          limit,
          offset,
          asset,
          category
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchAssetTaskBookingData: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/${CALENDAR}/asset/${id}/tasks-records/`),

  fetchAssetOrderStatusBookingData: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/${CALENDAR}/asset/${id}/orders-records/`),

  // EMPLOYEES
  fetchEmployeeRecords: makeCancelableRequest(
    api,
    (fetch, { workspaceId, params }) =>
      fetch('get', `/v1/${workspaceId}/${CALENDAR}/record-attendees/`, {
        params: {
          limit: params.limit,
          employee: params.employee,
          date_start: params.dateStart,
          date_end: params.dateEnd,
          exclude_record_object_id: params.excludeRecordObjectId,
          object_id: params.objectId
        },
        paramsSerializer(p) {
          return qs.stringify(p, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchOneEmployeeRecord: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/${CALENDAR}/record-attendees/${id}/`),

  updateEmployeeRecordStatus: ({ workspaceId, id, data }) =>
    api.patch(`/v1/${workspaceId}/${CALENDAR}/record-attendees/${id}/status/`, {
      status: data.status,
      date: data.date
    })
});
