import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ADDON_TYPE_WHATSAPP } from 'constants/billing';

import { getActiveWorkspace } from 'store/workspace';

import WhatsAppAccountAddon from './whatsapp';

const AccountAddon = ({
  availableAddon,
  addon,
  selectedTariff,
  checkout,
  changeAddon
}) => {
  const workspace = useSelector(getActiveWorkspace);

  const handleChangeAddon = values =>
    changeAddon({ id: availableAddon.id, ...values });

  const addonByType = {
    [ADDON_TYPE_WHATSAPP]: (
      <WhatsAppAccountAddon
        availableAddon={availableAddon}
        addon={addon}
        selectedTariff={selectedTariff}
        checkout={checkout}
        account={workspace.account}
        changeAddon={handleChangeAddon}
      />
    )
  };

  return addonByType[availableAddon.type] || null;
};

AccountAddon.propTypes = {
  availableAddon: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }).isRequired,
  selectedTariff: PropTypes.shape({
    tariff: PropTypes.object,
    period: PropTypes.string,
    currency: PropTypes.string
  }).isRequired,
  addon: PropTypes.shape({
    count: PropTypes.string,
    isEnabled: PropTypes.bool,
    isDisabled: PropTypes.bool
  }),
  checkout: PropTypes.shape({
    price: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.string,
    period: PropTypes.string,
    months: PropTypes.string,
    payNow: PropTypes.bool,
    paymentDate: PropTypes.string,
    nextPaymentDate: PropTypes.string
  }),
  changeAddon: PropTypes.func.isRequired
};

AccountAddon.defaultProps = {
  addon: undefined,
  checkout: null
};

export default AccountAddon;
