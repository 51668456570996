import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import TagOption from 'components/common/controls/custom-select/custom-select/tag-option';
import {
  TAG_TYPE_ADDRESS,
  TAG_TYPE_DATE,
  TAG_TYPE_NUMBER,
  TAG_TYPE_TEXT
} from 'components/common/tags/utils/tag-types';

import styles from './selecting-type.module.scss';

const SelectingType = ({ onSelect, selectedCreateTypeTag }) => {
  const { t } = useTranslation('TagsEditReplaceDelete');

  const availableOptions = [
    TAG_TYPE_TEXT,
    TAG_TYPE_ADDRESS,
    TAG_TYPE_NUMBER,
    TAG_TYPE_DATE
  ];

  const creationTagTypeList = [
    {
      id: 1,
      name: t('Text'),
      type: TAG_TYPE_TEXT
    },
    {
      id: 2,
      name: t('Address'),
      type: TAG_TYPE_ADDRESS
    },
    {
      id: 3,
      name: t('Date'),
      type: TAG_TYPE_DATE
    },
    {
      id: 4,
      name: t('Number'),
      type: TAG_TYPE_NUMBER
    }
  ];

  return (
    <div>
      <Typography.Paragraph color="black-55" className={styles.title}>
        {t('CreateByTagType')}
      </Typography.Paragraph>

      <div className={styles.actionWrap}>
        {creationTagTypeList
          .filter(opt => availableOptions.includes(opt.type))
          .map(opt => (
            <TagOption
              option={opt}
              isCreateType
              onCreate={onSelect}
              selectedCreateTypeTag={selectedCreateTypeTag}
            />
          ))}
      </div>
    </div>
  );
};

SelectingType.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedCreateTypeTag: PropTypes.object
};

SelectingType.defaultProps = {
  selectedCreateTypeTag: undefined
};

export default SelectingType;
