import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import styles from './unavailable.module.scss';

const UnavailableView = ({ icon, title, description }) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <Icon component={icon} />

      <Typography.Title className={styles.title}>{title}</Typography.Title>

      {description && (
        <Typography.Paragraph color="black-45">
          {description}
        </Typography.Paragraph>
      )}
    </div>
  </div>
);

UnavailableView.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

UnavailableView.defaultProps = {
  description: null
};
export default UnavailableView;
