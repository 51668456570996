import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Typography from 'components/common/typography';

import styles from './day-header.module.scss';

const DayHeader = ({ date, showDayOfMonth = true }) => {
  const dayOfWeek = moment(date).format('ddd');
  const dayOfMonth = moment(date).format('DD');

  return (
    <div className={styles.root}>
      <Typography.Text size="small" color="black-45" className={styles.day}>
        {dayOfWeek}
      </Typography.Text>

      {showDayOfMonth && (
        <Typography.Title level={2} className={styles.number}>
          {dayOfMonth}
        </Typography.Title>
      )}
    </div>
  );
};

DayHeader.propTypes = {
  date: PropTypes.any.isRequired,
  showDayOfMonth: PropTypes.bool
};

DayHeader.defaultProps = {
  showDayOfMonth: true
};

export default DayHeader;
