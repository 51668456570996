import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../icon';
import Typography from '../typography';

import styles from './details-field.module.scss';

const DetailsField = ({ iconType, title, className, children }) => (
  <div className={classnames(styles.root, className)}>
    <div className={styles.titleWrap}>
      <Icon
        type={iconType}
        color="black-55"
        size={16}
        className={styles.icon}
      />

      <Typography.Text color="black-55">{title} </Typography.Text>
    </div>

    {children}
  </div>
);

DetailsField.propTypes = {
  iconType: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

DetailsField.defaultProps = {
  className: undefined
};

export default DetailsField;
