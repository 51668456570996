import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getRouterUrlQuery } from '../../store/router';

const ANALYTICS_PARAMS = 'analyticsParams';

const UTM = 'utm';
const REFERR = 'referr';

const getInitialParams = () => {
  const stringified = JSON.parse(localStorage.getItem(ANALYTICS_PARAMS));

  return isEmpty(stringified) ? {} : stringified;
};

export const useAnalyticsParams = () => {
  const query = useSelector(getRouterUrlQuery);

  const [params, set] = useState(getInitialParams());

  const add = param => {
    localStorage.setItem(
      ANALYTICS_PARAMS,
      JSON.stringify({ ...params, ...param })
    );

    set({ ...params, ...param });
  };

  const remove = () => {
    set({});
    localStorage.removeItem(ANALYTICS_PARAMS);
  };

  const initialParams = {};

  Object.keys(query).forEach(key => {
    if (key.toLowerCase().includes(UTM) || key.toLowerCase().includes(REFERR)) {
      initialParams[key] = query[key];
    }
  });

  useEffect(() => {
    if (!isEmpty(initialParams)) {
      add(initialParams);
    }
    // eslint-disable-next-line
  }, [query]);

  return { params, add, remove };
};

export default useAnalyticsParams;
