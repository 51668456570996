import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import Button from 'components/common/button';
import { FormDatePicker, withoutBubbling } from 'components/common/hook-form';

import styles from './validity-date-form.module.scss';

const ValidityDateForm = ({ onSubmit, label, placeholder, data }) => {
  const { t } = useTranslation(['Common', 'Errors']);

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      validityDate: data.validityDate
        ? moment(data.validityDate).toDate()
        : moment().toDate()
    }
  });

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      const dataToSave = {
        validityDate: moment(values.validityDate).toISOString(true)
      };

      await onSubmit(dataToSave);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        <FormDatePicker
          name="validityDate"
          label={label}
          placeholderText={placeholder}
          showTimeSelect
          autoFocus
          minDate={new Date()}
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            validate: value =>
              !value || moment(value).isAfter(moment().add(24, 'hours'))
                ? true
                : t('PeriodGreaterThan24Hours', {
                    ns: 'Errors'
                  })
          }}
          wrapperClassname={styles.datePickerWrapper}
          dataTestId="validity-date-input"
        />

        <div className={styles.formButtons}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('Save', { ns: 'Common' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

ValidityDateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.shape({
    validityDate: PropTypes.string
  })
};

ValidityDateForm.defaultProps = {
  data: {}
};

export default ValidityDateForm;
