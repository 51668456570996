import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ActionsDropdown from 'components/common/actions';
import TemplateEditorDrawer from 'components/tasks-view/template-view/editor-drawer';

import { getUserEmployee } from 'store/workspace';

const Actions = ({ template, updateRecord }) => {
  const currentEmployee = useSelector(getUserEmployee);

  const [visibleTemplateEditor, setVisibleTemplateEditor] = useState(false);

  const { author } = template;

  const allowEdit = author.id === currentEmployee.id;

  const actions = [
    {
      title: 'EditTemplateAction',
      ns: 'MeetingPreviewCalendar',
      onClick: () => setVisibleTemplateEditor(true),
      allow: allowEdit
    }
  ];

  return (
    <>
      <ActionsDropdown actions={actions} />

      <TemplateEditorDrawer
        visible={visibleTemplateEditor}
        template={template}
        callback={updateRecord}
        onClose={() => setVisibleTemplateEditor(false)}
      />
    </>
  );
};

export default Actions;
