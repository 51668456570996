import React from 'react';

export const PenIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.30585 15.3327L2.17113 13.3048C1.7167 6.46439 7.14266 0.666016 13.9982 0.666016L12.5963 1.7211C12.3224 1.92727 12.1854 2.03035 12.0593 2.13357C10.291 3.5809 9.22605 5.71531 9.133 7.9985C9.12636 8.16132 9.12636 8.33274 9.12636 8.67559C9.12636 8.95926 9.12636 9.1011 9.1174 9.21017C8.98553 10.814 7.47392 11.9365 5.90048 11.599C5.79347 11.5761 5.65766 11.5351 5.38614 11.4531L2.44914 10.566"
      stroke="currentColor"
    />
  </svg>
);

export default PenIcon;
