import React from 'react';

export const NoAssetsIcon = ({ style = { width: 105, height: 105 } }) => (
  <svg
    style={style}
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.3155 90.0286C15.1163 84.1819 -2.02944 61.3329 2.92954 44.8867C3.90518 41.6645 5.66446 38.7344 8.04993 36.3587C29.8318 14.6037 89.5637 6.33576 92.2271 18.2983C93.9129 25.8937 91.8415 30.1712 93.5273 33.9823C95.536 38.5198 99.5086 37.7307 101.526 40.6092C107.355 48.9399 94.5227 74.5061 73.6286 85.5091C70.1762 87.3026 57.03 93.7411 40.7272 91.3917C38.557 91.1094 36.4129 90.6536 34.3155 90.0286Z"
      fill="#EBF8F8"
    />
    <path
      d="M79.3679 51.2446H20.8555L30.7555 31.2114H69.4768L79.3679 51.2446Z"
      fill="#FDA402"
    />
    <path
      d="M69.4861 31.2114H30.7559V58.5889H69.4861V31.2114Z"
      fill="#ED9406"
    />
    <path
      d="M80.6867 59.4408V89.2575C80.6867 90.7471 80.0956 92.1759 79.0431 93.23C77.9906 94.2842 76.5627 94.8776 75.0731 94.88H25.1605C23.7875 94.8787 22.4626 94.3742 21.4365 93.4621C20.4103 92.5499 19.754 91.2933 19.5917 89.93C19.5736 89.7092 19.5736 89.4872 19.5917 89.2664V59.4408H80.6867Z"
      fill="#ED9406"
    />
    <path
      d="M75.1359 59.4408V84.2805C75.1394 85.0212 74.9966 85.7552 74.7156 86.4405C74.4346 87.1257 74.021 87.7487 73.4986 88.2737C72.9761 88.7987 72.3551 89.2152 71.6712 89.4995C70.9873 89.7837 70.2539 89.93 69.5133 89.93H19.5917C19.5736 89.7092 19.5736 89.4872 19.5917 89.2664V59.4408H75.1359Z"
      fill="#FDA402"
    />
    <path
      d="M80.9281 51.2625H19.304C17.9668 51.2625 16.8828 52.3465 16.8828 53.6837V58.7234C16.8828 60.0606 17.9668 61.1446 19.304 61.1446H80.9281C82.2653 61.1446 83.3493 60.0606 83.3493 58.7234V53.6837C83.3493 52.3465 82.2653 51.2625 80.9281 51.2625Z"
      fill="#FCC977"
    />
    <path
      d="M58.4108 80.6667H41.4892C40.4737 80.6667 39.4998 80.2632 38.7817 79.5451C38.0636 78.8271 37.6602 77.8531 37.6602 76.8376C37.6602 75.822 38.0636 74.8481 38.7817 74.13C39.4998 73.4119 40.4737 73.0085 41.4892 73.0085H58.4108C59.4263 73.0085 60.4002 73.4119 61.1183 74.13C61.8364 74.8481 62.2398 75.822 62.2398 76.8376C62.2398 77.8531 61.8364 78.8271 61.1183 79.5451C60.4002 80.2632 59.4263 80.6667 58.4108 80.6667Z"
      fill="#2B2A36"
    />
    <path
      d="M75.04 42.76H25.6C23.56 42.76 22 41.2 22 39.16V31.6C22 29.56 23.56 28 25.6 28H75.04C77.08 28 78.64 29.56 78.64 31.6V39.16C78.64 41.08 77.08 42.76 75.04 42.76Z"
      fill="#CEF4F4"
    />
    <path
      d="M30.1605 38.92C32.0805 38.92 33.7605 37.36 33.7605 35.32C33.7605 33.28 32.2005 31.72 30.1605 31.72C28.2405 31.72 26.5605 33.28 26.5605 35.32C26.5605 37.36 28.2405 38.92 30.1605 38.92Z"
      fill="#2D9EA3"
    />
    <path
      d="M55.3585 39.52H38.6785C37.9585 39.52 37.4785 39.04 37.4785 38.32C37.4785 37.6 37.9585 37.12 38.6785 37.12H55.3585C56.0785 37.12 56.5585 37.6 56.5585 38.32C56.5585 39.04 55.9585 39.52 55.3585 39.52Z"
      fill="#2D9EA3"
    />
    <path
      d="M71.6794 33.64H38.5594C37.8394 33.64 37.3594 33.16 37.3594 32.44C37.3594 31.72 37.8394 31.24 38.5594 31.24H71.5594C72.2794 31.24 72.7594 31.72 72.7594 32.44C72.7594 33.16 72.2794 33.64 71.6794 33.64Z"
      fill="#2D9EA3"
    />
    <path
      d="M68.3189 39.52H62.0789C61.3589 39.52 60.8789 39.04 60.8789 38.32C60.8789 37.6 61.3589 37.12 62.0789 37.12H68.3189C69.0389 37.12 69.5189 37.6 69.5189 38.32C69.5189 39.04 68.9189 39.52 68.3189 39.52Z"
      fill="#2D9EA3"
    />
    <path
      d="M79.648 22.712H20.32C17.872 22.712 16 20.84 16 18.392V9.31999C16 6.872 17.872 5 20.32 5H79.648C82.096 5 83.968 6.872 83.968 9.31999V18.392C83.968 20.696 81.952 22.712 79.648 22.712Z"
      fill="#CEF4F4"
    />
    <path
      d="M25.7927 18.104C28.0967 18.104 30.1127 16.232 30.1127 13.784C30.1127 11.336 28.2407 9.46399 25.7927 9.46399C23.4887 9.46399 21.4727 11.336 21.4727 13.784C21.4727 16.232 23.3447 18.104 25.7927 18.104Z"
      fill="#2D9EA3"
    />
    <path
      d="M56.0322 18.8239H36.0162C35.1522 18.8239 34.5762 18.2479 34.5762 17.3839C34.5762 16.5199 35.1522 15.9439 36.0162 15.9439H56.0322C56.8962 15.9439 57.4722 16.5199 57.4722 17.3839C57.4722 18.2479 56.7522 18.8239 56.0322 18.8239Z"
      fill="#2D9EA3"
    />
    <path
      d="M75.6157 11.7679H35.8716C35.0076 11.7679 34.4316 11.1919 34.4316 10.3279C34.4316 9.46394 35.0076 8.88794 35.8716 8.88794H75.4717C76.3357 8.88794 76.9117 9.46394 76.9117 10.3279C76.9117 11.1919 76.3357 11.7679 75.6157 11.7679Z"
      fill="#2D9EA3"
    />
    <path
      d="M71.4397 18.8239H63.9517C63.0877 18.8239 62.5117 18.2479 62.5117 17.3839C62.5117 16.5199 63.0877 15.9439 63.9517 15.9439H71.4397C72.3037 15.9439 72.8797 16.5199 72.8797 17.3839C72.8797 18.2479 72.3037 18.8239 71.4397 18.8239Z"
      fill="#2D9EA3"
    />
  </svg>
);

export default NoAssetsIcon;
