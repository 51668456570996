import React from 'react';

export const ChannelsNotFoundIcon = ({
  style = { width: 150, height: 150 }
}) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.9852 86.1355C61.0173 86.1865 59.9985 86.2374 59.0305 86.1865C54.14 86.0846 49.0966 86.2374 44.257 86.3393C44.0023 86.3393 43.7476 86.3393 43.4929 86.3393C40.2325 86.3903 37.074 86.3903 34.1193 86.2374C33.1004 86.1865 32.0816 86.1355 31.1136 86.0337C21.1288 85.0657 14.608 81.7544 17.1552 71.362C17.359 70.496 17.4099 69.579 17.359 68.662C17.1043 64.7394 14.7609 60.2054 12.9269 55.5187C12.4684 54.3979 12.1118 53.2772 11.7552 52.1564C11.3477 50.8319 11.042 49.5074 10.8382 48.1828C10.5326 45.9413 10.5835 43.7508 11.2967 41.5602C11.2967 41.5602 12.0099 39.3697 13.6401 37.281C15.6269 34.7338 38.7551 10.5358 66.0098 19.8074C67.1305 20.164 93.9267 29.7923 94.3342 51.9526C94.7418 73.4507 69.7286 85.6771 61.9852 86.1355Z"
      fill="#EBF8F8"
    />
    <path
      d="M52.2551 25.7678C52.2551 25.7678 59.9985 21.7433 61.272 21.998C62.5456 22.2527 66.1626 29.4866 65.6532 29.7923C65.1437 30.098 61.8834 28.0602 61.8834 28.0602C61.8834 28.0602 61.5268 29.5376 60.406 29.3338C59.2852 29.13 57.757 27.5508 57.757 27.5508C57.757 27.5508 57.6551 28.5187 56.5343 28.3659C55.4136 28.2131 53.5287 26.5319 53.5287 26.5319L52.2551 25.7678Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M24.6948 23.1697C24.6948 23.1697 17.8175 17.7697 16.5439 17.7697C15.2703 17.7697 10.3797 24.2395 10.7873 24.6471C11.2458 25.0546 14.8118 23.6791 14.8118 23.6791C14.8118 23.6791 14.8628 25.1565 16.0344 25.2074C17.1552 25.2074 18.9892 23.9848 18.9892 23.9848C18.9892 23.9848 18.8873 24.9527 20.059 25.0037C21.2307 25.0546 23.3703 23.7301 23.3703 23.7301L24.6948 23.1697Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M50.6249 36.9753L24.3891 34.53C22.9627 34.3772 21.8929 33.1036 21.9948 31.6772L23.5231 15.5791C23.6759 14.1527 24.9495 13.0829 26.3759 13.1848L52.6117 15.6301C54.0381 15.7829 55.1079 17.0565 55.006 18.4829L53.4777 34.581C53.3249 36.0583 52.0513 37.1281 50.6249 36.9753Z"
      fill="#FCC977"
    />
    <path
      d="M54.2813 16.4594L40.0382 26.9312C38.7382 27.9312 37.0382 27.7312 35.8382 26.4312L24.3382 13.9312C24.8382 13.4312 25.5751 13.225 26.3751 13.225L52.7657 15.6937C53.4657 15.7937 53.7813 16.0594 54.2813 16.4594Z"
      fill="#F9B74B"
    />
    <path
      d="M53.9362 16.1905L40.4872 23.9339C39.2646 24.6471 37.7872 24.4942 36.7174 23.5773L24.9495 13.4905C25.408 13.2867 25.8969 13.1115 26.4063 13.1625L52.6719 15.6469C53.1814 15.6978 53.5796 15.8848 53.9362 16.1905Z"
      fill="#FFE181"
    />
    <path
      d="M87.2022 55.7734L49.657 47.0621L32.6929 52.7677L65.0418 64.8922L87.2022 55.7734Z"
      fill="#2D9EA3"
    />
    <path
      d="M75.9946 73.7054L45.3268 66.8281C43.6457 66.4715 42.5759 64.7903 42.9325 63.0583L47.1608 44.2093C47.5174 42.5282 49.1985 41.4583 50.9306 41.8149L81.5984 48.6923C83.2795 49.0489 84.3493 50.73 83.9927 52.4621L79.7644 71.3111C79.4078 73.0432 77.7267 74.113 75.9946 73.7054Z"
      fill="#F9B74B"
    />
    <path
      d="M70.9513 75.7431L40.2834 68.8658C38.6023 68.5092 37.5325 66.8281 37.8891 65.096L42.1174 46.247C42.474 44.5659 44.1551 43.4961 45.8872 43.8527L76.555 50.73C78.2361 51.0866 79.3059 52.7677 78.9493 54.4998L74.7211 73.3488C74.3644 75.0809 72.6833 76.1507 70.9513 75.7431Z"
      fill="#FCC977"
    />
    <path
      d="M78.0833 51.596L60.9664 58.7281C59.4381 59.3903 57.6551 58.9828 56.5853 57.7602L44.2061 44.0055C44.7155 43.8017 45.3268 43.8017 45.8872 43.9036L76.555 50.7809C77.1663 50.9338 77.6758 51.2394 78.0833 51.596Z"
      fill="#FFE181"
    />
    <path
      d="M31.0118 50.5772C42.1683 52.7168 53.3249 54.9073 64.4815 57.0469C66.4173 57.4035 67.7418 59.0847 67.6909 61.0205L66.8758 88.0204C66.7739 90.6185 64.2777 92.4016 61.8324 91.6884L28.5155 81.8563C26.8853 81.3469 25.7137 79.8695 25.7137 78.1374C25.8155 70.1394 25.9684 62.1922 26.0703 54.1941C26.0703 54.0922 25.9174 52.1564 27.4967 50.9847C29.0759 49.813 30.9099 50.5262 31.0118 50.5772Z"
      fill="#1DC1C9"
    />
    <path
      d="M67.6909 61.0205L66.8758 88.0204C66.8249 89.7016 65.7551 91.077 64.3286 91.5865C64.8381 90.9242 65.1437 90.1601 65.1947 89.2431L66.0098 62.2432C66.0607 60.3073 64.6852 58.6262 62.8003 58.2696C51.5928 56.13 40.4363 53.9394 29.2797 51.7998C29.2287 51.7489 28.0571 51.2904 26.7325 51.7489C26.9363 51.4941 27.1401 51.2394 27.4457 51.0356C29.025 49.864 30.8589 50.5772 30.9608 50.5772C42.1174 52.7168 53.274 54.9073 64.4305 57.0469C66.3664 57.4036 67.7418 59.0847 67.6909 61.0205Z"
      fill="#36B7BD"
    />
    <path
      d="M51.5928 70.445V70.9545C51.5419 72.3299 50.2174 73.3488 48.8928 72.9922L37.6344 70.1903C36.6155 69.9356 35.9533 69.0186 36.0042 67.9997C36.0551 66.6243 37.2778 65.6564 38.6023 65.9111L49.8608 68.2035C50.8796 68.4582 51.6438 69.3752 51.5928 70.445Z"
      fill="#2B2A36"
    />
    <path
      d="M67.2324 63.0583L87.2022 55.7734L86.489 80.379L66.672 89.294L67.2324 63.0583Z"
      fill="#36B7BD"
    />
    <path
      d="M82.4135 59.4922L75.8418 85.1676L66.672 89.294L67.1815 65.2998L82.4135 59.4922Z"
      fill="#1DC1C9"
    />
    <path
      d="M81.7512 29.181C81.7512 29.181 83.4324 26.1753 83.9418 25.9716C84.4003 25.7678 87.2022 27.398 87.1003 27.6017C86.9984 27.8055 85.4701 27.8565 85.4701 27.8565C85.4701 27.8565 85.6739 28.4168 85.2663 28.6206C84.8588 28.8244 83.9927 28.6206 83.9927 28.6206C83.9927 28.6206 84.1965 28.9772 83.738 29.181C83.2795 29.3848 82.3116 29.2319 82.3116 29.2319L81.7512 29.181Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M72.0211 34.3263C72.0211 34.3263 68.5569 34.0206 68.1494 34.3263C67.7418 34.581 67.4871 37.8414 67.7418 37.8414C67.9966 37.8923 68.8626 36.6187 68.8626 36.6187C68.8626 36.6187 69.2192 37.0772 69.5758 36.8734C69.9833 36.6187 70.289 35.8036 70.289 35.8036C70.289 35.8036 70.4418 36.1602 70.8494 35.9055C71.2569 35.6508 71.6645 34.7848 71.6645 34.7848L72.0211 34.3263Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M83.6361 33.3074L74.3645 38.1979C73.855 38.4526 73.2437 38.2489 72.9381 37.7394L69.9324 32.0338C69.6777 31.5244 69.8815 30.913 70.3909 30.6074L79.6626 25.7168C80.172 25.4621 80.7833 25.6659 81.089 26.1753L84.0946 31.881C84.3493 32.3904 84.1456 33.0527 83.6361 33.3074Z"
      fill="#F9B74B"
    />
    <path
      d="M80.2073 25.6272L77.4055 31.129C77.1507 31.6385 76.6413 31.8932 76.0809 31.8422L69.9677 31.0271C70.0696 30.8743 70.2225 30.7215 70.3753 30.6196L79.647 25.729C79.7998 25.6272 80.0036 25.5762 80.2073 25.6272Z"
      fill="#FFE181"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="58.9785"
        y1="21.9864"
        x2="58.9785"
        y2="29.8235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="17.729"
        y1="17.7697"
        x2="17.729"
        y2="25.2074"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="84.4271"
        y1="25.954"
        x2="84.4271"
        y2="29.287"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="69.8305"
        y1="34.1904"
        x2="69.8305"
        y2="37.8429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default ChannelsNotFoundIcon;
