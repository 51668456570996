import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../button';
import Icon from '../icon';

import styles from './entity-info-buttons.module.scss';

/**
 * Component for show buttons which open additional info about entity (docs, relations, etc.)
 * @link https://www.figma.com/file/OCHWlGO8BvCeFZoQvvtvVrBJ/UpServiceWeb?node-id=54077%3A277789
 */
const EntityInfoButtons = ({ buttons, className }) => (
  <div className={classnames(styles.root, className)}>
    {buttons.map(({ icon, text, count, ...b }) => (
      <Button
        key={`entity-info-button-${text}`}
        type="tertiary"
        size="small"
        className={styles.button}
        {...b}
      >
        <Icon type={icon} size={16} color="black-45" />
        {text}

        {Number.isInteger(count) && <div className={styles.count}>{count}</div>}
      </Button>
    ))}
  </div>
);

EntityInfoButtons.propTypes = {
  /** List of button's props object */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
      /** Count of entities */
      count: PropTypes.number
    })
  ).isRequired,
  /** Additional root class */
  className: PropTypes.string
};

EntityInfoButtons.defaultProps = {
  className: undefined
};

export default EntityInfoButtons;
