import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  MOBILE_SIDER_WIDTH,
  SIDER_WIDTH
} from '../../../../../constants/styles';
import useCollapsedSider from './use-collapsed-sider';

import styles from './sider.module.scss';

export const COLLAPSED_SIDER = 'collapsed-sider';

export const Sider = ({ children, className, style }) => {
  const [collapsed] = useCollapsedSider();

  return (
    <Layout.Sider
      className={classnames(styles.root, className)}
      style={{ ...style }}
      width={SIDER_WIDTH}
      collapsedWidth={MOBILE_SIDER_WIDTH}
      collapsed={collapsed}
    >
      {children}
    </Layout.Sider>
  );
};

Sider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object
};

Sider.defaultProps = {
  children: undefined,
  className: undefined,
  style: {}
};

export default Sider;
