import { SORTING } from 'constants/contacts';

export const getOrderingValue = ({ sortDirection, sortField }) => {
  const alphabetical = sortDirection === SORTING.TABLE_DIRECTION.ASC;

  if (!sortDirection) {
    return undefined;
  }

  return alphabetical ? sortField : `${sortField ? `-${sortField}` : ''}`;
};

export default getOrderingValue;
