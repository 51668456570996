import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import { fetchVideo, getVideoFactory } from 'store/guides';

import getIsDemoModeActive from 'utils/get-is-demo-mode-active';

import VideoModal from '../video-modal';

import styles from './video-btn.module.scss';

export const VideoBtn = ({ slug, style, className, hideText, isEllipsis }) => {
  const dispatch = useDispatch();

  const video = useSelector(state => getVideoFactory(state)(slug));

  const [data, setData] = useState({});

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let cleanup = false;

    const fetchData = async () => {
      const videoData = video || (await dispatch(fetchVideo({ slug })));

      if (!cleanup) {
        setData(videoData);
      }
    };

    if (!getIsDemoModeActive()) {
      fetchData();
    }

    return () => {
      cleanup = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (!data.content) {
    return null;
  }

  return (
    <>
      <Button
        type="text"
        mood="none"
        className={classnames(styles.btn, className)}
        style={style}
        onClick={() => setVisible(true)}
      >
        <Icon type="play-circle" theme="filled" size={18} />

        {!hideText && (
          <Typography.Text size="small" color="black-55" ellipsis={isEllipsis}>
            {data.title}
          </Typography.Text>
        )}
      </Button>

      <VideoModal
        data={data}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

VideoBtn.propTypes = {
  slug: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  isEllipsis: PropTypes.bool,
  hideText: PropTypes.bool
};

VideoBtn.defaultProps = {
  slug: '',
  className: '',
  style: {},
  isEllipsis: false,
  hideText: false
};

export default VideoBtn;
