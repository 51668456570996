export default api => ({
  create: ({ registrationId, type }) =>
    api.post(
      `v1/devices/`,
      {
        registrationId,
        type
      },
      { show400: false }
    ),

  delete: ({ registrationId }) => api.delete(`v1/devices/${registrationId}`)
});
