import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AttachmentsControl from 'components/common/attachments-control';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Attachments from 'components/common/attachments';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';
import { ModalsProvider } from 'components/app/modals-provider';
import { updateNewAttachmentValidityDate } from 'components/common/validity-date/utils/update-new-attachment-validity-date';
import { updateSubscribeNewAttachment } from 'components/common/subscriptions/utils/update-subscribe-new-attachment';

import FormItem from '../form-item';

export const FormAttachDocuments = ({
  rules,
  name,
  buttonProps,
  label,
  itemProps,
  actionsDeps
}) => {
  const { t } = useTranslation('Common');

  const { control, formState, setValue } = useFormContext();

  const fileList = useWatch({ name, control });

  const onRenameFile = ({ id, name: fileName }) => {
    const newFileList = fileList.reduce((acc, curr) => {
      if (curr.response && curr.response.id === id) {
        return [...acc, { ...curr, name: fileName }];
      }

      return [...acc, curr];
    }, []);

    setValue(name, newFileList);
  };

  const handleSubscription = ({ data, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments: fileList,
      setAttachments: updatedFileList => setValue(name, updatedFileList),
      data,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: fileList,
      setAttachments: updatedFileList => setValue(name, updatedFileList),
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments: fileList,
      setAttachments: updatedFileList => setValue(name, updatedFileList),
      data: attachment
    });
  };

  const onSetValidityDateToNewAttachment = ({ attachment }) =>
    setValue(
      name,
      updateNewAttachmentValidityDate({ data: attachment, fileList })
    );

  const onSubscribeToNewAttachment = ({ file, isSubscribed }) =>
    setValue(
      name,
      updateSubscribeNewAttachment({
        file,
        fileList,
        isSubscribed
      })
    );

  return (
    <ModalsProvider>
      <FormItem name={name} {...itemProps} errors={formState.errors}>
        <Controller
          control={control}
          rules={rules}
          render={({ field }) => (
            <AttachmentsControl
              fileList={field.value}
              onChange={field.onChange}
            >
              <Button
                size="large"
                type="secondary"
                style={{ width: '100%' }}
                {...buttonProps}
              >
                <Icon type="paper-clip" />
                {label || t('AttachDocsBtn')}
              </Button>
            </AttachmentsControl>
          )}
        />
      </FormItem>

      <Attachments
        attachmentProps={{
          fileLinkTarget: '_blank',
          onDelete: file => {
            const key = file.id ? 'id' : 'uid';

            setValue(
              name,
              fileList.filter(({ [key]: id }) => id !== file[key])
            );
          },
          onRename: onRenameFile,
          onSubscribeToNewAttachment,
          onSetValidityDateToNewAttachment
        }}
        actionsDeps={actionsDeps}
        fileList={fileList}
        style={{ marginBottom: 16 }}
        subscribeCallback={handleSubscription}
        unsubscribeCallback={handleSubscription}
        changeManageSubscribersCallback={handleAfterChangeManageSubscription}
        changeValidityDateCallback={handleAfterChangeValidityDateCallback}
      />
    </ModalsProvider>
  );
};

FormAttachDocuments.defaultProps = {
  rules: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemProps: PropTypes.object,
  actionsDeps: PropTypes.object
};

FormAttachDocuments.defaultProps = {
  rules: {},
  label: undefined,
  itemProps: {},
  actionsDeps: {}
};

export default FormAttachDocuments;
