import React from 'react';

export const BelcartIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.88757 21.3755C3.88757 22.1443 3.31954 22.6869 2.5254 22.6869C1.77657 22.6869 1.1582 22.1994 1.1582 21.2398V20.7674C1.1582 19.8428 1.60562 19.1708 2.435 19.0152L3.66014 18.8633V19.3558L2.55058 19.4878C2.048 19.5883 1.74133 19.9096 1.71112 20.5779C1.902 20.1959 2.27412 20.0945 2.61603 20.0945C3.34975 20.0943 3.88757 20.6118 3.88757 21.3755ZM3.32458 21.3705C3.32458 20.9082 2.99274 20.5766 2.53044 20.5766C2.06288 20.5766 1.73126 20.9082 1.73126 21.3705C1.73126 21.8428 2.06288 22.1694 2.53044 22.1694C2.99274 22.1694 3.32458 21.8428 3.32458 21.3705Z"
      fill="currentColor"
    />
    <path
      d="M6.87875 21.528C6.88072 21.446 6.87875 21.3672 6.87327 21.2912C6.80936 20.4458 6.27351 19.9805 5.54788 19.9805C4.77278 19.9805 4.2168 20.5682 4.2168 21.3424C4.2168 22.156 4.7973 22.6939 5.58838 22.6939C6.17304 22.6939 6.63796 22.3519 6.79907 21.9129H6.24396C6.11612 22.0862 5.89942 22.2167 5.59319 22.2167C5.16263 22.2167 4.82773 21.935 4.79248 21.528H6.87875ZM4.7927 21.0909C4.82225 20.7194 5.1379 20.448 5.54788 20.448C5.97363 20.448 6.27351 20.7243 6.28861 21.0909H4.7927Z"
      fill="currentColor"
    />
    <path
      d="M7.07144 22.1455C7.2726 22.1354 7.48361 22.0249 7.48361 21.6079V20.0352H9.53069V22.6428H8.96267V20.5476H8.04157V21.6177C8.04157 22.3412 7.61429 22.6678 7.06641 22.6579L7.07144 22.1455Z"
      fill="currentColor"
    />
    <path
      d="M11.0833 21.2513L12.3332 20.0352H11.6715L10.9804 20.7058L10.5915 21.0834V20.0352H10.5827H10.0567H10.0234V22.643H10.0567H10.5827H10.5915V21.4199L10.9743 21.8509L11.6769 22.643H12.3378L11.0833 21.2513Z"
      fill="currentColor"
    />
    <path
      d="M14.6058 20.0308L14.5366 20.4183C14.3127 20.1446 13.9279 19.9805 13.5816 19.9805C13.5774 19.9805 13.5737 19.9809 13.5695 19.9809C13.5634 19.9809 13.5575 19.9805 13.5514 19.9805C13.1749 19.9805 12.8682 20.1182 12.6489 20.3423C12.6381 20.353 12.6272 20.3633 12.6169 20.3743C12.6108 20.381 12.6051 20.3883 12.5992 20.3953C12.388 20.6343 12.2695 20.96 12.2695 21.3321C12.2695 21.3339 12.2698 21.3354 12.2698 21.3372C12.2698 21.3389 12.2695 21.3404 12.2695 21.3422C12.2695 21.7143 12.388 22.04 12.5992 22.2791C12.6051 22.2861 12.6108 22.2933 12.6169 22.3001C12.6272 22.3112 12.6381 22.3213 12.6489 22.332C12.8682 22.5562 13.1749 22.6939 13.5514 22.6939C13.5575 22.6939 13.5634 22.6934 13.5695 22.6934C13.5737 22.6934 13.5774 22.6939 13.5816 22.6939C13.9279 22.6939 14.3127 22.5297 14.5366 22.2561V22.6435H15.1147V22.6385V20.0358V20.0308H14.6058ZM13.6974 22.1711C13.2049 22.1711 12.8529 21.8244 12.8529 21.3372C12.8529 20.8497 13.2049 20.5032 13.6974 20.5032C14.1851 20.5032 14.5318 20.865 14.5318 21.3372C14.5318 21.8093 14.1848 22.1711 13.6974 22.1711Z"
      fill="currentColor"
    />
    <path
      d="M15.6055 20.0308V20.0358V23.8126V23.8176H16.1836V22.2561C16.4075 22.5297 16.7923 22.6939 17.1386 22.6939C17.1427 22.6939 17.1465 22.6934 17.1506 22.6934C17.1568 22.6934 17.1627 22.6939 17.1688 22.6939C17.5453 22.6939 17.852 22.5562 18.0713 22.332C18.082 22.3213 18.093 22.311 18.1032 22.3001C18.1094 22.2933 18.1151 22.2861 18.121 22.2791C18.3322 22.04 18.4506 21.7143 18.4506 21.3422C18.4506 21.3404 18.4504 21.3389 18.4504 21.3372C18.4504 21.3354 18.4506 21.3339 18.4506 21.3321C18.4506 20.96 18.3322 20.6343 18.121 20.3953C18.1151 20.3883 18.1094 20.381 18.1032 20.3743C18.093 20.3631 18.082 20.353 18.0713 20.3423C17.852 20.1182 17.5453 19.9805 17.1688 19.9805C17.1627 19.9805 17.1568 19.9809 17.1506 19.9809C17.1465 19.9809 17.1427 19.9805 17.1386 19.9805C16.7923 19.9805 16.4075 20.1446 16.1836 20.4183L16.1144 20.0308H15.6055ZM16.1886 21.3372C16.1886 20.8648 16.5355 20.5032 17.023 20.5032C17.5155 20.5032 17.8675 20.8499 17.8675 21.3372C17.8675 21.8246 17.5155 22.1711 17.023 22.1711C16.5353 22.1711 16.1886 21.8093 16.1886 21.3372Z"
      fill="currentColor"
    />
    <path
      d="M22.7556 21.0169V22.647H22.1817V21.1629C22.1817 20.6899 21.9401 20.4787 21.6181 20.4787C21.2858 20.4787 21.0343 20.7 21.0343 21.1629V22.647H20.4606V21.1629C20.4606 20.6899 20.2139 20.4787 19.9019 20.4787C19.5649 20.4787 19.3131 20.7 19.3131 21.1629V22.647H18.7344V20.0358H19.2391L19.3046 20.4123C19.4556 20.1457 19.7058 19.9805 20.0933 19.9805C20.4455 19.9805 20.7342 20.1256 20.89 20.4123C21.0612 20.1457 21.3464 19.9805 21.7693 19.9805C22.3579 19.9805 22.7556 20.378 22.7556 21.0169Z"
      fill="currentColor"
    />
    <path
      d="M11.0654 13.3698L8.33408 16.0482C8.15109 16.2277 8.15328 16.4579 8.21676 16.6138C8.28024 16.7694 8.43915 16.9362 8.69548 16.9371L13.5013 16.9524C13.871 16.9535 14.1774 16.6536 14.1842 16.2839C14.1846 16.2642 14.1849 16.2443 14.1849 16.2246C14.1881 15.1717 13.7701 14.1416 13.0324 13.3893C12.4954 12.8414 11.6131 12.8329 11.0654 13.3698Z"
      fill="currentColor"
    />
    <path
      d="M7.92966 11.3343L4.10452 11.2969C3.84819 11.2943 3.68687 11.4589 3.62164 11.6139C3.55641 11.7688 3.55094 11.9991 3.73152 12.181L7.1191 15.59C7.3798 15.8523 7.80839 15.8569 8.07478 15.6001C8.08901 15.5865 8.10324 15.5725 8.11725 15.5587C8.86389 14.8167 9.29686 13.7925 9.30715 12.739C9.31459 11.9718 8.69666 11.3418 7.92966 11.3343Z"
      fill="currentColor"
    />
    <path
      d="M4.23903 10.7955C4.25873 10.796 4.27865 10.7962 4.29835 10.7962C5.35122 10.7995 6.38133 10.3814 7.13366 9.64372C7.68133 9.10677 7.68987 8.22442 7.15292 7.67675L4.47456 4.94541C4.29507 4.76242 4.06479 4.76461 3.90894 4.82809C3.75331 4.89135 3.58651 5.05048 3.58564 5.3068L3.57031 10.1126C3.56944 10.4825 3.86932 10.7887 4.23903 10.7955Z"
      fill="currentColor"
    />
    <path
      d="M4.96537 4.72836C5.70742 5.475 6.73161 5.90797 7.78514 5.91826C8.55214 5.9257 9.18211 5.30798 9.18955 4.54099L9.22698 0.715844C9.22961 0.459522 9.065 0.298198 8.91003 0.232968C8.75505 0.167738 8.52478 0.162266 8.34288 0.342852L4.93407 3.73043C4.67184 3.99113 4.66724 4.41972 4.924 4.68611C4.93757 4.70012 4.95158 4.71435 4.96537 4.72836Z"
      fill="currentColor"
    />
    <path
      d="M12.8482 3.76208L15.5795 1.08372C15.7625 0.904224 15.7603 0.673949 15.6968 0.518098C15.6336 0.362465 15.4745 0.19567 15.2181 0.194794L10.4121 0.17969C10.0424 0.178596 9.73598 0.478478 9.72919 0.848187C9.72875 0.867888 9.72853 0.887807 9.72853 0.907507C9.72525 1.96038 10.1433 2.99049 10.881 3.74282C11.4182 4.29027 12.3005 4.29902 12.8482 3.76208Z"
      fill="currentColor"
    />
    <path
      d="M15.9828 5.79915L19.808 5.83658C20.0643 5.83921 20.2256 5.6746 20.2908 5.51962C20.3561 5.36465 20.3615 5.13437 20.1809 4.95247L16.7934 1.54345C16.5327 1.28121 16.1041 1.27662 15.8377 1.53338C15.8235 1.54695 15.8092 1.56096 15.7954 1.57475C15.0488 2.31679 14.6158 3.34099 14.6055 4.39452C14.5979 5.16152 15.2158 5.79149 15.9828 5.79915Z"
      fill="currentColor"
    />
    <path
      d="M19.6743 6.33661C19.6546 6.33618 19.6347 6.33596 19.615 6.33596C18.5621 6.33267 17.532 6.75076 16.7797 7.48842C16.232 8.02537 16.2235 8.90772 16.7604 9.45539L19.4388 12.1867C19.6183 12.3697 19.8486 12.3675 20.0044 12.3041C20.1601 12.2408 20.3269 12.0817 20.3277 11.8253L20.3431 7.01956C20.3439 6.64963 20.044 6.34318 19.6743 6.33661Z"
      fill="currentColor"
    />
    <path
      d="M18.9472 12.4048C18.2051 11.6582 17.1809 11.2252 16.1274 11.2149C15.3604 11.2075 14.7304 11.8252 14.723 12.5922L14.6856 16.4173C14.683 16.6736 14.8476 16.835 15.0025 16.9002C15.1575 16.9654 15.3878 16.9709 15.5697 16.7903L18.9787 13.4027C19.2409 13.142 19.2455 12.7134 18.9888 12.4471C18.975 12.433 18.961 12.4188 18.9472 12.4048Z"
      fill="currentColor"
    />
  </svg>
);

export default BelcartIcon;
