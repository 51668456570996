import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SUPERIOR } from 'constants/index';

import CreateTeamForm from 'components/auth-view/register-steps-view/forms/create-team';

import { createDepartmentWithInvite } from 'store/team/departments';

import useModalsService from 'services/modals';
import { showNoticeMessage } from 'services/notice';

export const InvitationStepForm = () => {
  const dispatch = useDispatch();
  const modals = useModalsService();
  const { state } = useLocation();
  const allowInviteOwner = state.position === SUPERIOR;

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Toast');

  const createDepartment = department => {
    setIsLoading(true);

    return dispatch(createDepartmentWithInvite(department)).finally(() => {
      setIsLoading(false);
      modals.close();

      showNoticeMessage({ customContent: t('TeamCreated') });
    });
  };

  return (
    <CreateTeamForm
      isLoading={isLoading}
      isCreateWorkspace={false}
      allowInviteOwner={allowInviteOwner}
      onSubmit={createDepartment}
      onSkip={() => modals.close()}
    />
  );
};

export default InvitationStepForm;
