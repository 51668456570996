import { GPT_FILTER_BY_ENTITY_OPTIONS } from 'constants/index';

import handleActions from 'utils/redux-actions';

import { clearGptFilter, setFilteredDialogsByEntity } from './actions';

const initialFilter = {
  entityType: GPT_FILTER_BY_ENTITY_OPTIONS[0]
};

const initialState = {
  filters: initialFilter
};

export default handleActions(
  {
    [setFilteredDialogsByEntity]: (state, { payload }) => {
      state.filters.entityType = payload;

      return state;
    },

    [clearGptFilter]: state => {
      state.filters = initialFilter;

      return state;
    }
  },
  initialState
);
