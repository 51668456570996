import qs from 'qs';

import makeCancelableRequest from 'utils/make-cancalable-request';

export default api => ({
  fetch: makeCancelableRequest(api, (fetch, {
    workspaceId,
    dateRange = [],
    price: { gte, lte },
    isIndividual,
    isRated,
    useSubscription,
    ...params
  }) =>
    fetch('get', `/v1/${workspaceId}/orders/`, {
        params: {
          ...params,
          price_gte: gte,
          price_lte: lte,
          date_end_gte: dateRange[0],
          date_end_lte: dateRange[1],
          is_individual: isIndividual,
          is_rated: isRated,
          use_subscription: useSubscription
        },
        paramsSerializer(paramsSer) {
          return qs.stringify(paramsSer, { arrayFormat: 'comma' });
        }
      })
      .then(({ data }) => data)),

  fetchOne: ({ workspaceId, orderId }) =>
    api.get(`/v1/${workspaceId}/orders/${orderId}/`).then(({ data }) => data),

  sendResponse: ({ workspaceId, orderId, response }) =>
    api
      .post(`/v1/${workspaceId}/orders/${orderId}/response/`, response)
      .then(({ data }) => data)
});
