import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const MessageDate = ({ date, dateFormat }) => {
  const { t } = useTranslation('Common');

  const datelabel =
    moment(date, dateFormat).format(dateFormat) === moment().format(dateFormat)
      ? t('Today')
      : moment(date, dateFormat).format(dateFormat);

  return <span data-qa="qa-f3qmggduzq2gndl">{datelabel}</span>;
};

MessageDate.propTypes = {
  date: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired
};

export default MessageDate;
