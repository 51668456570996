import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import { EmptyMessage } from 'components/common/icons';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import styles from './tabs.module.scss';

const { Paragraph } = Typography;

export const PermissionsError = () => {
  const { t } = useTranslation('Task');

  return (
    <Empty
      className={styles.permissionsError}
      image={<Icon className={styles.icon} component={EmptyMessage} />}
      imageStyle={{ height: 'auto' }}
      description={
        <Paragraph className={styles.text}>
          {t('CommentaryDisplayError')}
        </Paragraph>
      }
    />
  );
};

export default PermissionsError;
