import React from 'react';

export const SubtractIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0996 24.5C18.727 24.5 24.0996 19.1274 24.0996 12.5C24.0996 5.87258 18.727 0.5 12.0996 0.5C5.47219 0.5 0.0996094 5.87258 0.0996094 12.5C0.0996094 19.1274 5.47219 24.5 12.0996 24.5ZM20.8389 8.27736L11.1081 19.4643L9.81521 20.9507L8.42436 19.5556L3.45326 14.5696L6.05195 11.9787L9.6322 15.5697L18.0702 5.86903L20.8389 8.27736Z"
      fill="currentColor"
    />
  </svg>
);

export default SubtractIcon;
