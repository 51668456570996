import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tooltip } from 'antd';

import { GPT_CHATS_VIEW, GPT_MESSAGES_VIEW } from 'constants/index';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { useModalsService } from 'services/modals';
import useRoutesService from 'services/routes';

import renderEntityLink from '../../utils';

import styles from './gpt-header.module.scss';

const { Text } = Typography;

const GptHeader = ({
  toggleCollapse,
  closeWidget,
  classNameDnd,
  view,
  setView,
  entityInfo
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const { t } = useTranslation('AIAssistant');

  return (
    <div className={styles.root}>
      <div className={classnames(classNameDnd, styles.gptDndHeader)}>
        <div className={styles.leftSide}>
          {view === GPT_MESSAGES_VIEW && (
            <>
              <div className={styles.pinkCircle}>
                <Icon type="gpt-widget" color="black" size={16} />
              </div>

              <div className={styles.infoWrap}>
                <Text color="black" weight="semibold">
                  {t('AIAsssistHeading')}
                </Text>

                {renderEntityLink({ routes, modals, entityInfo })}
              </div>
            </>
          )}

          {view === GPT_CHATS_VIEW && (
            <>
              <Button
                type="text"
                className={styles.button}
                onClick={() => setView(GPT_MESSAGES_VIEW)}
              >
                <Icon type="left" color="black-55" />
              </Button>

              <Text color="black" weight="semibold">
                {t('DialogsHistoryHeading')}
              </Text>
            </>
          )}
        </div>
      </div>

      <div className={styles.rightSide}>
        {view === GPT_MESSAGES_VIEW && (
          <Tooltip title={t('DialogsHistoryTip')} mouseEnterDelay={0.5}>
            <Button
              type="text"
              className={styles.button}
              onClick={() => setView(GPT_CHATS_VIEW)}
            >
              <Icon type="history" color="black-55" />
            </Button>
          </Tooltip>
        )}

        <Button type="text" onClick={toggleCollapse} className={styles.button}>
          <Icon type="minus" color="black-55" />
        </Button>

        <Button type="text" className={styles.button} onClick={closeWidget}>
          <Icon type="close" color="black-55" />
        </Button>
      </div>
    </div>
  );
};

GptHeader.propTypes = {
  toggleCollapse: PropTypes.func.isRequired,
  closeWidget: PropTypes.func.isRequired,
  classNameDnd: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  entityInfo: PropTypes.object.isRequired
};

export default GptHeader;
