import React from 'react';

export const TimerAndCalendarIcon = ({
  style = { width: 150, height: 150 }
}) => (
  <svg
    style={style}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M137.587 77.91C137.486 77.5814 136.329 74.7528 133.887 68.9385C133.887 68.9385 132.129 64.7671 130.201 61.2385L129.872 60.6671C129.486 59.9671 129.101 59.2385 128.686 58.6814C128.272 58.1242 127.815 57.2528 127.401 56.6814C127.244 56.5528 127.201 56.3385 127.044 56.1957C126.429 55.2528 125.801 54.3957 125.101 53.4385C124.901 53.0814 124.587 52.8099 124.401 52.4671C123.521 51.2477 122.567 50.0836 121.544 48.9814C121.544 48.8528 121.401 48.8385 121.415 48.7528C121.429 48.6671 121.201 48.4957 121.044 48.3528C120.315 47.4671 119.501 46.5671 118.786 45.6957V45.5814C118.786 45.4671 118.629 45.4385 118.658 45.3385C118.686 45.2385 118.429 45.0957 118.272 44.9528C117.472 44.0528 116.558 43.1242 115.715 42.3099C111.669 38.294 107.302 34.6151 102.658 31.3099C96.6643 27.0944 90.1842 23.6169 83.3579 20.9528L83.0008 20.8528C82.1228 20.4831 81.2264 20.1589 80.3151 19.8814H79.9865H79.9008C74.7342 17.9691 69.3582 16.679 63.8865 16.0385C62.5865 15.8814 61.2436 15.7957 59.8294 15.6957C58.5656 15.5908 57.2969 15.5574 56.0294 15.5957C54.7676 15.6341 53.5087 15.7391 52.2579 15.9099C35.8294 18.4528 29.1294 33.1385 22.3008 48.4528C21.0008 51.3814 19.6865 54.4099 18.2865 57.3099C15.5545 63.5174 11.9305 69.2927 7.52935 74.4528C0.315067 85.4814 -1.49922 96.5099 2.12935 105.467C2.86984 106.47 3.41283 107.604 3.72935 108.81C7.74364 115.624 15.1579 120.981 25.9294 123.767C34.5008 126.039 63.0722 135.681 89.7294 134.281C89.7294 134.281 93.6579 134.11 98.9008 133.324C117.901 130.467 124.244 123.981 125.829 121.424C126.267 120.692 126.617 119.91 126.872 119.096C127.386 116.139 125.158 113.681 126.372 106.167C128.886 91.5671 139.986 87.0957 137.587 77.91Z"
      fill="#EBF8F8"
    />
    <path
      d="M118.887 46.0957V101.01C118.883 103.936 117.718 106.741 115.647 108.809C113.577 110.877 110.77 112.039 107.844 112.039H25.2151C22.2901 112.039 19.485 110.877 17.4167 108.808C15.3485 106.74 14.1865 103.935 14.1865 101.01V46.0957C14.1865 43.1708 15.3485 40.3656 17.4167 38.2973C19.485 36.2291 22.2901 35.0671 25.2151 35.0671H107.844C110.77 35.0671 113.577 36.2286 115.647 38.2965C117.718 40.3643 118.883 43.1694 118.887 46.0957Z"
      fill="white"
    />
    <path
      d="M118.944 49.6957H14.1865V55.8528H118.944V49.6957Z"
      fill="#E2E2E2"
      fillOpacity="0.5"
    />
    <path
      d="M118.887 46.0957V52.0672H14.1865V46.0957C14.1865 43.1708 15.3485 40.3656 17.4167 38.2973C19.485 36.2291 22.2901 35.0671 25.2151 35.0671H25.7865V30.8814C25.7865 30.1237 26.0875 29.3969 26.6234 28.8611C27.1592 28.3253 27.8859 28.0243 28.6437 28.0243C29.4014 28.0243 30.1282 28.3253 30.664 28.8611C31.1998 29.3969 31.5008 30.1237 31.5008 30.8814V35.0671H44.7437V30.8814C44.7437 30.1237 45.0447 29.3969 45.5805 28.8611C46.1163 28.3253 46.843 28.0243 47.6008 28.0243C48.3586 28.0243 49.0853 28.3253 49.6211 28.8611C50.1569 29.3969 50.458 30.1237 50.458 30.8814V35.0671H63.758V30.8814C63.758 30.1237 64.059 29.3969 64.5948 28.8611C65.1306 28.3253 65.8573 28.0243 66.6151 28.0243C67.3729 28.0243 68.0996 28.3253 68.6354 28.8611C69.1712 29.3969 69.4722 30.1237 69.4722 30.8814V35.0671H82.7865V30.8814C82.7865 30.1237 83.0875 29.3969 83.6234 28.8611C84.1592 28.3253 84.8859 28.0243 85.6437 28.0243C86.4014 28.0243 87.1282 28.3253 87.664 28.8611C88.1998 29.3969 88.5008 30.1237 88.5008 30.8814V35.0671H101.801V30.8814C101.801 30.1237 102.102 29.3969 102.638 28.8611C103.173 28.3253 103.9 28.0243 104.658 28.0243C105.416 28.0243 106.142 28.3253 106.678 28.8611C107.214 29.3969 107.515 30.1237 107.515 30.8814V35.0671H108.072C110.959 35.1269 113.707 36.3147 115.728 38.3761C117.75 40.4376 118.883 43.2086 118.887 46.0957Z"
      fill="#C4C4C4"
    />
    <path
      d="M43.5722 63.6957H25.8294C24.4013 63.6957 23.2437 64.8533 23.2437 66.2814V74.8528C23.2437 76.2809 24.4013 77.4385 25.8294 77.4385H43.5722C45.0003 77.4385 46.1579 76.2809 46.1579 74.8528V66.2814C46.1579 64.8533 45.0003 63.6957 43.5722 63.6957Z"
      fill="#E2E2E2"
    />
    <path
      d="M75.4151 63.6957H57.6723C56.2442 63.6957 55.0865 64.8533 55.0865 66.2814V74.8528C55.0865 76.2809 56.2442 77.4385 57.6723 77.4385H75.4151C76.8432 77.4385 78.0008 76.2809 78.0008 74.8528V66.2814C78.0008 64.8533 76.8432 63.6957 75.4151 63.6957Z"
      fill="#E2E2E2"
    />
    <path
      d="M107.244 63.6957H89.5008C88.0727 63.6957 86.915 64.8533 86.915 66.2814V74.8528C86.915 76.2809 88.0727 77.4385 89.5008 77.4385H107.244C108.672 77.4385 109.829 76.2809 109.829 74.8528V66.2814C109.829 64.8533 108.672 63.6957 107.244 63.6957Z"
      fill="#E2E2E2"
    />
    <path
      d="M43.5722 87.2385H25.8294C24.4013 87.2385 23.2437 88.3962 23.2437 89.8242V98.3957C23.2437 99.8237 24.4013 100.981 25.8294 100.981H43.5722C45.0003 100.981 46.1579 99.8237 46.1579 98.3957V89.8242C46.1579 88.3962 45.0003 87.2385 43.5722 87.2385Z"
      fill="#E2E2E2"
    />
    <path
      d="M75.4151 87.2385H57.6723C56.2442 87.2385 55.0865 88.3962 55.0865 89.8242V98.3957C55.0865 99.8237 56.2442 100.981 57.6723 100.981H75.4151C76.8432 100.981 78.0008 99.8237 78.0008 98.3957V89.8242C78.0008 88.3962 76.8432 87.2385 75.4151 87.2385Z"
      fill="#E2E2E2"
    />
    <path
      d="M107.244 87.2385H89.5008C88.0727 87.2385 86.915 88.3962 86.915 89.8242V98.3957C86.915 99.8237 88.0727 100.981 89.5008 100.981H107.244C108.672 100.981 109.829 99.8237 109.829 98.3957V89.8242C109.829 88.3962 108.672 87.2385 107.244 87.2385Z"
      fill="#E2E2E2"
    />
    <path d="M121.058 52.467H113.915V66.5528H121.058V52.467Z" fill="#FFE181" />
    <path d="M117.672 52.467H113.986V66.5528H117.672V52.467Z" fill="#FDA402" />
    <path
      d="M149.687 90.3957C149.638 98.8994 146.242 107.042 140.234 113.06C134.227 119.079 126.09 122.489 117.587 122.553V58.2671C126.086 58.327 134.221 61.7321 140.229 67.7453C146.236 73.7584 149.634 81.8957 149.687 90.3957Z"
      fill="#16969C"
    />
    <path
      d="M117.644 58.2671V122.553H117.215C112.493 122.562 107.83 121.507 103.572 119.467C98.1143 116.821 93.5118 112.692 90.2915 107.551C87.0712 102.411 85.3633 96.4684 85.3633 90.4028C85.3633 84.3373 87.0712 78.3943 90.2915 73.2542C93.5118 68.1141 98.1143 63.9847 103.572 61.3385C107.832 59.3033 112.494 58.2489 117.215 58.2528L117.644 58.2671Z"
      fill="#FDA402"
    />
    <path
      d="M145.172 90.41C145.172 90.5528 145.172 90.6957 145.172 90.8385C145.172 90.9814 145.172 91.4814 145.172 91.7957C144.835 98.6514 141.955 105.135 137.095 109.982C132.235 114.829 125.743 117.691 118.886 118.01C118.515 118.01 118.158 118.01 117.786 118.01H117.458H116.358C109.405 117.723 102.816 114.823 97.9084 109.889C93.0009 104.955 90.136 98.3503 89.8865 91.3957C89.8865 91.0671 89.8865 90.7385 89.8865 90.41C89.8865 90.0814 89.8865 89.4242 89.8865 88.9814C90.2329 82.0968 93.1377 75.5897 98.0318 70.7352C102.926 65.8808 109.456 63.0289 116.344 62.7385H117.458H118.886C125.784 63.0714 132.307 65.9721 137.173 70.8706C142.04 75.7691 144.898 82.3109 145.186 89.21C145.158 89.6528 145.172 90.0242 145.172 90.41Z"
      fill="white"
    />
    <path
      d="M145.172 90.41V90.8385H117.458V62.7385H118.886C125.784 63.0714 132.307 65.9721 137.173 70.8706C142.04 75.7691 144.898 82.3109 145.186 89.21C145.158 89.6528 145.172 90.0242 145.172 90.41Z"
      fill="#16969C"
    />
    <path
      d="M126.029 54.5814H108.887C108.563 54.5776 108.254 54.4465 108.027 54.2165C107.8 53.9865 107.672 53.6762 107.672 53.3528V50.2814C107.672 49.9593 107.8 49.6505 108.028 49.4228C108.256 49.195 108.564 49.0671 108.887 49.0671H126.029C126.19 49.0652 126.349 49.0952 126.498 49.1554C126.647 49.2156 126.783 49.3047 126.897 49.4177C127.012 49.5306 127.102 49.6651 127.164 49.8134C127.226 49.9616 127.258 50.1207 127.258 50.2814V53.3528C127.258 53.6787 127.129 53.9911 126.898 54.2215C126.668 54.452 126.355 54.5814 126.029 54.5814Z"
      fill="#FDA402"
    />
    <path
      d="M117.715 90.8385V117.981H117.458H116.358C109.409 117.695 102.825 114.799 97.9178 109.87C93.011 104.942 90.1431 98.3452 89.8865 91.3957C89.8865 91.0671 89.8865 90.7385 89.8865 90.4099C89.8865 90.0814 89.8865 89.4242 89.8865 88.9814C90.2329 82.0968 93.1377 75.5897 98.0318 70.7352C102.926 65.8808 109.456 63.0289 116.344 62.7385H117.458V90.8385H117.715Z"
      fill="#FFE181"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.886 62.7385L118.315 66.3385C118.304 66.5116 118.227 66.6736 118.099 66.7908C117.971 66.908 117.803 66.9711 117.629 66.9671C117.473 66.9509 117.328 66.8806 117.218 66.7684C117.109 66.6562 117.042 66.5092 117.029 66.3528L116.886 65.4528L116.315 62.7385H117.458H118.886Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.886 118.081C118.515 118.081 118.158 118.081 117.786 118.081H117.458H116.358L116.886 114.781C116.897 114.608 116.975 114.446 117.103 114.329C117.231 114.212 117.399 114.149 117.572 114.153H117.658C117.811 114.172 117.953 114.244 118.06 114.356C118.167 114.468 118.232 114.613 118.244 114.767L118.386 115.667L118.886 118.081Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.301 90.4528V90.8814C145.301 91.1957 145.301 91.5242 145.301 91.8385L141.601 91.3099C141.486 91.3012 141.375 91.2622 141.279 91.197C141.184 91.1318 141.108 91.0427 141.058 90.9385C141.002 90.8434 140.972 90.7348 140.972 90.6242C140.964 90.5341 140.974 90.4433 141.002 90.3572C141.029 90.271 141.073 90.191 141.132 90.122C141.19 90.0529 141.262 89.9961 141.342 89.9548C141.423 89.9135 141.511 89.8886 141.601 89.8814L142.501 89.7385L145.272 89.2957C145.286 89.6957 145.301 90.0671 145.301 90.4528Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.9436 90.167C93.9407 90.337 93.8748 90.4997 93.7586 90.6238C93.6425 90.7478 93.4844 90.8243 93.3151 90.8385L92.4151 90.9813L89.7722 91.3956C89.7722 91.067 89.7722 90.7385 89.7722 90.4099C89.7722 90.0813 89.7722 89.4242 89.7722 88.9813L93.3008 89.5385C93.4667 89.5452 93.6242 89.6132 93.743 89.7293C93.8617 89.8454 93.9332 90.0013 93.9436 90.167Z"
      fill="#2B2A36"
    />
    <path
      d="M37.4762 66.1242C37.7104 65.899 38.0227 65.7732 38.3477 65.7732C38.6726 65.7732 38.9849 65.899 39.2191 66.1242C39.4477 66.3567 39.5758 66.6696 39.5758 66.9956C39.5758 67.3216 39.4477 67.6346 39.2191 67.8671L36.362 70.9528L39.2191 74.0385C39.4477 74.2709 39.5758 74.5839 39.5758 74.9099C39.5758 75.2359 39.4477 75.5489 39.2191 75.7813C38.9849 76.0066 38.6726 76.1324 38.3477 76.1324C38.0227 76.1324 37.7104 76.0066 37.4762 75.7813L34.6905 72.7385L31.9762 75.6671C31.8672 75.7908 31.7344 75.8913 31.5858 75.9626C31.4371 76.034 31.2756 76.0746 31.1109 76.0822C30.9462 76.0898 30.7816 76.0641 30.627 76.0068C30.4724 75.9494 30.3309 75.8615 30.211 75.7483C30.0911 75.6351 29.9952 75.499 29.9291 75.3479C29.8629 75.1969 29.8278 75.0341 29.8259 74.8692C29.824 74.7043 29.8553 74.5407 29.9179 74.3882C29.9806 74.2357 30.0733 74.0973 30.1905 73.9813L33.0477 70.8956L30.1905 67.8099C30.0733 67.694 29.9806 67.5556 29.9179 67.4031C29.8553 67.2506 29.824 67.087 29.8259 66.9221C29.8278 66.7572 29.8629 66.5944 29.9291 66.4434C29.9952 66.2923 30.0911 66.1561 30.211 66.0429C30.3309 65.9298 30.4724 65.8419 30.627 65.7845C30.7816 65.7271 30.9462 65.7015 31.1109 65.7091C31.2756 65.7166 31.4371 65.7573 31.5858 65.8286C31.7344 65.9 31.8672 66.0005 31.9762 66.1242L34.762 69.0956L37.4762 66.1242Z"
      fill="#FDA402"
    />
    <path
      d="M65.3116 99.4748L59.4973 93.6748C59.3634 93.542 59.2571 93.384 59.1846 93.2099C59.112 93.0358 59.0747 92.8491 59.0747 92.6605C59.0747 92.4719 59.112 92.2852 59.1846 92.1111C59.2571 91.937 59.3634 91.7791 59.4973 91.6462C59.7649 91.3802 60.127 91.2308 60.5044 91.2308C60.8818 91.2308 61.2439 91.3802 61.5116 91.6462L65.3116 95.432L71.583 89.1605C71.8506 88.8945 72.2127 88.7451 72.5901 88.7451C72.9675 88.7451 73.3296 88.8945 73.5973 89.1605C73.8633 89.4282 74.0127 89.7903 74.0127 90.1677C74.0127 90.5451 73.8633 90.9072 73.5973 91.1748L65.3116 99.4748Z"
      fill="#2B2A36"
    />
    <path
      d="M117.687 95.0957C118.775 95.0985 119.84 94.7783 120.746 94.1757C121.652 93.5731 122.36 92.7151 122.778 91.7104C123.197 90.7056 123.307 89.5993 123.097 88.5315C122.886 87.4636 122.364 86.4823 121.595 85.7117C120.826 84.9411 119.846 84.4158 118.779 84.2024C117.712 83.9891 116.605 84.0971 115.599 84.513C114.594 84.9289 113.734 85.6338 113.129 86.5386C112.524 87.4433 112.201 88.5073 112.201 89.5957C112.199 90.3173 112.339 91.0322 112.614 91.6994C112.889 92.3666 113.293 92.973 113.802 93.4839C114.312 93.9948 114.917 94.4002 115.584 94.6767C116.25 94.9533 116.965 95.0957 117.687 95.0957Z"
      fill="#FDA402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.572 89.5957H114.915L117.015 76.3671C117.015 76.1777 117.09 75.996 117.224 75.862C117.358 75.7281 117.54 75.6528 117.729 75.6528C117.919 75.6528 118.1 75.7281 118.234 75.862C118.368 75.996 118.444 76.1777 118.444 76.3671L118.586 77.2671L120.458 89.5957H116.572Z"
      fill="#2B2A36"
    />
    <path
      d="M117.687 93.0671C118.374 93.0699 119.046 92.8687 119.619 92.489C120.192 92.1093 120.639 91.5681 120.904 90.934C121.169 90.3 121.24 89.6015 121.107 88.9272C120.975 88.2529 120.645 87.633 120.161 87.1461C119.676 86.6592 119.057 86.3271 118.383 86.192C117.71 86.0569 117.011 86.1248 116.376 86.3871C115.741 86.6495 115.197 87.0944 114.815 87.6656C114.433 88.2367 114.229 88.9085 114.229 89.5957C114.229 90.5139 114.593 91.3947 115.241 92.0453C115.889 92.6959 116.768 93.0633 117.687 93.0671Z"
      fill="#FFE181"
    />
  </svg>
);

export default TimerAndCalendarIcon;
