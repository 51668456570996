import {
  TYPE_ORDER_STATUS,
  TYPE_CONTACT,
  TYPE_ASSET,
  ONLINE_CHAT
} from 'constants/index';

import getFIO, { getFullName } from 'utils/get-fio';

const getIcon = (data, type) => {
  if (type === TYPE_CONTACT) {
    return {
      url: (data.avatarFile || {}).url,
      title: getFIO(data)
    };
  }

  return data.icon;
};

const getTitle = (data, type) => {
  if (type === TYPE_CONTACT) {
    return getFullName(data);
  }

  return data.title;
};

const getContactId = (data, type) => {
  if (type === TYPE_CONTACT) {
    return data.id;
  }

  return data.contractorId;
};

export const transformValueToRelation = (val, type) =>
  val.map(v => {
    if (v.label.relationId) {
      return v.label;
    }

    return {
      relationId: type === TYPE_ORDER_STATUS ? v.value : v.label.id,
      relationType: type,
      title: getTitle(v.label, type),
      icon: getIcon(v.label, type),
      orderId: type === TYPE_ORDER_STATUS ? v.label.orderId : null,
      employee: v.label.employee,
      firstName: v.label.firstName,
      lastName: v.label.lastName,
      onlineChat:
        type === TYPE_CONTACT && v.label.chats
          ? v.label.chats.find(c => c.channelKind === ONLINE_CHAT)
          : null,
      contactId: getContactId(v.label, type),
      inventoryNumber: type === TYPE_ASSET ? v.label.inventoryNumber : null,
      fullPath: type === TYPE_ASSET ? v.label.fullPath : null
    };
  });

export const transformRelationsToSelectValue = relations =>
  relations.map(r => ({
    ...r,
    relationId: r.type === TYPE_ORDER_STATUS ? r.objectId : r.relation.id,
    relationType: r.type,
    title: r.relation.name,
    icon:
      r.type === TYPE_CONTACT
        ? {
            url: (r.relation.avatarFile || {}).url,
            title: getFIO({ firstName: r.relation.name })
          }
        : r.icon,
    orderId: r.type === TYPE_ORDER_STATUS ? r.relation.id : null
  }));
