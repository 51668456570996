import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { uniqBy } from 'lodash';

import {
  ITEMS_PER_PAGE,
  CONTAINS_ANY_OPTION,
  CONTACTS,
  CHATS
} from 'constants/index';

import { makeSubscribeLocationChange } from 'store/router';
import { setActiveId } from 'store/workspace';
import { notifyChannel, editChannel } from 'store/channels/actions';
import { combineActionsThunk } from 'store/actions-thunk';

import { handleActions } from 'utils/redux-actions';

import {
  fetchDialogues,
  fetchDialog,
  joinChatRooms,
  getChatMessages,
  getChatMessagesLoading,
  editDialog,
  editDialogSync,
  setFilterSearch,
  setFilterStatus,
  setFilterChannel,
  setFilterResponsible,
  setFilterCreatedAtGte,
  setFilterCreatedAtLte,
  archiveDialog,
  restoreDialog,
  setFilterKind,
  setFilterTag
} from './actions';

export const initialFilter = {
  search: '',
  status: [],
  kind: [],
  channel: [],
  responsible: [],
  createdAtGte: null,
  createdAtLte: null,
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  }
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  isLoadingDialog: false,

  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE * 2,

  error: undefined,

  filter: initialFilter,

  entries: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(
      CONTACTS,
      CHATS,
      null,
      initialFilter
    ),

    [setActiveId]: () => initialState,

    [combineActions(
      setFilterSearch,
      setFilterStatus,
      setFilterKind,
      setFilterChannel,
      setFilterResponsible,
      setFilterCreatedAtGte,
      setFilterCreatedAtLte,
      setFilterTag
    )]: state => {
      state.entries = [];
      state.isFiltered = true;
      state.isLoaded = false;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterStatus]: (state, { payload }) => {
      state.filter.status = payload;

      return state;
    },

    [setFilterKind]: (state, { payload }) => {
      state.filter.kind = payload;

      return state;
    },

    [setFilterChannel]: (state, { payload }) => {
      state.filter.channel = payload;

      return state;
    },

    [setFilterResponsible]: (state, { payload }) => {
      state.filter.responsible = payload;

      return state;
    },

    [setFilterCreatedAtGte]: (state, { payload }) => {
      state.filter.createdAtGte = payload;

      return state;
    },

    [setFilterCreatedAtLte]: (state, { payload }) => {
      state.filter.createdAtLte = payload;

      return state;
    },

    [setFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [fetchDialogues.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = undefined;

      return state;
    },

    [fetchDialogues.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchDialogues.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchDialogues.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;
      state.totalItems = payload.count;
      state.entries = [...state.entries, ...payload.results];

      return state;
    },

    [combineActionsThunk(fetchDialog.START)]: state => {
      state.isLoadingDialog = true;

      return state;
    },

    [combineActionsThunk(fetchDialog.ENDED)]: state => {
      state.isLoadingDialog = false;

      return state;
    },

    [combineActionsThunk(fetchDialog.SUCCEEDED)]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.uuid === payload.uuid);

      if (index !== -1) {
        const dialod = state.entries[index];
        state.entries[index] = { ...dialod, ...payload, isRaised: false };
      } else {
        const dialogues = state.entries || [];
        state.entries = [payload, ...dialogues];
      }

      return state;
    },

    [editDialog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      if (index !== -1) {
        Object.keys(payload).forEach(key => {
          state.entries[index][key] = payload[key];
        });
      }

      return state;
    },

    [editDialogSync]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.uuid === payload.uuid);

      if (index !== -1) {
        Object.keys(payload).forEach(key => {
          state.entries[index][key] = payload[key];
        });
      }

      return state;
    },

    [combineActionsThunk(archiveDialog.SUCCEEDED, restoreDialog.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      if (index !== -1) {
        const dialog = state.entries[index];

        state.entries[index] = { ...dialog, ...payload };
      }

      return state;
    },

    [editChannel.SUCCEEDED]: (state, { payload }) => {
      state.entries.forEach(({ channel }, index) => {
        if (channel.id === payload.id) {
          state.entries[index].channel.name = payload.name;
        }
      });

      return state;
    },

    [notifyChannel.SUCCEEDED]: (state, { payload }) => {
      state.entries.forEach(({ channel }, index) => {
        if (channel.id === payload.id) {
          state.entries[index].channel.notify = payload.value;
        }
      });

      return state;
    },

    [joinChatRooms]: (state, { payload }) => {
      state.entries.map(dialog => {
        const data = payload.find(p => p.roomUuid === dialog.uuid);

        if (data) {
          delete dialog.isNew;
          dialog.messages = data.message ? [data.message] : [];
          dialog.messageTotalCount = data.count;
          dialog.isOnline = data.isOnline;
          dialog.operatorMessageRecentUuid = data.operatorMessageRecentUuid;
          dialog.clientMessageRecentUuid = data.clientMessageRecentUuid;
          dialog.messageNewCount = data.messageNewCount;
        }

        return dialog;
      });

      return state;
    },

    [getChatMessagesLoading]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.uuid === payload);

      state.entries[index].isLoadingMessages = true;

      return state;
    },

    [getChatMessages]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.uuid === payload.roomUuid);

      if (index !== -1) {
        const messages = state.entries[index].messages || [];

        state.entries[index].messages = uniqBy(
          [...payload.messages.reverse(), ...messages],
          'uuid'
        );
        state.entries[index].messageTotalCount = payload.count;
        state.entries[index].isLoadingMessages = false;
        state.entries[index].messageNewCount = payload.messageNewCount;
      }

      return state;
    }
  },
  initialState
);
