import React from 'react';

export const TimerIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.8 6.00001C59.6 5.70001 70.3 9.30001 78.7 16.1C86.8 22.7 90.2 32.8 94 42.6C98 53 104.3 63.6 101.3 74.4C98.2 85.2 89.1 94.5 78.3 98C68.4 101.2 59 92.8 48.8 91.3C40.8 90.1 32.2 93.9 25 90C17.5 85.9 13.6 77.7 9.89996 69.9C5.69996 61 0.49996 51.8 2.39996 42.2C4.29996 32 11.8 23.9 19.9 17.5C28.3 11 38.2 6.30001 48.8 6.00001Z"
      fill="#EBF8F8"
    />
    <path d="M55 18.2H48.7V32.7H55V18.2Z" fill="#FCC977" />
    <path d="M52.4 18.2H48.6V32.7H52.4V18.2Z" fill="#FDA402" />
    <path
      d="M30.5 24.5L25.7 28.5L35 39.6L39.7999 35.6L30.5 24.5Z"
      fill="#FDA402"
    />
    <path
      d="M29.5 28.6L32.1 26.4L30.5 24.5L25.7 28.5L35 39.6L37.2 37.7L29.5 28.6Z"
      fill="#FDA402"
    />
    <path
      d="M64.1 35.6L68.9 39.6L78.2 28.5L73.4 24.5L64.1 35.6Z"
      fill="#FCC977"
    />
    <path
      d="M70.0999 25.1L78.0999 31.8C78.4999 32.2 79.1999 32.1 79.5999 31.7L82.2999 28.5C82.6999 28.1 82.5999 27.4 82.1999 27L74.1999 20.3C73.7999 19.9 73.0999 20 72.6999 20.4L69.9999 23.6C69.5999 24 69.6999 24.7 70.0999 25.1Z"
      fill="#FCC977"
    />
    <path
      d="M84.8 57.2C84.8 75.3 70.2 90 52.2 90.2V24.2C70.3 24.4 84.8 39.1 84.8 57.2Z"
      fill="#FCC977"
    />
    <path
      d="M52.4 24.2V90.2C52.2 90.2 52.1 90.2 51.9 90.2C46.8 90.2 42 89.1 37.7 87C26.5 81.7 18.7 70.3 18.7 57.2C18.7 44 26.4 32.7 37.7 27.4C42 25.4 46.8 24.2 51.9 24.2C52.1 24.2 52.3 24.2 52.4 24.2Z"
      fill="#FDA402"
    />
    <path
      d="M33.6999 25.1L25.6999 31.8C25.1999 32.2 24.5999 32.1 24.1999 31.7L21.4999 28.5C21.0999 28.1 21.1999 27.4 21.5999 27L29.5999 20.3C29.9999 19.9 30.6999 20 31.0999 20.4L33.7999 23.6C34.1999 24 34.1999 24.7 33.6999 25.1Z"
      fill="#FDA402"
    />
    <path
      d="M51.8 85.6C67.5 85.6 80.2 72.9 80.2 57.2C80.2 41.5 67.5 28.8 51.8 28.8C36.1 28.8 23.4 41.5 23.4 57.2C23.4 72.8 36.1 85.6 51.8 85.6Z"
      fill="white"
    />
    <path
      d="M80.1999 57.6C80.1999 57.5 80.1999 57.3 80.1999 57.2C80.1999 41.5 67.4999 28.8 51.7999 28.8V57.6H80.1999Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M51.7999 40.6C50.6999 40.6 49.7999 39.7 49.7999 38.6V33.3C49.7999 32.2 50.6999 31.3 51.7999 31.3C52.8999 31.3 53.7999 32.2 53.7999 33.3V38.6C53.7999 39.7 52.8999 40.6 51.7999 40.6Z"
      fill="#FFE181"
    />
    <path
      d="M51.7999 82.9C50.6999 82.9 49.7999 82 49.7999 80.9V75.6C49.7999 74.5 50.6999 73.6 51.7999 73.6C52.8999 73.6 53.7999 74.5 53.7999 75.6V80.9C53.7999 82 52.8999 82.9 51.7999 82.9Z"
      fill="#FFE181"
    />
    <path
      d="M68.3 57.1C68.3 56 69.2 55.1 70.3 55.1H75.6C76.7 55.1 77.6 56 77.6 57.1C77.6 58.2 76.7 59.1 75.6 59.1H70.3C69.2 59.1 68.3 58.2 68.3 57.1Z"
      fill="#FFE181"
    />
    <path
      d="M63.5 68.8C64.3 68 65.5 68 66.3 68.8L70 72.5C70.8 73.3 70.8 74.5 70 75.3C69.2 76.1 68 76.1 67.2 75.3L63.5 71.6C62.7 70.8 62.7 69.6 63.5 68.8Z"
      fill="#FFE181"
    />
    <path
      d="M70 38.9C70.8 39.7 70.8 40.9 70 41.7L66.3 45.4C65.5 46.2 64.3 46.2 63.5 45.4C62.7 44.6 62.7 43.4 63.5 42.6L67.2 38.9C68 38.1 69.2 38.1 70 38.9Z"
      fill="#FFE181"
    />
    <path
      d="M26.1 57.1C26.1 56 27 55.1 28.1 55.1H33.4C34.5 55.1 35.4 56 35.4 57.1C35.4 58.2 34.5 59.1 33.4 59.1H28C26.9 59.1 26.1 58.2 26.1 57.1Z"
      fill="#FFE181"
    />
    <path
      d="M33.5999 38.9C34.3999 38.1 35.5999 38.1 36.3999 38.9L40.0999 42.6C40.8999 43.4 40.8999 44.6 40.0999 45.4C39.2999 46.2 38.0999 46.2 37.2999 45.4L33.5999 41.7C32.7999 40.9 32.7999 39.7 33.5999 38.9Z"
      fill="#FFE181"
    />
    <path
      d="M40.0999 68.8C40.8999 69.6 40.8999 70.8 40.0999 71.6L36.3999 75.3C35.5999 76.1 34.3999 76.1 33.5999 75.3C32.7999 74.5 32.7999 73.3 33.5999 72.5L37.2999 68.8C38.0999 68 39.3999 68 40.0999 68.8Z"
      fill="#FFE181"
    />
    <path
      d="M51.7999 63.4C55.2999 63.4 58.0999 60.6 58.0999 57.1C58.0999 53.6 55.2999 50.8 51.7999 50.8C48.2999 50.8 45.4999 53.6 45.4999 57.1C45.4999 60.6 48.2999 63.4 51.7999 63.4Z"
      fill="#FDA402"
    />
    <path
      d="M60.5999 20.4H42.9999C42.2999 20.4 41.7999 19.8 41.7999 19.1V16C41.7999 15.3 42.3999 14.7 42.9999 14.7H60.5999C61.2999 14.7 61.8999 15.3 61.8999 16V19.1C61.8999 19.8 61.2999 20.4 60.5999 20.4Z"
      fill="#FCC977"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 57.2H48.6L51 42C51.2 41 52.4 41 52.6 42L52.8 43L55 57.2H50.5Z"
      fill="#2B2A36"
    />
    <path
      d="M51.7999 61.1C53.9999 61.1 55.7999 59.3 55.7999 57.1C55.7999 54.9 53.9999 53.1 51.7999 53.1C49.5999 53.1 47.7999 54.9 47.7999 57.1C47.8999 59.4 49.5999 61.1 51.7999 61.1Z"
      fill="#FFE181"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="51.8131"
        y1="43.1918"
        x2="80.2032"
        y2="43.1918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default TimerIcon;
