export default api => ({
  fetchComments: (workspaceId, id, type, offset, limit = 1000) =>
    api.get(`/v1/${workspaceId}/${type}/${id}/comments/`, {
      params: {
        limit,
        offset
      }
    }),

  sendComment: ({ workspaceId, entityType, id, comment }) =>
    api.post(`/v1/${workspaceId}/${entityType}/${id}/comments/`, comment)
});
