import api from 'api';

import { getWorkspaceId } from 'store/workspace';
import createActionThunk from 'store/actions-thunk';
import { createAction } from 'redux-actions';

export const fetchDiscussionById = createActionThunk(
  'discussions/fetch-discussion-by-id',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.discussions
      .fetchOne({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchDiscussionMembers = createActionThunk(
  'discussions/fetch-discussion-members',
  ({ id, offset, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.discussions
      .fetchMembers({ workspaceId, id, offset })
      .then(({ data }) => data);
  }
);

export const archiveDiscussion = createActionThunk(
  'discussions/fetch-archive-discussion',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.discussions
      .archive({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const clearCreatedDiscussionId = createAction(
  'discussions/clear-created-discussion-id'
);
