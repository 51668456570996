import memoize from 'lodash/memoize';

export const transformCategoriesData = data => {
  const roots = [];
  const map = {};

  data.forEach(item => {
    map[item.id] = item;
  });

  data.forEach(item => {
    const parent = map[item.parentId];

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(item);
    } else {
      roots.push(item);
    }
  });

  return roots;
};

export const getCategoriesByIds = memoize(({ ids, categories }) =>
  ids.reduce((acc, curr) => {
    if (categories[curr]) {
      acc.push(categories[curr]);
    }
    return acc;
  }, [])
);
