import { createSelector } from 'reselect';

export const getTags = state => state.tags;

export const getTagsByEntity = createSelector(
  getTags,
  tags => ({ entityId, entityType }) => {
    if (!tags[entityType]) {
      return undefined;
    }

    return tags[entityType][entityId];
  }
);

export const getAllTagsEntries = createSelector(
  getTags,
  tags => tags.all.entries
);

export const getAllTagsTotal = createSelector(getTags, tags => tags.all.total);

export const getAllTagsLoading = createSelector(
  getTags,
  tags => tags.all.isLoading
);

export const getFilterSearchValue = createSelector(
  getTags,
  tags => tags.all.filter.search
);

export const getReFetchAfterCreateTag = createSelector(
  getTags,
  tags => tags.all.reFetchAfterCreateTag
);
