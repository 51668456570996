import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INVITE_DATA } from 'constants/index';

import PhoneInput from 'components/common/controls/phone-input';
import PromoCode from 'components/common/promo-code';
import Typography from 'components/common/typography';

import {
  validateMinLength,
  validateMaxLength,
  validateIsPhone,
  validateIsRequire
} from 'utils/validators';
import useAnalytics from 'hooks/analytics/use-analytics';
import { useLocalStorage } from 'hooks/common';

import styles from './styles.module.scss';

const { Title } = Typography;

const CompleteUserForm = ({ form, data, isLoading, isOAuth, onSubmit }) => {
  const [inviteData] = useLocalStorage(INVITE_DATA, {});

  const [countryCode, setCountryCode] = useState();

  const { t } = useTranslation(['CompleteProfile', 'Errors']);

  const { reachGoal, trackPageView } = useAnalytics(true);

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        await onSubmit({ ...values, countryCode });
        reachGoal('reg_step3', 'registration');
      }
    });
  };

  return (
    <Form
      className={classnames(styles.form, styles.maxWidth)}
      data-qa="qa-4oj00e0ophl4ke6"
      onSubmit={handleSubmit}
      hideRequiredMark
      colon={false}
    >
      <Title level={1} className={styles.title} data-qa="qa-u2psps0mxdblcfj">
        {t('CompleteProfileHeading')}
      </Title>

      <Form.Item label={t('LastName')}>
        {form.getFieldDecorator('lastName', {
          initialValue: data.lastName,
          rules: [
            validateIsRequire(),
            validateMinLength(2),
            validateMaxLength(40)
          ]
        })(<Input size="large" data-qa="qa-q3vzun6g1588yvm" />)}
      </Form.Item>

      <Form.Item label={t('FirstName')}>
        {form.getFieldDecorator('firstName', {
          initialValue: data.firstName,
          rules: [
            validateIsRequire(),
            validateMinLength(2),
            validateMaxLength(40)
          ]
        })(<Input size="large" data-qa="qa-sekp5fpg0q13vat" />)}
      </Form.Item>

      <Form.Item label={t('PhoneNumber')}>
        {form.getFieldDecorator('phone', {
          initialValue: data.phone || inviteData.phone,
          rules: [
            {
              validator: validateIsPhone
            }
          ]
        })(
          <PhoneInput
            onChange={(value, __, code) => {
              form.setFieldsValue({
                phone: value
              });
              setCountryCode(code);
            }}
            size="large"
            data-qa="qa-7ql3yutznrfw7wh"
          />
        )}
      </Form.Item>

      {isOAuth && (
        <PromoCode
          label={t('Promocode')}
          formItemClassName={styles.item}
          data-qa="qa-xwwxu6sqspn3ckb"
          form={form}
          name="promoCode"
          size="large"
        />
      )}

      <Button
        htmlType="submit"
        type="primary"
        size="large"
        loading={isLoading}
        data-qa="qa-q3bfsa68tuntps2"
      >
        {t('ProceedBtn')}
      </Button>
    </Form>
  );
};

CompleteUserForm.propTypes = {
  data: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

CompleteUserForm.defaultProps = {
  isLoading: false,
  data: {}
};

export default Form.create()(CompleteUserForm);
