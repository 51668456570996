import React from 'react';

export const SelectTagIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85714 4.42857H18.1429C18.617 4.42857 19 4.81161 19 5.28571V21.5714C19 22.0455 18.617 22.4286 18.1429 22.4286H1.85714C1.38304 22.4286 1 22.0455 1 21.5714V5.28571C1 4.81161 1.38304 4.42857 1.85714 4.42857ZM2.92857 20.5H17.0714V6.35714H2.92857V20.5ZM5.07143 1H21.5714C22.0455 1 22.4286 1.38304 22.4286 1.85714V18.3571C22.4286 18.475 22.3321 18.5714 22.2143 18.5714H20.7143C20.5964 18.5714 20.5 18.475 20.5 18.3571V2.92857H5.07143C4.95357 2.92857 4.85714 2.83214 4.85714 2.71429V1.21429C4.85714 1.09643 4.95357 1 5.07143 1ZM6.98899 13.2701C6.9107 13.1631 6.78543 13.0978 6.64972 13.0978H5.20911C5.03948 13.0978 4.94031 13.291 5.03948 13.4293L8.33826 17.9703C8.37726 18.0237 8.42832 18.0671 8.48727 18.0971C8.54623 18.127 8.61141 18.1426 8.67753 18.1426C8.74365 18.1426 8.80884 18.127 8.86779 18.0971C8.92674 18.0671 8.9778 18.0237 9.0168 17.9703L14.5652 10.3314C14.6644 10.1931 14.5652 10 14.3956 10H12.955C12.8219 10 12.694 10.0652 12.6157 10.1722L8.67753 15.5954L6.98899 13.2701Z"
      fill="currentColor"
    />
  </svg>
);

export default SelectTagIcon;
