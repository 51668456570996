import React, { useEffect, useState } from 'react';
import { Icon, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Button from 'components/common/button';
import { GooglePlay } from 'components/common/icons/common/google-play';
import AppleStore from 'components/common/icons/common/apple-store';
import { MainLogoColorFull } from 'components/common/icons/common/main-logo-colorfull';
import { WebStubIcon } from 'components/common/icons';

import { fetchOrderInfo } from 'store/order-statuses';
import { getRouterUrlQuery } from 'store/router';

import styles from './web-stub.module.scss';

const { Paragraph } = Typography;

export const WebStub = () => {
  const dispatch = useDispatch();
  const query = useSelector(getRouterUrlQuery);
  const [orderInfo, setOrderInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('DownloadBlankScreen');

  const APP_STORE_LINK =
    'https://apps.apple.com/us/app/upservice/id1510273341?l=ru&ls=1';
  const PLAY_MARKET_LINK =
    'https://play.google.com/store/apps/details?id=com.upservice.android';

  const fetchOrder = async () => {
    try {
      setIsLoading(true);

      const data = await dispatch(fetchOrderInfo({ id: query.order_id }));

      setOrderInfo(data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((query || {}).order_id) {
      fetchOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getLinkForDownload = () => {
    if (navigator.platform === 'iOS') {
      return APP_STORE_LINK;
    }

    return PLAY_MARKET_LINK;
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Drawer visible closable={false} width="100%">
      <div className={styles.content} data-qa="qa-kjy0pyljlmjtrnk">
        <Icon component={MainLogoColorFull} className={styles.logoSvg} />

        <Paragraph className={styles.text} data-qa="qa-1zylhpryatqk573">
          {!orderInfo && t('DownloadBlankScreenDescSignUp')}

          {orderInfo &&
            t('DownloadBlankScreenDescOrder', {
              orderId: orderInfo.orderId,
              orderTitle: orderInfo.orderTitle
            })}
        </Paragraph>

        {!orderInfo ? (
          <div className={styles.links} data-qa="qa-s09ta6eib06lnk0">
            <a
              href={PLAY_MARKET_LINK}
              className={styles.link}
              data-qa="qa-l8c4dejxqk4gl2r"
            >
              <Icon className={styles.icon} component={GooglePlay} alt="" />
            </a>
            <a
              href={APP_STORE_LINK}
              className={styles.link}
              data-qa="qa-0g7duzdbrs0jhjx"
            >
              <Icon className={styles.icon} component={AppleStore} alt="" />
            </a>
          </div>
        ) : (
          <a href={getLinkForDownload()}>
            <Button
              style={{ margin: '40px auto', display: 'block' }}
              type="primary"
            >
              {t('DownloadAppBtn')}
            </Button>
          </a>
        )}

        <Icon component={WebStubIcon} className={styles.backgroundImg} />
      </div>
    </Drawer>
  );
};

export default WebStub;
