import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  InputNumber,
  Input,
  Form,
  Alert,
  Typography,
  Checkbox
} from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';

import {
  TYPE_DATE_END,
  TYPE_DATE_START,
  SCHEDULED_TASK_TYPES,
  SCHEDULED_TASK_WEEK,
  DATE_TIME_FORMAT_FM,
  VIDEOS,
  SCHEDULED_TASK_DAY
} from 'constants/index';

import VideoBtn from 'components/common/video-btn';

import { fetchScheduledTaskNextDateLocal } from 'store/tasks';

import useDeepCompareEffect from 'hooks/common/use-deep-compare-effect';

import styles from './styles.module.scss';

const { Text } = Typography;

export const IntervalSelect = ({ form, values }) => {
  const dispatch = useDispatch();

  const defaultFreqType =
    (values.schedulerConfig || {}).freqType || SCHEDULED_TASK_WEEK;
  const defaultFreqInterval = (values.schedulerConfig || {}).freqInterval || 1;

  const { getFieldDecorator, getFieldValue } = form;

  const freqType = getFieldValue('freqType');
  const weekdays = getFieldValue('weekdays');
  const freqInterval = getFieldValue('freqInterval');
  const dateEnd = getFieldValue(TYPE_DATE_END);
  const dateStart = getFieldValue(TYPE_DATE_START);

  const selectOptions = Object.values(SCHEDULED_TASK_TYPES);
  const freqTypeOptions =
    SCHEDULED_TASK_TYPES[freqType || defaultFreqType].options;

  const [nextTaskDate, setNextTaskDate] = useState(null);

  const { t } = useTranslation('AddTask');

  const canShowNextTaskDate =
    !!freqInterval &&
    dateEnd &&
    (!freqTypeOptions || (weekdays || []).length > 0);

  useDeepCompareEffect(() => {
    if (!canShowNextTaskDate) {
      setNextTaskDate(null);
      return;
    }

    dispatch(
      fetchScheduledTaskNextDateLocal({
        dateEnd: dateEnd && moment(dateEnd).toISOString(true),
        dateStart: dateStart && moment(dateStart).toISOString(true),
        freqType,
        freqInterval,
        weekdays
      })
    ).then(({ nextDateEnd, nextDateStart }) => {
      setNextTaskDate({
        dateEnd: nextDateEnd && moment(nextDateEnd).format(DATE_TIME_FORMAT_FM),
        dateStart:
          nextDateStart && moment(nextDateStart).format(DATE_TIME_FORMAT_FM)
      });
    });
  }, [
    dateStart,
    dateEnd,
    dispatch,
    freqInterval,
    weekdays,
    freqType,
    freqTypeOptions
  ]);

  const renderNextCreateDate = ({ nextTaskDateStart }) => {
    const isOneDayPeriod =
      freqType === SCHEDULED_TASK_DAY && freqInterval === 1;

    return moment(nextTaskDateStart, DATE_TIME_FORMAT_FM)
      .subtract(1, isOneDayPeriod ? 'hours' : 'days')
      .format(DATE_TIME_FORMAT_FM);
  };

  return (
    <div>
      <Form.Item label={t('RecurrenceField')}>
        <Input.Group compact>
          {getFieldDecorator('freqInterval', {
            initialValue: defaultFreqInterval
          })(
            <InputNumber
              className={styles.delay}
              min={1}
              data-qa="qa-85xh63t47670ipf"
            />
          )}

          {getFieldDecorator('freqType', {
            initialValue: defaultFreqType
          })(
            <Select
              dropdownMatchSelectWidth={false}
              className={styles.repeatInterval}
            >
              {selectOptions.map(({ label, ns, value }) => (
                <Select.Option value={value} data-qa="qa-dyirwqncfdk6xuo">
                  <Translation ns={ns}>
                    {translate => translate(label)}
                  </Translation>
                </Select.Option>
              ))}
            </Select>
          )}

          <VideoBtn
            slug={VIDEOS.recurringTask}
            style={{ display: 'flex', position: 'absolute', right: 0, top: 0 }}
          />
        </Input.Group>
      </Form.Item>

      {freqTypeOptions && (
        <Form.Item>
          {getFieldDecorator('weekdays', {
            initialValue: (values.schedulerConfig || {}).weekdays || [
              SCHEDULED_TASK_TYPES[SCHEDULED_TASK_WEEK].options[moment().day()]
                .value
            ]
          })(
            <Checkbox.Group
              className={styles.weekdays}
              data-qa="qa-dfb6qacksgzc4ns"
              options={freqTypeOptions.map(f => ({
                ...f,
                label: (
                  <Translation ns={f.ns}>
                    {translate => translate(f.label)}
                  </Translation>
                )
              }))}
            />
          )}
        </Form.Item>
      )}

      {!canShowNextTaskDate && (
        <Alert
          style={{ marginBottom: 15 }}
          data-qa="qa-i3i70kg23zaj1uj"
          message={t('RepeatTaskWarning')}
          type="warning"
        />
      )}

      {canShowNextTaskDate && nextTaskDate && (
        <Alert
          style={{ marginBottom: 15, fontSize: 12 }}
          data-qa="qa-wo42mx1x6djppqr"
          message={
            <>
              {t('NextTaskCreateWarning')}{' '}
              <Text strong>
                {renderNextCreateDate({
                  nextTaskDateStart: nextTaskDate.dateStart
                })}
              </Text>{' '}
              {t('NextTaskCreateTermsWarning')} <br />
              {nextTaskDate.dateStart && (
                <>
                  {t('NextTaskCreateStartWarning')}{' '}
                  <Text strong>{nextTaskDate.dateStart}</Text> <br />
                </>
              )}
              {t('NextTaskCreateDueWarning')}{' '}
              <Text strong>{nextTaskDate.dateEnd}</Text>
            </>
          }
          type="warning"
        />
      )}
    </div>
  );
};

IntervalSelect.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object
};

IntervalSelect.defaultProps = {
  values: {}
};

export default IntervalSelect;
