import React from 'react';

export const AdminIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#0389B6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5H6V13H7V10H13L11.5714 7.5L13 5H7Z"
      fill="white"
    />
  </svg>
);

export default AdminIcon;
