import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDefaultState as clearAssetsState } from 'store/assets';
import { clearEmployeeCalendarFilter } from 'store/calendar';
import { clearFilter, setIsNeedToReconnect } from 'store/operator';
import { getUserEmployee, getWorkspaceId } from 'store/workspace';
import { clearAllTags } from 'store/tags';
import { clearFilter as clearContactsFilter } from 'store/contacts';

export const ChangeWorkspaceProvider = () => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getWorkspaceId);
  const employee = useSelector(getUserEmployee);

  const isFirstRender = useRef(true);

  // TODO: add all states
  useEffect(() => {
    if (!isFirstRender.current) {
      dispatch(clearAssetsState());
      dispatch(clearEmployeeCalendarFilter());
      dispatch(setIsNeedToReconnect(true));
      dispatch(clearFilter({ entityId: employee.id }));
      dispatch(clearAllTags());
      dispatch(clearContactsFilter());
    } else {
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return <Fragment />;
};

export default ChangeWorkspaceProvider;
