import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { Spin } from 'antd';

import SkeletonChat from './skeleton';
import ToBottom from './to-bottom';
import Empty from './empty';

import styles from './chat.module.scss';

const Chat = ({
  messages,
  empty,
  isLoading,
  className,
  messagesRef,
  hasMore,
  hasMessages,
  loadMore,
  controls,
  toBottom,
  isEntityLoading,
  canView,
  messagesClassName,
  skeletonProps,
  isReverse,
  threshold,
  infiniteClassName,
  isJoined,
  messagesCount
}) => {
  if (isEntityLoading) {
    return <SkeletonChat {...skeletonProps} />;
  }

  if (!canView) {
    return null;
  }

  const { className: skeletonClassName, ...restSkeletonProps } = skeletonProps;

  // messagesCount === 1 - handling switching between contacts, as they always have one message for preview.
  const isInitialLoading = (!hasMessages || messagesCount === 1) && isLoading;
  const isSomeLoading =
    !isInitialLoading && hasMessages && (isLoading || !isJoined);

  return (
    <div className={classnames(styles.root, className)}>
      {isSomeLoading && (
        <div
          className={classnames(styles.spinWrap, {
            [styles.isReverse]: isReverse
          })}
        >
          <Spin spinning />
        </div>
      )}

      <div
        ref={messagesRef}
        className={classnames(styles.messages, messagesClassName)}
      >
        <InfiniteScroll
          hasMore={hasMore}
          initialLoad={false}
          useWindow={false}
          isReverse={isReverse}
          threshold={threshold}
          className={classnames(styles.scroll, infiniteClassName)}
          loadMore={() => {
            if (!hasMore) {
              return;
            }

            loadMore();
          }}
        >
          {isInitialLoading && (
            <SkeletonChat
              className={classnames(styles.skeleton, skeletonClassName)}
              {...restSkeletonProps}
            />
          )}

          {!isLoading && !hasMessages && <Empty {...empty} />}

          {messages}
        </InfiniteScroll>
      </div>

      {toBottom && <ToBottom {...toBottom} />}

      {controls}
    </div>
  );
};

Chat.propTypes = {
  messages: PropTypes.object,
  empty: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    description: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  messagesRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  hasMore: PropTypes.bool,
  hasMessages: PropTypes.bool,
  loadMore: PropTypes.func,
  controls: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toBottom: PropTypes.shape({
    visible: PropTypes.bool,
    hasNewMessages: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }),
  isEntityLoading: PropTypes.bool,
  canView: PropTypes.bool,
  messagesClassName: PropTypes.string,
  skeletonProps: PropTypes.shape({
    className: PropTypes.string,
    isIncoming: PropTypes.bool
  }),
  isReverse: PropTypes.bool,
  threshold: PropTypes.number,
  infiniteClassName: PropTypes.string,
  isJoining: PropTypes.bool,
  messagesCount: PropTypes.number
};

Chat.defaultProps = {
  messages: null,
  empty: {},
  isLoading: false,
  className: undefined,
  messagesRef: () => {},
  hasMore: false,
  hasMessages: false,
  loadMore: () => {},
  controls: null,
  toBottom: null,
  isEntityLoading: false,
  canView: true,
  messagesClassName: undefined,
  skeletonProps: {},
  isReverse: true,
  threshold: 250,
  infiniteClassName: undefined,
  isJoining: false,
  messagesCount: 0
};

export default Chat;
