import axios from 'axios';
import { LANGUAGE } from 'i18n-config';

import { config } from 'config';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_URL}/ai/api`,
  params: {
    access_token: localStorage.getItem('token')
  }
});

api.interceptors.request.use(
  configuration => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

export default () => ({
  fetchChats: ({ workspaceId, entityType, limit, offset }) =>
    api.get(`/v1/chats/`, {
      params: {
        workspace: workspaceId,
        entity_type: entityType,
        limit,
        offset
      }
    }),

  fetchMessages: ({ id, workspaceId, limit, offset }) =>
    api.get(`/v1/chats/${id}/messages`, {
      params: {
        workspace: workspaceId,
        limit,
        offset
      }
    }),

  fetchPrompts: ({ entityType }) =>
    api.get(`/v1/prompts/`, {
      params: {
        entity_type: entityType
      }
    }),

  checkDialogPresence: ({ entityType, entityId, workspaceId }) =>
    api.get(`/v1/chats/chat`, {
      params: {
        workspace: workspaceId,
        entity_type: entityType,
        entity_id: entityId
      }
    })
});
