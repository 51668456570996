import React from 'react';

export const SnoozeChatIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
  >
    <path
      d="M88.55 123.551C87.1673 123.623 85.7117 123.696 84.329 123.623C77.3425 123.478 70.1377 123.696 63.2239 123.842C62.8601 123.842 62.4962 123.842 62.1323 123.842C57.4746 123.915 52.9625 123.915 48.7415 123.696C47.286 123.623 45.8305 123.551 44.4477 123.405C30.1836 122.022 20.8683 117.292 24.5071 102.446C24.7982 101.208 24.871 99.8985 24.7982 98.5885C24.4343 92.9848 21.0866 86.5077 18.4667 79.8123C17.8117 78.2112 17.3022 76.6101 16.7928 75.0091C16.2106 73.1169 15.7739 71.2247 15.4828 69.3325C15.0462 66.1304 15.119 63.001 16.1378 59.8716C16.1378 59.8716 17.1567 56.7423 19.4855 53.7585C22.3238 50.1197 55.3641 15.551 94.2993 28.7963C95.9004 29.3057 134.181 43.0604 134.763 74.718C135.345 105.429 99.612 122.896 88.55 123.551Z"
      fill="#EBF8F8"
    />
    <path
      d="M79.6895 33.5824C79.6895 33.5824 92.9817 26.674 95.1679 27.1112C97.3542 27.5485 103.563 39.9662 102.689 40.4909C101.814 41.0156 96.2173 37.5177 96.2173 37.5177C96.2173 37.5177 95.6052 40.0537 93.6813 39.7039C91.7574 39.3541 89.1339 36.6432 89.1339 36.6432C89.1339 36.6432 88.959 38.3047 87.0352 38.0423C85.1113 37.78 81.8757 34.8942 81.8757 34.8942L79.6895 33.5824Z"
      fill="url(#paint0_linear_87343_576334)"
    />
    <path
      d="M32.3797 29.1221C32.3797 29.1221 20.5741 19.8525 18.3879 19.8525C16.2016 19.8525 7.80653 30.9586 8.50612 31.6582C9.29316 32.3577 15.4146 29.9966 15.4146 29.9966C15.4146 29.9966 15.502 32.5326 17.5134 32.6201C19.4372 32.6201 22.5854 30.5213 22.5854 30.5213C22.5854 30.5213 22.4105 32.1828 24.4218 32.2703C26.4332 32.3577 30.106 30.0841 30.106 30.0841L32.3797 29.1221Z"
      fill="url(#paint1_linear_87343_576334)"
    />
    <path
      d="M76.8913 52.8209L31.855 48.6234C29.4065 48.361 27.57 46.1748 27.7449 43.7262L30.3684 16.0923C30.6307 13.6438 32.817 11.8073 35.2655 11.9822L80.3018 16.1798C82.7503 16.4421 84.5868 18.6284 84.4119 21.0769L81.7884 48.7108C81.5261 51.2468 79.3398 53.0833 76.8913 52.8209Z"
      fill="#FCC977"
    />
    <path
      d="M83.1678 17.6034L58.7181 35.5794C56.4866 37.296 53.5684 36.9527 51.5084 34.7211L31.7676 13.2636C32.6259 12.4053 33.8908 12.0513 35.2641 12.0513L80.5661 16.2891C81.7677 16.4608 82.3095 16.9167 83.1678 17.6034Z"
      fill="#F9B74B"
    />
    <path
      d="M82.5754 17.1419L59.4888 30.4341C57.3901 31.6584 54.854 31.3961 53.0176 29.822L32.8169 12.5071C33.6039 12.1573 34.4431 11.8566 35.3176 11.944L80.4051 16.2087C81.2796 16.2961 81.9632 16.6172 82.5754 17.1419Z"
      fill="#FFE181"
    />
    <path
      d="M130.323 39.4413C130.323 39.4413 133.209 34.2818 134.083 33.932C134.87 33.5822 139.68 36.3806 139.505 36.7304C139.33 37.0802 136.707 37.1677 136.707 37.1677C136.707 37.1677 137.056 38.1296 136.357 38.4794C135.657 38.8292 134.171 38.4794 134.171 38.4794C134.171 38.4794 134.52 39.0915 133.733 39.4413C132.946 39.7911 131.285 39.5288 131.285 39.5288L130.323 39.4413Z"
      fill="url(#paint2_linear_87343_576334)"
    />
    <path
      d="M113.62 48.2737C113.62 48.2737 107.673 47.749 106.974 48.2737C106.274 48.711 105.837 54.3077 106.274 54.3077C106.711 54.3952 108.198 52.2089 108.198 52.2089C108.198 52.2089 108.81 52.996 109.422 52.6462C110.122 52.2089 110.647 50.8097 110.647 50.8097C110.647 50.8097 110.909 51.4219 111.609 50.9846C112.308 50.5474 113.008 49.0608 113.008 49.0608L113.62 48.2737Z"
      fill="url(#paint3_linear_87343_576334)"
    />
    <path
      d="M133.558 46.5248L117.642 54.9199C116.768 55.3571 115.719 55.0073 115.194 54.1328L110.034 44.3386C109.597 43.4641 109.947 42.4147 110.821 41.89L126.737 33.4949C127.612 33.0576 128.661 33.4074 129.186 34.2819L134.345 44.0762C134.782 44.9507 134.433 46.0875 133.558 46.5248Z"
      fill="#F9B74B"
    />
    <path
      d="M127.672 33.3409L122.863 42.7854C122.426 43.6599 121.551 44.0972 120.589 44.0097L110.095 42.6105C110.27 42.3482 110.532 42.0858 110.795 41.9109L126.711 33.5158C126.973 33.3409 127.323 33.2535 127.672 33.3409Z"
      fill="#FFE181"
    />
    <path
      d="M97.7802 88.5011C97.7802 88.5011 91.3355 81.3382 89.8194 81.0969C88.4424 80.8242 81.804 87.004 82.2079 87.4971C82.6119 87.9902 86.8138 87.1848 86.8138 87.1848C86.8138 87.1848 86.604 88.8399 87.842 89.1441C89.08 89.4483 91.3173 88.3571 91.3173 88.3571C91.3173 88.3571 90.9816 89.4561 92.3587 89.7288C93.7357 90.0016 96.2825 88.9864 96.2825 88.9864L97.7802 88.5011Z"
      fill="url(#paint4_linear_87343_576334)"
    />
    <path
      d="M127.511 96.5292C127.511 96.5292 136.775 93.5545 138.076 94.1367C139.346 94.58 142.056 103.319 141.361 103.477C140.697 103.773 137.547 100.833 137.547 100.833C137.547 100.833 136.857 102.305 135.758 101.969C134.488 101.526 133.151 99.4903 133.151 99.4903C133.151 99.4903 132.954 100.558 131.684 100.115C130.415 99.6713 128.768 97.5599 128.768 97.5599L127.511 96.5292Z"
      fill="url(#paint5_linear_87343_576334)"
    />
    <path
      d="M95.1869 100.926L123.509 108.542C125.057 108.923 126.599 107.989 127.118 106.41L131.795 88.9831C132.175 87.4356 131.241 85.8933 129.662 85.374L101.34 77.758C99.792 77.3777 98.2497 78.3115 97.7304 79.8905L93.0543 97.3173C92.6741 98.8649 93.6393 100.546 95.1869 100.926Z"
      fill="#F9B74B"
    />
    <path
      d="M99.7977 77.8497L110.843 91.1318C111.853 92.3645 113.401 92.7448 114.898 92.2595L131.076 86.2577C130.704 85.9037 130.192 85.5811 129.712 85.3975L101.39 77.7814C100.91 77.5978 100.322 77.5847 99.7977 77.8497Z"
      fill="#FFE181"
    />
    <path
      d="M37.1284 132.629C52.8054 132.629 65.5141 119.92 65.5141 104.243C65.5141 88.5661 52.8054 75.8574 37.1284 75.8574C21.4514 75.8574 8.74268 88.5661 8.74268 104.243C8.74268 119.92 21.4514 132.629 37.1284 132.629Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3716 76.4286L37.8002 80.0286C37.743 80.4572 37.4287 80.6572 37.1144 80.6572C37.0859 80.6572 37.0716 80.6572 37.043 80.6429C36.7716 80.6286 36.5144 80.4286 36.443 80.0286L36.3002 79.1286L35.8716 76.4143C36.2573 76.4 36.6573 76.3857 37.043 76.3857C37.4859 76.3857 37.9287 76.4 38.3716 76.4286Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.3858 131.757C38.0144 131.786 37.6573 131.8 37.2858 131.8H37.0858C36.7144 131.8 36.3573 131.786 35.9858 131.771L36.5144 128.471C36.5716 128.043 36.8858 127.843 37.2001 127.843C37.2287 127.843 37.2573 127.843 37.2858 127.857C37.5573 127.886 37.8144 128.086 37.8716 128.471L38.0144 129.371L38.3858 131.757Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.8574 104.129V104.557H64.8431C64.8431 104.872 64.8431 105.2 64.8145 105.514L61.1574 104.929C60.8717 104.886 60.7002 104.743 60.6145 104.557C60.5574 104.457 60.5288 104.357 60.5288 104.243C60.5288 103.943 60.7288 103.643 61.1574 103.572L62.0574 103.429L64.8288 102.986C64.8431 103.372 64.8574 103.743 64.8574 104.129Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7426 89.1572L16.5712 91.4572C16.9141 91.7144 16.9283 92.1001 16.7712 92.3715C16.7569 92.4001 16.7426 92.4144 16.7283 92.4287C16.5855 92.6572 16.2855 92.7858 15.8998 92.6429L15.0426 92.3144L12.4712 91.3287C12.6569 90.9858 12.8426 90.6287 13.0283 90.3001C13.2569 89.9144 13.4855 89.5429 13.7283 89.1715L13.7426 89.1572Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.6714 116.814C61.5142 117.157 61.3428 117.471 61.1571 117.786L61.0571 117.957C60.8714 118.286 60.6857 118.586 60.4857 118.9L57.8857 116.786C57.5428 116.528 57.5285 116.143 57.6857 115.871C57.7 115.843 57.7142 115.828 57.7428 115.8C57.9 115.586 58.2 115.457 58.5714 115.6L59.4285 115.928L61.6857 116.8L61.6714 116.814Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9712 80.0573L51.3426 80.2716C51.614 80.443 51.8997 80.6001 52.1569 80.7858L49.814 83.6573C49.6283 83.8858 49.4283 83.9573 49.214 83.943C49.0998 83.943 48.9997 83.9144 48.8997 83.8573C48.6426 83.7144 48.4855 83.3858 48.6283 82.9716L48.9569 82.1144L49.9569 79.4858C50.2998 79.6716 50.6283 79.843 50.9569 80.0287L50.9712 80.0573Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.057 124.386C25.3142 124.543 25.4713 124.857 25.3285 125.271L24.9999 126.129L24.0428 128.629C23.7428 128.486 23.457 128.329 23.1713 128.157C22.7428 127.9 22.3285 127.657 21.8999 127.371L24.1428 124.6C24.3999 124.257 24.7856 124.243 25.057 124.4V124.386Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3713 79.5001L25.6713 82.9001C25.8427 83.3001 25.6713 83.6287 25.3855 83.7858C25.357 83.8001 25.3427 83.8144 25.3141 83.8144C25.0713 83.943 24.7427 83.9001 24.4855 83.5858L23.9141 82.8716L22.1855 80.7287C22.5141 80.5287 22.857 80.3144 23.1855 80.1144C23.5713 79.8858 23.957 79.6858 24.357 79.4858L24.3713 79.5001Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0428 127.415C51.7285 127.629 51.4285 127.815 51.1142 128L50.9428 128.1C50.6143 128.286 50.3 128.457 49.9714 128.629L48.7857 125.5C48.6143 125.1 48.7857 124.772 49.0714 124.615C49.1 124.6 49.1143 124.586 49.1571 124.586C49.4 124.472 49.7285 124.515 49.9714 124.829L50.5428 125.543L52.0571 127.429L52.0428 127.415Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.1572 90.2289L61.3715 90.6003C61.5286 90.886 61.7001 91.1574 61.8286 91.4574L58.3715 92.7717C58.1001 92.8717 57.8858 92.8432 57.7143 92.7146C57.6143 92.6574 57.5429 92.586 57.4858 92.486C57.3429 92.2289 57.3572 91.8717 57.7001 91.586L58.4143 91.0146L60.6001 89.2432C60.8001 89.5717 61.0001 89.886 61.2001 90.2146L61.1572 90.2289Z"
      fill="#36B7BD"
    />
    <path
      d="M37.2573 108.771C40.2859 108.771 42.743 106.314 42.743 103.286C42.743 100.257 40.2859 97.7998 37.2573 97.7998C34.2288 97.7998 31.7573 100.257 31.7573 103.286C31.7573 106.314 34.2145 108.771 37.2573 108.771Z"
      fill="#FDA402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.143 103.271H34.4858L36.5716 90.0428C36.7001 89.1999 37.8001 89.1999 37.943 90.0428L38.0858 90.9428L40.043 103.271H36.143Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8146 102.157V100.5L49.0432 102.643C49.886 102.771 49.8717 103.886 49.0432 104.014L48.1432 104.157L35.8003 106.057V102.157H35.8146Z"
      fill="#2B2A36"
    />
    <path
      d="M37.2574 106.743C39.1717 106.743 40.7146 105.186 40.7146 103.271C40.7146 101.357 39.1717 99.7998 37.2574 99.7998C35.3432 99.7998 33.8003 101.357 33.8003 103.271C33.8003 105.186 35.3432 106.743 37.2574 106.743Z"
      fill="#FFE181"
    />
    <path
      d="M69.2429 104.072C69.2429 121.686 54.9429 135.972 37.2143 136.229H36.7857C31.9 136.229 27.2714 135.129 23.1286 133.143C20.8 132.029 18.6429 130.629 16.6571 129.014L19.2714 125.229C23.8286 129.072 29.6286 131.457 35.9857 131.7C36.3571 131.714 36.7143 131.729 37.0857 131.729H37.2857C37.6571 131.729 38.0143 131.714 38.3857 131.686C52.6 131.043 64.0143 119.672 64.7 105.472C64.7286 105.157 64.7286 104.829 64.7286 104.514C64.7429 104.372 64.7429 104.229 64.7429 104.086C64.7429 103.7 64.7286 103.329 64.7143 102.943C64.1429 88.6573 52.6714 77.1287 38.4143 76.4716C37.9714 76.443 37.5286 76.4287 37.0857 76.4287C36.7 76.4287 36.3 76.4287 35.9143 76.4573C21.8857 77.0287 10.5429 88.0859 9.51429 101.986H14.6857L7.34286 110.114L0 101.986H5C5.75714 90.0573 12.9571 79.8859 23.1286 75.0144C27.2714 73.043 31.9 71.9287 36.7857 71.9287C36.9143 71.9287 37.0286 71.9287 37.1571 71.943H37.2143C54.9429 72.1859 69.2429 86.4859 69.2429 104.072Z"
      fill="url(#paint6_linear_87343_576334)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_87343_576334"
        x1="91.2309"
        y1="27.0913"
        x2="91.2309"
        y2="40.5445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_87343_576334"
        x1="20.4223"
        y1="19.8525"
        x2="20.4223"
        y2="32.6201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_87343_576334"
        x1="134.916"
        y1="33.9019"
        x2="134.916"
        y2="39.6232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_87343_576334"
        x1="109.86"
        y1="48.0405"
        x2="109.86"
        y2="54.3103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_87343_576334"
        x1="88.4842"
        y1="81.3488"
        x2="90.5438"
        y2="90.4438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_87343_576334"
        x1="133.405"
        y1="94.8048"
        x2="135.662"
        y2="104.772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36B7BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_87343_576334"
        x1="0"
        y1="104.072"
        x2="69.2429"
        y2="104.072"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default SnoozeChatIcon;
