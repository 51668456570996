import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import Icon from 'components/common/icon';
import Button from 'components/common/button';

import styles from '../components.module.scss';

const ShowTopToolbarButton = ({ onClick, isActive }) => (
  <Tooltip title="" mouseEnterDelay={0.5}>
    <Button
      type="text"
      mood="none"
      className={styles.toolbarBtn}
      onClick={onClick}
    >
      <Icon
        type="font-colors"
        size={16}
        color={isActive ? 'brand' : 'black-55'}
      />
    </Button>
  </Tooltip>
);

ShowTopToolbarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default ShowTopToolbarButton;
