import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import UserCardSmall from 'components/common/user-card-small';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import PrevResponsible from './prev-responsible';

import styles from './details.module.scss';

export const UserRole = ({
  label,
  labelProps,
  allowChange,
  user,
  previousResponsible,
  approvingStatus,
  onClickEditor,
  addLabel,
  className,
  stylesButton
}) => {
  const { t } = useTranslation(['Task', 'Common']);

  if (!user && !allowChange) {
    return null;
  }

  if (!user && allowChange) {
    return (
      <Button
        type="link"
        className={styles.addUserRoleButton}
        data-qa="qa-al5zn7jteqkurp2"
        onClick={() => onClickEditor(user)}
      >
        <Icon type="plus-circle" data-qa="qa-0ve55u7zzhx7j5k" />
        {addLabel || t('Add', { ns: 'Common' })}
      </Button>
    );
  }

  return (
    <div
      className={classnames(styles.userRole, className)}
      data-qa="qa-h4l0mvu1lj52iex"
    >
      <div style={{ minWidth: '25%' }}>
        <Typography.Paragraph
          color="black-35"
          style={{ marginBottom: 8 }}
          {...labelProps}
        >
          {label}
        </Typography.Paragraph>

        <UserCardSmall asLink {...user} />
      </div>

      {previousResponsible && <PrevResponsible user={previousResponsible} />}

      {allowChange && (
        <Button
          style={stylesButton}
          type="link"
          size="small"
          onClick={() => onClickEditor(user)}
        >
          {t('ChangeBtn')}
        </Button>
      )}

      {approvingStatus !== null && (
        <Typography.Title level={3} color="black-65" style={{ margin: 0 }}>
          {t(approvingStatus ? 'ApprovedStatus' : 'DeclinedStatus')}
        </Typography.Title>
      )}
    </div>
  );
};

UserRole.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  addLabel: PropTypes.string,
  allowChange: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarFile: PropTypes.object
  }),
  previousResponsible: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarFile: PropTypes.object
  }),
  approvingStatus: PropTypes.bool,
  onClickEditor: PropTypes.func
};

UserRole.defaultProps = {
  allowChange: false,
  user: undefined,
  previousResponsible: null,
  approvingStatus: null,
  onClickEditor: () => {},
  addLabel: null,
  labelProps: {}
};

export default UserRole;
