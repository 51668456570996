import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import { SkeletonButton } from 'components/common/skeletons';

import { addPaymentAddon, fetchAddonCheckout } from 'store/billing';

import AccountAddon from '../../addons/account-addon';

import styles from './add-payment-addon.module.scss';

const AddPaymentAddonModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [addedAddon, setAddedAddon] = useState({
    count: 1,
    isEnabled: true,
    isDisabled: true
  });
  const [checkout, setChekout] = useState(null);

  const { t } = useTranslation('ChangePlan');

  const { currentTariff, account, availableAddon, callback } = data;
  const { period, currency } = account || {};

  const changeAddedAddon = ({ fieldKey, fieldValue }) =>
    setAddedAddon(prev => ({
      ...prev,
      [fieldKey]: fieldValue
    }));

  const fetchCheckout = async () => {
    try {
      setIsLoading(true);

      const fetchedCheckout = await dispatch(
        fetchAddonCheckout({ addonId: availableAddon.id })
      );

      setChekout(fetchedCheckout);
    } catch {
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const addAddon = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        addPaymentAddon({ addonId: availableAddon.id, count: addedAddon.count })
      );

      onClose();

      if (callback) {
        callback();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      width={980}
      title={t('ConnectAddonsHeading')}
      visible={visible}
      destroyOnClose
      contentClassName={styles.root}
      onClose={onClose}
    >
      {isLoading && <SkeletonButton className={styles.skeleton} />}

      {!isLoading && checkout && (
        <>
          <AccountAddon
            availableAddon={availableAddon}
            addon={addedAddon}
            checkout={checkout}
            selectedTariff={{
              tariff: currentTariff,
              period,
              currency
            }}
            changeAddon={changeAddedAddon}
          />

          <Button
            type="primary"
            className={styles.button}
            loading={isLoading}
            onClick={addAddon}
          >
            {t(checkout.payNow ? 'PayBtn' : 'NextBtn')}
          </Button>
        </>
      )}
    </Modal>
  );
};

AddPaymentAddonModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    currentTariff: PropTypes.shape({
      id: PropTypes.number
    }),
    account: PropTypes.shape({
      period: PropTypes.string,
      currency: PropTypes.string,
      callback: PropTypes.func
    })
  }),
  onClose: PropTypes.func.isRequired
};

AddPaymentAddonModal.defaultProps = {
  data: {
    currentTariff: {},
    account: {},
    callback: () => {}
  }
};

export default AddPaymentAddonModal;
