import React from 'react';

export const OnlineIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M19.5 0H4.5C2.01472 0 0 2.01472 0 4.5V19.5C0 21.9853 2.01472 24 4.5 24H19.5C21.9853 24 24 21.9853 24 19.5V4.5C24 2.01472 21.9853 0 19.5 0Z"
        fill="#35BF5B"
      />
      <path
        d="M14.9999 12C16.6567 12 18 10.0972 18 7.75002C18 5.40277 17.559 3.5 14.9999 3.5C12.4407 3.5 11.9996 5.40277 11.9996 7.75002C11.9996 10.0972 13.3429 12 14.9999 12Z"
        fill="white"
      />
      <path
        d="M22.5 18.9409C22.4265 15.6904 21.8123 14.2863 17.1745 13.7C17.1745 13.7 16.5216 14.2827 15 14.2827C13.4784 14.2827 12.8253 13.7 12.8253 13.7C8.18755 14.2863 7.57328 15.6904 7.49974 18.9409C7.49974 18.9409 8.60425 20.5 15 20.5C21.3957 20.5 22.5 18.9409 22.5 18.9409Z"
        fill="white"
      />
      <path
        d="M9.23946 11.66C9.23946 11.66 8.71721 12.1262 7.49994 12.1262C6.28268 12.1262 5.7603 11.66 5.7603 11.66C2.05022 12.129 1.55883 13.2523 1.5 15.8527C1.5 15.8527 2.32625 16.8446 6.13504 17.0591C6.293 15.9991 6.63522 14.9639 7.39717 14.1115C8.34735 13.0485 9.74834 12.5023 11.5445 12.1789C10.964 11.951 10.2124 11.783 9.23946 11.66Z"
        fill="white"
      />
      <path
        d="M7.49985 10.3C8.82528 10.3 9.89985 8.77778 9.89985 6.90002C9.89985 5.02222 9.54705 3.5 7.49985 3.5C5.45264 3.5 5.09977 5.02222 5.09977 6.90002C5.09977 8.77778 6.17435 10.3 7.49985 10.3Z"
        fill="white"
      />
    </g>
  </svg>
);

export default OnlineIcon;
