import React, { useEffect, Fragment, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import i18n, { LANGUAGE } from 'i18n-config';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import {
  EMPLOYEE_DEPARTMENT_CHANGED,
  SELECT_WORKSPACE,
  TYPE_CONTACT
} from 'constants/index';

import { DocumentTitleContext } from 'components/common/document-title/document-title-context';

import {
  getIsUserLoaded,
  getIsUserLoading,
  getIsUserAuthorized,
  fetchCurrentUser,
  getToken,
  getHasErrorUser,
  updateUser
} from 'store/user';
import {
  fetchWorkspaces,
  getIsWorkspacesLoading,
  getIsWorkspacesLoaded,
  getHasErrorWorkspace,
  checkJoining,
  getWorkspaceId,
  getSelectedId,
  getJoining
} from 'store/workspace';
import {
  getRouterUrlQuery,
  getRouterUrlView,
  getRouterState,
  getRouterUrlSubmenu
} from 'store/router';
import { getEntityChatHasNewMessages } from 'store/operator';
import { setUILanguage } from 'store/ui';

import { useRoutesService } from 'services/routes';
import { socket } from 'socket';

import ErrorModal from './error-modal';
import JoiningMessage from './joining-message';
import useWorkspacesStorage from './use-workspaces-storage';

const FAVICON_NEW_MESSAGE_URL = `${process.env.PUBLIC_URL}/favicon-new-message.ico`;
const FAVICON_URL = `${process.env.PUBLIC_URL}/favicon.ico`;

const UserDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();

  const { t } = useTranslation('Common');

  const { layerTitles, setTitle, removeTitle } = useContext(
    DocumentTitleContext
  );

  const token = useSelector(getToken);
  const query = useSelector(getRouterUrlQuery);
  const workspaceId = useSelector(getWorkspaceId);
  const selectedId = useSelector(getSelectedId);
  const hasErrorUser = useSelector(getHasErrorUser);
  const hasErrorWorkspace = useSelector(getHasErrorWorkspace);
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const isUserLoading = useSelector(getIsUserLoading);
  const isUserLoaded = useSelector(getIsUserLoaded);
  const isWorksacesLoading = useSelector(getIsWorkspacesLoading);
  const isWorksacesLoaded = useSelector(getIsWorkspacesLoaded);
  const joining = useSelector(getJoining);
  const view = useSelector(getRouterUrlView);
  const subMenu = useSelector(getRouterUrlSubmenu);
  const state = useSelector(getRouterState) || {};

  const contactChatHasNewMessages = useSelector(reduxState =>
    getEntityChatHasNewMessages(reduxState)({ entityType: TYPE_CONTACT })
  );
  const titleTimer = useRef({});

  const DIALOGS_NEW = t('NewChatMessages');

  const fetchUser = () => dispatch(fetchCurrentUser());
  const fetchAllWorkspaces = personalWorkspaceId =>
    dispatch(fetchWorkspaces({ personalWorkspaceId }));
  const checkIsJoining = () => dispatch(checkJoining());
  const goTo = pathname => dispatch(push(pathname));

  const changeDocumentTitle = () => {
    const favicon = document.getElementById('favicon');

    if (contactChatHasNewMessages) {
      titleTimer.current[0] = setInterval(() => {
        if (layerTitles.current[1] === DIALOGS_NEW) {
          return removeTitle(DIALOGS_NEW);
        }

        return setTitle(DIALOGS_NEW, 1);
      }, 1000);

      setTimeout(() => {
        titleTimer.current[1] = setInterval(() => {
          favicon.href =
            favicon.href === FAVICON_URL || favicon.href.includes(FAVICON_URL)
              ? FAVICON_NEW_MESSAGE_URL
              : FAVICON_URL;
        }, 1000);
      }, 500);

      return null;
    }

    clearInterval(titleTimer.current[0]);
    clearInterval(titleTimer.current[1]);
    favicon.href = FAVICON_URL;

    return removeTitle(DIALOGS_NEW);
  };

  const checkAuthorized = () => {
    if (!isUserAuthorized) {
      return;
    }

    if (hasErrorUser || hasErrorWorkspace) {
      return;
    }

    if (!isUserLoading && !isUserLoaded && !hasErrorUser) {
      fetchUser().then(
        ({
          isRegistrationCompleted,
          isRegistrationCompanyCompleted,
          personalWorkspaceId,
          language,
          id
        }) => {
          Sentry.setUser({ id });

          if (
            (!isRegistrationCompleted || !isRegistrationCompanyCompleted) &&
            subMenu !== 'finish'
          ) {
            return routes.toRegisterFinish({
              ...state,
              isRegistrationCompleted,
              isRegistrationCompanyCompleted
            });
          }

          const storageLanguage = localStorage.getItem(LANGUAGE);

          if (language !== storageLanguage) {
            i18n.changeLanguage(storageLanguage);
            dispatch(setUILanguage(storageLanguage));
            dispatch(updateUser({ user: { id, language: storageLanguage } }));
          }

          if (!isWorksacesLoading && !isWorksacesLoaded) {
            return fetchAllWorkspaces(personalWorkspaceId);
          }
        }
      );
    }
  };

  useEffect(() => {
    checkAuthorized();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUserAuthorized,
    hasErrorUser,
    isUserLoading,
    isUserLoaded,
    isWorksacesLoading,
    isWorksacesLoaded,
    token
  ]);

  useEffect(() => {
    if (isUserAuthorized && isWorksacesLoaded && view !== SELECT_WORKSPACE) {
      checkIsJoining();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, selectedId]);

  const parseRegisterLink = () => {
    const { action, token: tokenQuery, uid, registration } = query;

    const isRegisterLink =
      action === 'ACCOUNT_ACTIVATION' &&
      !!tokenQuery &&
      !!uid &&
      !!registration;

    const isInviteLink = action === 'EMPLOYEE_INVITATION' && !!tokenQuery;

    if (isRegisterLink) {
      return goTo({
        pathname: `/auth/activate/${uid}/${tokenQuery}`,
        search: `registration=${registration}`
      });
    }

    if (isInviteLink) {
      return goTo({
        pathname: `auth/invitation/${tokenQuery}`
      });
    }

    return null;
  };

  useEffect(() => {
    socket.on(EMPLOYEE_DEPARTMENT_CHANGED, () => {
      fetchAllWorkspaces();
      checkIsJoining();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    parseRegisterLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    changeDocumentTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactChatHasNewMessages]);

  useWorkspacesStorage();

  return (
    <Fragment>
      {children}

      <ErrorModal visible={hasErrorUser || hasErrorWorkspace} />

      <JoiningMessage
        visible={joining.value}
        createdAt={joining.createdAt}
        assignee={joining.assignee}
      />
    </Fragment>
  );
};

export default UserDataProvider;
