import React from 'react';

export const MeetIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1876 0C12.9622 0 13.6248 0.275074 14.1747 0.825119C14.7247 1.37516 14.9998 2.03778 14.9998 2.81248V12.1874C14.9998 12.9621 14.7247 13.6246 14.1746 14.1748C13.6247 14.7249 12.9621 15 12.1874 15H2.81248C2.03781 15 1.37527 14.7249 0.82512 14.1748C0.275074 13.6246 0 12.9621 0 12.1874V2.81248C0 2.03778 0.275074 1.37527 0.82512 0.825119C1.37527 0.275074 2.03781 0 2.81248 0H12.1876ZM8.50073 3.04102C8.50073 2.48873 8.05302 2.04102 7.50073 2.04102C6.94845 2.04102 6.50073 2.48873 6.50073 3.04102V8.0831C6.50073 8.63538 6.94845 9.0831 7.50073 9.0831H10.2049C10.7572 9.0831 11.2049 8.63538 11.2049 8.0831C11.2049 7.53081 10.7572 7.0831 10.2049 7.0831H8.50073V3.04102Z"
      fill="#F9807D"
    />
  </svg>
);

export default MeetIcon;
