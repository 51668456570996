export const getMembers = ({ assignments = [] }) => {
  if (!assignments.length) {
    return [];
  }

  return assignments.reduce((acc, cur) => {
    const value = {
      value: cur.id,
      label: {
        ...cur,
        assignmentId: cur.id
      }
    };
    acc.push(value);

    return acc;
  }, []);
};

export default getMembers;
