import React from 'react';

export const AuditIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.21443 4.97712C6.09657 4.97712 6.00014 5.07355 6.00014 5.19141V6.47712C6.00014 6.59498 6.09657 6.69141 6.21443 6.69141H16.5001C16.618 6.69141 16.7144 6.59498 16.7144 6.47712V5.19141C16.7144 5.07355 16.618 4.97712 16.5001 4.97712H6.21443ZM11.143 8.83426H6.21443C6.09657 8.83426 6.00014 8.93069 6.00014 9.04855V10.3343C6.00014 10.4521 6.09657 10.5485 6.21443 10.5485H11.143C11.2609 10.5485 11.3573 10.4521 11.3573 10.3343V9.04855C11.3573 8.93069 11.2609 8.83426 11.143 8.83426ZM9.85728 21.1021H3.85728V2.24498H18.8573V10.8164C18.8573 10.9343 18.9537 11.0307 19.0716 11.0307H20.5716C20.6894 11.0307 20.7859 10.9343 20.7859 10.8164V1.17355C20.7859 0.699442 20.4028 0.316406 19.9287 0.316406H2.78585C2.31175 0.316406 1.92871 0.699442 1.92871 1.17355V22.1736C1.92871 22.6477 2.31175 23.0307 2.78585 23.0307H9.85728C9.97514 23.0307 10.0716 22.9343 10.0716 22.8164V21.3164C10.0716 21.1986 9.97514 21.1021 9.85728 21.1021ZM21.643 18.745H17.7859V17.7646C19.026 17.395 19.9287 16.2486 19.9287 14.8878C19.9287 13.2298 18.5867 11.8878 16.9287 11.8878C15.2707 11.8878 13.9287 13.2298 13.9287 14.8878C13.9287 16.2459 14.8314 17.395 16.0716 17.7646V18.745H12.2144C11.9787 18.745 11.7859 18.9378 11.7859 19.1735V23.245C11.7859 23.4807 11.9787 23.6736 12.2144 23.6736H21.643C21.8787 23.6736 22.0716 23.4807 22.0716 23.245V19.1735C22.0716 18.9378 21.8787 18.745 21.643 18.745ZM15.5894 14.8878C15.5894 14.1486 16.1894 13.5486 16.9287 13.5486C17.668 13.5486 18.268 14.1486 18.268 14.8878C18.268 15.6271 17.668 16.2271 16.9287 16.2271C16.1894 16.2271 15.5894 15.6271 15.5894 14.8878ZM20.4109 22.0128H13.4466V20.4057H20.4109V22.0128Z"
      fill="currentColor"
    />
  </svg>
);

export default AuditIcon;
