import axios from 'axios';
import { LANGUAGE } from 'i18n-config';

import { config } from 'config';

export const WEBSITE_FORM_SERVER_URL = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}/api/website-form`;

const api = axios.create({
  baseURL: WEBSITE_FORM_SERVER_URL
});

api.interceptors.request.use(
  configuration => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

export default () => ({
  sendForm: ({ channelUuid, data }) =>
    api.post(`/${channelUuid}/send-form`, data),

  uploadFileWebsiteForm: file =>
    api.post('/files/', file).then(({ data }) => data)
});
