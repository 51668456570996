import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  COMMENT,
  WRITE_FIRST_DRAWER,
  DIALOG_TYPE_COMMENT,
  DIALOG_TYPE_LOG,
  DIALOG_TYPE_MESSAGE,
  EMAIL,
  ONLINE_CHAT,
  TELEGRAM,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_DISCUSSION,
  TYPE_TASK,
  WEBSITE_FORM,
  WHATSAPP,
  AI_ASSISTANT_MENTION_REGEX
} from 'constants/index';

import { getLastMessage } from 'components/contacts-view/utils';
import {
  convertChatToOption,
  getIsCustomKindByValue,
  getToChatOptionByRoomUuid
} from 'components/common/new-editor/components/to-chat-select/utils';
import { MENTION_REGEX } from 'components/common/markdown/helpers';

import { editContact, restoreContact } from 'store/contacts';
import { getUserEmployee } from 'store/workspace';
import { editContactCompanies } from 'store/contacts-companies';
import { setVisibleDrawer } from 'store/drawers';
import { getEmailChannels } from 'store/channels';

import { useWebsocketOperatorContext } from 'providers';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

const emptyEditorValue = {
  description: ''
};

const INTERNAL_CHAT_ENTITY_TYPES = [TYPE_ASSET, TYPE_TASK, TYPE_DISCUSSION];

const filterChatToOption = ({
  chat,
  parentMessage,
  isOnlineContact,
  entityType
}) => {
  if (INTERNAL_CHAT_ENTITY_TYPES.includes(entityType)) {
    return true;
  }

  if (parentMessage) {
    return (
      (!isOnlineContact ? chat.channelKind !== ONLINE_CHAT : true) &&
      parentMessage.kind !== COMMENT &&
      parentMessage.roomUuid === chat.uuid &&
      chat.channelIsActive &&
      chat.channelKind !== WEBSITE_FORM
    );
  }

  return (
    (!isOnlineContact ? chat.channelKind !== ONLINE_CHAT : true) &&
    chat.channelIsActive &&
    chat.channelKind !== WEBSITE_FORM
  );
};

const findMentions = content => MENTION_REGEX.test(content.description);
const findAiAssistantMention = content =>
  AI_ASSISTANT_MENTION_REGEX.test(content.description);

const getMentionIds = (content, mentionedEmployees) => {
  const mentionedEmployeeMarkdown = content.description.match(
    new RegExp(MENTION_REGEX, 'g')
  );

  return (mentionedEmployeeMarkdown || []).reduce((acc, curr) => {
    const id = curr.split('(')[1].slice(0, -1);

    const employee = mentionedEmployees.find(e => e.id === +id);

    if (employee) {
      acc.push(employee.entity.userId);
    }

    return acc;
  }, []);
};

const sendCustomMessage = async (customSendMessage, data) => {
  if (customSendMessage) {
    const customResponse = await customSendMessage(data);
    return !!customResponse;
  }
  return false;
};

const useControls = ({
  messages,
  entity,
  isMessagesLoading,
  destination,
  parent,
  setParent = () => {},
  entityType,
  scrollToBottom,
  sendMessageCallback,
  customSendMessage
}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(emptyEditorValue);
  const [hasMention, setHasMention] = useState(false);
  const [hasAiAssistantMention, setHasAiAssistantMention] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [subject, setSubject] = useState('');
  const [toChat, setToChat] = useState({
    label: {},
    value: null
  });
  const [isLoadingSettedResponsible, setIsLoadingSettedResponsible] = useState(
    false
  );
  const [visibleCopyEmail, setVisibleCopyEmail] = useState(false);
  const [copyEmails, setCopyEmails] = useState(undefined);
  const [isPrivate, setIsPrivate] = useState(false);
  const [mentionedEmployees, setMentionedEmployees] = useState([]);

  const { t } = useTranslation('CommonChat');

  const { subscribeToNewAttachments } = useManageSubscribers();
  const { handleChangeValidityDateForNewAttachments } = useValidityDate();

  const transformedCopyEmails = (copyEmails || []).map(
    ({ value: email, label }) => ({
      fullName: `${label.lastName || ''} ${label.firstName}`,
      email
    })
  );

  const employee = useSelector(getUserEmployee);
  const emailChannels = useSelector(getEmailChannels);

  const {
    chats = [],
    id: entityId,
    responsible = {},
    permissions = {},
    employee: isOnlineContact,
    company = {}
  } = entity;
  const { canSendMessage = true } = permissions;

  const isEntityTypeDiscussion = entityType === TYPE_DISCUSSION;

  const isUploadingFiles = useUploadingFiles(fileList);

  const hasValue = useMemo(() => value.description || !!fileList.length, [
    fileList.length,
    value
  ]);

  const allowOnlyComment = INTERNAL_CHAT_ENTITY_TYPES.includes(entityType)
    ? false
    : hasMention || !canSendMessage;
  const allowSendPrivate = entityType === TYPE_TASK || isEntityTypeDiscussion;
  const allowOnlyPrivate =
    (!!parent && parent.isPrivate) || hasAiAssistantMention;
  const allowToSendFirstMessage = !!emailChannels.find(
    ({ isActive }) => isActive
  );
  const allowCreateFile = INTERNAL_CHAT_ENTITY_TYPES.filter(
    type => type !== TYPE_DISCUSSION
  ).includes(entityType);

  const onlineChat = chats.find(c => c.channelKind === ONLINE_CHAT);
  const hasOnlyOnlineChat =
    chats.length === 1 && chats.find(c => c.channelKind === ONLINE_CHAT);

  const socket = useWebsocketOperatorContext();

  const lastMessage =
    onlineChat && !getLastMessage(messages)
      ? { roomUuid: onlineChat.uuid }
      : getLastMessage(messages);
  const lastMessageWithSubject = [...messages]
    .reverse()
    .find(message => !!message.subject);

  const transformedChats = chats
    .filter(c =>
      filterChatToOption({
        chat: c,
        parentMessage: parent,
        isOnlineContact,
        entityType
      })
    )
    .map(chat => convertChatToOption(chat, chats));
  const commentOption = getToChatOptionByRoomUuid({
    chats,
    customKind: COMMENT
  });

  const toChatOptions = [
    ...transformedChats,
    ...(!INTERNAL_CHAT_ENTITY_TYPES.includes(entityType) &&
    (!parent || parent.kind === COMMENT || parent.kind === DIALOG_TYPE_LOG)
      ? [commentOption]
      : [])
  ].sort((a, b) => a.label.index - b.label.index);

  const onChange = useCallback(
    values => {
      setValue(values);
      setHasMention(findMentions(values));
      setHasAiAssistantMention(findAiAssistantMention(values));
    },
    [setValue]
  );

  const clear = useCallback(() => {
    onChange(emptyEditorValue); // this make value empty and trigger CLEAR_EDITOR_COMMAND
    setCopyEmails(undefined);
    setIsPrivate(false);
    setFileList([]);
    setParent(null);
  }, [onChange, setParent]);

  const sendMessage = useCallback(
    async ({ prompt } = {}) => {
      if (!hasValue) {
        return;
      }

      // roomUuid = customKind || roomUuid
      const { label, value: roomUuid } = toChat;

      const isCustomKind = getIsCustomKindByValue(roomUuid);

      try {
        setIsLoadingSettedResponsible(true);

        if (!responsible && destination.entityType === TYPE_CONTACT) {
          await dispatch(
            editContact({ id: entityId, values: { responsible: employee.id } })
          );
        }

        if (!company.responsible && destination.entityType === TYPE_CONTACT) {
          await dispatch(
            editContactCompanies({
              id: company.id,
              values: { responsible: employee.id }
            })
          );
        }

        const data = {
          entityId: entity.id,
          entityType,
          subject:
            label.channelKind === EMAIL && !isCustomKind ? subject : undefined,
          channelKind: label.channelKind,
          fileList,
          kind: isCustomKind ? DIALOG_TYPE_COMMENT : DIALOG_TYPE_MESSAGE,
          roomUuid: isCustomKind
            ? chats.find(c => c.channelKind === ONLINE_CHAT).uuid
            : roomUuid,
          text: [
            {
              text: prompt
                ? `${value.description.match(AI_ASSISTANT_MENTION_REGEX)[0]} ${
                    prompt.text
                  }`
                : value.description
            }
          ],
          channelUuid: label.channelUuid,
          isFromControls: true,
          destination: {
            entityType: destination.entityType,
            entityId: destination.entityId,
            workspaceId: employee.workspaceId,
            promptId: prompt ? prompt.id : null
          },
          recipients:
            label.channelKind === EMAIL ? transformedCopyEmails : undefined,
          parent,
          isPrivate,
          mentionIds: isPrivate
            ? getMentionIds(value, mentionedEmployees)
            : undefined,
          username:
            label.channelKind === TELEGRAM ||
            label.channelKind === EMAIL ||
            label.channelKind === WHATSAPP
              ? chats.find(c => c.channelUuid === label.channelUuid)[
                  label.channelKind === WHATSAPP ? 'channelSource' : 'source'
                ]
              : undefined
        };

        let isSendMessageEventSent = false;
        let isCustomRequestSentSuccessfully = false;

        if (customSendMessage) {
          const customData = {
            value,
            fileList,
            isPrivate
          };

          isCustomRequestSentSuccessfully = await sendCustomMessage(
            customSendMessage,
            customData
          );
        } else {
          isSendMessageEventSent = socket.sendMessage(data);
        }

        if (isSendMessageEventSent || isCustomRequestSentSuccessfully) {
          subscribeToNewAttachments(fileList);
          handleChangeValidityDateForNewAttachments({ attachments: fileList });

          clear();

          if (sendMessageCallback) {
            sendMessageCallback();
          }

          if (scrollToBottom) {
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          }
        }
      } finally {
        setIsLoadingSettedResponsible(false);
      }
    },
    [
      hasValue,
      toChat,
      responsible,
      destination,
      company.responsible,
      company.id,
      entity.id,
      entityType,
      subject,
      fileList,
      chats,
      value,
      transformedCopyEmails,
      parent,
      isPrivate,
      mentionedEmployees,
      dispatch,
      entityId,
      clear,
      sendMessageCallback,
      scrollToBottom
    ]
  );

  const onRestoreContact = async () => {
    await dispatch(restoreContact({ id: entity.id }));

    showNoticeMessage({ number: NOTICE_NUMBER.contactStatusChanged });
  };

  const openFirstWriteDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: WRITE_FIRST_DRAWER,
        data: { contact: entity }
      })
    );

  useEffect(() => {
    if (allowOnlyComment) {
      setToChat(commentOption);

      if (parent && parent.kind !== COMMENT) {
        setParent(null);
      }
    } else if (!customSendMessage) {
      const message = parent || lastMessage || {};

      let toChatValue = getToChatOptionByRoomUuid({
        roomUuid: message.roomUuid,
        chats,
        customKind:
          !INTERNAL_CHAT_ENTITY_TYPES.includes(entityType) &&
          (message.kind === COMMENT || (!isOnlineContact && hasOnlyOnlineChat))
            ? COMMENT
            : null
      });

      // TODO: temp solution, you need to take data from the last message, not the log
      // It is necessary that online chat is not substituted when it is not in the available selection
      if (!toChatOptions.find(o => o.value === toChatValue.value)) {
        [toChatValue = { label: {}, value: '' }] = toChatOptions;
      }

      setToChat(toChatValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id, allowOnlyComment, parent, entity.chats, customSendMessage]);

  const onClickReply = useCallback(
    ({ message, withRecipients, isEmail }) => {
      if (isEmail) {
        setSubject(message.subject);
      }

      if (withRecipients) {
        setVisibleCopyEmail(true);
        setCopyEmails(
          message.recipients.map(r => ({
            value: r.email,
            label: {
              ...r,
              firstName: r.fullName,
              id: Math.floor(Math.random() * 100 + 1)
            }
          }))
        );
      }

      setParent(message);
    },
    [setParent]
  );

  useEffect(() => {
    if (toChat.label.channelKind === EMAIL && !isMessagesLoading && !subject) {
      setSubject((lastMessageWithSubject || {}).subject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, toChat, isMessagesLoading]);

  useEffect(() => {
    setIsPrivate(allowOnlyPrivate);
  }, [allowOnlyPrivate]);

  return {
    value,
    fileList,
    hasValue,
    toChat,
    toChatOptions,
    subject,
    hasMention,
    isUploadingFiles,
    isLoadingSending: isLoadingSettedResponsible,
    allowOnlyComment,
    onChange,
    setSubject,
    setFileList,
    setValue,
    setToChat,
    sendMessage,
    onRestoreContact,
    openFirstWriteDrawer,
    allowToSendFirstMessage,
    employeeId: employee.id,
    setVisibleCopyEmail,
    visibleCopyEmail,
    setCopyEmails,
    copyEmails,
    entityType,
    allowCreateFile,
    privateData: {
      value: isPrivate,
      onChange: setIsPrivate,
      isDisabled: allowOnlyPrivate,
      allow: allowSendPrivate,
      tooltip: t('PrivateCommentChckbxTip')
    },
    setMentionedEmployees,
    onClickReply,
    hasAiAssistantMention,
    destination
  };
};

export default useControls;
