import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import Typography from 'components/common/typography';
import EmployeeAvatar from 'components/common/avatar/employee';

import { getUserEmployee } from 'store/workspace';

import styles from './event-content.module.scss';

const EventContent = props => {
  const currentEmployee = useSelector(getUserEmployee);

  const { event, view, timeText, size } = props;
  const { dateStart, dateEnd, employee, status } = event.extendedProps;

  const dateText = `${moment(dateStart).format('HH:mm')} - ${moment(
    dateEnd
  ).format('HH:mm')}`;

  const isMyEvent = employee.id === currentEmployee.id;

  return (
    <div
      className={classnames(
        styles.root,
        styles[status],
        styles[view.type],
        styles[size],
        {
          [styles.myEvent]: isMyEvent
        }
      )}
    >
      <div className={styles.info}>
        <Typography.Text size="small" color="black-45">
          {view.type === 'dayGridMonth' ? timeText : dateText}
        </Typography.Text>

        <Typography.Text
          size="small"
          ellipsis
          weight="semibold"
          className={styles.title}
        >
          {event.title}
        </Typography.Text>
      </div>

      <EmployeeAvatar employee={employee} size={20} isLink={false} />
    </div>
  );
};

export default EventContent;
