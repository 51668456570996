import { useDispatch } from 'react-redux';

import { TASK_CREATOR_DRAWER } from 'constants/index';

import { setVisibleDrawer } from 'store/drawers';
import { fetchDiscussionMembers } from 'store/discussions';

import { config } from 'config';

export const useDiscussionCreateTask = ({ discussionId, sender, fileList }) => {
  const dispatch = useDispatch();

  const showTaskCreator = async () => {
    const { results } = await dispatch(
      fetchDiscussionMembers({
        id: discussionId
      })
    );

    const members = results.filter(
      member => member.userId !== config.REACT_APP_AI_ASSISTANT_USER_ID
    );

    dispatch(
      setVisibleDrawer({
        drawer: TASK_CREATOR_DRAWER,
        data: {
          taskInfo: undefined,
          dateEnd: undefined,
          description: [{ text: `${sender.fullName}` }],
          fileList,
          relations: null,
          discussion: discussionId,
          members,
          assignments: members
        }
      })
    );
  };

  return showTaskCreator;
};

export default useDiscussionCreateTask;
