import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  TASK_FIELD_PROJECT,
  TASK_FIELD_SPRINT,
  TYPE_TASK
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';

import { FieldEditorItem } from 'providers/field-editor';
import useRoutesService from 'services/routes';

import styles from './project-info.module.scss';

const ProjectInfo = ({ task }) => {
  const { t } = useTranslation(['Task', 'Tasks']);

  const routesUrl = useRoutesService({ returnUrl: true });

  const { project, sprint } = task;

  const hideSprintItem =
    (!(project || {}).sprintsCount && !sprint) ||
    !(task.kind || '').includes(TYPE_TASK);

  return (
    <>
      <div className={styles.root}>
        <FieldEditorItem
          field={TASK_FIELD_PROJECT}
          className={styles.item}
          defaultValues={{
            [TASK_FIELD_PROJECT]: project
              ? {
                  value: task.project.id,
                  label: task.project
                }
              : undefined
          }}
        >
          <DetailsField
            iconType="project"
            title={t('Project')}
            className={styles.detailsField}
          >
            <Tooltip
              title={project ? project.title : undefined}
              mouseEnterDelay={0.5}
            >
              <Typography.Text ellipsis>
                {project
                  ? project.title
                  : t('WithoutAProject', { ns: 'Tasks' })}
              </Typography.Text>
            </Tooltip>
          </DetailsField>
        </FieldEditorItem>

        {!hideSprintItem && (
          <div className={styles.item}>
            <FieldEditorItem
              field={TASK_FIELD_SPRINT}
              className={styles.item}
              defaultValues={{
                [TASK_FIELD_SPRINT]: sprint
                  ? {
                      value: task.sprint.id,
                      label: task.sprint
                    }
                  : undefined
              }}
            >
              <DetailsField
                iconType="sprint"
                title={t('Sprint')}
                className={styles.detailsField}
              >
                <Tooltip
                  title={sprint ? sprint.title : undefined}
                  mouseEnterDelay={0.5}
                >
                  <Typography.Text ellipsis>
                    {sprint ? (
                      <a
                        href={routesUrl.toSprint({
                          projectId: project.id,
                          sprintId: sprint.id
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sprint.title}
                      </a>
                    ) : (
                      t('WithoutASprint', { ns: 'Tasks' })
                    )}
                  </Typography.Text>
                </Tooltip>
              </DetailsField>
            </FieldEditorItem>
          </div>
        )}
      </div>
    </>
  );
};

ProjectInfo.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    project: PropTypes.object,
    sprint: PropTypes.object
  })
};

ProjectInfo.defaultProps = {
  task: {}
};

export default ProjectInfo;
