import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

export const getTasks = state => state.tasks;

export const getTaskEntries = createSelector(getTasks, tasks => tasks.entries);

export const getIsTasksLoading = createSelector(
  getTasks,
  tasks => tasks.isLoading
);

export const getIsTasksLoaded = createSelector(
  getTasks,
  tasks => tasks.isLoaded
);

export const getItemsPerPage = createSelector(
  getTasks,
  tasks => tasks.itemsPerPage
);

export const getTotalItems = createSelector(
  getTasks,
  tasks => tasks.totalItems
);

export const getCurrentPage = createSelector(
  getTasks,
  tasks => tasks.currentPage
);

export const getSelectedTasks = createSelector(
  getTasks,
  tasks => tasks.selectedTasks
);

export const getTasksHasMore = createSelector(getTasks, tasks => tasks.hasMore);

export const getTasksHasMoreWorklog = createSelector(
  getTasks,
  tasks => tasks.hasMoreWorklogList
);

export const getTaskFactory = createSelector(getTaskEntries, entries =>
  memoize(taskId => entries.find(task => task.id === taskId))
);

export const getError = createSelector(getTasks, tasks => tasks.error);

export const getHasError = createSelector(getError, error => error !== null);

export const getAllTasksLength = createSelector(
  getTaskEntries,
  entries => entries.length
);

export const getIsBacklog = createSelector(getTasks, tasks => tasks.isBacklog);

export const getTaskViewType = createSelector(
  getTasks,
  tasks => tasks.viewType
);

// --filters-- //
export const getFilterTasks = createSelector(getTasks, tasks => tasks.filter);

export const getProjectFilterTasks = createSelector(
  getTasks,
  tasks => tasks.projectFilter
);

export const getBacklogFilterTasks = createSelector(
  getTasks,
  tasks => tasks.backlogFilter
);

export const getFilterSearchTasks = createSelector(
  getFilterTasks,
  filter => filter.search
);

export const getFilterFavoriteTasks = createSelector(
  getFilterTasks,
  filter => filter.isFavorite
);

export const getFilterTypeTasks = createSelector(
  getFilterTasks,
  filter => filter.type
);

export const getFilterStatusTasks = createSelector(
  getFilterTasks,
  filter => filter.status || []
);

export const getFilterSprintTasks = createSelector(
  getFilterTasks,
  filter => filter.sprint
);

export const getFilterOrderingTasks = createSelector(
  getFilterTasks,
  filter => filter.ordering
);

export const getFilterAuthorTasks = createSelector(
  getFilterTasks,
  filter => filter.authors || []
);

export const getFilterResponsibleTasks = createSelector(
  getFilterTasks,
  filter => filter.responsibles || []
);

export const getFilterControllerTasks = createSelector(
  getFilterTasks,
  filter => filter.controllers || []
);

export const getFilterCreatedDateRangeTasks = createSelector(
  getFilterTasks,
  filter => filter.createdDateRange
);

export const getFilterStartedDateRangeTasks = createSelector(
  getFilterTasks,
  filter => filter.startedDateRange
);

export const getFilterEndedDateRangeTasks = createSelector(
  getFilterTasks,
  filter => filter.endedDateRange
);

export const getFilterCompletedDateRangeTasks = createSelector(
  getFilterTasks,
  filter => filter.completedDateRange
);

export const getFilterProjectTasks = createSelector(
  getFilterTasks,
  filter => filter.project
);

export const getFilterTagsTasks = createSelector(
  getFilterTasks,
  filter => filter.tags || []
);

export const getFilterOrdersTasks = createSelector(
  getFilterTasks,
  filter => filter.orders
);

export const getFilterContactsTasks = createSelector(
  getFilterTasks,
  filter => filter.contacts || []
);

export const getFilterSubordinatesTasks = createSelector(
  getFilterTasks,
  filter => filter.subordinates
);

export const getFilterExpired = createSelector(
  getFilterTasks,
  filter => filter.expired
);

export const getFilterSprintIsNull = createSelector(
  getFilterTasks,
  filter => filter.sprintIsNull
);

export const getBacklogFilterSprintIsNull = createSelector(
  getBacklogFilterTasks,
  filter => filter.sprintIsNull
);

export const getBacklogFilterProject = createSelector(
  getBacklogFilterTasks,
  filter => filter.project
);

export const getBacklogFilterFavoriteTasks = createSelector(
  getBacklogFilterTasks,
  filter => filter.isFavorite
);

export const getTasksPageData = createSelector(
  getTasks,
  tasks => tasks.pageData
);

export const getTasksIsAllLoading = createSelector(
  getTasks,
  tasks => tasks.isAllLoading
);

export const getSprintTasksFilter = createSelector(
  getTasks,
  tasks => tasks.sprintFilter
);

export const getSprintTasks = createSelector(
  getTasks,
  tasks => tasks.sprintTasks
);

export const getSprintTasksEntries = createSelector(
  getSprintTasks,
  sprintTasks => id => (sprintTasks[id] ? sprintTasks[id].entries : [])
);

export const getSprintTasksHasMore = createSelector(
  getSprintTasks,
  sprintTasks => id => (sprintTasks[id] ? sprintTasks[id].hasMore : true)
);
