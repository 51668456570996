import React from 'react';

export const BubbleChartIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50005 9.2998C7.86715 9.2998 7.24846 9.48748 6.72223 9.8391C6.19599 10.1907 5.78584 10.6905 5.54364 11.2752C5.30144 11.8599 5.23806 12.5034 5.36154 13.1241C5.48501 13.7448 5.78978 14.315 6.23731 14.7625C6.68484 15.2101 7.25502 15.5148 7.87576 15.6383C8.4965 15.7618 9.13991 15.6984 9.72464 15.4562C10.3094 15.214 10.8091 14.8039 11.1608 14.2776C11.5124 13.7514 11.7001 13.1327 11.7001 12.4998C11.7001 11.6511 11.3629 10.8372 10.7628 10.2371C10.1627 9.63695 9.34874 9.2998 8.50005 9.2998ZM8.50005 11.1998C8.75717 11.1998 9.00851 11.276 9.22229 11.4189C9.43608 11.5617 9.6027 11.7648 9.70109 12.0023C9.79949 12.2399 9.82523 12.5012 9.77507 12.7534C9.72491 13.0056 9.6011 13.2372 9.41929 13.419C9.23748 13.6009 9.00584 13.7247 8.75367 13.7748C8.50149 13.825 8.24011 13.7992 8.00256 13.7008C7.76502 13.6025 7.56199 13.4358 7.41914 13.222C7.27629 13.0083 7.20005 12.7569 7.20005 12.4998C7.20005 12.155 7.33701 11.8244 7.58081 11.5806C7.82461 11.3368 8.15527 11.1998 8.50005 11.1998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7501 1.7998C15.8205 1.7998 14.9118 2.07545 14.1389 2.5919C13.366 3.10834 12.7635 3.84238 12.4078 4.70119C12.0521 5.56001 11.959 6.50502 12.1404 7.41673C12.3217 8.32844 12.7693 9.1659 13.4266 9.82321C14.084 10.4805 14.9214 10.9281 15.8331 11.1095C16.7448 11.2908 17.6898 11.1978 18.5487 10.842C19.4075 10.4863 20.1415 9.8839 20.658 9.11098C21.1744 8.33807 21.4501 7.42938 21.4501 6.4998C21.4501 5.25329 20.9549 4.05782 20.0735 3.1764C19.192 2.29498 17.9966 1.7998 16.7501 1.7998ZM16.7501 3.6998C17.3038 3.6998 17.8452 3.86402 18.3056 4.17169C18.7661 4.47936 19.125 4.91666 19.3369 5.42829C19.5488 5.93992 19.6043 6.50291 19.4962 7.04606C19.3882 7.5892 19.1215 8.08812 18.7299 8.4797C18.3384 8.87129 17.8394 9.13796 17.2963 9.246C16.7532 9.35404 16.1902 9.29859 15.6785 9.08667C15.1669 8.87474 14.7296 8.51586 14.4219 8.0554C14.1143 7.59494 13.9501 7.05359 13.9501 6.4998C13.9501 5.7572 14.2451 5.04501 14.7702 4.51991C15.2953 3.9948 16.0074 3.6998 16.7501 3.6998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3751 13.0498C18.8163 13.0498 18.2701 13.2155 17.8056 13.5259C17.341 13.8363 16.9789 14.2775 16.7651 14.7937C16.5513 15.3099 16.4953 15.8779 16.6043 16.4259C16.7133 16.9739 16.9824 17.4773 17.3775 17.8724C17.7726 18.2675 18.2759 18.5365 18.8239 18.6455C19.3719 18.7545 19.9399 18.6986 20.4561 18.4848C20.9723 18.2709 21.4135 17.9089 21.724 17.4443C22.0344 16.9797 22.2001 16.4335 22.2001 15.8748C22.2001 15.1256 21.9024 14.407 21.3726 13.8772C20.8428 13.3474 20.1243 13.0498 19.3751 13.0498ZM19.3751 14.9498C19.558 14.9498 19.7368 15.0041 19.889 15.1057C20.0411 15.2073 20.1596 15.3518 20.2296 15.5208C20.2996 15.6898 20.318 15.8758 20.2823 16.0553C20.2466 16.2347 20.1585 16.3995 20.0291 16.5289C19.8998 16.6582 19.7349 16.7463 19.5555 16.782C19.3761 16.8177 19.1901 16.7994 19.0211 16.7294C18.852 16.6594 18.7076 16.5408 18.6059 16.3887C18.5043 16.2366 18.4501 16.0578 18.4501 15.8748C18.4501 15.6295 18.5475 15.3942 18.721 15.2207C18.8944 15.0473 19.1297 14.9498 19.3751 14.9498Z"
      fill="currentColor"
    />
    <path
      d="M22.071 19.5008H3.64244V2.78655C3.64244 2.66869 3.54601 2.57227 3.42815 2.57227H1.92815C1.8103 2.57227 1.71387 2.66869 1.71387 2.78655V21.2151C1.71387 21.333 1.8103 21.4294 1.92815 21.4294H22.071C22.1889 21.4294 22.2853 21.333 22.2853 21.2151V19.7151C22.2853 19.5973 22.1889 19.5008 22.071 19.5008Z"
      fill="currentColor"
    />
  </svg>
);

export default BubbleChartIcon;
