import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Attachment from 'components/common/attachments/attachment';
import Upload from 'components/common/attachments-control/shared/upload';
import Attachments from 'components/common/attachments';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import { getUILanguage } from 'store/ui';

import downloadFile from 'hooks/common/use-file-upload/download-file';

import {
  base64ToXlsx,
  beforeUpload,
  CONTACT_IMPORT_TEMPLATE_BASE64_BY_LANGUAGE,
  CONTACT_IMPORT_TEMPLATE_DOWNLOAD_LINK_BY_LANGUAGE,
  handleFileUpload
} from '../../utils';

import styles from '../modals.module.scss';

const { Paragraph, Text } = Typography;

const FileUploadImportContacts = ({
  file,
  setFile,
  setColumns,
  setDataSource,
  handleOnSubmit
}) => {
  const language = useSelector(getUILanguage);

  const { t } = useTranslation('ContactImport');

  const templateName = t('TemplateFile');

  const actionsTemplate = [
    {
      key: 'download',
      title: 'Download',
      ns: 'Common',
      onClick: () =>
        downloadFile({
          url: CONTACT_IMPORT_TEMPLATE_DOWNLOAD_LINK_BY_LANGUAGE[language],
          fileName: `${templateName}.xlsx`
        }),
      allow: true
    }
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <Paragraph weight="semibold" size="large">
          {t('Step1DownloadTemplateHeading')}
        </Paragraph>

        <a
          download={templateName}
          href={CONTACT_IMPORT_TEMPLATE_DOWNLOAD_LINK_BY_LANGUAGE[language]}
        >
          <Attachment
            file={base64ToXlsx({
              base64Xlsx: CONTACT_IMPORT_TEMPLATE_BASE64_BY_LANGUAGE[language],
              fileName: `${templateName}.xlsx`
            })}
            actions={actionsTemplate}
          />
        </a>
      </div>

      <div className={styles.wrapper}>
        <Paragraph weight="semibold" size="large">
          {t('Step2UploadFileHeading')}
        </Paragraph>

        <Text>{t('Step2UploadFileDesc')}</Text>

        {!file.length && (
          <Upload
            showAttachments
            accept=".csv, .xlsx, .xls"
            beforeUpload={beforeUpload}
            onChange={f =>
              handleFileUpload({
                file: f,
                setFile,
                setColumns,
                setDataSource
              })
            }
            multiple={false}
          />
        )}

        {file.length ? (
          <Attachments
            fileList={file}
            attachmentProps={{
              onDelete: f => {
                setFile((_fileList = []) =>
                  _fileList.filter(({ id }) => id !== f.id)
                );
              },
              onRename: ({ id, name }) => {
                const newFileList = file.reduce((acc, curr) => {
                  if (curr.response && curr.response.id === id) {
                    return [...acc, { ...curr, name }];
                  }

                  return [...acc, curr];
                }, []);

                setFile(newFileList);
              }
            }}
          />
        ) : null}
      </div>

      <Button
        type="primary"
        size="large"
        onClick={handleOnSubmit}
        className={styles.btnSubmit}
        disabled={!file.length || !file[0].response || !file[0].response.id}
      >
        {t('ContinueBtn')}
      </Button>
    </>
  );
};

FileUploadImportContacts.propTypes = {
  file: PropTypes.array,
  setFile: PropTypes.func,
  setColumns: PropTypes.func,
  setDataSource: PropTypes.func,
  handleOnSubmit: PropTypes.func
};

FileUploadImportContacts.defaultProps = {
  file: [],
  setFile: () => {},
  setColumns: () => {},
  setDataSource: () => {},
  handleOnSubmit: () => {}
};

export default FileUploadImportContacts;
