import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TYPE_FILE } from 'constants/index';

import SubscribersDrawer from 'components/common/subscriptions/subscribers-drawer';

import { changeManageSubscribers } from 'store/subscriptions';

const AttachmentSubscribersDrawer = ({ data, visible, onClose }) => {
  const { t } = useTranslation('FileSubscribers');
  const dispatch = useDispatch();

  const { file, changeManageSubscribersCallback, actionDeps } = data;

  const onSubmit = ({ added, deleted }) => {
    dispatch(
      changeManageSubscribers({
        entityId: file.fileId,
        entityType: TYPE_FILE,
        added,
        deleted,
        actionDeps
      })
    ).then(res => {
      changeManageSubscribersCallback(res);
    });
  };

  return (
    <SubscribersDrawer
      canManageSubscribers={file.canManageSubscribers}
      id={file.fileId}
      visible={visible}
      onClose={onClose}
      submit={onSubmit}
      title={t('FileSubscribersHeading')}
      description={t('FileSubscribersDesc')}
    />
  );
};

AttachmentSubscribersDrawer.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.shape({
      canManageSubscribers: PropTypes.bool,
      canSubscribe: PropTypes.bool,
      createdAt: PropTypes.string,
      creator: PropTypes.shape({
        id: PropTypes.number,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        avatarFile: PropTypes.object
      }),
      entities: PropTypes.arrayOf(PropTypes.object),
      fileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      fileSize: PropTypes.number,
      highlight: PropTypes.any,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isSubscribed: PropTypes.bool,
      isSystem: PropTypes.bool,
      isTrash: PropTypes.bool,
      mimeType: PropTypes.string,
      permissions: PropTypes.object,
      title: PropTypes.string,
      updatedAt: PropTypes.string,
      workspace: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }).isRequired,
    changeManageSubscribersCallback: PropTypes.func,
    actionDeps: PropTypes.shape({
      entityId: PropTypes.number,
      entityType: PropTypes.string,
      messageUuid: PropTypes.string
    })
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

AttachmentSubscribersDrawer.defaultProps = {
  data: {
    file: {},
    changeManageSubscribersCallback: () => {},
    actionDeps: {}
  }
};

export default AttachmentSubscribersDrawer;
