import React from 'react';

export const EmptyPurchasesIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
  >
    <path
      d="M49.0225 128.612C21.595 120.26 -2.89884 87.6183 4.18542 64.1237C5.5792 59.5205 8.09245 55.3347 11.5003 51.9408C42.6172 20.8623 127.949 9.05093 131.753 26.1403C134.162 36.9908 131.202 43.1015 133.611 48.546C136.48 55.0281 142.156 53.9008 145.038 58.013C153.365 69.9141 135.033 106.437 105.184 122.156C100.252 124.718 81.4717 133.916 58.1821 130.559C55.0818 130.156 52.0188 129.505 49.0225 128.612Z"
      fill="#EBF8F8"
    />
    <path
      d="M113.383 73.2068H29.7938L43.9367 44.5879H99.2529L113.383 73.2068Z"
      fill="#FDA402"
    />
    <path
      d="M99.2656 44.5879H43.9366V83.6986H99.2656V44.5879Z"
      fill="#ED9406"
    />
    <path
      d="M115.266 84.915V127.51C115.266 129.638 114.422 131.679 112.918 133.185C111.414 134.691 109.375 135.539 107.247 135.542H35.9429C33.9815 135.541 32.0889 134.82 30.6229 133.517C29.157 132.214 28.2194 130.419 27.9875 128.471C27.9617 128.156 27.9617 127.839 27.9875 127.523V84.915H115.266Z"
      fill="#ED9406"
    />
    <path
      d="M107.336 84.915V120.4C107.341 121.458 107.137 122.507 106.736 123.486C106.335 124.465 105.744 125.355 104.997 126.105C104.251 126.855 103.364 127.45 102.387 127.856C101.41 128.262 100.362 128.471 99.3041 128.471H27.9875C27.9617 128.156 27.9617 127.839 27.9875 127.523V84.915H107.336Z"
      fill="#FDA402"
    />
    <path
      d="M115.612 73.2324H27.5775C25.6672 73.2324 24.1187 74.781 24.1187 76.6913V83.8908C24.1187 85.8011 25.6672 87.3497 27.5775 87.3497H115.612C117.522 87.3497 119.071 85.8011 119.071 83.8908V76.6913C119.071 74.781 117.522 73.2324 115.612 73.2324Z"
      fill="#FCC977"
    />
    <path
      d="M83.4446 115.238H59.271C57.8203 115.238 56.4289 114.662 55.4031 113.636C54.3772 112.61 53.8009 111.219 53.8009 109.768C53.8009 108.317 54.3772 106.926 55.4031 105.9C56.4289 104.874 57.8203 104.298 59.271 104.298H83.4446C84.8954 104.298 86.2867 104.874 87.3126 105.9C88.3384 106.926 88.9147 108.317 88.9147 109.768C88.9147 111.219 88.3384 112.61 87.3126 113.636C86.2867 114.662 84.8954 115.238 83.4446 115.238Z"
      fill="#2B2A36"
    />
    <path
      d="M107.2 61.0857H36.5714C33.6572 61.0857 31.4286 58.8571 31.4286 55.9428V45.1429C31.4286 42.2286 33.6572 40 36.5714 40H107.2C110.114 40 112.343 42.2286 112.343 45.1429V55.9428C112.343 58.6857 110.114 61.0857 107.2 61.0857Z"
      fill="#CEF4F4"
    />
    <path
      d="M43.0857 55.6002C45.8286 55.6002 48.2286 53.3716 48.2286 50.4573C48.2286 47.543 46 45.3145 43.0857 45.3145C40.3429 45.3145 37.9429 47.543 37.9429 50.4573C37.9429 53.3716 40.3429 55.6002 43.0857 55.6002Z"
      fill="#2D9EA3"
    />
    <path
      d="M79.0857 56.4579H55.2571C54.2286 56.4579 53.5428 55.7721 53.5428 54.7436C53.5428 53.715 54.2286 53.0293 55.2571 53.0293H79.0857C80.1143 53.0293 80.8 53.715 80.8 54.7436C80.8 55.7721 79.9429 56.4579 79.0857 56.4579Z"
      fill="#2D9EA3"
    />
    <path
      d="M102.4 48.0575H55.0857C54.0572 48.0575 53.3715 47.3718 53.3715 46.3432C53.3715 45.3146 54.0572 44.6289 55.0857 44.6289H102.229C103.257 44.6289 103.943 45.3146 103.943 46.3432C103.943 47.3718 103.257 48.0575 102.4 48.0575Z"
      fill="#2D9EA3"
    />
    <path
      d="M97.6 56.4579H88.6857C87.6572 56.4579 86.9714 55.7721 86.9714 54.7436C86.9714 53.715 87.6572 53.0293 88.6857 53.0293H97.6C98.6286 53.0293 99.3143 53.715 99.3143 54.7436C99.3143 55.7721 98.4572 56.4579 97.6 56.4579Z"
      fill="#2D9EA3"
    />
    <path
      d="M113.783 32.4454H29.0286C25.5315 32.4454 22.8572 29.7711 22.8572 26.274V13.314C22.8572 9.81686 25.5315 7.14258 29.0286 7.14258H113.783C117.28 7.14258 119.954 9.81686 119.954 13.314V26.274C119.954 29.5654 117.074 32.4454 113.783 32.4454Z"
      fill="#CEF4F4"
    />
    <path
      d="M36.8457 25.8624C40.1372 25.8624 43.0172 23.1881 43.0172 19.691C43.0172 16.1938 40.3429 13.5195 36.8457 13.5195C33.5543 13.5195 30.6743 16.1938 30.6743 19.691C30.6743 23.1881 33.3486 25.8624 36.8457 25.8624Z"
      fill="#2D9EA3"
    />
    <path
      d="M80.0458 26.8916H51.4516C50.2173 26.8916 49.3944 26.0688 49.3944 24.8345C49.3944 23.6002 50.2173 22.7773 51.4516 22.7773H80.0458C81.2801 22.7773 82.103 23.6002 82.103 24.8345C82.103 26.0688 81.0744 26.8916 80.0458 26.8916Z"
      fill="#2D9EA3"
    />
    <path
      d="M108.023 16.8115H51.2459C50.0116 16.8115 49.1887 15.9887 49.1887 14.7544C49.1887 13.5201 50.0116 12.6973 51.2459 12.6973H107.817C109.052 12.6973 109.874 13.5201 109.874 14.7544C109.874 15.9887 109.052 16.8115 108.023 16.8115Z"
      fill="#2D9EA3"
    />
    <path
      d="M102.057 26.8916H91.3601C90.1258 26.8916 89.303 26.0688 89.303 24.8345C89.303 23.6002 90.1258 22.7773 91.3601 22.7773H102.057C103.292 22.7773 104.114 23.6002 104.114 24.8345C104.114 26.0688 103.292 26.8916 102.057 26.8916Z"
      fill="#2D9EA3"
    />
  </svg>
);

export default EmptyPurchasesIcon;
