import {
  TYPE_ORDER,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_CHANNEL_CHAT,
  TYPE_ATTACHMENT,
  TYPE_PROJECT,
  TYPE_TASK_TEMPLATE,
  TYPE_ORDER_TEMPLATE,
  TYPE_ORDER_STATUS
} from 'constants/index';

import { getTagLink } from 'components/common/tags/utils/tag-link';

import handleActions from 'utils/redux-actions';

import {
  addTag,
  deleteTag,
  deleteTagFromWorkspace,
  editTag,
  fetchTagsByEntity,
  replaceTag,
  fetchTags,
  setFilterSearch,
  reFetchAllTags,
  bulkDeleteTagsFromWorkspace,
  clearAllTags
} from './actions';

const initialAllState = {
  entries: [],
  total: 0,
  filter: { search: '' },
  isLoading: false,
  reFetchAfterCreateTag: false
};

const initialState = {
  [TYPE_ORDER]: {},
  [TYPE_ORDER_STATUS]: {},
  [TYPE_TASK]: {},
  [TYPE_ASSET]: {},
  [TYPE_CONTACT]: {},
  [TYPE_CHANNEL_CHAT]: {},
  [TYPE_ATTACHMENT]: {},
  [TYPE_PROJECT]: {},
  [TYPE_TASK_TEMPLATE]: {},
  [TYPE_ORDER_TEMPLATE]: {},
  all: initialAllState
};

export default handleActions(
  {
    [fetchTagsByEntity.SUCCEEDED]: (state, { args, payload }) => {
      const transfromedPayload = {};

      Object.keys(payload).forEach(entityId => {
        transfromedPayload[entityId] = (payload[entityId] || []).map(tag => ({
          ...tag,
          entityType: args.entityType,
          entityId,
          link: getTagLink(tag)
        }));
      });

      state[args.entityType] = {
        ...state[args.entityType],
        ...transfromedPayload
      };

      return state;
    },

    [addTag.SUCCEEDED]: (state, { args }) => {
      state[args.tag.entityType][args.tag.entityId] = [
        ...(state[args.tag.entityType][args.tag.entityId] || []),
        { ...args.tag, link: getTagLink(args.tag) }
      ];

      return state;
    },

    [deleteTag.SUCCEEDED]: (state, { args }) => {
      state[args.tag.entityType][args.tag.entityId] = state[
        args.tag.entityType
      ][args.tag.entityId].filter(tag => tag.id !== args.tag.id);

      return state;
    },

    [deleteTagFromWorkspace.SUCCEEDED]: (state, { payload }) => {
      if (payload.entityType) {
        state[payload.entityType][payload.entityId] = state[payload.entityType][
          payload.entityId
        ].filter(tag => tag.id !== payload.id);
      }

      state.all.entries = state.all.entries.filter(
        tag => tag.id !== payload.id
      );

      state.all.total -= 1;

      return state;
    },

    [replaceTag.SUCCEEDED]: (state, { payload }) => {
      const { fromTag, toTag } = payload;

      if (fromTag.entityType) {
        let tags = [...state[fromTag.entityType][fromTag.entityId]];

        if (fromTag.id !== toTag.id) {
          tags = state[fromTag.entityType][fromTag.entityId].filter(
            tag => tag.id !== toTag.id
          );
        }

        const tagReplaceIndex = tags.findIndex(tag => tag.id === fromTag.id);

        tags[tagReplaceIndex] = {
          ...toTag,
          entityType: fromTag.entityType,
          entityId: fromTag.entityId,
          link: getTagLink(toTag)
        };

        state[fromTag.entityType][fromTag.entityId] = [...tags];
      }

      let tags = [...state.all.entries];

      if (fromTag.id !== toTag.id) {
        tags = state.all.entries.filter(tag => tag.id !== toTag.id);
      }

      const tagReplaceIndex = tags.findIndex(tag => tag.id === fromTag.id);

      tags[tagReplaceIndex] = {
        ...toTag,
        link: getTagLink(toTag)
      };

      state.all.entries = [...tags];

      return state;
    },

    [editTag.SUCCEEDED]: (state, { payload }) => {
      const { id, entityType, entityId, ...updatedData } = payload;

      if (entityType) {
        state[entityType][entityId] = state[entityType][entityId].map(tag =>
          tag.id === id ? { ...tag, ...updatedData } : tag
        );
      }

      state.all.entries = state.all.entries.map(tag =>
        tag.id === id ? { ...tag, ...updatedData } : tag
      );

      return state;
    },

    [fetchTags.START]: state => {
      state.all.reFetchAfterCreateTag = false;
      state.all.isLoading = true;

      return state;
    },

    [fetchTags.SUCCEEDED]: (state, { payload }) => {
      const { data, total } = payload;

      state.all.isLoading = false;

      state.all.entries = [
        ...state.all.entries,
        ...data.map(tag => ({
          ...tag,
          link: getTagLink(tag)
        }))
      ];

      state.all.total = total;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.all.filter.search = payload;

      state.all.entries = [];
      state.all.total = 0;
    },

    [reFetchAllTags]: state => {
      state.all = initialAllState;
      state.all.reFetchAfterCreateTag = true;

      return state;
    },

    [bulkDeleteTagsFromWorkspace.SUCCEEDED]: (state, { payload }) => {
      const { tagIds } = payload;

      state.all.entries = state.all.entries.filter(
        tag => !tagIds.includes(tag.id)
      );
      state.all.total -= tagIds.length;

      return state;
    },

    [clearAllTags]: state => {
      state.all = initialAllState;

      return state;
    }
  },
  initialState
);
