import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tree, Checkbox, Spin } from 'antd';
import difference from 'lodash/difference';
import PropTypes from 'prop-types';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import { fetchCategoriesLocal } from 'store/assets';

import { getEmployeesFromTreeData } from '../employee-select/utils';
import { getCategoriesByIds, transformCategoriesData } from './utils';

import styles from '../employee-select/employee-select.module.scss';

const { TreeNode } = Tree;

export const BooksStructureModal = ({
  onSelect,
  onClose,
  visible,
  isMulti,
  defaultSelectedKeys,
  callbackAfterClose,
  ...props
}) => {
  const { t } = useTranslation(['Journals', 'Journal', 'Filters']);

  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState(defaultSelectedKeys);
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
  const [expandedKeys, setExpandedKeys] = useState(defaultSelectedKeys);
  const [categories, setCategories] = useState({});
  const dispatch = useDispatch();

  const fetchCategories = useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await dispatch(fetchCategoriesLocal());

      const transformData = transformCategoriesData(data);

      setTreeData(transformData);
      setCategories(getEmployeesFromTreeData(transformData));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode
            title={
              <span
                style={{
                  color: '#0C0D0D'
                }}
              >
                {item.title}
              </span>
            }
            key={item.id}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={
            <span
              style={{
                color: '#0C0D0D'
              }}
            >
              {item.title}
            </span>
          }
          key={item.id}
        />
      );
    });

  const handleSelect = useCallback(() => {
    const ids = isMulti ? checkedKeys : selectedKeys;
    const selectedEmployees = getCategoriesByIds({ ids, categories });

    onSelect(isMulti ? selectedEmployees : selectedEmployees[0]);
    onClose();
  }, [categories, checkedKeys, isMulti, onClose, onSelect, selectedKeys]);

  const handleCheckAll = useCallback(() => {
    setCheckedKeys(checkedKeys.length ? [] : Object.keys(categories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys.length, categories]);

  const getIsCheckedAll = useCallback(
    () => !difference(Object.keys(categories), checkedKeys).length,
    [checkedKeys, categories]
  );

  const handleClose = () => {
    onClose();
    callbackAfterClose();
  };

  useEffect(() => {
    if (visible && isLoading) {
      fetchCategories();
    }
  }, [visible, isLoading, fetchCategories]);

  useEffect(() => {
    if (visible) {
      setCheckedKeys(defaultSelectedKeys);
    }
  }, [visible, defaultSelectedKeys]);

  return (
    <Modal
      title={t('AllJournalsBreadCrumbs')}
      contentStyle={{ display: 'flex', flexDirection: 'column', padding: 24 }}
      width={508}
      onClose={handleClose}
      contentClassName={styles.modal}
      visible={visible}
      {...props}
    >
      <Spin spinning={isLoading} />

      {!isLoading && (
        <>
          {isMulti && (
            <div style={{ display: 'flex' }}>
              <Checkbox
                style={{ marginRight: 'auto' }}
                indeterminate={checkedKeys.length && !getIsCheckedAll()}
                checked={getIsCheckedAll()}
                onChange={handleCheckAll}
              >
                {t('ChooseAllBtn', { ns: 'Journal' })}
              </Checkbox>
            </div>
          )}

          <DirectoryTree
            defaultExpandedKeys={
              treeData.length ? [treeData[0].id.toString()] : []
            }
            multiple={isMulti}
            checkable={isMulti}
            selectable={!isMulti}
            onCheck={setCheckedKeys}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKeys}
            showIcon={false}
            onSelect={setSelectedKeys}
            onExpand={setExpandedKeys}
            expandedKeys={expandedKeys}
            switcherIcon={<Icon type="arrow" size={16} />}
          >
            {renderTreeNodes(treeData)}
          </DirectoryTree>

          <Button
            type="primary"
            style={{ marginLeft: 'auto', marginTop: 20 }}
            onClick={handleSelect}
          >
            {t('ChooseBtn', { ns: 'Filters' })}
          </Button>
        </>
      )}
    </Modal>
  );
};

BooksStructureModal.propTypes = {
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  callbackAfterClose: PropTypes.func
};

BooksStructureModal.defaultProps = {
  defaultSelectedKeys: [],
  callbackAfterClose: () => {}
};

export default BooksStructureModal;
