import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET } from 'constants/index';

import { useTheme } from 'components/common/theme';
import { AssetDeletedIcon } from 'components/common/icons';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import ActionsDropdown from 'components/common/actions';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import AssetBookingModal from '../booking-modal';

const Actions = ({ asset, entityData, bookingData, onChange }) => {
  const { variables } = useTheme();

  const { t } = useTranslation([
    'Assets',
    'Common',
    'AddCalendarAsset',
    'DeleteAssetConnection'
  ]);

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleBookingModal, setVisibleBookingModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const actions = [
    {
      title: t('BookInCalendarAction'),
      allow: !bookingData && entityData.allowBooking,
      onClick: () => setVisibleBookingModal(true)
    },
    {
      title: t('EditBookingAction'),
      allow: bookingData,
      onClick: () => setVisibleBookingModal(true)
    },
    {
      title: t('DeleteLinkAction'),
      allow: true,
      style: { color: variables.color.red },
      onClick: () => setVisibleDeleteModal(true)
    }
  ];

  const deleteRelation = async () => {
    try {
      setIsDeleteLoading(true);

      await onChange([
        {
          isDelete: true,
          relType: TYPE_ASSET,
          relationIds: [asset.relationId]
        }
      ]);

      setVisibleDeleteModal(false);
      showNoticeMessage({ number: NOTICE_NUMBER.relationsSaved });
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <>
      <ActionsDropdown actions={actions} btnStyle={{ marginLeft: 16 }} />

      <ModalDeleteConfirm
        visible={visibleDeleteModal}
        title={t('DeleteAssetConnectionHeading', {
          ns: 'DeleteAssetConnection'
        })}
        deleteBtnText={t('Unlink', { ns: 'Common' })}
        isLoading={isDeleteLoading}
        onConfirm={deleteRelation}
        iconModal={AssetDeletedIcon}
        onClose={() => setVisibleDeleteModal(undefined)}
      />

      <AssetBookingModal
        values={{
          asset: {
            value: asset.id,
            label: asset
          },
          dateStart: entityData.dateStart
            ? moment(entityData.dateStart).toDate()
            : new Date(),
          dateEnd: entityData.dateEnd && moment(entityData.dateEnd).toDate()
        }}
        visible={visibleBookingModal}
        kind={entityData.type}
        objectId={entityData.id}
        bookingData={bookingData}
        allowEditDates={entityData.allowEditDates}
        title={t('AddCalendarAssetHeading', { ns: 'AddCalendarAsset' })}
        description={t('AddCalendarAssetDesc', { ns: 'AddCalendarAsset' })}
        onClose={() => setVisibleBookingModal(false)}
      />
    </>
  );
};

Actions.propTypes = {
  asset: PropTypes.object,
  entityData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    allowEditDates: PropTypes.bool
  }),
  bookingData: PropTypes.object,
  onChange: PropTypes.func
};

Actions.defaultProps = {
  asset: {},
  entityData: {},
  bookingData: null,
  onChange: () => {}
};

export default Actions;
