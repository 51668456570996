import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { PERIOD_MONTH, PERIOD_YEAR, PRICE_MAP } from 'constants/billing';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { getFormattedAmount } from 'components/settings-view/billing/utils';

import styles from './choose-tariff.module.scss';

const Tariff = ({ tariff, period, currency, onSelect }) => {
  const { t } = useTranslation('ChangePlan');

  const { title, modules, paid, topic } = tariff;
  const sortedModules = modules.sort((a, b) => a.sort - b.sort);

  const priceKey = PRICE_MAP[period][currency];
  const price = Number(tariff[priceKey]);
  const monthPeriodPrice = Number(tariff[PRICE_MAP[PERIOD_MONTH][currency]]);

  return (
    <div className={styles.tariff}>
      <div
        className={styles.tariffTitleWrap}
        style={{ backgroundColor: topic.color }}
      >
        <Typography.Title level={1} weight="bold" style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      </div>

      <div className={styles.tariffContent}>
        {paid && (
          <div className={styles.tariffPirceWrap}>
            <div className={styles.tariffPirce}>
              <Typography.Title level={1} weight="bold" style={{ margin: 0 }}>
                {getFormattedAmount({ amount: price, currency })}
              </Typography.Title>

              {period === PERIOD_YEAR && (
                <Typography.Text
                  color="black-35"
                  className={styles.monthlyPrice}
                >
                  {getFormattedAmount({ amount: monthPeriodPrice, currency })}
                </Typography.Text>
              )}
            </div>

            <Typography.Text>{t('ForCompanyMonthly')}</Typography.Text>
          </div>
        )}

        <div className={styles.modules}>
          {sortedModules.map((m, index) => (
            <div
              className={classnames(styles.module, {
                [styles.withBorderBottom]:
                  m.description && index !== sortedModules.length - 1
              })}
            >
              <div>
                {m.title && (
                  <Typography.Title
                    level={m.description ? 2 : 3}
                    weight="bold"
                    style={{ margin: 0 }}
                  >
                    {m.title}
                  </Typography.Title>
                )}

                {m.description && (
                  <Typography.Text>{m.description}</Typography.Text>
                )}
              </div>

              {m.description && <Icon type="subtract" color="brand" />}
            </div>
          ))}
        </div>

        <Button
          type="secondary"
          size="large"
          width="expanded"
          onClick={onSelect}
          className={styles.tariffButton}
        >
          {t(paid ? 'ChooseBtn' : 'ApplyBtn')}
        </Button>
      </div>
    </div>
  );
};

export default Tariff;
