import React from 'react';

export const SprintIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.95844 10.3048L3.07015 11.0671L3.15674 11.1683C3.62991 11.7211 4.19771 12.1699 4.82583 12.4991H0.33846V13.8324H12.9999V15.0255C12.9999 15.1376 13.1098 15.2043 13.1914 15.1416L15.6124 13.282C15.6847 13.2264 15.6847 13.1051 15.6124 13.0496L13.1914 11.1899C13.1098 11.1272 12.9999 11.1939 12.9999 11.3061V12.4991H10.0453C11.8446 11.5535 13.0749 9.66192 13.0749 7.48778C13.0749 4.37065 10.546 1.83429 7.43665 1.83429C4.51315 1.83429 2.10288 4.07722 1.82515 6.93553H0.473566C0.361385 6.93553 0.294715 7.04539 0.357387 7.12698L2.21707 9.54798C2.27259 9.62026 2.39391 9.62026 2.44943 9.54798L4.30912 7.12698C4.37179 7.04539 4.30512 6.93553 4.19294 6.93553H3.00227C3.27442 4.72338 5.15972 3.00554 7.43665 3.00554C9.89989 3.00554 11.9048 5.01599 11.9048 7.48778C11.9048 9.95958 9.89986 11.97 7.43665 11.97C6.13219 11.97 4.89613 11.4002 4.04526 10.4062L3.95844 10.3048Z"
      fill="currentColor"
    />
  </svg>
);

export default SprintIcon;
