import React from 'react';
import { Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const UserTooltip = ({ children, showTooltip, phone, email }) => {
  const { t } = useTranslation('AccountSettings');

  const show = (!!phone || !!email) && showTooltip;

  const tooltipUserContent = (
    <>
      {phone && (
        <div>
          <Text>
            <Text strong>{t('PhoneNumber')}: </Text>
            {phone}
          </Text>
        </div>
      )}

      {email && (
        <div>
          <Text>
            <Text strong>Email: </Text>
            {email}
          </Text>
        </div>
      )}
    </>
  );

  return show ? (
    <Tooltip title={tooltipUserContent} mouseEnterDelay={0.5}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

UserTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  showTooltip: PropTypes.bool,
  phone: PropTypes.string,
  email: PropTypes.string
};

UserTooltip.defaultProps = {
  children: undefined,
  showTooltip: false,
  phone: undefined,
  email: undefined
};

export default UserTooltip;
