import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/icon';

import styles from './deletable-field.module.scss';

export const DeletableField = ({
  children,
  style,
  className,
  allowDelete,
  onClick
}) => (
  <div className={classnames(styles.root, className)} style={style}>
    {children}

    {allowDelete && (
      <Icon
        type="close"
        color="black-55"
        className={styles.icon}
        data-qa="qa-99dafns4lak6vha"
        onClick={onClick}
      />
    )}
  </div>
);

DeletableField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  allowDelete: PropTypes.bool,
  onClick: PropTypes.func
};

DeletableField.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
  allowDelete: true,
  onClick: () => {}
};

export default DeletableField;
