import React from 'react';
import { notification } from 'antd';
import { Translation } from 'react-i18next';

const getFileExtension = file =>
  file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) ||
  file.name;

export default api => ({
  upload: (file, workspaceId, onProgress) => {
    const extension = file instanceof Blob ? file.type : getFileExtension(file);

    const sizeMB = 100;

    if (file.size > sizeMB * 1024 * 1024) {
      const error = (
        <Translation ns="Errors">
          {t => t('FileSizeMustNotExceed', { mb: sizeMB })}
        </Translation>
      );

      notification.warning({
        description: error
      });

      return Promise.reject(new Error(error));
    }

    if (extension === 'exe' || extension === 'msi') {
      const error = (
        <Translation ns="Errors">{t => t('MsiExeNotAllowed')}</Translation>
      );

      notification.warning({
        description: error
      });

      return Promise.reject(new Error(error));
    }

    const data = new FormData();
    data.append(0, file);

    const config = {
      headers: {
        'content-type':
          'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      },
      onUploadProgress: onProgress
    };

    return api
      .post(`/v1/${workspaceId}/data-files/`, data, config)
      .then(res => res.data);
  }
});
