import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table as AntdTable } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import { fillNegotiation } from 'store/order-statuses';

import capitalize from 'utils/capitalize';

import EditableCell from './editable-cell';

import styles from './table.module.scss';

export const Table = ({
  data,
  dataSource,
  setDataSource,
  total,
  setTotal,
  setIsFillLoading,
  isEditorField
}) => {
  const dispatch = useDispatch();

  const [tempDataSource, setTempDataSource] = useState(null); // when isLoading, form table cell rerendered and setted old default value
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['OrderConditions', 'Order', 'Common']);

  const {
    withTax,
    tax,
    currency,
    language,
    deadlineAt,
    title,
    measurementUnit,
    price,
    paymentFromDocumentType
  } = data;

  const defaultRowData = {
    key: 1,
    title,
    measurementUnit: measurementUnit || t('CostTypeOrder', { ns: 'Order' }),
    quantity: 1,
    price: price || '0.00',
    withoutTaxPrice: '0.00',
    tax,
    taxPrice: '0.00',
    totalPrice: 0,
    editedFieldName: 'price'
  };

  const getInitialDataSource = () => {
    if (dataSource.length) {
      return dataSource.map(d => ({
        ...d,
        editedFieldName: 'price'
      }));
    }

    return [defaultRowData];
  };

  const fillRows = async rows => {
    const preparedItems = rows.map(
      ({
        quantity,
        title: itemTitle,
        measurementUnit: itemMeasurementUnit,
        editedFieldName = 'price',
        id,
        ...fields
      }) => ({
        title: itemTitle,
        quantity,
        measurementUnit: itemMeasurementUnit,
        editedFieldName,
        id,
        [editedFieldName]: fields[editedFieldName]
      })
    );

    try {
      setIsLoading(true);
      setIsFillLoading(true);

      const resultTax = withTax ? tax : null;

      const {
        items,
        totalTaxPrice,
        totalPrice,
        totalWithoutTaxPrice
      } = await dispatch(
        fillNegotiation({
          data: {
            tax: resultTax,
            withTax,
            deadlineAt,
            items: preparedItems,
            language,
            currency,
            paymentFromDocumentType
          }
        })
      );

      setTotal({
        totalWithoutTaxPrice,
        tax: resultTax,
        totalTaxPrice,
        totalPrice
      });

      // It is necessary in order to save the row ID that comes from the backend when editing the act
      setDataSource(() =>
        items.map((current, idx) => ({
          ...current,
          ...(preparedItems[idx] || {}),
          key: idx,
          editedFieldName: preparedItems[idx].editedFieldName,
          tax: resultTax
        }))
      );
    } finally {
      setTempDataSource(null);
      setIsLoading(false);
      setIsFillLoading(false);
    }
  };

  const handleAdd = () => {
    const newDataSource = [
      ...dataSource,
      { ...defaultRowData, key: `${dataSource.length + 1}` }
    ];

    fillRows(newDataSource);
  };

  const handleUpdateField = (row, fieldName) => {
    const newDataSource = dataSource.map(item => {
      if (item.key !== row.key) {
        return item;
      }

      // update editedFieldName only if field which will be sent to a server changed
      if (['price', 'totalPrice'].includes(fieldName)) {
        row.editedFieldName = fieldName;
      }

      return row;
    });
    setTempDataSource(newDataSource);
    fillRows(newDataSource);
  };

  const handleDelete = key =>
    fillRows(dataSource.filter(item => item.key !== key));

  const columns = [
    {
      title: t('Number'),
      dataIndex: 'index',
      key: 'index',
      width: 41,
      render: (_, __, index) => index + 1
    },
    {
      title: t('ServiceNameTable'),
      dataIndex: 'title',
      key: 'title',
      width: 220,
      editable: isEditorField
    },
    {
      title: t('Unit'),
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      editable: isEditorField,
      width: 116
    },
    {
      title: t('Quantiity'),
      dataIndex: 'quantity',
      key: 'quantity',
      type: 'number',
      width: 72,
      editable: isEditorField || data.scheduler,
      number: true
    },
    {
      title: `${t('UnitPrice')}, ${currency}`,
      dataIndex: 'price',
      key: 'price',
      type: 'number',
      width: 97,
      editable: isEditorField || data.scheduler,
      number: true
    },
    {
      title: `${t('Cost')}, ${currency}`,
      dataIndex: 'withoutTaxPrice',
      key: 'withoutTaxPrice',
      width: 102,
      showInFooter: true,
      number: true
    },
    {
      title: t('VATPercent'),
      dataIndex: 'tax',
      key: 'tax',
      width: 66,
      showInFooter: true
    },
    {
      title: `${t('VATTotal')}, ${currency}`,
      dataIndex: 'taxPrice',
      key: 'taxPrice',
      width: 91,
      showInFooter: true
    },
    {
      title: withTax
        ? `${t('TotalWithVAT')}, ${currency}`
        : `${t('TotalToPay')}, ${currency}`,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      type: 'number',
      width: 95,
      showInFooter: true,
      editable: isEditorField,
      number: true
    },
    {
      title: '',
      key: 'action',
      showInFooter: true,
      render: (_, record) =>
        isEditorField && (
          <Button
            type="text"
            mood="none"
            className={styles.deleteBtn}
            data-qa="qa-otf738dktzkeuw8"
            onClick={() => handleDelete(record.key)}
          >
            <Icon type="close" />
          </Button>
        )
    }
  ];

  const transformedColumns = columns.map(column => {
    if (!column.editable) {
      return column;
    }

    return {
      ...column,
      onCell: record => ({
        record,
        title: column.title,
        number: column.number,
        editable: column.editable,
        dataIndex: column.dataIndex,
        handleSave: value => handleUpdateField(value, column.key)
      })
    };
  });

  const components = {
    header: {
      cell: ({ className, ...props }) => (
        <th className={classnames(className, styles.headerCell)} {...props} />
      )
    },
    body: {
      cell: EditableCell
    }
  };

  useEffect(() => {
    fillRows(getInitialDataSource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSource.length) {
      fillRows(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withTax, tax, paymentFromDocumentType]);

  const renderedTotal = useMemo(
    () => (
      <div className={styles.total} data-qa="qa-1ixs8u706e92h9b">
        {columns.map(col => {
          if (col.showInFooter) {
            return (
              <Typography.Paragraph
                style={{ minWidth: col.width, padding: '0 8px' }}
                key={col.key}
              >
                {total[col.key] || total[`total${capitalize(col.key)}`]}
              </Typography.Paragraph>
            );
          }

          if (col.key === 'price') {
            return (
              <Typography.Paragraph
                weight="semibold"
                style={{ minWidth: col.width, padding: '0 8px' }}
              >
                {t('TableTotal')}
              </Typography.Paragraph>
            );
          }

          return <Typography.Paragraph style={{ minWidth: col.width }} />;
        })}
      </div>
    ),
    [columns, t, total]
  );

  return (
    <>
      <AntdTable
        loading={isLoading}
        columns={transformedColumns}
        dataSource={tempDataSource || dataSource}
        components={components}
        footer={() =>
          isEditorField && (
            <Button
              type="link"
              size="large"
              style={{
                margin: '8px 0 8px 42px',
                padding: '0 8px',
                display: 'flex',
                alignItems: 'center'
              }}
              data-qa="qa-mduv4yk846z18w7"
              onClick={handleAdd}
            >
              <Icon type="plus" />
              {t('AddServiceBtn')}
            </Button>
          )
        }
        pagination={false}
        className={styles.table}
        scroll={{ x: '100%' }}
        locale={{
          emptyText: t('EmptyList', { ns: 'Common' })
        }}
      />

      {renderedTotal}
    </>
  );
};

Table.propTypes = {
  setIsFillLoading: PropTypes.func,
  isEditorField: PropTypes.bool
};

Table.defaultProps = {
  setIsFillLoading: () => {},
  isEditorField: true
};

export default Table;
