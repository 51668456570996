import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Typography from '../typography';
import Button from '../button';
import CopyLink from '../copy-link';

import styles from './google-meet-button.module.scss';

const GoogleMeetButton = ({ link, className }) => {
  const { t } = useTranslation('Common');

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.buttonWrap}>
        <Button
          type="secondary"
          size="large"
          onClick={() => window.open(link, '_blank')}
        >
          {t('JoinGoogleMeet')}
        </Button>

        <CopyLink link={link} />
      </div>

      <Typography.Text>{link}</Typography.Text>
    </div>
  );
};

export default GoogleMeetButton;
