import parsePhoneNumberFromString, {
  getCountryCallingCode
} from 'libphonenumber-js/min';

export const getPhoneCodeByContryCode = contryCode => {
  const callingCode = getCountryCallingCode(contryCode);
  if (callingCode) {
    return `+${callingCode}`;
  }

  return '+';
};

export default function isValidPhoneNumber(value, metadata) {
  if (!value) {
    return false;
  }

  const phoneNumber = parsePhoneNumberFromString(value, metadata);

  if (!phoneNumber) {
    return false;
  }

  return phoneNumber.isValid();
}
