import capitalize from 'utils/capitalize';

const EMAIL_DOMAIN_REGEXP = /@(\w+)/;

const IMAP = 'imap';
const SMTP = 'smtp';

const GMAIL = 'gmail';
const MAIL = 'mail';
const YANDEX = 'yandex';
const RAMBLER = 'rambler';
const UKR = 'ukr';
const OUTLOOK = 'outlook';
const YAHOO = 'yahoo';
const ICLOUD = 'icloud';

const setServerSettings = (type, server, port, isSsl) => ({
  [`${type}Server`]: server,
  [`${type}Port`]: port,
  [`is${capitalize(type)}Ssl`]: isSsl
});

const DOMAIN_IMAP_SMTP_SETTINGS = {
  [GMAIL]: {
    ...setServerSettings(IMAP, 'imap.gmail.com', 993, true),
    ...setServerSettings(SMTP, 'smtp.gmail.com', 465, true)
  },
  [MAIL]: {
    ...setServerSettings(IMAP, 'imap.mail.ru', 993, true),
    ...setServerSettings(SMTP, 'smtp.mail.ru', 465, true)
  },
  [YANDEX]: {
    ...setServerSettings(IMAP, 'imap.yandex.ru', 993, true),
    ...setServerSettings(SMTP, 'smtp.yandex.ru', 465, true)
  },
  [RAMBLER]: {
    ...setServerSettings(IMAP, 'imap.rambler.ru', 993, true),
    ...setServerSettings(SMTP, 'smtp.rambler.ru', 465, true)
  },
  [UKR]: {
    ...setServerSettings(IMAP, 'imap.ukr.net', 993, true),
    ...setServerSettings(SMTP, 'smtp.ukr.net', 465, true)
  },
  [OUTLOOK]: {
    ...setServerSettings(IMAP, 'imap-mail.outlook.com', 993, true),
    ...setServerSettings(SMTP, 'smtp-mail.outlook.com', 587, false)
  },
  [YAHOO]: {
    ...setServerSettings(IMAP, 'imap.mail.yahoo.com', 993, true),
    ...setServerSettings(SMTP, 'smtp.mail.yahoo.com', 465, true)
  },
  [ICLOUD]: {
    ...setServerSettings(IMAP, 'imap.mail.me.com', 993, true),
    ...setServerSettings(SMTP, 'smtp.mail.me.com', 465, true)
  }
};

export const getSelectDefaultValue = (options, value, defaultValue) =>
  options.find(o => {
    const checkedValue = value || defaultValue;

    return o.value === checkedValue;
  });

export const setImapSmtpSettings = (email, setValue) => {
  if (!email) {
    return null;
  }

  const [, emailProvider] = EMAIL_DOMAIN_REGEXP.exec(email) || [];

  if (!emailProvider || !DOMAIN_IMAP_SMTP_SETTINGS[emailProvider]) {
    return null;
  }

  const settings = DOMAIN_IMAP_SMTP_SETTINGS[emailProvider];

  return Object.keys(settings).forEach(key => setValue(key, settings[key]));
};
