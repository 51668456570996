import { EMIT_SEARCH_USER, READ_COMMENT } from '../constants/socket';

export default emit => ({
  searchUser: (workspaceId, id, comment) =>
    emit(EMIT_SEARCH_USER, {
      objectId: id,
      workspaceId,
      entity: comment.type,
      query: comment.query
    }),

  read: (workspaceId, objectId, entity) =>
    emit(READ_COMMENT, {
      objectId,
      workspaceId,
      entity
    })
});
