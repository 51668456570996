import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ONLY_THIS } from 'constants/index';

import useUpdateEmployeeRecordStatus from 'components/dashboard-view/views/calendar/hooks/use-update-employee-record-status';

import { fetchTemplateLocal } from 'store/tasks';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import Actions from './actions';
import View from '../view';

export const TaskTemplateView = ({ record, updateRecord }) => {
  const dispatch = useDispatch();

  const [template, setTemplate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { updateStatus } = useUpdateEmployeeRecordStatus();

  const { id, relation = {}, dateStart, recurringRecordId } = record;

  const changeRecordStatus = useCallback(
    async ({ status, acceptMeeting }) => {
      try {
        setIsStatusLoading(true);

        const date =
          (!acceptMeeting || acceptMeeting === ONLY_THIS) && !recurringRecordId
            ? dateStart
            : null;

        const newRecord = await updateStatus({
          recordId: id,
          taskStatus: status,
          date
        });
        await updateRecord(newRecord.id);

        showNoticeMessage({ number: NOTICE_NUMBER.taskStatusChanged });
      } finally {
        setIsStatusLoading(false);
      }
    },
    [dateStart, id, recurringRecordId, updateRecord, updateStatus]
  );

  const fetch = async () => {
    try {
      setIsLoading(true);

      const fetchedTemplate = await dispatch(
        fetchTemplateLocal({ id: relation.id, show400: false })
      );

      setTemplate(fetchedTemplate);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderedActions = useMemo(() => {
    if (!template) {
      return () => null;
    }

    return () => (
      <Actions template={template} updateRecord={() => updateRecord(id)} />
    );
  }, [id, template, updateRecord]);

  useEffect(() => {
    if (!template) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      record={record}
      hasError={hasError}
      isLoading={isLoading}
      isStatusLoading={isStatusLoading}
      onChangeStatus={changeRecordStatus}
      Actions={renderedActions}
    />
  );
};

TaskTemplateView.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    asset: PropTypes.object
  })
};

TaskTemplateView.defaultProps = {
  data: {}
};

export default TaskTemplateView;
