import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SCHEDULER_ON, SCHEDULER_OFF, SCHEDULER_NONE } from 'constants/index';

import Icon from 'components/common/icon';

import styles from '../styles.module.scss';

export const Repeating = ({ state }) => {
  const { t } = useTranslation('AddTaskType');

  const states = {
    [SCHEDULER_ON]: {
      text: t('RepeatOn'),
      icon: 'retweet'
    },
    [SCHEDULER_OFF]: {
      text: t('RepeatOff'),
      icon: 'retweet'
    },
    [SCHEDULER_NONE]: {
      text: t('NoRepeat')
    }
  };

  return (
    <div
      className={classnames(styles.repeating, {
        [styles.on]: state === SCHEDULER_ON,
        [styles.off]: state === SCHEDULER_OFF,
        [styles.none]: state === SCHEDULER_NONE
      })}
      data-qa="qa-j2314klca3gbn6g"
    >
      <span className={styles.text} data-qa="qa-eeaxzeof9rf9p33">
        {states[state].text}
      </span>

      {states[state].icon && <Icon type={states[state].icon} size={16} />}
    </div>
  );
};

Repeating.propTypes = {
  state: PropTypes.oneOf([SCHEDULER_ON, SCHEDULER_OFF, SCHEDULER_NONE])
    .isRequired
};

export default Repeating;
