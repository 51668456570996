import React from 'react';
import { Translation } from 'react-i18next';

import Typography from 'components/common/typography';

import getExternalUrl from 'utils/get-external-url';

import styles from './questions.module.scss';

const { Paragraph } = Typography;

export const getQuestions = (isNotVerified, isRegistration, language) => {
  if (isNotVerified) {
    return [
      {
        key: 'question-1',
        header: 'QAAlreadyRegisteredNotVerifiedQuestion',
        content: (
          <Paragraph color="black-35" style={{ marginBottom: 0 }}>
            <Translation ns="FAQ">
              {t => t('QAAlreadyRegisteredNotVerifiedAnswer')}
            </Translation>
          </Paragraph>
        )
      }
    ];
  }

  return [
    {
      key: 'question-2',
      header: 'QAWhyVerifyQuestion',
      content: (
        <>
          <Paragraph color="black-35">
            <Translation ns="FAQ">{t => t('QAWhyVerifyAnswer1')}</Translation>
          </Paragraph>

          <Paragraph color="black-35">
            <Translation ns="FAQ">{t => t('QAWhyVerifyAnswer2')}</Translation>
          </Paragraph>

          <Paragraph color="black-35" style={{ marginBottom: 0 }}>
            <Translation ns="FAQ">{t => t('QAWhyVerifyAnswer3')}</Translation>
          </Paragraph>
        </>
      )
    },
    {
      key: 'question-3',
      header: 'QAVisibleInfoQuestion',
      content: (
        <>
          <Paragraph color="black-35">
            <Translation ns="FAQ">{t => t('QAVisibleInfoAnswer1')}</Translation>
          </Paragraph>

          <Paragraph color="black-35">
            <Translation ns="FAQ">{t => t('QAVisibleInfoAnswer2')}</Translation>
          </Paragraph>

          <Paragraph color="black-35" style={{ marginBottom: 0 }}>
            <Translation ns="FAQ">{t => t('QAVisibleInfoAnswer3')}</Translation>{' '}
            <a
              href={getExternalUrl({
                enUrl:
                  'https://landing.upservice.io/uploads/protection-levels-ENG.pdf',
                ruUrl:
                  'https://landing.upservice.io/uploads/protection-levels.pdf',
                currentLanguage: language
              })}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <Translation ns="FAQ">
                {t => t('QAVisibleInfoAnswerPresentationLink4')}
              </Translation>
            </a>{' '}
            <Translation ns="FAQ">{t => t('QAVisibleInfoAnswer5')}</Translation>
          </Paragraph>
        </>
      )
    },
    {
      key: 'question-4',
      header: 'QAHowToVerifyWithoutCompanyQuestion',
      content: (
        <>
          <Paragraph color="black-35">
            <Translation ns="FAQ">
              {t => t('QAHowToVerifyWithoutCompanyAnswer1')}
            </Translation>
          </Paragraph>

          <Paragraph color="black-35" style={{ marginBottom: 0 }}>
            <Translation ns="FAQ">
              {t => t('QAHowToVerifyWithoutCompanyAnswer2')}
            </Translation>
          </Paragraph>
        </>
      )
    },
    isRegistration
      ? {
          key: 'question-5',
          header: 'QAAlreadyRegisteredVerifyQuestion',
          content: (
            <Paragraph color="black-35" style={{ marginBottom: 0 }}>
              <Translation ns="FAQ">
                {t => t('QAAlreadyRegisteredVerifyAnswer')}
              </Translation>
            </Paragraph>
          )
        }
      : null,
    {
      key: 'question-6',
      header: 'QACantFindCompanyQuestion',
      content: (
        <Paragraph color="black-35" style={{ marginBottom: 0 }}>
          <Translation ns="FAQ">
            {t => t('QACantFindCompanyAnswer1')}
          </Translation>{' '}
          <a
            href="https://upservice.com/contact"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Translation ns="FAQ">
              {t => t('QACantFindCompanyAnswerLink2')}
            </Translation>
          </a>
          .{' '}
          <Translation ns="FAQ">
            {t => t('QACantFindCompanyAnswer3')}
          </Translation>
        </Paragraph>
      )
    },
    isRegistration
      ? {
          key: 'question-7',
          header: 'QACantFindCountryQuestion',
          content: (
            <Paragraph color="black-35" style={{ marginBottom: 0 }}>
              <Translation ns="FAQ">
                {t => t('QACantFindCountryAnswer')}
              </Translation>
            </Paragraph>
          )
        }
      : null,
    {
      key: 'question-8',
      header: 'QACantFindAnswerQuestion',
      content: (
        <Paragraph color="black-35" style={{ marginBottom: 0 }}>
          <Translation ns="FAQ">
            {t => t('QACantFindAnswerAnswer1')}
          </Translation>{' '}
          <a
            href="https://upservice.com/contact"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Translation ns="FAQ">
              {t => t('QACantFindAnswerAnswerLink2')}
            </Translation>
          </a>
          .{' '}
          <Translation ns="FAQ">
            {t => t('QACantFindAnswerAnswer3')}
          </Translation>
        </Paragraph>
      )
    }
  ].filter(Boolean);
};

export default getQuestions;
