import React, { forwardRef } from 'react';
import { Input } from 'antd';

export const InputNumber = forwardRef(
  ({ onChange, isOnlyWhole, disableZeroEntry, ...props }, ref) => {
    const onChangeValue = e => {
      const { value } = e.target;
      const reg = isOnlyWhole ? /^\d+$/ : /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;

      if (disableZeroEntry && value.length === 1 && value[0] === '0') {
        e.target.value = '';
        onChange(e);
      }

      if (
        (!Number.isNaN(Number(value)) &&
          reg.test(isOnlyWhole ? value : Number(value))) ||
        value === ''
      ) {
        onChange(e);
      }
    };

    return (
      <Input
        onChange={onChangeValue}
        ref={ref}
        autoComplete="off"
        {...props}
        data-qa="qa-kc0hwcuq9b0lx8k"
      />
    );
  }
);

export default InputNumber;
