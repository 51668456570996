import { DISCUSSIONS } from 'constants/api';

export default api => ({
  create: ({ workspaceId, data }) =>
    api.post(`/v1/${workspaceId}/${DISCUSSIONS}/`, {
      message: data.message,
      is_private: data.isPrivate,
      file_list: data.fileList.map(file => {
        if (file.isCurrentlyAttach) {
          return {
            file_id: file.fileId,
            file_size: file.fileSize,
            mimetype: file.mimetype,
            title: file.title
          };
        }
        return {
          file_id: file.response.id,
          file_size: file.size,
          mimetype: file.type,
          title: file.name
        };
      })
    }),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/${DISCUSSIONS}/${id}/`, {
      show400: false
    }),

  fetchMembers: ({ workspaceId, id, limit = 25, offset = 0 }) =>
    api.get(`/v1/${workspaceId}/discussions/${id}/members/`, {
      params: { limit, offset }
    }),

  archive: ({ workspaceId, id }) =>
    api.post(`/v1/${workspaceId}/discussions/${id}/archive/`),

  fetchDiscussionStatuses: ({ workspaceId, roomUuids }) =>
    api.post(`/v1/${workspaceId}/discussions/retrieve-by-chats/`, {
      chat_ids: roomUuids
    })
});
