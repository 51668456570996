import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Panel from 'components/settings-view/panel';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import styles from './card.module.scss';

const AddonCard = ({
  title,
  amountText,
  headerAction,
  children,
  detailsLink
}) => {
  const { t } = useTranslation('Billing');

  return (
    <Panel className={styles.root}>
      <div className={styles.header}>
        <div>
          <Typography.Title level={2} style={{ margin: 0 }}>
            {title}
          </Typography.Title>

          {amountText && (
            <Typography.Title level={3} style={{ margin: 0 }}>
              {amountText}
            </Typography.Title>
          )}
        </div>

        {headerAction}
      </div>

      {children}

      {detailsLink && (
        <Button
          type="link"
          className={styles.detailsBtn}
          onClick={() => window.open(detailsLink, '_blank')}
        >
          {t('DetailsBtn')}
        </Button>
      )}
    </Panel>
  );
};

AddonCard.propTypes = {
  title: PropTypes.string.isRequired,
  amountText: PropTypes.string,
  headerAction: PropTypes.any,
  children: PropTypes.any,
  detailsLink: PropTypes.string
};

AddonCard.defaultProps = {
  amountText: null,
  headerAction: null,
  children: null,
  detailsLink: null
};

export default AddonCard;
