import { Spin, notification } from 'antd';
import axios from 'axios';
import { LANGUAGE } from 'i18n-config';
import React, { Suspense } from 'react';
import { Translation } from 'react-i18next';

import { ITEMS_PER_PAGE } from 'constants/index';

import config from 'config';
import {
  makeCancelableRequest,
  OLD_REQUEST_CANCELED
} from 'utils/make-cancalable-request';

const HAS_TAG_ERROR_CODE = 'entity-already-has-tag';

const API_TAGS = `${
  process.env.REACT_APP_API_PROTOCOL
}://${config.REACT_APP_API_TAGS_URL || 'tags.upservice.io'}/_tags`;

const api = axios.create({
  baseURL: API_TAGS
});

const errorHandler = error => {
  const { response = {} } = error;

  const notificationMessage = (
    <Suspense fallback={<Spin size="small" />}>
      <Translation ns="Errors">{t => t('SomethingWentWrong')}</Translation>
    </Suspense>
  );

  if (error && error.message === OLD_REQUEST_CANCELED) {
    throw error;
  }

  switch ((response.data || {}).code) {
    case HAS_TAG_ERROR_CODE:
      notification.warning({
        message: notificationMessage,
        description: (response.data || {}).details
      });
      break;
    default:
      notification.warn({
        message: notificationMessage,
        description: (response.data || {}).details
      });
      break;
  }

  throw error;
};

api.interceptors.request.use(
  async configuration => {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem(LANGUAGE);

    if (token) {
      configuration.headers.authorization = `Bearer ${token}`;
    }

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(response => response, errorHandler);

export default () => ({
  // get all workspace tags
  fetch: makeCancelableRequest(
    api,
    (fetch, { workspaceId, search, limit, offset, withSystem }) =>
      fetch('get', `/v1/${workspaceId}/tags`, {
        params: {
          query: search || '',
          limit: limit || ITEMS_PER_PAGE,
          offset: offset || 0,
          with_system: withSystem
        }
      })
  ),

  // get entities tags
  fetchByEntity: ({ workspaceId, entityType, entityIds }) =>
    api.post(`/v1/${workspaceId}/entities-tags/${entityType}`, entityIds),

  // create workspace tag
  create: ({ workspaceId, tag, employeeId }) =>
    api.post(
      `/v1/${workspaceId}/tags`,
      {
        name: tag.name,
        color: tag.color,
        type: tag.type
      },
      {
        params: {
          employeeId
        }
      }
    ),

  // add tag to entity
  add: ({ workspaceId, tag, employeeId }) =>
    api.post(
      `/v1/${workspaceId}/entities-tags`,
      {
        tag_id: tag.id,
        entity_id: tag.entityId,
        entity_type: tag.entityType
      },
      {
        params: {
          employeeId
        }
      }
    ),

  // delete tag from entity
  delete: ({ workspaceId, tag, employeeId }) =>
    api.delete(
      `/v1/${workspaceId}/entities-tags/${tag.entityType}/${tag.entityId}/${tag.id}`,
      {
        params: {
          employeeId
        }
      }
    ),

  // delete tag from workspace
  deleteFromWorkspace: ({ workspaceId, id, employeeId }) =>
    api.delete(`/v1/${workspaceId}/tags/${id}`, {
      params: {
        employeeId
      }
    }),

  // replace with new tag
  replace: ({ workspaceId, replaceData, employeeId }) =>
    api
      .post(
        `/v1/${workspaceId}/tags/replace`,
        {
          from_tag_id: replaceData.fromTag.id,
          to_tag_id: replaceData.toTagId
        },
        {
          params: {
            employeeId
          }
        }
      )
      .then(({ data }) => data),

  // edit tag in workspace
  edit: ({ workspaceId, tag, employeeId }) =>
    api
      .put(
        `/v1/${workspaceId}/tags/${tag.id}`,
        {
          name: tag.name,
          color: tag.color,
          type: tag.type
        },
        {
          params: {
            employeeId
          }
        }
      )
      .then(({ data }) => data),

  // bulk delete tags from workspace
  bulkDelete: ({ workspaceId, tagIds, employeeId }) =>
    api.post(
      `/v1/${workspaceId}/tags/bulk-delete`,
      {
        tags_ids: tagIds
      },
      {
        params: {
          employeeId
        }
      }
    )
});
