import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'constants/calendar';

import Actions from 'components/common/actions';

import formatBytes from 'utils/format-bytes';

import CollapseUsers from './collapse-users';
import AgreementList from './agreement-list';
import AgreementSheetDrawer from '../agreement-sheet';

import styles from './attachment-version.module.scss';

const { Paragraph } = Typography;

export const AttachmentVersion = ({ file, actions, onClick }) => {
  const [agreementSheetId, setAgreementSheetId] = useState(null);

  const { t } = useTranslation('MyDrive');

  const {
    createdAt,
    name,
    fromVersion,
    editors,
    fileSize,
    agreementSheets
  } = file;

  const formatDate = date => moment(date).format(DATE_TIME_FORMAT);

  const showAgreementSheet = sheetId => setAgreementSheetId(sheetId);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <Button type="link" onClick={onClick} className={styles.title}>
          №{name} {t('From')} {formatDate(createdAt)}
        </Button>

        {fromVersion && (
          <Paragraph
            ellipsis={{ rows: 1 }}
            className={styles.restoredTitle}
            title={formatDate(fromVersion.createdAt)}
          >
            {t('VersionRestored')} №{fromVersion.name} {t('From')}{' '}
            {formatDate(fromVersion.createdAt)}
          </Paragraph>
        )}

        <Actions getPopupContainer={() => document.body} actions={actions} />
      </div>

      <AgreementList list={agreementSheets} onClick={showAgreementSheet} />

      <div className={styles.bottom}>
        <CollapseUsers users={editors} />

        <span>{formatBytes(fileSize)}</span>
      </div>

      <AgreementSheetDrawer
        sheetId={agreementSheetId}
        visible={!!agreementSheetId}
        onClose={() => setAgreementSheetId(null)}
      />
    </div>
  );
};

AttachmentVersion.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.any.isRequired,
    editors: PropTypes.array,
    fromVersion: PropTypes.object,
    fileSize: PropTypes.number.isRequired
  }).isRequired,
  actions: PropTypes.array,
  onClick: PropTypes.func
};

AttachmentVersion.defaultProps = {
  actions: [],
  onClick: () => {}
};

export default AttachmentVersion;
