import React from 'react';

export const WebsiteFormIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_75528_19703)">
      <path
        d="M12.9993 0C13.8256 0 14.5323 0.293412 15.1189 0.880127C15.7056 1.46684 15.999 2.17363 15.999 2.99998V12.9999C15.999 13.8262 15.7056 14.5329 15.1188 15.1198C14.5322 15.7066 13.8255 16 12.9992 16H2.99925C2.17294 16 1.46622 15.7066 0.879396 15.1198C0.29268 14.5329 -0.000732422 13.8262 -0.000732422 12.9999V2.99998C-0.000732422 2.17363 0.29268 1.46695 0.879396 0.880127C1.46622 0.293412 2.17294 0 2.99925 0H12.9993Z"
        fill="#FF6433"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6005 3.5C12.0956 3.5 12.4961 3.91047 12.4961 4.41215L12.5 9.91517C12.5003 10.3856 12.1241 10.7972 11.6599 10.7972H4.40006C3.90503 10.7972 3.5 10.4169 3.5 9.91517V4.41215C3.5 3.91047 3.90503 3.5 4.40006 3.5H11.6005ZM5.75015 8.97292H10.2505C10.498 8.97292 10.7005 8.76768 10.7005 8.51684C10.7005 8.266 10.498 8.06077 10.2505 8.06077H5.75015C5.50263 8.06077 5.30012 8.266 5.30012 8.51684C5.30012 8.76768 5.50263 8.97292 5.75015 8.97292ZM5.75015 7.60469H10.2505C10.498 7.60469 10.7005 7.39945 10.7005 7.14861C10.7005 6.89777 10.498 6.69254 10.2505 6.69254H5.75015C5.50263 6.69254 5.30012 6.89777 5.30012 7.14861C5.30012 7.39945 5.50263 7.60469 5.75015 7.60469ZM5.75015 6.23646H10.2505C10.498 6.23646 10.7005 6.03122 10.7005 5.78038C10.7005 5.52954 10.498 5.32431 10.2505 5.32431H5.75015C5.50263 5.32431 5.30012 5.52954 5.30012 5.78038C5.30012 6.03122 5.50263 6.23646 5.75015 6.23646ZM6.73998 10.7973L8.00006 12.5L9.26015 10.7973H6.73998Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_75528_19703">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WebsiteFormIcon;
