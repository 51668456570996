import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from 'constants/index';

import { FormDatePicker } from 'components/common/hook-form';
import Button from 'components/common/button';
import FormBookingAssetSelect from 'components/common/hook-form/select/booking-asset';

import { fetchAssetBookingDateRangeLocal } from 'store/calendar';

import styles from './booking-modal.module.scss';

const ASSET = 'asset';
const DATE_START = 'dateStart';
const DATE_END = 'dateEnd';

const Form = ({
  defaultValues,
  allowEditDates,
  isLoading,
  bookingData,
  onCancel,
  onSubmit
}) => {
  const methods = useForm({
    defaultValues
  });

  const dispatch = useDispatch();

  const { t } = useTranslation(['AddCalendarAsset', 'Errors']);

  const [isDatesLoading, setIsDatesLoading] = useState(false);
  const [blockedDate, setBlockedDate] = useState({
    dates: [],
    time: {},
    dateIntervals: []
  });

  const dateStart = methods.watch(DATE_START);
  const dateEnd = methods.watch(DATE_END);
  const asset = methods.watch(ASSET);

  const isDisabled = !dateStart || !dateEnd;

  const transformDates = bookingDates => {
    const result = {
      dates: [],
      dateIntervals: [],
      time: {}
    };

    const setTime = ({ start, end, isToStart } = {}) => {
      const interval = 15;

      let currentDate = start;
      const key = moment(isToStart ? start : end).format(DATE_FORMAT);

      while (moment(end).diff(currentDate, 'minutes') > 0) {
        result.time[key] = [...(result.time[key] || []), currentDate];

        currentDate = moment(currentDate)
          .add(interval, 'minutes')
          .toDate();
      }
    };

    const setStartEndTime = ({ start, end }) => {
      setTime({
        start,
        end: moment(start)
          .toDate()
          .setHours(24, 0),
        isToStart: true
      });

      setTime({
        start: moment(end)
          .toDate()
          .setHours(0, 0),
        end,
        isToStart: false
      });
    };

    bookingDates.forEach(d => {
      const start = moment(d.start).toDate();
      const end = moment(d.end).toDate();

      if (moment(end).diff(start, 'days') > 2) {
        result.dateIntervals.push({
          start,
          end: moment(end)
            .add(-1, 'day')
            .toDate()
        });

        return setStartEndTime({ start, end });
      }

      if (moment(end).diff(start, 'days') > 1) {
        result.dates.push(
          moment(start)
            .add(1, 'day')
            .toDate()
        );

        return setStartEndTime({ start, end });
      }

      if (moment(end).diff(start, 'days') === 1) {
        return setStartEndTime({ start, end });
      }

      return setTime({ start, end, isToStart: true });
    });

    return result;
  };

  const getMaxDateEnd = () => {
    let result;

    const dates = [...blockedDate.dates];
    blockedDate.dateIntervals.forEach(({ start, end }) => {
      dates.push(start, end);
    });

    dates.sort((a, b) => a.getTime() - b.getTime());

    for (const d of dates) {
      if (moment(dateStart).isBefore(d)) {
        result = d;
        break;
      }
    }

    return result;
  };

  const filterDateEndTime = time => {
    if (!dateStart) {
      return true;
    }

    const selectedDate = new Date(time);
    const blockedTime = (blockedDate.time[
      moment(dateEnd).format(DATE_FORMAT)
    ] || [])[0];

    const blocked = blockedTime
      ? selectedDate.getTime() <
        moment(blockedTime)
          .toDate()
          .getTime()
      : true;

    if (selectedDate.getTime() > dateStart.getTime() && blocked) {
      return true;
    }

    return false;
  };

  const filterDateStartTime = time => {
    if (!dateEnd) {
      return true;
    }

    const selectedDate = new Date(time);

    return selectedDate.getTime() < dateEnd.getTime();
  };

  const fetchBookingDates = async () => {
    try {
      setIsDatesLoading(true);

      const result = await dispatch(
        fetchAssetBookingDateRangeLocal({
          params: {
            assetId: asset.value,
            recordId: (bookingData || {}).id,
            dateStart: moment(dateStart).format(DATE_FORMAT),
            dateEnd: moment(dateEnd).format(DATE_FORMAT)
          }
        })
      );

      setBlockedDate(transformDates(result));
    } finally {
      setIsDatesLoading(false);
    }
  };

  // return true if day blocked, null if day partial blocked, false if day not blocked
  const getIsBlocked = date => {
    let result = false;

    for (const d of blockedDate.dates) {
      if (moment(d).isSame(date, 'days')) {
        result = true;
        break;
      }
    }

    for (const d of blockedDate.dateIntervals) {
      if (moment(date).isBetween(d.start, d.end)) {
        result = true;
        break;
      }
    }

    if (blockedDate.time[moment(date).format(DATE_FORMAT)]) {
      result = null;
    }

    return result;
  };

  const renderDayContents = (day, date) => {
    const blocked = getIsBlocked(date);

    const getTooltip = () => {
      if (blocked === true) {
        return t('BookingDateUnavailable', { ns: 'Errors' });
      }

      if (blocked === null) {
        return t('BookingReservedHours', { ns: 'Errors' });
      }

      return undefined;
    };

    return (
      <Tooltip
        title={getTooltip()}
        overlayStyle={{
          maxWidth: blocked ? 135 : 162
        }}
        overlayClassName={styles.tooltip}
        mouseEnterDelay={0.5}
      >
        {moment(date).get('date')}
      </Tooltip>
    );
  };

  const getDayClassName = date => {
    const blocked = getIsBlocked(date);

    if (blocked === null) {
      return styles.partialBlocked;
    }

    return undefined;
  };

  useEffect(() => {
    if ((asset || {}).value && dateStart && dateEnd) {
      fetchBookingDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, dateStart, dateEnd]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormBookingAssetSelect
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          label={t('Asset')}
          name={ASSET}
          params={{
            dateStart,
            dateEnd
          }}
          isDisabled={isDisabled}
        />
        {isDisabled && (
          <Alert
            type="warning"
            message={<>{t('BookAssetDateFieldsRequired', { ns: 'Errors' })}</>}
            style={{ marginBottom: 16 }}
          />
        )}

        <div className={styles.dates}>
          <FormDatePicker
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            label={t('StartDate')}
            name={DATE_START}
            minDate={new Date()}
            maxDate={dateEnd}
            filterTime={filterDateStartTime}
            excludeDates={blockedDate.dates}
            excludeDateIntervals={blockedDate.dateIntervals}
            excludeTimes={
              blockedDate.time[moment(dateStart).format(DATE_FORMAT)] || []
            }
            popperClassName={isDatesLoading ? styles.popper : undefined}
            disabled={!allowEditDates}
            renderDayContents={renderDayContents}
            dayClassName={getDayClassName}
          />

          <FormDatePicker
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            label={t('TaskDueDate')}
            name={DATE_END}
            minDate={dateStart}
            maxDate={getMaxDateEnd()}
            filterTime={filterDateEndTime}
            popperClassName={isDatesLoading ? styles.popper : undefined}
            disabled={!allowEditDates}
          />
        </div>

        <div className={styles.buttons}>
          <Spin spinning={isLoading} wrapperClassName={styles.spin}>
            {bookingData && (
              <Button
                type="secondary"
                width="expanded"
                mood="negative"
                style={{ marginRight: 16 }}
                onClick={onCancel}
              >
                {t('TakeReservationOffBtn')}
              </Button>
            )}

            <Button type="primary" width="expanded" htmlType="submit">
              {t('BookBtn')}
            </Button>
          </Spin>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
