import React from 'react';

export const SwitcherPlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g
      id="Icons / Outlined / Application Icons / Switcher"
      clipPath="url(#clip0_79963_9295)"
    >
      <path
        id="Vector"
        d="M12.286 3.14509H1.42885C1.11278 3.14509 0.857422 3.40045 0.857422 3.71652V14.5737C0.857422 14.8897 1.11278 15.1451 1.42885 15.1451H12.286C12.6021 15.1451 12.8574 14.8897 12.8574 14.5737V3.71652C12.8574 3.40045 12.6021 3.14509 12.286 3.14509ZM11.5717 13.8594H2.14314V4.4308H11.5717V13.8594ZM14.5717 0.859375H3.57171C3.49314 0.859375 3.42885 0.923661 3.42885 1.00223V2.00223C3.42885 2.0808 3.49314 2.14509 3.57171 2.14509H13.8574V12.4308C13.8574 12.5094 13.9217 12.5737 14.0003 12.5737H15.0003C15.0789 12.5737 15.1431 12.5094 15.1431 12.4308V1.4308C15.1431 1.11473 14.8878 0.859375 14.5717 0.859375ZM4.21457 8.6808H9.50028V9.82366H4.21457V8.6808Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M7.42969 11.8951L7.42969 6.60938L6.28683 6.60937L6.28683 11.8951L7.42969 11.8951Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_79963_9295">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SwitcherPlusIcon;
