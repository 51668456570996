import { notification } from 'antd';
import React from 'react';
import { Translation } from 'react-i18next';

export const handleContactFields = ({ contact, name, methods }) => {
  const fields = [];

  if (contact && contact.label) {
    Object.entries(contact.label).forEach(([key, value]) => {
      if (key.startsWith(name) && value !== null) {
        fields.push(value);
      }
    });

    if (!fields.length) {
      notification.warning({
        message: (
          <Translation ns="Contacts">
            {t =>
              t(
                name === 'email'
                  ? 'EmailMissedWarningHeading'
                  : 'NoPhoneHeading'
              )
            }
          </Translation>
        ),
        description: (
          <Translation ns="Contacts">
            {t =>
              t(name === 'email' ? 'EmailMissedWarningDesc' : 'NoPhoneDesc')
            }
          </Translation>
        ),
        duration: 6
      });
    }
  }

  const mapFields = fields.map(item => ({ value: item, label: item }));

  methods.setValue(name, { value: fields[0], label: fields[0] });

  return mapFields;
};

export default handleContactFields;
