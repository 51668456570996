import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getActiveWorkspace } from 'store/workspace';

import Provider from './provider';

const PaymentAccountStatusProvider = ({ children }) => {
  const workspace = useSelector(getActiveWorkspace);

  return (
    <Fragment>
      {children}

      {!!workspace && <Provider workspace={workspace} />}
    </Fragment>
  );
};

export default PaymentAccountStatusProvider;
