import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from 'constants/index';

import Modal from 'components/common/modal';

import { setBacklogFilterSprintIsNull } from 'store/tasks';
import { createSprint, fetchSprintsLocal, updateSprint } from 'store/projects';

import BaseForm from './base-form';

import styles from './styles.module.scss';

const { Title } = Typography;

const SprintCreatorModal = ({
  isModalVisible,
  setIsModalVisible,
  isUpdate = false,
  editableSprint,
  projectId,
  ...modalProps
}) => {
  const dispatch = useDispatch();

  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [sprints, setSprints] = useState([]);

  const { t } = useTranslation('AddEditSprint');

  const fetchData = () => dispatch(fetchSprintsLocal());

  useEffect(() => {
    if (isModalVisible && sprints.length < 1) {
      fetchData().then(({ results }) => setSprints(results));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, sprints.length]);

  const sendValues = async values => {
    try {
      setIsLoadingSend(true);
      const { dateEnd, dateStart } = values;

      const _dateStart = dateStart
        ? moment(dateStart).format(DATE_FORMAT)
        : undefined;
      const _dateEnd = dateEnd
        ? moment(dateEnd).format(DATE_FORMAT)
        : undefined;

      const preparedValue = {
        ...values,
        dateEnd: _dateEnd,
        dateStart: _dateStart
      };

      if (isUpdate) {
        await dispatch(
          updateSprint({
            id: (editableSprint || {}).id,
            value: preparedValue,
            project: projectId
          })
        );
      } else {
        await dispatch(
          createSprint({ value: { ...preparedValue, project: projectId } })
        );
        dispatch(setBacklogFilterSprintIsNull(true));
      }

      setIsModalVisible(false);
    } finally {
      setIsLoadingSend(false);
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
      className={styles.root}
      data-qa="qa-b7zj3m7tkczgbni"
      footer={null}
      width={538}
      title={
        <Title level={4} className={styles.title}>
          {t(isUpdate ? 'EditSprintHeading' : 'AddSprintHeading')}
        </Title>
      }
      destroyOnClose
      {...modalProps}
    >
      <BaseForm
        onSubmit={sendValues}
        isLoading={isLoadingSend}
        isUpdate={isUpdate}
        values={editableSprint}
        sprints={sprints}
      />
    </Modal>
  );
};

SprintCreatorModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  isUpdate: PropTypes.bool,
  projectId: PropTypes.number
};

SprintCreatorModal.defaultProps = {
  isModalVisible: false,
  setIsModalVisible: () => {},
  isUpdate: false,
  projectId: null
};

export default SprintCreatorModal;
