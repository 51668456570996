import { createAction } from 'redux-actions';

import createActionThunk from '../../actions-thunk';
import api from '../../../api';
import { getWorkspaceId } from '../../workspace';
import { getOrders } from '../../order-statuses';
import {
  getCurrentPage,
  getItemsPerPage,
  getFilterSearchEmployees,
  getFilterRoleEmployees,
  getFilterCategoriesEmployees
} from './selectors';
import { ITEMS_PER_PAGE } from '../../../constants';
import mapValue from '../../../utils/map-value';

export const mapEmployees = employee => ({
  ...employee,
  avatar: (employee.avatarFile || {}).url
});

export const fetchEmployee = createActionThunk(
  'employees/fetch-employee',
  ({ id, emptyRequest, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.employees
      .fetch(id, workspaceId)
      .then(({ data }) => ({ ...data, emptyRequest }));
  }
);

export const fetchEmployeeLocal = createActionThunk(
  'employees/fetch-local',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.employees.fetch(id, workspaceId).then(({ data }) => data);
  }
);

const employeeAction = ({
  roleType,
  isManager,
  department,
  excludeSelf,
  excludeBench,
  excludeReplaced,
  headDepartment,
  directDepartment,
  direct,
  getState,
  emptyRequest,
  withPermissions
}) => {
  const state = getState();
  const workspaceId = getWorkspaceId(state);

  const search = getFilterSearchEmployees(state);
  const categories = (getFilterCategoriesEmployees(state) || []).map(mapValue);
  const role = roleType || getFilterRoleEmployees(state).value;
  const rate = null;
  const isActive = true;
  const page = getCurrentPage(state);
  const limit = getItemsPerPage(state);
  const offset = limit * (page - 1);

  return api.employees
    .fetchAll({
      workspaceId,
      isActive,
      offset,
      limit,
      search,
      rate,
      categories,
      role,
      department,
      headDepartment,
      excludeSelf,
      excludeBench,
      excludeReplaced,
      directDepartment,
      direct,
      isManager,
      withPermissions
    })
    .then(res => ({
      entries: res.data.results.map(mapEmployees),
      totalItems: res.data.count,
      emptyRequest
    }));
};

export const addCategory = createActionThunk(
  'employees/add-category-employee',
  ({ getState, employeeId, employeeCategory }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employeeCategory.add(workspaceId, employeeId, employeeCategory);
  }
);

export const deleteCategory = createActionThunk(
  'employees/delete-category-employee',
  ({ getState, employeeId, categoryId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employeeCategory
      .delete(workspaceId, categoryId)
      .then(() => ({ employeeId, categoryId }));
  }
);

export const changePosition = createActionThunk(
  'employees/change-position',
  ({ getState, employeeId, position }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.changePosition(workspaceId, employeeId, position);
  }
);

export const fetchEmployees = createActionThunk(
  'employees/fetch-employees',
  employeeAction
);

export const fetchEmployeesLocal = createActionThunk(
  'employees/fetch-local',
  ({
    search,
    exclude,
    roleType,
    isManager,
    department,
    excludeSelf,
    excludeBench = true,
    excludeReplaced,
    directDepartment,
    headDepartment,
    direct,
    offset = 0,
    limit = ITEMS_PER_PAGE,
    categories = [],
    rate,
    isActive = true,
    directWithBench,
    workspaceControllers,
    workspaceId,
    id,
    withPermissions,
    lite,
    // для меншена
    cancelable,
    isPostMethod,
    withSystem,
    getState
  }) => {
    const state = getState();
    const activeWorkspaceId = getWorkspaceId(state);

    const fetch = cancelable
      ? api.employees.fetchWithCancel
      : api.employees.fetchAll;

    return fetch({
      workspaceId: workspaceId || activeWorkspaceId,
      isActive,
      offset,
      limit,
      search,
      rate,
      categories,
      roleType,
      department,
      headDepartment,
      exclude,
      excludeSelf,
      excludeBench: excludeBench || undefined,
      excludeReplaced,
      directDepartment,
      direct,
      isManager,
      directWithBench,
      workspaceControllers,
      id,
      withPermissions,
      lite,
      isPostMethod,
      withSystem
    }).then(({ data }) => ({
      entries: data.results.map(mapEmployees),
      totalItems: data.count
    }));
  }
);

export const searchEmployeesLocal = createActionThunk(
  'search/employees',
  ({ search, vatNumber, isIndividual, offset, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const limit = ITEMS_PER_PAGE;

    return api.search
      .fetchEmployees(
        workspaceId,
        search,
        offset,
        limit,
        vatNumber,
        isIndividual
      )
      .then(({ data }) => ({
        entries: data.results.map(mapEmployees),
        totalItems: data.count
      }));
  }
);

export const deleteEmployee = createActionThunk(
  'employees/delete-employee',
  ({ id, transferTo, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.delete(workspaceId, id, transferTo);
  }
);

export const changeOwner = createActionThunk(
  'employees/change-owner',
  ({ transferTo, dismiss, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    if (dismiss) {
      return api.employees.dismissOwner(workspaceId, transferTo);
    }

    return api.employees.benchOwner(workspaceId, transferTo);
  }
);

export const changeAdmin = createActionThunk(
  'employees/change-admin',
  ({ newAdmin, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.changeAdmin(workspaceId, newAdmin);
  }
);

export const fetchEmployeeOrder = createActionThunk(
  'employees/fetch-employee-order',
  getOrders
);

export const inviteEmployee = createActionThunk(
  'employees/invite-employee',
  ({ employeeEmails, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.invitation
      .inviteEmployee({
        workspaceId,
        employeeEmails
      })
      .then(({ data }) => data);
  }
);

export const deleteInvite = createActionThunk(
  'employees/delete-invite-employee',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.invitation.deleteInvite(workspaceId, id);
  }
);

export const fetchInvitedEmployeeEmails = createActionThunk(
  'employees/fetch-inited-employee-emails',
  ({ offset = 0, limit = 10000, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.invitation
      .fetchInvitedEmployees(workspaceId, offset, limit)
      .then(({ data }) => data);
  }
);

export const updateEmployee = createActionThunk(
  'employees/update-employee',
  ({ employee, departmentId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees
      .patch(workspaceId, employee)
      .then(({ data }) => ({ ...data, departmentId }));
  }
);

export const moveEmployeeToNewDepartment = createActionThunk(
  'employees/move-employee-to-new-department',
  ({ id, newManagerId, newDepartmentId, reason, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.changeDepartment({
      workspaceId,
      id,
      newDepartmentId,
      newManagerId,
      reason
    });
  }
);

export const replaceEmployeeAction = createActionThunk(
  'employees/replace-employee',
  ({ id, replacerId, reason, fromDate, toDate, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.replaceEmployee({
      workspaceId,
      id,
      replacerId,
      reason,
      fromDate,
      toDate
    });
  }
);

export const dismissReplacement = createActionThunk(
  'employees/dismiss-replacement',
  ({ id, replacerId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.dismissReplacement({
      workspaceId,
      id,
      replacerId
    });
  }
);

export const assignController = createActionThunk(
  'employees/assign-controller',
  ({ employee, type, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .create(workspaceId, { employee, type })
      .then(response => {
        // Update employee roles
        dispatch(fetchEmployee({ id: employee }));
        return response;
      });
  }
);

export const updateController = createActionThunk(
  'employees/update-controller',
  ({ employee, type, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.controllers
      .update(workspaceId, employee, type)
      .then(response => {
        // Update employee roles
        dispatch(fetchEmployee({ id: employee }));
        return response;
      });
  }
);

export const kickController = createActionThunk(
  'employees/kick-controller',
  ({ employees, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .remove(workspaceId, employees)
      .then(response => {
        // Update employee roles
        dispatch(fetchEmployee({ id: employees.from }));
        return response;
      });
  }
);

export const checkEmail = createActionThunk(
  'employees/check-email',
  ({ email, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.invitation
      .checkEmail(workspaceId, email)
      .then(({ data }) => data);
  }
);

export const checkDismiss = createActionThunk(
  'employees/check-dismiss',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.checkDismiss(workspaceId, id).then(({ data }) => data);
  }
);

export const sendVerificationCode = createActionThunk(
  'employees/send-verification-code',
  ({ phone, workspaceId: id, getState }) => {
    const state = getState();
    const workspaceId = id || getWorkspaceId(state);

    return api.employees.sendVerificationCode(workspaceId, phone);
  }
);

export const acceptVerificationCode = createActionThunk(
  'employees/accep-verification-code',
  ({ code, workspaceId: id, getState }) => {
    const state = getState();
    const workspaceId = id || getWorkspaceId(state);

    return api.employees
      .acceptVerificationCode(workspaceId, code)
      .then(({ data }) => data);
  }
);

export const setCurrentPage = createAction('employees/set-current-page');
export const clearFilter = createAction('employees/clear-filter');
export const setFilterSearch = createAction('employees/set-filter-search');
export const setFilterCategories = createAction(
  'employees/set-filter-categories'
);
export const setFilterRole = createAction('employees/set-filter-role');

export const clearError = createAction('employees/clear-error');
