import React from 'react';
import { Form, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_MEMBERS } from 'constants/index';

import { ProjectParticipantsSelect } from 'components/common/controls/custom-select';
import Icon from 'components/common/icon';

import MembersField from '../members-field';

const mapToValue = member => ({
  value: member.id,
  label: member
});

const ActionTypeMembers = ({
  getFieldDecorator,
  relatedProjectId,
  values,
  currentTaskType
}) => {
  const { t } = useTranslation(['AddTask', 'Common']);

  const label = (
    <>
      <Typography.Text style={{ color: 'inherit', marginRight: 5 }}>
        {t('Coexecutors')}
      </Typography.Text>

      <Tooltip title={t('CoexecutorsTip')}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  if (relatedProjectId) {
    return (
      <Form.Item label={label}>
        {getFieldDecorator(TYPE_MEMBERS, {
          initialValue: (values.members || []).map(mapToValue)
        })(
          <ProjectParticipantsSelect
            projectId={relatedProjectId}
            placeholder={t('SelectMembers', { ns: 'Common' })}
            hideWorkspace
            isMulti
          />
        )}
      </Form.Item>
    );
  }

  return (
    <MembersField
      values={values}
      getFieldDecorator={getFieldDecorator}
      currentTaskType={currentTaskType}
      data-qa="qa-8bwsnl9b4gr58s6"
      label={label}
    />
  );
};

ActionTypeMembers.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  relatedProjectId: PropTypes.string,
  values: PropTypes.shape({ members: PropTypes.array }),
  currentTaskType: PropTypes.string
};

ActionTypeMembers.defaultProps = {
  relatedProjectId: undefined,
  values: {},
  currentTaskType: undefined
};

export default ActionTypeMembers;
