import { useState, useCallback } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

import { config } from 'config';

import MentionItem from './item';
import { $createMentionNode } from '../../nodes/mention-node';
import useMention from './use-mention';
import {
  MAX_MENTION_ITEMS_COUNT,
  MENTION_TYPE_EMPLOYEE,
  getMentionStyle,
  getPossibleQueryMatch,
  renderItemsTitle
} from './utils';

import styles from './mention.module.scss';

export const MentionPlugin = ({ setMentionedEmployees, destination }) => {
  const [editor] = useLexicalComposerContext();

  const [mentionString, setMentionString] = useState(null);

  const { results, isLoading, clearResults } = useMention({
    mentionString,
    destination
  });

  const { t } = useTranslation('CommonChat');

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const onSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const { mentionText, id, mentionType } = selectedOption;

        const mentionNode = $createMentionNode({
          mentionText,
          id,
          mentionType
        });

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        mentionNode.select();
        closeMenu();

        if (mentionType === MENTION_TYPE_EMPLOYEE) {
          setMentionedEmployees(prev => [...prev, selectedOption]);
        }
      });
    },
    [editor, setMentionedEmployees]
  );

  const checkForMentionMatch = useCallback(
    text => {
      if (text.indexOf('@') === -1) {
        return null;
      }

      const slashMatch = checkForSlashTriggerMatch(text, editor);

      if (slashMatch !== null) {
        return null;
      }

      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <>
      <LexicalTypeaheadMenuPlugin
        onQueryChange={setMentionString}
        onSelectOption={onSelectOption}
        triggerFn={checkForMentionMatch}
        onClose={() => {
          clearResults();
          setMentionString(null);
        }}
        options={results}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
        ) => {
          if (!anchorElementRef.current || (!results.length && !isLoading)) {
            return null;
          }

          return ReactDOM.createPortal(
            <div
              className={styles.mention}
              style={getMentionStyle({
                anchorRef: anchorElementRef.current
              })}
            >
              {isLoading
                ? [...Array(MAX_MENTION_ITEMS_COUNT).keys()].map(key => (
                    <Skeleton
                      active
                      key={`mention-skeleton-${key}`}
                      paragraph={false}
                      title={{
                        width: 'auto',
                        className: classnames(
                          styles.skeletonTitle,
                          styles.mentionItem
                        )
                      }}
                    />
                  ))
                : results.map((option, i) => (
                    <>
                      {renderItemsTitle({ results, index: i, t })}

                      <MentionItem
                        key={option.id}
                        item={option}
                        setSelected={() => {
                          setHighlightedIndex(i);
                          selectOptionAndCleanUp(option);
                        }}
                        className={classnames(styles.mentionItem, {
                          [styles.active]: selectedIndex === i,
                          [styles.aiAssistant]:
                            option.entity &&
                            option.entity.userId ===
                              config.REACT_APP_AI_ASSISTANT_USER_ID
                        })}
                        onMouseEnter={() => {
                          setHighlightedIndex(i);
                        }}
                      />
                    </>
                  ))}
            </div>,
            anchorElementRef.current
          );
        }}
      />
    </>
  );
};

MentionPlugin.propTypes = {
  setMentionedEmployees: PropTypes.func,
  destination: PropTypes.shape({
    entityType: PropTypes.string,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isDescriprion: PropTypes.bool
  })
};

MentionPlugin.defaultProps = {
  setMentionedEmployees: () => {},
  destination: {}
};

export default MentionPlugin;
