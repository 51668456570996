import i18n from 'i18n-config';

import { daysTranslations, timezonesOptions } from './constants';

const defaultIntervals = [
  {
    startTime: '09:00',
    endTime: '18:00'
  }
];

export const transformScheduleConfigValues = editorValues => {
  const schedule = Object.keys(daysTranslations).reduce((acc, day) => {
    acc[day] =
      editorValues && editorValues.schedule[day].length
        ? editorValues.schedule[day]
        : defaultIntervals;

    return acc;
  }, {});

  if (editorValues) {
    return {
      ...editorValues,
      nonWorkingTimeMessage: {
        description: editorValues.nonWorkingTimeMessage
      },
      timezone: timezonesOptions.find(
        ({ value }) => value === editorValues.timezone
      ),
      days: Object.assign(
        ...Object.entries(editorValues.schedule).map(([day, intervals]) => ({
          [day]: !!intervals.length
        }))
      ),
      schedule
    };
  }

  return {
    isActive: false,
    timezone: timezonesOptions.find(o => o.value === 'Europe/Minsk'),
    days: Object.keys(daysTranslations).reduce((acc, day) => {
      acc[day] = true;

      return acc;
    }, {}),
    schedule,
    nonWorkingTimeMessage: {
      description: i18n.t('NonWorkingTimeMessage', { ns: 'ChannelCreated' })
    }
  };
};

export const transformSubmitScheduleConfigValues = values => {
  const transformScheduleValues = Object.entries(values.schedule).map(
    ([key, value]) => ({
      [key]: [key, values.days[key] ? value : []]
    })
  );

  return {
    isActive: values.isActive,
    nonWorkingTimeMessage: values.nonWorkingTimeMessage.description,
    workingTimeMessage: values.workingTimeMessage,
    schedule: Object.fromEntries(
      transformScheduleValues.map(item => Object.values(item)).flat()
    ),
    timezone: values.timezone.value
  };
};

export const getDisabledHoursEnd = (value, day, index) => {
  const startHour = parseInt(value[day][index].startTime.split(':')[0], 10);

  return Array.from({ length: startHour }, (_, i) => i);
};

export const getDisabledMinutesEnd = (value, day, index, selectedHour) => {
  const startHour = parseInt(value[day][index].startTime.split(':')[0], 10);
  const startMinute = parseInt(value[day][index].startTime.split(':')[1], 10);

  if (selectedHour === startHour) {
    return Array.from({ length: startMinute }, (_, i) => i);
  }

  return [];
};

export const getDisabledHoursStart = (value, day, index) => {
  const endHour = parseInt(value[day][index].endTime.split(':')[0], 10);

  return Array.from({ length: 24 - endHour }, (_, i) => endHour + i + 1);
};

export const getDisabledMinutesStart = (value, day, index, selectedHour) => {
  const endHour = parseInt(value[day][index].endTime.split(':')[0], 10);
  const endMinute = parseInt(value[day][index].endTime.split(':')[1], 10);

  if (selectedHour === endHour) {
    return Array.from({ length: endMinute + 60 }, (_, i) => endMinute + i);
  }

  return [];
};

export const validateStartMinutes = (value, day, index) => {
  const startHour = parseInt(value[day][index].startTime.split(':')[0], 10);
  const endHour = parseInt(value[day][index].endTime.split(':')[0], 10);
  const endMinute = parseInt(value[day][index].endTime.split(':')[1], 10);
  const startMinute = parseInt(value[day][index].startTime.split(':')[1], 10);

  if (endHour === startHour) {
    if (startMinute > endMinute) {
      return i18n.t('StartTimeValidation', { ns: 'Errors' });
    }
  }

  return undefined;
};
