import React from 'react';
// eslint-disable-next-line no-restricted-syntax
import { Pagination as AntdPagination } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import CustomSelect from '../controls/custom-select';
import Button from '../button';
import Icon from '../icon';
import Typography from '../typography';

import styles from './pagination.module.scss';

const Pagination = ({
  currentPage,
  onChangePage,
  onChangePageSize,
  totalItems,
  itemsPerPage,
  showSizeChanger,
  isDisabled,
  pageSizeOptions,
  rootClassName,
  paginationProps,
  simplePaginationProps
}) => {
  const { t } = useTranslation('Common');

  const options = [
    {
      value: 25,
      label: t('NumberOnPage', { number: 25 })
    },
    {
      value: 50,
      label: t('NumberOnPage', { number: 50 })
    },
    {
      value: 100,
      label: t('NumberOnPage', { number: 100 })
    }
  ];

  const pageSizeValue = options.find(o => o.value === itemsPerPage);

  if (paginationProps.simple) {
    const { arrowSize, textProps } = simplePaginationProps;

    return (
      <div className={classnames(styles.simpleRoot, rootClassName)}>
        <Button
          type="text"
          mood="none"
          size="small"
          disabled={currentPage === 1}
          className={styles.arrowBtn}
          style={arrowSize ? { height: arrowSize } : {}}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <Icon type="arrow" side="left" size={arrowSize} />
        </Button>

        <Typography.Text weight="semibold" size="large" {...textProps}>
          {`${currentPage}/${totalItems}`}
        </Typography.Text>

        <Button
          type="text"
          mood="none"
          size="small"
          className={styles.arrowBtn}
          disabled={currentPage === totalItems}
          style={arrowSize ? { height: arrowSize } : {}}
          onClick={() => onChangePage(currentPage + 1)}
        >
          <Icon type="arrow" side="right" size={arrowSize} />
        </Button>
      </div>
    );
  }

  return (
    <div className={classnames(styles.root, rootClassName)}>
      <AntdPagination
        current={currentPage}
        onChange={onChangePage}
        total={totalItems}
        pageSize={itemsPerPage}
        hideOnSinglePage
        disabled={isDisabled}
        {...paginationProps}
      />

      {showSizeChanger && (
        <CustomSelect
          value={pageSizeValue}
          onChange={option => onChangePageSize(option.value)}
          options={pageSizeOptions || options}
          isDisabled={isDisabled}
          className={styles.pageSizeSelect}
        />
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  showSizeChanger: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChangePageSize: PropTypes.func,
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  rootClassName: PropTypes.string,
  paginationProps: PropTypes.object,
  simplePaginationProps: PropTypes.shape({
    arrowSize: PropTypes.number,
    textProps: PropTypes.object
  })
};

Pagination.defaultProps = {
  showSizeChanger: true,
  isDisabled: false,
  onChangePageSize: () => {},
  pageSizeOptions: null,
  rootClassName: undefined,
  paginationProps: {},
  simplePaginationProps: {}
};

export default Pagination;
