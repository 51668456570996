import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ONLY_THIS, ACCEPT_SCHEDULER_MEETING_OPTIONS } from 'constants/index';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import { FormRadio } from 'components/common/hook-form';

import styles from './accept-scheduler-meeting.module.scss';

export const AcceptSchedulerMeetingModal = ({
  isLoading,
  visible,
  onClose,
  onSubmit
}) => {
  const methods = useForm({
    changingScheduler: ONLY_THIS
  });

  const { t } = useTranslation('ConfirmMeetParticipation');

  return (
    <Modal
      width={508}
      visible={visible}
      title={t('ConfirmMeetParticipationHeading')}
      centered
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormRadio
            name="acceptMeeting"
            options={ACCEPT_SCHEDULER_MEETING_OPTIONS}
          />

          <Button
            htmlType="submit"
            type="primary"
            width="expanded"
            loading={isLoading}
          >
            {t('SaveBtn')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

AcceptSchedulerMeetingModal.propTypes = {
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

AcceptSchedulerMeetingModal.defaultProps = {
  isLoading: false,
  visible: false
};

export default AcceptSchedulerMeetingModal;
