import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';
import AccountAddon from 'components/settings-view/billing/addons/account-addon';
import Button from 'components/common/button';

import { getAvailableAddons } from 'store/billing';

import styles from './choose-addons.module.scss';

const ChooseAddonsStep = ({
  selectedTariff,
  addedAddons,
  changeAddedAddons,
  onGoBack,
  onSubmit
}) => {
  const availableAddons = useSelector(getAvailableAddons);

  const { t } = useTranslation('ChangePlan');

  return (
    <div className={styles.root}>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {t('AdditionalPaidFeaturesHeading')}
      </Typography.Title>

      <div className={styles.addons}>
        {availableAddons.map(a => (
          <AccountAddon
            availableAddon={a}
            addon={addedAddons[a.id]}
            changeAddon={changeAddedAddons}
            selectedTariff={selectedTariff}
          />
        ))}
      </div>

      <div className={styles.buttons}>
        <Button type="secondary" className={styles.button} onClick={onGoBack}>
          {t('BackBtn')}
        </Button>

        <Button type="primary" className={styles.button} onClick={onSubmit}>
          {t('NextBtn')}
        </Button>
      </div>
    </div>
  );
};

ChooseAddonsStep.propTypes = {
  selectedTariff: PropTypes.shape({
    tariff: PropTypes.object,
    period: PropTypes.string,
    currency: PropTypes.string
  }).isRequired,
  addedAddons: PropTypes.object.isRequired,
  changeAddedAddons: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

ChooseAddonsStep.defaultProps = {
  onGoBack: null
};

export default ChooseAddonsStep;
