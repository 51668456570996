const emailsMapValues = values => {
  const uniqueEmails = {};
  const transformedEntries = [];

  values.forEach(item => {
    if (item.email) {
      uniqueEmails[item.email] = true;

      transformedEntries.push({
        ...item,
        email: item.email
      });
    }

    for (let i = 1; item[`email${i}`]; i++) {
      if (!uniqueEmails[item[`email${i}`]]) {
        uniqueEmails[item[`email${i}`]] = true;

        transformedEntries.push({
          ...item,
          email: item[`email${i}`]
        });
      }
    }
  });

  return transformedEntries.map(value => ({
    value: value.email,
    label: {
      ...value,
      workspaceTitle: value.company.isIndividual ? '' : value.company.title
    }
  }));
};

export default emailsMapValues;
