export const addImagePrefixToBase64 = (base64String, onlyFormat) => {
  // Base64 encoded image formats
  const formats = {
    '/9j/': 'jpeg',
    iVBORw0KGgo: 'png',
    R0lGODlh: 'gif',
    PHN2Zy: 'svg+xml',
    PD94bW: 'svg+xml',
    UklGR: 'webp'
  };

  for (const format in formats) {
    if (base64String.startsWith(format)) {
      if (onlyFormat) {
        return `image/${formats[format]}`;
      }

      return `data:image/${formats[format]};base64,${base64String}`;
    }
  }

  return null;
};

export default addImagePrefixToBase64;
