import { LOCATION_CHANGE } from 'connected-react-router';

import handleActions from '../../utils/redux-actions';
import {
  fetchGuides,
  fetchVideo,
  setCurrentPage,
  setFilterSearch
} from './actions';

const initialFilter = { parent: 41, search: '', withContent: true };

const initialState = {
  isLoading: false,
  isLoaded: false,

  filter: initialFilter,

  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 12,

  entries: [],
  videos: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: state => {
      state.filter = initialFilter;
      state.currentPage = 1;

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;
      state.currentPage = 1;

      return state;
    },

    [fetchGuides.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchGuides.FAILED]: state => {
      state.isLoaded = false;

      return state;
    },

    [fetchGuides.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchGuides.SUCCEEDED]: (state, { payload }) => {
      state.totalItems = payload.count;
      state.entries = payload.results;
      state.isLoaded = true;

      return state;
    },

    [fetchVideo.SUCCEEDED]: (state, { payload }) => {
      state.videos = [...state.videos, payload];

      return state;
    }
  },
  initialState
);
