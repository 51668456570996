import React from 'react';
import { notification, Input } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation, useTranslation } from 'react-i18next';

import CopyLink from 'components/common/copy-link';

import styles from './code-copy.module.scss';

export const CodeCopy = ({ link, disabled, message, className }) => {
  const { t } = useTranslation('ChannelCreated');

  const onCopyCode = () =>
    notification.success({
      message,
      duration: 2
    });

  return (
    <div className={classnames(styles.root, className)}>
      <Input.TextArea
        readOnly
        autoSize={{
          minRow: 1,
          maxRows: 4
        }}
        disabled={disabled}
        value={link}
        className={styles.textarea}
      />

      <CopyLink
        link={link}
        disabled={disabled}
        onCopy={onCopyCode}
        className={styles.btnCopy}
      >
        {t('CopyLinkBtn')}
      </CopyLink>
    </div>
  );
};

CodeCopy.propTypes = {
  link: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  message: PropTypes.string
};

CodeCopy.defaultProps = {
  disabled: false,
  message: <Translation ns="Common">{t => t('CodeCopied')}</Translation>
};

export default CodeCopy;
