import { Skeleton } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './skeleton.module.scss';

const SkeletonMessage = ({ isOutgoing }) => (
  <div
    className={classnames(styles.message, { [styles.outgoing]: isOutgoing })}
  >
    {!isOutgoing && (
      <Skeleton
        avatar
        active
        title={false}
        paragraph={false}
        className={styles.avatar}
      />
    )}

    <Skeleton
      active
      title={{
        className: styles.title
      }}
      paragraph={{
        rows: 3,
        className: styles.paragraph
      }}
      className={classnames(styles.content, { [styles.outgoing]: isOutgoing })}
    />
  </div>
);

SkeletonMessage.propTypes = {
  isOutgoing: PropTypes.bool
};

SkeletonMessage.defaultProps = {
  isOutgoing: false
};

export default SkeletonMessage;
