import { createAction } from 'redux-actions';
import api from 'api';

import { TYPE_CHANNEL_CHAT } from 'constants/index';

import createActionThunk from 'store/actions-thunk';
import { getWorkspaceId } from 'store/workspace';
import { fetchTagsByEntity } from 'store/tags';

import mapValue from 'utils/map-value';

import { getItemsPerPage, getFilterDialogues, getDialogues } from './selectors';

// DELETE
export const fetchDialogues = createActionThunk(
  'dialogues/fetch-list',
  ({ getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const {
      search,
      status,
      kind,
      channel,
      responsible,
      createdAtGte,
      createdAtLte,
      tag
    } = getFilterDialogues(state);

    const hasTags = !!tag.ids.length;
    const tagsCondition = tag.condition.value;
    const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

    const filter = {
      search,
      status: status.map(mapValue),
      kind: kind.map(mapValue),
      channel: channel.map(mapValue),
      responsible: responsible.map(mapValue),
      created_at_gte: createdAtGte,
      created_at_lte: createdAtLte,
      tags_condition: tagsCondition,
      tags_ids: tagsIds
    };

    const limit = getItemsPerPage(state);
    const offset = getDialogues(state).length;

    return api.dialogues
      .fetch({
        workspaceId,
        limit,
        offset,
        ...filter
      })
      .then(data => {
        const dialogIds = data.results.map(dialog => dialog.id);

        if (dialogIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_CHANNEL_CHAT,
              entityIds: dialogIds
            })
          );
        }

        return data;
      });
  }
);

export const fetchDialoguesLocal = createActionThunk(
  'dialogues/fetch-list-local',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const {
      search,
      status = [],
      kind = [],
      channel = [],
      responsible = [],
      createdAtGte,
      createdAtLte,
      tag = {
        ids: [],
        condition: {}
      },
      limit,
      offset
    } = params;

    const hasTags = !!tag.ids.length;
    const tagsCondition = tag.condition.value;
    const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

    const filter = {
      search,
      status: status.map(mapValue),
      kind: kind.map(mapValue),
      channel: channel.map(mapValue),
      responsible: responsible.map(mapValue),
      created_at_gte: createdAtGte,
      created_at_lte: createdAtLte,
      tags_condition: tagsCondition,
      tags_ids: tagsIds
    };

    return api.dialogues.fetch({
      workspaceId,
      limit,
      offset,
      ...filter
    });
  }
);

export const fetchDialog = createActionThunk(
  'dialogues/fetch',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues.fetchOne({ workspaceId, id });
  }
);

export const fetchChannelChatByUuid = createActionThunk(
  'dialogues/fetch-uuid',
  ({ uuid, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues
      .fetchOneByUuid({ workspaceId, uuid })
      .then(({ data }) => data);
  }
);

export const fetchDialogByUuidLocal = createActionThunk(
  'dialogues/fetch-uuid0-local',
  ({ uuid, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues
      .fetchOneByUuid({ workspaceId, uuid })
      .then(data => data);
  }
);

export const editDialog = createActionThunk(
  'dialogues/edit',
  ({ dialog, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues.edit({ workspaceId, ...dialog });
  }
);

export const archiveDialog = createActionThunk(
  'dialogues/archive',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues.archive({ workspaceId, id });
  }
);

export const restoreDialog = createActionThunk(
  'dialogues/restore',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.dialogues.restore({ workspaceId, id });
  }
);

// RELATIONS
export const fetchRelations = createActionThunk(
  'dialogues/fetch-relations',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.dialogues
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const changeRelations = createActionThunk(
  'dialogues/change-relations',
  ({ getState, id, relations }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.dialogues.changeRelations({ workspaceId, id, relations });
  }
);

export const editDialogSync = createAction(
  'dialogues/edit-dialog-from-contact-info'
);

export const joinChatRooms = createAction('dialogues/join-chat-rooms');
export const sendChatMessage = createAction('dialogues/send-chat-message');
export const getChatMessagesLoading = createAction(
  'dialogues/get-chat-messages/loading'
);
export const getChatMessages = createAction(
  'dialogues/get-chat-messages/loaded'
);

export const setFilterSearch = createAction('dialogues/set-filter-search');

export const setFilterStatus = createAction('dialogues/set-filter-status');

export const setFilterKind = createAction('dialogues/set-filter-kind');

export const setFilterChannel = createAction('dialogues/set-filter-channel');

export const setFilterTag = createAction('dialogues/set-filter-tag');

export const setFilterResponsible = createAction(
  'dialogues/set-filter-responsible'
);
export const setFilterCreatedAtGte = createAction(
  'dialogues/set-filter-created-at-gte'
);
export const setFilterCreatedAtLte = createAction(
  'dialogues/set-filter-created-at-lte'
);
