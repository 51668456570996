import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_TIME_FORMAT } from 'constants/index';

import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';

import { getIsOutdated } from 'utils/get-is-outdated';

import styles from './validity-expiration.module.scss';

const ValidityExpiration = ({ children, dateEnd, className }) => {
  const { t } = useTranslation('Journals');

  const isOutdated = getIsOutdated(dateEnd);

  if (!isOutdated || !dateEnd) {
    return children;
  }

  return (
    <div className={styles.root}>
      <div className={classNames(styles.children, className)}>{children}</div>

      <Tooltip
        title={t('ValidUntilTooltip', {
          date: moment(dateEnd).format(DATE_TIME_FORMAT)
        })}
      >
        <Icon type="outdated" size={16} color="yellow-semi" />
      </Tooltip>
    </div>
  );
};

ValidityExpiration.propTypes = {
  dateEnd: PropTypes.string,
  className: PropTypes.string
};

ValidityExpiration.defaultProps = {
  dateEnd: null,
  className: undefined
};

export default ValidityExpiration;
