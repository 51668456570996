import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_PICKER_TIME_FORMAT
} from 'constants/index';

import DatePicker from 'components/common/date-picker';
import Icon from 'components/common/icon';

import styles from './date-range.module.scss';

const DATE_START = 'startDate';
const DATE_END = 'endDate';

const getFromattedDate = (date, format, isDateEnd) => {
  if (!date) {
    return date;
  }

  if (
    isDateEnd &&
    !moment(date)
      .toDate()
      .getHours()
  ) {
    const transformedDate = moment(date).set({
      hours: 23,
      minutes: 59
    });

    return format ? transformedDate.format(format) : transformedDate.toDate();
  }

  if (format) {
    return moment(date).format(format);
  }

  return moment(date).toDate();
};

export const DateRangeSelect = ({
  value,
  onChange,
  label,
  rootClassName,
  className,
  disabled,
  isShowTimeSelect,
  format,
  datePickerProps,
  allowSetBeforeCurrentDate
}) => {
  const { t } = useTranslation('Filters');

  const [startDate, endDate] = (value || {}).value || [];

  const makeChange = type => newValue => {
    const isStartDate = type === DATE_START;

    const dateEnd = isStartDate
      ? endDate
      : getFromattedDate(newValue, format, true);
    const dateStart = isStartDate
      ? getFromattedDate(newValue, format, false)
      : startDate;

    const results = [dateStart, dateEnd];
    const hasResultValue = !!results.filter(Boolean).length;

    onChange(hasResultValue ? { label: [], value: results } : {});
  };

  const getDate = date => date && moment(date, format).toDate();

  const resultDatePickerProps = {
    dateFormat: DATE_PICKER_TIME_FORMAT,
    showTimeSelect: isShowTimeSelect,
    popperClassName: styles.popperClassName,
    wrapperClassname: styles.date,
    startDate: getDate(startDate),
    endDate: getDate(endDate),
    ...datePickerProps
  };

  return (
    <div className={classnames(styles.rootWrap, rootClassName)}>
      {label && <span className={styles.label}>{label}</span>}

      <div
        data-error
        className={classnames(styles.root, className, {
          [styles.disabled]: disabled
        })}
      >
        <DatePicker
          selected={getDate(startDate)}
          onChange={makeChange(DATE_START)}
          placeholderText={t('FromDate')}
          showIcon={false}
          selectsStart
          className={styles.input}
          isClearable={!!startDate && !disabled}
          disabled={disabled}
          minDate={allowSetBeforeCurrentDate ? undefined : new Date()}
          maxDate={endDate ? getDate(endDate) : undefined}
          {...resultDatePickerProps}
        />

        <Icon
          type="arrow"
          side="right"
          color="black-55"
          size={20}
          className={styles.icon}
        />

        <DatePicker
          selected={getDate(endDate)}
          onChange={makeChange(DATE_END)}
          placeholderText={t('ToDate')}
          showIcon={!endDate}
          popperPlacement="bottom-end"
          selectsEnd
          minDate={getDate(startDate)}
          className={styles.input}
          isClearable={!!endDate && !disabled}
          disabled={disabled}
          {...resultDatePickerProps}
        />
      </div>
    </div>
  );
};

DateRangeSelect.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.array
  }),
  onChange: PropTypes.func,
  label: PropTypes.string,
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isShowTimeSelect: PropTypes.bool,
  format: PropTypes.string,
  datePickerProps: PropTypes.object,
  allowSetBeforeCurrentDate: PropTypes.bool
};

DateRangeSelect.defaultProps = {
  value: {},
  onChange: () => {},
  label: undefined,
  rootClassName: undefined,
  className: undefined,
  isDisabled: false,
  isShowTimeSelect: true,
  format: `${DATE_FORMAT} ${TIME_FORMAT}`,
  datePickerProps: {},
  allowSetBeforeCurrentDate: true
};

export default DateRangeSelect;
