import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormEmployeeSelect, FormSwitch } from 'components/common/hook-form';

import styles from './manage-subscribers-single-attachment.module.scss';

const ManageSubscribersSingleAttachment = () => {
  const form = useFormContext();
  const { t } = useTranslation(['FileSubscribers', 'AddFile']);

  const watchAllowSubscriberSelection = form.watch('allowSubscriberSelection');

  return (
    <div className={styles.root}>
      <FormSwitch
        name="allowSubscriberSelection"
        label={t('SubscribeEmployeesToggle', { ns: 'AddFile' })}
      />

      {watchAllowSubscriberSelection && (
        <FormEmployeeSelect
          name="subscribers"
          label={t('Subscribers')}
          isMulti
          params={{
            isPostMethod: true
          }}
        />
      )}
    </div>
  );
};
export default ManageSubscribersSingleAttachment;
