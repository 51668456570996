import React from 'react';

export const MoneyCollectIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_51509_2926)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4774 11.1006L11.0172 11.2154C12.3942 11.5267 13.3313 12.1298 13.3334 13.48C13.3334 14.943 12.1434 15.7582 10.4796 15.8924L10.4838 16.5092C10.4838 16.5967 10.4073 16.6667 10.3138 16.6667H9.71034C9.61684 16.6667 9.54034 16.5967 9.54034 16.5111V15.9002C7.64911 15.7737 6.763 14.7893 6.66738 13.7193C6.65888 13.6278 6.7375 13.55 6.83738 13.55H7.81911C7.90199 13.55 7.97424 13.6045 7.98698 13.6784C8.09536 14.2971 8.62235 14.7562 9.56159 14.871V12.0948L9.03672 11.9722C7.92536 11.729 6.86713 11.0948 6.86713 9.77959C6.86713 8.35935 8.04436 7.59865 9.54884 7.46441V6.82239C9.54884 6.73679 9.62534 6.66675 9.71883 6.66675H10.316C10.4095 6.66675 10.4859 6.73679 10.4859 6.82239V7.45858C11.9416 7.59282 13.0317 8.37103 13.1613 9.58309C13.1698 9.67453 13.0912 9.75429 12.9913 9.75429H12.0372C11.9522 9.75429 11.8799 9.69787 11.8693 9.62005C11.7843 9.05196 11.2849 8.58893 10.4774 8.48776V11.1006ZM9.24072 10.8029C9.34059 10.8399 9.44046 10.8691 9.55946 10.9002V8.48776C8.77322 8.5792 8.18886 8.98192 8.18886 9.68815C8.18886 10.1823 8.52248 10.5656 9.24072 10.8029ZM10.4774 12.2757V14.8769C11.3827 14.8029 12.0074 14.3457 12.0074 13.585C12.0074 12.9878 11.6696 12.5987 10.6644 12.3185C10.6028 12.3068 10.5433 12.2932 10.4774 12.2757Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0611 0.818742L19.5852 9.94701C19.8522 10.2296 20 10.6057 20 11.0028C20.0007 11.1993 19.9646 11.394 19.8938 11.5756C19.823 11.7572 19.7189 11.9222 19.5874 12.0609C19.3205 12.3435 18.9653 12.5 18.5881 12.5H17.5V20H2.5V12.5H1.43181C0.655283 12.5 0.00891557 11.8436 9.14393e-05 11.0215C-0.00205299 10.8221 0.0335409 10.6243 0.104772 10.4398C0.176004 10.2553 0.281429 10.0877 0.414825 9.94701L9.51029 0.213781C9.64048 0.0768506 9.81653 0 10 0C10.1835 0 10.3596 0.0768506 10.4898 0.213781L11.0611 0.818742ZM15.8529 18.3372V10.8333H18.1623L10.5118 2.61494L10.0022 2.07538L1.83992 10.8333H4.16666V18.3372H5.88087H14.9403H15.8529Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_51509_2926">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MoneyCollectIcon;
