import React, { useState } from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, checkIsLegatCountry } from 'constants/index';

import Button from 'components/common/button';
import FormInput from 'components/common/hook-form/input';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import FormVatNumber from 'components/common/hook-form/vat-number';
import {
  FormCheckbox,
  FormCountrySelect,
  FormDatePicker
} from 'components/common/hook-form';

import { joinWorkspace } from 'store/workspace';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import AddFieldModal from './add-field-modal';
import AccountFields from './account-fields';

import styles from '../styles.module.scss';

const EditWorkspaceRequisitesForm = ({
  workspace,
  isVerification,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation(['EditLegalInfo', 'Errors']);

  const isLegatCountry = checkIsLegatCountry(workspace.country.alpha2Code);

  const methods = useForm({
    defaultValues: {
      address: workspace.address,
      totalEmployees: workspace.totalEmployees,
      headFullName: workspace.headFullName,
      basedAct: workspace.basedAct,
      postCode: workspace.postCode,
      bankDetails: (workspace.bankDetails || []).length
        ? workspace.bankDetails
        : [
            {
              iban: workspace.iban,
              isIbanFormat: workspace.isIbanFormat,
              swift: workspace.swift,
              currency: workspace.country.currencies[0],
              additionalInfo: undefined
            }
          ],
      additionalFields: workspace.additionalFields || [],
      hasNotMyCountry:
        workspace.isNotVerified &&
        !isVerification &&
        (!!workspace.vatNumber || !isLegatCountry),
      country:
        workspace.isNotVerified && (!!workspace.vatNumber || !isLegatCountry)
          ? {
              label: workspace.country,
              value: workspace.country.id
            }
          : undefined,
      vatNumber: workspace.vatNumber,
      title: workspace.vatNumber ? workspace.title : null,
      foundationDate: workspace.foundationDate
        ? moment(workspace.foundationDate, DATE_FORMAT).toDate()
        : null
    }
  });

  const dispatch = useDispatch();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'additionalFields'
  });

  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const legatCompany = methods.watch('legatCompany');
  const hasNotMyCountry = methods.watch('hasNotMyCountry');

  const showAddressField = workspace.isIe || hasNotMyCountry;

  const isDisabledSubmit =
    workspace.isNotVerified &&
    (!hasNotMyCountry || isVerification) &&
    (!legatCompany || !legatCompany.isActive);

  const isJoin = legatCompany && legatCompany.id && !hasNotMyCountry;

  const handleSubmit = async ({
    bankDetails = [],
    additionalFields = [],
    hasNotMyCountry: _,
    addres,
    country,
    vatNumber,
    title,
    foundationDate,
    legatCompany: company,
    ...values
  }) => {
    try {
      setIsLoading(true);

      if (isJoin) {
        await dispatch(joinWorkspace({ workspaceId: legatCompany.id }));

        showNoticeMessage({ number: NOTICE_NUMBER.joinRequestSended });
        return onClose();
      }

      const accounts = bankDetails.map(item => ({
        ...item,
        swift: (item.swift || '').toUpperCase(),
        isIbanFormat: item.isIbanFormat,
        iban: (item.iban || '').replace(/\s/g, '').toUpperCase(),
        currency:
          typeof item.currency === 'string'
            ? item.currency
            : item.currency.value
      }));

      const getVerifiedData = () => {
        if (!workspace.isNotVerified) {
          return {};
        }

        return company && !hasNotMyCountry
          ? { ...company }
          : {
              country: country.value,
              vatNumber,
              title,
              aliasTitle: title,
              foundationDate: moment(foundationDate).format(DATE_FORMAT)
            };
      };

      await onSubmit({
        workspace: {
          id: workspace.id,
          bankDetails: accounts,
          additionalFields: additionalFields.filter(f => !!f.value),
          addres: showAddressField ? addres : undefined,
          ...getVerifiedData(),
          ...values
        }
      });

      return showNoticeMessage({
        number: NOTICE_NUMBER.companyRequistiesUpdated
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <div>
            {workspace.isNotVerified && (
              <>
                {!isVerification && (
                  <FormCheckbox name="hasNotMyCountry">
                    <Typography.Text>
                      {t('CountryNotInAListChckbx')}
                    </Typography.Text>
                  </FormCheckbox>
                )}

                {hasNotMyCountry && !isVerification ? (
                  <div className={styles.withoutCountryWrap}>
                    <FormCountrySelect
                      name="country"
                      valueText={t('ChooseCountry')}
                      label={t('Country')}
                      rules={{
                        required: t('RequiredField', { ns: 'Errors' })
                      }}
                      isClearable={false}
                      params={{
                        isRegistration: false
                      }}
                    />

                    <FormInput
                      label={t('CompanyRegistrationNumber')}
                      placeholder={t('EnterCompanyRegistrationNumber')}
                      name="vatNumber"
                      rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
                    />

                    <FormInput
                      label={t('CompanyName')}
                      placeholder={t('EnterCompanyName')}
                      name="title"
                      rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
                    />
                  </div>
                ) : (
                  <FormVatNumber
                    name="legatCompany"
                    defaultCountry={
                      isLegatCountry ? { id: workspace.country.id } : undefined
                    }
                  />
                )}
              </>
            )}

            <div className={styles.dateAndEmployees}>
              {workspace.isNotVerified && hasNotMyCountry && (
                <FormDatePicker
                  label={t('FoundationDate')}
                  name="foundationDate"
                  showTimeSelect={false}
                  maxDate={new Date()}
                  rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
                  wrapperClassname={styles.foundationDate}
                />
              )}

              <FormInput
                name="totalEmployees"
                label={t('EmployeesAmountOptional')}
                placeholder={t('EmployeesAmountOptionalPlchldr')}
              />
            </div>

            <FormInput
              name="headFullName"
              label={t('HeadOfCompanyName')}
              placeholder={t('HeadOfCompanyNamePlchldr')}
              rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            />

            <FormInput
              name="basedAct"
              label={t('ActOnBasis')}
              placeholder={t('ActOnBasisPlchldr')}
              rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            />

            {showAddressField && (
              <FormInput
                name="address"
                label={t('Email')}
                placeholder={t('Email')}
                rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
              />
            )}

            <FormInput
              name="postCode"
              label={t('MailAddressOptional')}
              placeholder={t('MailAddress')}
            />

            {fields.map((field, index) => (
              <div
                key={field.key}
                style={{
                  display: 'flex'
                }}
              >
                <div style={{ width: '100%' }}>
                  {/* Used as label and placeholder
                      This implementation is needed to create a field with the name we need
                      And send the value of this field to the back
                  */}
                  <FormInput
                    label={field.name}
                    name={`additionalFields.${index}.name`}
                    defaultValue={field.name}
                    itemProps={{
                      style: {
                        margin: 0
                      }
                    }}
                    style={{
                      display: 'none'
                    }}
                  />

                  <FormInput
                    placeholder={field.name}
                    name={`additionalFields.${index}.value`}
                    defaultValue={field.value}
                  />
                </div>

                <Button
                  type="text"
                  mood="none"
                  style={{
                    height: 'auto',
                    padding: 0,
                    margin: '4px 0 0 4px'
                  }}
                  onClick={() => remove(index)}
                >
                  <Icon type="close" color="black-55" />
                </Button>
              </div>
            ))}

            <Button
              type="link"
              size="large"
              style={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                padding: 0
              }}
              onClick={() => setVisibleModal(true)}
            >
              <Icon type="plus" />
              {t('AddOwnField')}
            </Button>
          </div>

          <Divider className={styles.divider} />

          <div>
            <Typography.Title level={3} style={{ marginBottom: 24 }}>
              {t('BankDetailsHeading')}
            </Typography.Title>

            <AccountFields
              name="bankDetails"
              control={methods.control}
              workspace={workspace}
            />
          </div>

          <Button
            type="primary"
            size="large"
            width="expanded"
            onClick={isJoin ? () => handleSubmit({ legatCompany }) : undefined}
            htmlType={isJoin ? undefined : 'submit'}
            disabled={isDisabledSubmit}
            loading={isLoading}
            style={{
              marginLeft: 'auto',
              marginTop: 'auto'
            }}
          >
            {isJoin ? t('JoinBtn') : t('SaveBtn')}
          </Button>
        </form>
      </FormProvider>

      <AddFieldModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onSubmit={append}
      />
    </>
  );
};

EditWorkspaceRequisitesForm.propsTypes = {
  isVerification: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  workspace: PropTypes.object.isRequired
};

EditWorkspaceRequisitesForm.defaultProps = {
  isVerification: false,
  onClose: () => {}
};

export default EditWorkspaceRequisitesForm;
