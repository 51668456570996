import { createAction } from 'redux-actions';

import createActionThunk from '../../actions-thunk';
import api from '../../../api';
import { getWorkspaceId } from '../../workspace';
import {
  getItemsPerPage,
  getAllEntriesLength,
  getFilterSearchIdeasPublic
} from './selectors';

export const fetchPublicIdeas = createActionThunk(
  'ideas/fetch-public',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const search = getFilterSearchIdeasPublic(state);
    const limit = getItemsPerPage(state);
    const offset = getAllEntriesLength(state);

    return api.ideas
      .fetchPublic(workspaceId, offset, limit, search)
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count,
        search
      }));
  }
);

export const fetchPublicIdea = createActionThunk(
  'ideas/fetch-idea-public',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.ideas.fetchOnePublic(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchAttachmentsPublic = createActionThunk(
  'ideas/fetch-attachments-public',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .fetchAttachmentsPublic(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const setFilterSearchPublic = createAction(
  'ideas/set-filter-search-public'
);
