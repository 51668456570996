import React from 'react';

export const StoryPointsIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.19 2.25C10.93 2.25 10.67 2.31 10.42 2.4L3.06001 5.45C2.57077 5.65035 2.18112 6.03678 1.97672 6.52433C1.77232 7.01189 1.7699 7.56066 1.97001 8.05L6.93001 20C7.07651 20.3625 7.32604 20.674 7.64773 20.8962C7.96941 21.1183 8.34916 21.2414 8.74001 21.25C9.00001 21.25 9.27001 21.22 9.53001 21.1L16.9 18.05C17.2557 17.899 17.5607 17.6492 17.7787 17.3302C17.9967 17.0111 18.1186 16.6363 18.13 16.25C18.14 16 18.09 15.71 18 15.45L13 3.5C12.8587 3.13402 12.6104 2.81906 12.2876 2.59612C11.9648 2.37317 11.5823 2.25257 11.19 2.25ZM14.67 2.25L18.12 10.6V4.25C18.12 3.71957 17.9093 3.21086 17.5342 2.83579C17.1591 2.46071 16.6504 2.25 16.12 2.25H14.67ZM20.13 3.79V12.82L22.56 6.96C22.6596 6.71777 22.7105 6.45825 22.7096 6.19633C22.7087 5.93441 22.6561 5.67524 22.5548 5.43369C22.4536 5.19214 22.3056 4.97295 22.1194 4.78871C21.9333 4.60446 21.7126 4.45877 21.47 4.36L20.13 3.79ZM11.19 4.22L16.17 16.24L8.78001 19.3L3.80001 7.29"
      fill="currentColor"
    />
  </svg>
);

export default StoryPointsIcon;
