import { TAG_TYPE_DATE } from './tag-types';
import { normalizeDate } from './normalize-date';

export const getInitialNameValue = (tagType, value) => {
  switch (tagType) {
    case TAG_TYPE_DATE:
      return value ? new Date(normalizeDate(value)) : '';

    default:
      return value;
  }
};
