export const getFIO = ({ firstName, lastName, email = '' }) =>
  lastName || firstName
    ? [(lastName || '').charAt(0), (firstName || '').charAt(0)].join('')
    : email && email.charAt(0);

export const getFullName = ({
  firstName,
  lastName,
  middleName,
  allName = false
} = {}) =>
  [lastName, firstName, allName ? middleName : ''].filter(Boolean).join(' ');

export default getFIO;
