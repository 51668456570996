import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Slider } from 'antd';

import InputNumber from 'components/common/controls/input-number';

import FormItem from '../form-item';

export const FormSlider = ({
  label,
  name,
  rules,
  itemProps,
  sliderProps,
  inputProps,
  transformValue
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value, onChange, ...props } = field;

          return (
            <div style={{ display: 'flex' }}>
              <Slider
                value={value}
                onChange={onChange}
                {...props}
                {...sliderProps}
              />

              <InputNumber
                value={value}
                onChange={e => onChange(transformValue ? transformValue(e) : e)}
                {...inputProps}
              />
            </div>
          );
        }}
      />
    </FormItem>
  );
};

FormSlider.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.any,
  label: PropTypes.string,
  itemProps: PropTypes.object,
  sliderProps: PropTypes.object,
  inputProps: PropTypes.object
};

FormSlider.defaultProps = {
  rules: {},
  label: undefined,
  itemProps: {},
  sliderProps: {},
  inputProps: {}
};

export default FormSlider;
