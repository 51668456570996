import { createAction } from 'redux-actions';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';
import api from '../../api';

export const fetchComments = createActionThunk(
  'comments/fetch-comments',
  ({ id, type, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.comments
      .fetchComments(workspaceId, id, type)
      .then(({ data }) => data);
  }
);

export const sendComment = createActionThunk(
  'comments/send',
  ({ id, comment, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const { entity, ...commentData } = comment;

    return api.comments
      .sendComment({
        workspaceId,
        id,
        entityType: entity,
        comment: commentData
      })
      .then(({ data }) => data);
  }
);

export const setReplyCommentState = createAction(
  'comments/set-reply-comment-state'
);
