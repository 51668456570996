import { useContext } from 'react';

import { FieldEditorContext } from './provider';

export const useFieldEditorContext = () => {
  const value = useContext(FieldEditorContext);

  return value;
};

export default useFieldEditorContext;
