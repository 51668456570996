import { createAction } from 'redux-actions';

import createActionThunk from '../../actions-thunk';
import api from '../../../api';

import { getWorkspaceId } from '../../workspace';
import {
  getItemsPerPage,
  getAllEntriesLength,
  getFilterSearchIdeas
} from './selectors';
import getFileIds from '../../../hooks/common/use-file-upload/get-file-ids';
import { convertMessageToArr } from '../../../components/common/comments/converters';

export const fetchIdeas = createActionThunk(
  'ideas/fetch-ideas',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const search = getFilterSearchIdeas(state);
    const limit = getItemsPerPage(state);
    const offset = getAllEntriesLength(state);

    return api.ideas
      .fetch(workspaceId, offset, limit, search)
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count,
        search
      }));
  }
);

export const fetchIdeasLocal = createActionThunk(
  'ideas/fetch-ideas-local',
  ({ search, offset, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const limit = getItemsPerPage(state);

    return api.ideas
      .fetch(workspaceId, offset, limit, search)
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count,
        search
      }));
  }
);

export const fetchIdea = createActionThunk(
  'ideas/fetch-idea',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.ideas.fetchOne(workspaceId, id).then(({ data }) => data);
  }
);

export const createIdea = createActionThunk(
  'ideas/create',
  ({ value, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const { fileList, description, links } = value;
    return api.ideas
      .create(workspaceId, {
        ...value,
        fileList: getFileIds(fileList),
        description: [{ text: description }, ...links.map(link => ({ link }))]
      })
      .then(({ data }) => data);
  }
);

export const editIdea = createActionThunk(
  'ideas/edit',
  ({ value, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const {
      id,
      data: { fileList, description, links }
    } = value;

    return api.ideas
      .update(workspaceId, id, {
        ...value.data,
        fileList: getFileIds(fileList),
        description: [{ text: description }, ...(links || [])]
      })
      .then(({ data }) => data);
  }
);

export const createMember = createActionThunk(
  'ideas/create-member',
  ({ id, memberId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .createMember(workspaceId, id, { member: memberId })
      .then(({ data }) => ({ id, data }));
  }
);

export const deleteMember = createActionThunk(
  'ideas/delete-member',
  ({ id, memberId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .deleteMember(workspaceId, id, memberId)
      .then(() => ({ id, memberId }));
  }
);

export const publishIdea = createActionThunk(
  'ideas/convert-to-public',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.ideas.publish(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchRelations = createActionThunk(
  'ideas/fetch-relations',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const createRelation = createActionThunk(
  'ideas/create-relation',
  ({ id, relationType, relationId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .createRelation(workspaceId, id, { relationType, relationId })
      .then(({ data }) => ({ id, data }));
  }
);

export const deleteRelation = createActionThunk(
  'ideas/delete-relation',
  ({ id, relation, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .deleteRelation(workspaceId, id, relation.id)
      .then(() => ({ id, relationId: relation.id }));
  }
);

export const convertIdeaToProject = createActionThunk(
  'ideas/convert-to-project',
  ({ ideaId, ideaData, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .convertToProject(workspaceId, ideaId, ideaData)
      .then(({ data }) => ({ ideaId, data }));
  }
);

export const createTask = createActionThunk(
  'ideas/create-task',
  ({ ideaId, value, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .createTask(workspaceId, ideaId, {
        ...value,
        description: convertMessageToArr(value.description),
        fileList: getFileIds(value.fileList)
      })
      .then(({ data }) => data);
  }
);

export const convertIdeaToTask = createActionThunk(
  'ideas/convert-to-task',
  ({ ideaId, value, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .convertToTask(workspaceId, ideaId, {
        ...value,
        fileList: getFileIds(value.fileList)
      })
      .then(({ data }) => ({ ideaId, data }));
  }
);

export const fetchAttachments = createActionThunk(
  'ideas/fetch-attachments',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ideas
      .fetchAttachments(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const setFilterSearch = createAction('ideas/set-filter-search');
