import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';

import Drawer from 'components/common/drawer';

import { fetchSubscribers } from 'store/subscriptions';

import mapValue from 'utils/map-value';
import { showNoticeMessage } from 'services/notice';

import Form from './form';

export const SubscribersDrawer = ({
  visible,
  submit,
  onClose,
  canManageSubscribers,
  id,
  title,
  description
}) => {
  const dispatch = useDispatch();

  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [defaultSubscribers, setDefaultSubscribers] = useState(null);
  const [deletedSubscribers, setDeletedSubscribers] = useState([]);

  const onFetch = async () => {
    try {
      setIsFetchLoading(true);

      const subscribers = await dispatch(fetchSubscribers({ entityId: id }));

      setDefaultSubscribers(subscribers);
    } finally {
      setIsFetchLoading(false);
    }
  };

  const onDelete = subscriber => {
    setDefaultSubscribers(prev => prev.filter(e => e.id !== subscriber.id));
    setDeletedSubscribers(prev => [...prev, subscriber]);
  };

  const onSubmit = async ({ subscribers }) => {
    try {
      setIsSubmitLoading(true);

      await submit({
        added: (subscribers || []).map(mapValue),
        deleted: deletedSubscribers.map(e => e.id)
      });

      onClose();
      showNoticeMessage();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      onFetch();
    } else {
      setDeletedSubscribers([]);
      setDefaultSubscribers(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (!visible) {
    return <div />;
  }

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />
          <Drawer.Title>{title}</Drawer.Title>
        </>
      }
      visible={visible}
      width={492}
      onClose={onClose}
      destroyOnClose
      maskClosable={false}
    >
      {isFetchLoading || !defaultSubscribers ? (
        <Spin />
      ) : (
        <Form
          isLoading={isSubmitLoading}
          onSubmit={onSubmit}
          onDelete={onDelete}
          subscribers={defaultSubscribers}
          canManageSubscribers={canManageSubscribers}
          deletedSubscribers={deletedSubscribers}
          description={description}
        />
      )}
    </Drawer>
  );
};

SubscribersDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  canManageSubscribers: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

SubscribersDrawer.defaultProps = {
  canManageSubscribers: false
};

export default SubscribersDrawer;
