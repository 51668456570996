export const addValidityDateState = file => {
  const validityDateState = {
    isValidDate: true,
    validityDateValue: file.validityDate
  };

  return {
    ...file,
    validityDateState
  };
};
