import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';

import { CREATOR_DRAWER_WIDTH, MESSAGE_DURATION } from 'constants/index';

import Drawer from 'components/common/drawer';
import MainForm from 'components/orders-view/forms/main';

import { updateTemplate } from 'store/order-statuses';

export const TemplateEditorDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ templateId, ...values }) => {
    try {
      setIsLoading(true);
      await dispatch(updateTemplate({ id: templateId, order: values }));

      message.success('Шаблон успешно отредактирован!', MESSAGE_DURATION);
      onClose({ isNeedFetch: true });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      width={CREATOR_DRAWER_WIDTH}
      bodyStyle={{ padding: 0 }}
      title={<Drawer.Title>Редактировать шаблон</Drawer.Title>}
      visible={visible}
      onClose={onClose}
    >
      <MainForm
        defaultValues={{
          ...data,
          contact: data.contact || data.workspace,
          isFromTemplate: true
        }}
        type={data.type}
        isEditor
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

TemplateEditorDrawer.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func
};

TemplateEditorDrawer.defaultProps = {
  visible: false,
  data: {},
  onClose: () => {}
};

export default TemplateEditorDrawer;
