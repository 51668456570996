import React from 'react';

export const OpenInNewWindowIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.14315 22.7108H21.8574C22.3315 22.7108 22.7146 22.3278 22.7146 21.8537V12.2108C22.7146 12.0929 22.6182 11.9965 22.5003 11.9965H21.0003C20.8824 11.9965 20.786 12.0929 20.786 12.2108V20.7822H3.21458V3.2108H11.786C11.9039 3.2108 12.0003 3.11437 12.0003 2.99651V1.49651C12.0003 1.37866 11.9039 1.28223 11.786 1.28223H2.14315C1.66905 1.28223 1.28601 1.66526 1.28601 2.13937V21.8537C1.28601 22.3278 1.66905 22.7108 2.14315 22.7108Z"
      fill="currentColor"
    />
    <path
      d="M17.1297 2.62884L18.5279 4.02705L11.6681 10.8922C11.585 10.9753 11.585 11.1119 11.6681 11.1949L12.8038 12.3306C12.8868 12.4137 13.0234 12.4137 13.1065 12.3306L19.969 5.46812L21.3725 6.87169C21.4984 6.99758 21.7154 6.92259 21.7368 6.7458L22.2993 1.94044C22.3154 1.80116 22.1975 1.68598 22.0609 1.70205L17.2556 2.26455C17.2157 2.26945 17.178 2.28544 17.1468 2.3107C17.1156 2.33595 17.0921 2.36946 17.079 2.40742C17.0659 2.44537 17.0636 2.48624 17.0726 2.52538C17.0816 2.56453 17.1013 2.60037 17.1297 2.62884Z"
      fill="currentColor"
    />
  </svg>
);

export default OpenInNewWindowIcon;
