import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  STATUS_IN_WORK,
  STATUS_FOR_EXECUTION,
  STATUS_CHECK,
  STATUS_BACKLOG,
  STATUS_REJECTED,
  STATUS_COMPLETED,
  STATUS_PENDING,
  STATUS_CANCELLED,
  AGREEMENT_TASK_APPROVED
} from 'constants/index';

import { getUserEmployee } from 'store/workspace';
import { fetchWorklogLocal } from 'store/tasks';

// FIXME: не верно работает на bord
const useWorklogModalData = (task = {}) => {
  const dispatch = useDispatch();
  const userEmployee = useSelector(getUserEmployee) || {};

  const [visibleWorklog, setVisibleWorklog] = useState(false);
  const [worklogCallback, setWorklogCallback] = useState();

  const isResponsible = (task.responsible || {}).id === userEmployee.id;
  const isController = (task.controller || {}).id === userEmployee.id;
  const isApprovingManager =
    (task.approvingManager || {}).id === userEmployee.id;

  const isStatusWork = task.status === STATUS_IN_WORK;
  const isStatusToDo = task.status === STATUS_FOR_EXECUTION;
  const isStatusReview = task.status === STATUS_CHECK;
  const isStatusBacklog = task.status === STATUS_BACKLOG;
  const isStatusPending = task.status === STATUS_PENDING;
  const isStatusCancelled = task.status === STATUS_CANCELLED;

  const checkIsWorklog = callback => async params => {
    // на случай если нельзя передать task в пропсах, передается isResponsible в params
    const {
      isResponsible: _isResponsible,
      status,
      changedRole,
      value: agreementValue
    } = params;
    const isResp = _isResponsible || isResponsible;

    const isNewStatusReview = status === STATUS_CHECK;
    const isNewStatusComplited = status === STATUS_COMPLETED;
    const isNewStatusRejected = status === STATUS_REJECTED;
    const isNewStatusForExecution = status === STATUS_FOR_EXECUTION;
    const isNewStatusApproved = agreementValue === AGREEMENT_TASK_APPROVED;

    const responsibleChange = changedRole === 'responsible';
    const controllerChange = changedRole === 'controller';
    const approvingManagerChange = changedRole === 'approvingManager';
    const roleCondition =
      (isResp && responsibleChange) ||
      (isController && controllerChange) ||
      (isApprovingManager && approvingManagerChange);

    const worklogs = await dispatch(
      fetchWorklogLocal({ id: task.id, employeeId: userEmployee.id })
    );

    if (
      ((!worklogs.length || !worklogs.find(w => w.objectId === task.id)) &&
        ((!changedRole &&
          ((isStatusWork && !isNewStatusRejected) ||
            isStatusReview ||
            isNewStatusReview ||
            isNewStatusComplited ||
            isStatusPending ||
            ((isStatusToDo || isStatusBacklog) && !status))) ||
          (!!changedRole && roleCondition))) ||
      (isStatusPending && isNewStatusForExecution) ||
      (isStatusWork &&
        (isNewStatusComplited || isNewStatusReview || isNewStatusApproved)) ||
      (isStatusReview && (isNewStatusComplited || isNewStatusForExecution)) ||
      (isStatusCancelled && isNewStatusForExecution)
    ) {
      setVisibleWorklog(true);
      return setWorklogCallback(() => ({
        callback,
        params
      }));
    }

    return callback(params);
  };

  const onSaveWorklog = () => {
    setVisibleWorklog(false);
    if (worklogCallback) {
      const { callback, params } = worklogCallback;
      callback(params);
      setWorklogCallback(null);
    }
  };

  return { visibleWorklog, setVisibleWorklog, checkIsWorklog, onSaveWorklog };
};

export default useWorklogModalData;
