import React from 'react';

export const GoogleCalendarIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_74826_2430)">
      <path
        d="M18.8159 6.5964L13.1317 5.96484L6.18429 6.5964L5.55261 12.9122L6.18417 19.2281L12.5 20.0176L18.8159 19.2281L19.4474 12.7544L18.8159 6.5964Z"
        fill="white"
      />
      <path
        d="M8.77531 16.3949C8.30323 16.0759 7.97635 15.6102 7.79791 14.9944L8.89375 14.5428C8.99323 14.9218 9.16687 15.2154 9.41479 15.4239C9.66115 15.6323 9.96115 15.7349 10.3117 15.7349C10.6701 15.7349 10.978 15.6259 11.2353 15.408C11.4926 15.1901 11.6222 14.9122 11.6222 14.5759C11.6222 14.2318 11.4864 13.9506 11.2148 13.7328C10.9432 13.515 10.6022 13.4059 10.1948 13.4059H9.56167V12.3213H10.13C10.4805 12.3213 10.7758 12.2266 11.0158 12.0371C11.2558 11.8476 11.3758 11.5887 11.3758 11.2587C11.3758 10.965 11.2684 10.7313 11.0538 10.5561C10.8391 10.3809 10.5674 10.2924 10.2374 10.2924C9.91531 10.2924 9.65947 10.3777 9.46999 10.5498C9.28051 10.7219 9.14311 10.9335 9.05635 11.1829L7.97167 10.7314C8.11531 10.324 8.37907 9.96399 8.76583 9.65295C9.15271 9.34191 9.64687 9.18555 10.2469 9.18555C10.6905 9.18555 11.09 9.27087 11.4438 9.44295C11.7974 9.61503 12.0753 9.85347 12.2758 10.1566C12.4764 10.4613 12.5758 10.8024 12.5758 11.1813C12.5758 11.5681 12.4827 11.8949 12.2964 12.1633C12.11 12.4318 11.881 12.637 11.6095 12.7807V12.8454C11.9679 12.9954 12.26 13.2244 12.4905 13.5323C12.7195 13.8402 12.8347 14.2081 12.8347 14.6376C12.8347 15.0671 12.7257 15.4507 12.5078 15.7871C12.2899 16.1235 11.9883 16.3887 11.6062 16.5813C11.2226 16.7739 10.7916 16.8718 10.3131 16.8718C9.75895 16.8733 9.24739 16.7139 8.77531 16.3949Z"
        fill="#1A73E8"
      />
      <path
        d="M15.5 10.9579L14.3031 11.8279L13.7016 10.9153L15.86 9.3584H16.6874V16.702H15.5V10.9579Z"
        fill="#1A73E8"
      />
      <path
        d="M18.8159 24.9121L24.5 19.228L21.6579 17.9648L18.8159 19.228L17.5527 22.07L18.8159 24.9121Z"
        fill="#EA4335"
      />
      <path
        d="M4.92105 22.0696L6.18417 24.9117H18.8157V19.2275H6.18417L4.92105 22.0696Z"
        fill="#34A853"
      />
      <path
        d="M2.39468 0.912109C1.34792 0.912109 0.5 1.76003 0.5 2.80679V19.2278L3.34208 20.4909L6.18416 19.2278V6.59627H18.8157L20.0788 3.75419L18.8158 0.912109H2.39468Z"
        fill="#4285F4"
      />
      <path
        d="M0.5 19.2275V23.017C0.5 24.0639 1.34792 24.9117 2.39468 24.9117H6.18416V19.2275H0.5Z"
        fill="#188038"
      />
      <path
        d="M18.8158 6.59613V19.2277H24.5V6.59613L21.6579 5.33301L18.8158 6.59613Z"
        fill="#FBBC04"
      />
      <path
        d="M24.5 6.59627V2.80679C24.5 1.75991 23.6521 0.912109 22.6053 0.912109H18.8158V6.59627H24.5Z"
        fill="#1967D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_74826_2430">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.912109)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleCalendarIcon;
