import { useState, useEffect } from 'react';

// Указывает активна ли вкладка
export const useVisibilityChange = () => {
  const [visible, setVisible] = useState(true);

  const onChange = () => setVisible(!document.hidden);

  useEffect(() => {
    window.addEventListener('visibilitychange', onChange);

    return () => {
      window.removeEventListener('visibilitychange', onChange);
    };
  });

  return visible;
};

export default useVisibilityChange;
