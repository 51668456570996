export default api => ({
  fetchAll: () => api.get(`/v1/workspace-social-credentials/`),

  add: ({ workspaceId, socialCredentials, type }) =>
    api.post(`/v1/workspace-social-credentials/`, {
      workspace: workspaceId,
      socialCredentials,
      type
    }),

  delete: id => api.delete(`/v1/workspace-social-credentials/${id}/`),

  update: ({ id, workspaceId }) =>
    api.patch(`/v1/workspace-social-credentials/${id}/`, {
      workspace: workspaceId
    })
});
