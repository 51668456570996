import { createAction } from 'redux-actions';
import api from 'api';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';

export const fetchGptChats = createActionThunk(
  'gpt/fetch-gpt-chats',
  ({ getState, entityType }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.gpt
      .fetchChats({
        workspaceId,
        entityType,
        limit: 1000,
        offset: 0
      })
      .then(({ data }) => data);
  }
);

export const fetchGptMessages = createActionThunk(
  'gpt/fetch-gpt-messages',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.gpt
      .fetchMessages({
        id,
        workspaceId,
        limit: 1000,
        offset: 0
      })
      .then(({ data }) => data);
  }
);

export const fetchGptPrompts = createActionThunk(
  'gpt/fetch-gpt-prompts',
  ({ entityType }) =>
    api.gpt
      .fetchPrompts({
        entityType
      })
      .then(({ data }) => data)
);

export const checkDialogPresence = createActionThunk(
  'gpt/check-dialog-presence',
  ({ getState, entityId, entityType }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.gpt
      .checkDialogPresence({
        entityId,
        entityType,
        workspaceId
      })
      .then(({ data }) => data);
  }
);

export const setFilteredDialogsByEntity = createAction(
  'gpt/set-filtered-dialogs-by-entity'
);
export const clearGptFilter = createAction('gpt/clear-gpt-filter');
