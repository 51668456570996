import React from 'react';

export const LetterWasSentIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.1002 81C47.0002 81 16.8002 77.8 10.0002 64.9C3.60015 52.8 13.9002 47.2 18.3002 37.1C25.7002 20.3 48.9002 6.99998 71.1002 11.3C93.3002 15.6 101.7 41.9 94.8002 56.6C87.9002 71.3 73.8002 81 56.1002 81Z"
      fill="#D7F1F2"
    />
    <g opacity="0.2">
      <path
        d="M79.0002 49L79.2002 48.8C79.7002 48.3 80.4002 48.3 80.9002 48.8L84.0002 51.9C84.5002 52.4 84.5002 53.1 84.0002 53.6L83.8002 53.8C83.3002 54.3 82.6002 54.3 82.1002 53.8L79.0002 50.7C78.5002 50.3 78.5002 49.5 79.0002 49Z"
        fill="#36B7BD"
      />
      <path
        d="M90.0003 45.4L90.2003 45.6C90.7003 46.1 90.7003 46.8 90.2003 47.3L83.7003 53.8C83.2003 54.3 82.5003 54.3 82.0003 53.8L81.8003 53.6C81.3003 53.1 81.3003 52.4 81.8003 51.9L88.3003 45.4C88.8003 45 89.6003 45 90.0003 45.4Z"
        fill="#36B7BD"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M66.3 27.5L66.5 27.3C67 26.8 67.7001 26.8 68.2001 27.3L71.3 30.4C71.8 30.9 71.8 31.6 71.3 32.1L71.1 32.3C70.6 32.8 69.9001 32.8 69.4001 32.3L66.3 29.2C65.8 28.8 65.8 28 66.3 27.5Z"
        fill="#36B7BD"
      />
      <path
        d="M77.3001 23.9L77.5001 24.1C78.0001 24.6 78.0001 25.3 77.5001 25.8L71.0001 32.3C70.5001 32.8 69.8001 32.8 69.3001 32.3L69.1001 32.1C68.6001 31.6 68.6001 30.9 69.1001 30.4L75.6001 23.9C76.1001 23.5 76.8001 23.5 77.3001 23.9Z"
        fill="#36B7BD"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M42.2002 23.2L42.4002 23C42.9002 22.5 43.6002 22.5 44.1002 23L47.2002 26.1C47.7002 26.6 47.7002 27.3 47.2002 27.8L47.0002 28C46.5002 28.5 45.8002 28.5 45.3002 28L42.2002 24.9C41.7002 24.4 41.7002 23.6 42.2002 23.2Z"
        fill="#36B7BD"
      />
      <path
        d="M53.2001 19.6L53.4001 19.8C53.9001 20.3 53.9001 21 53.4001 21.5L47.0001 28C46.5001 28.5 45.8001 28.5 45.3001 28L45.1001 27.8C44.6001 27.3 44.6001 26.6 45.1001 26.1L51.6001 19.6C52.0001 19.1 52.8001 19.1 53.2001 19.6Z"
        fill="#36B7BD"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M19.9001 50.9L20.1001 50.7C20.6001 50.2 21.3001 50.2 21.8001 50.7L24.9001 53.8C25.4001 54.3 25.4001 55 24.9001 55.5L24.7001 55.7C24.2001 56.2 23.5001 56.2 23.0001 55.7L19.9001 52.6C19.4001 52.1 19.4001 51.4 19.9001 50.9Z"
        fill="#36B7BD"
      />
      <path
        d="M30.9 47.3L31.1 47.5C31.6 48 31.6 48.7 31.1 49.2L24.6 55.7C24.1 56.2 23.4 56.2 22.9 55.7L22.8 55.5C22.3 55 22.3 54.3 22.8 53.8L29.3 47.3C29.7 46.8 30.5 46.8 30.9 47.3Z"
        fill="#36B7BD"
      />
    </g>
    <path
      d="M81.3001 63.7L58.7001 81.4C56.1 83.4 52.5001 83.4 49.9001 81.4L27.3 63.7L50.9001 45C53.0001 43.3 55.8001 43.3 57.9001 45L81.3001 63.7Z"
      fill="#FDA402"
    />
    <path
      d="M78 43.2V84C78 87.2999 75.8001 90.0999 72.8001 91.0999C72.1001 91.2999 71.3 91.5 70.5 91.5H37.3C36.5 91.5 35.7 91.3999 35 91.0999C32 90.0999 29.8 87.2999 29.8 84V43.2C29.8 39.1 33.2 35.7 37.3 35.7H70.4001C74.6001 35.7 78 39.1 78 43.2Z"
      fill="white"
    />
    <path
      d="M78 64.5V85C78 88.3 75.8001 91.1 72.8001 92.1H35C32 91.1 29.8 88.3 29.8 85V63.9L49.9001 79.5C52.5001 81.5 56.1 81.5 58.7001 79.5L78 64.5Z"
      fill="#F0F0F0"
    />
    <path
      d="M43.7002 56.6L52.6002 65.5L66.3002 51.9"
      stroke="#2B2A36"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M81.3002 63.7V89.5C81.3002 92 79.3002 94 76.8002 94H31.7002C29.2002 94 27.2002 92 27.2002 89.5V63.7L27.3002 63.7999L49.9002 81.4C52.5002 83.4 56.1002 83.4 58.7002 81.4L81.3002 63.7Z"
      fill="#FFE181"
    />
  </svg>
);

export default LetterWasSentIcon;
