import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  TYPE_AGREEMENT_TASK,
  TYPE_TASK,
  TYPE_TASK_TEMPLATE
} from 'constants/index';

import { useUpdateTags } from 'components/common/tags';
import useTemplateRelations from 'components/tasks-view/template-view/use-template-relations';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

import {
  createTask,
  createTemplateLocal,
  // createTemplateRelationLocal,
  // deleteTemplateRelationLocal,
  editTemplateFieldsLocal,
  createTasksInBackground
} from 'store/tasks';

import { useModalsService } from 'services/modals';
import mapValue from 'utils/map-value';
import getFileIds from 'hooks/common/use-file-upload/get-file-ids';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import { clearAccordingType, getTaskValue } from '../utils';
import { CreatorDrawerContext } from './creator-drawer-context';

export const useCreatorDrawerContext = () => {
  const dispatch = useDispatch();

  const {
    isFirstPage,
    initialPage,
    currentTaskType,
    setCurrentTaskType,
    currentTaskTemplate,
    setCurrentTaskTemplate,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    isBacklog,
    isSprints,
    value,
    ideaId,
    onSubmit,
    onTasksCreated,
    onClose,
    activeTab,
    setActiveTab,
    withShowDetails,
    visibleCalender,
    setVisibleCandar
  } = useContext(CreatorDrawerContext);

  const modals = useModalsService();
  const updateTags = useUpdateTags();
  const amplitude = useAmplitude();
  const { updateTemplateRelations } = useTemplateRelations();
  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const { t } = useTranslation('Toast');

  const isFromTemplate = !isEmpty(currentTaskTemplate);

  const resetForm = () => {
    setCurrentTaskType(undefined);
    setCurrentTaskTemplate(undefined);
    setCurrentPage(initialPage);
    setVisibleCandar(false);
  };

  const getTasksId = tasks => tasks.map(task => task.id);

  const onCreateTask = async ({ tags, ...data }, responsibleId) => {
    // сабмит приходит при конвертации идеи в задачу
    if (onSubmit) {
      return dispatch(
        onSubmit({
          ideaId,
          value: {
            ...data,
            responsible: responsibleId
          }
        })
      );
    }

    const task = await dispatch(
      createTask({
        task: {
          ...data,
          responsible: responsibleId
        }
      })
    );

    await updateTags({
      entityType: TYPE_TASK,
      entityId: task.id,
      initialTags: [],
      newTags: tags
    });

    amplitude.addTaskEvent({
      value: data,
      template: currentTaskTemplate
    });

    await manageBulkSubscribers(data.fileList);

    await handleChangeBulkValidityDates({
      fileList: data.fileList,
      isFromEditor: true
    });

    return task;
  };

  const onCreateOneTask = async ({ values }) => {
    const task = await onCreateTask(values, values.responsible[0]);

    if (withShowDetails) {
      if (value.parent) {
        modals.tasks.showDetails({
          id: value.parent
        });
      } else {
        modals.tasks.showDetails({
          id: task.id
        });
      }
    }

    return task;
  };

  const onCreateMultiplyTasks = async data => {
    // при создании <=5 задач, создаем их по одной, иначе в фоне
    const part = 4;
    const tasks = [];

    const firstTask = await onCreateTask(data, data.responsible[0]);
    tasks.push(firstTask);

    const responsiblies = data.responsible.slice(1);

    if (responsiblies.length <= part) {
      const fetchedTasks = await Promise.all(
        responsiblies.map(item =>
          onCreateTask({ ...data, fileList: firstTask.fileIds }, item)
        )
      );

      tasks.push(...fetchedTasks);

      showNoticeMessage({
        customContent: t('TasksWithIdCreated', {
          taskIds: getTasksId(tasks).join(', ')
        })
      });
    } else {
      await dispatch(
        createTasksInBackground({
          task: {
            ...data,
            responsible: responsiblies,
            fileList: firstTask.fileIds
          }
        })
      );

      showNoticeMessage({ number: NOTICE_NUMBER.multipleTaskCreated });
    }

    return tasks;
  };

  const createTemplate = task => dispatch(createTemplateLocal({ task }));

  const editTemplate = async newValue => {
    await dispatch(editTemplateFieldsLocal({ value: newValue }));
  };

  const handleSubmit = async ({
    saveAsTemplate,
    updateTemplate,
    ...values
  }) => {
    let tasks = [];

    try {
      setIsLoading(true);

      const resultTask = getTaskValue({
        ...value,
        ...values,
        dateStart:
          values.dateStart && moment(values.dateStart).toISOString(true),
        dateEnd: values.dateEnd && moment(values.dateEnd).toISOString(true),
        kind: values.approvingManager ? TYPE_AGREEMENT_TASK : currentTaskType,
        coResponsibles: (values.coResponsibles || []).map(mapValue),
        members: values.members || [],
        approvingManager: (values.approvingManager || {}).value
      });

      const filteredData = clearAccordingType(resultTask);

      let template;

      if (saveAsTemplate) {
        template = await createTemplate({
          ...filteredData,
          members: resultTask.members
        });

        await updateTags({
          entityType: TYPE_TASK_TEMPLATE,
          entityId: template.id,
          initialTags: [],
          newTags: filteredData.tags
        });

        amplitude.addTaskTemplate(template.isScheduler);
      }

      if (filteredData.responsible.length <= 1) {
        const task = await onCreateOneTask({
          values: {
            ...filteredData,
            template: (template || {}).id
          }
        });

        tasks = [task];
      } else {
        tasks = await onCreateMultiplyTasks({
          ...filteredData,
          template: (template || {}).id
        });
      }

      if (updateTemplate) {
        await editTemplate({
          id: currentTaskTemplate.id,
          data: {
            ...resultTask,
            controller: (values.controller || {}).value || null,
            description: values.description || undefined,
            dateEnd: values.dateEnd || null,
            dateStart: values.dateStart
              ? moment(values.dateStart).toISOString(true)
              : null,
            fileList: getFileIds(values.fileList),
            members: (values.members || []).map(member => member.value)
          }
        });

        await updateTags({
          entityType: TYPE_TASK_TEMPLATE,
          entityId: currentTaskTemplate.id,
          initialTags: filteredData.initialTags,
          newTags: filteredData.tags
        });

        updateTemplateRelations(
          currentTaskTemplate.relations || [],
          resultTask.relations || [],
          currentTaskTemplate.id
        );

        amplitude.updateTaskEvent({
          task: currentTaskTemplate,
          value: resultTask,
          isTemplate: true
        });
      }

      if (onTasksCreated) {
        await onTasksCreated(tasks);
      }

      onClose();
      resetForm();
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectType = (type, template) => {
    if (template) {
      setCurrentTaskTemplate(template);
    }

    setCurrentTaskType(type);
    setCurrentPage(currentPage + 1);
  };

  return {
    values: { ...value, ...(currentTaskTemplate || {}) },
    currentTaskType,
    setCurrentTaskType,
    isFromTemplate,
    currentTaskTemplate,
    setCurrentTaskTemplate,
    isLoading,
    setIsLoading,
    currentPage,
    isFirstPage,
    initialPage,
    setCurrentPage,
    isBacklog,
    isSprints,
    value,
    ideaId,
    onSubmit,
    handleSubmit,
    onSelectType,
    resetForm,
    activeTab,
    setActiveTab,
    visibleCalender,
    setVisibleCandar
  };
};

export default useCreatorDrawerContext;
