import { useDispatch } from 'react-redux';
import differenceWith from 'lodash/differenceWith';

import { addTag, deleteTag } from 'store/tags';

// tag: { value: id, label: { id, name, color, entityType, entityId } }
export const useUpdateTags = () => {
  const dispatch = useDispatch();

  const changeTags = ({ entityType, entityId, tags, isDelete }) => {
    if (!(tags || []).length) {
      return null;
    }

    const action = isDelete ? deleteTag : addTag;

    return Promise.all(
      tags.map(({ label }) =>
        dispatch(
          action({
            tag: { ...label, entityType, entityId }
          })
        )
      )
    );
  };

  const addTags = ({ entityType, entityId, tags }) =>
    changeTags({ entityType, entityId, tags, isDelete: false });
  const deleteTags = ({ entityType, entityId, tags }) =>
    changeTags({ entityType, entityId, tags, isDelete: true });

  const updateTags = async ({
    entityType,
    entityId,
    initialTags = [],
    newTags
  }) => {
    const addedTags = differenceWith(
      newTags,
      initialTags,
      (tag1, tag2) => tag1.value === tag2.value
    );

    const deletedTags = differenceWith(
      initialTags,
      newTags,
      (tag1, tag2) => tag1.value === tag2.value
    );

    await addTags({ entityType, entityId, tags: addedTags });
    await deleteTags({ entityType, entityId, tags: deletedTags });
  };

  return updateTags;
};

export default useUpdateTags;
