import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TYPE_ORDER, CONTACT_STATUS_VERIFIED } from 'constants/index';

import {
  // FormCheckbox,
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  LabelWithTooltip,
  withoutBubbling
} from 'components/common/hook-form';
import Button from 'components/common/button';
import FormCounterpartSelect from 'components/orders-view/forms/main/components/counterpart-select';
import ActionsDropdown from 'components/common/actions';
import Icon from 'components/common/icon';
import FormNewEditor from 'components/common/hook-form/markdown';
import { convertMessageToString } from 'components/common/comments/converters';

import { getUserCurrency } from 'store/workspace';

import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';
import useMinMaxTime from 'hooks/common/use-min-max-time';

import useAdditionalFields from './use-additional-fields';
import { transformFormSubmittedValues } from '../utils';

import styles from './main.module.scss';

const MainForm = ({
  defaultValues,
  isEditor,
  // isCopy, TODO: check logic
  isLoading,
  onSubmit
}) => {
  const userCurrency = useSelector(getUserCurrency);

  const { t } = useTranslation(['AddOrder', 'Errors', 'Common', 'Order']);

  const initialCurrency = defaultValues.currency || userCurrency;
  const initialTags = (defaultValues.tags || []).map(tag => ({
    value: tag.id,
    label: tag
  }));

  const description =
    typeof defaultValues.description === 'string'
      ? defaultValues.description
      : (defaultValues.description || []).filter(el => !el.link);

  const transformedDefaultValues = useMemo(
    () => ({
      title: defaultValues.title,
      content: {
        description: convertMessageToString(description),
        fileList: defaultValues.fileList
      },
      startAt: defaultValues.startAt && moment(defaultValues.startAt).toDate(),
      deadlineAt:
        defaultValues.deadlineAt && moment(defaultValues.deadlineAt).toDate(),
      location: defaultValues.location,
      locationExact: defaultValues.locationExact,
      price: defaultValues.price,
      currency: {
        value: initialCurrency,
        label: initialCurrency
      },
      relations: defaultValues.relations,
      tags: initialTags,
      signatory: defaultValues.signatory
        ? {
            value: defaultValues.signatory.id,
            label: defaultValues.signatory
          }
        : null,
      contractor: defaultValues.contractor
        ? {
            label: {
              ...defaultValues.contractor,
              workspaceTitle:
                defaultValues.contractor.workspaceTitle ||
                (defaultValues.contractor.company || {}).title
            },
            value: defaultValues.contractor.id
          }
        : null,
      isToWorkspace: !!defaultValues.isToWorkspace,
      asTemplate: false
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues]
  );

  const methods = useForm({
    defaultValues: transformedDefaultValues,
    shouldUnregister: true
  });

  const startAtField = useWatch({
    name: 'startAt',
    control: methods.control
  });

  const deadlineAtField = useWatch({
    name: 'deadlineAt',
    control: methods.control
  });

  const [minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: startAtField,
    endDate: deadlineAtField
  });

  const assetField = useWatch({ name: 'asset', control: methods.control });
  const contentField = useWatch({
    name: 'content',
    control: methods.control
  });
  const isToWorkspaceField = useWatch({
    name: 'isToWorkspace',
    control: methods.control
  });

  const isTemplateEditor = isEditor && defaultValues.isFromTemplate;

  const showContactField = true; // TODO: check logic

  const isUploadingFiles = useUploadingFiles(contentField.fileList);
  const isAllLoading = isLoading || isUploadingFiles;

  const { activeFields, allowedFields } = useAdditionalFields({
    defaultValues,
    isEditor,
    isTemplateEditor
  });

  useEffect(() => {
    methods.reset(transformedDefaultValues);
  }, [methods, transformedDefaultValues]);

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        data-qa="qa-qp0qp53it2rt2u5"
        onSubmit={event =>
          withoutBubbling(event, () =>
            methods.handleSubmit(values => {
              onSubmit(
                transformFormSubmittedValues({
                  values: {
                    ...values,
                    initialTags: isEditor ? undefined : initialTags,
                    isFromTemplate: defaultValues.isFromTemplate,
                    templateId: isTemplateEditor ? defaultValues.id : undefined
                  },
                  isShortForm: false,
                  t
                })
              );
            })
          )
        }
      >
        <FormInput
          name="title"
          label={t('OrderName')}
          placeholder={t('EnterName')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <FormNewEditor
          name="content"
          label={t('OrderDesc')}
          placeholder={t('OrderDescPlchldr')}
          actionsDeps={
            isEditor
              ? {
                  statusId: defaultValues.id,
                  sendCopyToComment: false
                }
              : {}
          }
          destination={{
            entityId: defaultValues.id,
            entityType: TYPE_ORDER
          }}
          resizeInput
        />

        {!isTemplateEditor && (
          <div className={styles.dateWrap}>
            <FormDatePicker
              name="startAt"
              label={`${t('OrderStartDate')} ${
                assetField ? '' : `(${t('OptionalField', { ns: 'Common' })})`
              }`}
              wrapperClassname={styles.datePicker}
              maxDate={deadlineAtField}
              rules={{
                required: {
                  value: !!assetField,
                  message: t('RequiredField', { ns: 'Errors' })
                }
              }}
              placeholderText={t('ChooseDate')}
            />

            <FormDatePicker
              name="deadlineAt"
              label={t('OrderDueDate')}
              minDate={startAtField || new Date()}
              minTime={minTimeEnd}
              maxTime={maxTimeEnd}
              wrapperClassname={styles.datePicker}
              itemProps={{
                'data-qa': 'qa-fltcsrbukd07rvx'
              }}
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                validate: value =>
                  !moment(startAtField).isAfter(value) ||
                  t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' })
              }}
              placeholderText={t('ChooseDate')}
            />
          </div>
        )}

        {(!isEditor || isTemplateEditor) && (
          <FormEmployeeSelect
            itemProps={{
              className: styles.signatorySelect
            }}
            name="signatory"
            label={
              <LabelWithTooltip
                label={t('Signer')}
                tooltip={t('SignerFieldTip')}
                style={{ maxWidth: 255 }}
              />
            }
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowSetYourself={false}
            params={{
              workspaceControllers: true
            }}
            renderContentTop={() => {}}
          />
        )}

        {showContactField && (
          <div className={styles.contactWrap}>
            {/* {isOrderToContractor ? (
              <>
                <FormCheckbox
                  name="isToWorkspace"
                  itemProps={{
                    className: styles.isToWorkspace,
                    'data-qa': 'qa-p4jfzwixm4j6ujn'
                  }}
                  callback={() => methods.setValue('contact', null)}
                >
                  Я знаю название компании, но не знаю контактного лица
                </FormCheckbox>

                <Typography.Paragraph>
                  {isToWorkspaceField
                    ? 'Введите название компании, которая будет выполнять ваш заказ. Указать можно только компанию, которая зарегистрирована в Upservice.'
                    : 'Выберите исполнителя из ваших контактов или добавьте нового. Исполнителем может быть как компания, так и физическое лицо.'}
                </Typography.Paragraph>
              </>
            ) : (
              <Typography.Paragraph>
                Выберите заказчика из ваших контактов или добавьте нового
              </Typography.Paragraph>
            )} */}

            {!isEditor && (
              <FormCounterpartSelect
                label={t('Contractor')}
                itemProps={{
                  className: styles.contactSelect
                }}
                name="contractor"
                rules={{
                  required: t('RequiredField', { ns: 'Errors' })
                }}
                params={{
                  status: CONTACT_STATUS_VERIFIED
                }}
                isToWorkspace={isToWorkspaceField}
              />
            )}
          </div>
        )}

        {activeFields.map(f => f.component)}

        {!!allowedFields.length && (
          <>
            <Divider className={styles.divider} data-qa="qa-hzjkuaq01oqqjis" />

            <div className={styles.btnWithPlus}>
              <ActionsDropdown
                actions={allowedFields}
                placement="bottomLeft"
                data-qa="qa-fu5pvqu50u0tx1p"
              >
                <Button type="link" size="large" data-qa="qa-j25g5iixexcobub">
                  <Icon type="plus" />
                  {t('ShowAllFieldsBtn')}
                </Button>
              </ActionsDropdown>
            </div>
          </>
        )}

        <div className={styles.submitWrap} data-qa="qa-pjuf7uz089qlucn">
          {/* {!isEditor && !isCopy && (
            <FormCheckbox name="asTemplate">
              {defaultValues.isFromTemplate
                ? 'Update template'
                : 'Save as template'}
            </FormCheckbox>
          )} */}

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isAllLoading}
            className={styles.submitBtn}
            data-qa="qa-ygkuh394koodjlh"
          >
            {`${t('SaveBtn')} ${
              isUploadingFiles ? t('FileLoading', { ns: 'Common' }) : ''
            }`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MainForm;
