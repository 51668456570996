import React from 'react';

export const MessageIcon = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0953 0.14286H2.95243C1.77386 0.14286 0.820285 1.10715 0.820285 2.28572L0.80957 21.5714L5.09528 17.2857H20.0953C21.2739 17.2857 22.2381 16.3214 22.2381 15.1429V2.28572C22.2381 1.10715 21.2739 0.14286 20.0953 0.14286ZM8.30957 9.78572H6.16671V7.64286H8.30957V9.78572ZM12.5953 9.78572H10.4524V7.64286H12.5953V9.78572ZM16.881 9.78572H14.7381V7.64286H16.881V9.78572Z"
      fill="white"
    />
  </svg>
);

export default MessageIcon;
