import React from 'react';

export const DeleteEventIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M70.964 17.4592C72.6035 17.7691 74.2172 18.1563 75.8308 18.5565C83.6796 20.6607 91.9157 22.4293 99.7515 24.1849L101.042 24.4947C106.361 25.6953 111.473 26.9346 116.172 28.3675C117.85 28.871 119.451 29.3486 121.013 29.9295C136.801 35.3772 146.083 43.3422 137.795 59.2205C137.099 60.5713 136.616 62.0212 136.362 63.5192C135.265 69.9738 137.24 78.2874 138.376 86.5492C138.647 88.5114 138.841 90.422 138.918 92.4746C139.053 94.7159 139.018 96.9641 138.815 99.2002C138.504 102.938 137.361 106.559 135.471 109.799C133.965 112.091 132.119 114.141 129.998 115.879C125.88 119.119 78.8257 149.34 38.3038 123.508C36.6643 122.45 -3.00564 96.3989 5.02388 60.2532C12.821 25.0886 58.171 15.1098 70.964 17.4592Z"
      fill="#EBF8F8"
    />
    <path
      d="M145.411 76.9578L128.952 45.4336C128.509 44.5861 127.86 43.8641 127.064 43.3344C126.268 42.8046 125.351 42.4844 124.399 42.4033C123.446 42.3222 122.488 42.4829 121.614 42.8704C120.74 43.258 119.978 43.8599 119.399 44.6203L97.8405 72.8914C97.2243 73.7003 96.8366 74.6596 96.7177 75.6695C96.5988 76.6793 96.7531 77.7025 97.1646 78.6323C97.5761 79.5621 98.2296 80.3644 99.057 80.9554C99.8844 81.5465 100.855 81.9046 101.868 81.9924L139.899 85.2326C140.91 85.3165 141.926 85.1278 142.84 84.6861C143.754 84.2444 144.533 83.5658 145.095 82.721C145.658 81.8762 145.984 80.8961 146.04 79.8825C146.095 78.8689 145.878 77.859 145.411 76.9578V76.9578Z"
      fill="#F9716C"
    />
    <path
      d="M106.387 73.6274L127.946 45.3563C128.117 45.134 128.303 44.9227 128.501 44.7237C127.979 44.0219 127.302 43.4501 126.522 43.053C125.743 42.6558 124.883 42.444 124.008 42.434C123.134 42.424 122.268 42.6161 121.48 42.9953C120.692 43.3746 120.002 43.9307 119.464 44.6204L97.906 72.8916C97.2898 73.7004 96.902 74.6598 96.7831 75.6696C96.6642 76.6794 96.8186 77.7026 97.2301 78.6324C97.6415 79.5622 98.2951 80.3645 99.1224 80.9556C99.9498 81.5466 100.921 81.9047 101.934 81.9925L110.48 82.7155C109.461 82.6408 108.482 82.2924 107.645 81.7071C106.809 81.1218 106.146 80.3213 105.726 79.3903C105.307 78.4593 105.147 77.4323 105.264 76.4179C105.38 75.4035 105.768 74.4393 106.387 73.6274V73.6274Z"
      fill="#F7413B"
    />
    <path
      d="M123.22 68.1926L121.194 68.0119C120.937 67.9907 120.687 67.9191 120.457 67.8013C120.228 67.6834 120.025 67.5216 119.858 67.325C119.691 67.1284 119.565 66.9009 119.486 66.6555C119.408 66.4101 119.378 66.1516 119.399 65.8948L120.445 53.6956C120.466 53.4387 120.538 53.1886 120.655 52.9594C120.773 52.7302 120.935 52.5265 121.132 52.3599C121.328 52.1932 121.556 52.067 121.801 51.9883C122.047 51.9096 122.305 51.88 122.562 51.9012L124.602 52.0819C125.118 52.1279 125.595 52.3763 125.929 52.773C126.263 53.1697 126.426 53.6824 126.383 54.199L125.299 66.3982C125.256 66.9097 125.014 67.3841 124.626 67.7195C124.237 68.0549 123.733 68.2248 123.22 68.1926Z"
      fill="white"
    />
    <path
      d="M121.31 78.6491C121.898 78.698 122.486 78.5716 123.002 78.2861C123.518 78.0006 123.937 77.5686 124.207 77.0448C124.478 76.521 124.587 75.9289 124.521 75.3432C124.455 74.7574 124.216 74.2044 123.836 73.754C123.456 73.3035 122.951 72.9759 122.385 72.8124C121.818 72.6488 121.216 72.6568 120.654 72.8353C120.093 73.0137 119.596 73.3547 119.228 73.815C118.86 74.2754 118.637 74.8345 118.586 75.4218C118.551 75.813 118.594 76.2073 118.712 76.5819C118.83 76.9566 119.021 77.304 119.274 77.6042C119.528 77.9043 119.838 78.1513 120.188 78.3306C120.537 78.51 120.919 78.6182 121.31 78.6491V78.6491Z"
      fill="white"
    />
    <path
      d="M108.646 47.9503V105.667C108.646 112.122 104.773 117.286 100.113 117.286H36.2254C31.5135 117.286 27.6924 112.122 27.6924 105.667V47.9503C27.6924 41.5603 31.5651 36.332 36.2254 36.332H100.113C104.825 36.3578 108.646 41.5603 108.646 47.9503Z"
      fill="#32C9C9"
    />
    <path
      d="M125.079 99.291C124.567 101.369 123.41 103.231 121.774 104.61H51.1738C48.166 103.9 45.5067 101.434 43.1572 97.9742C40.3469 93.5407 38.1087 88.7695 36.4961 83.7741L49.7151 87.9696L61.5657 84.7294L65.1158 83.7741L86.3643 87.7502L97.3371 89.8285L100.268 85.6847L104.644 86.8465L115.216 76.7773C116.707 82.3118 118.686 87.7031 121.129 92.888C122.238 95.1436 123.561 97.2877 125.079 99.291V99.291Z"
      fill="white"
    />
    <path
      d="M113.254 70.2838L102.681 80.3917L98.2536 79.2815L95.3232 83.4383L63.0502 77.3451L47.7012 81.5019L34.4822 77.3064C31.4952 68.2312 29.2333 58.9332 27.7178 49.5H108.684C109.821 56.5072 111.347 63.4459 113.254 70.2838V70.2838Z"
      fill="white"
    />
    <path
      d="M109.097 51.9915L28.2083 52.4304C28.0534 51.4493 27.8856 50.4811 27.7178 49.5H108.684C108.826 50.3262 108.968 51.1653 109.097 51.9915Z"
      fill="#E2E2E2"
      fillOpacity="0.5"
    />
    <path
      d="M108.646 44.8782V49.4997H27.7178V44.8782C27.7212 42.6219 28.6181 40.4587 30.2124 38.862C31.8067 37.2652 33.9686 36.365 36.2249 36.3582H36.6638V33.1309C36.6638 32.5523 36.8937 31.9973 37.3028 31.5882C37.712 31.1791 38.2669 30.9492 38.8455 30.9492C39.4241 30.9492 39.979 31.1791 40.3882 31.5882C40.7973 31.9973 41.0272 32.5523 41.0272 33.1309V36.3582H51.3545V33.1309C51.3545 32.5523 51.5844 31.9973 51.9935 31.5882C52.4026 31.1791 52.9575 30.9492 53.5362 30.9492C54.1148 30.9492 54.6697 31.1791 55.0788 31.5882C55.488 31.9973 55.7178 32.5523 55.7178 33.1309V36.3582H66.0452V33.1309C66.0452 32.8444 66.1016 32.5607 66.2112 32.296C66.3209 32.0313 66.4816 31.7908 66.6842 31.5882C66.8867 31.3856 67.1272 31.2249 67.3919 31.1153C67.6566 31.0056 67.9403 30.9492 68.2268 30.9492C68.5133 30.9492 68.797 31.0056 69.0617 31.1153C69.3264 31.2249 69.5669 31.3856 69.7695 31.5882C69.9721 31.7908 70.1328 32.0313 70.2424 32.296C70.352 32.5607 70.4085 32.8444 70.4085 33.1309V36.3582H80.7358V33.1309C80.7358 32.5523 80.9657 31.9973 81.3748 31.5882C81.784 31.1791 82.3389 30.9492 82.9175 30.9492C83.4961 30.9492 84.051 31.1791 84.4601 31.5882C84.8693 31.9973 85.0991 32.5523 85.0991 33.1309V36.3582H95.4265V33.1309C95.4265 32.8444 95.4829 32.5607 95.5926 32.296C95.7022 32.0313 95.8629 31.7908 96.0655 31.5882C96.2681 31.3856 96.5086 31.2249 96.7733 31.1153C97.038 31.0056 97.3216 30.9492 97.6081 30.9492C97.8946 30.9492 98.1783 31.0056 98.443 31.1153C98.7077 31.2249 98.9482 31.3856 99.1508 31.5882C99.3534 31.7908 99.5141 32.0313 99.6237 32.296C99.7334 32.5607 99.7898 32.8444 99.7898 33.1309V36.3582H100.229C102.469 36.3887 104.608 37.2994 106.183 38.8935C107.758 40.4877 108.642 42.6374 108.646 44.8782Z"
      fill="url(#paint0_linear_48421_227270)"
    />
    <path
      d="M125.377 96.7606C125.396 97.6307 125.296 98.4993 125.08 99.3424C124.56 101.525 123.327 103.473 121.575 104.875C119.824 106.278 117.655 107.057 115.411 107.088L57.2162 107.243C56.2072 107.104 55.2103 106.888 54.2342 106.597C47.0179 104.429 42.887 98.5291 43.1581 98.0902C43.4808 97.7288 47.7537 102.97 53.55 102.518C55.0151 102.461 56.4266 101.951 57.5905 101.059C60.5467 98.6195 60.0304 94.2046 59.9658 93.7269L121.181 92.9395H125.351L125.377 96.7606Z"
      fill="#CEF4F4"
    />
    <path
      d="M90.4444 75.9253C90.1757 78.0971 89.4592 80.1894 88.3402 82.0701L77.419 80.0046L69.7897 78.5588L63.1414 77.2678L58.3134 78.5588C58.013 77.5558 57.8055 76.5273 57.6937 75.4864C57.6937 75.4864 57.6937 75.3573 57.6937 75.3056C57.6239 71.1518 59.1237 67.1246 61.8937 64.0283C64.6636 60.9321 68.4997 58.9949 72.6356 58.6036C76.7715 58.2124 80.9028 59.3959 84.2042 61.9178C87.5056 64.4396 89.734 68.1142 90.4444 72.2074C90.5868 73.4426 90.5868 74.6901 90.4444 75.9253V75.9253Z"
      fill="#FEC458"
    />
    <path
      d="M79.6137 75.938C79.5794 75.3872 79.4226 74.851 79.1546 74.3686C78.8866 73.8861 78.5142 73.4697 78.0646 73.1496C77.2562 72.6081 76.3006 72.3287 75.3278 72.3492L78.284 68.0634L78.1162 66.3594L68.7441 67.263L68.9507 69.4059L75.1858 68.7734L72.3716 72.8269L72.5394 74.5696L73.8304 74.4405C74.6252 74.3065 75.442 74.4239 76.1669 74.7762C76.4033 74.9371 76.6018 75.1474 76.7489 75.3926C76.896 75.6377 76.9882 75.9119 77.0189 76.1962C77.0642 76.4934 77.0334 76.7972 76.9296 77.0794C76.8258 77.3615 76.6522 77.6128 76.4251 77.8098C75.8336 78.2935 75.0977 78.5661 74.3338 78.5844C73.5775 78.6628 72.8133 78.6104 72.0747 78.4295C71.4001 78.2695 70.7581 77.9944 70.1771 77.6162L69.7511 78.5844L77.3804 80.0302L77.5869 79.914C78.291 79.4988 78.8603 78.8892 79.2264 78.1584C79.4903 77.6148 79.6313 77.0198 79.6395 76.4156C79.6395 76.2478 79.6266 76.0929 79.6137 75.938Z"
      fill="white"
    />
    <path
      d="M86.3648 87.7501C84.067 90.177 79.7424 91.8036 74.7466 91.8036C67.9176 91.8036 62.2763 88.7054 61.5146 84.6906L65.0647 83.7354L86.3648 87.7501Z"
      fill="#FEC458"
    />
    <path
      d="M15.4932 82.0694L20.063 87.7752L22.916 80.0039L15.4932 82.0694Z"
      fill="white"
    />
    <path
      d="M30.958 97.7285L31.2291 102.776L35.9539 99.5616L30.958 97.7285Z"
      fill="white"
    />
    <path
      d="M112.583 114.743L112.854 119.791L117.592 116.576L112.583 114.743Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_48421_227270"
        x1="27.7178"
        y1="40.218"
        x2="108.646"
        y2="40.218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeleteEventIcon;
