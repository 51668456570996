import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Tooltip } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

import { fetchTemplateLocal } from 'store/tasks';

import TemplateEditorDrawer from '../../../../template-view/editor-drawer';

import styles from './scheduler.module.scss';

const { Text } = Typography;

export const Scheduler = ({ className, task, isAuthor }) => {
  const [template, setTemplate] = useState(undefined);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation('Task');

  const handleOpen = async () => {
    await dispatch(fetchTemplateLocal({ id: task.template })).then(setTemplate);

    setVisible(true);
  };

  return (
    <>
      <div
        className={classnames(styles.root, className)}
        data-qa="qa-5mmdk15zm3eyvsg"
      >
        <div className={styles.wrapper} data-qa="qa-eke0d108ve6irqp">
          <Icon
            type="retweet"
            size={16}
            className={styles.icon}
            data-qa="qa-ivszkixmz4cacem"
          />

          {!isAuthor && (
            <Text className={styles.label} data-qa="qa-foyn8629o4n54cv">
              {t('RepetitiveTaskBtn')}
            </Text>
          )}

          {isAuthor && (
            <Tooltip
              placement="bottom"
              title={t('RepetitiveTaskTip')}
              mouseEnterDelay={0.5}
            >
              <Button
                type="link"
                onClick={handleOpen}
                style={{ padding: 0, height: 'auto' }}
                data-qa="qa-ym2jvmha8c0em1j"
              >
                {t('RepetitiveTaskBtn')}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <TemplateEditorDrawer
        visible={visible}
        template={template}
        data-qa="qa-l9x52fk2682zmeu"
        onClose={() => setVisible(false)}
      />
    </>
  );
};

export default Scheduler;
