import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';

import { KEY, SELECTED_WORKSPACE } from 'constants/index';

import { initialLoadingAnimation } from 'components/common/icons';

import {
  acceptInviteWorkspace,
  fetchWorkspaces,
  setActiveId
} from 'store/workspace';

import { useQueryParam } from 'hooks/common';

const InviteeWorkspaceDataProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const selectedWorkspaceId = useQueryParam(SELECTED_WORKSPACE);
  const invitationKey = useQueryParam(KEY);

  const [isAccepted, setIsAccepted] = useState(false);

  const onAcceptMailInvitation = async () => {
    try {
      setIsLoading(true);

      await dispatch(acceptInviteWorkspace({ invitationKey }));
      setIsAccepted(true);
      history.replace({ search: '' });

      await dispatch(fetchWorkspaces());
      dispatch(setActiveId({ workspaceId: selectedWorkspaceId }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAccepted && invitationKey) {
      onAcceptMailInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((selectedWorkspaceId && invitationKey) || isLoading)
    return (
      <Lottie
        animationData={initialLoadingAnimation}
        loop
        style={{ width: '100%', height: 150, alignSelf: 'center' }}
      />
    );

  return <Fragment>{children}</Fragment>;
};

export default InviteeWorkspaceDataProvider;
