import React from 'react';

import Typography from 'components/common/typography';

import styles from './choose-tariff.module.scss';

const Topic = ({ topic, tariffCount, children }) => {
  const { title, color } = topic;

  return (
    <div className={styles.topic} style={{ gridColumn: `span ${tariffCount}` }}>
      <div className={styles.topicTitleWrap} style={{ backgroundColor: color }}>
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      </div>

      {children}
    </div>
  );
};

export default Topic;
