import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TIME_FORMAT } from 'constants/index';

import { useEmployeeRecords } from 'components/dashboard-view/views/calendar/hooks';
import { getCalendarLocale } from 'components/dashboard-view/views/calendar/utils';

import { getUILanguage } from 'store/ui';

const useMeetingCalendar = ({ visible, parentTaskId }) => {
  const ref = useRef();

  const language = useSelector(getUILanguage);

  const [dates, setDates] = useState();
  const [selectedDates, setSelectedDates] = useState({});

  const { t } = useTranslation('AddTask');

  const EMPTY_RESOURCES = [
    {
      id: 1,
      fake: true,
      title: t('NameSurname')
    },
    {
      id: 2,
      fake: true,
      title: t('NameSurname')
    }
  ];

  const [resources, setResources] = useState(EMPTY_RESOURCES);

  const {
    isLoading,
    records,
    openedRecord,
    onClickRecord
  } = useEmployeeRecords({
    dates,
    employees: resources.map(r => ({ value: r.id })).filter(res => !res.fake),
    parentTaskId
  });

  const locale = getCalendarLocale(language);

  const goToDate = value => {
    if (!value) {
      return;
    }

    const api = ref.current.getApi();

    if (!moment(value).isBetween(dates.start, dates.end, 'days', '[)')) {
      api.gotoDate(value);
    }

    setTimeout(() => {
      api.scrollToTime(moment(value).format(TIME_FORMAT));
    }, [100]);
  };

  const selectDatesToCalendar = ({ dateStart, dateEnd }) => {
    const api = ref.current.getApi();

    if (dateStart && dateEnd) {
      api.select(dateStart, dateEnd);
    }
  };

  useEffect(() => {
    if (selectedDates.start && selectedDates.end) {
      selectDatesToCalendar({
        dateStart: selectedDates.start,
        dateEnd: selectedDates.end
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if (!(resources || []).length) {
      setResources(EMPTY_RESOURCES);
    }
  }, [EMPTY_RESOURCES, resources]);

  useEffect(() => {
    if (visible) {
      goToDate(selectedDates.start || new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return {
    ref,
    selectedDates,
    resources,
    isLoading,
    records,
    openedRecord,
    locale,
    onClickRecord,
    goToDate,
    setDates,
    setSelectedDates,
    selectDatesToCalendar,
    setResources
  };
};

export default useMeetingCalendar;
