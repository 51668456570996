import { COUNTRIES } from 'constants/api';

export default api => ({
  fetch: ({ search, code, id, offset, limit, isRegistration }) =>
    api
      .get(`/v1/${COUNTRIES}/`, {
        params: {
          search,
          code,
          id,
          limit,
          offset,
          is_registration: isRegistration
        }
      })
      .then(({ data }) => data),
  fetchOne: id => api.get(`/v1/${COUNTRIES}/${id}/`).then(({ data }) => data)
});
