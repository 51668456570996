import api from 'api';

import createActionThunk from '../actions-thunk';
import { getFeatureByName } from './selectors';

export const fetchFeatures = createActionThunk('release/fetch-features', () =>
  api.release.fetchFeatures().then(({ data }) => data.results)
);

export const acceptFeature = createActionThunk(
  'release/accept-feature',
  ({ getState, id, name }) => {
    let resultId = id;

    const state = getState();

    if (name) {
      const feature = getFeatureByName(state)(name);
      resultId = feature.id;
    }

    return api.release
      .acceptFeature({ id: resultId })
      .then(() => ({ id: resultId }));
  }
);

export const fetchVersion = createActionThunk('release/fetch-version', () =>
  api.actions.fetchVersion().then(({ data }) => data.version)
);
