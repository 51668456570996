export const updateAttachmentSubscribers = ({
  attachments,
  setAttachments,
  data
}) => {
  const { added, deleted, entityId, userId } = data;

  if (added.includes(userId) || deleted.includes(userId)) {
    const index = attachments.findIndex(a => a.fileId === entityId);

    if (index !== -1) {
      const updatedEntry = {
        ...attachments[index],
        isSubscribed: !attachments[index].isSubscribed
      };
      setAttachments([
        ...attachments.slice(0, index),
        updatedEntry,
        ...attachments.slice(index + 1)
      ]);
    }
  }
};
