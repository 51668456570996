import { createAction } from 'redux-actions';

import { CHANNEL_TYPES, EMAIL, WHATSAPP } from 'constants/index';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';
import { getCurrentPage, getItemsPerPage } from './selectors';
import api from '../../api';

const NON_SYSTEM_CHANNEL_KINDS = CHANNEL_TYPES.map(t => t.type);

export const fetchChannels = createActionThunk(
  'channels/fetch',
  ({ isArchived, kind = NON_SYSTEM_CHANNEL_KINDS, page, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultPage = page || getCurrentPage(state);
    const limit = getItemsPerPage(state);
    const offset = limit * (resultPage - 1);

    return api.channels.fetch({
      workspaceId,
      limit,
      offset,
      kind,
      is_archived: isArchived
    });
  }
);

export const fetchChannelsLocal = createActionThunk(
  'channels/fetch-local',
  ({
    isArchived,
    kind = NON_SYSTEM_CHANNEL_KINDS,
    offset,
    limit,
    getState,
    hasChannelRole,
    isActive,
    search,
    channelUuid,
    lite
  }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const limitPerPage = getItemsPerPage(state);

    return api.channels.fetch({
      workspaceId,
      kind,
      limit: limit || limitPerPage,
      offset,
      is_archived: isArchived,
      has_channel_role: hasChannelRole,
      is_active: isActive,
      search,
      uuid: channelUuid,
      lite
    });
  }
);

export const fetchEmailChannels = createActionThunk(
  'channels/fetch-emails-channels',
  ({ getState, hasChannelRole, limit }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels
      .fetch({
        workspaceId,
        kind: EMAIL,
        limit,
        is_archived: false,
        has_channel_role: hasChannelRole,
        is_active: true
      })
      .then(({ results }) => results);
  }
);

export const fetchWhatsAppChannels = createActionThunk(
  'channels/fetch-whatsapp-channels',
  ({ getState, hasChannelRole, limit }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels
      .fetch({
        workspaceId,
        kind: WHATSAPP,
        limit,
        is_archived: false,
        has_channel_role: hasChannelRole,
        is_active: true
      })
      .then(({ results }) => results);
  }
);

export const fetchChannel = createActionThunk(
  'channels/fetch-one',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.fetchOne({ workspaceId, id });
  }
);

export const createChannel = createActionThunk(
  'channels/create',
  ({ channel, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.create({ workspaceId, ...channel });
  }
);

export const editChannel = createActionThunk(
  'channels/edit',
  ({ channel, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.edit({ workspaceId, ...channel });
  }
);

export const deleteChannel = createActionThunk(
  'channels/delete',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.delete({ workspaceId, id }).then(() => ({ id }));
  }
);

export const fetchOperatorsChannel = createActionThunk(
  'channels/fetch-operators',
  ({ id, operators = [], getState }) => {
    const state = getState();
    const currentWorkspace = getWorkspaceId(state);

    return api.channels
      .fetchOperators({
        workspaceId: currentWorkspace,
        id,
        operators
      })
      .then(results => ({
        id,
        operators: results
      }));
  }
);

export const operatorsChannel = createActionThunk(
  'channels/operators',
  ({ id, operators = [], getState }) => {
    const state = getState();
    const currentWorkspace = getWorkspaceId(state);

    return api.channels
      .operators({
        workspaceId: currentWorkspace,
        id,
        operators
      })
      .then(({ operators: entries }) => ({
        id,
        operators: entries
      }));
  }
);

export const notifyChannel = createActionThunk(
  'channels/notify',
  ({ id, value, getState }) => {
    const state = getState();
    const currentWorkspace = getWorkspaceId(state);

    return api.channels
      .notify({
        workspaceId: currentWorkspace,
        id,
        value
      })
      .then(() => ({ id, value }));
  }
);

export const sourceExistence = createActionThunk(
  'channels/source-existance',
  ({ source, kind, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.sourceExistence({
      workspaceId,
      source,
      kind
    });
  }
);

export const emailAuthentication = createActionThunk(
  'channels/email-authentication',
  ({ data, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.emailAuthentication({
      workspaceId,
      data
    });
  }
);

export const fetchUserChannelGroups = createActionThunk(
  'channels/user-channel-groups',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.fetchUserChannelGroups({
      workspaceId
    });
  }
);

export const updateEmailChannelPassword = createActionThunk(
  'channels/update-email-channel-password',
  ({ getState, id, credentials }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.channels.updateEmailChannelPassword({
      workspaceId,
      id,
      credentials
    });
  }
);

export const setCurrentPage = createAction('channels/set-current-page');
