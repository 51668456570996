import React from 'react';

export const CopyIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7148 0H4.14342C4.06484 0 4.00056 0.0642857 4.00056 0.142857V1.14286C4.00056 1.22143 4.06484 1.28571 4.14342 1.28571H13.0006V13.5714C13.0006 13.65 13.0648 13.7143 13.1434 13.7143H14.1434C14.222 13.7143 14.2863 13.65 14.2863 13.5714V0.571429C14.2863 0.255357 14.0309 0 13.7148 0ZM11.4291 2.28571H2.28627C1.9702 2.28571 1.71484 2.54107 1.71484 2.85714V12.3339C1.71484 12.4857 1.77556 12.6304 1.8827 12.7375L4.97734 15.8321C5.01663 15.8714 5.06127 15.9036 5.10949 15.9304V15.9643H5.18449C5.24699 15.9875 5.31306 16 5.38092 16H11.4291C11.7452 16 12.0006 15.7446 12.0006 15.4286V2.85714C12.0006 2.54107 11.7452 2.28571 11.4291 2.28571ZM5.1077 14.1464L3.5702 12.6071H5.1077V14.1464ZM10.7148 14.7143H6.25056V12.1786C6.25056 11.7839 5.93092 11.4643 5.53627 11.4643H3.00056V3.57143H10.7148V14.7143Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyIcon;
