import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PAYMENT_ACCOUNT_STATUS_BLOCKED } from 'constants/billing';

import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';

import { getAllWorkspaces, getJoining, setActiveId } from 'store/workspace';
import {
  fetchPaymentAccount,
  getIsNeedToFetchPaymentAccount,
  setIsOnlyCompanyProfile
} from 'store/billing';

import getActiveWorkspaces from 'utils/get-active-workspaces';
import useRoutesService from 'services/routes';

import PaymentFailedBanner from './payment-failed-banner';

const Provider = ({ workspace }) => {
  const dispatch = useDispatch();

  const joining = useSelector(getJoining);
  const isNeedToFetchAccount = useSelector(getIsNeedToFetchPaymentAccount);
  const workspaces = useSelector(getAllWorkspaces);

  const routes = useRoutesService();

  const { account, user } = workspace;
  const activeWorkspaces = getActiveWorkspaces(workspaces);

  const redirectAfterBlock = () => {
    const hasPaymentAccess = checkPaymentAccessByRoles(user.roles);

    if (hasPaymentAccess) {
      return routes.toBilling();
    }

    if (activeWorkspaces.length) {
      dispatch(setActiveId({ workspaceId: activeWorkspaces[0].id }));

      return routes.toDefaultPage({ workspaceId: activeWorkspaces[0].id });
    }

    dispatch(setActiveId({ workspaceId: user.personalWorkspaceId }));

    return routes.toUserProfile({ workspaceId: user.personalWorkspaceId });
  };

  useEffect(() => {
    if (isNeedToFetchAccount) {
      dispatch(fetchPaymentAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToFetchAccount]);

  useEffect(() => {
    const isPaymentAccountStatusBlocked =
      account.status === PAYMENT_ACCOUNT_STATUS_BLOCKED;

    dispatch(setIsOnlyCompanyProfile(isPaymentAccountStatusBlocked));

    if (isPaymentAccountStatusBlocked) {
      redirectAfterBlock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.status]);

  if (joining.value) {
    return null;
  }

  return <PaymentFailedBanner />;
};

export default Provider;
