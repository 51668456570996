import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';

import useRecordDetails from './use-record-details';
import RecordDetailsSkeleton from './skeleton';

import styles from './record-details.module.scss';

const RecordDetailsModal = ({ openedRecord }) => {
  const {
    visible,
    onClose,
    data,
    updateRecordInList,
    fetchRecords
  } = openedRecord;

  const { view, record, isLoading } = useRecordDetails({
    data,
    visible,
    onClose,
    updateRecordInList,
    fetchRecords
  });

  return (
    <Modal
      width={600}
      visible={visible}
      contentClassName={styles.root}
      onClose={onClose}
      destroyOnClose
      centered
    >
      {isLoading || !record ? <RecordDetailsSkeleton /> : view}
    </Modal>
  );
};

RecordDetailsModal.propTypes = {
  openedRecord: PropTypes.shape({})
};

RecordDetailsModal.defaultProps = {
  openedRecord: {}
};

export default RecordDetailsModal;
