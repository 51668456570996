import React from 'react';

export const ArrowIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.70062 8.19691C4.43313 8.45945 4.43313 8.88512 4.70062 9.14767L11.4813 15.803C11.6235 15.9426 11.8127 16.008 11.999 15.9991C12.1859 16.0085 12.3759 15.9432 12.5187 15.8031L19.2994 9.14777C19.5669 8.88523 19.5669 8.45956 19.2994 8.19701C19.0319 7.93447 18.5982 7.93447 18.3307 8.19701L12.0001 14.4106L5.66929 8.19691C5.4018 7.93436 4.96811 7.93436 4.70062 8.19691Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
