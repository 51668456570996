import React, { Children, cloneElement } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Typography from 'components/common/typography';

import styles from './form-item.module.scss';

export const FormItem = ({
  name,
  label,
  errors,
  children,
  className,
  errorTextClassName,
  ...props
}) => {
  const error = get(errors, name);

  return (
    <div
      className={classnames(styles.root, className, {
        [styles.hasError]: !!error
      })}
      {...props}
    >
      {label && <span className={styles.label}>{label}</span>}

      {Children.map(children, child =>
        cloneElement(child, {
          ...child.props,
          name
        })
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        as={
          <Typography.Text
            color="red"
            style={{ fontSize: 12, display: 'block' }}
            className={errorTextClassName}
          />
        }
      />
    </div>
  );
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  errors: PropTypes.object,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  errorTextClassName: PropTypes.string
};

FormItem.defaultProps = {
  label: undefined,
  errors: {},
  className: '',
  errorTextClassName: undefined
};

export default FormItem;
