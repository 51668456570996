import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import replace from 'lodash/replace';

export const variable = path => `{{${path}}}`;

const mapValuesDeep = (v, callback) =>
  isObject(v) ? mapValues(v, _v => mapValuesDeep(_v, callback)) : callback(v);

const TEMPLATE_RE = /{{\s*([^}]+)\s*}}/g;

const resolveValue = (values, path) => {
  const value = get(values, path);

  if (TEMPLATE_RE.test(value)) {
    return resolveValue(values, value.replace('{{', '').replace('}}', ''));
  }

  return value;
};

export const createTheme = values =>
  mapValuesDeep(values, value =>
    replace(value, TEMPLATE_RE, (_, path) => {
      const _value = resolveValue(values, path);

      if (typeof _value === 'undefined') {
        throw new Error(`Property ${path} does not exist`);
      }

      return _value;
    })
  );

export default createTheme;
