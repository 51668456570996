import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { INVITE_DATA } from 'constants/index';

import WorkflowOneNine from 'components/auth-view/register-steps-view/workflow-one-nine';
import Drawer from 'components/common/drawer';

import {
  getActiveWorkspace,
  getUserEmployee,
  setActiveId,
  updateWorkspaceUser
} from 'store/workspace';

import useModalsService from 'services/modals';
import { useLocalStorage } from 'hooks';

import InvitationStepForm from './invitation-step-form';

import styles from './styles.module.scss';

const NOT_FLOW = 0;

export const CreateWorkspaceDrawer = ({ onClose, ...props }) => {
  const dispatch = useDispatch();

  const modals = useModalsService();
  const { state } = useLocation();

  const [, setInviteData] = useLocalStorage(INVITE_DATA, {});

  const user = useSelector(getUserEmployee);
  const workspace = useSelector(getActiveWorkspace);

  const { t } = useTranslation('AddCompanyFromSystem');

  const handleClose = () => {
    setInviteData({});
    onClose();
  };

  const callback = async (_, __, workspaceId = workspace.id) => {
    await dispatch(
      updateWorkspaceUser({ user: { id: user.id, flow: NOT_FLOW } })
    );

    modals.close();

    setInviteData({});

    dispatch(setActiveId({ workspaceId }));
  };

  if (Object.values(state || {}).filter(Boolean).length) {
    return (
      <Drawer
        destroyOnClose
        className={styles.root}
        onClose={handleClose}
        {...props}
      >
        <div className={styles.content}>
          <InvitationStepForm />
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      title={<Drawer.Title>{t('AddCompanyHeading')}</Drawer.Title>}
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      onClose={handleClose}
      {...props}
    >
      <div className={styles.content} data-qa="qa-ksfqtq6puo6coan">
        <WorkflowOneNine
          isCreateWorkspace
          className={styles.form}
          data-qa="qa-919sv17zbg6uveg"
          defaultData={{
            countryCode: workspace ? workspace.country.alpha2Code : 'BY'
          }}
          callback={callback}
        />
      </div>
    </Drawer>
  );
};

export default CreateWorkspaceDrawer;
