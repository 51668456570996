import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton, Button, Badge, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import {
  getActiveWorkspace,
  getAllWorkspaces,
  getIsWorkspacesLoaded,
  getPersonalWorkspace
} from 'store/workspace';
import {
  getIsUserLoading,
  getIsUserLoaded,
  getIsOnlyUserProfile
} from 'store/user';
import { getEmployeeEntityChatsUnreadedCount } from 'store/operator';
import { getIsOnlyCompanyProfile } from 'store/billing';

import { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';
import getWorkspaceTitle from 'utils/get-workspace-title';

import Avatar from '../../../../avatar';
import { WorkspaceLogoIcon } from '../../../../icons';
import WorkspacesSwitch from '../../../../workspaces-switch';

import styles from './workspace-info.module.scss';

export const WorkspaceInfo = ({ className }) => {
  const isLoading = useSelector(getIsUserLoading);
  const isLoaded = useSelector(getIsUserLoaded);
  const currentWorkspace = useSelector(getActiveWorkspace);
  const unreadedCount = useSelector(getEmployeeEntityChatsUnreadedCount);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const workspaces = useSelector(getAllWorkspaces);
  const isWorkspacesLoaded = useSelector(getIsWorkspacesLoaded);
  const personalWorkspace = useSelector(getPersonalWorkspace);
  const isOnlyCompanyProfile = useSelector(getIsOnlyCompanyProfile);

  const routes = useRoutesService({ returnUrl: true });

  const { t } = useTranslation('Menu');

  const workspesList = Object.values(workspaces);

  const ref = useRef({});

  if (isLoading || !isLoaded || !isWorkspacesLoaded) {
    return (
      <Skeleton
        avatar
        active
        paragraph={false}
        className={classnames(styles.skeleton, className)}
      />
    );
  }

  if (isOnlyUserProfile && !workspesList.length) {
    return null;
  }

  const activeWorkspace = currentWorkspace || personalWorkspace;

  const workspaceTitle = getWorkspaceTitle({
    title: activeWorkspace.title,
    aliasTitle: activeWorkspace.aliasTitle
  });

  const showDot = !!Object.keys(unreadedCount).find(
    roomUuid =>
      activeWorkspace.user.chats &&
      activeWorkspace.user.chats[0].uuid !== roomUuid &&
      !!unreadedCount[roomUuid]
  );

  const toCompanyProfile = () => routes.toCompany({ id: activeWorkspace.id });
  const toUserProfile = () =>
    routes.toUserWorkspace({ employeeId: activeWorkspace.user.id });

  return (
    <div className={classnames(styles.root, className)} ref={ref}>
      {isOnlyUserProfile && !!workspesList.length ? (
        <Typography.Text>{t('ChooseWorkspaceHeading')}</Typography.Text>
      ) : (
        <>
          <Avatar
            src={(activeWorkspace.logoFile || {}).url}
            size={38}
            title={workspaceTitle}
            className={styles.avatar}
          >
            <Icon component={WorkspaceLogoIcon} />
          </Avatar>

          <div className={styles.info}>
            <Tooltip
              title={t('CompanyProfileTip')}
              placement="bottom"
              mouseEnterDelay={0.5}
            >
              <Link className={styles.link} to={toCompanyProfile()}>
                {workspaceTitle}
              </Link>
            </Tooltip>

            {isOnlyCompanyProfile ? (
              <Typography.Text size="small">
                {getFullName(activeWorkspace.user)}
              </Typography.Text>
            ) : (
              <Tooltip
                title={t('EmployeeProfileTip')}
                placement="bottom"
                mouseEnterDelay={0.5}
              >
                <Link className={styles.link} to={toUserProfile()}>
                  {getFullName(activeWorkspace.user)}
                </Link>
              </Tooltip>
            )}
          </div>
        </>
      )}

      <WorkspacesSwitch dropdownWidth={ref.current.clientWidth}>
        <div style={{ height: 45, display: 'flex', alignItems: 'center' }}>
          <Button className={styles.dropdownTrigger} type="link" size="small">
            <Icon type="arrow" color="black-55" size={20} />
          </Button>
        </div>
      </WorkspacesSwitch>

      <Badge dot={showDot} style={{ position: 'absolute', right: '-5px' }} />
    </div>
  );
};

WorkspaceInfo.propTyps = {
  className: PropTypes.string
};

WorkspaceInfo.defaultProps = {
  className: undefined
};

export default WorkspaceInfo;
