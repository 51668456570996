import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Trans } from 'react-i18next';

import { CREATE_BOT, CONNECT_BOT } from 'constants/index';

import Typography from 'components/common/typography';

import styles from './telegram-bot.module.scss';

const { Paragraph, Text } = Typography;

const Instruction = ({ method }) => {
  const message = {
    [CREATE_BOT]: (
      <Trans
        i18nKey="ConnectTelegramInstructionsNew"
        ns="ConnectTelegram"
        components={{
          ph24: <Paragraph className={styles.m24} />,
          ph: <Paragraph className={styles.m0} />,
          bold: <Text weight="semibold" />,
          a: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://telegram.me/botfather"
              target="_blank"
              rel="noopener noreferrer"
            />
          )
        }}
      />
    ),
    [CONNECT_BOT]: (
      <Trans
        i18nKey="ConnectTelegramInstructionsExisting"
        ns="ConnectTelegram"
        components={{
          ph24: <Paragraph className={styles.m24} />,
          ph: <Paragraph className={styles.m0} />,
          bold: <Text weight="semibold" />,
          a: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://telegram.me/botfather"
              target="_blank"
              rel="noopener noreferrer"
            />
          )
        }}
      />
    )
  };

  return (
    <Alert type="warning" message={message[method]} className={styles.m24} />
  );
};

Instruction.propTypes = {
  method: PropTypes.oneOf([CREATE_BOT, CONNECT_BOT]).isRequired
};

export default Instruction;
