import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  CREATOR_DRAWER_WIDTH,
  CREATE_ORDER_STEP,
  SELECT_ORDER_TYPE_STEP,
  TYPE_ORDER,
  TYPE_ORDER_TEMPLATE,
  OUTGOING,
  INCOMING
} from 'constants/index';

import Drawer from 'components/common/drawer';
import { SkeletonEntityCreation } from 'components/common/skeletons';
// import WorkspaceVerifyModal from 'components/common/workspace-verify-modal';
// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';

import useCreator from './use-creator';

const OrderCreatorDrawer = ({ data, visible, onClose }) => {
  const {
    component,
    title,
    // employee,
    // phoneModal,
    // verifyModal,
    handleClose
  } = useCreator({ ...data, visible, onClose });

  const [afterVisible, setAfterVisible] = useState(false);

  return (
    <>
      <Drawer
        width={CREATOR_DRAWER_WIDTH}
        title={<Drawer.Title>{title}</Drawer.Title>}
        visible={visible}
        maskClosable={false}
        bodyStyle={{ padding: 0 }}
        onClose={handleClose}
        destroyOnClose
        afterVisibleChange={setAfterVisible}
      >
        {afterVisible ? component : <SkeletonEntityCreation />}
      </Drawer>

      {/* <WorkspaceVerifyModal
        visible={verifyModal.visible}
        description={
          <>
            Внимание! <br />
            Ваш контрагент зарегистрирован в Upservice. Проводить сделки в
            режиме онлайн могут только компании, которые прошли проверку. Чтобы
            сделать это — введите название компании и ее страну. Мы проверим ваш
            налоговый статус.
          </>
        }
        onClose={verifyModal.onClose}
        callback={verifyModal.callback}
      /> */}

      {/* <PhoneConfirmationModal
        visible={phoneModal.visible}
        employee={employee}
        description={
          <>
            Подтверждение номера телефона необходимо <br /> для обеспечения
            безопасной работы наших <br /> пользователей
          </>
        }
        onClose={phoneModal.onClose}
        callback={phoneModal.callback}
      /> */}
    </>
  );
};

OrderCreatorDrawer.propTypes = {
  data: PropTypes.shape({
    defaultStep: PropTypes.oneOf([SELECT_ORDER_TYPE_STEP, CREATE_ORDER_STEP]),
    defaultKind: PropTypes.oneOf([OUTGOING, INCOMING]),
    defaultTab: PropTypes.oneOf([TYPE_ORDER, TYPE_ORDER_TEMPLATE]),
    defaultValues: PropTypes.object,
    withShowDetails: PropTypes.bool,
    callback: PropTypes.func
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

OrderCreatorDrawer.defaultProps = {
  data: {}
};

export default OrderCreatorDrawer;
