import { EN_US, RU_RU } from 'i18n-config';

export const getExternalUrl = ({ enUrl, ruUrl, currentLanguage }) => {
  const map = {
    [EN_US]: enUrl,
    [RU_RU]: ruUrl
  };

  return map[currentLanguage];
};

export default getExternalUrl;
