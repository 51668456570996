import React, { useEffect, useState, forwardRef, useMemo } from 'react';
import api from 'api';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-named-default
import { default as PInput } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { RU_RU } from 'i18n-config';

import { SkeletonInput } from 'components/common/skeletons';

import { getActiveWorkspace } from 'store/workspace';
import { getUILanguage } from 'store/ui';

import { getPhoneCodeByContryCode } from './helpers';

import styles from './phone-input.module.scss';

export const PhoneInput = forwardRef(
  (
    {
      size,
      value,
      onChange,
      id,
      errors,
      isDefaultOnChange,
      disabled,
      ...props
    },
    ref
  ) => {
    const workspace = useSelector(getActiveWorkspace);
    const language = useSelector(getUILanguage);

    const localization = useMemo(() => {
      if (language === RU_RU) {
        return ru;
      }

      return undefined;
    }, [language]);

    const [defaultCallingCode, setDefaultCallingCode] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSwitchingLanguage, setIsSwitchingLanguage] = useState(false);

    const fetchIP = async () => {
      const { data } = await api.geolocation.fetchIP();

      return data;
    };

    const fetchCountryCodeByIP = async ip => {
      const { data } = await api.geolocation.fetchInfo(ip);

      return data.country_code;
    };

    const getGeoInfo = async () => {
      setIsLoading(true);

      try {
        const IP = await fetchIP();
        const countryCode = workspace
          ? workspace.country.alpha2Code
          : await fetchCountryCodeByIP(IP);
        const callingCode = getPhoneCodeByContryCode(countryCode);

        if (isDefaultOnChange) {
          onChange(callingCode);
        } else {
          setDefaultCallingCode(callingCode);
        }
      } catch {
        if (isDefaultOnChange) {
          onChange('+375');
        } else {
          setDefaultCallingCode('+375');
        }
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (!value) {
        getGeoInfo();
      } else {
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (
      _,
      { countryCode, dialCode },
      ___,
      formattedValue
    ) => {
      onChange(formattedValue, id, countryCode, dialCode);
      setDefaultCallingCode('');
    };

    useEffect(() => {
      setIsSwitchingLanguage(true);
    }, [language]);

    useEffect(() => {
      let timeout;

      if (isSwitchingLanguage) {
        timeout = setTimeout(() => setIsSwitchingLanguage(false), 200);
      }

      return () => clearTimeout(timeout);
    }, [isSwitchingLanguage]);

    if (isSwitchingLanguage) {
      return <SkeletonInput height={40} withLabel={false} />;
    }

    return (
      <PInput
        ref={ref}
        country="BY"
        localization={localization}
        value={value || defaultCallingCode}
        onChange={handleChange}
        disabled={isLoading || disabled}
        containerClass={classnames(styles.root, styles[size])}
        inputClass={classnames(styles.input, {
          [styles.notErrors]: isEmpty(errors),
          [styles.disabled]: disabled
        })}
        data-qa="qa-4aeb2hhx64swtiy"
        placeholder={isLoading ? '...' : '375 (29) 111 22 33'}
        areaCodes={{ kz: [7, 6] }}
        {...props}
      />
    );
  }
);

PhoneInput.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['default', 'large', 'small']),
  isDefaultOnChange: PropTypes.bool,
  countryAlpha2Code: PropTypes.string,
  disabled: PropTypes.bool
};

PhoneInput.defaultProps = {
  onChange: () => {},
  size: 'default',
  isDefaultOnChange: false,
  countryAlpha2Code: null,
  disabled: false
};

export default PhoneInput;
