import React, { forwardRef, useMemo, useCallback } from 'react';
import { Button } from 'antd';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getUserEmployee } from 'store/workspace';

const WithYourselfButton = forwardRef(
  (
    {
      children,
      className,
      value,
      isMulti,
      isDisabled,
      allowSetYourself,
      onChange
    },
    ref
  ) => {
    const { t } = useTranslation('Common');
    const employee = useSelector(getUserEmployee);

    const setYourself = useCallback(() => {
      const yourselfValue = { value: employee.id, label: employee };

      return onChange(isMulti ? [yourselfValue] : yourselfValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee.id, isMulti]);

    const getSelectedYourself = useCallback(() => {
      if (isMulti) {
        return !!(value || []).find(item => item.value === employee.id);
      }

      return !isNil(value) ? value.value === employee.id : false;
    }, [employee.id, isMulti, value]);

    const selectedYourself = useMemo(getSelectedYourself, [employee.id, value]);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
        className={className}
        data-qa="qa-eh6er398t2pkc8v"
        ref={ref}
      >
        {children}

        {allowSetYourself && !selectedYourself && !isDisabled && (
          <Button
            type="link"
            size="small"
            style={{
              padding: '0',
              flexShrink: 0,
              fontWeight: 400,
              fontSize: 12
            }}
            data-qa="qa-i04k1mxp9vc67kk"
            onClick={setYourself}
          >
            {t('SetToMeFieldBtn')}
          </Button>
        )}
      </div>
    );
  }
);

WithYourselfButton.propTypes = {
  allowSetYourself: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

WithYourselfButton.defaultProps = {
  value: undefined,
  className: undefined,
  allowSetYourself: true,
  isMulti: false,
  isDisabled: false,
  onChange: () => {}
};

export default WithYourselfButton;
