import React from 'react';

export const EmptyMailboxIcon = () => (
  <svg
    width="127"
    height="104"
    viewBox="0 0 127 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M68.059 103.883C55.2133 103.883 12.4814 99.1631 2.882 80.1951C-6.15997 62.3285 8.40078 54.1951 14.669 39.1951C25.0129 14.4422 57.8609 -5.11532 89.282 1.19513C120.703 7.50557 132.487 46.231 122.857 67.8964C113.227 89.5618 93.0045 103.883 68.059 103.883Z"
      fill="#36B7BD"
    />
    <path
      d="M64 103.766C65.5571 103.846 66.9246 103.883 68.059 103.883C70.4165 103.883 72.7318 103.755 75 103.505V62H64V103.766Z"
      fill="#6A6972"
    />
    <path
      d="M84.3367 28H46.6633C37.4601 28 30 35.6116 30 45V60.5C30 61.3284 30.6716 62 31.5 62H99.5C100.328 62 101 61.3284 101 60.5V45C101 35.6116 93.5399 28 84.3367 28Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M64 62V45.5C64 36.3877 56.3892 29 47 29C37.6108 29 30 36.3877 30 45.5V62H64Z"
      fill="#2D9EA3"
    />
    <path
      d="M47.44 29C46.9547 29 46.4752 29.0251 46 29.066C54.4708 29.7942 61.12 36.8719 61.12 45.5V62H64V45.5C64 36.3877 56.5862 29 47.44 29Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M59.7684 79.8836C57.3406 88.6362 48.1392 93.8012 39.2173 91.4197C30.2947 89.0383 25.175 82.8921 27.602 74.1395L30.5238 62H64L59.7684 79.8836Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M71.0037 32.7422V43.8795C70.1465 44.3753 69.5698 45.3021 69.5698 46.3635C69.5698 47.9473 70.8537 49.2311 72.4375 49.2311C74.0212 49.2311 75.3051 47.9473 75.3051 46.3635C75.3051 45.3021 74.7284 44.3753 73.8713 43.8795V38.4775H76.022C77.5993 38.4775 78.8897 37.187 78.8897 35.6098C78.8897 34.0326 77.5993 32.7422 76.022 32.7422H71.0037Z"
      fill="#6A6972"
    />
    <path d="M75 62H64V64H75V62Z" fill="#2B2A36" fillOpacity="0.3" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="103.219"
        y1="54.8421"
        x2="82.2861"
        y2="29.1526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="58.7143"
        y1="92"
        x2="59.1997"
        y2="66.9563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyMailboxIcon;
