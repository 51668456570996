import React from 'react';
import classNames from 'classnames';
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from '../../toolbar.module.scss';

const UnorderedListItem = ({ editor, blockType }) => {
  const { t } = useTranslation('CommonChat');

  return (
    <Tooltip title={t('UnorderedListTip')} mouseEnterDelay={0.5}>
      <Button
        className={classNames(styles.toolbarItem, styles.toolbarSpaced, {
          [styles.toolbarActive]: blockType === 'ul'
        })}
        onClick={() => {
          if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
          }
        }}
      >
        <Icon type="unordered-list" size={16} />
      </Button>
    </Tooltip>
  );
};

export default UnorderedListItem;
