import React, { useMemo, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

import FormItem from '../form-item';

const PASSWORD = 'password';
const TEXT = 'text';

export const FormInputPassword = ({
  rules,
  name,
  label,
  itemProps,
  onChange: customOnChange,
  ...props
}) => {
  const { control, formState } = useFormContext();

  const [type, setType] = useState(PASSWORD);

  const { t } = useTranslation('SignIn');

  const suffix = useMemo(() => {
    const tooltipTitle = t(
      type === PASSWORD ? 'ShowPasswordTip' : 'HidePasswordTip'
    );

    const onClick = () => {
      const result = type === PASSWORD ? TEXT : PASSWORD;

      return setType(result);
    };

    return (
      <Tooltip title={tooltipTitle} mouseEnterDelay={0.5}>
        <Icon
          type={type === PASSWORD ? 'eye' : 'eye-invisible'}
          size={20}
          onClick={onClick}
        />
      </Tooltip>
    );
  }, [t, type]);

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { onChange, ...renderProps } = field;

          return (
            <Input
              type={type}
              suffix={suffix}
              onChange={value => {
                onChange(value);
                customOnChange(value);
              }}
              {...renderProps}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormInputPassword.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  onChange: PropTypes.func
};

FormInputPassword.defaultProps = {
  rules: {},
  itemProps: {},
  onChange: () => {}
};

export default FormInputPassword;
