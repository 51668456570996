import React from 'react';

export const DeclinedIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.5" r="12" fill="#F9807D" />
    <path
      d="M8.77689 7.53036C8.11678 6.9046 7.08754 6.91782 6.47624 7.55991C5.86308 8.20396 5.90282 9.23632 6.56493 9.86398L15.3273 18.1705C15.9874 18.7962 17.0167 18.783 17.628 18.1409C18.2411 17.4969 18.2014 16.4645 17.5393 15.8368L8.77689 7.53036Z"
      fill="white"
    />
    <path
      d="M15.3276 7.53036C15.9877 6.9046 17.0169 6.91782 17.6283 7.55992C18.2414 8.20396 18.2017 9.23632 17.5396 9.86398L8.77715 18.1705C8.11704 18.7962 7.08781 18.783 6.47651 18.1409C5.86335 17.4969 5.90309 16.4645 6.5652 15.8368L15.3276 7.53036Z"
      fill="white"
    />
  </svg>
);

export default DeclinedIcon;
