import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

const compareFn = (current, initial) => {
  const isFalsyAndNil =
    (current === false || initial === false) &&
    (isNil(current) || isNil(initial));

  if (isFalsyAndNil) {
    return true;
  }

  const isObjectValue = isObject(current) || isObject(initial);

  const v1 = isObjectValue ? omitBy(current, isUndefined) : current;
  const v2 = isObjectValue ? omitBy(initial, isUndefined) : initial;

  return isEqual(v1, v2);
};

export const useFilterIndicator = (
  initialFilter = {},
  currentFilter = {},
  execludeFn = () => false
) => {
  const getDiffsCount = (obj1, obj2, excludeFn = execludeFn) =>
    Object.keys(obj1).reduce(
      (acc, key) =>
        excludeFn(key)
          ? acc
          : acc + !isEqualWith(obj1[key], obj2[key], compareFn),
      0
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicator = useMemo(() => getDiffsCount(initialFilter, currentFilter), [
    currentFilter
  ]);

  return indicator;
};

export default useFilterIndicator;
