import React from 'react';
import PropTypes from 'prop-types';

import ValidityDateMultiAttachment from '../validity-date-multi-attachment';
import ManageValidityDate from '../manage-validity-date';

const ValidityDateWrapper = ({ attachmentProps, file, index }) => {
  if (!attachmentProps) {
    return null;
  }

  return (
    <>
      {attachmentProps.validityDates &&
        attachmentProps.validityDates.fields[index] && (
          <ValidityDateMultiAttachment
            key={attachmentProps.validityDates.fields[index].id}
            data={attachmentProps.validityDates.fields[index]}
            index={index}
          />
        )}

      {attachmentProps.validityDateDeps && (
        <ManageValidityDate
          file={file}
          index={index}
          validityDateDeps={attachmentProps.validityDateDeps}
        />
      )}
    </>
  );
};

ValidityDateWrapper.propTypes = {
  attachmentProps: PropTypes.object,
  file: PropTypes.object,
  index: PropTypes.number
};

ValidityDateWrapper.defaultProps = {
  attachmentProps: {},
  file: {},
  index: 0
};

export default ValidityDateWrapper;
