import { useSelector } from 'react-redux';

import { TYPE_EMPLOYEE } from 'constants/index';

import {
  getEmployeeEntityChatsUnreadedCount,
  getEntityChat
} from 'store/operator';
import { getUserEmployee } from 'store/workspace';

import { useWebsocketOperatorContext } from 'providers';

const useReadAll = () => {
  const employee = useSelector(getUserEmployee);

  const entityChat = useSelector(state =>
    getEntityChat(state)({ entityId: employee.id, entityType: TYPE_EMPLOYEE })
  );
  const unreadedCount = useSelector(getEmployeeEntityChatsUnreadedCount);

  const socket = useWebsocketOperatorContext();

  const { chats } = employee;
  const { isMessagesLoading, messages = [] } = entityChat;

  const allowReadAllNotifications =
    !!unreadedCount[employee.chats[0].uuid] &&
    (messages.length || !isMessagesLoading);

  const onReadAllNotifications = () =>
    socket.readAllNotificationMessages({
      roomUuid: chats[0].uuid,
      channelKind: chats[0].channelKind,
      channelUuid: chats[0].channelUuid
    });

  return { allowReadAllNotifications, onReadAllNotifications };
};

export default useReadAll;
