import React from 'react';

export const MusicIcon = () => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0665 0.0195022C4.2865 0.329502 0.666504 4.5195 0.666504 9.3095V15.9995C0.666504 17.6595 2.0065 18.9995 3.6665 18.9995H4.6665C5.7665 18.9995 6.6665 18.0995 6.6665 16.9995V12.9995C6.6665 11.8995 5.7665 10.9995 4.6665 10.9995H2.6665V9.2895C2.6665 5.4495 5.6265 2.1095 9.4565 1.9995C13.4265 1.8895 16.6665 5.0595 16.6665 8.9995V10.9995H14.6665C13.5665 10.9995 12.6665 11.8995 12.6665 12.9995V16.9995C12.6665 18.0995 13.5665 18.9995 14.6665 18.9995H16.6665V19.9995H10.6665C10.1165 19.9995 9.6665 20.4495 9.6665 20.9995C9.6665 21.5495 10.1165 21.9995 10.6665 21.9995H15.6665C17.3265 21.9995 18.6665 20.6595 18.6665 18.9995V8.9995C18.6665 3.8295 14.3065 -0.320498 9.0665 0.0195022Z"
      fill="white"
    />
  </svg>
);

export default MusicIcon;
