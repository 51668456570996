import React from 'react';

export const EmptyListIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M140.257 93.9202L140.257 93.921C130.752 115.341 111.756 130.219 88.0885 132.769L88.0837 132.77C85.676 133.053 83.1241 133.195 80.5707 133.195C79.285 133.195 77.875 133.195 76.1836 133.054L76.175 133.053L76.1663 133.053C67.3375 132.626 52.9505 130.916 39.588 126.948C32.9071 124.964 26.4995 122.421 21.1753 119.203C15.8483 115.982 11.6383 112.106 9.30293 107.47L9.30163 107.468C6.83479 102.64 5.98606 98.4947 6.18382 94.7439C6.38176 90.9894 7.63028 87.5893 9.42188 84.2532C10.8766 81.5445 12.675 78.9019 14.5486 76.1488C14.9896 75.5009 15.4348 74.8467 15.8806 74.1842C18.2121 70.7193 20.5469 67.044 22.3164 62.8912L22.3172 62.8892C27.9796 49.4411 39.7466 37.3416 54.4141 29.4751C69.0781 21.6105 86.6029 17.9976 103.757 21.471L103.759 21.4713C120.774 24.8602 132.517 37.0759 138.701 51.4288C144.89 65.7946 145.474 82.2176 140.257 93.9202Z"
      fill="#16969C"
      stroke="#1AACB3"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="19"
      width="140"
      height="149"
    >
      <path
        d="M9.3223 133.942L9.32205 133.941C4.37681 121.296 5.85897 112.145 9.44345 103.475C10.8971 99.9594 12.6931 96.53 14.5599 92.9656C15.0071 92.1118 15.4583 91.2503 15.9098 90.3779C18.241 85.8743 20.5687 81.1042 22.3317 75.7074L22.3322 75.7056C28.0129 58.0957 39.8031 42.3644 54.4635 32.1589C69.1222 21.9546 86.6166 17.295 103.73 21.7503C120.778 26.1883 132.548 42.0667 138.739 60.816C144.926 79.5562 145.484 101.014 140.24 116.391C129.711 147.267 107.57 167.481 80.4278 167.481C73.4078 167.481 58.2128 165.81 43.4649 160.758C28.6934 155.697 14.5239 147.298 9.3223 133.942Z"
        fill="#16969C"
        stroke="#1AACB3"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M88.1426 87.6948V133.266C85.714 133.552 83.1426 133.695 80.5711 133.695C79.2854 133.695 77.8569 133.695 76.1426 133.552V87.6948H88.1426Z"
        fill="#E2E2E2"
      />
      <path
        d="M98.4272 50.2666H56.9986C46.8558 50.2666 38.7129 58.6952 38.7129 68.9809V85.5523C38.7129 86.6952 39.7129 87.6952 40.8557 87.6952H114.57C115.713 87.6952 116.713 86.6952 116.713 85.5523V68.9809C116.713 58.6952 108.427 50.2666 98.4272 50.2666Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M75.9989 87.6949V69.552C75.9989 59.552 67.5703 51.4092 57.2846 51.4092C46.9989 51.4092 38.5703 59.552 38.5703 69.552V87.6949H75.9989Z"
        fill="#16969C"
      />
      <path
        d="M57.8558 51.4092C57.2844 51.4092 56.9294 51.4092 56.1816 51.4717C65.4674 52.186 72.8558 60.1235 72.8558 69.552V87.6949H75.9986V69.552C75.9986 59.552 67.8558 51.4092 57.8558 51.4092Z"
        fill="#1DC1C9"
      />
      <path
        d="M71.4283 107.266C68.714 116.838 58.714 122.552 48.8569 119.981C38.9998 117.409 33.4283 110.552 36.1426 100.981L38.571 87.6948H75.9998L71.4283 107.266Z"
        fill="#1DC1C9"
      />
      <path
        d="M85.2854 73.6949C86.9997 73.6949 88.4283 72.2663 88.4283 70.552C88.4283 68.8378 86.9997 67.4092 85.2854 67.4092C83.5711 67.4092 82.1426 68.8378 82.1426 70.552C82.1426 72.2663 83.5711 73.6949 85.2854 73.6949Z"
        fill="#252627"
      />
      <path
        d="M86.8558 55.5522H83.7129V71.2665H86.8558V55.5522Z"
        fill="#252627"
      />
      <path
        d="M89.2843 55.5522C90.9986 55.5522 92.4272 56.9808 92.4272 58.6951C92.4272 60.4094 90.9986 61.838 89.2843 61.838H86.8558C85.1415 61.838 83.7129 60.4094 83.7129 58.6951C83.7129 56.9808 85.1415 55.5522 86.8558 55.5522H89.2843Z"
        fill="#252627"
      />
      <path
        d="M88.1248 87.6948H76.1426V89.8377H88.1248V87.6948Z"
        fill="#C4C4C4"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="96.9265"
        y1="97.892"
        x2="73.9282"
        y2="69.6679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyListIcon;
