import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LanguageSelect } from 'components/common/controls/custom-select';

import { getUILanguage, setUILanguage } from 'store/ui';

import Container from '../common/layout/template/container';
import Icon from '../common/icon';
import { LogoIcon } from '../common/icons';
import FeedbackPanel from './components/feedback-panel';

import styles from './feedback-view.module.scss';

const FeedbackView = () => {
  const dispatch = useDispatch();
  const language = useSelector(getUILanguage);

  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng.value);
    dispatch(setUILanguage(lng.value));
  };

  useEffect(() => {
    document.body.classList.add(styles.body);

    return () => {
      document.body.classList.remove(styles.body);
    };
  }, []);

  return (
    <div className={styles.root}>
      <a
        href="https://upservice.com"
        target="_blank"
        rel="noreferrer"
        className={styles.logo}
      >
        <Icon component={LogoIcon} />
      </a>

      <Container className={styles.container}>
        <FeedbackPanel />
      </Container>

      <LanguageSelect
        value={language}
        rootClassName={styles.language}
        onChange={changeLanguage}
      />
    </div>
  );
};

export default FeedbackView;
