import React from 'react';

export const ChannelIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 17" fill="none">
    <path
      d="M8.26795 13L8.78756 12.7L8.61436 12.4H8.26795V13ZM9.82679 15.7L9.30718 16L9.30718 16L9.82679 15.7ZM10.1732 15.7L10.6928 16L10.6928 16L10.1732 15.7ZM11.7321 13V12.4H11.3856L11.2124 12.7L11.7321 13ZM1.6 2C1.6 1.77909 1.77909 1.6 2 1.6V0.4C1.11634 0.4 0.4 1.11634 0.4 2H1.6ZM1.6 12V2H0.4V12H1.6ZM2 12.4C1.77909 12.4 1.6 12.2209 1.6 12H0.4C0.4 12.8837 1.11635 13.6 2 13.6V12.4ZM8.26795 12.4H2V13.6H8.26795V12.4ZM10.3464 15.4L8.78756 12.7L7.74833 13.3L9.30718 16L10.3464 15.4ZM9.65359 15.4C9.80755 15.1333 10.1924 15.1333 10.3464 15.4L9.30718 16C9.6151 16.5333 10.3849 16.5333 10.6928 16L9.65359 15.4ZM11.2124 12.7L9.65359 15.4L10.6928 16L12.2517 13.3L11.2124 12.7ZM18 12.4H11.7321V13.6H18V12.4ZM18.4 12C18.4 12.2209 18.2209 12.4 18 12.4V13.6C18.8837 13.6 19.6 12.8837 19.6 12H18.4ZM18.4 2V12H19.6V2H18.4ZM18 1.6C18.2209 1.6 18.4 1.77909 18.4 2H19.6C19.6 1.11634 18.8837 0.4 18 0.4V1.6ZM2 1.6H18V0.4H2V1.6Z"
      fill="currentColor"
    />
    <path d="M5 4.5H15" stroke="currentColor" strokeWidth="1.2" />
    <path d="M5 7H15" stroke="currentColor" strokeWidth="1.2" />
    <path d="M5 9.5H15" stroke="currentColor" strokeWidth="1.2" />
  </svg>
);

export default ChannelIcon;
