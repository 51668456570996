import { useState } from 'react';

export const parseValueStorage = value => JSON.parse(value);

export const getInitialValueStorage = (
  key,
  initialValue,
  fnPrefix = _key => _key
) => {
  const getValue = () => {
    try {
      const item = window.localStorage.getItem(fnPrefix(key));
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };

  return getValue();
};

// read https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() =>
    getInitialValueStorage(key, initialValue)
  );

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // error
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
