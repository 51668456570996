import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export const Content = ({ className, children, style, title }) => (
  <div className={classnames(className)} style={style} title={title}>
    {children}
  </div>
);

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  title: PropTypes.string
};

Content.defaultProps = {
  className: undefined,
  children: undefined,
  style: {},
  title: undefined
};

export default Content;
