export const getWorkspaceTitle = ({
  title,
  aliasTitle,
  isIndividual = false
}) => {
  if (isIndividual) {
    return title;
  }

  if (title === aliasTitle) {
    return title;
  }

  return `${title}${aliasTitle ? ` (${aliasTitle})` : ''}`;
};

export default getWorkspaceTitle;
