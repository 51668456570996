/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
import { uniq } from 'lodash';

import { INCOMING } from 'constants/index';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';

export const getMembers = contractor => {
  if (!contractor || !contractor.responsible || isEmpty(contractor.members)) {
    return undefined;
  }

  const responsibleId = contractor.responsible.id;

  return uniq(
    [...contractor.members, responsibleId].filter(member => member != null)
  );
};

const transformContractorValue = ({ contractor, kind }) => {
  if (!contractor) {
    return null;
  }

  const contractors = Array.isArray(contractor) ? contractor : [contractor];

  const transformedValues = contractors.map(c => ({
    id: c.value,
    employeeId: c.label.employee,
    workspaceId:
      c.label.employee && c.label.company.workspace
        ? c.label.company.workspace.id
        : null
  }));

  return kind === INCOMING ? transformedValues : transformedValues[0];
};

// This function described all allowed fields to create orderStatus (and transforms they)
export const transformFormSubmittedValues = ({
  values,
  isShortForm,
  t = string => string
} = {}) => {
  if (!values || isEmpty(values)) {
    return {};
  }

  const shortFormFields = {
    title: values.title,
    kind: values.kind.value || values.kind,
    content: values.content && values.content.description,
    fileList: getFileIdsV2(values.fileList),
    signatoryId: values.signatory ? values.signatory.value : null,
    contractor: transformContractorValue({
      contractor: values.contractor,
      kind: values.kind.value || values.kind
    }),
    price: values.price,
    currency: values.price ? values.currency.value : undefined,
    measurementUnit: values.price
      ? t('CostTypeOrder', { ns: 'Order' })
      : undefined,
    deadlineAt: values.deadlineAt,
    relations: values.relations,
    attachmentFileList: values.fileList || [],
    members:
      (values.contractor &&
        values.contractor.label &&
        getMembers(values.contractor.label)) ||
      (values.members && values.members) ||
      [],
    position: values.position,
    profileLink: values.profileLink
  };

  if (isShortForm) {
    return shortFormFields;
  }

  return {
    // kind - set in form wrap component
    // responsibleId - set in createOrderStatus action
    ...shortFormFields,
    location: values.location,
    locationExact: values.locationExact,
    startAt: values.startAt,
    relations: values.relations,
    tags: values.tags,
    // additional fields for submit function
    initialTags: values.initialTags,
    isFromTemplate: values.isFromTemplate,
    templateId: values.templateId
  };
};
