import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Tooltip from 'components/common/tooltip';

import useRoutesService from 'services/routes';
import getFIO, { getFullName } from 'utils/get-fio';

import Avatar from '../index';
import ReplaceTooltip from '../replace-tooltip';

const EmployeeAvatar = ({ employee, size, tooltip, isLink, ...props }) => {
  const routes = useRoutesService({ returnUrl: true });

  const {
    className,
    content,
    isHide,
    isReplaced,
    replacer,
    fromDate,
    toDate
  } = tooltip;
  const { id, avatarFile, isActive = true } = employee;

  const TooltipTitle = () => (
    <div className={className}>
      {getFullName(employee)}
      {content}
    </div>
  );

  const ReplaceTooltipTitle = () => (
    <ReplaceTooltip replacer={replacer} content={{ fromDate, toDate }} />
  );

  const tooltipTitle = isReplaced
    ? ReplaceTooltipTitle
    : !isHide && TooltipTitle;

  return (
    <Tooltip title={tooltipTitle} placement={isReplaced ? 'topLeft' : undefined}>
      {isLink && isActive ? (
        <Link to={routes.toEmployee(id)}>
          <Avatar src={(avatarFile || {}).url} size={size} {...props}>
            {getFIO(employee)}
          </Avatar>
        </Link>
      ) : (
        <Avatar
          src={(avatarFile || {}).url}
          size={size}
          isActive={isActive}
          {...props}
        >
          {getFIO(employee)}
        </Avatar>
      )}
    </Tooltip>
  );
};

EmployeeAvatar.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number,
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isActive: PropTypes.bool
  }),
  size: PropTypes.number,
  tooltip: PropTypes.shape({
    className: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func
    ]),
    isHide: PropTypes.bool,
    isReplaced: PropTypes.bool,
    replacer: PropTypes.object
  }),
  isLink: PropTypes.bool
};

EmployeeAvatar.defaultProps = {
  employee: {},
  size: 24,
  tooltip: {},
  isLink: true
};

export default EmployeeAvatar;
