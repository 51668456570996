import React from 'react';
import { Typography } from 'antd';

import styles from './attachment.module.scss';

export const AttachmentLink = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.link}
  >
    <Typography.Paragraph
      ellipsis={{ rows: 1 }}
      title={url}
      className={styles.href}
    >
      {url}
    </Typography.Paragraph>
  </a>
);

export default AttachmentLink;
