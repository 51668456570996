import { useDispatch, useSelector } from 'react-redux';

import { TYPE_FILE } from 'constants/index';

import { changeManageBulkSubscribers } from 'store/subscriptions';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';
import { getUserEmployee } from 'store/workspace';

export const useManageSubscribers = () => {
  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);

  const manageBulkSubscribers = async subscribersForAttachments => {
    const transformData = subscribersForAttachments
      .filter(attachment => attachment.allowSubscriberSelection)
      .filter(
        attachment =>
          attachment.subscribers && attachment.subscribers.length > 0
      )
      .filter(attachment =>
        getIsEditableFile({
          mimeType: attachment.mimeType,
          type: attachment.type
        })
      )
      .map(attachment => ({
        entityId: attachment.fileId || (attachment.response || {}).id,
        entityType: TYPE_FILE,
        added: attachment.subscribers.map(s => s.value),
        withNewAttachment: true
      }));

    if (transformData.length > 0) {
      await dispatch(changeManageBulkSubscribers({ data: transformData }));
    }
  };

  const subscribeToNewAttachments = fileList => {
    const transformData = fileList
      .filter(file => file.isNewAttachmentSubscribed)
      .map(attachment => ({
        entityId: (attachment.response || {}).id,
        entityType: TYPE_FILE,
        added: [employee.id],
        withNewAttachment: true
      }));

    if (transformData.length > 0) {
      dispatch(changeManageBulkSubscribers({ data: transformData }));
    }
  };

  return { manageBulkSubscribers, subscribeToNewAttachments };
};

export default useManageSubscribers;
