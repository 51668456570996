import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import FormItem from '../form-item';

export const FormInput = forwardRef(
  (
    {
      rules,
      name,
      label,
      itemProps,
      onChange: customOnChange,
      transformValue,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const { control, formState } = useFormContext();

    return (
      <FormItem
        label={label}
        name={name}
        {...itemProps}
        errors={formState.errors}
      >
        <Controller
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => {
            const { onChange, ...renderProps } = field;

            return (
              <Input
                autoComplete="off"
                onChange={e => {
                  onChange(transformValue ? transformValue(e) : e);
                  customOnChange(e);
                }}
                {...renderProps}
                {...props}
                ref={ref}
              />
            );
          }}
        />
      </FormItem>
    );
  }
);

FormInput.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  onChange: PropTypes.func
};

FormInput.defaultProps = {
  rules: {},
  itemProps: {},
  onChange: () => {}
};

export default FormInput;
