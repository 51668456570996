export default api => ({
  fetch: ({ parent, limit, offset, search, withContent }) =>
    api.get(`/v1/guides/`, {
      params: {
        parent,
        limit,
        offset,
        search,
        with_content: withContent
      }
    }),

  fetchById: id => api.get(`/v1/guides/${id}/`)
});
