import { Skeleton } from 'antd';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './button.module.scss';

export const SkeletonButton = ({ className }) => (
  <Skeleton
    active
    title={false}
    paragraph={{
      rows: 1,
      width: '100%',
      className: classnames(styles.root, className)
    }}
  />
);

SkeletonButton.propTypes = {
  className: PropTypes.string
};

SkeletonButton.defaultProps = {
  className: undefined
};

export default SkeletonButton;
