export const CHOOSE_TARIFF_STEP = 'choose_tariff_step';
export const CHOOSE_ADDONS_STEP = 'choose_addons_step';
export const CHECKOUT_PAYMENT_STEP = 'checkout_payment_step';

export const STEPS = [
  {
    key: CHOOSE_TARIFF_STEP,
    label: 'ChoosePlanStep',
    ns: 'ChangePlan'
  },
  {
    key: CHOOSE_ADDONS_STEP,
    label: 'ConnectAddonsStep',
    ns: 'ChangePlan'
  },
  {
    key: CHECKOUT_PAYMENT_STEP,
    label: 'PaymentStep',
    ns: 'ChangePlan'
  }
];
