import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WEBSITE_FORM_STEP } from 'constants/index';

import { useTheme } from 'components/common/theme';
import WebsiteFormChannelForm from 'components/contacts-view/views/integrations/forms/website-form-channel';
import { transformSlaConfigValues } from 'components/contacts-view/views/integrations/components/sla-settings/utils';
import { getLocalizedText } from 'components/contacts-view/views/integrations/forms/website-form-channel/utils';

import { getUILanguage } from 'store/ui';

import { getChannelLanguage } from '../utils';

const WebsiteFormStep = ({
  currentStep,
  creator,
  isLoading,
  onSubmit,
  setValues
}) => {
  const { variables } = useTheme();

  const language = useSelector(getUILanguage);

  const { t } = useTranslation('ConnectForm');

  const channelLanguage = getChannelLanguage(language);

  const defaultValues = {
    message: '',
    language: channelLanguage,
    title: t('FormTitlePlchldr'),
    description: '',
    notify: true,
    responsible: creator,
    operators: [creator],
    isLogo: true,
    buttonText: t('SendBtnPlchldr'),
    color: variables.color['brand-90'],
    buttonColor: variables.color.brand,
    pageColor: variables.color.white,
    pageBackground: {},
    pageLogo: {},
    formElements: [
      {
        name: 'phone',
        placeholder: getLocalizedText('phone', channelLanguage.value),
        required: true
      },
      {
        name: 'email',
        placeholder: getLocalizedText('email', channelLanguage.value),
        required: true
      },
      {
        name: 'message',
        placeholder: getLocalizedText('message', channelLanguage.value),
        required: true
      },
      {
        name: 'file',
        placeholder: getLocalizedText('file', channelLanguage.value),
        required: false
      }
    ],
    slaConfig: transformSlaConfigValues()
  };

  const steps = {
    [WEBSITE_FORM_STEP]: (
      <>
        <WebsiteFormChannelForm
          defaultValues={defaultValues}
          isLoading={isLoading}
          onSubmit={onSubmit}
          setValues={setValues}
        />
      </>
    )
  };

  return <>{steps[currentStep]}</>;
};

WebsiteFormStep.propTypes = {
  currentStep: PropTypes.oneOf([WEBSITE_FORM_STEP]),
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired
};

WebsiteFormStep.defaultProps = {
  currentStep: WEBSITE_FORM_STEP
};

export default WebsiteFormStep;
