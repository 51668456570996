import React from 'react';

export const ControllerIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#636992" />
    <g clipPath="url(#clip0)">
      <path
        d="M5.70117 11.0898C6.27789 12.1311 7.80996 12.3974 8.99962 12.3974C10.1901 12.3974 11.7215 12.1308 12.2981 11.0898C9.57926 10.7035 8.42119 10.7033 5.70117 11.0898Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9998 4.8454C9.90203 4.8454 11.6037 5.96383 13.5522 7.83674C13.7075 7.9863 13.7226 8.21461 13.5891 8.37981L12.5683 9.64561C10.0439 9.21432 7.91514 9.22111 5.43126 9.64561L4.41046 8.37981C4.27686 8.21408 4.29246 7.98577 4.44735 7.83674C6.3957 5.96383 8.09757 4.8454 8.9998 4.8454ZM8.2 6.3493C8.08954 6.3493 8 6.43885 8 6.5493V7.89438C8 7.96056 8.03273 8.02245 8.08743 8.0597L8.88743 8.60444C8.95535 8.65069 9.04465 8.65069 9.11257 8.60444L9.91257 8.0597C9.96727 8.02245 10 7.96056 10 7.89438V6.5493C10 6.43885 9.91046 6.3493 9.8 6.3493H8.2Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x="4.31982"
          y="4.32001"
          width="9.36002"
          height="8.64"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ControllerIcon;
