import React from 'react';

export const CommentIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5609 2.28572C21.5609 1.10715 20.6074 0.14286 19.4288 0.14286H2.28592C1.10735 0.14286 0.143066 1.10715 0.143066 2.28572V15.1429C0.143066 16.3214 1.10735 17.2857 2.28592 17.2857H17.2859L21.5716 21.5714L21.5609 2.28572ZM16.2145 13H5.50021C4.91092 13 4.42878 12.5179 4.42878 11.9286C4.42878 11.3393 4.91092 10.8571 5.50021 10.8571H16.2145C16.8038 10.8571 17.2859 11.3393 17.2859 11.9286C17.2859 12.5179 16.8038 13 16.2145 13ZM16.2145 9.78572H5.50021C4.91092 9.78572 4.42878 9.30357 4.42878 8.71429C4.42878 8.125 4.91092 7.64286 5.50021 7.64286H16.2145C16.8038 7.64286 17.2859 8.125 17.2859 8.71429C17.2859 9.30357 16.8038 9.78572 16.2145 9.78572ZM16.2145 6.57143H5.50021C4.91092 6.57143 4.42878 6.08929 4.42878 5.5C4.42878 4.91072 4.91092 4.42857 5.50021 4.42857H16.2145C16.8038 4.42857 17.2859 4.91072 17.2859 5.5C17.2859 6.08929 16.8038 6.57143 16.2145 6.57143Z"
      fill="white"
    />
  </svg>
);

export default CommentIcon;
