import axios from 'axios';

import { config } from 'config';

export const API_REDASH =
  config.REACT_APP_REDASH_API_URL || 'https://redash.buselsoft.com';

const api = axios.create({
  baseURL: API_REDASH,
  headers: {
    Authorization: `Key ${config.REACT_APP_REDASH_AUTHORIZATION_KEY ||
      'OJKrmDRJa0sSkAFeZeHNUnehsjmO5zj5t2h2tSSz'}`
  }
});

export default () => ({
  create: ({ queryId, workspaceId, params }) =>
    api.post(`/api/queries/${queryId}/results`, {
      parameters: {
        workspace_id: workspaceId,
        ...params
      },
      max_age: 0
    }),

  check: ({ jobId }) => api.get(`/api/jobs/${jobId}`),

  fetch: ({ queryResultId }) => api.get(`/api/query_results/${queryResultId}`),

  fetchQuery: ({ queryId }) => api.get(`/api/queries/${queryId}`),

  fetchDashboard: ({ dashboardId }) =>
    api.get(`/api/dashboards/${dashboardId}`),

  export: ({ queryResultId, format }) =>
    api.get(`/api/query_results/${queryResultId}.${format}`, {
      responseType: 'arraybuffer'
    })
});
