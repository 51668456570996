import React from 'react';
import { Skeleton } from 'antd';
import classnames from 'classnames';

import { SkeletonButton } from 'components/common/skeletons';

import styles from './record-details.module.scss';

const RecordDetailsSkeleton = ({ className }) => (
  <div className={classnames(styles.skeleton, className)}>
    <Skeleton active title={{ className: styles.title }} paragraph={false} />

    <Skeleton
      active
      className={styles.bodyWrap}
      title={{ className: styles.body }}
      paragraph={false}
    />

    <div className={styles.buttonsWrap}>
      <SkeletonButton className={styles.button} />

      <SkeletonButton className={styles.button} />
    </div>
  </div>
);

export default RecordDetailsSkeleton;
