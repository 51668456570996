import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';

import { getUserEmployee } from 'store/workspace';

import styles from './typing.module.scss';

const ControlsTyping = ({ typingList }) => {
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation('Common');

  const targetIds = typingList.reduce(
    (acc, curr) => [...acc, ...curr.targetIds],
    []
  );
  const senderFullNames = typingList.map(typing => typing.sender.fullName);

  const visible =
    !!typingList.length &&
    (!targetIds.length || targetIds.includes(employee.userId));

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wave}>
        <span className={styles.dot} />

        <span className={styles.dot} />

        <span className={styles.dot} />
      </div>

      <Typography.Text color="black-45">
        {`${senderFullNames.join(', ')} ${t('Typing')}`}
      </Typography.Text>
    </div>
  );
};

ControlsTyping.propTypes = {
  typingList: PropTypes.arrayOf(
    PropTypes.shape({
      sender: PropTypes.shape({
        fullname: PropTypes.string
      }),
      targetIds: PropTypes.arrayOf(PropTypes.number)
    })
  )
};

ControlsTyping.defaultProps = {
  typingList: []
};

export default ControlsTyping;
