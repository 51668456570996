import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import { CopyLink as CopyContent } from 'components/common/copy-link';

import { getUserEmployee } from 'store/workspace';

import { useScrollBottom } from 'hooks';

import GptPrompts from '../gpt-prompts';

import styles from './gpt-message-thread.module.scss';

const { Paragraph, Text } = Typography;

const GptMessageThread = ({ entityInfo, sendPrompt, messages, prompts }) => {
  const { t } = useTranslation('AIAssistant');

  const employee = useSelector(getUserEmployee);

  const [isTyping, setIsTyping] = useState(false);

  const [scrollRef, , scrollToBottom] = useScrollBottom({
    dependencies: [messages.length, isTyping]
  });

  const formatTime = createdAt => {
    const now = moment();
    const messageTime = moment(createdAt);
    const isToday = now.isSame(messageTime, 'day');

    if (isToday) {
      return messageTime.format(TIME_FORMAT);
    }

    return messageTime.format(DATE_FORMAT);
  };

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].isUser) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }

    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isTyping]);

  return (
    <div ref={scrollRef} className={styles.root}>
      {!messages.length && (
        <GptPrompts
          sendPrompt={sendPrompt}
          entityInfo={entityInfo}
          prompts={prompts}
        />
      )}

      {messages.map(({ text, isUser, createdAt }, index) => (
        <>
          {isUser && (
            <div className={styles.meMessageWrap}>
              <CopyContent link={text} tooltipClassName={styles.copyTooltip} />

              <div className={styles.meMessage}>
                <Paragraph className={styles.username} weight="semibold">
                  {employee.lastName} {employee.firstName}
                </Paragraph>

                <Text>{text}</Text>

                <Paragraph
                  className={styles.time}
                  size="small"
                  color="black-55"
                >
                  {formatTime(createdAt)}
                </Paragraph>
              </div>
            </div>
          )}

          {!isUser && (
            <div className={styles.gptMessageWrap}>
              <div className={styles.pinkCircle}>
                <Icon type="gpt-widget" color="black" size={16} />
              </div>

              <div className={styles.gptMessage}>
                <Paragraph className={styles.username} weight="semibold">
                  {t('AIAsssistHeading')}
                </Paragraph>

                <Text>
                  {text.split('\n').map((line, i) => (
                    <>
                      <Text key={i}>{line}</Text>

                      <br />
                    </>
                  ))}
                </Text>

                <Paragraph
                  className={styles.time}
                  size="small"
                  color="black-55"
                >
                  {formatTime(createdAt)}
                </Paragraph>
              </div>

              <CopyContent
                tooltipClassName={styles.copyTooltip}
                className={styles.copyContent}
                link={text}
              />
            </div>
          )}

          {isTyping && index === messages.length - 1 && (
            <div className={styles.gptTyping}>
              <Text className={styles.dots}>...</Text>

              <Text color="black-45">{t('AIAssistantTyping')}</Text>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

GptMessageThread.propTypes = {
  entityInfo: PropTypes.object.isRequired,
  sendPrompt: PropTypes.func.isRequired,
  messages: PropTypes.array,
  prompts: PropTypes.array
};

GptMessageThread.defaultProps = {
  messages: [],
  prompts: []
};

export default GptMessageThread;
