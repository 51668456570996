import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWorkspaceId } from 'store/workspace';
import {
  fetchAttachments,
  getCurrentPage,
  getFilterAttachments
} from 'store/attachments';

export const useDataProvider = ({ isTrash, ...params }) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getWorkspaceId);
  const filter = useSelector(getFilterAttachments);
  const currentPage = useSelector(getCurrentPage);

  const fetchData = () =>
    dispatch(fetchAttachments({ ...params, isTrash, ignoreCache: true }));

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [workspaceId, currentPage, filter]);

  return null;
};

export default useDataProvider;
