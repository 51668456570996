import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import TypeForm from 'components/common/tags/type-form';
import { TAG_TYPE_NUMBER } from 'components/common/tags/utils/tag-types';

import { createTag } from 'store/tags';

import styles from './create-tag-modal.module.scss';

const CreateTagModal = ({
  visible,
  onClose,
  searchValue,
  type,
  createCallback
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['TagsEditReplaceDelete', 'TagsInTask']);

  const onSubmit = data => dispatch(createTag(data));

  const warningText =
    type === TAG_TYPE_NUMBER
      ? t('TagWarningNumberTag', { ns: 'TagsInTask' })
      : '';

  return (
    <Modal
      width={282}
      title={t('CreateTag', { ns: 'TagsEditReplaceDelete' })}
      destroyOnClose
      visible={visible}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <TypeForm
        searchValue={searchValue}
        type={type}
        handleClose={onClose}
        isOpenModal={visible}
        createCallback={createCallback}
        onSubmit={onSubmit}
        warningMessage={warningText}
      />
    </Modal>
  );
};

CreateTagModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  searchValue: PropTypes.string,
  type: PropTypes.string,
  createCallback: PropTypes.func
};

CreateTagModal.defaultProps = {
  visible: false,
  onClose: () => {},
  searchValue: '',
  type: '',
  createCallback: () => {}
};

export default CreateTagModal;
