import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getFullName } from 'utils/get-fio';

import styles from './attachment-version.module.scss';

export const CollapseUsers = ({ users, count }) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation('Common');

  if (!users.length) {
    return null;
  }

  const filterByCount = (_, i) => (visible ? i < users.length : i < count);

  const unvisiblCount = users.filter((_, i) => i >= count).length;
  const results = users.filter(filterByCount).reduce((acc, curr) => {
    if (!acc) return getFullName(curr);

    return [acc, getFullName(curr)].join(', ');
  }, undefined);

  return (
    <span style={{ marginRight: 6 }}>
      {results}

      {unvisiblCount > 0 && (
        <Button
          type="link"
          onClick={() => setVisible(!visible)}
          className={styles.btn}
        >
          {visible ? t('Hide') : `+${unvisiblCount}`}
        </Button>
      )}

      <Divider type="vertical" />
    </span>
  );
};

CollapseUsers.propTypes = {
  users: PropTypes.array,
  count: PropTypes.number
};

CollapseUsers.defaultProps = {
  users: [],
  count: 3
};

export default CollapseUsers;
