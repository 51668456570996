import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import styles from './message.module.scss';

const { Text, Paragraph } = Typography;

const CopyEmailInfo = ({ recipients }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('Contacts');

  if (!(recipients || []).length) {
    return null;
  }

  return (
    <div className={styles.recipients}>
      <div className={styles.recipientsHeader}>
        <Paragraph
          color="black-55"
          size="small"
          className={styles.recipientsTitle}
        >
          {t('CC')}:{' '}
          <Text color="black" size="small">
            {recipients[0].fullName} ({recipients[0].email})
          </Text>
        </Paragraph>

        {recipients.length > 1 && (
          <Button
            type="text"
            className={styles.recipientsBtn}
            onClick={() => setOpen(prev => !prev)}
          >
            <Icon type="arrow" size={16} side={open ? 'default' : 'left'} />
          </Button>
        )}
      </div>

      {open &&
        recipients.slice(1).map(({ fullName, email }) => (
          <Paragraph className={styles.recipientsList} size="small">
            {`${fullName} (${email})`}
          </Paragraph>
        ))}
    </div>
  );
};

CopyEmailInfo.propTypes = {
  recipients: PropTypes.array
};

CopyEmailInfo.defaultProps = {
  recipients: []
};

export default CopyEmailInfo;
