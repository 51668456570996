import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EMAIL, WHATSAPP } from 'constants/index';

import { EmailChannelIcon, WhatsAppChannelIcon } from 'components/common/icons';
import CustomSelect from 'components/common/controls/custom-select';

import { getEmailAndWhatsAppChannels } from 'store/channels';

import Option from './option';
import MessageSendModal from './modal';
import {
  filterOptionsByKind,
  isMultipleChatsOfType,
  isMultipleKeysWithPrefix
} from './utils';

import styles from './to-chat-select.module.scss';

const ToChatSelect = ({
  value,
  onChange,
  options,
  isDisabled,
  employeeId,
  contact
}) => {
  const { t } = useTranslation('Contacts');

  const { emailChannels, whatsAppChannels } = useSelector(
    getEmailAndWhatsAppChannels
  );

  const [visible, setVisible] = useState(undefined);

  const contactKeys = Object.keys(contact);

  const filteredEmailOptions = filterOptionsByKind(options, EMAIL);
  const filteredWhatsAppOptions = filterOptionsByKind(options, WHATSAPP);

  const isMultipleEmailChats = isMultipleChatsOfType(filteredEmailOptions);
  const isMultipleWhatsAppChats = isMultipleChatsOfType(
    filteredWhatsAppOptions
  );

  const isMultipleContactEmails = isMultipleKeysWithPrefix(
    contact,
    contactKeys,
    'email'
  );
  const isMultipleContactPhones = isMultipleKeysWithPrefix(
    contact,
    contactKeys,
    'phone'
  );

  const allOptions = useMemo(() => {
    const customOptions = [];

    if (emailChannels.some(({ isActive }) => isActive)) {
      customOptions.push({
        label: {
          text: t('EmailChannel'),
          iconComponent: EmailChannelIcon,
          index: 4,
          channelKind: EMAIL,
          channelUuid:
            !isMultipleEmailChats && filteredEmailOptions.length
              ? filteredEmailOptions[0].label.channelUuid
              : undefined
        },
        value:
          !isMultipleEmailChats && filteredEmailOptions.length
            ? filteredEmailOptions[0].value
            : EMAIL
      });
    }

    if (whatsAppChannels.some(({ isActive }) => isActive)) {
      customOptions.push({
        label: {
          text: t('WhatsAppChannel'),
          iconComponent: WhatsAppChannelIcon,
          index: 5,
          channelKind: WHATSAPP,
          channelUuid: !isMultipleWhatsAppChats
            ? filteredWhatsAppOptions.length &&
              filteredWhatsAppOptions[0].label.channelUuid
            : undefined
        },
        value:
          !isMultipleWhatsAppChats && filteredWhatsAppOptions.length
            ? filteredWhatsAppOptions[0].value
            : WHATSAPP
      });
    }

    const filteredAllOptions = options.filter(
      ({ label }) =>
        label.channelKind !== WHATSAPP && label.channelKind !== EMAIL
    );

    return customOptions.length
      ? [...customOptions, ...filteredAllOptions]
      : options;
  }, [
    emailChannels,
    filteredEmailOptions,
    filteredWhatsAppOptions,
    isMultipleEmailChats,
    isMultipleWhatsAppChats,
    options,
    t,
    whatsAppChannels
  ]);

  const onCloseModal = () => setVisible(undefined);

  return (
    <>
      <CustomSelect
        value={value}
        onChange={selectedOption => {
          if (
            (isMultipleContactEmails ||
              isMultipleEmailChats ||
              emailChannels.length > 1 ||
              !filteredEmailOptions.length) &&
            selectedOption.label.channelKind === EMAIL
          ) {
            setVisible(selectedOption);
            return;
          }

          if (
            (isMultipleContactPhones ||
              isMultipleWhatsAppChats ||
              whatsAppChannels.length > 1 ||
              !filteredWhatsAppOptions.length) &&
            selectedOption.label.channelKind === WHATSAPP
          ) {
            setVisible(selectedOption);
            return;
          }

          onChange(selectedOption);
        }}
        options={allOptions}
        isDisabled={isDisabled}
        Option={Option}
        className={styles.toChatSelect}
        popoverOverlayClassName={styles.toChatPopover}
      />

      <MessageSendModal
        contact={contact}
        employeeId={employeeId}
        visible={visible}
        onChange={onChange}
        channels={
          visible && visible.label.channelKind === EMAIL
            ? emailChannels
            : whatsAppChannels
        }
        onClose={onCloseModal}
      />
    </>
  );
};

ToChatSelect.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.shape({
      iconType: PropTypes.string,
      iconComponent: PropTypes.func,
      text: PropTypes.string
    }),
    value: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        iconType: PropTypes.string,
        iconComponent: PropTypes.func,
        text: PropTypes.string
      }),
      value: PropTypes.string
    })
  ).isRequired,
  isDisabled: PropTypes.bool,
  employeeId: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired
};

ToChatSelect.defaultProps = {
  isDisabled: false
};

export default ToChatSelect;
