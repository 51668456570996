import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { config } from 'config';

const { REACT_APP_SOCKET_API_DIALOGUES_URL } = config;
const { REACT_APP_API_PROTOCOL } = process.env;

const WIDGET_SERVER_URL = `${REACT_APP_API_PROTOCOL}://${REACT_APP_SOCKET_API_DIALOGUES_URL}`;
const WIDGET_SCRIPT_SRC = `${WIDGET_SERVER_URL}/static/bundle.js`;

const getIconUrl = id => `${WIDGET_SERVER_URL}/static/icons/${id}.svg`;

const getMessages = t => [
  {
    text: [{ text: t('ExampleMessageHello') }],
    isFrom: 'widget',
    createdAt: new Date().toISOString()
  },
  {
    text: [{ text: t('ExampleMessageQuestion') }],
    isFrom: 'widget',
    createdAt: new Date().toISOString()
  },
  {
    text: [{ text: t('ExampleMessageAnswer') }],
    isFrom: 'upservice',
    createdAt: new Date().toISOString()
  }
];

export const WidgetPreview = ({
  color,
  right,
  left,
  bottom,
  top,
  icon,
  message,
  title,
  avatar,
  isInvite,
  inviteDelay,
  isOpen,
  isExpanded,
  expand,
  channels,
  isCallbackOn,
  language
}) => {
  const { t } = useTranslation('ConnectWidget');

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (
      !isFirstRender.current &&
      typeof window._upservice.setState === 'function'
    ) {
      // TODO: вынести в функцию
      window._upservice.setState(state => ({
        chatIcon: getIconUrl(icon),
        firstMessage: message,
        messages: { ...state.messages, items: getMessages(t) },
        color,
        isOpen,
        autoInvite: {
          isInvite,
          inviteDelay
        },
        operator: {
          isDefault: true,
          name: title,
          avatar: getIconUrl(avatar)
        },
        hostStyle: {
          position: 'fixed',
          right,
          bottom,
          left,
          top,
          expand
        },
        isExpanded,
        channels,
        isCallbackOn,
        language
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    avatar,
    icon,
    message,
    title,
    right,
    left,
    bottom,
    color,
    isOpen,
    isInvite,
    inviteDelay,
    isExpanded,
    expand,
    channels,
    isCallbackOn,
    language
  ]);

  useEffect(() => {
    window._upservice = window._upservice || {};
    window._upservice.previewMode = true;
    window._upservice.onInit = () => {
      window._upservice.setState(state => ({
        previewMode: true,
        isStartupCompleted: true,
        isOffline: false,
        isOpen,
        chatIcon: getIconUrl(avatar),
        color,
        hostStyle: {
          position: 'fixed',
          right,
          bottom,
          left,
          top
        },
        firstMessage: message,
        messages: {
          ...state.messages,
          items: getMessages(t)
        },
        operator: {
          isDefault: true,
          name: title,
          avatar: getIconUrl(avatar)
        },
        isCallbackOn,
        language
      }));
    };

    const script = document.createElement('script');
    script.src = WIDGET_SCRIPT_SRC;
    document.head.appendChild(script);

    isFirstRender.current = false;
    // eslint-disable-next-line
  }, []);

  return <div data-widget-host="upservice-widget" />;
};

WidgetPreview.propTypes = {
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  avatar: PropTypes.string,
  isInvite: PropTypes.bool,
  inviteDelay: PropTypes.number,
  isOpen: PropTypes.bool,
  isCallbackOn: PropTypes.bool
};

WidgetPreview.defaultProps = {
  right: undefined,
  left: undefined,
  bottom: 52,
  top: undefined,
  icon: 'chat',
  message: '',
  title: 'Upservice',
  avatar: 'chat',
  isInvite: true,
  inviteDelay: 15,
  isOpen: false,
  isCallbackOn: true
};

export default WidgetPreview;
