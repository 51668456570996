import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CHANNELS_EDITOR_DRAWER,
  TELEGRAM,
  WEBSITE,
  EMAIL,
  WEBSITE_FORM,
  WHATSAPP
} from 'constants/index';

import Drawer from 'components/common/drawer';

import {
  operatorsChannel,
  editChannel,
  fetchOperatorsChannel,
  getChannelFactory,
  notifyChannel,
  fetchChannel,
  getIsChannelsLoading
} from 'store/channels';
import { getDrawer } from 'store/drawers';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import { useUpserviceWidgetContext } from 'providers';

import TelegramChannelForm from '../../forms/telegram-channel';
import WebsiteChannelForm from '../../forms/website-channel';
import { transformDefaultValues } from './utils';
import EmailChannelForm from '../../forms/email-channel';
import WebsiteFormChannelForm from '../../forms/website-form-channel';
import WebsiteFormPreview from '../creator/steps/website-form/preview';
import WhatsAppChannelForm from '../../forms/whatsapp-channel';
import WhatsAppAuthModal from '../../modals/whatsapp-auth';

export const ChannelsEditorDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [websiteFormValues, setWebsiteFormValues] = useState({});
  const [visibleWhatsAppAuthModal, setVisibleWhatsAppAuthModal] = useState(
    undefined
  );

  const drawerData =
    useSelector(state =>
      getDrawer(state)({ drawer: CHANNELS_EDITOR_DRAWER })
    ) || {};

  const channel =
    useSelector(state => getChannelFactory(state)(drawerData.id)) || {};
  const isChannelsLoading = useSelector(getIsChannelsLoading);

  const { removeWidget, recreateWidget } = useUpserviceWidgetContext();

  const { t } = useTranslation('ConnectEmail');

  const defaultValues = useMemo(() => transformDefaultValues(channel), [
    channel
  ]);

  const canEdit = (channel.permissions || {}).canUpdateChannel;

  const handleClose = useCallback(() => {
    recreateWidget();

    onClose();
  }, [onClose, recreateWidget]);

  const fetch = async () => {
    if (isEmpty(channel)) {
      await dispatch(fetchChannel({ id: drawerData.id }));
    }

    if (isNil(channel.operators)) {
      fetchOperators();
    }
  };

  const changeOperators = useCallback(
    (id, operators) => dispatch(operatorsChannel({ id, operators })),
    [dispatch]
  );

  const fetchOperators = useCallback(
    () => dispatch(fetchOperatorsChannel({ id: drawerData.id })),
    [dispatch, drawerData.id]
  );

  const onEditChannel = useCallback(
    async ({ operators, notify, sources, ...values }) => {
      await changeOperators(channel.id, operators);
      await dispatch(notifyChannel({ id: channel.id, value: !!notify }));

      await dispatch(
        editChannel({
          channel: {
            id: channel.id,
            sources: (sources || []).length
              ? sources.map(source => source.value)
              : [],
            ...values
          }
        })
      );
    },
    [changeOperators, channel.id, dispatch]
  );

  const onSubmit = useCallback(
    async values => {
      try {
        setIsLoading(true);

        if (channel.kind === WEBSITE_FORM) {
          await onEditChannel({
            ...values,
            ...websiteFormValues
          });
        } else {
          await onEditChannel(values);
        }

        showNoticeMessage({ number: NOTICE_NUMBER.accessSettingsSaved });
        handleClose();
      } finally {
        setIsLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, onEditChannel, websiteFormValues]
  );

  const form = {
    [TELEGRAM]: (
      <TelegramChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        isEditor
        isOnlyView={!canEdit}
        onSubmit={onSubmit}
      />
    ),
    [WEBSITE]: (
      <WebsiteChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        isEditor
        isOnlyView={!canEdit}
        onSubmit={onSubmit}
      />
    ),
    [EMAIL]: (
      <EmailChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        isEditor
        isOnlyView={!canEdit}
        onSubmit={onSubmit}
      />
    ),
    [WEBSITE_FORM]: (
      <WebsiteFormChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        isEditor
        isOnlyView={!canEdit}
        onSubmit={onSubmit}
        setValues={setWebsiteFormValues}
      />
    ),
    [WHATSAPP]: (
      <WhatsAppChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        isEditor
        isOnlyView={!canEdit}
        onSubmit={onSubmit}
        setVisibleWhatsAppAuthModal={setVisibleWhatsAppAuthModal}
      />
    )
  };

  useEffect(() => {
    if (visible) {
      removeWidget();
    }

    if (visible && !isEmpty(drawerData) && !isChannelsLoading) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, drawerData, isChannelsLoading]);

  return (
    <>
      <Drawer
        width={620}
        visible={visible}
        onClose={handleClose}
        title={<Drawer.Title>{t('ChannelSettingsHeading')}</Drawer.Title>}
        destroyOnClose
        maskClosable={false}
        mask={channel.kind !== WEBSITE_FORM}
        bodyStyle={{ padding: '24px 24px 0' }}
      >
        {isEmpty(channel) || isNil(channel.operators) ? (
          <Spin />
        ) : (
          <> {form[drawerData.kind || channel.kind]}</>
        )}
      </Drawer>

      {channel.kind === WEBSITE_FORM && !isNil(channel.operators) && (
        <WebsiteFormPreview values={websiteFormValues} />
      )}

      <WhatsAppAuthModal
        visible={visibleWhatsAppAuthModal}
        onClose={() => setVisibleWhatsAppAuthModal(false)}
      />
    </>
  );
};

ChannelsEditorDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

ChannelsEditorDrawer.defaultProps = {
  visible: false
};

export default ChannelsEditorDrawer;
