import React from 'react';
import PropTypes from 'prop-types';

import Option from '../../custom-select/custom-select/option';

import styles from './option.module.scss';

export const VatNumberOption = ({ option, isInInput, ...props }) => (
  <Option
    className={styles.root}
    title={option.title}
    ellipsis={isInInput ? { rows: 1 } : false}
    {...props}
  >
    {option.title}
  </Option>
);

export default VatNumberOption;

VatNumberOption.propTypes = {
  option: PropTypes.shape({
    title: PropTypes.string
  }).isRequired,
  isInInput: PropTypes.bool
};

VatNumberOption.defaultProps = {
  isInInput: false
};
