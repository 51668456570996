import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';

import styles from './controls.module.scss';

const ControlsSubject = ({
  value,
  onChange,
  className,
  onOpenCopy,
  isCopy,
  isDisabled
}) => {
  const { t } = useTranslation('Contacts');

  const suffix = useMemo(
    () => (
      <Button
        type="text"
        onClick={onOpenCopy}
        className={classnames(styles.btnCopy, {
          [styles.btnCopyActive]: isCopy
        })}
      >
        {t('CC')}
      </Button>
    ),
    [isCopy, onOpenCopy, t]
  );

  return (
    <Input
      disabled={isDisabled}
      value={value}
      suffix={suffix}
      className={classnames(styles.subject, className)}
      placeholder={t('EmailTheme')}
      onChange={e => onChange(e.target.value)}
    />
  );
};

ControlsSubject.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  onOpenCopy: PropTypes.func.isRequired,
  isCopy: PropTypes.bool,
  isDisabled: PropTypes.bool
};

ControlsSubject.defaultProps = {
  value: '',
  className: undefined,
  isCopy: false,
  isDisabled: false
};

export default ControlsSubject;
