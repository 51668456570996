export const OPEN_FILE = 'openFile';

export const getLocationSearchWithoutOpenFile = search =>
  search.replace(
    /[?&]openFile=([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/,
    ''
  );

export const getFileLocation = (location, fileId) => ({
  ...location,
  search: getLocationSearchWithoutOpenFile(location.search)
    ? `${getLocationSearchWithoutOpenFile(
        location.search
      )}&${OPEN_FILE}=${fileId}`
    : `?${OPEN_FILE}=${fileId}`
});
