import React from 'react';

export const ProfileLinkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4541 14.8377C16.1023 14.4895 16.8443 14.2913 17.6345 14.2913H17.6372C17.7175 14.2913 17.755 14.1949 17.6961 14.1413C16.8743 13.4038 15.9356 12.8082 14.9184 12.3788C14.9077 12.3734 14.897 12.3708 14.8863 12.3654C16.5497 11.1574 17.6318 9.19398 17.6318 6.9788C17.6318 3.30915 14.664 0.335938 11.0023 0.335938C7.34074 0.335938 4.37556 3.30915 4.37556 6.9788C4.37556 9.19398 5.4577 11.1574 7.12377 12.3654C7.11306 12.3708 7.10234 12.3734 7.09163 12.3788C5.89431 12.885 4.8202 13.6109 3.89609 14.5377C2.97732 15.4548 2.24586 16.542 1.74252 17.7386C1.24729 18.9104 0.980018 20.1658 0.955023 21.4377C0.954308 21.4663 0.959322 21.4948 0.969771 21.5214C0.980219 21.548 0.99589 21.5723 1.01586 21.5927C1.03583 21.6132 1.05969 21.6295 1.08605 21.6406C1.1124 21.6517 1.14071 21.6574 1.16931 21.6574H2.77377C2.88895 21.6574 2.98538 21.5636 2.98806 21.4484C3.04163 19.3806 3.86931 17.444 5.33449 15.9761C6.84788 14.4574 8.86217 13.6217 11.005 13.6217C12.5238 13.6217 13.9809 14.0422 15.2372 14.8297C15.2694 14.85 15.3065 14.8614 15.3446 14.8628C15.3827 14.8642 15.4204 14.8556 15.4541 14.8377ZM11.005 11.5859C9.77824 11.5859 8.62377 11.1065 7.75324 10.2359C7.32492 9.80873 6.98536 9.301 6.75413 8.74199C6.52289 8.18298 6.40455 7.58374 6.40592 6.9788C6.40592 5.74933 6.88538 4.59219 7.75324 3.72165C8.6211 2.85112 9.77556 2.37165 11.005 2.37165C12.2345 2.37165 13.3863 2.85112 14.2568 3.72165C14.6851 4.14886 15.0247 4.65659 15.2559 5.2156C15.4872 5.77461 15.6055 6.37385 15.6041 6.9788C15.6041 8.20826 15.1247 9.36541 14.2568 10.2359C13.3863 11.1065 12.2318 11.5859 11.005 11.5859Z"
      fill="currentColor"
    />
    <path
      d="M18.6462 20.5987C18.6305 20.5832 18.6094 20.5745 18.5874 20.5745C18.5653 20.5745 18.5442 20.5832 18.5285 20.5987L17.3181 21.8092C16.7577 22.3696 15.8119 22.429 15.1932 21.8092C14.5734 21.1894 14.6328 20.2445 15.1932 19.6841L16.4036 18.4736C16.4359 18.4413 16.4359 18.3882 16.4036 18.3559L15.989 17.9413C15.9733 17.9258 15.9522 17.9171 15.9301 17.9171C15.9081 17.9171 15.887 17.9258 15.8713 17.9413L14.6609 19.1518C13.7797 20.0331 13.7797 21.4592 14.6609 22.3394C15.5421 23.2197 16.9681 23.2207 17.8483 22.3394L19.0587 21.1289C19.091 21.0967 19.091 21.0435 19.0587 21.0112L18.6462 20.5987ZM21.3399 15.661C20.4586 14.7797 19.0326 14.7797 18.1525 15.661L16.9411 16.8714C16.9255 16.8871 16.9168 16.9083 16.9168 16.9303C16.9168 16.9523 16.9255 16.9735 16.9411 16.9892L17.3546 17.4027C17.3869 17.435 17.44 17.435 17.4723 17.4027L18.6827 16.1922C19.2431 15.6318 20.1889 15.5724 20.8076 16.1922C21.4274 16.8121 21.368 17.7569 20.8076 18.3173L19.5972 19.5278C19.5817 19.5435 19.573 19.5646 19.573 19.5867C19.573 19.6087 19.5817 19.6299 19.5972 19.6455L20.0118 20.0601C20.0441 20.0924 20.0972 20.0924 20.1295 20.0601L21.3399 18.8497C22.22 17.9684 22.22 16.5423 21.3399 15.661ZM19.0222 17.5454C19.0066 17.5299 18.9854 17.5212 18.9634 17.5212C18.9413 17.5212 18.9202 17.5299 18.9045 17.5454L16.5452 19.9039C16.5297 19.9195 16.521 19.9407 16.521 19.9627C16.521 19.9848 16.5297 20.0059 16.5452 20.0216L16.9577 20.4341C16.99 20.4664 17.0431 20.4664 17.0754 20.4341L19.4337 18.0757C19.466 18.0434 19.466 17.9903 19.4337 17.958L19.0222 17.5454Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.8"
    />
  </svg>
);

export default ProfileLinkIcon;
