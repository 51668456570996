import React, { useEffect, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { DeviceContext } from 'components/common/device-detection-provider';

import {
  getIsUserLoaded,
  getIsUserAuthorized,
  getUser,
  changeIsOnlyUserProfile
} from 'store/user';
import { getAllWorkspaces, setActiveId } from 'store/workspace';

import useRoutesService from 'services/routes';
import getActiveWorkspaces from 'utils/get-active-workspaces';

import WebStub from '../web-stub';

const AuthRequiredProvider = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const workspaces = useSelector(getAllWorkspaces);
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const isUserLoaded = useSelector(getIsUserLoaded);

  const routes = useRoutesService();

  const { params } = useRouteMatch('/:workspaceId');

  const { isSmallWidth, isMobile } = useContext(DeviceContext);
  const isNotDesktopView = isSmallWidth || isMobile;

  useEffect(() => {
    if (!isUserAuthorized) {
      routes.toLogin({ search: window.location.href });
    }

    if (isUserAuthorized && isUserLoaded && !user.firstName) {
      routes.toRegisterFinish();
    }
  }, [isUserAuthorized, isUserLoaded, routes, user]);

  useEffect(() => {
    const isOnlyUserProfile =
      user.personalWorkspaceId === +params.workspaceId &&
      !getActiveWorkspaces(workspaces).length;
    dispatch(changeIsOnlyUserProfile(isOnlyUserProfile));

    if (isOnlyUserProfile) {
      dispatch(setActiveId({ workspaceId: user.personalWorkspaceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, workspaces, params]);

  if (isNotDesktopView) {
    return <WebStub />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthRequiredProvider;
