export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const MODAL = 'modal';
export const DRAWER = 'drawer';

export const CHAT_ID = 'chatId'; // DELTE

// Orders
export const DETAIL_ORDER = 'detailOrder'; // CLEAN UP
export const COPY_ORDER = 'copyOrder';
export const CREATE_ORDER = 'createOrder';
export const CREATE_REVIEW = 'createReview';
export const SELECTED_STATUS = 'selectedStatus';
export const SELECTED_ORDER = 'selectedOrder';
export const ASSIGNING_SIGNATORY = 'assigningSignatory';
export const CONTRACTOR_ID = 'contractorId';

// Deals // TODO: удалить
export const DETAIL_DEAL = 'detailDeal';
export const SELECTED_DEAL = 'selectedDeal';
export const STATUS_DEAL = 'STATUS';

// Projects
export const CREATE_PROJECT = 'createProject';
export const EDIT_PROJECT = 'editProject';
export const SELECTED_PROJECT = 'selectedProject';

// Ideas
export const CREATE_IDEA = 'createIdea';
export const EDIT_IDEA = 'editIdea';
export const DETAILS_IDEA = 'detailsIdea';
export const SELECTED_IDEA = 'selectedIdea';
export const CONVERT_IDEA_TO_PROJECT = 'convertToProject';
export const CONVERT_IDEA_TO_TASK = 'convertToTask';
export const COPY_PUBLIC_IDEA = 'copyPublicIdea';

// Tasks
export const CREATE_TASK = 'createTask';
export const EDIT_TASK = 'editTask';
export const SELECTED_TASK = 'selectedTask';
export const DETAILS_TASK = 'detailsTask';
export const CREATE_SPRINT = 'createSprint';

// Assets
export const DETAILS_ASSET = 'detailsAsset';
export const SELECTED_ASSET = 'selectedAsset';
export const DELETED_ASSET = 'deletedAsset';

// Contacts
export const CHATS = 'chats';
export const CUSTOMERS = 'customers';
export const CONTRACTORS = 'contractors';
export const INTEGRATIONS = 'integrations';
export const CREATE_CONTACT = 'createContact';
export const SELECT_ORDER = 'selectOrder';
export const SELECTED_CONTACT = 'selectedContact';
export const FUNNELS_SETTINGS = 'dealSettings';
export const TAB_LINK = {
  INFO: 'info',
  FUNNELS: 'funnels'
};

// Team
export const EDIT_EMPLOYEE = 'editEmployee';
export const INVITE_EMPLOYEE = 'inviteEmployee';
export const REMOVE_EMPLOYEE = 'removeEmployee';
export const MOVE_EMPLOYEE = 'moveEmployee';
export const MOVE_MANAGER = 'moveManager';
export const MOVE_OWNER = 'moveOwner';
export const REMOVE_OWNER = 'removeOwner';
export const CHANGE_ADMIN = 'changeAdmin';
export const ASSIGN_CONTROLLER = 'assignController';
export const EMPLOYEE_ID = 'employeeId';

// Settings
export const SELECTED_WORKSPACE = 'selectedWorkspace';
export const SELECTED_EMPLOYEE = 'selectedEmployee';
export const KEY = 'key';
export const ID = 'id';

// Notifications
export const CREATE_FEEDBACK = 'createFeedback';

// Dialogues
export const DIALOGUES = 'dialogues';

// Common
export const CREATE_COMPANY = 'createCompany';
export const IS_COSTOMER = 'isCustomer';
export const STATUS_ID = 'statusId';
export const APPOINTMENT = 'appointment';
export const GUIDE = 'guide';
export const PROJECT_TASKS = 'project-tasks';

// Profile
export const EDITE_PROFILE = 'contactsEditor';
export const EDIT_WORKSPACE_PROFILE = 'editWorkspaceProfile';
export const EDIT_WORKSPACE_REQUISITES = 'editWorkspaceRequisites';
export const EDIT_USER_EMAIL = 'editUserEmail';
export const EDIT_PASSWORD = 'editUserPassword';

// Auth
export const SELECT_WORKSPACE = 'select-workspace';
export const INVITE_DATA = 'inviteData';

// For select link
export const LINK_TYPE = {
  PROJECT: 'project',
  CONTACT: 'contact',
  TASK: 'task',
  ASSETS: 'assets'
};
