import React from 'react';

export const DeleteNumberIcon = () => (
  <svg
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_88792_99473)">
      <path
        d="M90.8583 132.857C89.144 133 87.5726 133 85.8583 133C77.7154 132.857 69.2868 133.143 61.2868 133.143C60.8583 133.143 60.4297 133.143 60.0011 133.143C54.5726 133.286 49.2868 133.286 44.4297 133C42.7154 132.857 41.0011 132.857 39.2868 132.571C22.5726 131 11.7154 125.428 16.0011 108C16.4297 106.571 16.4297 105 16.4297 103.428C16.0011 96.857 12.144 89.1427 9.00113 81.4284C8.28685 79.5713 7.57256 77.7142 7.00113 75.7142C6.28685 73.4284 5.85828 71.2856 5.57256 69.1427C5.00113 65.4284 5.14399 61.7142 6.28685 57.9999C6.28685 57.9999 7.42971 54.2856 10.144 50.7142C13.2868 46.5713 52.0011 5.85701 97.4297 21.4284C99.2868 22.1427 144.001 38.1427 144.858 75.2856C145.573 111.571 103.858 132.143 90.8583 132.857Z"
        fill="#EBF8F8"
      />
      <path
        d="M85.7148 104.87C85.7148 104.87 96.7149 103.87 96.7149 108.727C96.7149 113.442 86.7149 126.299 121.715 121.585"
        stroke="#2B2A36"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M26.7164 55.1426L25.8592 56.1426C23.4307 59.5711 23.8592 62.5711 23.8592 62.5711C24.145 63.8569 23.7164 66.4283 24.4307 68.2854C27.0021 75.4283 32.0021 85.5711 45.8592 96.8569C59.8592 108.143 71.5735 111.571 79.7164 113.143C82.2878 113.571 85.145 112.857 86.7164 113C86.7164 113 87.2878 113 88.145 112.857C54.8592 110.571 34.8592 80.8569 26.7164 55.1426Z"
          fill="white"
        />
      </g>
      <ellipse
        cx="72.5449"
        cy="48.5893"
        rx="19.6875"
        ry="16.6616"
        fill="white"
      />
      <path
        d="M65.1736 68.7285L67.0718 61.8474C67.1123 61.7006 67.2564 61.6074 67.4069 61.6307L76.9771 63.1128C77.2696 63.1581 77.3267 63.5546 77.059 63.6807L65.5906 69.0797C65.3586 69.1889 65.1054 68.9756 65.1736 68.7285Z"
        fill="white"
      />
      <path
        d="M72.6382 51.3566C73.9389 51.3566 75.0538 50.2417 75.0538 48.941C75.0538 47.6403 73.9389 46.5254 72.6382 46.5254C71.3375 46.5254 70.2227 47.6403 70.2227 48.941C70.2227 50.2417 71.2446 51.3566 72.6382 51.3566Z"
        fill="#36B7BD"
      />
      <path
        d="M62.7906 51.3566C64.0913 51.3566 65.2062 50.2417 65.2062 48.941C65.2062 47.6403 64.0913 46.5254 62.7906 46.5254C61.4899 46.5254 60.375 47.6403 60.375 48.941C60.375 50.2417 61.4899 51.3566 62.7906 51.3566Z"
        fill="#36B7BD"
      />
      <path
        d="M82.4879 51.3566C83.7886 51.3566 84.9034 50.2417 84.9034 48.941C84.9034 47.6403 83.7886 46.5254 82.4879 46.5254C81.1872 46.5254 80.0723 47.6403 80.0723 48.941C80.0723 50.2417 81.0942 51.3566 82.4879 51.3566Z"
        fill="#36B7BD"
      />
      <path
        d="M89.1474 103.393L87.8467 105.159L84.2233 109.525C84.1304 109.618 83.9446 109.804 83.7588 109.99C82.551 111.476 81.0645 112.127 80.0425 112.405C79.8567 112.498 79.6709 112.498 79.4851 112.498C79.2992 112.498 79.2063 112.591 79.0205 112.591C78.9276 112.591 78.8347 112.591 78.7418 112.591C78.6489 112.591 78.6489 112.591 78.6489 112.591C77.0695 112.498 74.1893 113.242 71.6808 112.777C63.505 111.198 51.7058 107.76 37.7697 96.5183C23.8336 85.1836 18.8166 75.0567 16.3081 67.9029C15.6578 66.0447 16.0294 63.5362 15.7507 62.2355C15.7507 62.1426 15.2862 59.1696 17.7017 55.8249L18.5379 54.8029L22.8116 49.5072C24.5769 47.3704 27.7357 46.9987 29.9655 48.764L40.464 57.2185C42.6009 58.9838 42.9725 62.1426 41.2073 64.2795L34.425 72.6411C37.8626 77.1007 41.2073 80.9099 46.7817 85.4623C53.1923 90.6651 58.6738 93.6382 64.6199 96.3325L71.0305 88.4354C72.7957 86.2985 75.9546 85.9269 78.0914 87.6921L88.59 96.1467C90.6339 98.0977 90.9126 101.257 89.1474 103.393Z"
        fill="url(#paint0_linear_88792_99473)"
      />
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M3.57111 55.1641L2.73494 56.186C0.319355 59.6236 0.783891 62.5966 0.783891 62.5966C1.06261 63.8973 0.690984 66.4058 1.34133 68.264C3.84983 75.4178 8.86682 85.5447 22.8029 96.8794C36.739 108.214 48.5382 111.559 56.714 113.138C59.2225 113.603 62.1027 112.859 63.6821 112.952C63.6821 112.952 64.2395 112.952 65.0757 112.767C31.6291 110.537 11.7469 80.8065 3.57111 55.1641Z"
          fill="white"
        />
      </g>
      <path
        d="M149.819 92.0035L131.399 63.0417C130.904 62.2634 130.22 61.6226 129.411 61.1786C128.603 60.7347 127.695 60.502 126.773 60.502C125.85 60.502 124.943 60.7347 124.134 61.1786C123.325 61.6226 122.642 62.2634 122.146 63.0417L103.714 92.0035C103.184 92.8364 102.889 93.7968 102.86 94.7835C102.831 95.7701 103.068 96.7463 103.547 97.6092C104.027 98.472 104.73 99.1895 105.583 99.6859C106.437 100.182 107.408 100.439 108.395 100.429H145.248C146.225 100.42 147.183 100.148 148.02 99.6441C148.858 99.1398 149.546 98.4207 150.012 97.5612C150.478 96.7018 150.706 95.7333 150.672 94.7561C150.639 93.7789 150.344 92.8286 149.819 92.0035Z"
        fill="#F9716C"
      />
      <path
        d="M112.043 92.0042L130.475 63.0424C130.616 62.8088 130.779 62.589 130.961 62.3858C130.401 61.7415 129.698 61.2369 128.909 60.9122C128.119 60.5875 127.265 60.4516 126.413 60.5153C125.562 60.5791 124.737 60.8407 124.005 61.2795C123.273 61.7182 122.653 62.3219 122.195 63.0424L103.714 92.0042C103.184 92.8371 102.889 93.7975 102.86 94.7842C102.831 95.7708 103.068 96.747 103.547 97.6099C104.027 98.4727 104.73 99.1902 105.583 99.6866C106.437 100.183 107.408 100.44 108.395 100.43H116.675C115.692 100.431 114.727 100.168 113.881 99.6678C113.034 99.1679 112.338 98.4497 111.865 97.5884C111.391 96.7271 111.158 95.7543 111.189 94.7719C111.22 93.7895 111.515 92.8336 112.043 92.0042Z"
        fill="#F7413B"
      />
      <path
        d="M127.751 85.4012H125.782C125.278 85.4012 124.796 85.2013 124.44 84.8456C124.085 84.4899 123.885 84.0075 123.885 83.5044V71.6741C123.885 71.425 123.934 71.1784 124.029 70.9482C124.124 70.7181 124.264 70.509 124.44 70.3329C124.616 70.1568 124.826 70.017 125.056 69.9217C125.286 69.8264 125.532 69.7773 125.782 69.7773H127.751C128 69.7773 128.247 69.8264 128.477 69.9217C128.707 70.017 128.916 70.1568 129.092 70.3329C129.269 70.509 129.408 70.7181 129.504 70.9482C129.599 71.1784 129.648 71.425 129.648 71.6741V83.5044C129.648 84.0075 129.448 84.4899 129.092 84.8456C128.737 85.2013 128.254 85.4012 127.751 85.4012Z"
        fill="white"
      />
      <path
        d="M126.767 95.6147C127.339 95.6146 127.899 95.444 128.374 95.1246C128.849 94.8052 129.218 94.3515 129.435 93.8214C129.651 93.2913 129.704 92.7088 129.588 92.1482C129.472 91.5877 129.191 91.0744 128.782 90.674C128.373 90.2735 127.854 90.0039 127.291 89.8997C126.728 89.7955 126.147 89.8613 125.621 90.0888C125.096 90.3162 124.65 90.695 124.341 91.1768C124.032 91.6586 123.873 92.2216 123.885 92.7939C123.901 93.5476 124.212 94.265 124.751 94.7924C125.289 95.3197 126.013 95.6149 126.767 95.6147Z"
        fill="white"
      />
      <path
        d="M23.8759 35.2958C23.3206 35.851 22.4044 35.851 21.8491 35.2958L10.9243 24.3709C10.369 23.8156 10.369 22.8995 10.9243 22.3442C11.4795 21.7889 12.3957 21.7889 12.951 22.3442L23.8759 33.2691C24.4311 33.8243 24.4311 34.7405 23.8759 35.2958Z"
        fill="#F9716C"
      />
      <path
        d="M23.8759 24.3709L12.951 35.2958C12.3957 35.851 11.4795 35.851 10.9243 35.2958C10.369 34.7405 10.369 33.8243 10.9243 33.2691L21.8491 22.3442C22.4044 21.7889 23.3206 21.7889 23.8759 22.3442C24.4311 22.8995 24.4311 23.8156 23.8759 24.3709Z"
        fill="#F9716C"
      />
      <path
        d="M124.422 39.2094C123.992 39.6397 123.312 39.6397 122.881 39.2094L114.608 30.9359C114.178 30.5055 114.178 29.8253 114.608 29.395C115.038 28.9647 115.718 28.9647 116.149 29.395L124.422 37.6685C124.853 38.0988 124.853 38.779 124.422 39.2094Z"
        fill="#F9716C"
      />
      <path
        d="M124.422 30.9359L116.149 39.2094C115.718 39.6397 115.038 39.6397 114.608 39.2094C114.178 38.779 114.178 38.0988 114.608 37.6685L122.881 29.395C123.312 28.9647 123.992 28.9647 124.422 29.395C124.853 29.8253 124.853 30.5055 124.422 30.9359Z"
        fill="#F9716C"
      />
      <path
        d="M38.7224 119.222C38.4726 119.472 38.0839 119.472 37.834 119.222L33.0448 114.433C32.795 114.183 32.795 113.795 33.0448 113.545C33.2947 113.295 33.6834 113.295 33.9333 113.545L38.7224 118.334C38.9723 118.584 38.9723 118.973 38.7224 119.222Z"
        fill="#F9716C"
      />
      <path
        d="M38.7224 114.447L33.9333 119.236C33.6834 119.486 33.2947 119.486 33.0448 119.236C32.795 118.986 32.795 118.598 33.0448 118.348L37.834 113.558C38.0839 113.309 38.4726 113.309 38.7224 113.558C38.9723 113.808 38.9723 114.197 38.7224 114.447Z"
        fill="#F9716C"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_88792_99473"
        x1="15.6667"
        y1="80.4154"
        x2="90.3162"
        y2="80.4154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
      <clipPath id="clip0_88792_99473">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DeleteNumberIcon;
