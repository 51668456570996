export const markdownToPlainText = (markdownText = '') => {
  let plainText = markdownText;

  // Delete title characters
  plainText = plainText.replace(/#+\s+/g, '');

  // Delete formatting characters
  plainText = plainText.replace(/([*_~])/g, '');

  // Delete link characters
  plainText = plainText.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  return plainText;
};

export default markdownToPlainText;
