import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { getLocalizedText } from 'components/contacts-view/views/integrations/forms/website-form-channel/utils';

import addImagePrefixToBase64 from 'utils/add-image-prefix-to-base64';

import styles from './successed-submit-view.module.scss';

const SuccessedSubmitView = ({ title, setIsSubmitSuccess, config }) => {
  const {
    backgroundImage,
    backgroundColor,
    formColor,
    buttonColor,
    language
  } = config;

  const handleRetry = () => setIsSubmitSuccess(false);

  return (
    <div
      className={styles.root}
      style={{
        backgroundColor: !backgroundImage && backgroundColor
      }}
    >
      {backgroundImage && (
        <div
          className={styles.background}
          style={{
            backgroundImage: `url(${addImagePrefixToBase64(backgroundImage)})`
          }}
        />
      )}

      <div className={styles.content} style={{ backgroundColor: formColor }}>
        <Typography.Title className={styles.title}>{title}</Typography.Title>

        <Button
          className={styles.button}
          style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          type="primary"
          onClick={handleRetry}
        >
          {getLocalizedText('sendAnotherBtn', language)}
        </Button>
      </div>
    </div>
  );
};

SuccessedSubmitView.propTypes = {
  title: PropTypes.string.isRequired,
  setIsSubmitSuccess: PropTypes.func.isRequired,
  config: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    formColor: PropTypes.string,
    buttonColor: PropTypes.string
  })
};

SuccessedSubmitView.defaultProps = {
  config: {}
};

export default SuccessedSubmitView;
