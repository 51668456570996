import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import Form from './form';

import styles from './replace-tag-modal.module.scss';

const ReplaceTagModal = ({ visible, onClose, tag }) => {
  const { t } = useTranslation('TagsEditReplaceDelete');

  return (
    <Modal
      width={298}
      title={t('ReplaceTag')}
      destroyOnClose
      visible={visible}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <Form tag={tag} onCloseModal={onClose} />
    </Modal>
  );
};

ReplaceTagModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    link: PropTypes.string
  }).isRequired
};

export default ReplaceTagModal;
