import React from 'react';

export const SMSIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.1669 135.74C88.4669 135.84 86.7527 135.84 85.0527 135.84C76.6955 135.654 68.0669 135.94 59.8098 136.026H58.4812C52.8812 136.126 47.4669 136.126 42.4384 135.84C40.6384 135.74 38.9384 135.654 37.2241 135.454C20.1527 133.912 8.95266 128.197 13.3098 110.312C13.6877 108.795 13.8227 107.228 13.7098 105.669C13.2384 98.9259 9.25266 91.1545 6.12409 83.1831C5.35534 81.2913 4.68773 79.36 4.12409 77.3973C3.44901 75.1905 2.93809 72.9367 2.59552 70.6545C1.98548 66.8365 2.24428 62.9301 3.35266 59.2259C4.29647 56.5706 5.64056 54.0751 7.33838 51.8259C10.6527 47.5402 50.3241 5.99734 97.0098 21.9402C98.8955 22.5973 144.824 39.0831 145.581 76.9688C146.353 114.012 103.553 134.983 90.1669 135.74Z"
      fill="#EBF8F8"
    />
    <path
      d="M107.383 22.8264V100.826H39.4258V22.8264C39.4182 20.4722 40.3452 18.2112 42.0031 16.5399C43.6611 14.8686 45.9145 13.9235 48.2687 13.9121L98.4687 13.9121C100.832 13.9159 103.097 14.8563 104.768 16.5272C106.439 18.1982 107.379 20.4633 107.383 22.8264Z"
      fill="#D8D8D8"
    />
    <path
      d="M92.5946 58.8262H49.7661C47.3755 58.8262 45.4375 60.7641 45.4375 63.1547V73.4262C45.4375 75.8168 47.3755 77.7547 49.7661 77.7547H92.5946C94.9853 77.7547 96.9232 75.8168 96.9232 73.4262V63.1547C96.9232 60.7641 94.9853 58.8262 92.5946 58.8262Z"
      fill="white"
    />
    <path
      d="M65.1374 71.5696H51.8517C51.5978 71.5696 51.3544 71.4687 51.1749 71.2892C50.9954 71.1097 50.8945 70.8663 50.8945 70.6124C50.8945 70.3586 50.9954 70.1151 51.1749 69.9356C51.3544 69.7561 51.5978 69.6553 51.8517 69.6553H65.1374C65.3912 69.6553 65.6347 69.7561 65.8142 69.9356C65.9937 70.1151 66.0945 70.3586 66.0945 70.6124C66.0945 70.8663 65.9937 71.1097 65.8142 71.2892C65.6347 71.4687 65.3912 71.5696 65.1374 71.5696Z"
      fill="#E2E2E2"
    />
    <path
      d="M78.1223 66.9267H51.8795C51.6476 66.8982 51.4341 66.7859 51.2793 66.6109C51.1245 66.4359 51.0391 66.2103 51.0391 65.9767C51.0391 65.743 51.1245 65.5175 51.2793 65.3425C51.4341 65.1675 51.6476 65.0552 51.8795 65.0267H78.1223C78.2569 65.0101 78.3934 65.0224 78.5228 65.0626C78.6523 65.1028 78.7717 65.17 78.8733 65.2598C78.9748 65.3496 79.0561 65.46 79.1117 65.5835C79.1674 65.7071 79.1962 65.8411 79.1962 65.9767C79.1962 66.1122 79.1674 66.2462 79.1117 66.3698C79.0561 66.4934 78.9748 66.6037 78.8733 66.6936C78.7717 66.7834 78.6523 66.8506 78.5228 66.8908C78.3934 66.931 78.2569 66.9432 78.1223 66.9267Z"
      fill="#E2E2E2"
    />
    <path
      d="M75.4392 71.5696H70.5392C70.2853 71.5696 70.0419 71.4687 69.8624 71.2892C69.6829 71.1097 69.582 70.8663 69.582 70.6124C69.582 70.3586 69.6829 70.1151 69.8624 69.9356C70.0419 69.7561 70.2853 69.6553 70.5392 69.6553H75.4392C75.693 69.6553 75.9365 69.7561 76.116 69.9356C76.2955 70.1151 76.3963 70.3586 76.3963 70.6124C76.3963 70.8663 76.2955 71.1097 76.116 71.2892C75.9365 71.4687 75.693 71.5696 75.4392 71.5696Z"
      fill="#E2E2E2"
    />
    <path
      d="M54.8965 81.3403L60.1251 75.626H49.668L54.8965 81.3403Z"
      fill="white"
    />
    <path
      d="M107.383 100.826V107.283C107.385 108.443 107.158 109.592 106.715 110.664C106.272 111.736 105.622 112.71 104.801 113.53C103.981 114.35 103.007 115.001 101.935 115.444C100.863 115.887 99.7143 116.114 98.5544 116.112H48.2687C47.027 116.119 45.7987 115.855 44.6687 115.34C43.1043 114.655 41.7745 113.527 40.8429 112.096C39.9114 110.664 39.4188 108.991 39.4259 107.283V100.826H107.383Z"
      fill="white"
    />
    <path
      d="M73.4098 111.455C74.0286 111.455 74.6335 111.272 75.148 110.928C75.6625 110.584 76.0634 110.096 76.3002 109.524C76.537 108.952 76.599 108.323 76.4783 107.716C76.3576 107.11 76.0596 106.552 75.6221 106.115C75.1845 105.677 74.6271 105.379 74.0202 105.258C73.4133 105.138 72.7842 105.2 72.2126 105.436C71.6409 105.673 71.1523 106.074 70.8085 106.589C70.4647 107.103 70.2812 107.708 70.2812 108.327C70.2812 109.157 70.6109 109.952 71.1976 110.539C71.7843 111.126 72.5801 111.455 73.4098 111.455Z"
      fill="#B1B1B1"
    />
    <path d="M47.3811 43.4121L47.2383 43.5121L47.3811 43.4121Z" fill="white" />
    <path
      d="M101.809 36.0689V46.3403C101.864 47.408 101.499 48.4546 100.791 49.2559C100.083 50.0571 99.0895 50.549 98.0232 50.6261H60.4375C59.3701 50.549 58.3751 50.0576 57.6651 49.2568C56.955 48.456 56.5863 47.4093 56.6375 46.3403V36.0689C56.5863 34.9999 56.955 33.9533 57.6651 33.1525C58.3751 32.3517 59.3701 31.8603 60.4375 31.7832H97.9947C99.066 31.8531 100.067 32.3419 100.781 33.1439C101.494 33.946 101.864 34.9967 101.809 36.0689Z"
      fill="white"
    />
    <path
      d="M79.0665 37.9405H92.2808C92.4058 37.9386 92.53 37.9616 92.646 38.0082C92.7621 38.0547 92.8677 38.1239 92.9568 38.2116C93.0458 38.2993 93.1166 38.4039 93.1648 38.5192C93.2131 38.6346 93.238 38.7584 93.2379 38.8834C93.2379 39.0091 93.2132 39.1336 93.1651 39.2497C93.117 39.3658 93.0465 39.4713 92.9576 39.5602C92.8687 39.6491 92.7632 39.7196 92.6471 39.7677C92.531 39.8158 92.4065 39.8405 92.2808 39.8405H79.0665C78.9408 39.8405 78.8164 39.8158 78.7002 39.7677C78.5841 39.7196 78.4786 39.6491 78.3897 39.5602C78.3008 39.4713 78.2303 39.3658 78.1822 39.2497C78.1341 39.1336 78.1094 39.0091 78.1094 38.8834C78.1094 38.7584 78.1342 38.6346 78.1825 38.5192C78.2308 38.4039 78.3015 38.2993 78.3906 38.2116C78.4796 38.1239 78.5853 38.0547 78.7013 38.0082C78.8174 37.9616 78.9415 37.9386 79.0665 37.9405Z"
      fill="#E2E2E2"
    />
    <path
      d="M66.0952 42.5693H92.2809C92.5348 42.5693 92.7782 42.6702 92.9577 42.8497C93.1372 43.0292 93.2381 43.2726 93.2381 43.5265C93.2381 43.6515 93.2132 43.7753 93.1649 43.8906C93.1167 44.006 93.0459 44.1105 92.9569 44.1983C92.8678 44.286 92.7622 44.3552 92.6461 44.4017C92.5301 44.4482 92.4059 44.4712 92.2809 44.4693H66.0952C65.8451 44.4693 65.6053 44.37 65.4285 44.1932C65.2517 44.0164 65.1523 43.7765 65.1523 43.5265C65.1523 43.2751 65.2512 43.0338 65.4276 42.8547C65.604 42.6756 65.8438 42.5731 66.0952 42.5693Z"
      fill="#E2E2E2"
    />
    <path
      d="M68.7827 37.9405H73.7113C73.8363 37.9386 73.9604 37.9616 74.0765 38.0082C74.1925 38.0547 74.2982 38.1239 74.3872 38.2116C74.4763 38.2993 74.547 38.4039 74.5953 38.5192C74.6436 38.6346 74.6684 38.7584 74.6684 38.8834C74.6684 39.0091 74.6437 39.1336 74.5956 39.2497C74.5475 39.3658 74.477 39.4713 74.3881 39.5602C74.2992 39.6491 74.1937 39.7196 74.0776 39.7677C73.9614 39.8158 73.837 39.8405 73.7113 39.8405H68.7827C68.5313 39.8368 68.2915 39.7343 68.1151 39.5552C67.9387 39.3761 67.8398 39.1348 67.8398 38.8834C67.8398 38.6333 67.9392 38.3935 68.116 38.2167C68.2928 38.0399 68.5326 37.9405 68.7827 37.9405Z"
      fill="#E2E2E2"
    />
    <path
      d="M136.668 86.7695C135.479 87.8855 133.912 88.5084 132.282 88.5124H116.568L107.268 97.7838L97.9961 88.5124H82.2818C80.687 88.4807 79.1604 87.8599 77.9961 86.7695C78.0658 86.6485 78.1579 86.5418 78.2675 86.4552L106.568 63.9124C106.768 63.745 107.021 63.6533 107.282 63.6533C107.543 63.6533 107.796 63.745 107.996 63.9124L136.396 86.4981C136.502 86.572 136.594 86.6638 136.668 86.7695Z"
      fill="#FEC458"
    />
    <path
      d="M138.939 48.4833V81.8118C138.937 82.7511 138.734 83.6793 138.345 84.5341C137.956 85.389 137.389 86.1509 136.681 86.769C135.487 87.8834 133.915 88.5058 132.281 88.5118H82.2814C80.6866 88.4802 79.1599 87.8593 77.9957 86.769C77.28 86.1486 76.7075 85.3802 76.3178 84.517C75.9281 83.6537 75.7304 82.7161 75.7385 81.769V48.4833C75.7299 47.2315 76.0766 46.0029 76.7385 44.9404C77.0821 44.4028 77.5061 43.921 77.9957 43.5118C79.165 42.4313 80.6896 41.8164 82.2814 41.7833H132.281C133.911 41.7757 135.486 42.3768 136.696 43.469C137.407 44.0964 137.976 44.8681 138.365 45.7328C138.754 46.5974 138.955 47.5351 138.953 48.4833H138.939Z"
      fill="#FEC458"
    />
    <path
      d="M137.911 44.9117L107.911 70.3832C107.608 70.6353 107.226 70.7734 106.832 70.7734C106.438 70.7734 106.057 70.6353 105.754 70.3832L76.668 44.9403C77.0116 44.4027 77.4355 43.9209 77.9251 43.5117H136.682C137.155 43.9184 137.569 44.3897 137.911 44.9117Z"
      fill="#F9B74B"
    />
    <path
      d="M136.669 43.469C136.6 43.5945 136.507 43.706 136.397 43.7975L109.526 65.1404L107.997 66.3547C107.797 66.522 107.544 66.6137 107.283 66.6137C107.022 66.6137 106.769 66.522 106.569 66.3547L105.14 65.1404L78.1972 43.7975C78.0869 43.706 77.9949 43.5945 77.9258 43.469C79.1198 42.3877 80.6721 41.7871 82.2829 41.7832H132.283C133.904 41.7773 135.469 42.3787 136.669 43.469Z"
      fill="#FFE181"
    />
    <path
      d="M136.197 60.0122C142.564 60.0122 147.725 54.8507 147.725 48.4837C147.725 42.1166 142.564 36.9551 136.197 36.9551C129.829 36.9551 124.668 42.1166 124.668 48.4837C124.668 54.8507 129.829 60.0122 136.197 60.0122Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M131.804 47.6245L131.562 47.867C131.088 48.3412 131.088 49.11 131.562 49.5842L134.734 52.7561C135.208 53.2303 135.977 53.2303 136.451 52.7561L136.693 52.5137C137.168 52.0394 137.168 51.2706 136.693 50.7964L133.521 47.6245C133.047 47.1503 132.278 47.1503 131.804 47.6245Z"
      fill="white"
    />
    <path
      d="M141.088 44.2072L134.491 50.8035C134.017 51.2777 134.017 52.0465 134.491 52.5207L134.734 52.7632C135.208 53.2374 135.977 53.2374 136.451 52.7632L143.047 46.1669C143.522 45.6927 143.522 44.9238 143.047 44.4496L142.805 44.2072C142.331 43.733 141.562 43.733 141.088 44.2072Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="124.668"
        y1="48.4836"
        x2="147.739"
        y2="48.4836"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default SMSIcon;
