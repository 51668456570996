import axios from 'axios';
import { LANGUAGE } from 'i18n-config';
import qs from 'qs';

import { config } from 'config';
import { makeCancelableRequest } from 'utils/make-cancalable-request';

const API_MESSENGER = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}`;

const api = axios.create({
  baseURL: API_MESSENGER
});

api.interceptors.request.use(
  configuration => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

// data: [{entityid, entityType, chats: [{appId, roomUuid}], limit, offset}]
export default () => ({
  operatorChatsWithCancel: makeCancelableRequest(
    api,
    (fetch, { data, params }) => {
      const {
        accessToken,
        onlineChannelUuid,
        readable,
        withSource,
        withParent,
        withAggregation
      } = params;

      const queryParams = {
        access_token: accessToken,
        channel_id: onlineChannelUuid,
        readable,
        with_source: withSource,
        with_parent: withParent,
        with_aggregation: withAggregation
      };

      const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
      const urlWithParams = `/operator/chats?${queryString}`;

      return fetch('post', urlWithParams, {
        body: { data }
      });
    }
  ),

  operatorChats: ({ data, params }) =>
    api.post(
      `/operator/chats`,
      {
        data
      },
      {
        params: {
          access_token: params.accessToken,
          channel_id: params.onlineChannelUuid,
          readable: params.readable,
          with_source: params.withSource,
          with_parent: params.withParent,
          with_aggregation: params.withAggregation
        }
      }
    ),

  fetchUnreadedNotificationsCount: ({ roomUuids, params }) =>
    api.post(
      `/operator/chats/unread-notifications-count`,
      {
        data: roomUuids
      },
      {
        params: {
          access_token: params.accessToken
        }
      }
    )
});
