export const CHANNEL_DRAWER_WIDTH = 620;

export const OBSERVED_FIELDS = {
  name: 'name',
  sources: 'sources',
  responsible: 'responsible',
  operators: 'operators',
  message: 'message',
  icon: 'icon',
  title: 'title',
  color: 'color',
  position: 'position',
  left: 'left',
  right: 'right',
  bottom: 'bottom',
  isInvite: 'isInvite',
  inviteDelay: 'inviteDelay',
  nonWorkingTimeMessage: 'scheduleConfig.nonWorkingTimeMessage',
  isActiveScheduleConfig: 'scheduleConfig.isActive',

  withTelegram: 'withTelegram',
  telegramBot: 'telegramBot',
  whatsapp: 'whatsapp',
  withWhatsapp: 'withWhatsapp',
  botPosition: 'botPosition',
  isCallbackOn: 'isCallbackOn',
  language: 'language'
};
