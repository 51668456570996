import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import FormInput from 'components/common/hook-form/input';
import {
  validateMaxLength,
  validateMinLength,
  withoutBubbling
} from 'components/common/hook-form';

import styles from './add-field-modal.module.scss';

export const AddFieldModal = ({ visible, onClose, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['ConnectForm', 'Errors']);

  const handleModalClose = () => {
    methods.reset();

    onClose();
  };

  const addField = value => {
    onSubmit({ name: value.name, placeholder: value.name, required: true });

    methods.reset();

    onClose();
  };

  return (
    <Modal
      visible={visible}
      title={t('AddOwnField')}
      destroyOnClose
      width={508}
      contentClassName={styles.modal}
      onClose={handleModalClose}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={event =>
            withoutBubbling(event, () => methods.handleSubmit(addField))
          }
        >
          <FormInput
            label={t('FieldName')}
            name="name"
            itemProps={{
              className: styles.input
            }}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: validateMaxLength(256),
              minLength: validateMinLength(1),
              validate: value => {
                if (value.includes('.')) {
                  return t('ValidateWithoutDot', { ns: 'Errors' });
                }

                if (value.includes("'")) {
                  return t('ValidateWithoutSingleQuotes', { ns: 'Errors' });
                }

                if (value.includes('"')) {
                  return t('ValidateWithoutDoubleQuotes', { ns: 'Errors' });
                }

                return true;
              }
            }}
            onKeyPress={e => {
              if (e.key === '.' || e.key === "'" || e.key === '"') {
                e.preventDefault();
              }
            }}
          />

          <Button type="primary" htmlType="submit" className={styles.button}>
            {t('SaveBtn')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddFieldModal;
