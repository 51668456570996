import React from 'react';

export const WhatsAppChannelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_88631_30175)">
      <path
        d="M13.0001 0C13.8264 0 14.5331 0.293412 15.1197 0.880127C15.7063 1.46684 15.9997 2.17363 15.9998 2.99998V12.9999C15.9998 13.8262 15.7064 14.5329 15.1195 15.1198C14.533 15.7066 13.8263 16 12.9999 16H2.99998C2.17367 16 1.46695 15.7066 0.880128 15.1198C0.293413 14.5329 0 13.8262 0 12.9999V2.99998C0 2.17363 0.293413 1.46695 0.880128 0.880127C1.46695 0.293412 2.17367 0 2.99998 0H13.0001Z"
        fill="#27D045"
      />
      <g clipPath="url(#clip1_88631_30175)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5415 4.46039C10.614 3.52672 9.35059 3 8.03411 3H8.01902C5.27495 3.00002 3.04167 5.23371 3.04167 7.97949L3.04169 7.97978C3.04185 8.85345 3.27183 9.71171 3.70853 10.4684L3 13.0494L5.63994 12.3565C6.3696 12.7541 7.18719 12.9628 8.01817 12.9636H8.01823H8.01927C10.7696 12.9636 13 10.7331 13 7.98283C13 6.6621 12.4746 5.39487 11.5412 4.46147L11.5415 4.46039ZM8.02073 12.1248C7.27902 12.1248 6.54998 11.924 5.91281 11.5457L5.76114 11.4552L4.19452 11.8656L4.61116 10.3386L4.51324 10.1819C4.09778 9.52169 3.8764 8.75591 3.8764 7.97548C3.8764 5.68921 5.73047 3.83515 8.0167 3.83515C9.11711 3.83515 10.173 4.2734 10.9495 5.05283C11.7261 5.82983 12.1624 6.88342 12.1625 7.982C12.1625 10.2696 10.3078 12.1248 8.02018 12.1248H8.02073ZM10.2915 9.02238C10.1665 8.95947 9.55528 8.65947 9.44194 8.6178C9.3286 8.57613 9.24528 8.55489 9.16071 8.68073C9.07613 8.80654 8.83904 9.0853 8.76654 9.16904C8.69405 9.25279 8.62114 9.26363 8.4978 9.20071C8.37446 9.1378 7.97155 9.00654 7.49782 8.5824C7.22493 8.33077 6.99053 8.04039 6.80212 7.72057C6.72919 7.59556 6.79418 7.52849 6.85752 7.46766C6.92086 7.40684 6.98253 7.32265 7.04377 7.24974C7.09554 7.18695 7.13764 7.11678 7.16866 7.04155C7.18521 7.00726 7.19296 6.96939 7.19122 6.93135C7.18948 6.89331 7.1783 6.85631 7.15868 6.82367C7.12493 6.7612 6.87491 6.14868 6.77491 5.89912C6.67491 5.64955 6.57118 5.69081 6.49452 5.68495C6.41786 5.67912 6.3391 5.68122 6.25701 5.68122C6.19374 5.6827 6.13146 5.69728 6.0741 5.72403C6.01674 5.75078 5.96554 5.78912 5.92373 5.83664C5.81161 5.96037 5.48955 6.26244 5.48955 6.87493C5.48955 7.48743 5.93495 8.07825 5.99745 8.1624C6.05996 8.24656 6.87491 9.50281 8.12491 10.0416C8.42155 10.169 8.65322 10.2457 8.83322 10.3049C8.99391 10.3533 9.16081 10.3779 9.32862 10.3779C9.42499 10.3779 9.52155 10.3697 9.61644 10.3536C9.85562 10.3186 10.3523 10.0532 10.4568 9.76279C10.5614 9.47238 10.561 9.22114 10.5297 9.17157C10.4985 9.12198 10.4177 9.08574 10.2923 9.02196L10.2915 9.02238Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_88631_30175">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_88631_30175">
        <rect
          width="10"
          height="10.0494"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WhatsAppChannelIcon;
