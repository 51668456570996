import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BENCH } from 'constants/index';

import { fetchDepartmentsLocal } from 'store/team/departments';

import CustomSelect from '../custom-select';

const mapValue = value => ({
  value: value.id,
  label: value.name
});

const DepartmentSelect = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Filters');

  const fetchData = params =>
    dispatch(
      fetchDepartmentsLocal({
        lite: true,
        excludeType: [BENCH],
        ...params
      })
    ).then(({ entries }) => ({
      totalItems: entries.count,
      entries: entries.map(mapValue)
    }));

  return (
    <CustomSelect
      isAsync
      isClearable
      isSearchable
      fetchData={fetchData}
      valueText={t('PlaceholderDepartmentSelect')}
      {...props}
    />
  );
};

export default DepartmentSelect;
