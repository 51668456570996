import React from 'react';

export const ContactHistoryIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.5999 92.65C62.3999 92.75 61.2999 92.75 60.0999 92.75C54.3999 92.65 48.4999 92.85 42.8999 92.85C42.5999 92.85 42.2999 92.85 41.9999 92.85C38.1999 92.95 34.4999 92.95 31.0999 92.75C29.8999 92.65 28.6999 92.65 27.4999 92.45C15.7999 91.35 8.19991 87.45 11.1999 75.25C11.4999 74.25 11.4999 73.15 11.4999 72.05C11.1999 67.45 8.49992 62.05 6.29992 56.65C5.79992 55.35 5.29991 54.05 4.89991 52.65C4.39991 51.05 4.09992 49.55 3.89992 48.05C3.49992 45.45 3.59992 42.85 4.39992 40.25C4.39992 40.25 5.19992 37.65 7.09992 35.15C9.29992 32.25 36.3999 3.74998 68.1999 14.65C69.4999 15.15 100.8 26.35 101.4 52.35C101.9 77.75 72.6999 92.15 63.5999 92.65Z"
      fill="#EBF8F8"
    />
    <path
      d="M65.1999 73.85C65.1999 73.85 72.8999 73.15 72.8999 76.55C72.8999 79.85 65.8999 88.85 90.3999 85.55"
      stroke="#2B2A36"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <g opacity="0.4">
      <path
        opacity="0.4"
        d="M18.6999 38.25L18.0999 38.95C16.3999 41.35 16.6999 43.45 16.6999 43.45C16.8999 44.35 16.5999 46.15 17.0999 47.45C18.8999 52.45 22.3999 59.55 32.0999 67.45C41.8999 75.35 50.0999 77.75 55.7999 78.85C57.5999 79.15 59.5999 78.65 60.6999 78.75C60.6999 78.75 61.0999 78.75 61.6999 78.65C38.3999 77.05 24.3999 56.25 18.6999 38.25Z"
        fill="white"
      />
    </g>
    <ellipse cx="61.6999" cy="38.0086" rx="13.7812" ry="11.6631" fill="white" />
    <path
      d="M56.5922 51.9174L57.8475 47.3667C57.8881 47.2199 58.0321 47.1267 58.1827 47.15L64.5118 48.1302C64.8043 48.1755 64.8614 48.572 64.5937 48.6981L57.0092 52.2686C56.7772 52.3778 56.524 52.1646 56.5922 51.9174Z"
      fill="white"
    />
    <path
      d="M61.7656 39.9454C62.6761 39.9454 63.4566 39.165 63.4566 38.2545C63.4566 37.344 62.6761 36.5636 61.7656 36.5636C60.8552 36.5636 60.0747 37.344 60.0747 38.2545C60.0747 39.165 60.7901 39.9454 61.7656 39.9454Z"
      fill="#36B7BD"
    />
    <path
      d="M54.872 39.9454C55.7825 39.9454 56.5629 39.165 56.5629 38.2545C56.5629 37.344 55.7825 36.5636 54.872 36.5636C53.9615 36.5636 53.1811 37.344 53.1811 38.2545C53.1811 39.165 53.9615 39.9454 54.872 39.9454Z"
      fill="#36B7BD"
    />
    <path
      d="M68.6594 39.9454C69.5699 39.9454 70.3504 39.165 70.3504 38.2545C70.3504 37.344 69.5699 36.5636 68.6594 36.5636C67.749 36.5636 66.9685 37.344 66.9685 38.2545C66.9685 39.165 67.6839 39.9454 68.6594 39.9454Z"
      fill="#36B7BD"
    />
    <path
      d="M51.9909 72.1477L51.0804 73.3833L48.5441 76.44C48.479 76.505 48.349 76.6351 48.2189 76.7652C47.3734 77.8057 46.3329 78.261 45.6175 78.4561C45.4874 78.5211 45.3573 78.5211 45.2273 78.5211C45.0972 78.5211 45.0322 78.5861 44.9021 78.5861C44.8371 78.5861 44.772 78.5861 44.707 78.5861C44.642 78.5861 44.642 78.5861 44.642 78.5861C43.5364 78.5211 41.5203 79.0414 39.7643 78.7162C34.0412 77.6106 25.7818 75.2043 16.0265 67.3351C6.27128 59.4008 2.75938 52.312 1.00344 47.3043C0.548191 46.0036 0.808331 44.2476 0.613225 43.3371C0.613225 43.2721 0.28805 41.191 1.97896 38.8497L2.56428 38.1343L5.55589 34.4273C6.79156 32.9315 9.00275 32.6714 10.5636 33.907L17.9126 39.8252C19.4084 41.0609 19.6685 43.2721 18.4328 44.7679L13.6853 50.6211C16.0916 53.7427 18.4328 56.4092 22.3349 59.5959C26.8224 63.2379 30.6594 65.319 34.8217 67.205L39.3091 61.677C40.5448 60.1812 42.7559 59.9211 44.2518 61.1567L51.6007 67.0749C53.0315 68.4407 53.2266 70.6519 51.9909 72.1477Z"
      fill="url(#contact_history)"
    />
    <g opacity="0.4">
      <path
        opacity="0.4"
        d="M2.49921 38.2644L1.9139 38.9798C0.222987 41.3861 0.548162 43.4672 0.548162 43.4672C0.743267 44.3777 0.483127 46.1336 0.938373 47.4343C2.69432 52.442 6.20621 59.5309 15.9615 67.4652C25.7167 75.3994 33.9762 77.7407 39.6993 78.8463C41.4552 79.1715 43.4713 78.6512 44.5769 78.7162C44.5769 78.7162 44.9671 78.7162 45.5524 78.5861C22.1398 77.0253 8.2223 56.2141 2.49921 38.2644Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="contact_history"
        x1="0.554434"
        y1="56.0631"
        x2="52.8091"
        y2="56.0631"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
    </defs>
  </svg>
);

export default ContactHistoryIcon;
