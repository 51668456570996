import { useDispatch, useSelector } from 'react-redux';

import {
  setCollapsedNotificationsInBellFilterPanel,
  getCollapsedInBellFilterPanel
} from 'store/operator';

import { useLocalStorage } from 'hooks/common';
import { COLLAPSED_NOTIFICATIONS_IN_BELL_FILTER_PANEL } from 'constants/index';

export const useCollapsedNotificationsInBellFilterPanel = () => {
  const dispatch = useDispatch();

  const collapsed = useSelector(getCollapsedInBellFilterPanel);

  const [, setStorageCollapsed] = useLocalStorage(
    COLLAPSED_NOTIFICATIONS_IN_BELL_FILTER_PANEL,
    {
      opened: false,
      width: 380
    }
  );

  const setCollapsed = value => {
    setStorageCollapsed({ ...collapsed, opened: !value });
    dispatch(setCollapsedNotificationsInBellFilterPanel({ opened: !value }));
  };

  return [collapsed, setCollapsed];
};

export default useCollapsedNotificationsInBellFilterPanel;
