import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import { useTranslation } from 'react-i18next';

import Create from 'components/common/attachments-control/shared/create';

import UploadForm from './upload-form';

import styles from './add-file.module.scss';

const CREATE = 'create';
const UPLOAD = 'upload';

const TABS = [
  {
    key: CREATE,
    tab: 'CreateTab',
    Component: Create
  },
  {
    key: UPLOAD,
    tab: 'UploadTab',
    Component: UploadForm
  }
];

const Tabs = ({ isLoading, onSubmit }) => {
  const { t: trans } = useTranslation('AddFile');

  return (
    <AntdTabs defaultActiveKey={CREATE} className={styles.tabs}>
      {TABS.map(t => (
        <AntdTabs.TabPane tab={trans(t.tab)} key={t.key} className={styles.tab}>
          <t.Component isLoading={isLoading} onSubmit={onSubmit} />
        </AntdTabs.TabPane>
      ))}
    </AntdTabs>
  );
};

Tabs.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  isLoading: false
};

export default Tabs;
