import React from 'react';

export const DeleteTask = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="139"
    height="130"
    viewBox="0 0 139 130"
    fill="none"
  >
    <path
      d="M91.0403 132.727C89.4689 132.87 87.7546 132.87 86.1832 132.87C78.0403 132.727 69.7546 133.012 61.7546 133.155C61.326 133.155 60.8975 133.155 60.4689 133.155C55.0403 133.298 49.7546 133.155 44.8975 133.012C43.1832 132.87 41.4689 132.87 39.8975 132.727C23.1832 131.012 12.4689 125.584 16.6118 108.155C16.8975 106.727 17.0403 105.155 16.8975 103.727C16.4689 97.1552 12.6118 89.7267 9.61176 81.8695C8.89748 80.0124 8.18319 78.1552 7.61176 76.2981C6.89748 74.1552 6.46891 71.8695 6.04034 69.7267C5.46891 66.0124 5.61176 62.2981 6.75462 58.7267C6.75462 58.7267 7.89748 55.0124 10.6118 51.5838C13.8975 47.2981 52.1832 7.15522 97.4689 22.5838C99.6118 23.2981 144.04 39.1552 144.755 76.0124C145.469 111.727 104.04 132.012 91.0403 132.727Z"
      fill="#EBF8F8"
    />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="22"
      y="17"
      width="108"
      height="123"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.123 17.6537L22.8564 34.9275L44.1403 139.988L129.407 122.714L108.123 17.6537ZM76.8714 31.9103L68.2454 33.6578L67.122 65.6452L74.1244 64.2266L74.8787 102.52L81.2592 101.227L80.1877 123.175L88.8404 121.422L90.3241 91.0329L83.25 92.466L82.4957 54.1728L76.0436 55.4799L76.8714 31.9103Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M96.3395 40.8015L98.8251 52.7638L68.6863 123.761L55.3258 126.557C50.6652 127.489 46.1599 124.538 45.0724 119.877L31.4012 54.3174C30.4691 49.6567 33.4208 45.1515 38.0815 44.064L86.0861 34.1213C90.7467 33.0338 95.4073 36.1409 96.3395 40.8015Z"
        fill="#2D9EA3"
      />
      <path
        d="M98.8253 52.6085L110.011 106.361C110.943 111.022 107.991 115.527 103.331 116.615L68.6865 123.761L98.8253 52.6085Z"
        fill="#36B7BD"
      />
      <path
        d="M91.0781 40.3228L36.0264 51.8066L50.6509 121.914L105.703 110.43L91.0781 40.3228Z"
        fill="#E2E2E2"
      />
      <path
        d="M86.8627 38.6265L87.6395 42.6658L40.101 52.6085L39.3242 48.5692C42.4313 47.9478 44.917 47.0157 46.6259 46.2389C50.6651 44.2193 54.3936 42.5104 56.1025 38.9372C56.5686 38.0051 57.19 36.1409 59.0543 35.0534C59.9864 34.432 61.0739 34.2766 61.2292 34.2766C63.7149 33.8105 66.2006 34.7427 67.7541 36.6069C73.0362 41.4229 86.8627 38.6265 86.8627 38.6265Z"
        fill="#C4C4C4"
      />
      <path
        d="M62.1613 41.7337C63.3625 41.7337 64.3363 40.7599 64.3363 39.5587C64.3363 38.3575 63.3625 37.3837 62.1613 37.3837C60.9601 37.3837 59.9863 38.3575 59.9863 39.5587C59.9863 40.7599 60.9601 41.7337 62.1613 41.7337Z"
        fill="#2D9EA3"
      />
      <path
        d="M87.3161 41.6307L39.8682 51.5284L40.0902 52.593L87.5381 42.6953L87.3161 41.6307Z"
        fill="#D8D8D8"
      />
      <path
        d="M89.1929 53.5406L62.3165 59.1334C61.5398 59.2887 60.6076 58.8226 60.4523 57.8905C60.2969 57.1137 60.763 56.1816 61.6951 56.0263L88.5715 50.4335C89.3483 50.2781 90.2804 50.7442 90.4357 51.6763C90.5911 52.4531 90.125 53.3852 89.1929 53.5406Z"
        fill="#2B2A36"
      />
      <path
        d="M84.9986 61.9297L63.715 66.2797C62.9382 66.435 62.0061 65.969 61.8507 65.0368C61.6954 64.2601 62.1614 63.3279 63.0936 63.1726L84.3772 58.8227C85.1539 58.6673 86.0861 59.1334 86.2414 60.0655C86.3968 60.9976 85.7753 61.7744 84.9986 61.9297Z"
        fill="#2B2A36"
      />
      <path
        d="M52.9952 69.3868L46.9364 70.6296C45.2275 70.9403 43.6739 69.8528 43.2079 68.1439L41.965 62.0851C41.6543 60.3762 42.7418 58.8227 44.4507 58.3566L50.5095 57.1138C52.2184 56.803 53.772 57.8905 54.238 59.5994L55.4809 65.8136C55.7916 67.3672 54.7041 69.0761 52.9952 69.3868Z"
        fill="white"
      />
      <path
        d="M93.6987 74.9795L66.8224 80.5723C66.0456 80.7277 65.1135 80.2616 64.9582 79.3295C64.8028 78.5527 65.2689 77.6206 66.201 77.4652L93.0773 71.8725C93.8541 71.7171 94.7862 72.1832 94.9416 73.1153C94.9416 74.0474 94.4755 74.8242 93.6987 74.9795Z"
        fill="#2B2A36"
      />
      <path
        d="M89.3492 83.3687L68.0656 87.7186C67.2888 87.874 66.3567 87.4079 66.2013 86.4758C66.046 85.699 66.512 84.7669 67.4442 84.6115L88.7277 80.2616C89.5045 80.1063 90.4366 80.5723 90.592 81.5045C90.7473 82.4366 90.2813 83.2134 89.3492 83.3687Z"
        fill="#2B2A36"
      />
      <path
        d="M57.3458 90.8257L51.2869 92.0686C49.578 92.3793 48.0245 91.2918 47.5584 89.5829L46.3156 83.5241C46.0049 81.8152 47.0924 80.2616 48.8013 79.7956L54.8601 78.5527C56.569 78.242 58.1225 79.3295 58.5886 81.0384L59.8314 87.0972C60.1422 88.9615 59.0547 90.515 57.3458 90.8257Z"
        fill="white"
      />
      <path
        d="M98.0484 96.4185L71.172 102.011C70.3952 102.167 69.4631 101.701 69.3078 100.768C69.1524 99.9917 69.6185 99.0595 70.5506 98.9042L97.4269 93.3114C98.2037 93.1561 99.1358 93.6221 99.2912 94.5543C99.4466 95.4864 98.8251 96.2632 98.0484 96.4185Z"
        fill="#2B2A36"
      />
      <path
        d="M93.854 104.808L72.5705 109.158C71.7937 109.313 70.8616 108.847 70.7062 107.915C70.5508 107.138 71.0169 106.206 71.949 106.051L93.2326 101.701C94.0094 101.545 94.9415 102.011 95.0969 102.943C95.0969 103.876 94.6308 104.652 93.854 104.808Z"
        fill="#2B2A36"
      />
      <path
        d="M61.8507 112.42L55.7918 113.663C54.0829 113.974 52.5294 112.886 52.0633 111.177L50.8205 105.118C50.5098 103.409 51.5973 101.856 53.3062 101.39L59.365 100.147C61.0739 99.8363 62.6274 100.924 63.0935 102.633L64.3363 108.692C64.6471 110.4 63.5596 111.954 61.8507 112.42Z"
        fill="white"
      />
    </g>
    <path
      d="M125.726 69.72L111.15 46.8055C109.446 44.1268 105.536 44.1268 103.832 46.8055L89.256 69.7199C87.4194 72.6069 89.4933 76.3841 92.9149 76.3841H122.067C125.489 76.3841 127.563 72.6069 125.726 69.72Z"
      fill="#F9716C"
    />
    <path
      d="M95.8066 69.7209L110.383 46.8066C110.5 46.6217 110.629 46.451 110.767 46.2917C108.916 44.142 105.418 44.3127 103.832 46.8066L89.256 69.7209C87.4194 72.6078 89.4933 76.385 92.9149 76.385H99.4656C96.044 76.385 93.9701 72.6078 95.8066 69.7209Z"
      fill="#F7413B"
    />
    <path
      d="M108.27 64.4915H106.711C105.888 64.4915 105.214 63.8178 105.214 62.9944V53.6375C105.214 52.814 105.888 52.1404 106.711 52.1404H108.27C109.094 52.1404 109.767 52.814 109.767 53.6375V62.9944C109.767 63.8178 109.094 64.4915 108.27 64.4915Z"
      fill="white"
    />
    <path
      d="M107.491 72.5767C108.748 72.5767 109.767 71.5573 109.767 70.2999C109.767 69.0424 108.748 68.0231 107.491 68.0231C106.233 68.0231 105.214 69.0424 105.214 70.2999C105.214 71.5573 106.233 72.5767 107.491 72.5767Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.4739 14.483L79.6303 28.0197C79.6637 28.7737 78.6352 29.1152 78.1561 28.4978L76.856 26.5773C76.4797 25.9943 75.6228 26.1647 75.4506 26.8499L72.6967 37.9845C72.4559 38.8753 71.0853 38.7024 71.1206 37.7429L71.4498 18.9977C71.4849 18.2095 72.4448 17.9022 72.9239 18.5196L74.4294 20.6802C74.7715 21.1946 75.6626 21.0928 75.8689 20.4762L77.9321 14.1386C78.1386 13.3506 79.4406 13.5576 79.4739 14.483Z"
      fill="#FCC977"
    />
  </svg>
);

export default DeleteTask;
