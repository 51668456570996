import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import themeVariables from 'theme';

import styles from './content.module.scss';

export const ContentTitleStyles = {
  fontSize: 20,
  lineHeight: '27px',
  marginBottom: 16,
  color: themeVariables.color.black,
  wordBreak: 'break-word'
};

export const Content = ({ children, className, style, ...props }) => (
  <Layout.Content
    className={classnames(styles.root, className)}
    style={{
      backgroundColor: themeVariables.color['black-98'],
      display: 'flex',
      overflow: 'hidden',
      ...style
    }}
    {...props}
  >
    {children}
  </Layout.Content>
);

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object
};

Content.defaultProps = {
  children: undefined,
  className: undefined,
  style: {}
};

export default Content;
