import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './seporator.module.scss';

export const Seporator = ({
  onResizeStart,
  onResizeEnd,
  onResize,
  withNavigatorResize,
  className
}) => {
  const separatorXPosition = React.useRef(null);

  const handleMouseMove = e => {
    e.preventDefault();

    const newPaneWidth = e.clientX - separatorXPosition.current;

    if (newPaneWidth !== 0) {
      onResize(newPaneWidth);
    }
  };

  const handleMouseUp = e => {
    const newPaneWidth = e.clientX - separatorXPosition.current;
    onResizeEnd(newPaneWidth);

    separatorXPosition.current = null;
    removeListeners();
  };

  const removeListeners = () => {
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
  };

  const onMouseDown = e => {
    e.preventDefault();
    onResizeStart(e);

    separatorXPosition.current = e.clientX;

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  };

  return (
    <div
      onClick={onResizeStart}
      onMouseDown={onMouseDown}
      className={classnames(styles.root, className, {
        [styles.withNavigator]: withNavigatorResize
      })}
    />
  );
};

Seporator.propTypes = {
  onResizeStart: PropTypes.func,
  onResizeEnd: PropTypes.func,
  onResize: PropTypes.func,
  withNavigatorResize: PropTypes.bool,
  className: PropTypes.string
};

Seporator.defaultProps = {
  onResizeStart: () => {},
  onResizeEnd: () => {},
  onResize: () => {},
  withNavigatorResize: false,
  className: undefined
};

export default Seporator;
