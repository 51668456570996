// TEST IDS

// ---- AUTH

export const TESTID_AUTH_REGISTRATION_TAB = 'auth-registration-tab';
export const TESTID_AUTH_LOGIN_TAB = 'auth-login-tab';

export const TESTID_REGISTRATION_EMAIL_INPUT = 'registration-email-input';
export const TESTID_REGISTRATION_PASSWORD_INPUT = 'registration-password-input';
export const TESTID_REGISTRATION_AGREEMENT_CHECKBOX =
  'registration-agreement-checkbox';
export const TESTID_REGISTRATION_SUBMIT_BUTTON = 'registration-submit-button';
export const TESTID_REGISTRATION_SUCCESS_MODAL = 'registration-success-modal';

export const TESTID_LOGIN_EMAIL_INPUT = 'login-email-input';
export const TESTID_LOGIN_PASSWORD_INPUT = 'login-password-input';
export const TESTID_LOGIN_SUBMIT_BUTTON = 'login-submit-button';

// ---- TASKS

export const TESTID_TASKS_VIEW_TITLE = 'tasks-view-title';

// ---- ORDERS

export const TESTID_ORDERS_VIEW_CREATE_BUTTON = 'orders-view-create-button';

export const TESTID_ORDERS_CREATOR_MODAL = 'orders-creator-modal';
export const TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT =
  'orders-creator-modal-title-input';
export const TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER =
  'orders-creator-modal-deadline-at-picker';
export const TESTID_ORDERS_CREATOR_MODAL_PRICE_INPUT =
  'orders-creator-modal-price-input';
export const TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT =
  'orders-creator-modal-contractor-select';
export const TESTID_ORDERS_CREATOR_MODAL_SIGNATORY_SELECT =
  'orders-creator-modal-signatory-select';
export const TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON =
  'orders-creator-modal-submit-button';
export const TESTID_ORDERS_CREATOR_MODAL_SHOW_FULL_FORM_BUTTON =
  'orders-creator-modal-show-full-form-button';

export const TESTID_ORDERS_CONTRACT_MODAL = 'orders-contract-modal';
export const TESTID_ORDERS_DETAILS_DRAWER = 'orders-details-drawer';

export const TESTID_ORDERS_ICON_ID = 'orders-icon-id';
export const TESTID_ORDERS_USER_ROLE_INVITE_TO_REGISTER =
  'orders-user-role-invite-to-register';

export const TESTID_WEBSITE_FORM_CHANNEL_NAME = 'website-form-channel-name';
export const TESTID_WEBSITE_FORM_MESSAGE = 'website-form-message';

export const TESTID_CONTACTS_IMPORT_UPLOAD = 'contacts-import-upload';

// MOCKS

export const workspaceStateMock = {
  activeId: 1,
  entities: {
    1: {
      id: 1,
      title: 'Workspace',
      isArchived: false,
      user: {
        id: 1,
        currency: 'BYN',
        lastName: 'Test',
        firstName: 'Test',
        roles: []
      },
      account: {
        addons: [],
        tariff: {}
      }
    },
    2: {
      id: 2,
      title: 'Workspace 2',
      isArchived: true,
      user: {
        id: 2,
        currency: 'BYN',
        lastName: 'Test',
        firstName: 'Test',
        roles: []
      },
      account: {
        tariff: {}
      }
    }
  }
};

export const orderStatusMock = {
  id: '1',
  title: 'Order Status',
  startAt: '2022-11-04T08:46:13.023Z',
  deadlineAt: '2022-11-04T08:46:13.023Z',
  currency: 'BYN',
  price: 100,
  measurementUnit: 'За услугу',
  content: 'Description'
};

export const employeeMock = {
  id: 1,
  userId: 2,
  workspaceId: 1,
  firstName: 'Test',
  lastName: 'Employee',
  avatarFile: null,
  email: 'test@employee.com',
  phone: '+375291111111'
};

export const reportFromListMock = {
  id: 2,
  title: 'Отчет по трудозатратам (за период)',
  icon: '',
  isSystem: true
};

export const reportDetailsMock = {
  ...reportFromListMock,
  description: 'Описание отчета',
  filters: [],
  reportQueryId: 1,
  visualisations: [],
  fileList: []
};

export const paymentCardMock = {
  id: 21,
  isPrimary: true,
  number: '123456xxxxxx1234',
  expirationDate: '30.11.2024',
  brand: 'VISA',
  createdAt: '2024-04-01T22:21:48+03:00',
  country: 'BLR'
};

// export const channelMock = {
//   id: 1,
//   name: 'channel',
//   kind: 'website-form',
//   config: {
//     tags: [],
//     color: '#D2F7F9',
//     title: 'Форма для обратной связи',
//     fields: [
//       {
//         name: 'mail',
//         required: true,
//         placeholder: 'Email'
//       }
//     ],
//     message: 'test',
//     pageLogo: 'logo',
//     pageColor: '#FFFFFF',
//     buttonText: 'Отправить',
//     buttonColor: '#16969C',
//     pageBackground: ''
//   },
//   status: 'connected',
//   isActive: true
// };

export const employeeRecordMock = {
  id: 3100,
  title: '123',
  employee: {
    id: 235286,
    lastName: 'Bobby',
    firstName: 'Bob',
    middleName: null,
    phone: '+375292731342',
    email: 'shladeq@yopmail.com',
    position: 'BOSS',
    avatarFile: null
  },
  contact: null,
  status: 'possible',
  relation: {
    id: '64904',
    type: 'task',
    templateId: null
  },
  attendees: [
    {
      id: 3100,
      status: 'possible',
      employee: {
        id: 235286,
        lastName: 'Bobby',
        firstName: 'Bob',
        middleName: null,
        phone: '+375292731342',
        email: 'shladeq@yopmail.com',
        position: 'BOSS',
        avatarFile: null
      },
      contact: null
    }
  ],
  dateStart: new Date().setHours(16, 30),
  dateEnd: new Date().setHours(17, 0),
  gEventId: null,
  gEventUrl: null,
  scheduler: null
};
