import React from 'react';

export const PushpinIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1543 7.65747L12.3418 2.84497C12.2148 2.71802 12.0488 2.65552 11.8828 2.65552C11.7168 2.65552 11.5507 2.71802 11.4238 2.84497L8.27731 5.99341C8.03903 5.96606 7.7988 5.95435 7.55856 5.95435C6.12887 5.95435 4.69919 6.42505 3.52536 7.36646C3.22458 7.60669 3.20114 8.05786 3.47262 8.3313L7.02145 11.8801L2.81442 16.0833C2.76287 16.1345 2.73105 16.2023 2.72458 16.2747L2.65817 17.0012C2.64059 17.1848 2.78708 17.3411 2.96872 17.3411C2.97848 17.3411 2.98825 17.3411 2.99801 17.3391L3.72458 17.2727C3.79684 17.2668 3.8652 17.2336 3.91598 17.1829L8.12301 12.9758L11.6718 16.5247C11.7988 16.6516 11.9648 16.7141 12.1308 16.7141C12.3203 16.7141 12.5078 16.6321 12.6367 16.4719C13.7363 15.0989 14.1933 13.3801 14.0078 11.718L17.1543 8.57153C17.4062 8.32153 17.4062 7.91138 17.1543 7.65747Z"
      fill="currentColor"
    />
  </svg>
);

export default PushpinIcon;
