import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Lottie from 'lottie-react';
import api from 'api';
import { notification } from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { WEBSITE_FORM_SERVER_URL } from 'api/v1/website-form';

import {
  STATUS_CONNECTED,
  STATUS_DELETED,
  STATUS_DISCONNECTED
} from 'constants/index';

import {
  BigFormIcon,
  Error404Icon,
  initialLoadingAnimation
} from 'components/common/icons';
import WebsiteForm from 'components/contacts-view/views/integrations/forms/website-form';
import { getLocalizedText } from 'components/contacts-view/views/integrations/forms/website-form-channel/utils';

import { config } from 'config';
import addImagePrefixToBase64 from 'utils/add-image-prefix-to-base64';

import UnavailableView from './views/unavailable-view';
import SuccessedSubmitView from './views/successed-submit-view';

import styles from './website-form-view.module.scss';

const { REACT_APP_RECAPTCHA_SITE_KEY } = config;

const ERROR = 'error';

const WebsiteFormView = () => {
  const {
    params: { channelUuid }
  } = useRouteMatch();

  const [scriptData, setScriptData] = useState({});
  const [scriptStatus, setScriptStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [geo, setGeo] = useState(null);
  const [utm, setUtm] = useState(null);
  const [files, setFiles] = useState(null);

  const { channelStatus } = scriptData;
  const language = scriptData.language !== 'None' ? scriptData.language : 'ru';

  const fetchIP = async () => {
    const { data } = await api.geolocation.fetchIP();

    return data;
  };

  const fetchInfoByIP = async ip => {
    const { data } = await api.geolocation.fetchInfo(ip);

    return {
      country: data.country,
      city: data.city
    };
  };

  const getGeoInfo = async () => {
    const IP = await fetchIP();
    const geoPosition = await fetchInfoByIP(IP);

    setGeo(geoPosition);
  };

  const onSubmit = async values => {
    try {
      setIsLoadingSend(true);

      let fileData = [];

      if (files) {
        const filePromises = files.map(async file => {
          const formData = new FormData();
          formData.append('file', file);

          return api.websiteForm.uploadFileWebsiteForm(formData);
        });

        fileData = await Promise.all(filePromises);
      }

      await api.websiteForm.sendForm({
        channelUuid,
        data: {
          ...values,
          phone: values.phone
            ? values.phone.replace(/[^0-9+]/g, '')
            : undefined,
          files: fileData.length ? fileData : undefined,
          leads: {
            page: document.title,
            country: `${geo.country}, ${geo.city}`,
            link: window.location.href,
            ...utm
          }
        }
      });

      setIsSubmitSuccess(true);
    } catch ({ response }) {
      notification.error({
        message: getLocalizedText('errorMessage', language)
      });
    } finally {
      setIsLoadingSend(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${WEBSITE_FORM_SERVER_URL}/${channelUuid}`;
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      const dataFromScript = window._upserviceWebsiteForm;

      setScriptData(dataFromScript);
      setIsLoading(false);
    };

    script.onerror = e => {
      setScriptStatus(e.type);
      setIsLoading(false);
    };

    document.body.classList.add(styles.body);

    return () => {
      document.head.removeChild(script);
      document.body.classList.remove(styles.body);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmObject = {};
    let referrer;
    let lastPage;

    searchParams.forEach((value, key) => {
      if (key.startsWith('utm')) {
        utmObject[key] = value;
      }

      if (key === 'referrer') {
        referrer = value;
      }

      if (key === 'last_page') {
        lastPage = value;
      }
    });

    setUtm({ ...utmObject, referrer, lastPage });

    getGeoInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (scriptStatus === ERROR || !scriptData) {
    return <UnavailableView icon={Error404Icon} title="Page not found" />;
  }

  if (
    channelStatus === STATUS_DISCONNECTED ||
    channelStatus === STATUS_DELETED
  ) {
    return (
      <UnavailableView
        icon={BigFormIcon}
        title={getLocalizedText('formUnavailableTitle', language)}
        description={getLocalizedText('formUnavailableDescription', language)}
      />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.wrapLoader}>
        <Lottie
          animationData={initialLoadingAnimation}
          loop
          className={styles.loader}
        />
      </div>
    );
  }

  if (isSubmitSuccess) {
    return (
      <SuccessedSubmitView
        title={scriptData.message}
        setIsSubmitSuccess={setIsSubmitSuccess}
        config={{
          backgroundImage: scriptData.pageBackground,
          backgroundColor: scriptData.pageColor,
          formColor: scriptData.color,
          buttonColor: scriptData.buttonColor,
          language
        }}
      />
    );
  }

  return (
    <>
      {channelStatus === STATUS_CONNECTED && (
        <GoogleReCaptchaProvider
          reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}
          language="ru"
          useRecaptchaNet
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined
          }}
        >
          {scriptData.pageBackground && (
            <div
              className={styles.backgroundImage}
              style={{
                backgroundImage: `url(${addImagePrefixToBase64(
                  scriptData.pageBackground
                )})`
              }}
            />
          )}
          <div
            className={styles.root}
            style={{
              backgroundColor:
                !scriptData.pageBackground && scriptData.pageColor
            }}
          >
            <WebsiteForm
              values={{
                ...scriptData,
                language
              }}
              onSubmit={onSubmit}
              isLoading={isLoadingSend}
              onChangeFile={setFiles}
            />
          </div>
        </GoogleReCaptchaProvider>
      )}
    </>
  );
};

export default WebsiteFormView;
