import React from 'react';

export const GoogleAccNotConnected = () => (
  <svg
    width="151"
    height="151"
    viewBox="0 0 151 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M82.6077 23.0941C67.7017 23.0941 15.0484 49.3894 3.90938 70.4168C-6.58284 90.2232 23.9929 104.377 23.9929 118.352C23.9929 147.162 64.3773 131.96 100.838 124.964C137.299 117.969 157.369 87.0056 146.195 62.988C135.02 38.9703 111.554 23.0941 82.6077 23.0941Z"
      fill="#EBF8F8"
    />
    <rect
      x="18.3635"
      y="31.1876"
      width="109.351"
      height="81.0714"
      rx="10"
      fill="#EFEDED"
    />
    <path
      d="M18.3635 41.1876C18.3635 35.6648 22.8407 31.1876 28.3635 31.1876H117.715C123.238 31.1876 127.715 35.6648 127.715 41.1876V48.814H18.3635V41.1876Z"
      fill="#32C9C9"
    />
    <g clipPath="url(#clip0_35341_206352)">
      <path
        d="M66.8982 69.4969L66.0462 72.6772L62.9325 72.7431C62.0019 71.0171 61.4741 69.0424 61.4741 66.9439C61.4741 64.9147 61.9676 63.0011 62.8424 61.3162H62.8431L65.6152 61.8244L66.8295 64.5799C66.5753 65.3208 66.4368 66.1162 66.4368 66.9439C66.4369 67.8422 66.5996 68.7029 66.8982 69.4969Z"
        fill="#FDA402"
      />
      <path
        d="M85.7348 64.6578C85.8753 65.3981 85.9486 66.1626 85.9486 66.9439C85.9486 67.82 85.8565 68.6746 85.681 69.4989C85.0853 72.3041 83.5288 74.7535 81.3725 76.4869L81.3718 76.4862L77.8802 76.3081L77.386 73.2232C78.8168 72.3841 79.935 71.0709 80.524 69.4989H73.9805V64.6578H80.6195H85.7348Z"
        fill="#518EF8"
      />
      <path
        d="M81.3715 76.4862L81.3722 76.4869C79.2751 78.1725 76.6111 79.1811 73.7112 79.1811C69.051 79.1811 64.9993 76.5763 62.9324 72.7431L66.898 69.4969C67.9314 72.255 70.592 74.2183 73.7112 74.2183C75.0519 74.2183 76.3079 73.8559 77.3857 73.2232L81.3715 76.4862Z"
        fill="#28B446"
      />
      <path
        d="M81.5221 57.524L77.5578 60.7695C76.4424 60.0723 75.1238 59.6695 73.7112 59.6695C70.5214 59.6695 67.8111 61.7229 66.8295 64.5799L62.843 61.3162H62.8423C64.8789 57.3895 68.9817 54.7068 73.7112 54.7068C76.6804 54.7068 79.4028 55.7644 81.5221 57.524Z"
        fill="#F7413B"
      />
    </g>
    <rect
      x="31.6887"
      y="87.7175"
      width="82.7012"
      height="15.4394"
      rx="7.71968"
      fill="white"
    />
    <path
      d="M53.2974 100.544C54.3152 100.527 55.3092 100.233 56.1731 99.695C57.037 99.1566 57.7382 98.3934 58.2015 97.4871C58.6649 96.5807 58.873 95.5654 58.8036 94.5498C58.7342 93.5343 58.3898 92.5567 57.8075 91.7219C57.2251 90.887 56.4266 90.2263 55.4975 89.8104C54.5684 89.3946 53.5436 89.2393 52.533 89.3612C51.5224 89.483 50.564 89.8775 49.7604 90.5023C48.9568 91.1271 48.3383 91.9587 47.9711 92.9081L38.839 93.0357L35.2413 96.1887L39.9606 96.1232L39.9732 97.1168L42.1261 97.084L42.1135 96.0904L43.4758 96.0752L43.5197 98.4058L45.6836 98.375L45.6518 96.0694L47.7132 96.0416C47.9701 97.3268 48.6696 98.4812 49.69 99.3038C50.7104 100.126 51.9869 100.565 53.2974 100.544ZM50.536 94.9709C50.524 94.4194 50.6762 93.8767 50.9735 93.4119C51.2707 92.9471 51.6995 92.5812 52.2053 92.3608C52.711 92.1404 53.2709 92.0754 53.8137 92.1741C54.3565 92.2728 54.8577 92.5308 55.2535 92.9151C55.6493 93.2995 55.9218 93.7929 56.0364 94.3326C56.151 94.8722 56.1025 95.4338 55.897 95.9458C55.6915 96.4578 55.3384 96.8971 54.8825 97.2079C54.4266 97.5186 53.8886 97.6868 53.337 97.6909C52.6064 97.6963 51.903 97.4141 51.3789 96.9051C50.8548 96.3961 50.552 95.7013 50.536 94.9709Z"
      fill="#FEC458"
    />
    <ellipse
      cx="68.3881"
      cy="95.4371"
      rx="2.36805"
      ry="2.36805"
      fill="#C4C4C4"
    />
    <circle cx="77.1683" cy="95.4371" r="2.36805" fill="#C4C4C4" />
    <circle cx="85.9486" cy="95.4371" r="2.36805" fill="#C4C4C4" />
    <circle cx="94.7289" cy="95.4371" r="2.36805" fill="#C4C4C4" />
    <circle cx="103.509" cy="95.4371" r="2.36805" fill="#C4C4C4" />
    <path
      d="M30.7453 43.217C32.5013 43.5267 34.1758 42.3542 34.4854 40.5982C34.7951 38.8422 33.6225 37.1676 31.8665 36.858C30.1105 36.5484 28.436 37.7209 28.1264 39.4769C27.8167 41.2329 28.9893 42.9074 30.7453 43.217Z"
      fill="white"
    />
    <path
      d="M43.0883 43.1791C44.8443 43.4887 46.5188 42.3162 46.8284 40.5602C47.1381 38.8042 45.9656 37.1297 44.2096 36.82C42.4536 36.5104 40.779 37.6829 40.4694 39.4389C40.1598 41.1949 41.3323 42.8694 43.0883 43.1791Z"
      fill="white"
    />
    <path
      d="M55.4455 43.1436C57.2015 43.4532 58.876 42.2807 59.1856 40.5247C59.4952 38.7687 58.3227 37.0941 56.5667 36.7845C54.8107 36.4749 53.1362 37.6474 52.8266 39.4034C52.5169 41.1594 53.6895 42.8339 55.4455 43.1436Z"
      fill="white"
    />
    <path
      d="M119.574 51.9013C126.166 51.9013 131.511 46.5568 131.511 39.964C131.511 33.3712 126.166 28.0267 119.574 28.0267C112.981 28.0267 107.636 33.3712 107.636 39.964C107.636 46.5568 112.981 51.9013 119.574 51.9013Z"
      fill="url(#paint0_linear_35341_206352)"
    />
    <path
      d="M115.026 39.0742L114.775 39.3253C114.284 39.8163 114.284 40.6124 114.775 41.1034L118.06 44.3878C118.551 44.8788 119.347 44.8788 119.838 44.3878L120.089 44.1367C120.58 43.6457 120.58 42.8496 120.089 42.3586L116.804 39.0743C116.313 38.5832 115.517 38.5832 115.026 39.0742Z"
      fill="white"
    />
    <path
      d="M124.64 35.5365L117.81 42.3667C117.319 42.8577 117.319 43.6538 117.81 44.1448L118.061 44.3958C118.552 44.8869 119.348 44.8869 119.839 44.3958L126.67 37.5657C127.161 37.0747 127.161 36.2786 126.67 35.7875L126.419 35.5365C125.928 35.0455 125.131 35.0455 124.64 35.5365Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35341_206352"
        x1="107.636"
        y1="39.964"
        x2="131.526"
        y2="39.964"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <clipPath id="clip0_35341_206352">
        <rect
          width="24.4743"
          height="24.4743"
          fill="white"
          transform="translate(61.4741 54.7068)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleAccNotConnected;
