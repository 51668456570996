import moment from 'moment';

export const TIME_FORMAT = `HH:mm`;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;

export const DATE_PICKER_FORMAT = 'dd.MM.yyyy';
export const DATE_PICKER_TIME_FORMAT = `${DATE_PICKER_FORMAT} ${TIME_FORMAT}`;

export const DATE_FORMAT_FM = 'DD MMMM YYYY';
export const DATE_TIME_FORMAT_FM = `${DATE_FORMAT_FM}, ${TIME_FORMAT}`;
export const DEFAULT_CALENDAR_VIEW = 'month';
export const DEFAULT_CALENDAR_DATE_START = moment()
  .startOf(DEFAULT_CALENDAR_VIEW)
  .subtract(7, 'days')
  .format(DATE_FORMAT);
export const DEFAULT_CALENDAR_DATE_END = moment()
  .endOf(DEFAULT_CALENDAR_VIEW)
  .add(7, 'days')
  .format(DATE_FORMAT);

export const SHORT_DAY_FORMAT = 'ddd';
export const SHORT_DATE_FORMAT = 'DD.MM.YY';

const FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const getStartDate = date =>
  date
    .set({
      hours: 0,
      minutes: 0
    })
    .format(FORMAT);

export const getEndDate = date =>
  date
    .set({
      hours: 23,
      minutes: 59
    })
    .format(FORMAT);

export const PERIODS = [
  {
    label: 'TodayBtnFilters',
    ns: 'Filters',
    value: [getStartDate(moment()), getEndDate(moment())]
  },
  {
    label: 'YesterdayBtnFilters',
    ns: 'Filters',
    value: [
      getStartDate(moment().subtract(1, 'days')),
      getEndDate(moment().subtract(1, 'days'))
    ]
  },
  {
    label: 'ThisWeekBtnFilters',
    ns: 'Filters',
    value: [
      getStartDate(moment().startOf('isoWeek')),
      getEndDate(moment().endOf('isoWeek'))
    ]
  },
  {
    label: 'PreviousWeekBtnFilters',
    ns: 'Filters',
    value: [
      getStartDate(
        moment()
          .startOf('isoWeek')
          .subtract(1, 'days')
          .startOf('isoWeek')
      ),
      getEndDate(
        moment()
          .startOf('isoWeek')
          .subtract(1, 'days')
      )
    ]
  },
  {
    label: 'ThisMonthBtnFilters',
    ns: 'Filters',
    isDefault: true,
    value: [
      getStartDate(moment().startOf('month')),
      getEndDate(moment().endOf('month'))
    ]
  },
  {
    label: 'PreviousMonthFilters',
    ns: 'Filters',
    value: [
      getStartDate(
        moment()
          .startOf('month')
          .subtract(1, 'days')
          .startOf('month')
      ),
      getEndDate(
        moment()
          .startOf('month')
          .subtract(1, 'days')
      )
    ]
  }
];

export const CALENDAR_VIEW_TIME_GRID_DAY = 'timeGridDay';
export const CALENDAR_VIEW_TIME_GRID_WEEK = 'timeGridWeek';
export const CALENDAR_VIEW_DAY_GRID_MONTH = 'dayGridMonth';
export const CALENDAR_VIEW_RESOURCE_TIME_GRID_DAY = 'resourceTimeGridDay';
export const CALENDAR_VIEW_RESOURCE_TIME_GRID_WEEK = 'resourceTimeGridWeek';

export const CALENDAR_VIEW_RESOURCE_TIMELINE_DAY = 'resourceTimelineDay';
export const CALENDAR_VIEW_RESOURCE_TIMELINE_CUSTOM_WEEK =
  'resourceTimelineCustomWeek';
export const CALENDAR_VIEW_RESOURCE_TIMELINE_MONTH = 'resourceTimelineMonth';
export const CALENDAR_VIEW_RESOURCE_TIMELINE_YEAR = 'resourceTimelineYear';

export const CALENDAR_RECORD_STATUS_POSSIBLE = 'possible';
export const CALENDAR_RECORD_STATUS_REJECTED = 'rejected';
export const CALENDAR_RECORD_STATUS_ACCEPTED = 'accepted';

export const SOCIAL_CREDENTIALS_TYPE_GOOGLE_CALENDAR = 'google_calendar';
