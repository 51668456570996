import React, { useCallback, useMemo } from 'react';
import { Table, Empty } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TYPE_ATTACHMENT } from 'constants/index';

import { MyDriveIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import UserCardSmall from 'components/common/user-card-small';
import VideoBtn from 'components/common/video-btn';
import Typography from 'components/common/typography';
import AttachmentActions from 'components/attachments-view/actions';
import { TagList } from 'components/common/tags';
import useOrderStatuses from 'components/attachments-view/hooks/use-order-statuses';

import formatBytes from 'utils/format-bytes';

import Entities from './components/entities';
import Content from './components/content';
import CreatedAt from './components/created-at';
import Title from './components/title';
import ValidityDate from './components/validity-date';

import styles from './table.module.scss';

export const AttachmentsTable = ({
  data,
  pagination,
  setSorting,
  isLoading,
  isTrash,
  className,
  scroll,
  locale,
  rowSelection,
  videoBtnSlug,
  actionsDeps,
  onCloseAttachmentModal,
  fileLinkTarget,
  filters,
  subscribeCallback,
  unsubscribeCallback,
  changeManageSubscribersCallback,
  changeValidityDateCallback,
  hideSorting,
  ...props
}) => {
  const { t } = useTranslation(['MyDrive', 'ValidityDate', 'Common']);

  const hasData = data.length > 0;

  const { orderStatuses } = useOrderStatuses({ data, hasData });

  const { sorting, search } = filters;

  const onChangeSorting = (_, __, sorter) =>
    setSorting({
      sortField: (sorter || {}).columnKey,
      sortDirection: (sorter || {}).order
    });

  const getSortOrderByFileld = field =>
    (sorting.sortField === field && sorting.sortDirection) || false;

  const renderAttachmentActions = useCallback(
    file => (
      <AttachmentActions
        file={file}
        actionsDeps={{ ...actionsDeps, allowDeleteFrom: false }}
        onCloseAttachmentModal={onCloseAttachmentModal}
        getPopupContainer={null}
        btnClassName={styles.actionsBtn}
        subscribeCallback={subscribeCallback}
        unsubscribeCallback={unsubscribeCallback}
        changeManageSubscribersCallback={changeManageSubscribersCallback}
        changeValidityDateCallback={changeValidityDateCallback}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionsDeps]
  );

  const columns = useMemo(
    () => [
      {
        key: 'title',
        title: t('Title'),
        width: 240,
        sortOrder: getSortOrderByFileld('title'),
        sortDirections: ['descend', 'ascend'],
        sorter: hasData && !hideSorting,
        render: file => (
          <Title
            file={file}
            isTrash={isTrash}
            fileLinkTarget={fileLinkTarget}
            actionsDeps={actionsDeps}
          />
        )
      },
      {
        key: 'content',
        title: t('Content'),
        allow: !!search && !!(data[0] || {}).highlight,
        width: 240,
        render: ({ highlight }) =>
          highlight && (highlight.content || []).length ? (
            <Content content={highlight.content} />
          ) : (
            '-'
          )
      },
      {
        key: 'entities',
        title: t('Link'),
        dataIndex: 'entities',
        render: entities => (
          <Entities items={entities} orderStatuses={orderStatuses} />
        )
      },
      {
        key: 'tags',
        title: t('Tags'),
        render: file => (
          <TagList
            entityType={TYPE_ATTACHMENT}
            entityId={file.id}
            displayedCount={1}
            shortAddButton
            allowAddTag={!isTrash}
            allowDeleteTag={!isTrash}
          />
        )
      },
      {
        key: 'creator',
        title: t('Author'),
        dataIndex: 'creator',
        sortOrder: getSortOrderByFileld('creator'),
        sortDirections: ['descend', 'ascend'],
        sorter: hasData && !hideSorting,
        render: creator => (
          <UserCardSmall asLink className={styles.creator} {...creator} />
        )
      },
      {
        key: 'created_at',
        title: t('Added'),
        width: 133,
        sortOrder: getSortOrderByFileld('created_at'),
        sortDirections: ['descend', 'ascend'],
        sorter: hasData && !hideSorting,
        render: file => <CreatedAt file={file} />
      },
      {
        key: 'validity_date',
        title: t('ExpiryDate', { ns: 'ValidityDate' }),
        width: 160,
        sortOrder: getSortOrderByFileld('validity_date'),
        sortDirections: ['descend', 'ascend'],
        sorter: hasData && !hideSorting,
        render: file => <ValidityDate file={file} />
      },
      {
        key: 'file_size',
        title: t('Size'),
        dataIndex: 'fileSize',
        width: 100,
        sorter: hasData && !hideSorting,
        sortOrder: getSortOrderByFileld('file_size'),
        sortDirections: ['descend', 'ascend'],
        render: fileSize => (
          <Typography.Text>{formatBytes(fileSize)}</Typography.Text>
        )
      },
      {
        key: 'actions',
        title: '',
        width: 60,
        allow: true,
        render: renderAttachmentActions,
        fixed: 'right'
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasData,
      search,
      data,
      orderStatuses,
      isTrash,
      fileLinkTarget,
      actionsDeps,
      onCloseAttachmentModal,
      subscribeCallback,
      unsubscribeCallback,
      changeManageSubscribersCallback,
      changeValidityDateCallback
    ]
  );

  const getAllowedColumns = () =>
    columns.filter(({ allow }) => allow !== false);

  const memoizedColumns = React.useMemo(() => getAllowedColumns(), [
    getAllowedColumns
  ]);

  const memoizedLocale = React.useMemo(
    () => ({
      emptyText: isLoading ? (
        `${t('Loading', { ns: 'Common' })}...`
      ) : (
        <Empty
          image={<Icon component={MyDriveIcon} />}
          imageStyle={{ height: 'auto', marginBottom: 10 }}
          style={{ marginTop: 14, marginBottom: 24 }}
          description={
            <>
              <Typography.Paragraph>{t('FilesNotFound')}</Typography.Paragraph>

              {!!videoBtnSlug && (
                <VideoBtn
                  slug={videoBtnSlug}
                  style={{ margin: '15px auto 0' }}
                />
              )}
            </>
          }
        />
      ),
      sortTitle: t('SortAlphabetically'),
      ...locale
    }),
    [isLoading, locale, videoBtnSlug]
  );

  return (
    <Table
      columns={memoizedColumns}
      rowKey="id"
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        pageSize: 50,
        ...pagination
      }}
      scroll={{ y: 500, x: 1100, ...scroll }}
      locale={memoizedLocale}
      loading={isLoading}
      className={classnames(styles.table, className, {
        [styles.isOpacity]: isTrash
      })}
      rowSelection={hasData ? rowSelection : null}
      onChange={onChangeSorting}
      {...props}
    />
  );
};

AttachmentsTable.propTypes = {
  allowActions: PropTypes.bool,
  filters: PropTypes.shape({
    sorting: PropTypes.shape({
      sortField: PropTypes.string,
      sortDirection: PropTypes.string
    })
  }),
  setSorting: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpacity: PropTypes.bool,
  pagination: PropTypes.object,
  className: PropTypes.string,
  getActions: PropTypes.func,
  scroll: PropTypes.object,
  locale: PropTypes.object,
  rowSelection: PropTypes.object,
  actionsDeps: PropTypes.object,
  data: PropTypes.any,
  fileLinkTarget: PropTypes.string,
  subscribeCallback: PropTypes.func,
  unsubscribeCallback: PropTypes.func,
  changeValidityDateCallback: PropTypes.func,
  hideSorting: PropTypes.bool
};

AttachmentsTable.defaultProps = {
  allowActions: true,
  filters: {
    sorting: {
      sortField: '',
      sortDirection: ''
    }
  },
  setSorting: () => {},
  getActions: () => {},
  isLoading: false,
  pagination: {},
  scroll: {},
  locale: {},
  rowSelection: undefined,
  isOpacity: false,
  className: undefined,
  actionsDeps: {},
  data: [],
  fileLinkTarget: '_self',
  subscribeCallback: () => {},
  unsubscribeCallback: () => {},
  changeValidityDateCallback: () => {},
  hideSorting: false
};

export default AttachmentsTable;
