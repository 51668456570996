import axios from 'axios';

import { config } from 'config';

export const API_CATEGORIES = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_CATERORIES_URL}`;

const api = axios.create({
  baseURL: API_CATEGORIES
});

export default () => ({
  fetch: description =>
    api.get(`/v2/predict/`, {
      params: { description }
    }),

  send: ({ body, target, classifier }) =>
    api.post(`/v1/predict/`, {
      body, // текст заказа
      target, // дочерняя категория
      classifier // родительская
    })
});
