import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { RelationsSelect } from 'components/common/controls/custom-select';

import FormItem from '../form-item';

export const FormRelationsSelect = ({
  label,
  name,
  rules,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => <RelationsSelect {...field} {...props} />}
      />
    </FormItem>
  );
};

FormRelationsSelect.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormRelationsSelect.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormRelationsSelect;
