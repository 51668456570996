import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PaymentCard from 'components/settings-view/billing/payment-card';

import Option from '../option';

import styles from '../common-option/common-option.module.scss';

export const PaymentCardOption = ({ option, className, ...props }) => (
  <Option className={classnames(className, styles.root)} {...props}>
    <PaymentCard card={option} />
  </Option>
);

PaymentCardOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    brand: PropTypes.string,
    number: PropTypes.string,
    expirationDate: PropTypes.string
  }).isRequired
};

export default PaymentCardOption;
