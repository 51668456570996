import React from 'react';

export const DeleteTagIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.043 41.6502C96.9124 41.4543 95.6058 39.7558 92.8621 36.2935C92.8621 36.2935 90.9023 33.811 88.9425 31.7859C88.8119 31.6553 88.7466 31.5899 88.6159 31.4593C88.2239 31.0673 87.832 30.6754 87.44 30.3487C87.0481 29.9568 86.5908 29.5648 86.1988 29.2382C86.0682 29.1729 86.0028 29.0422 85.8722 28.9769C85.2842 28.4543 84.6963 27.997 84.043 27.4744C83.8471 27.2784 83.5857 27.1477 83.3898 26.9517C82.6059 26.2985 81.7566 25.7105 80.842 25.1226C80.7767 25.0573 80.7114 25.0573 80.7114 24.9919C80.5807 24.9266 80.5154 24.8613 80.3847 24.796C79.7315 24.3387 79.0129 23.8814 78.3596 23.4241L78.2943 23.3588C78.229 23.2935 78.1636 23.2935 78.1636 23.2281C78.033 23.1628 77.9677 23.0975 77.837 23.0321C77.1184 22.5749 76.3345 22.1176 75.6159 21.7256C72.2189 19.8311 68.5606 18.1327 64.7717 16.7608C60.0028 15.0623 54.9727 13.8864 49.8119 13.3638C49.7466 13.3638 49.6159 13.3638 49.5506 13.3638C48.8973 13.2985 48.244 13.2332 47.5908 13.2332C47.5254 13.2332 47.3948 13.2332 47.3295 13.2332H47.2641C43.3445 12.9065 39.5556 13.1025 35.7667 13.6904C34.8521 13.8211 33.9375 14.0171 32.9576 14.2131C32.043 14.409 31.1938 14.605 30.4099 14.8663C29.5606 15.1276 28.7114 15.4543 27.9275 15.7809C17.2792 20.5497 15.5154 31.7206 13.7516 43.2834C13.425 45.5045 13.0983 47.7909 12.7064 50.012C11.8571 54.7809 10.7466 59.4844 8.65611 63.6C5.84706 72.4191 6.69631 80.1929 10.8119 85.5497C10.9425 85.6151 11.8571 86.399 12.5104 87.5095C16.4953 91.3638 22.5054 93.5849 30.2792 93.4542C36.4852 93.3889 57.5204 94.5648 75.2893 88.6201C75.2893 88.6201 77.9023 87.7708 81.2993 86.2683C93.5807 80.7809 96.6511 75.2281 97.239 73.203C97.5003 72.2231 97.5003 71.4392 97.5003 71.4392C97.3043 69.3487 95.3445 68.1075 94.7566 62.8161C93.7114 52.4945 100.375 47.399 97.043 41.6502Z"
      fill="#EBF8F8"
    />
    <path
      d="M63.0758 70.6674L57.0452 86.0633C56.5485 87.2695 55.2005 87.8371 53.9944 87.4114L23.6281 75.35C22.422 74.8534 21.8544 73.5053 22.2801 72.2992L28.8783 55.6971L31.2906 58.0384L35.1928 61.7278L43.5648 59.0317L47.3251 64.211L56.1228 60.8764L60.8764 67.6875L63.0758 70.6674Z"
      fill="#2D9EA3"
    />
    <path
      d="M47.396 64.211L43.5648 59.0317L35.1218 61.6568L31.2196 57.9675L26.3241 70.3126C26.1113 70.8093 26.1113 71.3769 26.3241 71.8735C26.3241 71.9445 26.3951 72.0154 26.3951 72.0154C26.466 72.1573 26.537 72.2992 26.6789 72.4411C26.8208 72.583 27.0336 72.7249 27.2465 72.8668C27.3174 72.8668 27.3884 72.9377 27.3884 72.9377L52.7882 82.9416C52.8591 82.9416 52.9301 83.0125 52.9301 83.0125C53.1429 83.0835 53.3558 83.0835 53.5686 83.0835C53.7105 83.0835 53.9234 83.0125 54.0653 83.0125C54.1362 83.0125 54.2072 82.9416 54.2781 82.9416C54.7747 82.7287 55.1295 82.303 55.3423 81.8064L60.9473 67.6875L56.1937 60.8764L47.396 64.211Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M81.9483 41.0815L74.1439 29.1621V29.0911L72.9377 27.2464V27.1755L70.3836 23.2733C69.816 22.4219 68.8227 22.0672 67.8294 22.28L67.5456 22.351L65.559 22.8476L65.4171 22.9185L47.3251 27.3174H47.2541L38.9531 48.2474L41.3653 50.5887L45.2675 54.2781L53.7105 51.653L57.4708 56.8323L66.2685 53.4267L71.0221 60.2378L73.1506 63.2886L81.9483 41.0815ZM67.8294 31.2196C67.4746 32.1419 66.4104 32.6386 65.4171 32.2838C64.4948 31.9291 63.9981 30.8649 64.4238 29.8716C64.7786 28.9492 65.8428 28.4526 66.7651 28.8073C67.6875 29.233 68.1841 30.2973 67.8294 31.2196Z"
      fill="#2D9EA3"
    />
    <path
      d="M78.2589 41.9329L71.7316 31.4324L70.6674 29.7296L68.8227 26.7498C68.5389 26.3241 68.0422 25.9693 67.5456 25.8984C67.4747 25.8984 67.4037 25.8984 67.4037 25.8984C67.3328 25.8984 67.3328 25.8984 67.2618 25.8984C67.2618 25.8984 67.2618 25.8984 67.1909 25.8984C67.1199 25.8984 67.049 25.8984 66.978 25.8984C66.9071 25.8984 66.8361 25.8984 66.8361 25.8984H66.7652H66.6942L65.4171 26.2531L65.1333 26.3241L49.4536 30.3682H49.3826L41.3654 50.5177L45.2676 54.2071L53.7105 51.582L57.4708 56.7613L66.2685 53.4267L71.0221 60.2378L78.2589 41.9329ZM65.4171 32.2129C64.4948 31.8581 63.9981 30.7939 64.4238 29.8006C64.7786 28.8782 65.8428 28.3816 66.7652 28.8073C67.6875 29.162 68.1841 30.2263 67.7584 31.2196C67.4037 32.1419 66.3395 32.6385 65.4171 32.2129Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M74.1439 29.0911L72.9378 27.2464V27.1755C76.6271 22.7766 78.4009 18.5197 77.3366 16.3203C76.4852 14.6175 75.1372 13.9789 75.1372 13.9789C74.9953 13.908 73.4344 13.1985 71.7316 13.908C68.8937 15.1141 68.2551 19.6549 67.6875 22.3509C67.6875 22.4219 67.6875 22.4928 67.6875 22.5638C67.5456 23.5571 67.4037 24.6923 67.3328 25.8984C67.3328 25.9694 67.3328 26.1113 67.3328 26.1822C67.2618 27.2464 67.2618 28.3816 67.2618 29.5878C67.2618 29.7297 67.2618 29.8716 67.2618 30.0135C67.2618 30.581 66.8361 31.0777 66.1976 31.0777C66.0557 31.0777 65.9138 31.0777 65.7719 31.0067C65.4172 30.8648 65.1334 30.5101 65.1334 30.0135C65.1334 29.7297 65.1334 29.3749 65.1334 29.0911C65.1334 28.2397 65.1334 27.3883 65.2043 26.5369C65.2043 26.466 65.2043 26.395 65.2043 26.3241C65.2753 25.1889 65.3462 24.1247 65.4881 23.0604C65.4881 22.9895 65.4881 22.9185 65.4881 22.8476C66.2685 16.8169 68.3261 12.7728 71.164 11.8505C73.8601 10.9991 75.9886 11.9214 76.0595 11.9924C76.1305 11.9924 78.1171 12.9147 79.3232 15.327C80.7422 18.5197 78.7556 23.699 74.1439 29.0911Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.9589 55.2004L79.678 60.6635C79.0394 60.9473 79.1813 61.9406 79.8908 62.0825L82.0193 62.3663C82.6578 62.4372 82.8707 63.2177 82.374 63.6434L74.2858 70.5254C73.6473 71.093 74.3568 72.1573 75.1372 71.7316L90.5332 63.7143C91.1717 63.3596 91.0298 62.4372 90.3203 62.2953L87.9081 61.9406C87.3405 61.8696 87.0567 61.0892 87.4824 60.6635L91.8812 56.3356C92.4488 55.8389 91.7393 54.8457 90.9589 55.2004Z"
      fill="#FCC977"
    />
    <path
      d="M75.2081 78.3298C74.7824 78.3298 74.4277 78.117 74.2148 77.6913C74.002 77.1237 74.2148 76.5561 74.7115 76.2723L82.1611 72.7958C82.7287 72.583 83.2963 72.7958 83.5801 73.2925C83.793 73.86 83.5801 74.4276 83.0835 74.7114L75.6338 78.1879C75.4919 78.2589 75.35 78.3298 75.2081 78.3298Z"
      fill="white"
    />
    <path
      d="M31.7163 52.5043C31.2906 52.5043 30.8649 52.2205 30.723 51.7948L26.1822 37.7469C25.9694 37.1793 26.3241 36.6117 26.8917 36.3989C27.4593 36.186 28.0269 36.5408 28.2398 37.1084L32.7805 51.1563C32.9934 51.7239 32.6386 52.2915 32.071 52.5043C31.9291 52.5043 31.8582 52.5043 31.7163 52.5043Z"
      fill="white"
    />
    <path
      d="M24.9051 50.3049C24.6214 50.3049 24.2666 50.163 24.0538 49.9502L18.2359 42.9972C17.8812 42.5715 17.9521 41.862 18.3778 41.5072C18.8035 41.1525 19.513 41.2234 19.8678 41.6491L25.6856 48.6021C26.0403 49.0278 25.9694 49.7373 25.5437 50.0921C25.4018 50.234 25.118 50.3049 24.9051 50.3049Z"
      fill="white"
    />
    <path
      d="M60.1841 34.3381L49.9808 18.2979C48.788 16.4228 46.051 16.4228 44.8582 18.2979L34.6549 34.338C33.3692 36.3589 34.821 39.003 37.2161 39.003H57.6228C60.0179 39.003 61.4696 36.3589 60.1841 34.3381Z"
      fill="#F9716C"
    />
    <path
      d="M39.2403 34.3387L49.4437 18.2987C49.526 18.1693 49.6162 18.0498 49.7123 17.9382C48.4171 16.4335 45.9686 16.553 44.8582 18.2987L34.6549 34.3387C33.3692 36.3596 34.821 39.0036 37.2161 39.0036H41.8016C39.4065 39.0036 37.9548 36.3596 39.2403 34.3387Z"
      fill="#F7413B"
    />
    <path
      d="M47.9653 30.6781H46.8737C46.2973 30.6781 45.8257 30.2065 45.8257 29.6301V23.0803C45.8257 22.5039 46.2973 22.0323 46.8737 22.0323H47.9653C48.5417 22.0323 49.0133 22.5039 49.0133 23.0803V29.6301C49.0133 30.2065 48.5417 30.6781 47.9653 30.6781Z"
      fill="white"
    />
    <path
      d="M47.4195 36.3377C48.2997 36.3377 49.0133 35.6242 49.0133 34.744C49.0133 33.8638 48.2997 33.1502 47.4195 33.1502C46.5393 33.1502 45.8257 33.8638 45.8257 34.744C45.8257 35.6242 46.5393 36.3377 47.4195 36.3377Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="65.2731"
        y1="43.185"
        x2="38.8981"
        y2="77.685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="63.7731"
        y1="25.8984"
        x2="36.5231"
        y2="77.3099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeleteTagIcon;
