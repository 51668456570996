import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';
import { RRule } from 'rrule';

import {
  RRULE_SCHEDULER_TYPES,
  TYPE_TASK,
  TYPE_TEMPLATE
} from 'constants/index';

import {
  fetchEmployeeRecords,
  fetchOneEmployeeRecord,
  getEmployeeCalendarRecords
} from 'store/calendar';

const fromMillisecondsToDay = value => Math.round(value / 1000 / 60 / 60 / 24);

const checkDates = (currentDates, newDates) => {
  const { start: currentDateStart, end: currentDateEnd } = currentDates;
  const { start: newDateStart, end: newDateEnd } = newDates;

  return (
    moment(newDateStart).isBefore(currentDateStart) ||
    moment(newDateEnd).isAfter(currentDateEnd)
  );
};

const calculateDates = newDates => {
  const { start: newDateStart, end: newDateEnd } = newDates;

  const isDay =
    fromMillisecondsToDay(moment(newDateEnd).diff(newDateStart)) === 1;
  const isWeek =
    fromMillisecondsToDay(moment(newDateEnd).diff(newDateStart)) === 7;

  const additionalDays = isDay || isWeek ? 7 : 0;

  return {
    dateStart: moment(newDateStart)
      .add(-additionalDays, 'days')
      .toDate(),
    dateEnd: moment(newDateEnd)
      .add(additionalDays, 'days')
      .toDate()
  };
};

const generateSchedulerRecords = ({ records, currentDates }) => {
  if (!currentDates) {
    return records;
  }

  const { start, end } = currentDates;
  const templateSchedulerRecords = records.filter(
    r =>
      r.relation.type === TYPE_TEMPLATE && r.scheduler && !r.recurringRecordId
  );

  const result = records.filter(
    r => r.relation.type === TYPE_TASK || r.recurringRecordId
  );

  templateSchedulerRecords.forEach(templateRecord => {
    const {
      dateStart,
      dateEnd,
      freqInterval,
      weekdays,
      freqType,
      until
    } = templateRecord.scheduler;

    const rruleFreq = RRULE_SCHEDULER_TYPES[freqType];
    const rruleWeekdays =
      weekdays &&
      weekdays.map(wd => {
        const startsWithNumberPattern = /^\d+/g;

        if (startsWithNumberPattern.test(wd)) {
          const [number] = wd.match(startsWithNumberPattern);
          const newWd = wd.replace(number, '');

          return RRULE_SCHEDULER_TYPES[newWd].nth(number);
        }

        return RRULE_SCHEDULER_TYPES[wd];
      });
    const rruleUntil = until && moment(until).isBefore(end) ? until : end;

    const ruleEndDate = new RRule({
      freq: rruleFreq,
      interval: freqInterval,
      wkst: RRule.MO,
      byweekday: rruleWeekdays,
      dtstart: moment(dateEnd).toDate(),
      until: moment(rruleUntil).toDate()
    });

    const dates = ruleEndDate
      .between(moment(start).toDate(), moment(end).toDate())
      .map(endDate => {
        const dateInterval = moment(dateEnd).diff(dateStart, 'days');

        return {
          dateStart: moment(
            moment(endDate)
              .add(-dateInterval, 'days')
              .toDate()
              .setHours(
                moment(dateStart).get('hours'),
                moment(dateStart).get('minutes')
              )
          ).toDate(),
          dateEnd: endDate
        };
      });

    const datesWithoutRecords = dates.reduce((acc, curr) => {
      if (
        !result.find(
          r =>
            moment(r.dateEnd).isSame(curr.dateEnd, 'date') &&
            moment(r.dateStart).isSame(curr.dateStart, 'date') &&
            r.relation.templateId === templateRecord.relation.id &&
            (r.relation.type === TYPE_TASK || r.recurringRecordId)
        )
      ) {
        acc.push(curr);
      }

      return acc;
    }, []);

    result.push(...datesWithoutRecords.map(d => ({ ...templateRecord, ...d })));
  });

  return result;
};

const transformRecords = ({ records, currentDates }) => {
  const result = generateSchedulerRecords({ records, currentDates });

  return result.map(r => ({
    ...r,
    resourceId: r.employee.id,
    start: moment(r.dateStart).toDate(),
    end: moment(r.dateEnd).toDate()
  }));
};

export const useEmployeeRecords = ({
  dates,
  employees = [],
  gettingDataFromStore = false,
  parentTaskId,
  recordId
}) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [openedRecord, setOpenedRecord] = useState(null);

  const [currentDates, setCurrentDates] = useState(null);
  const [currentEmployees, setCurrentEmployees] = useState(employees);

  const storeRecords = useSelector(getEmployeeCalendarRecords);

  const transformedRecords = useMemo(
    () =>
      transformRecords({
        records: gettingDataFromStore ? storeRecords : records,
        currentDates
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [records, storeRecords]
  );

  const onClickRecord = ({ event }) => {
    const { id, title, extendedProps } = event;

    setOpenedRecord({ id, title, ...extendedProps });
  };

  const fetchRecords = useCallback(async () => {
    try {
      setIsLoading(true);

      const isEmployeesChanged = !isEqual(currentEmployees, employees);

      const { dateStart, dateEnd } = calculateDates(dates);
      setCurrentDates({ start: dateStart, end: dateEnd });

      if (isEmployeesChanged) {
        setCurrentEmployees(employees);
      }

      const { results } = await dispatch(
        fetchEmployeeRecords({
          params: {
            dateStart,
            dateEnd,
            employees,
            excludeRecordObjectId: parentTaskId
          },
          setDataToStore: gettingDataFromStore
        })
      );

      if (!gettingDataFromStore) {
        setRecords(results);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentEmployees,
    dates,
    dispatch,
    employees,
    gettingDataFromStore,
    parentTaskId
  ]);

  const updateRecordInList = useCallback(
    async id => {
      const fetchedRecord = await dispatch(fetchOneEmployeeRecord({ id }));

      if (!gettingDataFromStore) {
        setRecords(prev => {
          const recordList = [...prev];

          const index = recordList.findIndex(
            record => record.id === fetchedRecord.id
          );

          if (index !== -1) {
            recordList[index] = fetchedRecord;
          } else {
            recordList.push(fetchedRecord);
          }

          return recordList;
        });
      }

      return fetchedRecord;
    },
    [dispatch, gettingDataFromStore]
  );

  useEffect(() => {
    if (
      dates &&
      currentDates &&
      (checkDates(currentDates, dates) || !isEqual(currentEmployees, employees))
    ) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, employees]);

  useEffect(() => {
    if (!currentDates && dates) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDates, dates]);

  useEffect(() => {
    if (currentDates) {
      setCurrentDates(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (recordId) {
      setOpenedRecord({ id: recordId });
    }
  }, [recordId]);

  return {
    isLoading,
    records: transformedRecords,
    openedRecord: {
      data: openedRecord,
      visible: !!openedRecord,
      updateRecordInList,
      fetchRecords,
      onClose: () => setOpenedRecord(null)
    },
    onClickRecord
  };
};

export default useEmployeeRecords;
