import React from 'react';

export const LogoCircleIcon = ({ style = { width: 38, height: 38 } }) => (
  <svg
    style={style}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6943 17.9052C9.63876 18.6444 9.35592 20.0618 10.0626 21.0711L14.4868 27.3905C14.5458 27.4747 14.6098 27.5534 14.6782 27.6265C14.8819 27.9653 15.1764 28.2622 15.5496 28.4777C16.6601 29.1187 18.0469 28.7954 18.6474 27.7556L27.5381 12.3597C28.1387 11.3197 27.7251 9.95659 26.6143 9.31543C25.5039 8.67443 24.117 8.99767 23.5166 10.0375L16.5188 22.1553L13.8858 18.3944C13.179 17.3849 11.7501 17.1659 10.6943 17.9052Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="50.2949"
        y1="16.1311"
        x2="31.3663"
        y2="-13.3043"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoCircleIcon;
