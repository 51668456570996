import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  DATE_TIME_FORMAT,
  STATUS_DONE,
  TASK_FIELD_COMPLETED_AT,
  TASK_FIELD_CREATED_AT,
  TASK_FIELD_DATE_END,
  TASK_FIELD_DATE_START
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import EditorDatesModal from 'components/tasks-view/view/drawers/editor/editor-dates-modal';
import { checkChangedDates } from 'components/tasks-view/utils';

import { FieldEditorItem } from 'providers/field-editor';
import { getIsTaskOutdated } from 'utils/get-is-outdated';

import styles from './dates.module.scss';

const { Text } = Typography;

const Dates = ({ taskValues, className, isStyledOutdated }) => {
  const { t } = useTranslation(['Task', 'Tasks']);

  const dispatch = useDispatch();

  const [visibleEditorDatesModal, setVisibleEditorDatesModal] = useState(false);

  const getDates = () => {
    const { dateStart, dateEnd, createdAt, completedAt, status } = taskValues;

    const isDoneStatus = status === STATUS_DONE;

    return [
      {
        label: t('TaskCreatedDate'),
        field: TASK_FIELD_CREATED_AT,
        value: createdAt
      },
      {
        label: t('TaskStartDate', { ns: 'Tasks' }),
        value: dateStart,
        field: TASK_FIELD_DATE_START
      },
      {
        label: t('TaskDueDate'),
        value: dateEnd,
        field: TASK_FIELD_DATE_END,
        isOutdated: getIsTaskOutdated(taskValues)
      },
      {
        label: t('TaskDoneDate'),
        value: completedAt,
        field: TASK_FIELD_COMPLETED_AT,
        allow: isDoneStatus
      }
    ].filter(({ allow }) => allow !== false);
  };

  const dates = getDates();

  return (
    <div className={className} data-qa="qa-f9m9d8e0ioyspjs">
      {dates.map(({ label, field, value, isOutdated }) => (
        <FieldEditorItem
          key={`${label}-${value}`}
          field={field}
          defaultValues={{
            [field]: value && moment(value).toDate()
          }}
          className={styles.dateWrap}
          saveCallback={values =>
            checkChangedDates({
              dispatch,
              task: taskValues,
              setVisibleModal: setVisibleEditorDatesModal,
              dateStart: values.dateStart,
              dateEnd: values.dateEnd
            })
          }
        >
          {value && (
            <DetailsField
              iconType="calendar"
              title={label}
              className={styles.date}
            >
              <Text
                color={isOutdated && isStyledOutdated ? 'red-70' : 'black'}
                data-qa="qa-00fw2x6tp5vmke1"
              >
                {moment(value).format(DATE_TIME_FORMAT)}
              </Text>
            </DetailsField>
          )}
        </FieldEditorItem>
      ))}

      <EditorDatesModal
        visible={visibleEditorDatesModal}
        onClose={() => setVisibleEditorDatesModal(false)}
        params={{
          id: taskValues.id,
          dateStart: taskValues.dateStart,
          dateEnd: taskValues.dateEnd
        }}
      />
    </div>
  );
};

Dates.propTypes = {
  taskValues: PropTypes.shape({
    dateEnd: PropTypes.oneOfType([PropTypes.string, Date]),
    dateStart: PropTypes.oneOfType([PropTypes.string, Date]),
    createdAt: PropTypes.oneOfType([PropTypes.string, Date]),
    completedAt: PropTypes.oneOfType([PropTypes.string, Date]),
    status: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  isStyledOutdated: PropTypes.bool
};

Dates.defaultProps = {
  className: undefined,
  isStyledOutdated: true
};

export default Dates;
