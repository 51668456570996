import { useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SERVICE_UNAVAILABLE_MODAL, WHATSAPP } from 'constants/index';

import { useModalContext } from 'components/app/modals-provider';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';
import Typography from 'components/common/typography';

import { getActiveWorkspace } from 'store/workspace';

import useRoutesService from 'services/routes';

import styles from './service-unavailable.module.scss';

const { Text } = Typography;

export const useServiceUnavailable = () => {
  const { showModal, onCloseModal } = useModalContext();
  const { t } = useTranslation('ChangePlan');
  const routes = useRoutesService();

  const {
    account: { tariff, addons, admin },
    user
  } = useSelector(getActiveWorkspace);

  const checkServiceAvailability = () => {
    const hasPaymentAccess = checkPaymentAccessByRoles(user.roles);
    const unoccupiedAddon = addons.find(
      ({ type, isEmpty }) => type === WHATSAPP && isEmpty
    );

    if (!hasPaymentAccess && (!unoccupiedAddon || tariff.isDefault)) {
      showModal(SERVICE_UNAVAILABLE_MODAL, {
        title: t('WhatsAppNumberNotConnectedHeading'),
        description: (
          <>
            <Text color="black-45">{t('WhatsAppNumberNotConnectedDesc')}</Text>{' '}
            <Text
              className={styles.text}
              onClick={() => {
                onCloseModal();
                routes.toUserWorkspace({ employeeId: admin.id });
              }}
              color="brand"
              weight="semibold"
            >
              {t('CompanyAdminLink')}
            </Text>
          </>
        )
      });

      return false;
    }

    if (tariff.isDefault) {
      showModal(SERVICE_UNAVAILABLE_MODAL, {
        title: t('ChangePlanToPayHeading'),
        description: <Text color="black-45">{t('ChangePlanToPayDesc')}</Text>,
        buttonText: t('ChangePlanBtn')
      });

      return false;
    }

    if (!unoccupiedAddon) {
      showModal(SERVICE_UNAVAILABLE_MODAL, {
        title: t('WhatsAppNumberNotConnectedHeading'),
        description: (
          <Text color="black-45">
            {t('WhatsAppNumberNotConnectedAvailableDesc')}
          </Text>
        ),
        buttonText: t('GoToProfileBtn')
      });

      return false;
    }

    return true;
  };

  return {
    checkServiceAvailability
  };
};

export default useServiceUnavailable;
