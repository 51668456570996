import React from 'react';

import SkeletonButton from '../button';
import SkeletonInput from '../input';

import styles from './entity-creation.module.scss';

export const SkeletonEntityCreation = () => (
  <div className={styles.root}>
    <SkeletonInput />

    <SkeletonInput height={195} />

    <div className={styles.twoFields}>
      <SkeletonInput />

      <SkeletonInput />
    </div>

    <SkeletonInput />

    <SkeletonInput />

    <div className={styles.buttonWrap}>
      <SkeletonButton className={styles.button} />
    </div>
  </div>
);

export default SkeletonEntityCreation;
