import React from 'react';

export const PointerIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3751 10.7501H19.2054C19.0822 10.7501 18.9644 10.8037 18.8813 10.8974L13.0179 17.6555V2.57244C13.0179 2.45458 12.9215 2.35815 12.8037 2.35815H11.1965C11.0787 2.35815 10.9822 2.45458 10.9822 2.57244V17.6555L5.11883 10.8974C5.03848 10.8037 4.92062 10.7501 4.79473 10.7501H2.62508C2.44294 10.7501 2.34383 10.9671 2.46437 11.1037L11.3545 21.3492C11.4349 21.4419 11.5343 21.5163 11.6459 21.5672C11.7575 21.6182 11.8787 21.6446 12.0014 21.6446C12.1241 21.6446 12.2454 21.6182 12.357 21.5672C12.4686 21.5163 12.5679 21.4419 12.6483 21.3492L21.5358 11.1037C21.6563 10.9644 21.5572 10.7501 21.3751 10.7501Z"
      fill="currentColor"
    />
  </svg>
);

export default PointerIcon;
