import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';
import DebounceInput from 'components/common/controls/debounce-input';
import SkeletonCard from 'components/common/skeleton-card';
import { Error404Icon } from 'components/common/icons';
import Typography from 'components/common/typography';

import MessageTemplatesItem from './item';
import { MESSAGE_TEMPLATES_CREATE } from '../constants';

import styles from './components.module.scss';

const MessageTemplatesList = ({
  templates,
  setSelectedComponent,
  setTemplate,
  addTextToEditor,
  onClose,
  isLoading,
  searchProps
}) => {
  const { t } = useTranslation(['AnswerTemplates', 'Common']);

  return (
    <>
      <DebounceInput
        ref={input => input && input.focus()}
        autoFocus
        setValue={searchProps.setSearch}
        value={searchProps.search}
        minCharsToSearch={2}
        placeholder={t('Search', { ns: 'Common' })}
      />

      <Button
        type="text"
        className={styles.createBtn}
        onClick={() => setSelectedComponent(MESSAGE_TEMPLATES_CREATE)}
      >
        <Icon type="plus" />
        {t('AddNewAnswerTemplateBtn')}
      </Button>

      {isLoading && <SkeletonCard count={3} paragraph={{ rows: 1 }} />}

      {!isLoading && templates.length ? (
        <div className={styles.list}>
          {templates.map(template => (
            <div key={template.id} className={styles.item}>
              <MessageTemplatesItem
                template={template}
                setSelectedComponent={setSelectedComponent}
                setTemplate={setTemplate}
                addTextToEditor={addTextToEditor}
                onClose={onClose}
              />
            </div>
          ))}
        </div>
      ) : null}

      {!isLoading && !templates.length && (
        <div className={styles.empty}>
          <Icon component={Error404Icon} />

          <Typography.Title className={styles.title}>
            {t('TemplatesNotFound')}
          </Typography.Title>

          <Typography.Paragraph className={styles.description} color="black-45">
            {t('TemplatesNotFoundDesc')}
          </Typography.Paragraph>
        </div>
      )}
    </>
  );
};

MessageTemplatesList.propTypes = {
  templates: PropTypes.array,
  setSelectedComponent: PropTypes.func,
  setTemplate: PropTypes.func,
  addTextToEditor: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  searchProps: PropTypes.shape({
    search: PropTypes.string,
    setSearch: PropTypes.func
  })
};

MessageTemplatesList.defaultProps = {
  templates: [],
  setSelectedComponent: () => {},
  setTemplate: () => {},
  addTextToEditor: () => {},
  onClose: () => {},
  isLoading: false,
  searchProps: {}
};

export default MessageTemplatesList;
