import React from 'react';

export const TelegramIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53851_4894)">
      <path
        d="M12.9993 0C13.8256 0 14.5323 0.293412 15.1189 0.880127C15.7056 1.46684 15.999 2.17363 15.999 2.99998V12.9999C15.999 13.8262 15.7056 14.5329 15.1188 15.1198C14.5322 15.7066 13.8255 16 12.9992 16H2.99925C2.17294 16 1.46622 15.7066 0.879396 15.1198C0.29268 14.5329 -0.000732422 13.8262 -0.000732422 12.9999V2.99998C-0.000732422 2.17363 0.29268 1.46695 0.879396 0.880127C1.46622 0.293412 2.17294 0 2.99925 0H12.9993Z"
        fill="#269EDA"
      />
      <path
        d="M6.31881 9.35744L6.14237 11.7399C6.39482 11.7399 6.50415 11.6358 6.63527 11.5108L7.81885 10.425L10.2714 12.1491C10.7211 12.3897 11.038 12.263 11.1594 11.7519L12.7692 4.51053L12.7696 4.5101C12.9123 3.87181 12.5292 3.62221 12.091 3.7788L2.6285 7.25654C1.98271 7.49718 1.99249 7.84278 2.51872 7.99936L4.93789 8.72171L10.5571 5.34636C10.8216 5.17826 11.062 5.27127 10.8643 5.43938L6.31881 9.35744Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_53851_4894">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TelegramIcon;
