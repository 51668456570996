import { original } from 'immer';

import { combineActionsThunk } from '../actions-thunk';
import {
  fetchChatsByStatusId,
  fetchMessages,
  fetchChat,
  sendReadAck,
  setMessageWithEvent,
  clearCommands,
  setOpenChat,
  sendMessage
} from './actions';
import chatReducer from './chat-reducer';
import {
  NEW_CHAT_MESSAGE,
  CHAT_MESSAGE_READ,
  NEW_ORDER_CHAT,
  CHAT_UPDATED
} from '../../constants';
import handleActions from '../../utils/redux-actions';
import { createDeal } from '../order-statuses/actions';

const initialState = {
  byStatusId: {},
  entries: {}
};

export default handleActions(
  {
    // Используется chatId
    [combineActionsThunk(
      fetchMessages, // args.chatId
      sendReadAck, // args.??
      // TODO: проверить setMessageWithEvent, clearCommands
      setMessageWithEvent,
      clearCommands,
      NEW_CHAT_MESSAGE, // payload.chatId
      CHAT_MESSAGE_READ, // payload.chatId
      sendMessage,
      setOpenChat
    )]: (state, action) => {
      const chatId =
        (action.args && action.args.chatId) || action.payload.chatId;

      if (!chatId) {
        return state;
      }

      Object.keys(state.entries).forEach(key => {
        if (state.entries[key].id === chatId) {
          state.entries[key] = chatReducer(state.entries[key], action);
        }
      });

      return state;
    },

    // Используется chatStatusId
    [fetchChat]: (state, action) => {
      const statusChatId = action.args && action.args.chatId;

      if (!statusChatId) {
        return state;
      }

      state.entries[statusChatId] = chatReducer(
        state.entries[statusChatId],
        action
      );

      return state;
    },

    [fetchChat.START]: (state, action) => {
      const { args } = action;

      state.entries[args.chatId] = chatReducer(undefined, action);

      return state;
    },

    [fetchChat.SUCCEEDED]: (state, { payload }) => {
      const { statusId } = payload;

      state.byStatusId[statusId] = state.byStatusId[statusId] || {};
      const chatIds = state.byStatusId[statusId].chatIds || [];
      state.byStatusId[statusId].chatIds = Array.from(
        new Set([...chatIds, payload.id])
      );

      state.byStatusId[statusId].isLoading = false;
      state.byStatusId[statusId].isLoaded = true;

      return state;
    },

    [createDeal.SUCCEEDED]: (state, { payload }) => {
      if (state.byStatusId[payload.statusId]) {
        state.byStatusId[payload.statusId].chatIds = [payload.chatId];
        state.byStatusId[payload.statusId].totalItems = 1;
      }

      return state;
    },

    // Добавление отклика
    [CHAT_UPDATED]: (state, { payload }) => {
      const { statusId, chatId } = payload;

      if (!original(state.byStatusId[statusId])) {
        state.byStatusId[statusId] = {
          chatIds: [chatId],
          isLoaded: true,
          isLoading: false
        };

        if (!original(state.entries[chatId])) {
          state.entries[chatId] = {
            id: chatId,
            isLoading: false,
            isLoaded: false,
            messages: [],
            ...payload
          };
        }

        return state;
      }

      const chatIds = state.byStatusId[statusId].chatIds || [];

      state.byStatusId[payload.statusId].chatIds = Array.from(
        new Set([...chatIds, chatId])
      );

      if (!original(state.entries[chatId])) {
        state.entries[chatId] = {
          id: chatId,
          isLoading: false,
          isLoaded: false,
          messages: [],
          ...payload
        };
      }

      return state;
    },

    // Убираем isOpened из чатов, которые не открыты
    [setOpenChat]: (state, { payload }) => {
      Object.keys(state.entries).forEach(key => {
        if (state.entries[key].id !== payload.chatId) {
          state.entries[key].isOpened = false;
        }
      });

      return state;
    },

    [fetchChatsByStatusId.START]: (state, { args }) => {
      state.byStatusId[args.statusId] = state.byStatusId[args.statusId] || {};
      state.byStatusId[args.statusId].isLoading = true;

      return state;
    },

    [combineActionsThunk(
      fetchChatsByStatusId.SUCCEEDED,
      fetchChat.SUCCEEDED
    )]: (state, { payload, args }) => {
      const { results = [] } = payload;

      const chatInitial = chatReducer(undefined, {});
      const chatConfig = state.byStatusId[args.statusId] || {};
      const oldChatIds = chatConfig.chatIds || [];
      const newChatIds = Array.from(
        new Set([...oldChatIds, ...results.map(({ id }) => id)])
      );

      chatConfig.chatIds = newChatIds;
      // Если count нету, тогда делаем инкримент (fetchChat)
      chatConfig.totalItems = payload.count
        ? payload.count
        : chatConfig.totalItems + 1;
      chatConfig.isLoading = false;
      chatConfig.isLoaded = true;

      results.forEach(chat => {
        state.entries[chat.id] = {
          ...chatInitial,
          ...chat.chat,

          messageNewCount: chat.messageNewCount,
          // Отвечает за твое последнее прочитанное сообщение
          messageRecent: chat.messageRecentId,
          // Отвечает за последнее прочитанное сообщение собеседником
          counterpartMessageRecentId: chat.counterpartMessageRecentId,
          response: chat.response,

          hasMore: true,
          isLoaded: true,
          isLoading: false
        };
      });

      return state;
    },

    [NEW_ORDER_CHAT]: (state, { payload }) => {
      state.byStatusId[payload.statusId] =
        state.byStatusId[payload.statusId] || {};
      state.byStatusId[payload.statusId].chatIds = [
        payload.id,
        ...(state.byStatusId[payload.statusId].chatIds || [])
      ];
      state.byStatusId[payload.statusId].totalItems += 1;

      state.entries[payload.id] = {
        ...payload,
        ...payload.chat,
        messages: [],
        hasMore: true,
        isLoaded: true,
        isLoading: false
      };

      return state;
    }
  },
  initialState
);
