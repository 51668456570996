import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GPT_FILTER_BY_ENTITY_OPTIONS } from 'constants/index';

import CustomSelect from 'components/common/controls/custom-select';

import { getGptFilters, setFilteredDialogsByEntity } from 'store/gpt';

import styles from './filter-by-entity.module.scss';

const FilterByEntity = () => {
  const dispatch = useDispatch();

  const { entityType } = useSelector(getGptFilters);

  const setFilteredEntityType = value =>
    dispatch(setFilteredDialogsByEntity(value));

  return (
    <CustomSelect
      rootClassName={styles.root}
      className={styles.select}
      popoverOverlayClassName={styles.overlay}
      onChange={setFilteredEntityType}
      options={GPT_FILTER_BY_ENTITY_OPTIONS}
      value={entityType}
    />
  );
};

export default FilterByEntity;
