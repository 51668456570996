import React from 'react';

export const MyDriveIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.6999 90.1C62.5999 90.2 61.4999 90.2 60.3999 90.2C55.0999 90.1 49.4999 90.3 44.1999 90.3C43.8999 90.3 43.6999 90.3 43.3999 90.3C39.7999 90.4 36.3999 90.4 33.0999 90.2C31.8999 90.1 30.8999 90.1 29.7999 90C18.8999 89 11.6999 85.3 14.4999 73.9C14.6999 72.9 14.7999 72 14.6999 70.9C14.3999 66.6 11.7999 61.6 9.79992 56.5C9.29992 55.3 8.89992 54.1 8.49992 52.8C8.09992 51.3 7.69992 49.9 7.49992 48.5C7.09992 46.1 7.29992 43.6 7.99992 41.2C7.99992 41.2 8.79992 38.8 10.5999 36.5C12.7999 33.7 38.1999 7.09999 68.0999 17.3C69.2999 17.7 98.6999 28.2 99.1999 52.5C99.5999 76.2 72.2999 89.7 63.6999 90.1Z"
      fill="#EBF8F8"
    />
    <path
      d="M79.7999 40.9V67.5C79.7999 74.5 74.0999 80.3 66.9999 80.3H57.9999C50.9999 80.3 45.1999 74.6 45.1999 67.5V40.9C45.1999 36.1 47.8999 31.9 51.7999 29.8H73.1999C73.2999 29.8 73.2999 29.9 73.2999 29.9C77.1999 32 79.7999 36.2 79.7999 40.9Z"
      fill="#F0F0F0"
    />
    <path
      d="M69.8999 54.9L63.5999 53.5V41.4C63.5999 37.3 65.6999 33.7 68.8999 31.6C70.9999 30.3 72.7999 30.1 73.1999 30C72.3999 30.6 65.6999 36.8 69.8999 54.9Z"
      fill="#C4C4C4"
    />
    <path
      d="M72.8999 50.1V85.6C72.8999 88.3 70.7999 90.6 68.2999 90.6H23.0999L14.2999 77.5V50.1C14.2999 47.4 16.3999 45.1 18.8999 45.1H68.1999C70.7999 45.1 72.8999 47.4 72.8999 50.1Z"
      fill="#FDA402"
    />
    <path
      d="M73.2999 29.8C73.2999 29.9 73.1999 29.9 73.2999 29.8C72.7999 30 70.9999 30.2 68.8999 31.5C65.6999 33.6 63.5999 37.2 63.5999 41.3V85.4C63.5999 87.2 63.9999 89 64.7999 90.6H25.8999V41.4C25.8999 35 31.0999 29.7 37.5999 29.7C45.0999 29.5 51.1999 29.5 56.0999 29.4C63.5999 29.3 68.1999 29.3 70.7999 29.5C72.5999 29.6 73.2999 29.7 73.2999 29.8Z"
      fill="white"
    />
    <path
      d="M55.9999 62.7L39.8999 67C38.8999 67.2 37.9999 66.7 37.6999 65.7L34.9999 55.4C34.7999 54.4 35.2999 53.5 36.2999 53.2L52.3999 48.9C53.3999 48.7 54.2999 49.2 54.5999 50.2L57.2999 60.5C57.4999 61.4 56.8999 62.4 55.9999 62.7Z"
      fill="#36B7BD"
    />
    <path
      d="M46.0999 62C48.2999 62 50.1999 60.2 50.1999 57.9C50.1999 55.7 48.3999 53.8 46.0999 53.8C43.8999 53.8 41.9999 55.6 41.9999 57.9C41.9999 60.1 43.7999 62 46.0999 62Z"
      fill="white"
    />
    <path
      d="M47.4999 57.1L45.0999 56.4C44.7999 56.3 44.4999 56.6 44.5999 56.9L45.1999 59.3C45.2999 59.6 45.6999 59.7 45.8999 59.5L47.6999 57.8C47.8999 57.6 47.7999 57.2 47.4999 57.1Z"
      fill="#36B7BD"
    />
    <path
      d="M41.8999 70.5L14.6999 79.2C13.6999 79.6 12.4999 79 12.0999 77.9L6.09992 58.7C5.69992 57.7 6.29992 56.5 7.39992 56.1L34.5999 47.5C35.5999 47.1 36.7999 47.7 37.1999 48.8L43.2999 68C43.4999 69.1 42.9999 70.2 41.8999 70.5Z"
      fill="#CEF4F4"
    />
    <path
      d="M34.9999 49.7L8.29993 58.2L14.2999 77L40.9999 68.5L34.9999 49.7Z"
      fill="white"
    />
    <path
      d="M30.1999 59C31.3999 59 32.3999 58 32.3999 56.8C32.3999 55.6 31.3999 54.6 30.1999 54.6C28.9999 54.6 27.9999 55.6 27.9999 56.8C27.9999 58 28.9999 59 30.1999 59Z"
      fill="#CEF4F4"
    />
    <path d="M14.1999 77L19.4999 63.3L32.5999 72.6" fill="#CEF4F4" />
    <path
      d="M28.2999 72.1L32.9999 63.1L42.1999 68.3L41.4999 69.4L31.0999 73L28.2999 72.1Z"
      fill="#CEF4F4"
    />
    <path
      d="M72.8999 85.6C72.8999 88.4 70.6999 90.6 67.8999 90.6H19.2999L9.89993 77.5L9.49993 76.9L8.99993 76.2L7.69993 74.3C7.29993 73.6 7.29993 73 7.29993 72.8C7.49993 71.8 8.49993 71 9.89993 70.7C10.2999 70.6 10.7999 70.6 11.3999 70.6H34.9999C37.0999 70.6 38.2999 68.4 37.1999 66.6L34.9999 63.1C34.8999 62.9 34.7999 62.7 34.7999 62.6C34.2999 61 35.3999 59.1 37.2999 59.1H60.7999C61.8999 59.1 62.8999 59.8 63.1999 60.8L63.4999 61.7L67.0999 71.1L72.8999 85.6Z"
      fill="#FCC977"
    />
    <path
      d="M44.9999 44.6H31.0999C30.4999 44.6 30.0999 44.2 30.0999 43.6C30.0999 43 30.4999 42.6 31.0999 42.6H44.9999C45.5999 42.6 45.9999 43 45.9999 43.6C45.9999 44.2 45.5999 44.6 44.9999 44.6Z"
      fill="#2D9EA3"
    />
    <path
      d="M58.5999 39.8H31.0999C30.4999 39.8 30.0999 39.4 30.0999 38.8C30.0999 38.2 30.4999 37.8 31.0999 37.8H58.5999C59.1999 37.8 59.5999 38.2 59.5999 38.8C59.5999 39.4 59.1999 39.8 58.5999 39.8Z"
      fill="#2D9EA3"
    />
    <path
      d="M55.7999 44.6H50.5999C49.9999 44.6 49.5999 44.2 49.5999 43.6C49.5999 43 49.9999 42.6 50.5999 42.6H55.7999C56.3999 42.6 56.7999 43 56.7999 43.6C56.7999 44.2 56.3999 44.6 55.7999 44.6Z"
      fill="#2D9EA3"
    />
  </svg>
);

export default MyDriveIcon;
