export default api => ({
  token: {
    get: (email, password) => api.post('/v1/jwt/create/', { email, password }),
    refresh: refresh =>
      api.post('/v1/jwt/refresh/', {
        refresh
      }),
    verify: token => api.post('/v1/jwt/verify/', { token }),
    getDemo: () => api.post('/v1/demo/start/')
  },

  confirmUser: (token, uid, firstName, lastName, phone, promoCode) =>
    api.post('/v1/auth/users/activate/', {
      token,
      uid,
      firstName,
      lastName,
      phone,
      promoCode
    }),

  complete: (firstName, lastName, phone, promoCode) =>
    api
      .post('/v1/auth/users/registration-complete/', {
        firstName,
        lastName,
        phone,
        promoCode
      })
      .then(({ data }) => data),

  confirmEmail: token => api.post('/v1/auth/confirm-email/', { token }),

  changeEmail: (email, password) =>
    api.post('/v1/auth/email/', {
      current_password: password,
      new_email: email
    }),

  resendActivation: email =>
    api.post('/v1/auth/users/activation-resend/', { email }),

  changePassword: (newPassword, password) =>
    api.post('/v1/auth/password/', {
      new_password: newPassword,
      current_password: password
    }),

  changePhone: (phone, password) =>
    api.patch('/v1/auth/me/', {
      phone,
      current_password: password
    }),

  changeIsFeedbackSent: ({ isFeedbackSent, feedbackSentAt }) =>
    api.patch('/v1/auth/me/', {
      isFeedbackSent,
      feedbackSentAt
    }),

  sendFeedback: data => api.post('/v1/auth/users/feedback/', data),

  resetPassword: email => api.post('/v1/auth/password/reset/', { email }),
  resetPasswordConfirm: (uid, token, newPassword) =>
    api.post('/v1/auth/password/reset/confirm/', {
      uid,
      token,
      newPassword
    }),

  current: () =>
    api.get('/v1/auth/me/', { errorHandle: false }).then(({ data }) => data),
  patchCurrent: data => api.patch('/v1/auth/me/', data),

  deleteUser: () => api.delete('/v1/auth/me/'),

  registration: ({
    email,
    phone,
    password,
    promoCode,
    isAgreePolicy,
    language,
    params
  }) => {
    localStorage.removeItem('token');

    return api.post(
      '/v1/auth/users/create/',
      {
        email,
        phone,
        password,
        promoCode,
        isAgreePolicy,
        language
      },
      { show400: false, params }
    );
  },

  registrationWithOrder: data => {
    localStorage.removeItem('token');
    return api.post('/v1/auth/users/create-with-order/', data);
  },

  fetchLinkedSocials: () => api.get(`/v1/auth/linked-socials/`),

  updateLinkedSocials: (id, name) =>
    api.put(`/v1/auth/linked-socials/${id}/`, {
      name
    }),

  deleteLinkedSocials: id => api.delete(`/v1/auth/linked-socials/${id}/`),

  googleAuthCallback: ({ params }) =>
    api.post(`/v1/auth/google/oauth2callback/`, null, { params }),

  google: () => api.get(`/v1/auth/google/oauth2/`),

  googleAuth: ({ token, register, isAgreePolicy, params }) =>
    api.post(
      `/v1/auth/google/token-to-jwt/`,
      { token, register, isAgreePolicy },
      { errorHandle: false, params }
    ),

  addGoogleOauth: token => api.post(`/v1/auth/google/oauth2-add/`, token),
  deleteGoogleOauth: () => api.delete(`/v1/auth/google/oauth2-add/`),

  facebookAuth: token =>
    api.post(`/v1/auth/facebook/token-to-jwt/`, { token }, { show400: false }),

  addFacebookOauth: token =>
    api.post(`/v1/auth/facebook/oauth2-add/`, { token }),
  deleteFacebookOauth: () => api.delete(`/v1/auth/facebook/oauth2-add/`),

  acceptAgreement: () => api.post(`/v1/auth/accept-agreement/`),

  fetchOnboarding: ({ workspaceId }) =>
    api.get(`/v1/${workspaceId}/onboarding/`),

  updateOnboarding: ({ workspaceId, id, data }) =>
    api.patch(`/v1/${workspaceId}/onboarding/${id}/`, data)
});
