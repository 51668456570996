import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  FormInput,
  FormInputNumber,
  FormRadio
} from 'components/common/hook-form';

import capitalize from 'utils/capitalize';

import styles from '../../email-service.module.scss';

const ServiceSettings = ({ label, name }) => {
  const { t } = useTranslation(['ConnectEmail', 'Errors']);

  return (
    <>
      <FormInput
        label={label}
        name={`${name}Server`}
        placeholder={`${name}.server.com`}
        rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
      />

      <div className={styles.portWrapper}>
        <FormInputNumber
          label={t('Port')}
          name={`${name}Port`}
          placeholder={t('Port')}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
        />

        <FormRadio
          name={`is${capitalize(name)}Ssl`}
          options={[
            { label: 'SSL', value: true },
            { label: 'TLS/STARTTLS', value: false }
          ]}
          itemProps={{
            className: styles.sslItem
          }}
          className={styles.ssl}
        />
      </div>
    </>
  );
};

ServiceSettings.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default ServiceSettings;
