import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { FormInput, FormRelationsSelect } from 'components/common/hook-form';

import styles from './save-to-disk.module.scss';

const Form = ({ isLoading, defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });

  const { t } = useTranslation(['SaveReportToMyDrive', 'Errors', 'Common']);

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography.Paragraph>{t('SaveToMyDiskDesc')}</Typography.Paragraph>

        <FormInput
          name="name"
          label={t('Title')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <FormRelationsSelect
          name="relation"
          label={t('Connection')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          allowToSelectOne
        />

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          width="expanded"
          loading={isLoading}
          className={styles.submitBtn}
        >
          {t('Save', { ns: 'Common' })}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  defaultValues: PropTypes.shape({
    name: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  defaultValues: {
    name: ''
  }
};

export default Form;
