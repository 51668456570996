import React, { useEffect, createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getRouterUrl } from 'store/router';
import { getActiveWorkspace, getPersonalWorkspace } from 'store/workspace';
import { getIsOnlyUserProfile } from 'store/user';

import config from 'config';
import { useLocalStorage } from 'hooks';

import {
  UPSERVICE_WIDGET_FIELD,
  UPSERVICE_WIDGET_QUERY,
  UPSERVICE_WIDGET_SUPPORT_FIELD
} from './constants';

export const UpserviceWidgetContext = createContext({
  openWidget: () => {},
  removeWidget: () => {},
  recreateWidget: () => {}
});

export const UpserviceWidgetProvider = ({ children }) => {
  const { workspaceId, activePath, query } = useSelector(getRouterUrl);

  const workspace = useSelector(getActiveWorkspace);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const personalWorkspace = useSelector(getPersonalWorkspace);

  const [storageData, setStorageData] = useState(null);

  const [, setSupportData] = useLocalStorage(
    UPSERVICE_WIDGET_SUPPORT_FIELD,
    null
  );

  const canShowWidget = workspaceId || activePath === '/auth';

  const src = `https://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}/api/widget/${config.REACT_APP_UPSERVICE_WIDGET_UUID}`;

  const openWidget = () => {
    const widget = window[UPSERVICE_WIDGET_FIELD];

    if (widget) {
      widget.setState({ isOpen: true });
    }
  };

  const handleSupportData = () => {
    if (isEmpty(workspace) && isEmpty(personalWorkspace)) {
      return setSupportData(null);
    }

    if (isOnlyUserProfile) {
      return setSupportData(personalWorkspace);
    }

    return setSupportData(workspace);
  };

  const createWidget = ({ isNeedAddStyles } = {}) => {
    const script = document.createElement('script');

    script.setAttribute('async', '');
    script.setAttribute('src', src);

    if (isNeedAddStyles) {
      const style = document.createElement('style');

      const css = `
      ups {
        animation: unset !important;
      }
    `;

      script.addEventListener('load', () => {
        document.head.appendChild(style);
        style.appendChild(document.createTextNode(css));
      });
    }

    document.head.appendChild(script);
  };

  const clearWidgetData = () => {
    window[UPSERVICE_WIDGET_FIELD] = {};
    localStorage.removeItem(UPSERVICE_WIDGET_FIELD);

    const widgetElement = document.querySelector('[data-widget-host]');

    if (widgetElement) {
      widgetElement.remove();
    }
  };

  const removeWidget = () => {
    setStorageData(JSON.parse(localStorage.getItem(UPSERVICE_WIDGET_FIELD)));

    clearWidgetData();
  };

  const recreateWidget = () => {
    setTimeout(() => {
      clearWidgetData();

      localStorage.setItem(UPSERVICE_WIDGET_FIELD, JSON.stringify(storageData));

      createWidget({ isNeedAddStyles: false });
    }, [500]);
  };

  useEffect(() => {
    if (canShowWidget && !window[UPSERVICE_WIDGET_FIELD]) {
      createWidget({ isNeedAddStyles: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowWidget]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (canShowWidget && query && query[UPSERVICE_WIDGET_QUERY]) {
        openWidget();
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [query, canShowWidget]);

  useEffect(() => {
    handleSupportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, personalWorkspace]);

  return (
    <UpserviceWidgetContext.Provider
      value={{ openWidget, removeWidget, recreateWidget }}
    >
      {children}
    </UpserviceWidgetContext.Provider>
  );
};

export default UpserviceWidgetProvider;
