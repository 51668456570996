import { createSelector } from 'reselect';

import { SOCIAL_CREDENTIALS_TYPE_GOOGLE_CALENDAR } from 'constants/index';

export const getSocialCredentials = state => state.socialCredentials;

export const getSocialCredentialsEntries = createSelector(
  getSocialCredentials,
  socialCredentials => socialCredentials.entries
);

export const getSocialCredentialsGoogleCalendar = createSelector(
  getSocialCredentialsEntries,
  entries =>
    entries.find(e => e.type === SOCIAL_CREDENTIALS_TYPE_GOOGLE_CALENDAR)
);
