import React from 'react';

export const SingleCheckboxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57368 11.5382C7.68847 11.5339 7.80195 11.4843 7.88892 11.3898L13.4823 5.31174C13.6631 5.1153 13.6609 4.79888 13.4774 4.605C13.2938 4.41112 12.9985 4.4132 12.8177 4.60964L7.55332 10.3302L4.65517 7.07507C4.47785 6.87591 4.18292 6.86898 3.99644 7.05958C3.80995 7.25019 3.80252 7.56616 3.97984 7.76532L7.20303 11.3855C7.30278 11.4976 7.43974 11.5488 7.57368 11.5382Z"
      fill="currentColor"
    />
  </svg>
);

export default SingleCheckboxIcon;
