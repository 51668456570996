import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';

import { LETTER_KINDS } from '../../../../../../../constants';

const { Option } = Select;

export const LetterTypeSelect = ({
  onChange,
  value,
  isMulti,
  ...selectProps
}) => {
  const { t } = useTranslation('AddTask');

  return (
    <Select
      mode={isMulti ? 'multiple' : ''}
      onChange={onChange}
      placeholder={t('ChooseEmailType')}
      defaultValue={LETTER_KINDS[0].value}
      value={value}
      data-qa="qa-bmy0r2b4w6x70xm"
      {...selectProps}
    >
      {LETTER_KINDS.map(kind => (
        <Option value={kind.value} key={kind.key}>
          <Translation ns={kind.ns}>
            {translate => translate(kind.label)}
          </Translation>
        </Option>
      ))}
    </Select>
  );
};

LetterTypeSelect.propTypes = {
  value: PropTypes.object,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func
};

LetterTypeSelect.defaultProps = {
  value: undefined,
  isMulti: false,
  onChange: () => {}
};

export default LetterTypeSelect;
