import React from 'react';

export const PlusIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipPlus)">
      <path
        d="M12.8036 2.35791H11.1965C11.0536 2.35791 10.9822 2.42934 10.9822 2.5722V10.9829H3.00066C2.85781 10.9829 2.78638 11.0543 2.78638 11.1972V12.8043C2.78638 12.9472 2.85781 13.0186 3.00066 13.0186H10.9822V21.4293C10.9822 21.5722 11.0536 21.6436 11.1965 21.6436H12.8036C12.9465 21.6436 13.0179 21.5722 13.0179 21.4293V13.0186H21.0007C21.1435 13.0186 21.2149 12.9472 21.2149 12.8043V11.1972C21.2149 11.0543 21.1435 10.9829 21.0007 10.9829H13.0179V2.5722C13.0179 2.42934 12.9465 2.35791 12.8036 2.35791Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clipPlus">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PlusIcon;
