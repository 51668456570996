import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import EmployeeModal from 'components/common/modals/employee';

import { partialUpdatePaymentAccount } from 'store/billing';

import { showNoticeMessage } from 'services/notice';

const ChangePaymentAdminModal = ({ visible, onClose, data }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('ChangeCompanyAdmin');

  const { admin } = data;

  const onSubmit = async ({ value }) => {
    await dispatch(partialUpdatePaymentAccount({ account: { admin: value } }));

    showNoticeMessage();
  };

  return (
    <EmployeeModal
      width={565}
      title={t('ChangeCompanyAdminHeading')}
      description={t('ChangeCompanyAdminDesc')}
      btnText={t('SubmitBtn')}
      visible={visible}
      defaultValue={admin}
      selectProps={{
        label: t('CompanyAdmin')
      }}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

ChangePaymentAdminModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    admin: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  })
};

ChangePaymentAdminModal.defaultProps = {
  data: {}
};

export default ChangePaymentAdminModal;
