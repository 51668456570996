import { createSelector } from 'reselect';

const _getCities = state => state.cities;

export const getCities = createSelector(
  _getCities,
  cities => cities.entries
);

export const getIsCitiesLoading = createSelector(
  _getCities,
  cities => cities.isLoading
);

export const getIsCitiesLoaded = createSelector(
  _getCities,
  cities => cities.isLoaded
);

export const getCitiesTotalItems = createSelector(
  _getCities,
  cities => cities.totalItems
);

export const getCitiesItemsPerPage = createSelector(
  _getCities,
  cities => cities.itemsPerPage
);

export const getCitiesCurrentPage = createSelector(
  _getCities,
  cities => cities.currentPage
);

export const getHasMoreCities = createSelector(
  [getCitiesTotalItems, getCities],
  (totalItems, cities) => totalItems > cities.length
);
