import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from '../avatar';
import getFIO, { getFullName } from '../../../utils/get-fio';
import useRoutesService from '../../../services/routes';

export const UserCardSmall = ({
  asLink,
  id,
  firstName,
  lastName,
  avatarFile,
  children,
  className,
  style,
  isActive
}) => {
  const routes = useRoutesService({ returnUrl: true });

  return (
    <Link
      to={asLink && isActive ? routes.toEmployee(id) : ''}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        pointerEvents: asLink && isActive ? 'inherit' : 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...style
      }}
      className={className}
    >
      <Avatar
        src={(avatarFile || {}).url}
        size={24}
        isActive={isActive}
        style={{ marginRight: 8 }}
      >
        {getFIO({ firstName, lastName })}
      </Avatar>

      <Typography.Text ellipsis>
        {getFullName({ firstName, lastName })}

        {children}
      </Typography.Text>
    </Link>
  );
};

UserCardSmall.propTypes = {
  asLink: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarFile: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object
};

UserCardSmall.defaultProps = {
  children: undefined,
  asLink: false,
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  className: undefined,
  avatarFile: {},
  style: {}
};

export default UserCardSmall;
