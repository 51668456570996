import React from 'react';
import PropTypes from 'prop-types';

import ManageSubscribersMultiAttachment from 'components/common/subscriptions/manage-subscribers-multi-attachment';
import ManageSubscribers from 'components/common/subscriptions/manage-subscribers';

const ManageSubscribersWrapper = ({
  attachmentProps,
  file,
  fileList,
  onChangeFileList,
  index
}) => {
  if (!attachmentProps || !attachmentProps.allowManageSubscribers) {
    return null;
  }

  return (
    <>
      {attachmentProps.sub && attachmentProps.sub.fields[index] ? (
        <ManageSubscribersMultiAttachment
          key={attachmentProps.sub.fields[index].id}
          data={attachmentProps.sub.fields[index]}
          index={index}
        />
      ) : (
        <ManageSubscribers
          file={file}
          fileList={fileList}
          onChangeFileList={onChangeFileList}
        />
      )}
    </>
  );
};

ManageSubscribersWrapper.propTypes = {
  attachmentProps: PropTypes.object,
  file: PropTypes.object,
  fileList: PropTypes.array,
  onChangeFileList: PropTypes.func,
  index: PropTypes.number
};

ManageSubscribersWrapper.defaultProps = {
  attachmentProps: {},
  file: {},
  fileList: [],
  onChangeFileList: () => {},
  index: 0
};

export default ManageSubscribersWrapper;
