import { Skeleton } from 'antd';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createRef,
  forwardRef
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';

import { fetchEmployeesLocal } from 'store/team/employees';

import { useKeyPress } from 'hooks/common';
import getFIO, { getFullName } from 'utils/get-fio';

import styles from '../../controls.module.scss';

const Item = forwardRef(({ user, className, onClick }, ref) => (
  <div
    ref={ref}
    className={classnames(styles.mentionItem, className)}
    onClick={() => onClick(user)}
  >
    <div className={styles.avatarName}>
      <Avatar
        size={24}
        className={styles.avatar}
        src={(user.avatarFile || {}).url}
      >
        {getFIO(user)}
      </Avatar>

      <Typography.Text ellipsis>{getFullName(user)}</Typography.Text>
    </div>

    {user.position && (
      <Typography.Text ellipsis size="small">
        {user.position}
      </Typography.Text>
    )}
  </div>
));

export const MentionList = ({
  users,
  index,
  search,
  setUsers,
  className,
  onClick
}) => {
  const dispatch = useDispatch();

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');

  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(false);
  const [cursor, setCursor] = useState(index);

  const refs = useMemo(
    () =>
      users.reduce((acc, _, idx) => {
        acc[idx] = createRef();

        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users.length]
  );

  const fetch = useCallback(
    async offset => {
      try {
        setIsLoading(true);

        const { totalItems: total, entries } = await dispatch(
          fetchEmployeesLocal({
            search,
            excludeBench: null,
            offset,
            cancelable: true
          })
        );

        setUsers(offset ? [...users, ...entries] : entries);
        setTotalItems(total);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, search, setUsers, users]
  );

  useEffect(() => {
    if (users.length && downPress) {
      setCursor(prevCursor =>
        prevCursor < users.length - 1 ? prevCursor + 1 : prevCursor
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress]);

  useEffect(() => {
    if (users.length && upPress) {
      setCursor(prevCursor => (prevCursor > 0 ? prevCursor - 1 : prevCursor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress]);

  useEffect(() => {
    if (refs[cursor] && refs[cursor].current) {
      refs[cursor].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress, upPress]);

  useEffect(() => {
    fetch(0);

    return () => setUsers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!isLoading && !users.length) {
    return null;
  }

  return (
    <div className={classnames(styles.mentionList, className)}>
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        hasMore={!isLoading && users.length < totalItems}
        loadMore={() => fetch(users.length)}
      >
        {users.map((user, idx) => (
          <Item
            ref={refs[idx]}
            key={`mention-user-${user.id}`}
            user={user}
            className={idx === index ? styles.active : undefined}
            onClick={onClick}
          />
        ))}

        {isLoading &&
          [...Array(5).keys()].map(key => (
            <Skeleton
              key={`mention-user-skeleton-${key}`}
              active
              className={styles.skeletonMention}
              avatar={{ shape: 'circle', size: 24 }}
              title={false}
              paragraph={{ rows: 1 }}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default MentionList;
