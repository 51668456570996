import React from 'react';

export const EmailChannelIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0002 0C13.8266 0 14.5333 0.293412 15.1199 0.880126C15.7065 1.46684 16 2.17363 16 2.99997V12.9999C16 13.8262 15.7066 14.5329 15.1198 15.1198C14.5332 15.7066 13.8264 16 13.0001 16H3.00002C2.1737 16 1.46697 15.7066 0.88014 15.1198C0.293417 14.5329 0 13.8262 0 12.9999V2.99997C0 2.17363 0.293417 1.46695 0.88014 0.880126C1.46697 0.293412 2.1737 0 3.00002 0H13.0002Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0002 0C13.8266 0 14.5333 0.293412 15.1199 0.880126C15.7065 1.46684 16 2.17363 16 2.99997V12.9999C16 13.8262 15.7066 14.5329 15.1198 15.1198C14.5332 15.7066 13.8264 16 13.0001 16H3.00002C2.1737 16 1.46697 15.7066 0.88014 15.1198C0.293417 14.5329 0 13.8262 0 12.9999V2.99997C0 2.17363 0.293417 1.46695 0.88014 0.880126C1.46697 0.293412 2.1737 0 3.00002 0H13.0002ZM12.078 4.58349L8.00097 7.37293L3.92407 4.58349C3.96825 4.57597 4.01332 4.57077 4.05943 4.57077H11.9425C11.9886 4.57077 12.0336 4.57578 12.078 4.58349ZM8.24827 8.45702L8.2532 8.45427L8.25755 8.45148L12.7874 5.35188C12.7958 5.40432 12.801 5.45781 12.801 5.51294V10.4872C12.801 11.0075 12.4166 11.4293 11.9424 11.4293H4.05935C3.58511 11.4293 3.20082 11.0075 3.20082 10.4872V5.51294C3.20082 5.45781 3.2061 5.40422 3.21426 5.35188L7.7444 8.45148C7.74766 8.45377 7.751 8.45557 7.75435 8.45738C7.75678 8.45869 7.75922 8.46001 7.76162 8.46151C7.76759 8.46507 7.77374 8.46854 7.7798 8.47182C7.81204 8.48985 7.8448 8.5044 7.87853 8.51413C7.88196 8.5151 7.88547 8.51568 7.8889 8.51654C7.92588 8.52618 7.96409 8.53196 8.00151 8.53196C8.03892 8.53196 8.07626 8.52609 8.11341 8.51654C8.11675 8.51577 8.12009 8.51519 8.1236 8.51413C8.15733 8.5045 8.19036 8.48994 8.22242 8.47182L8.22478 8.47051C8.22997 8.46762 8.23541 8.4646 8.24051 8.46151C8.24305 8.45985 8.24566 8.45844 8.24827 8.45702Z"
      fill="#B37FEB"
    />
  </svg>
);

export default EmailChannelIcon;
