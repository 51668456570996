import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import FormItem from '../form-item';

const { TextArea } = Input;

export const FormTextarea = ({ rules, name, label, itemProps, ...props }) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => <TextArea {...field} {...props} />}
      />
    </FormItem>
  );
};

FormTextarea.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormTextarea.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormTextarea;
