import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchEmployeesLocal } from 'store/team/employees';
import { fetchProjectsLocal } from 'store/projects';
import { fetchDepartmentsLocal } from 'store/team/departments';

import { useDebounce } from 'hooks/index';

import {
  DEFAULT_MAX_MENTION_ITEMS_DEPARTMENTS,
  DEFAULT_MAX_MENTION_ITEMS_EMPLOYEES,
  DEFAULT_MAX_MENTION_ITEMS_PROJECTS,
  MAX_MENTION_ITEMS_COUNT,
  MENTION_MEMBERS_KEYS_BY_ENTITY_TYPE,
  transformDepartmentToMentionItem,
  transformEmployeeToMentionItem,
  transformProjectToMentionItem
} from './utils';

export const useMention = ({ mentionString, destination }) => {
  const dispatch = useDispatch();

  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation('CommonChat');

  const debouncedValue = useDebounce(mentionString, 300);

  const { entityId, entityType, isDescription = false } = destination;

  const membersMentionTitle = t(
    MENTION_MEMBERS_KEYS_BY_ENTITY_TYPE[entityType]
  );

  const allEmployeeMentions = [
    // TODO: Return when merging logs or conversations is done. Do a search on this comment to get everything back
    // {
    //   id: 0,
    //   mentionType: MENTION_TYPE_ALL,
    //   title: t('AllEmployees'),
    //   mentionText: t('AllEmployees'),
    //   iconType: 'big-team',
    //   allow: true
    // },
    {
      id: entityId,
      mentionType: entityType,
      title: membersMentionTitle,
      mentionText: membersMentionTitle,
      iconType: 'team',
      isMembers: true,
      allow: entityType && entityId
    }
  ]
    .filter(m => m.allow)
    .filter(({ title }) =>
      title.toLowerCase().includes((debouncedValue || '').toLowerCase())
    );

  const results = [
    ...allEmployeeMentions,
    ...employees.slice(0, DEFAULT_MAX_MENTION_ITEMS_EMPLOYEES),
    ...departments.slice(0, DEFAULT_MAX_MENTION_ITEMS_DEPARTMENTS),
    ...projects.slice(0, DEFAULT_MAX_MENTION_ITEMS_PROJECTS)
  ];

  const fetchEmployees = async () => {
    const { entries } = await dispatch(
      fetchEmployeesLocal({
        search: debouncedValue,
        excludeBench: null,
        offset: 0,
        limit: MAX_MENTION_ITEMS_COUNT,
        cancelable: true,
        lite: true,
        withSystem: !isDescription || undefined
      })
    );

    return entries;
  };

  const fetchProjects = async () => {
    const { entries } = await dispatch(
      fetchProjectsLocal({
        search: debouncedValue,
        offset: 0,
        limit: MAX_MENTION_ITEMS_COUNT,
        lite: true
      })
    );

    return entries;
  };

  const fetchDepartments = async () => {
    const { entries } = await dispatch(
      fetchDepartmentsLocal({
        search: debouncedValue,
        offset: 0,
        limit: MAX_MENTION_ITEMS_COUNT,
        lite: true
      })
    );

    return entries;
  };

  const fetch = async () => {
    try {
      setIsLoading(true);

      const [employeeList, porjectList, departmentList] = await Promise.all([
        fetchEmployees(),
        fetchProjects(),
        fetchDepartments()
      ]);

      setEmployees(employeeList.map(transformEmployeeToMentionItem));
      setProjects(porjectList.map(transformProjectToMentionItem));
      setDepartments(departmentList.map(transformDepartmentToMentionItem));
    } finally {
      setIsLoading(false);
    }
  };

  const clearResults = () => {
    setEmployees([]);
    setProjects([]);
    setDepartments([]);
    setIsLoading(true);
  };

  useEffect(() => {
    if (debouncedValue !== null) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return { results, isLoading, clearResults };
};

export default useMention;
