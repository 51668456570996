import React, { useEffect } from 'react';

import Header from './layout/header';
import DetailIssue from './detail-issue';
import Container from '../common/layout/template/container';

import styles from './issues-view.module.scss';

const IssuesView = () => {
  useEffect(() => {
    document.body.classList.add(styles.body);

    return () => {
      document.body.classList.remove(styles.body);
    };
  }, []);

  return (
    <div className={styles.root}>
      <Header />

      <Container className={styles.container}>
        <DetailIssue />
      </Container>
    </div>
  );
};

export default IssuesView;
