import handleActions from 'utils/redux-actions';

import {
  fetchMessageTemplates,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate
} from './actions';

const initialState = {
  list: []
};

export default handleActions(
  {
    [fetchMessageTemplates.SUCCEEDED]: (state, { payload }) => {
      state.list = payload.results;

      return state;
    },

    [createMessageTemplate.SUCCEEDED]: (state, { payload }) => {
      state.list = [...state.list, payload];

      return state;
    },

    [updateMessageTemplate.SUCCEEDED]: (state, { payload }) => {
      const index = state.list.findIndex(t => t.id === payload.id);

      if (index !== -1) {
        state.list[index] = {
          ...state.list[index],
          ...payload
        };
      }

      return state;
    },

    [deleteMessageTemplate.SUCCEEDED]: (state, { args }) => {
      state.list = state.list.filter(({ id }) => id !== args.templateId);

      return state;
    }
  },
  initialState
);
