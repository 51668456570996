import moment from 'moment';

import { DATE_FORMAT } from 'constants/index';

export const normalizeDate = dateStr => {
  // Remove all characters except digits and dots
  // eslint-disable-next-line no-param-reassign
  dateStr = dateStr.replace(/[^0-9.]/g, '');

  // Split the string into parts by dot
  const parts = dateStr.split('.').filter(Boolean);

  // Get the current date
  const now = moment();

  let day;
  let month;
  let year;

  if (parts.length === 1 && parseInt(parts[0], 10) <= 31) {
    // If it is a number from 1 to 31, use it as the day
    day = parseInt(parts[0], 10);
    month = now.month() + 1; // current month
    year = now.year(); // current year
  } else if (parts.length === 2) {
    // If there is a day and month
    day = parseInt(parts[0], 10);
    month = parseInt(parts[1], 10);
    year = now.year(); // current year
  } else if (parts.length === 3) {
    // If there is a day, month, and year
    day = parseInt(parts[0], 10);
    month = parseInt(parts[1], 10);
    year = parseInt(parts[2], 10);
  } else {
    // If the format does not match, return the current date
    return now.toDate();
  }

  // Create a date using moment and check if it is valid
  const date = moment(`${day}.${month}.${year}`, DATE_FORMAT);

  if (!date.isValid()) {
    // If the date is not valid, return the current date
    return now.toDate();
  }

  return date.toDate();
};

export const maxDate = new Date('2999-12-31');
