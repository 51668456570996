import { useEffect, useState } from 'react';

export const useUploadingFiles = fileList => {
  const [isLoading, setIsLoading] = useState(false);

  const hasUploadingFiles = files =>
    (files || []).some(({ percent }) => percent !== undefined && percent < 100);

  useEffect(() => {
    setIsLoading(hasUploadingFiles(fileList));
  }, [fileList]);

  return isLoading;
};

export default useUploadingFiles;
