import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Icon from 'components/common/icon';

import { getUILanguage } from 'store/ui';

import getQuestions from './utils';

import styles from './questions.module.scss';

export const Questions = ({ isNotVerified, isRegistration }) => {
  const language = useSelector(getUILanguage);

  const questions = getQuestions(isNotVerified, isRegistration, language);

  const [activeKey, setActiveKey] = useState([]);

  const { t } = useTranslation('FAQ');

  useEffect(() => {
    setActiveKey([]);
  }, [isNotVerified]);

  return (
    <Collapse
      activeKey={activeKey}
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ? (
          <Icon type="minus" size={24} />
        ) : (
          <Icon type="plus" size={24} />
        )
      }
      expandIconPosition="right"
      className={styles.root}
      onChange={setActiveKey}
    >
      {questions.map(q => (
        <Collapse.Panel key={q.key} header={t(q.header)}>
          {q.content}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

getQuestions.propTypes = {
  isNotVerified: PropTypes.bool,
  isRegistration: PropTypes.bool
};

getQuestions.defaultProps = {
  isNotVerified: false,
  isRegistration: false
};

export default Questions;
