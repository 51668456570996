import moment from 'moment';

const useValidityDateFileList = ({ fileList, onChange }) => {
  const changeValidityDateStateValue = ({ file, index, value }) => {
    const updatedFileList = [...fileList];
    updatedFileList[index] = {
      ...file,
      validityDateState: { validityDateValue: value, isValidDate: true }
    };

    onChange(updatedFileList);
  };

  const validateValidityDateStateValues = setFileList => {
    let isValid = true;
    const updatedFileList = fileList.map(file => {
      if (
        file.validityDateState.validityDateValue &&
        !moment(file.validityDateState.validityDateValue).isSame(
          file.validityDate
        ) &&
        !moment(file.validityDateState.validityDateValue).isAfter(
          moment().add(24, 'hours')
        )
      ) {
        isValid = false;
        return {
          ...file,
          validityDateState: {
            ...file.validityDateState,
            isValidDate: false
          }
        };
      }

      return file;
    });

    if (!isValid) {
      const updateList = setFileList || onChange;
      updateList(updatedFileList);
    }

    return isValid;
  };

  return { changeValidityDateStateValue, validateValidityDateStateValues };
};

export default useValidityDateFileList;
