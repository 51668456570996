import React from 'react';
import PropTypes from 'prop-types';
import pl from 'plural-ru';
import { Translation } from 'react-i18next';

import Typography from 'components/common/typography';

export const TotalItems = ({ firstWord, count, plural, style, className }) => (
  <Typography.Text color="black-45" style={style} className={className}>
    {firstWord} <span style={{ fontWeight: 600 }}>{count}</span>{' '}
    {pl(count, ...plural)}
  </Typography.Text>
);

TotalItems.propTypes = {
  count: PropTypes.number.isRequired,
  plural: PropTypes.arrayOf(PropTypes.string).isRequired, // 'объект', 'объекта', 'объектов'
  firstWord: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

TotalItems.defaultProps = {
  firstWord: <Translation>{t => t('Found', { ns: 'Common' })}</Translation>,
  style: {},
  className: undefined
};

export default TotalItems;
