import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

import Typography from 'components/common/typography';
import { CancelSubscriptionIcon } from 'components/common/icons/multicolor';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';

import { cancelPaymentSubscription } from 'store/billing';

import styles from './cancel-payment-subscription.module.scss';

const generatePoint = ({ title, description }) => {
  if (title && description) {
    return `${title} ${description}`;
  }

  if (title) {
    return title;
  }

  return description;
};

const CancelPaymentSubscriptionModal = ({ data, visible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['CancelSubscription', 'Common']);

  const { workspaceId, account = {} } = data;
  const { tariff = {}, nextPaymentDate } = account;
  const { modules = [] } = tariff;
  const sortedModules = modules.sort((a, b) => a.sort - b.sort);

  const onConfirm = async () => {
    try {
      setIsLoading(true);

      await dispatch(cancelPaymentSubscription({ workspaceId }));

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const description = (
    <>
      <Typography.Paragraph color="black-45">
        {t('CancelSubscriptionDesc', { date: nextPaymentDate })}
      </Typography.Paragraph>

      <Alert
        type="warning"
        message={
          <Typography.Paragraph className={styles.alertMessage}>
            {t('CancelSubscriptionWarning')}

            <ul>
              {sortedModules.map(m => (
                <li>{generatePoint(m)}</li>
              ))}
            </ul>
          </Typography.Paragraph>
        }
      />
    </>
  );

  return (
    <ModalDeleteConfirm
      visible={visible}
      onClose={onClose}
      iconModal={CancelSubscriptionIcon}
      title={t('CancelSubscriptionHeading')}
      isLoading={isLoading}
      description={description}
      cancelBtnText={t('NoBtn', { ns: 'Common' })}
      deleteBtnText={t('YesBtn', { ns: 'Common' })}
      onConfirm={onConfirm}
    />
  );
};

CancelPaymentSubscriptionModal.propTypes = {
  data: PropTypes.shape({
    workspaceId: PropTypes.number,
    account: PropTypes.object
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

CancelPaymentSubscriptionModal.defaultProps = {
  data: {},
  visible: false,
  onClose: () => {}
};

export default CancelPaymentSubscriptionModal;
