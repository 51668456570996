import React from 'react';

export const FolderIcon = () => (
  <svg
    width="143"
    height="124"
    viewBox="0 0 143 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.3251 0.342592C92.3268 -0.976306 111.34 1.09318 123.945 14.0124C138.018 28.436 147.087 49.5776 141.154 68.8364C135.529 87.1012 114.012 91.9854 97.1548 100.989C78.6737 110.861 61.2835 128.45 41.2932 122.175C19.1884 115.236 1.97794 93.616 0.103785 70.5236C-1.5915 49.6352 17.8277 35.0701 33.0553 20.6715C44.7783 9.58665 58.2344 1.52149 74.3251 0.342592Z"
      fill="#EBF8F8"
    />
    <path
      d="M116.518 30.6947H74.1522V20.067C74.1522 16.5891 76.9718 13.7695 80.4497 13.7695H110.22C113.698 13.7695 116.517 16.5891 116.517 20.067L116.518 30.6947Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M129.379 31.0328C129.379 27.71 126.686 25.016 123.362 25.016H67.0823V14.1076C67.0823 10.7846 64.3885 8.09082 61.0655 8.09082H30.7336C27.4105 8.09082 24.7168 10.7846 24.7168 14.1076V88.4626C24.7168 91.7857 27.4105 94.4794 30.7336 94.4794H123.362C126.685 94.4794 129.379 91.7857 129.379 88.4626L129.379 31.0328Z"
      fill="#FDA402"
    />
    <path
      d="M106.801 38.7435H64.4352V28.1159C64.4352 24.6379 67.2547 21.8184 70.7327 21.8184H100.503C103.981 21.8184 106.8 24.6379 106.8 28.1159V38.7435H106.801Z"
      fill="url(#paint1_linear)"
    />
    <g opacity="0.1">
      <path
        opacity="0.1"
        d="M122.278 38.1777C122.278 33.909 118.817 30.4486 114.549 30.4486H59.9809V21.2526C59.9809 16.9839 56.5205 13.5234 52.2518 13.5234H23.6325C20.3095 13.5234 17.6157 16.2174 17.6157 19.5403V93.8952C17.6157 97.2183 20.3095 99.912 23.6325 99.912H116.261C119.584 99.912 122.278 97.2183 122.278 93.8952V38.1777Z"
        fill="black"
      />
    </g>
    <path
      d="M119.662 39.0811C119.662 35.7583 116.968 33.0643 113.645 33.0643H57.3655V22.156C57.3655 18.8329 54.6717 16.1392 51.3487 16.1392H21.0168C17.6937 16.1392 15 18.8332 15 22.156V96.5109C15 99.834 17.6937 102.528 21.0168 102.528H113.645C116.968 102.528 119.662 99.834 119.662 96.5109V39.0811Z"
      fill="#FFE181"
    />
    <path d="M55.4578 71.0474H24.7172V86.4059H55.4578V71.0474Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="116.449"
        y1="20.2924"
        x2="85.6862"
        y2="3.47957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="124.86"
        y1="26.6735"
        x2="64.4352"
        y2="26.6735"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07BEDC" />
        <stop offset="1" stopColor="#0389B6" />
      </linearGradient>
    </defs>
  </svg>
);

export default FolderIcon;
