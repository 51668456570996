import React, { useMemo, useRef } from 'react';
import { Badge, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Emoji } from 'emoji-picker-react';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import { getEmojiWithPostfixByHex } from 'components/common/new-editor/components/emoji/utlls';

import { getUserEmployee } from 'store/workspace';

import styles from './reactions.module.scss';

const TooltipTitle = ({ entries, code }) => (
  <div className={styles.tooltipTitle}>
    <Emoji unified={code.toLowerCase()} size={16} />

    <Typography.Text color="white" size="small">
      {entries.map(e => e.sender.fullName).join(', ')}
    </Typography.Text>
  </div>
);

const Reactions = ({ messageUuid, reactions, onClick }) => {
  const employee = useSelector(getUserEmployee);

  const reactionIndex = useRef({});

  const groupedReactions = useMemo(
    () =>
      (reactions || []).reduce((acc, curr) => {
        if (!acc[curr.code]) {
          const getIndex = () => {
            if (reactionIndex.current[curr.code]) {
              return reactionIndex.current[curr.code];
            }

            const values = Object.values(reactionIndex.current);

            if (!values.length) {
              return 1;
            }

            return Math.max(...values) + 1;
          };

          const index = getIndex();

          acc[curr.code] = {
            needFirstAnimation: false,
            needHighlighting: false,
            hasMyReaction: false,
            entries: [],
            index
          };

          if (!reactionIndex.current[curr.code]) {
            reactionIndex.current = {
              ...reactionIndex.current,
              [curr.code]: index
            };
          }
        }

        acc[curr.code].entries.push(curr);
        acc[curr.code].needFirstAnimation = curr.isFirst;
        acc[curr.code].needHighlighting = curr.needHighlighting;

        if (!acc[curr.code].hasMyReaction) {
          acc[curr.code].hasMyReaction = curr.senderId === employee.userId;
        }

        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employee.userId, reactions]
  );

  if (!(reactions || []).length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {Object.keys(groupedReactions)
        .sort((a, b) => groupedReactions[a].index - groupedReactions[b].index)
        .map(code => {
          const {
            hasMyReaction,
            needFirstAnimation,
            needHighlighting,
            entries
          } = groupedReactions[code];

          return (
            <Tooltip
              title={<TooltipTitle entries={entries} code={code} />}
              mouseEnterDelay={0.5}
              key={`${code}-${messageUuid}`}
            >
              <div
                className={classnames(styles.reaction, {
                  [styles.hasMyReaction]: hasMyReaction,
                  [styles.firstAnimation]: needFirstAnimation,
                  [styles.highlighting]: needHighlighting
                })}
                onClick={() => {
                  if (hasMyReaction && entries.length === 1) {
                    reactionIndex.current = {
                      ...reactionIndex.current,
                      [code]: null
                    };
                  }

                  onClick({ code });
                }}
              >
                <Emoji
                  unified={getEmojiWithPostfixByHex(code.toLowerCase())}
                  size={16}
                />

                <Badge count={entries.length} className={styles.count} />
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
};

Reactions.propTypes = {
  messageUuid: PropTypes.string.isRequired,
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      senderId: PropTypes.number,
      sender: PropTypes.shape({
        fullName: PropTypes.string
      })
    })
  ),
  onClick: PropTypes.func.isRequired
};

Reactions.defaultProps = {
  reactions: []
};

export default Reactions;
