import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';

const CreatedAt = ({ file }) => {
  const { createdAt } = file;

  return (
    <Typography.Text>
      {moment(createdAt).format(DATE_TIME_FORMAT)}
    </Typography.Text>
  );
};

CreatedAt.propTypes = {
  file: PropTypes.shape({
    createdAt: PropTypes.string.isRequired
  }).isRequired
};

export default CreatedAt;
