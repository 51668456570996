import { useContext } from 'react';

import { MeeteingCalendarContext } from '.';

export const useMeetingCalendarContext = () => {
  const values = useContext(MeeteingCalendarContext);

  return (
    values || {
      goToDate: () => {},
      selectedDates: {},
      selectDatesToCalendar: () => {},
      setResources: () => {}
    }
  );
};

export default useMeetingCalendarContext;
