import React from 'react';

export const RatingIcon = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" width="2" height="9" rx="1" />
    <rect x="6" y="3.375" width="2" height="5.625" rx="1" />
    <rect y="5.625" width="2" height="3.375" rx="1" />
  </svg>
);

export default RatingIcon;
