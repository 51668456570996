import memoize from 'lodash/memoize';

import { BENCH, JOINS, MAIN } from 'constants/positions';

export const transformToTreeData = memoize(({ list, parentId = null }) => {
  if (!list.length) {
    return [];
  }

  const mainDepartment = list.find(department => !department.parentId);
  const childMainDepartments = list.filter(
    d => d.parentId === mainDepartment.id
  );

  const departments = parentId
    ? list.filter(item => item.parentId === parentId)
    : [mainDepartment, ...childMainDepartments];

  return departments.map(department => {
    const isMainDepartment = department.type === MAIN;
    const isBenchDepartment = department.type === BENCH;
    const isJoinsDepartment = department.type === JOINS;

    const hasSubDepartments =
      list.filter(item => item.parentId === department.id).length !== 0;

    const childrenDepartments =
      hasSubDepartments && !isMainDepartment
        ? transformToTreeData({ list, parentId: department.id })
        : [];

    const children = department.manager
      ? [
          {
            ...department.manager,
            isManager: true,
            isDirector: isMainDepartment
          },
          ...department.employees,
          ...childrenDepartments
        ]
      : [...department.employees, ...childrenDepartments];

    return {
      id: department.id,
      title: department.name,
      permissions: department.permissions,
      employeeCount: department.employeeCount,
      isDepartment: true,
      isMainDepartment,
      isBenchDepartment,
      isJoinsDepartment,
      children
    };
  });
});

export const mapTreeData = memoize(
  ({
    treeData = [],
    getIf = () => false,
    getItem = item => item,
    withKey = false
  }) => {
    let data = {};

    treeData.forEach(item => {
      if (getIf(item)) {
        data[withKey ? item.key : item.id] = getItem(item);
      }

      if ((item.children || []).length) {
        data = {
          ...data,
          ...mapTreeData({ treeData: item.children, getIf, getItem, withKey })
        };
      }
    });

    return data;
  }
);

export const getEmployeesFromTreeData = memoize(treeData =>
  mapTreeData({
    treeData,
    getIf: ({ isDepartment }) => !isDepartment,
    getItem: item => ({ value: item.id, label: item })
  })
);

export const getEmployeesByIds = memoize(({ ids, employees }) =>
  ids.reduce((acc, curr) => {
    if (employees[curr]) {
      acc.push(employees[curr]);
    }

    return acc;
  }, [])
);
