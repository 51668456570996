import api from 'api';
import { createAction } from 'redux-actions';

import { ADMIN } from 'constants/index';

import { getWorkspaceId } from 'store/workspace';
import createActionThunk from 'store/actions-thunk';

export const fetchDepartment = createActionThunk(
  'departments/fetch-department',
  ({ id, emptyRequest, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .fetchOne(workspaceId, id)
      .then(({ data }) => ({ ...data, emptyRequest }));
  }
);

const fetchDepartmentsAction = ({
  search,
  parent,
  limit,
  offset,
  excludeType,
  lite,
  excludeDepartment,
  excludeWithChildren,
  getState
}) => {
  const state = getState();
  const workspaceId = getWorkspaceId(state);

  return api.departments
    .fetch({
      workspaceId,
      offset,
      limit,
      search,
      parent,
      excludeType,
      excludeDepartment,
      excludeWithChildren,
      lite
    })
    .then(res => ({
      entries: res.data.results,
      employeeCount: res.data.employeeCount
    }));
};

export const fetchDepartments = createActionThunk(
  'departments/fetch',
  fetchDepartmentsAction
);

export const fetchDepartmentsLocal = createActionThunk(
  'departments/fetch-select',
  fetchDepartmentsAction
);

export const fetchJoinsDepartment = createActionThunk(
  'departments/fetch-join-department',
  ({ parentId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .fetchJoins(workspaceId)
      .then(({ data }) => ({ parentId, employees: data }));
  }
);

export const fetchDepartmentEmployees = createActionThunk(
  'departments/fetch-department-employees',
  ({ departmentId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.employees
      .fetchAll({
        workspaceId,
        department: departmentId,
        limit: 1000,
        withPermissions: true
      })
      .then(({ data }) => ({
        departmentId,
        employees: data.results
      }));
  }
);

export const fetchPositionsDepartment = createActionThunk(
  'departments/fetch-positions-department',
  ({ search, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .fetchPositions(workspaceId, search.toLowerCase())
      .then(({ data }) => data);
  }
);

export const fetchDepartmentActions = createActionThunk(
  'departments/fetch-department-actions',
  ({ departmentId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .fetchActions(workspaceId, departmentId)
      .then(({ data }) => ({
        departmentId,
        actions: data
      }));
  }
);

export const filterDepartments = createActionThunk(
  'departments/filter-departments',
  ({ search, category, roleType, position, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .filter(workspaceId, search, category, roleType, position)
      .then(({ data }) => ({
        entries: data.results,
        employeeCount: data.employeeCount
      }));
  }
);

export const disbandDepartment = createActionThunk(
  'departments/disband-department',
  ({ departmentId, reason, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.departments
      .disband(workspaceId, departmentId, reason)
      .then(({ data }) => data);
  }
);

export const updateDepartmentEmployee = createAction(
  'departments/update-employee'
);

export const fetchDepartmentEmployee = createActionThunk(
  'departments/fetch-employee',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.employees.fetch(id, workspaceId).then(({ data }) => data);
  }
);

export const fetchAdmin = createActionThunk(
  'departments/fetch-admin',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const roleType = ADMIN;
    const isActive = true;

    return api.employees
      .fetchAll({
        workspaceId,
        isActive,
        roleType,
        withPermissions: true
      })
      .then(({ data }) => data.results[0]);
  }
);

export const assignController = createActionThunk(
  'departments/assign-controller',
  ({ employee, type, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .create(workspaceId, { employee, type })
      .then(response => {
        dispatch(fetchDepartmentEmployee({ id: employee }));
        return response;
      });
  }
);

export const updateController = createActionThunk(
  'departments/update-controller',
  ({ employee, type, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.controllers
      .update(workspaceId, employee, type)
      .then(response => {
        // Update employee roles
        dispatch(fetchDepartmentEmployee({ id: employee }));
        return response;
      });
  }
);

export const kickController = createActionThunk(
  'departments/kick-controller',
  ({ employees, getState, dispatch }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .remove(workspaceId, employees)
      .then(response => {
        // Update employee roles
        dispatch(fetchDepartmentEmployee({ id: employees.from }));
        return response;
      });
  }
);

export const createDepartmentWithInvite = createActionThunk(
  'departments/create-invite',
  ({ name, managerEmail, employeeEmails, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const manager = managerEmail === '' ? undefined : managerEmail;

    return api.departments.createWithInvite(
      workspaceId,
      name,
      manager,
      employeeEmails
    );
  }
);

export const deleteDepartment = createActionThunk(
  'departments/delete',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.departments.delete(workspaceId, id);
  }
);

export const updateDepartment = createActionThunk(
  'departments/update',
  ({ id, name, manager, employees, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.departments
      .update(workspaceId, id, name, manager, employees)
      .then(({ data }) => data);
  }
);

export const joinDepartment = createActionThunk(
  'departments/join',
  ({ getState, ...departmentValues }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.join({
      workspaceId,
      ...departmentValues
    });
  }
);

export const becomeOwner = createActionThunk(
  'departments/become-owner',
  ({ getState, position }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees.becomeOwner(workspaceId, position);
  }
);

export const createDepartment = createActionThunk(
  'departments/create-department',
  ({ getState, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.departments
      .create({ workspaceId, data })
      .then(response => response.data);
  }
);

export const moveDepartment = createActionThunk(
  'departments/move-department',
  ({ getState, id, parentDepartmentId, reason }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.departments
      .move({ workspaceId, id, parentDepartmentId, reason })
      .then(response => response.data);
  }
);

export const setFilterSearch = createAction('departments/set-filter-search');

export const setFilterCategories = createAction(
  'departments/set-filter-categories'
);

export const setFilterRoleType = createAction(
  'departments/set-filter-role-type'
);

export const setFilterPosition = createAction(
  'departments/set-filter-position'
);

export const setNeedRefetchDepartments = createAction(
  'departments/set-need-refetch-departments'
);

export const clearFilter = createAction('departments/clear-filter');
