export const getFileIds = fileList =>
  (fileList || []).reduce((acc, curr) => {
    const fileId =
      typeof curr === 'string' || typeof curr === 'number'
        ? curr
        : curr.id || (curr.response || {}).id || curr.fileId;

    if (fileId) {
      return [...acc, fileId];
    }

    return acc;
  }, []);

export const getFileIdsV2 = fileList =>
  (fileList || []).reduce((acc, curr) => {
    const fileId =
      typeof curr === 'string' || typeof curr === 'number'
        ? curr
        : curr.fileId || curr.id || (curr.response || {}).id;

    if (fileId) {
      return [...acc, fileId];
    }

    return acc;
  }, []);

export const deleteFile = (fileList, file) =>
  (fileList || []).reduce((acc, curr) => {
    const fileId = curr.id || (curr.response || {}).id;
    const removeFileId = file.id || (file.response || {}).id;

    if (fileId !== removeFileId) {
      acc.push(curr);
    }

    return acc;
  }, []);

export default getFileIds;
