import React from 'react';

export const HistoryUndoIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2857 14.1074H13.2142C13.1357 14.1074 13.0714 14.0431 13.0714 13.9646V4.71446H4.39269V6.01804C4.39269 6.13769 4.25341 6.20554 4.16055 6.13054L1.62659 4.13052C1.60952 4.11716 1.59571 4.10008 1.58621 4.08059C1.57671 4.0611 1.57178 4.0397 1.57178 4.01802C1.57178 3.99634 1.57671 3.97494 1.58621 3.95545C1.59571 3.93596 1.60952 3.91888 1.62659 3.90552L4.16055 1.9055C4.25519 1.83049 4.39269 1.89835 4.39269 2.018V3.3573H13.2857C13.916 3.3573 14.4285 3.8698 14.4285 4.50017V13.9646C14.4285 14.0431 14.3642 14.1074 14.2857 14.1074Z"
      fill="currentColor"
    />
  </svg>
);

export default HistoryUndoIcon;
