import { PAYMENT_ACCOUNT_STATUS_BLOCKED } from 'constants/billing';

import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';

const checkActiveByAccountStatus = workspace => {
  const { account, user } = workspace;

  if (account.status !== PAYMENT_ACCOUNT_STATUS_BLOCKED) {
    return true;
  }

  return checkPaymentAccessByRoles(user.roles);
};

const getActiveWorkspaces = workspaces => {
  const workspacesList = Array.isArray(workspaces)
    ? workspaces
    : Object.values(workspaces);

  return workspacesList.filter(
    w => !w.isArchived && checkActiveByAccountStatus(w)
  );
};

export default getActiveWorkspaces;
