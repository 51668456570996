export default api => ({
  changeState: ({ workspaceId, id, state, fileList }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/hiring/change-state/`,
      {
        state,
        fileList      
      }
    ),
  reopen: ({ workspaceId, id, reason, fileList }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/hiring/reopen/`, {
      reason,
      fileList
    }),
  cancel: ({ workspaceId, id, reason, fileList }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/hiring/cancel/`, {
      reason,
      fileList
    })
});
