import React from 'react';

const styles =
  '#ebwy1orE6nq3_tr {animation: ebwy1orE6nq3_tr__tr 3000ms linear infinite normal forwards}@keyframes ebwy1orE6nq3_tr__tr { 0% {transform: translate(8px,8.5px) rotate(0deg)} 100% {transform: translate(8px,8.5px) rotate(1080deg)}} #ebwy1orE6nq4_tr {animation: ebwy1orE6nq4_tr__tr 3000ms linear infinite normal forwards}@keyframes ebwy1orE6nq4_tr__tr { 0% {transform: translate(8px,8.5px) rotate(0deg)} 100% {transform: translate(8px,8.5px) rotate(360deg)}}';

export const AnimatedClockCircleIcon = () => (
  <svg
    id="ebwy1orE6nq1"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <style>{styles}</style>
    <path
      d="M8,1C4.13437,1,1,4.13437,1,8s3.13437,7,7,7s7-3.1344,7-7-3.1344-7-7-7ZM8,13.8125c-3.20937,0-5.8125-2.6031-5.8125-5.8125s2.60313-5.8125,5.8125-5.8125s5.8125,2.60313,5.8125,5.8125-2.6031,5.8125-5.8125,5.8125Z"
      fill="currentColor"
    />
    <g id="ebwy1orE6nq3_tr" transform="translate(8,8.5) rotate(0)">
      <path
        d="M8,4.5v4"
        transform="translate(-8,-8.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
    <g id="ebwy1orE6nq4_tr" transform="translate(8,8.5) rotate(0)">
      <path
        d="M10.5,10.5L8,8.5"
        transform="translate(-8,-8.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default AnimatedClockCircleIcon;
