export const CURRENCY_USD = 'USD';
export const CURRENCY_EUR = 'EUR';
export const CURRENCY_BYN = 'BYN';
export const CURRENCY_RUB = 'RUB';

export const CURRENCY_OPTIONS = [
  {
    value: CURRENCY_USD,
    label: 'USDBtn',
    ns: 'ChangePlan'
  },
  {
    value: CURRENCY_EUR,
    label: 'EURBtn',
    ns: 'ChangePlan'
  },
  {
    value: CURRENCY_BYN,
    label: 'BYNBtn',
    ns: 'ChangePlan'
  },
  {
    value: CURRENCY_RUB,
    label: 'RUBBtn',
    ns: 'ChangePlan'
  }
];

export const CURRENCY_MAP = {
  [CURRENCY_USD]: '$',
  [CURRENCY_EUR]: '€',
  [CURRENCY_BYN]: 'BYN',
  [CURRENCY_RUB]: '₽'
};

export const PERIOD_MONTH = 'month';
export const PERIOD_YEAR = 'year';

export const PERIOD_OPTIONS = [
  {
    value: PERIOD_YEAR,
    label: 'ForYearBtn',
    additionLabel: 'YearBtnPercent',
    ns: 'ChangePlan'
  },
  {
    value: PERIOD_MONTH,
    label: 'ForMonthBtn',
    ns: 'ChangePlan'
  }
];

export const PRICE_MAP = {
  [PERIOD_YEAR]: {
    [CURRENCY_USD]: 'usdPriceYear',
    [CURRENCY_EUR]: 'eurPriceYear',
    [CURRENCY_BYN]: 'bynPriceYear',
    [CURRENCY_RUB]: 'rubPriceYear'
  },
  [PERIOD_MONTH]: {
    [CURRENCY_USD]: 'usdPriceMonth',
    [CURRENCY_EUR]: 'eurPriceMonth',
    [CURRENCY_BYN]: 'bynPriceMonth',
    [CURRENCY_RUB]: 'rubPriceMonth'
  }
};

export const TRANSACTION_TYPE_RECCURING_PAY = 'recurring_pay';

export const TRANSACTION_STATUS_FAILED = 'failed';
export const TRANSACTION_STATUS_SUCCESS = 'success';

export const CARD_BRAND_VISA = 'VISA';
export const CARD_BRAND_MASTERCARD = 'MASTERCARD';
export const CARD_BRAND_BELCART = 'БЕЛКАРТ';

export const PAYMENT_ACCOUNT_STATUS_ACTIVE = 'active';
export const PAYMENT_ACCOUNT_STATUS_EXPIRED = 'expired';
export const PAYMENT_ACCOUNT_STATUS_BLOCKED = 'blocked';

export const PAYMENT_FAILD_NOTIFICATION_TEMPLATE_IDS = [77, 79];

export const ADDON_TYPE_WHATSAPP = 'whatsapp';
