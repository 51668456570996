import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ASSET } from 'constants/index';

import Icon from 'components/common/icon';

import { subscribeAsset, unsubscribeAsset } from 'store/subscriptions';

import { showNoticeMessage } from 'services/notice';

import styles from './subscription-asset-btn.module.scss';

const SubscriptionAssetBtn = ({
  iconSize,
  assetId,
  isSubscribed,
  subscribeAssetCallback,
  unsubscribeAssetCallback,
  actionDeps
}) => {
  const { t } = useTranslation('Asset');
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const tooltipText = isSubscribed ? 'UnsubscribeTooltip' : 'SubscribeTooltip';
  const icon = isSubscribed ? 'subscribe-filled' : 'subscribe';

  const handleSubscribeAsset = async () => {
    setIsLoading(true);

    dispatch(
      subscribeAsset({
        entityType: ASSET,
        entityId: assetId,
        actionDeps
      })
    )
      .then(data => {
        subscribeAssetCallback({ data, isSubscribed: true });
        showNoticeMessage();
      })
      .finally(() => setIsLoading(false));
  };

  const handleUnsubscribeAsset = () => {
    setIsLoading(true);

    dispatch(
      unsubscribeAsset({
        entityType: ASSET,
        entityId: assetId,
        actionDeps
      })
    )
      .then(data => {
        unsubscribeAssetCallback({ data, isSubscribed: false });
        showNoticeMessage();
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubscription = () =>
    isSubscribed ? handleUnsubscribeAsset() : handleSubscribeAsset();

  return (
    <Tooltip title={t(tooltipText)} mouseEnterDelay={0.5}>
      <Button
        size="small"
        type="text"
        className={styles.btn}
        onClick={handleSubscription}
        disabled={isLoading}
      >
        <Icon type={icon} size={iconSize} color="brand" />
      </Button>
    </Tooltip>
  );
};

SubscriptionAssetBtn.propTypes = {
  iconSize: PropTypes.number,
  assetId: PropTypes.number.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  subscribeAssetCallback: PropTypes.func,
  unsubscribeAssetCallback: PropTypes.func,
  actionDeps: PropTypes.shape({
    entityId: PropTypes.number
  })
};

SubscriptionAssetBtn.defaultProps = {
  iconSize: 20,
  subscribeAssetCallback: () => {},
  unsubscribeAssetCallback: () => {},
  actionDeps: {}
};

export default SubscriptionAssetBtn;
