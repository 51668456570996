import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProjectCreatorDrawer from 'components/tasks-view/projects-view/drawers/creator';

import { fetchProjectsLocal } from 'store/projects';

import CustomSelect from '../custom-select';
import CommonOption from '../custom-select/common-option';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const ProjectSelect = ({
  isMulti,
  value,
  onChange,
  callback,
  ...props
}) => {
  const dispatch = useDispatch();

  const [visibleCreator, setVisibleCreator] = useState(false);

  const { t } = useTranslation('Common');

  const addEntityButtonData = {
    title: t('AddProject'),
    onClick: () => setVisibleCreator(true)
  };

  const handleChange = values => {
    onChange(values);
    callback();
  };

  const creatableCallback = values => {
    const transfromed = [values].map(mapValue)[0];
    handleChange(isMulti ? [...(value || []), transfromed] : transfromed);
  };

  const fetchData = params =>
    dispatch(fetchProjectsLocal({ lite: true, ...params, status: null })).then(
      ({ entries, ...res }) => ({
        ...res,
        entries: entries.map(mapValue)
      })
    );

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        placeholder={t('SearchForProjects')}
        valueText={t('ChooseProject')}
        fetchData={fetchData}
        Option={CommonOption}
        addEntityButtonData={addEntityButtonData}
        value={value}
        isMulti={isMulti}
        onChange={handleChange}
        {...props}
      />

      <ProjectCreatorDrawer
        visible={visibleCreator}
        isRedirectAfterSubmit={false}
        callback={creatableCallback}
        onClose={() => setVisibleCreator(false)}
      />
    </>
  );
};

ProjectSelect.propTypes = {
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string,
  showSelectedOptionSeparately: PropTypes.bool,
  callback: PropTypes.func
};

ProjectSelect.defaultProps = {
  isLinkToElement: false,
  selectType: '',
  showSelectedOptionSeparately: false,
  callback: () => {}
};

export default ProjectSelect;
