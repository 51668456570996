import React from 'react';
import PropTypes from 'prop-types';

import { STATUS_IMPORT_CONTACTS_FINISHED } from 'constants/contacts';

import Modal from 'components/common/modal';

import { SuccessImportContacts } from './components';

const ImportContactsSuccessModal = ({ visible, onClose, data }) => {
  const isStatusFinished =
    data && data.status === STATUS_IMPORT_CONTACTS_FINISHED;

  return (
    <Modal
      width={440}
      visible={visible}
      onClose={isStatusFinished ? onClose : undefined}
      contentStyle={{ padding: 24 }}
      centered
      closable={isStatusFinished}
      destroyOnClose
    >
      <SuccessImportContacts data={data} onClose={onClose} />
    </Modal>
  );
};
export default ImportContactsSuccessModal;

ImportContactsSuccessModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

ImportContactsSuccessModal.defaultProps = {
  visible: false,
  onClose: () => {}
};
