import { LOCATION_CHANGE } from 'connected-react-router';
import i18n from 'i18n-config';

import {
  ALL_OPTION,
  BENCH,
  DEPARTMENTS,
  JOINS,
  ORGSTRUCTURE,
  SUPERIOR,
  TEAM,
  WORKSPACE_JOIN
} from 'constants/index';

import { setActiveId } from 'store/workspace';
import { makeSubscribeLocationChange } from 'store/router';

import handleActions from 'utils/redux-actions';
import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';

import {
  fetchDepartments,
  fetchJoinsDepartment,
  updateDepartment,
  fetchDepartmentEmployees,
  fetchDepartmentActions,
  filterDepartments,
  fetchDepartmentEmployee,
  clearFilter,
  setFilterSearch,
  setFilterCategories,
  setFilterRoleType,
  updateDepartmentEmployee,
  fetchAdmin,
  setFilterPosition,
  createDepartmentWithInvite,
  createDepartment,
  setNeedRefetchDepartments
} from './actions';

export const initialFilter = {
  search: '',
  roleType: ALL_OPTION,
  categories: [],
  position: []
};

const initialState = {
  employeeCount: 0,

  filter: getInitialValueFilterStorage(DEPARTMENTS, initialFilter, () => {}),

  admin: undefined,
  entries: [],

  needRefetchDepartments: false
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(TEAM, ORGSTRUCTURE),

    [setActiveId]: () => initialState,

    [fetchDepartments.SUCCEEDED]: (state, { payload }) => {
      state.entries = payload.entries.map(d => ({ ...d, employees: [] }));
      state.employeeCount = payload.employeeCount;

      return state;
    },

    [fetchJoinsDepartment.SUCCEEDED]: (state, { payload }) => {
      if (payload.employees.length) {
        state.entries = [
          ...state.entries,
          {
            id: JOINS,
            type: JOINS,
            name: i18n.t('ActiveRequests', { ns: 'Team' }),
            parentId: payload.parentId,
            permissions: {},
            employeeCount: payload.employees.length,
            employees: payload.employees.map(({ employee, ...action }) => ({
              ...employee,
              action: {
                ...action,
                type: WORKSPACE_JOIN
              }
            }))
          }
        ];
      }

      return state;
    },

    [fetchDepartmentEmployee.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        ({ id }) => id === payload.departmentId
      );

      if (index !== -1) {
        if (state.entries[index].manager.id === payload.id) {
          state.entries[index].manager = {
            ...state.entries[index].manager,
            ...payload
          };

          return state;
        }

        const employeeIndex = state.entries[index].employees.findIndex(
          ({ id }) => id === payload.id
        );

        if (employeeIndex !== -1) {
          state.entries[index].employees[employeeIndex] = {
            ...state.entries[index].employees[employeeIndex],
            ...payload
          };
        } else {
          state.entries[index].employees = [payload];
        }
      }

      return state;
    },

    [updateDepartmentEmployee]: (state, { payload }) => {
      const index = state.entries.findIndex(
        ({ id }) => id === payload.departmentId
      );

      if (index !== -1) {
        if ((state.entries[index].manager || {}).id === payload.id) {
          state.entries[index].manager = {
            ...state.entries[index].manager,
            ...payload
          };

          return state;
        }

        const employeeIndex = state.entries[index].employees.findIndex(
          ({ id }) => id === payload.id
        );

        if (employeeIndex !== -1) {
          state.entries[index].employees[employeeIndex] = {
            ...state.entries[index].employees[employeeIndex],
            ...payload
          };
        }
      }

      return state;
    },

    [fetchDepartmentEmployees.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        ({ id }) => id === payload.departmentId
      );

      const getInvitedManager = invitations => {
        const manager = (invitations || []).find(
          ({ position }) => position === SUPERIOR
        );

        if (manager) {
          return {
            ...manager,
            id: null, // Для того чтобы нелья было по ним переходить
            firstName: manager.email,
            position: null
          };
        }

        return undefined;
      };

      if (index !== -1) {
        const invitedManager = getInvitedManager(
          state.entries[index].invitations
        );

        const manager =
          payload.employees.find(
            ({ id }) => id === state.entries[index].managerId
          ) || invitedManager;

        state.entries[index] = {
          ...state.entries[index],
          manager,
          employees: payload.employees.filter(
            ({ id }) => id !== state.entries[index].managerId
          )
        };
      }

      return state;
    },

    [fetchAdmin.SUCCEEDED]: (state, { payload }) => {
      state.admin = payload;

      return state;
    },

    [fetchDepartmentActions.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        ({ id }) => id === payload.departmentId
      );

      if (index !== -1) {
        payload.actions.forEach(({ employeeId, ...action }) => {
          const employeeIndex = state.entries[index].employees.findIndex(
            ({ id }) => id === employeeId
          );

          if ((state.entries[index].manager || {}).id === employeeId) {
            state.entries[index].manager = {
              ...state.entries[index].manager,
              action
            };
          }

          if (employeeIndex !== -1) {
            state.entries[index].employees[employeeIndex] = {
              ...state.entries[index].employees[employeeIndex],
              action
            };
          }
        });
      }

      return state;
    },

    [updateDepartment.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (index !== -1) {
        state.entries[index] = { ...state.entries[index], ...payload };
      }

      return state;
    },

    [createDepartmentWithInvite.SUCCEEDED]: (state, { args }) => {
      if (args.id) {
        const index = state.entries.findIndex(item => item.id === args.id);

        if (index !== -1) {
          state.entries[index].name = args.name;
        }
      }

      return state;
    },

    [createDepartment.SUCCEEDED]: (state, { payload, args }) => {
      const { employeeCount, data } = args;
      const { manager, employees } = data;

      const allEmployees = [...employees, manager];

      const entries = state.entries.map(d => ({
        ...d,
        employees: d.employees.filter(e => !allEmployees.includes(e.id))
      }));

      const bench = entries.find(({ type }) => type === BENCH);
      const joins = entries.find(({ type }) => type === JOINS);

      const entriesWithoutBenchJoins = entries.filter(
        ({ id }) => id !== (bench || {}).id && id !== (joins || {}).id
      );

      state.entries = [
        ...entriesWithoutBenchJoins,
        { ...payload, employeeCount, employees: [] }
      ];

      if (bench) {
        state.entries.push(bench);
      }

      if (joins) {
        state.entries.push(joins);
      }

      return state;
    },

    [filterDepartments.SUCCEEDED]: (state, { payload }) => {
      state.entries = payload.entries.map(d => {
        const manager = d.employees.find(e => e.id === d.managerId);

        if (manager) {
          return {
            ...d,
            manager: { ...manager, departmentId: d.id, department: d },
            employees: d.employees
              .filter(e => e.id !== d.managerId)
              .map(e => ({ ...e, departmentId: d.id, department: d }))
          };
        }

        return {
          ...d,
          employees: d.employees.map(e => ({
            ...e,
            departmentId: d.id,
            department: d
          }))
        };
      });

      state.employeeCount = payload.employeeCount;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterCategories]: (state, { payload }) => {
      state.filter.categories = payload;

      return state;
    },

    [setFilterRoleType]: (state, { payload }) => {
      state.filter.roleType = payload;

      return state;
    },

    [setFilterPosition]: (state, { payload }) => {
      state.filter.position = payload;

      return state;
    },

    [setNeedRefetchDepartments]: (state, { payload }) => {
      state.needRefetchDepartments = payload;

      return state;
    },

    [clearFilter]: state => {
      state.filter = initialFilter;

      return state;
    }
  },
  initialState
);
