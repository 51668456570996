import React from 'react';
import ReactPlayer from 'react-player';

export const VideoItem = ({ style, ...props }) => (
  <ReactPlayer
    controls
    playing
    width="1000px"
    height="600px"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      ...(style || {})
    }}
    {...props}
  />
);

export default VideoItem;
