import React from 'react';

export const OnlineChatIcon = () => {
  const uuid1 = crypto.randomUUID();
  const uuid2 = crypto.randomUUID();

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${uuid1})`}>
        <path
          d="M12 24.1279C18.6274 24.1279 24 18.7553 24 12.1279C24 5.50051 18.6274 0.12793 12 0.12793C5.37258 0.12793 0 5.50051 0 12.1279C0 18.7553 5.37258 24.1279 12 24.1279Z"
          fill={`url(#${uuid2})`}
        />
        <path
          d="M6.75537 11.4368C6.0887 11.9037 5.91006 12.7989 6.35636 13.4364L9.15063 17.4275C9.18791 17.4808 9.22838 17.5306 9.27164 17.5768C9.40033 17.7907 9.5863 17.9781 9.82195 18.1142C10.5233 18.519 11.3992 18.3149 11.7784 17.6581L17.3936 7.93442C17.7729 7.27754 17.5117 6.41665 16.8102 6.0117C16.1088 5.60686 15.2329 5.81102 14.8537 6.46772L10.434 14.1212L8.77102 11.7458C8.32464 11.1082 7.42216 10.9699 6.75537 11.4368Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={uuid2}
          x1="31.7652"
          y1="10.316"
          x2="19.8103"
          y2="-8.27482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3DF73" />
          <stop offset="1" stopColor="#3FBCA9" />
        </linearGradient>
        <clipPath id={uuid1}>
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.12793)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OnlineChatIcon;
