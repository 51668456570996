import React from 'react';

export const GoogleSyncIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="150" transform="translate(0.5)" fill="white" />
    <path
      d="M143.963 77.9063C143.863 77.592 142.706 74.7492 140.248 68.9349C140.248 68.9349 138.491 64.7635 136.577 61.2349C136.448 61.0063 136.377 60.892 136.248 60.6635C135.863 59.9778 135.477 59.292 135.063 58.692C134.663 58.0063 134.191 57.292 133.777 56.692C133.62 56.5492 133.577 56.3492 133.42 56.2063C132.806 55.2635 132.163 54.4063 131.477 53.4349C131.277 53.092 130.963 52.8063 130.777 52.4635C129.934 51.2635 128.991 50.1349 127.963 48.9778C127.891 48.8635 127.806 48.8349 127.834 48.7492C127.677 48.6063 127.606 48.492 127.448 48.3492C126.734 47.4778 125.906 46.5635 125.191 45.692L125.12 45.5778C125.063 45.4635 124.963 45.4349 124.991 45.3492C124.834 45.2063 124.763 45.092 124.606 44.9492C123.806 44.0492 122.891 43.1206 122.048 42.3063C118.106 38.4063 113.72 34.6778 109.02 31.3349C103.106 27.1778 96.6198 23.6349 89.7198 20.9492L89.3627 20.8492C88.4769 20.5063 87.6055 20.1635 86.7198 19.9206L86.3627 19.8206L86.2627 19.792C80.9912 17.8492 75.6912 16.6778 70.2627 16.0349C68.9627 15.8635 67.6198 15.792 66.1912 15.6778C64.8627 15.6063 63.6198 15.5492 62.4484 15.6063C61.1769 15.6492 59.8769 15.7635 58.6769 15.9206C42.2055 18.4349 35.5198 33.1349 28.6769 48.3778C27.3769 51.3063 26.0627 54.3349 24.6627 57.2349C21.6769 63.4778 18.3484 69.5206 13.9055 74.392C6.69123 85.4778 4.87694 96.5063 8.50551 105.463C8.66266 105.606 9.6198 107.035 10.1055 108.806C14.1198 115.635 21.5484 120.978 32.3055 123.763C40.8627 126.035 69.3769 135.678 96.1198 134.278C96.1198 134.278 100.034 134.106 105.291 133.335C124.291 130.463 130.634 123.992 132.22 121.421C132.948 120.163 133.248 119.092 133.248 119.092C133.777 116.135 131.548 113.678 132.748 106.178C135.248 91.5635 146.363 87.092 143.977 77.9063H143.963Z"
      fill="#EBF8F8"
    />
    <path
      d="M83.4198 28.0348L90.8055 65.8777C91.6198 70.0777 88.8769 74.1491 84.6769 74.9634L27.7341 86.0777C23.5341 86.892 19.4627 84.1491 18.6484 79.9634L11.2627 42.1205C10.4484 37.9205 13.1912 33.8491 17.3769 33.0348L74.3198 21.9205C78.5198 21.1062 82.5912 23.8348 83.4198 28.0348Z"
      fill="white"
    />
    <path
      d="M83.9557 30.5105L11.7623 44.6089L12.592 48.8572L84.7854 34.7588L83.9557 30.5105Z"
      fill="#F1F1F1"
    />
    <path
      d="M83.4198 28.0347L84.2198 32.149L12.0769 46.2347L11.2769 42.1204C10.4626 37.9204 13.2055 33.849 17.3912 33.0347L17.7912 32.9633L17.2341 30.0776C17.0198 29.0061 17.7198 27.9633 18.8055 27.749C19.8912 27.5347 20.9198 28.249 21.1341 29.3204L21.6912 32.2061L30.8626 30.4204L30.3055 27.5347C30.0912 26.4633 30.7912 25.4204 31.8769 25.2061C32.9626 24.9919 33.9912 25.7061 34.2055 26.7776L34.7626 29.6633L43.9341 27.8776L43.3769 24.9919C43.1627 23.9204 43.8627 22.8776 44.9484 22.6633C46.0341 22.449 47.0626 23.1633 47.2769 24.2347L47.8341 27.1204L57.0055 25.3347L56.4484 22.449C56.2341 21.3776 56.9341 20.3347 58.0198 20.1204C59.1055 19.9061 60.1341 20.6204 60.3484 21.6919L60.9055 24.5776L70.0769 22.7919L69.5198 19.9061C69.3055 18.8347 70.0055 17.7919 71.0912 17.5776C72.1769 17.3633 73.2055 18.0776 73.4198 19.149L73.9769 22.0347L74.3626 21.9633C78.5626 21.149 82.6341 23.8776 83.4627 28.0776L83.4198 28.0347Z"
      fill="url(#paint0_linear_71386_419017)"
    />
    <path
      d="M33.1179 50.4511L22.406 52.543C21.0044 52.8167 20.0901 54.1748 20.3638 55.5763L21.2236 59.9789C21.4973 61.3805 22.8554 62.2948 24.2569 62.0211L34.9689 59.9292C36.3705 59.6555 37.2848 58.2974 37.0111 56.8958L36.1513 52.4933C35.8776 51.0917 34.5195 50.1774 33.1179 50.4511Z"
      fill="#E2E2E2"
    />
    <path
      d="M55.0611 46.1679L44.3492 48.2598C42.9476 48.5335 42.0333 49.8916 42.307 51.2931L43.1668 55.6957C43.4405 57.0973 44.7985 58.0116 46.2001 57.7379L56.9121 55.646C58.3136 55.3723 59.2279 54.0142 58.9542 52.6126L58.0945 48.2101C57.8208 46.8085 56.4627 45.8942 55.0611 46.1679Z"
      fill="#E2E2E2"
    />
    <path
      d="M77.0043 41.8847L66.2924 43.9766C64.8908 44.2503 63.9765 45.6084 64.2502 47.0099L65.1099 51.4125C65.3836 52.8141 66.7417 53.7284 68.1433 53.4547L78.8552 51.3628C80.2568 51.0891 81.1711 49.731 80.8974 48.3294L80.0377 43.9269C79.7639 42.5253 78.4059 41.611 77.0043 41.8847Z"
      fill="#E2E2E2"
    />
    <path
      d="M36.2954 66.6718L25.5835 68.7637C24.1819 69.0374 23.2676 70.3955 23.5413 71.7971L24.4011 76.1996C24.6748 77.6012 26.0329 78.5155 27.4344 78.2418L38.1464 76.1499C39.5479 75.8762 40.4623 74.5181 40.1886 73.1165L39.3288 68.714C39.0551 67.3124 37.697 66.3981 36.2954 66.6718Z"
      fill="#E2E2E2"
    />
    <path
      d="M58.2386 62.3739L47.5267 64.4658C46.1251 64.7395 45.2108 66.0976 45.4845 67.4992L46.3442 71.9018C46.6179 73.3033 47.976 74.2176 49.3776 73.9439L60.0895 71.852C61.4911 71.5783 62.4054 70.2203 62.1317 68.8187L61.272 64.4161C60.9983 63.0146 59.6402 62.1002 58.2386 62.3739Z"
      fill="#E2E2E2"
    />
    <path
      d="M80.1818 58.0907L69.4698 60.1826C68.0683 60.4563 67.154 61.8144 67.4277 63.216L68.2874 67.6185C68.5611 69.0201 69.9192 69.9344 71.3208 69.6607L82.0327 67.5688C83.4343 67.2951 84.3486 65.937 84.0749 64.5355L83.2152 60.1329C82.9414 58.7313 81.5834 57.817 80.1818 58.0907Z"
      fill="#E2E2E2"
    />
    <path
      d="M129.648 74.7493L125.848 113.121C125.42 117.378 121.634 120.478 117.363 120.064L59.6198 114.335C55.3626 113.906 52.2626 110.121 52.6769 105.864L56.4769 67.4922C56.9055 63.2351 60.6912 60.135 64.9483 60.5493L122.691 66.2779C126.948 66.7065 130.063 70.4922 129.648 74.7493Z"
      fill="white"
    />
    <path
      d="M129.426 77.2673L56.2271 70.0127L55.8002 74.3202L128.999 81.5747L129.426 77.2673Z"
      fill="#F1F1F1"
    />
    <path
      d="M129.648 74.749L129.234 78.9204L56.0769 71.6633L56.4912 67.4919C56.9198 63.2347 60.7055 60.1347 64.9626 60.549L65.3626 60.5919L65.6483 57.6633C65.7626 56.5776 66.7198 55.7776 67.8198 55.8776C68.9198 55.9776 69.7055 56.9633 69.6055 58.049L69.3198 60.9776L78.6198 61.8919L78.9055 58.9633C79.0198 57.8776 79.9769 57.0776 81.0769 57.1776C82.1769 57.2776 82.9626 58.2633 82.8626 59.349L82.5769 62.2776L91.8769 63.1919L92.1626 60.2633C92.2769 59.1776 93.234 58.3776 94.334 58.4776C95.434 58.5776 96.2198 59.5633 96.1198 60.649L95.834 63.5776L105.134 64.5062L105.42 61.5776C105.534 60.4919 106.491 59.6919 107.591 59.7919C108.691 59.8919 109.477 60.8776 109.377 61.9633L109.091 64.8919L118.391 65.8062L118.677 62.8776C118.791 61.7919 119.748 60.9919 120.848 61.0919C121.948 61.1919 122.734 62.1776 122.634 63.2633L122.348 66.1919L122.734 66.2347C126.991 66.6633 130.105 70.449 129.691 74.7062L129.648 74.749Z"
      fill="#518EF8"
    />
    <mask
      id="mask0_71386_419017"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="77"
      y="83"
      width="27"
      height="27"
    >
      <path
        d="M79.9645 83.5247L77.6327 107.052L101.16 109.384L103.492 85.8564L79.9645 83.5247Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_71386_419017)">
      <path
        d="M83.7769 98.263L82.6483 101.234L79.6483 101.006C78.9198 99.263 78.6055 97.3059 78.8055 95.2916C79.0055 93.2773 79.6483 91.5487 80.6626 90.0059L83.2769 90.763L84.1769 93.5201C83.8626 94.2059 83.6483 94.963 83.5769 95.763C83.4912 96.6201 83.5769 97.463 83.7769 98.263Z"
        fill="#FBBB00"
      />
      <path
        d="M102.348 95.4056C102.42 96.1341 102.405 96.877 102.348 97.6198C102.263 98.4627 102.091 99.277 101.848 100.048C101.005 102.691 99.2769 104.891 97.0483 106.348L93.7055 105.848L93.5198 102.834C94.9769 102.163 96.1769 101.006 96.8912 99.5484L90.6055 98.9198L91.0626 94.2627L97.4483 94.8913L102.363 95.377L102.348 95.4056Z"
        fill="#518EF8"
      />
      <path
        d="M97.0197 106.363C94.8483 107.777 92.1912 108.491 89.4055 108.22C84.934 107.777 81.2769 104.891 79.6626 101.006L83.7769 98.2627C84.5055 101.006 86.8769 103.148 89.8769 103.448C91.1626 103.577 92.4055 103.348 93.5055 102.848L97.0197 106.363Z"
        fill="#28B446"
      />
      <path
        d="M98.9769 88.1486L94.8626 90.8915C93.8626 90.1201 92.634 89.6058 91.2769 89.4629C88.2054 89.1629 85.4055 90.8772 84.1912 93.5201L80.6769 90.0058C83.0055 86.4201 87.2055 84.2343 91.7483 84.6915C94.6055 84.9772 97.1197 86.2486 98.9912 88.1486H98.9769Z"
        fill="#F14336"
      />
    </g>
    <path
      d="M112.92 50.5638C112.106 50.5638 111.448 49.9067 111.448 49.0781V33.1781C111.448 32.3638 112.106 31.6924 112.92 31.6924C113.734 31.6924 114.391 32.3495 114.391 33.1781V49.0781C114.391 49.8924 113.734 50.5638 112.92 50.5638Z"
      fill="#32C9C9"
    />
    <path
      d="M120.877 42.6067H104.977C104.163 42.6067 103.505 41.9495 103.505 41.121C103.505 40.2924 104.163 39.6353 104.977 39.6353H120.877C121.691 39.6353 122.348 40.2924 122.348 41.121C122.348 41.9495 121.691 42.6067 120.877 42.6067Z"
      fill="#32C9C9"
    />
    <path
      d="M18.2626 121.821C17.6483 121.821 17.1483 121.321 17.1483 120.706V108.678C17.1483 108.063 17.6483 107.563 18.2626 107.563C18.8769 107.563 19.3769 108.063 19.3769 108.678V120.706C19.3769 121.321 18.8769 121.821 18.2626 121.821Z"
      fill="#32C9C9"
    />
    <path
      d="M24.2769 115.806H12.2483C11.634 115.806 11.134 115.306 11.134 114.692C11.134 114.078 11.634 113.578 12.2483 113.578H24.2769C24.8912 113.578 25.3912 114.078 25.3912 114.692C25.3912 115.306 24.8912 115.806 24.2769 115.806Z"
      fill="#32C9C9"
    />
    <path
      d="M36.9912 102.792C36.6341 102.792 36.3484 102.506 36.3484 102.149V95.1922C36.3484 94.835 36.6341 94.5493 36.9912 94.5493C37.3484 94.5493 37.6341 94.835 37.6341 95.1922V102.149C37.6341 102.506 37.3484 102.792 36.9912 102.792Z"
      fill="#32C9C9"
    />
    <path
      d="M40.4627 99.3209H33.5055C33.1484 99.3209 32.8627 99.0352 32.8627 98.678C32.8627 98.3209 33.1484 98.0352 33.5055 98.0352H40.4627C40.8198 98.0352 41.1055 98.3209 41.1055 98.678C41.1055 99.0352 40.8198 99.3209 40.4627 99.3209Z"
      fill="#32C9C9"
    />
    <defs>
      <linearGradient
        id="paint0_linear_71386_419017"
        x1="10.4537"
        y1="37.9562"
        x2="82.619"
        y2="23.8633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default GoogleSyncIcon;
