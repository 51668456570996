export const updateSubscribeNewAttachment = ({
  file,
  fileList,
  isSubscribed
}) => {
  const updatedAttachments = fileList.map(attachment =>
    attachment.response && attachment.response.id === file.response.id
      ? { ...attachment, isNewAttachmentSubscribed: isSubscribed }
      : attachment
  );

  return updatedAttachments;
};
