import React from 'react';

export const CommentIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08955 6.37496C8.67705 6.37496 8.35741 6.6946 8.35741 7.08925C8.35741 7.48389 8.67705 7.80353 9.08955 7.80353C9.46634 7.80353 9.78598 7.48389 9.78598 7.08925C9.78598 6.6946 9.46634 6.37496 9.08955 6.37496ZM4.08955 6.37496C3.67705 6.37496 3.35741 6.6946 3.35741 7.08925C3.35741 7.48389 3.67705 7.80353 4.08955 7.80353C4.46634 7.80353 4.78598 7.48389 4.78598 7.08925C4.78598 6.6946 4.46634 6.37496 4.08955 6.37496Z"
      fill="currentColor"
    />
    <path
      d="M14.8212 5.01642C13.995 3.87918 12.8037 3.05976 11.4462 2.695V2.69678C11.1408 2.3575 10.7962 2.045 10.4105 1.76642C7.48726 -0.358576 3.38369 0.289638 1.24976 3.21285C-0.469881 5.58785 -0.396667 8.7825 1.3569 11.0521L1.37119 13.42C1.37119 13.4771 1.38012 13.5343 1.39798 13.5879C1.42044 13.6594 1.45679 13.7258 1.50494 13.7833C1.5531 13.8408 1.61212 13.8883 1.67863 13.9229C1.74514 13.9576 1.81783 13.9788 1.89254 13.9853C1.96726 13.9919 2.04253 13.9836 2.11405 13.9611L4.37476 13.2486C4.97298 13.4611 5.59083 13.5825 6.20512 13.6164L6.19619 13.6236C7.78726 14.7825 9.87298 15.1307 11.7855 14.4986L14.0551 15.2379C14.1123 15.2557 14.1712 15.2664 14.2319 15.2664C14.548 15.2664 14.8033 15.0111 14.8033 14.695V12.3021C16.3765 10.1664 16.4176 7.21464 14.8212 5.01642ZM4.62476 11.9807L4.41048 11.8914L2.64262 12.445L2.62476 10.5879L2.4819 10.4271C0.97119 8.58428 0.87119 5.92892 2.28548 3.98071C4.00691 1.62 7.30691 1.09857 9.66048 2.80214C12.0212 4.51821 12.5444 7.81285 10.839 10.1593C9.40869 12.1218 6.84798 12.8468 4.62476 11.9807ZM13.6426 11.6771L13.4998 11.8557L13.5176 13.7129L11.7676 13.1236L11.5533 13.2129C10.5533 13.5843 9.48726 13.6146 8.49976 13.3379L8.49619 13.3361C9.85339 12.9199 11.0389 12.0746 11.8748 10.9271C13.239 9.04678 13.4605 6.68428 12.6676 4.67L12.6783 4.67714C13.089 4.97178 13.4658 5.33964 13.7855 5.78428C15.0819 7.56285 15.0087 9.98428 13.6426 11.6771Z"
      fill="currentColor"
    />
    <path
      d="M6.58955 6.37496C6.17705 6.37496 5.85741 6.6946 5.85741 7.08925C5.85741 7.48389 6.17705 7.80353 6.58955 7.80353C6.96634 7.80353 7.28598 7.48389 7.28598 7.08925C7.28598 6.6946 6.96634 6.37496 6.58955 6.37496Z"
      fill="currentColor"
    />
  </svg>
);

export default CommentIcon;
