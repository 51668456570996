import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Select, Input, Tooltip } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  REG_BOSS_INVITATION_EVENT,
  REG_SUBORDINATE_INVITATION_EVENT
} from 'constants/index';

import { LayoutAuth } from 'components/common/layout/template';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import {
  validateIsRequire,
  validateEmail,
  getValidEmails,
  validateMaxLength
} from 'utils/validators';

import styles from './styles.module.scss';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const splitValues = values => values.map(value => value.split(/[ ,]+/)).flat();

const CreateTeamForm = ({
  className,
  form: { validateFieldsAndScroll, getFieldDecorator, getFieldValue },
  data,
  isCreateWorkspace,
  allowInviteOwner,
  isLoading,
  onSubmit,
  onSkip
}) => {
  const amplitude = useAmplitude();

  const [employeeEmails, setEmployeeEmails] = useState([]);
  const [isShownMessageRed, setIsShownMessageRed] = useState(false);

  const { t } = useTranslation('InviteColleguesSignUp');

  const valueManager = getFieldValue('managerEmail');

  useEffect(() => {
    if (data.inviter && allowInviteOwner) {
      setEmployeeEmails([data.inviter]);
    }
  }, [data.inviter, allowInviteOwner]);

  const onChange = values => {
    const splitedValues = splitValues(values);
    const validEmails = getValidEmails(splitedValues);

    setEmployeeEmails(validEmails);
  };

  const handleSubmit = event => {
    event.preventDefault();

    validateFieldsAndScroll((err, values) => {
      if (!err && (!!values.managerEmail || hasEmployees)) {
        const amplitudeData = [REG_BOSS_INVITATION_EVENT];

        if ((employeeEmails || []).length) {
          amplitudeData.push(REG_SUBORDINATE_INVITATION_EVENT);
        }

        amplitude.colleagueInvitationEvent(amplitudeData);

        onSubmit({ ...values, employeeEmails });
      }
    });
  };

  const options = employeeEmails.map(email => (
    <Option key={email} disabled={email === data.inviter}>
      {email}
    </Option>
  ));

  const hasEmployees = !!employeeEmails.length;
  const hasManager = allowInviteOwner ? valueManager : true;

  return (
    <>
      <Form
        className={classnames(styles.form, className)}
        data-qa="qa-xdqaj7pyetihcsd"
        hideRequiredMark
        onSubmit={handleSubmit}
        colon={false}
      >
        <>
          {!isCreateWorkspace && (
            <Title
              level={1}
              className={styles.title}
              data-qa="qa-bai1ruinf7e4ex9"
            >
              {t('InviteColleguesSignUpHeading')}
            </Title>
          )}

          <Paragraph
            align={isCreateWorkspace ? 'left' : 'center'}
            color="black-35"
            style={{ marginBottom: isCreateWorkspace ? 0 : 24 }}
            data-qa="qa-pu7jo3kf6o7hdv8"
          >
            {t('InviteColleguesSignUpDesc')}
          </Paragraph>
        </>

        {allowInviteOwner && (
          <Form.Item
            label={
              <span>
                {t('YourImmediateSupervisor')} &nbsp;
                <Tooltip title={t('YourImmediateSupervisorTip')}>
                  <Icon type="question-circle" size={16} />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('managerEmail', {
              rules: [validateEmail()]
            })(
              <Input
                placeholder={t('EnterEmail')}
                size="large"
                data-qa="qa-17vgfbei99mbh6h"
              />
            )}
          </Form.Item>
        )}

        <Form.Item
          label={
            <span>
              {t('YourSubordinates')} &nbsp;
              <Tooltip title={t('YourSubordinatesTip')}>
                <Icon type="question-circle" size={16} />
              </Tooltip>
            </span>
          }
          style={{ marginBottom: hasEmployees ? 16 : 24 }}
          data-qa="qa-7qltrtklouyk1o6"
        >
          <Select
            placeholder={t('EnterEmail')}
            data-qa="qa-2y348av4qymecss"
            mode="tags"
            size="large"
            allowClear
            notFoundContent={null}
            value={employeeEmails}
            onChange={onChange}
          >
            {options}
          </Select>
        </Form.Item>

        {hasEmployees && hasManager && (
          <Form.Item label={t('YourDepartment')}>
            {getFieldDecorator('name', {
              rules: [validateIsRequire(), validateMaxLength(50)]
            })(
              <Input
                size="large"
                placeholder={t('YourDepartment')}
                data-qa="qa-bbnmjymft9lv1f9"
                autoComplete="off"
              />
            )}
          </Form.Item>
        )}
        <div
          className={classnames({
            [styles.btnsSubmitContainer]: isCreateWorkspace
          })}
          data-qa="qa-ggfy15ermnpkt24"
        >
          <div
            className={classnames(styles.column, {
              [styles.btnsSubmit]: isCreateWorkspace
            })}
            data-qa="qa-at4olzz200sun5e"
          >
            <Button
              disabled={!valueManager && !hasEmployees}
              type="primary"
              htmlType="submit"
              size="large"
              className={styles.btnSubmit}
              data-qa="qa-19sjestwbix5evy"
              loading={isLoading}
            >
              {t('SendInviteBtn')}
            </Button>

            <Button
              type="link"
              className={styles.btnSkip}
              data-qa="qa-yqwoomudah5c9il"
              onClick={onSkip}
              onMouseMove={() => setIsShownMessageRed(true)}
              onMouseLeave={() => setIsShownMessageRed(false)}
            >
              {t('SkipBtn')}
            </Button>
          </div>
          <div className={styles.messages} data-qa="qa-ynpkdhazhuk8owc">
            {!isShownMessageRed && (
              <div
                className={classnames(styles.message, styles.messageGreen)}
                data-qa="qa-9deqzyg8und63nk"
              >
                {t('GreenWarning')}
              </div>
            )}
            {isShownMessageRed && (
              <div
                className={classnames(styles.message, styles.messageRed)}
                data-qa="qa-p1uf090mnrri5qn"
              >
                {t('RedWarning')}
              </div>
            )}
          </div>
        </div>
      </Form>

      {/* В layout сделал ширину больше, если есть footer, по другому не придумал как (нужно по дизайну) */}
      <LayoutAuth.Footer>
        <div />
      </LayoutAuth.Footer>
    </>
  );
};

CreateTeamForm.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  allowInviteOwner: PropTypes.bool,
  isCreateWorkspace: PropTypes.bool,
  data: PropTypes.shape({
    inviter: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

CreateTeamForm.defaultProps = {
  className: undefined,
  isLoading: false,
  isCreateWorkspace: false,
  allowInviteOwner: true,
  data: {}
};

export default Form.create()(CreateTeamForm);
