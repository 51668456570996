import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';

import { getFIO, getFullName } from 'utils/get-fio';

export const PrevResponsible = ({ user }) => {
  const { t } = useTranslation('Task');

  const tooltip = (
    <div style={{ display: 'flex' }}>
      <Avatar
        src={(user.avatarFile || {}).url}
        size={32}
        isActive={user.isActive}
        style={{ marginRight: 8 }}
      >
        {getFIO(user)}
      </Avatar>

      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        data-qa="qa-rqpsmq2q7gbez5k"
      >
        <span style={{ fontWeight: 600, marginBottom: 4 }}>
          {getFullName(user)}
        </span>

        <span>{t('PreviousResponsibleTip')}</span>
      </div>
    </div>
  );

  return (
    <Typography.Paragraph
      style={{
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        margin: '0 auto 0 8px',
        overflow: 'hidden',
        minWidth: 158
      }}
    >
      <Tooltip title={tooltip} mouseEnterDelay={0.5}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Text color="black-55">
            ({t('PrevResponsible')}
          </Typography.Text>
          <Avatar
            src={(user.avatarFile || {}).url}
            size={24}
            style={{ margin: '0 8px' }}
          >
            {getFIO(user)}
          </Avatar>
          <Typography.Text ellipsis>{getFullName(user)}</Typography.Text>)
        </span>
      </Tooltip>
    </Typography.Paragraph>
  );
};

PrevResponsible.propTypes = {
  user: PropTypes.shape({
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

PrevResponsible.defaultProps = {
  user: {}
};

export default PrevResponsible;
