import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import moment from 'moment';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { STATUS_REJECTED, TASK_STATUS } from 'constants/tasks';
import { DATE_TIME_FORMAT } from 'constants/index';

import EventStatus from 'components/common/event-status';
import Typography from 'components/common/typography';
import { initialLoadingAnimation } from 'components/common/icons';
import Icon from 'components/common/icon';
import { renderDescriptions } from 'components/common/comments/converters';
import EmptyError from 'components/tasks-view/view/drawers/details/empty-error';
import CopyLink from 'components/common/copy-link';

import HistoryIssue from './components/history-issue';
import Actions from './components/actions';
import useDetailIssue from './use-detail-issue';

import styles from './detail-issue.module.scss';

const { Title, Text } = Typography;

export const DetailIssue = () => {
  const { t } = useTranslation('Task');

  const {
    data,
    isLoading,
    isLoadingAccepted,
    errorStatus,
    handleAction,
    getFilteredStatusChangeHistory,
    getReasonText,
    fetchIssueData
  } = useDetailIssue();

  useEffect(() => {
    if (!errorStatus) {
      fetchIssueData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={errorStatus ? styles.errorWrap : undefined}>
      {isLoading && !data && (
        <div className={styles.wrapLoader}>
          <Lottie
            animationData={initialLoadingAnimation}
            loop
            className={styles.loader}
          />
        </div>
      )}

      {!data && !isLoading && errorStatus && (
        <EmptyError status={errorStatus} />
      )}

      {data && (
        <>
          <div className={styles.issues}>
            <div className={styles.statusInfo}>
              <div className={styles.id}>
                <Text>ID {data.task.id}</Text>

                <CopyLink
                  link={window.location.href}
                  label={t('CopyIssueLinkTip')}
                />
              </div>

              <EventStatus
                id={1}
                statuses={TASK_STATUS}
                activeStatus={data.status}
                bordered
              />
            </div>

            {data.status === STATUS_REJECTED && (
              <div className={styles.reason}>
                {renderDescriptions(getReasonText())}
              </div>
            )}

            <Title level={3} className={styles.title}>
              {data.task.title}
            </Title>

            <div className={styles.createdAt}>
              <Icon type="calendar" size={16} color="black-55" />

              <Text color="black-55">
                {t('OrderCreatedDate')}{' '}
                <Text color="black">
                  {moment(data.task.createdAt).format(DATE_TIME_FORMAT)}
                </Text>
              </Text>
            </div>

            <Typography.Text>
              {renderDescriptions(data.messageText)}
            </Typography.Text>

            <Divider className={styles.divider} />

            <HistoryIssue
              history={getFilteredStatusChangeHistory()}
              createdAtTask={data.task.createdAt}
            />
          </div>

          <Actions
            feedbackLink={data.feedbackLink}
            status={data.status}
            handleAction={handleAction}
            isLoadingAccepted={isLoadingAccepted}
            workspaceId={data.task.workspaceId}
          />
        </>
      )}
    </div>
  );
};

export default DetailIssue;
