import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import { update } from 'store/assets';

import MainForm from '../../forms/main';

const CategoryChangeModal = ({ visible, onClose, isEditor, values }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ChangeJournal');

  const onSubmit = async category => {
    try {
      setIsLoading(true);

      await dispatch(
        update({ id: values.id, asset: category, isFromDetails: true })
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('ChangeJournalHeading')}
      contentStyle={{ padding: 24 }}
      centered
      visible={visible}
      width={508}
      onClose={onClose}
    >
      <MainForm
        values={values}
        isEditor={isEditor}
        isLoading={isLoading}
        onSubmit={onSubmit}
        isChangeCategory
      />
    </Modal>
  );
};

export default CategoryChangeModal;
