import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

import FormItem from '../form-item';

export const FormCheckbox = ({
  rules,
  name,
  itemProps,
  callback,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem name={name} {...itemProps} errors={formState.errors}>
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value: checked, onChange, ...renderProps } = field;

          const handleChange = event => {
            onChange(event);
            callback(event.target.checked);
          };

          return (
            <Checkbox
              checked={checked}
              onChange={handleChange}
              {...renderProps}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormCheckbox.propTypes = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  callback: PropTypes.func
};

FormCheckbox.defaultProps = {
  rules: {},
  itemProps: {},
  callback: () => {}
};

export default FormCheckbox;
