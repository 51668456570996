import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Badge } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

export const VerifiedPhone = ({
  children,
  isVerified,
  iconStyle,
  badgeProps
}) => {
  const { t } = useTranslation('Common');

  if (!isVerified) {
    return children || null;
  }

  return children ? (
    <Tooltip title={t('PhoneNumberConfirmed')} mouseEnterDelay={0.5}>
      <Badge
        color="transparent"
        count={
          <Icon
            type="safety"
            size={14}
            color="green"
            style={{ transform: 'translate(50%, -50%)', ...iconStyle }}
          />
        }
        offset={[-6, 18]}
        {...badgeProps}
      >
        {children}
      </Badge>
    </Tooltip>
  ) : (
    <Tooltip title={t('PhoneNumberConfirmed')} mouseEnterDelay={0.5}>
      <Icon type="safety" size={20} color="green" style={iconStyle} />
    </Tooltip>
  );
};

VerifiedPhone.propTypes = {
  children: PropTypes.node,
  isVerified: PropTypes.bool,
  iconStyle: PropTypes.object,
  badgeProps: PropTypes.object
};

VerifiedPhone.defaultProps = {
  children: null,
  isVerified: false,
  iconStyle: {},
  badgeProps: {}
};

export default VerifiedPhone;
