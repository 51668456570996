import React from 'react';
import classNames from 'classnames';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from '../../toolbar.module.scss';

const BoldItem = ({ editor, isBold }) => {
  const { t } = useTranslation('CommonChat');

  return (
    <Tooltip title={t('BoldFontTip')} mouseEnterDelay={0.5}>
      <Button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={classNames(styles.toolbarItem, styles.toolbarSpaced, {
          [styles.toolbarActive]: isBold
        })}
      >
        <Icon type="bold" size={16} />
      </Button>
    </Tooltip>
  );
};

export default BoldItem;
