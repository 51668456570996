import moment from 'moment';

export const useMinMaxTime = ({ startDate = new Date(), endDate }) => {
  const currentDate = moment(new Date());

  const minTimeStart = currentDate.isSame(startDate, 'day')
    ? moment()
        .toDate()
        .getTime()
    : moment()
        .toDate()
        .setHours(0, 0);

  const getMaxHoursDateStart = () => {
    if (endDate) {
      const { hours, minutes } = moment(endDate).toObject();

      if (moment(startDate).isSame(endDate, 'day')) {
        // Условие нужно, так как при добавлении даты начала, время даты завершение устанавливается на 00
        // Что приводит к тому, что время начала работы перестает работать
        return [hours || 23, hours ? minutes - 15 : 59]; // minus 15 minutes to avoid picking the same time
      }
    }

    return [23, 59];
  };

  const maxTimeStart = moment(endDate || new Date())
    .toDate()
    .setHours(...getMaxHoursDateStart());

  const isSameDate = moment(startDate || new Date()).isSame(endDate, 'day');

  const minTimeEnd = isSameDate
    ? moment(startDate || new Date())
        .toDate()
        .getTime() +
      15 * 60 * 1000 // added 15 minutes to avoid picking the same time
    : moment()
        .toDate()
        .setHours(0, 0);
  const maxTimeEnd = moment()
    .toDate()
    .setHours(23, 59);

  return [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd];
};

export default useMinMaxTime;
