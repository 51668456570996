import React, { createContext, useCallback, useMemo, useRef } from 'react';
import values from 'lodash/values';
import last from 'lodash/last';
import debounce from 'lodash/debounce';
import omitBy from 'lodash/omitBy';

const DEFAULT_DOCUMENT_TITLE = 'Upservice';

export const DocumentTitleContext = createContext({});

const setDocumentTitle = debounce(title => {
  document.title = title;
}, 300);

export const DocumentTitleProvider = ({ children }) => {
  const layerTitles = useRef({});

  const getLastTitle = () =>
    last(values(layerTitles.current)) || DEFAULT_DOCUMENT_TITLE;

  const setTitle = useCallback((title, elevation) => {
    const lastTitle = getLastTitle();

    if (lastTitle !== title) {
      layerTitles.current = {
        ...layerTitles.current,
        [elevation]: title
      };
    }

    setDocumentTitle(getLastTitle());
  }, []);

  const removeTitle = useCallback(title => {
    layerTitles.current = omitBy(
      layerTitles.current,
      _title => _title === title
    );

    setDocumentTitle(getLastTitle());
  }, []);

  const value = useMemo(
    () => ({
      layerTitles,
      setTitle,
      removeTitle
    }),
    [layerTitles, setTitle, removeTitle]
  );

  return (
    <DocumentTitleContext.Provider value={value}>
      {children}
    </DocumentTitleContext.Provider>
  );
};
