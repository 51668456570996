import { memo, useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const DynamicPortal = memo(({ id, children }) => {
  const el = useRef(
    document.getElementById(id) || document.createElement('div')
  );
  const [dynamic] = useState(!el.current.parentElement);

  useEffect(() => {
    if (dynamic) {
      el.current.id = id;
      document.body.appendChild(el.current);
    }
    return () => {
      if (dynamic && el.current.parentElement) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        el.current.parentElement.removeChild(el.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return createPortal(children, el.current);
});

export default DynamicPortal;
