import React from 'react';

export const TaskIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1876 0C12.9622 0 13.6248 0.275074 14.1747 0.825119C14.7247 1.37516 14.9998 2.03778 14.9998 2.81248V12.1874C14.9998 12.9621 14.7247 13.6246 14.1746 14.1748C13.6247 14.7249 12.9621 15 12.1874 15H2.81248C2.03781 15 1.37527 14.7249 0.82512 14.1748C0.275074 13.6246 0 12.9621 0 12.1874V2.81248C0 2.03778 0.275074 1.37527 0.82512 0.825119C1.37527 0.275074 2.03781 0 2.81248 0H12.1876ZM5.37577 5.5C5.37577 4.80984 4.81611 4.25 4.12596 4.25C3.43572 4.25 2.8761 4.80976 2.8761 5.5C2.8761 6.19024 3.43572 6.75 4.12596 6.75C4.81611 6.75 5.37577 6.19016 5.37577 5.5ZM12.1258 9.5C12.1258 8.94771 11.6781 8.5 11.1258 8.5H8.12577C7.57372 8.5 7.12642 8.94795 7.12642 9.5C7.12642 10.052 7.57372 10.5 8.12577 10.5H11.1258C11.6781 10.5 12.1258 10.0523 12.1258 9.5ZM5.37577 9.5C5.37577 8.80984 4.81611 8.25 4.12596 8.25C3.43572 8.25 2.8761 8.80976 2.8761 9.5C2.8761 10.1902 3.43572 10.75 4.12596 10.75C4.81611 10.75 5.37577 10.1902 5.37577 9.5ZM12.1258 5.5C12.1258 4.94772 11.6781 4.5 11.1258 4.5H8.12577C7.57372 4.5 7.12642 4.94795 7.12642 5.5C7.12642 6.05205 7.57372 6.5 8.12577 6.5H11.1258C11.6781 6.5 12.1258 6.05228 12.1258 5.5Z"
      fill="#36B7BD"
    />
  </svg>
);

export default TaskIcon;
