import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { changeIsFeedbackSent, sendFeedback } from 'store/user';
import { getUserEmployee } from 'store/workspace';

import packageJson from '../../../../package.json';
import Icon from '../icon';
import Button from '../button';
import Modal from '../modal';
import { InterviewIcon } from '../icons';
import Typography from '../typography';
import Form from './form';

const STAR_COUNT = 5;
const WEB = 'web';

export const FeedbackModal = ({ visible, onClose, eventName, ...props }) => {
  const dispatch = useDispatch();
  const employee = useSelector(getUserEmployee);

  const [rating, setRating] = useState(null);
  const [sended, setSended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ScreenErrors');

  const { version } = packageJson;

  const handleClose = () => {
    dispatch(changeIsFeedbackSent({ isFeedbackSent: false }));
    onClose();
  };

  const handleSubmit = async ({ content }) => {
    try {
      setIsLoading(true);

      await dispatch(
        sendFeedback({
          rating,
          content: !content && rating === STAR_COUNT ? undefined : content,
          employee: employee.id,
          user: employee.userId,
          eventName,
          platform: WEB,
          platformVersion: `v${version}`
        })
      );

      setSended(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      setRating(null);
      setSended(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      visible={visible}
      width={440}
      destroyOnClose
      maskClosable={false}
      closable={!rating}
      contentStyle={{
        padding: '60px 24px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      onClose={handleClose}
      {...props}
    >
      <Icon component={InterviewIcon} style={{ marginBottom: 10 }} />

      <Typography.Title level={1} weight="bold" style={{ meginBottom: 20 }}>
        {t(sended ? 'ThanksForRateHeading' : 'RateUpserviceHeading')}
      </Typography.Title>

      {sended ? (
        <Button type="primary" width="expanded" size="large" onClick={onClose}>
          {t('CloseBtn')}
        </Button>
      ) : (
        <Form
          rating={rating}
          onSubmit={handleSubmit}
          starCount={STAR_COUNT}
          btnProps={{
            loading: isLoading
          }}
          setRating={setRating}
        />
      )}
    </Modal>
  );
};

export default FeedbackModal;
