import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DETAILS_TASK, MODAL, SELECTED_TASK } from '../../constants';
import { getRouterUrlQuery } from '../../store/router';

export const useTaskDetailModal = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];
  const statusId = query[SELECTED_TASK];

  useEffect(() => {
    setVisible(!!(modal === DETAILS_TASK && statusId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [visible, setVisible];
};

export default useTaskDetailModal;
