import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  CALENDAR_RECORD_STATUS_ACCEPTED,
  CALENDAR_RECORD_STATUS_POSSIBLE,
  CALENDAR_RECORD_STATUS_REJECTED,
  STATUS_CANCELLED,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK
} from 'constants/index';

import { updateEmployeeRecordStatus } from 'store/calendar';

const transformTaskStatusToRecord = taskStatus => {
  const matchTaskStatusToRecord = {
    [STATUS_FOR_EXECUTION]: CALENDAR_RECORD_STATUS_POSSIBLE,
    [STATUS_IN_WORK]: CALENDAR_RECORD_STATUS_ACCEPTED,
    [STATUS_CANCELLED]: CALENDAR_RECORD_STATUS_REJECTED
  };

  return matchTaskStatusToRecord[taskStatus];
};

const useUpdateEmployeeRecordStatus = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = async ({ recordId, taskStatus, date = null }) => {
    const status = transformTaskStatusToRecord(taskStatus);

    try {
      setIsLoading(true);

      const record = await dispatch(
        updateEmployeeRecordStatus({
          id: recordId,
          data: {
            status,
            date
          }
        })
      );

      return record;
    } finally {
      setIsLoading(false);
    }
  };

  return { isUpdateStatusLoading: isLoading, updateStatus };
};

export default useUpdateEmployeeRecordStatus;
