import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import { OPTIONS_REASON_FOR_RETURN_ISSUE_MODAL } from 'components/issues-view/constants';

import Form from './form';

const ReasonForReturnIssueModal = ({ visible, onClose, onSubmit, options }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('BackTaskToWorkServiceDesc');

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      await onSubmit(values);

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={t('BackTaskToWorkHeading')}
      width={518}
      centered
      destroyOnClose
    >
      <Form isLoading={isLoading} onSubmit={handleSubmit} options={options} />
    </Modal>
  );
};

ReasonForReturnIssueModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.array
};

ReasonForReturnIssueModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onSubmit: () => {},
  options: OPTIONS_REASON_FOR_RETURN_ISSUE_MODAL
};

export default ReasonForReturnIssueModal;
