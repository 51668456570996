import dompurify from 'dompurify';

export const sanitizeHTML = (htmlString, santizeProps = {}) =>
  dompurify.sanitize(htmlString, {
    FORCE_BODY: true,
    ADD_ATTR: ['target'],
    FORBID_TAGS: ['img'],
    ...santizeProps
  });

export default sanitizeHTML;
