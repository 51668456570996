import React from 'react';
import { useDispatch } from 'react-redux';

import { searchWorkspacesLocal } from 'store/workspace';

import WorkspaceOption from '../custom-select/workspace-option';
import CustomSelect from '../custom-select';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const AllWorkspacesSelect = props => {
  const dispatch = useDispatch();

  const fetchData = params =>
    dispatch(
      searchWorkspacesLocal({
        ...params,
        search: params.search || undefined,
        limit: 25
      })
    ).then(({ count, results }) => ({
      totalItems: count,
      entries: results.map(mapValue)
    }));

  return (
    <CustomSelect
      isAsync
      isSearchable
      isClearable
      showSelectedOptionsOnList={false}
      Option={optionProps => (
        <WorkspaceOption {...optionProps} withWatNumber={false} />
      )}
      fetchData={fetchData}
      {...props}
    />
  );
};

export default AllWorkspacesSelect;
