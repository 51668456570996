export default api => ({
  sendMessage: ({ fileList, ...params }) => {
    const data = new FormData();

    Object.keys(params).forEach(key => {
      data.append(key, params[key]);
    });

    fileList.forEach((file, index) => {
      data.append(
        `file${index}`,
        file instanceof Blob ? file : file.originFileObj,
        file.name
      );
    });

    return api.post(`/v1/support/message/`, data);
  },

  sendMobileAppLink: phone => api.post(`/v1/support/mobile-app/`, { phone })
});
