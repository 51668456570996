import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import { SETTINGS } from 'constants/index';

import Icon from 'components/common/icon';
import { CancelSubscriptionIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Modal from 'components/common/modal';
import FormPaymentCardSelect from 'components/common/hook-form/select/payment-card';

import { retryLastPaymentTransaction } from 'store/billing';

import useRoutesService from 'services/routes';
import { showNoticeMessage } from 'services/notice';

import styles from './retry-payment.module.scss';

const Form = ({ isLoading, onSubmit, defaultValues, toBilling }) => {
  const methods = useForm({ defaultValues });

  const { t } = useTranslation(['ScreenErrors', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPaymentCardSelect
          name="card"
          label={t('PaymentMethod')}
          rules={{ required: t('RequierdField', { ns: 'Errors' }) }}
        />

        <div className={styles.buttons}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            className={styles.button}
            loading={isLoading}
          >
            {t('TryAgainBtn')}
          </Button>

          <Button type="link" className={styles.button} onClick={toBilling}>
            {t('PaymentSettingsBtn')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const RetryPaymentModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const routes = useRoutesService();

  const { t } = useTranslation(['ScreenErrors', 'Toast']);

  const { reason, primaryCard } = data;

  const retryPayment = async ({ card }) => {
    try {
      setIsLoading(true);

      const { url } = await dispatch(
        retryLastPaymentTransaction({ cardId: card.value })
      );

      if (url) {
        window.open(url, '_blank');
      } else {
        showNoticeMessage({
          customContent: t('PaymentWasSuccessful', { ns: 'Toast' })
        });
      }

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const toBilling = () => {
    routes.toBilling({ view: SETTINGS });

    onClose();
  };

  return (
    <Modal
      width={440}
      visible={visible}
      contentClassName={styles.root}
      onClose={onClose}
    >
      <Icon component={CancelSubscriptionIcon} />

      <Typography.Title level={1} weight="bold" className={styles.title}>
        {t('TryAgainHeading')}
      </Typography.Title>

      <Typography.Text color="black-45" className={styles.description}>
        {t('TryAgainDesc', { reason })}
      </Typography.Text>

      <Form
        isLoading={isLoading}
        defaultValues={{
          card: primaryCard
            ? {
                value: primaryCard.id,
                label: primaryCard
              }
            : undefined
        }}
        onSubmit={retryPayment}
        toBilling={toBilling}
      />
    </Modal>
  );
};

RetryPaymentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    reason: PropTypes.string,
    primaryCard: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

RetryPaymentModal.defaultProps = {
  data: {
    reason: '',
    primaryCard: {}
  }
};

export default RetryPaymentModal;
