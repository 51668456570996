import React from 'react';

export const EmailIcon = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6665 0.333336H2.33317C1.1415 0.333336 0.177337 1.30834 0.177337 2.5L0.166504 15.5C0.166504 16.6917 1.1415 17.6667 2.33317 17.6667H19.6665C20.8582 17.6667 21.8332 16.6917 21.8332 15.5V2.5C21.8332 1.30834 20.8582 0.333336 19.6665 0.333336ZM19.2332 4.9375L11.574 9.72583C11.2273 9.9425 10.7723 9.9425 10.4257 9.72583L2.7665 4.9375C2.49567 4.76417 2.33317 4.47167 2.33317 4.1575C2.33317 3.43167 3.124 2.99834 3.7415 3.3775L10.9998 7.91667L18.2582 3.3775C18.8757 2.99834 19.6665 3.43167 19.6665 4.1575C19.6665 4.47167 19.504 4.76417 19.2332 4.9375Z"
      fill="white"
    />
  </svg>
);

export default EmailIcon;
