import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Error404Icon } from 'components/common/icons';
import Icon from 'components/common/icon';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import useRoutesService from 'services/routes';

export const PageNotFound = ({ style }) => {
  const routes = useRoutesService();

  const { t } = useTranslation('ScreenErrors');

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        backgroundColor: 'white',
        width: '100vw',
        height: 'calc(100vh - 52px)',
        top: 0,
        left: 0,
        zIndex: 1000,
        ...style
      }}
    >
      <Empty
        image={<Icon component={Error404Icon} />}
        imageStyle={{ height: 'auto' }}
        style={{
          margin: 'auto auto'
        }}
        description={
          <>
            <Typography.Title
              level={1}
              style={{ fontWeight: 700, marginBottom: 20 }}
            >
              {t('PageNotFoundHeading')}
            </Typography.Title>

            <Button
              type="primary"
              size="large"
              width="expabnded"
              onClick={() => routes.toTasks()}
            >
              {t('GoToTasksBtn')}
            </Button>
          </>
        }
      />
    </div>
  );
};

PageNotFound.propTypes = {
  style: PropTypes.object
};

PageNotFound.defaultProps = {
  style: {}
};

export default PageNotFound;
