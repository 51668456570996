export const transfromCardNumber = number =>
  number.slice(-8).replace(/x/g, '*');

export const getPaymentCardText = (brand, number) => {
  if (!brand && !number) {
    return '';
  }

  return `${brand} ${transfromCardNumber(number)}`;
};
