import React from 'react';

export const ListDeletedIcon = ({ style = { width: 105, height: 105 } }) => (
  <svg
    style={style}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_100963_63738)">
      <path
        d="M73.4372 7.48439C75.1989 7.80664 76.9329 8.20946 78.6668 8.61228C87.1007 10.8009 95.9508 12.5868 104.371 14.4666L105.716 14.7888C111.431 16.0376 116.925 17.3266 121.974 18.817C123.777 19.3407 125.497 19.8509 127.19 20.4417C144.154 26.1215 154.128 34.4061 145.278 50.9083C144.515 52.3181 144.043 53.7817 143.738 55.393C142.559 62.1335 144.682 70.7404 145.888 79.3339C146.194 81.3882 146.402 83.4158 146.485 85.5104C146.596 87.9139 146.61 90.2905 146.36 92.506C145.999 96.3731 144.862 100.066 142.767 103.53C142.767 103.53 140.576 106.967 136.886 109.854C132.447 113.265 81.8573 144.658 38.3422 117.79C36.5805 116.675 -6.0468 89.5386 2.58131 52.0093C10.9597 15.4199 59.6489 5.02719 73.4372 7.48439Z"
        fill="#EBF8F8"
      />
      <path
        d="M107.888 64.695L101.96 123.609L101.905 124.122C101.46 127.218 98.4203 130.008 93.6867 132.132C92.6178 132.618 91.4656 133.062 90.2302 133.465C87.9952 134.214 85.5243 134.825 82.8451 135.269C79.2636 135.894 75.3351 136.227 71.2261 136.227C54.9429 136.227 41.6304 130.924 40.5477 124.206L40.4783 123.581L34.5508 64.695H107.902H107.888Z"
        fill="#C4C4C4"
      />
      <path
        d="M71.3363 85.2399H71.2669C68.5045 85.2399 66.2695 87.4749 66.2695 90.2373V121.152C66.2695 123.914 68.5045 126.149 71.2669 126.149H71.3363C74.0988 126.149 76.3337 123.914 76.3337 121.152V90.2373C76.3337 87.4749 74.0988 85.2399 71.3363 85.2399Z"
        fill="#E2E2E2"
      />
      <path
        d="M92.1317 85.3093H92.0623C89.3137 85.0872 86.8983 87.1417 86.6762 89.8902L84.2469 120.708C84.0248 123.456 86.0793 125.872 88.8417 126.094H88.9111C91.6597 126.316 94.0751 124.261 94.2972 121.513L96.7404 90.6954C96.9625 87.9468 94.908 85.5314 92.1456 85.3093H92.1317Z"
        fill="#E2E2E2"
      />
      <path
        d="M53.7344 126.205H53.8038C56.5524 125.983 58.6069 123.567 58.3987 120.819L55.9555 90.0013C55.7334 87.2527 53.3318 85.1982 50.5694 85.4065H50.5C47.7514 85.6286 45.6969 88.044 45.9052 90.7926L48.3483 121.61C48.5704 124.358 50.972 126.413 53.7344 126.205Z"
        fill="#E2E2E2"
      />
      <path
        d="M71.2262 81.6584C92.8384 81.6584 110.359 73.4421 110.359 63.3068C110.359 53.1715 92.8384 44.9553 71.2262 44.9553C49.6139 44.9553 32.0938 53.1715 32.0938 63.3068C32.0938 73.4421 49.6139 81.6584 71.2262 81.6584Z"
        fill="#E2E2E2"
      />
      <path
        d="M105.041 59.1562C105.041 62.9181 101.585 66.3192 95.9487 68.8456C94.7688 69.3731 93.5056 69.859 92.1452 70.3171C89.6881 71.1222 86.9673 71.8024 84.0244 72.3022C80.082 72.9824 75.7648 73.3572 71.2532 73.3572C52.5824 73.3572 37.4375 66.9994 37.4375 59.1562C37.4375 51.3131 52.5824 44.9553 71.2532 44.9553C89.9241 44.9553 105.069 51.3131 105.069 59.1562H105.041Z"
        fill="#C4C4C4"
      />
      <path
        d="M25.3055 83.0882C24.7503 83.6435 23.8341 83.6435 23.2788 83.0882L12.354 72.1633C11.7987 71.6081 11.7987 70.6919 12.354 70.1366C12.9092 69.5813 13.8254 69.5813 14.3807 70.1366L25.3055 81.0615C25.8608 81.6168 25.8608 82.5329 25.3055 83.0882Z"
        fill="#F9716C"
      />
      <path
        d="M25.3055 72.1633L14.3807 83.0882C13.8254 83.6435 12.9092 83.6435 12.354 83.0882C11.7987 82.5329 11.7987 81.6168 12.354 81.0615L23.2788 70.1366C23.8341 69.5813 24.7503 69.5813 25.3055 70.1366C25.8608 70.6919 25.8608 71.6081 25.3055 72.1633Z"
        fill="#F9716C"
      />
      <path
        d="M135.778 107.187C135.347 107.617 134.667 107.617 134.237 107.187L125.963 98.9133C125.533 98.483 125.533 97.8028 125.963 97.3724C126.394 96.9421 127.074 96.9421 127.504 97.3724L135.778 105.646C136.208 106.076 136.208 106.756 135.778 107.187Z"
        fill="#F9716C"
      />
      <path
        d="M135.778 98.9133L127.504 107.187C127.074 107.617 126.394 107.617 125.963 107.187C125.533 106.756 125.533 106.076 125.963 105.646L134.237 97.3724C134.667 96.9421 135.347 96.9421 135.778 97.3724C136.208 97.8028 136.208 98.483 135.778 98.9133Z"
        fill="#F9716C"
      />
      <path
        d="M130.681 73.8291C130.432 74.079 130.043 74.079 129.793 73.8291L125.004 69.04C124.754 68.7901 124.754 68.4014 125.004 68.1515C125.254 67.9017 125.642 67.9017 125.892 68.1515L130.681 72.9407C130.931 73.1906 130.931 73.5793 130.681 73.8291Z"
        fill="#F9716C"
      />
      <path
        d="M130.681 69.0539L125.892 73.8431C125.642 74.0929 125.254 74.0929 125.004 73.8431C124.754 73.5932 124.754 73.2045 125.004 72.9546L129.793 68.1655C130.043 67.9156 130.432 67.9156 130.681 68.1655C130.931 68.4153 130.931 68.804 130.681 69.0539Z"
        fill="#F9716C"
      />
      <path
        d="M98.2983 18.9116L78.3973 26.1407L76.5689 21.1074C75.9782 19.4812 76.8164 17.6866 78.4426 17.0959L92.4585 12.0046C94.0846 11.4139 95.8792 12.2521 96.4699 13.8783L98.2983 18.9116Z"
        fill="url(#paint0_linear_100963_63738)"
      />
      <path
        d="M104.676 17.6468C103.944 15.6335 101.727 14.598 99.714 15.3294L74.8571 24.3587C74.3924 24.5275 73.9421 24.2528 73.8014 23.8657L72.5356 20.381C71.8043 18.3677 69.5874 17.3322 67.5741 18.0636L54.952 22.6486C52.9387 23.3799 51.9032 25.5968 52.6346 27.6101L64.7581 60.9851C65.4894 62.9984 67.7063 64.0338 69.7196 63.3025L111.612 48.0848C113.626 47.3535 114.661 45.1366 113.93 43.1233L104.676 17.6468Z"
        fill="#FDA402"
      />
      <path
        d="M95.1108 24.3656L75.2098 31.5947L73.3814 26.5614C72.7907 24.9352 73.6289 23.1406 75.2551 22.5499L89.271 17.4586C90.8971 16.8679 92.6918 17.7061 93.2825 19.3323L95.1108 24.3656Z"
        fill="#1DC1C9"
      />
      <path
        d="M101.682 23.6428C100.866 21.3972 98.3325 20.2138 96.0868 21.0296L72.2365 29.6932C71.5396 29.9464 70.6951 29.5519 70.4419 28.855L69.5137 26.2996C68.6979 24.054 66.1644 22.8706 63.9187 23.6863L52.3033 27.9057C50.0577 28.7214 48.8743 31.2549 49.69 33.5006L61.4198 65.7914C62.2355 68.0371 64.769 69.2204 67.0147 68.4047L107.901 53.5527C110.147 52.737 111.33 50.2034 110.514 47.9578L101.682 23.6428Z"
        fill="#FFE181"
      />
      <path
        d="M72.8996 49.6132L65.6981 52.2292C63.6848 52.9605 62.6493 55.1774 63.3806 57.1907C64.112 59.204 66.3289 60.2395 68.3422 59.5082L75.5437 56.8922C77.5571 56.1608 78.5925 53.944 77.8612 51.9306C77.0524 49.9454 74.8355 48.91 72.8996 49.6132Z"
        fill="white"
      />
      <path
        d="M64.1666 3.57092L70.3444 9.93174C72.294 11.9486 61.2122 25.8736 45.6723 40.9683C30.1324 56.0631 15.8845 66.7392 13.9228 64.729L7.74498 58.3682C5.73762 56.3039 16.7652 42.4245 32.3714 27.2775C47.9776 12.1304 62.1592 1.50659 64.1666 3.57092Z"
        fill="#C4C4C4"
      />
      <path
        d="M39.0202 34.123C53.549 20.0124 64.0233 7.22975 62.4241 5.59045C60.8249 3.95115 47.7404 14.0347 33.2117 28.1452C18.6829 42.2557 8.20853 55.0384 9.80773 56.6778C11.4069 58.3171 24.4915 48.2335 39.0202 34.123Z"
        fill="#E2E2E2"
      />
      <path
        d="M46.0077 24.9073L29.7016 40.7405C28.7026 41.718 27.3629 42.2492 25.9647 42.2422C24.5666 42.2352 23.2394 41.6662 22.2564 40.686L19.2971 37.6403C18.3439 36.6279 17.8249 35.2815 17.8507 33.8888C17.8766 32.4961 18.4645 31.1744 19.4635 30.1969L35.7629 14.3515C36.7564 13.3929 38.0894 12.8495 39.4821 12.8754C40.8682 12.8891 42.1831 13.4648 43.1606 14.4638L45.9643 17.3417C46.4801 17.8184 46.8822 18.4052 47.1627 19.0589C47.4432 19.7126 47.5887 20.409 47.5979 21.1169C47.6072 21.8248 47.4668 22.5199 47.1945 23.1768C46.9222 23.8337 46.5167 24.4213 46.0077 24.9073ZM21.4708 32.2612C20.9862 32.7339 20.695 33.3853 20.6909 34.0689C20.6868 34.7524 20.9363 35.4074 21.4156 35.9042L24.2192 38.7821C24.6919 39.2667 25.3433 39.5579 26.0269 39.562C26.7104 39.5661 27.3654 39.3166 27.8622 38.8373L44.1682 23.0041C44.6529 22.5314 44.944 21.88 44.9482 21.1965C44.9523 20.5129 44.7028 19.8579 44.2235 19.3612L41.2641 16.3154C40.7915 15.8308 40.1401 15.5396 39.4565 15.5355C38.7729 15.5314 38.118 15.7809 37.6212 16.2602L21.4708 32.2612Z"
        fill="#E2E2E2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_100963_63738"
        x1="95.4937"
        y1="20.457"
        x2="78.1766"
        y2="17.8068"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <clipPath id="clip0_100963_63738">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ListDeletedIcon;
