/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState,
  useEffect,
  useCallback,
  createRef,
  useRef,
  useMemo
} from 'react';
import classnames from 'classnames';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const OFFSET_DEFAULT = {
  x: 0,
  y: 0
};

const ZOOM_LEVEL = {
  MIN: 0,
  MAX: 4
};

export const ImageWrapper = ({ showIndex, index, image, rotate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [zoom, setZoom] = useState(0);
  const [offset, setOffset] = useState(OFFSET_DEFAULT);
  const [isDraggable, setIsDraggable] = useState(false);
  const offsetRange = useRef(OFFSET_DEFAULT);
  const clientOffset = useRef({ x: undefined, y: undefined });

  const imageOuterRef = createRef();
  const imageRef = createRef();

  const loadImage = src => {
    setIsLoading(true);

    const newImage = new window.Image();
    newImage.src = src;

    newImage.onload = () => {
      if (!newImage) return;

      setIsLoading(false);
      setIsLoaded(true);
    };

    newImage.onerror = () => {
      if (!newImage) return;

      setIsLoading(false);
      setIsLoaded(false);
    };
  };

  const handleOffsetRange = useCallback(() => {
    if (!imageRef.current || !imageOuterRef.current) {
      return;
    }

    const dx =
      imageRef.current.scrollWidth * (1 + zoom / 2) -
      imageOuterRef.current.clientWidth;
    const dy =
      imageRef.current.scrollHeight * (1 + zoom / 2) -
      imageOuterRef.current.clientHeight;

    offsetRange.current = { x: Math.max(0, dx / 2), y: Math.max(0, dy / 2) };
  }, [imageOuterRef, imageRef, zoom]);

  const resetOffset = () => setOffset(OFFSET_DEFAULT);

  const zoomIn = useCallback(() => {
    if (!isLoaded) return;

    setZoom(Math.min(zoom + 1, ZOOM_LEVEL.MAX));
    handleOffsetRange();
  }, [handleOffsetRange, isLoaded, zoom]);

  const zoomOut = useCallback(() => {
    if (!isLoaded) return;

    setZoom(Math.max(0, zoom - 1));

    resetOffset();
    handleOffsetRange();
  }, [handleOffsetRange, isLoaded, zoom]);

  const onMoveStart = e => {
    if (!offsetRange.current.x && !offsetRange.current.y) {
      return;
    }

    clientOffset.current = { x: e.clientX, y: e.clientY };
    setIsDraggable(true);
  };

  const onMove = useCallback(
    e => {
      if ((!e.clientX && !e.clientY) || !isDraggable) {
        return;
      }

      const newOffset = {
        x: e.clientX - clientOffset.current.x,
        y: e.clientY - clientOffset.current.y
      };

      clientOffset.current = { x: e.clientX, y: e.clientY };

      setOffset({
        x: offset.x + newOffset.x,
        y: offset.y + newOffset.y
      });
    },
    [isDraggable, offset.x, offset.y]
  );

  const onMoveEnd = useCallback(() => {
    setIsDraggable(false);

    const newOffset = {
      x: Math.abs(offset.x),
      y: Math.abs(offset.y)
    };

    if (Math.abs(offset.x) >= offsetRange.current.x) {
      newOffset.x =
        offset.x < 0
          ? Math.min(0, -offsetRange.current.x)
          : Math.max(0, offsetRange.current.x);

      setOffset(newOffset);
    }

    if (Math.abs(offset.y) >= offsetRange.current.y) {
      newOffset.y =
        offset.y < 0
          ? Math.min(0, -offsetRange.current.y)
          : Math.max(0, offsetRange.current.y);

      setOffset(newOffset);
    }
  }, [offset.x, offset.y]);

  useEffect(() => {
    window.addEventListener('resize', handleOffsetRange);
    document.documentElement.addEventListener('mouseup', onMoveEnd);

    return () => {
      window.removeEventListener('resize', handleOffsetRange);
      document.documentElement.removeEventListener('mouseup', onMoveEnd);
    };
  }, [handleOffsetRange, onMoveEnd]);

  useEffect(() => {
    loadImage(image.src);

    return () => {
      imageRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformValue = useMemo(
    () => `translate3d(${offset.x}px, ${offset.y}px, 0px)`,
    [offset.x, offset.y]
  );

  const imageTransfrom = useMemo(() => {
    let scale3d = '1, 1, 1';

    switch (zoom) {
      case 1:
        scale3d = '1.5, 1.5, 1';
        break;
      case 2:
        scale3d = '2, 2, 1';
        break;
      case 3:
        scale3d = '2.5, 2.5, 1';
        break;
      case 4:
        scale3d = '3, 3, 1';
        break;
      default:
        scale3d = '1, 1, 1';
        break;
    }

    return `scale3d(${scale3d}) rotate(${rotate}deg)`;
  }, [rotate, zoom]);

  return (
    <div className="image-wrapper">
      <div
        style={{ transform: transformValue }}
        ref={imageOuterRef}
        className={classnames('image-outer', `zoom-${zoom}`, {
          dragging: isDraggable
        })}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <img
            style={{
              transform: imageTransfrom
            }}
            className="image"
            ref={imageRef}
            src={image.src}
            alt={image.name || ''}
            draggable={false}
            onDragStart={e => e.preventDefault()}
            onMouseMove={onMove}
            onMouseDown={onMoveStart}
            onMouseUp={onMoveEnd}
          />
        )}
      </div>

      <div className="tool-bar">
        {showIndex && <div className="index-indicator">{index}</div>}

        <p className="caption">
          {image.name ? <span className="name">{image.name}</span> : null}
          {image.name && image.content ? <span> - </span> : null}
          {image.name ? <span className="content">{image.content}</span> : null}
        </p>

        <div className="button-group">
          <div className="zoom-out button" onClick={zoomOut} />
          <div className="zoom-in button" onClick={zoomIn} />
        </div>
      </div>
    </div>
  );
};

ImageWrapper.defaultProps = {
  showIndex: PropTypes.bool,
  index: PropTypes.number,
  image: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string
  }),
  rotate: PropTypes.number
};

ImageWrapper.propTypes = {
  showIndex: true,
  index: 0,
  image: {},
  rotate: 0
};

export default ImageWrapper;
