import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import { EMAIL, WHATSAPP } from 'constants/index.js';

import {
  FormChanneleSelect,
  FormContactSelect,
  FormCustomSelect,
  FormInput
} from 'components/common/hook-form';
import Button from 'components/common/button';
import FormNewEditor from 'components/common/hook-form/markdown';
import emailsMapValues from 'components/common/controls/custom-select/contact-select/emails-map-values';

import removeSpecialCharactersPhone from 'utils/remove-special-characters-phone';

import { handleContactFields } from '../utils';

import styles from './styles.module.scss';

const mapChannelOptions = channel => ({
  value: channel.id,
  label: {
    title: `${channel.name} (${channel.source})`,
    uuid: channel.uuid
  }
});

const MainForm = ({ onSubmit, defaultValues, isLoading, onClose }) => {
  const [options, setOptions] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(EMAIL);

  const firstChannelWhatsApp = defaultValues.channels.find(
    ({ kind }) => kind === WHATSAPP
  );
  const firstChannelEmail = defaultValues.channels.find(
    ({ kind }) => kind === EMAIL
  );

  const transformedDefaultValues = useMemo(
    () => ({
      contact:
        defaultValues && defaultValues.contact
          ? { label: defaultValues.contact, value: defaultValues.contact.id }
          : undefined,
      email: '',
      subject: '',
      content: {
        description: '',
        fileList: []
      }
    }),
    [defaultValues]
  );

  const { t } = useTranslation([
    'ComposeEmail',
    'Contacts',
    'ComposeWhatsApp',
    'Errors'
  ]);

  const methods = useForm({
    defaultValues: transformedDefaultValues,
    shouldUnregister: true
  });

  const contactField = methods.watch('contact');
  const emailField = methods.watch('email') || '';
  const phoneField = methods.watch('phone') || '';

  useEffect(() => {
    // to avoid calling notification.warning again
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    const fields = handleContactFields({
      contact: contactField,
      name: selectedChannel === EMAIL ? 'email' : 'phone',
      methods
    });

    setOptions(fields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactField, methods, isFirstRender]);

  useEffect(() => {
    methods.reset(transformedDefaultValues);

    methods.setValue(
      'channel',
      mapChannelOptions(
        selectedChannel === EMAIL ? firstChannelEmail : firstChannelWhatsApp
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, selectedChannel]);

  const transformSubmittedValues = values => ({
    ...values,
    channel: values.channel.value,
    email: selectedChannel === EMAIL ? values.email.value : '',
    phone:
      selectedChannel === WHATSAPP
        ? removeSpecialCharactersPhone(values.phone.value)
        : '',
    chats: values.contact.label.chats,
    channelUuid: values.channel.label.uuid,
    recipients: values.recipients
      ? values.recipients.map(({ label, value }) => ({
          fullName: `${label.lastName || ''} ${label.firstName}`,
          email: value
        }))
      : []
  });

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        onSubmit={methods.handleSubmit(data =>
          onSubmit(transformSubmittedValues(data))
        )}
      >
        <Radio.Group
          className={styles.radioGroup}
          onChange={e => setSelectedChannel(e.target.value)}
          value={selectedChannel}
        >
          <Radio value={EMAIL}>{t('EmailChannel', { ns: 'Contacts' })}</Radio>

          <Radio value={WHATSAPP}>
            {t('WhatsAppMessageRadio', { ns: 'Contacts' })}
          </Radio>
        </Radio.Group>

        <FormChanneleSelect
          addEntityButtonData={null}
          mapEntries={mapChannelOptions}
          name="channel"
          label={t('From')}
          isSearchable={false}
          params={{
            kind: selectedChannel === EMAIL ? EMAIL : WHATSAPP,
            hasChannelRole: defaultValues.employeeId,
            isActive: true,
            lite: undefined
          }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <FormContactSelect
          name="contact"
          label={t('To')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        {!!options.length && (
          <FormCustomSelect
            name={selectedChannel === EMAIL ? 'email' : 'phone'}
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            label={
              selectedChannel === EMAIL
                ? t('ContactEmail')
                : t('ContactWhatsApp', { ns: 'ComposeWhatsApp' })
            }
            options={options}
          />
        )}

        {selectedChannel === EMAIL && (
          <>
            <FormContactSelect
              name="recipients"
              customMapValues={emailsMapValues}
              label={t('CC')}
              isMulti
              showEmail
              closeMenuOnSelect={false}
            />

            <FormInput name="subject" label={t('EmailTheme')} />
          </>
        )}

        <FormNewEditor
          label={
            selectedChannel === EMAIL
              ? t('EmailText')
              : t('MessageText', { ns: 'ComposeWhatsApp' })
          }
          name="content"
          placeholder={t('EnterMessage')}
          rules={{
            validate: value => {
              const fileSizeExceeded = value.fileList.some(
                file => file.fileSize > 25 * 1024 * 1024
              );

              if (fileSizeExceeded) {
                return t('FileSizeMustNotExceed', { ns: 'Errors' });
              }

              if (!value.description || !value.description.trim()) {
                return t('RequiredField', { ns: 'Errors' });
              }

              return true;
            }
          }}
          showItems={{
            upload: true,
            mention: false,
            emoji: true,
            topToolbar: true,
            messageTemplates: true
          }}
        />

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          disabled={!emailField.value && !phoneField.value}
          className={styles.btn}
        >
          {t('SendBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default MainForm;
