import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import Lottie from 'lottie-react';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import updates from './updates';

import styles from './modal.module.scss';

const { Text, Title, Paragraph } = Typography;

export const ReleaseModal = ({ visible, onClose }) => {
  const [step, setStep] = useState(0);

  const showBackButton = step !== 0;
  const isLastStep = step === updates.length - 1;

  const onBack = () => setStep(step - 1);

  const onGo = () => {
    if (isLastStep) {
      return onClose();
    }

    return setStep(step + 1);
  };

  return (
    <Modal
      width={797}
      contentClassName={styles.root}
      centered
      visible={visible}
      onClose={onClose}
    >
      <div>
        <Title level={1} weight="bold" className={styles.header}>
          Updates
        </Title>

        {updates.length > 1 && (
          <Text color="black-35">{`${step + 1} from ${updates.length}`}</Text>
        )}

        <Title level={3} className={styles.title}>
          {updates[step].title}
        </Title>

        <Paragraph color="black-35">{updates[step].description}</Paragraph>

        {updates[step].link && (
          <a
            href={updates[step].link.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {updates[step].link.text}
          </a>
        )}

        <div className={styles.buttons}>
          {showBackButton && (
            <Button
              type="secondary"
              size="large"
              width="expanded"
              onClick={onBack}
            >
              Back
            </Button>
          )}

          <Button type="primary" size="large" width="expanded" onClick={onGo}>
            {isLastStep ? 'Understand' : 'Next'}
          </Button>
        </div>
      </div>

      {/* <Lottie animationData={updates[step].animation} loop /> */}
      <img src={updates[step].animation} alt={updates[step].description} />
    </Modal>
  );
};

ReleaseModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ReleaseModal;
