import handleActions from 'utils/redux-actions';

import { chatMessageNew } from '../operator/actions';
import { TYPE_DISCUSSION } from '../../constants';
import { clearCreatedDiscussionId } from './actions';

const initialState = {
  createdDiscussionId: null
};

export default handleActions(
  {
    [chatMessageNew]: (state, { payload }) => {
      const { message, isNotification, isMyMessage } = payload;
      const { notificationData } = message;

      if (
        isMyMessage &&
        isNotification &&
        message.entityType === TYPE_DISCUSSION &&
        !notificationData.discussionCreated
      ) {
        state.createdDiscussionId = message.uuid;
      }

      return state;
    },

    [clearCreatedDiscussionId]: state => {
      state.createdDiscussionId = null;

      return state;
    }
  },
  initialState
);
