import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Typography, Button, Checkbox, Alert, Input } from 'antd';
import { Translation, useTranslation } from 'react-i18next';

import { REG_BOSS_INVITATION_EVENT } from 'constants/index';

import { EmployeeSelect } from 'components/common/controls/custom-select';
import Drawer from 'components/common/drawer';
import OnboardingModal from 'components/common/onboarding/modal';

import { joinDepartment, becomeOwner } from 'store/team/departments';
import { getUserEmployee } from 'store/workspace';

import { useModalsService } from 'services/modals';
import { validateIsRequire, validateMaxLength } from 'utils/validators';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import getHasOwnerRole from 'utils/get-has-owner-role';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import styles from '../styles.module.scss';

const { Paragraph } = Typography;

const AppointmentForm = Form.create()(
  ({
    values: defaultValues,
    className,
    form,
    isLoading,
    onClickBecome,
    onSubmit,
    btnClassName
  }) => {
    const amplitude = useAmplitude();

    const { t } = useTranslation('AllocationToTheDepartment');

    const handleSubmit = event => {
      event.preventDefault();

      form.validateFieldsAndScroll(
        (err, { isDirector, transferTo, ...values }) => {
          if (!err) {
            if (isDirector) {
              onClickBecome({ position: values.position });
            } else {
              onSubmit({
                ...values,
                [transferTo.isNew
                  ? 'manager_email'
                  : 'manager']: transferTo.value
              });
            }

            if (transferTo.isNew) {
              amplitude.colleagueInvitationEvent([REG_BOSS_INVITATION_EVENT]);
            }
          }
        }
      );
    };

    const isDirector = form.getFieldValue('isDirector');

    return (
      <Form
        className={className}
        hideRequiredMark
        onSubmit={handleSubmit}
        colon={false}
      >
        {defaultValues.benchReason && (
          <Alert
            message={defaultValues.benchReason}
            type="warning"
            style={{ marginBottom: 16 }}
          />
        )}

        <Paragraph>{t('AllocationToTheDepartmentDesc')}</Paragraph>

        {!isDirector && (
          <Form.Item label={t('YourImmediateSupervisor')}>
            {form.getFieldDecorator('transferTo', {
              rules: [validateIsRequire()]
            })(
              <EmployeeSelect
                allowCreatable
                closeMenuOnSelect
                params={{
                  excludeBench: true
                }}
                allowSetYourself={false}
                valueText={t('EnterYourNameEmail')}
              />
            )}
          </Form.Item>
        )}

        <Form.Item label={t('YourPosition')}>
          {form.getFieldDecorator('position', {
            initialValue: defaultValues.position,
            rules: [validateIsRequire(), validateMaxLength(500)]
          })(<Input placeholder={t('EnterYourPosition')} />)}
        </Form.Item>

        <Form.Item>
          {form.getFieldDecorator('isDirector', { initialValue: false })(
            <Checkbox>{t('HeadOfCompanyChckbx')}</Checkbox>
          )}
        </Form.Item>

        {isDirector && (
          <Alert message={t('HeadOfCompanyWarning')} type="warning" />
        )}

        <Button
          type="primary"
          htmlType="submit"
          className={btnClassName}
          loading={isLoading}
        >
          {t('NextBtn')}
        </Button>
      </Form>
    );
  }
);

export const AppointmentDrawer = ({
  className,
  closable,
  title,
  onClose,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const modals = useModalsService();

  const user = useSelector(getUserEmployee);

  const [isLoading, setIsLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const { isAfterRegister, isInvite } = state;

  const onClickBecome = async ({ position }) => {
    await dispatch(becomeOwner({ position }));

    showNoticeMessage({ number: NOTICE_NUMBER.becomeOwnerRequestSended });

    modals.close(state);

    if (closable) {
      onClose();
    }

    if (isAfterRegister && isInvite && getHasOwnerRole(user.roles)) {
      setVisibleModal(true);
    }
  };

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(joinDepartment(values));

      showNoticeMessage({ number: NOTICE_NUMBER.joinDepartmentRequestSended });

      modals.close(state);

      if (closable) {
        onClose();
      }

      if (isAfterRegister && isInvite && getHasOwnerRole(user.roles)) {
        setVisibleModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Drawer
        closable={closable}
        onClose={onClose}
        className={classnames(styles.root, className)}
        title={<Drawer.Title>{title}</Drawer.Title>}
        destroyOnClose
        {...drawerProps}
      >
        <AppointmentForm
          values={user}
          className={styles.form}
          btnClassName={styles.btn}
          isLoading={isLoading}
          onClickBecome={onClickBecome}
          onSubmit={onSubmit}
        />
      </Drawer>

      <OnboardingModal
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
          dispatch(push({ isAfterRegister: false }));
        }}
      />
    </>
  );
};

AppointmentDrawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func
};

AppointmentDrawer.defaultProps = {
  className: undefined,
  title: (
    <Translation ns="AllocationToTheDepartment">
      {t => t('AllocationToTheDepartmentHeading')}
    </Translation>
  ),
  closable: false,
  onClose: () => {}
};

export default AppointmentDrawer;
