import { Skeleton } from 'antd';
import React from 'react';

import { SkeletonButton, SkeletonInput } from 'components/common/skeletons';

import styles from './relations.module.scss';

const SkeletonRelations = () => (
  <div className={styles.skeletonRoot}>
    <Skeleton
      active
      title={false}
      paragraph={{
        rows: 3,
        className: styles.paragraph
      }}
    />

    <SkeletonInput className={styles.input} />

    <SkeletonInput className={styles.input} />

    <SkeletonInput className={styles.input} />

    <SkeletonInput className={styles.input} />

    <div className={styles.buttonWrap}>
      <SkeletonButton className={styles.button} />
    </div>
  </div>
);

export default SkeletonRelations;
