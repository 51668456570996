import React from 'react';

export const CheckIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7153 3.37598H20.843C20.5805 3.37598 20.3314 3.49651 20.1707 3.70276L9.12692 17.6929L3.83138 10.9831C3.75128 10.8814 3.64917 10.7992 3.53272 10.7426C3.41628 10.686 3.28853 10.6565 3.15906 10.6563H1.28674C1.10728 10.6563 1.00817 10.8626 1.11799 11.0019L8.4546 20.2965C8.79746 20.7304 9.45638 20.7304 9.80192 20.2965L22.8841 3.71883C22.9939 3.58223 22.8948 3.37598 22.7153 3.37598Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
