export const getItemPath = ({
  id,
  parent,
  fullParent,
  children,
  workspaceId
}) => {
  const firstSubItem = children && Object.keys(children)[0];

  const currentPath = parent ? `/${parent}/${id}` : `/${id}`;
  const fullPath = firstSubItem
    ? `${currentPath}/${firstSubItem}`
    : currentPath;

  const currentLink = fullParent
    ? `${fullParent}/${id}`
    : `/${workspaceId}${fullPath}`;

  return {
    current: currentPath,
    link: currentLink,
    path: fullPath
  };
};

export default getItemPath;
