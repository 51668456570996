import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import {
  checkDialogPresence,
  clearGptFilter,
  fetchGptMessages,
  fetchGptChats,
  fetchGptPrompts,
  getGptFilters
} from 'store/gpt';

export const useGptActions = () => {
  const dispatch = useDispatch();

  const filters = useSelector(getGptFilters);
  const [isLoading, setIsLoading] = useState(false);

  const [dialogs, setDialogs] = useState([]);

  const fetchChat = async (entityInfo, callback) => {
    const { results } = await dispatch(
      checkDialogPresence({
        entityType: entityInfo.entityType,
        entityId: entityInfo.entityId
      })
    );

    callback(results);
  };

  const fetchChats = async () => {
    try {
      setIsLoading(true);

      const { results } = await dispatch(
        fetchGptChats({ entityType: filters.entityType.value })
      );

      setDialogs(results);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = ({ id }) => dispatch(fetchGptMessages({ id }));

  const fetchPrompts = ({ entityType }) =>
    dispatch(fetchGptPrompts({ entityType }));

  const clearFilter = () => {
    dispatch(clearGptFilter());
  };

  return {
    fetchChat,
    fetchChats,
    fetchPrompts,
    fetchMessages,
    clearFilter,
    filters,
    dialogs,
    isLoading
  };
};

export default useGptActions;
