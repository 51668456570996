import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormInputNumber } from 'components/common/hook-form';
import Button from 'components/common/button';

export const StoryPointForm = ({
  defaultValues,
  isLoading,
  onSubmit,
  onCancel
}) => {
  const methods = useForm({ defaultValues });

  const { t } = useTranslation('Task');

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormInputNumber
          label={t('Storypoints')}
          name="storyPoint"
          placeholder="000"
        />

        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}
        >
          <Button type="secondary" onClick={onCancel}>
            {t('CancelBtn')}
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('SaveBtn')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

StoryPointForm.propTypes = {
  defaultValues: PropTypes.shape({
    storyPoint: PropTypes.number
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

StoryPointForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
  onSubmit: () => {},
  onCancel: () => {}
};

export default StoryPointForm;
