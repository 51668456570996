import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin, Empty } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET } from 'constants/index';

import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import { MyDriveIcon } from 'components/common/icons/multicolor';
import Attachments from 'components/common/attachments';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';

import { fetchAttachmentsLocal } from 'store/attachments';

import styles from './list.module.scss';

export const List = ({ asset, isAfterVisible }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const { id } = asset;

  const { t } = useTranslation('Common');

  const fetchAttachments = async () => {
    try {
      setIsLoading(true);

      const { entries } = await dispatch(
        fetchAttachmentsLocal({
          asset: [{ value: asset.id }],
          isTrash: false,
          ignoreCache: true
        })
      );

      setDocuments(entries);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscription = ({ data, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments: documents,
      setAttachments: setDocuments,
      data,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: documents,
      setAttachments: setDocuments,
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments: documents,
      setAttachments: setDocuments,
      data: attachment
    });
  };

  useEffect(() => {
    if (isAfterVisible) {
      fetchAttachments();
    }

    return () => setDocuments([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterVisible]);

  return (
    <Spin
      spinning={isLoading}
      wrapperClassName={classnames(styles.root, {
        [styles.empty]: !documents.length
      })}
    >
      <Attachments
        fileList={documents}
        actionsDeps={{
          assetId: id,
          sendCopyToComment: false,
          allowDeleteFrom: false,
          entityId: id,
          entityType: TYPE_ASSET
        }}
        showDownloadAllButton
        subscribeCallback={handleSubscription}
        unsubscribeCallback={handleSubscription}
        changeManageSubscribersCallback={handleAfterChangeManageSubscription}
        changeValidityDateCallback={handleAfterChangeValidityDateCallback}
      />

      {!documents.length && !isLoading && isAfterVisible && (
        <Empty
          image={<Icon component={MyDriveIcon} />}
          imageStyle={{ height: 'auto', marginBottom: 10 }}
          className={styles.emptyContainer}
          description={<Typography.Text>{t('EmptyList')}</Typography.Text>}
        />
      )}
    </Spin>
  );
};

List.propTypes = {
  asset: PropTypes.shape({}),
  isAfterVisible: PropTypes.bool
};

List.defaultProps = {
  asset: {},
  isAfterVisible: false
};

export default List;
