import { fetchSocialCredentials } from './actions';
import handleActions from '../../utils/redux-actions';

const initialState = {
  entries: []
};

export default handleActions(
  {
    [fetchSocialCredentials.SUCCEEDED]: (state, { payload }) => {
      state.entries = payload;

      return state;
    }
  },
  initialState
);
