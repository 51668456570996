import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SkeletonMessage from './message';

import styles from './skeleton.module.scss';

const SkeletonChat = ({ className, isIncoming }) => (
  <div className={classnames(styles.root, className)}>
    <SkeletonMessage isOutgoing={!isIncoming} />

    {[...Array(3).keys()].map(key => (
      <SkeletonMessage key={`skeleton-message-in-chat-${key}`} />
    ))}

    {[...Array(2).keys()].map(key => (
      <SkeletonMessage
        key={`skeleton-outgoing-message-in-chat-${key}`}
        isOutgoing={!isIncoming}
      />
    ))}

    <SkeletonMessage />
  </div>
);

SkeletonChat.propTypes = {
  className: PropTypes.string,
  isIncoming: PropTypes.bool
};

SkeletonChat.defaultProps = {
  className: undefined,
  isIncoming: false
};

export default SkeletonChat;
