import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';
import Modal from 'components/common/modal';

import { changeResponsible, fetchTask } from 'store/tasks';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import useRoutesService from 'services/routes';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const EditorForm = Form.create()(({ form, task, onCallback }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const routes = useRoutesService();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['TaskRoleActions', 'Errors', 'Common']);

  const { getFieldDecorator } = form;

  const onUpdateResponsible = async values => {
    setIsLoading(true);

    try {
      await dispatch(
        changeResponsible({
          id: task.id,
          employeeId: values.responsible.value
        })
      );

      await dispatch(fetchTask({ id: task.id })).catch(({ response }) => {
        if (response.status === 403) {
          routes.toTasks();
        }
      });

      amplitude.updateTaskEvent({
        task,
        value: {
          responsible: values.responsible.value
        }
      });

      showNoticeMessage({ number: NOTICE_NUMBER.changeOfResponsible });

      onCallback();
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = event => {
    event.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        await onUpdateResponsible(values);
      }
    });
  };

  const Select = (task.project || {}).id
    ? ProjectParticipantsSelect
    : EmployeeSelect;

  return (
    <Form
      colon={false}
      hideRequiredMark
      onSubmit={onSave}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 24px 24px'
      }}
      data-qa="qa-pa1kj1t3l3959uw"
    >
      <Form.Item label={t('TaskResponsible')}>
        {getFieldDecorator('responsible', {
          initialValue: task.responsible && {
            value: (task.responsible || {}).id,
            label: task.responsible
          },
          rules: [
            {
              required: true,
              message: t('RequiredField', { ns: 'Errors' })
            }
          ]
        })(
          <Select
            projectId={(task.project || {}).id}
            placeholder={t('ChooseResponsible', { ns: 'Common' })}
            data-qa="qa-n95e4tjai43sjx4"
            hideWorkspace
            isClearable={false}
            closeMenuOnSelect
          />
        )}
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        style={{ marginLeft: 'auto' }}
        data-qa="qa-996ojcghexj5uxj"
      >
        {t('SaveBtn')}
      </Button>
    </Form>
  );
});

EditorForm.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    projectId: PropTypes.number,
    responsible: PropTypes.object
  }),
  onCallback: PropTypes.func
};

EditorForm.defaultProps = {
  task: {},
  onCallback: () => {}
};

export const EditorResponsibleModal = ({ onClose, task, ...props }) => {
  const { t } = useTranslation('TaskRoleActions');

  return (
    <Modal
      width={540}
      title={t('ChangeResponsibleHeading')}
      data-qa="qa-6a4sjgbq7dme7lq"
      onClose={onClose}
      {...props}
    >
      <EditorForm task={task} onCallback={onClose} />
    </Modal>
  );
};

export default EditorResponsibleModal;
