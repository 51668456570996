import React from 'react';

export const FileIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.16602"
      y="4.66699"
      width="6.66667"
      height="2.5"
      rx="0.833333"
      fill="currentColor"
    />
    <rect
      x="4.16602"
      y="8.83301"
      width="11.6667"
      height="2.5"
      rx="0.833333"
      fill="currentColor"
    />
    <rect
      x="4.16602"
      y="13"
      width="11.6667"
      height="2.5"
      rx="0.833333"
      fill="currentColor"
    />
    <path
      d="M2.66667 1.16667H17.3333V-0.166667H2.66667V1.16667ZM19.3333 3.16667V17.8333H20.6667V3.16667H19.3333ZM17.3333 19.8333H2.66667V21.1667H17.3333V19.8333ZM0.666667 17.8333V3.16667H-0.666667V17.8333H0.666667ZM2.66667 19.8333C1.5621 19.8333 0.666667 18.9379 0.666667 17.8333H-0.666667C-0.666667 19.6743 0.825719 21.1667 2.66667 21.1667V19.8333ZM19.3333 17.8333C19.3333 18.9379 18.4379 19.8333 17.3333 19.8333V21.1667C19.1743 21.1667 20.6667 19.6743 20.6667 17.8333H19.3333ZM17.3333 1.16667C18.4379 1.16667 19.3333 2.0621 19.3333 3.16667H20.6667C20.6667 1.32572 19.1743 -0.166667 17.3333 -0.166667V1.16667ZM2.66667 -0.166667C0.825718 -0.166667 -0.666667 1.32572 -0.666667 3.16667H0.666667C0.666667 2.0621 1.5621 1.16667 2.66667 1.16667V-0.166667Z"
      fill="currentColor"
    />
  </svg>
);

export default FileIcon;
