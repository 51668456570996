import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, WORKSPACE_JOIN } from 'constants/index';

import Typography from 'components/common/typography';
import Pagination from 'components/common/pagination';
import { SkeletonButton } from 'components/common/skeletons';
import Avatar from 'components/common/avatar';
import { convertMessageToNode } from 'components/common/comments/converters';
import Button from 'components/common/button';
import LimitedFeature from 'components/settings-view/billing/limited-feature';

import getFIO, { getFullName } from 'utils/get-fio';

import useData from './use-data';

import styles from './with-actions.module.scss';

const NotificationsWithActions = ({ afterVisibleChange, className }) => {
  const {
    index,
    setIndex,
    isLoading,
    totalItems,
    current,
    activeAction,
    isOnlyUserProfile,
    onAccept,
    onReject
  } = useData({ afterVisibleChange });

  const { t } = useTranslation('Dashboard');

  if (isLoading) {
    return <SkeletonButton className={styles.skeleton} />;
  }

  if (!isLoading && (!totalItems || !current)) {
    return isOnlyUserProfile ? (
      <Typography.Title level={3} className={styles.empty}>
        {t('NoNotificationsYet')}
      </Typography.Title>
    ) : null;
  }

  const { id, sender, createdAt, content, action } = current;

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.header}>
        <Typography.Title level={3} className={styles.title}>
          {t('ActiveRequests')}
        </Typography.Title>

        <Pagination
          currentPage={index + 1}
          onChangePage={page => setIndex(page - 1)}
          paginationProps={{
            simple: true
          }}
          itemsPerPage={1}
          totalItems={totalItems}
        />
      </div>

      <div className={styles.notification}>
        <div className={styles.sender}>
          <Avatar size={24} src={sender.avatarUrl}>
            {getFIO(sender)}
          </Avatar>

          <Typography.Text>{getFullName(sender)}</Typography.Text>
        </div>

        <div className={styles.content}>
          {convertMessageToNode(content)}

          <Spin
            wrapperClassName={styles.actions}
            spinning={activeAction === id}
          >
            <LimitedFeature
              allowLimit={action.type === WORKSPACE_JOIN}
              childrenDisableProps="disabled"
            >
              <Button
                type="secondary"
                width="expanded"
                className={styles.actionBtn}
                onClick={() => onAccept(current)}
              >
                {t('SubmitBtn')}
              </Button>
            </LimitedFeature>

            <Button
              type="secondary"
              width="expanded"
              mood="negative"
              className={styles.actionBtn}
              onClick={() => onReject(current)}
            >
              {t('DeclineBtn')}
            </Button>
          </Spin>
        </div>

        <div className={styles.createdAt}>
          <Typography.Text color="black-55">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

NotificationsWithActions.propTypes = {
  className: PropTypes.string,
  afterVisibleChange: PropTypes.bool
};

NotificationsWithActions.defaultProps = {
  className: undefined,
  afterVisibleChange: true
};

export default NotificationsWithActions;
