export default api => ({
  fetchReminder: ({ workspaceId, entityType, entityId }) =>
    api.get(`/v1/${workspaceId}/reminders/${entityType}/${entityId}/`),

  createReminder: ({ workspaceId, entityType, entityId, values }) =>
    api.post(`/v1/${workspaceId}/reminders/${entityType}/${entityId}/`, values),

  updateReminder: ({ workspaceId, entityType, entityId, reminderId, values }) =>
    api.put(
      `/v1/${workspaceId}/reminders/${entityType}/${entityId}/${reminderId}/`,
      values
    ),

  deleteReminder: ({ workspaceId, entityType, entityId, reminderId }) =>
    api.delete(
      `/v1/${workspaceId}/reminders/${entityType}/${entityId}/${reminderId}/`
    )
});
