import React from 'react';

export const AgreementIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.5" r="12" fill="#D8D8D8" />
    <g clipPath="url(#clip0_65043_4874)">
      <path
        d="M7.625 5.5C7.14102 5.5 6.75 5.89102 6.75 6.375C6.75 6.85898 7.14102 7.25 7.625 7.25V7.55078C7.625 8.71016 8.08711 9.82305 8.90742 10.6434L10.7613 12.5L8.90742 14.3566C8.08711 15.177 7.625 16.2898 7.625 17.4492V17.75C7.14102 17.75 6.75 18.141 6.75 18.625C6.75 19.109 7.14102 19.5 7.625 19.5H16.375C16.859 19.5 17.25 19.109 17.25 18.625C17.25 18.141 16.859 17.75 16.375 17.75V17.4492C16.375 16.2898 15.9129 15.177 15.0926 14.3566L13.2387 12.5L15.0953 10.6434C15.9156 9.82305 16.3777 8.71016 16.3777 7.55078V7.25C16.8617 7.25 17.2527 6.85898 17.2527 6.375C17.2527 5.89102 16.8617 5.5 16.3777 5.5H7.625ZM9.375 7.55078V7.25H14.625V7.55078C14.625 8.07031 14.4719 8.57344 14.1875 9H9.8125C9.53086 8.57344 9.375 8.07031 9.375 7.55078ZM9.8125 16C9.9082 15.8551 10.0203 15.7184 10.1434 15.5926L12 13.7387L13.8566 15.5953C13.9824 15.7211 14.0918 15.8578 14.1902 16.0027H9.8125V16Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65043_4874">
        <rect
          width="10.5"
          height="14"
          fill="white"
          transform="translate(6.75 5.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AgreementIcon;
