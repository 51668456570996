const convertFileToBase64 = (file, callback) => {
  if (file) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;

      callback(base64String);
    };

    reader.readAsDataURL(file);
  }
};

export const uploadFileAsBase64 = async file => {
  if (file) {
    return new Promise(resolve => {
      convertFileToBase64(file, base64String => {
        resolve(base64String);
      });
    });
  }

  return '';
};

export default uploadFileAsBase64;
