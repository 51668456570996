import React from 'react';
import { Translation } from 'react-i18next';

import {
  CHANNEL_TYPES,
  TELEGRAM,
  EMAIL,
  WEBSITE_FORM,
  WHATSAPP
} from 'constants/index';

import IconComponent from 'components/common/icon';
import Typography from 'components/common/typography';

import { config } from 'config';

import styles from './integrations.module.scss';

export const getWidgetLink = uuid =>
  `<script type="text/javascript" async="" src="https://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}/api/widget/${uuid}"></script>`;

export const getTelegramBotLink = username => `https://t.me/${username}`;

export const getWebsiteFormLink = uuid =>
  `${window.location.origin}/forms/${uuid}`;

export const getWhatsAppLink = phone => `https://wa.me/+${phone}`;

export const renderChannelKind = kind => {
  const { Icon, title, ns } = CHANNEL_TYPES.find(({ type }) => type === kind);

  return (
    <div className={styles.kind}>
      <IconComponent component={Icon} size={18} className={styles.icon} />

      <Typography.Text>
        <Translation ns={ns}>{t => t(title)}</Translation>
      </Typography.Text>
    </div>
  );
};

const getHref = (source, kind) => {
  if (kind === EMAIL) {
    return `mailto:${source}`;
  }

  return source;
};

const hasRepeatedColon = url => {
  const colonIndex = url.indexOf(':');
  return colonIndex !== -1 && colonIndex !== url.lastIndexOf(':');
};

export const filterSources = sources =>
  (sources || []).filter(url => {
    const httpsUrl = `https${url.substring(4)}`;

    return (
      (url.startsWith('http') || url.startsWith('https')) &&
      (!url.startsWith('http') ||
        (url.startsWith('http') &&
          !sources.includes(httpsUrl) &&
          !url.includes('localhost'))) &&
      !hasRepeatedColon(url)
    );
  });

export const renderSources = channel => {
  if (
    !(channel.sources || []).length &&
    !channel.source &&
    channel.kind !== WEBSITE_FORM
  ) {
    return '-';
  }

  const { config: channelConfig, sources = [] } = channel;

  let resultSources = [];

  if (channel.kind === WEBSITE_FORM) {
    resultSources.push(getWebsiteFormLink(channel.uuid));
  }

  if (channel.kind === TELEGRAM) {
    resultSources.push(getTelegramBotLink(channelConfig.name));
  }

  if (channel.kind === EMAIL) {
    resultSources.push(channel.source);
  }

  if (channel.kind === WHATSAPP) {
    resultSources.push(getWhatsAppLink(channel.source));
  }

  resultSources = [...resultSources, ...filterSources(sources)];

  return resultSources.map(s => (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={getHref(s, channel.kind)}
        style={{ fontSize: 14 }}
        onClick={e => e.stopPropagation()}
      >
        {s}
      </a>

      <br />
    </>
  ));
};
