import { createAction } from 'redux-actions';

import { fetchTagsByEntity } from 'store/tags';

import mapValue from 'utils/map-value';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getWorkspaceId } from '../workspace';
import {
  getItemsPerPage,
  getAllProjectsLength,
  getProjectFactory,
  getFilterProjects
} from './selectors';
import { ITEMS_PER_PAGE, TYPE_PROJECT } from '../../constants';

export const addProject = createActionThunk(
  'projects/add-project',
  ({ getState, projectId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.project.add(workspaceId, projectId);
  }
);

export const createProject = createActionThunk(
  'projects/create-project',
  ({ project, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.projects.create(workspaceId, project).then(({ data }) => data);
  }
);

export const projectAction = ({ getState, dispatch, access = 'full' }) => {
  const state = getState();

  const workspaceId = getWorkspaceId(state);
  const limit = getItemsPerPage(state);
  const offset = getAllProjectsLength(state);

  const { tag, search } = getFilterProjects(state);

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  return api.projects
    .fetch({
      workspaceId,
      offset,
      limit,
      search,
      access,
      tagsCondition,
      tagsIds
    })
    .then(({ data }) => {
      const projectIds = data.results.map(project => project.id);

      if (projectIds) {
        dispatch(
          fetchTagsByEntity({
            entityType: TYPE_PROJECT,
            entityIds: projectIds
          })
        );
      }

      return {
        entries: data.results,
        totalItems: data.count,
        search
      };
    });
};

export const fetchProjects = createActionThunk(
  'projects/fetch-projects',
  projectAction
);

export const fetchProject = createActionThunk(
  'projects/fetch-project',
  ({ id, getState, errorHandle }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.projects
      .fetchOne(workspaceId, id, errorHandle)
      .then(({ data }) => data);
  }
);

export const fetchProjectLocal = createActionThunk(
  'projects/fetch-project-local',
  ({ id, getState, errorHandle }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.projects
      .fetchOne(workspaceId, id, errorHandle)
      .then(({ data }) => data);
  }
);

export const fetchProjectsLocal = createActionThunk(
  'projects/fetch-projects-local',
  ({ offset, search, access, status, isActive, lite, limit, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const resultLimit = limit || ITEMS_PER_PAGE;

    return api.projects
      .fetch({
        workspaceId,
        offset,
        limit: resultLimit,
        search,
        access,
        status,
        isActive,
        lite
      })
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count,
        search
      }));
  }
);

export const fetchParticipantsLocal = createActionThunk(
  'projects/fetch-participants-local',
  ({ search, id, offset, limit = ITEMS_PER_PAGE, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.projects
      .fetchParticipants(workspaceId, id, offset, limit, search)
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count
      }));
  }
);

export const fetchAnnotatedParticipantsLocal = createActionThunk(
  'projects/fetch-participants-local',
  ({ search, id, offset, limit = ITEMS_PER_PAGE, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.projects
      .fetchAnnotatedParticipants(workspaceId, id, offset, limit, search)
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count
      }));
  }
);

export const deleteProject = createActionThunk(
  'projects/delete-project',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.projects.delete(workspaceId, id).then(() => id);
  }
);

export const completeProject = createActionThunk(
  'projects/complete-project',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.projects.complete(workspaceId, id).then(() => id);
  }
);

export const editProjectFields = createActionThunk(
  'projects/edit-project',
  ({ project, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.projects
      .update(workspaceId, project.id, project.data)
      .then(({ data }) => data);
  }
);

export const changeManager = createActionThunk(
  'projects/change-manager',
  ({ project, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.projects
      .changeManagers(workspaceId, project.id, { employees: project.employees })
      .then(({ data }) => data);
  }
);

export const changeMembers = createActionThunk(
  'projects/change-members',
  ({ id, employees, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.projects
      .changeMembers(workspaceId, id, { employees })
      .then(({ data }) => ({ assignments: data, id }));
  }
);

export const clearProjects = createAction('projects/clear-projects');
export const setCurrentProjectId = createAction('projects/set-current-project');

// SPRINTS
export const createSprint = createActionThunk(
  'sprints/create-sprint',
  ({ getState, value }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .createSprint(workspaceId, value)
      .then(({ data }) => ({ ...data, project: value.project }));
  }
);

export const fetchSprints = createActionThunk(
  'sprints/fetch-sprints',
  ({ getState, project, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const limit = getItemsPerPage(state);
    const offset = (getProjectFactory(state)(project).sprints || []).length;

    const startedDateRange = (params.sprintStartedDateRange || {}).value;
    const endedDateRange = (params.sprintEndedDateRange || {}).value;
    const status = params.sprintStatus.map(mapValue);
    const sprints = params.sprints.map(mapValue);

    return api.sprints
      .fetchSprints({
        workspaceId,
        project,
        offset,
        limit,
        startedDateRange,
        endedDateRange,
        status,
        sprints
      })
      .then(({ data }) => ({ ...data, project }));
  }
);

export const fetchOneSprintLocal = createActionThunk(
  'sprints/fetch-one-sprint-local',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .fetchOneSprint({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchSprintsLocal = createActionThunk(
  'sprints/fetch-sprints-local',
  ({ getState, project, offset, status, search, isLag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const limit = getItemsPerPage(state);

    return api.sprints
      .fetchSprints({
        workspaceId,
        project,
        status,
        offset,
        limit,
        search,
        isLag
      })
      .then(({ data }) => ({ ...data, totalItems: data.count }));
  }
);

export const deleteSprint = createActionThunk(
  'sprints/delete-sprint',
  ({ getState, sprintId, project }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .deleteSprint(workspaceId, sprintId)
      .then(() => ({ id: sprintId, project }));
  }
);

export const updateSprint = createActionThunk(
  'sprints/update-sprint',
  ({ getState, id, value, project }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .updateSprint(workspaceId, id, value)
      .then(({ data }) => ({ ...data, project }));
  }
);

export const activateSprint = createActionThunk(
  'sprints/activate-sprint',
  ({ getState, id, project }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .activateSprint(workspaceId, id)
      .then(({ data }) => ({ ...data, project }));
  }
);

export const completeSprint = createActionThunk(
  'sprints/complete-sprint',
  ({ getState, id, project }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .completeSprint(workspaceId, id)
      .then(({ data }) => ({ ...data, project }));
  }
);

export const selectSprint = createAction('sprints/select-sprint');
export const clearSprints = createAction('sprints/clear-sprints');

// FILTERS
export const clearFilter = createAction('projects/clear-filter');
export const setFilterSearch = createAction('projects/set-filter-search');
export const setFilterTag = createAction('projects/set-filter-tag');

export const setFilterSprintStatus = createAction(
  'project/set-filter-sprint-status'
);
export const setFilterSprint = createAction('project/set-filter-sprint-sprint');
export const setFilterSprintStartedDateRange = createAction(
  'tasks/set-filter-sprint-started-date-range'
);
export const setFilterSprintEndedDateRange = createAction(
  'tasks/set-filter-sprint-ended-date-range'
);
export const clearSprintFilter = createAction('tasks/clear-sprint-filter');
