import React from 'react'; // { useState, useEffect }
// import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Spin } from 'antd';
import { Translation, useTranslation } from 'react-i18next';

import {
  // COMPLETE_CLOSE_ORDER,
  COMPLETE_KINDS
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';

// import { fetchRelations } from 'store/tasks';

// import useRoutesService from 'services/routes';
// import useModalsService from 'services/modals';

export const CompleteRequire = ({ task, className }) => {
  const { t } = useTranslation('Task');

  // const dispatch = useDispatch();

  // const routes = useRoutesService({ returnUrl: true });
  // const modals = useModalsService({ returnUrl: true });

  // const [isLoading, setIsLoading] = useState(false);

  const currentComplete = COMPLETE_KINDS.find(
    ({ value }) => value === task.completeRequire
  );

  // const { objectId, relation = {} } =
  //   (task.relations || []).find(({ type }) => type === ORDER) || {};

  // const orderLink = `${routes.toOrderStatuses()}${modals.orders.showDetails({
  //   orderStatusId: objectId
  // })}`;

  // const onFetchRelations = async () => {
  //   try {
  //     setIsLoading(true);

  //     await dispatch(fetchRelations({ id: task.id }));
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (currentComplete.value === COMPLETE_CLOSE_ORDER) {
  //     onFetchRelations();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [task.completeRequire]);

  return (
    <DetailsField iconType="diff" title={t('TaskResult')} className={className}>
      <Typography.Text>
        <Translation ns={currentComplete.ns}>
          {translate => translate(currentComplete.label)}
        </Translation>
      </Typography.Text>

      {/* {currentComplete.value === COMPLETE_CLOSE_ORDER && (
        <>
          {isLoading ? (
            <Spin />
          ) : (
            <Link
              to={orderLink}
              style={{ marginLeft: 8 }}
            >{`ID ${relation.id} ${relation.name}`}</Link>
          )}
        </>
      )} */}
    </DetailsField>
  );
};

CompleteRequire.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    completeRequire: PropTypes.string,
    relations: PropTypes.array
  }).isRequired,
  className: PropTypes.string
};

CompleteRequire.defaultProps = {
  className: undefined
};

export default CompleteRequire;
