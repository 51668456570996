import { createAction } from 'redux-actions';

import createActionThunk from '../actions-thunk';
import {
  getMessageOffsetFactory,
  getChatsOffsetFactory,
  getMessageWithEvent
} from './selectors';
import { getWorkspaceId } from '../workspace';
import api from '../../api';
import socket from '../../socket';
import {
  EMIT_SEND_CHAT_MESSAGE,
  EMIT_READ_CHAT_MESSAGE,
  EMIT_CHAT_TYPING
} from '../../constants';

export const fetchChatsByStatusId = createActionThunk(
  'chats/fetch-chats-by-statusId',
  ({ statusId, creator, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const limit = 20;
    const offset = getChatsOffsetFactory(state)(statusId);

    return api.chatStatuses
      .fetch(workspaceId, statusId, creator, offset, limit)
      .then(({ data }) => data);
  }
);

export const fetchChat = createActionThunk(
  'chats/fetch-chat',
  ({ chatId, statusId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.chatStatuses
      .fetchChat(workspaceId, statusId, chatId)
      .then(({ data }) => ({ results: [data], count: undefined }));
  }
);

export const subscribeUserUpdates = createActionThunk(
  'socket/subscribe-user-updates',
  ({ userIds }) => socket.chats.joinUserUpdates(userIds)
);

export const unsubscribeUserUpdates = createActionThunk(
  'socket/unsubscribe-user-updates',
  ({ userIds }) => socket.chats.leaveUserUpdates(userIds)
);

export const addMessage = createAction('chats/add-message');

export const sendReadAck = createActionThunk(
  EMIT_READ_CHAT_MESSAGE,
  ({ message, chatId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return socket.chats.readMessage(chatId, workspaceId, message.id);
  }
);

export const fetchMessages = createActionThunk(
  'chats/fetch-messages',
  ({ statusId, chatId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const limit = 50;
    const offset = getMessageOffsetFactory(state)(chatId);
    const withEvent = getMessageWithEvent(state)(chatId);

    return api.chatStatuses
      .getMessages(workspaceId, statusId, chatId, offset, limit, withEvent)
      .then(({ data }) => data);
  }
);

export const chatTyping = createActionThunk(EMIT_CHAT_TYPING, ({ chatId }) =>
  socket.chats.chatTyping(chatId)
);

export const sendMessage = createActionThunk(
  EMIT_SEND_CHAT_MESSAGE,
  ({ chatId, message, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return socket.chats.sendMessage(chatId, message, workspaceId);
  }
);

export const sendResponse = createActionThunk(
  'chats/send-response',
  ({ workspaceId, orderId, response, getState }) => {
    const currentWorkspaceId = workspaceId || getWorkspaceId(getState());

    return api.marketplace.sendResponse({
      workspaceId: currentWorkspaceId,
      orderId,
      response
    });
  }
);

export const fetchNegotiations = createActionThunk(
  'chats/fetch-negotiations',
  ({ statusId, chatId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.chatStatuses
      .getMessages(workspaceId, statusId, chatId, 0, 1, true, 'order-negotiate')
      .then(({ data }) => data);
  }
);

export const fetchLastNegotiation = createActionThunk(
  'chats/fetch-last-negotiation',
  ({ chatId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.chatStatuses
      .fetchLastNegotiation(workspaceId, chatId)
      .then(({ data }) => data);
  }
);

export const fetchResponse = createActionThunk(
  'chats/fetch-response',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.chatStatuses
      .fetchResponse(workspaceId, id)
      .then(({ data }) => data);
  }
);

export const fetchAttachmentsLocal = createActionThunk(
  'chats/fetch-attachments',
  ({ statusId, chatId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.chatStatuses
      .fetchAttachments(workspaceId, statusId, chatId)
      .then(({ data }) => data);
  }
);

export const setOpenChat = createAction('chats/set-open-chat');
export const clearCommands = createAction('chats/clear-commands');
export const setMessageWithEvent = createAction('chats/handle-with-event');

// Пока не активно
export const editMessage = createActionThunk('chats/edit-message');
