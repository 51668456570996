import React from 'react';
import { LANGUAGE_OPTIONS } from 'i18n-config';

import CustomSelect from '../custom-select';

export const LanguageSelect = ({ value, ...props }) => {
  const resultValue =
    typeof value === 'string'
      ? LANGUAGE_OPTIONS.find(l => l.value === value)
      : value;

  return (
    <CustomSelect
      value={resultValue}
      options={LANGUAGE_OPTIONS}
      isSearchable
      {...props}
    />
  );
};

export default LanguageSelect;
