import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_ASSET,
  TYPE_ORDER_STATUS,
  TYPE_TASK,
  TYPE_FILE,
  ATTACHMENT_SUBSCRIBERS_DRAWER,
  ADD_VALIDITY_DATE_ATTACHMENT_MODAL,
  CHANGE_VALIDITY_DATE_ATTACHMENT_MODAL
} from 'constants/index';
import { TOKEN_FILES } from 'constants/api';

import ActionsDropdown from 'components/common/actions';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteFile } from 'components/common/icons';
import PopConfirm from 'components/common/pop-confirm';

import {
  checkSingleRelation,
  deleteAttachment,
  deleteFromEntity,
  fetchAttachments,
  getUrlAttachment,
  makeCopy,
  renameAttachment,
  restoreAttachment,
  saveToDiskAttachment,
  toTrashAttachment,
  fetchChatFilesInfo
} from 'store/attachments';
import { getActiveWorkspace } from 'store/workspace';
import { changeTaskAttachments } from 'store/tasks';
import { changeOrderStatusAttachments } from 'store/order-statuses';
import { changeAssetAttachments } from 'store/assets';
import {
  subscribeAttachment,
  unsubscribeAttachment
} from 'store/subscriptions';
import { setVisibleDrawer } from 'store/drawers';

import downloadFile from 'hooks/common/use-file-upload/download-file';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import { useLocalStorage } from 'hooks';
import useApi from 'hooks/common/use-file-upload/use-api';
import alphabeticallySort from 'utils/alphabetically-sort';
import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import { useModalContext } from 'components/app/modals-provider';
import RenameFileModal from '../modals/rename-file';
import AccessSettingsDrawer from '../drawers/access-settings';
import VersionsDrawer from '../drawers/versions';
import CopyDrawer from '../drawers/copy';
import { getEntityData } from '../utils';
import AttachFileToEntityDrawer from '../drawers/attach-file-to-entity';

import styles from './actions.module.scss';

const AttachmentActions = ({
  file,
  actionsDeps,
  onDelete,
  onRename,
  onCloseAttachmentModal,
  setChangedName,
  changeFileList,
  customActions,
  subscribeCallback,
  unsubscribeCallback,
  hideSubscribersAction,
  onSubscribeToNewAttachment,
  allowManageSubscribers,
  changeManageSubscribersCallback,
  hideSubscribeAction,
  changeValidityDateCallback,
  hideValidityDateAction,
  onSetValidityDateToNewAttachment,
  ...dropdownProps
}) => {
  const { t } = useTranslation([
    'MyDrive',
    'DeleteFile',
    'DeleteFilesFromTrash',
    'FileSubscribers'
  ]);

  const dispatch = useDispatch();
  const modalContext = useModalContext();

  const [, setToken] = useLocalStorage(TOKEN_FILES);
  const { auth, renameFile } = useApi();

  const [visibleTaskCreator, setVisibleTaskCreator] = useState(false);
  const [visibleRenameFile, setVisibleRenameFile] = useState(false);
  const [visibleAccessSettings, setVisibleAccessSettings] = useState(false);
  const [visibleVersion, setVisibleVersion] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCopy, setVisibleCopy] = useState(false);
  const [visibleDeletePopup, setVisibleDeletePopup] = useState(false);
  const [visibleAttachFileTo, setVisibleAttachFileTo] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [visibleActionsDropdown, setVisibleActionsDropdown] = useState(false);
  const [isLoadingActions, setIsLoadingActions] = useState(false);

  const workspace = useSelector(getActiveWorkspace);

  const {
    sendCopyToComment,
    isFromOtherWorkspace,
    messageUuid,
    allowDeleteFrom = true,
    isPrivate: isPrivateComment,
    entityType,
    entityId
  } = actionsDeps;
  const entityData = getEntityData(actionsDeps); // TODO: REPLACE ALL TO entityType, entityId AND DELETE

  const isFromEntity = entityType || entityData.entityType; // from entity or from attachments-view table (MyDisk section)
  const resultEntityId = entityId || entityData.entityId;
  const resultEntityType = entityType || entityData.entityType;

  const {
    fileId,
    title,
    isTrash,
    isSystem,
    // permissions = {},
    response = {},
    acceptedState // when file is specification or act
  } = file;

  const deleteModalData = {
    title: !isFromEntity
      ? t('DeleteFilesFromTrashHeading', { ns: 'DeleteFilesFromTrash' })
      : t('DeleteFileHeading', { ns: 'DeleteFile' }),
    description: !isFromEntity
      ? t('DeleteFilesFromTrashDesc', { ns: 'DeleteFilesFromTrash' })
      : t('DeleteFileDesc', { ns: 'DeleteFile' }),
    cancelBtnText: !isFromEntity
      ? t('CancelBtn', { ns: 'DeleteFilesFromTrash' })
      : t('NoBtn', { ns: 'DeleteFile' }),
    deleteBtnText: !isFromEntity
      ? t('DeleteBtn', { ns: 'DeleteFilesFromTrash' })
      : t('YesBtn', { ns: 'DeleteFile' }),
    onConfirm: () =>
      !isFromEntity ? remove() : deleteFrom({ isFromModal: true })
  };

  const changeAttachments = useCallback(
    ({ entityType: eType, entityId: eId, attachment, isDelete }) => {
      if (eType === TYPE_TASK) {
        dispatch(changeTaskAttachments({ id: eId, attachment, isDelete }));
      }

      if (eType === TYPE_ORDER_STATUS) {
        dispatch(
          changeOrderStatusAttachments({
            attachment: file,
            isDelete
          })
        );
      }

      if (eType === TYPE_ASSET) {
        dispatch(changeAssetAttachments({ id: eId, attachment, isDelete }));
      }
    },
    [dispatch, file]
  );

  const download = useCallback(
    () =>
      dispatch(getUrlAttachment({ fileId, ...entityData })).then(({ url }) =>
        downloadFile({ url, fileName: title })
      ),
    [dispatch, entityData, fileId, title]
  );

  const trash = useCallback(
    () =>
      dispatch(toTrashAttachment({ fileId })).then(() =>
        showNoticeMessage({ number: NOTICE_NUMBER.fileReplacedToTrash })
      ),
    [dispatch, fileId]
  );

  const restore = useCallback(
    () =>
      dispatch(restoreAttachment({ fileId })).then(() =>
        showNoticeMessage({ number: NOTICE_NUMBER.fileRestored })
      ),
    [dispatch, fileId]
  );

  const remove = async () => {
    try {
      setIsDeleteLoading(true);

      await dispatch(deleteAttachment({ fileId })).then(() => {
        showNoticeMessage({ number: NOTICE_NUMBER.fileDeleted });
      });
    } finally {
      setIsDeleteLoading(false);
      setVisibleDeleteModal(false);
    }
  };

  const saveToDisk = useCallback(
    () =>
      dispatch(
        saveToDiskAttachment({
          entityId: resultEntityId,
          entityType: resultEntityType,
          fileId
        })
      ).then(() =>
        showNoticeMessage({ number: NOTICE_NUMBER.fileSavedToDisk })
      ),
    [dispatch, fileId, resultEntityId, resultEntityType]
  );

  const rename = useCallback(
    async ({ name, format }) => {
      // For website forms and issues, since it's an anonymous user.
      if (workspace) {
        if (!onRename || fileId) {
          const { title: newFileName } = await dispatch(
            renameAttachment({ fileId, name })
          );

          return setChangedName(newFileName);
        }

        const fileName = `${name}.${format}`;

        await auth(workspace.id, workspace.user.id).then(setToken);
        await renameFile({ uuid: response.id, name: fileName });

        return onRename({ id: response.id, name: fileName });
      }

      return null;
    },
    [
      auth,
      dispatch,
      fileId,
      onRename,
      renameFile,
      response.id,
      setChangedName,
      setToken,
      workspace
    ]
  );

  const copy = useCallback(
    async ({ name, relation, isComment = true, isPrivate = false }) => {
      const { relationId, relationType } = Array.isArray(relation)
        ? relation[0]
        : relation;

      const newFile = await dispatch(
        makeCopy({
          fileId,
          isComment,
          isPrivate,
          fileName: name,
          entityType: relationType,
          entityId: relationId
        })
      );

      if (!isComment && isFromEntity) {
        const { entities } = newFile;
        const { objectId, type } = entities[0];

        changeAttachments({
          entityType: type,
          entityId: objectId,
          attachment: newFile,
          isDelete: false
        });
      }

      if (!isFromEntity) {
        dispatch(fetchAttachments({ isTrash: false, ignoreCache: true }));
      }

      setVisibleCopy(false);
      onCloseAttachmentModal();
    },
    [changeAttachments, dispatch, fileId, isFromEntity, onCloseAttachmentModal]
  );

  const deleteFrom = useCallback(
    async ({ isFromPopup, isFromModal } = {}) => {
      try {
        if (isFromPopup || isFromModal) {
          setIsDeleteLoading(true);

          const entity = {
            entityType: resultEntityType,
            entityId: resultEntityId,
            messageUuid
          };

          await dispatch(deleteFromEntity({ fileId, ...entity }));

          if (!entity.messageUuid) {
            changeAttachments({
              ...entity,
              attachment: { fileId },
              isDelete: true
            });
          }

          if (entity.messageUuid) {
            changeFileList(fileId);
          }

          setVisibleDeleteModal(false);

          return;
        }

        const { isView } = await dispatch(checkSingleRelation({ fileId }));

        if (isView) {
          setVisibleDeleteModal(true);
        } else {
          setVisibleDeletePopup(true);
        }
      } finally {
        setIsDeleteLoading(false);
      }
    },
    [
      changeAttachments,
      changeFileList,
      dispatch,
      fileId,
      messageUuid,
      resultEntityId,
      resultEntityType
    ]
  );

  const handleVisibleActionsDropdown = visible => {
    setVisibleActionsDropdown(visible);
  };

  const getChatFileInfo = async () => {
    setIsLoadingActions(true);

    try {
      await dispatch(
        fetchChatFilesInfo({
          entityIds: [fileId],
          entityType,
          entityId,
          messageUuid
        })
      );
    } finally {
      setIsLoadingActions(false);
    }
  };

  useEffect(() => {
    if (visibleActionsDropdown && messageUuid) {
      getChatFileInfo();
    }
  }, [messageUuid, visibleActionsDropdown]);

  const handleSubscribe = async () => {
    dispatch(
      subscribeAttachment({
        entityType: TYPE_FILE,
        entityId: fileId,
        actionDeps: actionsDeps
      })
    ).then(data => {
      subscribeCallback({ data, isSubscribed: true });
      showNoticeMessage();
    });
  };

  const handleUnsubscribe = () => {
    dispatch(
      unsubscribeAttachment({
        entityType: TYPE_FILE,
        entityId: fileId,
        actionDeps: actionsDeps
      })
    ).then(data => {
      unsubscribeCallback({ data, isSubscribed: false });
      showNoticeMessage();
    });
  };

  const handleSubscribeToNewAttachment = () =>
    onSubscribeToNewAttachment({ file, isSubscribed: true });

  const handleUnsubscribeToNewAttachment = () =>
    onSubscribeToNewAttachment({ file, isSubscribed: false });

  const isNewEditableAttachment =
    getIsEditableFile(file) && file.response && file.response.id;

  const getAllowSubscribe = () => {
    const isNewAttachment =
      onSubscribeToNewAttachment && isNewEditableAttachment;

    return isNewAttachment
      ? !file.isNewAttachmentSubscribed &&
          !isTrash &&
          !allowManageSubscribers &&
          !hideSubscribeAction
      : file.canSubscribe &&
          !isTrash &&
          !file.isSubscribed &&
          !allowManageSubscribers &&
          !hideSubscribeAction;
  };

  const getAllowUnsubscribe = () => {
    const isNewAttachment =
      onSubscribeToNewAttachment && isNewEditableAttachment;

    return isNewAttachment
      ? file.isNewAttachmentSubscribed &&
          !isTrash &&
          !allowManageSubscribers &&
          !hideSubscribeAction
      : file.canSubscribe &&
          !isTrash &&
          file.isSubscribed &&
          !allowManageSubscribers &&
          !hideSubscribeAction;
  };

  const showSubscribersDrawer = () => {
    dispatch(
      setVisibleDrawer({
        drawer: ATTACHMENT_SUBSCRIBERS_DRAWER,
        data: {
          file,
          changeManageSubscribersCallback,
          actionDeps: actionsDeps
        }
      })
    );
  };

  const getAllowAddValidityDate = () => {
    const isNewAttachment = file.response && file.response.id;
    const allowAction = !hideValidityDateAction;

    return (
      allowAction &&
      (isNewAttachment
        ? !file.validityDate
        : file.canChangeValidityDate && !file.validityDate)
    );
  };

  const getAllowChangeValidityDate = () => {
    const isNewAttachment = file.response && file.response.id;
    const allowAction = !hideValidityDateAction;

    return (
      allowAction &&
      (isNewAttachment
        ? file.validityDate
        : file.canChangeValidityDate && file.validityDate)
    );
  };

  const handleValidityDateCallback = () => {
    const isNewAttachment =
      onSetValidityDateToNewAttachment && file.response && file.response.id;

    return isNewAttachment
      ? onSetValidityDateToNewAttachment
      : changeValidityDateCallback;
  };

  const handleAddValidityDate = () => {
    if (modalContext && typeof modalContext.showModal === 'function') {
      modalContext.showModal(ADD_VALIDITY_DATE_ATTACHMENT_MODAL, {
        data: file,
        actionDeps: actionsDeps,
        changeValidityDateCallback: handleValidityDateCallback()
      });
    }
  };

  const handleChangeValidityDate = () => {
    if (modalContext && typeof modalContext.showModal === 'function') {
      modalContext.showModal(CHANGE_VALIDITY_DATE_ATTACHMENT_MODAL, {
        data: file,
        actionDeps: actionsDeps,
        changeValidityDateCallback: handleValidityDateCallback()
      });
    }
  };

  const actions = useMemo(
    () =>
      [
        ...[
          {
            key: 'copy',
            title: t('CreateCopyFileAction'),
            onClick: () =>
              isFromEntity
                ? copy({
                    relation: {
                      relationId: resultEntityId,
                      relationType: resultEntityType
                    },
                    isComment: sendCopyToComment,
                    isPrivate: isPrivateComment
                  })
                : setVisibleCopy(true),
            allow:
              getIsEditableFile(file) &&
              !isFromOtherWorkspace &&
              !isSystem &&
              !isTrash &&
              !!fileId,
            icon: 'copy',
            iconSize: 20
          },
          {
            key: 'rename',
            title: t('RenameFileAction'),
            onClick: () => setVisibleRenameFile(true),
            allow:
              !isFromOtherWorkspace && !isSystem && !isTrash && !acceptedState,
            icon: 'edit',
            iconSize: 20
          },
          {
            key: 'access',
            title: t('AccessSettingsFileAction'),
            onClick: () => setVisibleAccessSettings(true),
            allow: !isFromOtherWorkspace && !isTrash && !!fileId,
            icon: 'user-add',
            iconSize: 20
          },
          {
            key: 'download',
            title: t('DownloadFileAction'),
            onClick: download,
            allow: !!fileId,
            icon: 'download',
            iconSize: 20
          },
          {
            key: 'restore',
            title: t('RestoreFileAction'),
            onClick: restore,
            allow: !isFromOtherWorkspace && isTrash
          },
          {
            key: 'history',
            title: t('VersionHistoryFileAction'),
            onClick: () => setVisibleVersion(true),
            allow: !isFromOtherWorkspace && !isSystem && !isTrash && fileId,
            icon: 'read',
            iconSize: 20
          },
          {
            key: 'save',
            title: t('SaveToMyDiskAction'),
            onClick: saveToDisk,
            allow: isFromOtherWorkspace
          },
          {
            key: 'createTask',
            title: t('CreateTaskFileAction'),
            onClick: () => setVisibleTaskCreator(true),
            allow: !isFromOtherWorkspace && !isTrash && !!fileId,
            icon: 'snippets',
            iconSize: 20
          }
        ].sort((a, b) => alphabeticallySort(a, b, 'title')),
        {
          key: 'attachFileTo',
          title: t('AttachFileToAction'),
          onClick: () => setVisibleAttachFileTo(true),
          allow: !isFromOtherWorkspace && !isSystem && !isTrash && !!fileId,
          icon: 'share-alt',
          iconSize: 20
        },
        {
          key: 'subscribe',
          title: t('SubscribeAction', { ns: 'FileSubscribers' }),
          onClick:
            onSubscribeToNewAttachment && isNewEditableAttachment
              ? handleSubscribeToNewAttachment
              : handleSubscribe,
          allow: getAllowSubscribe(),
          icon: 'subscribe',
          iconSize: 20
        },
        {
          key: 'unsubscribe',
          title: t('UnsubscribeAction', { ns: 'FileSubscribers' }),
          onClick:
            onSubscribeToNewAttachment && isNewEditableAttachment
              ? handleUnsubscribeToNewAttachment
              : handleUnsubscribe,
          allow: getAllowUnsubscribe(),
          icon: 'minus-circle',
          iconSize: 20
        },
        {
          key: 'subscribers',
          title: t('Subscribers', { ns: 'FileSubscribers' }),
          onClick: showSubscribersDrawer,
          allow:
            !hideSubscribersAction &&
            !isTrash &&
            file.canSubscribe &&
            !allowManageSubscribers,
          icon: 'subscribers',
          iconSize: 20
        },
        {
          key: 'addValidityDate',
          title: t('AddValidityExpirationAction', { ns: 'MyDrive' }),
          onClick: handleAddValidityDate,
          allow: getAllowAddValidityDate(),
          icon: 'carry-out',
          iconSize: 20
        },
        {
          key: 'changeValidityDate',
          title: t('ChangeValidityExpirationAction', { ns: 'MyDrive' }),
          onClick: handleChangeValidityDate,
          allow: getAllowChangeValidityDate(),
          icon: 'carry-out',
          iconSize: 20
        },
        {
          key: 'deleteFromEntity',
          title: t('DeleteAction'),
          onClick: deleteFrom,
          className: styles.delete,
          allow:
            !isFromOtherWorkspace &&
            !isSystem &&
            !!fileId &&
            isFromEntity &&
            !onDelete &&
            allowDeleteFrom,
          icon: 'delete',
          iconSize: 20
        },
        {
          key: 'toTrash',
          title: t('SendToTrashFileAction'),
          onClick: trash,
          className: styles.delete,
          allow:
            !isFromOtherWorkspace && !isTrash && !isFromEntity && !onDelete,
          icon: 'delete',
          iconSize: 20
        },
        // it is delete when file is attached
        {
          key: 'toTrash',
          title: t('DeleteAction'),
          onClick: onDelete,
          allow: !isFromOtherWorkspace && !!onDelete,
          icon: 'delete',
          iconSize: 20
        },
        {
          key: 'delete',
          title: t('DeletePermanentlyFileAction'),
          onClick: () => setVisibleDeleteModal(true),
          className: styles.delete,
          allow: !isFromOtherWorkspace && isTrash && !isFromEntity && !onDelete,
          icon: 'delete',
          iconSize: 20
        }
      ].filter(a => a.allow),

    [
      t,
      file,
      isFromOtherWorkspace,
      isSystem,
      isTrash,
      fileId,
      acceptedState,
      download,
      restore,
      saveToDisk,
      deleteFrom,
      isFromEntity,
      onDelete,
      allowDeleteFrom,
      trash,
      copy,
      resultEntityId,
      resultEntityType,
      sendCopyToComment,
      isPrivateComment
    ]
  );

  return (
    <>
      <PopConfirm
        title={t('DeleteFilePopoverHeading', { ns: 'DeleteFile' })}
        visible={visibleDeletePopup}
        placement="topRight"
        trigger="none"
        onConfirm={() => deleteFrom({ isFromPopup: true })}
        onVisibleChange={setVisibleDeletePopup}
      >
        <ActionsDropdown
          actions={customActions || actions}
          className={styles.dropdownContent}
          afterChangeVisibilityCallback={handleVisibleActionsDropdown}
          isLoading={isLoadingActions}
          {...dropdownProps}
        />
      </PopConfirm>

      {visibleActionsDropdown && (
        <>
          <TaskCreatorDrawer
            visible={visibleTaskCreator}
            onClose={() => setVisibleTaskCreator(false)}
            value={{ fileList: [file] }}
          />

          <RenameFileModal
            visible={visibleRenameFile}
            title={title}
            onClose={() => setVisibleRenameFile(false)}
            onSubmit={rename}
          />

          <AccessSettingsDrawer
            visible={visibleAccessSettings}
            file={file}
            onClose={() => setVisibleAccessSettings(false)}
          />

          <VersionsDrawer
            visible={visibleVersion}
            fileId={fileId}
            onClose={() => setVisibleVersion(false)}
          />

          <CopyDrawer
            file={file}
            visible={visibleCopy}
            onClose={() => setVisibleCopy(false)}
            onSubmit={copy}
          />

          <AttachFileToEntityDrawer
            visible={visibleAttachFileTo}
            onClose={() => setVisibleAttachFileTo(false)}
            file={file}
          />

          <ModalDeleteConfirm
            visible={visibleDeleteModal}
            title={deleteModalData.title}
            description={deleteModalData.description}
            cancelBtnText={deleteModalData.cancelBtnText}
            deleteBtnText={deleteModalData.deleteBtnText}
            onConfirm={deleteModalData.onConfirm}
            isLoading={isDeleteLoading}
            iconModal={DeleteFile}
            onClose={() => setVisibleDeleteModal(false)}
          />
        </>
      )}
    </>
  );
};

AttachmentActions.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    isTrash: PropTypes.bool,
    isSystem: PropTypes.bool,
    isSubscribed: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    canChangeValidityDate: PropTypes.bool,
    validityDate: PropTypes.string
  }),
  actionsDeps: PropTypes.shape({
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    assetId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    messageUuid: PropTypes.string,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityType: PropTypes.string,
    isFromOtherWorkspace: PropTypes.bool
  }),
  setChangedName: PropTypes.func,
  onCloseAttachmentModal: PropTypes.func,
  customActions: PropTypes.array,
  subscribeCallback: PropTypes.func,
  unsubscribeCallback: PropTypes.func,
  hideSubscribersAction: PropTypes.bool,
  onSubscribeToNewAttachment: PropTypes.func,
  allowManageSubscribers: PropTypes.bool,
  changeManageSubscribersCallback: PropTypes.func,
  hideSubscribeAction: PropTypes.bool,
  changeValidityDateCallback: PropTypes.func,
  hideValidityDateAction: PropTypes.bool,
  onSetValidityDateToNewAttachment: PropTypes.func
};

AttachmentActions.defaultProps = {
  file: {},
  actionsDeps: {},
  setChangedName: () => {},
  onCloseAttachmentModal: () => {},
  customActions: undefined,
  subscribeCallback: () => {},
  unsubscribeCallback: () => {},
  hideSubscribersAction: false,
  onSubscribeToNewAttachment: undefined,
  allowManageSubscribers: false,
  changeManageSubscribersCallback: () => {},
  hideSubscribeAction: false,
  changeValidityDateCallback: () => {},
  hideValidityDateAction: false,
  onSetValidityDateToNewAttachment: undefined
};

export default React.memo(AttachmentActions);
