import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

export const RestoreContactModal = ({ link, ...props }) => {
  const { t } = useTranslation('AddContact');

  return (
    <Modal
      title={t('RestoreContact')}
      width={510}
      contentStyle={{ padding: 24 }}
      data-qa="qa-f57idie2gb5e9zl"
      {...props}
    >
      <p style={{ marginBottom: 20 }}>{t('SimilarContactFinded')}</p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data-qa="qa-ufk9hyxr96z2s1x"
      >
        <Button
          type="primary"
          className="ant-btn-background-ghost"
          data-qa="qa-rki9mhqhwq9yw1z"
          href={link}
          target="_blank"
        >
          {t('ShowContactBtn')}
        </Button>
      </div>
    </Modal>
  );
};

RestoreContactModal.propTypes = {
  link: PropTypes.string
};

RestoreContactModal.defaultProps = {
  link: ''
};

export default RestoreContactModal;
