import axios from 'axios';

const IP_API_URL = 'https://get.geojs.io';

const api = axios.create({ baseURL: IP_API_URL });

export default () => ({
  fetchIP: () => api.get(`${IP_API_URL}/v1/ip`),

  fetchInfo: ip => api.get(`${IP_API_URL}/v1/ip/geo/${ip}.json`)
});
