import React from 'react';

export const GoogleIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon="google"
  >
    <path
      d="M6.43242 14.0865L5.73625 16.6854L3.19176 16.7392C2.43133 15.3288 2 13.7151 2 12.0002C2 10.342 2.40328 8.77822 3.11813 7.40131H3.11867L5.38399 7.81662L6.37633 10.0683C6.16863 10.6738 6.05543 11.3238 6.05543 12.0002C6.05551 12.7343 6.18848 13.4376 6.43242 14.0865Z"
      fill="#FBBB00"
    />
    <path
      d="M21.8252 10.1321C21.94 10.737 21.9999 11.3617 21.9999 12.0002C21.9999 12.7161 21.9246 13.4145 21.7812 14.0881C21.2944 16.3804 20.0224 18.3821 18.2604 19.7986L18.2598 19.798L15.4065 19.6524L15.0027 17.1315C16.1719 16.4458 17.0857 15.3727 17.567 14.0881H12.2197V10.1321H17.645H21.8252Z"
      fill="#518EF8"
    />
    <path
      d="M18.26 19.7978L18.2606 19.7984C16.5469 21.1758 14.3699 22 12.0001 22C8.1919 22 4.88092 19.8715 3.19189 16.739L6.43256 14.0863C7.27705 16.3401 9.45124 17.9445 12.0001 17.9445C13.0957 17.9445 14.1221 17.6484 15.0029 17.1313L18.26 19.7978Z"
      fill="#28B446"
    />
    <path
      d="M18.383 4.30219L15.1434 6.95438C14.2319 6.38461 13.1544 6.05547 12 6.05547C9.39344 6.05547 7.1786 7.73348 6.37641 10.0681L3.11871 7.4011H3.11816C4.78246 4.19231 8.1352 2 12 2C14.4264 2 16.6511 2.8643 18.383 4.30219Z"
      fill="#F14336"
    />
  </svg>
);

export default GoogleIcon;
