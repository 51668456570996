import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CREATE_BOT, CONNECT_BOT, VIDEOS } from 'constants/index';

import {
  FormInput,
  FormRadio,
  SubmitWrapper,
  validateMaxLength,
  withoutBubbling
} from 'components/common/hook-form';
import Button from 'components/common/button';
import VideoBtn from 'components/common/video-btn';

import Instruction from './instruction';

import styles from './telegram-bot.module.scss';

const TelegramBotForm = ({ defaultValues, isLoading, onSubmit }) => {
  const methods = useForm({
    defaultValues: {
      method: CREATE_BOT,
      token: defaultValues.token
    }
  });

  const { t } = useTranslation(['ConnectTelegram', 'Errors']);

  const method = methods.watch('method');

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(onSubmit))
        }
      >
        <FormRadio
          name="method"
          itemProps={{
            className: styles.m24
          }}
          options={[
            {
              label: 'NewBotRadio',
              ns: 'ConnectTelegram',
              value: CREATE_BOT
            },
            {
              label: 'ExistingBotRadio',
              ns: 'ConnectTelegram',
              value: CONNECT_BOT
            }
          ]}
        />

        {method === CREATE_BOT ? (
          <VideoBtn
            slug={VIDEOS.createTelegramBot}
            style={{ marginBottom: 24 }}
          />
        ) : (
          <VideoBtn
            slug={VIDEOS.connectTelegramBot}
            style={{ marginBottom: 24 }}
          />
        )}

        <Instruction method={method} />

        <FormInput
          name="token"
          label={t('APIToken')}
          placeholder={t('APITokenPlchldr')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            maxLength: validateMaxLength(2500)
          }}
        />

        <SubmitWrapper>
          <Button
            type="primary"
            size="large"
            width="expanded"
            htmlType="submit"
            loading={isLoading}
            className={styles.button}
          >
            {t('NextBtn')}
          </Button>
        </SubmitWrapper>
      </form>
    </FormProvider>
  );
};

TelegramBotForm.propTypes = {
  defaultValues: PropTypes.shape({
    token: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

TelegramBotForm.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export default TelegramBotForm;
