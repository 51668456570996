import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

import Button from 'components/common/button';

import { validateFileUpload } from 'utils/validators';

import FormItem from '../form-item';
import Icon from '../../icon';
import Attachments from '../../attachments';

import styles from './attach-file.module.scss';

// value: { file, fileList }

const defaultMaxSize = 5 * 1024 * 1024;
const notAllowedExtensions = ['.exe', '.msi'];
const allowedUploadTypes = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/gif'
];

const FormAttachFile = ({
  rules,
  name,
  label,
  itemProps,
  isMultiple,
  maxSize,
  buttonProps,
  isImage,
  children,
  isHideAttachment,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem name={name} {...itemProps} errors={formState.errors}>
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value, onChange, ...renderProps } = field;

          const transformFileList = (value.fileList || []).map(f => ({
            uid: f.uid,
            name: f.name,
            size: f.size,
            type: f.type,
            isSystem: true
          }));

          const handleDeleteAttachment = attachment => {
            const filteredFileList = value.fileList.filter(
              ({ uid }) => uid !== attachment.uid
            );

            onChange({ fileList: filteredFileList });
          };

          return (
            <>
              <Upload
                className={styles.uploadBtn}
                showUploadList={false}
                multiple={isMultiple}
                fileList={value.fileList}
                beforeUpload={(file, fileListArr) => {
                  const fileList = [file];

                  if (
                    validateFileUpload({
                      file: fileList,
                      maxSize,
                      allowedTypes: allowedUploadTypes,
                      isImage
                    })
                  ) {
                    if (!isMultiple) {
                      onChange({
                        file: fileList[0],
                        fileList: [fileList[0]]
                      });
                    } else {
                      const allowedFileList = fileListArr.filter(f => {
                        const fileExt = f.name
                          .split('.')
                          .pop()
                          .toLowerCase();

                        return !notAllowedExtensions.includes(`.${fileExt}`);
                      });

                      const currentFileList = Array.isArray(value.fileList)
                        ? value.fileList
                        : [];

                      const updatedFileList = [
                        ...currentFileList,
                        ...allowedFileList
                      ];
                      onChange({ fileList: updatedFileList });
                    }
                  }

                  return false;
                }}
                {...renderProps}
                {...props}
              >
                {children || (
                  <Button
                    width="full"
                    type="secondary"
                    className={styles.button}
                    {...buttonProps}
                  >
                    <Icon type="paper-clip" />

                    {label}
                  </Button>
                )}
              </Upload>

              {!isHideAttachment && (
                <Attachments
                  attachmentProps={{
                    onDelete: handleDeleteAttachment
                  }}
                  fileList={transformFileList}
                  className={styles.attachaments}
                />
              )}
            </>
          );
        }}
      />
    </FormItem>
  );
};

FormAttachFile.propTypes = {
  rules: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemProps: PropTypes.object,
  isMultiple: PropTypes.bool,
  maxSize: PropTypes.number,
  buttonProps: PropTypes.object,
  isImage: PropTypes.bool,
  children: PropTypes.node,
  isHideAttachment: PropTypes.bool
};

FormAttachFile.defaultProps = {
  rules: {},
  label: null,
  itemProps: {},
  isMultiple: false,
  maxSize: defaultMaxSize,
  buttonProps: {},
  isImage: true,
  children: undefined,
  isHideAttachment: false
};

export default FormAttachFile;
