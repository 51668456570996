import React from 'react';

export const EmptySalesIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
  >
    <path
      d="M54.5426 117.671V140.899C69.6686 140.32 84.4427 136.169 97.6569 128.785C97.6569 125.099 97.6569 121.399 97.6569 117.714L54.5426 117.671Z"
      fill="#32C9C9"
    />
    <path
      d="M82.0569 9.09961C67.6569 9.09961 16.814 34.4996 6.05687 54.8139C-4.0717 73.9282 25.4569 87.671 25.4569 101.1C25.4569 128.914 64.4569 114.242 99.7426 107.485C135.028 100.728 154.257 70.8282 143.471 47.671C132.685 24.5139 110.014 9.09961 82.0569 9.09961Z"
      fill="#EBF8F8"
    />
    <path
      d="M123.585 54.8135L97.6854 117.671C96.9997 120.442 87.5997 122.642 76.114 122.642C64.6283 122.642 55.2283 120.528 54.5426 117.671C45.914 96.7182 37.2807 75.7659 28.6426 54.8135L76.114 55.6992L123.585 54.8135Z"
      fill="url(#paint0_linear_75219_422941)"
    />
    <path
      d="M76.114 80.0001C102.75 80.0001 124.343 69.8753 124.343 57.3858C124.343 44.8962 102.75 34.7715 76.114 34.7715C49.4781 34.7715 27.8854 44.8962 27.8854 57.3858C27.8854 69.8753 49.4781 80.0001 76.114 80.0001Z"
      fill="#32C9C9"
    />
    <path
      d="M117.785 52.2706C117.785 56.8992 113.5 61.0992 106.571 64.2135C105.041 64.8974 103.477 65.503 101.885 66.0278C98.618 67.0914 95.2753 67.908 91.8854 68.4706C86.6938 69.3498 81.4367 69.7847 76.1712 69.7706C53.0997 69.7706 34.4426 61.9563 34.4426 52.2706C34.4426 42.5849 53.0997 34.8135 76.114 34.8135C99.1283 34.8135 117.785 42.5992 117.785 52.2706Z"
      fill="#16969C"
    />
    <path
      d="M95.2997 48.0428L39.414 29.5857C38.8792 29.4095 38.3844 29.1297 37.9578 28.7622C37.5312 28.3947 37.1812 27.9467 36.9278 27.4439C36.6745 26.9411 36.5226 26.3933 36.481 25.8318C36.4394 25.2703 36.5089 24.7061 36.6854 24.1714L39.5426 15.6C39.7188 15.0652 39.9986 14.5704 40.3661 14.1438C40.7336 13.7172 41.1815 13.3672 41.6843 13.1138C42.1872 12.8604 42.735 12.7086 43.2965 12.667C43.858 12.6254 44.4222 12.6948 44.9569 12.8714L100.8 31.4C101.34 31.5703 101.84 31.8459 102.273 32.2109C102.706 32.5759 103.062 33.023 103.321 33.5264C103.58 34.0298 103.737 34.5796 103.782 35.1439C103.828 35.7082 103.761 36.2759 103.585 36.8143L100.728 45.3857C100.358 46.456 99.5793 47.3362 98.5622 47.8341C97.545 48.332 96.3721 48.407 95.2997 48.0428Z"
      fill="#E2E2E2"
    />
    <path
      d="M45.9854 26.9996C46.7908 27.2734 47.6593 27.3013 48.4806 27.0799C49.3018 26.8585 50.0386 26.3977 50.5971 25.7561C51.1556 25.1146 51.5106 24.3214 51.6169 23.4775C51.7232 22.6336 51.576 21.7772 51.194 21.0172C50.812 20.2572 50.2125 19.6281 49.4718 19.2099C48.7311 18.7918 47.8827 18.6035 47.0347 18.669C46.1866 18.7345 45.3773 19.0509 44.7096 19.5779C44.0419 20.1048 43.5462 20.8186 43.2854 21.6282C42.9407 22.6987 43.0305 23.8619 43.5356 24.8667C44.0407 25.8716 44.9206 26.6377 45.9854 26.9996Z"
      fill="#C4C4C4"
    />
    <path
      d="M74.2283 37.014L55.3283 30.7711C54.9685 30.6535 54.6702 30.3978 54.4987 30.0603C54.3273 29.7228 54.2968 29.3311 54.414 28.9711C54.4722 28.7914 54.5655 28.6249 54.6884 28.4814C54.8114 28.338 54.9615 28.2203 55.1303 28.1353C55.299 28.0502 55.483 27.9995 55.6714 27.986C55.8599 27.9726 56.0492 27.9967 56.2283 28.0569L75.1283 34.2997C75.3068 34.3593 75.4717 34.4537 75.6137 34.5772C75.7556 34.7008 75.8717 34.8512 75.9553 35.0198C76.039 35.1884 76.0885 35.3719 76.101 35.5596C76.1135 35.7474 76.0888 35.9358 76.0283 36.114C75.9077 36.4712 75.6508 36.7662 75.3137 36.9347C74.9765 37.1033 74.5864 37.1318 74.2283 37.014Z"
      fill="#C4C4C4"
    />
    <path
      d="M94.8569 36.514L57.4997 24.1712C57.1426 24.0506 56.8476 23.7937 56.679 23.4565C56.5104 23.1194 56.4819 22.7293 56.5997 22.3712C56.7174 22.0114 56.9731 21.713 57.3106 21.5416C57.648 21.3702 58.0398 21.3397 58.3997 21.4569L95.7569 33.7997C96.1166 33.9174 96.415 34.1731 96.5864 34.5106C96.7579 34.8481 96.7883 35.2398 96.6712 35.5997C96.613 35.7795 96.5197 35.946 96.3968 36.0894C96.2738 36.2329 96.1236 36.3506 95.9549 36.4356C95.7861 36.5207 95.6022 36.5714 95.4137 36.5848C95.2252 36.5983 95.036 36.5742 94.8569 36.514Z"
      fill="#C4C4C4"
    />
    <path
      d="M88.8569 41.8428L81.8712 39.5428C81.6929 39.4837 81.5281 39.3901 81.3861 39.2673C81.244 39.1445 81.1275 38.9949 81.0433 38.8271C80.9591 38.6593 80.9087 38.4765 80.8951 38.2893C80.8815 38.102 80.9049 37.9139 80.964 37.7357C81.0231 37.5574 81.1167 37.3926 81.2395 37.2506C81.3623 37.1085 81.5119 36.9921 81.6797 36.9078C81.8475 36.8236 82.0303 36.7732 82.2175 36.7596C82.4048 36.746 82.5929 36.7694 82.7712 36.8285L89.7569 39.0999C89.9451 39.1518 90.1207 39.2416 90.2728 39.3639C90.425 39.4862 90.5504 39.6384 90.6414 39.8111C90.7325 39.9838 90.7871 40.1733 90.8021 40.368C90.817 40.5626 90.7919 40.7582 90.7283 40.9428C90.6692 41.128 90.5729 41.2992 90.4454 41.4459C90.3178 41.5926 90.1617 41.7117 89.9865 41.796C89.8113 41.8802 89.6207 41.9278 89.4265 41.9359C89.2323 41.9439 89.0384 41.9123 88.8569 41.8428Z"
      fill="#C4C4C4"
    />
    <path
      d="M123.385 39.7569L61.3283 63.0998C60.1252 63.5432 58.7955 63.4914 57.6305 62.9558C56.4656 62.4202 55.5606 61.4445 55.114 60.2426L51.5569 50.7998C51.3354 50.2042 51.2336 49.5708 51.2574 48.9359C51.2812 48.3009 51.43 47.6769 51.6955 47.0996C51.9609 46.5222 52.3377 46.003 52.8042 45.5716C53.2707 45.1402 53.8177 44.8051 54.414 44.5855L116.443 21.2712C117.039 21.0495 117.674 20.9475 118.31 20.9711C118.946 20.9947 119.571 21.1434 120.149 21.4088C120.728 21.6741 121.248 22.0509 121.681 22.5176C122.114 22.9842 122.45 23.5316 122.671 24.1284L126.214 33.5712C126.658 34.7667 126.609 36.0894 126.079 37.2491C125.549 38.4088 124.58 39.3107 123.385 39.7569Z"
      fill="white"
    />
    <path
      d="M65.2569 56.2427C66.1436 55.9151 66.9135 55.3323 67.4694 54.5676C68.0253 53.803 68.3423 52.8909 68.3804 51.9463C68.4185 51.0018 68.176 50.0671 67.6834 49.2602C67.1909 48.4533 66.4705 47.8103 65.613 47.4124C64.7555 47.0145 63.7993 46.8794 62.8652 47.0243C61.931 47.1692 61.0606 47.5875 60.3639 48.2264C59.6672 48.8653 59.1753 49.6963 58.9504 50.6145C58.7254 51.5327 58.7773 52.4969 59.0997 53.3856C59.5374 54.5809 60.432 55.5534 61.5866 56.0892C62.7413 56.625 64.0615 56.6802 65.2569 56.2427Z"
      fill="#2D9EA3"
    />
    <path
      d="M97.114 44.9857L76.1283 52.8857C75.9277 52.9747 75.711 53.0218 75.4915 53.0243C75.2721 53.0267 75.0544 52.9843 74.8519 52.8998C74.6493 52.8152 74.4662 52.6903 74.3135 52.5325C74.1609 52.3748 74.0421 52.1876 73.9643 51.9824C73.8865 51.7772 73.8513 51.5582 73.861 51.3389C73.8707 51.1197 73.925 50.9047 74.0205 50.7071C74.1161 50.5095 74.251 50.3335 74.4169 50.1898C74.5828 50.0461 74.7762 49.9378 74.9854 49.8714L95.9854 41.9571C96.1824 41.883 96.3921 41.8484 96.6024 41.8554C96.8128 41.8623 97.0197 41.9106 97.2114 41.9975C97.4031 42.0843 97.5758 42.2081 97.7196 42.3618C97.8634 42.5154 97.9756 42.6959 98.0497 42.8928C98.1238 43.0898 98.1584 43.2995 98.1515 43.5098C98.1446 43.7202 98.0963 43.9271 98.0094 44.1188C97.9225 44.3105 97.7987 44.4832 97.6451 44.627C97.4914 44.7708 97.311 44.883 97.114 44.9571V44.9857Z"
      fill="#2D9EA3"
    />
    <path
      d="M114.857 29.9571L73.4283 45.5571C73.0349 45.6819 72.6086 45.6513 72.2371 45.4716C71.8655 45.2919 71.5769 44.9767 71.4306 44.5907C71.2843 44.2048 71.2913 43.7775 71.4503 43.3966C71.6093 43.0157 71.9082 42.7102 72.2854 42.5428L113.714 26.9428C114.107 26.818 114.534 26.8486 114.905 27.0283C115.277 27.208 115.565 27.5232 115.712 27.9092C115.858 28.2951 115.851 28.7224 115.692 29.1033C115.533 29.4842 115.234 29.7897 114.857 29.9571Z"
      fill="#2D9EA3"
    />
    <path
      d="M113.357 38.8855L105.6 41.7427C105.209 41.8548 104.791 41.8157 104.428 41.633C104.065 41.4504 103.785 41.1376 103.642 40.7572C103.5 40.3767 103.506 39.9566 103.66 39.5806C103.813 39.2045 104.103 38.9002 104.471 38.7284L112.228 35.8713C112.43 35.7771 112.649 35.7256 112.872 35.7202C113.094 35.7148 113.316 35.7554 113.522 35.8397C113.728 35.9239 113.914 36.0498 114.069 36.2096C114.224 36.3693 114.345 36.5595 114.423 36.768C114.501 36.9765 114.535 37.1989 114.523 37.4213C114.511 37.6436 114.453 37.861 114.353 38.0599C114.253 38.2588 114.113 38.4348 113.941 38.5769C113.77 38.719 113.571 38.8241 113.357 38.8855Z"
      fill="#2D9EA3"
    />
    <path
      d="M106.985 62.5709C106.9 63.1304 106.761 63.6805 106.571 64.2137C105.041 64.8977 103.477 65.5032 101.885 66.028C98.618 67.0917 95.2753 67.9083 91.8854 68.4709L91.6997 68.328C91.514 68.1709 91.3283 68.0137 91.1569 67.8423L91.0569 67.7423C89.9251 66.6026 89.1043 65.1919 88.6726 63.6449C88.241 62.0978 88.213 60.4659 88.5913 58.9049C88.9695 57.344 89.7415 55.9059 90.8334 54.7281C91.9254 53.5502 93.3009 52.6718 94.8289 52.1766C96.3568 51.6814 97.9861 51.586 99.5614 51.8995C101.137 52.213 102.605 52.9248 103.827 53.9672C105.049 55.0096 105.984 56.3477 106.542 57.8538C107.099 59.36 107.262 60.984 107.014 62.5709H106.985Z"
      fill="#FDA402"
    />
    <path
      d="M101.5 59.214C101.391 59.893 101.084 60.5245 100.616 61.0285C100.148 61.5324 99.5412 61.8861 98.8721 62.0446C98.2031 62.2032 97.502 62.1595 96.8578 61.919C96.2136 61.6786 95.6553 61.2522 95.2538 60.694C94.8522 60.1359 94.6254 59.471 94.6023 58.7838C94.5791 58.0966 94.7605 57.418 95.1235 56.834C95.4865 56.2501 96.0148 55.7871 96.6413 55.5037C97.2678 55.2204 97.9643 55.1295 98.6426 55.2426C99.5466 55.3933 100.354 55.8954 100.89 56.6394C101.425 57.3834 101.644 58.3089 101.5 59.214Z"
      fill="white"
    />
    <path
      d="M91.8711 68.4713L92.1283 68.6856C92.0286 68.6267 91.9373 68.5547 91.8569 68.4713L91.6711 68.3285C91.4854 68.1713 91.2997 68.0142 91.1283 67.8428L91.0283 67.7428L91.3283 65.8428C91.37 65.5789 91.4634 65.3259 91.6031 65.0983C91.7428 64.8707 91.9261 64.6729 92.1424 64.5162C92.3588 64.3596 92.604 64.2473 92.8638 64.1856C93.1237 64.124 93.3932 64.1143 93.6569 64.157L100.6 65.2428C100.856 65.2807 101.102 65.3699 101.322 65.5049C101.543 65.6398 101.735 65.8179 101.885 66.0285C98.6134 67.0929 95.2658 67.9094 91.8711 68.4713Z"
      fill="#FFE181"
    />
    <path
      d="M44.0569 41.3565C44.3077 42.4983 44.2154 43.6888 43.7916 44.7784C43.3677 45.8679 42.6311 46.8077 41.6746 47.4798C40.718 48.1518 39.5841 48.5261 38.4154 48.5555C37.2467 48.5848 36.0954 48.268 35.1063 47.6449C34.1171 47.0217 33.3343 46.12 32.8562 45.0532C32.3782 43.9864 32.2262 42.802 32.4194 41.649C32.6126 40.496 33.1423 39.4259 33.9421 38.5732C34.7418 37.7204 35.7758 37.1232 36.914 36.8565C37.6788 36.6773 38.4715 36.6519 39.2462 36.7817C40.0208 36.9115 40.762 37.194 41.4266 37.6127C42.0911 38.0313 42.6659 38.5779 43.1174 39.2206C43.569 39.8633 43.8883 40.5893 44.0569 41.3565Z"
      fill="#FDA402"
    />
    <path
      d="M40.0712 40.6565C40.134 40.9379 40.1408 41.229 40.0912 41.513C40.0416 41.797 39.9365 42.0685 39.7819 42.3119C39.6274 42.5554 39.4264 42.766 39.1905 42.9317C38.9545 43.0975 38.6883 43.2151 38.4069 43.278C38.1255 43.3408 37.8344 43.3476 37.5504 43.298C37.2664 43.2484 36.9949 43.1433 36.7515 42.9887C36.508 42.8342 36.2974 42.6332 36.1317 42.3973C35.9659 42.1614 35.8483 41.8951 35.7854 41.6137C35.6585 41.0454 35.7626 40.4499 36.0747 39.9583C36.3868 39.4667 36.8814 39.1192 37.4497 38.9923C38.018 38.8653 38.6135 38.9694 39.1051 39.2815C39.5967 39.5936 39.9442 40.0882 40.0712 40.6565Z"
      fill="white"
    />
    <path
      d="M40.9283 44.3997L36.6426 45.3711C36.3137 45.4495 36.0282 45.653 35.8469 45.9384C35.6655 46.2237 35.6025 46.5686 35.6712 46.8997L35.9426 48.0854C37.0953 48.5902 38.3791 48.7142 39.6072 48.4393C40.8353 48.1644 41.9436 47.5049 42.7712 46.5568L42.4997 45.3711C42.4629 45.2027 42.3925 45.0435 42.2929 44.9029C42.1933 44.7622 42.0664 44.6431 41.9198 44.5524C41.7732 44.4618 41.6099 44.4016 41.4396 44.3754C41.2692 44.3491 41.0954 44.3574 40.9283 44.3997Z"
      fill="#FFE181"
    />
    <path
      d="M13.014 94.3852C12.6351 94.3852 12.2718 94.2347 12.0039 93.9667C11.7359 93.6988 11.5854 93.3355 11.5854 92.9566V77.0137C11.5854 76.6216 11.7412 76.2455 12.0185 75.9682C12.2958 75.6909 12.6719 75.5352 13.064 75.5352C13.4562 75.5352 13.8322 75.6909 14.1095 75.9682C14.3868 76.2455 14.5426 76.6216 14.5426 77.0137V92.9137C14.5491 93.1136 14.5135 93.3127 14.4382 93.4979C14.3629 93.6832 14.2496 93.8506 14.1055 93.9894C13.9614 94.1281 13.7898 94.235 13.6018 94.3031C13.4138 94.3713 13.2135 94.3993 13.014 94.3852Z"
      fill="#32C9C9"
    />
    <path
      d="M20.9712 86.4425H5.01401C4.62187 86.4425 4.24579 86.2867 3.9685 86.0094C3.69122 85.7321 3.53544 85.3561 3.53544 84.9639C3.53544 84.5718 3.69122 84.1957 3.9685 83.9184C4.24579 83.6411 4.62187 83.4854 5.01401 83.4854H20.9712C21.3633 83.4854 21.7394 83.6411 22.0167 83.9184C22.2939 84.1957 22.4497 84.5718 22.4497 84.9639C22.4497 85.3561 22.2939 85.7321 22.0167 86.0094C21.7394 86.2867 21.3633 86.4425 20.9712 86.4425Z"
      fill="#32C9C9"
    />
    <path
      d="M124.343 107.014C124.047 107.014 123.764 106.897 123.555 106.688C123.346 106.479 123.228 106.195 123.228 105.9V93.8711C123.228 93.5756 123.346 93.2922 123.555 93.0832C123.764 92.8742 124.047 92.7568 124.343 92.7568C124.638 92.7568 124.922 92.8742 125.131 93.0832C125.339 93.2922 125.457 93.5756 125.457 93.8711V105.9C125.457 106.195 125.339 106.479 125.131 106.688C124.922 106.897 124.638 107.014 124.343 107.014Z"
      fill="#32C9C9"
    />
    <path
      d="M130.357 101H118.328C118.17 101.018 118.011 101.002 117.859 100.954C117.708 100.906 117.568 100.826 117.45 100.72C117.331 100.614 117.236 100.485 117.171 100.34C117.107 100.195 117.073 100.037 117.073 99.8786C117.073 99.7197 117.107 99.5626 117.171 99.4175C117.236 99.2725 117.331 99.1428 117.45 99.0369C117.568 98.9311 117.708 98.8514 117.859 98.8032C118.011 98.755 118.17 98.7393 118.328 98.7571H130.357C130.632 98.7883 130.887 98.9199 131.072 99.1267C131.257 99.3336 131.359 99.6012 131.359 99.8786C131.359 100.156 131.257 100.424 131.072 100.63C130.887 100.837 130.632 100.969 130.357 101Z"
      fill="#32C9C9"
    />
    <path
      d="M136.914 68.2571C136.742 68.2534 136.579 68.1825 136.459 68.0597C136.338 67.9369 136.271 67.7718 136.271 67.6V60.6429C136.271 60.4724 136.339 60.3088 136.459 60.1883C136.58 60.0677 136.744 60 136.914 60C137.085 60 137.248 60.0677 137.369 60.1883C137.489 60.3088 137.557 60.4724 137.557 60.6429V67.6714C137.539 67.8306 137.465 67.978 137.346 68.0858C137.228 68.1937 137.074 68.2546 136.914 68.2571Z"
      fill="#32C9C9"
    />
    <path
      d="M140.4 64.814H133.428C133.258 64.814 133.094 64.7463 132.974 64.6257C132.853 64.5052 132.785 64.3417 132.785 64.1712C132.785 64.0007 132.853 63.8372 132.974 63.7166C133.094 63.596 133.258 63.5283 133.428 63.5283H140.4C140.57 63.5283 140.734 63.596 140.854 63.7166C140.975 63.8372 141.043 64.0007 141.043 64.1712C141.043 64.3417 140.975 64.5052 140.854 64.6257C140.734 64.7463 140.57 64.814 140.4 64.814Z"
      fill="#32C9C9"
    />
    <defs>
      <linearGradient
        id="paint0_linear_75219_422941"
        x1="28.6426"
        y1="88.7278"
        x2="123.571"
        y2="88.7278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptySalesIcon;
