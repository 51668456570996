import { TASK_STATUS } from 'constants/tasks';

export const getTasksInitialPageData = () => {
  const result = {};

  TASK_STATUS.forEach((_, key) => {
    result[key] = {
      number: 0,
      totalItems: 0,
      totalPages: 0
    };
  });

  return result;
};

export default getTasksInitialPageData;
