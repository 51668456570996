import { useDispatch, useSelector } from 'react-redux';

import {
  getCollapsedOrderStatusContractorPanel,
  setCollapsedOrderStatusContractorPanel
} from 'store/order-statuses';

import useLocalStorage from 'hooks/common/use-local-storage';

export const ORDER_COLLAPSED_CONTRACTOR_PANEL =
  'order-collapsed-contracor-panel';

export const useCollapsedContractorPanel = (callback = () => {}) => {
  const dispatch = useDispatch();

  const collapsed = useSelector(getCollapsedOrderStatusContractorPanel);
  const [, setStorageCollapsed] = useLocalStorage(
    ORDER_COLLAPSED_CONTRACTOR_PANEL
  );

  const setCollapsed = value => {
    setStorageCollapsed(value);
    dispatch(setCollapsedOrderStatusContractorPanel(value));
    callback(value);
  };

  return [collapsed, setCollapsed];
};

export default useCollapsedContractorPanel;
