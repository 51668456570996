import i18n from 'i18n-config';
import memoize from 'lodash/memoize';
import plural from 'plural-ru';

import getTimeByEstimate, { getHoursByTime } from 'utils/get-time-by-estimate';

export const getHours = workLog => {
  if (workLog) {
    return getHoursByTime(workLog);
  }

  return 0;
};

export const getEstimateTitle = memoize((estimate = 0) => {
  const { hours, minutes } = getTimeByEstimate(estimate);

  const hoursCount = plural(
    hours,
    i18n.t('SingularFormHour', { ns: 'Common' }),
    i18n.t('GenitiveFormHour', { ns: 'Common' }),
    i18n.t('PluralFormHour', { ns: 'Common' })
  );
  const minutesCount = plural(
    minutes,
    i18n.t('SingularFormMinute', { ns: 'Common' }),
    i18n.t('GenitiveFormMinute', { ns: 'Common' }),
    i18n.t('PluralFormMinute', { ns: 'Common' })
  );

  const hoursLabel = hours ? `${hours} ${hoursCount}` : '';
  const minutesLabel = minutes ? `${minutes} ${minutesCount}` : '';

  if (!minutes) {
    return hoursLabel;
  }

  return `${hoursLabel} ${minutesLabel}`;
});

export const getPercent = (workLog, estimate) => (workLog / estimate) * 100;
