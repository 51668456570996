import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import ActionsDropdown from 'components/common/actions';
import Tooltip from 'components/common/tooltip';

import {
  counterIncrementMessageTemplate,
  deleteMessageTemplate
} from 'store/message-templates';

import { MESSAGE_TEMPLATES_EDIT } from '../constants';

import styles from './components.module.scss';

const MessageTemplatesItem = ({
  template,
  setSelectedComponent,
  setTemplate,
  addTextToEditor,
  onClose
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('AnswerTemplates');

  const onEdit = () => {
    setTemplate(template);
    setSelectedComponent(MESSAGE_TEMPLATES_EDIT);
  };

  const onDelete = () =>
    dispatch(deleteMessageTemplate({ templateId: template.id }));

  const selectItem = () => {
    onClose();

    addTextToEditor(template.text);

    dispatch(counterIncrementMessageTemplate({ templateId: template.id }));
  };

  const actions = [
    {
      title: 'EditAnswerTemplateAction',
      ns: 'AnswerTemplates',
      allow: true,
      onClick: onEdit
    },
    {
      title: 'DeleteAnswerTemplateAction',
      ns: 'AnswerTemplates',
      className: styles.deleteTextAction,
      allow: true,
      onClick: onDelete,
      isConfirm: true,
      confirmText: t('DeleteAnswerTemplatePopoverHeading'),
      placement: 'topLeft'
    }
  ];

  return (
    <>
      <div className={styles.textWrap} onClick={selectItem}>
        <Tooltip title={template.title}>
          <Typography.Paragraph ellipsis className={styles.text}>
            {template.title}
          </Typography.Paragraph>
        </Tooltip>
      </div>

      <ActionsDropdown actions={actions} />
    </>
  );
};

MessageTemplatesItem.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string
  }),
  setSelectedComponent: PropTypes.func,
  setTemplate: PropTypes.func,
  addTextToEditor: PropTypes.func
};

MessageTemplatesItem.defaultProps = {
  template: PropTypes.shape({
    id: null,
    text: null
  }),
  setSelectedComponent: () => {},
  setTemplate: () => {},
  addTextToEditor: () => {}
};

export default MessageTemplatesItem;
