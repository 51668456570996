import React from 'react';
import { Skeleton } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export const SkeletonCard = ({
  className,
  rootClassName,
  count,
  avatar,
  ...props
}) => {
  const skeletons = [...new Array(count).keys()];

  return (
    <div className={classnames(styles.root, rootClassName)}>
      {skeletons.map(skeleton => (
        <div className={classnames(styles.card, className)} key={skeleton}>
          <div className={styles.content}>
            <Skeleton active avatar={avatar} {...props} />
          </div>
          <Skeleton
            active
            avatar={avatar}
            loading={avatar}
            className={styles.avatar}
          />
        </div>
      ))}
    </div>
  );
};

SkeletonCard.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  count: PropTypes.number,
  avatar: PropTypes.bool
};

SkeletonCard.defaultProps = {
  rootClassName: undefined,
  className: undefined,
  count: 1,
  avatar: false
};

export default SkeletonCard;
