import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormInput, {
  FormInputPassword
} from 'components/common/hook-form/input';
import Button from 'components/common/button';

import styles from './update-password-email.module.scss';

const FormUpdateEmailPassword = ({ onSubmit, isLoading }) => {
  const methods = useForm();

  const { t } = useTranslation(['ConnectEmail', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormInput
          label={t('Email')}
          name="email"
          type="email"
          autoComplete="on"
          placeholder={t('EnterEmail')}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
        />

        <FormInputPassword
          label={t('AppPassword')}
          name="password"
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          placeholder={t('EnterPassword')}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          loading={isLoading}
          className={styles.submitBtn}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default FormUpdateEmailPassword;
