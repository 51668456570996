import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ANY, TAGS_CONDITION_OPTIONS, UNTAGGED } from 'constants/index';

import TagsSelect from 'components/common/controls/custom-select/tags-select';
import CustomSelect from 'components/common/controls/custom-select';

import styles from './filter.module.scss';

const IDS = 'ids';
const CONDITION = 'condition';

const checkUntaggedOrAny = value => value === UNTAGGED || value === ANY;

export const TagsFilter = ({
  value,
  className,
  tagsSelectProps,
  conditionSelectProps,
  onChange
}) => {
  const { params = {}, ...restTagsSelectProps } = tagsSelectProps;
  const { t } = useTranslation('Filters');

  const disabledTagsSelect = checkUntaggedOrAny(value[CONDITION].value);

  const handleChange = (newValue, key) => {
    if (key === CONDITION && checkUntaggedOrAny(newValue.value)) {
      onChange({ [key]: newValue, [IDS]: [] });
    } else {
      onChange({ ...value, [key]: newValue });
    }
  };

  return (
    <div className={classnames(styles.root, className)}>
      <TagsSelect
        label={t('TagsFilters')}
        value={value.ids}
        isDisabled={disabledTagsSelect}
        allowCreateTag={false}
        onChange={v => handleChange(v, IDS)}
        params={{ withSystem: true, ...params }}
        {...restTagsSelectProps}
      />

      <CustomSelect
        label={t('TagSearchConditionsFilters')}
        value={value.condition}
        options={TAGS_CONDITION_OPTIONS}
        onChange={v => handleChange(v, CONDITION)}
        {...conditionSelectProps}
      />
    </div>
  );
};

TagsFilter.propTypes = {
  value: PropTypes.shape({
    ids: PropTypes.array,
    condition: PropTypes.object
  }),
  tagsSelectProps: PropTypes.object,
  conditionSelectProps: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

TagsFilter.defaultProps = {
  value: {},
  tagsSelectProps: {},
  conditionSelectProps: {}
};

export default TagsFilter;
