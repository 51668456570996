import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-syntax
import { Drawer as CommonDrawer, Button, Tooltip } from 'antd';
import classnames from 'classnames';
import { Translation } from 'react-i18next';

import {
  WIDTH_DRAWER,
  SIDER_WIDTH,
  HEADER_HEIGHT,
  MOBILE_SIDER_WIDTH
} from 'constants/styles';

import useCollapsedSider from 'components/common/layout/template/sider/use-collapsed-sider';
import DocumentTitle from 'components/common/document-title';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import styles from './drawer.module.scss';

const { Title: CommonTitle } = Typography;

const DrawerContext = createContext({});

const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  return context;
};

export const Drawer = ({
  isContent,
  fullWidth,
  closable,
  className,
  contentClassName,
  children,
  style,
  headerStyle,
  bodyStyle,
  onClose,
  documentTitleProps,
  title,
  ...props
}) => {
  const [collapsed] = useCollapsedSider();
  const siderWidth = collapsed ? MOBILE_SIDER_WIDTH : SIDER_WIDTH;

  const localStyle = {
    contentStyle: isContent
      ? { top: HEADER_HEIGHT, zIndex: 899, transform: 'none' }
      : {},
    headerStyle: {
      maxHeight: HEADER_HEIGHT,
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    bodyStyle: { padding: isContent ? 0 : 24 }
  };

  return (
    <>
      {props.visible && <DocumentTitle elevation={1} {...documentTitleProps} />}

      <CommonDrawer
        closable={false}
        style={{ ...localStyle.contentStyle, ...style }}
        headerStyle={{ ...localStyle.headerStyle, ...headerStyle }}
        bodyStyle={{ ...localStyle.bodyStyle, ...bodyStyle }}
        width={fullWidth ? `calc(100% - ${siderWidth}px)` : WIDTH_DRAWER}
        className={classnames(styles.root, className, {
          [styles.isContentDrawer]: isContent
        })}
        onClose={onClose}
        title={
          title && (
            <>
              {title}

              {closable && (
                <Tooltip
                  title={
                    <Translation ns="Common">
                      {t => t('DrawerCloseTooltip')}
                    </Translation>
                  }
                  mouseEnterDelay={0.5}
                  placement="left"
                >
                  <Button onClick={onClose} className={styles.btnClose}>
                    <Icon type="close" />
                  </Button>
                </Tooltip>
              )}
            </>
          )
        }
        {...props}
      >
        <DrawerContext.Provider value={{ onClose, ...props }}>
          {children}
        </DrawerContext.Provider>
      </CommonDrawer>
    </>
  );
};

const Back = ({ onClick, ...props }) => {
  const { onClose } = useDrawerContext();

  const handleClick = onClick || onClose;

  return (
    <Button onClick={handleClick} className={styles.btnArrow} {...props}>
      <Icon type="pointer" side="left" />
    </Button>
  );
};

const Title = ({ children, ...props }) => (
  <CommonTitle level={2} {...props}>
    {children}
  </CommonTitle>
);

Drawer.Back = Back;
Drawer.Title = Title;

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  isContent: PropTypes.bool,
  closable: PropTypes.bool,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  onClose: PropTypes.func
};

Drawer.defaultProps = {
  children: undefined,
  className: undefined,
  contentClassName: undefined,
  fullWidth: false,
  isContent: false,
  closable: true,
  style: {},
  headerStyle: {},
  bodyStyle: {},
  onClose: () => {}
};

export default Drawer;
