import moment from 'moment';

import { DATE_TIME_FORMAT_FM } from 'constants/calendar';

import { getIsTaskOutdated } from './get-is-outdated';
import { getIsEditableFile } from '../hooks/common/use-file-upload/types';
import { addValidityDateState } from '../components/common/validity-date/utils/add-validity-date-state';

export const transformTask = task => {
  if (!task) {
    return null;
  }

  const formatDate = date => moment(date).format(DATE_TIME_FORMAT_FM);

  const isOutdated = getIsTaskOutdated(task);

  return {
    ...task,
    fileList:
      Array.isArray(task.fileList) && task.fileList.length > 0
        ? task.fileList.map(file => {
            if (getIsEditableFile(file)) {
              return {
                ...file,
                subscribers: [],
                allowSubscriberSelection: false,
                ...addValidityDateState(file)
              };
            }
            return {
              ...file,
              ...addValidityDateState(file)
            };
          })
        : [],
    members: task.assignments,
    relations: task.relations || [],
    dateStart: task.dateStart && formatDate(task.dateStart),
    dateEnd: task.dateEnd && formatDate(task.dateEnd),
    isOutdated
  };
};

export default transformTask;
