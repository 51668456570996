import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseConfig as configFirebase } from 'index';

import { createDevice } from 'store/devices';

import { config } from 'config';

export const useFirebase = () => {
  const dispatch = useDispatch();

  const messaging = getMessaging();

  const firebaseConfig = encodeURIComponent(JSON.stringify(configFirebase));

  const register = async () => {
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      `/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`
    );

    getToken(messaging, {
      vapidKey: config.REACT_APP_FIREBASE_WEB_PUSH_KEY,
      serviceWorkerRegistration
    })
      .then(currentToken => {
        if (currentToken) {
          dispatch(createDevice({ registrationId: currentToken }));
        } else {
          console.log('Permissions denied');
        }
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    register();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFirebase;
