import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message, Skeleton, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MESSAGE_DURATION } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import {
  inviteEmployee,
  fetchInvitedEmployeeEmails,
  deleteInvite
} from 'store/team/employees/actions';

const InviteList = ({ afterVisible }) => {
  const { t } = useTranslation('InviteCollegue');

  const dispatch = useDispatch();

  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const notFound = !emails.length && !isLoading;

  const fetch = async () => {
    try {
      setIsLoading(true);

      const { results } = await dispatch(fetchInvitedEmployeeEmails());
      setEmails(results);
    } finally {
      setIsLoading(false);
    }
  };

  const onSendInvite = async ({ email }) => {
    await dispatch(inviteEmployee({ employeeEmails: [email] }));

    message.success(
      t('InvitationAgainToast', {
        value: email
      }),
      MESSAGE_DURATION
    );
  };

  const onDeleteInvite = async ({ id }) => {
    await dispatch(deleteInvite({ id }));

    setEmails(emails.filter(e => e.id !== id));

    message.success(t('InvitationRescindedToast'), MESSAGE_DURATION);
  };

  useEffect(() => {
    if (afterVisible) {
      fetch();
    }

    return setEmails([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterVisible]);

  if (isLoading) {
    return (
      <Skeleton
        active
        title={false}
        paragraph={{
          rows: 10,
          width: '100%'
        }}
      />
    );
  }

  if (notFound) {
    return null;
  }

  return (
    <>
      <Typography.Paragraph>{t('ColleguesYouInvited')}</Typography.Paragraph>

      <InfiniteScroll
        hasMore={false}
        useWindow={false}
        style={{ marginBottom: 20 }}
      >
        {emails.map(item => (
          <div
            key={item.email}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <Typography.Text ellipsis>{item.email}</Typography.Text>

            <div style={{ display: 'flex' }}>
              <Tooltip
                title={t('Resend')}
                mouseEnterDelay={0.5}
                placement="topRight"
                style={{ left: 0 }}
              >
                <Button
                  type="text"
                  size="small"
                  style={{
                    fontSize: 24,
                    lineHeight: 0
                  }}
                  onClick={() => onSendInvite(item)}
                >
                  <Icon type="reload" side="left" size={20} />
                </Button>
              </Tooltip>

              <Tooltip
                title={t('CancelInvitation')}
                mouseEnterDelay={0.5}
                placement="topRight"
              >
                <Button
                  type="text"
                  size="small"
                  mood="negative"
                  onClick={() => onDeleteInvite(item)}
                >
                  <Icon type="delete" size={20} />
                </Button>
              </Tooltip>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
};

InviteList.propTypes = {
  afterVisible: PropTypes.bool.isRequired
};

export default InviteList;
