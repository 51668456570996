import React from 'react';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import styles from '../../email-service.module.scss';

const Option = ({ option }) => (
  <div className={styles.serviceOption}>
    <Icon
      type={option.iconType}
      component={option.iconComponent}
      size={16}
      color="black-55"
    />

    <Typography.Text weight="semibold">{option.title}</Typography.Text>
  </div>
);

export default Option;
