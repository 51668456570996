import React from 'react';
import classnames from 'classnames';

import Editor from 'components/common/new-editor';

import MessageToolbar from './message-toolbar';

import styles from '../../editor.module.scss';

const MessageEditor = ({
  onChange,
  fileList,
  setFileList,
  value,
  openFirstWriteDrawer,
  allowToSendFirstMessage,
  valueChat,
  onChangeChat,
  options,
  isDisabledChat,
  employeeId,
  entity,
  sendMessage,
  isLoadingSending,
  isDisabledSubmit,
  editorContainerClassName,
  entityType,
  allowCreateFile,
  privateData,
  setMentionedEmployees,
  destination,
  placeholder,
  submitText
}) => (
  <Editor
    value={value}
    fileList={fileList}
    onChangeFileList={setFileList}
    onChange={onChange}
    placeholder={placeholder}
    editorContainerClassName={classnames(
      styles.editorContainerMessage,
      editorContainerClassName
    )}
    bottomToolbar={
      <MessageToolbar
        value={value}
        fileList={fileList}
        setFileList={setFileList}
        openFirstWriteDrawer={openFirstWriteDrawer}
        allowToSendFirstMessage={allowToSendFirstMessage}
        valueChat={valueChat}
        onChangeChat={onChangeChat}
        options={options}
        isDisabledChat={isDisabledChat}
        employeeId={employeeId}
        entity={entity}
        sendMessage={sendMessage}
        isLoadingSending={isLoadingSending}
        isDisabledSubmit={isDisabledSubmit}
        entityType={entityType}
        allowCreateFile={allowCreateFile}
        privateData={privateData}
        submitText={submitText}
      />
    }
    setMentionedEmployees={setMentionedEmployees}
    destination={destination}
  />
);

export default MessageEditor;
