import React from 'react';

export const ArchiveIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.0225 128.612C21.595 120.26 -2.89884 87.6183 4.18542 64.1237C5.5792 59.5205 8.09245 55.3347 11.5003 51.9408C42.6172 20.8623 127.949 9.05093 131.753 26.1403C134.162 36.9908 131.202 43.1015 133.611 48.546C136.48 55.0281 142.155 53.9008 145.038 58.013C153.365 69.9141 135.033 106.437 105.184 122.156C100.252 124.718 81.4717 133.916 58.1821 130.559C55.0818 130.156 52.0187 129.505 49.0225 128.612Z"
      fill="#EBF8F8"
    />
    <path
      d="M113.383 73.2068H29.7938L43.9367 44.5879H99.2529L113.383 73.2068Z"
      fill="#FDA402"
    />
    <path
      d="M99.2656 44.5879H43.9366V83.6986H99.2656V44.5879Z"
      fill="#ED9406"
    />
    <path
      d="M62.9092 22.9889V42.9862C62.9092 44.1066 63.1299 45.2161 63.5586 46.2512C63.9874 47.2864 64.6158 48.2269 65.4081 49.0192C67.0081 50.6192 69.1782 51.5181 71.441 51.5181H89.1325L103.058 58.1796L101.418 51.5181H106.158C108.421 51.5181 110.591 50.6192 112.191 49.0192C113.791 47.4191 114.69 45.249 114.69 42.9862V22.9889C114.69 20.7261 113.791 18.556 112.191 16.956C110.591 15.3559 108.421 14.457 106.158 14.457H71.441C69.1782 14.457 67.0081 15.3559 65.4081 16.956C63.8081 18.556 62.9092 20.7261 62.9092 22.9889Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M94.1414 36.3634V58.5257C94.1414 59.7673 93.8968 60.9967 93.4217 62.1437C92.9466 63.2908 92.2502 64.333 91.3723 65.2109C90.4944 66.0888 89.4522 66.7852 88.3051 67.2603C87.1581 67.7354 85.9287 67.98 84.6872 67.98H46.2554C44.7074 67.9819 43.1827 67.6031 41.8155 66.8771C40.4484 66.1511 39.2807 65.1 38.4153 63.8165C37.3674 62.2561 36.8097 60.4182 36.814 58.5386V36.3634C36.8123 35.1214 37.0557 33.8912 37.5302 32.7434C38.0047 31.5956 38.7011 30.5528 39.5793 29.6745C40.4575 28.7963 41.5004 28.0999 42.6482 27.6254C43.796 27.1509 45.0262 26.9075 46.2682 26.9092H84.7C86.5972 26.9054 88.4509 27.4772 90.0164 28.549C91.2908 29.4146 92.3333 30.5798 93.0525 31.9422C93.7717 33.3047 94.1456 34.8228 94.1414 36.3634Z"
      fill="#A6E9E9"
    />
    <path
      d="M91.5793 33.8013V55.9636C91.5793 58.471 90.5832 60.8758 88.8102 62.6488C87.0372 64.4218 84.6325 65.4178 82.1251 65.4178H43.6933C41.8137 65.4221 39.9757 64.8644 38.4153 63.8165C37.3674 62.2561 36.8097 60.4182 36.814 58.5386V36.3634C36.8123 35.1214 37.0557 33.8912 37.5302 32.7434C38.0047 31.5956 38.7011 30.5528 39.5793 29.6745C40.4575 28.7963 41.5004 28.0999 42.6482 27.6254C43.796 27.1509 45.0262 26.9075 46.2682 26.9092H84.7C86.5972 26.9054 88.4509 27.4772 90.0164 28.549C91.0459 30.1064 91.5898 31.9344 91.5793 33.8013Z"
      fill="#CEF4F4"
    />
    <path
      d="M51.2131 67.7105L49.3812 75.2304L65.6763 67.4287L51.2131 67.7105Z"
      fill="#A6E9E9"
    />
    <path
      d="M65.4585 50.4677C66.0595 50.4677 66.647 50.2893 67.1466 49.9552C67.6462 49.621 68.0353 49.146 68.2647 48.5905C68.4941 48.035 68.5535 47.4239 68.4353 46.8346C68.3171 46.2453 68.0267 45.7043 67.6008 45.2802C67.1749 44.8561 66.6327 44.568 66.0429 44.4523C65.4531 44.3366 64.8423 44.3986 64.2877 44.6303C63.7332 44.8621 63.2599 45.2532 62.9278 45.7542C62.5958 46.2552 62.4199 46.8434 62.4224 47.4444C62.4207 47.843 62.4981 48.238 62.6503 48.6064C62.8024 48.9748 63.0262 49.3093 63.3086 49.5906C63.5911 49.8718 63.9265 50.0942 64.2956 50.2448C64.6647 50.3953 65.0599 50.4711 65.4585 50.4677Z"
      fill="#36B7BD"
    />
    <path
      d="M53.0962 50.4677C53.6978 50.4702 54.2865 50.294 54.7878 49.9614C55.289 49.6287 55.6802 49.1547 55.9115 48.5994C56.1429 48.0441 56.2041 47.4326 56.0874 46.8424C55.9706 46.2523 55.6812 45.7101 55.2558 45.2848C54.8305 44.8594 54.2883 44.57 53.6982 44.4532C53.108 44.3365 52.4965 44.3977 51.9412 44.6291C51.3859 44.8605 50.9119 45.2516 50.5792 45.7528C50.2466 46.2541 50.0704 46.8428 50.0729 47.4444C50.0893 48.2411 50.413 49.0006 50.9765 49.5641C51.54 50.1276 52.2995 50.4513 53.0962 50.4677Z"
      fill="#36B7BD"
    />
    <path
      d="M77.8079 50.4677C78.4089 50.4677 78.9964 50.2893 79.496 49.9552C79.9955 49.621 80.3847 49.146 80.6141 48.5905C80.8435 48.035 80.9029 47.4239 80.7847 46.8346C80.6665 46.2453 80.376 45.7043 79.9502 45.2802C79.5243 44.8561 78.9821 44.568 78.3923 44.4523C77.8025 44.3366 77.1916 44.3986 76.6371 44.6303C76.0825 44.8621 75.6093 45.2532 75.2772 45.7542C74.9451 46.2552 74.7692 46.8434 74.7718 47.4444C74.77 47.843 74.8475 48.238 74.9996 48.6064C75.1518 48.9748 75.3755 49.3093 75.658 49.5906C75.9404 49.8718 76.2759 50.0942 76.645 50.2448C77.014 50.3953 77.4093 50.4711 77.8079 50.4677Z"
      fill="#36B7BD"
    />
    <path
      d="M115.266 84.915V127.51C115.266 129.638 114.422 131.679 112.918 133.185C111.414 134.691 109.375 135.539 107.247 135.542H35.9429C33.9815 135.541 32.0889 134.82 30.6229 133.517C29.157 132.214 28.2194 130.419 27.9875 128.471C27.9617 128.156 27.9617 127.839 27.9875 127.523V84.915H115.266Z"
      fill="#ED9406"
    />
    <path
      d="M107.336 84.915V120.4C107.341 121.458 107.137 122.507 106.736 123.486C106.335 124.465 105.744 125.355 104.997 126.105C104.251 126.855 103.364 127.45 102.387 127.856C101.41 128.262 100.362 128.471 99.3041 128.471H27.9875C27.9617 128.156 27.9617 127.839 27.9875 127.523V84.915H107.336Z"
      fill="#FDA402"
    />
    <path
      d="M115.612 73.2324H27.5775C25.6672 73.2324 24.1187 74.781 24.1187 76.6913V83.8908C24.1187 85.8011 25.6672 87.3497 27.5775 87.3497H115.612C117.522 87.3497 119.071 85.8011 119.071 83.8908V76.6913C119.071 74.781 117.522 73.2324 115.612 73.2324Z"
      fill="#FCC977"
    />
    <path
      d="M83.4446 115.238H59.271C57.8203 115.238 56.4289 114.662 55.4031 113.636C54.3772 112.61 53.8009 111.219 53.8009 109.768C53.8009 108.317 54.3772 106.926 55.4031 105.9C56.4289 104.874 57.8203 104.298 59.271 104.298H83.4446C84.8954 104.298 86.2867 104.874 87.3126 105.9C88.3384 106.926 88.9147 108.317 88.9147 109.768C88.9147 111.219 88.3384 112.61 87.3126 113.636C86.2867 114.662 84.8954 115.238 83.4446 115.238Z"
      fill="#2B2A36"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="108.656"
        y1="42.3329"
        x2="67.0086"
        y2="22.7327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default ArchiveIcon;
