import { RU_RU } from 'i18n-config';

import {
  TELEGRAM,
  WEBSITE,
  EMAIL,
  EMAIL_SERVICE_STEP,
  EMAIL_CHANNEL_STEP,
  WEBSITE_CHANNEL_STEP,
  TELEGRAM_BOT_STEP,
  TELEGRAM_CHANNEL_STEP,
  WEBSITE_FORM,
  WEBSITE_FORM_STEP,
  WHATSAPP,
  WHATSAPP_CHANNEL_STEP
} from 'constants/index';

import getIso2CodeByLanguage from 'utils/get-iso-2-code-by-language';

const WEBSITE_STEPS = [WEBSITE_CHANNEL_STEP];
const TELEGRAM_STEPS = [TELEGRAM_BOT_STEP, TELEGRAM_CHANNEL_STEP];
const EMAIL_STEPS = [EMAIL_SERVICE_STEP, EMAIL_CHANNEL_STEP];
const WEBSITE_FORM_STEPS = [WEBSITE_FORM_STEP];
const WHATSAPP_STEPS = [WHATSAPP_CHANNEL_STEP];

const STEPS_BY_TYPE = {
  [WEBSITE]: WEBSITE_STEPS,
  [TELEGRAM]: TELEGRAM_STEPS,
  [EMAIL]: EMAIL_STEPS,
  [WEBSITE_FORM]: WEBSITE_FORM_STEPS,
  [WHATSAPP]: WHATSAPP_STEPS
};

export const getFirstStep = type => STEPS_BY_TYPE[type][0];

export const getPreviousStep = (currentStep, type) => {
  const steps = STEPS_BY_TYPE[type];

  const stepIndex = (steps || []).findIndex(s => s === currentStep);

  return stepIndex !== -1 && stepIndex ? steps[stepIndex - 1] : null;
};

export const LANGUAGE_OPTIONS = [
  { value: 'ru', label: 'Ru' },
  { value: 'eng', label: 'Eng' }
];

export const getChannelLanguage = userLanguage => {
  const language = getIso2CodeByLanguage({
    language: userLanguage || RU_RU,
    isEnToEng: true
  });

  return LANGUAGE_OPTIONS.find(o => o.value === language);
};
