import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-syntax
import { Typography as AntTypography } from 'antd';
import classnames from 'classnames';

import styles from './typography.module.scss';

const create = (Component, rootClassName, defaultProps = {}) => ({
  className,
  weight = defaultProps.weight,
  size = defaultProps.size,
  color = defaultProps.color,
  ...props
}) => (
  <Component
    {...props}
    className={classnames(
      `color-${color}`,
      styles.root,
      rootClassName,
      className,
      {
        [styles.regular]: weight === 'regular',
        [styles.semibold]: weight === 'semibold',
        [styles.bold]: weight === 'bold',
        [styles.small]: size === 'small',
        [styles.large]: size === 'large'
      }
    )}
  />
);

const Typography = ({ children, ...props }) =>
  React.cloneElement(AntTypography, props, children);

const commonPropTypes = {
  weight: PropTypes.oneOf(['bold', 'semibold', 'regular']),
  color: PropTypes.string
};

const textPropTypes = {
  size: PropTypes.oneOf(['default', 'large', 'small'])
};

/**
 * **Title**
 *
 * <h2>Example:</h2>
 * <h3>$heading-3-regular ($color-black-55):</h3>
 * <Title <br/>
 * &nbsp;&nbsp; level={3}<br/>
 * &nbsp;&nbsp; weight="regular"<br/>
 * &nbsp;&nbsp; color="black-55"<br/>
 * />
 *
 * @param  props
 * @param  props.weight { bold | semibold | regular } semibold
 * @param  props.color { one of theme colors } black
 * @param  props.level { 1 | 2 | 3 | 4 | 5 } 1
 */
Typography.Title = create(AntTypography.Title, styles.title, {
  weight: 'semibold',
  color: 'black'
});
Typography.Title.propTypes = {
  ...AntTypography.Title.propTypes,
  ...commonPropTypes
};

/**
 * <h1>Paragraph</h1>
 *
 * <h2>Example:</h2>
 * <h3>$text-small-semibold ($color-black-55):</h3>
 * <Paragraph <br/>
 * &nbsp;&nbsp; size="small"<br/>
 * &nbsp;&nbsp; weight="semibold"<br/>
 * &nbsp;&nbsp; color="black-55"<br/>
 * />
 *
 * @param props
 * @param props.weight { bold | semibold | regular } semibold
 * @param props.color { one of theme colors } black
 * @param props.size { default | large | small } default
 */
Typography.Paragraph = create(AntTypography.Paragraph, styles.paragraph, {
  weight: 'regular',
  color: 'black'
});
Typography.Paragraph.propTypes = {
  ...AntTypography.Paragraph.propTypes,
  ...commonPropTypes,
  ...textPropTypes
};

/**
 * <h1>Text</h1>
 *
 * <h2>Example:</h2>
 * <h3>$text-small-semibold ($color-black-55):</h3>
 * <Text <br/>
 * &nbsp;&nbsp; size="small"<br/>
 * &nbsp;&nbsp; weight="semibold"<br/>
 * &nbsp;&nbsp; color="black-55"<br/>
 * />
 *
 * @param  props
 * @param  props.weight { bold | semibold | regular } semibold
 * @param  props.color { one of theme colors } black
 * @param  props.size { default | large | small } default
 */
Typography.Text = create(AntTypography.Text, styles.text, {
  weight: 'regular',
  color: 'black'
});
Typography.Text.propTypes = {
  ...AntTypography.Text.propTypes,
  ...commonPropTypes,
  ...textPropTypes
};

export { Typography };

export default Typography;
