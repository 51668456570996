import { config } from 'config';

export const GPT_CHATS_VIEW = 'chats';
export const GPT_MESSAGES_VIEW = 'messages';

export const GPT_FILTER_BY_ENTITY_OPTIONS = [
  { label: 'AllFilter', value: '', ns: 'AIAssistant' },
  { label: 'TasksFilter', value: 'task', ns: 'AIAssistant' },
  { label: 'FunnelsFilter', value: 'order', ns: 'AIAssistant' },
  { label: 'ContactsFilter', value: 'contact', ns: 'AIAssistant' },
  { label: 'DirectoriesFilter', value: 'asset', ns: 'AIAssistant' }
];

export const AI_ASSISTANT_MENTION_REGEX = new RegExp(
  String.raw`@\[([^\]]+)\]\((${config.REACT_APP_AI_ASSISTANT_EMPLOYEE_ID}+)\)`
);
