import moment from 'moment';

const getDateEndNoStart = date =>
  moment(date)
    .set({ hour: 23, minute: 59 })
    .toISOString(true);

export const transformDateEnd = (dateEnd, dateStart) =>
  dateStart ? moment(dateEnd).toISOString(true) : getDateEndNoStart(dateEnd);

export default transformDateEnd;
