import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { useTheme } from 'components/common/theme';
import { ReleaseFeatureTag } from 'components/common/release';

import { DOCX, PPTX, XLSX } from 'hooks/common/use-file-upload/types';

import styles from './select-type.module.scss';

export const SelectType = ({ isLoading, onClick }) => {
  const { variables } = useTheme();

  const { t } = useTranslation('AddFile');

  const types = [
    {
      iconType: 'file',
      iconColor: 'danube',
      backgroundColor: variables.color['danube-light'],
      title: t('Document'),
      description: t('DocumentDesc'),
      type: DOCX
    },
    {
      iconType: 'list',
      iconColor: 'brand',
      backgroundColor: variables.color['brand-90'],
      title: t('Table'),
      description: t('TableDesc'),
      type: XLSX
    },
    {
      iconType: 'presentation',
      iconColor: 'yellow',
      backgroundColor: variables.color['yellow-light'],
      title: t('Presentation'),
      description: t('PresentationDesc'),
      type: PPTX
    }
  ];

  return (
    <Spin wrapperClassName={styles.spin} spinning={isLoading}>
      <div className={styles.root}>
        {types.map(t => (
          <Button
            key={t.type}
            className={styles.button}
            style={{ backgroundColor: t.backgroundColor }}
            onClick={() => onClick(t.type)}
          >
            <Icon
              type={t.iconType}
              color={t.iconColor}
              size={16}
              className={styles.icon}
            />

            <ReleaseFeatureTag
              featureName={t.featureName}
              wrapperClassName={styles.titleWrap}
            >
              <Typography.Title level={3} className={styles.title}>
                {t.title}
              </Typography.Title>
            </ReleaseFeatureTag>

            <Typography.Text size="small">{t.description}</Typography.Text>
          </Button>
        ))}
      </div>
    </Spin>
  );
};

SelectType.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

SelectType.defaultProps = {
  isLoading: false
};

export default SelectType;
