import React from 'react';

export const SortDescendingIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7746 9.90606L18.3478 2.31767C18.3321 2.26689 18.3004 2.22254 18.2574 2.19118C18.2145 2.15983 18.1626 2.14313 18.1094 2.14356H16.0362C15.9264 2.14356 15.8326 2.21321 15.7978 2.31767L13.3523 9.90606C13.3442 9.93017 13.3389 9.95696 13.3389 9.98374C13.3389 10.1203 13.4514 10.2329 13.588 10.2329H15.0987C15.2112 10.2329 15.3076 10.1579 15.3398 10.0507L15.8085 8.40071H18.1925L18.6559 10.048C18.6853 10.1552 18.7844 10.2302 18.8969 10.2302H20.5362C20.563 10.2302 20.5871 10.2275 20.6112 10.2195C20.6755 10.198 20.7264 10.1552 20.7585 10.0962C20.788 10.0373 20.7934 9.97035 20.7746 9.90606ZM16.0523 7.00517L16.9309 3.87392H17.0996L17.9594 7.00517H16.0523ZM19.896 20.2079H16.4246V20.1971L19.9764 15.1373C20.0059 15.0945 20.0219 15.0462 20.0219 14.9927V14.0177C20.0219 13.8811 19.9094 13.7686 19.7728 13.7686H14.3085C14.1719 13.7686 14.0594 13.8811 14.0594 14.0177V15.1695C14.0594 15.3061 14.1719 15.4186 14.3085 15.4186H17.5925V15.4293L14.0273 20.4891C13.9975 20.5315 13.9816 20.582 13.9817 20.6337V21.6087C13.9817 21.7454 14.0942 21.8579 14.2309 21.8579H19.8934C20.03 21.8579 20.1425 21.7454 20.1425 21.6087V20.457C20.1428 20.4244 20.1367 20.392 20.1245 20.3618C20.1123 20.3316 20.0942 20.3041 20.0712 20.281C20.0483 20.2578 20.021 20.2394 19.991 20.2269C19.9609 20.2143 19.9286 20.2078 19.896 20.2079ZM6.59692 2.76231C6.57688 2.7367 6.55127 2.71599 6.52203 2.70174C6.49279 2.6875 6.4607 2.68009 6.42817 2.68009C6.39565 2.68009 6.36355 2.6875 6.33432 2.70174C6.30508 2.71599 6.27947 2.7367 6.25942 2.76231L3.25942 6.56321C3.14692 6.70517 3.24871 6.91142 3.42817 6.91142H5.46389V21.1079C5.46389 21.2257 5.56032 21.3221 5.67817 21.3221H7.17817C7.29603 21.3221 7.39246 21.2257 7.39246 21.1079V6.91142H9.42817C9.60764 6.91142 9.70942 6.70249 9.59692 6.56321L6.59692 2.76231Z"
      fill="currentColor"
    />
  </svg>
);

export default SortDescendingIcon;
