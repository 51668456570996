import { useState, useEffect } from 'react';

import { MODAL, EDIT_PROJECT, SELECTED_PROJECT } from '../../constants';
import { useQueryParam } from '../common';

export const useProjectEditor = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);
  const project = Number(useQueryParam(SELECTED_PROJECT));

  useEffect(() => {
    setVisible(!!(modal === EDIT_PROJECT && project));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useProjectEditor;
