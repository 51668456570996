import { ORDERS } from 'constants/index';

export default api => ({
  createFeedback: (workspaceId, id, toEmployee, rating, content, fileList) =>
    api.post(`/v1/${workspaceId}/${ORDERS}/${id}/feedback/`, {
      id,
      rating,
      content,
      toEmployee,
      fileList
    }),

  fetchFeedbacks: (workspaceId, id, type, isCustomer, offset, limit) =>
    api.get(`/v1/${workspaceId}/${type}/${id}/feedbacks/`, {
      params: { offset, limit, is_customer: isCustomer }
    })
});
