import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from 'antd';

import Avatar from 'components/common/avatar';
import Icon from 'components/common/icon';

import { getFIO, getFullName } from 'utils/get-fio';

import styles from './styles.module.scss';

export const UserOption = ({
  className,
  member,
  onDelete,
  allowDelete,
  hideWorkspace
}) => (
  <div className={className} data-qa="qa-1fqhu9z6lfywh6i">
    <div className={styles.member} data-qa="qa-djj13qcvxqn4eih">
      <Avatar
        size={23}
        className={styles.avatar}
        data-qa="qa-18f0w4udx8c1ec3"
        src={(member.avatarFile || {}).url}
      >
        {getFIO(member)}
      </Avatar>
      <Tooltip placement="right" title={member.position}>
        {getFullName(member)}
        {!hideWorkspace && ` (${member.workspaceTitle})`}
      </Tooltip>
    </div>

    {allowDelete && (
      <Button
        type="link"
        className={styles.btn}
        onClick={onDelete}
        data-qa="qa-h82r75nl0rpcl0a"
      >
        <Icon type="close" size={16} />
      </Button>
    )}
  </div>
);

UserOption.propTypes = {
  className: PropTypes.string,
  member: PropTypes.object,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  hideWorkspace: PropTypes.bool
};

UserOption.defaultProps = {
  className: undefined,
  member: {},
  allowDelete: false,
  onDelete: undefined,
  hideWorkspace: false
};

export default UserOption;
