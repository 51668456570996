import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import FormItem from '../form-item';

import styles from './radio.module.scss';

export const FormRadio = ({
  rules,
  name,
  label,
  itemProps,
  options,
  withButton,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        defaultValue={options[0].value}
        render={({ field }) => {
          const { onChange, ...radioProps } = field;

          if (withButton) {
            return (
              <Radio.Group
                className={styles.buttonGroup}
                buttonStyle="solid"
                onChange={e => onChange(e.target.value)}
                {...radioProps}
                {...props}
              >
                {options.map(option => (
                  <Radio.Button
                    value={option.value}
                    className={styles.radioButton}
                  >
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            );
          }

          return (
            <Radio.Group
              onChange={e => onChange(e.target.value)}
              options={options.map(o => ({
                ...o,
                label: <Translation ns={o.ns}>{t => t(o.label)}</Translation>
              }))}
              {...radioProps}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormRadio.defaultProps = {
  options: PropTypes.array,
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormRadio.defaultProps = {
  options: [],
  rules: {},
  itemProps: {}
};

export default FormRadio;
