import React, { useEffect, useRef, useState } from 'react';
import ReactInfiniteScroller from 'react-infinite-scroller';

const getScrollableParent = node => {
  if (!node) {
    return null;
  }

  if (node === document) {
    return document.scrollingElement;
  }

  const { overflowY } = window.getComputedStyle(node);
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (isScrollable && node.scrollHeight > node.clientHeight) {
    return node;
  }

  return getScrollableParent(node.parentNode);
};

export const InfiniteScroll = ({ findScrollParent = false, ...props }) => {
  // после обновления основного лэйаута перестал работать лэйзи лоад почти везде.
  // этот компонент может сам найти ближайший родительский элемент прокрутки.
  // TODO: стоит пересмотреть верстку, либо вручную прокидывать рефы каждого элемента прокрутки.
  const [scrollableParent, setScrollableParent] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (findScrollParent) {
      setScrollableParent(getScrollableParent(ref.current));
    }
  }, [props, findScrollParent]);

  return (
    <>
      <span style={{ display: 'none' }} ref={ref} />
      <ReactInfiniteScroller
        useWindow={!findScrollParent}
        getScrollParent={findScrollParent ? () => scrollableParent : undefined}
        {...props}
      />
    </>
  );
};

export default InfiniteScroll;
