import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Tooltip } from 'antd';

import Typography from 'components/common/typography';
import { useViewFile } from 'components/attachments-view/hooks';
import { getFileLocation } from 'components/app/open-file-provider/utils';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import styles from './file-title.module.scss';

const { Paragraph } = Typography;

const MAX_TEXT_LENGTH = 44;

const FileTitle = ({
  isDisabled,
  file,
  target,
  actionsDeps,
  isOpenViaLink
}) => {
  const location = useLocation();
  const { viewFile } = useViewFile(actionsDeps);

  const { title, highlight, fileId } = file;

  const { fileName = [] } = highlight || {};

  const getTitleIfTooLong = value =>
    value && value.length > MAX_TEXT_LENGTH ? value : undefined;

  const getTooltipTitle = () => {
    if (fileName.length > 0) {
      return fileName.length > MAX_TEXT_LENGTH ? fileName.join(' ') : undefined;
    }

    if (title) {
      return getTitleIfTooLong(title);
    }

    return undefined;
  };

  if (isDisabled) {
    return (
      <Tooltip title={getTitleIfTooLong(title)} mouseEnterDelay={0.5}>
        <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
          {title}
        </Paragraph>{' '}
      </Tooltip>
    );
  }

  if (getIsEditableFile(file) || !isOpenViaLink) {
    return (
      <Tooltip title={getTooltipTitle()} mouseEnterDelay={0.5}>
        <Paragraph
          className={classnames(styles.link, styles.withHighlight)}
          onClick={() => viewFile(file)}
          ellipsis={{ rows: 2 }}
        >
          {fileName.length ? (
            <span
              dangerouslySetInnerHTML={{
                __html: fileName.join(' ')
              }}
            />
          ) : (
            title
          )}
        </Paragraph>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={getTooltipTitle()} mouseEnterDelay={0.5}>
      <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
        <Link
          to={{
            ...getFileLocation(location, fileId),
            state: { actionsDeps }
          }}
          replace
          target={target}
          className={styles.withHighlight}
        >
          {fileName.length ? (
            <span
              dangerouslySetInnerHTML={{
                __html: fileName.join(' ')
              }}
            />
          ) : (
            title
          )}
        </Link>
      </Paragraph>
    </Tooltip>
  );
};

FileTitle.propTypes = {
  isDisabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
  target: PropTypes.string,
  actionsDeps: PropTypes.object,
  isOpenViaLink: PropTypes.bool
};

FileTitle.defaultProps = {
  isDisabled: false,
  target: '_self',
  actionsDeps: {},
  isOpenViaLink: true
};

export default FileTitle;
