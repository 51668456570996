import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DATE_FORMAT } from 'constants/index';
import {
  CARD_BRAND_BELCART,
  CARD_BRAND_MASTERCARD,
  CARD_BRAND_VISA
} from 'constants/billing';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import { transfromCardNumber } from './utls';

import styles from './payment-card.module.scss';

const CARD_ICON_TYPE_MAP = {
  [CARD_BRAND_VISA]: 'visa',
  [CARD_BRAND_MASTERCARD]: 'mastercard',
  [CARD_BRAND_BELCART]: 'belcart'
};

const PaymentCard = ({ card, className }) => {
  const { brand, number, expirationDate } = card;

  const transfromedNumber = transfromCardNumber(number);

  const brandIconType = CARD_ICON_TYPE_MAP[brand];

  return (
    <div className={classnames(styles.root, className)}>
      {brandIconType ? (
        <Icon type={brandIconType} color="black-35" />
      ) : (
        <Typography.Text color="black-55">{brand}</Typography.Text>
      )}

      <Typography.Text color="black-35">
        {`${transfromedNumber}, `}
      </Typography.Text>

      <Typography.Text color="black-35">
        {moment(expirationDate, DATE_FORMAT).format('MM/YYYY')}
      </Typography.Text>
    </div>
  );
};

PaymentCard.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string,
    number: PropTypes.string,
    expirationDate: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

PaymentCard.defaultProps = {
  className: undefined
};

export default PaymentCard;
