export const MAX_SIZE_LOGO = 1 * 1024 * 1024;

export const translations = {
  ru: {
    privacyPolicy:
      'Соглашаюсь на обработку персональных данных и принимаю условия ',
    privacyPolicyTextLink: 'политики.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy',
    fieldRequired: 'Обязательное поле',
    phoneFieldRequired: 'Введите корректный номер телефона.',
    businessFormText: 'Бизнес форма ',
    formUnavailableTitle: 'Форма недоступна',
    formUnavailableDescription:
      'Администратор отключил или удалил данную форму',
    phone: 'Телефон',
    name: 'Имя',
    message: 'Ваше сообщение',
    email: 'Email',
    sendAnotherBtn: 'Заполнить повторно',
    errorMessage: 'Что-то пошло не так...',
    file: 'Добавить файл',
    tryUpdatePage: 'Попробуйте обновить страницу'
  },
  eng: {
    privacyPolicy:
      'I agree to the processing of personal data and accept the terms of the ',
    privacyPolicyTextLink: 'policy.',
    privacyPolicyLink: 'https://upservice.com/privacy-policy-eng',
    fieldRequired: 'This field is required',
    phoneFieldRequired: 'Enter the correct phone number.',
    businessFormText: 'Form created by ',
    formUnavailableTitle: 'Unavailable form',
    formUnavailableDescription:
      'Administrator has disabled or deleted this form',
    phone: 'Phone number',
    name: 'Name',
    message: 'Your message',
    email: 'Email',
    sendAnotherBtn: 'Send another one',
    errorMessage: 'Something went wrong...',
    file: 'Add file',
    tryUpdatePage: 'Please try refreshing the page'
  }
};
