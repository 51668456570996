import React from 'react';

export const MastercardIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4538 17.0208C11.5018 17.0618 11.5538 17.1028 11.6048 17.1428C10.5912 17.8156 9.40136 18.174 8.18477 18.1728C7.37384 18.1735 6.57073 18.0143 5.82138 17.7043C5.07203 17.3943 4.39113 16.9397 3.81762 16.3664C3.24412 15.793 2.78925 15.1123 2.47905 14.363C2.16884 13.6138 2.00937 12.8107 2.00977 11.9998C2.00966 10.8829 2.31247 9.78693 2.88596 8.82853C3.45945 7.87013 4.28213 7.08521 5.26641 6.55736C6.25068 6.02952 7.35969 5.77851 8.47533 5.83106C9.59097 5.88361 10.6715 6.23777 11.6018 6.8558C11.5518 6.8988 11.5018 6.9378 11.4638 6.9818C10.7429 7.60422 10.1646 8.37456 9.76807 9.24044C9.37153 10.1063 9.16611 11.0474 9.16577 11.9998C9.16577 13.9248 9.99877 15.7548 11.4538 17.0208ZM15.8148 5.8258C14.5992 5.82344 13.4104 6.18191 12.3988 6.8558C12.4478 6.8988 12.4978 6.9378 12.5358 6.9818C13.9978 8.2448 14.8348 10.0758 14.8348 11.9998C14.8348 13.9238 13.9998 15.7528 12.5468 17.0208C12.4978 17.0618 12.4458 17.1028 12.3958 17.1428C13.4085 17.8159 14.5978 18.1742 15.8138 18.1728C16.6311 18.1833 17.4424 18.0313 18.2005 17.7258C18.9587 17.4203 19.6486 16.9673 20.2303 16.393C20.812 15.8188 21.2739 15.1347 21.5891 14.3806C21.9044 13.6264 22.0668 12.8172 22.0668 11.9998C22.0669 11.1824 21.9047 10.3731 21.5895 9.61895C21.2744 8.86475 20.8126 8.18063 20.231 7.60629C19.6494 7.03195 18.9595 6.57881 18.2014 6.27317C17.4433 5.96753 16.6321 5.81547 15.8148 5.8258ZM11.9998 7.1498C11.2646 7.72626 10.6703 8.4626 10.2621 9.30294C9.8539 10.1433 9.64244 11.0656 9.64377 11.9998C9.64183 12.9346 9.85301 13.8575 10.2613 14.6984C10.6695 15.5393 11.2641 16.2761 11.9998 16.8528C12.7353 16.2759 13.3297 15.5391 13.7381 14.6982C14.1465 13.8574 14.3581 12.9346 14.3568 11.9998C14.3581 11.0655 14.1466 10.1431 13.7382 9.3027C13.3298 8.46233 12.7353 7.72606 11.9998 7.1498Z"
      fill="currentColor"
    />
  </svg>
);

export default MastercardIcon;
