import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import { ITEMS_PER_PAGE } from 'constants/pagination';
import { CONTACTS_COMPANIES, CONTACTS } from 'constants/api';
import { CUSTOMERS } from 'constants/index';

import { setActiveId } from 'store/workspace';
import { makeSubscribeLocationChange } from 'store/router';

import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';
import handleActions from 'utils/redux-actions';

import {
  fetchContactsCompanies,
  clearError,
  setCurrentPage,
  setFilterSearch,
  setFilterIsCustomers,
  setFilterResponsible,
  clearFilterCompanies,
  setSorting,
  editContactCompanies
} from './actions';

const initialFilter = {
  search: '',
  isCustomers: false,
  responsible: []
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,

  filter: getInitialValueFilterStorage(CONTACTS_COMPANIES, initialFilter),

  currentPage: 1,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  sortDirection: undefined,
  sortField: '',

  entries: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(CONTACTS, CUSTOMERS),

    [setActiveId]: () => initialState,

    [combineActions(
      setFilterSearch,
      setFilterIsCustomers,
      setFilterResponsible,
      setSorting
    )]: state => {
      state.currentPage = 1;

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterIsCustomers]: (state, { payload }) => {
      state.filter.isCustomers = payload;

      return state;
    },

    [clearFilterCompanies]: state => {
      state.filter = initialFilter;

      return state;
    },

    [setSorting]: (
      state,
      { payload: { sortDirection = undefined, sortField = '' } }
    ) => {
      state.sortDirection = sortDirection;
      state.sortField = sortField;

      return state;
    },

    [fetchContactsCompanies.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchContactsCompanies.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchContactsCompanies.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchContactsCompanies.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.isLoading = false;
      state.isLoaded = true;
      state.totalItems = payload.totalItems;
      state.entries = payload.results;

      return state;
    },

    [editContactCompanies.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = {
          ...state.entries[index],
          ...payload
        };
      }

      return state;
    },

    [clearError]: state => {
      state.error = null;

      return state;
    }
  },
  initialState
);
