import api from 'api';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';

export const fetchReminder = createActionThunk(
  'reminders/fetch-reminder',
  ({ getState, entityType, entityId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reminders
      .fetchReminder({
        workspaceId,
        entityType,
        entityId
      })
      .then(({ data }) => data);
  }
);

export const createReminder = createActionThunk(
  'reminders/create-reminder',
  ({ getState, entityType, entityId, values }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reminders
      .createReminder({
        workspaceId,
        entityType,
        entityId,
        values
      })
      .then(({ data }) => data);
  }
);

export const updateReminder = createActionThunk(
  'reminders/update-reminder',
  ({ getState, entityType, entityId, reminderId, values }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reminders
      .updateReminder({
        workspaceId,
        entityType,
        entityId,
        reminderId,
        values
      })
      .then(({ data }) => data);
  }
);

export const deleteReminder = createActionThunk(
  'reminders/delete-reminder',
  ({ getState, entityType, entityId, reminderId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reminders.deleteReminder({
      workspaceId,
      entityType,
      entityId,
      reminderId
    });
  }
);
