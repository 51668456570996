import { LANGUAGE } from 'i18n-config';
import { createSelector } from 'reselect';

export const getUI = state => state.ui;

export const getUILanguage = createSelector(
  getUI,
  ui => ui.language || localStorage.getItem(LANGUAGE)
);

export const getUITimeZone = createSelector(
  getUI,
  ui => ui.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
);
