import React from 'react';
import PropTypes from 'prop-types';

import { UPSERVICE_SENDER } from 'constants/index';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import Avatar from 'components/common/avatar';
import { LogoCircleIcon } from 'components/common/icons';

import getFIO from 'utils/get-fio';

import styles from './sender.module.scss';

const Sender = ({ sender }) => (
  <div className={styles.root}>
    {(sender.fullName || '').toLowerCase() === UPSERVICE_SENDER ? (
      <Icon
        component={() => <LogoCircleIcon style={{ width: 24, height: 24 }} />}
      />
    ) : (
      <Avatar size={24} src={sender.avatarUrl}>
        {getFIO({ firstName: sender.fullName })}
      </Avatar>
    )}

    <Typography.Text>{sender.fullName}:</Typography.Text>
  </div>
);

Sender.propTypes = {
  sender: PropTypes.shape({
    fullName: PropTypes.string,
    avatarUrl: PropTypes.oneOfType([PropTypes.string, null]),
    contactId: PropTypes.oneOfType([PropTypes.string, null]),
    employee: PropTypes.oneOfType([PropTypes.string, null]),
    username: PropTypes.oneOfType([PropTypes.string, null])
  }).isRequired
};

export default Sender;
