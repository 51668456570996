import React from 'react';
import classnames from 'classnames';

import styles from './panel.module.scss';

const Panel = ({ children, className }) => (
  <div className={classnames(styles.root, className)}>{children}</div>
);

export default Panel;
