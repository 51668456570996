import React from 'react';
import { Select, Icon } from 'antd';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import { TASK_TYPES } from '../../../../constants';

import styles from './styles.module.scss';

const { Option } = Select;

export const TaskTypeSelect = ({
  values,
  onChange,
  disabled,
  onlyAllowedTypes,
  ...selectProps
}) => (
  <Select
    defaultValue={values}
    disabled={disabled}
    optionFilterProp="title"
    onChange={item => onChange(item)}
    className={styles.select}
    data-qa="qa-wf10h8t4ygmhpff"
    {...selectProps}
  >
    {TASK_TYPES.filter(type => !onlyAllowedTypes || type.allow).map(type => (
      <Option
        key={type.id}
        value={type.id}
        title={<Translation>{t => t(type.label, { ns: type.ns })}</Translation>}
      >
        <Icon
          component={type.icon}
          className={styles.icon}
          data-qa="qa-l7eb8in10p45sco"
        />

        <span>
          <Translation>{t => t(type.label, { ns: type.ns })}</Translation>
        </span>
      </Option>
    ))}
  </Select>
);

TaskTypeSelect.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onlyAllowedTypes: PropTypes.bool
};

TaskTypeSelect.defaultProps = {
  values: undefined,
  disabled: false,
  onChange: () => {},
  onlyAllowedTypes: false
};

export default TaskTypeSelect;
