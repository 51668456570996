/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const getBilling = state => state.billing;

export const getPaymentTransactions = createSelector(
  getBilling,
  billing => billing.transactions
);

export const getIsNeedToFetchPaymentAccount = createSelector(
  getBilling,
  billing => billing.isNeedToFetchAccount
);

export const getIsOnlyCompanyProfile = createSelector(
  getBilling,
  billing => billing.isOnlyCompanyPofile
);

export const getAvailableAddons = createSelector(
  getBilling,
  billing => billing.availableAddons
);
