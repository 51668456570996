import addImagePrefixToBase64 from './add-image-prefix-to-base64';

export const base64ToFile = (base64String, fileName) => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: addImagePrefixToBase64(base64String, true)
  });

  const file = new File([blob], fileName, {
    type: addImagePrefixToBase64(base64String, true)
  });

  return file;
};

export default base64ToFile;
