import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import {
  VIDEOS,
  TYPE_ORDER,
  TYPE_ORDER_TEMPLATE,
  ORDER_TYPES
} from 'constants/index';

import VideoBtn from 'components/common/video-btn';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Templates from 'components/orders-view/drawers/creator/templates';

import styles from './select-type.module.scss';

// NOT USED NOW
export const SelectType = ({
  activeTab,
  onChangeTab,
  createFromTemplate,
  onClick
}) => (
  <Tabs
    tabBarStyle={{ margin: '12px 24px 0' }}
    className={styles.tabs}
    activeKey={activeTab}
    onChange={onChangeTab}
  >
    {/* Новый */}
    <Tabs.TabPane tab="" key={TYPE_ORDER}>
      <div className={styles.root} data-qa="qa-sy92v5pmt8re8c4">
        <div className={styles.orderTypesTitle} data-qa="qa-6fj7raceoea5105">
          {/* <Typography.Text>Выберите тип заказа</Typography.Text> */}

          <VideoBtn slug={VIDEOS.typeOrder} />
        </div>

        <div className={styles.items} data-qa="qa-6mesgdb4ulq7y49">
          {Object.keys(ORDER_TYPES).map(key => (
            <Button
              key={key}
              type="link"
              style={{
                backgroundColor: ORDER_TYPES[key].backgroundColor
              }}
              className={styles.item}
              data-qa="qa-bdbfnmefow7lp75"
              onClick={() => onClick(key)}
            >
              <Icon
                component={ORDER_TYPES[key].icon}
                style={{ marginBottom: 8, color: ORDER_TYPES[key].iconColor }}
              />

              <Typography.Title
                level={3}
                style={{ color: ORDER_TYPES[key].color, marginBottom: 8 }}
              >
                {ORDER_TYPES[key].title}
              </Typography.Title>

              <Typography.Text size="small">
                {ORDER_TYPES[key].description}
              </Typography.Text>
            </Button>
          ))}
        </div>
      </div>
    </Tabs.TabPane>

    {/* Выбрать из шаблонов */}
    <Tabs.TabPane tab="" key={TYPE_ORDER_TEMPLATE}>
      {activeTab === TYPE_ORDER_TEMPLATE && (
        <Templates onClick={createFromTemplate} />
      )}
    </Tabs.TabPane>
  </Tabs>
);

SelectType.propTypes = {
  createFromTemplate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default SelectType;
