import React from 'react';
import { useDispatch } from 'react-redux';

import { fetchPaymentCards } from 'store/billing';

import CustomSelect from '..';

import PaymentCardOption from '../custom-select/payment-card-option';

const mapCard = card => ({
  value: card.id,
  label: card
});

export const PaymentCardSelect = props => {
  const dispatch = useDispatch();

  const fetchData = params =>
    dispatch(fetchPaymentCards({ params })).then(({ results, count }) => ({
      totalItems: count,
      entries: results.map(mapCard)
    }));

  return (
    <CustomSelect
      isAsync
      fetchData={fetchData}
      Option={PaymentCardOption}
      {...props}
    />
  );
};

export default PaymentCardSelect;
