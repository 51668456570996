import { createSelector } from 'reselect';

import getOrderingValue from '../../utils/get-ordering-value';

export const _getAttachments = state => state.attachments;

export const getAttachments = createSelector(
  _getAttachments,
  attachments => attachments.entries
);

export const getIsAttachmentsLoading = createSelector(
  _getAttachments,
  attachments => attachments.isLoading
);

export const getIsAttachmentsLoaded = createSelector(
  _getAttachments,
  attachments => attachments.isLoaded
);

export const getCurrentPage = createSelector(
  _getAttachments,
  attachments => attachments.currentPage
);

export const getTotalItems = createSelector(
  _getAttachments,
  attachments => attachments.totalItems
);

export const getItemsPerPage = createSelector(
  _getAttachments,
  attachments => attachments.itemsPerPage
);

export const getFilterAttachments = createSelector(
  _getAttachments,
  attachments => attachments.filter
);

export const getSortingAttachments = createSelector(
  getFilterAttachments,
  filter => getOrderingValue(filter.sorting)
);

export const getAttachmentsSearchFilter = createSelector(
  getFilterAttachments,
  filter => filter.search
);
