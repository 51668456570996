import React from 'react';

export const Container = ({ children, style, className }) => (
  <div
    style={{ width: '100%', overflow: 'auto', ...style }}
    className={className}
  >
    {children}
  </div>
);

export default Container;
