import React from 'react';

export const RestoreProfileIcon = () => (
  <svg
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.9998 138.277C70.3569 137.805 68.7284 137.248 67.1141 136.691C59.2712 133.763 51.0141 131.234 43.1569 128.62L41.8998 128.191C36.5712 126.448 31.4569 124.691 26.7855 122.748C25.1141 122.063 23.5284 121.405 21.9855 120.663C6.38551 113.448 -2.22878 104.048 8.12836 87.5055C9.01408 86.1055 9.64265 84.6055 10.1141 82.9483C12.0141 76.0055 10.9569 66.8626 10.7712 57.8055C10.7141 55.6483 10.7426 53.5197 10.8998 51.3197C11.0569 48.8055 11.3141 46.3197 11.8141 44.034C12.5998 40.0197 14.0998 36.2626 16.4998 32.8055C16.4998 32.8055 18.9855 29.3912 22.8569 26.6483C27.4855 23.434 79.4998 -5.46597 118.214 25.9055C119.771 27.1912 157.514 58.7626 145 97.2626C132.728 134.777 84.9284 141.905 71.9998 138.291V138.277Z"
      fill="#EBF8F8"
    />
    <path
      d="M104.258 21.6485C110.615 21.6771 115.744 26.2485 115.715 31.8771L115.429 108.106C115.415 113.734 110.244 118.263 103.886 118.234L46.6721 118.02C40.315 117.991 35.1864 113.42 35.215 107.791L35.5007 31.5628C35.515 25.9342 40.6864 21.4057 47.0435 21.42L104.258 21.6342V21.6485Z"
      fill="white"
    />
    <path
      d="M93.9575 51.034C93.9575 54.6054 92.9146 57.9483 91.1289 60.7626C87.8575 65.9768 82.0575 69.434 75.4575 69.4054C68.8575 69.3768 63.3718 66.0626 60.0861 61.0483C58.1861 58.1197 57.0718 54.634 57.0861 50.8911C57.1289 40.7054 65.4146 32.4768 75.6004 32.5197C85.7718 32.5626 94.0004 40.8483 93.9575 51.034Z"
      fill="#36B7BD"
    />
    <path
      d="M91.1717 51.2909L91.1288 60.7623C87.8574 65.9766 82.0574 69.4338 75.4574 69.4052C68.8574 69.3766 63.3717 66.0623 60.0859 61.048L60.1288 51.1766C60.1288 48.4623 62.3574 46.248 65.0859 46.248L86.2574 46.3338C88.9717 46.3338 91.1717 48.5766 91.1717 51.2909Z"
      fill="#FFE181"
    />
    <path
      d="M81.1576 53.3628L70.1433 53.3199C68.4719 53.3199 67.1147 51.9485 67.129 50.2771L67.1576 42.8914C67.1576 41.2199 68.529 39.8628 70.2004 39.8771L81.2147 39.9199C82.8862 39.9199 84.2433 41.2914 84.229 42.9628L84.2004 50.3485C84.2004 52.0199 82.829 53.3771 81.1576 53.3628ZM70.1719 42.7342C70.0862 42.7342 70.0004 42.8199 70.0004 42.9056L69.9719 50.2913C69.9719 50.3771 70.0576 50.4628 70.1433 50.4628L81.1576 50.5056C81.2433 50.5056 81.329 50.4199 81.329 50.3342L81.3576 42.9485C81.3576 42.8628 81.2719 42.7771 81.1862 42.7771L70.1719 42.7342Z"
      fill="#FEC458"
    />
    <path
      d="M91.186 51.3046V53.4189C91.086 53.4617 91.0003 53.5046 90.9003 53.5331L76.6003 58.7474C75.986 58.976 75.3003 58.976 74.686 58.7474L60.4003 53.4046C60.3003 53.376 60.2146 53.3331 60.1289 53.2903V51.1903C60.1432 48.476 62.3718 46.2617 65.1003 46.2617L86.2718 46.3474C88.986 46.3474 91.186 48.5903 91.186 51.3046Z"
      fill="#FEC458"
    />
    <path
      d="M68.0073 54.0098C68.0103 53.2208 67.3764 52.5788 66.5913 52.5758C65.8063 52.5728 65.1675 53.2099 65.1644 53.9989L65.1512 57.456C65.1481 58.245 65.7821 58.887 66.5671 58.89C67.3521 58.893 67.991 58.2559 67.994 57.4669L68.0073 54.0098Z"
      fill="white"
    />
    <path
      d="M86.1499 54.0801C86.1529 53.2911 85.5189 52.6491 84.7339 52.6461C83.9489 52.6431 83.31 53.2802 83.307 54.0692L83.2937 57.5263C83.2907 58.3153 83.9246 58.9573 84.7097 58.9603C85.4947 58.9633 86.1335 58.3262 86.1366 57.5372L86.1499 54.0801Z"
      fill="white"
    />
    <path
      d="M93.9178 77.3807L56.9467 77.2388C54.0669 77.2277 51.7235 79.5533 51.7124 82.433C51.7013 85.3127 54.0269 87.6562 56.9066 87.6673L93.8778 87.8092C96.7575 87.8203 99.101 85.4948 99.112 82.615C99.1231 79.7353 96.7976 77.3918 93.9178 77.3807Z"
      fill="#CEF4F4"
    />
    <path
      d="M93.8417 93.6092L56.8705 93.4673C53.9908 93.4562 51.6473 95.7818 51.6362 98.6615C51.6252 101.541 53.9507 103.885 56.8305 103.896L93.8016 104.038C96.6814 104.049 99.0248 101.723 99.0359 98.8435C99.0469 95.9638 96.7214 93.6203 93.8417 93.6092Z"
      fill="#CEF4F4"
    />
    <path
      d="M40.4853 126.076C56.1623 126.076 68.871 113.367 68.871 97.6904C68.871 82.0134 56.1623 69.3047 40.4853 69.3047C24.8083 69.3047 12.0996 82.0134 12.0996 97.6904C12.0996 113.367 24.8083 126.076 40.4853 126.076Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.7285 69.8768L41.1571 73.4768C41.0999 73.9054 40.7857 74.1054 40.4714 74.1054C40.4428 74.1054 40.4285 74.1054 40.3999 74.0911C40.1285 74.0768 39.8714 73.8768 39.7999 73.4768L39.6571 72.5768L39.2285 69.8626C39.6142 69.8483 40.0142 69.834 40.3999 69.834C40.8428 69.834 41.2857 69.8483 41.7285 69.8768Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.7438 125.205C41.3723 125.234 41.0152 125.248 40.6438 125.248H40.4437C40.0723 125.248 39.7152 125.234 39.3438 125.22L39.8723 121.92C39.9295 121.491 40.2438 121.291 40.558 121.291C40.5866 121.291 40.6152 121.291 40.6438 121.305C40.9152 121.334 41.1723 121.534 41.2295 121.92L41.3723 122.82L41.7438 125.205Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.2133 97.5765V98.005H68.1991C68.1991 98.3193 68.1991 98.6479 68.1705 98.9622L64.5133 98.3765C64.2276 98.3336 64.0562 98.1907 63.9705 98.005C63.9133 97.905 63.8848 97.805 63.8848 97.6907C63.8848 97.3907 64.0848 97.0907 64.5133 97.0193L65.4133 96.8765L68.1848 96.4336C68.1991 96.8193 68.2133 97.1907 68.2133 97.5765Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0996 82.6055L19.9281 84.9055C20.271 85.1626 20.2853 85.5483 20.1281 85.8198C20.1138 85.8483 20.0996 85.8626 20.0853 85.8769C19.9424 86.1055 19.6424 86.234 19.2567 86.0912L18.3996 85.7626L15.8281 84.7769C16.0138 84.434 16.1996 84.0769 16.3853 83.7483C16.6138 83.3626 16.8424 82.9912 17.0853 82.6198L17.0996 82.6055Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0273 110.263C64.8702 110.606 64.6988 110.92 64.5131 111.234L64.4131 111.406C64.2273 111.734 64.0416 112.034 63.8416 112.349L61.2416 110.234C60.8988 109.977 60.8845 109.591 61.0416 109.32C61.0559 109.291 61.0702 109.277 61.0988 109.249C61.2559 109.034 61.5559 108.906 61.9273 109.049L62.7845 109.377L65.0416 110.249L65.0273 110.263Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.3286 73.505L54.7 73.7193C54.9715 73.8907 55.2572 74.0479 55.5143 74.2336L53.1715 77.105C52.9857 77.3336 52.7857 77.405 52.5715 77.3907C52.4572 77.3907 52.3572 77.3622 52.2572 77.305C52 77.1622 51.8429 76.8336 51.9857 76.4193L52.3143 75.5622L53.3143 72.9336C53.6572 73.1193 53.9857 73.2907 54.3143 73.4764L54.3286 73.505Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.413 117.834C28.6701 117.991 28.8273 118.305 28.6844 118.72L28.3559 119.577L27.3987 122.077C27.0987 121.934 26.813 121.777 26.5273 121.605C26.0987 121.348 25.6844 121.105 25.2559 120.82L27.4987 118.048C27.7559 117.705 28.1416 117.691 28.413 117.848V117.834Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7287 72.9479L29.0287 76.3479C29.2001 76.7479 29.0287 77.0764 28.743 77.2336C28.7144 77.2479 28.7001 77.2622 28.6715 77.2622C28.4287 77.3907 28.1001 77.3479 27.843 77.0336L27.2715 76.3193L25.543 74.1764C25.8715 73.9764 26.2144 73.7622 26.543 73.5622C26.9287 73.3336 27.3144 73.1336 27.7144 72.9336L27.7287 72.9479Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.3998 120.863C55.0855 121.077 54.7855 121.263 54.4712 121.449L54.2998 121.549C53.9712 121.734 53.6569 121.906 53.3283 122.077L52.1426 118.949C51.9712 118.549 52.1426 118.22 52.4283 118.063C52.4569 118.049 52.4712 118.034 52.514 118.034C52.7569 117.92 53.0855 117.963 53.3283 118.277L53.8998 118.991L55.414 120.877L55.3998 120.863Z"
      fill="#36B7BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.5146 83.6771L64.7289 84.0486C64.8861 84.3343 65.0575 84.6057 65.1861 84.9057L61.7289 86.22C61.4575 86.32 61.2432 86.2914 61.0718 86.1628C60.9718 86.1057 60.9003 86.0343 60.8432 85.9343C60.7003 85.6771 60.7146 85.32 61.0575 85.0343L61.7718 84.4628L63.9575 82.6914C64.1575 83.02 64.3575 83.3343 64.5575 83.6628L64.5146 83.6771Z"
      fill="#36B7BD"
    />
    <path
      d="M40.6152 102.219C43.6438 102.219 46.1009 99.7623 46.1009 96.7338C46.1009 93.7052 43.6438 91.248 40.6152 91.248C37.5867 91.248 35.1152 93.7052 35.1152 96.7338C35.1152 99.7623 37.5724 102.219 40.6152 102.219Z"
      fill="#FDA402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5009 96.7201H37.8438L39.9295 83.4915C40.058 82.6487 41.158 82.6487 41.3009 83.4915L41.4438 84.3915L43.4009 96.7201H39.5009Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.1725 95.6064V93.9492L52.4011 96.0921C53.2439 96.2206 53.2296 97.3349 52.4011 97.4635L51.5011 97.6064L39.1582 99.5064V95.6064H39.1725Z"
      fill="#2B2A36"
    />
    <path
      d="M40.6153 100.191C42.5296 100.191 44.0725 98.6338 44.0725 96.7195C44.0725 94.8052 42.5296 93.248 40.6153 93.248C38.7011 93.248 37.1582 94.8052 37.1582 96.7195C37.1582 98.6338 38.7011 100.191 40.6153 100.191Z"
      fill="#FFE181"
    />
    <path
      d="M72.6003 97.5198C72.6003 115.134 58.3003 129.42 40.5717 129.677H40.1431C35.2574 129.677 30.6289 128.577 26.486 126.591C24.1574 125.477 22.0003 124.077 20.0146 122.463L22.6289 118.677C27.186 122.52 32.986 124.906 39.3431 125.148C39.7146 125.163 40.0717 125.177 40.4431 125.177H40.6431C41.0146 125.177 41.3717 125.163 41.7431 125.134C55.9574 124.491 67.3717 113.12 68.0574 98.9198C68.086 98.6055 68.086 98.277 68.086 97.9627C68.1003 97.8198 68.1003 97.6769 68.1003 97.5341C68.1003 97.1484 68.086 96.777 68.0717 96.3912C67.5003 82.1055 56.0289 70.577 41.7717 69.9198C41.3288 69.8912 40.886 69.8769 40.4431 69.8769C40.0574 69.8769 39.6574 69.877 39.2717 69.9055C25.2431 70.477 13.9003 81.5341 12.8717 95.4341H18.0431L10.7003 103.563L3.35742 95.4341H8.35742C9.11456 83.5055 16.3146 73.3341 26.486 68.4627C30.6289 66.4912 35.2574 65.377 40.1431 65.377C40.2717 65.377 40.386 65.377 40.5146 65.3912H40.5717C58.3003 65.6341 72.6003 79.9341 72.6003 97.5198Z"
      fill="url(#paint0_linear_78213_136569)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_78213_136569"
        x1="3.35742"
        y1="97.5198"
        x2="72.6003"
        y2="97.5198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default RestoreProfileIcon;
