export default api => ({
  fetch: (workspaceId, statusId, creator, offset, limit) =>
    api.get(`/v1/${workspaceId}/order-statuses/${statusId}/chats/`, {
      params: { employee: creator, offset, limit }
    }),

  fetchChat: (workspaceId, statusId, chatStatusId) =>
    api.get(
      `/v1/${workspaceId}/order-statuses/${statusId}/chats/${chatStatusId}/`,
      { errorHandle: false }
    ),

  getMessages: (
    workspaceId,
    statusId,
    chatId,
    offset,
    limit,
    withEvent = null,
    eventType = null
  ) =>
    api.get(
      `/v1/${workspaceId}/order-statuses/${statusId}/chats/${chatId}/messages/`,
      {
        params: { offset, limit, with_event: withEvent, event_type: eventType }
      }
    ),

  fetchLastNegotiation: (workspaceId, chatId) =>
    api.get(`/v1/${workspaceId}/order-chats/${chatId}/negotiation/`),

  fetchPaymentlogList: (workspaceId, chatId) =>
    api.get(`/v1/${workspaceId}/order-chats/${chatId}/transactions/`),

  fetchPaymentlog: (workspaceId, chatId, id) =>
    api.get(`/v1/${workspaceId}/order-chats/${chatId}/transactions/${id}/`),

  createPaymentlog: (workspaceId, chatId, value) =>
    api.post(`/v1/${workspaceId}/order-chats/${chatId}/transactions/`, value),

  deletePaymentlog: (workspaceId, chatId, id) =>
    api.delete(`/v1/${workspaceId}/order-chats/${chatId}/transactions/${id}`),

  updatePaymentlog: (workspaceId, chatId, id, value) =>
    api.put(
      `/v1/${workspaceId}/order-chats/${chatId}/transactions/${id}/`,
      value
    ),

  fetchAttachments: (workspaceId, statusId, chatId) =>
    api.get(
      `/v1/${workspaceId}/order-statuses/${statusId}/chats/${chatId}/attachments/`
    )
});
