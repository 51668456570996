import React from 'react';

export const ReportsNotFoundIcon = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.8269 18.2014C102.201 19.0541 110.309 20.645 110.309 20.645C112.752 21.1287 113.974 21.3959 115.222 21.6887C131.258 25.4432 141.16 32.316 134.682 48.7215C134.122 50.1215 133.829 51.547 133.714 53.0997C133.282 59.5652 136.056 67.4816 138.003 75.4744C138.474 77.3835 138.856 79.2671 139.149 81.2399C139.48 83.4926 139.722 85.7326 139.709 87.8454C139.76 91.5236 139.085 95.1127 137.507 98.5745C137.507 98.5745 135.84 102.024 132.76 105.091C129.043 108.718 85.9105 143.069 43.5921 121.827C26.232 113.096 1.41372 91.3581 4.55737 63.1416C7.06465 40.7669 26.092 23.9287 42.6757 18.3159C54.7667 14.2305 67.7868 15.5541 93.8269 18.2014Z"
      fill="#EBF8F8"
    />
    <path
      d="M113.847 31.8071C115.222 48.0726 116.583 64.3382 117.958 80.6037C118.314 84.8419 115.133 88.5583 110.882 88.8637L37.6613 94.031C33.4485 94.3238 29.8085 91.1547 29.503 86.9546L26.0412 38.0053C25.7485 33.7926 28.9176 30.1398 33.1303 29.8471L105.689 24.8325C109.851 24.5398 113.478 27.658 113.822 31.8071H113.847Z"
      fill="#E2E2E2"
    />
    <path
      d="M51.2666 43.2238L32.8247 44.522C32.0992 44.5729 31.4629 44.0256 31.412 43.2874C31.3611 42.5493 31.9083 41.9256 32.6465 41.8747L51.0884 40.5765C51.8139 40.5256 52.4503 41.0729 52.5012 41.8111C52.5521 42.5493 52.0048 43.1729 51.2666 43.2238Z"
      fill="white"
    />
    <path
      d="M68.8051 35.5109L32.3667 38.0818C31.6412 38.1328 31.0049 37.5855 30.954 36.8473C30.9031 36.1091 31.4503 35.4855 32.1885 35.4346L68.6269 32.8636C69.3523 32.8127 69.9887 33.36 70.0396 34.0982C70.0905 34.8364 69.5432 35.46 68.8051 35.5109Z"
      fill="white"
    />
    <path
      d="M65.5342 42.218L58.7124 42.7016C57.9869 42.7525 57.3506 42.2052 57.2997 41.467C57.2488 40.7289 57.796 40.1052 58.5342 40.0543L65.3561 39.5707C66.0815 39.5198 66.7179 40.067 66.7688 40.8052C66.8197 41.5434 66.2724 42.167 65.5342 42.218Z"
      fill="white"
    />
    <path
      d="M107.811 47.0915L34.1885 52.2913C33.2209 52.3596 32.4919 53.1994 32.5603 54.167L32.9969 60.3499C33.0653 61.3175 33.9051 62.0465 34.8727 61.9781L108.495 56.7783C109.463 56.7099 110.192 55.8701 110.123 54.9025L109.687 48.7197C109.618 47.7521 108.779 47.0231 107.811 47.0915Z"
      fill="white"
    />
    <path
      d="M109.143 60.4015L34.7583 65.6551C34.0011 65.7086 33.4306 66.3658 33.484 67.1231L33.7091 70.3097C33.7626 71.067 34.4198 71.6375 35.1771 71.584L109.561 66.3304C110.319 66.2769 110.889 65.6197 110.836 64.8624L110.61 61.6758C110.557 60.9185 109.9 60.348 109.143 60.4015Z"
      fill="white"
    />
    <path
      d="M109.808 69.8644L35.4239 75.118C34.6666 75.1715 34.0961 75.8287 34.1496 76.586L34.3746 79.7726C34.4281 80.5299 35.0854 81.1004 35.8426 81.0469L110.227 75.7933C110.984 75.7398 111.555 75.0825 111.501 74.3253L111.276 71.1387C111.223 70.3814 110.565 69.8109 109.808 69.8644Z"
      fill="white"
    />
    <path
      d="M110.473 79.338L36.0889 84.5916C35.3317 84.6451 34.7611 85.3024 34.8146 86.0596L35.0397 89.2462C35.0932 90.0035 35.7504 90.574 36.5077 90.5205L110.892 85.2669C111.649 85.2134 112.22 84.5562 112.166 83.7989L111.941 80.6123C111.888 79.855 111.23 79.2845 110.473 79.338Z"
      fill="white"
    />
    <path
      d="M146.824 75.1561L144.66 124.551C144.418 130.164 139.658 134.529 134.046 134.275L48.5814 130.533C42.9687 130.291 38.6032 125.531 38.8578 119.918L41.3269 63.5487C41.5687 57.936 46.3287 53.5705 51.9415 53.825L129.209 57.2105L146.824 75.1561Z"
      fill="#CEF4F4"
    />
    <path
      d="M128.458 74.3547L129.209 57.2109L146.824 75.1565L128.458 74.3547Z"
      fill="white"
    />
    <path
      d="M70.5491 72.1145L48.518 71.1472C47.6399 71.109 46.9653 70.3708 47.0035 69.4927C47.0417 68.6145 47.7799 67.9399 48.6581 67.9781L70.6891 68.9454C71.5673 68.9836 72.2418 69.7218 72.2036 70.5999C72.1654 71.4781 71.4273 72.1527 70.5491 72.1145Z"
      fill="#16969C"
    />
    <path
      d="M92.3893 65.3688L48.8491 63.4597C47.9709 63.4215 47.2964 62.6833 47.3346 61.8052C47.3727 60.927 48.1109 60.2524 48.9891 60.2906L92.5293 62.1997C93.4075 62.2379 94.0821 62.9761 94.0439 63.8543C94.0057 64.7325 93.2675 65.407 92.3893 65.3688Z"
      fill="#16969C"
    />
    <path
      d="M87.5908 72.8649L79.4453 72.5085C78.5671 72.4704 77.8926 71.7322 77.9307 70.854C77.9689 69.9758 78.7071 69.3013 79.5853 69.3394L87.7308 69.6958C88.609 69.734 89.2835 70.4722 89.2453 71.3504C89.2072 72.2285 88.469 72.9031 87.5908 72.8649Z"
      fill="#16969C"
    />
    <path
      d="M137.397 84.3886L48.7464 80.518C47.7773 80.4757 46.9574 81.227 46.9151 82.1961L46.5631 90.2576C46.5208 91.2267 47.2721 92.0466 48.2412 92.0889L136.892 95.9594C137.861 96.0017 138.681 95.2504 138.723 94.2813L139.075 86.2199C139.117 85.2508 138.366 84.4309 137.397 84.3886Z"
      fill="#32C9C9"
    />
    <path
      d="M137.083 100.35L47.6696 96.4457C46.9112 96.4126 46.2695 97.0006 46.2364 97.759L46.0471 102.095C46.014 102.853 46.602 103.495 47.3604 103.528L136.774 107.432C137.532 107.465 138.174 106.877 138.207 106.119L138.396 101.783C138.429 101.024 137.841 100.383 137.083 100.35Z"
      fill="white"
    />
    <path
      d="M136.578 111.657L47.1643 107.753C46.4058 107.72 45.7642 108.308 45.7311 109.067L45.5417 113.402C45.5086 114.161 46.0966 114.803 46.855 114.836L136.268 118.74C137.027 118.773 137.669 118.185 137.702 117.426L137.891 113.09C137.924 112.332 137.336 111.69 136.578 111.657Z"
      fill="#32C9C9"
    />
    <path
      d="M136.097 122.978L46.6838 119.075C45.9254 119.041 45.2837 119.629 45.2506 120.388L45.0613 124.724C45.0282 125.482 45.6161 126.124 46.3746 126.157L135.788 130.061C136.546 130.094 137.188 129.506 137.221 128.748L137.411 124.412C137.444 123.653 136.856 123.012 136.097 122.978Z"
      fill="#32C9C9"
    />
    <path
      d="M61.7795 102.176L54.894 101.871C54.0285 101.833 53.3667 101.107 53.4049 100.242C53.4431 99.3762 54.1685 98.7144 55.034 98.7525L61.9195 99.058C62.7849 99.0962 63.4468 99.8216 63.4086 100.687C63.3704 101.553 62.6449 102.214 61.7795 102.176Z"
      fill="#CEF4F4"
    />
    <path
      d="M74.978 102.748L68.3725 102.456C67.507 102.418 66.8452 101.692 66.8834 100.827C66.9216 99.9611 67.647 99.2993 68.5125 99.3375L75.118 99.6302C75.9835 99.6684 76.6453 100.394 76.6071 101.259C76.5689 102.125 75.8435 102.787 74.978 102.748Z"
      fill="#CEF4F4"
    />
    <path
      d="M61.2834 113.491L54.3979 113.185C53.5324 113.147 52.8706 112.422 52.9088 111.556C52.947 110.691 53.6724 110.029 54.5379 110.067L61.4234 110.372C62.2888 110.411 62.9507 111.136 62.9125 112.002C62.8743 112.867 62.1488 113.529 61.2834 113.491Z"
      fill="#CEF4F4"
    />
    <path
      d="M60.4689 124.704L53.5834 124.398C52.718 124.36 52.0562 123.634 52.0943 122.769C52.1325 121.904 52.858 121.242 53.7234 121.28L60.6089 121.585C61.4744 121.624 62.1362 122.349 62.098 123.214C62.0598 124.08 61.3344 124.742 60.4689 124.704Z"
      fill="#CEF4F4"
    />
    <path
      d="M74.4814 114.064L67.8759 113.771C67.0105 113.733 66.3486 113.007 66.3868 112.142C66.425 111.277 67.1505 110.615 68.0159 110.653L74.6214 110.946C75.4869 110.984 76.1487 111.709 76.1105 112.575C76.0723 113.44 75.3469 114.102 74.4814 114.064Z"
      fill="#CEF4F4"
    />
    <path
      d="M13.1355 115.502L29.3628 106.517L24.3737 97.5059L8.14637 106.491C5.66454 107.866 4.77363 110.984 6.14818 113.466L6.17363 113.504C7.54819 115.999 10.6537 116.902 13.1482 115.502H13.1355Z"
      fill="url(#paint0_linear_73913_412949)"
    />
    <path
      d="M25.2139 98.9949L28.5357 104.989L33.2957 102.355L29.9739 96.3604L25.2139 98.9949Z"
      fill="#2B2A36"
    />
    <path
      d="M28.4209 83.1491C25.0227 94.9728 31.8573 107.306 43.681 110.691C55.5047 114.077 67.8375 107.255 71.2229 95.431C74.6084 83.6073 67.7865 71.2745 55.9628 67.889C44.1392 64.5035 31.8064 71.3254 28.4209 83.1491Z"
      fill="#2B2A36"
    />
    <path
      d="M33.9829 84.7403C31.4756 93.484 36.5283 102.609 45.272 105.117C54.0157 107.624 63.1412 102.571 65.6485 93.8276C68.1558 85.0839 63.103 75.9584 54.3593 73.4511C45.6156 70.9438 36.4901 75.9966 33.9829 84.7403Z"
      fill="#F2F2F2"
    />
    <path
      d="M39.6976 82.7415C38.934 81.3543 40.334 79.1143 42.8413 77.727C45.3358 76.3397 47.9831 76.3397 48.7595 77.727C49.5231 79.1143 48.1231 81.3543 45.6158 82.7415C43.1085 84.1288 40.474 84.1288 39.6976 82.7415Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_73913_412949"
        x1="13.1133"
        y1="116.159"
        x2="22.7081"
        y2="98.3766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default ReportsNotFoundIcon;
