import React, { useEffect, useMemo } from 'react';
import { Alert } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, SCHEDULER_OPTIONS } from 'constants/index';

import {
  FormCustomSelect,
  FormDatePicker,
  FormInputNumber
} from 'components/common/hook-form';

import { fetchSchedulerOrderNextDateLocal } from 'store/order-statuses';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from './scheduler.module.scss';

const SchedulerForm = () => {
  const dispatch = useDispatch();
  const { control } = useFormContext();

  const { t } = useTranslation(['OrderConditions', 'Errors']);

  const [startAtScheduler, interval, frequency, deadlineAt, startAt] = useWatch(
    {
      name: [
        'scheduler.startAt',
        'scheduler.interval',
        'scheduler.frequency',
        'deadlineAt',
        'startAt'
      ],
      control
    }
  );

  const [, , minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: startAt,
    endDate: startAtScheduler || startAt
  });

  const canShowNextActDate = useMemo(
    () => !!interval && startAtScheduler && frequency && deadlineAt,
    [interval, startAtScheduler, frequency, deadlineAt]
  );

  useEffect(() => {
    if (canShowNextActDate) {
      dispatch(
        fetchSchedulerOrderNextDateLocal({
          startAt:
            startAtScheduler && moment(startAtScheduler).toISOString(true),
          frequency: frequency && frequency.value.toLowerCase(),
          until: deadlineAt && moment(deadlineAt).toISOString(true),
          interval
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAtScheduler, interval, frequency, deadlineAt]);

  return (
    <>
      <div className={styles.root}>
        <FormDatePicker
          name="scheduler.startAt"
          label={t('FirstActDate')}
          minDate={startAt || new Date()}
          maxDate={moment(deadlineAt)
            .subtract(1, 'day')
            .toDate()}
          minTime={minTimeEnd}
          maxTime={maxTimeEnd}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          wrapperClassname={styles.datePicker}
        />

        <div className={styles.interval}>
          <FormInputNumber
            className={styles.count}
            isOnlyWhole
            disableZeroEntry
            label={t('ActRecurrence')}
            name="scheduler.interval"
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
          />

          <FormCustomSelect
            name="scheduler.frequency"
            options={SCHEDULER_OPTIONS}
            className={styles.frequency}
          />
        </div>
      </div>

      {canShowNextActDate && startAtScheduler && (
        <Alert
          message={t('ActScheduleWarning', {
            firstDate: moment(startAtScheduler).format(DATE_TIME_FORMAT),
            lastDate: moment(deadlineAt).format(DATE_TIME_FORMAT)
          })}
          type="warning"
        />
      )}
    </>
  );
};

export default SchedulerForm;
