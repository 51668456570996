import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  PAYMENT_ACCOUNT_STATUS_BLOCKED,
  PAYMENT_ACCOUNT_STATUS_EXPIRED
} from 'constants/billing';

import { WorkspaceLogoIcon } from 'components/common/icons';
import Avatar from 'components/common/avatar';
import Icon from 'components/common/icon';
import { useTheme } from 'components/common/theme';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';

import { getAllWorkspaces, getWorkspaceId, setActiveId } from 'store/workspace';
import { getEmployeeEntityChatsUnreadedCount } from 'store/operator';

import useModalsService from 'services/modals';
import useRoutesService from 'services/routes';
import getWorkspaceTitle from 'utils/get-workspace-title';

import Typography from '../typography';
import RestoreWorkspaceModal from './restore-workspace-modal';
import Tooltip from '../tooltip';

import styles from './workspaces-switch.module.scss';

export const WorkspacesSwitch = ({ children, dropdownWidth }) => {
  const dispatch = useDispatch();

  const workspaces = useSelector(getAllWorkspaces);
  const workspaceId = useSelector(getWorkspaceId);
  const unreadedCount = useSelector(getEmployeeEntityChatsUnreadedCount);

  const [restoredWorkspace, setRestoredWorkspace] = useState(undefined);

  const { variables } = useTheme();

  const modalUrls = useModalsService({ returnUrl: true });
  const routes = useRoutesService();

  const { t } = useTranslation(['Menu', 'Billing']);

  const onSelectWorkspace = id => {
    dispatch(setActiveId({ workspaceId: id }));
    routes.toDefaultPage({ workspaceId: id });
  };

  const toBilling = id => {
    dispatch(setActiveId({ workspaceId: id }));
    routes.toBilling({ id, workspaceId: id });
  };

  const selectWorkspace = ({
    id,
    isArchived,
    isAccountStatusExpired,
    isAccountStatusBlocked,
    hasPaymentAccess
  }) => {
    if (isArchived) {
      return setRestoredWorkspace(workspaces[id]);
    }

    if (isAccountStatusExpired) {
      return hasPaymentAccess ? toBilling(id) : onSelectWorkspace(id);
    }

    if (isAccountStatusBlocked) {
      return hasPaymentAccess ? toBilling(id) : null;
    }

    return onSelectWorkspace(id);
  };

  const menu = (
    <Menu>
      {Object.values(workspaces)
        .filter(({ id }) => id !== workspaceId)
        .map(
          ({ id, logoFile, title, aliasTitle, isArchived, user, account }) => {
            const workspaceTitle = getWorkspaceTitle({
              title,
              aliasTitle
            });

            const hasPaymentAccess = checkPaymentAccessByRoles(user.roles);

            const isAccountStatusExpired =
              account.status === PAYMENT_ACCOUNT_STATUS_EXPIRED;
            const isAccountStatusBlocked =
              account.status === PAYMENT_ACCOUNT_STATUS_BLOCKED;

            return (
              <Menu.Item key={id}>
                <div
                  className={classnames(styles.itemContent, {
                    [styles.isArchived]: isArchived,
                    [styles.isExpired]:
                      isAccountStatusExpired || isAccountStatusBlocked,
                    [styles.isBlocked]:
                      isAccountStatusBlocked && !hasPaymentAccess
                  })}
                  onClick={() =>
                    selectWorkspace({
                      id,
                      isArchived,
                      isAccountStatusExpired,
                      isAccountStatusBlocked,
                      hasPaymentAccess
                    })
                  }
                >
                  <Badge
                    className={styles.notification}
                    offset={[-9, 4]}
                    dot={
                      !!(
                        (user.chats || []).length &&
                        unreadedCount[user.chats[0].uuid]
                      )
                    }
                  >
                    <Avatar
                      src={(logoFile || {}).url}
                      size={25}
                      title={workspaceTitle}
                      className={styles.avatar}
                    >
                      <Icon component={WorkspaceLogoIcon} />
                    </Avatar>
                  </Badge>

                  <div className={styles.titleWrap}>
                    <Tooltip title={workspaceTitle}>
                      <Typography.Text ellipsis className={styles.title}>
                        {workspaceTitle}
                      </Typography.Text>
                    </Tooltip>

                    {isArchived && (
                      <div className={styles.additionalInfo}>
                        <Typography.Text size="small">
                          {t('CompanyDeleted')}{' '}
                        </Typography.Text>

                        <Typography.Text size="small" color="brand">
                          {t('RestoreBtn')}
                        </Typography.Text>
                      </div>
                    )}

                    {isAccountStatusExpired && !isArchived && (
                      <div className={styles.additionalInfo}>
                        <Typography.Text size="small" color="red">
                          {t('SubscriptionNotPaid', { ns: 'Billing' })}{' '}
                        </Typography.Text>

                        {hasPaymentAccess && (
                          <Typography.Text size="small" color="brand">
                            {t('PayBtn', { ns: 'Billing' })}
                          </Typography.Text>
                        )}
                      </div>
                    )}

                    {isAccountStatusBlocked && !isArchived && (
                      <div className={styles.additionalInfo}>
                        <Typography.Text size="small" color="red">
                          {t('CompanyBlocked', { ns: 'Billing' })}{' '}
                        </Typography.Text>

                        {hasPaymentAccess && (
                          <Typography.Text size="small" color="brand">
                            {t('PayOffDebtBtn', { ns: 'Billing' })}
                          </Typography.Text>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          }
        )}

      <Menu.Item>
        <Link to={modalUrls.workspace.create()}>
          <div
            className={styles.itemContent}
            style={{
              color: variables.color['brand-40'],
              whiteSpace: 'normal'
            }}
          >
            <Icon
              type="plus-circle"
              size={26}
              style={{
                marginRight: 5
              }}
            />

            <span className={styles.title}>{t('AddCompanyBtn')}</span>
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={menu}
        trigger={['click']}
        overlayClassName={styles.dropdownOverlay}
        overlayStyle={{ width: dropdownWidth }}
      >
        {children}
      </Dropdown>

      <RestoreWorkspaceModal
        visible={!!restoredWorkspace}
        workspace={restoredWorkspace}
        onClose={() => setRestoredWorkspace(undefined)}
      />
    </>
  );
};

export default WorkspacesSwitch;
