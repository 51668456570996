import { combineActions } from 'redux-actions';

import { COLLAPSED_SIDER } from 'components/common/layout/template/sider';

import handleActions from 'utils/redux-actions';
import { parseValueStorage } from 'hooks/common/use-local-storage';

import {
  logout,
  login,
  confirmUser,
  refreshAuthToken,
  fetchCurrentUser,
  updateUser,
  updateProfile,
  acceptAgreement,
  changeFieldValue,
  googleAuth,
  deleteGoogleAccount,
  changeIsFeedbackSent,
  setCollapsedSider,
  confirmEmail,
  changeIsSuccess,
  completeRegistration,
  deleteFacebookAccount,
  addFacebookAccount,
  facebookAuth,
  sendFeedback,
  fetchOnboarding,
  updateOnboarding,
  changeIsOnlyUserProfile
} from './actions';

const initialState = {
  id: undefined,
  fullName: '',
  token: localStorage.getItem('token'),
  isLoading: false,
  isLoaded: false,
  isSuccess: false,
  error: undefined,
  agreementAcceptedAt: null,
  popUpCount: 0,
  lastPopUpSeenAt: null,
  isRegisterStep: false,
  collapsedSider: parseValueStorage(localStorage.getItem(COLLAPSED_SIDER)),
  onboarding: [],
  isOnlyUserProfile: false
};

export default handleActions(
  {
    [logout]: () => ({
      ...initialState,

      token: null
    }),

    [combineActions(
      confirmUser.SUCCEEDED,
      refreshAuthToken.SUCCEEDED,
      confirmEmail.SUCCEEDED
    )]: (state, { payload }) => {
      state.token = payload;

      return state;
    },

    [completeRegistration.SUCCEEDED]: state => {
      state.isRegistrationCompleted = true;
    },

    [combineActions(
      login.SUCCEEDED,
      googleAuth.SUCCEEDED,
      facebookAuth.SUCCEEDED
    )]: (state, { payload }) => {
      state.token = payload.access;

      return state;
    },

    [changeIsFeedbackSent.SUCCEEDED]: (state, { payload }) => {
      state.isFeedbackSent = payload.isFeedbackSent;
      state.feedbackSentAt = payload.feedbackSentAt;

      return state;
    },

    [sendFeedback.SUCCEEDED]: state => {
      state.isFeedbackSent = true;
      state.feedbackSentAt = new Date();

      return state;
    },

    [deleteGoogleAccount.SUCCEEDED]: state => {
      state.googleEmail = null;

      return state;
    },

    [addFacebookAccount.SUCCEEDED]: (state, { payload }) => {
      state.facebookUserId = payload;

      return state;
    },

    [deleteFacebookAccount.SUCCEEDED]: state => {
      state.facebookUserId = null;

      return state;
    },

    [fetchCurrentUser.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchCurrentUser.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchCurrentUser.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [acceptAgreement.SUCCEEDED]: state => {
      state.isAgreePolicy = true;

      return state;
    },

    [combineActions(
      fetchCurrentUser.SUCCEEDED,
      updateUser.SUCCEEDED,
      completeRegistration.SUCCEEDED,
      updateProfile
    )]: (state, { payload }) => {
      state.isLoaded = true;
      state.fullName = [
        payload.lastName,
        payload.firstName,
        payload.middleName
      ].join(' ');

      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });

      return state;
    },

    [changeFieldValue]: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });

      return state;
    },

    [setCollapsedSider]: (state, { payload }) => {
      state.collapsedSider = payload;

      return state;
    },

    [changeIsSuccess]: (state, { payload }) => {
      state.isSuccess = payload;

      return state;
    },

    [changeIsOnlyUserProfile]: (state, { payload }) => {
      state.isOnlyUserProfile = payload;

      return state;
    },

    [fetchOnboarding.SUCCEEDED]: (state, { payload }) => {
      state.onboarding = payload.results;

      return state;
    },

    [updateOnboarding.SUCCEEDED]: (state, { payload }) => {
      const newOnboarding = state.onboarding.filter(o => o.id !== payload.id);
      state.onboarding = [...newOnboarding, payload];

      return state;
    }
  },
  initialState
);
