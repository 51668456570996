import React from 'react';

export const EmptyMessage = () => (
  <svg
    width="135"
    height="141"
    viewBox="0 0 135 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1151 0.0265679C95.0217 -0.404898 117.219 4.33383 128.782 20.4871C139.582 35.5753 127.812 55.4334 128.039 73.9651C128.273 92.9673 142.112 113.999 130.11 128.767C117.906 143.785 94.3081 141.854 75.1151 139.091C58.7467 136.734 46.4639 125.769 34.0056 114.93C20.1218 102.851 2.29225 92.2187 0.257827 73.9651C-1.90294 54.5779 9.83061 36.4961 23.7333 22.764C37.5696 9.09756 55.6381 0.448722 75.1151 0.0265679Z"
      fill="#EBF8F8"
    />
    <path
      d="M76.9574 23.9512C62.6502 23.9512 50.0549 30.3902 42.709 40.151L93.0863 93.2371C107.735 87.7264 118 74.9387 118 60.0416C118 40.1094 99.6244 23.9512 76.9574 23.9512Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M99.9748 73.2355C99.9748 53.3301 81.624 37.1934 58.9873 37.1934C36.3506 37.1934 18 53.3299 18 73.2355C18 89.2088 29.8178 102.753 46.1834 107.482L43.7229 115.022C43.5244 115.63 44.1006 116.207 44.709 116.008L68.0469 108.393C86.324 104.766 99.9748 90.4033 99.9748 73.2355Z"
      fill="#CEF4F4"
    />
    <path
      d="M76.9574 23.9512C62.6502 23.9512 50.0549 30.3902 42.709 40.151C69.6516 29.8865 99.9746 47.2721 99.9746 73.2361C99.9746 80.6365 97.4363 87.5145 93.0863 93.2371C107.735 87.7264 118 74.9387 118 60.0416C118 40.1094 99.6244 23.9512 76.9574 23.9512Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M58.9881 78.5406C61.7872 78.5406 64.0563 76.2715 64.0563 73.4725C64.0563 70.6734 61.7872 68.4043 58.9881 68.4043C56.189 68.4043 53.9199 70.6734 53.9199 73.4725C53.9199 76.2715 56.189 78.5406 58.9881 78.5406Z"
      fill="#36B7BD"
    />
    <path
      d="M38.3807 78.5406C41.1797 78.5406 43.4488 76.2715 43.4488 73.4725C43.4488 70.6734 41.1797 68.4043 38.3807 68.4043C35.5816 68.4043 33.3125 70.6734 33.3125 73.4725C33.3125 76.2715 35.5816 78.5406 38.3807 78.5406Z"
      fill="#36B7BD"
    />
    <path
      d="M79.5936 78.5406C82.3926 78.5406 84.6617 76.2715 84.6617 73.4725C84.6617 70.6734 82.3926 68.4043 79.5936 68.4043C76.7945 68.4043 74.5254 70.6734 74.5254 73.4725C74.5254 76.2715 76.7945 78.5406 79.5936 78.5406Z"
      fill="#36B7BD"
    />
    <path
      d="M70.0297 38.5176C83.6983 44.3607 93.1203 56.6873 93.1203 70.95C93.1203 88.1178 79.4695 102.48 61.1924 106.107L44.8973 111.424L43.7234 115.021C43.525 115.63 44.1012 116.206 44.7096 116.008L68.0475 108.392C86.3246 104.766 99.9754 90.4031 99.9754 73.2352C99.9752 56.6937 87.3026 42.7566 70.0297 38.5176Z"
      fill="#CEF4F4"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="117.877"
        y1="50.6536"
        x2="50.6607"
        y2="34.7053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="117.877"
        y1="50.6536"
        x2="50.6607"
        y2="34.7053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyMessage;
