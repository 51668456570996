import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  DATE_FORMAT,
  TIME_FORMAT,
  TYPE_CONTACT,
  TYPE_ORDER
} from 'constants/index';

import Typography from 'components/common/typography';

import styles from './chat-item.module.scss';

const { Text } = Typography;

const ChatItem = ({ dialog }) => {
  const formattedDate = moment(dialog.updated_at).format(DATE_FORMAT);
  const formattedTime = moment(dialog.updated_at).format(TIME_FORMAT);

  const isYesterday = moment(formattedDate).isSame(
    moment().subtract(1, 'days'),
    'day'
  );

  const renderTitle = () => {
    if (dialog.entity_type === TYPE_CONTACT) {
      return `${dialog.entity_id} ${(dialog.entity &&
        dialog.entity.full_name) ||
        ''}`;
    }

    if (dialog.entity_type === TYPE_ORDER) {
      return `${dialog.entity.id} ${dialog.entity.title}`;
    }

    return `${dialog.entity_id} ${dialog.entity.title}`;
  };

  return (
    <div className={styles.root}>
      <div className={styles.dialogInfo}>
        <Text ellipsis className={styles.paragraph} weight="semibold">
          ID {renderTitle()}
        </Text>

        <Text ellipsis className={styles.paragraph}>
          {dialog.last_message}
        </Text>
      </div>

      <Text color="black-55">
        {isYesterday ? formattedDate : formattedTime}
      </Text>
    </div>
  );
};

ChatItem.propTypes = {
  dialog: PropTypes.shape({
    workspace: PropTypes.number,
    user_id: PropTypes.number,
    entity_type: PropTypes.string,
    entity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entity: PropTypes.object,
    last_message: PropTypes.string,
    updated_at: PropTypes.string,
    id: PropTypes.string
  }).isRequired
};

export default ChatItem;
