import React from 'react';

export const MyOrdersIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.8 40.85C96.7 40.65 95.4 38.95 92.6 35.55C92.6 35.55 90.6 33.05 88.7 31.05C88.6 30.95 88.5 30.85 88.4 30.75C88 30.35 87.6 29.95 87.2 29.65C86.8 29.25 86.4 28.85 86 28.55C85.9 28.45 85.8 28.35 85.7 28.25C85.1 27.75 84.5 27.25 83.9 26.75C83.7 26.55 83.4 26.45 83.3 26.25C82.5 25.65 81.7 25.05 80.8 24.45C80.7 24.35 80.7 24.35 80.7 24.35C80.6 24.25 80.5 24.25 80.4 24.15C79.7 23.65 79 23.25 78.4 22.75L78.2 22.65C78.1 22.55 78.1 22.55 78.1 22.55C78 22.45 77.9 22.45 77.8 22.35C77 21.95 76.2 21.45 75.5 21.05C72.1 19.15 68.5 17.45 64.7 16.15C60 14.45 54.9 13.25 49.8 12.75C49.7 12.75 49.6 12.75 49.5 12.75C48.8 12.65 48.2 12.65 47.5 12.65C47.4 12.65 47.3 12.65 47.2 12.65H47.1C43.4 12.25 39.6 12.45 35.8 13.05C34.9 13.15 34 13.35 33 13.55C32.1 13.75 31.2 13.95 30.5 14.25C29.7 14.55 28.8 14.85 28 15.15C17.4 19.85 15.7 31.05 13.9 42.55C13.6 44.75 13.2 47.05 12.9 49.25C12.1 53.95 10.9 58.65 8.89996 62.75C6.09996 71.55 6.89996 79.25 11 84.65C11.1 84.75 12 85.45 12.7 86.65C16.7 90.45 22.7 92.65 30.4 92.55C36.6 92.45 57.5 93.65 75.2 87.75C75.2 87.75 77.8 86.95 81.2 85.45C93.4 79.95 96.5 74.45 97.1 72.45C97.4 71.45 97.4 70.65 97.4 70.65C97.2 68.55 95.3 67.35 94.7 62.05C93.5 51.65 100.1 46.65 96.8 40.85Z"
      fill="#EBF8F8"
    />
    <path
      d="M77.2 68.85V76.65C77.2 78.45 75.6999 79.95 73.8999 79.95H41.4C39.6 79.95 38.1 78.45 38.1 76.65V68.85C38.1 67.05 39.6 65.55 41.4 65.55H73.8999C75.6999 65.55 77.2 67.05 77.2 68.85Z"
      fill="#FBFFFF"
    />
    <path
      d="M64 76.35H53.9C53.5 76.35 53.2 76.05 53.2 75.65C53.2 75.25 53.5 74.95 53.9 74.95H64C64.4 74.95 64.7 75.25 64.7 75.65C64.7 76.05 64.4 76.35 64 76.35Z"
      fill="#E2E2E2"
    />
    <path
      d="M73.7999 72.85H53.9C53.5 72.85 53.2 72.55 53.2 72.15C53.2 71.75 53.5 71.45 53.9 71.45H73.7999C74.1999 71.45 74.5 71.75 74.5 72.15C74.5 72.55 74.1999 72.85 73.7999 72.85Z"
      fill="#E2E2E2"
    />
    <path
      d="M71.7999 76.35H68.0999C67.6999 76.35 67.3999 76.05 67.3999 75.65C67.3999 75.25 67.6999 74.95 68.0999 74.95H71.7999C72.1999 74.95 72.5 75.25 72.5 75.65C72.5 76.05 72.1999 76.35 71.7999 76.35Z"
      fill="#E2E2E2"
    />
    <path
      d="M49.6 74.15C49.6 76.35 47.8 78.15 45.6 78.15C43.4 78.15 41.6 76.35 41.6 74.15C41.6 71.95 43.4 70.15 45.6 70.15C47.8 70.15 49.6 71.95 49.6 74.15Z"
      fill="#E2E2E2"
    />
    <path
      d="M47 73.15C47 73.95 46.3 74.65 45.5 74.65C44.7 74.65 44 73.95 44 73.15C44 72.35 44.7 71.65 45.5 71.65C46.4 71.65 47 72.25 47 73.15Z"
      fill="#FBFFFF"
    />
    <path
      d="M47.1 75.65H44.1C43.6 75.65 43.2 76.05 43.2 76.55V77.35C43.9 77.85 44.7 78.15 45.6 78.15C46.5 78.15 47.3 77.85 48 77.35V76.55C47.9 76.05 47.5 75.65 47.1 75.65Z"
      fill="#FBFFFF"
    />
    <path
      d="M80.8 52.75V63.55C80.8 66.05 78.8 68.05 76.3 68.05H31.3C28.8 68.05 26.8 66.05 26.8 63.55V52.75C26.8 50.25 28.8 48.25 31.3 48.25H76.2C78.8 48.25 80.8 50.25 80.8 52.75Z"
      fill="white"
    />
    <path
      d="M62.5 63.15H48.6C48 63.15 47.6 62.75 47.6 62.15C47.6 61.55 48 61.15 48.6 61.15H62.5C63.1 61.15 63.5 61.55 63.5 62.15C63.5 62.75 63.1 63.15 62.5 63.15Z"
      fill="#E2E2E2"
    />
    <path
      d="M76.1 58.25H48.6C48 58.25 47.6 57.85 47.6 57.25C47.6 56.65 48 56.25 48.6 56.25H76.1C76.7 56.25 77.1 56.65 77.1 57.25C77.1 57.85 76.7 58.25 76.1 58.25Z"
      fill="#E2E2E2"
    />
    <path
      d="M73.3 63.15H68.1C67.5 63.15 67.1 62.75 67.1 62.15C67.1 61.55 67.5 61.15 68.1 61.15H73.3C73.9 61.15 74.3 61.55 74.3 62.15C74.3 62.75 73.8 63.15 73.3 63.15Z"
      fill="#E2E2E2"
    />
    <path
      d="M42.6 60.05C42.6 63.05 40.1 65.55 37.1 65.55C34.1 65.55 31.6 63.05 31.6 60.05C31.6 57.05 34.1 54.55 37.1 54.55C40.1 54.55 42.6 57.05 42.6 60.05Z"
      fill="#E2E2E2"
    />
    <path
      d="M39.1 58.65C39.1 59.75 38.2 60.65 37.1 60.65C36 60.65 35.1 59.75 35.1 58.65C35.1 57.55 36 56.65 37.1 56.65C38.2 56.55 39.1 57.45 39.1 58.65Z"
      fill="white"
    />
    <path
      d="M39.1 62.15H35C34.3 62.15 33.8 62.65 33.8 63.35V64.45C34.7 65.15 35.8 65.55 37.1 65.55C38.3 65.55 39.4 65.15 40.4 64.45V63.35C40.3 62.75 39.8 62.15 39.1 62.15Z"
      fill="white"
    />
    <path
      d="M91.1 31.05V80.85"
      stroke="#B1B1B1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.1 37.05C89.4 37.05 87.9 35.65 87.9 33.85V26.95C87.9 25.25 89.3 23.75 91.1 23.75C92.8 23.75 94.3 25.15 94.3 26.95V33.85C94.2 35.65 92.8 37.05 91.1 37.05Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M30.4 32.15C26 32.15 22.5 35.65 22.5 40.05C22.5 44.45 26 47.95 30.4 47.95C34.8 47.95 38.3 44.45 38.3 40.05C38.3 35.65 34.8 32.15 30.4 32.15Z"
      fill="white"
    />
    <path
      d="M80.8 52.75V53.25H26.8V52.75C26.8 50.25 28.8 48.25 31.3 48.25H76.2C78.8 48.25 80.8 50.25 80.8 52.75Z"
      fill="#F0F0F0"
    />
    <path
      d="M78.4 27.15H24.4C21.4 27.15 19 29.55 19 32.55V45.45C19 48.45 21.5 50.85 24.4 50.85H78.3C81.3 50.85 83.7 48.35 83.7 45.45V32.55C83.7999 29.55 81.4 27.15 78.4 27.15Z"
      fill="white"
    />
    <path
      d="M59.8 42.35H45.9C45.3 42.35 44.9 41.95 44.9 41.35C44.9 40.75 45.3 40.35 45.9 40.35H59.8C60.4 40.35 60.8 40.75 60.8 41.35C60.8 41.95 60.4 42.35 59.8 42.35Z"
      fill="#2D9EA3"
    />
    <path
      d="M73.5 37.45H45.9C45.3 37.45 44.9 37.05 44.9 36.45C44.9 35.85 45.3 35.45 45.9 35.45H73.4C74 35.45 74.4 35.85 74.4 36.45C74.4 37.05 74 37.45 73.5 37.45Z"
      fill="#2D9EA3"
    />
    <path
      d="M70.6 42.35H65.4C64.8 42.35 64.4 41.95 64.4 41.35C64.4 40.75 64.8 40.35 65.4 40.35H70.6C71.2 40.35 71.6 40.75 71.6 41.35C71.6 41.95 71.2 42.35 70.6 42.35Z"
      fill="#2D9EA3"
    />
    <path
      d="M37.4 39.55C37.4 43.25 34.4 46.25 30.7 46.25C27 46.25 24 43.25 24 39.55C24 35.85 27 32.85 30.7 32.85C34.4 32.85 37.4 35.85 37.4 39.55Z"
      fill="#2D9EA3"
    />
    <path
      d="M33.2 37.75C33.2 39.15 32.1 40.25 30.7 40.25C29.3 40.25 28.2 39.15 28.2 37.75C28.2 36.35 29.3 35.25 30.7 35.25C32.1 35.25 33.2 36.45 33.2 37.75Z"
      fill="white"
    />
    <path
      d="M33.2 42.15H28.2C27.4 42.15 26.7 42.85 26.7 43.65V45.05C27.8 45.85 29.2 46.35 30.7 46.35C32.2 46.35 33.6 45.85 34.7 45.05V43.65C34.7 42.75 34 42.15 33.2 42.15Z"
      fill="#FFE181"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="88.6563"
        y1="35.7037"
        x2="92.9296"
        y2="26.305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
    </defs>
  </svg>
);

export default MyOrdersIcon;
