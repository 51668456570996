import { useContext } from 'react';

import { UpserviceWidgetContext } from '.';

export const useUpserviceWidgetContext = () => {
  const values = useContext(UpserviceWidgetContext);

  return values;
};

export default useUpserviceWidgetContext;
