import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

export const getAssets = state => state.assets;

export const getAssetsEntries = createSelector(
  getAssets,
  assets => assets.assets.entries
);

export const getCategoriesEntries = createSelector(
  getAssets,
  assets => assets.categories.entries
);

export const getAllCategories = createSelector(
  getAssets,
  assets => assets.categories.all
);

export const getAssetFactory = createSelector(getAssetsEntries, entries =>
  memoize(assetId => entries.find(asset => asset.id === +assetId) || {})
);

export const getAssetsIsLoading = createSelector(
  getAssets,
  assets => assets.isLoading
);

export const getAssetsTotalItems = createSelector(
  getAssets,
  assets => assets.assets.totalItems
);

export const getAssetsManagers = createSelector(
  getAssets,
  assets => assets.managers
);

export const getAssetsFilter = createSelector(
  getAssets,
  assets => assets.filter
);
