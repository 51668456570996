import React from 'react';

export const VisaIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6282 8.01172C13.9258 8.01172 12.4044 8.89984 12.4044 10.5407C12.4044 12.4225 15.1026 12.5525 15.1026 13.4978C15.1026 13.8959 14.6494 14.2522 13.8753 14.2522C12.7767 14.2522 11.9557 13.7543 11.9557 13.7543L11.6044 15.4101C11.6044 15.4101 12.5502 15.8307 13.806 15.8307C15.6672 15.8307 17.1318 14.899 17.1318 13.23C17.1318 11.2416 14.4224 11.1155 14.4224 10.238C14.4224 9.92622 14.7945 9.58457 15.5663 9.58457C16.4372 9.58457 17.1477 9.94666 17.1477 9.94666L17.4916 8.34741C17.4916 8.34741 16.7184 8.01172 15.6282 8.01172ZM0.0529404 8.13242L0.0117188 8.37381C0.0117188 8.37381 0.727917 8.50574 1.37297 8.76891C2.20353 9.07069 2.26269 9.24637 2.40257 9.79202L3.92684 15.7062H5.97012L9.11795 8.13242H7.07935L5.05668 13.2819L4.23131 8.91696C4.15562 8.41739 3.77221 8.13242 3.30289 8.13242H0.0529404ZM9.9377 8.13242L8.33849 15.7062H10.2825L11.8761 8.13242H9.9377ZM20.7799 8.13242C20.3112 8.13242 20.0628 8.38502 19.8805 8.82643L17.0325 15.7062H19.0711L19.4655 14.5596H21.9491L22.189 15.7062H23.9877L22.4185 8.13242H20.7799ZM21.0451 10.1786L21.6493 13.0207H20.0304L21.0451 10.1786Z"
      fill="currentColor"
    />
  </svg>
);

export default VisaIcon;
