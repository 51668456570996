import React from 'react';

export const AssetDeletedIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.0383 16.2815C105.014 17.5161 123.484 22.2405 134.15 36.7922C146.019 53.0395 152.011 75.1964 143.418 93.4025C135.286 110.67 113.327 112.448 95.4006 118.967C75.7458 126.128 56.0746 141.058 37.1936 132.037C16.3372 122.062 2.36154 98.2586 3.77721 75.1634C5.04473 54.2741 26.3621 42.636 43.383 30.5534C56.5191 21.2363 70.9556 15.1621 87.0383 16.2815Z"
      fill="#EBF8F8"
    />
    <path
      d="M64.2229 89.1719L34.0988 53.7473C33.2872 52.7991 32.2501 52.0704 31.083 51.6281C29.9159 51.1858 28.6563 51.0442 27.4201 51.2164C26.1839 51.3885 25.0109 51.8689 24.0091 52.6132C23.0072 53.3575 22.2087 54.3419 21.687 55.4757L2.36147 97.7483C1.80951 98.9578 1.59246 100.293 1.73301 101.615C1.87356 102.937 2.36655 104.197 3.16047 105.264C3.9544 106.33 5.02012 107.164 6.24632 107.678C7.47252 108.192 8.8142 108.367 10.1312 108.185L59.515 101.337C60.8364 101.16 62.0861 100.632 63.1334 99.8068C64.1807 98.9819 64.9871 97.8907 65.4683 96.6474C65.9494 95.404 66.0877 94.0543 65.8686 92.7392C65.6496 91.4242 65.0812 90.1921 64.2229 89.1719Z"
      fill="#F9716C"
    />
    <path
      d="M13.4564 96.201L32.7819 54.0107C32.9433 53.6817 33.1247 53.3628 33.3251 53.056C32.4547 52.295 31.4186 51.7479 30.2993 51.4581C29.1801 51.1684 28.0086 51.144 26.8783 51.387C25.7479 51.6301 24.69 52.1337 23.7887 52.8578C22.8874 53.582 22.1678 54.5066 21.687 55.5581L2.36147 97.7483C1.80951 98.9578 1.59246 100.293 1.73301 101.615C1.87356 102.937 2.36655 104.197 3.16047 105.264C3.9544 106.33 5.02012 107.164 6.24632 107.678C7.47252 108.192 8.8142 108.367 10.1312 108.185L21.2261 106.687C19.9041 106.868 18.5577 106.691 17.3278 106.174C16.0979 105.656 15.0298 104.817 14.2354 103.745C13.441 102.673 12.9496 101.407 12.8125 100.08C12.6754 98.7525 12.8978 97.4128 13.4564 96.201Z"
      fill="#F7413B"
    />
    <path
      d="M33.3582 84.4146L30.7244 84.7767C30.0499 84.8705 29.3657 84.6926 28.8224 84.282C28.2791 83.8714 27.9211 83.2619 27.8272 82.5874L25.6378 66.7352C25.558 66.0685 25.7425 65.3971 26.1517 64.8648C26.561 64.3325 27.1624 63.9816 27.8272 63.8874L30.461 63.5252C31.1355 63.4314 31.8197 63.6094 32.363 64.0199C32.9063 64.4305 33.2643 65.0401 33.3582 65.7146L35.5475 81.5668C35.6273 82.2334 35.4429 82.9049 35.0336 83.4372C34.6244 83.9695 34.023 84.3203 33.3582 84.4146Z"
      fill="white"
    />
    <path
      d="M33.9505 98.3079C34.7163 98.2017 35.4333 97.8703 36.0105 97.3558C36.5876 96.8413 36.9989 96.167 37.1921 95.4184C37.3853 94.6697 37.3516 93.8806 37.0955 93.1511C36.8394 92.4216 36.3722 91.7846 35.7534 91.3211C35.1346 90.8576 34.392 90.5884 33.62 90.5477C32.8479 90.5069 32.0811 90.6966 31.417 91.0924C30.7528 91.4883 30.2213 92.0726 29.8899 92.7711C29.5584 93.4696 29.442 94.2508 29.5554 95.0156C29.7062 96.0329 30.2525 96.9495 31.0756 97.566C31.8987 98.1826 32.9319 98.4492 33.9505 98.3079Z"
      fill="white"
    />
    <path
      d="M118.002 69.2208L113.162 103.789C112.73 106.872 111.091 109.656 108.606 111.529C106.121 113.403 102.993 114.213 99.9109 113.781L50.3296 106.687L56.6178 61.7639L69.4247 57.0889L79.8611 66.6364L100.141 62.5705L118.002 69.2208Z"
      fill="white"
    />
    <path
      d="M113.393 102.078L113.162 103.724C112.73 106.806 111.091 109.59 108.606 111.464C106.121 113.337 102.993 114.148 99.911 113.716L50.3296 106.687C47.2849 106.226 44.5432 104.587 42.6956 102.124C40.848 99.6605 40.0425 96.5696 40.4529 93.5177L41.0949 88.925L98.1661 96.9088L97.7545 99.8718C97.6097 100.904 97.6696 101.955 97.9308 102.964C98.192 103.973 98.6494 104.921 99.2769 105.753C99.9044 106.585 100.69 107.286 101.588 107.815C102.486 108.343 103.48 108.69 104.512 108.835C105.544 108.98 106.595 108.92 107.604 108.659C108.613 108.397 109.561 107.94 110.393 107.313C111.225 106.685 111.926 105.9 112.455 105.002C112.983 104.103 113.33 103.11 113.475 102.078H113.393Z"
      fill="#D9D9D9"
    />
    <path
      d="M123.22 32.0185L118.842 63.229L100.981 56.5787L80.7008 60.7104L70.2644 51.1794L57.4575 55.8379L62.0008 23.4421L123.22 32.0185Z"
      fill="white"
    />
    <path
      d="M88.454 48.6608L65.7375 45.4673C65.3055 45.41 64.914 45.1837 64.6486 44.8381C64.3832 44.4925 64.2657 44.0558 64.3218 43.6237C64.3501 43.4082 64.4208 43.2005 64.5299 43.0125C64.6389 42.8245 64.784 42.66 64.957 42.5284C65.1299 42.3967 65.3272 42.3007 65.5374 42.2457C65.7477 42.1907 65.9667 42.1779 66.1819 42.208L88.9149 45.4015C89.3515 45.4604 89.7468 45.6904 90.0138 46.0408C90.2809 46.3912 90.3978 46.8333 90.3388 47.2699C90.2799 47.7064 90.0499 48.1017 89.6996 48.3688C89.3492 48.6358 88.9071 48.7527 88.4705 48.6938L88.454 48.6608Z"
      fill="#2D9EA3"
    />
    <path
      d="M111.73 43.8377L66.8404 37.5495C66.6242 37.5193 66.4161 37.4467 66.228 37.336C66.0399 37.2253 65.8754 37.0787 65.7439 36.9044C65.6125 36.7302 65.5166 36.5317 65.4619 36.3204C65.4071 36.1091 65.3945 35.8891 65.4247 35.6729C65.455 35.4568 65.5276 35.2487 65.6382 35.0605C65.7489 34.8724 65.8956 34.7079 66.0698 34.5764C66.2441 34.445 66.4425 34.3491 66.6538 34.2944C66.8651 34.2396 67.0852 34.227 67.3013 34.2573L112.191 40.5455C112.628 40.6066 113.022 40.8386 113.288 41.1906C113.553 41.5425 113.668 41.9855 113.607 42.4221C113.546 42.8586 113.314 43.253 112.962 43.5185C112.61 43.784 112.167 43.8988 111.73 43.8377Z"
      fill="#2D9EA3"
    />
    <path
      d="M106.034 51.13L97.6226 49.9448C97.1861 49.8837 96.7916 49.6516 96.5262 49.2997C96.2607 48.9478 96.1459 48.5048 96.207 48.0682C96.2681 47.6316 96.5001 47.2372 96.8521 46.9717C97.204 46.7062 97.647 46.5914 98.0836 46.6525L106.479 47.8213C106.915 47.8802 107.311 48.1102 107.578 48.4606C107.845 48.8109 107.962 49.2531 107.903 49.6896C107.844 50.1262 107.614 50.5215 107.263 50.7885C106.913 51.0556 106.471 51.1725 106.034 51.1135V51.13Z"
      fill="#2D9EA3"
    />
    <path
      d="M76.8979 79.5092C76.6672 81.177 75.9497 82.7397 74.8353 84.0018C73.7209 85.2639 72.2591 86.1694 70.6327 86.6048C69.0063 87.0403 67.2876 86.9864 65.6916 86.45C64.0957 85.9136 62.6934 84.9185 61.6601 83.5891C60.6269 82.2597 60.0087 80.6551 59.8828 78.9762C59.757 77.2972 60.129 75.6184 60.9525 74.1498C61.7759 72.6812 63.0142 71.4881 64.5123 70.7197C66.0105 69.9514 67.7019 69.6419 69.3751 69.83L68.7331 74.3897C67.9639 74.3294 67.1939 74.4957 66.5181 74.8679C65.8424 75.2402 65.2904 75.8021 64.9303 76.4845C64.5702 77.1668 64.4178 77.9396 64.4918 78.7076C64.5659 79.4756 64.8632 80.205 65.3471 80.806C65.8309 81.4069 66.4801 81.853 67.2146 82.0893C67.9491 82.3255 68.7366 82.3415 69.4801 82.1354C70.2236 81.9292 70.8904 81.5098 71.3983 80.9291C71.9061 80.3483 72.2329 79.6315 72.3381 78.8672C72.4488 78.0214 72.2874 77.1624 71.8772 76.4145L75.581 73.6325C76.7173 75.3672 77.1852 77.4554 76.8979 79.5092Z"
      fill="#FFE181"
    />
    <path
      d="M75.5813 73.6325L71.8775 76.4145C71.5856 75.8729 71.1692 75.4085 70.6626 75.0593C70.1561 74.7102 69.5738 74.4863 68.9639 74.4062C68.8879 74.3897 68.8093 74.3897 68.7334 74.4062L69.3754 69.8464H69.6058C70.8141 70.0152 71.9724 70.4404 73.003 71.0933C74.0335 71.7463 74.9126 72.6121 75.5813 73.6325Z"
      fill="#F9716C"
    />
    <path
      d="M72.3381 78.8672C72.2356 79.6366 71.9096 80.3591 71.4006 80.9451C70.8915 81.5311 70.2217 81.9548 69.4742 82.1639C68.7267 82.373 67.9342 82.3582 67.195 82.1213C66.4558 81.8845 65.8024 81.4359 65.3156 80.8313C64.8288 80.2267 64.5301 79.4926 64.4564 78.7199C64.3827 77.9472 64.5374 77.1698 64.9011 76.4841C65.2649 75.7984 65.8219 75.2345 66.503 74.8622C67.1841 74.49 67.9595 74.3257 68.7331 74.3898C68.809 74.3733 68.8876 74.3733 68.9635 74.3898C69.5735 74.4699 70.1557 74.6937 70.6623 75.0429C71.1688 75.392 71.5852 75.8565 71.8772 76.398C72.2908 77.1506 72.4524 78.016 72.3381 78.8672Z"
      fill="#FDA402"
    />
    <path
      d="M106.216 81.0565L83.4825 77.863C83.0459 77.8019 82.6515 77.5698 82.386 77.2179C82.1205 76.866 82.0057 76.423 82.0668 75.9864C82.128 75.5498 82.36 75.1554 82.7119 74.8899C83.0639 74.6244 83.5068 74.5096 83.9434 74.5707L106.66 77.7642C107.089 77.8255 107.476 78.0535 107.738 78.3986C108 78.7438 108.116 79.1783 108.059 79.6079C108.035 79.8247 107.967 80.0345 107.861 80.225C107.755 80.4156 107.612 80.5831 107.44 80.7179C107.269 80.8527 107.072 80.952 106.862 81.0102C106.652 81.0683 106.432 81.084 106.216 81.0565Z"
      fill="#D9D9D9"
    />
    <path
      d="M105.047 89.3366L82.3302 86.1596C82.114 86.1294 81.9059 86.0568 81.7178 85.9461C81.5296 85.8354 81.3651 85.6888 81.2337 85.5145C81.1022 85.3403 81.0064 85.1418 80.9516 84.9305C80.8968 84.7192 80.8842 84.4992 80.9145 84.283C80.9448 84.0669 81.0173 83.8588 81.128 83.6706C81.2387 83.4825 81.3853 83.318 81.5596 83.1866C81.7338 83.0551 81.9323 82.9592 82.1436 82.9045C82.3549 82.8497 82.5749 82.8371 82.7911 82.8674L105.508 86.0444C105.94 86.1056 106.33 86.3359 106.592 86.6847C106.855 87.0336 106.968 87.4723 106.907 87.9045C106.88 88.1214 106.811 88.3309 106.703 88.5208C106.595 88.7107 106.45 88.8771 106.277 89.0104C106.104 89.1437 105.906 89.2412 105.695 89.2972C105.483 89.3533 105.263 89.3666 105.047 89.3366Z"
      fill="#D9D9D9"
    />
    <path
      d="M72.3381 78.8672C72.2356 79.6366 71.9096 80.3591 71.4006 80.9451C70.8915 81.5311 70.2217 81.9548 69.4742 82.1639C68.7267 82.373 67.9342 82.3582 67.195 82.1213C66.4558 81.8845 65.8024 81.4359 65.3156 80.8313C64.8288 80.2267 64.5301 79.4926 64.4564 78.7199C64.3827 77.9472 64.5374 77.1698 64.9011 76.4841C65.2649 75.7984 65.8219 75.2345 66.503 74.8622C67.1841 74.49 67.9595 74.3257 68.7331 74.3898C68.809 74.3733 68.8876 74.3733 68.9635 74.3898C69.5735 74.4699 70.1557 74.6937 70.6623 75.0429C71.1688 75.392 71.5852 75.8565 71.8772 76.398C72.2908 77.1506 72.4524 78.016 72.3381 78.8672Z"
      fill="white"
    />
    <ellipse
      cx="136.875"
      cy="70.9967"
      rx="11.5179"
      ry="12.6395"
      fill="#FEC458"
    />
    <ellipse
      cx="127.983"
      cy="32.3557"
      rx="8.59704"
      ry="9.43427"
      transform="rotate(-14.2317 127.983 32.3557)"
      fill="#FEC458"
    />
    <ellipse cx="136.875" cy="70.6966" rx="7.511" ry="8.24247" fill="#FFE181" />
    <ellipse
      cx="127.928"
      cy="32.1386"
      rx="5.60628"
      ry="6.15225"
      transform="rotate(-14.2317 127.928 32.1386)"
      fill="#FFE181"
    />
  </svg>
);

export default AssetDeletedIcon;
