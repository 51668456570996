import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';

import { usePrevious } from 'hooks';
import { DocumentTitleContext } from './document-title-context';

const createTitle = ({ title, postfix = '' }) => {
  if (!title) {
    return title;
  }

  const clean = str => str.replace(/\n/g, ' ').replace(/\s\s+/g, ' ');

  let cropped = clean(title).slice(0, 50);

  if (cropped.length < clean(title).length) {
    cropped = cropped.concat('...');
  }

  return cropped.concat(postfix);
};

export const DocumentTitle = ({ title, elevation, postfix }) => {
  const { setTitle, removeTitle } = useContext(DocumentTitleContext);

  const fullTitle = createTitle({ title, postfix });

  useEffect(() => {
    if (title) {
      setTitle(fullTitle, elevation);
    }

    return () => {
      removeTitle(fullTitle);
    };
  }, [title]);

  const prevTitle = usePrevious(title);

  useEffect(() => {
    if (!!prevTitle && prevTitle !== title) {
      removeTitle(prevTitle);
    }
  }, [prevTitle, title]);

  return null;
};

DocumentTitle.propTypes = {
  title: PropTypes.string,
  elevation: PropTypes.number,
  postfix: PropTypes.string
};

DocumentTitle.defaultProps = {
  title: undefined,
  elevation: 0,
  postfix: ' - Upservice'
};

export default DocumentTitle;
