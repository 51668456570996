import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import { RestoreProfileIcon } from 'components/common/icons';

import { restoreWorkspace } from 'store/workspace';

import getWorkspaceTitle from 'utils/get-workspace-title';
import useRoutesService from 'services/routes';

import styles from './restore-workspace-modal.module.scss';

const RestoreWorkspaceModal = ({ visible, onClose, workspace }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const routes = useRoutesService({ returnUrl: true });

  const { t } = useTranslation('RestoreCompany');

  const workspaceTitle = getWorkspaceTitle(workspace);

  const restore = async () => {
    try {
      setIsLoading(true);

      await dispatch(restoreWorkspace({ workspaceId: workspace.id }));
      onClose();

      window.location.replace(
        routes.toDefaultPage({ workspaceId: workspace.id })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      width={440}
      visible={visible}
      contentClassName={styles.root}
      closable={false}
    >
      <Icon component={RestoreProfileIcon} />

      <Typography.Title level={1} weight="bold" className={styles.title}>
        {t('RestoreCompanyHeading', { workspaceTitle: workspaceTitle || '' })}
      </Typography.Title>

      <div className={styles.buttons}>
        <Button
          type="secondary"
          mood="negative"
          size="large"
          width="expanded"
          onClick={onClose}
        >
          {t('NoBtn')}
        </Button>

        <Button
          type="primary"
          size="large"
          width="expanded"
          loading={isLoading}
          onClick={restore}
        >
          {t('YesBtn')}
        </Button>
      </div>
    </Modal>
  );
};

RestoreWorkspaceModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    aliasTitle: PropTypes.string
  })
};

RestoreWorkspaceModal.defaultProps = {
  visible: false,
  workspace: {}
};

export default RestoreWorkspaceModal;
