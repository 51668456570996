// eslint-disable-next-line import/prefer-default-export
export const withoutBubbling = (event, submit) => {
  if (event) {
    // sometimes not true, e.g. React Native
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
      // prevent any outer forms from receiving the event too
      event.stopPropagation();
    }
  }

  return submit()(event);
};
