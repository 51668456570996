export default api => ({
  fetch: ({ workspaceId, limit = 1000, offset = 0 }) =>
    api.get(`/v1/${workspaceId}/workspace-controllers/`, {
      params: { limit, offset }
    }),

  create: (workspaceId, assign) =>
    api.post(`/v1/${workspaceId}/workspace-controllers/`, {
      employee: assign.employee,
      type: assign.type || 'all'
    }),

  remove: (workspaceId, employees) =>
    api.patch(`/v1/${workspaceId}/workspace-controllers/${employees.from}/`, {
      employee: employees.to
    })
});
