import React, { createContext, useContext, useEffect, useState } from 'react';

import { GPT_MESSAGES_VIEW } from 'constants/index';

import useGptSocket from './use-gpt-socket';
import useGptActions from './use-gpt-actions';

const GptWidgetContext = createContext();

export const GptWidgetProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [view, setView] = useState(GPT_MESSAGES_VIEW);

  const {
    sendMessage,
    sendPrompt,
    disconnect,
    connect,
    messages,
    setMessages,
    setEntityInfo,
    entityInfo,
    connecting,
    prompts,
    initDialog,
    retrieveMessagesFromDialogs,
    isLoadingMessages
  } = useGptSocket();

  const { clearFilter } = useGptActions();

  const toggleWidget = ({ entityType, entityId, entity }) => {
    setIsOpen(!isOpen);
    setEntityInfo({ entityType, entityId, entity });

    if (isOpen) {
      disconnect();
      setIsCollapse(false);
    }
  };

  const toggleCollapse = () => setIsCollapse(!isCollapse);

  const closeWidget = () => {
    setIsOpen(false);
    setIsCollapse(false);
    setView(GPT_MESSAGES_VIEW);
    clearFilter();
    setEntityInfo(null);
    setMessages([]);

    disconnect();
  };

  useEffect(() => {
    if (isOpen) {
      connect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <GptWidgetContext.Provider
      value={{
        isOpen,
        toggleWidget,
        messages,
        toggleCollapse,
        closeWidget,
        isCollapse,
        sendMessage,
        sendPrompt,
        entityInfo,
        view,
        setView,
        connecting,
        prompts,
        initDialog,
        retrieveMessagesFromDialogs,
        isLoadingMessages
      }}
    >
      {children}
    </GptWidgetContext.Provider>
  );
};

export const useGptWidget = () => useContext(GptWidgetContext);
