import React from 'react';

export const DoubleCheckboxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.88892 11.3898C5.80195 11.4843 5.68847 11.5339 5.57368 11.5382C5.43974 11.5488 5.30278 11.4976 5.20303 11.3855L1.97984 7.76532C1.80252 7.56616 1.80995 7.25019 1.99644 7.05958C2.18292 6.86898 2.47785 6.87591 2.65517 7.07507L5.55332 10.3302L10.8177 4.60964C10.9985 4.4132 11.2938 4.41112 11.4774 4.605C11.6609 4.79888 11.6631 5.1153 11.4823 5.31174L5.88892 11.3898ZM9.27313 11.3898C9.1861 11.4844 9.07251 11.5339 8.95764 11.5382C8.82375 11.5487 8.68686 11.4975 8.58715 11.3855L8.05919 10.7925C7.88187 10.5934 7.8893 10.2774 8.07578 10.0868C8.26227 9.8962 8.55719 9.90313 8.73451 10.1023L8.93749 10.3303L14.2019 4.60964C14.3827 4.4132 14.678 4.41112 14.8616 4.605C15.0451 4.79888 15.0473 5.1153 14.8665 5.31174L9.27313 11.3898Z"
      fill="currentColor"
    />
  </svg>
);

export default DoubleCheckboxIcon;
