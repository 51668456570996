import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/common/button';
import {
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormPhoneInput,
  validateMaxLength,
  validateRequired
} from 'components/common/hook-form';

import { getUserEmail, getUserPhone } from 'store/user';

import styles from './editor.module.scss';

export const Form = ({
  isLoading,
  onSubmit,
  isAfterRegister,
  defaultValues
}) => {
  const methods = useForm({
    defaultValues
  });

  const userEmail = useSelector(getUserEmail, shallowEqual);
  const userPhone = useSelector(getUserPhone, shallowEqual);

  const { t } = useTranslation(['FiilEmployeeProfile', 'Errors']);

  const setRegistrationData = () => {
    methods.setValue('email', userEmail);
    methods.setValue('phone', userPhone);
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPhoneInput
          name="phone"
          label={t('CorporatePhone')}
          rules={{
            required: validateRequired()
          }}
        />

        <FormInput
          type="email"
          name="email"
          label={t('CorporateEmail')}
          rules={{
            required: validateRequired()
          }}
          placeholder={t('CorporateEmail')}
        />

        <FormInput
          name="position"
          label={t('YourPosition')}
          rules={{
            required: validateRequired(),
            maxLength: validateMaxLength(500)
          }}
          placeholder={t('YourPosition')}
        />

        <FormDatePicker
          name="contractDuration"
          label={t('TermOfOffice')}
          minDate={new Date()}
          showTimeSelect={false}
          wrapperClassname={styles.datePicker}
        />

        {isAfterRegister && (
          <FormCheckbox
            name="isRegistrationDataSelect"
            callback={value => {
              if (value) {
                setRegistrationData();
              }
            }}
          >
            {t('UseSignInInfoChckbx')}
          </FormCheckbox>
        )}

        <Button
          type="primary"
          size="large"
          width="expanded"
          htmlType="submit"
          className={styles.submit}
          loading={isLoading}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string,
    contractDuration: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isAfterRegister: PropTypes.bool
};

Form.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isAfterRegister: false
};

export default Form;
