import { TAG_TYPE_ADDRESS, TAG_TYPE_AI_ASSISTANT } from './tag-types';

const GOOGLE_MAP_QUERY = 'https://www.google.com/maps/search/?api=1&query=';
const AI_ASSISTANT_LINK =
  'https://help.upservice.com/articles/124051-%D0%BA%D0%B0%D0%BA-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-ai-%D0%B0%D1%81%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BD%D1%82%D0%B0-%D1%80%D0%B8%D1%87%D0%B0%D1%80%D0%B4%D0%B0-%D0%B4%D0%BB%D1%8F-%D1%83%D1%81%D0%BA%D0%BE%D1%80%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B2%D0%B0%D1%88%D0%B5%D0%B9-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D0%B2-upservice ';

const getGoogleMapLink = name => `${GOOGLE_MAP_QUERY}${name}`;

export const getTagLink = tag => {
  switch (tag.type) {
    case TAG_TYPE_ADDRESS:
      return getGoogleMapLink(tag.name);

    case TAG_TYPE_AI_ASSISTANT:
      return AI_ASSISTANT_LINK;

    default:
      return '';
  }
};
