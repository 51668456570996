import React from 'react';

export const UnknownPersonIcon = ({ style, withCircle = true }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    {...style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {withCircle && <circle cx="16" cy="16.493" r="16" fill="#D8D8D8" />}
    <path
      d="M22.6663 21.993V20.3263C22.6663 19.4422 22.3152 18.5944 21.69 17.9693C21.0649 17.3441 20.2171 16.993 19.333 16.993H12.6663C11.7823 16.993 10.9344 17.3441 10.3093 17.9693C9.6842 18.5944 9.33301 19.4422 9.33301 20.3263V21.993"
      fill="currentColor"
    />
    <path
      d="M16.0003 15.6596C17.8413 15.6596 19.3337 14.1672 19.3337 12.3263C19.3337 10.4853 17.8413 8.99295 16.0003 8.99295C14.1594 8.99295 12.667 10.4853 12.667 12.3263C12.667 14.1672 14.1594 15.6596 16.0003 15.6596Z"
      fill="currentColor"
    />
  </svg>
);

export default UnknownPersonIcon;
