import React, { useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import themeVariables from 'theme';

import FormItem from '../form-item';

const defaultColors = [
  themeVariables.color.green,
  themeVariables.color.yellow,
  themeVariables.color.danube,
  themeVariables.color.magenta,
  themeVariables.color.indigo,
  themeVariables.color.brand
];

export const FormRadioColor = ({
  rules,
  name,
  label,
  itemProps,
  options,
  onClick,
  groupClassName,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const colors = options || defaultColors;

  const getStyleRadioButton = useCallback(
    ({ color, isActive }) => ({
      position: 'relative',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      borderRadius: '50%',
      background: 'transparent',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: color,
      border: isActive ? `2px solid ${color}` : 'none',
      padding: isActive ? 3 : 0,
      backgroundClip: 'content-box',
      lineHeight: 'initial',

      '&::before': {
        display: 'none'
      }
    }),
    []
  );

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        defaultValue={colors[0].value}
        render={({ field }) => {
          const { value, onChange, ...radioProps } = field;

          return (
            <Radio.Group
              value={value}
              onChange={e => onChange(e.target.value)}
              style={{ display: 'flex', alignItems: 'center' }}
              className={classnames('without-border', groupClassName)}
              {...props}
              {...radioProps}
            >
              {colors.map((color, index) => (
                <Radio.Button
                  key={color}
                  value={color}
                  style={getStyleRadioButton({
                    color,
                    isActive: value === color
                  })}
                  autoFocus={index === 0}
                  onClick={() => onClick(color)}
                />
              ))}
            </Radio.Group>
          );
        }}
      />
    </FormItem>
  );
};

FormRadioColor.propTypes = {
  options: PropTypes.array,
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  groupClassName: PropTypes.string
};

FormRadioColor.defaultProps = {
  options: undefined,
  rules: {},
  itemProps: {},
  onClick: () => {},
  groupClassName: undefined
};

export default FormRadioColor;
