import {
  TYPE_CONTACT,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT_COMPANY,
  TYPE_ORDER_STATUS
} from 'constants/index';

export const getEntityData = ({
  statusId,
  orderId,
  contactId,
  taskId,
  assetId,
  companyId,
  entityId,
  entityType
} = {}) => {
  if (statusId) {
    return { entityType: TYPE_ORDER_STATUS, entityId: statusId, orderId };
  }

  if (contactId) {
    return { entityType: TYPE_CONTACT, entityId: contactId };
  }

  if (taskId) {
    return { entityType: TYPE_TASK, entityId: taskId };
  }

  if (assetId) {
    return { entityType: TYPE_ASSET, entityId: assetId };
  }

  if (companyId) {
    return { entityType: TYPE_CONTACT_COMPANY, entityId: companyId };
  }

  if (entityId && entityType) {
    return { entityId, entityType };
  }

  return {};
};

export default getEntityData;
