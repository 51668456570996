import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import styles from './to-chat-select.module.scss';

const Option = ({ option, isInInput }) => {
  const { iconType, iconComponent, text } = option;

  return (
    <div className={styles.toChatSelectOption}>
      {iconComponent && (
        <Icon
          component={iconComponent}
          theme="filled"
          color="black-55"
          className={styles.icon}
          size={isInInput ? 20 : 24}
        />
      )}

      {iconType && (
        <Icon
          type={iconType}
          theme="filled"
          color="black-55"
          className={styles.icon}
          size={isInInput ? 20 : 24}
        />
      )}

      <Typography.Text>{text}</Typography.Text>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    iconType: PropTypes.string,
    iconComponent: PropTypes.func,
    text: PropTypes.string
  })
};

Option.defaultProps = {
  option: {}
};

export default Option;
