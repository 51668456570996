import React from 'react';

export const ProjectIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      d="M4.33936 13.8239H5.9465C6.03489 13.8239 6.10721 13.7516 6.10721 13.6632V4.3418C6.10721 4.2534 6.03489 4.18108 5.9465 4.18108H4.33936C4.25096 4.18108 4.17864 4.2534 4.17864 4.3418V13.6632C4.17864 13.7516 4.25096 13.8239 4.33936 13.8239ZM8.1965 8.19894H9.80364C9.89203 8.19894 9.96436 8.12662 9.96436 8.03823V4.3418C9.96436 4.2534 9.89203 4.18108 9.80364 4.18108H8.1965C8.10811 4.18108 8.03578 4.2534 8.03578 4.3418V8.03823C8.03578 8.12662 8.10811 8.19894 8.1965 8.19894ZM12.0536 9.64537H13.6608C13.7492 9.64537 13.8215 9.57305 13.8215 9.48465V4.3418C13.8215 4.2534 13.7492 4.18108 13.6608 4.18108H12.0536C11.9652 4.18108 11.8929 4.2534 11.8929 4.3418V9.48465C11.8929 9.57305 11.9652 9.64537 12.0536 9.64537ZM16.3929 0.966797H1.60721C1.25163 0.966797 0.964355 1.25407 0.964355 1.60965V16.3954C0.964355 16.7509 1.25163 17.0382 1.60721 17.0382H16.3929C16.7485 17.0382 17.0358 16.7509 17.0358 16.3954V1.60965C17.0358 1.25407 16.7485 0.966797 16.3929 0.966797ZM15.5894 15.5918H2.41078V2.41323H15.5894V15.5918Z"
      fill="currentColor"
    />
  </svg>
);

export default ProjectIcon;
