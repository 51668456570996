import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER,
  TYPE_CONTACT,
  TYPE_ASSET
} from 'constants/index';

import AttachmentsControl, {
  CREATE
} from 'components/common/attachments-control';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import styles from './components.module.scss';

const CreateFile = ({
  fileList,
  onChange,
  attachmentActionsDeps,
  createData,
  className
}) => {
  const { t } = useTranslation('CommonChat');

  return (
    <AttachmentsControl
      fileList={fileList}
      onChange={onChange}
      createData={createData}
      actionsDeps={attachmentActionsDeps}
    >
      <Tooltip
        title={t('CreateFileTip')}
        mouseEnterDelay={0.5}
        modalType={CREATE}
      >
        <Button
          type="text"
          mood="none"
          className={classnames(styles.toolbarBtn, className)}
        >
          <Icon type="file-add" size={16} />
        </Button>
      </Tooltip>
    </AttachmentsControl>
  );
};

CreateFile.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  attachmentActionsDeps: PropTypes.shape({
    statusId: PropTypes.number,
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    orderId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    isPrivate: PropTypes.bool,
    isFromOtherWorkspace: PropTypes.bool
  }),
  createData: PropTypes.shape({
    title: PropTypes.string,
    relation: PropTypes.shape({
      relationId: PropTypes.number,
      relationType: PropTypes.oneOf([
        TYPE_TASK,
        TYPE_ORDER,
        TYPE_CONTACT,
        TYPE_ASSET
      ])
    })
  }),
  className: PropTypes.string
};

CreateFile.defaultProps = {
  fileList: [],
  onChange: () => {},
  attachmentActionsDeps: {},
  createData: {},
  className: undefined
};

export default CreateFile;
