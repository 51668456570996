import { AGREEMENT_SHEET } from 'constants/api';

export default api => ({
  fetch: (workspaceId, sheetId) =>
    api
      .get(`/v1/${workspaceId}/${AGREEMENT_SHEET}/${sheetId}/`)
      .then(({ data }) => data),
  fetchFile: (workspaceId, sheetId) =>
    api
      .get(`/v1/${workspaceId}/${AGREEMENT_SHEET}/${sheetId}/content/`)
      .then(({ data }) => data)
});
