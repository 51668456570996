import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CreateByType from 'components/common/tags/create-by-type';

import { fetchTagsLocal } from 'store/tags';

import CustomSelect from '../custom-select';
import TagOption from '../custom-select/tag-option';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const TagsSelect = ({
  isMulti,
  value,
  onChange,
  allowCreateTag,
  ...props
}) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [visibleDropdownMenu, setVisibleDropdownMenu] = useState(false);

  const { t } = useTranslation('Common');

  const onCreateTag = async createdTag => {
    const tagValue = {
      value: createdTag.id,
      label: createdTag
    };

    setSearch('');

    onChange(isMulti ? [tagValue, ...(value || [])] : tagValue);
  };

  const fetchData = async params => {
    const fetchedData = await dispatch(fetchTagsLocal(params)).then(data => ({
      totalItems: data.total,
      entries: data.data.map(mapValue)
    }));

    setVisibleDropdownMenu(true);
    setSearch(params.search);
    setItems(fetchedData.entries);
    setTotalItems(fetchedData.totalItems);

    return fetchedData;
  };

  const renderContentTop = () => {
    if (
      !allowCreateTag ||
      !search ||
      items.find(
        item =>
          item.label.name.toLowerCase().trim() === search.toLowerCase().trim()
      ) ||
      totalItems > items.length // if searchable tag doesn't contain in items
    ) {
      return null;
    }

    return (
      <CreateByType
        handleTagListDropdown={setVisibleDropdownMenu}
        searchValue={search}
        createCallback={onCreateTag}
      />
    );
  };

  return (
    <CustomSelect
      isAsync
      isSearchable
      isClearable
      placeholder={t('TagsSearch')}
      valueText={t('ChooseTags')}
      fetchData={fetchData}
      Option={TagOption}
      closeMenuOnSelect={!isMulti}
      isMulti={isMulti}
      optionProps={{
        isOption: true
      }}
      visibleDropdownMenu={visibleDropdownMenu}
      renderContentTop={renderContentTop}
      value={value}
      reloadAfterOpen
      onChange={onChange}
      {...props}
    />
  );
};

TagsSelect.propTypes = {
  isMulti: PropTypes.bool,
  allowCreateTag: PropTypes.bool
};

TagsSelect.defaultProps = {
  isMulti: true,
  allowCreateTag: true
};

export default TagsSelect;
