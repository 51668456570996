import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import FormDepartmentSelect from 'components/common/hook-form/select/department';
import {
  FormEmployeeSelect,
  FormInput,
  validateMaxLength,
  validateMinLength,
  validateRequired
} from 'components/common/hook-form';

import { createDepartment } from 'store/team/departments';

import mapValue from 'utils/map-value';

import styles from './styles.module.scss';

const MANAGER_COUNT = 1;

const FormCreateDepartment = ({ onClose }) => {
  const { t } = useTranslation(['Common, Filters, Team']);

  const methods = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const managerWatch = useWatch({ name: 'manager', control: methods.control });
  const employeesWatch = useWatch({
    name: 'employees',
    control: methods.control
  });

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        createDepartment({
          data: {
            ...values,
            manager: values.manager.value,
            employees: values.employees.map(mapValue),
            parentDepartment: values.parentDepartment
              ? values.parentDepartment.value
              : undefined
          },
          employeeCount: values.employees.length + MANAGER_COUNT
        })
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <FormInput
          name="name"
          label={t('CreateDepartmentNameLabel', { ns: 'Team' })}
          rules={{
            required: validateRequired(),
            minLength: validateMinLength(2),
            maxLength: validateMaxLength(512)
          }}
        />

        <FormDepartmentSelect
          name="parentDepartment"
          label={t('DepartmentFilter', { ns: 'Filters' })}
        />

        <FormEmployeeSelect
          name="manager"
          label={t('ManagerDepartmentFilter', { ns: 'Filters' })}
          rules={{
            required: validateRequired()
          }}
          allowSetYourself={false}
          valueText={t('PlaceholdeManagerSelect', { ns: 'Team' })}
          isHideManagers
          params={{
            excludeBench: false,
            exclude: (employeesWatch || []).length
              ? employeesWatch.map(mapValue)
              : undefined
          }}
          departmentsParams={{
            excludeType: undefined
          }}
          reloadAfterOpen
        />

        <FormEmployeeSelect
          isMulti
          name="employees"
          label={t('EmployeesFilter', { ns: 'Filters' })}
          rules={{
            required: validateRequired()
          }}
          allowSetYourself={false}
          valueText={t('PlaceholdeEmployeesSelect', { ns: 'Team' })}
          isHideManagers
          params={{
            excludeBench: false,
            excludeSelf: true,
            exclude: (managerWatch || {}).value
          }}
          departmentsParams={{
            excludeType: undefined
          }}
          reloadAfterOpen
        />

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          width="expanded"
          className={styles.submitBtn}
          disabled={isLoading}
          loading={isLoading}
        >
          {t('Create', { ns: 'Common' })}
        </Button>
      </form>
    </FormProvider>
  );
};

FormCreateDepartment.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default FormCreateDepartment;
