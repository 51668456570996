import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CONTACTS_IMPORT_RESULTS_DRAWER,
  STATUS_IMPORT_CONTACTS_ACCEPTED
} from 'constants/index';

import { ImportContactsIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { setVisibleDrawer } from 'store/drawers';

import styles from '../modals.module.scss';

const ProcessImportContacts = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('ContactImport');

  return (
    <div className={styles.processModal}>
      <ImportContactsIcon />

      <Typography.Title>
        {t('PreviousContactsImportNotReadyHeading')}
      </Typography.Title>

      <Typography.Text color="black-45" className={styles.info}>
        {t(
          data.status === STATUS_IMPORT_CONTACTS_ACCEPTED
            ? 'PreviousContactsImportNotReadyDesc'
            : 'PreviousContactsImportNotAcceptedDesc'
        )}
      </Typography.Text>

      {data.status !== STATUS_IMPORT_CONTACTS_ACCEPTED && (
        <Button
          type="primary"
          className={styles.closeBtn}
          onClick={() => {
            onClose();

            dispatch(
              setVisibleDrawer({
                drawer: CONTACTS_IMPORT_RESULTS_DRAWER,
                data: true
              })
            );
          }}
        >
          {t('ShowResultsBtn')}
        </Button>
      )}
    </div>
  );
};

ProcessImportContacts.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object
};

ProcessImportContacts.defaultProps = {
  onClose: () => {},
  data: {}
};

export default ProcessImportContacts;
