import qs from 'qs';

export default api => ({
  create: (workspaceId, task) =>
    api.post(`/v1/${workspaceId}/tasks/templates/`, task),

  fetch: ({ workspaceId, offset, limit, isScheduler }) =>
    api.get(`/v1/${workspaceId}/tasks/templates/`, {
      params: {
        limit,
        offset,
        is_scheduler: isScheduler
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: ({ workspaceId, id, show400 = true }) =>
    api.get(`/v1/${workspaceId}/tasks/templates/${id}/`, { show400 }),

  update: (workspaceId, id, task) =>
    api.put(`/v1/${workspaceId}/tasks/templates/${id}/`, task),

  share: (workspaceId, id, employeesIds) =>
    api.post(`/v1/${workspaceId}/tasks/templates/${id}/share/`, {
      employeesIds
    }),

  delete: (workspaceId, id) =>
    api.delete(`/v1/${workspaceId}/tasks/templates/${id}/`),

  fetchRelations: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/tasks/templates/${id}/relations/`, {
      params: {
        limit,
        offset
      }
    }),

  createRelation: (workspaceId, id, relation) =>
    api.post(`/v1/${workspaceId}/tasks/templates/${id}/relations/`, relation),

  deleteRelation: (workspaceId, id, relationId) =>
    api.delete(
      `/v1/${workspaceId}/tasks/templates/${id}/relations/${relationId}/`
    ),

  fetchScheduledTaskNextDate: ({
    workspaceId,
    dateEnd,
    dateStart,
    freqType,
    freqInterval,
    weekdays
  }) =>
    api.post(`/v1/${workspaceId}/tasks/templates/scheduler/preview/`, {
      dateEnd,
      dateStart,
      freqType,
      freqInterval,
      weekdays
    })
});
