export default api => ({
  fetchTariffs: () => api.get(`/v1/tariffs/`),

  fetchTopics: () => api.get(`/v1/topics/`),

  fetchAvailableAddons: () => api.get(`/v1/available-addons/`),

  fetchTransactions: ({ workspaceId, limit, offset }) =>
    api.get(`v1/${workspaceId}/transactions/`, {
      params: {
        limit,
        offset
      }
    }),

  retryLastTransaction: ({ workspaceId, cardId = null }) =>
    api.post(`v1/${workspaceId}/transactions/retry-last/`, { card: cardId }),

  addSubscription: ({ workspaceId, tariffId, period, currency, addons }) =>
    api.post(`/v1/${workspaceId}/payments/subscriptions/ `, {
      tariff: tariffId,
      period,
      currency,
      addons
    }),

  changeSubscription: ({ workspaceId, tariffId, period, currency, addons }) =>
    api.post(`/v1/${workspaceId}/payments/subscriptions/change/ `, {
      tariff: tariffId,
      period,
      currency,
      addons
    }),

  cancelSubscription: ({ workspaceId }) =>
    api.post(`/v1/${workspaceId}/payments/subscriptions/cancel/ `),

  fetchPaymentCheckout: ({
    workspaceId,
    tariffId = null,
    period,
    currency,
    addons = null
  }) =>
    api.post(`/v1/${workspaceId}/payments/subscriptions/checkout/ `, {
      tariff: tariffId,
      period,
      currency,
      addons
    }),

  addAddon: ({ workspaceId, addonId, count }) =>
    api.post(`/v1/${workspaceId}/payments/addons/`, {
      addon: addonId,
      count
    }),

  deleteAddon: ({ workspaceId, addonId }) =>
    api.delete(`/v1/${workspaceId}/payments/addons/${addonId}/`),

  fetchAddonCheckout: ({ workspaceId, addonId }) =>
    api.post(`/v1/${workspaceId}/payments/addons/checkout/ `, {
      addon: addonId
    }),

  fetchCards: ({ workspaceId, params = {} }) =>
    api.get(`/v1/${workspaceId}/cards/`, {
      params: {
        limit: 1000,
        ...params
      }
    }),

  addCard: ({ workspaceId }) => api.post(`/v1/${workspaceId}/cards/`),

  deleteCard: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/cards/${id}/`),

  makeCardPrimary: ({ workspaceId, id }) =>
    api.post(`/v1/${workspaceId}/cards/${id}/primary/`),

  fetchAccount: ({ workspaceId }) => api.get(`/v1/${workspaceId}/account/`),

  partialUpdateAccount: ({ workspaceId, account }) =>
    api.patch(`/v1/${workspaceId}/account/`, account),

  fetchCheck: ({ workspaceId, transactionId, isPreview }) =>
    api.post(`/v1/${workspaceId}/transactions/${transactionId}/check/`, {
      preview: isPreview
    }),

  fetchAct: ({ workspaceId, transactionId, isPreview }) =>
    api.post(`/v1/${workspaceId}/transactions/${transactionId}/act/`, {
      preview: isPreview
    })
});
