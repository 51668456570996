import { EMAIL, ITEMS_PER_PAGE, WHATSAPP } from 'constants/index';

import { combineActionsThunk } from 'store/actions-thunk';

import { handleActions } from 'utils/redux-actions';

import {
  fetchChannels,
  fetchChannel,
  createChannel,
  operatorsChannel,
  notifyChannel,
  editChannel,
  deleteChannel,
  setCurrentPage,
  fetchOperatorsChannel,
  fetchEmailChannels,
  updateEmailChannelPassword,
  fetchWhatsAppChannels
} from './actions';

const initialState = {
  isLoading: false,
  isLoaded: false,

  currentPage: 1,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE,

  emailChannels: [],
  whatsAppChannels: [],

  entries: []
};

export default handleActions(
  {
    [fetchChannels.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchChannels.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchChannels.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;
      state.totalItems = payload.count;
      state.entries = payload.results;

      return state;
    },

    [fetchEmailChannels.SUCCEEDED]: (state, { payload }) => {
      state.emailChannels = payload;

      return state;
    },

    [fetchWhatsAppChannels.SUCCEEDED]: (state, { payload }) => {
      state.whatsAppChannels = payload;

      return state;
    },

    [notifyChannel.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      if (index !== -1) {
        const channel = state.entries[index];

        state.entries[index] = { ...channel, notify: payload.value };
      }

      return state;
    },

    [fetchChannel.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      if (index === -1) {
        state.entries = [payload, ...state.entries];
      }

      return state;
    },

    [createChannel.SUCCEEDED]: (state, { payload }) => {
      state.totalItems += 1;
      state.entries = [payload, ...state.entries];

      if (payload.kind === EMAIL) {
        state.emailChannels = [payload, ...state.emailChannels];
      }

      if (payload.kind === WHATSAPP) {
        state.whatsAppChannels = [payload, ...state.whatsAppChannels];
      }

      return state;
    },

    [combineActionsThunk(
      editChannel.SUCCEEDED,
      updateEmailChannelPassword.SUCCEEDED
    )]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      const indexEmailChannels = state.emailChannels.findIndex(
        e => e.id === payload.id
      );
      const indexWhatsAppChannels = state.whatsAppChannels.findIndex(
        e => e.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = { ...state.entries[index], ...payload };

        if (payload.kind === EMAIL) {
          state.emailChannels[indexEmailChannels] = {
            ...state.emailChannels[indexEmailChannels],
            ...payload
          };
        }

        if (payload.kind === WHATSAPP) {
          state.whatsAppChannels[indexWhatsAppChannels] = {
            ...state.whatsAppChannels[indexWhatsAppChannels],
            ...payload
          };
        }
      }

      return state;
    },

    [deleteChannel.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(ch => ch.id !== payload.id);

      if (payload.kind === EMAIL) {
        state.emailChannels = state.emailChannels.filter(
          ch => ch.id !== payload.id
        );
      }

      if (payload.kind === WHATSAPP) {
        state.whatsAppChannels = state.whatsAppChannels.filter(
          ch => ch.id !== payload.id
        );
      }

      state.totalItems -= 1;

      return state;
    },

    [combineActionsThunk(
      operatorsChannel.SUCCEEDED,
      fetchOperatorsChannel.SUCCEEDED
    )]: (state, { payload }) => {
      const index = state.entries.findIndex(e => e.id === payload.id);

      if (index !== -1) {
        state.entries[index].operators = payload.operators;
      }

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    }
  },
  initialState
);
