import { createSelector } from 'reselect';

const _getCategories = state => state.categories;

export const getCategories = createSelector(
  _getCategories,
  categories => categories.entries
);

export const getIsCategoriesLoading = createSelector(
  _getCategories,
  categories => categories.isLoading
);

export const getIsCategoriesLoaded = createSelector(
  _getCategories,
  categories => categories.isLoaded
);

export const getTotalItemsCategories = createSelector(
  _getCategories,
  categories => categories.totalItems
);

export const getRecommendedCategory = createSelector(
  _getCategories,
  categories => categories.recommended
);

export const getHasMoreCategories = createSelector(
  [getCategories, getTotalItemsCategories],
  (categories, totalItems) => totalItems > categories.length
);
