import qs from 'qs';

export default api => ({
  fetch: ({ workspaceId, dates = [], ...params }) =>
    api
      .get(`/v1/${workspaceId}/worklogs-reports/`, {
        params: {
          started_at_gte: dates[0],
          started_at_lte: dates[1],
          ...params
        },
        paramsSerializer(prms) {
          return qs.stringify(prms, { arrayFormat: 'comma' });
        }
      })
      .then(({ data }) => data),

  fetchDetails: ({ workspaceId, employeeId, date }) =>
    api
      .get(`/v1/${workspaceId}/worklogs-reports/details/${employeeId}/${date}/`)
      .then(({ data }) => data),

  export: ({ workspaceId, params }) =>
    api
      .get(`/v1/${workspaceId}/worklogs-reports/xlsx-export/`, {
        responseType: 'arraybuffer',
        params: {
          started_at_gte: params.from,
          started_at_lte: params.to,
          employee: params.employee_id !== '0' ? params.employee_id : undefined,
          project: params.project_id !== '0' ? params.project_id : undefined
        },
        paramsSerializer(prms) {
          return qs.stringify(prms, { arrayFormat: 'comma' });
        }
      })
      .then(({ data }) => data)
});
