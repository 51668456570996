import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection
} from 'lexical';
import { $isLinkNode } from '@lexical/link';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
// import { createPortal } from 'react-dom';
import { $isHeadingNode } from '@lexical/rich-text';
import classnames from 'classnames';
// import Icon from 'components/common/icon';
// import Button from 'components/common/button';й
import { Divider } from 'antd';

import { getSelectedNode } from 'components/common/new-editor/utils';

import BoldItem from './items/bold';
import ItalicItem from './items/italic';
import StrikethroughItem from './items/strikethrough';
import UnorderedListItem from './items/unordered-list';
import OrderedListItem from './items/ordered-list';
import {
  // BLOCK_TYPE_TO_BLOCK_NAME,
  LOW_PRIORITY
  // SUPPORTED_BLOCK_TYPES
} from '../constants';
import LinkItem from './items/link';
// import BlockOptionsDropdownList from './items/block-options-dropdown-list';
import RedoItem from './items/redo';
import UndoItem from './items/undo';

import styles from '../toolbar.module.scss';

export const TopToolbarPlugin = ({ isDisabled }) => {
  const toolbarRef = useRef(null);

  const [editor] = useLexicalComposerContext();

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  // const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
  //   false
  // );
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();

          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          setBlockType(type);
        }
      }

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          LOW_PRIORITY
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          payload => {
            setCanUndo(payload);
            return false;
          },
          LOW_PRIORITY
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          payload => {
            setCanRedo(payload);
            return false;
          },
          LOW_PRIORITY
        )
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  return (
    <div
      className={classnames(styles.toolbar, {
        [styles.isDisabled]: isDisabled
      })}
      ref={toolbarRef}
    >
      {/* {SUPPORTED_BLOCK_TYPES.has(blockType) && ( */}
      {/*  <> */}
      {/*    <Button */}
      {/*      className={styles.toolbarItem} */}
      {/*      onClick={() => */}
      {/*        setShowBlockOptionsDropDown(!showBlockOptionsDropDown) */}
      {/*      } */}
      {/*    > */}
      {/*      <span className={styles.dropdownText}> */}
      {/*        {BLOCK_TYPE_TO_BLOCK_NAME[blockType]} */}
      {/*      </span> */}

      {/*      <Icon type="arrow" size={16} /> */}
      {/*    </Button> */}

      {/*    {showBlockOptionsDropDown && */}
      {/*      createPortal( */}
      {/*        <BlockOptionsDropdownList */}
      {/*          editor={editor} */}
      {/*          blockType={blockType} */}
      {/*          toolbarRef={toolbarRef} */}
      {/*          setShowBlockOptionsDropDown={setShowBlockOptionsDropDown} */}
      {/*        />, */}
      {/*        document.body */}
      {/*      )} */}
      {/*  </> */}
      {/* )} */}

      <BoldItem editor={editor} isBold={isBold} />

      <ItalicItem editor={editor} isItalic={isItalic} />

      <StrikethroughItem editor={editor} isStrikethrough={isStrikethrough} />

      <Divider type="vertical" className={styles.divider} />

      <UnorderedListItem editor={editor} blockType={blockType} />

      <OrderedListItem editor={editor} blockType={blockType} />

      <Divider type="vertical" className={styles.divider} />

      <LinkItem editor={editor} isLink={isLink} />

      <Divider type="vertical" className={styles.divider} />

      <UndoItem editor={editor} canUndo={canUndo} />

      <RedoItem editor={editor} canRedo={canRedo} />
    </div>
  );
};

export default TopToolbarPlugin;
