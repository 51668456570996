export const mapUserToSelectValue = value => {
  if (value) {
    return {
      value: value.id,
      label: {
        title: value.workspaceTitle,
        ...value
      }
    };
  }
  return null;
};

export default mapUserToSelectValue;
