import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { STATUS_CONNECTED } from 'constants/index';

import { editChannel } from 'store/channels';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const CHATPUSH_API_URL = 'msg-farmer.pushsms.ru';

export const useQrCode = ({ modalVisible, onClose, setSuccessedModalData }) => {
  const dispatch = useDispatch();

  const socket = useRef({});

  const [authInfo, setAuthInfo] = useState(null);

  const connectToWebSocket = () => {
    const { token, customerId } = modalVisible.config.chatpushInstance;

    const socketUrl = `wss://${CHATPUSH_API_URL}/socket/websocket?token=${token}&vsn=2.0.0`;
    const ws = new WebSocket(socketUrl);

    const joinCommand = [
      '1',
      '1',
      `room:v1:whats_app:auth:${customerId}`,
      'phx_join',
      {}
    ];

    const heartbeatCommand = ['1', '1', `phoenix`, 'heartbeat', {}];

    ws.onopen = () => {
      ws.send(JSON.stringify(joinCommand));

      // To maintain the connection
      setInterval(() => {
        ws.send(JSON.stringify(heartbeatCommand));
      }, [50000]);
    };

    ws.onmessage = async event => {
      const data = JSON.parse(event.data);

      if (data.length) {
        setAuthInfo(data[data.length - 1]);

        if (data[data.length - 1].auth) {
          const channel = await dispatch(
            editChannel({
              channel: {
                id: modalVisible.id,
                status: STATUS_CONNECTED
              }
            })
          );

          onClose();
          showNoticeMessage({ number: NOTICE_NUMBER.channelConnected });

          setSuccessedModalData(channel);
        }
      }
    };

    socket.current = ws;
  };

  useEffect(() => {
    if (modalVisible) {
      connectToWebSocket();
    }

    return () => {
      if (socket.current.close) {
        socket.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  return {
    data: authInfo
  };
};

export default useQrCode;
