import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  CURRENCY_OPTIONS,
  CURRENCY_USD,
  PERIOD_OPTIONS,
  PERIOD_YEAR
} from 'constants/billing';

import { SkeletonButton } from 'components/common/skeletons';

import { fetchTariffs, fetchTopics } from 'store/billing';

import Topic from './topic';
import Tariff from './tariff';

import styles from './choose-tariff.module.scss';

const ChooseTariffStep = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [period, setPeriod] = useState(PERIOD_YEAR);
  const [currency, setCurrency] = useState(CURRENCY_USD);

  const [data, setData] = useState({
    topics: [],
    tariffs: []
  });
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ChangePlan');

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [{ results: topics }, { results: tariffs }] = await Promise.all([
        dispatch(fetchTopics()),
        dispatch(fetchTariffs())
      ]);
      setData({
        topics,
        tariffs
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <Radio.Group
          value={period}
          onChange={({ target }) => setPeriod(target.value)}
        >
          {PERIOD_OPTIONS.map(p => (
            <Radio.Button key={p.label} value={p.value}>
              {t(p.label)}
              {p.additionLabel && <sup> {t(p.additionLabel)}</sup>}
            </Radio.Button>
          ))}
        </Radio.Group>

        <Radio.Group
          value={currency}
          onChange={({ target }) => setCurrency(target.value)}
        >
          {CURRENCY_OPTIONS.map(c => (
            <Radio.Button key={c.label} value={c.value}>
              {t(c.label)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>

      {isLoading ? (
        <div className={styles.topics}>
          {[...Array(4).keys()].map(key => (
            <SkeletonButton
              key={`skeleton-topic-${key}`}
              className={styles.skeletonTopic}
            />
          ))}
        </div>
      ) : (
        <div className={styles.topics}>
          {data.topics.map(topic => {
            const tariffs = data.tariffs.filter(
              tariff => tariff.topic.id === topic.id
            );

            return (
              <Topic
                key={`topic-${topic.id}`}
                topic={topic}
                tariffCount={tariffs.length}
              >
                <div className={styles.tariffs}>
                  {tariffs.map(tariff => (
                    <Tariff
                      key={`tariff-${tariff.id}`}
                      tariff={tariff}
                      period={period}
                      currency={currency}
                      onSelect={() => onSubmit({ tariff, period, currency })}
                    />
                  ))}
                </div>
              </Topic>
            );
          })}
        </div>
      )}
    </div>
  );
};

ChooseTariffStep.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ChooseTariffStep;
