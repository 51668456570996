import React from 'react';
import PropTypes from 'prop-types';

import { EMAIL_SERVICE_STEP, EMAIL_CHANNEL_STEP } from 'constants/index';

import EmailServiceFrom from 'components/contacts-view/views/integrations/forms/email-service';
import EmailChannelForm from 'components/contacts-view/views/integrations/forms/email-channel';

const EmailStep = ({ currentStep, defaultValues, isLoading, onSubmit }) => {
  const steps = {
    [EMAIL_SERVICE_STEP]: (
      <EmailServiceFrom
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    ),
    [EMAIL_CHANNEL_STEP]: (
      <EmailChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    )
  };

  return <>{steps[currentStep]}</>;
};

EmailStep.propTypes = {
  currentStep: PropTypes.oneOf([EMAIL_SERVICE_STEP, EMAIL_CHANNEL_STEP]),
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

EmailStep.defaultProps = {
  currentStep: EMAIL_SERVICE_STEP,
  defaultValues: {}
};

export default EmailStep;
