import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  VIDEOS,
  CHANNELS_CREATOR_DRAWER,
  WEBSITE,
  WEBSITE_CHANNEL_STEP
} from 'constants/index';

import { getTelegramBotLink } from 'components/contacts-view/views/integrations/utils';

import { setVisibleDrawer } from 'store/drawers';

import CommonModal from '../common';

export const TelegramCreationSuccessedModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['ChannelCreated', 'Toast']);

  const addChat = () => {
    dispatch(
      setVisibleDrawer({
        drawer: CHANNELS_CREATOR_DRAWER,
        data: { type: WEBSITE, step: WEBSITE_CHANNEL_STEP, channel: data }
      })
    );
    onClose();
  };

  const buttons = [
    {
      text: t('AddChatBtn'),
      type: 'link',
      size: 'large',
      onClick: addChat
    },
    {
      text: t('DoneBtn'),
      type: 'primary',
      size: 'large',
      width: 'expanded'
    }
  ];

  return (
    <CommonModal
      visible={visible}
      buttons={buttons}
      codeCopyMessage={t('LinkCopied', { ns: 'Toast' })}
      description={t('TelegramChannelCreatedDesc')}
      link={getTelegramBotLink(data.config.name)}
      videoSlug={VIDEOS.connectTelegramToSiteChat}
      onClose={onClose}
    />
  );
};

TelegramCreationSuccessedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    config: PropTypes.shape({
      name: PropTypes.string
    })
  })
};

TelegramCreationSuccessedModal.defaultProps = {
  data: {
    config: {}
  }
};

export default TelegramCreationSuccessedModal;
