import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export const List = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default memo(List);
