import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import Typography from 'components/common/typography';

import styles from './steps.module.scss';

const Step = ({ step, isCompleted, isNext, isLast }) => {
  const { number, label, ns } = step;

  return (
    <div>
      <div className={classnames(styles.step, { [styles.isNext]: isNext })}>
        <div className={styles.number}>
          <Typography.Text weight="semibold" color="white">
            {number}
          </Typography.Text>
        </div>

        <Typography.Text size="small" color={isNext ? 'black-55' : 'black'}>
          <Translation ns={ns}>{t => t(label)}</Translation>
        </Typography.Text>
      </div>

      {!isLast && (
        <div className={styles.lineWrap} style={{ zIndex: number }}>
          <div
            className={classnames(styles.line, {
              [styles.isCompleted]: isCompleted
            })}
          />
        </div>
      )}
    </div>
  );
};

const Steps = ({ current, steps, className }) => (
  <div className={classnames(styles.root, className)}>
    {steps.map((step, index) => {
      const number = index + 1;

      const isCurrent = index === current;
      const isCompleted = current > index;
      const isLast = steps.length === number;
      const isNext = !isCompleted && !isCurrent;

      return (
        <Step
          key={`step-${step.label}-${index}`}
          step={{ ...step, number }}
          isCompleted={isCompleted}
          isLast={isLast}
          isNext={isNext}
        />
      );
    })}
  </div>
);

Steps.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ns: PropTypes.string
    })
  ).isRequired,
  className: PropTypes.string
};

Steps.defaultProps = {
  current: 0,
  className: undefined
};

export default Steps;
