import qs from 'qs';

import makeCancelableRequest from 'utils/make-cancalable-request';

export const ORDER_STATUSES = 'order-statuses';
export const ORDER_STATUSES_WEB = `${ORDER_STATUSES}/web`;

export default api => ({
  fetch: makeCancelableRequest(
    api,
    (
      fetch,
      workspace,
      contact,
      status,
      offset,
      limit,
      search,
      category,
      employee,
      endDateRange = [],
      counterpart,
      counterpartWorkspace,
      isCustomer,
      access,
      subStatus,
      controller,
      isOutdated,
      ordering,
      paid,
      kind,
      isFavorite,
      asset,
      createDateRange = [],
      startDateRange = [],
      completedDateRange = [],
      members,
      tagsCondition,
      tagsIds,
      order,
      task,
      channelChat
    ) =>
      fetch('get', `/v1/${workspace}/${ORDER_STATUSES_WEB}/`, {
        params: {
          access,
          status,
          sub_status: subStatus,
          offset,
          limit,
          search,
          category,
          employee,
          counterpart,
          counterpart_workspace: counterpartWorkspace,
          date_end_gte: endDateRange[0],
          date_end_lte: endDateRange[1],
          is_customer: isCustomer,
          controller,
          is_outdated: isOutdated,
          ordering,
          paid,
          contact,
          kind,
          is_favorite: isFavorite,
          asset,
          created_at_gte: createDateRange[0],
          created_at_lte: createDateRange[1],
          date_start_gte: startDateRange[0],
          date_start_lte: startDateRange[1],
          completed_at_gte: completedDateRange[0],
          completed_at_lte: completedDateRange[1],
          members,
          tags_condition: tagsCondition,
          tags_ids: tagsIds,
          order,
          task,
          channel_chat: channelChat
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchOne: (statusId, workspaceId, show400 = true) =>
    api
      .get(`/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${statusId}/`, { show400 })
      .then(({ data }) => data),

  fetchOneByOrderId: ({ orderId, workspaceId }) =>
    api
      .get(`/v1/${workspaceId}/${ORDER_STATUSES_WEB}/by-order/${orderId}/`)
      .then(({ data }) => data),

  fetchFilter: (
    workspace,
    filterBy,
    status,
    offset,
    limit,
    search,
    category,
    employee,
    endDateRange = [],
    isCustomer,
    access
  ) =>
    api.get(`/v1/${workspace}/${ORDER_STATUSES}/${filterBy}/`, {
      params: {
        access,
        status,
        offset,
        limit,
        search,
        category,
        employee,
        date_end_gte: endDateRange[0],
        date_end_lte: endDateRange[1],
        is_customer: isCustomer
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  remove: ({ orderStatusId, workspaceId }) =>
    api.delete(`/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${orderStatusId}/`),

  handleFavorite: (order, workspaceId) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${order.id}/favorite-set/`,
      {
        isFavorite: !order.isFavorite
      }
    ),

  createDeal: (workspaceId, contractorStatusId, negotiation) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${contractorStatusId}/deal-set/`,
      {
        negotiation
      }
    ),

  addNegotiation: (workspaceId, id, negotiation) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/negotiations/`,
      negotiation
    ),

  previewSystemSpecification: ({ workspaceId, orderStatusId, specification }) =>
    api
      .post(
        `/v1/${workspaceId}/negotiations/${orderStatusId}/preview/`,
        specification
      )
      .then(({ data }) => data),

  addUsersNegotiation: (workspaceId, id, negotiation) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/users-negotiations/`,
      negotiation
    ),

  fetchSchedulerConfig: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/${ORDER_STATUSES}/${id}/act-scheduler/`),

  addSchedulerAct: (workspaceId, id, config) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/act-scheduler/`,
      config
    ),

  changeSchedulerAct: (workspaceId, statusId, actId, data) =>
    api.put(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${statusId}/order-act/${actId}/`,
      data
    ),

  deleteSchedulerAct: (workspaceId, statusId, actId) =>
    api.delete(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${statusId}/order-act/${actId}/`
    ),

  fetchMessageAttachments: (workspaceId, id, chatId, messageId) =>
    api
      .get(
        `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/chats/${chatId}/messages/${messageId}/attachments/`
      )
      .then(({ data }) => data),

  customerNotify: (workspaceId, id, phone) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${id}/customer-notify/`,
      {
        phone
      }
    ),

  convertExtended: (workspaceId, id) =>
    api
      .put(`/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${id}/convert-extended/`)
      .then(({ data }) => data),

  createWorklog: (workspaceId, id, value) =>
    api.post(`/v1/${workspaceId}/${ORDER_STATUSES}/${id}/worklog/`, value),

  submitNotifySms: (workspaceId, id) =>
    api.post(
      `/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${id}/customer-notify-done/`
    ),

  getOrderInfo: id => api.get(`/v1/orders/${id}/invitation/ `),

  getIsCanSubmitSms: (workspaceId, id) =>
    api.get(
      `/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${id}/customer-notify-done/check/`
    ),

  fetchWorklogList: (workspaceId, id, employeeId) =>
    api.get(`/v1/${workspaceId}/${ORDER_STATUSES}/${id}/worklog/`, {
      params: { employee: employeeId }
    }),

  deleteWorklog: (workspaceId, id, orderStatusId) =>
    api.delete(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/worklog/${orderStatusId}/`
    ),

  updateWorklog: (workspaceId, id, worklogId, value) =>
    api.put(
      `/v1/${workspaceId}/${ORDER_STATUSES}/${id}/worklog/${worklogId}/`,
      value
    ),

  previewAct: ({ workspaceId, id, act }) =>
    api.post(`/v1/${workspaceId}/acts/${id}/preview/`, act)
});
