import { STATUS_DONE, STATUS_REJECTED } from 'constants/tasks';

const NOT_DONE_VALUE = 'not_done';
const NOT_DONE_LABEL = 'JobNotDoneRadio';

const NEW_PROBLEMS_VALUE = 'new_problems';
const NEW_PROBLEMS_LABEL = 'NewIssuesRadio';

const ACCIDENTAL_CLOSING_VALUE = 'accidental_closing';
const ACCIDENTAL_CLOSING_LABEL = 'AccidentallyClosedRadio';

const OTHER_VALUE = 'other';
const OTHER_LABEL = 'OtherRadio';

export const OPTIONS_REASON_FOR_RETURN_ISSUE_MODAL = [
  {
    label: NOT_DONE_LABEL,
    value: NOT_DONE_VALUE,
    ns: 'BackTaskToWorkServiceDesc'
  },
  {
    label: NEW_PROBLEMS_LABEL,
    value: NEW_PROBLEMS_VALUE,
    ns: 'BackTaskToWorkServiceDesc'
  },
  {
    label: ACCIDENTAL_CLOSING_LABEL,
    value: ACCIDENTAL_CLOSING_VALUE,
    ns: 'BackTaskToWorkServiceDesc'
  },
  { label: OTHER_LABEL, value: OTHER_VALUE, ns: 'BackTaskToWorkServiceDesc' }
];

export const BACK_TO_WORK = 'back_to_work';
export const ACCEPT_THE_JOB = 'accept_the_job';

export const ISSUES_STATUSES = [
  {
    id: STATUS_DONE,
    actions: [
      {
        key: 'AcceptTaskBtn',
        ns: 'Task',
        mood: 'positive',
        action: ACCEPT_THE_JOB
      },
      {
        key: 'BackTaskToWorkBtn',
        ns: 'Task',
        mood: 'negative',
        action: BACK_TO_WORK
      }
    ]
  },
  {
    id: STATUS_REJECTED,
    actions: [
      {
        key: 'BackTaskToWorkBtn',
        ns: 'Task',
        mood: 'negative',
        action: BACK_TO_WORK
      }
    ]
  }
];
