import React from 'react';

export const OutdatedIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9949 4.07813C13.0053 4.07879 13.8245 4.91831 13.8264 5.95491L13.8385 12.8557C13.8403 13.8959 13.0186 14.7399 12.0047 14.7393C10.9944 14.7386 10.1751 13.8991 10.1733 12.8625L10.1612 5.96168C10.1594 4.9215 10.9811 4.07746 11.9949 4.07813ZM12.0002 19.9231C13.0164 19.9231 13.8402 19.0993 13.8402 18.0831C13.8402 17.0669 13.0164 16.2431 12.0002 16.2431C10.984 16.2431 10.1602 17.0669 10.1602 18.0831C10.1602 19.0993 10.984 19.9231 12.0002 19.9231Z"
      fill="white"
    />
  </svg>
);

export default OutdatedIcon;
