import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { CALENDAR } from 'constants/index';

import {
  getEmployeeCalendarFilter,
  setEmployeeCalendarFilterView
} from 'store/calendar';

import { useChangedStorageFilter } from 'hooks/common/use-filter-storage';

import useEmployeeRecords from './use-employee-records';

export const useCalendar = () => {
  const dispatch = useDispatch();

  const ref = useRef();
  const { state = {} } = useLocation();

  const [taskData, setTaskData] = useState(null);

  const [dates, setDates] = useState(null);

  const [visibleTaskPreview, setVisibleTaskPreview] = useState(undefined);

  const filter = useSelector(getEmployeeCalendarFilter);
  useChangedStorageFilter(CALENDAR, filter);

  const {
    isLoading,
    records,
    openedRecord,
    onClickRecord
  } = useEmployeeRecords({
    dates,
    employees: filter.employee,
    gettingDataFromStore: true,
    recordId: state.recordId
  });

  const onChangeDates = values => {
    dispatch(setEmployeeCalendarFilterView(values.view.type));
    setDates(values);
  };

  return {
    ref,
    isLoading,
    filter,
    dates,
    visibleTaskPreview,
    taskData,
    records,
    openedRecord,
    onChangeDates,
    setTaskData,
    setVisibleTaskPreview,
    onClickRecord
  };
};

export default useCalendar;
