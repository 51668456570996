import qs from 'qs';

export default api => ({
  createSprint: (workspaceId, value) =>
    api.post(`/v1/${workspaceId}/sprints/`, value),

  fetchOneSprint: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/sprints/${id}/`),

  fetchSprints: ({
    workspaceId,
    project,
    status,
    offset,
    limit,
    search,
    isLag,
    startedDateRange = [],
    endedDateRange = [],
    sprints
  }) =>
    api.get(`/v1/${workspaceId}/sprints/`, {
      params: {
        limit,
        offset,
        project,
        status,
        search,
        is_lag: isLag,
        date_end_gte: endedDateRange[0],
        date_end_lte: endedDateRange[1],
        date_start_gte: startedDateRange[0],
        date_start_lte: startedDateRange[1],
        id: sprints
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  updateSprint: (workspaceId, sprintId, value) =>
    api.put(`/v1/${workspaceId}/sprints/${sprintId}/`, value),

  activateSprint: (workspaceId, sprintId) =>
    api.post(`/v1/${workspaceId}/sprints/${sprintId}/activate/`),

  completeSprint: (workspaceId, sprintId) =>
    api.post(`/v1/${workspaceId}/sprints/${sprintId}/complete/`),

  deleteSprint: (workspaceId, sprintId) =>
    api.delete(`/v1/${workspaceId}/sprints/${sprintId}/`),

  addTasksToSprint: (workspaceId, sprintId, value) =>
    api.post(`/v1/${workspaceId}/sprints/${sprintId}/add-tasks/`, {
      tasks: value
    }),

  sendTasksInWork: (workspaceId, strintId, tasks) =>
    api.put(`/v1/${workspaceId}/sprints/${strintId}/`, { tasks })
});
