import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getAllWorkspaces } from 'store/workspace';

import CustomSelect from '../custom-select';
import WorkspaceOption from '../custom-select/workspace-option';

const mapValue = workspace => ({
  value: workspace.id,
  label: workspace
});

export const WorkspacesSelect = forwardRef(({ filterId, ...props }, ref) => {
  const workspaces = useSelector(getAllWorkspaces);

  const filtredWorkspaces = Object.values(workspaces)
    .filter(({ id }) => id !== filterId)
    .map(mapValue);

  return (
    <CustomSelect
      ref={ref}
      options={filtredWorkspaces}
      showSelectedOptionsOnList={false}
      isSearchable
      Option={optionProps => (
        <WorkspaceOption {...optionProps} withWatNumber={false} />
      )}
      {...props}
    />
  );
});

WorkspacesSelect.propTypes = {
  filterId: PropTypes.number
};

WorkspacesSelect.defaultProps = {
  filterId: null
};

export default WorkspacesSelect;
