import React from 'react';

export const LogoutIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5351 17.8937H19.6521C19.5235 17.8937 19.403 17.9499 19.3226 18.049C19.1351 18.2767 18.9342 18.4963 18.7226 18.7053C17.8572 19.5716 16.832 20.262 15.7039 20.7383C14.5351 21.232 13.2789 21.4852 12.0101 21.483C10.7271 21.483 9.48423 21.2312 8.31637 20.7383C7.18824 20.262 6.16309 19.5716 5.29762 18.7053C4.43061 17.8419 3.73928 16.8185 3.26191 15.6919C2.76637 14.524 2.51727 13.2838 2.51727 12.0008C2.51727 10.7178 2.76905 9.47759 3.26191 8.30974C3.7387 7.18206 4.42441 6.16688 5.29762 5.29634C6.17084 4.42581 7.18602 3.74009 8.31637 3.26331C9.48423 2.77045 10.7271 2.51866 12.0101 2.51866C13.2932 2.51866 14.536 2.76777 15.7039 3.26331C16.8342 3.74009 17.8494 4.42581 18.7226 5.29634C18.9342 5.50795 19.1324 5.72759 19.3226 5.95259C19.403 6.0517 19.5262 6.10795 19.6521 6.10795H21.5351C21.7039 6.10795 21.8083 5.92045 21.7146 5.77849C19.6601 2.58563 16.0655 0.472236 11.9807 0.482951C5.5628 0.499022 0.417267 5.70884 0.481553 12.1187C0.545839 18.4267 5.68334 23.5187 12.0101 23.5187C16.0842 23.5187 19.6628 21.408 21.7146 18.2231C21.8057 18.0812 21.7039 17.8937 21.5351 17.8937ZM23.9164 11.8321L20.1155 8.83206C19.9735 8.71956 19.7673 8.82134 19.7673 9.00081V11.0365H11.3566C11.2387 11.0365 11.1423 11.133 11.1423 11.2508V12.7508C11.1423 12.8687 11.2387 12.9651 11.3566 12.9651H19.7673V15.0008C19.7673 15.1803 19.9762 15.2821 20.1155 15.1696L23.9164 12.1696C23.942 12.1495 23.9627 12.1239 23.9769 12.0947C23.9912 12.0654 23.9986 12.0333 23.9986 12.0008C23.9986 11.9683 23.9912 11.9362 23.9769 11.907C23.9627 11.8777 23.942 11.8521 23.9164 11.8321Z"
      fill="currentColor"
    />
  </svg>
);

export default LogoutIcon;
