import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TESTID_CONTACTS_IMPORT_UPLOAD } from 'constants/tests';

import { FormEmployeeSelect } from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import styles from './form.module.scss';

const MainForm = ({ data, onSubmit, isLoading }) => {
  const methods = useForm();

  const { t } = useTranslation(['ContactImport', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.root}>
        <FormEmployeeSelect
          name="employee"
          dataTestId={TESTID_CONTACTS_IMPORT_UPLOAD}
          label={t('ContractorResponsible')}
          itemProps={{ className: styles.employee }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <Typography.Text className={styles.info}>
          {t('ContactImportTableDesc')}
        </Typography.Text>

        <Table
          className={styles.table}
          dataSource={data.dataSource}
          columns={data.columns}
        />

        <Button
          htmlType="submit"
          className={styles.btnSubmit}
          type="primary"
          size="large"
          loading={isLoading}
        >
          {t('ContinueBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

MainForm.propTypes = {
  data: PropTypes.shape({
    dataSource: PropTypes.array,
    columns: PropTypes.array
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

MainForm.defaultProps = {
  data: {
    dataSource: [],
    columns: []
  },
  onSubmit: () => {},
  isLoading: false
};

export default MainForm;
