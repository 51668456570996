import React from 'react';

export const ImportContactsIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
  >
    <path
      d="M71.4299 137.983C69.7871 137.526 68.1585 136.969 66.5442 136.412C58.7156 133.555 50.4442 130.94 42.6013 128.34L41.3442 127.898C36.0156 126.169 30.8871 124.398 26.2299 122.455C24.5585 121.769 22.9728 121.126 21.4299 120.369C5.90135 113.155 -2.78437 103.755 7.55849 87.2119C8.43563 85.7909 9.10435 84.2514 9.5442 82.6405C11.4442 75.6976 10.3871 66.5548 10.2013 57.4976C10.2013 55.3405 10.2013 53.2119 10.3299 51.0119C10.4522 48.5639 10.7578 46.1285 11.2442 43.7262C12.0014 39.7186 13.5876 35.9135 15.9013 32.5548C17.7062 30.2101 19.8429 28.1407 22.2442 26.4119C26.8871 23.1833 78.9013 -5.71666 117.616 25.6691C119.173 26.9548 156.916 58.5262 144.401 97.0976C132.158 134.469 84.3585 141.598 71.4299 137.983Z"
      fill="#EBF8F8"
    />
    <path
      d="M122.686 38.812V109.469C122.686 114.683 117.743 118.912 111.658 118.912H56.7434C50.6577 118.912 45.7148 114.626 45.7148 109.469V38.812C45.7148 33.5977 50.6577 29.3691 56.7434 29.3691H111.615C117.743 29.3691 122.686 33.5977 122.686 38.812Z"
      fill="white"
    />
    <path d="M128.902 41.9121H81.502V52.3407H128.902V41.9121Z" fill="#F9716C" />
    <path
      d="M118.643 38.812V109.469C118.643 114.683 113.7 118.912 107.615 118.912H52.7004C46.6147 118.912 41.6719 114.626 41.6719 109.469V38.812C41.6719 33.5977 46.6147 29.3691 52.7004 29.3691H107.615C113.7 29.3691 118.643 33.5977 118.643 38.812Z"
      fill="#E2E2E2"
    />
    <path d="M128.902 57.998H81.502V68.4266H128.902V57.998Z" fill="#FEC458" />
    <path
      d="M103.087 22.04H48.716C42.4752 22.04 37.416 27.0992 37.416 33.34V114.94C37.416 121.181 42.4752 126.24 48.716 126.24H103.087C109.328 126.24 114.387 121.181 114.387 114.94V33.34C114.387 27.0992 109.328 22.04 103.087 22.04Z"
      fill="url(#paint0_linear_45129_180014)"
    />
    <path
      d="M45.7162 36.2116C45.7162 36.9694 45.4152 37.6961 44.8794 38.2319C44.3436 38.7678 43.6169 39.0688 42.8591 39.0688H34.8591C34.1013 39.0688 33.3746 38.7678 32.8388 38.2319C32.303 37.6961 32.002 36.9694 32.002 36.2116C32.002 35.4539 32.303 34.7271 32.8388 34.1913C33.3746 33.6555 34.1013 33.3545 34.8591 33.3545H42.8591C43.6169 33.3545 44.3436 33.6555 44.8794 34.1913C45.4152 34.7271 45.7162 35.4539 45.7162 36.2116Z"
      fill="#2B2A36"
    />
    <path
      d="M45.7162 55.1687C45.7162 55.9264 45.4152 56.6532 44.8794 57.189C44.3436 57.7248 43.6169 58.0258 42.8591 58.0258H34.8591C34.1013 58.0258 33.3746 57.7248 32.8388 57.189C32.303 56.6532 32.002 55.9264 32.002 55.1687C32.002 54.4109 32.303 53.6842 32.8388 53.1484C33.3746 52.6125 34.1013 52.3115 34.8591 52.3115H42.8591C43.6169 52.3115 44.3436 52.6125 44.8794 53.1484C45.4152 53.6842 45.7162 54.4109 45.7162 55.1687Z"
      fill="#2B2A36"
    />
    <path
      d="M45.7162 74.1267C45.7162 74.8844 45.4152 75.6112 44.8794 76.147C44.3436 76.6828 43.6169 76.9838 42.8591 76.9838H34.8591C34.1013 76.9838 33.3746 76.6828 32.8388 76.147C32.303 75.6112 32.002 74.8844 32.002 74.1267C32.002 73.3689 32.303 72.6422 32.8388 72.1064C33.3746 71.5706 34.1013 71.2695 34.8591 71.2695H42.8591C43.6169 71.2695 44.3436 71.5706 44.8794 72.1064C45.4152 72.6422 45.7162 73.3689 45.7162 74.1267Z"
      fill="#2B2A36"
    />
    <path
      d="M45.7162 93.0974C45.7162 93.8551 45.4152 94.5819 44.8794 95.1177C44.3436 95.6535 43.6169 95.9545 42.8591 95.9545H34.8591C34.1013 95.9545 33.3746 95.6535 32.8388 95.1177C32.303 94.5819 32.002 93.8551 32.002 93.0974C32.002 92.3396 32.303 91.6129 32.8388 91.0771C33.3746 90.5413 34.1013 90.2402 34.8591 90.2402H42.8591C43.6169 90.2402 44.3436 90.5413 44.8794 91.0771C45.4152 91.6129 45.7162 92.3396 45.7162 93.0974Z"
      fill="#2B2A36"
    />
    <path
      d="M45.7162 112.054C45.7162 112.812 45.4152 113.539 44.8794 114.075C44.3436 114.611 43.6169 114.912 42.8591 114.912H34.8591C34.1013 114.912 33.3746 114.611 32.8388 114.075C32.303 113.539 32.002 112.812 32.002 112.054C32.002 111.297 32.303 110.57 32.8388 110.034C33.3746 109.498 34.1013 109.197 34.8591 109.197H42.8591C43.6169 109.197 44.3436 109.498 44.8794 110.034C45.4152 110.57 45.7162 111.297 45.7162 112.054Z"
      fill="#2B2A36"
    />
    <path
      d="M75.9017 89.7546C88.5964 89.7546 98.8874 79.4636 98.8874 66.7689C98.8874 54.0743 88.5964 43.7832 75.9017 43.7832C63.2071 43.7832 52.916 54.0743 52.916 66.7689C52.916 79.4636 63.2071 89.7546 75.9017 89.7546Z"
      fill="white"
    />
    <path
      d="M89.2013 75.2831L88.7299 75.9259L87.3299 77.5402L87.1585 77.7117C86.7907 78.1537 86.2894 78.4645 85.7299 78.5974C85.6599 78.6176 85.5856 78.6176 85.5156 78.5974H85.3442H85.2156C84.3602 78.6742 83.4996 78.6742 82.6442 78.5974C78.0379 77.7058 73.7383 75.6446 70.1585 72.6117C66.5559 69.9372 63.8066 66.2748 62.2442 62.0688C62.1166 61.3807 62.0497 60.6828 62.0442 59.9831C61.9826 59.1317 62.2369 58.2874 62.7585 57.6116L63.0728 57.2402L64.7728 55.4116C65.088 55.031 65.54 54.7893 66.0316 54.7385C66.5232 54.6877 67.015 54.832 67.4013 55.1402L71.2728 58.2688C71.4673 58.416 71.6306 58.6004 71.7533 58.8113C71.876 59.0222 71.9556 59.2553 71.9875 59.4972C72.0194 59.739 72.0029 59.9848 71.9391 60.2203C71.8753 60.4557 71.7653 60.6762 71.6156 60.8688L69.0442 63.9831C70.3637 65.7401 71.8942 67.3282 73.6013 68.7117C75.6015 70.3351 77.812 71.6807 80.1728 72.7117L82.5299 69.8545C82.84 69.4757 83.2872 69.2347 83.7741 69.1839C84.261 69.133 84.7483 69.2765 85.1299 69.5831L89.0013 72.5545C89.3845 72.8931 89.6199 73.3681 89.6573 73.878C89.6947 74.388 89.531 74.8923 89.2013 75.2831Z"
      fill="#32C9C9"
    />
    <path
      d="M110.672 138.369H118.072C120.258 138.369 122.029 136.597 122.029 134.412V94.8691C122.029 92.6837 120.258 90.912 118.072 90.912H110.672C108.487 90.912 106.715 92.6837 106.715 94.8691V134.412C106.715 136.597 108.487 138.369 110.672 138.369Z"
      fill="#FFE181"
    />
    <path
      d="M131.53 103.54L117.83 81.9831C117.463 81.4018 116.955 80.9228 116.353 80.5909C115.751 80.259 115.075 80.085 114.387 80.085C113.7 80.085 113.024 80.259 112.422 80.5909C111.82 80.9228 111.311 81.4018 110.944 81.9831L97.2302 103.54C96.8382 104.156 96.6191 104.866 96.5958 105.596C96.5726 106.326 96.746 107.049 97.0981 107.689C97.4501 108.328 97.9677 108.862 98.5967 109.233C99.2256 109.604 99.9428 109.799 100.673 109.797H128.087C128.818 109.799 129.535 109.604 130.164 109.233C130.793 108.862 131.31 108.328 131.662 107.689C132.014 107.049 132.188 106.326 132.165 105.596C132.141 104.866 131.922 104.156 131.53 103.54Z"
      fill="#FFE181"
    />
    <path
      d="M103.387 103.54L117.102 81.9829C117.209 81.8128 117.328 81.6505 117.459 81.4972C117.045 81.0149 116.523 80.6367 115.936 80.393C115.349 80.1493 114.713 80.047 114.079 80.0942C113.445 80.1415 112.831 80.337 112.287 80.6651C111.742 80.9932 111.282 81.4446 110.944 81.9829L97.2302 103.54C96.8382 104.156 96.6191 104.866 96.5958 105.596C96.5726 106.326 96.746 107.049 97.0981 107.688C97.4501 108.328 97.9677 108.862 98.5967 109.233C99.2256 109.604 99.9428 109.799 100.673 109.797H106.83C106.1 109.799 105.383 109.604 104.754 109.233C104.125 108.862 103.607 108.328 103.255 107.688C102.903 107.049 102.73 106.326 102.753 105.596C102.776 104.866 102.995 104.156 103.387 103.54Z"
      fill="#FEC458"
    />
    <defs>
      <linearGradient
        id="paint0_linear_45129_180014"
        x1="51.5874"
        y1="55.6829"
        x2="112.63"
        y2="102.011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default ImportContactsIcon;
