import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FormCheckbox } from 'components/common/hook-form';
import FormTimePicker from 'components/common/hook-form/time-picker';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import { daysTranslations } from './constants';
import {
  getDisabledHoursEnd,
  getDisabledHoursStart,
  getDisabledMinutesEnd,
  getDisabledMinutesStart,
  validateStartMinutes
} from './utils';

import styles from './operator-hours.module.scss';

const OperatorHoursForm = ({ isOnlyView }) => {
  const { t } = useTranslation(['ConnectWidget', 'Common']);

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const scheduleWatch = watch('scheduleConfig.schedule');
  const daysWatch = watch('scheduleConfig.days');

  const addInterval = day => {
    setValue('scheduleConfig.schedule', {
      ...scheduleWatch,
      [day]: [...scheduleWatch[day], { startTime: '09:00', endTime: '18:00' }]
    });
  };

  const removeInterval = (day, index) => {
    const updatedIntervals = scheduleWatch[day].filter((_, i) => i !== index);

    setValue(`scheduleConfig.schedule.${day}`, updatedIntervals);
  };

  const handleTimeChange = (day, intervalIndex, field, value) => {
    const updatedIntervals = scheduleWatch[day].map((interval, i) =>
      i === intervalIndex
        ? { ...interval, [field]: moment(value, 'hmm').format('HH:mm') }
        : interval
    );

    setValue(`scheduleConfig.schedule.${day}`, updatedIntervals);
  };

  return (
    <div className={styles.container}>
      {Object.entries(scheduleWatch).map(([day, intervals], i) => (
        <div key={`${day}-${i}`} className={styles.day}>
          <FormCheckbox
            name={`scheduleConfig.days.${day}`}
            className={styles.checkbox}
            itemProps={{
              className: styles.rootCheckbox
            }}
            disabled={isOnlyView}
          >
            {t(daysTranslations[day])}
          </FormCheckbox>

          <div className={styles.timepickerWrap}>
            {intervals.map((interval, index) => (
              <div key={index} className={styles.rowTimepicker}>
                <div
                  className={classnames(styles.timepicker, {
                    [styles.mb32]:
                      errors.scheduleConfig &&
                      errors.scheduleConfig.schedule &&
                      errors.scheduleConfig.schedule[day][index]
                  })}
                >
                  <FormTimePicker
                    name={`scheduleConfig.schedule.${day}.${index}.startTime`}
                    format="HH:mm"
                    minuteStep={15}
                    placeholder={t('Time', { ns: 'Common' })}
                    suffixIcon={<></>}
                    itemProps={{
                      className: styles.inputTimepicker,
                      errorTextClassName: styles.errorMessage
                    }}
                    onChange={value =>
                      handleTimeChange(day, index, 'startTime', value)
                    }
                    clearIcon={<></>}
                    disabledHours={() =>
                      getDisabledHoursStart(scheduleWatch, day, index)
                    }
                    disabledMinutes={selectedHour =>
                      getDisabledMinutesStart(
                        scheduleWatch,
                        day,
                        index,
                        selectedHour
                      )
                    }
                    disabled={!daysWatch[day] || isOnlyView}
                    rules={{
                      validate: () =>
                        daysWatch[day]
                          ? validateStartMinutes(scheduleWatch, day, index)
                          : undefined
                    }}
                  />

                  <Icon type="arrow-right" size={16} color="black-55" />

                  <FormTimePicker
                    name={`scheduleConfig.schedule.${day}.${index}.endTime`}
                    placeholder={t('Time', { ns: 'Common' })}
                    format="HH:mm"
                    minuteStep={15}
                    itemProps={{
                      className: styles.inputTimepicker
                    }}
                    onChange={value =>
                      handleTimeChange(day, index, 'endTime', value)
                    }
                    clearIcon={<></>}
                    disabledHours={() =>
                      getDisabledHoursEnd(scheduleWatch, day, index)
                    }
                    disabledMinutes={selectedHour =>
                      getDisabledMinutesEnd(
                        scheduleWatch,
                        day,
                        index,
                        selectedHour
                      )
                    }
                    disabled={!daysWatch[day] || isOnlyView}
                  />
                </div>

                {index === 0 && (
                  <Tooltip
                    placement="topRight"
                    title={t('AddIntervalTip')}
                    mouseEnterDelay={0.5}
                    arrowPointAtCenter
                  >
                    <Button
                      type="text"
                      className={styles.addIntervalBtn}
                      onClick={() => addInterval(day)}
                      disabled={
                        scheduleWatch[day].length > 4 ||
                        !daysWatch[day] ||
                        isOnlyView
                      }
                    >
                      <Icon type="plus" color="brand" />
                    </Button>
                  </Tooltip>
                )}

                {intervals.length > 1 && index !== 0 && (
                  <Tooltip
                    placement="topRight"
                    title={t('DeleteIntervalTip')}
                    mouseEnterDelay={0.5}
                    arrowPointAtCenter
                  >
                    <Button
                      type="text"
                      className={styles.addIntervalBtn}
                      onClick={() => removeInterval(day, index)}
                      disabled={!daysWatch[day] || isOnlyView}
                    >
                      <Icon type="close" color="black-55" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

OperatorHoursForm.propTypes = {
  isOnlyView: PropTypes.bool
};

OperatorHoursForm.defaultProps = {
  isOnlyView: false
};

export default OperatorHoursForm;
