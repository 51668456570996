import React from 'react';

export const NoAccessIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.5499 135.783C90.7933 135.884 89.023 135.92 87.2251 135.884C78.5109 135.709 69.4619 135.984 60.7844 136.113L59.4084 136.136C53.5516 136.214 47.8781 136.209 42.6037 135.943C40.7371 135.851 38.9346 135.728 37.1734 135.558C19.2864 133.866 7.57261 127.935 12.159 109.232C12.159 109.232 12.159 109.204 12.159 109.195C12.5211 107.606 12.6433 105.972 12.5214 104.347C12.0627 97.3071 7.90742 89.2121 4.6052 80.7776C3.83009 78.7917 3.10085 76.7829 2.48168 74.7694C1.74922 72.4523 1.1987 70.0816 0.835152 67.6788C0.195049 63.7089 0.474342 59.645 1.65154 55.7999C2.62172 53.0304 4.02586 50.4327 5.81143 48.1039C9.34756 43.545 50.7951 0.120723 99.7094 16.7924C101.714 17.4758 149.729 34.6473 150.49 74.3933C151.256 113.084 106.484 134.998 92.5499 135.783Z"
      fill="#EBF8F8"
    />
    <path
      d="M19.1646 33.6387H132.647C133.863 33.6387 135.03 34.1219 135.89 34.982C136.75 35.8421 137.233 37.0087 137.233 38.2251V106.678H14.5781V38.2251C14.5781 37.0087 15.0613 35.8421 15.9215 34.982C16.7816 34.1219 17.9482 33.6387 19.1646 33.6387Z"
      fill="#D9D9D9"
    />
    <path
      d="M139.319 111.81L12.5041 111.81C12.0427 111.81 11.5859 111.719 11.1597 111.542C10.7334 111.366 10.3461 111.107 10.0199 110.781C9.69365 110.454 9.43488 110.067 9.25833 109.641C9.08177 109.215 8.99089 108.758 8.99089 108.296V106.003L142.832 106.003V108.296C142.832 109.228 142.462 110.122 141.803 110.781C141.144 111.439 140.251 111.81 139.319 111.81Z"
      fill="#B1B1B1"
    />
    <path
      d="M132.112 38.0967H19.7031V102.215H132.112V38.0967Z"
      fill="#F6F6F6"
    />
    <path
      d="M71.2511 81.6952C68.1375 84.5636 64.7934 87.1714 61.2527 89.4921C58.3814 91.372 55.3874 93.0575 52.2908 94.5372C45.6702 97.6843 38.6508 99.9125 31.4272 101.16L19.3465 74.7559L11.6367 57.9008C11.6367 57.9008 36.4676 54.2041 44.659 39.9541C47.4044 46.3088 50.5715 52.473 54.1391 58.4053C59.1103 66.6813 64.8388 74.478 71.2511 81.6952Z"
      fill="white"
    />
    <path
      d="M68.5264 74.7971C65.6687 77.9298 62.5561 80.8199 59.2205 83.4379C56.5257 85.5595 53.6893 87.4949 50.7311 89.2306C44.4075 92.9374 37.6072 95.7628 30.5187 97.6283L16.2182 72.3663L7.08203 56.2358C7.08203 56.2358 31.5002 50.4111 38.4257 35.5098C41.7131 41.6081 45.4026 47.481 49.4698 53.0895C55.1365 60.8996 61.516 68.1667 68.5264 74.7971Z"
      fill="white"
    />
    <path
      d="M21.3438 65.5047C21.1904 65.5055 21.0411 65.455 20.9197 65.3612C20.7982 65.2673 20.7117 65.1356 20.6737 64.987C20.6358 64.8383 20.6486 64.6812 20.7102 64.5407C20.7718 64.4001 20.8785 64.2842 21.0136 64.2114L39.2996 54.1992C39.4582 54.1259 39.6388 54.1157 39.8046 54.1707C39.9704 54.2256 40.1091 54.3416 40.1926 54.4951C40.2761 54.6486 40.298 54.828 40.254 54.9971C40.2099 55.1662 40.1032 55.3121 39.9555 55.4054L21.674 65.4176C21.5737 65.4757 21.4597 65.5058 21.3438 65.5047Z"
      fill="#6B6A72"
    />
    <path
      d="M23.6531 69.7109C23.4993 69.7127 23.3493 69.6629 23.2271 69.5695C23.1049 69.476 23.0175 69.3443 22.979 69.1954C22.9404 69.0465 22.9529 68.8889 23.0144 68.7479C23.0759 68.6069 23.1829 68.4906 23.3183 68.4175L41.6044 58.4054C41.6842 58.3514 41.7744 58.3147 41.8692 58.2978C41.964 58.2809 42.0613 58.2841 42.1548 58.3071C42.2483 58.3302 42.3359 58.3726 42.412 58.4317C42.488 58.4908 42.5509 58.5651 42.5964 58.65C42.6419 58.7349 42.669 58.8284 42.6761 58.9244C42.6832 59.0205 42.6701 59.1169 42.6375 59.2076C42.605 59.2982 42.5538 59.381 42.4872 59.4506C42.4206 59.5202 42.3402 59.575 42.2511 59.6116L23.9834 69.6238C23.8827 69.6809 23.7689 69.7109 23.6531 69.7109Z"
      fill="#6B6A72"
    />
    <path
      d="M26.6557 74.3153C26.5325 74.3158 26.4115 74.2828 26.3056 74.2198C26.1998 74.1567 26.1131 74.0661 26.0548 73.9576C25.9722 73.7994 25.9542 73.6154 26.0047 73.4442C26.0552 73.2731 26.1702 73.1283 26.3254 73.0403L44.6253 62.9914C44.7858 62.9039 44.9746 62.8836 45.1501 62.9352C45.3256 62.9869 45.4733 63.1061 45.5609 63.2666C45.6485 63.4272 45.6687 63.616 45.6171 63.7914C45.5655 63.9669 45.4463 64.1147 45.2857 64.2022L26.9996 74.2328C26.8938 74.289 26.7755 74.3174 26.6557 74.3153Z"
      fill="#6B6A72"
    />
    <path
      d="M28.9722 78.5216C28.8203 78.5205 28.673 78.4692 28.5533 78.3756C28.4336 78.2821 28.3483 78.1515 28.3106 78.0044C28.2729 77.8572 28.2849 77.7017 28.3449 77.5622C28.4048 77.4226 28.5093 77.3068 28.6419 77.2328L46.928 67.2206C47.0874 67.14 47.2718 67.1245 47.4424 67.1774C47.613 67.2303 47.7563 67.3474 47.8421 67.5041C47.9278 67.6607 47.9493 67.8446 47.902 68.0168C47.8547 68.189 47.7422 68.336 47.5885 68.4268L29.3024 78.439C29.2009 78.4936 29.0874 78.5219 28.9722 78.5216Z"
      fill="#6B6A72"
    />
    <path
      d="M123.952 49.6222C123.515 49.4988 123.117 49.2654 122.796 48.9442C122.474 48.623 122.241 48.2248 122.118 47.7876C122.111 47.7671 122.098 47.7492 122.081 47.7364C122.064 47.7237 122.043 47.7168 122.021 47.7168C122 47.7168 121.979 47.7237 121.961 47.7364C121.944 47.7492 121.931 47.7671 121.925 47.7876C121.803 48.2254 121.57 48.6243 121.248 48.9456C120.927 49.267 120.528 49.5 120.09 49.6222C120.07 49.6286 120.052 49.6414 120.039 49.6587C120.026 49.6761 120.02 49.697 120.02 49.7185C120.02 49.74 120.026 49.761 120.039 49.7783C120.052 49.7956 120.07 49.8084 120.09 49.8148C120.528 49.937 120.927 50.17 121.248 50.4914C121.57 50.8128 121.803 51.2116 121.925 51.6494C121.931 51.6699 121.944 51.6879 121.961 51.7006C121.979 51.7134 122 51.7202 122.021 51.7202C122.043 51.7202 122.064 51.7134 122.081 51.7006C122.098 51.6879 122.111 51.6699 122.118 51.6494C122.241 51.2122 122.474 50.814 122.796 50.4928C123.117 50.1716 123.515 49.9382 123.952 49.8148C123.973 49.8084 123.991 49.7956 124.003 49.7783C124.016 49.761 124.023 49.74 124.023 49.7185C124.023 49.697 124.016 49.6761 124.003 49.6587C123.991 49.6414 123.973 49.6286 123.952 49.6222Z"
      fill="#FCC977"
    />
    <path
      d="M120.68 43.8935C120.016 43.7083 119.412 43.3552 118.925 42.8683C118.438 42.3814 118.085 41.7772 117.9 41.1141C117.894 41.0802 117.876 41.0495 117.85 41.0274C117.823 41.0053 117.79 40.9932 117.756 40.9932C117.721 40.9932 117.688 41.0053 117.662 41.0274C117.635 41.0495 117.617 41.0802 117.611 41.1141C117.426 41.7772 117.073 42.3814 116.586 42.8683C116.099 43.3552 115.495 43.7083 114.832 43.8935C114.798 43.8995 114.767 43.9173 114.745 43.9437C114.723 43.9701 114.711 44.0035 114.711 44.0379C114.711 44.0724 114.723 44.1057 114.745 44.1321C114.767 44.1585 114.798 44.1763 114.832 44.1824C115.495 44.3676 116.099 44.7207 116.586 45.2076C117.073 45.6944 117.426 46.2986 117.611 46.9618C117.617 46.9957 117.635 47.0264 117.662 47.0485C117.688 47.0706 117.721 47.0827 117.756 47.0827C117.79 47.0827 117.823 47.0706 117.85 47.0485C117.876 47.0264 117.894 46.9957 117.9 46.9618C118.086 46.2995 118.439 45.6962 118.926 45.2102C119.413 44.7242 120.017 44.3718 120.68 44.187C120.712 44.1788 120.741 44.1599 120.762 44.1333C120.783 44.1067 120.794 44.0739 120.794 44.0402C120.794 44.0065 120.783 43.9738 120.762 43.9472C120.741 43.9206 120.712 43.9017 120.68 43.8935Z"
      fill="#FCC977"
    />
    <path
      d="M139.295 72.3677L103.348 63.0107L96.3452 89.9127L132.293 99.2696L139.295 72.3677Z"
      fill="#2D9EA3"
    />
    <path
      d="M136.271 74.3386L105.023 66.2051L99.3647 87.945L130.612 96.0785L136.271 74.3386Z"
      fill="white"
    />
    <path
      d="M100.286 81.6447L112.192 76.4941L120.113 86.6439L126.997 84.3094L133.001 92.3081L132.024 97.3349L98.043 88.9876L100.286 81.6447Z"
      fill="#2D9EA3"
    />
    <path
      d="M123.854 78.48C124.824 78.48 125.611 77.6935 125.611 76.7234C125.611 75.7533 124.824 74.9668 123.854 74.9668C122.884 74.9668 122.098 75.7533 122.098 76.7234C122.098 77.6935 122.884 78.48 123.854 78.48Z"
      fill="#2D9EA3"
    />
    <path
      d="M113.441 53.3603L113.01 50.8056L110.529 50.0856C110.364 50.0351 106.594 48.9527 96.1413 46.2788C95.6826 46.1642 95.1781 46.0403 94.6736 45.9073C82.3912 42.784 76.6994 41.6511 76.4609 41.6052L75.6583 41.4355L74.8419 41.6007C74.608 41.6419 68.9208 42.8161 56.6613 46.0266C45.1081 49.049 41.0078 50.2598 40.8473 50.3149L38.3614 51.067L37.9578 53.6217C37.8707 54.1308 36.0132 66.4637 41.4986 88.6849C45.1677 103.536 55.1432 113.91 65.0774 119.932C68.0816 121.766 71.2736 123.273 74.5988 124.427L75.9472 124.886L77.2819 124.427C89.4818 120.175 105.41 108.205 110.134 88.4556C115.473 66.1702 113.524 53.8602 113.441 53.3603Z"
      fill="url(#paint0_linear_66480_416878)"
    />
    <path
      d="M75.9326 122.386L75.3593 122.193C63.6868 118.217 48.414 106.902 43.7771 88.1113C38.4064 66.3395 40.1951 54.479 40.2731 53.9837L40.452 52.9013L41.5114 52.5848C41.6811 52.5343 45.7447 51.3281 57.2567 48.3194C69.4611 45.1089 75.0795 43.9669 75.3134 43.921L75.662 43.8477L76.0106 43.9165C76.2445 43.9623 81.872 45.086 94.0948 48.191C105.63 51.1217 109.689 52.3188 109.868 52.3463L110.932 52.6582L111.115 53.7498C111.198 54.2451 113.073 66.0918 107.854 87.9049C103.346 106.709 88.1325 118.143 76.5059 122.202L75.9326 122.386ZM43.6258 55.6531C43.3368 58.9966 42.9057 69.9444 47.1894 87.2995C51.409 104.402 65.1591 114.818 75.9096 118.684C86.6281 114.749 100.305 104.233 104.41 87.1023C108.583 69.6738 108.079 58.7673 107.753 55.4559C105.854 54.9331 101.392 53.7222 93.2142 51.6446C82.7664 48.9936 77.07 47.7782 75.6574 47.4847C74.2539 47.7874 68.5622 49.0441 58.1372 51.7684C49.9872 53.8507 45.5337 55.089 43.6258 55.6531Z"
      fill="white"
    />
    <path
      d="M91.4853 76.6907V93.8669C91.4853 94.475 91.2437 95.0583 90.8137 95.4884C90.3836 95.9185 89.8003 96.1601 89.1921 96.1601H62.1643C61.5583 96.1529 60.9792 95.909 60.5507 95.4805C60.1221 95.052 59.8782 94.4728 59.8711 93.8669V76.6907C59.8711 76.0825 60.1127 75.4992 60.5428 75.0691C60.9728 74.6391 61.5561 74.3975 62.1643 74.3975H89.1738C89.7794 74.4058 90.3579 74.65 90.7861 75.0783C91.2144 75.5066 91.4587 76.0851 91.467 76.6907H91.4853Z"
      fill="#FCC977"
    />
    <path
      d="M83.307 69.6973V74.3801H81.0138V69.6973C81.0348 68.9822 80.912 68.2701 80.6528 67.6032C80.3936 66.9364 80.0032 66.3283 79.5047 65.8151C79.0063 65.3018 78.4099 64.8938 77.751 64.6151C77.092 64.3365 76.3838 64.1929 75.6683 64.1929C74.9529 64.1929 74.2447 64.3365 73.5857 64.6151C72.9267 64.8938 72.3304 65.3018 71.8319 65.8151C71.3335 66.3283 70.9431 66.9364 70.6839 67.6032C70.4247 68.2701 70.3019 68.9822 70.3229 69.6973V74.3801H68.0296V69.6973C68.0047 68.6784 68.1838 67.6649 68.5564 66.7163C68.9291 65.7676 69.4878 64.9032 70.1996 64.1737C70.9113 63.4443 71.7619 62.8646 72.7011 62.4688C73.6403 62.073 74.6492 61.8691 75.6683 61.8691C76.6875 61.8691 77.6964 62.073 78.6356 62.4688C79.5748 62.8646 80.4253 63.4443 81.1371 64.1737C81.8489 64.9032 82.4076 65.7676 82.7802 66.7163C83.1529 67.6649 83.332 68.6784 83.307 69.6973Z"
      fill="#6B6A72"
    />
    <path
      d="M91.4845 76.6911V93.8673C91.4845 94.4755 91.2429 95.0587 90.8129 95.4888C90.3828 95.9189 89.7995 96.1605 89.1913 96.1605H62.1635C61.5898 96.1612 61.0367 95.9468 60.6133 95.5596L90.7048 74.9482C90.9524 75.1657 91.1501 75.4339 91.2847 75.7347C91.4192 76.0354 91.4874 76.3616 91.4845 76.6911Z"
      fill="#FDA402"
    />
    <path
      d="M72.9805 89.4548L73.8978 85.171C73.5685 84.826 73.3706 84.3765 73.3382 83.9006C73.3641 83.3311 73.6086 82.7936 74.0208 82.3997C74.433 82.0059 74.9811 81.7861 75.5512 81.7861C76.1212 81.7861 76.6694 82.0059 77.0815 82.3997C77.4937 82.7936 77.7382 83.3311 77.7641 83.9006C77.742 84.371 77.5591 84.8195 77.2458 85.171L78.4016 89.4548H72.9805Z"
      fill="#6B6A72"
    />
    <defs>
      <linearGradient
        id="paint0_linear_66480_416878"
        x1="37.5863"
        y1="83.1537"
        x2="113.854"
        y2="83.1537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoAccessIcon;
