import createActionThunk from '../actions-thunk';

import api from '../../api';
import { getCitiesItemsPerPage, getCitiesCurrentPage } from './selectors';

// eslint-disable-next-line import/prefer-default-export
export const fetchCities = createActionThunk('cities/fetch', ({ getState }) => {
  const state = getState();
  const currentPage = getCitiesCurrentPage(state);
  const itemsPerPage = getCitiesItemsPerPage(state);
  const offset = itemsPerPage * (currentPage - 1);

  return api.cities.fetch('', offset, itemsPerPage);
});
