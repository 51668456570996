import React from 'react';

export const SafetyIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 3.75L10 1.25L17.5 3.75V11.25C17.5 15.3926 14.1426 18.75 10 18.75C5.85742 18.75 2.5 15.3926 2.5 11.25V3.75Z"
      fill="currentColor"
    />
    <path
      d="M7.16222 9.12399C7.24752 9.15944 7.32498 9.21139 7.39015 9.27686L9.43312 11.3198L13.2749 7.47998C13.3366 7.41822 13.4098 7.36923 13.4904 7.33579C13.571 7.30236 13.6574 7.28516 13.7446 7.28516C13.8319 7.28516 13.9183 7.30236 13.9989 7.33579C14.0795 7.36923 14.1527 7.41822 14.2144 7.47998L14.2691 7.53467C14.3308 7.59631 14.3798 7.66953 14.4132 7.75013C14.4467 7.83073 14.4639 7.91714 14.4639 8.0044C14.4639 8.09165 14.4467 8.17806 14.4132 8.25866C14.3798 8.33926 14.3308 8.41248 14.2691 8.47412L9.90382 12.8394C9.84218 12.9011 9.76896 12.9501 9.68836 12.9835C9.60776 13.017 9.52136 13.0342 9.4341 13.0342C9.34684 13.0342 9.26043 13.017 9.17983 12.9835C9.09923 12.9501 9.02601 12.9011 8.96437 12.8394L6.39601 10.271C6.33054 10.2058 6.27859 10.1284 6.24315 10.0431C6.2077 9.95777 6.18945 9.8663 6.18945 9.77393C6.18945 9.68155 6.2077 9.59009 6.24315 9.50479C6.27859 9.41949 6.33054 9.34203 6.39601 9.27686C6.46118 9.21139 6.53864 9.15944 6.62394 9.12399C6.70924 9.08855 6.80071 9.0703 6.89308 9.0703C6.98545 9.0703 7.07692 9.08855 7.16222 9.12399Z"
      fill="white"
    />
  </svg>
);

export default SafetyIcon;
