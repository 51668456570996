import React from 'react';

export const IdeaIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1876 0C12.9622 0 13.6248 0.275074 14.1747 0.825119C14.7247 1.37516 14.9998 2.03778 14.9998 2.81248V12.1874C14.9998 12.9621 14.7247 13.6246 14.1746 14.1748C13.6247 14.7249 12.9621 15 12.1874 15H2.81248C2.03781 15 1.37527 14.7249 0.82512 14.1748C0.275074 13.6246 0 12.9621 0 12.1874V2.81248C0 2.03778 0.275074 1.37527 0.82512 0.825119C1.37527 0.275074 2.03781 0 2.81248 0H12.1876ZM5.94118 10.7327C5.94118 11.6473 6.63969 12.3881 7.50157 12.3881C8.36325 12.3881 9.06195 11.6473 9.06202 10.7327H5.94118ZM6.05335 9.86302H8.94965C8.97899 9.54238 9.2839 9.2372 9.65294 8.86784C10.2651 8.25514 11.0537 7.46583 11.0537 6.13639C11.0537 3.8636 9.35375 2.65865 7.50123 2.61285C5.64885 2.65844 3.94922 3.8636 3.94922 6.13639C3.94922 7.46581 4.73785 8.25512 5.35003 8.86782C5.71909 9.23719 6.02401 9.54237 6.05335 9.86302Z"
        fill="#A3DF73"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H15V15H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IdeaIcon;
