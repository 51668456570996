import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';

import { getFullName } from 'utils/get-fio';

import EmployeeAvatar from '../employee';

import styles from './replace-employee-option.module.scss';

const ReplaceEmployeeOption = ({ replacer }) => {
  const { t } = useTranslation('ReplaceAnEmployee');
  const name = getFullName(replacer);

  return (
    <div className={styles.replaceWrapper}>
      <Typography.Text size="small">{t('Replaces')}</Typography.Text>

      <div className={styles.userWrapper}>
        <EmployeeAvatar employee={replacer} />

        <Tooltip title={name}>
          <Typography.Text ellipsis>{name}</Typography.Text>
        </Tooltip>
      </div>
    </div>
  );
};

ReplaceEmployeeOption.propTypes = {
  replacer: PropTypes.shape({
    id: PropTypes.number,
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isActive: PropTypes.bool
  })
};

ReplaceEmployeeOption.defaultProps = {
  replacer: {}
};

export default ReplaceEmployeeOption;
