import React, { Fragment, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_FORMAT_FM } from 'constants/calendar';

import MessageDate from './message-date';

import styles from './date-list.module.scss';

export const DateList = ({
  className,
  dateFormat,
  children,
  selector,
  firstDateAdditionalComponent
}) => {
  if (!children || !children.length) {
    return null;
  }

  return (
    <Fragment>
      {Children.map(children, (child, index) => {
        const item = child.props;
        const prevItem = (children[index - 1] || {}).props;

        const date = moment(selector(item)).format(dateFormat);
        const prevDate =
          prevItem && moment(selector(prevItem)).format(dateFormat);

        const datesDifferent = date !== prevDate;

        return (
          <Fragment key={child.props.id || index}>
            {datesDifferent && (
              <div className={classnames(styles.date, className)}>
                <MessageDate
                  date={date}
                  dateFormat={dateFormat}
                  data-qa="qa-9dwqmn77sgo3mjc"
                />

                {!prevDate && <>{firstDateAdditionalComponent}</>}
              </div>
            )}

            {cloneElement(child)}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

DateList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      createdAt: PropTypes.string
    })
  ),
  dateFormat: PropTypes.string,
  selector: PropTypes.func,
  firstDateAdditionalComponent: PropTypes.any
};

DateList.defaultProps = {
  className: undefined,
  children: [],
  dateFormat: DATE_FORMAT_FM,
  selector: item => item.createdAt,
  firstDateAdditionalComponent: null
};

export default DateList;
