import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';
import Markdown from 'components/common/markdown';

import styles from './video-collapse.module.scss';

const { Text } = Typography;

export const VideoCollapse = ({ data, onShowSupport }) => {
  const { t } = useTranslation('Onboarding');

  return (
    <Collapse className={styles.collapse} bordered={false} accordion>
      {data.map(item => (
        <Collapse.Panel
          header={item.title}
          key={item.id}
          showArrow={false}
          className={styles.panel}
        >
          <Markdown
            santizeProps={{ ADD_TAGS: ['iframe'] }}
            className={styles.content}
          >
            {item.content || ''}
          </Markdown>

          <div className={styles.commentBlock}>
            <Text className={styles.commentText}>{t('HaveQuestion')} </Text>

            <Button
              type="link"
              className={styles.button}
              onClick={onShowSupport}
            >
              {t('AskHere')}
            </Button>
          </div>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

VideoCollapse.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.number
    })
  ),
  onShowSupport: PropTypes.func
}.isRequired;

export default VideoCollapse;
