import React from 'react';

export const ReplyIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1017 3.15629C8.42877 3.27729 8.64592 3.58914 8.64592 3.93787V7.37658C10.3145 7.29753 12.3817 7.75064 14.2061 8.94352C16.4553 10.4141 18.3095 12.9913 18.643 16.9937C18.6762 17.3918 18.4222 17.7574 18.0376 17.8652C17.6529 17.9731 17.2459 17.7928 17.0672 17.4355C16.2491 15.7992 14.727 14.6669 12.9887 14.015C11.5111 13.4609 9.94033 13.2792 8.64592 13.4066V17.0629C8.64592 17.4116 8.42877 17.7234 8.1017 17.8444C7.77463 17.9654 7.40682 17.87 7.17987 17.6052L1.55487 11.0427C1.28737 10.7306 1.28737 10.2701 1.55487 9.95804L7.17987 3.39554C7.40682 3.13076 7.77463 3.0353 8.1017 3.15629ZM3.28515 10.5004L6.97925 14.8102V12.0047L7.64915 11.8707C9.37829 11.5249 11.5675 11.702 13.5739 12.4545C14.5052 12.8037 15.4159 13.2844 16.2303 13.9096C15.537 12.252 14.4633 11.103 13.294 10.3385C11.4408 9.12675 9.31877 8.86146 7.97601 9.13002L6.97925 9.32937V6.19058L3.28515 10.5004Z"
      fill="currentColor"
    />
  </svg>
);

export default ReplyIcon;
