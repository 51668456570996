import { useEffect, useState } from 'react';
import { uniqueId, union } from 'lodash';

import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import { DONE } from 'constants/index';

import { useFileUpload } from '..';

export const useFilePaste = ({
  allowPaste = true,
  target,
  fileList = [],
  onChange = () => {},
  uploadFileServer = true
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { onUploadAttachment } = useFileUpload();

  const handleChange = file => onChange(union([...fileList, file], 'uid'));

  const pasteFile = ({ clipboardData }) => {
    const { files } = clipboardData;

    if (files.length) {
      const file = files[0];
      const uid = uniqueId();
      file.uid = uid;

      setIsLoading(true);

      if (uploadFileServer) {
        onUploadAttachment({
          file,
          onProgress: ({ percent }) => {
            file.percent = percent;
            handleChange(file);
          },
          onSuccess: ({ id }) => {
            file.response = { id };
            file.mimeType = file.type;

            setIsLoading(false);

            handleChange({
              mimeType: file.mimeType,
              percent: file.percent,
              response: file.response,
              uid: file.uid,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              name: file.name,
              size: file.size,
              type: file.type,
              webkitRelativePath: file.webkitRelativePath,
              originFileObj: file,
              status: DONE,
              ...addValidityDateState(file)
            });
          },
          onError: () => setIsLoading(false)
        });
      } else {
        file.percent = 100;
        handleChange(file);
      }
    }
  };

  useEffect(() => {
    if (target && allowPaste) target.addEventListener('paste', pasteFile);

    return () => {
      if (target) target.removeEventListener('paste', pasteFile);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasteFile]);

  return { isLoading };
};

export default useFilePaste;
