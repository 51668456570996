import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EDIT_COMPANY_DETAILS_EVENT } from 'constants/index';

import Drawer from 'components/common/drawer';

import { updateWorkspace, getActiveWorkspace } from 'store/workspace';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import EditWorkspaceRequisitesForm from './form';

import styles from '../styles.module.scss';

const EditWorkspaceRequisitesDrawer = ({
  className,
  onClose,
  ...drawerProps
}) => {
  const { t } = useTranslation('EditLegalInfo');

  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const { state = {} } = useLocation();

  const workspace = useSelector(getActiveWorkspace, shallowEqual);
  const onUpdateWorkspace = data => dispatch(updateWorkspace(data));

  const handleSubmit = async data => {
    amplitude.logEvent({ event: EDIT_COMPANY_DETAILS_EVENT });

    await onUpdateWorkspace(data);

    onClose();
  };

  return (
    <Drawer
      className={classnames(styles.root, className)}
      title={<Drawer.Title>{t('EditLegalInfoHeading')}</Drawer.Title>}
      destroyOnClose
      onClose={onClose}
      width={590}
      {...drawerProps}
    >
      <EditWorkspaceRequisitesForm
        isVerification={state.isVerification}
        workspace={workspace}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </Drawer>
  );
};

EditWorkspaceRequisitesDrawer.propsTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

EditWorkspaceRequisitesDrawer.defaultProps = {
  className: undefined
};

export default EditWorkspaceRequisitesDrawer;
