import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCurrency } from 'store/workspace';

import CustomSelect from '../custom-select';

const mapValue = ({ code }) => ({
  value: code,
  label: code
});

export const CurrencySelect = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const fetchData = params =>
    dispatch(fetchCurrency(params)).then(({ entries, ...res }) => ({
      ...res,
      entries: entries.map(mapValue)
    }));

  return (
    <CustomSelect
      fetchData={fetchData}
      ref={ref}
      showSelectedOptionsOnList={false}
      isSearchable
      isAsync
      {...props}
    />
  );
});

export default CurrencySelect;
