import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/common/hook-form/form-item';

import ChatIcon from './icons/chat';
import WeChatIcon from './icons/we-chat';
import MusicIcon from './icons/music';
import CommentIcon from './icons/comment';
import ForwardInboxIcon from './icons/forward-inbox';
import MessageIcon from './icons/message';
import EmailIcon from './icons/email';
import { OBSERVED_FIELDS } from './utills';

import styles from './website-channel.module.scss';

const icons = [
  { id: 'chat', icon: <ChatIcon /> },
  { id: 'we-chat', icon: <WeChatIcon /> },
  { id: 'music', icon: <MusicIcon /> },
  { id: 'comment', icon: <CommentIcon /> },
  { id: 'forward-inbox', icon: <ForwardInboxIcon /> },
  { id: 'message', icon: <MessageIcon /> },
  { id: 'email', icon: <EmailIcon /> }
];

export const FormIconRadio = ({
  rules,
  name,
  label,
  disabled,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const color = useWatch({ name: OBSERVED_FIELDS.color, control });

  const getStyleRadioButton = isActive => ({
    position: 'relative',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    background: 'transparent',
    marginRight: 16,
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: color,
    border: `1px solid ${isActive ? color : 'transparent'}`,
    padding: 2,
    backgroundClip: 'content-box',
    lineHeight: 'initial'
  });

  const defaultValue = icons[0].id;

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        defaultProps={defaultValue}
        {...props}
        render={({ field }) => {
          const { value, onChange, ...renderProps } = field;

          return (
            <Radio.Group
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              className="without-border"
              value={value}
              disabled={disabled}
              onChange={e => onChange(e.target.value)}
              {...renderProps}
            >
              {icons.map(({ id, icon }) => (
                <Radio.Button
                  key={id}
                  value={id}
                  style={getStyleRadioButton(value === id)}
                  className={styles.radioItem}
                >
                  {icon}
                </Radio.Button>
              ))}
            </Radio.Group>
          );
        }}
      />
    </FormItem>
  );
};

FormIconRadio.defaultProps = {
  rules: PropTypes.any,
  disabled: PropTypes.bool,
  itemProps: PropTypes.object
};

FormIconRadio.defaultProps = {
  rules: {},
  disabled: false,
  itemProps: {}
};

export default FormIconRadio;
