import { Modal } from 'antd';
import React from 'react';
import * as Sentry from '@sentry/react';

import configNoInternetConnection from 'components/common/modals/no-internet-connection';

import SpareUI from './spare-ui';

const fallback = () => {
  if (!navigator.onLine) {
    Modal.destroyAll();

    return Modal.info({
      ...configNoInternetConnection,
      content: <configNoInternetConnection.content />
    });
  }

  return <SpareUI />;
};

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
);

export default ErrorBoundary;
