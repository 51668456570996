import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { TYPE_ORDER_TEMPLATE } from 'constants/index';

import { useTheme } from 'components/common/theme';
import ShareTemplateModal from 'components/common/share-template-modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import ActionsDropdown from 'components/common/actions';
import { TagList } from 'components/common/tags';

import { getTagsByEntity } from 'store/tags';

import styles from './card.module.scss';

export const TemplateCard = ({ data, onClick, onEdit, onDelete }) => {
  const { variables } = useTheme();

  const [showShareModal, setShowShareModal] = useState(false);

  const { id, isCustomer, isMarketplace, title } = data;

  const tags = useSelector(state =>
    getTagsByEntity(state)({ entityType: TYPE_ORDER_TEMPLATE, entityId: id })
  );

  const actions = [
    // {
    //   allow: true,
    //   title: 'Поделиться шаблоном',
    //   style: { whiteSpace: 'nowrap' },
    //   onClick: () => setShowShareModal(true)
    // },
    {
      allow: true,
      title: 'Редактировать',
      style: { whiteSpace: 'nowrap' },
      onClick: onEdit
    },
    {
      allow: true,
      title: 'Удалить шаблон',
      style: { color: variables.color.red, whiteSpace: 'nowrap' },
      onClick: onDelete,
      isConfirm: true,
      confirmText: 'Вы уверены, что хотите удалить шаблон заказа?',
      placement: 'topLeft'
    }
  ];

  const renderSideInfo = () => {
    if (isMarketplace) {
      return 'На биржу';
    }

    return isCustomer ? 'Для исполнителя' : 'Для заказчика';
  };

  return (
    <>
      <Button className={styles.root} onClick={() => onClick(tags)}>
        <div className={styles.header}>
          <Typography.Text color="black-55">{renderSideInfo()}</Typography.Text>

          <div onClick={event => event.stopPropagation()}>
            <ActionsDropdown actions={actions} />
          </div>
        </div>

        <div className={styles.content}>
          {/* <div className={styles.image}>
            <img src={icon} alt={title} />
          </div> */}

          <Tooltip title={title} mouseEnterDelay={0.5}>
            <Typography.Title
              level={3}
              ellipsis={{ rows: 2 }}
              className={styles.title}
            >
              {title}
            </Typography.Title>
          </Tooltip>
        </div>

        <TagList
          entityType={TYPE_ORDER_TEMPLATE}
          entityId={id}
          displayedCount={1}
          shortAddButton
        />
      </Button>

      <ShareTemplateModal
        id={id}
        visible={showShareModal}
        share={() => {}}
        onClose={() => setShowShareModal(false)}
      />
    </>
  );
};

export default TemplateCard;

TemplateCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number
    // дописать
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

TemplateCard.defaultProps = {
  data: {},
  onEdit: () => {},
  onDelete: () => {}
};
