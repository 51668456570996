import React from 'react';

export const SankeyChartIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2 4C20.0895 4 20 4.08954 20 4.2V5.8C20 5.91046 19.9105 6 19.8 6H4.2C4.08954 6 4 5.91046 4 5.8V4.2C4 4.08954 3.91046 4 3.8 4H2.2C2.08954 4 2 4.08954 2 4.2V11.8C2 11.9105 2.08954 12 2.2 12H3.8C3.91046 12 4 11.9105 4 11.8V10.2C4 10.0895 4.09126 9.99983 4.2017 10.0017C8.21745 10.0689 9.95018 12.1464 11.77 14.34C13.5912 16.5353 15.5868 18.9243 19.7984 18.9982C19.9088 19.0002 20 19.0895 20 19.2V20.8C20 20.9105 20.0895 21 20.2 21H21.8C21.9105 21 22 20.9105 22 20.8V15.2C22 15.0895 21.9105 15 21.8 15H20.2C20.0895 15 20 15.0895 20 15.2V16.8C20 16.9105 19.9092 17.0002 19.7988 16.9978C16.5294 16.9271 15.035 15.128 13.31 13.06C11.34 10.69 9.1 8 4 8H19.8C19.9105 8 20 8.08954 20 8.2V9.8C20 9.91046 20.0895 10 20.2 10H21.8C21.9105 10 22 9.91046 22 9.8V4.2C22 4.08954 21.9105 4 21.8 4H20.2Z"
      fill="currentColor"
    />
  </svg>
);

export default SankeyChartIcon;
