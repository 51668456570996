import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import VatNumberInput from 'components/common/controls/vat-number';

import FormItem from '../form-item';

export const FormVatNumber = ({ rules, name, label, itemProps, ...props }) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => (
          <VatNumberInput isRegistration {...props} {...field} />
        )}
      />
    </FormItem>
  );
};

FormVatNumber.defaultProps = {
  isLegatCountries: PropTypes.bool,
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormVatNumber.defaultProps = {
  isLegatCountries: true,
  rules: {},
  itemProps: {}
};

export default FormVatNumber;
