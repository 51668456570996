import { LOCATION_CHANGE } from 'connected-react-router';

import { handleActions } from 'utils/redux-actions';

import { setVisibleDrawer } from './actions';

const initialState = {};

export default handleActions(
  {
    [LOCATION_CHANGE]: () => initialState,

    [setVisibleDrawer]: (state, { payload }) => {
      const { drawer, data } = payload;

      state[drawer] = data;

      return state;
    }
  },
  initialState
);
