import { handleActions } from '../../utils/redux-actions';
import { fetchCategories, fetchRecommendedCategory } from './actions';

const initialState = {
  entries: [],
  isLoaded: false,
  isLoading: false,
  totalItems: 0,
  error: null
};

export default handleActions(
  {
    [fetchCategories.START]: state => {
      state.isLoading = true;
      state.error = null;

      return state;
    },

    [fetchCategories.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchCategories.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchCategories.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;
      state.entries = payload.entries;
      state.totalItems = payload.totalItems;

      return state;
    },

    [fetchRecommendedCategory.START]: state => {
      state.isLoading = true;
      state.error = null;

      return state;
    },

    [fetchRecommendedCategory.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;

      state.recommended = payload;

      return state;
    },

    [fetchRecommendedCategory.ENDED]: state => {
      state.isLoading = false;

      return state;
    }
  },
  initialState
);
