import React from 'react';

export const AgreementIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2 0.8C13.6 0.3 13 0 12.2 0H2.8C2 0 1.4 0.3 0.8 0.8C0.2 1.3 0 2 0 2.8V12.2C0 13 0.3 13.6 0.8 14.2C1.4 14.7 2 15 2.8 15H12.2C13 15 13.6 14.7 14.2 14.2C14.7 13.6 15 13 15 12.2V2.8C15 2 14.7 1.4 14.2 0.8ZM11 11.5C11 12.0523 10.5523 12.5 10 12.5H5C4.44772 12.5 4 12.0523 4 11.5V11H11V11.5ZM12 10H3V8.3C3 7.6 3.6 7 4.3 7H6.8L6.5 3C6.5 3 6.5 2.55 6.8 2.25C7 2.05 7.2 2 7.5 2C7.8 2 8 2.05 8.2 2.25C8.4 2.45 8.5 2.8 8.5 3L8.2 7H10.7C11.4 7 12 7.6 12 8.3V10Z"
      fill="#585B8A"
    />
  </svg>
);

export default AgreementIcon;
