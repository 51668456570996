import React from 'react';

export const DiffIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0356 8.97589C11.0356 8.87143 10.9525 8.78571 10.8481 8.78571H9.72308C9.62129 8.78571 9.53558 8.87143 9.53558 8.97589V11.25H7.27218C7.16236 11.25 7.07129 11.333 7.07129 11.4375V12.5625C7.07129 12.6643 7.16236 12.75 7.27218 12.75H9.53558V15.0241C9.53558 15.1286 9.62129 15.2143 9.72308 15.2143H10.8481C10.9525 15.2143 11.0356 15.1286 11.0356 15.0241V12.75H13.299C13.4088 12.75 13.4999 12.6643 13.4999 12.5625V11.4375C13.4999 11.333 13.4088 11.25 13.299 11.25H11.0356V8.97589ZM13.299 17.1429H7.27218C7.16236 17.1429 7.07129 17.2286 7.07129 17.3304V18.4554C7.07129 18.5571 7.16236 18.6429 7.27218 18.6429H13.299C13.4088 18.6429 13.4999 18.5571 13.4999 18.4554V17.3304C13.4999 17.2286 13.4088 17.1429 13.299 17.1429ZM13.1088 3.68036C12.9481 3.51964 12.7311 3.42857 12.5034 3.42857H3.42843C2.95432 3.42857 2.57129 3.81161 2.57129 4.28571V23.1429C2.57129 23.617 2.95432 24 3.42843 24H17.1427C17.6168 24 17.9999 23.617 17.9999 23.1429V8.92768C17.9999 8.7 17.9088 8.48304 17.7481 8.32232L13.1088 3.68036ZM16.0713 22.0714H4.49986V5.35714H12.0588L16.0713 9.36964V22.0714ZM21.1659 6.49821L14.6597 0.238393C14.499 0.0857143 14.2874 0 14.065 0H6.21415C6.09629 0 5.99986 0.0964286 5.99986 0.214286V1.71429C5.99986 1.83214 6.09629 1.92857 6.21415 1.92857H13.6338L19.4999 7.56964V20.3571C19.4999 20.475 19.5963 20.5714 19.7141 20.5714H21.2141C21.332 20.5714 21.4284 20.475 21.4284 20.3571V7.11429C21.4284 6.88125 21.3347 6.65893 21.1659 6.49821Z"
      fill="currentColor"
    />
  </svg>
);

export default DiffIcon;
