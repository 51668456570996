import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ACCEPTED_STATE_AGREEMENT } from 'constants/index';

import Drawer from 'components/common/drawer';
import useMediaViewer from 'components/common/media-viewer-provider/use-media-viewer';

import { previewAct } from 'store/order-statuses';

import getUrlFileByArrayBinary from 'hooks/common/use-file-upload/get-url-file-by-binaty';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import ActChangeSystemForm from '../../forms/change-act';

const OrderActEditorDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { onOpen } = useMediaViewer();

  const { t } = useTranslation('ChangeAct');

  const {
    orderStatus = {},
    specificationData = {}, // data from custom or previous specification
    onSubmit,
    resetReordering
  } = data;

  const preview = useCallback(
    async values => {
      try {
        setIsLoadingPreview(true);

        await dispatch(
          previewAct({
            act: { ...values, specificationCreatedAt: values.actCreatedAt },
            id: orderStatus.id
          })
        ).then(({ data: result, filename, mimetype: mimeType }) => {
          const url = getUrlFileByArrayBinary({ data: result, mimeType });

          onOpen([{ url, name: filename, mimeType }]);
        });
      } finally {
        setIsLoadingPreview(false);
      }
    },
    [dispatch, onOpen, orderStatus.id]
  );

  const handleSubmit = useCallback(
    async ({ isPreview, ...values }) => {
      if (isPreview) {
        return preview(values);
      }

      try {
        setIsLoading(true);

        await onSubmit({
          items: values.items,
          attachmentId: orderStatus.acts.find(
            ({ state }) => state === ACCEPTED_STATE_AGREEMENT
          ).documentList[0],
          comment: values.actDescription,
          actCreatedAt: values.actCreatedAt
        });

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusChangedAct });

        return onClose();
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, onSubmit, orderStatus.acts, preview]
  );

  const handleCloseDrawer = useCallback(() => {
    resetReordering();
    onClose();
  }, [onClose, resetReordering]);

  return (
    <>
      <Drawer
        visible={visible}
        width={1100}
        title={<Drawer.Title>{t('ChangeActHeading')}</Drawer.Title>}
        destroyOnClose
        onClose={handleCloseDrawer}
      >
        <ActChangeSystemForm
          orderStatus={orderStatus}
          defaultValues={specificationData}
          isLoading={isLoading}
          isLoadingPreview={isLoadingPreview}
          onSubmit={handleSubmit}
        />
      </Drawer>
    </>
  );
};

OrderActEditorDrawer.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired
};

OrderActEditorDrawer.defaultProps = {
  visible: false,
  data: {}
};

export default OrderActEditorDrawer;
