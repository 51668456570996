import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select } from 'antd';

import Icon from 'components/common/icon';

import { getAllWorkspaces, getPersonalWorkspace } from 'store/workspace';

import getActiveWorkspaces from 'utils/get-active-workspaces';

import styles from './workspace-select.module.scss';

const { Option } = Select;

const WorkspaceSelect = forwardRef(
  (
    { className, onChange, callback, onlyActiveWorkspaces, ...selectProps },
    ref
  ) => {
    const workspaces = useSelector(getAllWorkspaces);
    const personalWorkspace = useSelector(getPersonalWorkspace);

    const workspacesList = onlyActiveWorkspaces
      ? getActiveWorkspaces(workspaces)
      : Object.values(workspaces);

    const resultWorkspacesList = [personalWorkspace, ...workspacesList];

    return (
      <Select
        className={classnames(styles.root, className)}
        suffixIcon={<Icon type="arrow" size={20} color="black-55" />}
        data-qa="qa-qaovs7fh2t79k0rf4"
        selectRef={ref}
        onChange={e => {
          onChange(e);
          callback();
        }}
        {...selectProps}
      >
        {resultWorkspacesList.map(item => (
          <Option key={item.id} value={item.id}>
            {item.title}
          </Option>
        ))}
      </Select>
    );
  }
);

WorkspaceSelect.propTypes = {
  className: PropTypes.string,
  callback: PropTypes.func,
  onlyActiveWorkspaces: PropTypes.bool
};

WorkspaceSelect.defaultProps = {
  className: undefined,
  callback: () => {},
  onlyActiveWorkspaces: false
};

export default WorkspaceSelect;
