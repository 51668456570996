import { FormProvider, useForm } from 'react-hook-form';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import Button from '../button';
import { FormDatePicker, FormTextarea, validateMaxLength } from '../hook-form';

import styles from './reminder.module.scss';

const FormReminder = ({
  defaultValues,
  isLoading,
  onSubmit,
  onDelete,
  setVisiblePopover,
  deadlineAt
}) => {
  const { t } = useTranslation(['Task', 'Common', 'Errors']);

  const methods = useForm({
    defaultValues: {
      remindDate: defaultValues && moment(defaultValues.remindDate).toDate(),
      comment:
        (defaultValues && defaultValues.comment) ||
        t('ReminderDefaultValue', { ns: 'Common' })
    }
  });

  const remindDateWatch = methods.watch('remindDate');

  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: remindDateWatch,
    endDate: deadlineAt
  });

  return (
    <Spin spinning={isLoading}>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormDatePicker
            name="remindDate"
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              validate: value =>
                deadlineAt && !moment(deadlineAt).isAfter(value)
                  ? t('CannotBeLaterDeadlineAt', { ns: 'Errors' })
                  : true
            }}
            itemProps={{
              label: t('WhenToRemind'),
              className: styles.input
            }}
            placeholder={t('ChooseDate', { ns: 'Common' })}
            minDate={new Date()}
            maxDate={moment(deadlineAt).toDate()}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
          />

          <FormTextarea
            name="comment"
            label={t('ReminderComment')}
            itemProps={{
              className: styles.input
            }}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: {
                value: 512,
                message: validateMaxLength(512)
              }
            }}
            className={styles.textarea}
            placeholder={t('EnterText')}
          />

          <div className={styles.actions}>
            <Button type="secondary" onClick={() => setVisiblePopover(false)}>
              {t('CancelBtn')}
            </Button>

            <Button htmlType="submit" type="primary">
              {t('SaveBtn')}
            </Button>
          </div>

          {defaultValues && (
            <Button
              onClick={onDelete}
              type="text"
              mood="negative"
              className={styles.deleteBtn}
            >
              {t('DeleteReminderBtn')}
            </Button>
          )}
        </form>
      </FormProvider>
    </Spin>
  );
};

FormReminder.propTypes = {
  defaultValues: PropTypes.shape({
    remindDate: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setVisiblePopover: PropTypes.func.isRequired,
  deadlineAt: PropTypes.string
};

FormReminder.defaultProps = {
  defaultValues: undefined,
  isLoading: false,
  deadlineAt: ''
};

export default FormReminder;
