import React from 'react';

export const CanbanIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20L20 4L4 4L4 20L20 20ZM20.6667 22C21.403 22 22 21.403 22 20.6667L22 3.33333C22 2.59695 21.403 2 20.6667 2L3.33333 2C2.59695 2 2 2.59695 2 3.33333L2 20.6667C2 21.403 2.59695 22 3.33333 22L20.6667 22Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2L16 22L14 22L14 2L16 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2L10 22L8 22L8 2L10 2Z"
      fill="currentColor"
    />
  </svg>
);

export default CanbanIcon;
