import React from 'react';

export const CancelSubscriptionIcon = ({
  style = { width: 150, height: 151 }
}) => (
  <svg
    style={style}
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.4841 31.3645C79.0487 31.6695 80.5869 32.0408 82.125 32.4253C89.6169 34.4408 97.48 36.1116 104.945 37.8619L106.139 38.1536C111.217 39.3205 116.097 40.5271 120.579 41.8796C122.183 42.3703 123.708 42.8211 125.207 43.378C140.283 48.5627 149.167 55.975 141.437 70.4283C140.787 71.6756 140.339 73.0183 140.111 74.4063C139.116 80.3335 141.052 87.9447 142.179 95.5426C142.464 97.3481 142.654 99.1672 142.749 100.992C142.916 103.044 142.916 105.106 142.749 107.158C142.481 110.582 141.418 113.896 139.647 116.838C138.216 118.927 136.472 120.783 134.475 122.341C130.497 125.298 85.9306 152.441 47.1055 128.268C45.4481 127.207 7.49812 102.836 14.8839 69.8448C22.1636 37.6895 65.2451 29.0838 77.4841 31.3645Z"
      fill="#EBF8F8"
    />
    <path
      d="M102.501 13.2064L61.7077 18.0418C59.7733 18.2711 58.391 20.0252 58.6203 21.9597L59.8955 32.7177C60.1248 34.6521 61.8788 36.0344 63.8133 35.8051L104.607 30.9697C106.541 30.7404 107.924 28.9863 107.694 27.0519L106.419 16.2938C106.19 14.3594 104.436 12.9771 102.501 13.2064Z"
      fill="#32C9C9"
    />
    <path
      d="M90.3256 19.6732L49.5319 24.5086C47.5975 24.7379 46.2152 26.492 46.4445 28.4264L47.7197 39.1845C47.949 41.1189 49.703 42.5012 51.6375 42.2719L92.4312 37.4365C94.3656 37.2072 95.7479 35.4531 95.5186 33.5187L94.2434 22.7606C94.0141 20.8262 92.26 19.4439 90.3256 19.6732Z"
      fill="url(#paint0_linear_88955_190125)"
    />
    <path
      d="M129.411 39.811L134.463 82.4019C134.646 84.7742 133.883 87.1227 132.34 88.934C130.797 90.7453 128.6 91.8722 126.229 92.0684L57.0913 100.276C54.7396 100.639 52.3399 100.056 50.4167 98.6545C48.4936 97.2532 47.2034 95.1475 46.8282 92.7977L41.7231 50.2068C41.5362 47.8336 42.2981 45.4832 43.8418 43.6711C45.3855 41.859 47.5848 40.7331 49.9575 40.5403L119.148 32.3456C121.499 31.9754 123.9 32.5539 125.825 33.9537C127.749 35.3536 129.039 37.4604 129.411 39.811Z"
      fill="#FFE181"
    />
    <path
      d="M123.391 35.5402L129.411 86.1003C130.074 91.7225 126.468 96.7083 121.376 97.3713L52.1723 105.619C47.0672 106.229 42.3997 102.158 41.7234 96.5359L35.7299 45.936C35.0537 40.3138 38.6604 35.3281 43.7655 34.6651L112.956 26.4306C118.087 25.8472 122.742 29.918 123.391 35.5402Z"
      fill="#FDA402"
    />
    <path
      d="M130.896 52.3809L132.753 68.0143L98.5687 71.9923C96.4973 72.2385 94.413 71.6517 92.7742 70.3611C91.1355 69.0705 90.0766 67.1818 89.8304 65.1104C89.5842 63.039 90.171 60.9547 91.4616 59.316C92.7522 57.6772 94.6409 56.6183 96.7123 56.3721L130.896 52.3809Z"
      fill="#FEC458"
    />
    <path
      d="M100.477 67.7354C102.586 67.7354 104.296 66.0256 104.296 63.9165C104.296 61.8074 102.586 60.0977 100.477 60.0977C98.368 60.0977 96.6582 61.8074 96.6582 63.9165C96.6582 66.0256 98.368 67.7354 100.477 67.7354Z"
      fill="white"
    />
    <path
      d="M89.3582 127.981L96.1443 86.082C96.6115 83.1976 94.652 80.4807 91.7676 80.0135L14.3178 67.4694C11.4335 67.0022 8.71648 68.9617 8.24931 71.8461L1.46314 113.745C0.995974 116.63 2.95552 119.347 5.83991 119.814L83.2896 132.358C86.174 132.825 88.891 130.865 89.3582 127.981Z"
      fill="url(#paint1_linear_88955_190125)"
    />
    <path
      d="M94.9947 93.2359L7.09961 79L5.69192 87.6913L93.587 101.927L94.9947 93.2359Z"
      fill="#2B2A36"
    />
    <path
      d="M41.1222 98.6696L16.0037 94.6012C13.141 94.1376 10.4449 96.0795 9.98185 98.9386C9.51878 101.798 11.4641 104.491 14.3268 104.955L39.4453 109.023C42.308 109.487 45.004 107.545 45.4671 104.686C45.9302 101.827 43.9849 99.1332 41.1222 98.6696Z"
      fill="white"
    />
    <path
      d="M86.3686 108.644L84.7243 118.827C84.6104 119.519 84.227 120.138 83.6579 120.548C83.0888 120.958 82.3804 121.126 81.6878 121.015L65.9218 118.509C65.2272 118.398 64.6051 118.016 64.1923 117.447C63.7795 116.877 63.6098 116.167 63.7206 115.473L65.3648 105.289C65.4756 104.594 65.8578 103.972 66.4272 103.56C66.9967 103.147 67.7068 102.977 68.4014 103.088L84.1674 105.647C84.854 105.758 85.4694 106.134 85.8811 106.695C86.2929 107.255 86.4679 107.955 86.3686 108.644Z"
      fill="#FFE181"
    />
    <path
      d="M70.9232 103.493L68.4258 118.912L71.0437 119.336L73.541 103.917L70.9232 103.493Z"
      fill="white"
    />
    <path
      d="M79.0325 104.815L76.5352 120.234L79.153 120.658L81.6504 105.239L79.0325 104.815Z"
      fill="white"
    />
    <path
      d="M64.7599 109.068L64.3359 111.686L85.3313 115.086L85.7553 112.468L64.7599 109.068Z"
      fill="white"
    />
    <path
      d="M21.9403 38.1532C21.385 38.7085 20.4689 38.7085 19.9136 38.1532L8.98872 27.2283C8.43345 26.6731 8.43345 25.7569 8.98872 25.2016C9.54398 24.6463 10.4602 24.6463 11.0154 25.2016L21.9403 36.1265C22.4956 36.6818 22.4956 37.5979 21.9403 38.1532Z"
      fill="#F9716C"
    />
    <path
      d="M21.9403 27.2283L11.0154 38.1532C10.4602 38.7085 9.54398 38.7085 8.98872 38.1532C8.43345 37.5979 8.43345 36.6818 8.98872 36.1265L19.9136 25.2016C20.4689 24.6463 21.385 24.6463 21.9403 25.2016C22.4956 25.7569 22.4956 26.6731 21.9403 27.2283Z"
      fill="#F9716C"
    />
    <path
      d="M148.709 33.4945C148.279 33.9248 147.599 33.9248 147.168 33.4945L138.895 25.221C138.465 24.7907 138.465 24.1105 138.895 23.6802C139.325 23.2498 140.006 23.2498 140.436 23.6802L148.709 31.9536C149.14 32.384 149.14 33.0642 148.709 33.4945Z"
      fill="#F9716C"
    />
    <path
      d="M148.709 25.221L140.436 33.4945C140.006 33.9248 139.325 33.9248 138.895 33.4945C138.465 33.0642 138.465 32.384 138.895 31.9536L147.168 23.6802C147.599 23.2498 148.279 23.2498 148.709 23.6802C149.14 24.1105 149.14 24.7907 148.709 25.221Z"
      fill="#F9716C"
    />
    <path
      d="M108.722 129.222C108.473 129.472 108.084 129.472 107.834 129.222L103.045 124.433C102.795 124.183 102.795 123.795 103.045 123.545C103.295 123.295 103.683 123.295 103.933 123.545L108.722 128.334C108.972 128.584 108.972 128.973 108.722 129.222Z"
      fill="#F9716C"
    />
    <path
      d="M108.722 124.447L103.933 129.236C103.683 129.486 103.295 129.486 103.045 129.236C102.795 128.986 102.795 128.598 103.045 128.348L107.834 123.558C108.084 123.309 108.473 123.309 108.722 123.558C108.972 123.808 108.972 124.197 108.722 124.447Z"
      fill="#F9716C"
    />
    <path
      d="M148.39 103.431L129.969 74.4694C129.474 73.6911 128.79 73.0503 127.982 72.6064C127.173 72.1624 126.266 71.9297 125.343 71.9297C124.42 71.9297 123.513 72.1624 122.704 72.6064C121.895 73.0503 121.212 73.6911 120.717 74.4694L102.284 103.431C101.754 104.264 101.459 105.225 101.43 106.211C101.401 107.198 101.638 108.174 102.118 109.037C102.597 109.9 103.301 110.617 104.154 111.114C105.007 111.61 105.978 111.867 106.965 111.857H143.818C144.796 111.847 145.753 111.576 146.591 111.072C147.428 110.568 148.116 109.848 148.582 108.989C149.049 108.13 149.277 107.161 149.243 106.184C149.209 105.207 148.914 104.256 148.39 103.431Z"
      fill="#F9716C"
    />
    <path
      d="M110.613 103.432L129.045 74.4701C129.187 74.2365 129.349 74.0167 129.532 73.8136C128.972 73.1693 128.269 72.6647 127.479 72.3399C126.69 72.0152 125.835 71.8793 124.984 71.9431C124.132 72.0068 123.308 72.2685 122.575 72.7072C121.843 73.1459 121.223 73.7496 120.765 74.4701L102.284 103.432C101.754 104.265 101.459 105.225 101.43 106.212C101.401 107.199 101.638 108.175 102.118 109.038C102.597 109.9 103.301 110.618 104.154 111.114C105.007 111.611 105.978 111.868 106.965 111.858H115.245C114.262 111.859 113.297 111.596 112.451 111.096C111.605 110.596 110.908 109.877 110.435 109.016C109.961 108.155 109.728 107.182 109.759 106.2C109.791 105.217 110.085 104.261 110.613 103.432Z"
      fill="#F7413B"
    />
    <path
      d="M126.322 96.8289H124.352C123.849 96.8289 123.366 96.6291 123.011 96.2734C122.655 95.9176 122.455 95.4352 122.455 94.9322V83.1018C122.455 82.8527 122.504 82.6061 122.599 82.376C122.695 82.1458 122.834 81.9367 123.011 81.7606C123.187 81.5845 123.396 81.4448 123.626 81.3495C123.856 81.2541 124.103 81.2051 124.352 81.2051H126.322C126.571 81.2051 126.817 81.2541 127.047 81.3495C127.277 81.4448 127.487 81.5845 127.663 81.7606C127.839 81.9367 127.979 82.1458 128.074 82.376C128.169 82.6061 128.218 82.8527 128.218 83.1018V94.9322C128.218 95.4352 128.018 95.9176 127.663 96.2734C127.307 96.6291 126.825 96.8289 126.322 96.8289Z"
      fill="white"
    />
    <path
      d="M125.337 107.042C125.91 107.042 126.469 106.872 126.944 106.552C127.419 106.233 127.789 105.779 128.005 105.249C128.221 104.719 128.274 104.137 128.158 103.576C128.042 103.015 127.762 102.502 127.352 102.102C126.943 101.701 126.424 101.432 125.861 101.327C125.298 101.223 124.717 101.289 124.192 101.516C123.666 101.744 123.221 102.123 122.912 102.605C122.602 103.086 122.444 103.649 122.456 104.222C122.472 104.975 122.782 105.693 123.321 106.22C123.86 106.747 124.583 107.043 125.337 107.042Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_88955_190125"
        x1="49.3181"
        y1="39.4891"
        x2="95.875"
        y2="21.2052"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07BEDC" />
        <stop offset="1" stopColor="#0389B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_88955_190125"
        x1="1.39072"
        y1="99.9093"
        x2="96.1992"
        y2="99.9093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default CancelSubscriptionIcon;
