import { LANGUAGE } from 'i18n-config';

import {
  CURRENCY_MAP,
  PAYMENT_ACCOUNT_STATUS_BLOCKED
} from 'constants/billing';
// TODO: PAYMENT
// import { OWNER, ACCOUNT_MANAGER } from 'constants/index';

// const paymentRoles = [OWNER, ACCOUNT_MANAGER];
const paymentRoles = [];

export const checkPaymentAccessByRoles = (roles = []) => {
  const roleTypes = roles.map(r => r.type);

  return !!paymentRoles.find(role => roleTypes.includes(role));
};

export const checkAllowInviteEmployee = ({
  currentEmployeeCount = 0,
  maxEmployeeCount
}) => !maxEmployeeCount || maxEmployeeCount > currentEmployeeCount;

export const checkIsOnlyCompanyProfile = workspace =>
  workspace && workspace.account.status === PAYMENT_ACCOUNT_STATUS_BLOCKED;

export const getFormattedAmount = ({ amount, currency }) => {
  const language = localStorage.getItem(LANGUAGE);

  return `${Number(amount).toLocaleString(language)} ${CURRENCY_MAP[currency]}`;
};

export const checkHasNextPayment = ({
  currentTariff,
  nextPeriodTariff,
  addons
}) =>
  !currentTariff.isDefault && (!nextPeriodTariff.isDefault || !!addons.length);
