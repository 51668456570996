import React from 'react';

export const Error404Icon = () => (
  <svg
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M111.455 139.727C97.4819 139.727 32.0895 144.96 11.7282 109.584C1.89233 90.8874 5.46016 74.8457 19.0359 59.6724C35.2224 38.2194 71.5343 20.3091 105.714 26.9128C139.894 33.5166 129.208 72.2295 142.237 96.7141C150.616 120.891 138.591 139.727 111.455 139.727Z"
      fill="#D7F1F2"
    />
    <g>
      <path
        d="M21.4154 92.2196L31.3792 103.596C32.2576 101.139 33.5312 98.3997 35.1661 95.4398L101.219 29.8045C103.953 28.34 106.489 27.1955 108.773 26.4025L99.2477 15.5272C94.5567 17.6708 89.6029 20.4057 84.5082 23.6782L51.2251 47.0998L28.7552 79.3154C25.8918 83.7481 23.4339 88.0757 21.4154 92.2196Z"
        fill="#FFE181"
      />
      <path
        d="M120.773 71.4618L107.504 41.6842V34.351C103.513 30.9667 98.9694 28.2257 94.0279 26.2894L92.2059 27.2273L77.6058 24.8882L76.696 23.0255C50.2157 23.0255 28.7478 44.7118 28.7478 71.462C28.7478 71.5704 28.7507 71.6782 28.7515 71.7864L30.5548 72.4959L33.4967 85.1507L32.1238 89.3037L32.111 89.3096C34.0684 94.2931 36.8192 98.8715 40.2082 102.884L47.8844 102.419L79.9648 114.691L79.3534 119.823C103.665 118.478 123.158 98.8023 124.564 74.2619L120.773 71.4618Z"
        fill="#F0F0F0"
      />
      <path
        d="M107.504 34.348C88.7353 59.5771 66.3915 82.3846 40.4215 102.699L40.2056 102.883L47.8845 102.419L79.9649 114.691L79.3532 119.825L80.7713 118.939C99.5036 106.159 114.197 91.2932 124.531 74.3225L124.564 74.2614C124.616 73.3364 124.644 72.4014 124.644 71.4617C124.644 56.5618 117.983 43.2321 107.504 34.348Z"
        fill="#D8D8D8"
      />
      <path
        d="M76.6961 23.0253C82.8077 23.0253 88.6543 24.1809 94.0299 26.2884V26.2908C76.4507 49.3756 55.8293 70.3994 32.1238 89.3034L32.1066 89.3108C29.977 83.8837 28.7895 77.9733 28.7502 71.7856C46.5698 56.7875 62.5444 40.5284 76.6961 23.0253Z"
        fill="#D8D8D8"
      />
      <path
        d="M21.4175 92.2157C21.4167 92.217 21.4162 92.2183 21.4154 92.2196L21.4157 92.2198C21.4164 92.2185 21.417 92.2172 21.4177 92.2157H21.4175Z"
        fill="#FEE97D"
      />
      <path
        d="M31.3806 103.595L31.3792 103.597L31.3806 103.595Z"
        fill="#FEE97D"
      />
      <path
        d="M59.0976 87.1053C53.0951 92.4561 46.8704 97.6548 40.4215 102.699L40.2056 102.883C48.9988 113.296 62.0857 119.898 76.6961 119.898C77.5867 119.898 78.4724 119.874 79.3532 119.825L80.7713 118.939C85.9376 115.414 90.7948 111.73 95.3398 107.888C80.3152 106.888 67.183 98.8982 59.0976 87.1053Z"
        fill="#D8D8D8"
      />
      <path
        d="M131.244 13.9183C125.297 7.96932 113.485 9.02578 99.2502 15.5302L108.773 26.4025H108.772C113.791 24.6591 117.587 24.6152 119.58 26.6101C125.972 32.9989 111.43 57.9042 87.0989 82.2328C62.7702 106.564 37.865 121.106 31.4762 114.714C29.4403 112.68 29.5301 108.768 31.3793 103.596L21.4157 92.2202C14.0126 107.418 12.5363 120.132 18.7843 126.378C30.5537 138.15 65.2703 122.514 96.3264 91.4603C127.38 60.4043 143.016 25.6876 131.244 13.9183Z"
        fill="#FFE181"
      />
      <path
        d="M133.538 120.489L118.812 105.763L110.631 113.944L125.357 128.67C127.606 130.92 131.247 130.92 133.497 128.67L133.538 128.629C135.808 126.379 135.808 122.739 133.538 120.489Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M111.992 112.563L117.429 107.125L113.105 102.801L107.668 108.239L111.992 112.563Z"
        fill="#2B2A36"
      />
      <path
        d="M93.9009 114.026C107.704 114.026 118.894 102.836 118.894 89.0331C118.894 75.23 107.704 64.0403 93.9009 64.0403C80.0977 64.0403 68.9081 75.23 68.9081 89.0331C68.9081 102.836 80.0977 114.026 93.9009 114.026Z"
        fill="#2B2A36"
      />
      <path
        d="M93.901 107.522C104.112 107.522 112.39 99.2443 112.39 89.0332C112.39 78.822 104.112 70.5442 93.901 70.5442C83.6898 70.5442 75.412 78.822 75.412 89.0332C75.412 99.2443 83.6898 107.522 93.901 107.522Z"
        fill="#F0F0F0"
      />
      <path
        d="M42.3557 13.0545C42.3557 14.7393 40.9861 16.1089 39.3013 16.1089C37.6164 16.1089 36.2468 14.7393 36.2468 13.0545C36.2468 11.3696 37.6164 10 39.3013 10C40.9861 10 42.3557 11.3587 42.3557 13.0545Z"
        fill="#FDA402"
      />
      <path
        d="M31.0146 30.7545C27.3713 29.7504 24.4899 26.8704 23.4852 23.2289C23.3748 22.8207 22.8118 22.8207 22.7014 23.2289C21.6967 26.8704 18.8152 29.7504 15.172 30.7545C14.7635 30.8649 14.7635 31.4276 15.172 31.538C18.8152 32.5421 21.6967 35.4222 22.7014 39.0636C22.8118 39.4719 23.3748 39.4719 23.4852 39.0636C24.4899 35.4222 27.3713 32.5421 31.0146 31.538C31.412 31.4276 31.412 30.8649 31.0146 30.7545Z"
        fill="#FDA402"
      />
      <g>
        <path
          d="M93.4477 97.2757C92.7336 97.2757 92.1543 96.727 92.1543 96.0507C92.1543 95.3744 92.7336 94.8258 93.4477 94.8258C94.1618 94.8258 94.7412 95.3744 94.7412 96.0507C94.7412 96.727 94.1618 97.2757 93.4477 97.2757ZM95.4953 89.487C95.0375 89.6866 94.7412 90.124 94.7412 90.6007V91.1507C94.7412 91.8268 94.163 92.3757 93.4477 92.3757C92.7324 92.3757 92.1543 91.8268 92.1543 91.1507V90.6007C92.1543 89.1709 93.0415 87.8603 94.4115 87.2612C95.7296 86.6867 96.6815 85.1614 96.6815 84.4129C96.6815 82.725 95.2315 81.3504 93.4477 81.3504C91.664 81.3504 90.214 82.725 90.214 84.4129C90.214 85.0892 89.6356 85.6381 88.9203 85.6381C88.205 85.6381 87.6268 85.0892 87.6268 84.4129C87.6268 81.3737 90.2384 78.9003 93.4477 78.9003C96.6571 78.9003 99.2686 81.3737 99.2686 84.4129C99.2686 86.0681 97.7525 88.5009 95.4953 89.487Z"
          fill="#36B7BD"
        />
      </g>
    </g>
  </svg>
);

export default Error404Icon;
