import { useContext } from 'react';

import { WebsocketOperatorContext } from './provider';

export const useWebsocketOperatorContext = () => {
  const values = useContext(WebsocketOperatorContext);

  return values;
};

export default useWebsocketOperatorContext;
