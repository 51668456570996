import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { STATUS_IMPORT_CONTACTS_FINISHED } from 'constants/contacts';

import { ImportContactsIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { completeImportContacts } from 'store/contacts';

import styles from '../modals.module.scss';

const SuccessImportContacts = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ContactImport');

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        completeImportContacts({ id: data.contactImportProcessing })
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.successSection}>
      <ImportContactsIcon />

      <Typography.Title>{t('ContactImportCompleteHeading')}</Typography.Title>

      {data.status !== STATUS_IMPORT_CONTACTS_FINISHED && (
        <>
          <Typography.Paragraph className={styles.info} color="black-45">
            {t('ContactImportCompleteDesc')}{' '}
            <Typography.Text color="black-45" weight="semibold">
              {data.successRow || data.countSuccessRows}
            </Typography.Text>
          </Typography.Paragraph>

          <Button
            loading={isLoading}
            type="primary"
            onClick={onSubmit}
            className={styles.closeBtn}
          >
            {t('CompleteBtn')}
          </Button>
        </>
      )}
    </div>
  );
};

SuccessImportContacts.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object
};

SuccessImportContacts.defaultProps = {
  onClose: () => {},
  data: {}
};

export default SuccessImportContacts;
