import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Button from 'components/common/button';

import styles from '../components.module.scss';

const MessageTemplates = ({ onClick, ...props }) => {
  const { t } = useTranslation('CommonChat');

  return (
    <Tooltip title={t('AnswerTemplatesTip')} mouseEnterDelay={0.5}>
      <Button
        type="text"
        mood="none"
        className={styles.toolbarBtn}
        onClick={onClick}
        {...props}
      >
        <Icon type="thunderbolt" size={16} />
      </Button>
    </Tooltip>
  );
};

MessageTemplates.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MessageTemplates;
