import React from 'react';

export const EmptyTimeLogIcon = () => (
  <svg
    width="110"
    height="100"
    viewBox="0 0 110 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.4276 0.0144775C63.2841 -0.267935 75.0943 3.58907 84.3139 10.9146C93.2444 18.0106 96.975 28.9855 101.126 39.5036C105.546 50.7025 112.455 62.2102 109.12 73.7656C105.749 85.445 95.655 95.5047 83.8877 99.2283C73.0644 102.653 62.6751 93.6655 51.4276 92.0214C42.5862 90.729 33.1408 94.8865 25.3039 90.6648C17.0279 86.2065 12.7259 77.3726 8.68486 68.996C4.06811 59.4261 -1.6013 49.4879 0.421436 39.0865C2.55335 28.1237 10.7185 19.3192 19.6804 12.4541C28.8893 5.39995 39.7513 0.292596 51.4276 0.0144775Z"
      fill="#EBF8F8"
    />
    <path
      d="M57.1361 13.1671H50.3501V28.7748H57.1361V13.1671Z"
      fill="#FDA402"
    />
    <path
      d="M54.4217 13.1671H50.3501V28.7748H54.4217V13.1671Z"
      fill="#E89600"
    />
    <path
      d="M30.7742 19.9855L25.5762 24.3475L35.6088 36.303L40.8069 31.941L30.7742 19.9855Z"
      fill="#FDA402"
    />
    <path
      d="M29.6482 24.3482L32.4573 21.9911L30.775 19.9866L25.5767 24.3482L35.609 36.3044L37.9984 34.2995L29.6482 24.3482Z"
      fill="#E89600"
    />
    <path
      d="M66.9489 31.9424L72.147 36.3044L82.1796 24.3489L76.9816 19.9869L66.9489 31.9424Z"
      fill="#FDA402"
    />
    <path
      d="M71.0179 31.9428L79.368 21.9911L76.9787 19.9866L66.9463 31.9428L72.1447 36.3044L73.8269 34.2995L71.0179 31.9428Z"
      fill="#E89600"
    />
    <path
      d="M73.5221 20.5869L82.1861 27.8567C82.6645 28.2581 83.3781 28.1957 83.7795 27.7173L86.6873 24.2517C87.0887 23.7733 87.0262 23.0597 86.5478 22.6584L77.8838 15.3886C77.4054 14.9872 76.6919 15.0496 76.2905 15.528L73.3827 18.9936C72.9813 19.472 73.0437 20.1856 73.5221 20.5869Z"
      fill="#FDA402"
    />
    <path
      d="M53.743 90.8327C73.4189 90.8327 89.3693 74.8823 89.3693 55.2064C89.3693 35.5305 73.4189 19.5801 53.743 19.5801C34.0671 19.5801 18.1167 35.5305 18.1167 55.2064C18.1167 74.8823 34.0671 90.8327 53.743 90.8327Z"
      fill="#FDA402"
    />
    <path
      d="M86.6548 56.2245C86.0926 56.2245 85.6369 55.7688 85.6369 55.2066C85.6369 41.1688 76.6265 28.9287 63.2164 24.7485C62.6797 24.5812 62.3801 24.0105 62.5473 23.4738C62.7146 22.937 63.286 22.6381 63.8221 22.8047C70.5812 24.9114 76.6543 29.2133 80.9227 34.9173C85.3387 40.8183 87.6727 47.834 87.6727 55.2066C87.6727 55.7685 87.217 56.2245 86.6548 56.2245Z"
      fill="#FFA730"
    />
    <path
      d="M77.5937 20.5866C77.1153 20.1852 77.0529 19.4716 77.4543 18.9932L79.4069 16.666L77.8842 15.3882C77.4058 14.9868 76.6922 15.0489 76.2908 15.5276L73.3827 18.9932C72.9813 19.4716 73.0437 20.1852 73.5221 20.5866L82.1861 27.8564C82.6645 28.2578 83.3781 28.1953 83.7795 27.7169L84.7346 26.5786L77.5937 20.5866Z"
      fill="#E89600"
    />
    <path
      d="M25.1384 24.2521C24.737 23.7737 24.7994 23.0601 25.2778 22.6587L32.4187 16.6667L31.4636 15.5284C31.0622 15.05 30.3486 14.9875 29.8702 15.3889L21.2062 22.6587C20.7278 23.0601 20.6654 23.7737 21.0668 24.2521L23.9749 27.7177C24.3763 28.1961 25.0898 28.2585 25.5682 27.8571L27.0914 26.5793L25.1384 24.2521Z"
      fill="#A56021"
    />
    <path
      d="M28.2956 55.2068C28.2956 37.2592 41.568 22.4136 58.8325 19.9438C57.1699 19.706 55.4714 19.5804 53.743 19.5804C34.0671 19.5804 18.1167 35.5309 18.1167 55.2068C18.1167 74.8827 34.0671 90.8331 53.743 90.8331C55.4714 90.8331 57.1699 90.7075 58.8325 90.4697C41.568 87.9996 28.2956 73.1543 28.2956 55.2068Z"
      fill="#E89600"
    />
    <path
      d="M34.2319 20.5869L25.5679 27.8567C25.0895 28.2581 24.376 28.1957 23.9746 27.7173L21.0668 24.2517C20.6654 23.7733 20.7278 23.0597 21.2062 22.6584L29.8702 15.3886C30.3486 14.9872 31.0622 15.0496 31.4636 15.528L34.3713 18.9936C34.7731 19.472 34.7106 20.1856 34.2319 20.5869Z"
      fill="#E89600"
    />
    <path
      d="M53.7426 85.8448C70.6639 85.8448 84.3813 72.1274 84.3813 55.2061C84.3813 38.2849 70.6639 24.5675 53.7426 24.5675C36.8214 24.5675 23.104 38.2849 23.104 55.2061C23.104 72.1274 36.8214 85.8448 53.7426 85.8448Z"
      fill="#FFEDCC"
    />
    <path
      d="M84.3757 55.692C84.3784 55.5302 84.3818 55.3687 84.3818 55.2065C84.3818 38.3122 70.6375 24.5679 53.7432 24.5679V55.6924H84.3757V55.692Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M53.7426 37.3428C52.5621 37.3428 51.605 36.3856 51.605 35.2052V29.505C51.605 28.3246 52.5621 27.3674 53.7426 27.3674C54.923 27.3674 55.8801 28.3246 55.8801 29.505V35.2052C55.8801 36.3856 54.923 37.3428 53.7426 37.3428Z"
      fill="#FFE181"
    />
    <path
      d="M53.7426 82.9456C52.5621 82.9456 51.605 81.9884 51.605 80.808V75.1078C51.605 73.9274 52.5621 72.9702 53.7426 72.9702C54.923 72.9702 55.8801 73.9274 55.8801 75.1078V80.808C55.8801 81.9884 54.923 82.9456 53.7426 82.9456Z"
      fill="#FFE181"
    />
    <path
      d="M71.5562 55.1667V55.1463C71.5562 53.9713 72.5086 53.0189 73.6836 53.0189H79.4041C80.5791 53.0189 81.5315 53.9713 81.5315 55.1463V55.1667C81.5315 56.3417 80.5791 57.2941 79.4041 57.2941H73.6836C72.5086 57.2941 71.5562 56.3417 71.5562 55.1667Z"
      fill="#FFE181"
    />
    <path
      d="M66.3384 67.7525C67.1731 66.9178 68.5265 66.9178 69.3616 67.7525L73.3921 71.783C74.2267 72.6177 74.2267 73.9711 73.3921 74.8061C72.5574 75.6408 71.2039 75.6408 70.3689 74.8061L66.3384 70.7756C65.5037 69.9406 65.5037 68.5871 66.3384 67.7525Z"
      fill="#FFE181"
    />
    <path
      d="M73.3921 35.5071C74.2267 36.3418 74.2267 37.6952 73.3921 38.5303L69.3616 42.5608C68.5269 43.3954 67.1734 43.3954 66.3384 42.5608C65.5037 41.7261 65.5037 40.3726 66.3384 39.5376L70.3689 35.5071C71.2039 34.6724 72.5574 34.6724 73.3921 35.5071Z"
      fill="#FFE181"
    />
    <path
      d="M25.9551 55.1667V55.1463C25.9551 53.9713 26.9075 53.0189 28.0825 53.0189H33.803C34.978 53.0189 35.9304 53.9713 35.9304 55.1463V55.1667C35.9304 56.3417 34.978 57.2941 33.803 57.2941H28.0825C26.9075 57.2941 25.9551 56.3417 25.9551 55.1667Z"
      fill="#FFF4C1"
    />
    <path
      d="M34.0933 35.5071C34.928 34.6724 36.2814 34.6724 37.1164 35.5071L41.147 39.5376C41.9816 40.3723 41.9816 41.7258 41.147 42.5608C40.3123 43.3954 38.9588 43.3954 38.1238 42.5608L34.0933 38.5303C33.2586 37.6952 33.2586 36.3418 34.0933 35.5071Z"
      fill="#FFE181"
    />
    <path
      d="M41.147 67.7527C41.9816 68.5874 41.9816 69.9409 41.147 70.7759L37.1164 74.8064C36.2818 75.6411 34.9283 75.6411 34.0933 74.8064C33.2586 73.9717 33.2586 72.6183 34.0933 71.7833L38.1238 67.7527C38.9588 66.9177 40.3123 66.9177 41.147 67.7527Z"
      fill="#FFE181"
    />
    <path
      d="M37.116 74.8066L38.5729 73.3496C37.7077 72.1763 36.9215 70.9409 36.228 69.6486L34.0928 71.7838C33.2581 72.6184 33.2581 73.9719 34.0928 74.8069C34.9275 75.6419 36.2813 75.6413 37.116 74.8066Z"
      fill="#FCE575"
    />
    <path
      d="M32.6053 55.2071C32.6053 54.4708 32.6393 53.7427 32.6908 53.0193H28.0825C26.9075 53.0193 25.9551 53.9717 25.9551 55.1467V55.167C25.9551 56.342 26.9075 57.2948 28.0825 57.2948H32.6837C32.6369 56.6036 32.6053 55.9091 32.6053 55.2071Z"
      fill="#FFE181"
    />
    <path
      d="M34.0933 35.5072C33.2586 36.3419 33.2586 37.6953 34.0933 38.5303L36.2624 40.6998C36.9587 39.4098 37.7489 38.1785 38.6165 37.0079L37.1161 35.5075C36.2814 34.6725 34.928 34.6725 34.0933 35.5072Z"
      fill="#FCE575"
    />
    <path
      d="M53.7431 61.9756C57.4815 61.9756 60.5121 58.945 60.5121 55.2066C60.5121 51.4682 57.4815 48.4376 53.7431 48.4376C50.0047 48.4376 46.9741 51.4682 46.9741 55.2066C46.9741 58.945 50.0047 61.9756 53.7431 61.9756Z"
      fill="#FDA402"
    />
    <path
      d="M63.2431 15.5089H44.2424C43.4929 15.5089 42.8853 14.9012 42.8853 14.1517V10.7587C42.8853 10.0092 43.4929 9.40149 44.2424 9.40149H63.2431C63.9927 9.40149 64.6003 10.0092 64.6003 10.7587V14.1517C64.6003 14.9012 63.9927 15.5089 63.2431 15.5089Z"
      fill="#FDA402"
    />
    <path
      d="M51.707 14.1517V10.7587C51.707 10.0092 52.3147 9.40149 53.0642 9.40149H44.2424C43.4929 9.40149 42.8853 10.0092 42.8853 10.7587V14.1517C42.8853 14.9012 43.4929 15.5089 44.2424 15.5089H53.0642C52.3147 15.5089 51.707 14.9012 51.707 14.1517Z"
      fill="#E89600"
    />
    <path
      d="M51.0457 55.207C51.0457 52.1838 53.0384 49.6184 55.7789 48.7518C55.1359 48.5486 54.4523 48.4376 53.7431 48.4376C50.0108 48.4376 46.9741 51.4743 46.9741 55.2066C46.9741 58.9389 50.0108 61.976 53.7431 61.976C54.4523 61.976 55.1359 61.865 55.7789 61.6618C53.0384 60.7955 51.0457 58.2301 51.0457 55.207Z"
      fill="#E89600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.3233 55.2067H50.3232L52.9006 38.8817C53.0641 37.8475 54.4226 37.8475 54.5858 38.8817L54.7613 39.9922L54.7612 39.9925L57.1635 55.2067H52.3591L52.359 55.207H50.3232L50.3233 55.2067Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M53.7424 59.4811C56.1035 59.4811 58.0176 57.567 58.0176 55.2059C58.0176 52.8448 56.1035 50.9308 53.7424 50.9308C51.3813 50.9308 49.4673 52.8448 49.4673 55.2059C49.4673 57.567 51.3813 59.4811 53.7424 59.4811Z"
      fill="#FFE181"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="84.3318"
        y1="36.5631"
        x2="56.715"
        y2="30.6272"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="60.0793"
        y1="43.0117"
        x2="50.3232"
        y2="43.0117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07BEDC" />
        <stop offset="1" stopColor="#0389B6" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyTimeLogIcon;
