import React from 'react';

export const Error403Icon = () => (
  <svg
    width="145"
    height="132"
    viewBox="0 0 145 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.3229 17C64.7058 17 13.073 42.7857 2.14992 63.4055C-8.13895 82.828 21.8442 96.708 21.8442 110.411C21.8442 138.663 61.4459 123.756 97.1999 116.896C132.954 110.036 152.636 79.6728 141.677 56.1207C130.719 32.5686 107.708 17 79.3229 17Z"
      fill="#EBF8F8"
    />
    <path
      d="M73.1552 43.1802L90.0777 109.795H56.2328L73.1552 43.1802Z"
      fill="#D8D8D8"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="56"
      y="43"
      width="35"
      height="67"
    >
      <path
        d="M73.1236 43.0336L90.0461 109.648H56.2012L73.1236 43.0336Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="53" y="90" width="37" height="9" fill="#C4C4C4" />
    </g>
    <path
      d="M20 27.3292C20 21.1409 25.0166 16.1243 31.205 16.1243H115.106C121.294 16.1243 126.311 21.1409 126.311 27.3292V83.0808C126.311 89.2691 121.294 94.2858 115.106 94.2858H31.205C25.0166 94.2858 20 89.2691 20 83.0808V27.3292Z"
      fill="#E2E2E2"
    />
    <path
      d="M20 80.8944H126.311V83.0807C126.311 89.2691 121.294 94.2857 115.106 94.2857H31.205C25.0166 94.2857 20 89.2691 20 83.0807V80.8944Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9751 103.304C28.5412 103.304 27.3789 102.142 27.3789 100.708C27.3789 99.2742 28.5412 98.1118 29.9751 98.1118C31.409 98.1118 32.5714 99.2742 32.5714 100.708C32.5714 102.142 31.409 103.304 29.9751 103.304ZM29.9751 106.037C27.0319 106.037 24.6459 103.651 24.6459 100.708C24.6459 97.7649 27.0319 95.3789 29.9751 95.3789C32.9184 95.3789 35.3043 97.7649 35.3043 100.708C35.3043 103.651 32.9184 106.037 29.9751 106.037Z"
      fill="#36B7BD"
    />
    <path
      d="M47.1925 109.863H33.5279L30.1117 89.0931H50.6086L47.1925 109.863Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M114.439 27.6013L98.2966 2.22492C96.4095 -0.741639 92.0793 -0.741639 90.1923 2.22492L74.0501 27.6012C72.0162 30.7984 74.3129 34.9814 78.1021 34.9814H110.387C114.176 34.9814 116.473 30.7984 114.439 27.6013Z"
      fill="#FFE181"
    />
    <path
      d="M81.3044 27.6012L97.4467 2.22502C97.5769 2.02027 97.7197 1.83121 97.8716 1.6548C95.8226 -0.725848 91.949 -0.536794 90.1922 2.22502L74.05 27.6012C72.0161 30.7983 74.3128 34.9813 78.102 34.9813H85.3565C81.5673 34.9813 79.2706 30.7983 81.3044 27.6012Z"
      fill="#FEC458"
    />
    <path
      d="M95.1078 21.8109H93.3808C92.4689 21.8109 91.7229 21.0648 91.7229 20.1529V9.79075C91.7229 8.87883 92.4689 8.13281 93.3808 8.13281H95.1078C96.0197 8.13281 96.7657 8.87883 96.7657 9.79075V20.1529C96.7657 21.0648 96.0197 21.8109 95.1078 21.8109Z"
      fill="white"
    />
    <path
      d="M94.2443 30.7649C95.6368 30.7649 96.7657 29.636 96.7657 28.2435C96.7657 26.8509 95.6368 25.722 94.2443 25.722C92.8518 25.722 91.7229 26.8509 91.7229 28.2435C91.7229 29.636 92.8518 30.7649 94.2443 30.7649Z"
      fill="white"
    />
    <path
      d="M57.264 43.6121C57.7802 43.0615 58.6449 43.0336 59.1955 43.5498C59.746 44.0659 59.7739 44.9307 59.2578 45.4812L56.0346 48.9193L59.2578 52.3574C59.7739 52.908 59.7461 53.7727 59.1955 54.2889C58.6449 54.805 57.7802 54.7771 57.264 54.2266L54.1615 50.9172L51.059 54.2266C50.5429 54.7771 49.6781 54.805 49.1276 54.2889C48.577 53.7727 48.5491 52.908 49.0653 52.3574L52.2885 48.9193L49.0653 45.4812C48.5491 44.9307 48.577 44.0659 49.1276 43.5498C49.6781 43.0336 50.5429 43.0615 51.059 43.6121L54.1615 46.9214L57.264 43.6121Z"
      fill="#2B2A36"
    />
    <path
      d="M95.2516 43.6121C95.7677 43.0615 96.6325 43.0336 97.183 43.5498C97.7336 44.0659 97.7615 44.9307 97.2453 45.4812L94.0221 48.9193L97.2453 52.3574C97.7615 52.908 97.7336 53.7727 97.183 54.2889C96.6325 54.805 95.7677 54.7771 95.2516 54.2266L92.1491 50.9172L89.0466 54.2266C88.5304 54.7771 87.6657 54.805 87.1151 54.2889C86.5646 53.7727 86.5367 52.908 87.0528 52.3574L90.276 48.9193L87.0528 45.4812C86.5367 44.9307 86.5646 44.0659 87.1151 43.5498C87.6657 43.0336 88.5304 43.0615 89.0466 43.6121L92.1491 46.9214L95.2516 43.6121Z"
      fill="#2B2A36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.559 62.8572C48.559 62.1025 49.1707 61.4907 49.9254 61.4907H96.5217C97.2764 61.4907 97.8881 62.1025 97.8881 62.8572C97.8881 63.6119 97.2764 64.2236 96.5217 64.2236H93.5155V67.9131C93.5155 68.8534 93.2732 70.3436 92.3811 71.6419C91.4345 73.0194 89.8305 74.0622 87.4154 74.0622C84.9475 74.0622 83.3841 72.9021 82.5036 71.4878C81.6788 70.163 81.4906 68.6922 81.4906 67.9131V64.2236H49.9254C49.1707 64.2236 48.559 63.6119 48.559 62.8572ZM88.8695 64.2236H90.7826V67.9131C90.7826 68.4758 90.6216 69.3769 90.1287 70.0942C89.6903 70.7322 88.9274 71.3292 87.4154 71.3292C85.9562 71.3292 85.2404 70.7129 84.8236 70.0434C84.351 69.2843 84.2236 68.3637 84.2236 67.9131V64.2236H86.1366V67.3665C86.1366 68.1212 86.7484 68.733 87.5031 68.733C88.2577 68.733 88.8695 68.1212 88.8695 67.3665V64.2236Z"
      fill="#2B2A36"
    />
    <circle cx="73.1553" cy="87.5901" r="3.14286" fill="#C4C4C4" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="30.1452"
        y1="97.0978"
        x2="48.6165"
        y2="93.1178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Error403Icon;
