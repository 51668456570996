import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';

import PaymentDetails from '../../payment-details';

import styles from './amount-details.module.scss';

const AmountDetailsModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation('ChangePlan');

  return (
    <Modal
      title={t('SumDetailsHeading')}
      with={980}
      visible={visible}
      destroyOnClose
      contentClassName={styles.root}
      onClose={onClose}
    >
      <PaymentDetails {...data} />
    </Modal>
  );
};

AmountDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    period: PropTypes.string,
    currency: PropTypes.string,
    tariffId: PropTypes.number,
    addons: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  onClose: PropTypes.func.isRequired
};

AmountDetailsModal.defaultProps = {
  data: {}
};

export default AmountDetailsModal;
