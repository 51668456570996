import produce from 'immer';
import { handleActions as ReduxHandleActions } from 'redux-actions';

export const handleActions = (actions, state) =>
  ReduxHandleActions(
    Object.keys(actions).reduce((acc, key) => {
      acc[key] = produce(actions[key]);

      return acc;
    }, {}),
    state
  );

export default handleActions;
