import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import LocationInput from 'components/common/controls/location-input';

import FormItem from '../form-item';

export const FormLocationInput = forwardRef(
  ({ rules, name, label, itemProps, inputRef, ...props }, ref) => {
    const { control, formState } = useFormContext();

    return (
      <FormItem
        label={label}
        name={name}
        {...itemProps}
        errors={formState.errors}
      >
        <Controller
          control={control}
          rules={rules}
          render={({ field }) => (
            <LocationInput
              {...field}
              {...props}
              ref={ref}
              inputRef={inputRef}
            />
          )}
        />
      </FormItem>
    );
  }
);

FormLocationInput.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormLocationInput.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormLocationInput;
