import React from 'react';

export const DissmissIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47649 7.00194C9.04201 7.38594 8.61661 7.78574 8.20101 8.20133C7.78542 8.61693 7.38562 9.04234 7.00162 9.47681C-2.71787 20.4738 -2.31808 37.2802 8.20101 47.7993C18.7201 58.3184 35.5265 58.7182 46.5235 48.9987C46.958 48.6147 47.3834 48.2149 47.799 47.7993C48.2146 47.3837 48.6144 46.9583 48.9984 46.5238C58.7179 35.5268 58.3181 18.7204 47.799 8.20133C37.2799 -2.31775 20.4735 -2.71755 9.47649 7.00194ZM9.48196 11.9572C1.12613 21.582 1.52411 36.1727 10.6759 45.3244C19.8277 54.4762 34.4184 54.8742 44.0432 46.5184L9.48196 11.9572ZM46.518 44.0435L11.9568 9.48228C21.5816 1.12645 36.1723 1.52443 45.3241 10.6762C54.4759 19.828 54.8739 34.4187 46.518 44.0435Z"
      fill="currentColor"
    />
  </svg>
);

export default DissmissIcon;
