import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import OnboardingModal from 'components/common/onboarding/modal';
// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';
import Typography from 'components/common/typography';

import Form from './form';
import useEditor from './use-editor';

// TODO: When moving the drawer to the general DrawerProvider,
// pay attention and take into account the logic of processing EDIT_EMPLOYEE_INFO deeplink
export const EmployeeEditorDrawer = ({ title, visible, ...drawerProps }) => {
  const { t } = useTranslation('FiilEmployeeProfile');

  const {
    defaultValues,
    isAfterRegister,
    isLoading,
    visibleOnboardingModal,
    onCloseOnboardingModal,
    handleDefaultValues,
    setDefaultValues,
    handleSubmit
  } = useEditor();

  useEffect(() => {
    if (visible) {
      handleDefaultValues();
    }

    return () => setDefaultValues(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Drawer
        {...drawerProps}
        visible={visible}
        width={480}
        title={<Drawer.Title>{title || t('EditEmployeeProfile')}</Drawer.Title>}
        destroyOnClose
      >
        <Typography.Paragraph>
          {t('FiilEmployeeProfileDesc')}
        </Typography.Paragraph>

        {defaultValues && (
          <Form
            isAfterRegister={isAfterRegister}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        )}
      </Drawer>

      <OnboardingModal
        visible={visibleOnboardingModal}
        onClose={onCloseOnboardingModal}
      />

      {/* <PhoneConfirmationModal
        visible={visiblePhoneConfirmation}
        employee={{ ...userEmployee, ...submitData }}
        onClose={() => setVisiblePhoneConfirmation(false)}
        callback={() => onSubmit(submitData)}
      /> */}
    </>
  );
};

EmployeeEditorDrawer.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool
};

EmployeeEditorDrawer.defaultProps = {
  title: undefined,
  visible: false
};

export default EmployeeEditorDrawer;
