import React from 'react';

export const WatchIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2 0.8C13.6 0.3 13 0 12.2 0H2.8C2 0 1.4 0.3 0.8 0.8C0.2 1.3 0 2 0 2.8V12.2C0 13 0.3 13.6 0.8 14.2C1.3 14.8 2 15 2.8 15H12.2C13 15 13.6 14.7 14.2 14.2C14.7 13.7 15 13 15 12.2V2.8C15 2 14.7 1.4 14.2 0.8ZM12.3 8.1C12.1 8.4 10.3 11.3 7.5 11.3C4.7 11.3 2.9 8.4 2.7 8.1C2.5 7.7 2.5 7.3 2.7 6.9C2.9 6.6 4.7 3.7 7.5 3.7C10.3 3.7 12.1 6.6 12.3 6.9C12.5 7.3 12.5 7.7 12.3 8.1Z"
      fill="#C32373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50156 5.59961C6.40156 5.59961 5.60156 6.49961 5.60156 7.49961C5.60156 8.49961 6.40156 9.39961 7.50156 9.39961C8.60156 9.39961 9.40156 8.49961 9.40156 7.49961C9.40156 6.39961 8.60156 5.59961 7.50156 5.59961ZM7.50156 8.29961C7.10156 8.29961 6.70156 7.99961 6.70156 7.49961C6.70156 7.09961 7.00156 6.69961 7.50156 6.69961C7.90156 6.69961 8.30156 6.99961 8.30156 7.49961C8.30156 7.89961 7.90156 8.29961 7.50156 8.29961Z"
      fill="#C32373"
    />
  </svg>
);

export default WatchIcon;
