import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { fetchDialoguesLocal } from 'store/dialogues';

import { getFullName } from 'utils/get-fio';

import CustomSelect from '../custom-select';
import DialogOption from '../custom-select/dialog-option';

const mapValue = channelChat => ({
  value: channelChat.id,
  label: {
    title: channelChat.contant
      ? getFullName(channelChat.contant)
      : channelChat.firstName,
    ...channelChat
  }
});

export const ChannelChatSelect = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const fetchData = params =>
    dispatch(
      fetchDialoguesLocal({ params: { isArchived: false, ...params } })
    ).then(({ count, results }) => ({
      totalItems: count,
      entries: results.map(mapValue)
    }));

  return (
    <CustomSelect
      fetchData={fetchData}
      ref={ref}
      isSearchable
      isAsync
      Option={DialogOption}
      {...props}
    />
  );
});

export default ChannelChatSelect;
