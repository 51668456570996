import React from 'react';

export const PaymentJournalIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    style={style}
  >
    <path
      d="M77.486 31.3645C79.0507 31.6695 80.5888 32.0408 82.127 32.4253C89.6188 34.4408 97.482 36.1116 104.947 37.8619L106.141 38.1536C111.219 39.3205 116.099 40.5271 120.581 41.8796C122.185 42.3703 123.71 42.8211 125.208 43.378C140.285 48.5627 149.169 55.975 141.439 70.4283C140.788 71.6756 140.341 73.0183 140.113 74.4063C139.118 80.3335 141.054 87.9447 142.181 95.5426C142.466 97.3481 142.656 99.1672 142.751 100.992C142.918 103.044 142.918 105.106 142.751 107.158C142.483 110.582 141.42 113.896 139.649 116.838C138.218 118.927 136.474 120.783 134.477 122.341C130.499 125.298 85.9326 152.441 47.1075 128.268C45.45 127.207 7.50008 102.836 14.8859 69.8448C22.1656 37.6895 65.2471 29.0838 77.486 31.3645Z"
      fill="#EBF8F8"
    />
    <path
      d="M102.501 13.2064L61.7077 18.0418C59.7733 18.2711 58.391 20.0252 58.6203 21.9597L59.8955 32.7177C60.1248 34.6521 61.8788 36.0344 63.8133 35.8051L104.607 30.9697C106.541 30.7404 107.924 28.9863 107.694 27.0519L106.419 16.2938C106.19 14.3594 104.436 12.9771 102.501 13.2064Z"
      fill="#32C9C9"
    />
    <path
      d="M90.3266 19.6732L49.5329 24.5086C47.5985 24.7379 46.2162 26.492 46.4455 28.4264L47.7207 39.1845C47.95 41.1189 49.704 42.5012 51.6385 42.2719L92.4322 37.4365C94.3666 37.2072 95.7489 35.4531 95.5196 33.5187L94.2444 22.7606C94.0151 20.8262 92.261 19.4439 90.3266 19.6732Z"
      fill="url(#paint0_linear_91847_77893)"
    />
    <path
      d="M129.412 39.811L134.464 82.4019C134.647 84.7742 133.884 87.1227 132.341 88.934C130.798 90.7453 128.601 91.8722 126.23 92.0684L57.0923 100.276C54.7406 100.639 52.3408 100.056 50.4177 98.6545C48.4945 97.2532 47.2043 95.1475 46.8291 92.7977L41.7241 50.2068C41.5372 47.8336 42.2991 45.4832 43.8428 43.6711C45.3865 41.859 47.5858 40.7331 49.9585 40.5403L119.149 32.3456C121.5 31.9754 123.901 32.5539 125.826 33.9537C127.75 35.3536 129.04 37.4604 129.412 39.811Z"
      fill="#FFE181"
    />
    <path
      d="M123.392 35.5402L129.412 86.1003C130.075 91.7225 126.469 96.7083 121.377 97.3713L52.1732 105.619C47.0682 106.229 42.4007 102.158 41.7244 96.5359L35.7309 45.936C35.0547 40.3138 38.6614 35.3281 43.7664 34.6651L112.957 26.4306C118.088 25.8472 122.743 29.918 123.392 35.5402Z"
      fill="#FDA402"
    />
    <path
      d="M130.897 52.3809L132.754 68.0143L98.5696 71.9923C96.4983 72.2385 94.414 71.6517 92.7752 70.3611C91.1365 69.0705 90.0775 67.1818 89.8314 65.1104C89.5852 63.039 90.1719 60.9547 91.4625 59.316C92.7532 57.6772 94.6419 56.6183 96.7133 56.3721L130.897 52.3809Z"
      fill="#FEC458"
    />
    <path
      d="M100.479 67.7354C102.588 67.7354 104.298 66.0256 104.298 63.9165C104.298 61.8074 102.588 60.0977 100.479 60.0977C98.3699 60.0977 96.6602 61.8074 96.6602 63.9165C96.6602 66.0256 98.3699 67.7354 100.479 67.7354Z"
      fill="white"
    />
    <path
      d="M89.3592 127.981L96.1453 86.082C96.6125 83.1976 94.6529 80.4807 91.7686 80.0135L14.3188 67.4694C11.4344 67.0022 8.71745 68.9617 8.25028 71.8461L1.46412 113.745C0.996951 116.63 2.95649 119.347 5.84089 119.814L83.2906 132.358C86.175 132.825 88.892 130.865 89.3592 127.981Z"
      fill="url(#paint1_linear_91847_77893)"
    />
    <path
      d="M94.9956 93.2359L7.10059 79L5.6929 87.6913L93.5879 101.927L94.9956 93.2359Z"
      fill="#2B2A36"
    />
    <path
      d="M41.1222 98.6695L16.0037 94.6012C13.141 94.1376 10.4449 96.0795 9.98185 98.9386C9.51878 101.798 11.4641 104.491 14.3268 104.955L39.4452 109.023C42.308 109.487 45.004 107.545 45.4671 104.686C45.9302 101.827 43.9849 99.1332 41.1222 98.6695Z"
      fill="white"
    />
    <path
      d="M86.3705 108.644L84.7263 118.827C84.6124 119.519 84.2289 120.138 83.6598 120.548C83.0907 120.958 82.3824 121.126 81.6898 121.015L65.9237 118.509C65.2292 118.398 64.6071 118.016 64.1943 117.447C63.7815 116.877 63.6118 116.167 63.7226 115.473L65.3668 105.289C65.4776 104.594 65.8597 103.972 66.4292 103.56C66.9986 103.147 67.7088 102.977 68.4033 103.088L84.1694 105.647C84.8559 105.758 85.4714 106.134 85.8831 106.695C86.2948 107.255 86.4699 107.955 86.3705 108.644Z"
      fill="#FFE181"
    />
    <path
      d="M70.9251 103.493L68.4277 118.912L71.0456 119.336L73.543 103.917L70.9251 103.493Z"
      fill="white"
    />
    <path
      d="M79.0345 104.815L76.5371 120.234L79.155 120.658L81.6524 105.239L79.0345 104.815Z"
      fill="white"
    />
    <path
      d="M64.7609 109.068L64.3369 111.686L85.3322 115.086L85.7562 112.468L64.7609 109.068Z"
      fill="white"
    />
    <path
      d="M149.169 109.412C148.081 114.915 143.149 118.575 138.15 117.593C133.151 116.612 130.021 111.295 131.042 105.832C132.063 100.369 137.076 96.6692 142.075 97.6636C147.074 98.6581 150.256 103.909 149.169 109.412Z"
      fill="#FEC458"
    />
    <path
      d="M145.602 108.71C145.291 109.754 144.683 110.685 143.853 111.39C143.022 112.096 142.005 112.544 140.924 112.682C139.843 112.819 138.746 112.64 137.765 112.166C136.785 111.691 135.963 110.942 135.4 110.009C134.837 109.076 134.558 108 134.595 106.911C134.633 105.822 134.986 104.768 135.611 103.876C136.237 102.984 137.108 102.293 138.119 101.887C139.13 101.481 140.237 101.378 141.306 101.589C142.817 101.969 144.117 102.93 144.922 104.264C145.726 105.598 145.971 107.196 145.602 108.71Z"
      fill="#FFE181"
    />
    <path
      d="M25.945 36.8534C26.7153 38.8109 26.6791 40.9938 25.8441 42.9246C25.0092 44.8554 23.4435 46.3769 21.4897 47.1564C19.4677 47.7346 17.2989 47.4887 15.4577 46.4724C13.6165 45.4561 12.2525 43.7521 11.6641 41.7331C10.8945 39.7739 10.9327 37.5896 11.7702 35.6586C12.6078 33.7275 14.1764 32.207 16.1327 31.4301C18.1529 30.8559 20.3182 31.1038 22.1564 32.1198C23.9946 33.1357 25.3565 34.8373 25.945 36.8534Z"
      fill="#FEC458"
    />
    <path
      d="M23.1331 37.8223C23.6013 39.001 23.5853 40.3168 23.0888 41.4838C22.5922 42.6508 21.6551 43.5746 20.4812 44.0545C19.2557 44.3996 17.9437 44.2479 16.8293 43.6324C15.7149 43.0169 14.8879 41.987 14.5274 40.766C14.0629 39.5871 14.0806 38.2727 14.5768 37.1067C15.073 35.9407 16.0078 35.0166 17.1794 34.5339C18.4049 34.1852 19.7186 34.3352 20.8339 34.9513C21.9492 35.5673 22.7757 36.5993 23.1331 37.8223Z"
      fill="#FFE181"
    />
    <defs>
      <linearGradient
        id="paint0_linear_91847_77893"
        x1="49.319"
        y1="39.4891"
        x2="95.876"
        y2="21.2052"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07BEDC" />
        <stop offset="1" stopColor="#0389B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_91847_77893"
        x1="1.3917"
        y1="99.9093"
        x2="96.2002"
        y2="99.9093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default PaymentJournalIcon;
