import React from 'react';
import PropTypes from 'prop-types';
import { Table as CommonTable } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';

import { getFullName } from 'utils/get-fio';

import styles from './acquaintence-sheet-drawer.module.scss';

export const Table = ({ dataSource, isLoading, ...props }) => {
  const { t } = useTranslation(['IntroductionList', 'Common']);

  const columns = [
    {
      dataIndex: 'step',
      title: '№',
      width: 80,
      render: (_, __, index) => index + 1
    },
    {
      dataIndex: 'responsible',
      title: t('FullName'),
      width: 175,
      render: employee => getFullName(employee)
    },
    {
      dataIndex: 'date',
      title: t('IntroductionDateAndTime'),
      width: 300,
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : null)
    },
    {
      dataIndex: 'status',
      title: t('Status'),
      render: status => (
        <Typography.Text color={status === t('Aquainted') ? 'brand' : 'red'}>
          {status}
        </Typography.Text>
      )
    }
  ];

  return (
    <CommonTable
      className={styles.table}
      data-qa="qa-qp9w5j67ke1seim"
      dataSource={dataSource}
      columns={columns}
      pagination={{ hideOnSinglePage: true, defaultPageSize: 10000 }}
      loading={isLoading}
      locale={{
        emptyText: t(isLoading ? 'Loading' : 'EmptyList', { ns: 'Common' })
      }}
      scroll={{ y: true }}
      {...props}
    />
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      responsible: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      }),
      status: PropTypes.string,
      date: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool
};

Table.defaultProps = {
  dataSource: [],
  isLoading: false
};

export default Table;
