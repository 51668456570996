import React from 'react';

export const GoogleUnsyncIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="150"
      height="150"
      transform="translate(0.5 0.000488281)"
      fill="white"
    />
    <path
      d="M147.009 78.8571C146.906 78.5315 145.707 75.5861 143.161 69.5622C143.161 69.5622 141.341 65.2403 139.357 61.5845C139.224 61.3477 139.15 61.2292 139.017 60.9924C138.617 60.282 138.218 59.5715 137.788 58.9499C137.374 58.2395 136.886 57.4994 136.456 56.8778C136.294 56.7298 136.249 56.5225 136.086 56.3745C135.45 55.3977 134.784 54.5096 134.073 53.5032C133.866 53.1479 133.541 52.8519 133.348 52.4967C132.475 51.2534 131.498 50.0841 130.432 48.8853C130.358 48.7669 130.27 48.7373 130.299 48.6485C130.136 48.5004 130.062 48.382 129.9 48.234C129.16 47.3312 128.301 46.3839 127.561 45.4811L127.487 45.3626C127.428 45.2442 127.324 45.2146 127.354 45.1258C127.191 44.9778 127.117 44.8594 126.954 44.7114C126.125 43.7789 125.178 42.8169 124.305 41.9732C120.22 37.9326 115.676 34.0695 110.806 30.6061C104.679 26.2991 97.9591 22.6284 90.8103 19.8458L90.4403 19.7422C89.5226 19.387 88.6198 19.0318 87.7021 18.7802L87.3321 18.6766L87.2285 18.647C81.7669 16.634 76.2758 15.4204 70.6514 14.7543C69.3045 14.5767 67.9133 14.5027 66.4332 14.3843C65.0567 14.3103 63.769 14.2511 62.5553 14.3103C61.238 14.3547 59.8912 14.4731 58.6479 14.6359C41.5824 17.2409 34.6556 32.471 27.5659 48.2636C26.219 51.2978 24.8574 54.4356 23.4069 57.4402C20.3135 63.9082 16.8649 70.169 12.2618 75.2161C4.78729 86.7016 2.90757 98.128 6.66701 107.408C6.82982 107.556 7.82148 109.036 8.32471 110.872C12.4838 117.946 20.1803 123.482 31.3254 126.368C40.1911 128.721 69.7338 138.712 97.4411 137.262C97.4411 137.262 101.497 137.084 106.943 136.285C126.629 133.31 133.2 126.605 134.843 123.941C135.598 122.638 135.909 121.528 135.909 121.528C136.456 118.464 134.147 115.919 135.391 108.148C137.981 93.0068 149.496 88.3741 147.024 78.8571H147.009Z"
      fill="#EBF8F8"
    />
    <path
      d="M74.7854 26.7634L81.4825 61.0779C82.2208 64.8863 79.7337 68.5781 75.9253 69.3165L24.2916 79.3945C20.4832 80.1329 16.7913 77.6458 16.053 73.8503L9.35587 39.5358C8.61751 35.7274 11.1046 32.0355 14.9001 31.2972L66.5338 21.2192C70.3422 20.4808 74.034 22.955 74.7854 26.7634Z"
      fill="white"
    />
    <path
      d="M75.2712 29.0071L9.80884 41.791L10.5611 45.6432L76.0235 32.8593L75.2712 29.0071Z"
      fill="#F1F1F1"
    />
    <path
      d="M74.7856 26.7632L75.511 30.4939L10.0945 43.2663L9.36906 39.5356C8.63069 35.7272 11.1178 32.0354 14.9133 31.297L15.276 31.2323L14.7708 28.6156C14.5765 27.6441 15.2112 26.6984 16.1957 26.5041C17.1802 26.3098 18.1129 26.9575 18.3072 27.9291L18.8124 30.5457L27.1287 28.9265L26.6235 26.3098C26.4292 25.3383 27.0639 24.3927 28.0484 24.1984C29.0329 24.0041 29.9655 24.6517 30.1599 25.6233L30.6651 28.2399L38.9814 26.6207L38.4762 24.0041C38.2819 23.0325 38.9166 22.0869 39.9011 21.8926C40.8856 21.6983 41.8182 22.346 42.0126 23.3175L42.5177 25.9342L50.8341 24.315L50.3289 21.6983C50.1346 20.7268 50.7693 19.7811 51.7538 19.5868C52.7383 19.3925 53.6709 20.0402 53.8653 21.0117L54.3705 23.6284L62.6868 22.0092L62.1816 19.3925C61.9873 18.421 62.622 17.4754 63.6065 17.2811C64.591 17.0867 65.5236 17.7344 65.7179 18.706L66.2231 21.3226L66.5729 21.2579C70.3813 20.5195 74.0731 22.9937 74.8245 26.8021L74.7856 26.7632Z"
      fill="url(#paint0_linear_70725_416402)"
    />
    <path
      d="M29.1735 47.0893L19.4603 48.9862C18.1894 49.2344 17.3604 50.4658 17.6086 51.7367L18.3882 55.7288C18.6364 56.9997 19.8678 57.8288 21.1387 57.5806L30.8519 55.6837C32.1228 55.4355 32.9519 54.2041 32.7037 52.9332L31.9241 48.9411C31.6759 47.6702 30.4444 46.8411 29.1735 47.0893Z"
      fill="#E2E2E2"
    />
    <path
      d="M49.0708 43.2055L39.3576 45.1024C38.0867 45.3506 37.2576 46.582 37.5058 47.8529L38.2854 51.845C38.5336 53.1159 39.765 53.945 41.0359 53.6968L50.7491 51.7999C52.02 51.5517 52.8491 50.3203 52.6009 49.0494L51.8213 45.0573C51.5731 43.7864 50.3417 42.9573 49.0708 43.2055Z"
      fill="#E2E2E2"
    />
    <path
      d="M68.9682 39.3212L59.255 41.2181C57.9841 41.4663 57.1551 42.6977 57.4032 43.9686L58.1828 47.9607C58.431 49.2316 59.6625 50.0607 60.9334 49.8125L70.6466 47.9156C71.9175 47.6675 72.7465 46.436 72.4984 45.1651L71.7188 41.173C71.4706 39.9021 70.2391 39.0731 68.9682 39.3212Z"
      fill="#E2E2E2"
    />
    <path
      d="M32.0546 61.7978L22.3414 63.6947C21.0705 63.9428 20.2415 65.1743 20.4897 66.4452L21.2693 70.4373C21.5175 71.7082 22.7489 72.5372 24.0198 72.2891L33.733 70.3922C35.0039 70.144 35.833 68.9126 35.5848 67.6417L34.8052 63.6496C34.557 62.3787 33.3255 61.5496 32.0546 61.7978Z"
      fill="#E2E2E2"
    />
    <path
      d="M51.9521 57.9008L42.2389 59.7977C40.968 60.0459 40.1389 61.2773 40.3871 62.5482L41.1667 66.5403C41.4149 67.8112 42.6464 68.6403 43.9173 68.3921L53.6305 66.4952C54.9014 66.247 55.7304 65.0156 55.4822 63.7447L54.7027 59.7526C54.4545 58.4817 53.223 57.6526 51.9521 57.9008Z"
      fill="#E2E2E2"
    />
    <path
      d="M71.8493 54.0166L62.1361 55.9134C60.8652 56.1616 60.0362 57.3931 60.2843 58.664L61.0639 62.656C61.3121 63.9269 62.5436 64.756 63.8145 64.5078L73.5277 62.611C74.7986 62.3628 75.6277 61.1313 75.3795 59.8604L74.5999 55.8683C74.3517 54.5974 73.1202 53.7684 71.8493 54.0166Z"
      fill="#E2E2E2"
    />
    <path
      d="M129.041 87.5668L125.612 122.193C125.226 126.035 121.809 128.832 117.955 128.458L65.8478 123.289C62.0062 122.902 59.2087 119.486 59.5826 115.644L63.0117 81.018C63.3984 77.1763 66.8146 74.3789 70.6563 74.7527L122.763 79.9222C126.605 80.3089 129.415 83.7252 129.041 87.5668Z"
      fill="white"
    />
    <path
      d="M128.841 89.8395L62.7864 83.293L62.4011 87.18L128.455 93.7265L128.841 89.8395Z"
      fill="#F1F1F1"
    />
    <path
      d="M129.042 87.5669L128.668 91.3312L62.6509 84.7824L63.0247 81.0181C63.4115 77.1765 66.8277 74.379 70.6693 74.7529L71.0303 74.7916L71.2881 72.1488C71.3912 71.1691 72.255 70.4472 73.2476 70.5374C74.2402 70.6276 74.9493 71.5171 74.859 72.4969L74.6012 75.1396L82.9935 75.9647L83.2513 73.3219C83.3545 72.3422 84.2182 71.6203 85.2108 71.7105C86.2035 71.8008 86.9125 72.6903 86.8222 73.67L86.5644 76.3127L94.9567 77.1378L95.2145 74.4951C95.3177 73.5153 96.1814 72.7934 97.174 72.8836C98.1667 72.9739 98.8757 73.8634 98.7854 74.8431L98.5276 77.4859L106.92 78.3238L107.178 75.6811C107.281 74.7013 108.145 73.9794 109.137 74.0696C110.13 74.1599 110.839 75.0494 110.749 76.0291L110.491 78.6719L118.883 79.4969L119.141 76.8542C119.244 75.8744 120.108 75.1525 121.1 75.2428C122.093 75.333 122.802 76.2225 122.712 77.2023L122.454 79.845L122.802 79.8837C126.644 80.2704 129.454 83.6866 129.08 87.5283L129.042 87.5669Z"
      fill="#518EF8"
    />
    <mask
      id="mask0_70725_416402"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="82"
      y="95"
      width="24"
      height="24"
    >
      <path
        d="M84.2067 95.4865L82.1025 116.718L103.334 118.822L105.438 97.5907L84.2067 95.4865Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_70725_416402)">
      <path
        d="M87.6469 108.787L86.6285 111.468L83.9213 111.262C83.2639 109.689 82.9803 107.923 83.1607 106.105C83.3412 104.288 83.9213 102.728 84.8366 101.335L87.1958 102.019L88.0079 104.507C87.7243 105.126 87.5309 105.809 87.4665 106.531C87.3891 107.304 87.4665 108.065 87.6469 108.787Z"
        fill="#FBBB00"
      />
      <path
        d="M104.406 106.208C104.47 106.866 104.457 107.536 104.406 108.206C104.328 108.967 104.174 109.702 103.955 110.398C103.194 112.783 101.634 114.768 99.6231 116.083L96.6065 115.632L96.4389 112.912C97.7538 112.306 98.8367 111.261 99.4813 109.947L93.8091 109.379L94.2216 105.177L99.9841 105.744L104.419 106.182L104.406 106.208Z"
        fill="#518EF8"
      />
      <path
        d="M99.5974 116.096C97.6379 117.372 95.2401 118.017 92.7263 117.772C88.6912 117.372 85.3911 114.768 83.9343 111.262L87.647 108.787C88.3045 111.262 90.4445 113.195 93.1517 113.466C94.3119 113.582 95.4334 113.376 96.4261 112.925L99.5974 116.096Z"
        fill="#28B446"
      />
      <path
        d="M101.363 99.6593L97.6508 102.134C96.7484 101.438 95.6397 100.974 94.415 100.845C91.6434 100.575 89.1167 102.122 88.0209 104.506L84.8496 101.335C86.9509 98.0995 90.741 96.1271 94.8404 96.5396C97.4187 96.7974 99.6876 97.9448 101.376 99.6593H101.363Z"
        fill="#F14336"
      />
    </g>
    <circle cx="69.8958" cy="75.1107" r="23.6815" fill="#F9716C" />
    <path
      d="M79.2945 63.7994L77.6019 66.3384L65.0066 85.6544L63.6127 87.7453L60.5261 85.7042L61.9201 83.6133L74.5153 64.347L76.2079 61.7583L79.2945 63.7994Z"
      fill="white"
    />
    <path
      d="M77.6017 66.338L65.0065 85.654C63.8615 85.1562 62.816 84.4592 61.9199 83.6129L74.5152 64.3467C75.6602 64.8445 76.7056 65.5415 77.6017 66.338Z"
      fill="white"
    />
    <path
      d="M69.7857 58.5718C60.7251 58.5718 53.3572 65.9397 53.3572 75.0003C53.3572 75.7969 53.407 76.5934 53.5065 77.3402C53.6559 78.535 54.0044 79.7298 54.4026 80.825C56.7922 86.9982 62.7663 91.4289 69.7857 91.4289C78.8464 91.4289 86.2143 84.061 86.2143 75.0003C86.2143 65.9397 78.8464 58.5718 69.7857 58.5718ZM69.7857 87.6952C67.894 87.6952 66.052 87.2471 64.4091 86.5003C63.2641 85.9527 62.2186 85.3055 61.3225 84.4592C59.9784 83.2644 58.9329 81.8207 58.1862 80.1778C57.7381 79.1822 57.3896 78.1367 57.2403 76.9917C57.1407 76.3445 57.0909 75.6475 57.0909 74.9506C57.0909 67.9311 62.8161 62.2558 69.7857 62.2558C71.6775 62.2558 73.4697 62.654 75.1126 63.4506C76.2576 63.9484 77.3031 64.6454 78.1992 65.4917C80.8377 67.8315 82.4806 71.2168 82.4806 75.0003C82.4806 82.0198 76.8052 87.6952 69.7857 87.6952Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_70725_416402"
        x1="8.62254"
        y1="35.7597"
        x2="74.0595"
        y2="22.9808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default GoogleUnsyncIcon;
