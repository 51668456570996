import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { CALENDAR, TYPE_ASSET } from 'constants/index';

import Typography from 'components/common/typography';
import EmployeeAvatar from 'components/common/avatar/employee';
import Actions from 'components/assets-view/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { TagList } from 'components/common/tags';
import SubscriptionAssetBtn from 'components/common/subscriptions/subscription-asset-btn';
import ValidityExpiration from 'components/assets-view/common/validity-expiration';
import Tooltip from 'components/common/tooltip';

import useModalsService from 'services/modals';
import useRoutesService from 'services/routes';

import styles from './card.module.scss';

const Card = ({
  asset,
  tagListOptions,
  style,
  ActionsComponent,
  entityData
}) => {
  const modals = useModalsService({ returnUrl: true });
  const routes = useRoutesService();

  return (
    <div className={styles.root} style={style}>
      <Link
        to={modals.assets.showDetails({ id: asset.id })}
        className={classnames(styles.flex, styles.link)}
      >
        <span>
          <ValidityExpiration
            dateEnd={asset.dateEnd}
            className={styles.expiration}
          >
            <Tooltip
              title={
                <>
                  {asset.title}
                  <br />
                  {asset.fullPath}
                </>
              }
              mouseEnterDelay={0.5}
            >
              <Typography.Text ellipsis>{asset.title}</Typography.Text>
            </Tooltip>
          </ValidityExpiration>

          {asset.fullPath && (
            <Typography.Text color="black-55" size="small" ellipsis>
              {asset.fullPath}
            </Typography.Text>
          )}
        </span>
      </Link>

      <div className={styles.flex}>
        <TagList
          entityType={TYPE_ASSET}
          entityId={asset.id}
          displayedCount={2}
          className={styles.tags}
          {...tagListOptions}
        />

        {asset.inventoryNumber && (
          <Typography.Text
            size="small"
            color="black-55"
            style={{ marginRight: 16, whiteSpace: 'nowrap' }}
          >
            {asset.inventoryNumber}
          </Typography.Text>
        )}

        {asset.responsible && (
          <div style={{ marginRight: 16 }}>
            <EmployeeAvatar employee={asset.responsible} />
          </div>
        )}

        <div className={styles.subscription}>
          <SubscriptionAssetBtn
            isSubscribed={asset.isSubscribed}
            assetId={asset.id}
            actionDeps={{ entityId: entityData.id }}
          />
        </div>

        <Button
          type="link"
          className={styles.calendar}
          onClick={() => routes.toAssets({ view: CALENDAR, asset })}
        >
          <Icon type="schedule" />
        </Button>

        {ActionsComponent ? (
          <ActionsComponent asset={asset} />
        ) : (
          <Actions
            isFromDetails={false}
            asset={asset}
            style={{ marginLeft: 16 }}
          />
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  tagListOptions: PropTypes.object,
  entityData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    allowEditDates: PropTypes.bool
  })
};

Card.defaultProps = {
  tagListOptions: {},
  entityData: {}
};

export default Card;
