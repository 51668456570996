import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormInputNumber, FormSwitch } from 'components/common/hook-form';
import Typography from 'components/common/typography';

import styles from './sla.module.scss';

const { Title, Text } = Typography;

const SlaSettings = ({ isOnlyView }) => {
  const { t } = useTranslation(['ConnectWidget', 'Errors']);

  const { control } = useFormContext();

  const isActiveWatch = useWatch({
    name: 'slaConfig.isActive',
    control
  });

  const isOperatorHoursWatch = useWatch({
    name: 'slaConfig.operatorSlaHours',
    control
  });

  const isOperatorMinutesWatch = useWatch({
    name: 'slaConfig.operatorSlaMinutes',
    control
  });

  const isExecutorHoursWatch = useWatch({
    name: 'slaConfig.executorSlaHours',
    control
  });

  const isExecutorMinutesWatch = useWatch({
    name: 'slaConfig.executorSlaMinutes',
    control
  });

  return (
    <>
      <Title level={3} className={styles.title}>
        {t('SLAHeading')}
      </Title>

      <FormSwitch
        name="slaConfig.isActive"
        label={t('SetSLAToggle')}
        disabled={isOnlyView}
      />

      {isActiveWatch && (
        <>
          <Title level={4} className={styles.subtitle}>
            {t('OperatorSLAHeading')}
          </Title>

          <Text size="small" className={styles.text}>
            {t('OperatorSLADesc')}
          </Text>

          <div className={styles.inputWrapper}>
            <FormInputNumber
              name="slaConfig.operatorSlaHours"
              label={t('Hours')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={3}
              rules={{
                required:
                  !isOperatorMinutesWatch &&
                  t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />

            <FormInputNumber
              name="slaConfig.operatorSlaMinutes"
              label={t('Minutes')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={2}
              rules={{
                required:
                  !isOperatorHoursWatch && t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />
          </div>

          <Title level={4} className={styles.subtitle}>
            {t('PerformerSLAHeading')}
          </Title>

          <Text size="small" className={styles.text}>
            {t('PerformerSLADesc')}
          </Text>

          <div className={styles.inputWrapper}>
            <FormInputNumber
              name="slaConfig.executorSlaHours"
              label={t('Hours')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={3}
              rules={{
                required:
                  !isExecutorMinutesWatch &&
                  t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />

            <FormInputNumber
              name="slaConfig.executorSlaMinutes"
              label={t('Minutes')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={2}
              rules={{
                required:
                  !isExecutorHoursWatch && t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />
          </div>
        </>
      )}
    </>
  );
};

SlaSettings.propTypes = {
  isOnlyView: PropTypes.bool
};

SlaSettings.defaultProps = {
  isOnlyView: false
};

export default SlaSettings;
