import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

export const EditableField = ({
  children,
  allowAdd,
  onAdd,
  onDelete,
  ...props
}) => (
  <div
    style={{
      display: 'flex'
    }}
  >
    {cloneElement(children, props)}

    {allowAdd && (
      <Button
        type="link"
        style={{ margin: '20px 0 0 8px', padding: 0 }}
        onClick={onAdd}
      >
        <Icon type="plus" size={20} />
      </Button>
    )}

    {onDelete && (
      <Button
        type="link"
        style={{ margin: '20px 0 0 8px', padding: 0 }}
        onClick={onDelete}
      >
        <Icon type="close" size={20} />
      </Button>
    )}
  </div>
);

EditableField.propTypes = {
  allowAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func
};

EditableField.defaultProps = {
  allowAdd: false,
  onAdd: () => {},
  onDelete: undefined
};

export default EditableField;
