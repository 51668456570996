import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/icon';

import styles from './value-remove.module.scss';

export const ValueRemove = ({ onClick, className, ...props }) => {
  const hasClick = typeof onClick === 'function';

  const onClickDelete = event => {
    event.stopPropagation();
    event.preventDefault();

    if (hasClick) {
      onClick();
    }
  };

  return (
    <Button
      type="text"
      className={classnames(styles.root, className)}
      onClick={onClickDelete}
      {...props}
    >
      <Icon type="close" size={16} />
    </Button>
  );
};

ValueRemove.propTypes = {
  onClick: PropTypes.func
};

ValueRemove.defaultProps = {
  onClick: undefined
};

export default ValueRemove;
