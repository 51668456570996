import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import classnames from 'classnames';

import { HEADER_HEIGHT } from '../../../../../constants/styles';

import styles from './header.module.scss';

export const Header = ({ className, children, style }) => (
  <Layout.Header
    className={classnames(styles.root, className)}
    style={{ height: HEADER_HEIGHT, ...style }}
  >
    {children}
  </Layout.Header>
);

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object
};

Header.defaultProps = {
  className: undefined,
  children: undefined,
  style: {}
};

export default Header;
