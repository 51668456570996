import React from 'react';

export const LetterInfoIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.6002 81.5C57.5002 81.5 88.8002 65.8 95.4002 53.2C101.6 41.4 83.5002 32.9 83.5002 24.6C83.5002 7.40001 59.5002 16.5 37.8002 20.7C16.1002 24.9 4.20017 43.4 10.8002 57.7C17.5002 72 31.4002 81.5 48.6002 81.5Z"
      fill="#D7F1F2"
    />
    <path
      d="M77.6001 60.4L55.0001 78.1C52.4001 80.1 48.8001 80.1 46.2001 78.1L23.6001 60.5L47.2001 41.8C49.3001 40.1 52.1001 40.1 54.2001 41.8L77.6001 60.4Z"
      fill="#FDA402"
    />
    <path
      d="M78.7002 26.3479C78.7002 27.3189 78.5474 28.2084 78.2418 29.0165C77.9363 29.8178 77.5051 30.4425 76.9483 30.8906C76.3983 31.3388 75.76 31.5629 75.0334 31.5629C74.497 31.5629 74.0318 31.4203 73.638 31.1351C73.2441 30.8499 72.9793 30.4628 72.8435 29.9739H72.7213C72.3885 30.5036 71.9777 30.9008 71.4888 31.1656C70.9999 31.4305 70.4499 31.5629 69.8387 31.5629C68.7319 31.5629 67.8593 31.2064 67.221 30.4934C66.5895 29.7804 66.2738 28.8162 66.2738 27.6007C66.2738 26.2019 66.6948 25.0679 67.5368 24.1987C68.3788 23.3227 69.5094 22.8848 70.9286 22.8848C71.4447 22.8848 72.0151 22.9323 72.6398 23.0274C73.2713 23.1156 73.8315 23.2413 74.3204 23.4042L74.0963 28.1405V28.385C74.0963 29.4714 74.4494 30.0147 75.1556 30.0147C75.6921 30.0147 76.1165 29.6684 76.4288 28.9758C76.748 28.2831 76.9075 27.4004 76.9075 26.3275C76.9075 25.1663 76.6699 24.1478 76.1945 23.2718C75.7192 22.3891 75.0436 21.71 74.1676 21.2347C73.2917 20.7594 72.2867 20.5217 71.1527 20.5217C69.7063 20.5217 68.4467 20.8205 67.3738 21.4181C66.3077 22.0156 65.4929 22.8712 64.9293 23.9848C64.3657 25.0916 64.0839 26.3784 64.0839 27.8451C64.0839 29.8144 64.6101 31.3286 65.6626 32.3879C66.7151 33.4472 68.226 33.9769 70.1952 33.9769C71.6959 33.9769 73.2611 33.6713 74.8908 33.0602V34.7306C73.4648 35.3146 71.9132 35.6065 70.236 35.6065C67.7235 35.6065 65.7645 34.9309 64.3589 33.5796C62.9533 32.2215 62.2505 30.3304 62.2505 27.9062C62.2505 26.134 62.6307 24.5586 63.3913 23.1802C64.1518 21.7949 65.2043 20.7356 66.5488 20.0023C67.9001 19.2689 69.4279 18.9022 71.1323 18.9022C72.6058 18.9022 73.9164 19.2078 75.0639 19.8189C76.2183 20.4301 77.1112 21.3026 77.7427 22.4366C78.381 23.5638 78.7002 24.8676 78.7002 26.3479ZM68.2294 27.6414C68.2294 29.2236 68.8507 30.0147 70.0934 30.0147C71.4039 30.0147 72.1203 29.0199 72.2426 27.0303L72.3648 24.5959C71.937 24.4805 71.4786 24.4228 70.9897 24.4228C70.1205 24.4228 69.4415 24.7114 68.9526 25.2886C68.4705 25.8657 68.2294 26.65 68.2294 27.6414Z"
      fill="#36B7BD"
    />
    <path
      d="M74.3002 39.9V80.7C74.3002 84 72.1002 86.8 69.1002 87.8C68.4002 88 67.6002 88.2 66.8002 88.2H33.7002C32.9002 88.2 32.1002 88.1 31.4002 87.8C28.4002 86.8 26.2002 84 26.2002 80.7V39.9C26.2002 35.8 29.6002 32.4 33.7002 32.4H66.8002C70.9002 32.4 74.3002 35.8 74.3002 39.9Z"
      fill="white"
    />
    <path
      d="M74.3001 61.2V81.7C74.3001 85 72.1001 87.8 69.1001 88.8H31.3001C28.3001 87.8 26.1001 85 26.1001 81.7V60.6L46.2001 76.2C48.8001 78.2 52.4001 78.2 55.0001 76.2L74.3001 61.2Z"
      fill="#F0F0F0"
    />
    <path
      d="M77.7002 60.4V86.2C77.7002 88.7 75.7002 90.7 73.2002 90.7H28.0002C25.5002 90.7 23.5002 88.7 23.5002 86.2V60.4L23.6002 60.5L46.2002 78.1C48.8002 80.1 52.4002 80.1 55.0002 78.1L77.7002 60.4Z"
      fill="#FFE181"
    />
    <path
      d="M36.8 40.3H58.7001C59.2001 40.3 59.7001 40.7 59.7001 41.3C59.7001 41.8 59.3001 42.3 58.7001 42.3H36.8C36.3 42.3 35.8 41.9 35.8 41.3C35.8 40.8 36.3 40.3 36.8 40.3Z"
      fill="#2B2A36"
    />
    <path
      d="M36.8 45.3H50.7001C51.2001 45.3 51.7001 45.7 51.7001 46.3C51.7001 46.8 51.3001 47.3 50.7001 47.3H36.8C36.3 47.3 35.8 46.9 35.8 46.3C35.8 45.7 36.3 45.3 36.8 45.3Z"
      fill="#2B2A36"
    />
    <path
      d="M55.7002 45.3H58.7002C59.2002 45.3 59.7002 45.7 59.7002 46.3C59.7002 46.8 59.3002 47.3 58.7002 47.3H55.7002C55.2002 47.3 54.7002 46.9 54.7002 46.3C54.7002 45.7 55.1002 45.3 55.7002 45.3Z"
      fill="#2B2A36"
    />
    <path
      d="M36.8 50.3H53.7001C54.2001 50.3 54.7001 50.7 54.7001 51.3C54.7001 51.8 54.3001 52.3 53.7001 52.3H36.8C36.3 52.3 35.8 51.9 35.8 51.3C35.8 50.7 36.3 50.3 36.8 50.3Z"
      fill="#2B2A36"
    />
    <path
      d="M36.8 55.2H58.7001C59.2001 55.2 59.7001 55.6 59.7001 56.2C59.7001 56.7 59.3001 57.2 58.7001 57.2H36.8C36.3 57.2 35.8 56.8 35.8 56.2C35.8 55.7 36.3 55.2 36.8 55.2Z"
      fill="#2B2A36"
    />
    <path
      d="M36.8 60.2H43.8C44.3 60.2 44.8 60.6 44.8 61.2C44.8 61.7 44.4 62.2 43.8 62.2H36.8C36.3 62.2 35.8 61.8 35.8 61.2C35.8 60.6 36.3 60.2 36.8 60.2Z"
      fill="#2B2A36"
    />
    <path
      d="M48.7002 60.2H58.6002C59.1002 60.2 59.6002 60.6 59.6002 61.2C59.6002 61.7 59.2002 62.2 58.6002 62.2H48.7002C48.2002 62.2 47.7002 61.8 47.7002 61.2C47.7002 60.6 48.2002 60.2 48.7002 60.2Z"
      fill="#2B2A36"
    />
    <path
      d="M36.8 65.2H58.7001C59.2001 65.2 59.7001 65.6 59.7001 66.2C59.7001 66.7 59.3001 67.2 58.7001 67.2H36.8C36.3 67.2 35.8 66.8 35.8 66.2C35.8 65.6 36.3 65.2 36.8 65.2Z"
      fill="#2B2A36"
    />
  </svg>
);

export default LetterInfoIcon;
