import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { fetchGptPrompts } from 'store/gpt';

import styles from './ai-assistant-prompts.module.scss';

const ControlsAiAssistantPrompts = ({
  hasAiAssistantMention,
  entityType,
  onClick
}) => {
  const dispatch = useDispatch();

  const [prompts, setPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('AIAssistant');

  const visible = hasAiAssistantMention && !isLoading && !!prompts.length;

  const fetch = async () => {
    try {
      setIsLoading(true);

      const results = await dispatch(fetchGptPrompts({ entityType }));
      setPrompts(results);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasAiAssistantMention) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAiAssistantMention]);

  return (
    <div className={classnames(styles.root, { [styles.visible]: visible })}>
      <Typography.Paragraph weight="semibold" className={styles.title}>
        {t('HowCanIHelpHeading')}
      </Typography.Paragraph>

      <div className={styles.prompts}>
        {prompts.map(prompt => (
          <Button
            key={`ai-assistant-prompt-${prompt.id}`}
            type="tertiary"
            size="small"
            onClick={() => onClick(prompt)}
          >
            {prompt.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

ControlsAiAssistantPrompts.propTypes = {
  hasAiAssistantMention: PropTypes.bool,
  entityType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

ControlsAiAssistantPrompts.defaultProps = {
  hasAiAssistantMention: false
};

export default ControlsAiAssistantPrompts;
