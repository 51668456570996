import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import Option from '../option';

import styles from '../common-option/common-option.module.scss';

const { Paragraph, Text } = Typography;

export const AssetOption = ({
  option,
  className,
  isLinkToElement,
  ...props
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const AssetOptionContent = () => (
    <>
      <Paragraph
        style={{
          margin: '4px 0px 4px',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ marginRight: 10, whiteSpace: 'normal' }}>
          <Text color={isLinkToElement ? 'brand' : 'black'}>
            {option.title}
          </Text>

          {option.inventoryNumber && (
            <Text color="brand">{` ${option.inventoryNumber}`}</Text>
          )}
        </div>

        {option.fullPath && (
          <Text color="black-55" size="small" style={{ whiteSpace: 'normal' }}>
            {option.fullPath}
          </Text>
        )}
      </Paragraph>
    </>
  );

  return (
    <Option
      className={classnames(className, styles.root)}
      title={option.title}
      {...props}
    >
      {isLinkToElement ? (
        <Link
          to={`${routes.toAssets()}${modals.assets.showDetails({
            id: option.id
          })}`}
        >
          <AssetOptionContent />
        </Link>
      ) : (
        <AssetOptionContent />
      )}
    </Option>
  );
};

AssetOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  showId: PropTypes.bool,
  showIcon: PropTypes.bool,
  isLinkToElement: PropTypes.bool
};

AssetOption.defaultProps = {
  showId: true,
  showIcon: true,
  isLinkToElement: false
};

export default AssetOption;
