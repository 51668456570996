import React from 'react';

export const BigTeamIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88484 11.6374C5.86361 11.4322 5.85181 11.2245 5.85181 11.0145C5.85181 10.6393 5.8872 10.2736 5.95327 9.9173C5.96979 9.83236 5.92496 9.74505 5.84709 9.70966C5.52619 9.56573 5.23124 9.36752 4.97641 9.11741C4.67613 8.82625 4.43983 8.4757 4.28261 8.08812C4.12538 7.70054 4.05067 7.28444 4.06325 6.86637C4.08449 6.10895 4.38887 5.38928 4.91978 4.84657C5.50259 4.2496 6.28597 3.92398 7.1189 3.93342C7.87161 3.9405 8.59836 4.23072 9.14814 4.74511C9.33454 4.91972 9.495 5.11321 9.62949 5.32085C9.67668 5.394 9.76871 5.42467 9.84893 5.39635C10.2642 5.25242 10.7031 5.15096 11.1538 5.10377C11.2859 5.08961 11.3614 4.94803 11.3024 4.83006C10.5356 3.31285 8.96881 2.2652 7.15666 2.23688C4.54224 2.19677 2.362 4.34162 2.362 6.95603C2.362 8.43785 3.04391 9.75921 4.1128 10.6252C3.36246 10.972 2.6711 11.451 2.07177 12.0504C0.778719 13.3411 0.0472505 15.0447 5.90016e-05 16.8639C-0.000570781 16.8891 0.00384659 16.9141 0.0130506 16.9376C0.0222546 16.961 0.0360592 16.9824 0.0536508 17.0004C0.0712425 17.0185 0.0922654 17.0328 0.115481 17.0426C0.138696 17.0524 0.163634 17.0574 0.188825 17.0574H1.51255C1.61401 17.0574 1.69895 16.9772 1.70131 16.8757C1.74615 15.5071 2.30065 14.2259 3.27515 13.2537C3.96887 12.56 4.81831 12.0787 5.74563 11.8451C5.83529 11.8191 5.89664 11.7318 5.88484 11.6374Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5147 11.0145C16.5147 8.43313 14.4407 6.33547 11.8687 6.29535C9.25432 6.25524 7.07643 8.4001 7.07643 11.0145C7.07643 12.4963 7.76071 13.8177 8.82724 14.6836C8.06912 15.0351 7.37897 15.5175 6.78856 16.1088C5.49551 17.3995 4.76404 19.1031 4.71685 20.92C4.71622 20.9452 4.72064 20.9702 4.72985 20.9937C4.73905 21.0171 4.75285 21.0385 4.77044 21.0565C4.78804 21.0746 4.80906 21.0889 4.83227 21.0987C4.85549 21.1085 4.88043 21.1135 4.90562 21.1135H6.22698C6.32844 21.1135 6.41339 21.0333 6.41575 20.9318C6.46058 19.5632 7.01508 18.282 7.98959 17.3099C9.00656 16.2929 10.3562 15.7302 11.7956 15.7302C13.2349 15.7302 14.5846 16.2894 15.6016 17.3064C16.5761 18.2786 17.1306 19.5598 17.1754 20.9284C17.1778 21.0298 17.2627 21.1101 17.3642 21.1101H18.6856C18.7107 21.1101 18.7357 21.105 18.7589 21.0953C18.7821 21.0855 18.8031 21.0711 18.8207 21.0531C18.8383 21.0351 18.8521 21.0137 18.8613 20.9903C18.8705 20.9668 18.8749 20.9418 18.8743 20.9166C18.8271 19.0997 18.0957 17.3961 16.8026 16.1054C16.2122 15.5141 15.522 15.0316 14.7639 14.6802C15.8171 13.8149 16.5147 12.4846 16.5147 11.0145ZM11.7956 14.0348C12.6026 14.0348 13.36 13.7209 13.931 13.1499C14.502 12.5789 14.8158 11.8215 14.8182 11.0145C14.8182 10.1981 14.4973 9.43123 13.9121 8.85785C13.3411 8.29863 12.5648 7.98717 11.7649 7.99425C10.9556 8.00369 10.1981 8.32695 9.63185 8.9074C9.09151 9.46191 8.7824 10.2099 8.77532 10.9838C8.77034 11.3857 8.84609 11.7845 8.99808 12.1566C9.15008 12.5287 9.37522 12.8665 9.66017 13.1499C10.2312 13.7209 10.9886 14.0348 11.7956 14.0348Z"
      fill="currentColor"
    />
    <path
      d="M18.1152 11.6375C18.1364 11.4323 18.1482 11.2246 18.1482 11.0146C18.1482 10.6394 18.1128 10.2737 18.0467 9.91741C18.0302 9.83246 18.075 9.74516 18.1529 9.70977C18.4738 9.56583 18.7688 9.36763 19.0236 9.11751C19.3239 8.82636 19.5602 8.47581 19.7174 8.08822C19.8746 7.70064 19.9493 7.28454 19.9368 6.86648C19.9155 6.10905 19.6111 5.38938 19.0802 4.84668C18.4974 4.24971 17.714 3.92408 16.8811 3.93352C16.1284 3.9406 15.4016 4.23083 14.8519 4.74522C14.6655 4.91983 14.505 5.11331 14.3705 5.32095C14.3233 5.3941 14.2313 5.42477 14.1511 5.39646C13.7358 5.25253 13.2969 5.15106 12.8462 5.10387C12.7141 5.08971 12.6386 4.94814 12.6976 4.83016C13.4644 3.31295 15.0312 2.2653 16.8433 2.23699C19.4578 2.19687 21.638 4.34173 21.638 6.95614C21.638 8.43795 20.9561 9.75932 19.8872 10.6253C20.6375 10.9721 21.3289 11.4511 21.9282 12.0505C23.2213 13.3412 23.9527 15.0448 23.9999 16.864C24.0006 16.8892 23.9962 16.9142 23.9869 16.9377C23.9777 16.9611 23.9639 16.9825 23.9464 17.0005C23.9288 17.0186 23.9077 17.0329 23.8845 17.0427C23.8613 17.0525 23.8364 17.0575 23.8112 17.0575H22.4875C22.386 17.0575 22.301 16.9773 22.2987 16.8758C22.2539 15.5072 21.6994 14.226 20.7248 13.2538C20.0311 12.5601 19.1817 12.0788 18.2544 11.8452C18.1647 11.8192 18.1034 11.7319 18.1152 11.6375Z"
      fill="currentColor"
    />
  </svg>
);

export default BigTeamIcon;
