import { SEARCH } from 'constants/api';

export default api => ({
  fetchEmployees: (
    workspaceId,
    search,
    offset,
    limit,
    vatNumber,
    isIndividual,
    ordering
  ) =>
    api.get(`/v1/${workspaceId}/${SEARCH}/employees/`, {
      params: {
        search,
        ordering,
        limit,
        offset,
        vat_number: vatNumber,
        is_individual: isIndividual
      }
    }),

  fetchUsers: (search, limit, offset, workspace, ordering) =>
    api.get(`/v1/${workspace}/${SEARCH}/users/`, {
      params: { search, ordering, limit, offset }
    })
});
