import React from 'react';

export const TasksIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.6 92.5C62.5 92.6 61.3 92.6 60.2 92.6C54.5 92.5 48.7 92.7 43.1 92.8C42.8 92.8 42.5 92.8 42.2 92.8C38.4 92.9 34.7 92.8 31.3 92.7C30.1 92.6 28.9 92.6 27.8 92.5C16.1 91.3 8.59999 87.5 11.5 75.3C11.7 74.3 11.8 73.2 11.7 72.2C11.4 67.6 8.69999 62.4 6.59999 56.9C6.09999 55.6 5.59999 54.3 5.19999 53C4.69999 51.5 4.39999 49.9 4.09999 48.4C3.69999 45.8 3.79999 43.2 4.59999 40.7C4.59999 40.7 5.39999 38.1 7.29999 35.7C9.59999 32.7 36.4 4.60003 68.1 15.4C69.6 15.9 100.7 27 101.2 52.8C101.7 77.8 72.7 92 63.6 92.5Z"
      fill="#EBF8F8"
    />
    <path
      d="M60.3 30.9L61.9 38.6L42.5 84.3L33.9 86.1C30.9 86.7 28 84.8 27.3 81.8L18.5 39.6C17.9 36.6 19.8 33.7 22.8 33L53.7 26.6C56.7 25.9 59.7 27.9 60.3 30.9Z"
      fill="#2D9EA3"
    />
    <path
      d="M61.9 38.5001L69.1 73.1001C69.7 76.1001 67.8 79.0001 64.8 79.7001L42.5 84.3001L61.9 38.5001Z"
      fill="#36B7BD"
    />
    <path
      d="M56.9134 30.5918L21.4773 37.9839L30.8909 83.1112L66.3271 75.7191L56.9134 30.5918Z"
      fill="white"
    />
    <path
      d="M54.2 29.5L54.7 32.1L24.1 38.5L23.6 35.9C25.6 35.5 27.2 34.9 28.3 34.4C30.9 33.1 33.3 32 34.4 29.7C34.7 29.1 35.1 27.9 36.3 27.2C36.9 26.8 37.6 26.7 37.7 26.7C39.3 26.4 40.9 27 41.9 28.2C45.3 31.3 54.2 29.5 54.2 29.5Z"
      fill="#C4C4C4"
    />
    <path
      d="M38.3 31.5C39.0732 31.5 39.7 30.8732 39.7 30.1C39.7 29.3268 39.0732 28.7 38.3 28.7C37.5268 28.7 36.9 29.3268 36.9 30.1C36.9 30.8732 37.5268 31.5 38.3 31.5Z"
      fill="#2D9EA3"
    />
    <path
      d="M54.4916 31.4338L23.95 37.8048L24.0929 38.49L54.6346 32.119L54.4916 31.4338Z"
      fill="#D8D8D8"
    />
    <path
      d="M55.7 39.1L38.4 42.7C37.9 42.8 37.3 42.5 37.2 41.9C37.1 41.4 37.4 40.8 38 40.7L55.3 37.1C55.8 37 56.4 37.3 56.5 37.9C56.6 38.4 56.3 39 55.7 39.1Z"
      fill="#2D9EA3"
    />
    <path
      d="M53 44.5L39.3 47.3C38.8 47.4 38.2 47.1 38.1 46.5C38 46 38.3 45.4 38.9 45.3L52.6 42.5C53.1 42.4 53.7 42.7 53.8 43.3C53.9 43.9 53.5 44.4 53 44.5Z"
      fill="#2D9EA3"
    />
    <path
      d="M32.4 49.3L28.5 50.1C27.4 50.3 26.4 49.6 26.1 48.5L25.3 44.6C25.1 43.5 25.8 42.5 26.9 42.2L30.8 41.4C31.9 41.2 32.9 41.9 33.2 43L34 47C34.2 48 33.5 49.1 32.4 49.3Z"
      fill="#E2E2E2"
    />
    <path
      d="M58.6 52.9001L41.3 56.5001C40.8 56.6001 40.2 56.3001 40.1 55.7001C40 55.2001 40.3 54.6001 40.9 54.5001L58.2 50.9001C58.7 50.8001 59.3 51.1001 59.4 51.7001C59.4 52.3001 59.1 52.8001 58.6 52.9001Z"
      fill="#2D9EA3"
    />
    <path
      d="M55.8 58.3001L42.1 61.1001C41.6 61.2001 41 60.9001 40.9 60.3001C40.8 59.8001 41.1 59.2001 41.7 59.1001L55.4 56.3001C55.9 56.2001 56.5 56.5001 56.6 57.1001C56.7 57.7001 56.4 58.2001 55.8 58.3001Z"
      fill="#2D9EA3"
    />
    <path
      d="M35.2 63.1001L31.3 63.9001C30.2 64.1001 29.2 63.4001 28.9 62.3001L28.1 58.4001C27.9 57.3001 28.6 56.3001 29.7 56.0001L33.6 55.2001C34.7 55.0001 35.7 55.7001 36 56.8001L36.8 60.7001C37 61.9001 36.3 62.9001 35.2 63.1001Z"
      fill="#E2E2E2"
    />
    <path
      d="M61.4 66.7L44.1 70.3C43.6 70.4 43 70.1 42.9 69.5C42.8 69 43.1 68.4 43.7 68.3L61 64.7C61.5 64.6 62.1 64.9 62.2 65.5C62.3 66.1 61.9 66.6 61.4 66.7Z"
      fill="#2D9EA3"
    />
    <path
      d="M58.7 72.1001L45 74.9001C44.5 75.0001 43.9 74.7001 43.8 74.1001C43.7 73.6001 44 73.0001 44.6 72.9001L58.3 70.1001C58.8 70.0001 59.4 70.3001 59.5 70.9001C59.5 71.5001 59.2 72.0001 58.7 72.1001Z"
      fill="#2D9EA3"
    />
    <path
      d="M38.1 77L34.2 77.8C33.1 78 32.1 77.3 31.8 76.2L31 72.3C30.8 71.2 31.5 70.2 32.6 69.9L36.5 69.1C37.6 68.9 38.6 69.6 38.9 70.7L39.7 74.6C39.9 75.7 39.2 76.7 38.1 77Z"
      fill="#E2E2E2"
    />
    <path
      d="M75.0667 22.1561L60.2544 58.6921L65.9377 60.9962L80.7501 24.4602L75.0667 22.1561Z"
      fill="#FDA402"
    />
    <path
      d="M77.9015 23.3354L63.0892 59.8714L65.9309 61.0235L80.7432 24.4875L77.9015 23.3354Z"
      fill="#FCC977"
    />
    <path
      d="M60.2501 58.7198L65.945 60.9831L63.0976 63.6115L60.4692 65.9479L60.3961 62.5164L60.2501 58.7198Z"
      fill="white"
    />
    <path
      d="M60.3962 62.5164L63.0976 63.6115L60.4692 65.9479L60.3962 62.5164Z"
      fill="#FDA402"
    />
  </svg>
);

export default TasksIcon;
