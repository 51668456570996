import { createAction } from 'redux-actions';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getWorkspaceId } from '../workspace';
import {
  getCurrentPageCompanies,
  getItemsPerPageCompanies,
  getContactsCompaniesSearchFilter,
  getContactsResponsibleFilter,
  getOrderingCompanies
} from './selectors';
import mapValue from '../../utils/map-value';
import { ITEMS_PER_PAGE } from '../../constants';

export const fetchContactsCompanies = createActionThunk(
  'contacts-companies/fetch',
  ({ kind, lite, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const search = getContactsCompaniesSearchFilter(state);
    const responsible = (getContactsResponsibleFilter(state) || []).map(
      mapValue
    );
    const page = getCurrentPageCompanies(state);
    const itemsPerPage = getItemsPerPageCompanies(state);
    const offset = itemsPerPage * (page - 1);
    const ordering = getOrderingCompanies(state);

    const limit = ITEMS_PER_PAGE;

    return api.contactsCompanies
      .fetch({
        workspaceId,
        kind,
        responsible,
        ordering,
        search,
        limit,
        offset,
        lite
      })
      .then(({ data }) => ({
        results: data.results,
        totalItems: data.count,
        search
      }));
  }
);

export const fetchContactsCompaniesLocal = createActionThunk(
  'contacts-companies/fetch-local',
  ({
    limit = ITEMS_PER_PAGE,
    offset,
    search,
    isIndividual,
    onlyHasContacts,
    getState
  }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .fetch({
        workspaceId,
        search,
        limit,
        offset,
        isIndividual,
        onlyHasContacts
      })
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count
      }));
  }
);

export const fetchContactsCompanyLocal = createActionThunk(
  'contacts/fetch-contacts-company',
  ({ id, getState }) => {
    const state = getState();
    const workspace = getWorkspaceId(state);

    return api.contactsCompanies.fetchOne(id, workspace);
  }
);

export const fetchContactsCompaniesSearchLocal = createActionThunk(
  'contacts/fetch-local',
  ({ limit, getState, search, country, vatNumber, errorHandle, offset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .search({
        workspaceId,
        country,
        search,
        vatNumber,
        limit,
        offset,
        errorHandle
      })
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count
      }));
  }
);

export const createContactsCompanyLocal = createActionThunk(
  'contacts/create-contacts-company-local',
  ({ contact, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .create(workspaceId, contact)
      .then(({ data }) => data);
  }
);

export const editContactsCompaniesLocal = createActionThunk(
  'contacts/edit-contacts-company-local',
  ({ contact, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies.edit(workspaceId, contact);
  }
);

export const editContactCompanies = createActionThunk(
  'contacts-companies/edit',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .edit(workspaceId, {
        id,
        ...values
      })
      .then(({ data }) => data);
  }
);

export const fetchContactsCompanyHistoryLocal = createActionThunk(
  'contacts-companies/fetch-history-local',
  ({ id, limit, offset, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .fetchHistory({ workspaceId, id, limit, offset })
      .then(({ data }) => ({
        totalItems: data.count,
        entries: data.results
      }));
  }
);

export const sendContactsCompanyCommentLocal = createActionThunk(
  'contacts-companies/send-comment-local',
  ({ id, data, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contactsCompanies
      .sendComment({ workspaceId, id, data })
      .then(({ data: response }) => response);
  }
);

export const setFilterSearch = createAction(
  'contacts-companies/set-filter-search'
);

export const setFilterResponsible = createAction(
  'contacts-companies/set-filter-responsible'
);

export const setFilterIsCustomers = createAction(
  'contacts-companies/set-filter-is-customers'
);

export const clearFilterCompanies = createAction(
  'contacts-companies/clear-filter'
);
export const setCurrentPage = createAction(
  'contacts-companies/set-current-page'
);
export const clearError = createAction('contacts-companies/clear-error');
export const setSorting = createAction('contacts-companies/set-sorting');
