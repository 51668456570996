import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Spin } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import api from 'api';
import { Translation } from 'react-i18next';

import styles from './styles.module.scss';

const validationStates = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error'
};

const doValidate = _.debounce((code, resolve, reject) => {
  api.promo
    .validatePromoCode(code)
    .then(({ data }) =>
      resolve(
        _.get(
          data,
          'message',
          <Translation ns="SignUp">{t => t('PromocodeSuccess')}</Translation>
        )
      )
    )
    .catch(({ response }) =>
      reject(
        _.get(
          response,
          'data.code.0',
          <Translation ns="SignUp">
            {t => t('PromocodeErrorNotFound')}
          </Translation>
        )
      )
    );
}, 400);

export const PromoCode = ({
  label,
  formItemClassName,
  form,
  initialValue,
  decoratorProps,
  errorMessage,
  rules,
  name,
  ...props
}) => {
  const [validation, setValidation] = useState({
    state: validationStates.EMPTY,
    message: null
  });
  const [isValidating, setIsValidating] = useState(false);
  const { getFieldDecorator, getFieldValue } = form;

  const validatorPromoCode = (__, value, callback) => {
    if (_.isEmpty(value)) {
      setValidation({ state: validationStates.EMPTY, message: null });
      return callback();
    }

    setIsValidating(true);

    const createOnResponse = isSuccess => message => {
      setIsValidating(false);
      callback(isSuccess ? undefined : true);
      setValidation({
        state: isSuccess ? validationStates.SUCCESS : validationStates.ERROR,
        message
      });
    };

    return doValidate(value, createOnResponse(true), createOnResponse(false));
  };

  useEffect(() => {
    setValidation({ state: validationStates.EMPTY, message: null });
  }, [getFieldValue(name)]);

  return (
    <Form.Item
      label={label}
      className={classnames(styles.root, formItemClassName)}
    >
      {getFieldDecorator(name, {
        initialValue,
        ...decoratorProps,
        rules: [{ validator: validatorPromoCode }, ...rules]
      })(
        <Input
          suffix={
            <Spin
              spinning={isValidating}
              size="small"
              style={{ display: 'flex' }}
            />
          }
          autocomplete="nope"
          {...props}
        />
      )}

      {validation.message && !!getFieldValue(name) && (
        <div
          className={classnames(styles.message, {
            [styles.success]: validation.state === validationStates.SUCCESS,
            [styles.error]: validation.state === validationStates.ERROR
          })}
        >
          {validation.message}
        </div>
      )}
    </Form.Item>
  );
};

PromoCode.defaultProps = {
  initialValue: undefined,
  decoratorProps: {},
  rules: [],
  label: '',
  formItemClassName: ''
};

PromoCode.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  decoratorProps: PropTypes.object,
  rules: PropTypes.array,
  label: PropTypes.string,
  formItemClassName: PropTypes.string
};

export default PromoCode;
