import qs from 'qs';

export default api => ({
  fetch: ({ workspaceId, ...params }) =>
    api
      .get(`/v1/${workspaceId}/channels/`, {
        params,
        paramsSerializer(p) {
          return qs.stringify(p, { arrayFormat: 'comma' });
        }
      })
      .then(({ data }) => data),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/channels/${id}/`).then(({ data }) => data),

  create: ({ workspaceId, ...chanel }) =>
    api.post(`/v1/${workspaceId}/channels/`, chanel).then(({ data }) => data),

  edit: ({ workspaceId, id, ...chanel }) =>
    api
      .patch(`/v1/${workspaceId}/channels/${id}/`, chanel)
      .then(({ data }) => data),

  delete: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/channels/${id}/`).then(({ data }) => data),

  fetchOperators: ({ workspaceId, id, operators }) =>
    api
      .get(`/v1/${workspaceId}/channels/${id}/operators/`, { operators })
      .then(({ data }) => data),

  operators: ({ workspaceId, id, operators }) =>
    api
      .put(`/v1/${workspaceId}/channels/${id}/operators/`, { operators })
      .then(({ data }) => data),

  notify: ({ workspaceId, id, value }) =>
    api
      .put(`/v1/${workspaceId}/channels/${id}/notify/`, { value })
      .then(({ data }) => data),

  getUrlAttachment: ({ workspaceId, id, fileId, isView }) =>
    api
      .get(`/v1/${workspaceId}/channels/${id}/attachments/${fileId}/`, {
        params: {
          view: isView || undefined
        }
      })
      .then(({ data }) => data),

  sourceExistence: ({ workspaceId, source, kind }) =>
    api
      .post(`/v1/${workspaceId}/channels/check-source-existence/`, {
        source,
        kind
      })
      .then(({ data }) => data),

  emailAuthentication: ({ workspaceId, data }) =>
    api
      .post(
        `/v1/${workspaceId}/channels/check-email-authentication/`,
        {
          email: data.email,
          password: data.password,
          imapServer: data.imapServer,
          imapPort: data.imapPort,
          isImapSsl: data.isImapSsl,
          smtpServer: data.smtpServer,
          smtpPort: data.smtpPort,
          isSmtpSsl: data.isSmtpSsl
        },
        { hide428: true }
      )
      .then(({ data: response }) => response),

  fetchUserChannelGroups: ({ workspaceId }) =>
    api
      .get(`/v1/${workspaceId}/channels/user-channel-groups/`)
      .then(({ data }) => data),

  updateEmailChannelPassword: ({ workspaceId, id, credentials }) =>
    api
      .patch(
        `/v1/${workspaceId}/channels/${id}/update-email-source/`,
        credentials,
        { hide428: true }
      )
      .then(({ data }) => data)
});
