import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './popconfirm.module.scss';

export const PopConfirm = ({
  children,
  style,
  placement,
  overlayClassName,
  ...props
}) => {
  const { t } = useTranslation('Common');

  return (
    <Popconfirm
      cancelButtonProps={{
        size: 'default',
        className: styles.btnCancel
      }}
      overlayClassName={classnames(styles.popover, overlayClassName)}
      overlayStyle={style}
      okText={t('YesBtn')}
      cancelText={t('NoBtn')}
      icon={null}
      style={{ maxWidth: 202 }}
      placement={placement || 'bottomLeft'}
      {...props}
    >
      {children}
    </Popconfirm>
  );
};

PopConfirm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  overlayClassName: PropTypes.string
};

PopConfirm.defaultProps = {
  children: undefined,
  overlayClassName: undefined
};

export default PopConfirm;
