import { useDispatch, useSelector } from 'react-redux';

import {
  getCollapsedSider,
  setCollapsedSider
} from '../../../../../store/user';
import useLocalStorage from '../../../../../hooks/common/use-local-storage';
import { COLLAPSED_SIDER } from '.';

export const useCollapsedSider = () => {
  const dispatch = useDispatch();
  const [, setStorageCollapsed] = useLocalStorage(COLLAPSED_SIDER);
  const collapsed = useSelector(getCollapsedSider);

  const setSollapsed = value => {
    setStorageCollapsed(value);
    dispatch(setCollapsedSider(value));
  };

  return [collapsed, setSollapsed];
};

export default useCollapsedSider;
