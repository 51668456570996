import React from 'react';

export const TimerIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5576 3.88914V2.02547H14.4083C14.9675 2.02547 15.421 1.57201 15.421 1.01273C15.421 0.453462 14.9676 0 14.4083 0H9.58766C9.02838 0 8.57492 0.453462 8.57492 1.01273C8.57492 1.57201 9.02838 2.02547 9.58766 2.02547H10.4384V3.88914C5.59845 4.6414 1.88245 8.83703 1.88245 13.8844C1.88245 19.4621 6.42031 23.9999 11.998 23.9999C17.5756 23.9999 22.1135 19.4621 22.1135 13.8844C22.1135 8.83703 18.3975 4.6414 13.5576 3.88914ZM11.998 21.9745C7.53707 21.9745 3.90792 18.3453 3.90792 13.8845C3.90792 9.42369 7.53715 5.79446 11.998 5.79446C16.4588 5.79446 20.088 9.42369 20.088 13.8845C20.088 18.3453 16.4589 21.9745 11.998 21.9745Z"
      fill="currentColor"
    />
    <path
      d="M13.0089 13.4635V8.69242C13.0089 8.13315 12.5554 7.67969 11.9961 7.67969C11.4369 7.67969 10.9834 8.13315 10.9834 8.69242V14.0478C10.9834 14.2125 11.049 14.3704 11.1657 14.4866L13.9988 17.3078C14.3952 17.7025 15.0363 17.701 15.4311 17.3048C15.8257 16.9084 15.8243 16.2672 15.4281 15.8725L13.0089 13.4635Z"
      fill="currentColor"
    />
  </svg>
);

export default TimerIcon;
