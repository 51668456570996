import React from 'react';
import { Modal } from 'antd';
import { Translation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { ServerErrorIcon } from 'components/common/icons';
import Icon from 'components/common/icon';

import theme from 'theme';
import getSupportLink from 'utils/get-support-link';

const { Title, Text, Paragraph } = Typography;

/**
 * Конфиг для создания модалки при отправке запроса, если отключился интерент
 * Используем api Antd (Modal.info()).
 * Открытие через redux не получится
 */
export const configNoInternetConnection = {
  width: 440,
  icon: null,
  closable: true,
  cancelText: 'awdwd',
  content: () => {
    const supportLink = getSupportLink();

    return (
      <div
        style={{
          padding: '50px 24px 32px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        <Icon
          component={ServerErrorIcon}
          style={{ justifyContent: 'center', marginBottom: 16 }}
        />

        <Title level={1} weight="bold" style={{ marginBottom: 20 }}>
          <Translation ns="ScreenErrors">
            {t => t('NoInternetConnectionHeading')}
          </Translation>
        </Title>

        <Text style={{ display: 'block', marginBottom: 20 }} color="black-45">
          <Translation ns="ScreenErrors">
            {t => t('NoInternetConnectionDesc')}
          </Translation>
        </Text>

        <Button
          size="large"
          type="primary"
          style={{ margin: '0 auto' }}
          onClick={() => window.location.reload()}
        >
          <Translation ns="ScreenErrors">{t => t('TryAgainBtn')}</Translation>
        </Button>

        <Paragraph color="black-45" style={{ margin: '20px 0 0' }}>
          <Translation ns="ScreenErrors">{t => t('SupportDesc')}</Translation>
          <br />
          <a href={supportLink.href} target={supportLink.target}>
            <Translation ns="ScreenErrors">{t => t('SupportBtn')}</Translation>
          </a>
        </Paragraph>

        <Button
          size="large"
          type="text"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            fontSize: 10,
            height: 50,
            padding: 20,
            color: theme.color['black-55']
          }}
          onClick={() => Modal.destroyAll()}
        >
          <Icon type="close" />
        </Button>
      </div>
    );
  }
};

export default configNoInternetConnection;
