export const timezonesOptions = [
  {
    value: 'Australia/Adelaide',
    label: 'AustraliaAdelaide',
    ns: 'Timezone'
  },
  {
    value: 'Atlantic/Azores',
    label: 'AtlanticAzores',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Almaty',
    label: 'AsiaAlmaty',
    ns: 'Timezone'
  },
  {
    value: 'America/Anchorage',
    label: 'AmericaAnchorage',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Astrakhan',
    label: 'EuropeAstrakhan',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Baghdad',
    label: 'AsiaBaghdad',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Bangkok',
    label: 'AsiaBangkok',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Barnaul',
    label: 'AsiaBarnaul',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Belgrade',
    label: 'EuropeBelgrade',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Berlin',
    label: 'EuropeBerlin',
    ns: 'Timezone'
  },
  {
    value: 'America/Bogota',
    label: 'AmericaBogota',
    ns: 'Timezone'
  },
  {
    value: 'Australia/Brisbane',
    label: 'AustraliaBrisbane',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Bucharest',
    label: 'EuropeBucharest',
    ns: 'Timezone'
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'AmericaArgentinaBuenosAires',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Vladivostok',
    label: 'AsiaVladivostok',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Volgograd',
    label: 'EuropeVolgograd',
    ns: 'Timezone'
  },
  {
    value: 'America/Halifax',
    label: 'AmericaHalifax',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Hong_Kong',
    label: 'AsiaHongKong',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Honolulu',
    label: 'PacificHonolulu',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Guam',
    label: 'PacificGuam',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Dhaka',
    label: 'AsiaDhaka',
    ns: 'Timezone'
  },
  {
    value: 'Australia/Darwin',
    label: 'AustraliaDarwin',
    ns: 'Timezone'
  },
  {
    value: 'America/Denver',
    label: 'AmericaDenver',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Dubai',
    label: 'AsiaDubai',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'AsiaYekaterinburg',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Jerusalem',
    label: 'AsiaJerusalem',
    ns: 'Timezone'
  },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'AmericaIndianaIndianapolis',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Irkutsk',
    label: 'AsiaIrkutsk',
    ns: 'Timezone'
  },
  {
    value: 'Africa/Johannesburg',
    label: 'AfricaJohannesburg',
    ns: 'Timezone'
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'AtlanticCapeVerde',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Kabul',
    label: 'AsiaKabul',
    ns: 'Timezone'
  },
  {
    value: 'Africa/Cairo',
    label: 'AfricaCairo',
    ns: 'Timezone'
  },
  {
    value: 'America/Caracas',
    label: 'AmericaCaracas',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Karachi',
    label: 'AsiaKarachi',
    ns: 'Timezone'
  },
  {
    value: 'Africa/Casablanca',
    label: 'AfricaCasablanca',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Kathmandu',
    label: 'AsiaKathmandu',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Kiev',
    label: 'EuropeKiev',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Kirov',
    label: 'EuropeKirov',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Kolkata',
    label: 'AsiaKolkata',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Colombo',
    label: 'AsiaColombo',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'AsiaKrasnoyarsk',
    ns: 'Timezone'
  },
  {
    value: 'Africa/Lagos',
    label: 'AfricaLagos',
    ns: 'Timezone'
  },
  {
    value: 'Europe/London',
    label: 'EuropeLondon',
    ns: 'Timezone'
  },
  {
    value: 'America/Los_Angeles',
    label: 'AmericaLosAngeles',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Magadan',
    label: 'AsiaMagadan',
    ns: 'Timezone'
  },
  {
    value: 'America/Managua',
    label: 'AmericaManagua',
    ns: 'Timezone'
  },
  {
    value: 'America/Mexico_City',
    label: 'AmericaMexicoCity',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Minsk',
    label: 'EuropeMinsk',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Moscow',
    label: 'EuropeMoscow',
    ns: 'Timezone'
  },
  {
    value: 'Africa/Nairobi',
    label: 'AfricaNairobi',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Novosibirsk',
    label: 'AsiaNovosibirsk',
    ns: 'Timezone'
  },
  {
    value: 'America/Godthab',
    label: 'AmericaGodthab',
    ns: 'Timezone'
  },
  {
    value: 'America/New_York',
    label: 'AmericaNewYork',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Auckland',
    label: 'PacificAuckland',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Omsk',
    label: 'AsiaOmsk',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Pago_Pago',
    label: 'PacificPagoPago',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Paris',
    label: 'EuropeParis',
    ns: 'Timezone'
  },
  {
    value: 'Australia/Perth',
    label: 'AustraliaPerth',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Kamchatka',
    label: 'AsiaKamchatka',
    ns: 'Timezone'
  },
  {
    value: 'America/Regina',
    label: 'AmericaRegina',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Samara',
    label: 'EuropeSamara',
    ns: 'Timezone'
  },
  {
    value: 'America/Sao_Paulo',
    label: 'AmericaSaoPaulo',
    ns: 'Timezone'
  },
  {
    value: 'America/Santiago',
    label: 'AmericaSantiago',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Saratov',
    label: 'EuropeSaratov',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Sakhalin',
    label: 'AsiaSakhalin',
    ns: 'Timezone'
  },
  {
    value: 'America/St_Johns',
    label: 'AmericaStJohns',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Seoul',
    label: 'AsiaSeoul',
    ns: 'Timezone'
  },
  {
    value: 'Australia/Sydney',
    label: 'AustraliaSydney',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Simferopol',
    label: 'EuropeSimferopol',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Singapore',
    label: 'AsiaSingapore',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Istanbul',
    label: 'EuropeIstanbul',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Taipei',
    label: 'AsiaTaipei',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Tbilisi',
    label: 'AsiaTbilisi',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Tehran',
    label: 'AsiaTehran',
    ns: 'Timezone'
  },
  {
    value: 'America/Tijuana',
    label: 'AmericaTijuana',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Tokyo',
    label: 'AsiaTokyo',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Tomsk',
    label: 'AsiaTomsk',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Tongatapu',
    label: 'PacificTongatapu',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Ulyanovsk',
    label: 'EuropeUlyanovsk',
    ns: 'Timezone'
  },
  {
    value: 'America/Noronha',
    label: 'AmericaNoronha',
    ns: 'Timezone'
  },
  {
    value: 'Pacific/Fiji',
    label: 'PacificFiji',
    ns: 'Timezone'
  },
  {
    value: 'America/Phoenix',
    label: 'AmericaPhoenix',
    ns: 'Timezone'
  },
  {
    value: 'Europe/Helsinki',
    label: 'EuropeHelsinki',
    ns: 'Timezone'
  },
  {
    value: 'Australia/Hobart',
    label: 'AustraliaHobart',
    ns: 'Timezone'
  },
  {
    value: 'America/Chicago',
    label: 'AmericaChicago',
    ns: 'Timezone'
  },
  {
    value: 'America/Chihuahua',
    label: 'AmericaChihuahua',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Riyadh',
    label: 'AsiaRiyadh',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Yakutsk',
    label: 'AsiaYakutsk',
    ns: 'Timezone'
  },
  {
    value: 'Asia/Yangon',
    label: 'AsiaYangon',
    ns: 'Timezone'
  }
].sort((a, b) => a.label.localeCompare(b.label));

export const daysTranslations = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday'
};

export const COLLECT_CLIENT_CONTACT = 'collect-client-contact';
export const NOT_DISPLAY = 'not-display';

export const WIDGET_DISPLAY_OPTIONS = [
  {
    label: 'CollectContactsRadio',
    ns: 'ConnectWidget',
    value: COLLECT_CLIENT_CONTACT
  },
  {
    label: 'DontShowRadio',
    ns: 'ConnectWidget',
    value: NOT_DISPLAY
  }
];
