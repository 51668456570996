import { useEffect, useState } from 'react';
import api from 'api';
import { useParams } from 'react-router-dom';

export const useFeedback = () => {
  const { feedbackUuid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [data, setData] = useState(null);
  const [rating, setRating] = useState(null);

  const fetch = async () => {
    try {
      setIsLoading(true);

      const { data: result } = await api.feedbacks.fetchFeedback({
        feedbackUuid
      });

      setData(result);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitFeedback = async ({ content }) => {
    try {
      setIsLoadingSubmit(true);

      const { data: result } = await api.feedbacks.sendFeedback({
        feedbackUuid,
        payload: { content, rating }
      });

      setData(result);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    data,
    onSubmitFeedback,
    isLoadingSubmit,
    setRating,
    rating
  };
};

export default useFeedback;
