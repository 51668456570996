import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import {
  FormInput,
  FormCurrencySelect,
  FormTextarea,
  numberOrLatinPattern,
  FormSwitch
} from 'components/common/hook-form';

const MAX_FIELDS_COUNT = 10;

export const AccountFields = ({ control, name, workspace }) => {
  const { t } = useTranslation(['EditLegalInfo', 'Errors']);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name
  });

  const validateLengthWithoutSpace = value => {
    if (value && value.replace(/\s/g, '').length < 13) {
      return t('MinCheckingAccount', { ns: 'Errors', value: 13 });
    }

    if (value && value.replace(/\s/g, '').length > 50) {
      return t('MaxCheckingAccount', { ns: 'Errors', value: 50 });
    }

    return true;
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16
            }}
          >
            <Typography.Text>{`${t('CurrentAccountNumber')} ${index +
              1}`}</Typography.Text>

            {fields.length > 1 && (
              <Button
                type="text"
                mood="none"
                style={{
                  display: 'flex',
                  height: 'auto',
                  padding: 0
                }}
                onClick={() => remove(index)}
              >
                <Icon type="close" color="black-55" />
              </Button>
            )}
          </div>

          <FormSwitch
            name={`${name}.${index}.isIbanFormat`}
            defaultChecked={field.isIbanFormat || true}
            onClick={value => update(value, index)}
            label={t('UseIBANToggle')}
          />

          <div
            style={{
              display: 'grid',
              gap: 16,
              gridTemplateColumns: '262px 100px 1fr'
            }}
          >
            <FormInput
              label={t('CurrentAccountNumber')}
              placeholder={t('CurrentAccountNumber')}
              name={`${name}.${index}.iban`}
              defaultValue={field.iban}
              rules={{
                pattern: {
                  value:
                    field.isIbanFormat &&
                    new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{9,30}$/),
                  message: t('InvalidIban', { ns: 'Errors' })
                },
                validate: validateLengthWithoutSpace,
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            <FormCurrencySelect
              label={t('Currency')}
              valueText=""
              name={`${name}.${index}.currency`}
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            <FormInput
              label={t('BankCode')}
              placeholder={t('BankCode')}
              name={`${name}.${index}.swift`}
              defaultValue={field.swift}
              rules={{
                pattern: numberOrLatinPattern,
                minLength: {
                  value: 8,
                  message: t('ValidateMinLength', { ns: 'Errors', value: 8 })
                },
                maxLength: {
                  value: 11,
                  message: t('ValidateMaxLength', { ns: 'Errors', value: 11 })
                },
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />
          </div>

          <FormTextarea
            label={t('AdditionalInfo')}
            name={`${name}.${index}.additionalInfo`}
            placeholder={t('AdditionalInfoPlchldr')}
            defaultValue={field.additionalInfo}
            autoSize={{
              minRows: 4,
              maxRows: 4
            }}
            itemProps={{ style: { marginBottom: 20 } }}
            style={{ resize: 'none' }}
          />
        </div>
      ))}

      {fields.length < MAX_FIELDS_COUNT && (
        <Button
          type="link"
          size="large"
          style={{ display: 'flex', alignItems: 'center', padding: 0 }}
          onClick={() =>
            append({
              iban: undefined,
              currency: workspace.country.currencies[0],
              swift: undefined,
              additionalInfo: undefined,
              isIbanFormat: true
            })
          }
        >
          <Icon type="plus" />
          {t('AddCurrentAccountBtn')}
        </Button>
      )}
    </>
  );
};

export default AccountFields;
