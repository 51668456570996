import React from 'react';

export const InterviewIcon = () => (
  <svg
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.3238 13.9479C96.2251 12.6365 115.126 14.6856 127.651 27.5379C141.65 41.8819 150.601 62.8979 144.765 82.0451C139.175 100.209 117.782 104.995 101.028 114.012C82.6515 123.848 65.3568 141.323 45.4883 135.077C23.5051 128.175 6.39064 106.684 4.52182 83.7172C2.88251 62.9471 22.1444 48.4719 37.3081 34.1607C48.9472 23.1281 62.324 15.1119 78.3238 13.9479Z"
      fill="#EBF8F8"
    />
    <path
      d="M116.471 27.4887C114.414 27.9234 112.453 28.7288 110.684 29.8657C108.297 31.3986 106.283 33.4451 104.789 35.8558C103.294 38.2665 102.356 40.9806 102.045 43.7999C101.96 44.5291 101.916 45.2624 101.914 45.9966V118.29L101.045 120.471L40.4885 120.159L40.1934 46.1441C40.187 43.6298 40.6766 41.139 41.6343 38.8142C42.592 36.4895 43.9989 34.3765 45.7745 32.5964C47.5501 30.8162 49.6594 29.4039 51.9817 28.4402C54.304 27.4765 56.7935 26.9805 59.3078 26.9805C71.6355 26.7182 81.6025 26.5871 89.5696 26.4723C95.8481 26.374 101.799 25.2592 110.766 26.4723C113.192 26.8166 115.159 27.21 116.471 27.4887Z"
      fill="white"
    />
    <path
      d="M71.6356 51.3079H48.6852C48.2504 51.3079 47.8335 51.1352 47.526 50.8278C47.2186 50.5204 47.0459 50.1034 47.0459 49.6686C47.0459 49.2338 47.2186 48.8169 47.526 48.5094C47.8335 48.202 48.2504 48.0293 48.6852 48.0293H71.6356C72.0704 48.0293 72.4874 48.202 72.7948 48.5094C73.1022 48.8169 73.2749 49.2338 73.2749 49.6686C73.2749 50.1034 73.1022 50.5204 72.7948 50.8278C72.4874 51.1352 72.0704 51.3079 71.6356 51.3079Z"
      fill="#2D9EA3"
    />
    <path
      d="M93.8647 43.3901H48.6852C48.2504 43.3901 47.8335 43.2174 47.526 42.9099C47.2186 42.6025 47.0459 42.1855 47.0459 41.7508C47.0459 41.316 47.2186 40.899 47.526 40.5916C47.8335 40.2842 48.2504 40.1115 48.6852 40.1115H93.8647C94.2995 40.1115 94.7164 40.2842 95.0239 40.5916C95.3313 40.899 95.504 41.316 95.504 41.7508C95.504 42.1855 95.3313 42.6025 95.0239 42.9099C94.7164 43.2174 94.2995 43.3901 93.8647 43.3901Z"
      fill="#2D9EA3"
    />
    <path
      d="M89.2415 51.3079H80.799C80.3642 51.3079 79.9472 51.1352 79.6398 50.8278C79.3324 50.5204 79.1597 50.1034 79.1597 49.6686C79.1597 49.2338 79.3324 48.8169 79.6398 48.5094C79.9472 48.202 80.3642 48.0293 80.799 48.0293H89.2415C89.6762 48.0293 90.0932 48.202 90.4006 48.5094C90.7081 48.8169 90.8808 49.2338 90.8808 49.6686C90.8808 50.1034 90.7081 50.5204 90.4006 50.8278C90.0932 51.1352 89.6762 51.3079 89.2415 51.3079Z"
      fill="#2D9EA3"
    />
    <path
      d="M55.603 78.5369C60.311 78.5369 64.1275 74.7204 64.1275 70.0125C64.1275 65.3046 60.311 61.488 55.603 61.488C50.8951 61.488 47.0786 65.3046 47.0786 70.0125C47.0786 74.7204 50.8951 78.5369 55.603 78.5369Z"
      fill="#EFEDED"
    />
    <path
      d="M57.8158 66.2092C57.9004 66.1023 58.0063 66.0142 58.1267 65.9504C58.2472 65.8866 58.3796 65.8486 58.5155 65.8387C58.6515 65.8288 58.788 65.8472 58.9165 65.8929C59.0449 65.9385 59.1625 66.0104 59.2616 66.1039C59.3608 66.1974 59.4395 66.3105 59.4927 66.436C59.5459 66.5615 59.5724 66.6967 59.5705 66.833C59.5687 66.9693 59.5385 67.1037 59.4819 67.2278C59.4254 67.3518 59.3437 67.4627 59.242 67.5535L56.8814 70.0124L59.1764 72.455C59.2781 72.5458 59.3598 72.6567 59.4164 72.7807C59.4729 72.9048 59.5031 73.0392 59.5049 73.1755C59.5068 73.3118 59.4803 73.447 59.4271 73.5725C59.374 73.698 59.2953 73.8111 59.1961 73.9046C59.0969 73.9981 58.9793 74.07 58.8509 74.1156C58.7224 74.1613 58.5859 74.1797 58.45 74.1698C58.314 74.1599 58.1816 74.1219 58.0611 74.0581C57.9407 73.9943 57.8348 73.9062 57.7502 73.7993L55.5371 71.4386L53.324 73.7993C53.2471 73.9266 53.1424 74.0348 53.0176 74.1157C52.8929 74.1967 52.7514 74.2484 52.6038 74.2668C52.4562 74.2852 52.3064 74.2698 52.1656 74.222C52.0248 74.1741 51.8966 74.095 51.7908 73.9904C51.685 73.8859 51.6043 73.7587 51.5548 73.6185C51.5052 73.4783 51.4881 73.3287 51.5047 73.1809C51.5213 73.0331 51.5712 72.891 51.6507 72.7652C51.7301 72.6395 51.8371 72.5335 51.9634 72.455L54.2585 70.0124L51.9634 67.5535C51.8617 67.4627 51.78 67.3518 51.7234 67.2278C51.6669 67.1037 51.6367 66.9693 51.6349 66.833C51.633 66.6967 51.6595 66.5615 51.7127 66.436C51.7659 66.3105 51.8445 66.1974 51.9437 66.1039C52.0429 66.0104 52.1605 65.9385 52.2889 65.8929C52.4174 65.8472 52.5539 65.8288 52.6898 65.8387C52.8258 65.8486 52.9582 65.8866 53.0787 65.9504C53.1991 66.0142 53.305 66.1023 53.3896 66.2092L55.6027 68.5698L57.8158 66.2092Z"
      fill="#F9716C"
    />
    <path
      d="M55.603 101.815C60.311 101.815 64.1275 97.9987 64.1275 93.2908C64.1275 88.5829 60.311 84.7664 55.603 84.7664C50.8951 84.7664 47.0786 88.5829 47.0786 93.2908C47.0786 97.9987 50.8951 101.815 55.603 101.815Z"
      fill="#EFEDED"
    />
    <path
      d="M54.8158 97.0612L51.1437 93.3891C51.0598 93.3052 50.9932 93.2055 50.9477 93.0958C50.9023 92.9861 50.8789 92.8685 50.8789 92.7498C50.8789 92.631 50.9023 92.5135 50.9477 92.4038C50.9932 92.2941 51.0598 92.1944 51.1437 92.1104C51.2277 92.0265 51.3274 91.9599 51.4371 91.9144C51.5468 91.869 51.6643 91.8456 51.7831 91.8456C51.9018 91.8456 52.0194 91.869 52.1291 91.9144C52.2388 91.9599 52.3384 92.0265 52.4224 92.1104L54.8158 94.5038L58.7829 90.5531C58.8656 90.4668 58.9648 90.3982 59.0747 90.3512C59.1846 90.3043 59.3028 90.2802 59.4223 90.2802C59.5417 90.2802 59.66 90.3043 59.7698 90.3512C59.8797 90.3982 59.979 90.4668 60.0616 90.5531C60.2268 90.7216 60.3193 90.9482 60.3193 91.1842C60.3193 91.4202 60.2268 91.6468 60.0616 91.8154L54.8158 97.0612Z"
      fill="#2B2A36"
    />
    <path
      d="M93.258 67.4715H70.4059C69.9711 67.4715 69.5542 67.2988 69.2467 66.9914C68.9393 66.6839 68.7666 66.267 68.7666 65.8322C68.7666 65.3974 68.9393 64.9804 69.2467 64.673C69.5542 64.3656 69.9711 64.1929 70.4059 64.1929H93.258C93.6927 64.1929 94.1097 64.3656 94.4171 64.673C94.7246 64.9804 94.8973 65.3974 94.8973 65.8322C94.8973 66.267 94.7246 66.6839 94.4171 66.9914C94.1097 67.2988 93.6927 67.4715 93.258 67.4715Z"
      fill="#D9D9D9"
    />
    <path
      d="M93.258 75.8977H70.4059C69.9711 75.8977 69.5542 75.7249 69.2467 75.4175C68.9393 75.1101 68.7666 74.6931 68.7666 74.2583C68.7666 73.8236 68.9393 73.4066 69.2467 73.0992C69.5542 72.7917 69.9711 72.619 70.4059 72.619H93.258C93.6927 72.619 94.1097 72.7917 94.4171 73.0992C94.7246 73.4066 94.8973 73.8236 94.8973 74.2583C94.8973 74.6931 94.7246 75.1101 94.4171 75.4175C94.1097 75.7249 93.6927 75.8977 93.258 75.8977Z"
      fill="#D9D9D9"
    />
    <path
      d="M93.258 90.7662H70.4059C69.9711 90.7662 69.5542 90.5935 69.2467 90.286C68.9393 89.9786 68.7666 89.5616 68.7666 89.1269C68.7666 88.6921 68.9393 88.2751 69.2467 87.9677C69.5542 87.6603 69.9711 87.4875 70.4059 87.4875H93.258C93.6927 87.4875 94.1097 87.6603 94.4171 87.9677C94.7246 88.2751 94.8973 88.6921 94.8973 89.1269C94.8973 89.5616 94.7246 89.9786 94.4171 90.286C94.1097 90.5935 93.6927 90.7662 93.258 90.7662Z"
      fill="#D9D9D9"
    />
    <path
      d="M93.258 99.0939H70.4059C69.9711 99.0939 69.5542 98.9212 69.2467 98.6138C68.9393 98.3064 68.7666 97.8894 68.7666 97.4546C68.7666 97.0199 68.9393 96.6029 69.2467 96.2955C69.5542 95.988 69.9711 95.8153 70.4059 95.8153H93.258C93.6927 95.8153 94.1097 95.988 94.4171 96.2955C94.7246 96.6029 94.8973 97.0199 94.8973 97.4546C94.8973 97.8894 94.7246 98.3064 94.4171 98.6138C94.1097 98.9212 93.6927 99.0939 93.258 99.0939Z"
      fill="#D9D9D9"
    />
    <path
      d="M121.897 36.341V88.422C121.875 91.041 120.818 93.5451 118.957 95.3879C117.096 97.2306 114.581 98.2623 111.962 98.2579C111.104 98.2619 110.248 98.1461 109.421 97.9137C107.302 97.3664 105.425 96.1276 104.089 94.3936C102.753 92.6595 102.033 90.5292 102.045 88.3401V36.341C102.047 33.9195 102.935 31.5825 104.542 29.7707C106.148 27.9588 108.362 26.7973 110.766 26.5051C111.162 26.4446 111.562 26.4172 111.962 26.4232C112.611 26.424 113.259 26.4843 113.897 26.6035L114.307 26.7018C115.032 26.8879 115.736 27.1517 116.405 27.4887C118.038 28.3203 119.409 29.5873 120.367 31.1496C121.324 32.7118 121.831 34.5086 121.831 36.341H121.897Z"
      fill="#D9D9D9"
    />
    <path
      d="M121.897 36.341V88.422C121.875 91.041 120.819 93.5451 118.957 95.3878C117.096 97.2305 114.582 98.2623 111.963 98.2579C111.104 98.2619 110.249 98.146 109.422 97.9136C109.405 97.6954 109.405 97.4761 109.422 97.2579V36.6032C109.366 34.6598 109.785 32.7321 110.642 30.9869C111.499 29.2416 112.768 27.7315 114.34 26.587C115.065 26.7731 115.769 27.0369 116.438 27.3739C118.071 28.2055 119.442 29.4724 120.4 31.0347C121.357 32.597 121.864 34.3938 121.864 36.2262L121.897 36.341Z"
      fill="#EFEDED"
    />
    <path
      d="M91.9137 121.077C94.9195 121.077 97.3562 118.582 97.3562 115.503C97.3562 112.425 94.9195 109.93 91.9137 109.93C88.9079 109.93 86.4712 112.425 86.4712 115.503C86.4712 118.582 88.9079 121.077 91.9137 121.077Z"
      fill="#F9716C"
    />
    <path
      d="M88.8482 109.192H37.7344V121.815H88.8482V109.192Z"
      fill="#FDA402"
    />
    <path
      d="M88.8482 115.471H37.7344V121.815H88.8482V115.471Z"
      fill="#FCC977"
    />
    <path
      d="M37.7345 109.192V121.831L30.5871 118.356L24.1118 115.208L30.5871 112.356L37.7345 109.192Z"
      fill="white"
    />
    <path
      d="M30.5871 112.356V118.356L24.1118 115.208L30.5871 112.356Z"
      fill="#FDA402"
    />
    <ellipse
      cx="105.839"
      cy="103.342"
      rx="29.3097"
      ry="24.8048"
      fill="#1DC1C9"
    />
    <path
      d="M94.8066 133.539L97.7161 122.992C97.7625 122.824 97.9279 122.717 98.1006 122.744L112.769 125.015C113.105 125.067 113.17 125.522 112.863 125.667L95.2851 133.942C95.0189 134.067 94.7284 133.823 94.8066 133.539Z"
      fill="#1DC1C9"
    />
    <path
      d="M105.913 117.225C105.554 117.205 105.209 117.081 104.92 116.867C104.631 116.654 104.411 116.36 104.287 116.022C104.164 115.684 104.142 115.318 104.225 114.968C104.308 114.618 104.492 114.3 104.754 114.054C105.016 113.808 105.344 113.644 105.698 113.582C106.053 113.521 106.417 113.565 106.746 113.709C107.076 113.853 107.356 114.09 107.552 114.392C107.747 114.693 107.85 115.046 107.848 115.405C107.841 115.652 107.786 115.895 107.685 116.121C107.584 116.346 107.44 116.549 107.26 116.718C107.08 116.887 106.868 117.019 106.637 117.106C106.406 117.193 106.16 117.233 105.913 117.225ZM108.962 105.635C108.637 105.769 108.358 105.996 108.16 106.288C107.961 106.579 107.853 106.922 107.848 107.274V108.094C107.803 108.574 107.581 109.02 107.225 109.345C106.869 109.67 106.404 109.85 105.922 109.85C105.439 109.85 104.974 109.67 104.618 109.345C104.262 109.02 104.04 108.574 103.995 108.094V107.274C104.012 106.226 104.33 105.205 104.911 104.333C105.493 103.461 106.313 102.775 107.274 102.356C108.129 101.96 108.879 101.37 109.465 100.633C110.051 99.8959 110.457 99.0321 110.651 98.1103C110.651 96.8321 110.143 95.6062 109.239 94.7024C108.335 93.7985 107.11 93.2907 105.831 93.2907C104.553 93.2907 103.327 93.7985 102.423 94.7024C101.52 95.6062 101.012 96.8321 101.012 98.1103C101.012 98.619 100.81 99.1069 100.45 99.4666C100.09 99.8263 99.6025 100.028 99.0938 100.028C98.5851 100.028 98.0972 99.8263 97.7375 99.4666C97.3779 99.1069 97.1758 98.619 97.1758 98.1103C97.2729 95.8778 98.2282 93.7689 99.8423 92.2234C101.456 90.678 103.605 89.8153 105.84 89.8153C108.074 89.8153 110.223 90.678 111.837 92.2234C113.451 93.7689 114.406 95.8778 114.503 98.1103C114.301 99.7339 113.685 101.278 112.715 102.596C111.745 103.913 110.453 104.96 108.962 105.635Z"
      fill="#CEF4F4"
    />
  </svg>
);

export default InterviewIcon;
