import React from 'react';

export const ProjectsIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.7 53.2C93.7 53.3 93.6 53.3 93.6 53.4C93.6 53.5 93.5 53.6 93.5 53.7C93.5 53.8 93.4 53.9 93.4 53.9V54C93.4 54.1 93.4 54.1 93.4 54.1C92.7 55.7 91.9 57.4 90.9 59.1C90.1 60.8 89.2 62.5 88.1 64.2C88 64.3 87.9 64.5 87.9 64.6C87.6 65 87.3 65.5 87 65.9C86.7 66.3 86.4 66.8 86.1 67.2C86 67.3 85.9 67.4 85.9 67.5C85.5 68.1 85 68.7 84.5 69.3C84.3 69.5 84.2 69.7 84 69.9C83.4 70.7 82.7 71.4 82 72.2L81.9 72.3C81.8 72.4 81.7 72.5 81.7 72.6C81.2 73.2 80.6 73.7 80.1 74.3L80 74.4L79.9 74.5C79.8 74.6 79.7 74.7 79.7 74.7C79.1 75.3 78.5 75.9 77.9 76.4C75.1 78.9 72 81.2 68.7 83.3C64.6 85.9 60.1 88 55.3 89.6C55.2 89.6 55.1 89.7 55.1 89.7C54.5 89.9 53.9 90.1 53.3 90.3C53.2 90.3 53.1 90.3 53.1 90.4H53C49.5 91.4 45.9 92 42.2 92.2C41.3 92.3 40.4 92.3 39.5 92.3C38.6 92.3 37.8 92.3 37 92.2C36.1 92.1 35.3 92 34.5 91.8C23.4 89.5 19.5 79.4 15.5 68.8C14.7 66.8 13.9 64.7 13.1 62.7C11.3 58.4 9.30004 54.2 6.50004 50.8C1.60004 43 0.900041 35.5 3.60004 29.6C3.70004 29.5 4.40004 28.6 4.80004 27.4C7.70004 23 12.9 19.6 20.3 18.2C26.2 17 45.7 11.6 63.6 13.5C65.7 13.7 67.7 14 69.8 14.5C72.1 15.8 74.3 17.9 74.6 21C74.6 21.2 74.6 21.4 74.6 21.6C74.6 23.1 75.3 23.9 76.4 24.3C78.8 25.1 83.1 24.1 87.4 23.5C91.2 23.1 95 23 97.2 25.2C102.5 30.2 95.5 48.8 93.7 53.2Z"
      fill="#EBF8F8"
    />
    <path
      d="M73.1 49.5V60.625L72.4688 61.5L64.6 73.3C64.6 73.3 64.6 73.4 64.5 73.4C64.2001 73.6 63.8 73.6 63.5 73.6H27.9C26.7 73.6 25.6001 73.2 24.7001 72.5C23.8001 71.7 23.1 70.6 22.9 69.4C22.9 69.1 22.8 68.9 22.8 68.6V39C22.8 36.2 25.1 33.9 27.9 33.9H36.8C39.6 33.9 41.9001 36.2 41.9001 39V39.4C41.9001 42.2 44.2001 44.5 47 44.5H68C70.8 44.4 73.1 46.7 73.1 49.5Z"
      fill="#FDA402"
    />
    <path
      d="M39.4001 66.8L29.4 73.6H27.9C26.7 73.6 25.6001 73.2 24.7001 72.5C23.8001 71.7 23.1 70.6 22.9 69.4C22.9 69.1 22.8 68.9 22.8 68.6V59.3L31.8 51.5L39.4001 66.8Z"
      fill="#ED9406"
    />
    <path
      d="M77.7 54L73 60.9L72.6 61.4L64.6523 73.3008C64.6523 73.3008 64.5421 73.3991 64.4375 73.4688C64.1375 73.6688 63.7 73.6 63.4 73.6H27.9C26.7 73.6 25.6 73.2 24.7 72.5C23.8 71.7 23.1 70.6 22.9 69.4L31.8 51.5C32.2 50.6 33.1 50.1 34.1 50.1H75.5C76.9 50.1 77.7 50.9063 78 52.3C78.136 52.9318 78.1 53.4 77.7 54Z"
      fill="#FCC977"
    />
    <path
      d="M89 47.4H47.8C46.1 47.4 44.8 46.1 44.8 44.4V38.1C44.8 36.4 46.1 35.1 47.8 35.1H89C90.7001 35.1 92 36.4 92 38.1V44.4C92 46 90.7001 47.4 89 47.4Z"
      fill="#CEF4F4"
    />
    <path
      d="M51.6001 44.2C53.2001 44.2 54.6001 42.9 54.6001 41.2C54.6001 39.5 53.3001 38.2 51.6001 38.2C50.0001 38.2 48.6001 39.5 48.6001 41.2C48.6001 42.9 50.0001 44.2 51.6001 44.2Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M72.6001 44.7001H58.7001C58.1001 44.7001 57.7001 44.3001 57.7001 43.7001C57.7001 43.1001 58.1001 42.7001 58.7001 42.7001H72.6001C73.2001 42.7001 73.6001 43.1001 73.6001 43.7001C73.6001 44.3001 73.1001 44.7001 72.6001 44.7001Z"
      fill="#2D9EA3"
    />
    <path
      d="M86.2001 39.8H58.6001C58.0001 39.8 57.6001 39.4 57.6001 38.8C57.6001 38.2 58.0001 37.8 58.6001 37.8H86.1001C86.7001 37.8 87.1001 38.2 87.1001 38.8C87.1001 39.4 86.7001 39.8 86.2001 39.8Z"
      fill="#2D9EA3"
    />
    <path
      d="M83.4 44.7001H78.2C77.6 44.7001 77.2 44.3001 77.2 43.7001C77.2 43.1001 77.6 42.7001 78.2 42.7001H83.4C84 42.7001 84.4 43.1001 84.4 43.7001C84.4 44.3001 83.9 44.7001 83.4 44.7001Z"
      fill="#2D9EA3"
    />
    <path
      d="M101 31.9H59.8C58.1 31.9 56.8 30.6 56.8 28.9V22.6C56.8 20.9 58.1 19.6 59.8 19.6H101C102.7 19.6 104 20.9 104 22.6V28.9C104 30.5 102.6 31.9 101 31.9Z"
      fill="#CEF4F4"
    />
    <path
      d="M63.6001 28.7C65.2001 28.7 66.6001 27.4 66.6001 25.7C66.6001 24 65.3001 22.7 63.6001 22.7C62.0001 22.7 60.6001 24 60.6001 25.7C60.6001 27.4 61.9001 28.7 63.6001 28.7Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M84.6 29.2001H70.7C70.1 29.2001 69.7 28.8001 69.7 28.2001C69.7 27.6001 70.1 27.2001 70.7 27.2001H84.6C85.2 27.2001 85.6 27.6001 85.6 28.2001C85.6 28.8001 85.1 29.2001 84.6 29.2001Z"
      fill="#2D9EA3"
    />
    <path
      d="M98.2001 24.3H70.6001C70.0001 24.3 69.6001 23.9 69.6001 23.3C69.6001 22.7 70.0001 22.3 70.6001 22.3H98.1001C98.7001 22.3 99.1001 22.7 99.1001 23.3C99.1001 23.9 98.7001 24.3 98.2001 24.3Z"
      fill="#2D9EA3"
    />
    <path
      d="M95.3001 29.2001H90.1001C89.5001 29.2001 89.1001 28.8001 89.1001 28.2001C89.1001 27.6001 89.5001 27.2001 90.1001 27.2001H95.3001C95.9001 27.2001 96.3001 27.6001 96.3001 28.2001C96.3001 28.8001 95.9001 29.2001 95.3001 29.2001Z"
      fill="#2D9EA3"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="48.6558"
        y1="41.2291"
        x2="54.5552"
        y2="41.2291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="60.6359"
        y1="25.7459"
        x2="66.5353"
        y2="25.7459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#36B7BD" />
      </linearGradient>
    </defs>
  </svg>
);

export default ProjectsIcon;
