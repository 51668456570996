import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWebsocketOperatorContext } from 'providers/index';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  CREATOR_DRAWER_WIDTH,
  DIALOG_TYPE_MESSAGE,
  EMAIL,
  TYPE_CONTACT,
  WHATSAPP
} from 'constants/index';

import Drawer from 'components/common/drawer';
import { SkeletonEntityCreation } from 'components/common/skeletons';

import {
  createEmailChat,
  createWhatsAppChat,
  editContact
} from 'store/contacts';
import { fetchChannelsLocal } from 'store/channels';
import { getEmployeeId, getWorkspaceId } from 'store/workspace';

import useRoutesService from 'services/routes';

import MainForm from './form';

const WriteFirstDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();
  const routes = useRoutesService({ returnUrl: false });

  const workspaceId = useSelector(getWorkspaceId);
  const employeeId = useSelector(state => getEmployeeId(state)(workspaceId));

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingChannels, setIsLoadingChannels] = useState(false);
  const [channels, setChannels] = useState([]);

  const socket = useWebsocketOperatorContext();

  const { t } = useTranslation('ComposeEmail', 'Contacts');

  const onSubmit = async values => {
    const contactChat = (values.chats || []).find(
      ({ email, source, channelUuid, channelKind }) =>
        (email === values.email || source === values.phone) &&
        channelKind === (values.email ? EMAIL : WHATSAPP) &&
        channelUuid === values.channelUuid
    );

    try {
      setIsLoading(true);

      let responseChats = [];

      if (isEmpty(contactChat)) {
        let response;

        if (values.email) {
          response = await dispatch(
            createEmailChat({
              id: values.contact.value,
              values: { channel: values.channel, email: values.email }
            })
          );
        }

        if (values.phone) {
          response = await dispatch(
            createWhatsAppChat({
              id: values.contact.value,
              values: {
                channel: values.channel,
                phone: values.phone
              }
            })
          );
        }

        responseChats = response.chats;
        socket.joinRooms([response]);
      }

      if (!values.contact.label.responsible) {
        await dispatch(
          editContact({
            id: values.contact.value,
            values: { responsible: employeeId }
          })
        );
      }

      const emailChat = (responseChats.length
        ? responseChats
        : values.chats
      ).find(
        c => c.email === values.email && c.channelUuid === values.channelUuid
      );

      const whatsAppChat = (responseChats.length
        ? responseChats
        : values.chats
      ).find(
        c => c.source === values.phone && c.channelUuid === values.channelUuid
      );

      socket.sendMessage({
        entityId: values.contact.value,
        entityType: TYPE_CONTACT,
        subject: values.subject,
        channelKind: values.phone ? WHATSAPP : EMAIL,
        fileList: values.content.fileList,
        kind: DIALOG_TYPE_MESSAGE,
        roomUuid: values.phone ? whatsAppChat.uuid : emailChat.uuid,
        channelUuid: values.phone
          ? whatsAppChat.channelUuid
          : emailChat.channelUuid,
        text: [{ text: values.content.description }],
        recipients: values.recipients,
        username: values.phone ? whatsAppChat.source : emailChat.source
      });

      routes.toContact({ id: values.contact.value });

      return onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChannels = async () => {
    try {
      setIsLoadingChannels(true);

      await dispatch(
        fetchChannelsLocal({
          kind: `${EMAIL},${WHATSAPP}`,
          hasChannelRole: employeeId,
          isArchived: false,
          isActive: true
        })
      ).then(({ results }) => setChannels(results));
    } finally {
      setIsLoadingChannels(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchChannels();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Drawer
      title={
        <Drawer.Title>
          {t('WriteFirstHeading', { ns: 'Contacts' })}
        </Drawer.Title>
      }
      visible={visible}
      width={CREATOR_DRAWER_WIDTH}
      onClose={onClose}
      maskClosable={false}
      destroyOnClose
    >
      {isLoadingChannels && <SkeletonEntityCreation />}

      {!isLoadingChannels && channels.length && (
        <MainForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          defaultValues={{
            ...data,
            employeeId,
            channels
          }}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

WriteFirstDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

WriteFirstDrawer.defaultProps = {
  data: {}
};

export default WriteFirstDrawer;
