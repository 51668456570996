import React from 'react';
// eslint-disable-next-line no-restricted-syntax
import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';

const Tooltip = ({ title, children, ...props }) => (
  <AntTooltip title={title} mouseEnterDelay={0.5} {...props}>
    {children}
  </AntTooltip>
);

Tooltip.propTypes = {
  title: PropTypes.string
};

Tooltip.defaultProps = {
  title: undefined
};

export default Tooltip;
