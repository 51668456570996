import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormItem from '../form-item';

import styles from './time-picker.module.scss';

export const FormTimePicker = ({ rules, name, itemProps, ...props }) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem name={name} {...itemProps} errors={formState.errors}>
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value, onChange, ...renderProps } = field;

          return (
            <TimePicker
              popupClassName={styles.popup}
              defaultValue={moment(value, 'HH:mm')}
              onChange={onChange}
              {...renderProps}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormTimePicker.propTypes = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormTimePicker.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormTimePicker;
