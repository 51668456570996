import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchSprintsLocal } from 'store/projects';

import CustomSelect from '../custom-select';
import CommonOption from '../custom-select/common-option';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const SprintSelect = props => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Common');

  const fetchData = params =>
    dispatch(fetchSprintsLocal({ ...params })).then(({ results, ...res }) => ({
      ...res,
      entries: results.map(mapValue)
    }));

  return (
    <CustomSelect
      isAsync
      isSearchable
      isClearable
      placeholder={t('SearchForSprints')}
      valueText={t('ChooseSprint')}
      Option={CommonOption}
      fetchData={fetchData}
      {...props}
    />
  );
};

export default SprintSelect;
