import React from 'react';

export const ServerErrorIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.0999 15C47.0999 15 11.9 32.6 4.49995 46.6C-2.50005 59.8 17.9 69.3 17.9 78.6C17.9 97.9 44.9 87.7 69.3 83C93.7 78.3 107.1 57.6 99.6 41.6C92.1 25.6 76.3999 15 57.0999 15Z"
      fill="#D7F1F2"
    />
    <path
      d="M28.4 39.7H73.3C73.4 39.7 73.5 39.7 73.7 39.7V34.3C73.6 34.3 73.5 34.3 73.3 34.3H28.4C28.3 34.3 28.2 34.3 28 34.3V39.7C28.2 39.7 28.3 39.7 28.4 39.7Z"
      fill="white"
    />
    <path
      d="M35.5 34.3H28.4C28.3 34.3 28.2 34.3 28 34.3V39.7C28.1 39.7 28.2 39.7 28.4 39.7H35.5V34.3Z"
      fill="#F0F0F0"
    />
    <path
      d="M73.3 58.5H28.4C28.3 58.5 28.2 58.5 28 58.5V63.9C28.1 63.9 28.2 63.9 28.4 63.9H73.3C73.4 63.9 73.5 63.9 73.7 63.9V58.5C73.6 58.5 73.5 58.5 73.3 58.5Z"
      fill="white"
    />
    <path
      d="M35.5 58.5H28.4C28.3 58.5 28.2 58.5 28 58.5V63.9C28.1 63.9 28.2 63.9 28.4 63.9H35.5V58.5Z"
      fill="#F0F0F0"
    />
    <path
      d="M73.2999 15H28.3999C25.8999 15 23.8999 17 23.8999 19.5V30.3C23.8999 32.8 25.8999 34.8 28.3999 34.8H73.2999C75.7999 34.8 77.7999 32.8 77.7999 30.3V19.5C77.8999 17 75.7999 15 73.2999 15Z"
      fill="#E2E2E2"
    />
    <path
      d="M73.2999 39.4H28.3999C25.8999 39.4 23.8999 41.4 23.8999 43.9V54.7C23.8999 57.2 25.8999 59.2 28.3999 59.2H73.2999C75.7999 59.2 77.7999 57.2 77.7999 54.7V43.9C77.8999 41.4 75.7999 39.4 73.2999 39.4Z"
      fill="#E2E2E2"
    />
    <path
      d="M73.2999 63.3H28.3999C25.8999 63.3 23.8999 65.3 23.8999 67.8V78.6C23.8999 81.1 25.8999 83.1 28.3999 83.1H73.2999C75.7999 83.1 77.7999 81.1 77.7999 78.6V67.9C77.8999 65.4 75.7999 63.3 73.2999 63.3Z"
      fill="#E2E2E2"
    />
    <rect
      x="28"
      y="18.4625"
      width="46.4516"
      height="12.875"
      rx="2"
      fill="#D8D8D8"
    />
    <rect
      x="28"
      y="42.8625"
      width="46.4516"
      height="12.875"
      rx="2"
      fill="#D8D8D8"
    />
    <rect
      x="27.6241"
      y="66.625"
      width="46.4516"
      height="12.875"
      rx="2"
      fill="#D8D8D8"
    />
    <path
      d="M34.3 27.1C35.5 27.1 36.5 26.1 36.5 24.9C36.5 23.7 35.5 22.7 34.3 22.7C33.1 22.7 32.1 23.7 32.1 24.9C32.1 26.1 33.1 27.1 34.3 27.1Z"
      fill="#36B7BD"
    />
    <path
      d="M41.9999 27.1C43.1999 27.1 44.1999 26.1 44.1999 24.9C44.1999 23.7 43.1999 22.7 41.9999 22.7C40.7999 22.7 39.7999 23.7 39.7999 24.9C39.7999 26.1 40.7999 27.1 41.9999 27.1Z"
      fill="#36B7BD"
    />
    <path
      d="M49.7 27.1C50.9 27.1 51.9 26.1 51.9 24.9C51.9 23.7 50.9 22.7 49.7 22.7C48.5 22.7 47.5 23.7 47.5 24.9C47.5 26.1 48.5 27.1 49.7 27.1Z"
      fill="#36B7BD"
    />
    <path
      d="M34.3 51.5C35.5 51.5 36.5 50.5 36.5 49.3C36.5 48.1 35.5 47.1 34.3 47.1C33.1 47.1 32.1 48.1 32.1 49.3C32.1 50.5 33.1 51.5 34.3 51.5Z"
      fill="#36B7BD"
    />
    <path
      d="M41.9999 51.5C43.1999 51.5 44.1999 50.5 44.1999 49.3C44.1999 48.1 43.1999 47.1 41.9999 47.1C40.7999 47.1 39.7999 48.1 39.7999 49.3C39.7999 50.5 40.7999 51.5 41.9999 51.5Z"
      fill="#36B7BD"
    />
    <path
      d="M49.7 51.5C50.9 51.5 51.9 50.5 51.9 49.3C51.9 48.1 50.9 47.1 49.7 47.1C48.5 47.1 47.5 48.1 47.5 49.3C47.5 50.5 48.5 51.5 49.7 51.5Z"
      fill="#36B7BD"
    />
    <path
      d="M34.3 75.4C35.5 75.4 36.5 74.4 36.5 73.2C36.5 72 35.5 71 34.3 71C33.1 71 32.1 72 32.1 73.2C32.1 74.4 33.1 75.4 34.3 75.4Z"
      fill="#36B7BD"
    />
    <path
      d="M41.9999 75.4C43.1999 75.4 44.1999 74.4 44.1999 73.2C44.1999 72 43.1999 71 41.9999 71C40.7999 71 39.7999 72 39.7999 73.2C39.7999 74.4 40.7999 75.4 41.9999 75.4Z"
      fill="#36B7BD"
    />
    <path
      d="M49.7 75.4C50.9 75.4 51.9 74.4 51.9 73.2C51.9 72 50.9 71 49.7 71C48.5 71 47.5 72 47.5 73.2C47.5 74.4 48.5 75.4 49.7 75.4Z"
      fill="#36B7BD"
    />
    <path
      d="M89.2 76.7L75.1 54.5C73.4 51.9 69.7 51.9 68 54.5L53.9 76.7C52.1 79.5 54.1 83.2 57.4 83.2H85.6C89 83.2 91 79.5 89.2 76.7Z"
      fill="#FFE181"
    />
    <path
      d="M60.2 76.7L74.3 54.5C74.4 54.3 74.5 54.2 74.7 54C72.9 51.9 69.5 52.1 68 54.5L53.9 76.7C52.1 79.5 54.1 83.2 57.4 83.2H63.7C60.5 83.2 58.5 79.5 60.2 76.7Z"
      fill="#FCC977"
    />
    <path
      d="M72.2999 71.7H70.7999C69.9999 71.7 69.3999 71 69.3999 70.3V61.2C69.3999 60.4 70.0999 59.8 70.7999 59.8H72.2999C73.0999 59.8 73.7999 60.5 73.7999 61.2V70.3C73.6999 71 73.0999 71.7 72.2999 71.7Z"
      fill="white"
    />
    <path
      d="M71.4999 79.5C72.6999 79.5 73.6999 78.5 73.6999 77.3C73.6999 76.1 72.6999 75.1 71.4999 75.1C70.2999 75.1 69.2999 76.1 69.2999 77.3C69.2999 78.5 70.2999 79.5 71.4999 79.5Z"
      fill="white"
    />
    <path
      d="M60.7999 26H56.2999C55.6999 26 55.2999 25.5 55.2999 25C55.2999 24.4 55.7999 24 56.2999 24H60.7999C61.3999 24 61.7999 24.5 61.7999 25C61.7999 25.5 61.2999 26 60.7999 26Z"
      fill="#2B2A36"
    />
    <path
      d="M69.5 26H65.1C64.5 26 64.1 25.5 64.1 25C64.1 24.4 64.6 24 65.1 24H69.5C70.1 24 70.5 24.5 70.5 25C70.5 25.5 70 26 69.5 26Z"
      fill="#2B2A36"
    />
    <path
      d="M60.7999 50.3H56.2999C55.6999 50.3 55.2999 49.8 55.2999 49.3C55.2999 48.7 55.7999 48.3 56.2999 48.3H60.7999C61.3999 48.3 61.7999 48.8 61.7999 49.3C61.7999 49.9 61.2999 50.3 60.7999 50.3Z"
      fill="#2B2A36"
    />
    <path
      d="M69.5 50.3H65.1C64.5 50.3 64.1 49.8 64.1 49.3C64.1 48.7 64.6 48.3 65.1 48.3H69.5C70.1 48.3 70.5 48.8 70.5 49.3C70.5 49.9 70 50.3 69.5 50.3Z"
      fill="#2B2A36"
    />
  </svg>
);

export default ServerErrorIcon;
