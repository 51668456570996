import React from 'react';
import { Translation } from 'react-i18next';
import { EN_US, RU_RU } from 'i18n-config';

import managerImage from './images/manager.svg';
import subordinatesImage from './images/subordinates.svg';
import employeesImage from './images/employees.svg';
import managerEnImage from './images/manager-en.svg';
import subordinatesEnImage from './images/subordinates-en.svg';
import employeesEnImage from './images/employees-en.svg';

export const MANAGER = 'manager';
export const SUBORDINATES = 'subordinates';
export const EMPLOYEES = 'employees';

export const DEPARTMENT_DEFAULT_NAME = (
  <Translation ns="Team">{t => t('Untitled')}</Translation>
);

export const INVITATION_TYPE_OPTIONS = [
  {
    label: 'YourImmediateSupervisorRadio',
    ns: 'InviteCollegue',
    value: MANAGER
  },
  {
    label: 'YourSubordinatesRadio',
    ns: 'InviteCollegue',
    value: SUBORDINATES
  },
  {
    label: 'OtherColleguesRadio',
    ns: 'InviteCollegue',
    value: EMPLOYEES
  }
];

export const INVITATION_TYPE_IMAGES = {
  [RU_RU]: {
    [MANAGER]: managerImage,
    [SUBORDINATES]: subordinatesImage,
    [EMPLOYEES]: employeesImage
  },
  [EN_US]: {
    [MANAGER]: managerEnImage,
    [SUBORDINATES]: subordinatesEnImage,
    [EMPLOYEES]: employeesEnImage
  }
};
