import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { useTheme } from 'components/common/theme';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import {
  TAG_TYPE_ADDRESS,
  TAG_TYPE_NUMBER,
  TAG_TYPE_TEXT,
  TAG_TYPE_DATE,
  TAG_TYPE_AI_ASSISTANT
} from 'components/common/tags/utils/tag-types';
import ActionMenu from 'components/common/tags/action-menu';
import renderActionComponent from 'components/common/tags/utils/render-action-component';

import { getUserEmployee } from 'store/workspace';

import hasManagerRoles from 'utils/has-manager-roles';

import Option from '../option';

import styles from './tag-option.module.scss';

export const HEX_ALPHA = '1A'; // 90%
const MAX_TAG_TEXT_LENGTH = 27;

const getTagIcon = type => {
  switch (type) {
    case TAG_TYPE_TEXT:
      return 'pen';
    case TAG_TYPE_ADDRESS:
      return 'environment';
    case TAG_TYPE_NUMBER:
      return 'calculator';
    case TAG_TYPE_DATE:
      return 'calendar';
    case TAG_TYPE_AI_ASSISTANT:
      return 'ai-assistant';

    default:
      return '';
  }
};

export const TagOption = ({
  className,
  option: optionValue,
  isTag,
  isOption,
  isClearable,
  isCreateType,
  onCreate,
  isSearchList,
  selectedCreateTypeTag,
  ...props
}) => {
  const { variables } = useTheme();
  const employee = useSelector(getUserEmployee);

  const [visibleActionsDropdown, setVisibleActionsDropdown] = useState(false);
  // State to manage whether the tag option is currently hovered by the mouse,
  // and to apply different hover styles when the actions menu is active
  const [hovered, setHovered] = useState(false);

  const hasManagerRole = hasManagerRoles(employee.roles);

  const option =
    typeof optionValue === 'string'
      ? { id: optionValue, name: optionValue }
      : optionValue;

  const color = option.color || variables.color.green;
  const getRgbaColor = useCallback(() => `${color}${HEX_ALPHA}`, [color]);

  const isDisplayAction =
    hasManagerRole && !isSearchList && !isCreateType && !isOption;

  const isDisabledActions =
    optionValue.type === TAG_TYPE_AI_ASSISTANT && !isOption;

  const isSelectedCreateTypeTag =
    isCreateType &&
    selectedCreateTypeTag &&
    selectedCreateTypeTag.id === option.id;

  const onClick = event => {
    if (isOption) {
      return null;
    }

    if (option.link) {
      window.open(option.link, '_blank');
    }

    if (onCreate) {
      onCreate(option);
    }

    return event.preventDefault();
  };

  const changeHoveredEnter = value => {
    setHovered(value);
  };

  const changeHoveredLeave = value => {
    if (visibleActionsDropdown) {
      return;
    }
    setHovered(value);
  };

  useEffect(() => {
    if (!visibleActionsDropdown && hovered) {
      setHovered(false);
    }
  }, [visibleActionsDropdown]);

  return (
    <div
      onClick={onClick}
      style={{ display: 'flex', maxWidth: '100%' }}
      onMouseEnter={() => changeHoveredEnter(true)}
      onMouseLeave={() => changeHoveredLeave(false)}
    >
      <Option
        className={classnames(className, styles.root, {
          [styles.isTag]: !isOption,
          [styles.isClearable]: isClearable && !hasManagerRole,
          [styles.isActions]: isDisplayAction && !isDisabledActions,
          [styles.isHover]: hovered,
          [styles.ellipsisOnHover]: option.name.length > 3,
          [styles.createTypeOption]: isCreateType,
          [styles.createTypeOptionSelected]: isSelectedCreateTypeTag,
          [styles.clickable]: option.link
        })}
        title={option.name}
        contentClassName={styles.content}
        style={{ backgroundColor: getRgbaColor() }}
        isClearable={isClearable && !isDisabledActions}
        actionComponent={renderActionComponent(
          <ActionMenu
            tag={optionValue}
            color={color}
            setVisibleActionsDropdown={setVisibleActionsDropdown}
          />,
          isDisplayAction && !isDisabledActions
        )}
        {...props}
      >
        {option.type && (
          <Icon
            type={getTagIcon(option.type)}
            size={isCreateType ? 16 : 14}
            style={{ color: isCreateType ? variables.color.brand : color }}
          />
        )}

        <Tooltip
          title={
            optionValue.name.length > MAX_TAG_TEXT_LENGTH
              ? optionValue.name
              : undefined
          }
          mouseEnterDelay={0.5}
        >
          <Typography.Text
            ellipsis
            key={option.id}
            size="small"
            style={{ color }}
            className={classnames({
              [styles.createTypeText]: isCreateType,
              [styles.underline]: option.link
            })}
          >
            {option.name}
          </Typography.Text>
        </Tooltip>
      </Option>
    </div>
  );
};

TagOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string
  }).isRequired,
  isOption: PropTypes.bool,
  className: PropTypes.string,
  isCreateType: PropTypes.bool,
  isSearchList: PropTypes.bool,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  selectedCreateTypeTag: PropTypes.object
};

TagOption.defaultProps = {
  isOption: false,
  className: undefined,
  isCreateType: false,
  isSearchList: false,
  onCreate: undefined,
  onRemove: undefined,
  selectedCreateTypeTag: undefined
};

export default TagOption;
