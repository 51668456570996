import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import usePrevious from './use-previous';

export const useDeepCompareEffect = (effect, deps) => {
  const isFirstRender = useRef(true);
  const prevDeps = usePrevious(deps);

  useEffect(() => {
    let returnedFunction;

    if (!isFirstRender.current && !isEqual(prevDeps, deps)) {
      returnedFunction = effect();
    }

    if (isFirstRender.current) {
      returnedFunction = effect();
      isFirstRender.current = false;
    }

    return returnedFunction;

    // eslint-disable-next-line
  }, deps);
};

export default useDeepCompareEffect;
