import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SETTINGS } from 'constants/index';

import Icon from 'components/common/icon';
import { CancelSubscriptionIcon } from 'components/common/icons';
import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { retryLastPaymentTransaction } from 'store/billing';

import useRoutesService from 'services/routes';
import { useQueryParam } from 'hooks';

import styles from './payment-failed.module.scss';

const PaymentFailedModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ScreenErrors');

  const routes = useRoutesService();

  const text = useQueryParam('text');

  const tryAgain = async () => {
    try {
      setIsLoading(true);

      const { url } = await dispatch(retryLastPaymentTransaction());

      if (url) {
        window.open(url, '_blank');
      }

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={440}
      visible={visible}
      centered
      contentClassName={styles.root}
      onClose={onClose}
    >
      <Icon component={CancelSubscriptionIcon} />

      <Typography.Title level={1} weight="bold" className={styles.title}>
        {t('PaymentFailedHeading')}
      </Typography.Title>

      <Typography.Text color="black-45">{text}</Typography.Text>

      <div className={styles.buttons}>
        <Button
          type="primary"
          size="large"
          width="expanded"
          className={styles.button}
          loading={isLoading}
          onClick={tryAgain}
        >
          {t('TryAgainBtn')}
        </Button>

        <Button
          type="link"
          className={styles.button}
          onClick={() => routes.toBilling({ view: SETTINGS })}
        >
          {t('ChangePaymentMethodBtn')}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentFailedModal;
