import React from 'react';

export const ActualIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.5" r="12" fill="#A3DF73" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53456 12.0651C4.69937 12.6508 4.5047 13.8142 5.09953 14.6651L8.451 19.4589C8.52392 19.5632 8.60532 19.658 8.69357 19.7429C8.84738 20.0169 9.0733 20.2532 9.36332 20.4202C10.2394 20.9248 11.3692 20.6065 11.8888 19.7088L18.7857 7.79245C19.3068 6.89202 19.0165 5.75022 18.1378 5.24414C17.2617 4.73959 16.1319 5.05786 15.6124 5.95557L10.1368 15.4162L8.13035 12.5463C7.53428 11.6937 6.37149 11.4781 5.53456 12.0651Z"
      fill="white"
    />
  </svg>
);

export default ActualIcon;
