export const SOCKET = 'socket';

export const EMIT_SEND_CHAT_MESSAGE = 'send-chat-message';
export const EMIT_READ_CHAT_MESSAGE = 'read-chat-message';
export const EMIT_NEW_ORDER_CHAT = 'new-order-chat';
export const EMIT_SEARCH_USER = 'search-user';
export const EMIT_CHAT_TYPING = 'set-chat-typing';

export const EMIT_JOIN_USER_UPDATES = 'join-user-updates';
export const USER_ONLINE_CHANGED = 'user-online-changed';
export const EMIT_LEAVE_USER_UPDATES = 'leave-user-updates';

export const NEW_USER_NOTIFICATION = `${SOCKET}/new-user-notification`;
export const UPDATE_EVENT_COUNTER_TREE = `${SOCKET}/update-event-counter-tree`;
export const EMPLOYEE_DEPARTMENT_CHANGED = 'employee-department-changed';

export const NEW_ORDER_CHAT = `${SOCKET}/${EMIT_NEW_ORDER_CHAT}`;
export const ORDER_CREATED = `${SOCKET}/order-created`;
export const CHAT_MESSAGE_NEW = 'chat-message-new';
export const NEW_CHAT_MESSAGE = `${SOCKET}/${CHAT_MESSAGE_NEW}`;
export const SEND_CHAT_MESSAGE = `${SOCKET}/${EMIT_SEND_CHAT_MESSAGE}`;
export const READ_CHAT_MESSAGE = `${SOCKET}/${EMIT_READ_CHAT_MESSAGE}`;
export const CHAT_MESSAGE_READ = `${SOCKET}/chat-message-read`;
export const ORDER_UPDATED = `${SOCKET}/order-updated`;
export const DEAL_UPDATED = `${SOCKET}/deal-updated`;
export const CHAT_UPDATED = `${SOCKET}/chat-updated`;
export const CHAT_TYPING = 'chat-typing';
export const READ_COMMENT = 'read-comment';
export const COMMENT_READ = 'comment-read';
export const COMMENT_NEW = 'comment-new';

export const SOCKET_READ_COMMENT = `${SOCKET}/${READ_COMMENT}`;
export const SOCKET_COMMENT_NEW = `${SOCKET}/${COMMENT_NEW}`;

export const TASK_STATUS_UPDATED = `${SOCKET}/task-status-updated`;

// UNIFIED CHAT

export const CHANNEL_CHAT_NEW_MESSAGES = 'channel-chat-new-messages'; // used for push, tab indicator, sidebar indicator
export const CHANNEL_OPERATOR_REMOVED = `${SOCKET}/channel-operator-removed`;
export const CHANNEL_CHAT_REOPENED = `${SOCKET}/channel-chat-reopened`;
export const CHANNEL_CHAT_CREATED = `${SOCKET}/channel-chat-created`;
export const CHANNEL_CHAT_UPDATED = `${SOCKET}/channel-chat-updated`;

export const RECORD_REMINDER = `${SOCKET}/record-reminder`;
