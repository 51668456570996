import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BENCH } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import { getUserEmployee } from 'store/workspace';

import useModalsService from 'services/modals';

import styles from './view-info.module.scss';

const Projects = () => {
  const employee = useSelector(getUserEmployee);

  const modals = useModalsService();

  const { t } = useTranslation('Projects');

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  const onShowCreator = () => modals.projects.showProjectCreator();

  return (
    <div className={styles.projects}>
      <Typography.Title level={2} className={styles.title}>
        {t('TasksHeading')}
      </Typography.Title>

      <Button
        type="primary"
        disabled={isBench}
        className={styles.button}
        onClick={onShowCreator}
      >
        <Icon type="plus" />
        {t('AddProjectBtn')}
      </Button>
    </div>
  );
};

export default Projects;
