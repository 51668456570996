import { createAction } from 'redux-actions';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import {
  getGuidesCurrentPage,
  getGuidesItemsPerPage,
  getGuidesFilter
} from './selectors';

export const fetchGuides = createActionThunk('guides/fetch', ({ getState }) => {
  const state = getState();

  const currentPage = getGuidesCurrentPage(state);
  const limit = getGuidesItemsPerPage(state);
  const offset = limit * (currentPage - 1);
  const { search, parent, withContent } = getGuidesFilter(state);

  return api.guides
    .fetch({
      parent,
      limit,
      offset,
      search,
      withContent
    })
    .then(({ data }) => data);
});

export const setCurrentPage = createAction('guides/set-current-page');

export const setFilterSearch = createAction('guides/set-filter-search');

export const fetchVideo = createActionThunk(
  'guides/fetch-video-guide',
  ({ slug }) => api.videos.fetchBySlug(slug).then(({ data }) => data)
);
