import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/icon';

import styles from './to-bottom.module.scss';

const ToBottom = ({ visible, hasNewMessages, className, onClick }) => (
  <div className={styles.root}>
    <div
      className={classnames(
        styles.toBottom,
        { [styles.visible]: visible, [styles.hidden]: !visible },
        className
      )}
      onClick={onClick}
    >
      {hasNewMessages && <div className={styles.badge} />}

      <Icon type="arrow" color="black-55" />
    </div>
  </div>
);

ToBottom.propTypes = {
  visible: PropTypes.bool,
  hasNewMessages: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

ToBottom.defaultProps = {
  visible: false,
  className: undefined,
  hasNewMessages: false
};

export default ToBottom;
