import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { TYPE_CONTACT } from 'constants/index';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import {
  getIsFromWebsiteFrom,
  getIsFromWidget
} from 'components/contacts-view/utils';

import ClientInfo from './client-info';
import CopyEmailInfo from './copy-email-info';

import styles from './message.module.scss';

const Header = ({
  sender,
  leads,
  recipients,
  isComment,
  isFrom,
  entityType,
  getSourceInfoLabel
}) => {
  const { t } = useTranslation('Common');

  const { fullName, username } = sender;

  const isFromWidget = getIsFromWidget(isFrom);
  const isFromWebsiteForm = getIsFromWebsiteFrom(isFrom);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {fullName && (
            <Typography.Text weight="semibold" className={styles.fullname}>
              {fullName}
            </Typography.Text>
          )}

          {username && (
            <Typography.Text
              className={styles.username}
              weight="semibold"
              color="black-55"
            >
              {fullName ? getSourceInfoLabel() : username}
            </Typography.Text>
          )}

          {isComment && (
            <Tooltip
              title={
                entityType === TYPE_CONTACT ? t('PrivateMessageTip') : undefined
              }
            >
              <Icon type="eye-invisible" color="black-55" theme="filled" />
            </Tooltip>
          )}
        </div>

        {leads && (isFromWidget || isFromWebsiteForm) && (
          <ClientInfo leads={leads} />
        )}
      </div>

      <CopyEmailInfo recipients={recipients} />
    </>
  );
};

Header.propTypes = {
  sender: PropTypes.shape({
    fullName: PropTypes.string,
    username: PropTypes.string
  }).isRequired,
  isComment: PropTypes.bool.isRequired,
  isFrom: PropTypes.string.isRequired,
  recipients: PropTypes.array,
  leads: PropTypes.object,
  entityType: PropTypes.string,
  getSourceInfoLabel: PropTypes.func
};

Header.defaultProps = {
  recipients: [],
  leads: undefined,
  entityType: '',
  getSourceInfoLabel: () => {}
};

export default Header;
