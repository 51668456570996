import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  BENCH,
  SPRINTS,
  STATUS_BACKLOG,
  STATUS_COMPLETED,
  STATUS_IN_WORK
} from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import SprintCreatorModal from 'components/tasks-view/view/drawers/sprint-creator';
import ActionsDropdown from 'components/common/actions';

import { getProjectFactory } from 'store/projects';
import { getUserEmployee, getWorkspacePermissions } from 'store/workspace';

import styles from './view-info.module.scss';

const Tasks = () => {
  const employee = useSelector(getUserEmployee);

  const [taskCreatorVisible, setTaskCreatorVisible] = useState(false);
  const [sprintCreatorVisible, setSprintCreatorVisible] = useState(false);

  const permissions = useSelector(getWorkspacePermissions);
  const isCanCreateTask = (permissions || {}).createTask;

  const match = useRouteMatch('/:id/tasks/projects/:projectId/:status');
  const { projectId, status } = (match || {}).params || {};

  const project = useSelector(state => getProjectFactory(state)(+projectId));

  const { t } = useTranslation(['Tasks', 'Backlog', 'Sprints']);

  const isProjectStatusCompleted = (project || {}).status === STATUS_COMPLETED;
  const allowCreateSptrint = project && (project.permissions || {}).editProject;

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  const renderedButton = useMemo(() => {
    if (!status || status === STATUS_IN_WORK) {
      return (
        <Button
          type="primary"
          className={styles.button}
          disabled={!isCanCreateTask || isProjectStatusCompleted || isBench}
          onClick={() => setTaskCreatorVisible(true)}
        >
          <Icon type="plus" />
          {t('AddTaskBtn')}
        </Button>
      );
    }

    if (status === STATUS_BACKLOG || status === SPRINTS) {
      const actions = [
        {
          title: t('AddTaskBtn', { ns: 'Backlog' }),
          onClick: () => setTaskCreatorVisible(true),
          allow: isCanCreateTask
        },
        {
          title: t('AddSprintBtn', { ns: 'Backlog' }),
          onClick: () => setSprintCreatorVisible(true),
          allow: allowCreateSptrint
        }
      ];

      return (
        <ActionsDropdown actions={actions}>
          <Button
            type="primary"
            className={styles.button}
            disabled={isProjectStatusCompleted || isBench}
          >
            <Icon type="plus" />
            {t('AddProjectBtn', { ns: 'Backlog' })}
          </Button>
        </ActionsDropdown>
      );
    }

    return null;
  }, [
    allowCreateSptrint,
    isBench,
    isCanCreateTask,
    isProjectStatusCompleted,
    status,
    t
  ]);

  return (
    <>
      <div className={styles.tasks}>
        <Typography.Title level={2} className={styles.title}>
          {t('TasksHeading')}
        </Typography.Title>

        {renderedButton}
      </div>

      <TaskCreatorDrawer
        visible={taskCreatorVisible}
        value={{ project }}
        onClose={() => setTaskCreatorVisible(false)}
      />

      <SprintCreatorModal
        setIsModalVisible={setSprintCreatorVisible}
        isModalVisible={sprintCreatorVisible}
        projectId={+projectId}
      />
    </>
  );
};

export default Tasks;
