import React from 'react';
import PropTypes from 'prop-types';

import { CREATE_TAG_MODAL } from 'constants/index';

import { useModalContext } from 'components/app/modals-provider';
import SelectingType from 'components/common/tags/selecting-type';

import styles from './create-by-type.module.scss';

const CreateByType = ({
  searchValue,
  handleTagListDropdown,
  createCallback
}) => {
  const { showModal } = useModalContext();

  const handleSelectType = item => {
    showModal(CREATE_TAG_MODAL, {
      type: item.type,
      searchValue,
      createCallback
    });
    handleTagListDropdown(false);
  };

  return (
    <div className={styles.root}>
      <SelectingType searchValue={searchValue} onSelect={handleSelectType} />
    </div>
  );
};

CreateByType.propTypes = {
  searchValue: PropTypes.string,
  handleTagListDropdown: PropTypes.func,
  createCallback: PropTypes.func
};

CreateByType.defaultProps = {
  searchValue: '',
  handleTagListDropdown: () => {},
  createCallback: () => {}
};

export default CreateByType;
