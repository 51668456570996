import { Skeleton } from 'antd';
import React from 'react';
import classNames from 'classnames';

import styles from './attachment.module.scss';

export const AttachmentSkeleton = ({ className }) => (
  <Skeleton
    className={classNames(className, styles.root)}
    active
    avatar={{ shape: 'square', size: 16 }}
    title={false}
  />
);

export default AttachmentSkeleton;
