import React, { useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Input } from 'antd';

import styles from './color-input.module.scss';

const ColorPicker = ({ value, onChange, ...props }) => {
  const inputRef = useRef(null);

  const handleKeyDown = e => {
    const cursorPosition = inputRef.current.selectionStart;

    if (cursorPosition === 0 && e.key === 'Backspace') {
      e.preventDefault();
    }
  };

  const handleChange = e => {
    let inputValue = e.target.value;

    if (inputValue === '' || inputValue === '#') {
      inputValue = '#';
    } else if (!inputValue.startsWith('#')) {
      inputValue = `#${inputValue}`;
    }

    onChange(inputValue);
  };

  return (
    <div className={styles.colorPicker}>
      <HexColorPicker color={value} onChange={onChange} {...props} />

      <Input
        autoFocus
        autoComplete="off"
        size="large"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />
    </div>
  );
};

export default ColorPicker;
