import React from 'react';

export const FilteredChartIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#FFEDCC" />
    <path
      d="M26.8473 7.93947L21.5116 8.58233C21.3348 8.60376 21.2598 8.82072 21.3857 8.94661L22.9741 10.535L16.9205 16.5886L14.1938 13.8645C14.025 13.6957 13.7545 13.6984 13.5884 13.8645L4.97143 22.4841C4.93155 22.5244 4.90918 22.5788 4.90918 22.6355C4.90918 22.6921 4.93155 22.7465 4.97143 22.7868L6.17679 23.9975C6.25982 24.0805 6.39643 24.0805 6.47947 23.9975L13.8911 16.5886L16.6152 19.3127C16.7839 19.4788 17.0545 19.4788 17.2205 19.3127L24.4902 12.0484L26.0786 13.6368C26.107 13.6651 26.1429 13.6849 26.182 13.6938C26.2212 13.7028 26.262 13.7006 26.3 13.6875C26.3379 13.6744 26.3715 13.6509 26.3967 13.6197C26.422 13.5884 26.438 13.5508 26.4429 13.5109L27.0857 8.17518C27.1045 8.03858 26.9866 7.92072 26.8473 7.93947Z"
      fill="#FDA402"
    />
    <path
      d="M22.0001 26.002V24.5402L22.0478 24.2343L21.8761 23.9313L20.2162 21.002H24.7841L23.1241 23.9313L22.9524 24.2343L23.0001 24.5402V26.002H22.0001Z"
      fill="#FDA402"
      stroke="#FDA402"
      strokeWidth="2"
    />
  </svg>
);

export default FilteredChartIcon;
