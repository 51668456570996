import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import classnames from 'classnames';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

import { hexEmojiByEmojiPostfix } from './utlls';

import styles from '../components.module.scss';

const Emoji = ({ iconProps, popoverProps, onSelect, isDisabled }) => {
  const { t } = useTranslation('CommonChat');

  const categories = useMemo(
    () => [
      {
        category: 'suggested',
        name: t('RecentlyUsed')
      },
      {
        category: 'smileys_people',
        name: t('SmileysPeople')
      },
      {
        category: 'animals_nature',
        name: t('AnimalsNature')
      },
      {
        category: 'food_drink',
        name: t('FoodDrinks')
      },
      {
        category: 'travel_places',
        name: t('TravelsPlaces')
      },
      {
        category: 'activities',
        name: t('Activities')
      },
      {
        category: 'objects',
        name: t('Objects')
      },
      {
        category: 'symbols',
        name: t('Symbols')
      }
    ],
    [t]
  );

  const { className: iconClassName, ...restIconProps } = iconProps;
  const { overlayClassName, ...restPopoverProps } = popoverProps;

  return (
    <Popover
      trigger={!isDisabled ? 'hover' : ''}
      placement="top"
      overlayClassName={classnames(styles.emojiPopover, overlayClassName)}
      {...restPopoverProps}
      content={
        <EmojiPicker
          searchPlaceHolder={t('Search')}
          width={256}
          height={344}
          skinTonesDisabled
          emojiVersion="0.6"
          emojiStyle={EmojiStyle.NATIVE}
          previewConfig={{ showPreview: false }}
          categories={categories}
          onEmojiClick={emoji =>
            onSelect({
              ...emoji,
              unified: hexEmojiByEmojiPostfix[emoji.unified] || emoji.unified
            })
          }
        />
      }
    >
      <Icon
        type="smile"
        size={16}
        color="black-55"
        className={classnames(styles.emojiIcon, iconClassName)}
        {...restIconProps}
      />
    </Popover>
  );
};

Emoji.propTypes = {
  onSelect: PropTypes.func.isRequired,
  iconProps: PropTypes.object,
  popoverProps: PropTypes.object,
  isDisabled: PropTypes.bool
};

Emoji.defaultProps = {
  iconProps: {},
  popoverProps: {},
  isDisabled: false
};

export default Emoji;
