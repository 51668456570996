export const updateAttachmentValidityDate = ({
  attachments,
  setAttachments,
  data
}) => {
  const updatedAttachments = attachments.map(attachment =>
    attachment.fileId === data.fileId
      ? { ...attachment, validityDate: data.validityDate }
      : attachment
  );

  setAttachments(updatedAttachments);
};
