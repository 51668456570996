import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  TYPE_ASSET,
  TYPE_CHANNEL,
  TYPE_CONTACT,
  TYPE_DISCUSSION,
  TYPE_EMPLOYEE,
  TYPE_MESSAGE,
  TYPE_ORDER,
  TYPE_PROJECT,
  TYPE_REPORT,
  TYPE_TASK
} from 'constants/index';

import { transformChatsToJoinedGroups } from 'components/common/chat/use-chat';
import { convertMessageToNode } from 'components/common/comments/converters';
import { getChannelKindByMessageIsFrom } from 'components/contacts-view/utils';

import { fetchOne } from 'store/assets';
import { fetchContactByChatLocal } from 'store/contacts';
import { fetchTaskLocal } from 'store/tasks';
import {
  clearCreatedDiscussionId,
  fetchDiscussionById
} from 'store/discussions';
import {
  changeNotificationChatDisplay,
  changeNotificationMessageTempIsRead,
  getChatEntities,
  getEmployeeEntityChatsUnreadedCount,
  getNotificationChatDisplay,
  setChatEntity
} from 'store/operator';
import { getUserEmployee } from 'store/workspace';

import { useWebsocketOperatorContext } from 'providers';
import useNotificationsService from 'services/notifications';

const useNotificationMessage = ({ message, view }) => {
  const dispatch = useDispatch();

  const notificationsService = useNotificationsService();
  const socket = useWebsocketOperatorContext();

  const notificationChatDisplay = useSelector(state =>
    getNotificationChatDisplay(state)({ view })
  );
  const chatEntities = useSelector(getChatEntities);
  const unreadedCount = useSelector(getEmployeeEntityChatsUnreadedCount);
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation(['Common', 'Contacts']);

  const {
    uuid,
    roomUuid,
    channelId: channelUuid,
    text,
    source,
    notificationData,
    isFrom,
    isRead,
    firstUnreadMessageId,
    firstUnreadNotificationId,
    sourceChatUnreadMessagesCount
  } = message;

  const sourceRoomUuid = source ? source.roomUuid : '';
  const entity = chatEntities[sourceRoomUuid];
  const currentUnreadedCount = unreadedCount[roomUuid];

  const content = useMemo(() => convertMessageToNode(text), [text]);

  const entityFromNotification = useMemo(() => {
    const {
      id: _taskId,
      taskId,
      orderStatusId,
      employeeId,
      projectId,
      channelId,
      contactId,
      contactCompanyId,
      orderId,
      reportId,
      assetId,
      recordId,
      entityId,
      entityTitle: eTitle,
      entityType,
      sourceEntityId,
      sourceEntityTitle,
      sourceEntityType
    } = notificationData;
    const resultId =
      _taskId ||
      taskId ||
      orderId ||
      orderStatusId ||
      employeeId ||
      projectId ||
      channelId ||
      contactId ||
      contactCompanyId ||
      reportId ||
      assetId ||
      recordId ||
      sourceEntityId ||
      entityId;

    let type = null;
    let destinationEntityType = null;
    let destinationEntityId = resultId;

    const entityTitle = eTitle || sourceEntityTitle;

    if (entityType && entityType !== TYPE_MESSAGE) {
      type = entityType;
    }

    if (_taskId || taskId) {
      type = TYPE_TASK;
    }

    if (contactId || orderStatusId) {
      type = TYPE_CONTACT;
    }

    if (assetId) {
      type = TYPE_ASSET;
    }

    destinationEntityType = type;

    if (orderStatusId) {
      destinationEntityType = TYPE_ORDER;
      destinationEntityId = orderStatusId;
    }

    if (projectId) {
      destinationEntityType = TYPE_PROJECT;
    }

    if (contactId && contactCompanyId) {
      destinationEntityType = TYPE_CONTACT;
    }

    if (employeeId) {
      destinationEntityType = TYPE_EMPLOYEE;
    }

    if (reportId) {
      destinationEntityType = TYPE_REPORT;
    }

    if (channelId) {
      destinationEntityType = TYPE_CHANNEL;
    }

    if (entityType === TYPE_MESSAGE) {
      destinationEntityType = sourceEntityType;
    }

    return {
      title: resultId && entityTitle ? `ID ${resultId} ${entityTitle}` : null,
      entityTitle,
      id: resultId,
      type,
      destinationEntityType,
      destinationEntityId: (destinationEntityId || '').toString(),
      chats: source
        ? [
            {
              channelIsActive: true,
              channelKind: getChannelKindByMessageIsFrom(source.isFrom),
              channelUuid: source.channelId,
              uuid: source.roomUuid
            }
          ]
        : []
    };
  }, [notificationData, source]);

  const isDiscussion =
    entityFromNotification.destinationEntityType === TYPE_DISCUSSION;

  const onRead = unreadNotificationId => {
    const getUnreadNotificationCount = () => {
      if (!isRead) {
        return (
          currentUnreadedCount -
          (unreadNotificationId ? 1 : sourceChatUnreadMessagesCount)
        );
      }

      return currentUnreadedCount + 1;
    };

    socket.readNotificationMessage({
      roomUuid,
      messageUuid: unreadNotificationId || uuid,
      channelKind: getChannelKindByMessageIsFrom(isFrom),
      channelUuid,
      isRead: !isRead,
      parentNotificationUuid: uuid,
      unreadNotificationCount: getUnreadNotificationCount()
    });

    dispatch(
      changeNotificationMessageTempIsRead({
        entityId: employee.id,
        messageUuid: uuid,
        value: null
      })
    );
  };

  const handleNotificationChatDisplay = value => {
    dispatch(
      changeNotificationChatDisplay({ roomUuid: sourceRoomUuid, view, value })
    );

    if (isEmpty(entity) && value) {
      fetchEntity({ isNeedJoinChannels: true });
    }

    // visually do not read notifications when chat is open
    dispatch(
      changeNotificationMessageTempIsRead({
        entityId: employee.id,
        messageUuid: uuid,
        value: value ? isRead : null
      })
    );
  };

  const fetchEntity = async ({ isNeedJoinChannels }) => {
    let fetchedEntity = {};

    try {
      if (entityFromNotification.destinationEntityType === TYPE_TASK) {
        fetchedEntity = await dispatch(
          fetchTaskLocal({ id: entityFromNotification.id })
        );
      }

      if (entityFromNotification.destinationEntityType === TYPE_ASSET) {
        fetchedEntity = await dispatch(
          fetchOne({ id: entityFromNotification.id })
        );

        if (
          isEmpty(fetchedEntity) ||
          (fetchedEntity && !fetchedEntity.permissions.canSendMessage)
        ) {
          throw new Error();
        }
      }

      if (
        entityFromNotification.destinationEntityType === TYPE_CONTACT ||
        entityFromNotification.destinationEntityType === TYPE_ORDER
      ) {
        fetchedEntity = await dispatch(
          fetchContactByChatLocal({
            roomUuid: source.roomUuid
          })
        );

        if (
          isEmpty(fetchedEntity) ||
          (fetchedEntity && !fetchedEntity.email && !fetchedEntity.phone)
        ) {
          throw new Error();
        }
      }

      if (isDiscussion) {
        fetchedEntity = await dispatch(
          fetchDiscussionById({
            id: entityFromNotification.id
          })
        );
      }

      if (isNeedJoinChannels) {
        socket.joinChannels(transformChatsToJoinedGroups(fetchedEntity.chats));
      }

      dispatch(
        setChatEntity({ roomUuid: sourceRoomUuid, entity: fetchedEntity })
      );

      return fetchedEntity;
    } catch {
      dispatch(setChatEntity({ roomUuid: sourceRoomUuid, entity: {} }));

      handleNotificationChatDisplay(false);

      notification.warning({
        message: t('NoAccess')
      });

      return null;
    }
  };

  const onClick = () => {
    if (isDiscussion) {
      handleNotificationChatDisplay(true);

      return dispatch(clearCreatedDiscussionId());
    }

    if (!isRead) {
      onRead(firstUnreadNotificationId);
    }

    return notificationsService.handle({
      templateUid: notificationData.templateUid,
      info: {
        ...notificationData,
        messageUuid:
          firstUnreadMessageId ||
          (source && source.uuid) ||
          notificationData.messageUuid
      }
    });
  };

  const checkAllowReaction = async () => {
    if (isEmpty(entity)) {
      const fetchedEntity = await fetchEntity({ isNeedJoinChannels: false });

      return !!fetchedEntity;
    }

    return true;
  };

  const isDiscussionChatOpened =
    isDiscussion && notificationChatDisplay[sourceRoomUuid];

  const isDisplayTime = !isDiscussionChatOpened;

  return {
    entityFromNotification,
    entity,
    content,
    checkAllowReaction,
    onClick,
    onRead,
    sourceRoomUuid,
    notificationChatDisplay,
    handleNotificationChatDisplay,
    isDisplayTime,
    isDiscussionChatOpened,
    isDiscussion
  };
};

export default useNotificationMessage;
