import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, message } from 'antd';

import {
  CONTRACTOR_ORDER,
  CUSTOMER_ORDER,
  MESSAGE_DURATION,
  ORDER_TO_MARKET,
  TYPE_ORDER_TEMPLATE
} from 'constants/index';

import Typography from 'components/common/typography';

import { deleteTemplate, fetchTemplatesLocal } from 'store/order-statuses';
import { fetchTagsByEntity } from 'store/tags';

import TemplateCard from './card';
import TemplateEditorDrawer from './editor-drawer';

import styles from './template.module.scss';

export const Templates = ({ onClick }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [visibleEditor, setVisibleEditor] = useState(undefined);
  const [templates, setTemplates] = useState([]);

  const getType = item => {
    if (item.isMarketplace) {
      return ORDER_TO_MARKET;
    }

    return item.isCustomer ? CONTRACTOR_ORDER : CUSTOMER_ORDER;
  };

  const onEdit = data => setVisibleEditor(data);

  const onDelete = async id => {
    try {
      setIsLoading(true);

      await dispatch(deleteTemplate({ id }));

      setTemplates(templates.filter(t => t.id !== id));
      message.success('Шаблон удален', MESSAGE_DURATION);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = async ({ isNeedFetch }) => {
    setVisibleEditor(undefined);

    if (isNeedFetch) {
      setTemplates([]);
      fetch();
    }
  };

  const fetch = async () => {
    try {
      setIsLoading(true);

      const { results } = await dispatch(
        fetchTemplatesLocal({ offset: 0, limit: 10000 })
      );

      const templateIds = results.map(template => template.id);

      if (templateIds) {
        dispatch(
          fetchTagsByEntity({
            entityType: TYPE_ORDER_TEMPLATE,
            entityIds: templateIds
          })
        );
      }

      const filtredTemplates = results
        .map(r => ({ ...r, type: getType(r) }))
        // 67877 - нужно убрать фильтр по бирже
        .filter(r => r.type !== ORDER_TO_MARKET);

      setTemplates(filtredTemplates);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!templates.length && !isLoading) {
    return (
      <Typography.Paragraph style={{ margin: 24 }}>
        У вас нет шаблонов. <br /> Чтобы добавить шаблон, создайте заказ и
        укажите “Сохранить как шаблон”
      </Typography.Paragraph>
    );
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className={styles.root}>
          {templates.map(t => (
            <TemplateCard
              key={t.id}
              data={t}
              onClick={tags => onClick({ ...t, tags })}
              onEdit={() => onEdit(t)}
              onDelete={() => onDelete(t.id)}
            />
          ))}
        </div>
      </Spin>

      <TemplateEditorDrawer
        visible={visibleEditor !== undefined}
        data={visibleEditor}
        onClose={onClose}
      />
    </>
  );
};

export default Templates;
