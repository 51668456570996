import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Modal from 'components/common/modal';
import { EmailIcon, SMSIcon } from 'components/common/icons';

import { sendEmailInvite, sendSmsInvite } from 'store/contacts';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import { EMAIL } from '../utils';

import styles from './send-invite-modal.module.scss';

const { Title, Paragraph } = Typography;
const { Option } = Select;

export const SendInviteModal = ({ contact, visible, onClose, ...props }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('InviteForRegistration');

  const isEmailSend = contact.inviteType === EMAIL;

  const listData = isEmailSend ? contact.emails || [] : contact.phones || [];

  const onSend = async () => {
    try {
      setIsLoading(true);

      if (isEmailSend) {
        await dispatch(
          sendEmailInvite({ id: contact.id, email: data || listData[0] })
        );
      } else {
        await dispatch(
          sendSmsInvite({ id: contact.id, phone: data || listData[0] })
        );
      }

      amplitude.contactInvitationEvent({
        value: contact,
        type: contact.inviteType
      });

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const Icon = isEmailSend ? EmailIcon : SMSIcon;
  const title = t(
    isEmailSend ? 'SendEmailInvitationHeading' : 'SendSMSInvitationHeading'
  );
  const text = t(
    isEmailSend ? 'SendEmailInvitationDesc' : 'SendSMSInvitationDesc'
  );

  return (
    <Modal
      width={440}
      visible={visible}
      contentClassName={styles.root}
      data-qa="qa-slu0e2aj4egd9hr"
      onClose={onClose}
      {...props}
    >
      <Icon />

      <Title
        level={1}
        weight="bold"
        style={{ margin: '20px 0', fontWeight: 700 }}
      >
        {title}
      </Title>

      <Paragraph
        color="black-45"
        style={{ margin: '0 auto 20px', maxWidth: 290 }}
      >
        {text}
      </Paragraph>

      {listData.length > 1 ? (
        <Select
          onChange={value => setData(value)}
          value={data || listData[0]}
          className={styles.select}
          data-qa="qa-dkds96hab39w8r0"
        >
          {listData.map(item => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))}
        </Select>
      ) : (
        <Paragraph style={{ fontSize: 16, fontWeight: 600, marginBottom: 0 }}>
          {listData[0]}
        </Paragraph>
      )}

      <Spin spinning={isLoading} wrapperClassName={styles.spinWrap}>
        <div className={styles.buttons} data-qa="qa-0fil63horchd6u4">
          <Button
            type="link"
            className={styles.close}
            data-qa="qa-tdbq5tqcyhjyruf"
            onClick={onClose}
          >
            {t('CloseBtn')}
          </Button>

          <Button type="primary" onClick={onSend}>
            {t('SendBtn')}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

SendInviteModal.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phones: PropTypes.array,
    emails: PropTypes.array
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

SendInviteModal.defaultProps = {
  contact: {},
  visible: false,
  onClose: () => {}
};

export default SendInviteModal;
