import ControlsAiAssistantPrompts from './ai-assistant-prompts';
import ControlsAttachments from './attachments';
import ControlsParentMessage from './parent-message';
import ControlsSubject from './subject';
import ControlsToolbar from './toolbar';
import ControlsTyping from './typing';

const ChatControls = ({ chdilren }) => chdilren;

ChatControls.Attachments = ControlsAttachments;
ChatControls.Toolbar = ControlsToolbar;
ChatControls.Subject = ControlsSubject;
ChatControls.ParentMessage = ControlsParentMessage;
ChatControls.AiAssistantPrompts = ControlsAiAssistantPrompts;
ChatControls.Typing = ControlsTyping;

export {
  ControlsAttachments,
  ControlsToolbar,
  ControlsSubject,
  ControlsParentMessage
};
export default ChatControls;
