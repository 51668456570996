/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER,
  TYPE_CONTACT,
  TYPE_ASSET,
  DATE_FORMAT
} from 'constants/index';
import { TOKEN_FILES } from 'constants/api';

import { getActiveWorkspace } from 'store/workspace';

import { useLocalStorage } from 'hooks';
import useApi from 'hooks/common/use-file-upload/use-api';
import {
  DOCX,
  DOCX_TYPE,
  PPTX,
  PPTX_TYPE,
  XLSX,
  XLSX_TYPE
} from 'hooks/common/use-file-upload/types';

import SelectType from './select-type';
import Settings from './settings';

const SELECT_TYPE_STEP = 'select-type-step';
const SETTINGS_STEP = 'settings-step';

const labelByType = {
  [TYPE_TASK]: 'Task',
  [TYPE_ORDER]: 'Order',
  [TYPE_CONTACT]: 'Contact',
  [TYPE_ASSET]: 'Asset'
};

export const Create = ({ defaultValues, onSubmit }) => {
  const [, setToken] = useLocalStorage(TOKEN_FILES);

  const { t } = useTranslation('Common');

  const { auth, createFile } = useApi();

  const [currentType, setCurrentType] = useState(null);
  const [currentStep, setCurrentStep] = useState(
    defaultValues.step || SELECT_TYPE_STEP
  );

  const [isLoading, setIsLoading] = useState(false);

  const workspace = useSelector(getActiveWorkspace);

  const goTo = ({ step, type }) => {
    setCurrentType(type);
    setCurrentStep(step);
  };

  const onSelectType = type => {
    const { relation } = defaultValues;

    if (relation) {
      const title = `${t(labelByType[relation.relationType], {
        ns: 'Common'
      })}_${relation.relationId}_${moment(new Date()).format(DATE_FORMAT)}`;

      return handleSubmit({ type, title, ...defaultValues });
    }

    return goTo({ step: SETTINGS_STEP, type });
  };

  const onCreateFile = async ({ title, type }) => {
    await auth(workspace.id, workspace.user.id).then(setToken);

    return createFile({ title, type });
  };

  const handleSubmit = async ({
    type = currentType,
    title,
    relation,
    subscribers,
    allowSubscriberSelection,
    validityDate
  }) => {
    try {
      setIsLoading(true);

      const { file_id } = await onCreateFile({ title, type });

      const getMimeType = () => {
        if (type === DOCX) {
          return DOCX_TYPE;
        }

        if (type === XLSX) {
          return XLSX_TYPE;
        }

        if (type === PPTX) {
          return PPTX_TYPE;
        }

        return 'invalid-type';
      };

      const data = {
        id: file_id,
        mimeType: getMimeType(),
        isCreated: true,
        title: `${title}.${type}`
      };

      const subscribersForAttachments = allowSubscriberSelection
        ? [
            {
              allowSubscriberSelection,
              mimeType: data.mimeType,
              fileId: file_id,
              subscribers
            }
          ]
        : [];

      const validityDates = validityDate
        ? [
            {
              validityDate,
              fileId: file_id
            }
          ]
        : [];

      await onSubmit({
        fileList: [data],
        relation,
        subscribersForAttachments,
        validityDates
      });
    } finally {
      setIsLoading(false);
    }
  };

  const steps = {
    [SELECT_TYPE_STEP]: (
      <SelectType isLoading={isLoading} onClick={onSelectType} />
    ),
    [SETTINGS_STEP]: (
      <Settings
        type={currentType}
        isLoading={isLoading}
        onBack={() => goTo({ step: SELECT_TYPE_STEP, type: null })}
        onSubmit={handleSubmit}
      />
    )
  };

  return <>{steps[currentStep]}</>;
};

Create.propTypes = {
  defaultValues: PropTypes.shape({
    step: PropTypes.oneOf([SELECT_TYPE_STEP, SETTINGS_STEP]),
    relation: PropTypes.shape({
      relationId: PropTypes.number,
      relationType: PropTypes.oneOf([
        TYPE_TASK,
        TYPE_ORDER,
        TYPE_CONTACT,
        TYPE_ASSET
      ])
    })
  }),
  onSubmit: PropTypes.func
};

Create.defaultProps = {
  defaultValues: {},
  onSubmit: () => {}
};

export default Create;
