import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_SERVICE_OPTIONS, IMAP, VIDEOS } from 'constants/index';

import Typography from 'components/common/typography';
import { SubmitWrapper, withoutBubbling } from 'components/common/hook-form';
import Button from 'components/common/button';
import VideoBtn from 'components/common/video-btn';

import nullishCoalescingOperator from 'utils/nullish-coalescing-operator';

import ServiceSelect from './components/service-select';
import Imap from './components/imap';
import { getSelectDefaultValue, setImapSmtpSettings } from './utils';

import styles from './email-service.module.scss';

const EmailServiceFrom = ({ defaultValues, isLoading, onSubmit }) => {
  const { control, ...methods } = useForm({
    defaultValues: {
      ...defaultValues,
      service: getSelectDefaultValue(
        EMAIL_SERVICE_OPTIONS,
        defaultValues.service,
        IMAP
      ),
      imapServer: defaultValues.imapServer,
      imapPort: defaultValues.imapPort || 993,
      isImapSsl: nullishCoalescingOperator(defaultValues.isImapSsl, true),
      smtpServer: defaultValues.smtpServer,
      smtpPort: defaultValues.smtpPort || 465,
      isSmtpSsl: nullishCoalescingOperator(defaultValues.isSmtpSsl, true)
    }
  });

  const { t } = useTranslation('ConnectEmail');

  const service = useWatch({ name: 'service', control });
  const email = useWatch({ name: 'email', control });

  const onBlurEmail = useCallback(
    () => setImapSmtpSettings(email, methods.setValue),
    [email, methods.setValue]
  );

  const handleSubmit = useCallback(
    ({ smtpPort, imapPort, ...values }) =>
      onSubmit({
        smtpPort: +smtpPort,
        imapPort: +imapPort,
        ...values
      }),
    [onSubmit]
  );

  const component = useMemo(() => {
    if (service.value === IMAP) {
      return <Imap onBlurEmail={onBlurEmail} />;
    }

    return null;
  }, [onBlurEmail, service.value]);

  return (
    <FormProvider control={control} {...methods}>
      <form
        className={styles.root}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        <div className={styles.service}>
          <Typography.Title level={3} className={styles.title}>
            {t('MailService')}
          </Typography.Title>

          <ServiceSelect
            itemProps={{
              className: styles.serviceSelect
            }}
            name="service"
            isDisabled
          />
        </div>

        <VideoBtn
          slug={VIDEOS.connectEmailChannel}
          style={{ marginBottom: 16 }}
        />

        {component}

        <SubmitWrapper>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            loading={isLoading}
            className={styles.btn}
          >
            {t('NextBtn')}
          </Button>
        </SubmitWrapper>
      </form>
    </FormProvider>
  );
};

EmailServiceFrom.propTypes = {
  defaultValues: PropTypes.shape({
    service: PropTypes.oneOf(EMAIL_SERVICE_OPTIONS.map(o => o.value)),
    email: PropTypes.string,
    password: PropTypes.string,
    imapServer: PropTypes.string,
    imapPort: PropTypes.number,
    isImapSsl: PropTypes.bool,
    smtpServer: PropTypes.string,
    smtpPort: PropTypes.number,
    isSmtpSsl: PropTypes.bool
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

EmailServiceFrom.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export default EmailServiceFrom;
