import { createAction } from 'redux-actions';

import { getUser } from 'store/user';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getWorkspaceId, getEmployeeId } from '../workspace';

export const fetchActiveNotifications = createActionThunk(
  'notifications/fetch-active',
  ({ offset = 0, limit = 1000, getState }) => {
    const state = getState();

    const user = getUser(state);
    const activeWorkspaceId = getWorkspaceId(state);

    const receiver =
      activeWorkspaceId === user.personalWorkspaceId
        ? user.personalEmployeeId
        : getEmployeeId(state)(activeWorkspaceId);

    return api.notifications
      .fetch({ receiver, offset, limit })
      .then(({ data }) => ({
        results: data.results,
        totalItems: data.count,
        workspaceId: activeWorkspaceId
      }));
  }
);

export const acceptNotification = createActionThunk(
  'notifications/accept',
  ({ actionId, isAccept, withExtendPerms, getState }) => {
    const state = getState();

    const activeWorkspaceId = getWorkspaceId(state);

    return api.actions
      .accept(activeWorkspaceId, actionId, isAccept, withExtendPerms)
      .then(() => ({ workspaceId: activeWorkspaceId }));
  }
);

export const disableIsArrived = createAction(
  'notifications/disable-is-arrived'
);

export const closeQueueNotification = createAction(
  'notifications/close-queue-notification'
);

export const setDrawerVisible = createAction(
  'notifications/set-drawer-visible'
);

export const setChannelChatNewMessagesPush = createAction(
  'notification/set-channel-chat-new-messages-push'
);
