import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

export const getProjects = state => state.projects;

export const getProjectsEntries = createSelector(
  getProjects,
  projects => projects.entries
);

export const getFilterProjects = createSelector(
  getProjects,
  projects => projects.filter
);

export const getFilterSearchProjects = createSelector(
  getFilterProjects,
  filter => filter.search
);

export const getTotalItems = createSelector(
  getProjects,
  projects => projects.totalItems
);

export const getCurrentPage = createSelector(
  getProjects,
  projects => projects.currentPage
);

export const getItemsPerPage = createSelector(
  getProjects,
  projects => projects.itemsPerPage
);

export const getIsProjectsLoading = createSelector(
  getProjects,
  projects => projects.isLoading
);

export const getIsProjectsLoaded = createSelector(
  getProjects,
  projects => projects.isLoaded
);

export const getProjectsHasMore = createSelector(
  getProjects,
  projects => projects.hasMore
);

export const getCurrentProjectId = createSelector(
  getProjects,
  projects => projects.currentProjectId
);

export const getProjectFactory = createSelector(getProjectsEntries, entries =>
  memoize(projectId => entries.find(project => +project.id === +projectId))
);

export const getError = createSelector(getProjects, projects => projects.error);

export const getHasError = createSelector(getError, error => error !== null);

export const getAllProjectsLength = createSelector(
  getProjectsEntries,
  entries => entries.length
);

export const getSprintsFilter = createSelector(
  getProjects,
  projects => projects.sprintFilter
);
