import qs from 'qs';

export default api => ({
  fetch: ({
    workspaceId,
    offset,
    limit,
    search = null,
    parent,
    excludeType,
    excludeDepartment,
    excludeWithChildren,
    lite
  }) =>
    api.get(`/v1/${workspaceId}/departments/`, {
      params: {
        limit,
        offset,
        search,
        parent,
        exclude_type: excludeType,
        exclude: excludeDepartment,
        exclude_with_children: excludeWithChildren,
        lite
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/departments/${id}/`),

  fetchJoins: workspaceId => api.get(`/v1/${workspaceId}/departments/joins/`),

  fetchActions: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/departments/${id}/actions/`),

  fetchPositions: (workspaceId, search = null) =>
    api.get(`/v1/${workspaceId}/departments/positions/`, {
      params: {
        search
      }
    }),

  filter: (workspaceId, search, category, roleType, position) =>
    api.post(`/v1/${workspaceId}/departments/search/`, {
      search,
      category,
      roleType,
      position
    }),

  disband: (workspaceId, id, reason) =>
    api.post(`/v1/${workspaceId}/departments/${id}/disband/`, { reason }),

  create: ({ workspaceId, data }) =>
    api.post(`/v1/${workspaceId}/departments/`, data),

  move: ({ workspaceId, id, parentDepartmentId, reason }) =>
    api.post(`/v1/${workspaceId}/departments/${id}/move/`, {
      parentDepartment: parentDepartmentId,
      reason
    }),

  createWithInvite: (workspaceId, name, managerEmail, employeeEmails) =>
    api.post(`/v1/${workspaceId}/departments/with-invitations/`, {
      name,
      managerEmail,
      employeeEmails
    }),

  update: (workspaceId, id, name, manager, employees) =>
    api.put(`/v1/${workspaceId}/departments/${id}/`, {
      name,
      manager,
      employees
    }),

  delete: (workspaceId, id) =>
    api.delete(`/v1/${workspaceId}/departments/${id}/`)
});
