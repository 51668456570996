import { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { showNoticeMessage } from 'services/notice';

import { getWorkspaceId } from '../../../store/workspace';
import { fetchTemplatesLocal, deleteTemplateLocal } from '../../../store/tasks';

const transformTemplate = template =>
  _.pickBy(
    {
      ...template,
      assignments: (template || {}).members,
      createdAt: null
    },
    _.identity
  );

const actionTypes = {
  setTemplates: 'TEMPLATES',
  editTemplate: 'EDIT',
  deleteTemplate: 'DELETE',
  setIsLoading: 'LOADING',
  setHasError: 'ERROR'
};

const initialState = {
  totalItems: undefined,
  currentlyDeletedCount: 0,
  currentlyEditableTemplate: undefined,
  isLoading: false,
  hasError: undefined,

  templates: []
};

const dataReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.setTemplates: {
      return { ...state, ...payload };
    }

    case actionTypes.deleteTemplate: {
      return {
        ...state,
        currentlyDeletedCount: state.currentlyDeletedCount + 1,
        totalItems: state.totalItems - 1,
        templates: state.templates.filter(({ id }) => id !== payload.id)
      };
    }

    case actionTypes.editTemplate: {
      return { ...state, currentlyEditableTemplate: payload };
    }

    case actionTypes.setTotalItems: {
      return { ...state, totalItems: payload };
    }

    case actionTypes.setHasError: {
      return { ...state, hasError: payload };
    }

    case actionTypes.setIsLoading: {
      return { ...state, isLoading: payload };
    }

    default: {
      throw new Error(`Unknow type: ${type}`);
    }
  }
};

export const useData = ({
  active,
  isSchedulerTemplates,
  reducer = dataReducer
} = {}) => {
  const [data, localDispatch] = useReducer(reducer, initialState);
  const workspaceId = useSelector(getWorkspaceId);
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const { t } = useTranslation('AddTaskType');

  const setHasError = useCallback(
    payload => localDispatch({ type: actionTypes.setHasError, payload }),
    [localDispatch]
  );
  const setIsLoading = useCallback(
    payload => localDispatch({ type: actionTypes.setIsLoading, payload }),
    [localDispatch]
  );
  const setTemplates = useCallback(
    payload => localDispatch({ type: actionTypes.setTemplates, payload }),
    [localDispatch]
  );
  const deleteTemplate = useCallback(
    async template => {
      await dispatch(deleteTemplateLocal({ id: template.id }));

      amplitude.deleteTaskTemplateEvent(template.isScheduler);

      showNoticeMessage({ customContent: t('TemplateDeletedToast') });

      localDispatch({
        type: actionTypes.deleteTemplate,
        payload: { id: template.id }
      });
    },
    [dispatch, amplitude, t]
  );
  const editTemplate = useCallback(
    payload => {
      localDispatch({
        type: actionTypes.editTemplate,
        payload
      });
    },
    [localDispatch]
  );

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { results = [], totalItems } = await dispatch(
        fetchTemplatesLocal({
          offset: 0,
          limit: 10000,
          isScheduler: isSchedulerTemplates
        })
      );

      const transformedTemplates = results.map(transformTemplate);

      setTemplates({
        totalItems,
        templates: transformedTemplates
      });
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (active && !data.isLoading) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [workspaceId, active]);

  const onEditDrawerClose = updatedTemplate => {
    editTemplate(false);

    if (updatedTemplate) {
      const _updatedTemplate = {
        ...updatedTemplate,
        assignments: updatedTemplate.assignments || []
      };

      const templates = data.templates.map(template =>
        template.id === updatedTemplate.id ? _updatedTemplate : template
      );

      setTemplates({ templates });
    }
  };

  return {
    data,

    setHasError,
    setIsLoading,
    fetchData,
    editTemplate,
    onEditDrawerClose,
    deleteTemplate
  };
};

export default useData;
