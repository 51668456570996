import React from 'react';

export const DeleteFile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.9381 13.3807C84.4283 12.9786 98.7844 17.8033 110.055 26.9164C120.922 35.7616 125.484 49.2974 130.582 62.4311C135.949 76.3689 144.402 90.5748 140.377 105.049C136.217 119.523 124.008 131.986 109.518 136.677C96.2352 140.965 83.6233 129.708 69.9381 127.698C59.2046 126.089 47.6661 131.182 38.0059 125.955C27.9433 120.461 22.7107 109.471 17.7464 99.0179C12.1114 87.0903 5.13458 74.7607 7.68378 61.895C10.233 48.2252 20.2957 37.3698 31.1633 28.7927C42.4335 20.0815 55.7162 13.7827 69.9381 13.3807Z"
      fill="#EBF8F8"
    />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="2"
      y="-9"
      width="135"
      height="155"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.65 -8.07127L2.85742 13.5635L29.5145 145.147L136.307 123.512L109.65 -8.07127ZM70.5089 9.78459L59.7052 11.9733L58.2981 52.0361L67.0683 50.2593L68.0131 98.2199L76.0044 96.601L74.6624 124.089L85.4995 121.894L87.3577 83.8331L78.4978 85.628L77.5531 37.6674L69.4721 39.3045L70.5089 9.78459Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M28.1294 31.6322L87.646 15.6848C92.4717 14.3917 97.3666 17.2179 98.6597 22.0435L116.978 90.4073C118.271 95.2329 115.445 100.128 110.619 101.421L51.1023 117.368C46.2766 118.661 41.3816 115.835 40.0886 111.01L21.7706 42.6459C20.3167 37.8633 23.3037 32.9253 28.1294 31.6322Z"
        fill="#E2E2E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1995 39.4875L87.2819 25.2641C87.7645 25.1348 88.451 25.1233 88.9021 25.5197C89.31 25.7552 89.8041 26.3124 89.9334 26.795L97.002 53.1753C97.1313 53.6579 97.1428 54.3444 96.7465 54.7954C96.511 55.2033 95.9537 55.6974 95.4712 55.8268L42.3887 70.0501C41.9062 70.1794 41.2197 70.191 40.7686 69.7946C40.3607 69.5591 39.8666 69.0019 39.7373 68.5193L32.6687 42.139C32.5394 41.6564 32.5279 40.9699 32.9242 40.5189C33.1166 39.9501 33.5561 39.6599 34.1995 39.4875Z"
        fill="#36B7BD"
      />
      <path
        d="M70.3852 87.0304L48.0262 93.0215C47.0611 93.2801 46.2453 92.809 45.9867 91.8439C45.7281 90.8788 46.1991 90.0629 47.1642 89.8043L69.5232 83.8133C70.4883 83.5547 71.3042 84.0257 71.5628 84.9908C71.8214 85.956 71.1895 86.8149 70.3852 87.0304Z"
        fill="#2B2A36"
      />
      <path
        d="M90.15 73.2867L45.7538 85.1826C44.7887 85.4412 43.9728 84.9702 43.7142 84.005C43.4556 83.0399 43.9266 82.2241 44.8918 81.9655L89.1271 70.1126C90.0922 69.854 90.9081 70.3251 91.1667 71.2902C91.4253 72.2553 90.9543 73.0712 90.15 73.2867Z"
        fill="#2B2A36"
      />
      <path
        d="M87.7579 82.3759L79.3934 84.6172C78.4283 84.8758 77.6125 84.4047 77.3539 83.4396C77.0953 82.4745 77.5663 81.6586 78.5314 81.4L86.8959 79.1588C87.861 78.9002 88.6769 79.3712 88.9355 80.3363C89.1941 81.3015 88.5622 82.1604 87.7579 82.3759Z"
        fill="#2B2A36"
      />
      <path
        d="M78.6857 40.1539C80.6275 40.3232 82.46 38.7977 82.6293 36.8559C82.7986 34.9141 81.2732 33.0816 79.3314 32.9123C77.3895 32.7429 75.557 34.2684 75.3877 36.2102C75.2184 38.152 76.583 40.0277 78.6857 40.1539Z"
        fill="white"
      />
      <path
        d="M54.0201 45.901L46.4038 63.803L74.7144 56.2172L59.1675 44.5217C57.2888 43.3011 54.8759 43.9476 54.0201 45.901Z"
        fill="white"
      />
      <path
        d="M73.0103 48.5707L69.0844 57.7256L86.1351 53.1569L78.1577 47.1914C76.4398 45.9277 73.8661 46.6173 73.0103 48.5707Z"
        fill="white"
      />
    </g>
    <path
      d="M135.726 112.566L121.15 89.6516C119.446 86.9729 115.536 86.9729 113.832 89.6516L99.256 112.566C97.4194 115.453 99.4933 119.23 102.915 119.23H132.067C135.489 119.23 137.563 115.453 135.726 112.566Z"
      fill="#F9716C"
    />
    <path
      d="M105.807 112.567L120.383 89.6528C120.5 89.4679 120.629 89.2972 120.767 89.1379C118.916 86.9882 115.418 87.1589 113.832 89.6528L99.256 112.567C97.4194 115.454 99.4933 119.231 102.915 119.231H109.466C106.044 119.231 103.97 115.454 105.807 112.567Z"
      fill="#F7413B"
    />
    <path
      d="M118.27 107.337H116.711C115.888 107.337 115.214 106.664 115.214 105.84V96.4834C115.214 95.66 115.888 94.9863 116.711 94.9863H118.27C119.094 94.9863 119.767 95.66 119.767 96.4834V105.84C119.767 106.664 119.094 107.337 118.27 107.337Z"
      fill="white"
    />
    <path
      d="M117.491 115.423C118.748 115.423 119.767 114.403 119.767 113.146C119.767 111.888 118.748 110.869 117.491 110.869C116.233 110.869 115.214 111.888 115.214 113.146C115.214 114.403 116.233 115.423 117.491 115.423Z"
      fill="white"
    />
  </svg>
);
export default DeleteFile;
