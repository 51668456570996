import qs from 'qs';
import { LOCATION_CHANGE } from 'connected-react-router';

import { handleActions } from 'utils/redux-actions';

import { setIsTabVisible, setTabKey, setWithoutDefaultFilter } from './actions';

const initialState = {
  workspaceId: null,
  activePath: '',
  view: undefined,
  subMenu: undefined,
  query: {},
  tabKey: null,
  isTabVisible: true
};

export default handleActions(
  {
    [LOCATION_CHANGE]: (state, { payload }) => {
      const { pathname, search } = payload.location;
      const urls = pathname.split('/').slice(1);

      const [workspaceId, view = 'orders', ...subMenu] = urls;
      const query = qs.parse(search, {
        ignoreQueryPrefix: true,
        decoder: (str, defaultDecoder, charset, type) => {
          const keywords = {
            true: true,
            false: false,
            null: null,
            // eslint-disable-next-line object-shorthand
            undefined: undefined
          };

          if (type === 'value' && str in keywords) {
            return keywords[str];
          }

          return defaultDecoder(str, defaultDecoder, charset);
        }
      });

      state.isFirstRendering = payload.isFirstRendering;
      state.workspaceId = +workspaceId;
      state.activePath = `/${workspaceId}`;

      state.view = view;
      // TODO убрать subMenu как rest, когда будет убрана вложенность
      state.subMenu = subMenu.join('/');
      state.query = query;

      return state;
    },

    [setTabKey]: (state, { payload }) => {
      state.tabKey = payload;
    },

    [setWithoutDefaultFilter]: (state, { payload }) => {
      state.withoutDefaultFilter = payload;
    },

    [setIsTabVisible]: (state, { payload }) => {
      state.isTabVisible = payload;
    }
  },
  initialState
);
