import React, { useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TYPE_ORDER_STATUS, TYPE_TASK } from 'constants/index';

import Icon from 'components/common/icon';
import CustomCollapse from 'components/tasks-view/view/drawers/components/collapse';
import Button from 'components/common/button';
import Card from 'components/assets-view/views/all/list/card';
import Typography from 'components/common/typography';

import {
  changeOrderStatusRelations,
  fetchOrderStatusRelations
} from 'store/order-statuses';
import {
  changeRelations as changeTaskRelations,
  fetchRelations as fetchTaskRelations
} from 'store/tasks';

import AssetRelationModal from '../relation-modal';
import Actions from './actions';

import styles from './list.module.scss';

const AssetList = ({ assets, entityData, bookings }) => {
  const dispatch = useDispatch();

  const [visibleRelationModal, setVisibleRelationModal] = useState(false);

  const { t } = useTranslation('Common');

  const hasAssets = !!assets.length;
  const transfromedAssets = assets.map(a => ({
    ...a.relation,
    relationId: a.id,
    title: a.relation.name,
    bookingData: (bookings.find(b => b.assetId === a.relation.id) || {})
      .recordData
  }));

  const changeRelations = async value => {
    if (entityData.type === TYPE_ORDER_STATUS) {
      await dispatch(
        changeOrderStatusRelations({ id: entityData.id, relations: value })
      );

      dispatch(fetchOrderStatusRelations({ id: entityData.id }));
    }

    if (entityData.type === TYPE_TASK) {
      await dispatch(
        changeTaskRelations({ id: entityData.id, relations: value })
      );

      dispatch(fetchTaskRelations({ id: entityData.id }));
    }

    return () => {};
  };

  return (
    <>
      <div className={styles.root} data-qa="qa-w3cdhrqerodsilt">
        <Tooltip title={t('AddRelationWithAsset')} placement="topRight">
          <Button
            type="text"
            mood="none"
            className={styles.btn}
            onClick={() => setVisibleRelationModal(true)}
          >
            <Icon type="plus" color="black-55" data-qa="qa-2rugt0vbszunkcs" />
          </Button>
        </Tooltip>

        {hasAssets ? (
          <CustomCollapse
            defaultActiveKey={!transfromedAssets.length ? null : 'assets'}
          >
            <CustomCollapse.Panel
              header={
                <Typography.Title level={3} className={styles.title}>
                  {t('Assets')}
                </Typography.Title>
              }
              key="assets"
              showArrow={hasAssets}
            >
              {transfromedAssets.map((a, index) => (
                <Card
                  key={`${a.id}-${index}`}
                  asset={a}
                  tagListOptions={{
                    needFetchTags: true,
                    displayedCount: 1,
                    shortAddButton: true
                  }}
                  style={{ marginBottom: 12 }}
                  ActionsComponent={props => (
                    <Actions
                      {...props}
                      onChange={changeRelations}
                      entityData={entityData}
                      bookingData={a.bookingData}
                    />
                  )}
                  entityData={entityData}
                />
              ))}
            </CustomCollapse.Panel>
          </CustomCollapse>
        ) : (
          <Typography.Title level={3} className={styles.title}>
            {t('Assets')}
          </Typography.Title>
        )}
      </div>

      <AssetRelationModal
        visible={visibleRelationModal}
        assets={transfromedAssets}
        onSubmit={changeRelations}
        onClose={() => setVisibleRelationModal(false)}
      />
    </>
  );
};

AssetList.propTypes = {
  assets: PropTypes.array,
  entityData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    allowEditDates: PropTypes.bool
  }),
  bookings: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

AssetList.defaultProps = {
  assets: [],
  entityData: {},
  bookings: []
};

export default AssetList;
