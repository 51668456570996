export default api => ({
  // ----IDEAS--- //

  fetch: (workspaceId, offset, limit, search = null) =>
    api.get(`/v1/${workspaceId}/ideas/`, {
      params: {
        limit,
        offset,
        search
      }
    }),

  create: (workspaceId, idea) => api.post(`/v1/${workspaceId}/ideas/`, idea),

  fetchOne: (workspaceId, id) => api.get(`/v1/${workspaceId}/ideas/${id}/`),

  update: (workspaceId, id, idea) =>
    api.put(`/v1/${workspaceId}/ideas/${id}/`, idea),

  publish: (workspaceId, id) =>
    api.put(`/v1/${workspaceId}/ideas/${id}/publish/`),

  convertToProject: (workspaceId, ideaId, ideaData) =>
    api.post(`/v1/${workspaceId}/ideas/${ideaId}/convert-project/`, ideaData),

  convertToTask: (workspaceId, ideaId, data) =>
    api.post(`/v1/${workspaceId}/ideas/${ideaId}/convert-task/`, data),

  createTask: (workspaceId, ideaId, data) =>
    api.post(`/v1/${workspaceId}/ideas/${ideaId}/tasks/`, data),

  fetchComments: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/ideas/${id}/comments/`, {
      params: {
        limit,
        offset
      }
    }),

  createMember: (workspaceId, id, member) =>
    api.post(`/v1/${workspaceId}/ideas/${id}/members/`, member),

  deleteMember: (workspaceId, id, memberId) =>
    api.delete(`/v1/${workspaceId}/ideas/${id}/members/${memberId}/`),

  fetchRelations: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/ideas/${id}/relations/`, {
      params: {
        limit,
        offset
      }
    }),

  createRelation: (workspaceId, ideaId, relation) =>
    api.post(`/v1/${workspaceId}/ideas/${ideaId}/relations/`, relation),

  readRelation: (workspaceId, ideaId, relationId) =>
    api.get(`/v1/${workspaceId}/ideas/${ideaId}/relations/${relationId}/`),

  deleteRelation: (workspaceId, ideaId, relationId) =>
    api.delete(`/v1/${workspaceId}/ideas/${ideaId}/relations/${relationId}/`),

  fetchAttachments: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/ideas/${id}/attachments/`, {
      params: {
        limit,
        offset
      }
    }),

  // ----IDEAS-PUBLIC--- //

  fetchPublic: (workspaceId, offset, limit, search = null) =>
    api.get(`/v1/${workspaceId}/ideas-public/`, {
      params: {
        limit,
        offset,
        search
      }
    }),

  fetchOnePublic: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/ideas-public/${id}/`),

  fetchAttachmentsPublic: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/ideas-public/${id}/attachments/`, {
      params: {
        limit,
        offset
      }
    })
});
