import React from 'react';

export const AppstoreAddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.2828 1.71484L21.4256 1.71485C21.6613 1.71485 21.8542 1.9077 21.8542 2.14342V10.2863C21.8542 10.522 21.6613 10.7148 21.4256 10.7148L13.2828 10.7148C13.047 10.7148 12.8542 10.522 12.8542 10.2863L12.8542 2.14342C12.8542 1.9077 13.047 1.71484 13.2828 1.71484ZM14.6756 8.89342L20.0328 8.89342V3.53627L14.6756 3.53627L14.6756 8.89342ZM2.56847 1.71484L10.7113 1.71484C10.947 1.71484 11.1399 1.9077 11.1399 2.14342L11.1399 10.2863C11.1399 10.522 10.947 10.7148 10.7113 10.7148L2.56847 10.7148C2.33275 10.7148 2.13989 10.522 2.13989 10.2863L2.13989 2.14342C2.13989 1.9077 2.33275 1.71484 2.56847 1.71484ZM3.96132 8.89342L9.31847 8.89342L9.31847 3.53627L3.96132 3.53627L3.96132 8.89342ZM2.56847 12.4291L10.7113 12.4291C10.947 12.4291 11.1399 12.622 11.1399 12.8577L11.1399 21.0006C11.1399 21.2363 10.947 21.4291 10.7113 21.4291H2.56846C2.33275 21.4291 2.13989 21.2363 2.13989 21.0006L2.13989 12.8577C2.13989 12.622 2.33275 12.4291 2.56847 12.4291ZM3.96132 19.6077H9.31847L9.31847 14.2506L3.96132 14.2506L3.96132 19.6077ZM14.3542 16.9291H17.7828L17.7828 13.5006C17.7828 13.3827 17.8792 13.2863 17.997 13.2863L19.2828 13.2863C19.4006 13.2863 19.497 13.3827 19.497 13.5006V16.9291H22.9256C23.0435 16.9291 23.1399 17.0256 23.1399 17.1434V18.4291C23.1399 18.547 23.0435 18.6434 22.9256 18.6434H19.497L19.497 22.072C19.497 22.1898 19.4006 22.2863 19.2828 22.2863H17.997C17.8792 22.2863 17.7828 22.1898 17.7828 22.072V18.6434H14.3542C14.2363 18.6434 14.1399 18.547 14.1399 18.4291V17.1434C14.1399 17.0256 14.2363 16.9291 14.3542 16.9291Z"
      fill="currentColor"
    />
  </svg>
);

export default AppstoreAddIcon;
