import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createReminder,
  deleteReminder,
  fetchReminder,
  getEntityReminder,
  updateReminder
} from 'store/reminders';

import useModalsService from 'services/modals';
import { showNoticeMessage } from 'services/notice';

export const useReminder = ({ entityType, entityId, textReminder }) => {
  const dispatch = useDispatch();
  const modals = useModalsService();

  const { t } = useTranslation('Toast', 'Common');

  const entityReminder = useSelector(state =>
    getEntityReminder(state)({ entityType, entityId })
  );

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const fetch = async () => {
    try {
      setIsLoadingFetch(true);

      await dispatch(fetchReminder({ entityType, entityId }));
    } finally {
      setIsLoadingFetch(false);
    }
  };

  const onSubmit = async values => {
    const updatedComment =
      values.comment === t('ReminderDefaultValue', { ns: 'Common' }) ||
      values.comment.trim() === ''
        ? `${t('ReminderDefaultValue', { ns: 'Common' })} ${textReminder}`
        : values.comment;

    try {
      setIsLoadingSubmit(true);

      let data;

      if (entityReminder) {
        data = await dispatch(
          updateReminder({
            entityType,
            entityId,
            reminderId: entityReminder.id,
            values: {
              ...values,
              comment: updatedComment
            }
          })
        );
      } else {
        data = await dispatch(
          createReminder({
            entityType,
            entityId,
            values: {
              ...values,
              comment: updatedComment
            }
          })
        );
      }

      if (data.taskId) {
        showNoticeMessage({
          customContent: t('TaskWithIdCreated', { taskId: data.taskId })
        });
      }

      setVisiblePopover(false);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoadingSubmit(true);

      await dispatch(
        deleteReminder({ entityType, entityId, reminderId: entityReminder.id })
      );

      setVisiblePopover(false);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const openTaskModal = () =>
    modals.tasks.showDetails({ id: entityReminder.taskId });

  useEffect(() => {
    if (!entityReminder) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    visiblePopover,
    setVisiblePopover,
    isLoadingFetch,
    isLoadingSubmit,
    reminder: entityReminder,
    onSubmit,
    onDelete,
    openTaskModal
  };
};

export default useReminder;
