import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import EditableField from './editable-field';

const MAX_FIELD_COUNT = 5;

export const MiltipleField = ({
  children,
  control,
  name,
  label,
  newValue,
  ...props
}) => {
  const { fields, append } = useFieldArray({
    control,
    name
  });

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0 24px'
      }}
    >
      {fields.map((field, index) => (
        <EditableField
          key={field.id}
          name={`${name}.${index}.value`}
          label={`${label} ${index + 1}`}
          defaultValue={field.value}
          allowAdd={
            fields.length === index + 1 && fields.length < MAX_FIELD_COUNT
          }
          onAdd={() => append({ value: newValue })}
          {...props}
        >
          {children}
        </EditableField>
      ))}
    </div>
  );
};

MiltipleField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default MiltipleField;
