import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './creatable-option.module.scss';

const { Paragraph } = Typography;

export const CreatableOption = ({ children, style, ...props }) => {
  const { t } = useTranslation('Filters');

  return (
    <div className={styles.root} style={{ ...style }} {...props}>
      <Paragraph ellipsis={{ rows: 1 }} className={styles.row}>
        <span className={styles.btn}>{t('ChooseBtn')}</span>

        {children}
      </Paragraph>
    </div>
  );
};

export default CreatableOption;
