import React from 'react';

export const OwnerIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#36B7BD" />
    <path d="M12 13H6L5 8L7.5 9.5L9 5L10.5 9.5L13 8L12 13Z" fill="white" />
  </svg>
);

export default OwnerIcon;
