import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchManagersLocal } from 'store/assets';

import CustomSelect from '../custom-select';
import UserOption from '../custom-select/user-option';

const mapValue = value => ({
  value: value.id,
  label: value.employee
});

const ManagersSelect = ({ isMulti, value, ...props }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Common');

  const fetchData = params =>
    dispatch(fetchManagersLocal({ params })).then(({ count, results }) => ({
      totalItems: count,
      entries: results.map(mapValue)
    }));

  return (
    <CustomSelect
      fetchData={fetchData}
      isAsync
      isClearable
      value={value}
      isSearchable
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      style={{ width: '100%' }}
      Option={UserOption}
      valueText={t('EnterYourNameEmail')}
      {...props}
    />
  );
};

export default ManagersSelect;
