import memoize from 'lodash/memoize';
import cloneDeep from 'lodash/cloneDeep';

export const getAssetKey = asset => `${asset.id}-${asset.category.id}`;

export const getAssetIdByKey = key => Number(key.split('-')[0]);

const getAssetCategoryIdByKey = key => Number(key.split('-')[1]);

export const getAssetIdsByKey = key => ({
  id: getAssetIdByKey(key),
  categoryId: getAssetCategoryIdByKey(key)
});

export const getCategoryKeyById = id => `asset-category-${id}`;

export const getCategoryIdByKey = key => {
  if (!key) {
    return key;
  }

  return Number(key.split('-')[2]);
};

export const transformToTreeData = memoize(data => {
  const list = cloneDeep(data);

  const roots = [];
  const map = {};

  list.forEach(item => {
    map[item.id] = item;
  });

  list.forEach(item => {
    const parent = map[item.parentId];

    item.key = getCategoryKeyById(item.id);
    item.isCategory = true;

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(item);
    } else {
      roots.push(item);
    }
  });

  return roots;
});

export const getAssetsByKeys = memoize(({ keys, assets }) =>
  keys.reduce((acc, curr) => {
    const asset = assets.find(a => a.id === getAssetIdByKey(curr));

    if (asset) {
      acc.push({
        value: asset.id,
        label: asset
      });
    }

    return acc;
  }, [])
);
