import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spin, Tooltip } from 'antd';
import classnames from 'classnames';

import Button from 'components/common/button';

import useFieldEditorContext from '../use-context';

import styles from './field-editor-item.module.scss';

export const FieldEditorItem = ({
  children,
  field,
  defaultValues,
  className,
  saveCallback
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    fieldMap,
    changeCurrentValues,
    cancel,
    remove,
    save,
    edit,
    checkField
  } = useFieldEditorContext();

  const { t } = useTranslation('Common');

  const methdos = useForm({
    defaultValues: useMemo(() => defaultValues, [defaultValues])
  });

  const watchedValues = useWatch({ control: methdos.control });

  const onSave = async values => {
    try {
      setIsLoading(true);

      await save({ field, values, setValue: methdos.setValue });

      if (typeof saveCallback === 'function') {
        saveCallback(values);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onRemove = async () => {
    try {
      setIsLoading(true);

      await remove({ field, setValue: methdos.setValue });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    cancel({ field });

    methdos.reset();
  };

  const fieldData = fieldMap[field];

  useEffect(() => {
    methdos.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    if (changeCurrentValues) {
      changeCurrentValues(watchedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  if (!fieldData) {
    return <div className={className}>{children}</div>;
  }

  const {
    formItems,
    permissions = {},
    allowDoubleClickEdit,
    isDisabledSaveButton,
    editButtonText
  } = fieldData;
  const { canRemove, canEdit } = permissions;

  const isNowEditing = checkField(field);

  if (isNowEditing) {
    return (
      <Spin spinning={isLoading}>
        <FormProvider {...methdos}>
          <form className={styles.form} onSubmit={methdos.handleSubmit(onSave)}>
            <div>{formItems}</div>

            <div className={styles.allActions}>
              {canRemove && (
                <Button
                  type="secondary"
                  mood="negative"
                  className={styles.button}
                  onClick={onRemove}
                >
                  {t('Delete')}
                </Button>
              )}

              <div className={styles.actionsBlock}>
                <Button
                  type="secondary"
                  mood="negative"
                  className={styles.button}
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>

                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={isDisabledSaveButton}
                  className={styles.button}
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Spin>
    );
  }

  if (allowDoubleClickEdit && canEdit && children) {
    return (
      <Tooltip
        mouseEnterDelay={0.5}
        title={t('DoubleClickToEdit')}
        placement="right"
      >
        <div
          className={classnames(styles.withDoubleClick, className)}
          onDoubleClick={() => edit({ field })}
          onMouseDown={event => {
            if (event.detail > 1) {
              event.preventDefault();
            }
          }}
        >
          {children}
        </div>
      </Tooltip>
    );
  }

  if (children) {
    return (
      <div className={classnames(styles.field, className)}>
        {children}

        {canEdit && (
          <Button
            type="link"
            className={styles.linkButton}
            onClick={() => edit({ field })}
          >
            {editButtonText}
          </Button>
        )}
      </div>
    );
  }

  return null;
};

export default FieldEditorItem;
