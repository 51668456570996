import React from 'react';

export const AssetManagerIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_88268_114507)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#B37FEB"
      />
      <g clipPath="url(#clip1_88268_114507)">
        <path
          d="M16.9999 4.99976H6.99988C6.72332 4.99976 6.49988 5.22319 6.49988 5.49976V18.4998C6.49988 18.7763 6.72332 18.9998 6.99988 18.9998H16.9999C17.2764 18.9998 17.4999 18.7763 17.4999 18.4998V5.49976C17.4999 5.22319 17.2764 4.99976 16.9999 4.99976ZM12.9374 6.12476H14.4374V9.40444L13.7108 8.87476L12.9374 9.42788V6.12476ZM16.3749 17.8748H7.62488V6.12476H11.9999V10.7638C11.9999 10.8154 12.0155 10.8669 12.0468 10.9091C12.0657 10.9359 12.0898 10.9587 12.1177 10.9762C12.1455 10.9936 12.1765 11.0054 12.2089 11.0107C12.2413 11.0161 12.2745 11.015 12.3064 11.0075C12.3384 11 12.3686 10.9862 12.3952 10.9669L13.7046 10.031L14.9764 10.9591C15.0186 10.9904 15.0702 11.0076 15.1233 11.0076C15.2608 11.0076 15.3733 10.8951 15.3733 10.7576V6.12476H16.3733V17.8748H16.3749Z"
          fill="white"
        />
        <path
          d="M12.9374 6.12476H14.4374V9.40444L13.7108 8.87476L12.9374 9.42788V6.12476Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_88268_114507">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_88268_114507">
        <rect width="14" height="14" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default AssetManagerIcon;
