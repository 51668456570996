import React, { useMemo } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  Error404Icon,
  NoAccessIcon,
  TaskDeletedIcon
} from 'components/common/icons';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import styles from './details.module.scss';

export const EmptyError = ({ status }) => {
  const { t } = useTranslation('Task');

  const data = useMemo(() => {
    if (status === 403) {
      return {
        icon: NoAccessIcon,
        key: 'NoTaskAccess'
      };
    }

    if (status === 410) {
      return {
        icon: TaskDeletedIcon,
        key: 'TaskNotFound'
      };
    }

    return {
      icon: Error404Icon,
      key: 'TaskDeleted'
    };
  }, [status]);

  return (
    <Empty
      image={<Icon component={data.icon} />}
      imageStyle={{ height: 'auto' }}
      description={
        <Typography.Title level={1} weight="bold" style={{ marginBottom: 20 }}>
          {t(data.key)}
        </Typography.Title>
      }
      className={styles.empty}
    />
  );
};

export default EmptyError;
