import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  DASHBOARD,
  ITEMS_PER_PAGE,
  TYPE_DISCUSSION,
  TYPE_EMPLOYEE
} from 'constants/index';

import { getUserEmployee } from 'store/workspace';
import {
  changeNotificationChatDisplay,
  setEntityChatRoomUuids,
  fetchDiscussionStatuses,
  getFilterNotifications
} from 'store/operator';
import { getRouterUrlView } from 'store/router';

import mapValue from 'utils/map-value';

import useChat, { transformChatsToJoinedGroups } from '../use-chat';

const sortByIsRead = (a, b) => {
  if (a.isRead === b.isRead) {
    return 0;
  }

  return a.isRead ? -1 : 1;
};

const useEmployeeNotificationsChat = ({ afterVisibleChange, view }) => {
  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);
  const urlView = useSelector(getRouterUrlView);

  const filters = useSelector(getFilterNotifications);

  const [fetchedSourceChatUuids, setFetchedSourceChatUuids] = useState([]);

  const messagesRef = useRef();

  const {
    hasMessages,
    isLoading,
    messages,
    hasMore,
    isReady,
    fetchMessages,
    joinChannels
  } = useChat({
    entity: employee,
    entityType: TYPE_EMPLOYEE,
    needJoin: false,
    needLeave: false,
    defaultNeedAutoScroll: false,
    customMessagesSort: sortByIsRead
  });

  const transformFilters = filtersData => ({
    sender: filtersData.sender.map(mapValue).join(','),
    createdDateFrom:
      Object.keys(filtersData.createdDateRange).length &&
      filtersData.createdDateRange.value[0] !== null
        ? filtersData.createdDateRange.value[0]
        : '',
    createdDateTo:
      Object.keys(filtersData.createdDateRange).length &&
      filtersData.createdDateRange.value[1] !== null
        ? filtersData.createdDateRange.value[1]
        : '',
    entityType: filtersData.entityType.map(mapValue).join(',')
  });

  const memoizedFilters = useMemo(() => transformFilters(filters), [filters]);

  const fetch = useCallback(
    async ({ offset, isRefetch, filtersData }) => {
      const chat = await fetchMessages({
        readable: true,
        offset,
        isRefetch,
        limit: ITEMS_PER_PAGE,
        withAggregation: true,
        filters: filtersData,
        cancelable: true
      });

      const discussionRoomUuids = chat.messages
        .filter(
          message =>
            (message.notificationData || {}).entityType === TYPE_DISCUSSION &&
            message.source
        )
        .map(message => message.source.roomUuid);

      if (discussionRoomUuids.length > 0) {
        await dispatch(
          fetchDiscussionStatuses({
            entityId: chat.entityId,
            roomUuids: discussionRoomUuids
          })
        );
      }

      if (!isRefetch) {
        const sourceChats = chat.messages.reduce((acc, curr) => {
          if (curr.source) {
            return [...acc, { uuid: curr.source.roomUuid }];
          }

          return acc;
        }, []);

        const chatUuids = [
          ...new Set(transformChatsToJoinedGroups(sourceChats, TYPE_EMPLOYEE))
        ];

        setFetchedSourceChatUuids(chatUuids);
      }
    },
    [fetchMessages]
  );

  const loadMore = useCallback(
    () => fetch({ offset: messages.length, filtersData: memoizedFilters }),
    [fetch, messages.length, memoizedFilters]
  );

  useEffect(() => {
    if (fetchedSourceChatUuids.length && isReady) {
      joinChannels(fetchedSourceChatUuids, false);

      dispatch(
        setEntityChatRoomUuids([
          {
            entityId: employee.id,
            entityType: TYPE_EMPLOYEE,
            chats: fetchedSourceChatUuids.map(uuid => ({ roomUuid: uuid }))
          }
        ])
      );

      setFetchedSourceChatUuids([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, fetchedSourceChatUuids]);

  useEffect(() => {
    if (
      view === DASHBOARD ||
      (afterVisibleChange && (!messages.length || urlView !== DASHBOARD))
    ) {
      fetch({
        offset: 0,
        isRefetch: !!messages.length,
        filtersData: memoizedFilters
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.id, afterVisibleChange, memoizedFilters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(changeNotificationChatDisplay({ view })), []);

  return {
    hasMessages,
    isLoading,
    messages: [...messages].reverse(),
    hasMore,
    messagesRef,
    loadMore
  };
};

export default useEmployeeNotificationsChat;
