import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Typography from 'components/common/typography';

import { getFeatureByName } from 'store/release';

import styles from './tag.module.scss';

export const ReleaseFeatureTag = ({
  children,
  featureName,
  tagClassName,
  wrapperClassName,
  wrapperStyle
}) => {
  const feature = useSelector(state => getFeatureByName(state)(featureName));

  const Tag = () =>
    !feature || feature.isAccepted ? null : (
      <Typography.Text
        size="small"
        weight="semibold"
        className={classnames(styles.tag, tagClassName)}
      >
        NEW
      </Typography.Text>
    );

  return children ? (
    <div
      className={classnames(styles.wrapper, wrapperClassName)}
      style={wrapperStyle}
    >
      {children}

      <Tag />
    </div>
  ) : (
    <Tag />
  );
};

ReleaseFeatureTag.propTypes = {
  featureName: PropTypes.string,
  tagClassName: PropTypes.string,
  wrapperClassName: PropTypes.string
};

ReleaseFeatureTag.defaultProps = {
  featureName: undefined,
  tagClassName: undefined,
  wrapperClassName: undefined
};

export default ReleaseFeatureTag;
