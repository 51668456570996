import React from 'react';

export const renderActionComponent = (actionComponent, allowDisplay) => {
  if (!allowDisplay || !React.isValidElement(actionComponent)) {
    return null;
  }
  return actionComponent;
};

export default renderActionComponent;
