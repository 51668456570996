import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { STATUS_CHECK, VIDEOS } from 'constants/index';

import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';
import Modal from 'components/common/modal';
import VideoBtn from 'components/common/video-btn';

import { changeController, fetchTask } from 'store/tasks';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import useRoutesService from 'services/routes';
import { showNoticeMessage } from 'services/notice';

const EditorForm = Form.create()(
  ({ form, task, hasController, onCallback }) => {
    const dispatch = useDispatch();
    const amplitude = useAmplitude();
    const routes = useRoutesService();

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation(['TaskRoleActions', 'Toast', 'Common']);

    const { getFieldDecorator } = form;

    const onSave = event => {
      event.preventDefault();

      form.validateFields(async (err, values) => {
        if (!err) {
          setIsLoading(true);

          try {
            await dispatch(
              changeController({
                id: task.id,
                employeeId: (values.controller || {}).value || null
              })
            );

            await dispatch(fetchTask({ id: task.id })).catch(({ response }) => {
              if (response.status === 403) {
                routes.toTasks();
              }
            });

            let noticeTKey = hasController
              ? 'ControllerChanged'
              : 'ControllerAdded';

            if (hasController && !(values.controller || {}).value) {
              noticeTKey = 'ControllerDeleted';
            }

            amplitude.updateTaskEvent({
              task,
              value: { controller: (values.controller || {}).value || null }
            });

            showNoticeMessage({
              customContent: t(noticeTKey, { ns: 'Toast' })
            });

            onCallback();
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    const isCheckType = task.status === STATUS_CHECK;
    const Select = (task.project || {}).id
      ? ProjectParticipantsSelect
      : EmployeeSelect;

    return (
      <Form
        colon={false}
        hideRequiredMark
        onSubmit={onSave}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px 24px'
        }}
        data-qa="qa-kgj3ldmdxfczot7"
      >
        <VideoBtn
          slug={VIDEOS.taskControllerRole}
          style={{ marginBottom: 20 }}
        />

        <Form.Item label={t('Controller')}>
          {getFieldDecorator('controller', {
            initialValue: hasController && {
              value: (task.controller || {}).id,
              label: task.controller
            }
          })(
            <Select
              projectId={(task.project || {}).id}
              valueText={t('ChooseController', { ns: 'Common' })}
              hideWorkspace
              isClearable={!isCheckType}
              closeMenuOnSelect
            />
          )}
        </Form.Item>

        <Button
          type="primary"
          data-qa="qa-okrl4246omkkcf2"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 'auto' }}
        >
          {t('SaveBtn')}
        </Button>
      </Form>
    );
  }
);

EditorForm.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    controller: PropTypes.object,
    projectId: PropTypes.number
  }),
  onCallback: PropTypes.func
};

EditorForm.defaultProps = {
  task: {},
  onCallback: () => {}
};

export const EditorControllerModal = ({ onClose, task, ...props }) => {
  const { t } = useTranslation('TaskRoleActions');

  const hasController = !!(task || {}).controller;

  return (
    <Modal
      width={540}
      title={t(
        hasController ? 'ChangeControllerHeading' : 'AddControllerHeading'
      )}
      data-qa="qa-xaigeb0dy0hcip1"
      onClose={onClose}
      {...props}
    >
      <EditorForm
        task={task}
        hasController={hasController}
        onCallback={onClose}
      />
    </Modal>
  );
};

export default EditorControllerModal;
