import React from 'react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from '../components.module.scss';

const Mention = ({ className, onClick, ...props }) => {
  const { t } = useTranslation('CommonChat');

  return (
    <Tooltip title={t('TagCollegueTip')} mouseEnterDelay={0.5}>
      <Button
        type="text"
        mood="none"
        className={classnames(styles.toolbarBtn, className)}
        data-qa="qa-je3dfom1ewdx3yj"
        onClick={onClick}
        {...props}
      >
        <Icon type="at" size={16} data-qa="qa-ucb02w3y1xj2b6y" />
      </Button>
    </Tooltip>
  );
};

Mention.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

Mention.defaultProps = {
  className: undefined
};

export default Mention;
