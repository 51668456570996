export default api => ({
  fetchMessageTemplates: ({ workspaceId, search }) =>
    api.get(`/v1/${workspaceId}/message-templates/`, {
      params: {
        search
      }
    }),

  createMessageTemplate: ({ workspaceId, payload }) =>
    api.post(`/v1/${workspaceId}/message-templates/`, payload),

  updateMessageTemplate: ({ workspaceId, templateId, payload }) =>
    api.put(`/v1/${workspaceId}/message-templates/${templateId}/`, payload),

  deleteMessageTemplate: ({ workspaceId, templateId }) =>
    api.delete(`/v1/${workspaceId}/message-templates/${templateId}/`),

  counterIncrementMessageTemplate: ({ workspaceId, templateId }) =>
    api.patch(
      `/v1/${workspaceId}/message-templates/${templateId}/increase-usage-counter/`
    )
});
