import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';

import CustomSelect from '../custom-select';

import styles from './search-by-select.module.scss';

export const SearchBySelect = forwardRef((props, ref) => {
  const { t } = useTranslation('Common');

  return (
    <div className={styles.root}>
      <Typography.Text>{t('SearchBy')}</Typography.Text>

      <CustomSelect
        ref={ref}
        className={styles.select}
        popoverOverlayClassName={styles.overlay}
        {...props}
      />
    </div>
  );
});

export default SearchBySelect;
