import React from 'react';

import DynamicPortal from 'components/common/dynamic-portal';
import Viewer from './shared/viewer';
import useMediaViewer from './use-media-viewer';

import './styles.scss';

export const MediaViewer = () => {
  const { visible, items, activeIndex, onClose } = useMediaViewer();

  if (!visible) {
    return null;
  }

  return (
    <DynamicPortal id="root">
      <Viewer
        onClose={onClose}
        showPreview
        activeIndex={activeIndex}
        items={items}
      />
    </DynamicPortal>
  );
};

export default MediaViewer;
