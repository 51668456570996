import { handleActions } from 'utils/redux-actions';

import { createDevice, deleteDevice } from './actions';

const initialState = {};

export default handleActions(
  {
    [createDevice.SUCCEEDED]: (state, { payload }) => {
      localStorage.setItem('registrationId', payload);

      return state;
    },

    [createDevice.FAILED]: (state, { payload }) => {
      localStorage.setItem('registrationId', payload);

      return state;
    },

    [deleteDevice.SUCCEEDED]: () => localStorage.removeItem('registrationId')
  },
  initialState
);
