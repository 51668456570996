export const WIDTH_MOBILE_DRAWER = 'calc(100% - 50px)';
export const WIDTH_MOBILE_DRAWER_FILTER = 380;
export const WIDTH_DRAWER = 610;
export const WIDTH_DRAWER_CREATOR = 610;
export const WIDTH_SMALL_DRIVER = 479;

export const SIDER_WIDTH = 205;
export const MOBILE_SIDER_WIDTH = 72;
export const USER_DROPDOWN_WIDTH = 280;
export const HEADER_HEIGHT = 52;
export const MOBILE_HEADER_HEIGHT = 40;

export const WITH_BOTTOM_BANNER_ROOT_CLASSNAME = 'with-bottom-banner-spacer';
export const BOTTOM_BANNER_HEIGHT = 39;
