import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ISSUE_STATUSES, STATUS_PENDING } from 'constants/tasks';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';
import { renderDescriptions } from 'components/common/comments/converters';

import styles from './history-issue.module.scss';

const { Text } = Typography;

const HistoryIssue = ({ history, createdAtTask }) => {
  const { t } = useTranslation('Task');

  const extendedHistory = [
    ...history,
    {
      currentStatus: STATUS_PENDING,
      previousStatus: STATUS_PENDING,
      createdAt: createdAtTask
    }
  ];

  const groupedHistory = extendedHistory.reduce(
    (result, { currentStatus, previousStatus, createdAt, reason }) => {
      const dateKey = moment(createdAt).format(DATE_FORMAT);
      const time = moment(createdAt).format(TIME_FORMAT);

      if (!result[dateKey]) {
        result[dateKey] = [];
      }
      const modifiedPreviousStatus = !previousStatus
        ? STATUS_PENDING
        : previousStatus;

      const getStatusText = () => {
        if (history.length && currentStatus !== previousStatus) {
          return t(
            reason
              ? 'IssueStatusChangedFromToWithComment'
              : 'IssueStatusChangedFromTo',
            {
              reason,
              from: t(ISSUE_STATUSES[modifiedPreviousStatus]),
              to: t(ISSUE_STATUSES[currentStatus])
            }
          );
        }

        return t('IssueStatusChangedTo', {
          to: t(ISSUE_STATUSES[currentStatus])
        });
      };

      result[dateKey].push({
        text: getStatusText(),
        createdAt,
        time
      });

      return result;
    },
    {}
  );

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {Object.entries(groupedHistory).map(([date, statuses]) => (
          <div key={date}>
            <Text color="black-55" className={styles.date}>
              {date}
            </Text>

            {statuses.map(({ text, createdAt, time }) => (
              <div key={createdAt} className={styles.statusWrap}>
                <Text className={styles.statusText}>
                  {renderDescriptions(text)}
                </Text>

                <Text color="black-55">{time}</Text>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

HistoryIssue.propTypes = {
  history: PropTypes.array,
  createdAtTask: PropTypes.string
};

HistoryIssue.defaultProps = {
  history: [],
  createdAtTask: null
};

export default HistoryIssue;
