import React from 'react';

export const MobileAppQrIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="264"
    height="264"
    viewBox="0 0 264 264"
  >
    <rect x="0" y="0" width="264" height="264" fill="#ffffff" />
    <defs>
      <rect id="p" width="8" height="8" />
    </defs>
    <g fill="#000000">
      <use xlinkHref="#p" x="32" y="32" />
      <use xlinkHref="#p" x="32" y="40" />
      <use xlinkHref="#p" x="32" y="48" />
      <use xlinkHref="#p" x="32" y="56" />
      <use xlinkHref="#p" x="32" y="64" />
      <use xlinkHref="#p" x="32" y="72" />
      <use xlinkHref="#p" x="32" y="80" />
      <use xlinkHref="#p" x="32" y="96" />
      <use xlinkHref="#p" x="32" y="112" />
      <use xlinkHref="#p" x="32" y="120" />
      <use xlinkHref="#p" x="32" y="128" />
      <use xlinkHref="#p" x="32" y="136" />
      <use xlinkHref="#p" x="32" y="152" />
      <use xlinkHref="#p" x="32" y="176" />
      <use xlinkHref="#p" x="32" y="184" />
      <use xlinkHref="#p" x="32" y="192" />
      <use xlinkHref="#p" x="32" y="200" />
      <use xlinkHref="#p" x="32" y="208" />
      <use xlinkHref="#p" x="32" y="216" />
      <use xlinkHref="#p" x="32" y="224" />
      <use xlinkHref="#p" x="40" y="32" />
      <use xlinkHref="#p" x="40" y="80" />
      <use xlinkHref="#p" x="40" y="96" />
      <use xlinkHref="#p" x="40" y="120" />
      <use xlinkHref="#p" x="40" y="128" />
      <use xlinkHref="#p" x="40" y="136" />
      <use xlinkHref="#p" x="40" y="144" />
      <use xlinkHref="#p" x="40" y="160" />
      <use xlinkHref="#p" x="40" y="176" />
      <use xlinkHref="#p" x="40" y="224" />
      <use xlinkHref="#p" x="48" y="32" />
      <use xlinkHref="#p" x="48" y="48" />
      <use xlinkHref="#p" x="48" y="56" />
      <use xlinkHref="#p" x="48" y="64" />
      <use xlinkHref="#p" x="48" y="80" />
      <use xlinkHref="#p" x="48" y="96" />
      <use xlinkHref="#p" x="48" y="112" />
      <use xlinkHref="#p" x="48" y="136" />
      <use xlinkHref="#p" x="48" y="152" />
      <use xlinkHref="#p" x="48" y="176" />
      <use xlinkHref="#p" x="48" y="192" />
      <use xlinkHref="#p" x="48" y="200" />
      <use xlinkHref="#p" x="48" y="208" />
      <use xlinkHref="#p" x="48" y="224" />
      <use xlinkHref="#p" x="56" y="32" />
      <use xlinkHref="#p" x="56" y="48" />
      <use xlinkHref="#p" x="56" y="56" />
      <use xlinkHref="#p" x="56" y="64" />
      <use xlinkHref="#p" x="56" y="80" />
      <use xlinkHref="#p" x="56" y="112" />
      <use xlinkHref="#p" x="56" y="120" />
      <use xlinkHref="#p" x="56" y="136" />
      <use xlinkHref="#p" x="56" y="152" />
      <use xlinkHref="#p" x="56" y="176" />
      <use xlinkHref="#p" x="56" y="192" />
      <use xlinkHref="#p" x="56" y="200" />
      <use xlinkHref="#p" x="56" y="208" />
      <use xlinkHref="#p" x="56" y="224" />
      <use xlinkHref="#p" x="64" y="32" />
      <use xlinkHref="#p" x="64" y="48" />
      <use xlinkHref="#p" x="64" y="56" />
      <use xlinkHref="#p" x="64" y="64" />
      <use xlinkHref="#p" x="64" y="80" />
      <use xlinkHref="#p" x="64" y="96" />
      <use xlinkHref="#p" x="64" y="112" />
      <use xlinkHref="#p" x="64" y="128" />
      <use xlinkHref="#p" x="64" y="144" />
      <use xlinkHref="#p" x="64" y="152" />
      <use xlinkHref="#p" x="64" y="176" />
      <use xlinkHref="#p" x="64" y="192" />
      <use xlinkHref="#p" x="64" y="200" />
      <use xlinkHref="#p" x="64" y="208" />
      <use xlinkHref="#p" x="64" y="224" />
      <use xlinkHref="#p" x="72" y="32" />
      <use xlinkHref="#p" x="72" y="80" />
      <use xlinkHref="#p" x="72" y="96" />
      <use xlinkHref="#p" x="72" y="104" />
      <use xlinkHref="#p" x="72" y="120" />
      <use xlinkHref="#p" x="72" y="128" />
      <use xlinkHref="#p" x="72" y="144" />
      <use xlinkHref="#p" x="72" y="152" />
      <use xlinkHref="#p" x="72" y="176" />
      <use xlinkHref="#p" x="72" y="224" />
      <use xlinkHref="#p" x="80" y="32" />
      <use xlinkHref="#p" x="80" y="40" />
      <use xlinkHref="#p" x="80" y="48" />
      <use xlinkHref="#p" x="80" y="56" />
      <use xlinkHref="#p" x="80" y="64" />
      <use xlinkHref="#p" x="80" y="72" />
      <use xlinkHref="#p" x="80" y="80" />
      <use xlinkHref="#p" x="80" y="96" />
      <use xlinkHref="#p" x="80" y="112" />
      <use xlinkHref="#p" x="80" y="128" />
      <use xlinkHref="#p" x="80" y="144" />
      <use xlinkHref="#p" x="80" y="160" />
      <use xlinkHref="#p" x="80" y="176" />
      <use xlinkHref="#p" x="80" y="184" />
      <use xlinkHref="#p" x="80" y="192" />
      <use xlinkHref="#p" x="80" y="200" />
      <use xlinkHref="#p" x="80" y="208" />
      <use xlinkHref="#p" x="80" y="216" />
      <use xlinkHref="#p" x="80" y="224" />
      <use xlinkHref="#p" x="88" y="96" />
      <use xlinkHref="#p" x="88" y="104" />
      <use xlinkHref="#p" x="88" y="112" />
      <use xlinkHref="#p" x="88" y="120" />
      <use xlinkHref="#p" x="88" y="136" />
      <use xlinkHref="#p" x="88" y="144" />
      <use xlinkHref="#p" x="88" y="160" />
      <use xlinkHref="#p" x="96" y="48" />
      <use xlinkHref="#p" x="96" y="80" />
      <use xlinkHref="#p" x="96" y="88" />
      <use xlinkHref="#p" x="96" y="96" />
      <use xlinkHref="#p" x="96" y="128" />
      <use xlinkHref="#p" x="96" y="144" />
      <use xlinkHref="#p" x="96" y="160" />
      <use xlinkHref="#p" x="96" y="168" />
      <use xlinkHref="#p" x="96" y="176" />
      <use xlinkHref="#p" x="96" y="184" />
      <use xlinkHref="#p" x="96" y="192" />
      <use xlinkHref="#p" x="96" y="208" />
      <use xlinkHref="#p" x="96" y="216" />
      <use xlinkHref="#p" x="96" y="224" />
      <use xlinkHref="#p" x="104" y="40" />
      <use xlinkHref="#p" x="104" y="56" />
      <use xlinkHref="#p" x="104" y="64" />
      <use xlinkHref="#p" x="104" y="104" />
      <use xlinkHref="#p" x="104" y="120" />
      <use xlinkHref="#p" x="104" y="136" />
      <use xlinkHref="#p" x="104" y="160" />
      <use xlinkHref="#p" x="104" y="176" />
      <use xlinkHref="#p" x="104" y="192" />
      <use xlinkHref="#p" x="104" y="200" />
      <use xlinkHref="#p" x="112" y="32" />
      <use xlinkHref="#p" x="112" y="72" />
      <use xlinkHref="#p" x="112" y="80" />
      <use xlinkHref="#p" x="112" y="112" />
      <use xlinkHref="#p" x="112" y="120" />
      <use xlinkHref="#p" x="112" y="128" />
      <use xlinkHref="#p" x="112" y="136" />
      <use xlinkHref="#p" x="112" y="176" />
      <use xlinkHref="#p" x="112" y="184" />
      <use xlinkHref="#p" x="112" y="192" />
      <use xlinkHref="#p" x="112" y="216" />
      <use xlinkHref="#p" x="120" y="48" />
      <use xlinkHref="#p" x="120" y="72" />
      <use xlinkHref="#p" x="120" y="88" />
      <use xlinkHref="#p" x="120" y="104" />
      <use xlinkHref="#p" x="120" y="112" />
      <use xlinkHref="#p" x="120" y="128" />
      <use xlinkHref="#p" x="120" y="136" />
      <use xlinkHref="#p" x="120" y="152" />
      <use xlinkHref="#p" x="120" y="168" />
      <use xlinkHref="#p" x="120" y="176" />
      <use xlinkHref="#p" x="120" y="192" />
      <use xlinkHref="#p" x="120" y="200" />
      <use xlinkHref="#p" x="120" y="216" />
      <use xlinkHref="#p" x="128" y="56" />
      <use xlinkHref="#p" x="128" y="64" />
      <use xlinkHref="#p" x="128" y="72" />
      <use xlinkHref="#p" x="128" y="80" />
      <use xlinkHref="#p" x="128" y="96" />
      <use xlinkHref="#p" x="128" y="104" />
      <use xlinkHref="#p" x="128" y="112" />
      <use xlinkHref="#p" x="128" y="120" />
      <use xlinkHref="#p" x="128" y="160" />
      <use xlinkHref="#p" x="128" y="168" />
      <use xlinkHref="#p" x="128" y="176" />
      <use xlinkHref="#p" x="128" y="184" />
      <use xlinkHref="#p" x="128" y="224" />
      <use xlinkHref="#p" x="136" y="40" />
      <use xlinkHref="#p" x="136" y="72" />
      <use xlinkHref="#p" x="136" y="88" />
      <use xlinkHref="#p" x="136" y="96" />
      <use xlinkHref="#p" x="136" y="112" />
      <use xlinkHref="#p" x="136" y="120" />
      <use xlinkHref="#p" x="136" y="144" />
      <use xlinkHref="#p" x="136" y="168" />
      <use xlinkHref="#p" x="136" y="184" />
      <use xlinkHref="#p" x="136" y="208" />
      <use xlinkHref="#p" x="144" y="40" />
      <use xlinkHref="#p" x="144" y="72" />
      <use xlinkHref="#p" x="144" y="80" />
      <use xlinkHref="#p" x="144" y="88" />
      <use xlinkHref="#p" x="144" y="128" />
      <use xlinkHref="#p" x="144" y="176" />
      <use xlinkHref="#p" x="144" y="184" />
      <use xlinkHref="#p" x="144" y="192" />
      <use xlinkHref="#p" x="144" y="200" />
      <use xlinkHref="#p" x="152" y="32" />
      <use xlinkHref="#p" x="152" y="40" />
      <use xlinkHref="#p" x="152" y="48" />
      <use xlinkHref="#p" x="152" y="64" />
      <use xlinkHref="#p" x="152" y="72" />
      <use xlinkHref="#p" x="152" y="88" />
      <use xlinkHref="#p" x="152" y="96" />
      <use xlinkHref="#p" x="152" y="128" />
      <use xlinkHref="#p" x="152" y="152" />
      <use xlinkHref="#p" x="152" y="168" />
      <use xlinkHref="#p" x="152" y="176" />
      <use xlinkHref="#p" x="152" y="200" />
      <use xlinkHref="#p" x="152" y="224" />
      <use xlinkHref="#p" x="160" y="32" />
      <use xlinkHref="#p" x="160" y="40" />
      <use xlinkHref="#p" x="160" y="72" />
      <use xlinkHref="#p" x="160" y="80" />
      <use xlinkHref="#p" x="160" y="104" />
      <use xlinkHref="#p" x="160" y="120" />
      <use xlinkHref="#p" x="160" y="128" />
      <use xlinkHref="#p" x="160" y="152" />
      <use xlinkHref="#p" x="160" y="160" />
      <use xlinkHref="#p" x="160" y="168" />
      <use xlinkHref="#p" x="160" y="176" />
      <use xlinkHref="#p" x="160" y="184" />
      <use xlinkHref="#p" x="160" y="192" />
      <use xlinkHref="#p" x="160" y="200" />
      <use xlinkHref="#p" x="160" y="208" />
      <use xlinkHref="#p" x="160" y="216" />
      <use xlinkHref="#p" x="160" y="224" />
      <use xlinkHref="#p" x="168" y="96" />
      <use xlinkHref="#p" x="168" y="120" />
      <use xlinkHref="#p" x="168" y="128" />
      <use xlinkHref="#p" x="168" y="136" />
      <use xlinkHref="#p" x="168" y="144" />
      <use xlinkHref="#p" x="168" y="152" />
      <use xlinkHref="#p" x="168" y="160" />
      <use xlinkHref="#p" x="168" y="192" />
      <use xlinkHref="#p" x="168" y="200" />
      <use xlinkHref="#p" x="176" y="32" />
      <use xlinkHref="#p" x="176" y="40" />
      <use xlinkHref="#p" x="176" y="48" />
      <use xlinkHref="#p" x="176" y="56" />
      <use xlinkHref="#p" x="176" y="64" />
      <use xlinkHref="#p" x="176" y="72" />
      <use xlinkHref="#p" x="176" y="80" />
      <use xlinkHref="#p" x="176" y="96" />
      <use xlinkHref="#p" x="176" y="104" />
      <use xlinkHref="#p" x="176" y="112" />
      <use xlinkHref="#p" x="176" y="128" />
      <use xlinkHref="#p" x="176" y="136" />
      <use xlinkHref="#p" x="176" y="160" />
      <use xlinkHref="#p" x="176" y="176" />
      <use xlinkHref="#p" x="176" y="192" />
      <use xlinkHref="#p" x="176" y="216" />
      <use xlinkHref="#p" x="184" y="32" />
      <use xlinkHref="#p" x="184" y="80" />
      <use xlinkHref="#p" x="184" y="112" />
      <use xlinkHref="#p" x="184" y="128" />
      <use xlinkHref="#p" x="184" y="144" />
      <use xlinkHref="#p" x="184" y="160" />
      <use xlinkHref="#p" x="184" y="192" />
      <use xlinkHref="#p" x="184" y="200" />
      <use xlinkHref="#p" x="184" y="224" />
      <use xlinkHref="#p" x="192" y="32" />
      <use xlinkHref="#p" x="192" y="48" />
      <use xlinkHref="#p" x="192" y="56" />
      <use xlinkHref="#p" x="192" y="64" />
      <use xlinkHref="#p" x="192" y="80" />
      <use xlinkHref="#p" x="192" y="112" />
      <use xlinkHref="#p" x="192" y="152" />
      <use xlinkHref="#p" x="192" y="160" />
      <use xlinkHref="#p" x="192" y="168" />
      <use xlinkHref="#p" x="192" y="176" />
      <use xlinkHref="#p" x="192" y="184" />
      <use xlinkHref="#p" x="192" y="192" />
      <use xlinkHref="#p" x="192" y="200" />
      <use xlinkHref="#p" x="192" y="208" />
      <use xlinkHref="#p" x="192" y="216" />
      <use xlinkHref="#p" x="200" y="32" />
      <use xlinkHref="#p" x="200" y="48" />
      <use xlinkHref="#p" x="200" y="56" />
      <use xlinkHref="#p" x="200" y="64" />
      <use xlinkHref="#p" x="200" y="80" />
      <use xlinkHref="#p" x="200" y="128" />
      <use xlinkHref="#p" x="200" y="136" />
      <use xlinkHref="#p" x="200" y="160" />
      <use xlinkHref="#p" x="200" y="168" />
      <use xlinkHref="#p" x="200" y="176" />
      <use xlinkHref="#p" x="200" y="184" />
      <use xlinkHref="#p" x="200" y="192" />
      <use xlinkHref="#p" x="200" y="200" />
      <use xlinkHref="#p" x="200" y="216" />
      <use xlinkHref="#p" x="208" y="32" />
      <use xlinkHref="#p" x="208" y="48" />
      <use xlinkHref="#p" x="208" y="56" />
      <use xlinkHref="#p" x="208" y="64" />
      <use xlinkHref="#p" x="208" y="80" />
      <use xlinkHref="#p" x="208" y="96" />
      <use xlinkHref="#p" x="208" y="112" />
      <use xlinkHref="#p" x="208" y="144" />
      <use xlinkHref="#p" x="208" y="200" />
      <use xlinkHref="#p" x="216" y="32" />
      <use xlinkHref="#p" x="216" y="80" />
      <use xlinkHref="#p" x="216" y="112" />
      <use xlinkHref="#p" x="216" y="120" />
      <use xlinkHref="#p" x="216" y="128" />
      <use xlinkHref="#p" x="216" y="144" />
      <use xlinkHref="#p" x="216" y="152" />
      <use xlinkHref="#p" x="216" y="168" />
      <use xlinkHref="#p" x="216" y="176" />
      <use xlinkHref="#p" x="216" y="184" />
      <use xlinkHref="#p" x="216" y="192" />
      <use xlinkHref="#p" x="216" y="216" />
      <use xlinkHref="#p" x="216" y="224" />
      <use xlinkHref="#p" x="224" y="32" />
      <use xlinkHref="#p" x="224" y="40" />
      <use xlinkHref="#p" x="224" y="48" />
      <use xlinkHref="#p" x="224" y="56" />
      <use xlinkHref="#p" x="224" y="64" />
      <use xlinkHref="#p" x="224" y="72" />
      <use xlinkHref="#p" x="224" y="80" />
      <use xlinkHref="#p" x="224" y="104" />
      <use xlinkHref="#p" x="224" y="112" />
      <use xlinkHref="#p" x="224" y="128" />
      <use xlinkHref="#p" x="224" y="136" />
      <use xlinkHref="#p" x="224" y="144" />
      <use xlinkHref="#p" x="224" y="168" />
      <use xlinkHref="#p" x="224" y="176" />
      <use xlinkHref="#p" x="224" y="192" />
      <use xlinkHref="#p" x="224" y="208" />
      <use xlinkHref="#p" x="224" y="224" />
    </g>
    <g />
  </svg>
);

export default MobileAppQrIcon;
