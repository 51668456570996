import React from 'react';

export const DocsNotFoundIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.3285 10.625C47.1247 10.625 10.9234 28.6369 3.29274 43.0997C-3.89428 56.6751 17.0457 66.4353 17.0457 76.018C17.0457 95.8045 44.729 85.3345 69.8392 80.5431C94.8607 75.7518 108.614 54.4569 100.983 37.9534C93.2635 21.5386 77.2037 10.625 57.3285 10.625Z"
      fill="#EBF8F8"
    />
    <ellipse
      cx="88.5121"
      cy="17.7251"
      rx="11.4879"
      ry="9.72225"
      fill="#F9716C"
    />
    <path
      d="M84.2832 29.2147L85.2889 25.5689C85.3295 25.422 85.4735 25.3289 85.6241 25.3522L90.6947 26.1374C90.9872 26.1827 91.0443 26.5793 90.7766 26.7053L84.7002 29.5659C84.4682 29.6751 84.215 29.4619 84.2832 29.2147Z"
      fill="#F9716C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.5999 72.5L74.8999 53.1C74.0999 52.2 73.0999 51.6 71.8999 51.6H38.1999C36.9999 51.6 35.8999 52.2 35.1999 53.1L19.4999 72.5V82.6H90.6999L90.5999 72.5Z"
      fill="#16969C"
    />
    <path
      d="M36.3 27.8H73.3C76.3 27.8 78.7 30.2 78.7 33.2V75.7C78.7 78.7 76.3 81.1 73.3 81.1H36.3C33.3 81.1 30.9 78.7 30.9 75.7V33.2C30.8 30.2 33.3 27.8 36.3 27.8Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5999 33.3H71.5999C71.8999 33.3 72.2999 33.4 72.4999 33.7C72.6999 33.9 72.8999 34.3 72.8999 34.6V51C72.8999 51.3 72.7999 51.7 72.4999 51.9C72.2999 52.1 71.8999 52.3 71.5999 52.3H38.5999C38.2999 52.3 37.8999 52.2 37.6999 51.9C37.4999 51.7 37.2999 51.3 37.2999 51V34.6C37.2999 34.3 37.3999 33.9 37.6999 33.7C37.8999 33.4 38.1999 33.3 38.5999 33.3Z"
      fill="#FCC977"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.4999 95.7C89.9999 97.7 88.1999 99.2 86.0999 99.2H23.9999C21.8999 99.2 20.0999 97.7 19.5999 95.7C19.4999 95.3 19.4999 94.9 19.4999 94.5V72.5H36.7999C38.6999 72.5 40.1999 74.1 40.1999 76.1C40.1999 78 41.7999 79.6 43.6999 79.6H66.4999C68.3999 79.6 69.9999 78 69.9999 76.1C69.9999 74.1 71.4999 72.5 73.3999 72.5H90.6999V94.5C90.5999 94.9 90.5999 95.3 90.4999 95.7Z"
      fill="#1DC1C9"
    />
    <path
      d="M52.1999 66.5H38.2999C37.6999 66.5 37.2999 66.1 37.2999 65.5C37.2999 64.9 37.6999 64.5 38.2999 64.5H52.1999C52.7999 64.5 53.1999 64.9 53.1999 65.5C53.1999 66.1 52.6999 66.5 52.1999 66.5Z"
      fill="#2B2A36"
    />
    <path
      d="M65.8 61.6H38.2C37.6 61.6 37.2 61.2 37.2 60.6C37.2 60 37.6 59.6 38.2 59.6H65.6999C66.2999 59.6 66.6999 60 66.6999 60.6C66.6999 61.2 66.3 61.6 65.8 61.6Z"
      fill="#2B2A36"
    />
    <path
      d="M62.9999 66.5H57.7999C57.1999 66.5 56.7999 66.1 56.7999 65.5C56.7999 64.9 57.1999 64.5 57.7999 64.5H62.9999C63.5999 64.5 63.9999 64.9 63.9999 65.5C63.9999 66.1 63.4999 66.5 62.9999 66.5Z"
      fill="#2B2A36"
    />
    <path
      d="M64.3 40.6C65.4 41 66.7 40.4 67.1 39.3C67.5 38.2 66.9 36.9 65.8 36.5C64.7 36.1 63.4 36.7 63 37.8C62.6 38.9 63.1 40.2 64.3 40.6Z"
      fill="white"
    />
    <path
      d="M49.0999 40.1L41.8999 49.3H59.4999L52.2999 40.1C51.3999 39.1 49.8999 39.1 49.0999 40.1Z"
      fill="white"
    />
    <path
      d="M59.7 44.6L56 49.3H66.6L62.9 44.6C62.1 43.6 60.5 43.6 59.7 44.6Z"
      fill="white"
    />
    <path
      d="M92.062 14.1751L84.962 21.2751"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M92.062 21.2751L84.962 14.1751"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default DocsNotFoundIcon;
