import { useDispatch, useSelector } from 'react-redux';

import {
  setCollapsedContactPanel,
  getCollapsedContactPanel
} from 'store/contacts';

import useLocalStorage from 'hooks/common/use-local-storage';

export const COLLAPSED_CONTACT_PANEL = 'collapsed-contact-panel';

export const useCollapsedContactPanel = () => {
  const dispatch = useDispatch();

  const collapsed = useSelector(getCollapsedContactPanel);
  const [, setStorageCollapsed] = useLocalStorage(COLLAPSED_CONTACT_PANEL);

  const setCollapsed = value => {
    setStorageCollapsed(value);
    dispatch(setCollapsedContactPanel(value));
  };

  return [collapsed, setCollapsed];
};

export default useCollapsedContactPanel;
