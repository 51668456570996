import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { WHATSAPP_CHANNEL_STEP } from 'constants/index';

import WhatsAppChannelForm from 'components/contacts-view/views/integrations/forms/whatsapp-channel';
import { transformScheduleConfigValues } from 'components/contacts-view/views/integrations/components/operator-hours/utils';
import { transformSlaConfigValues } from 'components/contacts-view/views/integrations/components/sla-settings/utils';

const WhatsAppStep = ({
  currentStep,
  isLoading,
  onSubmit,
  creator,
  setVisibleWhatsAppAuthModal
}) => {
  const { t } = useTranslation('ChannelSettings');

  const defaultValues = {
    source: '',
    responsible: creator,
    operators: [creator],
    scheduleConfig: transformScheduleConfigValues(),
    slaConfig: transformSlaConfigValues(),
    nonWorkingTimeMessage: {
      description: t('OperatorsWorkingHoursDesc')
    },
    config: {
      chatpushInstance: undefined
    }
  };

  const steps = {
    [WHATSAPP_CHANNEL_STEP]: (
      <WhatsAppChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
        setVisibleWhatsAppAuthModal={setVisibleWhatsAppAuthModal}
      />
    )
  };

  return <>{steps[currentStep]}</>;
};

WhatsAppStep.propTypes = {
  currentStep: PropTypes.oneOf([WHATSAPP_CHANNEL_STEP]),
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setVisibleWhatsAppAuthModal: PropTypes.func
};

WhatsAppStep.defaultProps = {
  currentStep: WHATSAPP_CHANNEL_STEP,
  setVisibleWhatsAppAuthModal: () => {}
};

export default WhatsAppStep;
