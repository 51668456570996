import { useDispatch, useSelector } from 'react-redux';

import {
  getOrderStatusSmallListOpened,
  setOpenedOrderStatusSmallList
} from 'store/order-statuses';

import useLocalStorage from 'hooks/common/use-local-storage';

export const ORDER_SMALL_LIST_OPENED = 'order-small-list-opened';

const useSmallListOpened = (callback = () => {}) => {
  const dispatch = useDispatch();

  const opened = useSelector(getOrderStatusSmallListOpened);
  const [, setStorageOpened] = useLocalStorage(ORDER_SMALL_LIST_OPENED);

  const setOpened = value => {
    setStorageOpened(value);
    dispatch(setOpenedOrderStatusSmallList(value));
    callback(value);
  };

  return [opened, setOpened];
};

export default useSmallListOpened;
