import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import useReadAll from './use-read-all';

import styles from './read-all.module.scss';

const ReadAllNotifications = () => {
  const { allowReadAllNotifications, onReadAllNotifications } = useReadAll();

  const { t } = useTranslation('Dashboard');

  return (
    <Button
      type="link"
      size="large"
      disabled={!allowReadAllNotifications}
      className={styles.readAllButton}
      onClick={onReadAllNotifications}
    >
      <Icon type="double-checkbox" />

      {t('MarkAllAsReadBtn')}
    </Button>
  );
};

export default ReadAllNotifications;
