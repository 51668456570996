import { createSelector } from 'reselect';

export const getDepartments = state => state.team.departments;

export const getDepartmentsEntries = createSelector(
  getDepartments,
  departments => departments.entries
);

export const getEmployeeCount = createSelector(
  getDepartments,
  departments => departments.employeeCount
);

export const getAdmin = createSelector(
  getDepartments,
  departments => departments.admin
);

export const getDepartmentEmployeeFactory = createSelector(
  getDepartmentsEntries,
  departments => (departmentId, employeeId) => {
    const department = departments.find(d => d.id === departmentId);

    if (department) {
      return department.managerId === employeeId
        ? department.manager
        : (department.employees || []).find(e => e.id === employeeId);
    }

    return {};
  }
);

export const getFilter = createSelector(
  getDepartments,
  departments => departments.filter
);

export const getNeedRefetchDepartments = createSelector(
  getDepartments,
  departments => departments.needRefetchDepartments
);
