import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { WORKSPACES } from '../../../constants';
import { useChangedStorageFilter } from '../../../hooks/common/use-filter-storage';
import { getAllWorkspaces, getWorkspaceId } from '../../../store/workspace';

const clearWorkspacesStorage = () =>
  Object.keys(window.localStorage)
    .filter(key => key === WORKSPACES)
    .map(key => window.localStorage.removeItem(key));

// записывает компании в localStorage
// используется для получения значений из store
// TODO: стоит ли хранить все значения ? (экономия памяти)
export const useWorkspacesStorage = () => {
  const workspaceId = useSelector(getWorkspaceId);
  const workspaces = useSelector(getAllWorkspaces) || {};

  useChangedStorageFilter(WORKSPACES, workspaces, key => key);

  useEffect(() => {
    if (!Object.keys(workspaces).length) {
      clearWorkspacesStorage();
    }
  }, [workspaceId, workspaces]);
};

export default useWorkspacesStorage;
