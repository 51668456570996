import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { ADDON_TYPE_WHATSAPP } from 'constants/billing';

import Panel from 'components/settings-view/panel';
import { SkeletonButton } from 'components/common/skeletons';
import Typography from 'components/common/typography';

import { fetchPaymentCheckout } from 'store/billing';

import { getFormattedAmount } from '../utils';

import styles from './payment-details.module.scss';

const Title = ({ children, ...props }) => (
  <Typography.Title style={{ margin: 0 }} {...props}>
    {children}
  </Typography.Title>
);

const renderCount = ({ addonType, count, t }) => {
  let countText = t('UnitAmount');

  if (addonType === ADDON_TYPE_WHATSAPP) {
    countText = t('NumbersAmount');
  }

  return <Title level={3}>{`${countText} ${count}`}</Title>;
};

const renderPrice = ({ addonType, price, currency, t }) => {
  const amount = getFormattedAmount({
    amount: price,
    currency
  });

  let priceText = t('SumForUnit');

  if (addonType === ADDON_TYPE_WHATSAPP) {
    priceText = t('SumForNumber');
  }

  return <Title level={3}>{`${priceText} ${amount}`}</Title>;
};

const PaymentDetails = ({
  period,
  currency,
  tariffId,
  addons,
  tariffDescription,
  totalDescription,
  callback
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});

  const { t } = useTranslation('ChangePlan');

  const fetch = async () => {
    try {
      setIsLoading(true);

      const data = await dispatch(
        fetchPaymentCheckout({ period, currency, tariffId, addons })
      );
      setDetails(data);

      if (callback) {
        callback(data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <SkeletonButton className={styles.skeleton} />;
  }

  if (isEmpty(details)) {
    return null;
  }

  return (
    <Panel className={styles.root}>
      <div className={styles.row}>
        <div>
          <Title level={2}>
            {t('PlanTitleDetails', { tariffTitle: details.tariff.title })}
          </Title>

          {tariffDescription}
        </div>

        <Title level={3}>
          {getFormattedAmount({
            amount: details.tariffPrice,
            currency: details.currency
          })}
        </Title>
      </div>

      {details.addons.map(a => (
        <div className={styles.row}>
          <div>
            <Title level={2}>{a.addon.title}</Title>

            {renderPrice({
              addonType: a.addon.type,
              price: a.price,
              currency: details.currency,
              t
            })}

            {renderCount({ addonType: a.addon.type, count: a.count, t })}
          </div>

          <Title level={3}>
            {getFormattedAmount({
              amount: a.amount,
              currency: details.currency
            })}
          </Title>
        </div>
      ))}

      <div className={styles.row}>
        <div>
          <Title level={2}>{t('Total')}</Title>

          {totalDescription}
        </div>

        <Title level={2}>
          {getFormattedAmount({
            amount: details.amount,
            currency: details.currency
          })}
        </Title>
      </div>
    </Panel>
  );
};

export default PaymentDetails;
