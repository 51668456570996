import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from './message.module.scss';

const ChatActions = ({ actions }) => (
  <div className={styles.chatActions}>
    {actions.map(action => (
      <Button
        key={`unified-chat-message-action-${action.text}`}
        type="text"
        mood="none"
        className={styles.chatActionBtn}
        onClick={action.onClick}
      >
        <Icon type={action.icon} size={20} />

        <span>{action.text}</span>
      </Button>
    ))}
  </div>
);

ChatActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      allow: PropTypes.bool,
      onClick: PropTypes.func
    })
  ).isRequired
};

export default ChatActions;
