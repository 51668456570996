import React from 'react';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './input.module.scss';

export const SkeletonInput = ({ height, withLabel, className }) => (
  <div className={classnames(styles.root, className)}>
    {withLabel && (
      <Skeleton
        active
        title={{ width: '20%', className: styles.label }}
        paragraph={false}
      />
    )}

    <Skeleton
      active
      title={false}
      paragraph={{
        rows: 1,
        width: '100%',
        style: { height },
        className: styles.input
      }}
    />
  </div>
);

SkeletonInput.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
  withLabel: PropTypes.bool
};

SkeletonInput.defaultProps = {
  height: undefined,
  className: undefined,
  withLabel: true
};

export default SkeletonInput;
