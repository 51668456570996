import React from 'react';

export const ListIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 20L20 20L20 4L4 4L4 20ZM2 20.6667C2 21.403 2.59695 22 3.33333 22L20.6667 22C21.403 22 22 21.403 22 20.6667L22 3.33333C22 2.59695 21.403 2 20.6667 2L3.33333 2C2.59695 2 2 2.59695 2 3.33333L2 20.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 16L2 16L2 14L22 14L22 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10L2 10L2 8L22 8L22 10Z"
      fill="currentColor"
    />
  </svg>
);

export default ListIcon;
