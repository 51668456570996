import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  REASON_FOR_RETURN_ISSUE_MODAL,
  STATUS_PENDING,
  STATUS_REJECTED
} from 'constants/index';

import { useModalContext } from 'components/app/modals-provider';

import { acceptedIssue, fetchIssue, rejectedIssue } from 'store/tasks';

import { showNoticeMessage } from 'services/notice';

import {
  ACCEPT_THE_JOB,
  BACK_TO_WORK,
  OPTIONS_REASON_FOR_RETURN_ISSUE_MODAL
} from '../constants';

export const useDetailIssue = () => {
  const dispatch = useDispatch();

  const { showModal } = useModalContext();
  const { issueUuid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAccepted, setIsLoadingAccepted] = useState(false);

  const [errorStatus, setErrorStatus] = useState(null);
  const [data, setData] = useState(null);

  const { t } = useTranslation(['Task', 'Toast', 'BackTaskToWorkServiceDesc']);

  const getFilteredStatusChangeHistory = () => {
    if (data && data.statusChangeHistory.length) {
      const { statusChangeHistory } = data;

      if (
        statusChangeHistory[statusChangeHistory.length - 1].currentStatus ===
          STATUS_PENDING &&
        !statusChangeHistory[statusChangeHistory.length - 1].previousStatus
      ) {
        return data.statusChangeHistory.slice(0, -1);
      }

      return data.statusChangeHistory;
    }

    return [];
  };

  const getReasonText = () => {
    const rejectedStatusInHistory = data.statusChangeHistory.find(
      ({ currentStatus }) => currentStatus === STATUS_REJECTED
    );

    return `${t('IssueCancellationReason')} \n${
      rejectedStatusInHistory.reason
    }`;
  };

  const fetchIssueData = async () => {
    try {
      setIsLoading(true);

      const result = await dispatch(fetchIssue({ issueUuid }));

      setData(result);
    } catch ({ response }) {
      setErrorStatus(response && response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitRejectedIssue = useCallback(
    async ({ reason, reasonType, files }) => {
      const reasonOption = reason.description
        ? null
        : OPTIONS_REASON_FOR_RETURN_ISSUE_MODAL.find(
            ({ value }) => reasonType === value
          );

      const reasonText = reasonOption
        ? t(reasonOption.label, { ns: reasonOption.ns })
        : reason.description;

      const fileData = new FormData();

      if (files && files.fileList && files.fileList.length) {
        files.fileList.forEach((f, index) => {
          fileData.append(`file_${index}`, f);
        });
      }

      fileData.append('reason', reasonText);
      fileData.append('reasonType', reasonType);

      const result = await dispatch(
        rejectedIssue({
          issueUuid,
          values: fileData
        })
      );

      setData(prevState => ({ ...prevState, ...result }));

      showNoticeMessage({
        customContent: t('IssesStatusChanged', { ns: 'Toast' })
      });
    },
    [dispatch, issueUuid, t]
  );

  const onSubmitAcceptedIssue = useCallback(async () => {
    try {
      setIsLoadingAccepted(true);

      const { feedbackLink } = await dispatch(
        acceptedIssue({
          issueUuid
        })
      );

      await setData(prevState => ({ ...prevState, feedbackLink }));

      window.location.href = feedbackLink;
    } finally {
      setIsLoadingAccepted(false);
    }
  }, [dispatch, issueUuid]);

  const handleAction = useCallback(
    action => {
      if (action === BACK_TO_WORK) {
        showModal(REASON_FOR_RETURN_ISSUE_MODAL, {
          onSubmit: onSubmitRejectedIssue
        });
      }

      if (action === ACCEPT_THE_JOB) {
        return onSubmitAcceptedIssue();
      }

      return null;
    },
    [onSubmitAcceptedIssue, onSubmitRejectedIssue, showModal]
  );

  return {
    data,
    isLoading,
    isLoadingAccepted,
    errorStatus,
    handleAction,
    getFilteredStatusChangeHistory,
    getReasonText,
    fetchIssueData
  };
};

export default useDetailIssue;
