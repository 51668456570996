import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LogoIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import { LanguageSelect } from 'components/common/controls/custom-select';

import { getUILanguage, setUILanguage } from 'store/ui';

import styles from './header.module.scss';

const Header = () => {
  const dispatch = useDispatch();

  const language = useSelector(getUILanguage);

  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng.value);
    dispatch(setUILanguage(lng.value));
  };

  return (
    <div className={styles.root}>
      <a
        href="https://upservice.com"
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        <Icon component={LogoIcon} />
      </a>

      <LanguageSelect
        value={language}
        rootClassName={styles.language}
        onChange={changeLanguage}
      />
    </div>
  );
};

export default Header;
