const removeTrailingZeros = (numberStr = '') => numberStr.replace(/0+$/, '');

const cleanNumberString = value => {
  const firstDotIndex = value.indexOf('.');
  if (firstDotIndex === -1) {
    return value;
  }

  const beforeDot = value.slice(0, firstDotIndex + 1);
  const afterDot = value.slice(firstDotIndex + 1).replace(/[.,\s]/g, '');

  return beforeDot + afterDot;
};

export const getFormattedTagNumber = (amount = '') => {
  const replacedAmount = amount.replace(/,/g, '.');

  const normalizedAmount = cleanNumberString(replacedAmount);

  if (normalizedAmount.includes('.')) {
    const [integerPart, fractionalPart] = normalizedAmount.split('.');

    const trimmedFractionalPart = removeTrailingZeros(fractionalPart);

    if (trimmedFractionalPart.length === 0) {
      return integerPart;
    }

    return `${integerPart}.${trimmedFractionalPart}`;
  }

  return normalizedAmount;
};
