import createActionThunk from '../actions-thunk';
import api from '../../api';

// it use to connect google scopes
export const googleAuthCallback = createActionThunk(
  `social-credentials/google/oauth-2-callback`,
  ({ code }) =>
    api.user.googleAuthCallback({ params: { code } }).then(({ data }) => data)
);

// it use to check, if socaials (like google calendar) connected to workspace
export const fetchSocialCredentials = createActionThunk(
  'social-credentials/fetch',
  () => api.socialCredentials.fetchAll().then(({ data }) => data)
);

// it use to connect social (like google calendar) to workspace
export const addSocialCredentials = createActionThunk(
  'social-credentials/add',
  ({ socialCredentials, type, workspaceId }) =>
    api.socialCredentials
      .add({ workspaceId, socialCredentials, type })
      .then(({ data }) => data)
);

export const updateSocialCredential = createActionThunk(
  'social-credentials/update',
  ({ id, workspaceId }) => api.socialCredentials.update({ id, workspaceId })
);

// use this endpoint to get social accounts linked to the current user
export const fetchLinkedSocials = createActionThunk(
  `social-credentials/fetch-linked-socials`,
  () => api.user.fetchLinkedSocials().then(({ data }) => data)
);

export const deleteLinkedSocials = createActionThunk(
  `social-credentials/delete-linked-socials`,
  ({ id }) => api.user.deleteLinkedSocials(id)
);

// UNUSABLE

export const fetchGoogle = createActionThunk(
  `social-credentials/fetch-google`,
  () => api.user.google().then(({ data }) => data)
);

export const updateLinkedSocials = createActionThunk(
  `social-credentials/update-linked-socials`,
  ({ id, name }) =>
    api.user.updateLinkedSocials(id, name).then(({ data }) => data)
);

export const deleteSocialCredentials = createActionThunk(
  'social-credentials/delete',
  ({ id }) => api.socialCredentials.delete(id)
);
