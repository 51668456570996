import React from 'react';

export const MapIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9323 3.0222L15.9323 1.5222C15.779 1.48418 15.6177 1.4952 15.471 1.5537L8.196 4.4637L2.43225 3.0222C2.32166 2.9945 2.20622 2.99237 2.09468 3.01598C1.98315 3.03959 1.87847 3.08832 1.78859 3.15845C1.69871 3.22859 1.62601 3.31829 1.576 3.42074C1.526 3.5232 1.5 3.6357 1.5 3.7497V20.2497C1.5 20.4169 1.55588 20.5793 1.65874 20.7111C1.76161 20.8429 1.90557 20.9366 2.06775 20.9772L8.06775 22.4772C8.12738 22.492 8.18857 22.4995 8.25 22.4997C8.3456 22.4997 8.44032 22.4814 8.529 22.4457L15.804 19.5357L21.5685 20.9772C21.679 21.0048 21.7944 21.0068 21.9058 20.9831C22.0173 20.9595 22.1219 20.9107 22.2116 20.8406C22.3014 20.7705 22.3741 20.6808 22.424 20.5784C22.474 20.476 22.5 20.3636 22.5 20.2497V3.7497C22.5 3.58251 22.4441 3.42011 22.3413 3.2883C22.2384 3.15649 22.0944 3.06283 21.9323 3.0222ZM9.2 5.90791V5.89286L10.5921 5.33604L14.8234 3.59777V18.1607L9.19886 20.3497V5.90838L9.2 5.90791ZM16.7 17.9841L16.6779 17.9785V3.41553L20.8127 4.44911V19.0424L20.0099 18.8358L16.7 18.0078V17.9841ZM3.2 19.4738L3.17908 19.4682V4.90521L7.34429 5.90838V20.5929L4.93274 19.9417L3.2 19.5085V19.4738Z"
      fill="currentColor"
    />
  </svg>
);

export default MapIcon;
