import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Tooltip, Badge } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';

import { useLocalStorage } from 'hooks';

import Icon from '../icon';
import SplitPane from '../split-pane';
import { useTheme } from '../theme';
import Button from '../button';

import styles from './filter-pane.module.scss';

export const PREFIX_FILTER = 'filter-pane/';

export const FilterPane = ({
  opened: openedOuter,
  onOpenedChange,
  children,
  name,
  className,
  contentClassName,
  bodyClassName,
  headClassName,
  bodyStyle,
  onReset,
  indicator,
  sizes,
  hideFilter,
  canReset,
  secondPaneClassName,
  firstPaneClassName,
  initialOpenedState
}) => {
  const { variables } = useTheme();

  const { t } = useTranslation(['Filters', 'Common']);

  const withOuterState =
    onOpenedChange instanceof Function && typeof openedOuter === 'boolean';

  // width - тоже нужно для ресайза, пока просто не использую
  // eslint-disable-next-line no-unused-vars
  const [{ opened: openedLocal, width }, setCollapsed] = useLocalStorage(
    `${PREFIX_FILTER}${name}`,
    {
      opened: initialOpenedState,
      width: sizes.width || 380
    }
  );

  const opened = withOuterState ? openedOuter : openedLocal;

  const handleCollapse = value => {
    setCollapsed(prevCollapsed => ({ ...prevCollapsed, ...value }));

    if (withOuterState && typeof value.opened === 'boolean') {
      onOpenedChange(value.opened);
    }
  };

  useEffect(() => {
    const shouldSetInitialOuterState =
      withOuterState && openedOuter !== openedLocal;

    if (shouldSetInitialOuterState) {
      onOpenedChange(openedLocal);
    }
    // eslint-disable-next-line
  }, []);

  const [filterChildren, contentChildren] = children;

  // Настройки для ресайза
  // пока не удаляю их, но если пройдет много времени и не понадобяться - убрать
  const collapseWidth = sizes.collapseWidth || 38;
  const minWidth = sizes.minWidth || 300;
  const maxWidth = sizes.maxWidth || 480;

  return (
    <div className={classnames(styles.root, className)}>
      <SplitPane
        minWidth={opened ? minWidth : collapseWidth}
        maxWidth={opened ? maxWidth : collapseWidth}
        withNavigatorResize
        defaultWidth={380}
        styleFirstPane={{
          borderRight: `1px solid ${variables.color['black-85']}`,
          display: hideFilter ? 'none' : 'flex',
          transition: 'all 0.3s'
        }}
        visisbleSeporator={false}
        onResizeEnd={resizedWidth => handleCollapse({ width: resizedWidth })}
        secondPaneClassName={secondPaneClassName}
        firstPaneClassName={firstPaneClassName}
      >
        <div
          className={classnames(styles.content, contentClassName, {
            [styles.hidden]: hideFilter,
            [styles.closed]: !opened
          })}
        >
          <div
            className={classnames(styles.content, {
              [styles.hidden]: !opened
            })}
          >
            <div className={classnames(styles.head, headClassName)}>
              <Typography.Title level={3} className={styles.title}>
                {t('FilterHeading')}
              </Typography.Title>

              {canReset && (
                <Button
                  type="link"
                  className={styles.resetBtn}
                  onClick={onReset}
                >
                  <Icon type="reload" size={16} side="left" />

                  <span>{t('ResetFilterBtn')}</span>
                </Button>
              )}

              <Tooltip
                title={t('CollapseFilter', { ns: 'Common' })}
                placement="right"
                mouseEnterDelay={0.5}
              >
                <Button
                  size="small"
                  type="text"
                  mood="none"
                  className={styles.collapseBtn}
                  onClick={() => handleCollapse({ opened: !opened })}
                >
                  <Icon type="arrow" side="left" size={20} />
                </Button>
              </Tooltip>
            </div>

            <div
              className={classnames(styles.body, bodyClassName)}
              style={{ padding: 20, ...bodyStyle }}
            >
              {filterChildren}
            </div>
          </div>

          <Tooltip
            title={t('OpenFilterTooltip')}
            placement="right"
            mouseEnterDelay={0.5}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                height: '100%',
                padding: '20px 6px',
                cursor: 'pointer'
              }}
              className={classnames(styles.grayColor, {
                [styles.hidden]: opened
              })}
              onClick={() => handleCollapse({ opened: !opened })}
            >
              <Badge
                count={indicator}
                showZero={false}
                offset={[-4, 0]}
                style={{
                  width: 15,
                  height: 15,
                  minWidth: 15,
                  lineHeight: '15px',
                  padding: 0,
                  fontSize: 10,
                  backgroundColor: variables.color['brand-40']
                }}
              >
                <Icon type="control" />
              </Badge>
            </div>
          </Tooltip>
        </div>

        {contentChildren}
      </SplitPane>
    </div>
  );
};

FilterPane.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any, PropTypes.array]).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  headClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  indicator: PropTypes.number,
  sizes: PropTypes.shape({
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    width: PropTypes.number,
    collapseWidth: PropTypes.number
  }),
  bodyStyle: PropTypes.object,
  hideFilter: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
  canReset: PropTypes.bool,
  secondPaneClassName: PropTypes.string,
  firstPaneClassName: PropTypes.string,
  initialOpenedState: PropTypes.bool
};

FilterPane.defaultProps = {
  className: undefined,
  contentClassName: undefined,
  bodyClassName: undefined,
  headClassName: undefined,
  indicator: 0,
  hideFilter: false,
  bodyStyle: {},
  sizes: {},
  canReset: true,
  secondPaneClassName: undefined,
  firstPaneClassName: undefined,
  initialOpenedState: true
};

export default FilterPane;
