import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import { FormEmployeeSelect } from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

const Form = ({ employee, isLoading, selectProps, btnText, onSubmit }) => {
  const methods = useForm({ defaultValues: { employee } });

  const { t } = useTranslation('Errors');

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormEmployeeSelect
          name="employee"
          itemProps={{ style: { marginBottom: 24 } }}
          rules={{
            required: t('RequiredField')
          }}
          {...selectProps}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          style={{ marginLeft: 'auto', display: 'block' }}
          loading={isLoading}
        >
          {btnText}
        </Button>
      </form>
    </FormProvider>
  );
};

const EmployeeModal = ({
  defaultValue,
  visible,
  title,
  btnText,
  selectProps,
  description,
  onSubmit,
  onClose,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async ({ employee }) => {
    try {
      setIsLoading(true);

      // employee: {value, label} - TODO: pass only label
      await onSubmit(employee);

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const transformDefaultValue = defaultValue
    ? {
        value: defaultValue.id,
        label: defaultValue
      }
    : undefined;

  return (
    <Modal
      width={508}
      visible={visible}
      onClose={onClose}
      title={title}
      contentStyle={{ padding: 24 }}
      destroyOnClose
      centered
      {...props}
    >
      {description && (
        <Typography.Paragraph>{description}</Typography.Paragraph>
      )}

      <Form
        employee={transformDefaultValue}
        isLoading={isLoading}
        onSubmit={handleOnSubmit}
        btnText={btnText}
        selectProps={selectProps}
      />
    </Modal>
  );
};

EmployeeModal.propTypes = {
  visible: PropTypes.bool,
  selectProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.any,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

EmployeeModal.defaultProps = {
  visible: false,
  selectProps: {},
  title: '',
  description: null,
  onSubmit: () => {},
  onClose: () => {}
};

export default EmployeeModal;
