import differenceWith from 'lodash/differenceWith';
import { useDispatch } from 'react-redux';

import {
  createTemplateRelationLocal,
  deleteTemplateRelationLocal
} from 'store/tasks';

export const useTemplateRelations = () => {
  const dispatch = useDispatch();

  const updateTemplateRelations = async (
    defaultRelations,
    newRelations,
    templateId
  ) => {
    const addedRelations = differenceWith(
      newRelations,
      defaultRelations,
      (rel1, rel2) => rel1.relationId === rel2.relationId
    );

    const deletedRelations = differenceWith(
      defaultRelations,
      newRelations,
      (rel1, rel2) => rel1.relationId === rel2.relationId
    );

    for (const relation of addedRelations) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(
        createTemplateRelationLocal({
          id: templateId,
          relationId: relation.relationId,
          relationType: relation.relationType
        })
      );
    }

    for (const relation of deletedRelations) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(deleteTemplateRelationLocal({ id: templateId, relation }));
    }
  };

  return {
    updateTemplateRelations
  };
};

export default useTemplateRelations;
