import React from 'react';

export const PasswordChangedIcon = ({
  style = { width: 150, height: 150 }
}) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M57.15 22.55C47.35 22.55 12.85 39.65 5.45001 53.35C-1.44999 66.25 18.65 75.45 18.65 84.45C18.65 103.15 45.15 93.25 69.15 88.75C93.05 84.25 106.25 64.15 98.95 48.55C91.55 32.95 76.15 22.55 57.15 22.55Z"
      fill="#36B7BD"
    />
    <path
      d="M89.35 37.65V70.25C89.35 71.45 88.85 72.55 88.05 73.35C87.25 74.15 86.15 74.65 84.95 74.65H33.75C32.65 74.65 31.55 74.15 30.65 73.35C30.05 72.75 29.55 71.85 29.45 70.95C29.45 70.75 29.35 70.45 29.35 70.25V37.65C29.35 36.45 29.85 35.35 30.65 34.55C31.45 33.75 32.55 33.25 33.75 33.25H84.95C86.05 33.25 87.15 33.75 88.05 34.55C88.85 35.45 89.25 36.55 89.35 37.65Z"
      fill="#FDA402"
    />
    <path
      d="M82.55 34.05V66.55C82.55 67.75 82.05 68.85 81.25 69.65C80.45 70.45 79.35 70.95 78.15 70.95H29.45C29.45 70.75 29.35 70.45 29.35 70.25V37.65C29.35 36.45 29.85 35.35 30.65 34.55C31.45 33.75 32.55 33.25 33.75 33.25H82.55C82.55 33.55 82.55 33.7031 82.55 34.05Z"
      fill="#FCC977"
    />
    <path
      d="M73.85 24.35V33.25H69.55V24.35C69.55 22.95 69.35 21.65 68.85 20.35C68.35 19.05 67.65 17.95 66.65 16.95C65.75 15.95 64.55 15.25 63.35 14.65C62.05 14.15 60.75 13.85 59.35 13.85C57.95 13.85 56.65 14.15 55.35 14.65C54.05 15.15 52.95 16.05 52.05 17.05C51.15 18.05 50.35 19.15 49.85 20.45C49.35 21.75 49.15 23.05 49.15 24.45V33.35H44.85V24.45C44.85 22.55 45.15 20.55 45.85 18.75C46.55 16.95 47.65 15.35 48.95 13.95C50.35 12.55 51.95 11.45 53.65 10.75C55.45 9.94999 57.35 9.54999 59.35 9.54999C61.25 9.54999 63.15 9.94999 64.95 10.65C66.75 11.35 68.35 12.45 69.65 13.85C70.95 15.25 72.05 16.85 72.75 18.65C73.55 20.55 73.85 22.45 73.85 24.35Z"
      fill="#2B2A36"
    />
    <path
      d="M54.25 61.85L55.95 53.75C55.35 53.05 54.95 52.25 54.85 51.35C54.85 50.25 55.35 49.25 56.15 48.55C56.95 47.85 57.95 47.35 59.05 47.35C60.15 47.35 61.15 47.75 61.95 48.55C62.75 49.25 63.15 50.35 63.25 51.35C63.25 52.25 62.85 53.05 62.25 53.75L64.45 61.85H54.25Z"
      fill="#2B2A36"
    />
    <path
      d="M15.45 59.55C23.95 58.85 29.95 52.95 29.95 52.95C29.95 52.95 37.25 58.85 44.45 59.55C44.45 69.65 40.25 84.75 29.95 84.75C19.65 84.75 15.45 65.45 15.45 59.55Z"
      fill="#36B7BD"
    />
    <path
      d="M29.7499 75.15L23.4499 68.85C23.0499 68.45 23.0499 67.85 23.4499 67.45C23.8499 67.05 24.4499 67.05 24.8499 67.45L29.7499 72.35L37.1499 64.95C37.5499 64.55 38.1499 64.55 38.5499 64.95C38.9499 65.35 38.9499 65.95 38.5499 66.35L29.7499 75.15Z"
      fill="white"
    />
  </svg>
);

export default PasswordChangedIcon;
