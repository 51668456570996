import React from 'react';

export const AtIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C14.1864 21 16.1814 20.2477 17.7333 18.9878L18.9939 20.5405C17.0913 22.0851 14.6528 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 12.0348 23 12.0701 23 12.1057C23.0006 13.0274 23.0014 14.201 22.6104 15.1382C22.3901 15.6661 22.0339 16.1589 21.4725 16.5072C20.9205 16.8496 20.2546 17 19.5 17C18.7414 17 18.0703 16.8482 17.4995 16.5324C17.0019 16.2571 16.623 15.8805 16.3408 15.4513C16.2785 15.5296 16.2142 15.6061 16.1478 15.6808C15.1334 16.8232 13.6504 17.5455 12 17.5455C8.93733 17.5455 6.45455 15.0627 6.45455 12C6.45455 8.93733 8.93733 6.45455 12 6.45455C13.3486 6.45455 14.5848 6.93655 15.5455 7.73595V5.5H17.5455V12C17.5455 12.969 17.6632 13.7468 17.9544 14.2586C18.0885 14.4943 18.2544 14.6643 18.4678 14.7824C18.6854 14.9028 19.0086 15 19.5 15C19.9954 15 20.267 14.9015 20.4181 14.8077C20.5599 14.7197 20.6724 14.589 20.7646 14.368C20.9835 13.8435 21 13.0719 21 12C21 7.02944 16.9706 3 12 3ZM15.5455 12C15.5455 11.2007 15.2821 10.4658 14.8371 9.87331C14.1886 9.0099 13.1593 8.45455 12 8.45455C10.0419 8.45455 8.45455 10.0419 8.45455 12C8.45455 13.9581 10.0419 15.5455 12 15.5455C13.055 15.5455 14.0014 15.0859 14.6523 14.3528C15.2087 13.7262 15.5455 12.9037 15.5455 12Z"
      fill="currentColor"
    />
  </svg>
);

export default AtIcon;
