import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';

import { getFIO, getFullName } from 'utils/get-fio';
import getWorkspaceTitle from 'utils/get-workspace-title';

import Option from '../option';

import styles from './workspace-option.module.scss';

export const WorkspaceOption = ({
  option,
  className,
  withWatNumber,
  ...props
}) => {
  const vatNumber = !option.vatNumber
    ? option.creatorFullName
    : option.vatNumber || '';

  const companyTitle =
    withWatNumber && vatNumber ? `${option.title} ${vatNumber}` : option.title;

  const title = option.isIndividual
    ? getFullName({ ...option.contacts[0], allName: true })
    : companyTitle;

  const workspaceTitle = getWorkspaceTitle(option);

  return (
    <Option
      className={classnames(className, styles.root)}
      contentClassName={styles.contentWrap}
      title={title}
      {...props}
    >
      <Avatar
        src={(option.logoFile || {}).url}
        size={23}
        style={{ marginRight: 10, flexShrink: 0 }}
      >
        {getFIO({ lastName: title })}
      </Avatar>

      <div className={styles.content}>
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ marginBottom: 0 }}
        >
          {title}
        </Typography.Paragraph>

        {option.isIndividual && (
          <Typography.Text color="black-55" size="small">
            {workspaceTitle}
          </Typography.Text>
        )}

        {withWatNumber && vatNumber && (
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            color="black-55"
            size="small"
            style={{ marginBottom: 0 }}
          >
            {vatNumber}
          </Typography.Paragraph>
        )}
      </div>
    </Option>
  );
};

WorkspaceOption.propTypes = {
  option: PropTypes.shape({
    isIndividual: PropTypes.bool,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    logoFile: PropTypes.object,
    title: PropTypes.string.isRequired,
    vatNumber: PropTypes.string,
    creatorFullName: PropTypes.string
  }).isRequired,
  withWatNumber: PropTypes.bool
};

WorkspaceOption.defaultProps = {
  withWatNumber: true
};

export default WorkspaceOption;
