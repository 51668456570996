import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = createContext({});

export const ThemeProvider = ({ variables, children }) => {
  const spacing = (multiplier = 1) =>
    multiplier * parseFloat(variables['space-unit']);

  return (
    <ThemeContext.Provider value={{ variables, spacing }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
  variables: PropTypes.object.isRequired
};

export default ThemeProvider;
