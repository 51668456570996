import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Steps from 'components/common/steps';

import {
  addPaymentSubscription,
  changePaymentSubscription
} from 'store/billing';

import { showNoticeMessage } from 'services/notice';

import {
  CHECKOUT_PAYMENT_STEP,
  CHOOSE_ADDONS_STEP,
  CHOOSE_TARIFF_STEP,
  STEPS
} from './utils';
import ChooseTariffStep from './steps/choose-tariff';
import ChooseAddonsStep from './steps/choose-addons';
import CheckoutPaymentStep from './steps/checkout-payment';

import styles from './change-payment-subscription.module.scss';

const ChangePaymentSubscriptionModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [addedAddons, setAddedAddons] = useState({});

  const [selectedTariff, setSelectedTariff] = useState({
    tariffId: null,
    period: null,
    currency: null
  });
  const [selectedAddons, setSelectedAddons] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['ChangePlan', 'Toast']);

  const { workspaceId, currentTariff = {} } = data;

  const steps = useMemo(() => {
    if (currentTariff.isDefault) {
      return STEPS;
    }

    return STEPS.filter(s => s.key !== CHOOSE_ADDONS_STEP);
  }, [currentTariff.isDefault]);

  const isChooseTariffStep = (steps[step] || {}).key === CHOOSE_TARIFF_STEP;
  const isChooseAddonsStep = (steps[step] || {}).key === CHOOSE_ADDONS_STEP;
  const isCheckoutPaymentStep =
    (steps[step] || {}).key === CHECKOUT_PAYMENT_STEP;

  const handleStep = () => {
    if (step === steps.length - 1) {
      return onClose();
    }

    return setStep(prev => prev + 1);
  };

  const onGoBack = () => setStep(prev => prev - 1);

  const changeAddedAddons = ({ id, fieldKey, fieldValue }) =>
    setAddedAddons(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [fieldKey]: fieldValue
      }
    }));

  const onSubmit = async ({ tariff, period, currency }) => {
    if (isChooseTariffStep) {
      const { requestLink, paid, id } = tariff;

      if (!paid) {
        onClose();
        return window.open(requestLink, '_blank');
      }

      setSelectedTariff({ tariffId: id, period, currency });
    }

    if (isChooseAddonsStep) {
      const addons = Object.keys(addedAddons).reduce((acc, curr) => {
        if (!addedAddons[curr].isEnabled) {
          return acc;
        }

        acc.push({ addon: curr, count: addedAddons[curr].count || 1 });
        return acc;
      }, []);

      setSelectedAddons(addons);
    }

    if (isCheckoutPaymentStep) {
      try {
        setIsLoading(true);

        let paymentLink;

        const values = {
          workspaceId,
          period: selectedTariff.period,
          currency: selectedTariff.currency,
          tariffId: selectedTariff.tariffId,
          addons: selectedAddons
        };

        if (!currentTariff.isDefault) {
          const { url } = await dispatch(changePaymentSubscription(values));
          paymentLink = url;
        } else {
          const { url } = await dispatch(addPaymentSubscription(values));
          paymentLink = url;
        }

        if (paymentLink) {
          window.open(paymentLink, '_blank');
        } else {
          showNoticeMessage({
            customContent: t('TariffChangesSuccessfully', { ns: 'Toast' })
          });
        }
      } finally {
        setIsLoading(false);
      }
    }

    return handleStep();
  };

  useEffect(() => {
    if (!visible) {
      setStep(0);
      setAddedAddons({});
    }
  }, [visible]);

  return (
    <Modal
      width={1220}
      title={t('ChangePlanHeading')}
      contentClassName={styles.root}
      visible={visible}
      centered
      destroyOnClose
      onClose={onClose}
    >
      <Steps current={step} steps={steps} />

      <Spin spinning={isLoading}>
        <div className={styles.wrap}>
          {isChooseTariffStep && (
            <>
              <ChooseTariffStep onSubmit={onSubmit} />

              <Button
                type="link"
                href="https://upservice.com/pricing"
                target="target_blank"
                className={styles.detailsTariffsBtn}
              >
                {t('PlansDetailsBtn')}
              </Button>
            </>
          )}

          {isChooseAddonsStep && (
            <ChooseAddonsStep
              addedAddons={addedAddons}
              selectedTariff={selectedTariff}
              changeAddedAddons={changeAddedAddons}
              onGoBack={onGoBack}
              onSubmit={onSubmit}
            />
          )}

          {isCheckoutPaymentStep && (
            <CheckoutPaymentStep
              currentTariff={currentTariff}
              selectedData={{ ...selectedTariff, addons: selectedAddons }}
              onGoBack={onGoBack}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </Spin>
    </Modal>
  );
};

ChangePaymentSubscriptionModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    workspaceId: PropTypes.number,
    currentTariff: PropTypes.shape({
      isDefault: PropTypes.bool
    })
  }),
  onClose: PropTypes.func
};

ChangePaymentSubscriptionModal.defaultProps = {
  visible: false,
  data: {
    workspaceId: null,
    currentTariff: {}
  },
  onClose: () => {}
};

export default ChangePaymentSubscriptionModal;
