import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TYPE_TASK } from 'constants/index';

import useChat from '../use-chat';

export const useNotificationMessageChat = ({
  entity = {},
  entityFromNotification = {},
  sendMessageCallback
}) => {
  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    renderedControls,
    isJoined,
    entityChat,
    hasMore,
    fetchMessages,
    controls
  } = useChat({
    entity,
    entityType: entityFromNotification.type || TYPE_TASK,
    destination: {
      entityType: entityFromNotification.destinationEntityType,
      entityId: entity.id && entity.id.toString(),
      entity
    },
    needLeave: false,
    sendMessageCallback
  });

  useEffect(() => {
    if (
      (entityChat.isNeedInitalFetchMessages === undefined ||
        entityChat.isNeedInitalFetchMessages) &&
      !isEmpty(entity)
    ) {
      fetchMessages({ offset: 0, withSource: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityChat.isNeedInitalFetchMessages, entity]);

  return {
    controls,
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore: fetchMessages
  };
};

export default useNotificationMessageChat;
