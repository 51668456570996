// Events
export const TASK_COMPLETED_RESPONSIBLE = 'task_completed_responsible';
export const TASK_COMPLETED_CONTROLLER = 'task_completed_controller';
export const SPECIFICATION_SENT = 'specification_sent';
export const SPECIFICATION_AGREED = 'specification_agreed';
export const DIALOGUE_ARCHIVE_MANUALL = 'dialogue_archive_manuall';
export const ADD_CONTACT = 'add_contact';

// Components
export * from './modal';
export * from './use-feedback-modal';
