import queryString from 'qs';

const DEEP_LINKS_MAP = {
  DASHBOARD: '/dashboard/notifications',
  OPEN_TASKS: '/tasks/all',
  OPEN_ORDERS: '/crm/orders',
  OPEN_PROJECTS: '/tasks/projects',
  OPEN_ORGSTRUCTURE: '/team/orgstructure',
  OPEN_CONTACTS: '/contacts/chats',
  OPEN_TIME_TRACKING: '/reports/time-tracking',
  REPORTS: '/reports/all',
  OPEN_STORAGE: '/attachments/all',
  OPEN_STORAGE_RECYCLE_BIN: '/attachments/basket',
  OPEN_PERSONAL_PROFILE: '/settings/user-profile/personal',
  OPEN_SECURITY_SETTINGS: '/settings/user-profile/security',
  OPEN_WORKSPACE_INFO: '/settings/workspace-profile/workspace/contact-info',
  OPEN_WORKSPACE_REQUISITES:
    '/settings/workspace-profile/workspace/contact-info',
  OPEN_WORKSPACE_PRICE_LIST: '/crm/settings/price-list',
  OPEN_WORKSPACE_RATING: '/settings/workspace-profile/workspace/rating',
  OPEN_WORKSPACE_CHANNELS: '/contacts/integrations',
  OPEN_DIALOGUES: '/contacts/chats',
  OPEN_SUPPORT: '/support',
  OPEN_PERFORMING_DISCIPLINE: '/reports/performing-discipline',
  OPEN_ASSETS: '/assets/categories',
  OPEN_ASSETS_CALENDAR: '/assets/calendar',
  OPEN_ORDERS_PRIVAT_PERSON: '/crm/orders/to-individual',

  CREATE_TASK: '/tasks/all?modal=createTask',
  CREATE_ORDER: '/crm/orders?modal=createOrder',
  CREATE_EMPLOYEE: '/team/orgstructure?modal=inviteEmployee',

  EDIT_EMPLOYEE_INFO: '/settings/workspace-profile/user/edit-self',
  OPEN_NOTIFICATIONS_SETTINGS: '/settings/workspace-profile/user/notifications',

  EMAIL_CHANGE_CONFIRM: ({ token }) => `/auth/confirm-email/${token}`
};

export const DeepLinksHandler = ({ children, history }) => {
  const { action, ...data } = queryString.parse(
    history.location.search.slice(1)
  );

  let deeplink = DEEP_LINKS_MAP[action];

  if (deeplink) {
    if (typeof deeplink === 'function') {
      deeplink = deeplink(data);
    }

    history.replace(deeplink);
  }

  return children;
};

export default DeepLinksHandler;
