export const disableActionsInExternalServices = ({ workspaceId }) => {
  const workspaces = localStorage.getItem('workspaces');
  const workspace = localStorage.getItem('workspace');

  const keysWorkspaces = workspaces && Object.keys(JSON.parse(workspaces));

  return (
    workspaceId &&
    ((workspaces && keysWorkspaces.some(w => +w === workspaceId)) ||
      (workspace && workspaceId === +workspace))
  );
};

export default disableActionsInExternalServices;
