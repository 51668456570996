import themeVariables from 'theme';

export const RATING = 'rating';
// classes of raitings
export const SUPPORTERS = 'supporters';
export const NEUTRAL = 'neutral';
export const CRITICS = 'critics';

export const RATES = [
  {
    value: 5,
    // title: 'Отлично',
    color: themeVariables.color['brand-40'],
    backgroundColor: themeVariables.color['brand-95']
  },
  {
    value: 4,
    // title: 'Хорошо',
    color: themeVariables.color.yellow,
    backgroundColor: themeVariables.color['yellow-light']
  },
  {
    value: 3,
    // title: 'Средне',
    color: themeVariables.color['red-60'],
    backgroundColor: themeVariables.color['red-90']
  },
  {
    value: 2,
    // title: 'Плохо',
    color: themeVariables.color['red-55'],
    backgroundColor: themeVariables.color['red-90']
  },
  {
    value: 1,
    // title: 'Очень плохо',
    color: themeVariables.color['red-40'],
    backgroundColor: themeVariables.color['red-80']
  }
];
