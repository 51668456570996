export const getOrderingDirectionByKey = key =>
  key.startsWith('-') ? 'DESC' : 'ASC';

export const getClearOrderingKey = key =>
  key.startsWith('-')
    ? key
        .split('')
        .splice(1)
        .join('')
    : key;
