import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'constants/calendar';

import styles from './attachment-version.module.scss';

const { Text } = Typography;

export const AgreementList = ({ list, onClick }) => {
  const { t } = useTranslation('ApprovingList');

  return (list || []).map(item => (
    <div key={`${item.createdAt}-${item.id}`} className={styles.agreement}>
      <Text className={styles.agreementText}>
        {item.status} {moment(item.createdAt).format(DATE_TIME_FORMAT)}
      </Text>{' '}
      <Button
        type="link"
        style={{ padding: 0, height: 'auto', fontSize: 12, border: 'none' }}
        onClick={() => onClick(item.id)}
      >
        {t('ApprovingListHeading')}
      </Button>
    </div>
  ));
};

AgreementList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.number
    })
  ),
  onClick: PropTypes.func.isRequired
};

AgreementList.defaultProps = {
  list: []
};

export default AgreementList;
