import React from 'react';
import { Button, Tooltip, Badge } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import SplitPane from 'components/common/split-pane';
import Icon from 'components/common/icon';

import { useLocalStorage } from 'hooks';

import BackBtn from '../back-btn';

import styles from './collapse-panel.module.scss';

export const CollapsePanel = ({
  children,
  name,
  defaultOpened,
  className,
  contentClassName,
  bodyClassName,
  headClassName,
  onClickBack,
  labels,
  icons,
  head,
  indicator,
  styleFirstPane,
  offsetHead,
  showBackBtn,
  sizes,
  outerState
}) => {
  const [isOpenedLocal, setIsOpenedLocal] = useLocalStorage(
    name,
    defaultOpened
  );

  const currentIcons = {
    back: 'arrow',
    menu: 'menu',
    close: 'close',
    ...icons
  };

  const isOpened =
    outerState.opened === undefined ? isOpenedLocal : outerState.opened;
  const setIsOpened =
    outerState.setOpened === undefined
      ? setIsOpenedLocal
      : outerState.setOpened;

  const isArray = React.Children.toArray(children).length > 1;

  const getStyleFirstPane = () =>
    !isOpened
      ? {
          visibility: 'hidden',
          opacity: 0,
          ...styleFirstPane
        }
      : styleFirstPane;

  const minWidth = sizes.minWidth || 260;
  const maxWidth = sizes.maxWidth || 420;
  const collapseWidth = sizes.collapseWidth || 30;

  return (
    <div
      className={classnames(styles.root, className)}
      data-qa="qa-xxasnkl7wi4h396"
    >
      <SplitPane
        minWidth={isOpened ? minWidth : collapseWidth}
        maxWidth={isOpened ? maxWidth : collapseWidth}
        defaultWidth={sizes.width}
        styleFirstPane={getStyleFirstPane()}
        visisbleSeporator={isOpened}
      >
        <div
          className={classnames(styles.content, contentClassName)}
          data-qa="qa-211iusn974y05vl"
        >
          <div
            className={classnames(styles.flexWrap, headClassName)}
            data-qa="qa-6lqvn5fg8x388nz"
          >
            {head}

            <Tooltip
              title={labels.close}
              placement="right"
              mouseEnterDelay={0.5}
            >
              <Button
                size="small"
                className={styles.btn}
                data-qa="qa-8yk1u8tb8ze3rzp"
                onClick={() => setIsOpened(!isOpened)}
              >
                <Icon type={currentIcons.close} size={16} />
              </Button>
            </Tooltip>
          </div>

          <div
            className={classnames(styles.contentBottom, bodyClassName)}
            data-qa="qa-i7bx2ryed2kk4db"
          >
            {isArray ? children[0] : children}
          </div>
        </div>

        {isArray ? children[1] : undefined}
      </SplitPane>

      {!isOpened && (
        <div
          className={styles.head}
          style={{
            position: 'absolute',
            right: -8,
            top: 12,
            zIndex: 1,
            ...offsetHead
          }}
          data-qa="qa-au69gajg3aeezwv"
        >
          {showBackBtn && (
            <BackBtn
              tooltipProps={{
                title: labels.back,
                placement: 'right'
              }}
              size="small"
              type="default"
              icon={null}
              className={styles.btn}
              onClick={onClickBack}
              style={{ marginBottom: 8 }}
            >
              <Icon type={currentIcons.back} side="left" size={20} />
            </BackBtn>
          )}

          {!!children && (
            <Tooltip
              title={labels.open}
              placement="right"
              mouseEnterDelay={0.5}
            >
              <Button
                size="small"
                className={styles.btn}
                onClick={() => setIsOpened(!isOpened)}
              >
                <Badge
                  count={indicator}
                  showZero={false}
                  offset={[2, -2]}
                  style={{
                    width: 14,
                    height: 14,
                    minWidth: 14,
                    lineHeight: '14px',
                    padding: 0,
                    fontSize: 10
                  }}
                >
                  <Icon type={currentIcons.menu} size={14} />
                </Badge>
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

CollapsePanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any, PropTypes.array]).isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  headClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultOpened: PropTypes.bool,
  showBackBtn: PropTypes.bool,
  offsetHead: PropTypes.object,
  styleFirstPane: PropTypes.object,
  labels: PropTypes.shape({
    close: PropTypes.string,
    open: PropTypes.string,
    back: PropTypes.string
  }).isRequired,
  icons: PropTypes.shape({
    back: PropTypes.any,
    menu: PropTypes.any,
    close: PropTypes.any
  }),
  sizes: PropTypes.shape({
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    width: PropTypes.number,
    collapseWidth: PropTypes.number
  }),
  onClickBack: PropTypes.func,
  outerState: PropTypes.shape({
    opened: PropTypes.bool,
    setOpened: PropTypes.func
  })
};

CollapsePanel.defaultProps = {
  className: undefined,
  contentClassName: undefined,
  bodyClassName: undefined,
  headClassName: undefined,
  defaultOpened: false,
  showBackBtn: true,
  icons: {},
  offsetHead: {},
  sizes: {},
  styleFirstPane: {},
  onClickBack: undefined,
  outerState: {}
};

export default CollapsePanel;
