import api from 'api';

import createActionThunk from 'store/actions-thunk';
import { getWorkspaceId } from 'store/workspace';

export const fetchMessageTemplates = createActionThunk(
  'message-templates/fetch-message-templates',
  ({ getState, search }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.messageTemplates
      .fetchMessageTemplates({
        workspaceId,
        search
      })
      .then(({ data }) => data);
  }
);

export const createMessageTemplate = createActionThunk(
  'message-templates/create-message-template',
  ({ getState, template }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.messageTemplates
      .createMessageTemplate({
        workspaceId,
        payload: template
      })
      .then(({ data }) => data);
  }
);

export const updateMessageTemplate = createActionThunk(
  'message-templates/update-message-template',
  ({ getState, template }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.messageTemplates
      .updateMessageTemplate({
        workspaceId,
        templateId: template.id,
        payload: template
      })
      .then(({ data }) => data);
  }
);

export const deleteMessageTemplate = createActionThunk(
  'message-templates/delete-message-template',
  ({ getState, templateId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.messageTemplates.deleteMessageTemplate({
      workspaceId,
      templateId
    });
  }
);

export const counterIncrementMessageTemplate = createActionThunk(
  'message-templates/counter-increment-message-template',
  ({ getState, templateId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.messageTemplates.counterIncrementMessageTemplate({
      workspaceId,
      templateId
    });
  }
);
