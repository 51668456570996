import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import InputNumber from 'components/common/controls/input-number';

import FormItem from '../form-item';

export const FormInputNumber = forwardRef(
  ({ rules, name, label, itemProps, ...props }, ref) => {
    const { control, formState } = useFormContext();

    return (
      <FormItem
        label={label}
        name={name}
        {...itemProps}
        errors={formState.errors}
      >
        <Controller
          control={control}
          rules={rules}
          render={({ field }) => (
            <InputNumber {...field} {...props} ref={ref} />
          )}
        />
      </FormItem>
    );
  }
);

FormInputNumber.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormInputNumber.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormInputNumber;
