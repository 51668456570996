export const VIDEOS = {
  interfaceIndividual: '1-2',
  keepOrdersTrack: '1-3',
  planWorkTasks: '1-4',
  chatOnSite: '1-5',

  createTask: '2-1',
  searchTask: '2-2',
  typeTask: '2-3',
  cardTask: '2-4',
  workWithTask: '2-5',
  workWithTags: '2-6',
  createSubTask: '2-7',
  workWithTimeLog: '2-8',
  whatIsTemplate: '2-9',
  taskMembers: '2-10',
  taskRelations: '2-11',
  recurringTask: '2-12',
  cardTaskAgreement: '2-13',
  cardTaskAcquaintance: '2-14',
  taskControllerRole: '2-15',
  whatIsTaskStatus: '2-16',
  fillTaskCard: '2-17',

  createOrder: '3-1',
  typeOrder: '3-2',
  cardOrder: '3-3',
  workWithOrder: '3-4',
  searchOrder: '3-5',
  subscribeOrder: '3-6',
  controllerOrder: '3-7',
  rating: '3-8',
  howItContoller: '3-9',
  orderToMarket: '3-10',
  workWithMarketplaceOrder: '3-11',
  whatIsMarketplace: '3-12',
  cardInvoice: '3-13',

  addEmployee: '4-1',
  deleteEmploee: '4-2',
  changeAdmin: '4-3',
  changePosition: '4-4',
  admin: '4-5',

  addClient: '5-1',
  deleteClient: '5-2',
  contacts: '5-3',

  workspaceProfile: '6-1',
  priceList: '6-2',
  publicContract: '6-3',

  userProfile: '7-1',
  ratingEmployee: '7-2',

  createProject: '8-1',

  addAttachments: '9-1',

  dialogs: '10-1',
  connectChannel: '10-2',
  addChannelToSite: '10-3',
  workWithDialogs: '10-4',
  connectEmailChannel: '10-5',
  setupEmailChannel: '10-6',
  answerTemplates: '10-7',
  captions: '10-8',
  relationsInDialogues: '10-9',
  sendLetter: '10-10',
  createTelegramBot: '10-11',
  connectTelegramBot: '10-12',
  setupTelegramChannel: '10-13',
  connectTelegramToSiteChat: '10-14',

  ratingIndividual: '11-1'
};

export default VIDEOS;
