import { unionBy } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { combineActions } from 'redux-actions';

import {
  setFilterSearch,
  fetchProjects,
  createProject,
  editProjectFields,
  completeProject,
  deleteProject,
  changeManager,
  changeMembers,
  fetchProject,
  createSprint,
  fetchSprints,
  deleteSprint,
  activateSprint,
  completeSprint,
  updateSprint,
  selectSprint,
  clearSprints,
  clearProjects,
  setCurrentProjectId,
  clearFilter,
  setFilterTag,
  setFilterSprintStatus,
  setFilterSprint,
  setFilterSprintStartedDateRange,
  setFilterSprintEndedDateRange,
  clearSprintFilter
} from './actions';
import { makeSubscribeLocationChange } from '../router';
import { convertIdeaToProject } from '../ideas/active/actions';
import { createTask, deleteTask } from '../tasks/actions';
import handleActions from '../../utils/redux-actions';
import {
  ITEMS_PER_PAGE,
  PROJECT_COMPLETED,
  TASKS,
  PROJECTS,
  CONTAINS_ANY_OPTION,
  SPRINT_STATUS_OPTIONS
} from '../../constants';
import { setActiveId } from '../workspace';

const initialFilter = {
  search: '',
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  }
};

const sprintInitialFilter = {
  sprintStatus: [SPRINT_STATUS_OPTIONS[0]],
  sprints: [],
  sprintStartedDateRange: {},
  sprintEndedDateRange: {}
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  hasMore: true,

  filter: initialFilter,
  sprintFilter: sprintInitialFilter,
  currentProjectId: undefined,
  entries: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(TASKS, PROJECTS),

    [setActiveId]: () => initialState,

    [combineActions(clearFilter, setFilterSearch, setFilterTag)]: state => {
      state.entries = [];

      return state;
    },

    [clearFilter]: state => {
      state.filter = { ...initialFilter, tag: { ...initialFilter.tag } };
      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [fetchProjects.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;

      return state;
    },

    [fetchProjects.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchProjects.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchProjects.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.isLoaded = true;
      state.totalItems = payload.totalItems;
      state.entries = unionBy([...state.entries], [...payload.entries], 'id');
      state.hasMore = payload.totalItems > state.entries.length;

      return state;
    },

    [fetchProject.SUCCEEDED]: (state, { payload }) => {
      state.entries = [{ ...payload, isLoaded: true }, ...state.entries];

      return state;
    },

    [createProject.SUCCEEDED]: (state, { payload }) => {
      state.entries = [payload, ...state.entries];

      return state;
    },

    [completeProject.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(project => project.id === payload);
      state.entries[index].status = PROJECT_COMPLETED;

      return state;
    },

    [deleteProject.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(item => item.id !== payload);
      state.totalItems -= 1;

      return state;
    },

    [clearProjects]: state => {
      state.entries = [];

      return state;
    },

    [editProjectFields.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => project.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = payload;
      }

      return state;
    },

    [changeMembers.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => project.id === payload.id
      );

      if (index !== -1) {
        state.entries[index].assignments = payload.assignments;
      }

      return state;
    },

    [changeManager.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => project.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = payload;
      }

      return state;
    },

    [convertIdeaToProject.SUCCEEDED]: (state, { payload }) => {
      state.entries = [payload.data, ...state.entries];

      return state;
    },

    [createTask.SUCCEEDED]: (state, { payload }) => {
      if (payload.projectId) {
        // для счетчика заказов в связанном проекте
        const index = state.entries.findIndex(
          project => project.id === payload.projectId
        );

        if (index === -1) {
          return state;
        }

        // eslint-disable-next-line operator-assignment
        state.entries[index].tasksActiveCount =
          state.entries[index].tasksActiveCount + 1;
      }

      return state;
    },

    [deleteTask.SUCCEEDED]: (state, { payload }) => {
      if (payload.projectId) {
        // для счетчика заказов в связанном проекте
        const index = state.entries.findIndex(
          project => project.id === payload.projectId
        );

        if (index === -1) {
          return state;
        }

        // eslint-disable-next-line operator-assignment
        state.entries[index].tasksActiveCount =
          state.entries[index].tasksActiveCount - 1;
      }

      return state;
    },

    [setCurrentProjectId]: (state, { payload }) => {
      state.currentProjectId = payload;

      return state;
    },

    // Sprints
    [createSprint.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      state.entries[index].sprints = [
        payload,
        ...(state.entries[index].sprints || [])
      ];

      state.entries[index].sprintsCount += 1;

      return state;
    },

    [fetchSprints.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      if (index !== -1) {
        state.entries[index].sprintsCount = payload.count;

        state.entries[index].sprints = [
          ...(state.entries[index].sprints || []),
          ...payload.results
        ];

        state.entries[index].sprintsHasMore =
          state.entries[index].sprintsCount >
          (state.entries[index].sprints || []).length;
      }

      return state;
    },

    [deleteSprint.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      state.entries[index].sprints = state.entries[index].sprints.filter(
        item => item.id !== payload.id
      );

      state.entries[index].sprintsCount -= 1;

      return state;
    },

    [updateSprint.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      const sprintIndex = state.entries[index].sprints.findIndex(
        item => item.id === payload.id
      );

      if (!state.entries[index].sprints[sprintIndex]) {
        return state;
      }

      state.entries[index].sprints[sprintIndex] = payload;

      return state;
    },

    [activateSprint.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      const sprintIndex = state.entries[index].sprints.findIndex(
        item => item.id === payload.id
      );

      if (!state.entries[index].sprints[sprintIndex]) {
        return state;
      }

      state.entries[index].sprints[sprintIndex] = payload;

      return state;
    },

    [completeSprint.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      const sprintIndex = state.entries[index].sprints.findIndex(
        item => item.id === payload.id
      );

      if (!state.entries[index].sprints[sprintIndex]) {
        return state;
      }

      state.entries[index].sprints[sprintIndex] = payload;

      return state;
    },

    [selectSprint]: (state, { payload }) => {
      const { project, sprint } = payload || {};

      const index = state.entries.findIndex(item => +item.id === +project);

      if (index !== -1) {
        state.entries[index].selectedSprint = sprint;
      }

      return state;
    },

    [clearSprints]: (state, { payload }) => {
      const index = state.entries.findIndex(
        project => +project.id === +payload.project
      );

      if (index !== -1) {
        state.entries[index].sprints = [];
      }

      return state;
    },

    [setFilterSprintStatus]: (state, { payload }) => {
      state.sprintFilter.sprintStatus = payload;

      return state;
    },

    [setFilterSprint]: (state, { payload }) => {
      state.sprintFilter.sprints = payload;

      return state;
    },

    [setFilterSprintStartedDateRange]: (state, { payload }) => {
      state.sprintFilter.sprintStartedDateRange = payload;

      return state;
    },

    [setFilterSprintEndedDateRange]: (state, { payload }) => {
      state.sprintFilter.sprintEndedDateRange = payload;

      return state;
    },

    [clearSprintFilter]: state => {
      state.sprintFilter = sprintInitialFilter;

      return state;
    },

    [combineActions(
      setFilterSprintStatus,
      setFilterSprint,
      setFilterSprintStartedDateRange,
      setFilterSprintEndedDateRange,
      clearSprintFilter
    )]: state => {
      state.currentPage = 1;
      state.entries = [];

      return state;
    }
  },
  initialState
);
