import handleActions from 'utils/redux-actions';

import { acceptFeature, fetchFeatures } from './actions';

const initialState = {
  features: []
};

export default handleActions(
  {
    [fetchFeatures.SUCCEEDED]: (state, { payload }) => {
      state.features = payload;

      return state;
    },

    [acceptFeature.SUCCEEDED]: (state, { payload }) => {
      const index = state.features.findIndex(f => f.id === payload.id);

      if (index !== -1) {
        state.features[index] = { ...state.features[index], isAccepted: true };
      }

      return state;
    }
  },
  initialState
);
