import { createSelector } from 'reselect';

const getNotifications = state => state.notifications;

export const getActiveNotifications = createSelector(
  getNotifications,
  notifications => notifications.active
);

export const getActiveNotificationByWorkspaces = createSelector(
  getActiveNotifications,
  active => active.byWorkspaces
);

export const getDrawerVisible = createSelector(
  getNotifications,
  notifications => notifications.drawerVisible
);

export const getActiveNotificationsIsLoading = createSelector(
  getActiveNotifications,
  active => active.isLoading
);

export const getActiveNotificationsIsLoaded = createSelector(
  getActiveNotifications,
  active => active.isLoaded
);

export const getActiveNotificationEntries = createSelector(
  getActiveNotificationByWorkspaces,
  byWorkspaces => workspaceId => (byWorkspaces[workspaceId] || {}).entries || []
);

export const getActiveNotificationsTotalItems = createSelector(
  getActiveNotificationByWorkspaces,
  byWorkspaces => workspaceId =>
    (byWorkspaces[workspaceId] || {}).totalItems || 0
);

export const getQueueNotifications = createSelector(
  getNotifications,
  notifications => notifications.queue
);

export const getIsArrived = createSelector(
  getNotifications,
  notifications => notifications.isArrived
);
