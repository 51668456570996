import React from 'react';

export const NoMessagesIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_66447_415171)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.7668 0.528264C103.944 0.0692572 127.559 5.11046 139.859 22.2949C151.349 38.3461 138.827 59.4717 139.069 79.1863C139.317 99.4013 154.04 121.775 141.272 137.486C128.289 153.463 103.185 151.409 82.7668 148.469C65.3536 145.961 52.2869 134.297 39.0333 122.766C24.2633 109.916 5.29572 98.605 3.13143 79.1863C0.832731 58.5616 13.3152 39.3256 28.1054 24.717C42.8247 10.1783 62.0466 0.977364 82.7668 0.528264Z"
        fill="#EBF8F8"
      />
      <path
        d="M84.2931 25.8447C69.1533 25.8447 55.8248 32.6586 48.0514 42.9873L101.361 99.1632C116.862 93.3317 127.724 79.7997 127.724 64.0356C127.724 42.9433 108.279 25.8447 84.2931 25.8447Z"
        fill="url(#paint0_linear_66447_415171)"
      />
      <path
        d="M108.65 77.9992C108.65 56.9352 89.2317 39.8594 65.2774 39.8594C41.3233 39.8594 21.9047 56.9351 21.9047 77.9992C21.9047 94.9021 34.4103 109.235 51.7283 114.239L49.1246 122.218C48.9146 122.861 49.5243 123.471 50.1682 123.261L74.8643 115.202C94.2052 111.365 108.65 96.1662 108.65 77.9992Z"
        fill="#CEF4F4"
      />
      <path
        d="M65.2784 83.613C68.2404 83.613 70.6416 81.2119 70.6416 78.2499C70.6416 75.2879 68.2404 72.8867 65.2784 72.8867C62.3164 72.8867 59.9153 75.2879 59.9153 78.2499C59.9153 81.2119 62.3164 83.613 65.2784 83.613Z"
        fill="#36B7BD"
      />
      <path
        d="M43.4717 83.613C46.4337 83.613 48.8348 81.2119 48.8348 78.2499C48.8348 75.2879 46.4337 72.8867 43.4717 72.8867C40.5097 72.8867 38.1086 75.2879 38.1086 78.2499C38.1086 81.2119 40.5097 83.613 43.4717 83.613Z"
        fill="#36B7BD"
      />
      <path
        d="M87.084 83.613C90.046 83.613 92.4471 81.2119 92.4471 78.2499C92.4471 75.2879 90.046 72.8867 87.084 72.8867C84.122 72.8867 81.7208 75.2879 81.7208 78.2499C81.7208 81.2119 84.122 83.613 87.084 83.613Z"
        fill="#16969C"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M76.9621 41.2598C91.4263 47.4431 101.397 60.487 101.397 75.5798C101.397 93.7468 86.9514 108.945 67.6106 112.783L50.367 118.41L49.1249 122.216C48.9149 122.86 49.5246 123.47 50.1684 123.26L74.8646 115.201C94.2056 111.364 108.651 96.1652 108.651 77.9981C108.651 60.4939 95.2404 45.7456 76.9621 41.2598Z"
          fill="#CEF4F4"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_66447_415171"
        x1="127.594"
        y1="54.1012"
        x2="56.4658"
        y2="37.2247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <clipPath id="clip0_66447_415171">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NoMessagesIcon;
