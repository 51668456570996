import React from 'react';
import { Alert, Select } from 'antd';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';

import {
  // COMPLETE_CLOSE_ORDER,
  COMPLETE_COMMENT,
  COMPLETE_DOCUMENT,
  COMPLETE_KINDS,
  COMPLETE_LINK,
  COMPLETE_MEDIA
} from 'constants/index';

export const CompleteRequireSelect = ({ onChange, value, ...selectProps }) => {
  const { t } = useTranslation('AddTask');

  const alertTextByValue = {
    [COMPLETE_DOCUMENT]: t('TaskResultDocumentWarning'),
    // [COMPLETE_CLOSE_ORDER]: t('TaskResultOrderClosureWarning'),
    [COMPLETE_COMMENT]: t('TaskResultCommentWarning'),
    [COMPLETE_MEDIA]: t('TaskResultMediafileWarning'),
    [COMPLETE_LINK]: t('TaskResultLinkWarning')
  };

  return (
    <>
      <Select
        onChange={onChange}
        placeholder={t('TaskResultDesc')}
        data-qa="qa-a4qok4vv8xmi7mp"
        value={value}
        {...selectProps}
      >
        {COMPLETE_KINDS.map(kind => (
          <Select.Option value={kind.value} key={kind.key}>
            <Translation ns={kind.ns}>
              {translate => translate(kind.label)}
            </Translation>
          </Select.Option>
        ))}
      </Select>

      {value && (
        <Alert
          type="warning"
          style={{ marginTop: 16 }}
          message={alertTextByValue[value]}
        />
      )}
    </>
  );
};

CompleteRequireSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};

CompleteRequireSelect.defaultProps = {
  value: undefined,
  onChange: () => {}
};

export default CompleteRequireSelect;
