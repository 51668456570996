import React from 'react';
import { Typography, Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';

import { TASK_STATUS, STATUS_DONE, RESPONSIBLE, AUTHOR } from 'constants/index';

import { UserCard } from 'components/common/user-card';
import { IconId } from 'components/tasks-view/components';
import EventStatus from 'components/common/event-status';
import Avatar from 'components/common/avatar';

import getFIO from 'utils/get-fio';
import getUserPosition from 'utils/get-user-position';
import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';
import { getIsTaskOutdated } from 'utils/get-is-outdated';
import hasDiffFromCurrentDate from 'utils/has-diff-from-current-date';

import styles from './subtask-card.module.scss';

const { Paragraph } = Typography;

export const SubtaskCard = ({
  className,
  style,
  id,
  kind,
  title,
  responsible,
  author,
  dateEnd,
  completedAt,
  createdAt,
  status
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const link = `${routes.toTasks()}${modals.tasks.showDetails({ id })}`;

  const onClickUser = userId => modals.team.goToEmployee(userId);

  const isStatusDone = status === STATUS_DONE;
  const isOutdated = getIsTaskOutdated({ dateEnd, status, completedAt });

  const isNew = hasDiffFromCurrentDate(createdAt);

  const user = responsible || author;

  const getTooltip = role => {
    const position = getUserPosition(role);

    return (
      <UserCard
        {...user}
        position={
          <Translation ns={position.ns}>{t => t(position.label)}</Translation>
        }
        avatar={(user.avatarFile || {}).url}
        onClick={() => onClickUser(user.id)}
        className={styles.tooltipContent}
        data-qa="qa-7gxqvl4oiu9y4yb"
      />
    );
  };

  return (
    <Link
      className={classnames(styles.root, className, {
        [styles.outdated]: isOutdated,
        [styles.isNew]: isNew
      })}
      data-qa="qa-i01kskn3ozvo0uq"
      style={style}
      to={link}
    >
      <div
        className={classnames(styles.flexWrap, styles.flexOferflow)}
        data-qa="qa-dlpswlhhs8sbbhc"
      >
        <IconId object={{ id, kind }} type="link" />

        <Tooltip title={title} mouseEnterDelay={0.5}>
          <Paragraph
            ellipsis={{ rows: 1 }}
            style={{ marginBottom: 0 }}
            delete={isStatusDone}
            className={styles.title}
            data-qa="qa-25bolowpx6wv6wy"
          >
            {title}
          </Paragraph>
        </Tooltip>
      </div>

      <div className={styles.flexWrap} data-qa="qa-ink4luocgbb21ap">
        <EventStatus
          bordered
          statuses={TASK_STATUS}
          activeStatus={status}
          style={{
            marginRight: 8
          }}
          data-qa="qa-j393jzvbbp4o182"
        />
        <Tooltip
          placement="top"
          title={getTooltip(responsible ? RESPONSIBLE : AUTHOR)}
          overlayClassName={styles.tooltip}
          data-qa="qa-nrk53cb1g24ns8t"
        >
          <Avatar
            src={(user.avatarFile || {}).url}
            size={25}
            isActive={user.isActive}
            className={styles.avatar}
          >
            {getFIO(user)}
          </Avatar>
        </Tooltip>
      </div>
    </Link>
  );
};

SubtaskCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.number.isRequired,
  kind: PropTypes.string,
  title: PropTypes.string.isRequired,
  responsible: PropTypes.object,
  dateEnd: PropTypes.any,
  createdAt: PropTypes.string,
  status: PropTypes.string
};

SubtaskCard.defaultProps = {
  className: undefined,
  style: {},
  kind: undefined,
  responsible: undefined,
  dateEnd: undefined,
  createdAt: undefined,
  status: undefined
};

export default SubtaskCard;
