import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';

import Attachments from 'components/common/attachments';
import Table from 'components/common/attachments-control/shared/table';
import Upload from 'components/common/attachments-control/shared/upload';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateSubscribeNewAttachment } from 'components/common/subscriptions/utils/update-subscribe-new-attachment';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';
import { updateNewAttachmentValidityDate } from 'components/common/validity-date/utils/update-new-attachment-validity-date';

export const UPLOAD = 'upload';
export const TABLE = 'table';

const TABS = [
  {
    key: TABLE,
    title: 'MyDriveTab',
    ns: 'UploadFile',
    Component: Table
  },
  {
    key: UPLOAD,
    title: 'UploadTab',
    ns: 'UploadFile',
    Component: Upload
  }
];

export const AttachmentsTabs = ({
  value,
  taskId,
  statusId,
  onChange,
  selectTabCallback,
  isChangeAfterSelect, // if table view: call onChange after select, hide attachments view
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(TABS[0].key);

  const { t } = useTranslation('UploadFile');

  const showAttachments =
    activeTab === TABLE ? value && !isChangeAfterSelect : value;

  const onAttach = (newFiles, replaceValue) => {
    const files = replaceValue
      ? newFiles
      : union([...value, ...newFiles], 'uid');

    onChange(uniqBy(files, i => i.id || i.uid));
  };

  const onDelete = file =>
    onChange(
      value.filter(({ id, uid }) => (uid ? uid !== file.uid : id !== file.id))
    );
  const onRenameFile = ({ id, name }) => {
    const newFileList = value.reduce((acc, curr) => {
      if (curr.response && curr.response.id === id) {
        return [...acc, { ...curr, name }];
      }

      return [...acc, curr];
    }, []);

    onChange(newFileList);
  };

  const handleSubscription = ({ data, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments: value,
      setAttachments: onChange,
      data,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: value,
      setAttachments: onChange,
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments: value,
      setAttachments: onChange,
      data: attachment
    });
  };

  const onSubscribeToNewAttachment = ({ file, isSubscribed }) =>
    onChange(
      updateSubscribeNewAttachment({ file, fileList: value, isSubscribed })
    );

  const onSetValidityDateToNewAttachment = ({ attachment }) =>
    onChange(
      updateNewAttachmentValidityDate({ data: attachment, fileList: value })
    );

  useEffect(() => {
    if (selectTabCallback) {
      selectTabCallback(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
        {TABS.map(tab => (
          <Tabs.TabPane tab={t(tab.title, { ns: tab.ns })} key={tab.key}>
            <tab.Component
              fileList={value}
              onChange={onAttach}
              uploadFileServer
              multiple
              isChangeAfterSelect
              {...props}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>

      {showAttachments && (
        <div style={{ marginTop: 8 }}>
          <Attachments
            fileList={value}
            attachmentProps={{
              fileLinkTarget: '_blank',
              onDelete,
              onRename: onRenameFile,
              onSubscribeToNewAttachment,
              onSetValidityDateToNewAttachment
            }}
            actionsDeps={{ taskId, statusId }}
            subscribeCallback={handleSubscription}
            unsubscribeCallback={handleSubscription}
            changeManageSubscribersCallback={
              handleAfterChangeManageSubscription
            }
            changeValidityDateCallback={handleAfterChangeValidityDateCallback}
          />
        </div>
      )}
    </>
  );
};

export default AttachmentsTabs;
