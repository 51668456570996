import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/common/icon';
import { isAfterCurrentDateTime } from 'components/common/validity-date/utils/formated-date';

import FileTitle from '../../../file-title';

import styles from './title.module.scss';

const Title = ({ file, isTrash, fileLinkTarget, actionsDeps }) => {
  const { validityDate } = file;

  const isValidityDateOutdated =
    !!validityDate && !isAfterCurrentDateTime(validityDate);

  return (
    <div className={styles.root}>
      <div className={styles.iconWrap}>
        <Icon
          type="attachment"
          size={20}
          style={{ marginRight: 12 }}
          color="brand"
        />

        {isValidityDateOutdated && (
          <Icon
            type="outdated"
            size={14}
            color="yellow-semi"
            className={styles.outdatedIcon}
          />
        )}
      </div>

      <FileTitle
        isDisabled={isTrash}
        target={fileLinkTarget}
        file={file}
        actionsDeps={actionsDeps}
      />
    </div>
  );
};

Title.propTypes = {
  file: PropTypes.shape({
    validityDate: PropTypes.string
  }).isRequired,
  isTrash: PropTypes.bool,
  fileLinkTarget: PropTypes.string,
  actionsDeps: PropTypes.object
};

Title.defaultProps = {
  isTrash: false,
  fileLinkTarget: '',
  actionsDeps: {}
};

export default Title;
