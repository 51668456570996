import React from 'react';
// eslint-disable-next-line no-restricted-syntax
import { Modal, notification } from 'antd';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-named-default
import { default as apiV1 } from 'api';
import { LANGUAGE } from 'i18n-config';
import { Translation } from 'react-i18next';

import REFRESH_AUTH_TOKEN_DELAY, { REDIRECT } from 'constants/index';
import { TOKEN_FILES } from 'constants/api';

import configNoInternetConnection from 'components/common/modals/no-internet-connection';
import Button from 'components/common/button';

import { OLD_REQUEST_CANCELED } from 'utils/make-cancalable-request';
import getSupportLink from 'utils/get-support-link';
import sendDataToDomain, {
  TYPE_APP_AUTH_TOKEN_TRANSFER
} from 'utils/send-data-to-domain';
import { reconnectSockets } from 'socket';

notification.config({
  duration: 0
});

const err = notification.error;

const serverError = () => {
  const supportLink = getSupportLink();

  return err({
    message: (
      <Translation ns="ScreenErrors" useSuspense="false">
        {t => t('ServerErrorHeading')}
      </Translation>
    ),
    description: (
      <>
        <Translation ns="ScreenErrors" useSuspense="false">
          {t => t('ServerErrorDesc')}
        </Translation>{' '}
        <Button
          type="link"
          href={supportLink.href}
          target={supportLink.target}
          style={{
            padding: 0,
            lineHeight: 1,
            height: 'auto',
            fontWeight: 400
          }}
        >
          <Translation ns="ScreenErrors" useSuspense="false">
            {t => t('ServerErrorSupportBtn')}
          </Translation>
        </Button>
      </>
    )
  });
};

export const errorResponseHandler = (error, api, handle400) => {
  // cancel same request
  if (error.message === OLD_REQUEST_CANCELED) {
    // eslint-disable-next-line
    throw { message: 'Old request canceled', type: OLD_REQUEST_CANCELED };
  }

  // no internet connection
  if (!navigator.onLine && !error.config.retryCount) {
    Modal.destroyAll();

    Modal.info({
      ...configNoInternetConnection,
      content: <configNoInternetConnection.content />
    });

    return Promise.reject(error);
  }

  // retry request (retryCount set in axios create function)
  if (
    !!error.config.retryCount &&
    (!error.response || error.response.status > 500)
  ) {
    const delayRetryRequest = new Promise(resolve => {
      setTimeout(() => resolve(), error.config.retryDelay || 1000);
    });

    error.config.retryCount -= 1;
    return delayRetryRequest.then(() => api(error.config));
  }

  // disable error handling
  if (
    error.config.hasOwnProperty('errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  // handle response errors
  if (error.response) {
    const { response } = error;

    // handle >= 500
    if (response.status >= 500) {
      if (response.status === 503) {
        window.location = `/engineering-works/?${REDIRECT}=${encodeURIComponent(
          window.location.href
        )}`;

        throw error;
      }

      serverError();

      throw error;
    }

    // unauthorized
    if (response.status === 401) {
      localStorage.clear();
      window.location = `/auth/login/?${REDIRECT}=${encodeURIComponent(
        window.location.href
      )}`;

      throw error;
    }

    if (response.status === 428 && error.config.hide428) {
      return Promise.reject(error);
    }

    if (
      response.status >= 400 &&
      (error.config.show400 === undefined || error.config.show400 === true)
    ) {
      handle400();
    }

    throw error;
  }

  // request without response or CORS
  serverError();

  throw error;
};

export const requestInterception = async configuration => {
  let token = localStorage.getItem('token');
  let refreshToken = localStorage.getItem('refresh');
  const language = localStorage.getItem(LANGUAGE);

  // ignore if it is refresh token request
  if (token != null) {
    if (!configuration.url.endsWith('jwt/refresh/')) {
      try {
        const decoded = jwtDecode(token);

        const timestamp = +new Date() / 1000;

        if (decoded.exp - timestamp <= REFRESH_AUTH_TOKEN_DELAY) {
          const { data } = await apiV1.user.token.refresh(refreshToken);

          token = data.access;
          localStorage.setItem('token', token);

          if (data.refresh) {
            refreshToken = data.refresh;
            localStorage.setItem('refresh', refreshToken);
          }

          sendDataToDomain({
            data: { token },
            type: TYPE_APP_AUTH_TOKEN_TRANSFER
          });
          reconnectSockets();
        }
      } catch {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        localStorage.removeItem(TOKEN_FILES);
        window.location = '/';
      }
    }
    // eslint-disable-next-line
    configuration.headers.authorization = `JWT ${token}`;
  }

  if (language) {
    configuration.headers['Accept-Language'] = language;
  }

  return configuration;
};
