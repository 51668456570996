import React from 'react';

export const TaskDeletedIcon = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M73.4887 16.4839C75.2517 16.8171 76.9869 17.2336 78.7221 17.65C87.1622 19.9127 96.0187 21.759 104.445 23.7024L105.791 24.0356C111.511 25.3266 117.008 26.6592 122.061 28.2001C123.865 28.7415 125.587 29.269 127.28 29.8798C144.257 35.7517 154.238 44.3167 145.382 61.3773C144.618 62.8348 144.146 64.3479 143.841 66.0137C142.661 72.9823 144.785 81.8805 145.993 90.7647C146.298 92.8886 146.506 94.9848 146.59 97.1503C146.701 99.6351 146.715 102.092 146.465 104.383C146.104 108.381 144.965 112.198 142.869 115.78C142.869 115.78 140.676 119.333 136.983 122.318C132.541 125.844 81.9149 158.299 38.3681 130.522C36.6051 129.37 -6.05322 101.315 2.58117 62.5156C10.9657 24.688 59.6904 13.9436 73.4887 16.4839Z"
      fill="#EBF8F8"
    />
    <path
      d="M107.888 64.6949L101.96 123.609L101.905 124.122C101.46 127.218 98.4203 130.008 93.6867 132.132C92.6178 132.618 91.4656 133.062 90.2302 133.465C87.9952 134.214 85.5243 134.825 82.8451 135.269C79.2636 135.894 75.3351 136.227 71.2262 136.227C54.943 136.227 41.6304 130.924 40.5477 124.206L40.4783 123.581L34.5508 64.6949H107.902H107.888Z"
      fill="#C4C4C4"
    />
    <path
      d="M71.3373 85.2399H71.2679C68.5055 85.2399 66.2705 87.4748 66.2705 90.2373V121.152C66.2705 123.914 68.5055 126.149 71.2679 126.149H71.3373C74.0998 126.149 76.3347 123.914 76.3347 121.152V90.2373C76.3347 87.4748 74.0998 85.2399 71.3373 85.2399Z"
      fill="#E2E2E2"
    />
    <path
      d="M92.1317 85.3093H92.0623C89.3137 85.0872 86.8983 87.1417 86.6762 89.8902L84.2469 120.708C84.0248 123.456 86.0793 125.872 88.8417 126.094H88.9111C91.6597 126.316 94.0751 124.261 94.2972 121.513L96.7404 90.6954C96.9625 87.9468 94.908 85.5314 92.1456 85.3093H92.1317Z"
      fill="#E2E2E2"
    />
    <path
      d="M53.7354 126.205H53.8048C56.5534 125.982 58.6079 123.567 58.3996 120.819L55.9565 90.0012C55.7344 87.2526 53.3328 85.1981 50.5704 85.4064H50.501C47.7524 85.6285 45.6979 88.0439 45.9061 90.7925L48.3493 121.61C48.5714 124.358 50.9729 126.413 53.7354 126.205Z"
      fill="#E2E2E2"
    />
    <path
      d="M87.8009 12.9996L90.1469 21.5507C90.8826 24.2577 74.4606 31.1152 53.5687 36.8483C32.6768 42.5815 15.0471 45.0663 14.2975 42.3594L11.9515 33.8083C11.188 31.0319 27.5406 24.1883 48.5158 18.4413C69.491 12.6942 87.0374 10.2233 87.8009 12.9996Z"
      fill="#C4C4C4"
    />
    <path
      d="M51.0424 27.6448C70.5739 22.2864 85.9131 16.1369 85.3023 13.9297C84.6916 11.7225 68.3667 14.249 48.8352 19.6073C29.3037 24.9656 13.9644 31.1152 14.5752 33.3224C15.186 35.5296 31.5109 33.0031 51.0424 27.6448Z"
      fill="#E2E2E2"
    />
    <path
      d="M61.6059 22.9389L39.6867 28.9497C38.3402 29.3245 36.9104 29.144 35.6888 28.4638C34.4672 27.7836 33.5788 26.6453 33.1901 25.3127L32.0657 21.2176C31.7187 19.8711 31.913 18.4413 32.6071 17.2336C33.3012 16.0259 34.4534 15.1513 35.7999 14.7765L57.719 8.75186C59.0517 8.39094 60.4815 8.55752 61.6892 9.2516C62.8969 9.9318 63.7714 11.0701 64.1462 12.4166L65.2151 16.2896C65.4372 16.9559 65.5066 17.6639 65.4372 18.3719C65.3678 19.0798 65.1596 19.76 64.8264 20.3847C64.4933 21.0094 64.0352 21.5508 63.4799 21.995C62.9247 22.4392 62.2861 22.7585 61.6059 22.9389ZM36.5634 17.5528C35.9109 17.7333 35.3418 18.1636 35.0086 18.7605C34.6755 19.3575 34.5783 20.0515 34.7587 20.7179L35.8276 24.5908C36.0081 25.2433 36.4384 25.8124 37.0353 26.1456C37.6323 26.4788 38.3263 26.5759 38.9927 26.3955L60.9118 20.3847C61.5642 20.2042 62.1334 19.7739 62.4666 19.177C62.7997 18.5801 62.8969 17.886 62.7164 17.2197L61.592 13.1246C61.4116 12.4721 60.9812 11.903 60.3843 11.5698C59.7874 11.2367 59.0933 11.1395 58.427 11.32L36.5634 17.5528Z"
      fill="#E2E2E2"
    />
    <path
      d="M71.2262 81.6583C92.8384 81.6583 110.359 73.4421 110.359 63.3068C110.359 53.1715 92.8384 44.9552 71.2262 44.9552C49.6139 44.9552 32.0938 53.1715 32.0938 63.3068C32.0938 73.4421 49.6139 81.6583 71.2262 81.6583Z"
      fill="#E2E2E2"
    />
    <path
      d="M105.041 59.1561C105.041 62.9181 101.585 66.3191 95.9487 68.8456C94.7688 69.3731 93.5056 69.8589 92.1452 70.317C89.6881 71.1221 86.9673 71.8023 84.0244 72.3021C80.082 72.9823 75.7648 73.3571 71.2532 73.3571C52.5824 73.3571 37.4375 66.9993 37.4375 59.1561C37.4375 51.313 52.5824 44.9552 71.2532 44.9552C89.9241 44.9552 105.069 51.313 105.069 59.1561H105.041Z"
      fill="#C4C4C4"
    />
    <path
      d="M125.24 26.4788L57.2612 39.5414C53.4854 40.2633 51.0006 43.9419 51.7224 47.7177L54.8597 64.0287C55.5815 67.8045 59.274 70.3032 63.036 69.5675L131.014 56.5048C134.79 55.783 137.275 52.1043 136.553 48.3285L133.416 32.0175C132.694 28.2417 129.016 25.7569 125.24 26.4788Z"
      fill="white"
    />
    <path
      d="M105.624 50.3136L88.0642 53.6869C87.3701 53.8257 86.6899 53.3676 86.565 52.6735C86.44 51.9794 86.8842 51.2992 87.5783 51.1743L105.139 47.8011C105.833 47.6622 106.513 48.1203 106.638 48.8144C106.763 49.5085 106.319 50.1887 105.624 50.3136Z"
      fill="#2D9EA3"
    />
    <path
      d="M121.589 40.888L86.8845 47.5651C86.1904 47.7039 85.5102 47.2458 85.3853 46.5517C85.2603 45.8576 85.7045 45.1774 86.3986 45.0525L121.103 38.3754C121.797 38.2366 122.477 38.6947 122.602 39.3888C122.727 40.0828 122.283 40.763 121.589 40.888Z"
      fill="#2D9EA3"
    />
    <path
      d="M119.215 47.7039L112.718 48.9532C112.024 49.0921 111.344 48.634 111.219 47.9399C111.094 47.2458 111.539 46.5656 112.233 46.4407L118.729 45.1913C119.423 45.0525 120.104 45.5106 120.228 46.2047C120.353 46.8988 119.909 47.579 119.215 47.7039Z"
      fill="white"
    />
    <path
      d="M76.8345 51.3685C77.7368 56.0467 74.669 60.5721 69.9908 61.4744C65.3127 62.3767 60.7873 59.3089 59.885 54.6307C58.9827 49.9526 62.0505 45.4272 66.7287 44.5249C71.4068 43.6226 75.9322 46.6904 76.8345 51.3685Z"
      fill="url(#paint0_linear_76443_436937)"
    />
    <path
      d="M68.3107 57.3238C67.9776 57.3932 67.6305 57.3238 67.339 57.1295L64.41 55.1305C63.8269 54.728 63.6742 53.9367 64.0768 53.3537C64.4794 52.7707 65.2706 52.618 65.8537 53.0205L67.6722 54.2699L70.2264 50.1331C70.6012 49.5362 71.3924 49.3419 71.9894 49.7167C72.5863 50.0915 72.7806 50.8827 72.4058 51.4797L69.1436 56.7547C68.9632 57.0462 68.6578 57.2683 68.3246 57.3377H68.2968L68.3107 57.3238Z"
      fill="white"
    />
    <path
      d="M25.3055 83.0881C24.7503 83.6434 23.8341 83.6434 23.2788 83.0881L12.354 72.1633C11.7987 71.608 11.7987 70.6918 12.354 70.1365C12.9092 69.5813 13.8254 69.5813 14.3807 70.1365L25.3055 81.0614C25.8608 81.6167 25.8608 82.5329 25.3055 83.0881Z"
      fill="#F9716C"
    />
    <path
      d="M25.3055 72.1633L14.3807 83.0881C13.8254 83.6434 12.9092 83.6434 12.354 83.0881C11.7987 82.5329 11.7987 81.6167 12.354 81.0614L23.2788 70.1365C23.8341 69.5813 24.7503 69.5813 25.3055 70.1365C25.8608 70.6918 25.8608 71.608 25.3055 72.1633Z"
      fill="#F9716C"
    />
    <path
      d="M135.776 107.187C135.345 107.617 134.665 107.617 134.235 107.187L125.961 98.9133C125.531 98.483 125.531 97.8028 125.961 97.3724C126.392 96.9421 127.072 96.9421 127.502 97.3724L135.776 105.646C136.206 106.076 136.206 106.756 135.776 107.187Z"
      fill="#F9716C"
    />
    <path
      d="M135.776 98.9133L127.502 107.187C127.072 107.617 126.392 107.617 125.961 107.187C125.531 106.756 125.531 106.076 125.961 105.646L134.235 97.3724C134.665 96.9421 135.345 96.9421 135.776 97.3724C136.206 97.8028 136.206 98.483 135.776 98.9133Z"
      fill="#F9716C"
    />
    <path
      d="M130.681 73.8291C130.432 74.079 130.043 74.079 129.793 73.8291L125.004 69.0399C124.754 68.7901 124.754 68.4014 125.004 68.1515C125.254 67.9016 125.642 67.9016 125.892 68.1515L130.681 72.9407C130.931 73.1906 130.931 73.5792 130.681 73.8291Z"
      fill="#F9716C"
    />
    <path
      d="M130.681 69.0539L125.892 73.843C125.642 74.0929 125.254 74.0929 125.004 73.843C124.754 73.5932 124.754 73.2045 125.004 72.9546L129.793 68.1654C130.043 67.9156 130.432 67.9156 130.681 68.1654C130.931 68.4153 130.931 68.804 130.681 69.0539Z"
      fill="#F9716C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_76443_436937"
        x1="59.8619"
        y1="54.6171"
        x2="76.8339"
        y2="51.355"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);

export default TaskDeletedIcon;
