import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LANGUAGE } from 'i18n-config';

import { WORKSPACE } from 'constants/index';

import { fetchVersion } from 'store/release';

import { UPSERVICE_WIDGET_FIELD } from 'providers';

import packageJson from '../../../package.json';
import useLocalStorage from './use-local-storage';
import { clearStorageFilter } from './use-filter-storage';

global.appVersion = packageJson.version;

const APP_VERSION = 'appVersion';

const useCacheBuster = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const [localVersion, setLocalVersion] = useLocalStorage(APP_VERSION, '');
  const [lastOpenedWorkspaceId, setLastOpenedWorkspaceId] = useLocalStorage(
    WORKSPACE
  );
  const [language, setLanguage] = useLocalStorage(LANGUAGE);
  const [upserviceWidget, setUpserviceWidget] = useLocalStorage(
    UPSERVICE_WIDGET_FIELD
  );

  const check = async () => {
    if (!localVersion) {
      setIsLoading(false);

      const currentVersion = await dispatch(fetchVersion());

      return setLocalVersion(currentVersion);
    }

    const currentVersion = await dispatch(fetchVersion());

    if (currentVersion !== localVersion) {
      console.log(
        `We have a new version - ${currentVersion}. Should force refresh`
      );

      if (caches) {
        caches.keys().then(async names => {
          await Promise.all(names.map(name => caches.delete(name)));
        });
      }

      clearStorageFilter();

      if (lastOpenedWorkspaceId) {
        setLastOpenedWorkspaceId(lastOpenedWorkspaceId);
      }

      if (language) {
        setLanguage(language);
      }

      if (upserviceWidget) {
        setUpserviceWidget({ ...upserviceWidget, previewMode: false });
      }

      setLocalVersion(currentVersion);

      return window.location.reload(true);
    }

    console.log(
      `You already have the latest version - ${currentVersion}. No cache refresh needed.`
    );

    return setIsLoading(false);
  };

  useEffect(() => {
    if (!window.location.pathname.includes('engineering-works')) {
      check();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading;
};

export default useCacheBuster;

// // version from response - first param, local version second param
// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split(/\./g);
//   const versionsB = versionB.split(/\./g);

//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());
//     const b = Number(versionsB.shift());

//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }

//   return false;
// };

// CLAER CACHE WITH COMPARE PACKAGE.JSON AND META.JSON VERSION
// export const useCacheBuster = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLatestVersion, setIsLatestVersion] = useState(true);

//   const refreshCacheAndReload = () => {
//     console.log('Clearing cache and hard reloading...');

//     // eslint-disable-next-line no-undef
//     if (caches) {
//       // Service worker cache should be cleared with caches.delete()
//       // eslint-disable-next-line no-undef
//       caches.keys().then(async names => {
//         // eslint-disable-next-line no-undef
//         await Promise.all(names.map(name => caches.delete(name)));
//       });
//     }

//     const lastOpenedWorkspaceId = localStorage.getItem(WORKSPACE);
//     localStorage.clear(); // Очищаем полностью сторейдж
//     // При необходимости можно скрывать. (Очистка фильтров)
//     // clearStorageFilter();
//     if (lastOpenedWorkspaceId) {
//       localStorage.setItem(WORKSPACE, lastOpenedWorkspaceId);
//     }

//     // delete browser cache and hard reload
//     window.location.reload(true);
//   };

//   useEffect(() => {
//     // eslint-disable-next-line no-undef
//     fetch('/meta.json', { cache: 'no-store' })
//       .then(response => response.json())
//       .then(meta => {
//         const latestVersion = meta.version;
//         const currentVersion = global.appVersion;

//         const shouldForceRefresh = semverGreaterThan(
//           latestVersion,
//           currentVersion
//         );

//         if (shouldForceRefresh) {
//           console.log(
//             `We have a new version - ${latestVersion}. Should force refresh`
//           );
//           setIsLoading(false);
//           setIsLatestVersion(false);
//         } else {
//           console.log(
//             `You already have the latest version - ${latestVersion}. No cache refresh needed.`
//           );
//           setIsLoading(false);
//           setIsLatestVersion(true);
//         }
//       });
//   }, []);

//   useEffect(() => {
//     if (!isLoading && !isLatestVersion) {
//       refreshCacheAndReload();
//     }
//   }, [isLoading, isLatestVersion]);

//   return [isLoading, isLatestVersion, refreshCacheAndReload];
// };
