import React, { useState } from 'react';
import classNames from 'classnames';
import { UNDO_COMMAND } from 'lexical';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from '../../toolbar.module.scss';

const UndoItem = ({ editor, canUndo }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { t } = useTranslation('CommonChat');

  return (
    <div
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <Tooltip
        title={t('UndoTip')}
        mouseEnterDelay={0.5}
        visible={tooltipVisible}
      >
        <Button
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND);
          }}
          className={classNames(styles.toolbarItem, styles.toolbarSpaced)}
        >
          <Icon
            type="history-undo"
            size={16}
            className={styles.toolbarFormat}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default UndoItem;
