import React from 'react';
import PropTypes from 'prop-types';

import Chat from 'components/common/chat';
import useNotificationMessageChat from 'components/common/chat/entity-hooks/use-notification-message-chat';

import styles from './notification-message-chat.module.scss';

const NotificationMessageChat = ({
  entity,
  entityFromNotification,
  isDisplayControls = true
}) => {
  const {
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore: fetchMessages
  } = useNotificationMessageChat({
    entity,
    entityFromNotification
  });

  return (
    <Chat
      messagesRef={messagesRef}
      isLoading={isLoading}
      hasMessages={hasMessages}
      messages={renderedMessages}
      toBottom={toBottom}
      controls={isDisplayControls ? renderedControls : null}
      isEntityLoading={!isJoined}
      isJoined={isJoined}
      hasMore={hasMore}
      loadMore={fetchMessages}
      infiniteClassName={styles.infiniteChat}
      className={styles.rootChat}
    />
  );
};

NotificationMessageChat.propTypes = {
  entity: PropTypes.object.isRequired,
  entityFromNotification: PropTypes.object.isRequired
};

export default NotificationMessageChat;
