import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import { fetchVersionsAttachment } from 'store/attachments';

import AttachmentVersion from './attachmnet-version';
import useActions from './use-actions';

export const VersionsDrawer = ({ fileId, visible, onClose, ...props }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [versions, setVersions] = useState([]);

  const { getActions, viewFile } = useActions({ onClose, setVersions });

  const { t } = useTranslation(['MyDriveVersionHistory', 'Common']);

  const isEmpty = !versions.length && !isLoading;

  const getAllowRestore = version => {
    if (versions.length <= 1) {
      return false;
    }

    const latestVersion = versions.reduce(
      (acc, curr) => (curr.sequence > acc.sequence ? curr : acc),
      { sequence: 0 }
    );

    return version.sequence !== latestVersion.sequence;
  };

  const fetchVersions = async () => {
    setIsLoading(true);

    try {
      const { results } = await dispatch(fetchVersionsAttachment({ fileId }));

      setVersions(results);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fileId && visible) {
      fetchVersions();
    }

    return () => setVersions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Drawer
      visible={visible}
      width={520}
      title={<Drawer.Title>{t('VersionHistoryHeading')}</Drawer.Title>}
      bodyStyle={{ padding: 0 }}
      onClose={onClose}
      {...props}
    >
      {isLoading && <Spin />}

      {isEmpty && (
        <Typography.Paragraph style={{ padding: 20 }}>
          {t('EmptyVersionHistory', { ns: 'Common' })}
        </Typography.Paragraph>
      )}

      {versions.map(version => (
        <AttachmentVersion
          key={version.id}
          file={version}
          onClick={() =>
            viewFile({
              ...version.attachment,
              versionId: version.id
            })
          }
          actions={getActions({
            versionId: version.id,
            allowRestore: getAllowRestore(version),
            allowDelete: versions.length > 1,
            ...version.attachment
          })}
        />
      ))}
    </Drawer>
  );
};

VersionsDrawer.propTypes = {
  versionId: PropTypes.number
};

VersionsDrawer.defaultProps = {
  versionId: undefined
};

export default VersionsDrawer;
