import React from 'react';
import { Typography, Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { getFullName } from 'utils/get-fio';

import { Rate } from '../rate';
import VerifiedPhone from '../verified-phone';
import UserTooltip from './user-tooltip';
import ContactAvatar from '../avatar/contact';

import styles from './user-card.module.scss';

const { Text } = Typography;

export const UserCard = ({
  children,
  className,
  userClassName,
  bodyClassName,
  firstName,
  lastName,
  middleName,
  label,
  orientation,
  rate,
  companyRate,
  phone,
  email,
  position,
  avatar,
  avatarSize,
  fullWidth,
  onClick,
  onClickCompany,
  showFullName,
  showUserRate,
  showCompanyRate,
  showTooltip,
  showPhone,
  showEmail,
  showAvatar,
  showIsPhoneVerified,
  lineStyleContent,
  isOffline,
  ellipsisPosition,
  showWorkspaceTitile,
  workspaceTitle,
  userId
}) => {
  const fullName = getFullName({
    firstName,
    lastName,
    middleName,
    allName: showFullName
  });

  const hasOnClick = typeof onClick === 'function';
  const hasOnClickCompany = typeof onClickCompany === 'function';

  const onClickUser = () => hasOnClick && onClick();

  return (
    <div
      className={classnames(styles.root, styles[orientation], className, {
        [styles.fullWidth]: fullWidth,
        [styles.line]: lineStyleContent
      })}
    >
      <div className={classnames(styles.body, bodyClassName)}>
        {showAvatar && (
          <Button
            type="link"
            onClick={onClickUser}
            className={classnames(styles.link, {
              [styles.unlink]: !hasOnClick
            })}
          >
            <div className={styles.avatar}>
              <VerifiedPhone
                isVerified={showIsPhoneVerified}
                badgeProps={{
                  offset: [-5, 32]
                }}
              >
                <ContactAvatar
                  size={avatarSize}
                  contact={{
                    id: userId,
                    firstName,
                    lastName,
                    employee: !isOffline,
                    avatarFile: { url: avatar }
                  }}
                  style={{ marginRight: 8 }}
                />
              </VerifiedPhone>
            </div>
          </Button>
        )}

        <div className={styles.content}>
          {label && <Text className={styles.label}>{label}</Text>}

          {!!fullName.length && (
            <UserTooltip showTooltip={showTooltip} phone={phone} email={email}>
              <Button
                className={classnames(userClassName, styles.name, styles.link, {
                  [styles.unlink]: !hasOnClick
                })}
                type="link"
                onClick={onClickUser}
              >
                <span> {fullName}</span>
                {showUserRate && <Rate className={styles.rate} value={rate} />}
              </Button>
            </UserTooltip>
          )}

          {position && (
            <Button
              className={classnames(styles.position, styles.link, {
                [styles.unlink]: !hasOnClickCompany
              })}
              type="link"
              onClick={onClickCompany}
            >
              <Text
                style={{ marginRight: 5 }}
                className={classnames({
                  [styles.ellipsis]: ellipsisPosition
                })}
              >
                {position}
              </Text>
              {showCompanyRate && (
                <Rate className={styles.rate} value={companyRate} />
              )}
            </Button>
          )}

          {showPhone && <Text className={styles.phone}>{phone}</Text>}
          {showEmail && <Text className={styles.email}>{email}</Text>}
          {showWorkspaceTitile && (
            <Text className={styles.phone}>{workspaceTitle}</Text>
          )}
        </div>
      </div>

      {children}
    </div>
  );
};

UserCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  userClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  label: PropTypes.string,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  rate: PropTypes.number,
  companyRate: PropTypes.number,
  phone: PropTypes.string,
  email: PropTypes.string,
  position: PropTypes.any,
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  fullWidth: PropTypes.bool,
  badgeProps: PropTypes.shape({}),
  onClick: PropTypes.func,
  onClickCompany: PropTypes.func,
  showFullName: PropTypes.bool,
  showUserRate: PropTypes.bool,
  showCompanyRate: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showPhone: PropTypes.bool,
  showAvatar: PropTypes.bool,
  showIsPhoneVerified: PropTypes.bool,
  isOffline: PropTypes.bool,
  lineStyleContent: PropTypes.bool,
  ellipsisPosition: PropTypes.bool,
  showWorkspaceTitile: PropTypes.bool,
  workspaceTitle: PropTypes.string,
  userId: PropTypes.number
};

UserCard.defaultProps = {
  children: undefined,
  className: undefined,
  userClassName: undefined,
  bodyClassName: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  label: undefined,
  rate: undefined,
  companyRate: undefined,
  phone: undefined,
  email: undefined,
  position: undefined,
  orientation: 'horizontal',
  avatar: undefined,
  fullWidth: false,
  avatarSize: 38,
  showFullName: false,
  badgeProps: {},
  onClick: undefined,
  onClickCompany: undefined,
  showPhone: false,
  showTooltip: false,
  showUserRate: false,
  showCompanyRate: false,
  showAvatar: true,
  showIsPhoneVerified: false,
  isOffline: false,
  lineStyleContent: false,
  ellipsisPosition: false,
  showWorkspaceTitile: false,
  workspaceTitle: null,
  userId: null
};

export default UserCard;
