import { HIRING, INCOMING, LEADS } from 'constants/index';

export const transformContactorDefaultValue = ({ contractor, kind }) => {
  if (!contractor) {
    return null;
  }

  const contractorOption = {
    label: {
      ...contractor,
      workspaceTitle:
        (contractor || {}).workspaceTitle || (contractor.company || {}).title
    },
    value: contractor.id
  };

  return kind === INCOMING ? [contractorOption] : contractorOption;
};

export const handleFunnelTypeCallback = ({
  funnelType,
  contractorField,
  methods
}) => {
  const isIncomingFunnelType = funnelType.value === INCOMING;

  if (
    isIncomingFunnelType &&
    contractorField &&
    !Array.isArray(contractorField)
  ) {
    methods.setValue('contractor', [contractorField]);
  }

  if (!isIncomingFunnelType && Array.isArray(contractorField)) {
    methods.setValue(
      'contractor',
      contractorField.length ? contractorField[0] : null
    );
  }
};

export const FieldNames = {
  FUNNEL_TYPE: 'funnelTypeField',
  SERVICE_NAME: 'serviceNameField',
  DOCUMENT_LIST: 'documentListField',
  ATTACHMENTS_ALERT: 'attachmentsAlert',
  SIGNER: 'signerField',
  CONTRACTOR: 'contractorField',
  HIRING: 'hiringFields',
  DATE: 'dateFields',
  ADD_LINK: 'addLinkButton',
  RELATIONS: 'relationsField'
};

export const getShortFormFields = ({
  isCreate,
  isCopy,
  isEdit,
  defaultValues,
  isHiring,
  relationsVisible,
  withContractor
}) => {
  const fields = [];

  if (isCreate || isCopy) {
    fields.push(FieldNames.FUNNEL_TYPE);
  }

  fields.push(FieldNames.SERVICE_NAME);
  fields.push(FieldNames.DOCUMENT_LIST);

  if (isCreate || isCopy) {
    fields.push(FieldNames.ATTACHMENTS_ALERT);
  }

  if (
    isEdit &&
    defaultValues.kind === HIRING &&
    defaultValues.state !== LEADS
  ) {
    fields.push(FieldNames.SIGNER);
  }

  if ((withContractor && isCreate) || (isCopy && defaultValues.contractor)) {
    fields.push(FieldNames.CONTRACTOR);
  }

  if (isHiring && !isEdit) {
    fields.push(FieldNames.HIRING);
  }

  fields.push(FieldNames.DATE);

  if (!isEdit && !relationsVisible) {
    fields.push(FieldNames.ADD_LINK);
  }

  if (relationsVisible) {
    fields.push(FieldNames.RELATIONS);
  }

  return fields;
};
