import React from 'react';

export const PlusCircleIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3123 11.2511H12.7498V7.6886C12.7498 7.58547 12.6654 7.5011 12.5623 7.5011H11.4373C11.3342 7.5011 11.2498 7.58547 11.2498 7.6886V11.2511H7.68732C7.58419 11.2511 7.49982 11.3355 7.49982 11.4386V12.5636C7.49982 12.6667 7.58419 12.7511 7.68732 12.7511H11.2498V16.3136C11.2498 16.4167 11.3342 16.5011 11.4373 16.5011H12.5623C12.6654 16.5011 12.7498 16.4167 12.7498 16.3136V12.7511H16.3123C16.4154 12.7511 16.4998 12.6667 16.4998 12.5636V11.4386C16.4998 11.3355 16.4154 11.2511 16.3123 11.2511Z"
      fill="currentColor"
    />
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusCircleIcon;
