import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_RELATIONS, TYPE_TAGS } from 'constants/index';

import {
  FormCurrencySelect,
  FormInput,
  FormInputNumber,
  FormLocationInput,
  FormRelationsSelect,
  FormTagsSelect
} from 'components/common/hook-form';
import Typography from 'components/common/typography';

import DeletableField from './components/deletable-field';

import styles from './main.module.scss';

const TYPE_LOCATION = 'location';
const TYPE_PRICE = 'price';

const useAdditionalFields = ({ defaultValues, isEditor, isTemplateEditor }) => {
  const [activeFields, setActiveFields] = useState([]);

  const { t } = useTranslation('AddOrder');

  const onDeleteField = type =>
    setActiveFields(prev => prev.filter(f => f.type !== type));

  const onAddField = type =>
    setActiveFields(prev => [...prev, allFields.find(f => f.type === type)]);

  const allFields = [
    {
      type: TYPE_LOCATION,
      title: 'OrderAddressField',
      ns: 'AddOrder',
      icon: 'environment',
      allow: true,
      onClick: () => onAddField(TYPE_LOCATION),
      component: (
        <DeletableField
          className={styles.location}
          onClick={() => onDeleteField(TYPE_LOCATION)}
        >
          <Typography.Title level={3}>
            {t('OrderAddressField')}
          </Typography.Title>

          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            placeholder={t('EnterAddress')}
          />

          <FormInput label={t('AdditionalAddress')} name="locationExact" />
        </DeletableField>
      )
    },
    {
      type: TYPE_PRICE,
      title: t('OrderCostField'),
      ns: 'AddOrder',
      icon: 'dollar',
      allow: true,
      onClick: () => onAddField(TYPE_PRICE),
      component: (
        <DeletableField
          className={styles.price}
          onClick={() => onDeleteField(TYPE_PRICE)}
        >
          <FormInputNumber
            name="price"
            label={t('OrderCostField')}
            placeholder="000"
          />

          <FormCurrencySelect name="currency" label={t('Currency')} />
        </DeletableField>
      )
    },
    {
      type: TYPE_TAGS,
      title: t('OrderTagsField'),
      ns: 'AddOrder',
      icon: 'tags',
      allow: !isEditor,
      onClick: () => onAddField(TYPE_TAGS),
      component: (
        <DeletableField onClick={() => onDeleteField(TYPE_TAGS)}>
          <FormTagsSelect
            name="tags"
            label={t('OrderTagsField')}
            itemProps={{
              className: styles.tagsSelect
            }}
          />
        </DeletableField>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('OrderLinksField'),
      icon: 'link',
      allow: !isEditor,
      onClick: () => onAddField(TYPE_RELATIONS),
      component: (
        <DeletableField
          className={styles.relations}
          onClick={() => onDeleteField(TYPE_RELATIONS)}
        >
          <Typography.Title level={3}>{t('OrderLinksField')}</Typography.Title>

          <FormRelationsSelect name="relations" label={t('LinkType')} />
        </DeletableField>
      )
    }
  ];

  const allowedFields = allFields.filter(
    f => f.allow && !activeFields.find(({ type }) => type === f.type)
  );

  const checkIsActiveFeild = value => {
    if (Array.isArray(value) && !value.length) {
      return false;
    }

    return !!value;
  };

  useEffect(() => {
    setActiveFields(
      allFields.filter(
        f => checkIsActiveFeild(defaultValues[f.type]) && f.allow
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return { activeFields, allowedFields };
};

export default useAdditionalFields;
