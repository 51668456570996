import { handleActions } from '../../utils/redux-actions';
import { fetchCities } from './actions';

const initialState = {
  entries: [],
  totalItems: 0,
  isLoaded: false,
  isLoading: false,
  currentPage: 1,
  itemsPerPage: 50,
  error: null
};

export default handleActions(
  {
    [fetchCities.START]: state => {
      state.isLoading = true;
      state.error = null;

      return state;
    },

    [fetchCities.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchCities.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchCities.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;
      state.totalItems = payload.count;
      state.entries = payload.results || [];

      return state;
    }
  },
  initialState
);
