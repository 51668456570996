import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import { REDIRECT } from '../../constants';

export const getUser = state => state.user;

export const getIsUserLoaded = createSelector(getUser, user => user.isLoaded);

export const getIsRegisterStep = createSelector(
  getUser,
  user => user.isRegisterStep
);

export const getIsUserLoading = createSelector(getUser, user => user.isLoading);

export const getIsUserAuthorized = createSelector(
  getUser,
  user => user.token !== null
);

export const getRedirectPath = createSelector(getUser, user => user[REDIRECT]);

export const getToken = createSelector(getUser, user => user.token);

export const getRefreshToken = createSelector(getUser, user => user.refresh);

export const getHasErrorUser = createSelector(getUser, user => !!user.error);

export const getUserEmail = createSelector(getUser, user => user.email);

export const getUserPhone = createSelector(getUser, user => user.phone);

export const getUserCreatedAt = createSelector(getUser, user => user.createdAt);

export const getPopUpCountShow = createSelector(
  getUser,
  user => user.popUpCount
);

export const getPopUpLastShowDate = createSelector(
  getUser,
  user => user.lastPopUpSeenAt
);

export const getCollapsedSider = createSelector(
  getUser,
  user => user.collapsedSider
);

export const getIsSuccess = createSelector(getUser, user => user.isSuccess);

export const getOnboardingByEntity = createSelector(getUser, user =>
  memoize(entity => user.onboarding.find(o => o.entity === entity))
);

export const getIsOnlyUserProfile = createSelector(
  getUser,
  user => user.isOnlyUserProfile
);
