import React, { useEffect, useRef, useState } from 'react';

import variables from 'theme';

import styles from './submit-wrapper.module.scss';

export const SubmitWrapper = ({ children }) => {
  const ref = useRef(null);

  const [withShadow, setWithShadow] = useState(false);

  const getScrollParent = node => {
    if (node === null) {
      return false;
    }

    if (node.scrollHeight > node.clientHeight) {
      return true;
    }

    return getScrollParent(node.parentNode);
  };

  useEffect(() => {
    setWithShadow(getScrollParent(ref.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className={styles.root}
      style={{
        boxShadow: withShadow
          ? `0px -1px 0px ${variables.color['black-85']}`
          : 'none'
      }}
    >
      {children}
    </div>
  );
};
export default SubmitWrapper;
