import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from './actions.module.scss';

const Actions = ({ messageUuid, actions, className }) => {
  if (!actions.length) {
    return null;
  }

  return (
    <div className={classnames(styles.root, className)}>
      {actions.map(a => {
        if (a.component) {
          return <div key={`${a.id}-${messageUuid}`}>{a.component}</div>;
        }

        return (
          <Tooltip
            title={a.tooltip}
            mouseEnterDelay={0.5}
            key={`${a.id}-${messageUuid}`}
          >
            <Button
              type="text"
              mood={a.buttonMood || 'none'}
              size="large"
              className={styles.button}
              onClick={a.onClick}
            >
              <Icon type={a.iconType} size={20} />
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string,
      onClick: PropTypes.func,
      iconType: PropTypes.string,
      component: PropTypes.object,
      buttonMood: PropTypes.string
    })
  )
};

Actions.defaultProps = {
  className: undefined,
  actions: []
};

export default Actions;
