import { Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { CountrySelect } from '../custom-select';

import styles from './unverified-workspace.module.scss';

const UnverifiedWorkspace = ({
  value,
  onChange,
  countrySelectProps,
  inputProps
}) => {
  const { title, country } = value;

  return (
    <div className={styles.root}>
      <CountrySelect
        value={country}
        onChange={v => onChange({ ...value, country: v })}
        isSearchable
        isClearable={false}
        className={styles.country}
        {...countrySelectProps}
      />

      <Input
        value={title}
        className={styles.title}
        onChange={e => onChange({ ...value, title: e.target.value })}
        onBlur={e => onChange({ ...value, title: e.target.value })}
        {...inputProps}
      />
    </div>
  );
};

UnverifiedWorkspace.propTypes = {
  value: PropTypes.shape({
    country: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.object
    }),
    title: PropTypes.string
  }).isRequired,
  onChage: PropTypes.func.isRequired,
  countrySelectProps: PropTypes.object,
  inputProps: PropTypes.object
};

UnverifiedWorkspace.defaultProps = {
  countrySelectProps: {},
  inputProps: {}
};

export default UnverifiedWorkspace;
