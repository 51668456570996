import React from 'react';

export const OfflineIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M19.5 0H4.5C2.01472 0 0 2.01472 0 4.5V19.5C0 21.9853 2.01472 24 4.5 24H19.5C21.9853 24 24 21.9853 24 19.5V4.5C24 2.01472 21.9853 0 19.5 0Z"
        fill="#F9716C"
      />
      <path
        d="M20.9899 18.8431C20.9017 15.4015 20.1744 14.4208 14.6094 13.8C14.6094 13.8 13.8261 14.417 12.0002 14.417C10.1743 14.417 9.39085 13.8 9.39085 13.8C3.88656 14.414 3.1151 15.3801 3.01366 18.7313C3.00534 19.005 3.00149 19.0194 3 18.9876C3.00034 19.0471 3.00074 19.1572 3.00074 19.3492C3.00074 19.3492 4.32564 21 12.0002 21C19.6746 21 20.9997 19.3492 20.9997 19.3492L21 19.0817C20.9985 19.1014 20.9955 19.0633 20.9899 18.8431Z"
        fill="white"
      />
      <path
        d="M12.0001 12C13.9882 12 15.6 9.9853 15.6 7.50003C15.6 5.0147 15.0708 3 12.0001 3C8.92929 3 8.4 5.0147 8.4 7.50003C8.4 9.9853 10.0118 12 12.0001 12Z"
        fill="white"
      />
    </g>
  </svg>
);

export default OfflineIcon;
