import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from 'components/common/button';
import FormDepartmentSelect from 'components/common/hook-form/select/department';
import { FormTextarea, validateMaxLength } from 'components/common/hook-form';

import {
  moveDepartment,
  setNeedRefetchDepartments
} from 'store/team/departments';

import styles from './moving-department.module.scss';

const MovingDepartmentForm = ({ department, onClose }) => {
  const { t } = useTranslation(['Common, MoveDepartment, Team']);
  const { id, parentId } = department;

  const methods = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        moveDepartment({
          id,
          parentDepartmentId: (values.parentDepartment || {}).value,
          reason: values.reason
        })
      );
      dispatch(setNeedRefetchDepartments(true));

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <FormDepartmentSelect
          name="parentDepartment"
          label={t('Department', { ns: 'MoveDepartment' })}
          params={{
            excludeWithChildren: [id],
            excludeDepartment: [parentId]
          }}
        />

        <FormTextarea
          label={t('MoveDepartmentReason', { ns: 'MoveDepartment' })}
          name="reason"
          placeholder={t('ReasonForMove', { ns: 'MoveDepartment' })}
          autoSize={{
            minRows: 7,
            maxRows: 7
          }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            maxLength: validateMaxLength(512)
          }}
          style={{
            resize: 'none'
          }}
        />

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          className={styles.submitBtn}
        >
          {t('SubmitBtn', { ns: 'Team' })}
        </Button>
      </form>
    </FormProvider>
  );
};

MovingDepartmentForm.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number,
    parentId: PropTypes.number
  }),
  onClose: PropTypes.func.isRequired
};

MovingDepartmentForm.defaultProps = {
  department: {}
};

export default MovingDepartmentForm;
