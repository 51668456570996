import React from 'react';
import PropTypes from 'prop-types';
import { Table as CommonTable, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT } from 'constants/index';

import { renderDescriptions } from 'components/common/comments/converters';

import { getFullName } from 'utils/get-fio';

import styles from './agreement-sheet.module.scss';

const { Text, Paragraph } = Typography;

export const Table = ({ dataSource, isLoading, ...props }) => {
  const { t } = useTranslation(['ApprovingList', 'Common']);

  const columns = [
    {
      dataIndex: 'step',
      title: '№',
      width: 80
    },
    {
      dataIndex: 'employee',
      title: t('FullName'),
      width: 175,
      render: employee => getFullName(employee)
    },
    {
      dataIndex: 'agreementDate',
      title: t('ApprovingDateAndTime'),
      width: 300,
      render: agreementDate =>
        agreementDate ? moment(agreementDate).format(DATE_TIME_FORMAT) : null
    },
    {
      dataIndex: 'status',
      title: t('Status'),
      render: (status, record) =>
        status ? (
          <>
            <Paragraph style={{ margin: 0 }}>{status}</Paragraph>
            {record.rejectionReason && (
              <>
                <Text style={{ fontSize: 10, opacity: 0.5, display: 'block' }}>
                  {t('NotApprovedReason')}{' '}
                  {renderDescriptions(record.rejectionReason)}
                </Text>
                <Text className={styles.suspended}>
                  {t('ApprovingOnPause')}
                </Text>
              </>
            )}
          </>
        ) : null
    }
  ];

  const transformDataSource = () => {
    const results = dataSource.length
      ? [{ step: t('Step', { value: 1 }) }]
      : null;
    let count = 0;
    let step = 1;

    dataSource.forEach((item, index) => {
      if (dataSource[index + 1] && item.step !== dataSource[index + 1].step) {
        results.push({ ...item, step: (count += 1) });
        results.push({ step: t('Step', { value: (step += 1) }) });
      } else {
        results.push({ ...item, step: (count += 1) });
      }
    });

    return results;
  };

  return (
    <CommonTable
      className={styles.table}
      dataSource={transformDataSource()}
      columns={columns}
      pagination={{ hideOnSinglePage: true, defaultPageSize: 10000 }}
      loading={isLoading}
      locale={{
        emptyText: t(isLoading ? 'Loading' : 'EmptyList', { ns: 'Common' })
      }}
      scroll={{ y: true }}
      {...props}
    />
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      employee: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      }),
      status: PropTypes.string,
      agreementdDate: PropTypes.string,
      rejectionReason: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool
};

Table.defaultProps = {
  dataSource: [],
  isLoading: false
};

export default Table;
