import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import {
  NotificationBell,
  WorkspaceInfo,
  SettingsMenu,
  ViewInfo
} from 'components/common/layout/header/components';

import { getIsOnlyCompanyProfile } from 'store/billing';

import { Header as HeaderTemplate } from '../template/header';

import styles from './header.module.scss';

export const Header = () => {
  const isOnlyCompanyProfile = useSelector(getIsOnlyCompanyProfile);

  return (
    <HeaderTemplate className={styles.root}>
      <ViewInfo />

      <div className={styles.rightPanel}>
        {!isOnlyCompanyProfile && <NotificationBell className={styles.bell} />}

        <WorkspaceInfo className={styles.workspaceInfo} />
      </div>

      <Divider
        style={{ marginRight: 1, marginLeft: 20, height: 32 }}
        type="vertical"
      />

      <SettingsMenu />
    </HeaderTemplate>
  );
};

export default Header;
