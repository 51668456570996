import React from 'react';

export const SortAscendingIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7742 9.90704L18.3474 2.31865C18.3317 2.26787 18.3 2.22352 18.2571 2.19216C18.2141 2.1608 18.1622 2.14411 18.1091 2.14454H16.0358C15.926 2.14454 15.8323 2.21418 15.7974 2.31865L13.3519 9.90704C13.3439 9.93115 13.3385 9.95793 13.3385 9.98472C13.3385 10.1213 13.451 10.2338 13.5876 10.2338H15.0983C15.2108 10.2338 15.3073 10.1588 15.3394 10.0517L15.8082 8.40168H18.1921L18.6555 10.049C18.6849 10.1561 18.7841 10.2311 18.8966 10.2311H20.5358C20.5626 10.2311 20.5867 10.2285 20.6108 10.2204C20.6751 10.199 20.726 10.1561 20.7582 10.0972C20.7876 10.0383 20.793 9.97133 20.7742 9.90704ZM16.0519 7.00615L16.9305 3.8749H17.0992L17.9591 7.00615H16.0519ZM19.8957 20.2088H16.4242V20.1981L19.976 15.1383C20.0055 15.0954 20.0216 15.0472 20.0216 14.9936V14.0186C20.0216 13.882 19.9091 13.7695 19.7724 13.7695H14.3082C14.1716 13.7695 14.0591 13.882 14.0591 14.0186V15.1704C14.0591 15.307 14.1716 15.4195 14.3082 15.4195H17.5921V15.4303L14.0269 20.4901C13.9972 20.5324 13.9812 20.583 13.9814 20.6347V21.6097C13.9814 21.7463 14.0939 21.8588 14.2305 21.8588H19.893C20.0296 21.8588 20.1421 21.7463 20.1421 21.6097V20.4579C20.1424 20.4253 20.1363 20.393 20.1241 20.3628C20.1119 20.3326 20.0938 20.3051 20.0709 20.2819C20.048 20.2588 20.0207 20.2404 19.9906 20.2278C19.9605 20.2153 19.9283 20.2088 19.8957 20.2088ZM9.42781 17.091H7.39209V2.89454C7.39209 2.77668 7.29566 2.68025 7.17781 2.68025H5.67781C5.55995 2.68025 5.46352 2.77668 5.46352 2.89454V17.091H3.42781C3.24834 17.091 3.14656 17.2999 3.25906 17.4392L6.25906 21.2401C6.2791 21.2657 6.30471 21.2864 6.33395 21.3006C6.36319 21.3149 6.39528 21.3223 6.42781 21.3223C6.46033 21.3223 6.49243 21.3149 6.52166 21.3006C6.5509 21.2864 6.57651 21.2657 6.59656 21.2401L9.59656 17.4392C9.70638 17.2999 9.60727 17.091 9.42781 17.091Z"
      fill="currentColor"
    />
  </svg>
);

export default SortAscendingIcon;
