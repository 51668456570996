import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from 'components/common/modal';
import { FormDatePicker } from 'components/common/hook-form';
import Button from 'components/common/button';

import { partialUpdate } from 'store/assets';

import { showNoticeMessage } from 'services/notice';
import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from './change-asset-validity-expiration.module.scss';

const Form = ({ onSubmit, isLoading, defaultValues }) => {
  const { t } = useTranslation('Asset');

  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: new Date()
  });

  const methods = useForm({ defaultValues });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormDatePicker
          name="dateEnd"
          label={t('ValidityExpiration')}
          minDate={new Date()}
          minTime={minTimeStart}
          maxTime={maxTimeStart}
          placeholderText={t('ExpirationDate')}
          wrapperClassname={styles.datePicker}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          className={styles.submit}
          loading={isLoading}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

const ChangeAssetValidityExpirationModal = ({ visible, onClose, asset }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Asset');

  const { id, dateEnd } = asset;

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        partialUpdate({
          id,
          asset: {
            dateEnd: values.dateEnd
          }
        })
      );

      showNoticeMessage();

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('ChangeValidityExpirationHeading')}
      contentStyle={{ padding: 24 }}
      centered
      visible={visible}
      width={383}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        isLoading={isLoading}
        defaultValues={{ dateEnd: moment(dateEnd).toDate() }}
      />
    </Modal>
  );
};

ChangeAssetValidityExpirationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.number,
    dateEnd: PropTypes.string
  })
};

ChangeAssetValidityExpirationModal.defaultProps = {
  asset: {}
};

export default ChangeAssetValidityExpirationModal;
