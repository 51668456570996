import { useEffect, useMemo, useState } from 'react';

/**
 * Function check if element is in the scope of browser's viewport
 * @param {HTMLElement} ref Element ref
 * @param {IntersectionObserverInit} observerOptions options
 */
export const useIsInViewport = (ref, observerOptions = { threshold: 0.5 }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        observerOptions
      ),
    [observerOptions]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default useIsInViewport;
