import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import getOrderingValue from 'utils/get-ordering-value';

export const _getContacts = state => state.contactsCompanies;

export const getContactsCompanies = createSelector(
  _getContacts,
  contacts => contacts.entries
);

export const getContactCompaniesFactory = createSelector(
  getContactsCompanies,
  entries => memoize(id => entries.find(contact => contact.id === id))
);

export const getIsContactsCompaniesLoading = createSelector(
  _getContacts,
  contacts => contacts.isLoading
);

export const getIsContactsCompaniesLoaded = createSelector(
  _getContacts,
  contacts => contacts.isLoaded
);

export const getCurrentPageCompanies = createSelector(
  _getContacts,
  contacts => contacts.currentPage
);

export const getTotalItemsCompanies = createSelector(
  _getContacts,
  contacts => contacts.totalItems
);

export const getItemsPerPageCompanies = createSelector(
  _getContacts,
  contacts => contacts.itemsPerPage
);

export const getFilterContactsCompanies = createSelector(
  _getContacts,
  contacts => contacts.filter
);

export const getContactsCompaniesSearchFilter = createSelector(
  getFilterContactsCompanies,
  filter => filter.search
);

export const getContactsIsCustomersFilter = createSelector(
  getFilterContactsCompanies,
  filter => filter.isCustomers
);

export const getContactsResponsibleFilter = createSelector(
  getFilterContactsCompanies,
  filter => filter.responsible
);

export const getOrderingCompanies = createSelector(_getContacts, contacts =>
  getOrderingValue(contacts)
);

export const getSortFieldCompanies = createSelector(
  _getContacts,
  contacts => contacts.sortField
);

export const getSortDirectionCompanies = createSelector(
  _getContacts,
  contacts => contacts.sortDirection
);
