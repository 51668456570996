import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import omitBy from 'lodash/omitBy';

import { getWorkspaceId } from 'store/workspace';
import useLocalStorage, { getInitialValueStorage } from './use-local-storage';

export const DEFAULT_EXCLUDED_KEYS = ['search'];

// Подставляем префикс к key, так как нужно будет очищать все фильтра
// при разлогинивании, переключении компании, кнопкой сбросить фильтр
const FILTER_PREFIX = '--filter--';
const getKeyWithPrefix = key => `${FILTER_PREFIX}${key}`;
const defaultExcludeFn = (_, key) => DEFAULT_EXCLUDED_KEYS.includes(key);

export const getInitialValueFilterStorage = (
  key,
  initialValue,
  excludeFn = defaultExcludeFn
) =>
  omitBy(
    getInitialValueStorage(key, initialValue, getKeyWithPrefix),
    excludeFn
  );

// Подписка на сохранение данных по ключу в localStorage
export const useChangedStorageFilter = (
  key,
  dependencies,
  fnPrefix = getKeyWithPrefix
) => {
  const workspaceId = useSelector(getWorkspaceId);
  const [value, setValue] = useLocalStorage(fnPrefix(key));

  const changeValue = newValue => setValue({ ...value, ...newValue });

  useEffect(() => {
    changeValue(dependencies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies, workspaceId]);

  return [value, setValue];
};

// Очистка всех localStorage по FILTER_PREFIX
export const clearStorageFilter = () => {
  Object.keys(window.localStorage)
    .filter(key => key.startsWith(FILTER_PREFIX))
    .map(key => window.localStorage.removeItem(key));
};
