import api from 'api';

import createActionThunk from 'store/actions-thunk';

export const createDevice = createActionThunk(
  'devices/create',
  ({ registrationId }) => {
    const type = 'web';

    return api.devices
      .create({ registrationId, type })
      .then(() => registrationId)
      .catch(() => registrationId);
  }
);

export const deleteDevice = createActionThunk(
  'devices/delete',
  ({ registrationId }) => api.devices.delete({ registrationId })
);
