import React, { Children, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import { getActiveWorkspace } from 'store/workspace';

import useRoutesService from 'services/routes';

import { checkAllowInviteEmployee, checkPaymentAccessByRoles } from '../utils';

import styles from './limited-feature.module.scss';

const LimitedFeature = ({
  children,
  childrenDisableProps,
  allowLimit,
  className
}) => {
  const workspace = useSelector(getActiveWorkspace);

  const routes = useRoutesService();

  const { t } = useTranslation('Billing');

  const { countEmployee, account = {}, user = {} } = workspace || {};

  const allowInviteEmployee = checkAllowInviteEmployee({
    currentEmployeeCount: countEmployee,
    maxEmployeeCount: (account.tariff || {}).usersCount
  });

  const limited = allowLimit && !allowInviteEmployee;

  const hasPaymentAccess = checkPaymentAccessByRoles(user.roles);

  const getTooltip = () => {
    if (!allowInviteEmployee) {
      return (
        <div className={styles.tooltipContent}>
          <Typography.Text size="small" color="white">
            {t('EmployeesLimitTip', {
              countInfo: `${countEmployee}/${account.tariff.usersCount}`
            })}
          </Typography.Text>

          {hasPaymentAccess && (
            <Typography.Text
              size="small"
              color="white"
              className={styles.changeTariffText}
              onClick={() => routes.toBilling()}
            >
              {t('ChangePlanTipBtn')}
            </Typography.Text>
          )}
        </div>
      );
    }

    return undefined;
  };

  if (!limited) {
    return children || null;
  }

  return (
    <Tooltip title={getTooltip()}>
      <div className={classnames(styles.root, className)}>
        {Children.map(children, child =>
          cloneElement(child, {
            ...child.props,
            [childrenDisableProps]: child.props[childrenDisableProps] || limited
          })
        )}

        <div className={styles.icon}>
          <Icon type="lock" size={16} color="black-35" />
        </div>
      </div>
    </Tooltip>
  );
};

LimitedFeature.propTypes = {
  childrenDisableProps: PropTypes.string,
  allowLimit: PropTypes.bool,
  className: PropTypes.string
};

LimitedFeature.defaultProps = {
  childrenDisableProps: null,
  allowLimit: true,
  className: undefined
};

export default LimitedFeature;
