import React, { useState } from 'react';
import { Divider } from 'antd';
import { useGptWidget } from 'providers/gpt-widget-provider';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';

import { GPT_CHATS_VIEW, GPT_MESSAGES_VIEW } from 'constants/index';

import Skeleton from 'components/common/chat/skeleton';

import GptHeader from './components/gpt-header';
import GptMessageThread from './components/gpt-message-thread';
import GptMessageInput from './components/gpt-message-input';
import GptCollapseElement from './components/gpt-collapse-element';
import GptChats from './components/gpt-chats';

import styles from './gpt-widget-component.module.scss';

const GptWidgetComponent = () => {
  const [width, setWidth] = useState(350);
  const [height, setHeight] = useState(520);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const {
    isOpen,
    isCollapse,
    closeWidget,
    toggleCollapse,
    sendMessage,
    sendPrompt,
    entityInfo,
    setView,
    view,
    messages,
    connecting,
    prompts,
    retrieveMessagesFromDialogs,
    isLoadingMessages
  } = useGptWidget();

  const handleDrag = (e, ui) => {
    const { x, y } = position;

    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  return (
    isOpen && (
      <div>
        {isCollapse && <GptCollapseElement toggleCollapse={toggleCollapse} />}

        {!isCollapse && (
          <Draggable
            axis="x"
            position={position}
            bounds="body"
            onDrag={handleDrag}
            handle=".gpt-header-handle"
          >
            <Resizable
              className={styles.root}
              defaultSize={{
                width,
                height
              }}
              minWidth={350}
              minHeight={520}
              maxWidth={1000}
              maxHeight={900}
              onResizeStop={(event, direction, elementRef, delta) => {
                setWidth(prevVal => prevVal + delta.width);
                setHeight(prevVal => prevVal + delta.height);
              }}
            >
              <div className={styles.gptWrap}>
                <GptHeader
                  toggleCollapse={toggleCollapse}
                  closeWidget={closeWidget}
                  view={view}
                  setView={setView}
                  classNameDnd="gpt-header-handle"
                  entityInfo={entityInfo}
                />

                <Divider className={styles.divider} />

                <div className={styles.content}>
                  {(connecting || isLoadingMessages) && <Skeleton />}

                  {!connecting && (
                    <>
                      {view === GPT_CHATS_VIEW && (
                        <GptChats
                          fetchMessages={retrieveMessagesFromDialogs}
                          setView={setView}
                        />
                      )}

                      {view === GPT_MESSAGES_VIEW && !isLoadingMessages && (
                        <>
                          <GptMessageThread
                            entityInfo={entityInfo}
                            sendPrompt={sendPrompt}
                            messages={messages}
                            prompts={prompts}
                          />

                          <div className={styles.messageInputWrap}>
                            <GptMessageInput
                              sendMessage={sendMessage}
                              entityInfo={entityInfo}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Resizable>
          </Draggable>
        )}
      </div>
    )
  );
};

export default GptWidgetComponent;
