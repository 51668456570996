import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import { ListDeletedIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import Drawer from 'components/common/drawer';
import BackBtn from 'components/common/back-btn';

import styles from './deleted.module.scss';

export const AssetDeletedDrawer = ({ visible }) => {
  const { t } = useTranslation('ScreenErrors');

  return (
    <Drawer
      closable={false}
      destroyOnClose
      visible={visible}
      fullWidth
      isContent
    >
      <div className={styles.root}>
        <BackBtn
          size="small"
          type="default"
          icon={null}
          className={styles.btn}
          style={{ marginBottom: 8 }}
        >
          <Icon type="arrow" side="left" size={20} />
        </BackBtn>

        <div className={styles.content}>
          <Empty
            image={<Icon component={ListDeletedIcon} />}
            imageStyle={{ height: 'auto' }}
            description={
              <Typography.Title
                level={1}
                weight="bold"
                style={{ marginBottom: 20 }}
              >
                {t('ListDeletedHeading')}
              </Typography.Title>
            }
            className={styles.empty}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default AssetDeletedDrawer;
