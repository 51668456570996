import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './layout-template.module.scss';

export const LayoutTemplate = ({ className, children }) => (
  <Layout className={classnames(className, styles.root)}>{children}</Layout>
);

LayoutTemplate.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

LayoutTemplate.defaultProps = {
  className: undefined,
  children: undefined
};

export default LayoutTemplate;
