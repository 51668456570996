import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Button from 'components/common/button';
import Attachment from 'components/common/attachments/attachment';
import Typography from 'components/common/typography';
import SkeletonCard from 'components/common/skeleton-card';

import {
  completeImportContacts,
  fetchResultsImportContacts,
  fetchStatusImportContacts
} from 'store/contacts';
import { getUILanguage } from 'store/ui';

import {
  CONTACT_IMPORT_TEMPLATE_BASE64_BY_LANGUAGE,
  CONTACT_IMPORT_TEMPLATE_COLUMN_KEYS,
  base64ToXlsx
} from '../../import-contacts/utils';

import styles from './contacts-import-results.module.scss';

const ContactsImportResultsDrawer = ({ visible, onClose, ...props }) => {
  const dispatch = useDispatch();

  const language = useSelector(getUILanguage);

  const [results, setResults] = useState({
    errorsRow: true,
    successRow: 120
  });
  const [id, setId] = useState(null);

  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const { t } = useTranslation('ContactImport');

  const fetch = async () => {
    try {
      setIsLoadingResults(true);

      const { contactImportProcessing } = await dispatch(
        fetchStatusImportContacts()
      );

      if (contactImportProcessing) {
        const data = await dispatch(
          fetchResultsImportContacts({ id: contactImportProcessing })
        );

        setResults(data);
        setId(contactImportProcessing);
      }
    } finally {
      setIsLoadingResults(false);
    }
  };

  const onSubmit = useCallback(async () => {
    try {
      setIsLoadingSubmit(true);

      await dispatch(completeImportContacts({ id }));

      onClose();
    } finally {
      setIsLoadingSubmit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const errorKeys = results
    ? [
        ...new Set(
          (results.errors || [])
            .flatMap(error => Object.keys(error))
            .filter(
              item => item !== 'errors' && item !== 'contactImportProcessing'
            )
        )
      ]
    : [];

  const columns = Object.keys(CONTACT_IMPORT_TEMPLATE_COLUMN_KEYS).map(key => ({
    title: t(CONTACT_IMPORT_TEMPLATE_COLUMN_KEYS[key]),
    dataIndex: key,
    key
  }));

  const dataSource = ((results && results.errors) || []).map(info => {
    const data = {};

    errorKeys.forEach(key => {
      if (key !== 'errors') {
        const value = info[key] || '-';
        const errorText = info.errors[key] ? info.errors[key].join(', ') : null;
        const shouldDisplayNonFieldErrors =
          (key === 'phone' && !info.phone) || (key === 'email' && !info.email);

        data[key] = (
          <>
            <Typography.Text>{value}</Typography.Text>
            <br />

            {errorText && (
              <Typography.Text size="small" color="red">
                {errorText}
              </Typography.Text>
            )}

            {shouldDisplayNonFieldErrors && info.errors.nonFieldErrors && (
              <Typography.Text size="small" color="red">
                {info.errors.nonFieldErrors.join(', ')}
              </Typography.Text>
            )}
          </>
        );
      }
    });

    return data;
  });

  useEffect(() => {
    if (visible) {
      fetch();
    }

    return () => {
      setResults(null);
      setId(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Drawer
      title={<Drawer.Title>{t('ContactImportHeading')}</Drawer.Title>}
      width={960}
      closable={!isLoadingResults && !id}
      onClose={!isLoadingResults && !id ? onClose : undefined}
      visible={visible}
      destroyOnClose
      className={styles.root}
      {...props}
    >
      {isLoadingResults && (
        <SkeletonCard count={3} avatar={false} paragraph={{ rows: 3 }} />
      )}

      {!isLoadingResults && results && (
        <>
          <Typography.Paragraph>
            {t('ContactImportedSuccessfullyDesc')} {results.successRow}
          </Typography.Paragraph>

          {results.errorsRow ? (
            <>
              <Typography.Text>
                <Trans
                  i18nKey="ContactImportedErrorsDesc"
                  ns="ContactImport"
                  components={{
                    bold: <Typography.Text weight="bold" />,
                    space: <br />
                  }}
                />
              </Typography.Text>

              <a
                download={results.errorsFileTitle}
                href={results.errorsFileUrl}
              >
                <Attachment
                  file={base64ToXlsx({
                    base64Xlsx:
                      CONTACT_IMPORT_TEMPLATE_BASE64_BY_LANGUAGE[language],
                    fileName: results.errorsFileTitle
                  })}
                  actions={[
                    {
                      key: 'download',
                      title: 'Download',
                      ns: 'Common',
                      onClick: () => {
                        window.location.href = results.errorsFileUrl;
                      },
                      allow: true
                    }
                  ]}
                  className={styles.file}
                  size="small"
                />
              </a>

              <Table
                className={styles.table}
                columns={columns}
                dataSource={dataSource}
              />
            </>
          ) : null}
          <Button
            onClick={onSubmit}
            type="primary"
            size="large"
            className={styles.btn}
            loading={isLoadingSubmit}
          >
            {t('CompleteBtn')}
          </Button>
        </>
      )}

      {!isLoadingResults && !id && (
        <Typography.Paragraph>
          {t('ContactImportCompleteHeading')}
        </Typography.Paragraph>
      )}
    </Drawer>
  );
};

export default ContactsImportResultsDrawer;

ContactsImportResultsDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

ContactsImportResultsDrawer.defaultProps = {
  visible: false,
  onClose: () => {}
};
