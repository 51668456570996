import { NOTIFICATIONS } from 'constants/api';

export default api => ({
  fetch: ({ receiver, offset, limit, isViewed, type, sender }) =>
    api.get(`/v1/${NOTIFICATIONS}/`, {
      params: { receiver, limit, offset, is_viewed: isViewed, type, sender }
    }),

  fetchUnreaded: () => api.get(`/v1/${NOTIFICATIONS}-workspace/`),

  readOne: ({ id }) => api.patch(`/v1/${NOTIFICATIONS}/${id}/read/`),

  readAll: ({ workspaceId }) =>
    api.patch(`/v1/${NOTIFICATIONS}/read/`, {
      workspaceId
    }),

  unreadOne: ({ id }) => api.patch(`/v1/${NOTIFICATIONS}/${id}/unread/`)
});
