import qs from 'qs';

export default api => ({
  fetch: ({ workspaceId, params = {} }) =>
    api.get(`/v1/${workspaceId}/reports/`, {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/reports/${id}/`),

  fetchAnalysts: ({ workspaceId, params }) =>
    api.get(`/v1/${workspaceId}/reports-analysts/`, {
      params
    }),

  fetchAccess: ({ limit = 1000, offset = 0, id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/reports/${id}/access/`, {
      params: {
        limit,
        offset
      }
    }),

  updateAccess: ({ employees, id, isDelete, workspaceId }) =>
    api.post(`/v1/${workspaceId}/reports/${id}/access/`, {
      employees,
      delete: isDelete
    }),

  fetchReportTemplates: ({ workspaceId, params = {} }) =>
    api.get(`/v1/${workspaceId}/report-templates/`, {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { arrayFormat: 'comma' });
      }
    }),

  fetchReportTemplate: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/report-templates/${id}/`),

  createReportTemplate: ({ workspaceId, data }) =>
    api.post(`/v1/${workspaceId}/report-templates/`, {
      title: data.title,
      report: data.reportId,
      parameters: data.parameters
    }),

  partialUpdateReportTemplate: ({ workspaceId, id, data }) =>
    api.patch(`/v1/${workspaceId}/report-templates/${id}/`, {
      parameters: data.parameters
    }),

  deleteReportTemplate: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/report-templates/${id}/`),

  fetchReportTemplateAccess: ({ limit = 1000, offset = 0, id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/report-templates/${id}/access/`, {
      params: {
        limit,
        offset
      }
    }),

  updateReportTemplateAccess: ({ employees, id, isDelete, workspaceId }) =>
    api.post(`/v1/${workspaceId}/report-templates/${id}/access/`, {
      employees,
      delete: isDelete
    })
});
