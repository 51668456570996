import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import {
  FormCustomSelect,
  FormRadio,
  FormSwitch
} from 'components/common/hook-form';

import OperatorHoursForm from './operator-hours-form';
import { WIDGET_DISPLAY_OPTIONS, timezonesOptions } from './constants';

import styles from './operator-hours.module.scss';

const { Title, Paragraph } = Typography;

const OperatorHours = ({ isWebsiteChannel, isOnlyView, children }) => {
  const { t } = useTranslation(['ConnectWidget', 'Timezone']);

  const { watch } = useFormContext();

  const isActiveScheduleWatch = watch('scheduleConfig.isActive');

  return (
    <>
      <Divider />

      <Title className={styles.title} level={3}>
        {t('ChannelWorkTimeHeading')}
      </Title>

      <FormSwitch
        name="scheduleConfig.isActive"
        label={t('SetChannelWorkTimeToggle')}
        disabled={isOnlyView}
      />

      {isActiveScheduleWatch && (
        <>
          <Title className={styles.subtitle} level={4}>
            {t('WorkingHoursHeading')}
          </Title>

          <Paragraph size="small">{t('WorkingHoursDesc')}</Paragraph>

          <FormCustomSelect
            name="scheduleConfig.timezone"
            label={t('Timezone')}
            options={timezonesOptions}
            isDisabled={isOnlyView}
          />

          <OperatorHoursForm isOnlyView={isOnlyView} />

          {isWebsiteChannel && (
            <>
              <Title level={4} className={styles.subtitle}>
                {t('ShowChatNoWorkingHoursHeading')}
              </Title>

              <FormRadio
                name="displayIfOffline"
                itemProps={{
                  className: styles.radio
                }}
                options={WIDGET_DISPLAY_OPTIONS}
                disabled={isOnlyView}
              />
            </>
          )}
        </>
      )}

      {children}

      <Divider />
    </>
  );
};

OperatorHours.propTypes = {
  isWebsiteChannel: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  children: PropTypes.any
};

OperatorHours.defaultProps = {
  isWebsiteChannel: false,
  isOnlyView: false,
  children: undefined
};

export default OperatorHours;
