import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { getRouterUrlQuery } from '../../store/router';

export const useQueryParam = (param, defaultValue = null) => {
  const query = useSelector(getRouterUrlQuery, shallowEqual);

  const [value, setValue] = useState(defaultValue || query[param]);

  useEffect(() => {
    setValue(query[param]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return value;
};

export default useQueryParam;
