import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useViewFile } from 'components/attachments-view/hooks';

import { fetchAttachment } from 'store/attachments';
import { getRouterState, getRouterUrlQuery } from 'store/router';
import { getWorkspaceId } from 'store/workspace';

export const OpenFileProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { openFile } = useSelector(getRouterUrlQuery);
  const routerState = useSelector(getRouterState);

  const workspaceId = useSelector(getWorkspaceId);

  const { viewFile } = useViewFile(routerState.actionsDeps);

  const onViewFile = useCallback(async () => {
    const file = await dispatch(fetchAttachment({ fileId: openFile }));

    viewFile(file);
  }, [dispatch, openFile, viewFile]);

  useEffect(() => {
    if (openFile && workspaceId) {
      onViewFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFile, workspaceId]);

  return <Fragment>{children}</Fragment>;
};

export default OpenFileProvider;
