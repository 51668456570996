import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Tooltip } from 'antd';

import { TIME_FORMAT } from 'constants/index';

import Attachments from 'components/common/attachments';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import styles from './message.module.scss';

const ChatMessage = ({
  header,
  content,
  parent,
  reactions,
  createdAt,
  className,
  fileList,
  isDraft,
  isNotSended,
  isNotificationSource,
  getIconStatusMessage,
  getStatusTextNotDeliveryMessage,
  isSending,
  attachmentActionsDeps,
  changeFileList
}) => {
  const [visibleClock, setVisibleClock] = useState(false);

  useEffect(() => {
    let timeout = null;

    if (isDraft) {
      timeout = setTimeout(() => setVisibleClock(true), 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isDraft]);

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.withStatusIcon]: isDraft && (visibleClock || isNotSended),
          [styles.draft]: (isDraft && !isNotSended) || isSending,
          [styles.notSended]: isNotSended && !isSending,
          [styles.source]: isNotificationSource
        },
        className
      )}
    >
      {header}

      {parent}

      <div className={styles.content}>
        {content}

        <Attachments
          attachmentProps={{
            isOpenViaLink: false
          }}
          actionsDeps={attachmentActionsDeps}
          fileList={fileList}
          className={styles.attachments}
          rootClassName={styles.attachmentsWrap}
          showDownloadAllButton
          changeFileList={changeFileList}
        />

        {reactions}
      </div>

      <Typography.Text
        size="small"
        color="black-55"
        className={styles.createdAt}
      >
        {moment(createdAt).format(TIME_FORMAT)}

        {!isSending && !isNotSended && getIconStatusMessage()}
      </Typography.Text>

      {isSending && visibleClock && (
        <Icon
          type="animated-clock-circle"
          size={16}
          color="black-55"
          className={styles.clock}
        />
      )}

      {isNotSended && !isSending && (
        <Tooltip title={getStatusTextNotDeliveryMessage()}>
          <Icon
            type="warning"
            size={16}
            color="red"
            className={styles.warning}
          />
        </Tooltip>
      )}
    </div>
  );
};

ChatMessage.propTypes = {
  header: PropTypes.object,
  content: PropTypes.object,
  createdAt: PropTypes.string,
  className: PropTypes.string,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  parent: PropTypes.object,
  reactions: PropTypes.object,
  isDraft: PropTypes.bool,
  isNotSended: PropTypes.bool,
  isNotificationSource: PropTypes.bool,
  getIconStatusMessage: PropTypes.func,
  getStatusTextNotDeliveryMessage: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  attachmentActionsDeps: PropTypes.shape({
    isFromOtherWorkspace: PropTypes.bool,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool,
    sendCopyToComment: PropTypes.bool,
    entityType: PropTypes.string,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    messageUuid: PropTypes.string
  })
};

ChatMessage.defaultProps = {
  header: <></>,
  content: <></>,
  createdAt: null,
  className: undefined,
  fileList: [],
  parent: <></>,
  reactions: <></>,
  isDraft: false,
  isNotSended: false,
  isNotificationSource: false,
  getIconStatusMessage: () => {},
  isSending: false,
  attachmentActionsDeps: {}
};

export default ChatMessage;
