import { createAction } from 'redux-actions';
import api from 'api';

import { SOCKET_READ_COMMENT } from 'constants/index';

import createActionThunk from 'store/actions-thunk';
import { getCategories } from 'store/categories';

import socket from 'socket';

import {
  getWorkspaceId,
  getUserEmployee,
  getActiveWorkspace
} from './selectors';

export const setActiveId = createAction(
  'workspaces/set-active-id',
  ({ workspaceId }) => workspaceId
);

// Используется для подписки при переходе на странице "Выберите профиль" (checkIsJoining)
// Подписка на workspaceId не срабатывает, т.к. если этот же workspaceId активный,
// и мы переходим на него, то обновление стейта не происходит
export const setSelectedId = createAction('workspaces/set-selected-id');

export const fetchWorkspace = createActionThunk('workspace/fetch', ({ id }) =>
  api.workspaces.fetch(+id)
);

export const fetchWorkspaceLocal = createActionThunk(
  'workspace/fetch-local',
  ({ id }) => api.workspaces.fetch(+id)
);

export const fetchJoinWorkspacesLocal = createActionThunk(
  'workspace/fetch-join-workspaces',
  ({ workspaceId }) =>
    api.workspaces
      .fetchJoinWorkspaces(workspaceId)
      .then(({ data }) => data.results)
);

export const fetchPersonalWorkspace = createActionThunk(
  'workspace/fetch-personal',
  ({ id }) => api.workspaces.fetch(id)
);

export const fetchWorkspaces = createActionThunk(
  'workspaces/fetch',
  async ({ limit, getState, dispatch }) => {
    const state = getState();
    const { personalWorkspaceId } = state.user;

    if (personalWorkspaceId) {
      await dispatch(fetchPersonalWorkspace({ id: personalWorkspaceId }));
    }

    return api.workspaces.fetchAll({ limit });
  }
);

export const searchWorkspacesLocal = createActionThunk(
  'workspaces/search-local',
  ({ search, country, limit, offset, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces.search({
      search,
      country,
      workspaceId,
      limit,
      offset
    });
  }
);

export const searchExternalCompanyLocal = createActionThunk(
  'workspaces/search-external-local',
  api.workspaces.searchExternal
);

// нужно для обновления воркспейсов с локальным прелоадером.
export const fetchWorkspacesSkipLoading = createActionThunk(
  'workspaces/fetch-skip-loading',
  () => api.workspaces.fetchAll()
);

export const fetchWorksapceVatNumber = createActionThunk(
  'workspaces/fetch-local',
  ({ vatNumber, country }) => api.workspaces.vatNumber({ vatNumber, country })
);

export const createWorkspace = createActionThunk(
  'workspaces/create',
  vatNumberData => api.workspaces.create(vatNumberData).then(({ data }) => data)
);

export const updateWorkspace = createActionThunk(
  'workspaces/update',
  ({ workspace }) => api.workspaces.patch(workspace).then(({ data }) => data)
);

export const updateWorkspaceUser = createActionThunk(
  'workspaces/update-user',
  ({ user, getState }) => {
    const state = getState();
    const workspaceId = user.workspaceId || getWorkspaceId(state);

    return api.employees.patch(workspaceId, user).then(({ data }) => ({
      ...data,
      workspace: data.workspace || workspaceId
    }));
  }
);

export const updateWorkspaceUserCurrency = createActionThunk(
  'workspaces/set-employee-currency',
  async ({ currency, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const { id } = getUserEmployee(state);

    await api.employees.setCurrency({
      currency,
      workspaceId,
      userId: id
    });

    return { currency, workspaceId };
  }
);

export const setWorkspaceUserPriceVisible = createActionThunk(
  'workspaces/update-user-subscription',
  ({ visible, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return { visible, workspaceId };
  }
);

export const updateWorkspaceUserSubscription = createAction(
  'workspaces/update-user-subscription'
);

export const createWorkspaceCategory = createActionThunk(
  'workspaces/create-workspace-category',
  ({ categories, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const allCategories = getCategories(state);

    const filtredCategories = categories.filter(({ category }) => {
      const currentCategory = allCategories.find(c => c.id === category);

      return currentCategory.level !== 1;
    });

    return api.workspaces
      .createCategory(workspaceId, filtredCategories)
      .then(({ data }) => ({ ...data, workspaceId }));
  }
);

export const deleteWorkspaceCategory = createActionThunk(
  'workspaces/delete-workspace-category',
  ({ categories, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces
      .deleteCategory(categories, workspaceId)
      .then(({ data }) => ({ ...data, workspaceId }));
  }
);

export const createWorkspaceCity = createActionThunk(
  'workspaces/create-workspace-city',
  ({ cityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces
      .createCity(workspaceId, cityId)
      .then(({ data }) => ({ ...data, workspaceId }));
  }
);

export const deleteWorkspaceCity = createActionThunk(
  'workspaces/delete-workspace-city',
  ({ cityId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces
      .deleteCity(cityId, workspaceId)
      .then(({ data }) => ({ ...data, workspaceId }));
  }
);

const acceptEmployee = accept => ({ invitationKey, workspaceId }) =>
  api.invitation.acceptEmployee(invitationKey, accept, workspaceId);

export const acceptInviteWorkspace = createActionThunk(
  'workspace/accept-invite-workspace',
  ({ invitationKey, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return acceptEmployee()({ invitationKey, workspaceId });
  }
);

export const cancelInviteWorkspace = createActionThunk(
  'workspace/cancel-invite-workspace',
  ({ invitationKey, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return acceptEmployee(false)({ invitationKey, workspaceId });
  }
);

export const joinWorkspace = createActionThunk(
  'workspace/join',
  ({ workspaceId }) => api.workspaces.join(workspaceId).then(({ data }) => data)
);

export const checkJoining = createActionThunk(
  'workspace/check-joining',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    if (!workspaceId) {
      return Promise.resolve({ value: false });
    }

    return api.actions.joining(workspaceId).then(({ data }) => data);
  }
);

export const readComments = createActionThunk(
  SOCKET_READ_COMMENT,
  ({ entity, objectId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return socket.comments.read(workspaceId, objectId, entity);
  }
);

export const fetchRegions = createActionThunk(
  'workspaces/fetch-regions',
  params =>
    api.regions.fetch(params).then(({ data }) => ({
      entries: data.results,
      totalItems: data.count
    }))
);

export const fetchCurrency = createActionThunk('workspaces/currency', params =>
  api.currency.fetch(params).then(({ data }) => ({
    entries: data.results,
    totalItems: data.count
  }))
);

export const fetchPriceList = createActionThunk(
  'workspaces/fetch-price-list',
  ({ dispatch, getState, ...params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces.fetchPriceList({ workspaceId, ...params });
  }
);

export const fetchPriceListHistory = createActionThunk(
  'workspaces/fetch-price-list',
  ({ dispatch, getState, ...params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces.fetchPriceListHistory({ workspaceId, ...params });
  }
);

export const createPriceList = createActionThunk(
  'workspaces/create-price-list',
  ({ priceList, getState, workspaceId: wid }) => {
    const state = getState();
    const workspaceId = wid || getWorkspaceId(state);

    return api.workspaces.createPriceList({ workspaceId, priceList });
  }
);

export const fetchPublicContract = createActionThunk(
  'workspaces/fetch-public-contract',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces.fetchPublicContract({ workspaceId });
  }
);

export const createPublicContract = createActionThunk(
  'workspaces/create-public-contract',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces.createPublicContract({ workspaceId });
  }
);

export const fetchNotificationSettings = createActionThunk(
  'workspaces/fetch-notification-settings',
  ({ getState }) => {
    const state = getState();
    const { id, user } = getActiveWorkspace(state);

    return api.employees
      .fetchSettings({ workspaceId: id, id: user.id })
      .then(data => ({
        settings: data,
        workspaceId: id
      }));
  }
);

export const setNotificationSettings = createActionThunk(
  'workspaces/set-notification-settings',
  ({ field, value, getState, enableSms }) => {
    const state = getState();
    const { id, user } = getActiveWorkspace(state);

    return api.employees
      .setSettings({ workspaceId: id, id: user.id, field, value, enableSms })
      .then(data => ({
        settings: data,
        workspaceId: id
      }));
  }
);

export const deleteWorkspace = createActionThunk(
  'workspaces/delete',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces
      .deleteWorkspace({ workspaceId })
      .then(() => ({ workspaceId, isArchived: true }));
  }
);

export const restoreWorkspace = createActionThunk(
  'workspaces/restore',
  ({ workspaceId }) =>
    api.workspaces
      .restoreWorkspace({ workspaceId })
      .then(() => ({ workspaceId, isArchived: false }))
);

export const checkCanDeleteWorkspace = createActionThunk(
  'workspaces/can-delete',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.workspaces
      .checkCanDeleteWorkspace({ workspaceId })
      .then(({ data }) => data);
  }
);

export const updateWorkspaceLegatInfo = createActionThunk(
  'workspaces/update-legat-info',
  ({ workspaceId }) =>
    api.workspaces
      .updateWorkspaceLegatInfo({ workspaceId })
      .then(({ data }) => data)
);
