import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsField from '../details-field';

const Location = ({ location, locationExact, className }) => {
  const { t } = useTranslation('Common');

  return (
    <DetailsField
      iconType="environment"
      title={t('Аddress')}
      className={className}
    >
      {location} {locationExact}
    </DetailsField>
  );
};

Location.propTypes = {
  location: PropTypes.string.isRequired,
  locationExact: PropTypes.string,
  className: PropTypes.string
};

Location.defaultProps = {
  locationExact: undefined,
  className: undefined
};

export default Location;
