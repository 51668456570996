import qs from 'qs';

import { CONTACTS_COMPANIES } from 'constants/api';

export default api => ({
  fetch: ({
    workspaceId,
    kind,
    responsible,
    ordering,
    search,
    limit,
    offset,
    isIndividual,
    onlyHasContacts,
    lite
  }) =>
    api.get(`/v1/${workspaceId}/${CONTACTS_COMPANIES}/`, {
      params: {
        kind,
        responsible,
        ordering,
        search,
        limit,
        offset,
        is_individual: isIndividual,
        only_has_contacts: onlyHasContacts,
        lite
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  search: ({
    workspaceId,
    country,
    search,
    vatNumber,
    limit,
    offset,
    errorHandle = true
  }) =>
    api.get(`/v1/${workspaceId}/${CONTACTS_COMPANIES}/search/`, {
      params: {
        country,
        search,
        vat_number: vatNumber,
        limit,
        offset
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
      errorHandle
    }),

  fetchOne: (id, workspaceId) =>
    api
      .get(`/v1/${workspaceId}/${CONTACTS_COMPANIES}/${id}/`)
      .then(({ data }) => data),

  edit: (workspaceId, contact) =>
    api.patch(
      `/v1/${workspaceId}/${CONTACTS_COMPANIES}/${contact.id}/`,
      contact
    ),

  create: (workspaceId, contact) =>
    api.post(`/v1/${workspaceId}/${CONTACTS_COMPANIES}/`, contact),

  fetchHistory: ({ workspaceId, id, limit, offset }) =>
    api.get(`/v1/${workspaceId}/${CONTACTS_COMPANIES}/${id}/log/`, {
      params: { limit, offset }
    }),

  sendComment: ({ workspaceId, id, data }) =>
    api.post(
      `/v1/${workspaceId}/${CONTACTS_COMPANIES}/${id}/log/comments/`,
      data
    )
});
