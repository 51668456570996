import React from 'react';
import { Translation } from 'react-i18next';

import Typography from 'components/common/typography';

import styles from './view-info.module.scss';

const Reports = () => (
  <div className={styles.reports}>
    <Typography.Title level={2} className={styles.title}>
      <Translation ns="Reports">{t => t('ReportsHeading')}</Translation>
    </Typography.Title>
  </div>
);

export default Reports;
