import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { EMAIL, VIDEOS } from 'constants/index';

import FormMarkdownEditor from 'components/common/hook-form/markdown-editor';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import mapValue from 'utils/map-value';

import CommonChannelForm from '../common-channel';
import { transformSubmitScheduleConfigValues } from '../../components/operator-hours/utils';
import { transformSubmitSlaConfigValues } from '../../components/sla-settings/utils';
import OperatorHours from '../../components/operator-hours';
import SlaSettings from '../../components/sla-settings';

import styles from './email-channel.module.scss';

const EmailChannelForm = ({
  defaultValues,
  isLoading,
  isEditor,
  isOnlyView,
  onSubmit
}) => {
  const { t } = useTranslation(['ConnectEmail', 'Errors']);

  const handleSubmit = ({
    responsible,
    operators = [],
    message,
    imapPort,
    imapServer,
    isImapSsl,
    smtpServer,
    smtpPort,
    isSmtpSsl,
    email,
    password,
    name,
    scheduleConfig,
    slaConfig,
    ...values
  }) =>
    onSubmit({
      ...values,
      name,
      kind: EMAIL,
      responsible: responsible.value,
      operators: operators.map(mapValue),
      config: {
        name,
        email,
        password,
        imapPort: +imapPort,
        imapServer,
        isImapSsl,
        smtpServer,
        smtpPort: +smtpPort,
        isSmtpSsl,
        scheduleConfig: transformSubmitScheduleConfigValues(scheduleConfig),
        slaConfig: transformSubmitSlaConfigValues(slaConfig)
      }
    });

  return (
    <CommonChannelForm
      defaultValues={defaultValues}
      isLoading={isLoading}
      isEditor={isEditor}
      isOnlyView={isOnlyView}
      onSubmit={handleSubmit}
      videoSlug={VIDEOS.setupEmailChannel}
    >
      {({ isActiveScheduleConfigWatch }) => (
        <div>
          <OperatorHours isOnlyView={isOnlyView} />

          {isActiveScheduleConfigWatch && (
            <>
              <Typography.Title
                level={3}
                className={styles.welcomeMessageTitle}
              >
                {t('AutomatedAnswerHeading')}
                <Tooltip title={t('AutomatedAnswerTip')}>
                  <Icon
                    type="question-circle"
                    theme="filled"
                    color="black-55"
                    size={15}
                  />
                </Tooltip>
              </Typography.Title>

              <FormMarkdownEditor
                name="scheduleConfig.nonWorkingTimeMessage"
                label={t('AutomatedAnswer')}
                toolbarHidden
                allowAttach={false}
                placeholder={t('AutomatedAnswer')}
                readOnly={isOnlyView}
                uploadProps={{
                  disabled: isOnlyView
                }}
                itemProps={{
                  className: styles.editor
                }}
                rules={{
                  validate: value =>
                    !!value.description || t('RequiredField', { ns: 'Errors' })
                }}
              />

              <Divider />
            </>
          )}

          <SlaSettings isOnlyView={isOnlyView} />

          <Divider />
        </div>
      )}
    </CommonChannelForm>
  );
};

EmailChannelForm.propTypes = {
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

EmailChannelForm.defaultProps = {
  defaultValues: {},
  isEditor: false,
  isOnlyView: false
};

export default EmailChannelForm;
