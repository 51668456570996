import { useDispatch, useSelector } from 'react-redux';

import { PREFIX_FILTER } from 'components/common/filter-pane';

import {
  getCollapsedFilterPanel,
  setCollapsedContactsFilterPanel
} from 'store/contacts';

import { useLocalStorage } from 'hooks';

export const COLLAPSED_CONTACTS_FILTER_PANEL = `${PREFIX_FILTER}collapsed-contacts-filter-panel`;

export const useCollapsedContactsFilterPanel = () => {
  const dispatch = useDispatch();

  const collapsed = useSelector(getCollapsedFilterPanel);
  const [, setStorageCollapsed] = useLocalStorage(
    COLLAPSED_CONTACTS_FILTER_PANEL,
    {
      opened: true,
      width: 380
    }
  );

  const setCollapsed = value => {
    setStorageCollapsed({ ...collapsed, opened: !value });
    dispatch(setCollapsedContactsFilterPanel({ opened: !value }));
  };

  return [collapsed, setCollapsed];
};

export default useCollapsedContactsFilterPanel;
