import React from 'react';

export const ForwardInboxIcon = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7623 0.285713H2.61942C1.44085 0.285713 0.476562 1.25 0.476562 2.42857V15.2857C0.476562 16.4643 1.44085 17.4286 2.61942 17.4286H12.2623V15.2857H2.61942V4.57143L10.0551 9.22143C10.7516 9.66071 11.6301 9.66071 12.3266 9.22143L19.7623 4.57143V9.92857H21.9051V2.42857C21.9051 1.25 20.9408 0.285713 19.7623 0.285713ZM11.1908 7.78571L2.61942 2.42857H19.7623L11.1908 7.78571ZM18.6908 13.3679C18.6908 12.8857 19.2694 12.65 19.6016 12.9929L22.5908 15.9821C22.8051 16.1964 22.8051 16.5286 22.5908 16.7429L19.6016 19.7321C19.2694 20.0643 18.6908 19.8286 18.6908 19.3464V17.4286H15.4766C14.8873 17.4286 14.4051 16.9464 14.4051 16.3571C14.4051 15.7679 14.8873 15.2857 15.4766 15.2857H18.6908V13.3679Z"
      fill="white"
    />
  </svg>
);

export default ForwardInboxIcon;
