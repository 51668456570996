import React from 'react';

export const DeleteReportIcon = () => (
  <svg
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_74248_408295)">
      <path
        d="M99.1683 16.1203C108.444 17.0648 117.423 18.8268 117.423 18.8268C120.13 19.3625 121.483 19.6585 122.864 19.9827C140.626 24.1412 151.593 31.7533 144.418 49.9235C143.798 51.4742 143.473 53.053 143.347 54.7727C142.867 61.9337 145.94 70.7017 148.097 79.5542C148.619 81.6687 149.041 83.755 149.366 85.9399C149.732 88.435 150 90.9159 149.986 93.2559C150.042 97.3298 149.295 101.305 147.547 105.139C147.547 105.139 145.701 108.959 142.289 112.357C138.173 116.374 90.4004 154.42 43.5298 130.893C24.3023 121.223 -3.18574 97.1466 0.296072 65.8948C3.07307 41.1133 24.1472 22.4637 42.5148 16.2472C55.9064 11.7222 70.3271 13.1883 99.1683 16.1203Z"
        fill="#EBF8F8"
      />
      <path
        d="M136.764 46.1459L139.583 97.189C139.921 103.391 135.157 108.72 128.926 109.058L95.9967 110.877L91.9369 106.605L85.2129 99.5431L81.1814 95.3283L77.9533 91.9311L78.5876 88.0404L79.3206 83.5578L80.5611 76.0726L81.3223 71.4349L82.3373 65.317L77.7982 59.4811L73.3438 53.7579L74.6688 49.8673L75.8106 46.4983L77.4458 41.6632L78.5876 38.2801L80.9699 31.2319L116.577 29.2725L136.764 46.1318V46.1459Z"
        fill="white"
      />
      <path
        d="M117.55 47.2027L116.563 29.2861L136.764 46.1455L117.55 47.2027Z"
        fill="#32C9C9"
      />
      <path
        d="M85.509 39.5626C85.5653 40.4789 84.8605 41.2542 83.9583 41.3106L77.4458 41.663L78.5876 38.2799L83.7751 37.9979C84.6914 37.9556 85.4667 38.6464 85.509 39.5626Z"
        fill="#32C9C9"
      />
      <path
        d="M81.294 47.8657C81.3504 48.782 80.6456 49.5573 79.7293 49.5996L74.6687 49.8815L75.8105 46.5125L79.5461 46.301C80.4623 46.2446 81.2376 46.9494 81.294 47.8657Z"
        fill="#32C9C9"
      />
      <path
        d="M126.361 58.7761L126.812 66.9803C126.868 68.0516 126.05 68.9819 124.965 69.0383L81.3224 71.4347L82.3374 65.3169L77.7983 59.481L124.302 56.9295C125.374 56.8731 126.304 57.6907 126.361 58.7761Z"
        fill="#32C9C9"
      />
      <path
        d="M127.248 75.0297L127.488 79.3855C127.53 80.2313 126.896 80.9502 126.05 80.9925L79.3206 83.558L80.561 76.0728L125.641 73.5918C126.487 73.5496 127.206 74.1839 127.248 75.0297Z"
        fill="#EFEDED"
      />
      <path
        d="M127.897 86.8705L128.137 91.2263C128.179 92.0721 127.53 92.791 126.699 92.8333L81.1673 95.3424L77.9392 91.9452L78.5735 88.0546L126.29 85.4327C127.122 85.3904 127.855 86.0247 127.897 86.8705Z"
        fill="#EFEDED"
      />
      <path
        d="M128.545 98.6975L128.785 103.053C128.827 103.899 128.193 104.618 127.347 104.66L91.9369 106.606L85.2129 99.5433L126.952 97.2456C127.798 97.1892 128.517 97.8377 128.559 98.6834L128.545 98.6975Z"
        fill="#EFEDED"
      />
      <path
        d="M84.3389 106.803L34.4799 109.679C33.6482 109.721 32.9152 109.072 32.8729 108.241L32.6192 103.871C32.5769 103.039 33.2253 102.306 34.057 102.264L77.5868 99.7688L73.5552 95.5681L33.8174 97.8517C32.9716 97.894 32.2527 97.2597 32.2104 96.4139L31.9567 92.0581C31.9144 91.2123 32.5487 90.4934 33.3945 90.437L70.9473 88.2803L71.6804 83.7976L33.1408 86.0107C32.295 86.053 31.576 85.4187 31.5338 84.5729L31.28 80.2171C31.2377 79.3713 31.8862 78.6524 32.7179 78.6101L72.9067 76.2983L73.6539 71.6606L32.7038 74.0147C31.6324 74.0711 30.7021 73.2535 30.6457 72.1821L30.1805 63.978C30.1241 62.9067 30.9417 61.9763 32.013 61.92L70.1016 59.7209L65.633 54.0119L66.944 50.1212L63.4903 50.3186C62.5741 50.375 61.7988 49.6702 61.7565 48.7539C61.7001 47.8517 62.4049 47.0764 63.3071 47.02L68.0858 46.7522L69.7069 41.903L30.7021 44.1444C29.7858 44.2007 29.0105 43.4959 28.9541 42.5938C28.8977 41.6775 29.6025 40.9022 30.5188 40.8458L70.8487 38.5199L73.2169 31.4717L28.6581 34.0372C22.4416 34.4037 17.677 39.7322 18.0435 45.9487L21.3561 103.645C21.7227 109.848 27.0511 114.612 33.2676 114.26L88.441 111.088L84.3671 106.831L84.3389 106.803ZM30.9558 48.8667L53.9894 47.5416C54.9056 47.4852 55.6809 48.19 55.7373 49.1063C55.7937 50.0226 55.0889 50.7979 54.1726 50.8402L31.1391 52.1652C30.2228 52.2216 29.4475 51.5168 29.3911 50.6146C29.3347 49.6984 30.0395 48.923 30.9558 48.8667Z"
        fill="white"
      />
      <path
        d="M31.1532 52.1795C30.2369 52.2359 29.4616 51.5311 29.4053 50.6289C29.3489 49.7126 30.0537 48.9373 30.97 48.881L54.0035 47.5559C54.9198 47.4995 55.6951 48.2043 55.7515 49.1206C55.8078 50.0369 55.103 50.8122 54.1868 50.8545L31.1532 52.1795Z"
        fill="white"
      />
      <path
        d="M37.722 82.0214C37.6656 81.1192 38.3563 80.358 39.2585 80.3016L46.4477 79.8928C47.3498 79.8364 48.111 80.5412 48.1674 81.4434C48.2238 82.3315 47.5331 83.1068 46.6309 83.1632L39.4417 83.572C38.5396 83.6284 37.7643 82.9235 37.722 82.0214Z"
        fill="white"
      />
      <path
        d="M51.8184 81.2183C51.762 80.3161 52.4527 79.5549 53.3549 79.4985L60.2622 79.1038C61.1643 79.0474 61.9255 79.7381 61.9819 80.6403C62.0383 81.5425 61.3476 82.3037 60.4454 82.3601L53.5382 82.7689C52.636 82.8112 51.8748 82.1204 51.8184 81.2183Z"
        fill="white"
      />
      <path
        d="M38.3987 93.8627C38.3423 92.9605 39.0471 92.1993 39.9352 92.1429L47.1384 91.72C48.0406 91.6636 48.8018 92.3544 48.8582 93.2565C48.9146 94.1587 48.2239 94.934 47.3217 94.9904L40.1184 95.4133C39.2304 95.4556 38.4551 94.7648 38.3987 93.8627Z"
        fill="white"
      />
      <path
        d="M52.4952 93.0444C52.4388 92.1423 53.1295 91.3811 54.0317 91.3247L60.9248 90.93C61.827 90.8736 62.6023 91.5643 62.6446 92.4665C62.701 93.3687 62.0102 94.1299 61.1081 94.1863L54.2149 94.581C53.3128 94.6373 52.5516 93.9466 52.4952 93.0444Z"
        fill="white"
      />
      <path
        d="M46.5464 105.281C46.6028 106.183 45.9121 106.958 45.0099 107.001L37.8067 107.424C36.9186 107.48 36.1433 106.789 36.0869 105.887C36.0305 104.985 36.7353 104.224 37.6234 104.167L44.8267 103.744C45.7289 103.688 46.4901 104.379 46.5464 105.281Z"
        fill="white"
      />
      <path
        d="M55.7373 49.106C55.7937 50.0222 55.0889 50.7975 54.1726 50.8398L31.1391 52.1649C30.2228 52.2213 29.4475 51.5164 29.3911 50.6143C29.3347 49.698 30.0395 48.9227 30.9558 48.8663L53.9894 47.5412C54.9056 47.4849 55.6809 48.1897 55.7373 49.106Z"
        fill="#32C9C9"
      />
      <path
        d="M70.8204 38.5059L69.6927 41.9031L30.6879 44.1444C29.7716 44.2008 28.9963 43.496 28.9399 42.5938C28.8835 41.6776 29.5884 40.9023 30.5046 40.8459L70.8345 38.52L70.8204 38.5059Z"
        fill="#32C9C9"
      />
      <path
        d="M68.0718 46.7383L66.9441 50.1214L63.4904 50.3188C62.5742 50.3752 61.7989 49.6703 61.7566 48.7541C61.7002 47.8519 62.405 47.0766 63.3072 47.0202L68.0859 46.7524L68.0718 46.7383Z"
        fill="#32C9C9"
      />
      <path
        d="M74.6405 65.5425L73.6397 71.6604L32.6896 74.0145C31.6183 74.0709 30.6879 73.2533 30.6315 72.1819L30.1663 63.9778C30.1099 62.9065 30.9275 61.9761 31.9989 61.9197L70.0874 59.7207L74.6405 65.5425Z"
        fill="#32C9C9"
      />
      <path
        d="M32.7037 78.6097C31.872 78.652 31.2236 79.3709 31.2659 80.2167L31.5196 84.5725C31.5619 85.4182 32.2808 86.0526 33.1266 86.0103L71.6662 83.7971L72.8926 76.2979L32.7037 78.6097ZM46.6451 83.1628L39.4559 83.5716C38.5537 83.628 37.7784 82.9232 37.7361 82.021C37.6797 81.1188 38.3705 80.3576 39.2726 80.3012L46.4618 79.8924C47.364 79.8361 48.1252 80.5409 48.1816 81.443C48.238 82.3311 47.5472 83.1064 46.6451 83.1628ZM60.4455 82.3593L53.5382 82.7681C52.636 82.8104 51.8748 82.1197 51.8184 81.2175C51.7621 80.3153 52.4528 79.5541 53.355 79.4977L60.2622 79.103C61.1644 79.0467 61.9256 79.7374 61.982 80.6395C62.0384 81.5417 61.3476 82.3029 60.4455 82.3593Z"
        fill="#EFEDED"
      />
      <path
        d="M48.1816 81.4434C48.238 82.3315 47.5472 83.1068 46.6451 83.1632L39.4559 83.572C38.5537 83.6284 37.7784 82.9235 37.7361 82.0214C37.6797 81.1192 38.3705 80.358 39.2726 80.3016L46.4618 79.8928C47.364 79.8364 48.1252 80.5412 48.1816 81.4434Z"
        fill="#EFEDED"
      />
      <path
        d="M61.9819 80.6403C62.0383 81.5425 61.3476 82.3037 60.4454 82.3601L53.5382 82.7689C52.636 82.8112 51.8748 82.1204 51.8184 81.2183C51.762 80.3161 52.4527 79.5549 53.3549 79.4985L60.2622 79.1038C61.1643 79.0474 61.9255 79.7381 61.9819 80.6403Z"
        fill="#EFEDED"
      />
      <path
        d="M70.3129 92.1713L70.9473 88.2666L33.3944 90.4234C32.5486 90.4797 31.9143 91.1987 31.9566 92.0444L32.2103 96.4002C32.2526 97.246 32.9715 97.8804 33.8173 97.8381L73.5551 95.5545L70.3129 92.1572V92.1713ZM47.3217 94.9906L40.1184 95.4135C39.2303 95.4558 38.455 94.7651 38.3987 93.8629C38.3423 92.9607 39.0471 92.1995 39.9352 92.1431L47.1384 91.7202C48.0406 91.6638 48.8018 92.3546 48.8582 93.2567C48.9146 94.1589 48.2239 94.9342 47.3217 94.9906ZM61.108 94.1871L54.2148 94.5818C53.3127 94.6382 52.5515 93.9475 52.4951 93.0453C52.4387 92.1431 53.1294 91.3819 54.0316 91.3255L60.9247 90.9308C61.8269 90.8744 62.6022 91.5652 62.6445 92.4673C62.7009 93.3695 62.0102 94.1307 61.108 94.1871Z"
        fill="#EFEDED"
      />
      <path
        d="M48.8582 93.2565C48.9146 94.1587 48.2239 94.934 47.3217 94.9904L40.1184 95.4133C39.2304 95.4556 38.4551 94.7648 38.3987 93.8627C38.3423 92.9605 39.0471 92.1993 39.9352 92.1429L47.1384 91.72C48.0406 91.6636 48.8018 92.3544 48.8582 93.2565Z"
        fill="#EFEDED"
      />
      <path
        d="M62.6587 92.4665C62.7151 93.3687 62.0244 94.1299 61.1222 94.1863L54.2291 94.581C53.3269 94.6373 52.5657 93.9466 52.5093 93.0444C52.4529 92.1423 53.1437 91.3811 54.0458 91.3247L60.939 90.93C61.8412 90.8736 62.6165 91.5643 62.6587 92.4665Z"
        fill="#EFEDED"
      />
      <path
        d="M77.6009 99.7686L34.0711 102.264C33.2395 102.306 32.591 103.039 32.6333 103.871L32.887 108.241C32.9293 109.072 33.6623 109.721 34.494 109.678L84.3531 106.803L77.615 99.7686H77.6009ZM45.01 107.014L37.8067 107.437C36.9186 107.493 36.1433 106.803 36.0869 105.901C36.0305 104.998 36.7354 104.237 37.6234 104.181L44.8267 103.758C45.7289 103.701 46.4901 104.392 46.5465 105.294C46.6029 106.197 45.9121 106.972 45.01 107.014Z"
        fill="#EFEDED"
      />
      <path
        d="M46.5464 105.281C46.6028 106.183 45.9121 106.958 45.0099 107.001L37.8067 107.424C36.9186 107.48 36.1433 106.789 36.0869 105.887C36.0305 104.985 36.7353 104.224 37.6234 104.167L44.8267 103.744C45.7289 103.688 46.4901 104.379 46.5464 105.281Z"
        fill="#EFEDED"
      />
      <path
        d="M48.1816 81.4434C48.238 82.3315 47.5472 83.1068 46.6451 83.1632L39.4559 83.572C38.5537 83.6284 37.7784 82.9235 37.7361 82.0214C37.6797 81.1192 38.3705 80.358 39.2726 80.3016L46.4618 79.8928C47.364 79.8364 48.1252 80.5412 48.1816 81.4434Z"
        fill="white"
      />
      <path
        d="M61.9819 80.6403C62.0383 81.5425 61.3476 82.3037 60.4454 82.3601L53.5382 82.7689C52.636 82.8112 51.8748 82.1204 51.8184 81.2183C51.762 80.3161 52.4527 79.5549 53.3549 79.4985L60.2622 79.1038C61.1643 79.0474 61.9255 79.7381 61.9819 80.6403Z"
        fill="white"
      />
      <path
        d="M48.8582 93.2565C48.9146 94.1587 48.2239 94.934 47.3217 94.9904L40.1184 95.4133C39.2304 95.4556 38.4551 94.7648 38.3987 93.8627C38.3423 92.9605 39.0471 92.1993 39.9352 92.1429L47.1384 91.72C48.0406 91.6636 48.8018 92.3544 48.8582 93.2565Z"
        fill="white"
      />
      <path
        d="M46.5464 105.281C46.6028 106.183 45.9121 106.958 45.0099 107.001L37.8067 107.424C36.9186 107.48 36.1433 106.789 36.0869 105.887C36.0305 104.985 36.7353 104.224 37.6234 104.167L44.8267 103.744C45.7289 103.688 46.4901 104.379 46.5464 105.281Z"
        fill="white"
      />
      <path
        d="M62.6587 92.4665C62.7151 93.3687 62.0244 94.1299 61.1222 94.1863L54.2291 94.581C53.3269 94.6373 52.5657 93.9466 52.5093 93.0444C52.4529 92.1423 53.1437 91.3811 54.0458 91.3247L60.939 90.93C61.8412 90.8736 62.6165 91.5643 62.6587 92.4665Z"
        fill="white"
      />
      <path
        d="M57.2737 107.155L31.5054 76.8191C28.4888 73.2668 22.822 74.0562 20.8767 78.2852L4.32751 114.485C2.24124 119.038 6.01908 124.113 10.981 123.422L53.2985 117.558C58.2604 116.867 60.5158 110.961 57.2737 107.141V107.155Z"
        fill="#F9716C"
      />
      <path
        d="M13.8426 113.174L30.3918 76.9745C30.5187 76.6785 30.6737 76.4107 30.8429 76.157C27.7276 73.4082 22.681 74.3526 20.8767 78.2996L4.32751 114.499C2.24124 119.052 6.01908 124.127 10.981 123.436L20.4961 122.111C15.5342 122.802 11.7563 117.727 13.8426 113.174Z"
        fill="#F7413B"
      />
      <path
        d="M30.8851 103.067L28.6156 103.377C27.4174 103.546 26.3037 102.7 26.1346 101.502L24.2457 87.9132C24.0765 86.715 24.9223 85.6014 26.1205 85.4323L28.39 85.1221C29.5882 84.953 30.7018 85.7988 30.871 86.997L32.7599 100.586C32.9291 101.784 32.0833 102.898 30.8851 103.067Z"
        fill="white"
      />
      <path
        d="M31.3785 114.965C33.197 114.711 34.4797 113.019 34.226 111.201C33.9723 109.382 32.2807 108.1 30.4623 108.353C28.6438 108.607 27.361 110.299 27.6148 112.117C27.8685 113.935 29.546 115.218 31.3785 114.965Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_74248_408295">
        <rect
          width="150"
          height="125.148"
          fill="white"
          transform="translate(0 13.3574)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DeleteReportIcon;
