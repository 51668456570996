import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON,
  OUTGOING
} from 'constants/index';

import Button from 'components/common/button';

import { getUserEmployee } from 'store/workspace';
import { editContact } from 'store/contacts';

import { transformFormSubmittedValues } from '../utils';
import useShortFormFields from './use-short-form';
import useRenderFields from './use-render-fields';

import styles from './short.module.scss';

const ShortForm = ({
  isCreate,
  isCopy,
  isEdit,
  withContractor,
  isLoading,
  defaultValues,
  defaultKind,
  onSubmit,
  onIsHiringChange
}) => {
  const dispatch = useDispatch();
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation('Common');

  const [relationsVisible, setRelationsVisible] = useState(false);

  const isRelationsVisible = relationsVisible || defaultValues.relations;

  const {
    methods,
    contractorField,
    positionField,
    profileLinkField,
    isHiring,
    isIncoming,
    minTimeEnd,
    maxTimeEnd
  } = useShortFormFields(defaultValues, defaultKind, employee);

  useEffect(() => {
    if (onIsHiringChange) {
      onIsHiringChange(isHiring);
    }
  }, [isHiring, onIsHiringChange, defaultValues.kind]);

  useEffect(() => {
    if (isHiring && contractorField && contractorField.value) {
      const { position, profileLink } = contractorField.label;

      methods.setValue('position', position || '');
      methods.setValue('profileLink', profileLink || '');
    }
  }, [contractorField, defaultValues.kind, isCopy, isHiring, methods]);

  const handleSubmit = async values => {
    if (
      isHiring &&
      contractorField &&
      contractorField.label &&
      (positionField !== contractorField.label.position ||
        profileLinkField !== contractorField.label.profileLink)
    ) {
      await dispatch(
        editContact({
          id: contractorField.value,
          values: { position: positionField, profileLink: profileLinkField }
        })
      );
    }

    const { position, profileLink, ...newValues } = values;

    onSubmit(
      transformFormSubmittedValues({ values: newValues, isShortForm: true, t })
    );
  };

  const renderFields = useRenderFields({
    methods,
    defaultValues,
    isHiring,
    isCreate,
    isEdit,
    isCopy,
    relationsVisible: isRelationsVisible,
    setRelationsVisible,
    withContractor,
    contractorField,
    isIncoming,
    minTimeEnd,
    maxTimeEnd
  });

  return (
    <FormProvider {...methods}>
      <form
        className={styles.formWrapper}
        style={{
          gap: isEdit && !isRelationsVisible ? '24px' : undefined
        }}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <div className={styles.root}>{renderFields}</div>

        <div className={styles.submitWrap}>
          <Button
            type="primary"
            size="medium"
            htmlType="submit"
            className={styles.submitBtn}
            loading={isLoading}
            data-testid={TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON}
          >
            {t(!isCreate ? 'Save' : 'Add', { ns: 'Common' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

ShortForm.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  isCopy: PropTypes.bool,
  withContractor: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  defaultKind: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onIsHiringChange: PropTypes.func
};

ShortForm.defaultProps = {
  isLoading: false,
  defaultValues: {},
  defaultKind: OUTGOING,
  isCreate: false,
  isEdit: false,
  isCopy: false,
  withContractor: false,
  onIsHiringChange: undefined
};

export default ShortForm;
