import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';
import {
  formattedValidityDate,
  isAfterCurrentDateTime
} from 'components/common/validity-date/utils/formated-date';

import styles from './validity-date.module.scss';

const ValidityDate = ({ file }) => {
  const { validityDate } = file;

  const isValidityDateOutdated =
    !!validityDate && !isAfterCurrentDateTime(validityDate);

  return (
    <Typography.Text
      style={{ display: 'block', maxWidth: 110 }}
      className={classnames({
        [styles.validityDateOutdated]: isValidityDateOutdated
      })}
    >
      {validityDate ? formattedValidityDate(validityDate) : '-'}
    </Typography.Text>
  );
};

ValidityDate.propTypes = {
  file: PropTypes.shape({
    validityDate: PropTypes.string
  }).isRequired
};

export default ValidityDate;
