import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import { createDefer } from 'store/contacts';

import { showNoticeMessage } from 'services/notice';

import Form from './form';

import styles from './defer-dialog.module.scss';

const DeferDialogModal = ({ visible, onClose, contactId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Contacts');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ deferUntil, comment }) => {
    try {
      setIsLoading(true);

      await dispatch(
        createDefer({
          contactId,
          payload: { deferUntil, comment: comment || null }
        })
      );

      showNoticeMessage({ customContent: t('DialogSnoozedToast') });
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={440}
      destroyOnClose
      centered
      contentClassName={styles.modal}
      visible={visible}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit} isLoading={isLoading} />
    </Modal>
  );
};

DeferDialogModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactId: PropTypes.number
};

DeferDialogModal.defaultProps = {
  contactId: null
};

export default DeferDialogModal;
