import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlsParentMessage } from 'components/common/chat/controls';
import { convertMessageToString } from 'components/common/comments/converters';
import { getMarkdownHtml } from 'components/common/markdown';

import Header from './header';

const convertHtmlToPlain = html => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;

  return tempDivElement.textContent || tempDivElement.innerText || '';
};

const Parent = ({ data, isMessageContent, onClick, onDelete }) => {
  const { t } = useTranslation('Contacts');

  const { sender, subject, text, uuid, fileList } = data;

  const content = useMemo(() => {
    if (subject) {
      return `${t('EmailTheme')}: ${subject}`;
    }
    const markdownString = convertMessageToString(text);
    const htmlString = getMarkdownHtml({ children: markdownString });

    return convertHtmlToPlain(htmlString);
  }, [subject, t, text]);

  return (
    <ControlsParentMessage
      header={
        <Header sender={sender} recipients={[]} isFrom="" isComment={false} />
      }
      content={content}
      fileList={fileList}
      isMessageContent={isMessageContent}
      onClick={() => onClick(uuid)}
      onDelete={onDelete}
    />
  );
};

export default Parent;
