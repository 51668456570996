import React, { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { PhoneInput as PInput } from 'components/common/controls/phone-input';

import FormItem from '../form-item';
import { validatePhone } from '../validators';

export const FormPhoneInput = forwardRef(
  ({ size, label, name, rules, itemProps, isOnlyNumber, ...props }, ref) => {
    const { control, formState } = useFormContext();

    return (
      <FormItem
        label={label}
        name={name}
        {...itemProps}
        errors={formState.errors}
      >
        <Controller
          control={control}
          rules={{
            validate: v => rules.required && validatePhone(v, { isOnlyNumber }),
            ...rules
          }}
          render={({ field }) => {
            const { value, onChange, ...phoneInputProps } = field;

            return (
              <PInput
                value={isOnlyNumber ? value : value.number}
                size={size}
                errors={formState.errors}
                onChange={(number, _, countryCode, dialCode) => {
                  const resultValue = isOnlyNumber
                    ? number
                    : {
                        number,
                        countryCode,
                        dialCode
                      };

                  onChange(resultValue);
                }}
                {...phoneInputProps}
                {...props}
                ref={ref}
              />
            );
          }}
        />
      </FormItem>
    );
  }
);

FormPhoneInput.propTypes = {
  rules: PropTypes.any,
  label: PropTypes.string,
  isOnlyNumber: PropTypes.bool,
  itemProps: PropTypes.object,
  size: PropTypes.oneOf(['default', 'large', 'small']),
  name: PropTypes.string.isRequired
};

FormPhoneInput.defaultProps = {
  rules: {},
  label: undefined,
  itemProps: {},
  isOnlyNumber: true,
  size: 'default'
};

export default FormPhoneInput;
