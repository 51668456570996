import {
  WEBSITE,
  TELEGRAM,
  EMAIL,
  WEBSITE_FORM,
  SYSTEM_LOGO,
  WHATSAPP
} from 'constants/index';

import base64ToFile from 'utils/base64-to-file';
import unescapeSingleQuotes from 'utils/unescape-single-quotes';

import { filterSources } from '../../utils';
import { transformScheduleConfigValues } from '../../components/operator-hours/utils';
import { transformSlaConfigValues } from '../../components/sla-settings/utils';
import { LANGUAGE_OPTIONS } from '../creator/steps/utils';

// eslint-disable-next-line import/prefer-default-export
export const transformDefaultValues = (channel = {}) => {
  if (channel.kind === WEBSITE) {
    const {
      config,
      responsible,
      operators,
      telegramChannel,
      whatsappChannel
    } = channel;

    const paddings = config.paddings || [25, 25];
    const isRightPosition = config.position === 'right';
    const left = !isRightPosition ? paddings[0] : 25;
    const right = isRightPosition ? paddings[0] : 25;
    const bottom = paddings[1];
    const inviteDelay = config.inviteDelay || 15;
    const filteredSources = filterSources(channel.sources);

    return {
      ...channel,
      ...config,
      scheduleConfig: transformScheduleConfigValues(config.scheduleConfig),
      slaConfig: transformSlaConfigValues(config.slaConfig),
      language:
        LANGUAGE_OPTIONS.find(l => l.value === config.language) ||
        LANGUAGE_OPTIONS[0],
      inviteDelay,
      name: channel.name,
      bottom,
      right,
      left,
      message: config.message && { description: config.message },
      title: config.name,
      responsible: responsible && {
        value: responsible.id,
        label: responsible
      },
      withTelegram: !!telegramChannel,
      telegramBot: telegramChannel
        ? {
            value: telegramChannel.id,
            label: { title: telegramChannel.name, ...telegramChannel }
          }
        : null,
      withWhatsapp: !!whatsappChannel,
      whatsapp: whatsappChannel
        ? {
            value: whatsappChannel.id,
            label: { title: whatsappChannel.name, ...whatsappChannel }
          }
        : null,
      botPosition: config.botPosition || 'vertical',
      sources: (filteredSources || []).length
        ? filteredSources.map(source => ({
            value: source,
            label: source
          }))
        : [],
      operators: (operators || []).map(o => ({
        value: o.id,
        label: o
      }))
    };
  }

  if (channel.kind === TELEGRAM) {
    const { config, responsible, operators, source, ...channelValue } = channel;

    return {
      ...channelValue,
      ...config,
      name: channel.name,
      source: config.name,
      token: source,
      responsible: responsible && {
        value: responsible.id,
        label: responsible
      },
      operators: (operators || []).map(o => ({
        value: o.id,
        label: o
      })),
      scheduleConfig: transformScheduleConfigValues(config.scheduleConfig),
      slaConfig: transformSlaConfigValues(config.slaConfig)
    };
  }

  if (channel.kind === EMAIL) {
    const { responsible, operators, config, ...channelValue } = channel;

    return {
      ...channelValue,
      ...config,
      name: channel.name,
      responsible: responsible && {
        value: responsible.id,
        label: responsible
      },
      operators: (operators || []).map(o => ({
        value: o.id,
        label: o
      })),
      scheduleConfig: transformScheduleConfigValues(config.scheduleConfig),
      slaConfig: transformSlaConfigValues(config.slaConfig)
    };
  }

  if (channel.kind === WEBSITE_FORM) {
    const { config, responsible, operators } = channel;

    const logo = base64ToFile(config.pageLogo, 'logo');
    const backgroundImage = base64ToFile(config.pageBackground, 'background');

    return {
      ...channel,
      ...config,
      buttonText: unescapeSingleQuotes(config.buttonText),
      channelName: channel.name,
      language: { value: config.language, label: config.language },
      name: 'Name',
      message: unescapeSingleQuotes(config.message),
      formElements: config.fields,
      title: unescapeSingleQuotes(config.title),
      description: {
        description: unescapeSingleQuotes(config.description)
      },
      responsible: responsible && {
        value: responsible.id,
        label: responsible
      },
      operators: (operators || []).map(o => ({
        value: o.id,
        label: o
      })),
      isLogo: !!config.pageLogo,
      pageLogo:
        config.pageLogo && config.pageLogo !== SYSTEM_LOGO
          ? { file: logo, fileList: [logo] }
          : '',
      pageBackground: config.pageBackground
        ? { file: backgroundImage, fileList: [backgroundImage] }
        : '',
      slaConfig: transformSlaConfigValues(
        config.slaConfig ? config.slaConfig : undefined
      )
    };
  }

  if (channel.kind === WHATSAPP) {
    const { config, responsible, operators } = channel;

    return {
      ...channel,
      ...config,
      responsible: responsible && {
        value: responsible.id,
        label: responsible
      },
      operators: (operators || []).map(o => ({
        value: o.id,
        label: o
      })),
      source: `+${channel.source}`,
      nonWorkingTimeMessage: {
        description: config.scheduleConfig
          ? config.scheduleConfig.nonWorkingTimeMessage
          : ''
      },
      scheduleConfig: transformScheduleConfigValues(config.scheduleConfig),
      slaConfig: transformSlaConfigValues(
        config.slaConfig ? config.slaConfig : undefined
      )
    };
  }

  return channel;
};
