import React from 'react';

export const BillingAdminIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FDA402"
    />
    <path
      d="M12.5255 17.0711L12.5301 17.811C12.5301 17.9137 12.4461 18.0001 12.3434 18.0001H11.6805C11.5778 18.0001 11.4938 17.916 11.4938 17.8133V17.0804C9.42102 16.9263 8.44533 15.7452 8.34029 14.4615C8.33095 14.3517 8.41732 14.2584 8.52703 14.2584H9.60542C9.69645 14.2584 9.77581 14.3237 9.78982 14.4124C9.90886 15.1524 10.4854 15.7056 11.5194 15.8433V12.5124L10.9429 12.3654C9.72212 12.0736 8.5597 11.3126 8.5597 9.73474C8.5597 8.03313 9.85284 7.11813 11.5054 6.95707V6.1868C11.5054 6.08409 11.5895 6.00006 11.6922 6.00006H12.3481C12.4508 6.00006 12.5348 6.08409 12.5348 6.1868V6.95007C14.1337 7.11113 15.3335 8.0448 15.4735 9.49899C15.4852 9.6087 15.3988 9.7044 15.2868 9.7044H14.2388C14.1454 9.7044 14.066 9.63437 14.0544 9.54334C13.961 8.86176 13.4148 8.30623 12.5255 8.18485V11.3196L13.1184 11.4574C14.6309 11.8308 15.6603 12.5544 15.6603 14.1743C15.6603 15.932 14.3531 16.9123 12.5255 17.0711ZM10.0116 9.62737C10.0116 10.2203 10.378 10.6801 11.167 10.9649C11.2767 11.0092 11.3864 11.0442 11.5171 11.0816V8.18718C10.6558 8.29689 10.0116 8.78006 10.0116 9.62737ZM12.7309 12.7832C12.6655 12.7692 12.6002 12.7528 12.5255 12.7318V15.8526C13.5198 15.7639 14.2061 15.2177 14.2061 14.3027C14.2061 13.5861 13.8349 13.1193 12.7309 12.7832Z"
      fill="white"
    />
  </svg>
);

export default BillingAdminIcon;
