import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import debounce from 'lodash.debounce';
import i18n from 'i18n-config';

import Icon from 'components/common/icon';

const doSearch = debounce((setSearch, value, minCharsToSearch) => {
  if (value.length >= minCharsToSearch || value.length === 0) {
    setSearch(value);
  }
}, 300);

export const DebounceInput = forwardRef(
  (
    {
      className,
      value,
      setValue,
      placeholder,
      isSetValueByPressEnter,
      minCharsToSearch,
      ...inputProps
    },
    ref
  ) => {
    const [localValue, setLocalValue] = useState(value);

    const handleChange = event => {
      const newValue = event.target.value;

      setLocalValue(newValue);

      if (!isSetValueByPressEnter || (value && !newValue)) {
        doSearch(setValue, newValue, minCharsToSearch);
      }
    };

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    return (
      <Input
        placeholder={placeholder}
        className={className}
        value={localValue}
        prefix={<Icon type="search" size={20} />}
        ref={ref}
        onChange={handleChange}
        onPressEnter={() => setValue(localValue)}
        {...inputProps}
      />
    );
  }
);

DebounceInput.propTypes = {
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  isSetValueByPressEnter: PropTypes.bool,
  minCharsToSearch: PropTypes.number
};

DebounceInput.defaultProps = {
  className: undefined,
  allowClear: true,
  placeholder: i18n.t('Search', { ns: 'Common' }),
  isSetValueByPressEnter: false,
  minCharsToSearch: 0
};

export default DebounceInput;
