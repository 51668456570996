import React from 'react';

export const VerifiedIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_52485_272888)">
      <path
        d="M24 12.4943L21.3382 9.3065L21.7091 5.08672L17.7709 4.14644L15.7091 0.5L12 2.17415L8.29091 0.5L6.22909 4.14644L2.29091 5.07525L2.66182 9.29503L0 12.4943L2.66182 15.682L2.29091 19.9133L6.22909 20.8536L8.29091 24.5L12 22.8144L15.7091 24.4885L17.7709 20.8421L21.7091 19.9018L21.3382 15.682L24 12.4943Z"
        fill="currentColor"
      />
      <path
        d="M8.59408 11.4488C8.69644 11.4913 8.78939 11.5537 8.86759 11.6322L11.3192 14.0838L15.9293 9.47598C16.0033 9.40187 16.0911 9.34307 16.1879 9.30295C16.2846 9.26284 16.3883 9.24219 16.493 9.24219C16.5977 9.24219 16.7014 9.26284 16.7981 9.30295C16.8948 9.34307 16.9827 9.40187 17.0567 9.47598L17.1223 9.5416C17.1964 9.61558 17.2552 9.70344 17.2953 9.80016C17.3354 9.89688 17.3561 10.0006 17.3561 10.1053C17.3561 10.21 17.3354 10.3137 17.2953 10.4104C17.2552 10.5071 17.1964 10.595 17.1223 10.6689L11.884 15.9072C11.81 15.9813 11.7222 16.0401 11.6254 16.0803C11.5287 16.1204 11.425 16.141 11.3203 16.141C11.2156 16.141 11.1119 16.1204 11.0152 16.0803C10.9185 16.0401 10.8306 15.9813 10.7567 15.9072L7.67463 12.8252C7.59607 12.747 7.53373 12.654 7.49119 12.5517C7.44865 12.4493 7.42676 12.3396 7.42676 12.2287C7.42676 12.1179 7.44865 12.0081 7.49119 11.9057C7.53373 11.8034 7.59607 11.7104 7.67463 11.6322C7.75283 11.5537 7.84578 11.4913 7.94814 11.4488C8.0505 11.4063 8.16026 11.3844 8.27111 11.3844C8.38196 11.3844 8.49172 11.4063 8.59408 11.4488Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_52485_272888">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedIcon;
