import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getLocationSearchWithoutOpenFile,
  OPEN_FILE
} from 'components/app/open-file-provider/utils';

import { MediaViewerContext } from './index';

export const useMediaViewer = () => {
  const history = useHistory();

  const [state, setState] = useContext(MediaViewerContext);

  const onOpen = (items, activeIndex = 0) =>
    setState(currentState => ({
      ...currentState,
      visible: !!items.length,
      items,
      activeIndex
    }));

  const onClose = () => {
    setState(currentState => ({
      ...currentState,
      items: [],
      visible: false,
      activeIndex: undefined
    }));

    if (history.location.search.includes(OPEN_FILE)) {
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state || {}),
          actionsDeps: {}
        },
        search: getLocationSearchWithoutOpenFile(history.location.search)
      });
    }
  };

  return {
    activeIndex: state.activeIndex,
    visible: state.visible,
    items: state.items,
    onClose,
    onOpen
  };
};

export default useMediaViewer;
