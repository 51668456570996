import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FilterMenu as Menu } from 'components/common/filter-menu';
import Icon from 'components/common/icon';

import styles from './filters.module.scss';

const FILTER_ALL = 'filterAll';
const MY_SUBSCRIPTIONS = 'mySubscriptions';

export const MenuFilter = ({ actions, menuFilters, isLoading }) => {
  const { t } = useTranslation('MyDrive');

  const { isMySubscriptions } = menuFilters;
  const { setMySubscriptions } = actions;

  const getSelectedKeysOnMenu = () => {
    const keys = [];

    if (isMySubscriptions) {
      keys.push(MY_SUBSCRIPTIONS);
      return keys;
    }

    keys.push(FILTER_ALL);

    return keys;
  };

  const selectedKeys = getSelectedKeysOnMenu();

  const resetFilter = () => {
    setMySubscriptions(false);
  };

  useEffect(() => {
    resetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = [
    {
      label: t('AllDriveFilter'),
      key: FILTER_ALL,
      onClick: () => resetFilter(),
      visible: true,
      disabled: isLoading
    },
    {
      label: (
        <>
          <Icon type="subscribe-filled" color="brand" size={20} />

          {t('MySubscriptionsDriveFilter')}
        </>
      ),
      key: MY_SUBSCRIPTIONS,
      onClick: () => {
        if (isMySubscriptions) {
          return null;
        }

        return setMySubscriptions(true);
      },
      visible: true,
      disabled: isLoading
    }
  ];

  const filteredMenu = menu.filter(item => item.visible);

  return (
    <Menu
      menu={filteredMenu}
      selectedKeys={selectedKeys}
      className={styles.filterMenu}
    />
  );
};

MenuFilter.propTypes = {
  actions: PropTypes.shape({
    setMySubscriptions: PropTypes.func.isRequired
  }),
  menuFilters: PropTypes.shape({
    isMySubscriptions: PropTypes.bool.isRequired
  }),
  isLoading: PropTypes.bool
};

MenuFilter.defaultProps = {
  actions: {
    setMySubscriptions: () => {}
  },
  menuFilters: {
    isMySubscriptions: false
  },
  isLoading: false
};

export default MenuFilter;
