import { config } from 'config';

export const getSupportLink = () => {
  const token = localStorage.getItem('token');

  const getUpserviceSiteUrl = () => {
    if (config.REACT_APP_ENVIRONMENT === 'development') {
      return 'https://dev.buselsoft.com/contact';
    }

    return 'https://upservice.com/contact';
  };

  return {
    href: token ? '/support' : getUpserviceSiteUrl(),
    target: token ? '_self' : '_blank'
  };
};

export default getSupportLink;
