import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

export const _getDialogues = state => state.dialogues;

export const getDialogues = createSelector(
  _getDialogues,
  dialogues => dialogues.entries
);

export const getDialogFactory = createSelector(getDialogues, entries =>
  memoize(uuid => entries.find(d => d.uuid === uuid))
);

export const getIsDialoguesLoading = createSelector(
  _getDialogues,
  dialogues => dialogues.isLoading
);

export const getIsDialoguesLoaded = createSelector(
  _getDialogues,
  dialogues => dialogues.isLoaded
);

export const getIsDialogLoading = createSelector(
  _getDialogues,
  dialogues => dialogues.isLoadingDialog
);

export const getTotalItems = createSelector(
  _getDialogues,
  dialogues => dialogues.totalItems
);

export const getItemsPerPage = createSelector(
  _getDialogues,
  dialogues => dialogues.itemsPerPage
);

export const getErrorDialogues = createSelector(
  _getDialogues,
  dialogues => dialogues.error
);

export const getFilterDialogues = createSelector(
  _getDialogues,
  dialogues => dialogues.filter
);
