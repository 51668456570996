import {
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_MESSAGE,
  TYPE_ORDER_STATUS,
  TYPE_TASK
} from 'constants/index';

import handleActions from 'utils/redux-actions';

import {
  fetchReminder,
  createReminder,
  updateReminder,
  deleteReminder
} from './actions';
import { combineActionsThunk } from '../actions-thunk';

const initialState = {
  entities: {
    [TYPE_CONTACT]: {},
    [TYPE_ASSET]: {},
    [TYPE_TASK]: {},
    [TYPE_ORDER_STATUS]: {},
    [TYPE_MESSAGE]: {}
  }
};

export default handleActions(
  {
    [fetchReminder.SUCCEEDED]: (state, { payload, args }) => {
      const { entityType, entityId } = args;
      const [data] = payload;

      if (payload.length) {
        state.entities[entityType][entityId] = data;
      }

      return state;
    },

    [combineActionsThunk(createReminder.SUCCEEDED, updateReminder.SUCCEEDED)]: (
      state,
      { payload, args }
    ) => {
      const { entityType, entityId } = args;

      state.entities[entityType][entityId] = payload;

      return state;
    },

    [deleteReminder.SUCCEEDED]: (state, { args }) => {
      const { entityType, entityId } = args;

      delete state.entities[entityType][entityId];

      return state;
    }
  },
  initialState
);
