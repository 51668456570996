import { createSelector } from 'reselect';
import { MODAL, SELECTED_STATUS } from '../../constants';

// Selectors of connected-react-router
export const getRouter = state => state.router;

export const getRouterLocation = createSelector(
  getRouter,
  router => router.location
);

export const getRouterPathname = createSelector(
  getRouterLocation,
  location => location.pathname
);

export const getRouterSearch = createSelector(
  getRouterLocation,
  location => location.search
);

export const getRouterState = createSelector(
  getRouterLocation,
  location => location.state || {}
);

// Selectors of RouterURl
export const getRouterUrl = state => state.routerUrl;

export const getRouterIsFirstRendering = createSelector(
  [getRouterUrl],
  router => router.isFirstRendering
);

export const getRouterUrlView = createSelector(
  [getRouterUrl],
  router => router.view
);

export const getRouterUrlSubmenu = createSelector(
  [getRouterUrl],
  router => router.subMenu || ''
);

export const getRouterUrlActivePath = createSelector(
  [getRouterUrl],
  router => router.activePath
);

export const getRouterUrlQuery = createSelector(
  [getRouterUrl],
  router => router.query || {}
);

export const getTabKey = createSelector(
  [getRouterUrl],
  router => router.tabKey
);

export const getWithoutDefaultFilter = createSelector(
  [getRouterUrl],
  router => router.withoutDefaultFilter
);

export const getRouterUrlModal = createSelector(
  [getRouterUrlQuery],
  query => query[MODAL]
);

export const getRouterUrlSelectedStatus = createSelector(
  [getRouterUrlQuery],
  query => query[SELECTED_STATUS]
);
