import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

import Drawer from 'components/common/drawer';

import FormCreateDepartment from './form';

const CreateDepartmentDrawer = ({ visible, onClose }) => {
  const { t } = useTranslation('Team');

  return (
    <Drawer
      title={<Drawer.Title>{t('CreateDepartmentHeading')}</Drawer.Title>}
      width={480}
      visible={visible}
      destroyOnClose
      onClose={onClose}
      maskClosable={false}
    >
      <Alert type="warning" message={t('CreateDepartmentAlert')} />

      <FormCreateDepartment onClose={onClose} />
    </Drawer>
  );
};

CreateDepartmentDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CreateDepartmentDrawer;
