import React from 'react';

export const PresentationIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66634 5.99967L13.333 5.99967L13.333 3.33301L2.66634 3.33301L2.66634 5.99967ZM1.33301 6.44412C1.33301 6.93504 1.73098 7.33301 2.2219 7.33301L13.7775 7.33301C14.2684 7.33301 14.6663 6.93504 14.6663 6.44412L14.6663 2.88856C14.6663 2.39764 14.2684 1.99967 13.7775 1.99967L2.2219 1.99967C1.73098 1.99967 1.33301 2.39764 1.33301 2.88856L1.33301 6.44412Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66634 12.6667L13.333 12.6667L13.333 10L2.66634 10L2.66634 12.6667ZM1.33301 13.1111C1.33301 13.602 1.73098 14 2.2219 14L13.7775 14C14.2684 14 14.6663 13.602 14.6663 13.1111L14.6663 9.55556C14.6663 9.06464 14.2684 8.66667 13.7775 8.66667L2.2219 8.66667C1.73098 8.66667 1.33301 9.06464 1.33301 9.55556L1.33301 13.1111Z"
      fill="currentColor"
    />
  </svg>
);

export default PresentationIcon;
