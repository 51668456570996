import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TELEGRAM, WEBSITE_CHANNEL_STEP, WHATSAPP } from 'constants/index';

import WebsiteChannelForm from 'components/contacts-view/views/integrations/forms/website-channel';
import { useTheme } from 'components/common/theme';
import { transformScheduleConfigValues } from 'components/contacts-view/views/integrations/components/operator-hours/utils';
import { transformSlaConfigValues } from 'components/contacts-view/views/integrations/components/sla-settings/utils';
import { COLLECT_CLIENT_CONTACT } from 'components/contacts-view/views/integrations/components/operator-hours/constants';

import { getActiveWorkspace } from 'store/workspace';
import { getUILanguage } from 'store/ui';

import { getChannelLanguage } from '../utils';

const WebsiteStep = ({
  currentStep,
  channel,
  creator,
  isLoading,
  onSubmit
}) => {
  const { variables } = useTheme();

  const workspace = useSelector(getActiveWorkspace);
  const language = useSelector(getUILanguage);

  const { t } = useTranslation('ConnectWidget');

  const channelLanguage = getChannelLanguage(language);

  const defaultValues = {
    icon: 'chat',
    message: {
      description: t('HelloMessage')
    },
    color: variables.color['brand-40'],
    position: 'right',
    left: 25,
    bottom: 25,
    right: 25,
    title: workspace.title,
    notify: true,
    responsible: creator,
    operators: [creator],
    isInvite: true,
    inviteDelay: 15,
    withTelegram: !!channel && channel.kind === TELEGRAM,
    withWhatsapp: !!channel && channel.kind === WHATSAPP,
    telegramBot: channel &&
      channel.kind === TELEGRAM && {
        value: channel.id,
        label: {
          title: channel.name,
          ...channel
        }
      },
    whatsapp: channel &&
      channel.kind === WHATSAPP && {
        value: channel.id,
        label: {
          title: channel.name,
          ...channel
        }
      },
    botPosition: 'vertical',
    isCallbackOn: false,
    scheduleConfig: transformScheduleConfigValues(),
    slaConfig: transformSlaConfigValues(),
    displayIfOffline: COLLECT_CLIENT_CONTACT,
    language: channelLanguage
  };

  const steps = {
    [WEBSITE_CHANNEL_STEP]: (
      <WebsiteChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    )
  };

  return <>{steps[currentStep]}</>;
};

WebsiteStep.propTypes = {
  currentStep: PropTypes.oneOf([WEBSITE_CHANNEL_STEP]),
  channel: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

WebsiteStep.defaultProps = {
  currentStep: WEBSITE_CHANNEL_STEP,
  channel: null
};

export default WebsiteStep;
