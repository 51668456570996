import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation } from 'react-i18next';

import { config } from 'config';

import Icon from '../icon';
import { GoogleIcon } from '../icons';
import Button from '../button';

import styles from './google-login.module.scss';

export const GoogleLogin = ({
  text,
  withIcon,
  onAuth,
  className,
  checkInitTokenCallback,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const requestTokenCallback = useCallback(
    async ({ access_token: accessToken }) => {
      if (accessToken) {
        try {
          setIsLoading(true);

          await onAuth({ token: accessToken });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [onAuth]
  );

  const onClick = useCallback(() => {
    const canInitToken = checkInitTokenCallback();

    if (canInitToken) {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: config.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        callback: requestTokenCallback
      });

      client.requestAccessToken();
    }
  }, [checkInitTokenCallback, requestTokenCallback]);

  return (
    <Button
      loading={isLoading}
      mood="none"
      size="large"
      type="google"
      onClick={onClick}
      className={classnames(styles.root, className)}
      {...props}
    >
      {withIcon && <Icon component={GoogleIcon} />}
      {text}
    </Button>
  );
};

GoogleLogin.propTypes = {
  onAuth: PropTypes.func.isRequired,
  text: PropTypes.string,
  withIcon: PropTypes.bool,
  checkInitTokenCallback: PropTypes.func
};

GoogleLogin.defaultProps = {
  text: (
    <Translation ns="ScreenErrors">{t => t('ConnectGoogleBtn')}</Translation>
  ),
  withIcon: true,
  checkInitTokenCallback: () => true
};

export default GoogleLogin;
