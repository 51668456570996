import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchOne, getAssetFactory, getAssetsIsLoading } from 'store/assets';

const useAsset = ({ id, afterVisibleChange = false }) => {
  const dispatch = useDispatch();

  const asset = useSelector(state => getAssetFactory(state)(id));
  const isAllLoading = useSelector(getAssetsIsLoading);

  const [isOneLoading, setIsOneLoading] = useState(false);

  const [hasError, setHasError] = useState(false);

  const isLoading = isAllLoading || isOneLoading;
  const isDrawerOpened = id && afterVisibleChange;

  const fetch = async () => {
    try {
      setIsOneLoading(true);

      await dispatch(fetchOne({ id }));
    } catch {
      setHasError(true);
    } finally {
      setIsOneLoading(false);
    }
  };

  useEffect(() => {
    setHasError(false);

    if (isDrawerOpened) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, afterVisibleChange]);

  return {
    asset,
    hasError,
    isLoading
  };
};

export default useAsset;
