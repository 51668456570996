import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import Icon from 'components/common/icon';

export const LabelWithTooltip = ({ label, showIcon, tooltip, style }) => (
  <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 4 }}>{label}</span>

      {showIcon && (
        <Tooltip title={tooltip} mouseEnterDelay={0.5} overlayStyle={style}>
          <Icon type="question-circle" size={15} theme="filled" />
        </Tooltip>
      )}
    </div>
  </>
);

LabelWithTooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltip: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  style: PropTypes.object
};

LabelWithTooltip.defaultProps = {
  showIcon: true,
  style: undefined
};

export default LabelWithTooltip;
