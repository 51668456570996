import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { FormEmployeeSelect } from 'components/common/hook-form';
import NoSubscribersIcon from 'components/common/icons/multicolor/no-subscribers';
import Icon from 'components/common/icon';

import SubscribersList from './subscribers-list';

import styles from './subscribers-drawer.module.scss';

const Form = ({
  isLoading,
  onSubmit,
  onDelete,
  canManageSubscribers,
  subscribers,
  deletedSubscribers,
  description
}) => {
  const { t } = useTranslation('FileSubscribers');
  const methods = useForm();

  const watchSubscribers = methods.watch('subscribers');

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        {canManageSubscribers && (
          <>
            <Typography.Paragraph>{description}</Typography.Paragraph>

            <FormEmployeeSelect
              name="subscribers"
              label={t('Subscribers')}
              isMulti
              allowSetYourself={false}
              params={{
                exclude: subscribers.map(({ id }) => id),
                isPostMethod: true
              }}
            />
          </>
        )}

        {subscribers.length === 0 && !canManageSubscribers && (
          <Empty
            image={<Icon component={NoSubscribersIcon} />}
            imageStyle={{ height: 'auto', marginTop: 210 }}
            description={
              <Typography.Paragraph>{t('NoSubscribers')}</Typography.Paragraph>
            }
          />
        )}

        {subscribers.length > 0 && (
          <SubscribersList
            canManageSubscribers={canManageSubscribers}
            onDelete={onDelete}
            subscribers={subscribers}
          />
        )}

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
          className={styles.submitBtn}
          disabled={
            (!watchSubscribers ||
              (watchSubscribers && watchSubscribers.length === 0)) &&
            deletedSubscribers.length === 0
          }
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canManageSubscribers: PropTypes.bool,
  subscribers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object,
      position: PropTypes.string
    })
  ),
  deletedSubscribers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object,
      position: PropTypes.string
    })
  ),
  description: PropTypes.string.isRequired
};

Form.defaultProps = {
  canManageSubscribers: false,
  subscribers: [],
  deletedSubscribers: []
};

export default Form;
