import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CHANNELS_EDITOR_DRAWER } from 'constants/index';

import { getWidgetLink } from 'components/contacts-view/views/integrations/utils';

import { setVisibleDrawer } from 'store/drawers';

import CommonModal from '../common';

export const WebsiteCreationSuccessedModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('ChannelCreated');

  const toSettings = () => {
    onClose();
    dispatch(setVisibleDrawer({ drawer: CHANNELS_EDITOR_DRAWER, data }));
  };

  const buttons = [
    {
      text: t('SetLaterBtn'),
      type: 'link',
      mood: 'negative',
      size: 'large'
    },
    {
      text: t('SettingsBtn'),
      type: 'primary',
      size: 'large',
      width: 'expanded',
      onClick: toSettings
    }
  ];

  return (
    <CommonModal
      visible={visible}
      description={t('WidgetChannelCreatedDesc')}
      link={getWidgetLink(data.uuid)}
      buttons={buttons}
      onClose={onClose}
    />
  );
};

WebsiteCreationSuccessedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    uuid: PropTypes.string
  })
};

WebsiteCreationSuccessedModal.defaultProps = {
  data: {}
};

export default WebsiteCreationSuccessedModal;
