import React, { useState } from 'react';
import { Input as InputAntd } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';

import styles from './gpt-message-input.module.scss';

const { TextArea } = InputAntd;

const { Text } = Typography;

const GptMessageInput = ({ sendMessage, entityInfo }) => {
  const { t } = useTranslation('AIAssistant');

  const [value, setValue] = useState('');

  const handleSubmit = () => {
    const messageData = {
      entity_id: entityInfo.entityId,
      entity_type: entityInfo.entityType,
      text: value
    };

    sendMessage(messageData);
    setValue('');
  };

  const handleEnterSubmit = event => {
    if (event.ctrlKey && event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <div className={styles.root}>
        <TextArea
          value={value}
          onChange={({ target }) => setValue(target.value)}
          placeholder={t('EnterYourTextPlchldr')}
          className={styles.textarea}
          onKeyDown={handleEnterSubmit}
          autoSize={{
            minRows: 2,
            maxRows: 5
          }}
        />

        <div className={styles.toolbar}>
          <Button
            disabled={!value.trim()}
            type="text"
            className={styles.sendButton}
            onClick={handleSubmit}
          >
            {t('SendBtn')}
          </Button>
        </div>
      </div>

      <Text className={styles.bottomText} color="black-35" size="small">
        {t('ChatGPTDesc')}
      </Text>
    </>
  );
};

GptMessageInput.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  entityInfo: PropTypes.shape({
    entityId: PropTypes.number,
    entityType: PropTypes.string
  }).isRequired
};

export default GptMessageInput;
