import api from 'api';
import { createAction } from 'redux-actions';

import { getWorkspaceId } from 'store/workspace';

import createActionThunk from '../actions-thunk';

export const fetchReports = createActionThunk(
  'reports/fetch',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.reports
      .fetch({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const fetchReportTemplates = createActionThunk(
  'reports/templates/fetch',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.reports
      .fetchReportTemplates({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const createReportTemplate = createActionThunk(
  'reports/templates/create',
  ({ getState, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports
      .createReportTemplate({ workspaceId, data })
      .then(({ data: result }) => result);
  }
);

export const fetchReport = createActionThunk(
  'reports/fetch-one',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports.fetchOne({ workspaceId, id }).then(({ data }) => data);
  }
);

export const fetchReportTemplate = createActionThunk(
  'reports/templates/fetch-one',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports
      .fetchReportTemplate({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const deleteReportTemplate = createActionThunk(
  'reports/templates/delete',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports.deleteReportTemplate({ workspaceId, id });
  }
);

export const partialUpdateReportTemplate = createActionThunk(
  'reports/templates/partial-update',
  ({ getState, id, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports
      .partialUpdateReportTemplate({ workspaceId, id, data })
      .then(({ data: result }) => result);
  }
);

export const fetchReportTemplateLocal = createActionThunk(
  'reports/templates/fetch-one-local',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.reports
      .fetchReportTemplate({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchReportsAnalysts = createActionThunk(
  'reports/analysts/fetch',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.reports
      .fetchAnalysts({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const addReportsAnalyst = createActionThunk(
  'reports/analysts/add',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.reports
      .fetchAnalysts({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const deleteReportsAnalyst = createActionThunk(
  'reports/analysts/delete',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.reports
      .fetchAnalysts({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const createRedashQuery = createActionThunk(
  'reports/redash/create-query',
  ({ getState, queryId, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.redash
      .create({ workspaceId, queryId, params })
      .then(({ data }) => ({ ...data, queryId }));
  }
);

export const checkRedashJobStatus = createActionThunk(
  'reports/redash/check-job-status',
  ({ jobId }) => api.redash.check({ jobId }).then(({ data }) => data)
);

export const fetchRedashQuery = createActionThunk(
  'reports/redash/fetch-query',
  ({ queryId }) => api.redash.fetchQuery({ queryId }).then(({ data }) => data)
);

export const fetchRedashDashboard = createActionThunk(
  'reports/redash/fetch-dashboard',
  ({ dashboardId }) =>
    api.redash.fetchDashboard({ dashboardId }).then(({ data }) => data)
);

export const fetchRedashReportData = createActionThunk(
  'reports/redash/fetch-data',
  ({ queryResultId, queryId }) =>
    api.redash
      .fetch({ queryResultId })
      .then(({ data }) => ({ data: data.query_result.data, queryId }))
);

export const exportRedashReport = createActionThunk(
  'reports/redash/export',
  ({ queryResultId, format, queryId }) =>
    api.redash
      .export({ queryResultId, format })
      .then(({ data }) => ({ data, queryId }))
);

export const fetchReportAccess = createActionThunk(
  'reports/access/fetch',
  ({ getState, limit, offset, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.reports
      .fetchAccess({ limit, offset, id, workspaceId })
      .then(({ data }) => data.results);
  }
);

export const updateReportAccess = createActionThunk(
  'reports/access/update',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.reports.updateAccess({ workspaceId, id, employees, isDelete });
  }
);

export const fetchReportTemplateAccess = createActionThunk(
  'reports/templates/access/fetch',
  ({ getState, limit, offset, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.reports
      .fetchReportTemplateAccess({ limit, offset, id, workspaceId })
      .then(({ data }) => data.results);
  }
);

export const updateReportTemplateAccess = createActionThunk(
  'reports/templates/access/update',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.reports.updateReportTemplateAccess({
      workspaceId,
      id,
      employees,
      isDelete
    });
  }
);

export const exportWorklogReport = createActionThunk(
  'reports/worklog/export',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.worklogsReports.export({ workspaceId, params });
  }
);

export const clearReportEntries = createAction('reports/clear-entries');
export const clearReportFilter = createAction('reports/clear-filter');

export const setReportFilterEmployee = createAction(
  'reports/set-filter-employee'
);
export const setReportFilterEmployee2 = createAction(
  'reports/set-filter-employee-2'
);
export const setReportFilterEmployee3 = createAction(
  'reports/set-filter-employee-3'
);
export const setReportFilterProject = createAction(
  'reports/set-filter-project'
);
export const setReportFilterPeriod = createAction('reports/set-filter-period');
export const setReportFilterCompany = createAction(
  'reports/set-filter-company'
);
export const setReportFilterFunnelType = createAction(
  'reports/set-filter-funnel-type'
);
export const setReportFilterTag = createAction('reports/set-filter-tag');
export const setReportFilterContactStatus = createAction(
  'reports/set-filter-contact-status'
);
export const setReportFilterChannelKind = createAction(
  'reports/set-filter-channel-kind'
);
export const setReportFilterChannelId = createAction(
  'reports/set-filter-channel-id'
);
export const setReportFilterContactCompanyIsIndividual = createAction(
  'reports/set-filter-contact-company-is-individual'
);
export const setReportFilterContactFirstPageLink = createAction(
  'reports/set-filter-contact-first-page-link'
);
export const setReportFilterTaskKind = createAction(
  'reports/set-filter-task-kind'
);
export const setReportFilterSprint = createAction('reports/set-filter-sprint');
export const setReportFilterTaskStatus = createAction(
  'reports/set-filter-task-status'
);
export const setReportFilterContact = createAction(
  'reports/set-filter-contact'
);
export const setReportFilterReportTerms = createAction(
  'reports/set-filter-report-terms'
);
export const setReportFilterShowOverdueIssues = createAction(
  'reports/set-filter-show-overdue-issues'
);
export const setReportFilterCurrency = createAction(
  'reports/set-filter-currency'
);
export const setReportFilterHideEarlierContacts = createAction(
  'reports/set-filter-hide-earlier-contacts'
);
export const setReportFilterHideConversion = createAction(
  'reports/set-filter-hide-conversion'
);
export const setReportFilterGrade = createAction('reports/set-filter-grade');
export const setReportFilterIsFeedback = createAction(
  'reports/set-filter-is-feedback'
);
export const setReportFilterReturnedToWork = createAction(
  'reports/set-filter-returned-to-work'
);
export const setReportFilterOrderTag = createAction(
  'reports/set-filter-order-tag'
);
export const setReportFilterPrice = createAction('reports/set-filter-price');
