import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CHANGING_PROCESSES,
  EMPLOYEE_DISMISS,
  JOIN_REQUEST_ACCEPTED_EVENT,
  JOIN_REQUEST_REJECTED_EVENT
} from 'constants/index';

import {
  acceptNotification,
  fetchActiveNotifications,
  getActiveNotificationEntries,
  getActiveNotificationsIsLoaded,
  getActiveNotificationsIsLoading,
  getActiveNotificationsTotalItems
} from 'store/notifications';
import {
  fetchWorkspaces,
  getActiveWorkspace,
  getPersonalWorkspace
} from 'store/workspace';
import { getIsOnlyUserProfile } from 'store/user';

import useAmplitude from 'hooks/amplitude/use-amplitude';
import { showNoticeMessage } from 'services/notice';
import useNotificationsService from 'services/notifications';

const useData = ({ afterVisibleChange }) => {
  const dispatch = useDispatch();

  const workspace = useSelector(getActiveWorkspace);
  const personalWorkspace = useSelector(getPersonalWorkspace);

  const activeWorkspace = workspace || personalWorkspace;

  const notifications = useSelector(state =>
    getActiveNotificationEntries(state)(activeWorkspace.id)
  );
  const totalItems = useSelector(state =>
    getActiveNotificationsTotalItems(state)(activeWorkspace.id)
  );
  const isLoading = useSelector(getActiveNotificationsIsLoading);
  const isLoaded = useSelector(getActiveNotificationsIsLoaded);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  const [index, setIndex] = useState(0);
  const [activeAction, setActiveAction] = useState(null);

  const amplitude = useAmplitude();
  const notificationsService = useNotificationsService();

  const { t } = useTranslation('Toast');

  const current = notifications[index];

  const checkIndex = useCallback(() => {
    if (totalItems !== 1 && totalItems === index + 1) {
      setIndex(prev => prev - 1);
    }
  }, [index, totalItems]);

  const onAccept = useCallback(
    async notification => {
      try {
        setActiveAction(notification.id);

        await dispatch(
          acceptNotification({
            id: notification.id,
            actionId: notification.action.id,
            isAccept: true
          })
        );

        // запрос на присоединение к компании
        if (notification.templateUid === 73) {
          amplitude.logEvent({
            event: JOIN_REQUEST_ACCEPTED_EVENT
          });
        }

        if (notification.templateUid === 29) {
          await dispatch(fetchWorkspaces()); // для добавления нового воркспейса в стор

          notificationsService.handle({ ...notification, isAccepted: true }); // открытие приглашения сотрудников
        }

        if (notification.action.type === EMPLOYEE_DISMISS) {
          return showNoticeMessage({
            customContent: t('DismissalProcess')
          });
        }

        if (CHANGING_PROCESSES.includes(notification.action.type)) {
          return showNoticeMessage({
            customContent: t('ChangeDepartmentProcess')
          });
        }

        checkIndex();

        return showNoticeMessage({
          customContent: t('RequestSuccessfullyAccepted')
        });
      } finally {
        setActiveAction(null);
      }
    },
    [amplitude, checkIndex, dispatch, notificationsService, t]
  );

  const onReject = useCallback(
    async notification => {
      try {
        setActiveAction(notification.id);

        await dispatch(
          acceptNotification({
            id: notification.id,
            actionId: notification.action.id,
            isAccept: false
          })
        );

        if (notification.templateUid === 73) {
          amplitude.logEvent({
            event: JOIN_REQUEST_REJECTED_EVENT
          });
        }

        checkIndex();

        showNoticeMessage({ customContent: t('RequestSuccessfullyRejected') });
      } finally {
        setActiveAction(null);
      }
    },
    [amplitude, checkIndex, dispatch, t]
  );

  useEffect(() => {
    if (!isLoaded && afterVisibleChange) {
      dispatch(fetchActiveNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspace, afterVisibleChange]);

  return {
    index,
    setIndex,
    isLoading,
    totalItems,
    current,
    activeAction,
    isOnlyUserProfile,
    onAccept,
    onReject
  };
};

export default useData;
