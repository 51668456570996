import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormItem from '../form-item';
import Switch from '../../controls/switch';

export const FormSwitch = ({ rules, name, itemProps, ...props }) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem name={name} {...itemProps} errors={formState.errors}>
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { ref, ...renderProps } = field;

          return <Switch {...renderProps} {...props} />;
        }}
      />
    </FormItem>
  );
};

FormSwitch.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormSwitch.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormSwitch;
