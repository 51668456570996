import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import styles from './message.module.scss';

const { Text, Paragraph } = Typography;

const handleDecodeURIComponent = encodedURIComponent => {
  let URI;

  try {
    URI = decodeURIComponent(encodedURIComponent);
  } catch {
    URI = encodedURIComponent;
  }

  return URI;
};

const ClientInfo = ({ leads }) => {
  const [utmStrings, setUtmStrings] = useState([]);

  const { t } = useTranslation('Contacts');

  const utmKeys = useMemo(
    () => leads && Object.keys(leads).filter(key => key.startsWith('utm')),
    [leads]
  );

  useEffect(() => {
    if (utmKeys && utmKeys.length > 0) {
      const utmContentStrings = utmKeys.map(
        key => `${key} = ${handleDecodeURIComponent(leads[key])}`
      );

      setUtmStrings(utmContentStrings);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip
      title={
        <>
          <Text weight="bold" className={styles.leadInfoTitle}>
            {t('PageWidgetTip')}
          </Text>

          <div className={styles.leadInfoDescription}>
            <Text>{leads.page}</Text>

            <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
              <a href={leads.link} target="_blank" rel="noreferrer">
                {leads.link}
              </a>
            </Paragraph>
          </div>

          <Text weight="bold" className={styles.leadInfoTitle}>
            {t('CountryWidgetTip')}
          </Text>

          <div className={styles.leadInfoDescription}>
            <Text className={styles.leadInfoDescription}>{leads.country}</Text>
          </div>

          {leads.referrer || leads.lastPage || utmStrings.length ? (
            <>
              <Text weight="bold" className={styles.leadInfoTitle}>
                {t('TagsWidgetTip')}
              </Text>

              {utmStrings.map((label, index) => (
                <div key={`utm_label_${index}`} className={styles.labels}>
                  <Text className={styles.leadLabel} ellipsis={{ rows: 2 }}>
                    {label}
                  </Text>
                </div>
              ))}

              {leads.referrer && (
                <Paragraph ellipsis={{ rows: 2 }} className={styles.referrer}>
                  referrer ={' '}
                  <a
                    href={handleDecodeURIComponent(leads.referrer)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {handleDecodeURIComponent(leads.referrer)}
                  </a>
                </Paragraph>
              )}

              {leads.lastPage && (
                <Paragraph ellipsis={{ rows: 2 }}>
                  last_page ={' '}
                  <a href={leads.lastPage} target="_blank" rel="noreferrer">
                    {handleDecodeURIComponent(leads.lastPage)}
                  </a>
                </Paragraph>
              )}
            </>
          ) : null}

          {leads.support && (
            <div className={styles.leadSupportInfo}>
              <Divider style={{ margin: '8px 0 0' }} />

              {Object.keys(leads.support).map(key => (
                <div key={`support-${key}`}>
                  <Text weight="bold">{`${key}: `}</Text>

                  <Text>{leads.support[key]}</Text>
                </div>
              ))}
            </div>
          )}
        </>
      }
    >
      <Icon color="black-55" type="info-circle" size={20} />
    </Tooltip>
  );
};

ClientInfo.propTypes = {
  leads: PropTypes.object.isRequired
};

export default ClientInfo;
