import { createSelector } from 'reselect';

const getMessageTemplates = state => state.messageTemplates;

export const getMessageTemplatesList = createSelector(
  getMessageTemplates,
  messageTemplates => messageTemplates.list
);

export default getMessageTemplatesList;
