import React from 'react';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';

import { CHANNEL_TYPES } from 'constants/index';

import Typography from 'components/common/typography';
import IconComponent from 'components/common/icon';

import styles from './select-type.module.scss';

const SelectTypeStep = ({ onClick }) => {
  const { t } = useTranslation('ConnectChannel');

  return (
    <div className={styles.root}>
      <Typography.Paragraph className={styles.text}>
        {t('ConnectChannelDesc')}
      </Typography.Paragraph>

      <div className={styles.types}>
        {CHANNEL_TYPES.map(
          ({
            type,
            Icon,
            title,
            ns,
            titleColor,
            description,
            backgroundColor
          }) => (
            <div
              key={type}
              className={styles.card}
              style={{
                backgroundColor
              }}
              onClick={() => onClick(type)}
            >
              <IconComponent
                component={Icon}
                size={16}
                className={styles.icon}
              />

              <Typography.Title
                level={3}
                color={titleColor}
                className={styles.title}
              >
                <Translation ns={ns}>
                  {translate => translate(title)}
                </Translation>
              </Typography.Title>

              <Typography.Text size="small">
                <Translation ns={ns}>
                  {translate => translate(description)}
                </Translation>
              </Typography.Text>
            </div>
          )
        )}
      </div>
    </div>
  );
};

SelectTypeStep.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SelectTypeStep;
