import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import { useDispatch } from 'react-redux';

import { TYPE_ORDER_STATUS } from 'constants/index';
import { fetchOrderStatusesByIds } from 'store/order-statuses';

export const useOrderStatuses = ({ data, hasData }) => {
  const dispatch = useDispatch();

  const [orderStatuses, setOrderStatuses] = useState(null);

  const fetchOrderStatusIds = async () => {
    const orderStatusIds = data.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.entities.filter(e => e.type === TYPE_ORDER_STATUS)
      ],
      []
    );

    if (orderStatusIds.length) {
      const { _embedded } = await dispatch(
        fetchOrderStatusesByIds({
          ids: uniq(orderStatusIds.map(os => os.objectId))
        })
      );

      if (_embedded) {
        const { orderStatuses: fetchedOrderStatuses } = _embedded;
        setOrderStatuses(fetchedOrderStatuses);
      }
    }
  };

  useEffect(() => {
    if (hasData) {
      setOrderStatuses(null);
      fetchOrderStatusIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { orderStatuses };
};

export default useOrderStatuses;
