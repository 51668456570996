import { useEffect } from 'react';

import { TYPE_ASSET } from 'constants/index';

import useChat from '../use-chat';

export const useAssetChat = ({ asset }) => {
  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    renderedControls,
    isJoined,
    hasMore,
    fetchMessages
  } = useChat({
    entity: asset,
    entityType: TYPE_ASSET,
    destination: {
      entityType: TYPE_ASSET,
      entityId: asset.id && asset.id.toString(),
      entity: asset
    }
  });

  useEffect(() => {
    if (asset && asset.chats) {
      fetchMessages({ offset: 0, withSource: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset.chats]);

  return {
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore: fetchMessages
  };
};

export default useAssetChat;
