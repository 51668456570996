import api from 'api';

import { getUserEmployee, getWorkspaceId } from 'store/workspace';
import createActionThunk from 'store/actions-thunk';

export const subscribeAttachment = createActionThunk(
  'subscriptions/subscribe-attachment',
  ({ getState, entityType, entityId, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());
    const employee = getUserEmployee(getState());

    return api.subscriptions
      .subscribe({ workspaceId, entityId, entityType })
      .then(({ data }) => ({
        ...data,
        actionDeps: { ...actionDeps, employeeId: employee.id }
      }));
  }
);

export const unsubscribeAttachment = createActionThunk(
  'subscriptions/unsubscribe-attachment',
  ({ getState, entityType, entityId, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());
    const employee = getUserEmployee(getState());

    return api.subscriptions
      .unsubscribe({ workspaceId, entityId, entityType })
      .then(() => ({
        entityType,
        entityId,
        actionDeps: { ...actionDeps, employeeId: employee.id }
      }));
  }
);

export const fetchSubscribers = createActionThunk(
  'subscriptions/fetch-subscribers',
  ({ getState, entityId }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.subscriptions
      .fetchSubscribers({ workspaceId, entityId })
      .then(({ data }) => data);
  }
);

export const changeManageSubscribers = createActionThunk(
  'subscriptions/change-manage-subscribers',
  ({ getState, entityId, entityType, added, deleted, created, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());
    const employee = getUserEmployee(getState());
    const { id } = employee;

    return api.subscriptions
      .manageSubscribers({
        workspaceId,
        entityId,
        entityType,
        added,
        deleted,
        created
      })
      .then(({ data }) => ({
        userId: id,
        added,
        deleted,
        subscribers: data,
        entityId,
        entityType,
        actionDeps
      }));
  }
);

export const changeManageBulkSubscribers = createActionThunk(
  'subscriptions/change-bulk-manage-subscribers',
  ({ getState, data }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.subscriptions.manageBulkSubscribers({ workspaceId, data });
  }
);

export const subscribeAsset = createActionThunk(
  'subscriptions/subscribe-asset',
  ({ getState, entityType, entityId, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.subscriptions
      .subscribe({ workspaceId, entityId, entityType })
      .then(({ data }) => ({
        ...data,
        actionDeps
      }));
  }
);

export const unsubscribeAsset = createActionThunk(
  'subscriptions/unsubscribe-asset',
  ({ getState, entityType, entityId, actionDeps }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.subscriptions
      .unsubscribe({ workspaceId, entityId, entityType })
      .then(() => ({
        entityType,
        entityId,
        actionDeps
      }));
  }
);
