import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { MESSAGE_DURATION, TYPE_PROJECT } from 'constants/index';

import { useUpdateTags } from 'components/common/tags';

import { changeRelations } from 'store/assets';

import useRoutesService from 'services/routes';

import { createProject } from '../../../../../store/projects';
import { convertIdeaToProject } from '../../../../../store/ideas/active';
import CreatorForm from '../../creator-form';
import {
  getUserEmployee,
  getActiveWorkspace
} from '../../../../../store/workspace';
import { getMembers } from '../../../../../utils/get-members';
import { convertMessageToString } from '../../../../common/comments/converters';
import Drawer from '../../../../common/drawer';

const ProjectCreatorDrawer = ({
  className,
  title,
  value,
  defaultValues,
  isRedirectAfterSubmit,
  onClose,
  callback,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const updateTags = useUpdateTags();

  const { t } = useTranslation(['AddEditProject', 'Toast']);

  const [isLoading, setIsLoading] = useState(false);

  const userEmployee = useSelector(getUserEmployee, shallowEqual);
  const workspace = useSelector(getActiveWorkspace, shallowEqual);

  const transformManager = manager => ({
    value: manager.id,
    label: {
      title: workspace.title,
      ...manager
    }
  });

  const managers = transformManager(defaultValues.managers || userEmployee);

  const onCreate = async ({ tags, ...values }) => {
    try {
      setIsLoading(true);

      const { description } = values.description;
      let fetchedProject = {};

      const project = {
        ...values,
        description,
        managers: values.managers.map(m => m.value),
        members: values.members.map(member => member.value)
      };

      if (Object.keys(value).length) {
        await dispatch(
          convertIdeaToProject({ ideaId: value.id, ideaData: project })
        );
      } else {
        fetchedProject = await dispatch(createProject({ project }));
      }

      await updateTags({
        entityType: TYPE_PROJECT,
        entityId: fetchedProject.id,
        newTags: tags
      });

      message.success(t('ProjectCreated', { ns: 'Toast' }), MESSAGE_DURATION);

      if (defaultValues.assetId) {
        await dispatch(
          changeRelations({
            id: defaultValues.assetId,
            relations: [
              {
                relationIds: [fetchedProject.id],
                relType: TYPE_PROJECT,
                isDelete: false
              }
            ]
          })
        );
      }

      await callback(fetchedProject);

      onClose();

      if (isRedirectAfterSubmit) {
        routes.toProject(fetchedProject.id);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const ideaData = {
    ...(value || {}),
    description: convertMessageToString(value.description),
    members: getMembers(value)
  };

  return (
    <Drawer
      title={<Drawer.Title>{t('CreatingProject')}</Drawer.Title>}
      data-qa="qa-mpa8x9foegmi6du"
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      documentTitleProps={{ title: t('CreatingProject') }}
      onClose={onClose}
      {...drawerProps}
    >
      <CreatorForm
        onSubmit={onCreate}
        data-qa="qa-jxth8whhougi2um"
        values={{ ...ideaData, managers: [managers] }}
        isDisabledManager
        isLoading={isLoading}
      />
    </Drawer>
  );
};

ProjectCreatorDrawer.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  defaultValues: PropTypes.object,
  isRedirectAfterSubmit: PropTypes.bool,
  callback: PropTypes.func
};

ProjectCreatorDrawer.defaultProps = {
  className: undefined,
  value: {},
  defaultValues: {},
  isRedirectAfterSubmit: false,
  callback: () => {}
};

export default ProjectCreatorDrawer;
