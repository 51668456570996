import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useGptActions from 'providers/gpt-widget-provider/use-gpt-actions';

import { GPT_MESSAGES_VIEW } from 'constants/index';

import SkeletonCard from 'components/common/skeleton-card';

import ChatItem from './chat-item';
import FilterByEntity from './filter-by-entity';

import styles from './gpt-chats.module.scss';

const GptChats = ({ fetchMessages, setView }) => {
  const { dialogs, isLoading, fetchChats, filters } = useGptActions();

  useEffect(() => {
    fetchChats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSelectDialog = dialog => {
    setView(GPT_MESSAGES_VIEW);

    fetchMessages({
      id: dialog.id,
      entity: {
        entityId: dialog.entity_id,
        entityType: dialog.entity_type,
        entity: dialog.entity
      }
    });
  };

  return (
    <div className={styles.root}>
      <FilterByEntity />

      {isLoading && <SkeletonCard count={4} paragraph={{ rows: 1 }} />}

      {!isLoading &&
        dialogs.map(dialog => (
          <div key={dialog.id} onClick={() => handleSelectDialog(dialog)}>
            <ChatItem dialog={dialog} />
          </div>
        ))}
    </div>
  );
};

GptChats.propTypes = {
  setView: PropTypes.func,
  fetchMessages: PropTypes.func
};

GptChats.defaultProps = {
  setView: () => {},
  fetchMessages: () => {}
};

export default GptChats;
