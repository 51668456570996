import { createSelector } from 'reselect';

export const getCalendar = state => state.calendar;

export const getCalendarAssets = createSelector(
  getCalendar,
  calendar => calendar.byAssets
);

export const getEmployeeCalendar = createSelector(
  getCalendar,
  calendar => calendar.byEmployees
);

export const getCalendarAssetsFilter = createSelector(
  getCalendarAssets,
  byAssets => byAssets.filter
);

export const getCalendarAssetsResources = createSelector(
  getCalendarAssets,
  byAssets => byAssets.resources
);

export const getCalendarAssetsRecord = createSelector(
  getCalendarAssets,
  byAssets => byAssets.record
);

export const getEmployeeCalendarFilter = createSelector(
  getEmployeeCalendar,
  byEmployees => byEmployees.filter
);

export const getEmployeeCalendarRecords = createSelector(
  getEmployeeCalendar,
  byEmployees => byEmployees.records
);
