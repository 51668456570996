import React from 'react';

export const MessageIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1747 0.825119C13.6248 0.275074 12.9622 0 12.1876 0H2.81248C2.03781 0 1.37527 0.275074 0.82512 0.825119C0.275074 1.37527 0 2.03778 0 2.81248V12.1874C0 12.9621 0.275074 13.6246 0.82512 14.1748C1.37527 14.7249 2.03781 15 2.81248 15H12.1874C12.9621 15 13.6247 14.7249 14.1746 14.1748C14.7247 13.6246 14.9998 12.9621 14.9998 12.1874V2.81248C14.9998 2.03778 14.7247 1.37516 14.1747 0.825119ZM7.50081 6.91211L11.3229 4.29701C11.2813 4.28978 11.2392 4.28508 11.196 4.28508H3.80566C3.76243 4.28508 3.72019 4.28996 3.67877 4.29701L7.50081 6.91211ZM7.73264 7.92845C7.7356 7.92684 7.73855 7.92524 7.74135 7.92326L11.9881 5.01738C11.9959 5.06654 12.0007 5.11669 12.0007 5.16837V9.83174C12.0007 10.3195 11.6404 10.7149 11.1959 10.7149H3.80559C3.361 10.7149 3.00073 10.3195 3.00073 9.83174V5.16837C3.00073 5.11669 3.00567 5.06645 3.01333 5.01738L7.26028 7.92326C7.26333 7.9254 7.26647 7.92709 7.2696 7.92878C7.27188 7.93002 7.27417 7.93125 7.27642 7.93265C7.28202 7.936 7.28778 7.93925 7.29347 7.94232C7.32369 7.95922 7.3544 7.97286 7.38602 7.98199C7.38818 7.9826 7.39037 7.98304 7.39255 7.98351C7.39362 7.98374 7.39468 7.98398 7.39574 7.98425C7.43041 7.99329 7.46549 7.99871 7.50057 7.99871H7.50098H7.50131C7.53639 7.99871 7.57139 7.9932 7.60622 7.98425C7.60717 7.98403 7.60811 7.98383 7.60906 7.98362C7.61126 7.98316 7.61347 7.98268 7.61577 7.98199C7.64739 7.97295 7.67835 7.95931 7.70841 7.94232L7.71061 7.9411C7.71548 7.93839 7.72058 7.93556 7.72537 7.93265C7.72775 7.9311 7.7302 7.92977 7.73264 7.92845Z"
      fill="#17A7D8"
    />
  </svg>
);

export default MessageIcon;
