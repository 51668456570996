import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PERIOD_MONTH } from 'constants/billing';

import { getFormattedAmount } from 'components/settings-view/billing/utils';
import Switch from 'components/common/controls/switch';
import InputNumber from 'components/common/controls/input-number';
import Typography from 'components/common/typography';

import AddonCard from '../../card';
import { getAccountAddonData } from '../utils';
import WhatsAppAccountAddonDescription from './description';

import styles from './whatsapp.module.scss';

const WhatsAppAccountAddon = ({
  availableAddon,
  selectedTariff,
  addon,
  checkout,
  account,
  changeAddon
}) => {
  const { t } = useTranslation('ChangePlan');

  const { count, isEnabled, isDisabled } = addon;

  const { title, period, currency, price, amount } = getAccountAddonData({
    availableAddon,
    checkout,
    selectedTariff
  });

  return (
    <AddonCard
      title={title}
      amountText={t(
        period === PERIOD_MONTH
          ? 'WhatsAppAmountMonthly'
          : 'WhatsAppAmountAnnually',
        {
          amount: getFormattedAmount({
            amount: price,
            currency
          })
        }
      )}
      detailsLink="https://help.upservice.com/articles/121935-как-подключить-whatsapp-к-upservice"
      headerAction={
        <Switch
          value={isEnabled}
          disabled={isDisabled}
          onChange={() => {
            changeAddon({ fieldKey: 'isEnabled', fieldValue: !isEnabled });

            if (!isEnabled) {
              changeAddon({
                fieldKey: 'count',
                fieldValue: 1
              });
            }
          }}
        />
      }
    >
      {isEnabled && (
        <div className={styles.content}>
          {checkout && (
            <WhatsAppAccountAddonDescription
              account={{
                ...account,
                nextPaymentDate: checkout.nextPaymentDate
              }}
              checkout={checkout}
            />
          )}

          <div>
            <Typography.Text size="small" color="black-55">
              {t('HowManyNumbers')}
            </Typography.Text>

            <div className={styles.count}>
              <InputNumber
                isOnlyWhole
                disableZeroEntry
                value={count}
                className={styles.input}
                onChange={e =>
                  changeAddon({
                    fieldKey: 'count',
                    fieldValue: e.target.value
                  })
                }
                onBlur={e => {
                  if (!e.target.value) {
                    changeAddon({
                      fieldKey: 'count',
                      fieldValue: 1
                    });
                  }
                }}
              />

              <Typography.Title level={3} style={{ margin: 0 }}>
                {t('FinalCost', {
                  amount: getFormattedAmount({
                    amount: amount * (count || 1),
                    currency
                  })
                })}
              </Typography.Title>
            </div>
          </div>
        </div>
      )}
    </AddonCard>
  );
};

WhatsAppAccountAddon.propTypes = {
  availableAddon: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }).isRequired,
  selectedTariff: PropTypes.shape({
    tariff: PropTypes.object,
    period: PropTypes.string,
    currency: PropTypes.string
  }).isRequired,
  addon: PropTypes.shape({
    count: PropTypes.string,
    isEnabled: PropTypes.bool,
    isDisabled: PropTypes.bool
  }),
  changeAddon: PropTypes.func.isRequired
};

WhatsAppAccountAddon.defaultProps = {
  addon: {
    count: 1,
    isEnabled: false,
    isDisabled: false
  }
};

export default WhatsAppAccountAddon;
