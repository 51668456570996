import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from 'constants/index';

import Button from 'components/common/button';
import {
  FormDatePicker,
  FormInput,
  FormLocationInput,
  withoutBubbling
} from 'components/common/hook-form';
import { useTheme } from 'components/common/theme';
import FormRadioColor from 'components/common/hook-form/radio/color';
import {
  TAG_TYPE_ADDRESS,
  TAG_TYPE_NUMBER,
  TAG_TYPE_TEXT,
  TAG_TYPE_DATE
} from 'components/common/tags/utils/tag-types';
import Typography from 'components/common/typography';

import { getFormattedTagNumber } from '../utils/formatted-number';
import { getInitialNameValue } from '../utils/initial-name-value';

import styles from './type-form.module.scss';
import { maxDate } from '../utils/normalize-date';
import InputTypeNumber from './input-type-number';

const TypeForm = ({
  searchValue,
  createCallback,
  editableTag,
  type,
  handleClose,
  isOpenModal,
  onSubmit,
  warningMessage
}) => {
  const { variables } = useTheme();
  const { t } = useTranslation(['Common', 'TagsEditReplaceDelete', 'Errors']);

  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const addressRef = useRef();

  const selectedType = editableTag ? editableTag.type : type;
  const initialColor = editableTag ? editableTag.color : variables.color.green;

  const methods = useForm({
    defaultValues: {
      name: selectedType === TAG_TYPE_ADDRESS ? searchValue : '',
      color: initialColor,
      type: ''
    }
  });

  useEffect(() => {
    const currentValues = methods.getValues();

    methods.reset({
      ...currentValues,
      type: selectedType,
      name: getInitialNameValue(selectedType, searchValue)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectedType]);

  useEffect(() => {
    let timeoutId;

    if (isOpenModal && selectedType && inputRef.current && !isLoading) {
      timeoutId = setTimeout(() => {
        inputRef.current.focus();

        if (selectedType === TAG_TYPE_ADDRESS && addressRef.current) {
          addressRef.current.debouncedFetchPredictions();
        }
      }, 300);
    }

    return () => clearTimeout(timeoutId);
  });

  const getFormatedName = values => {
    if (values.type === TAG_TYPE_DATE) {
      return moment(new Date(values.name)).format(DATE_FORMAT);
    }

    if (values.type === TAG_TYPE_NUMBER) {
      return getFormattedTagNumber(values.name);
    }

    return values.name;
  };

  const isOnlyWhitespace = str => /^\s*$/.test(str);
  const isOnlyDots = value => {
    const dotMatches = value.match(/\./g) || [];
    return dotMatches.length > 0 && /^\.+$/.test(value);
  };

  const handleSubmit = async values => {
    if (
      isOnlyWhitespace(values.name) ||
      (selectedType === TAG_TYPE_NUMBER && isOnlyDots(values.name))
    ) {
      methods.setValue('name', '', { shouldValidate: true });
      return;
    }

    try {
      setIsLoading(true);

      const dataToSave = {
        ...values,
        name: getFormatedName(values).trim()
      };

      const createdTag = await onSubmit({
        tag: {
          ...(editableTag || {}),
          ...dataToSave
        }
      });

      await createCallback(createdTag);

      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        {warningMessage && (
          <div className={styles.warningSection}>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {warningMessage}
            </Typography.Paragraph>
          </div>
        )}

        <div className={styles.fieldWrap}>
          {selectedType === TAG_TYPE_TEXT && (
            <FormInput
              name="name"
              label={t('TagName', { ns: 'TagsEditReplaceDelete' })}
              ref={inputRef}
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                maxLength: {
                  value: 25,
                  message: t('TagMaxLength', {
                    ns: 'TagsEditReplaceDelete'
                  })
                }
              }}
              data-testid="text-type-input"
            />
          )}

          {selectedType === TAG_TYPE_NUMBER && (
            <InputTypeNumber ref={inputRef} />
          )}

          {selectedType === TAG_TYPE_ADDRESS && (
            <FormLocationInput
              label={t('SelectAddress', { ns: 'TagsEditReplaceDelete' })}
              name="name"
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
              inputRef={inputRef}
              ref={addressRef}
              data-testid="address-type-input"
            />
          )}

          {selectedType === TAG_TYPE_DATE && (
            <>
              <FormDatePicker
                name="name"
                label={t('SelectDate', { ns: 'TagsEditReplaceDelete' })}
                showTimeSelect={false}
                autoFocus
                rules={{
                  required: t('RequiredField', { ns: 'Errors' })
                }}
                wrapperClassname={styles.datePickerWrapper}
                dataTestId="date-type-input"
                maxDate={maxDate}
              />
            </>
          )}
        </div>

        <FormRadioColor
          label={t('TagColor', { ns: 'TagsEditReplaceDelete' })}
          name="color"
          itemProps={{
            className: styles.colorItem
          }}
          groupClassName={styles.colorGroup}
        />

        <div className={styles.formButtons}>
          <Button type="secondary" width="full" onClick={handleClose}>
            {t('CancelBtn', { ns: 'TagsEditReplaceDelete' })}
          </Button>

          <Button
            type="primary"
            width="full"
            htmlType="submit"
            loading={isLoading}
          >
            {t('Save', { ns: 'Common' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

TypeForm.propTypes = {
  searchValue: PropTypes.string,
  createCallback: PropTypes.func,
  editableTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    link: PropTypes.string
  }),
  type: PropTypes.string,
  handleClose: PropTypes.func,
  isOpenModal: PropTypes.bool,
  warningMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

TypeForm.defaultProps = {
  searchValue: '',
  createCallback: () => {},
  editableTag: null,
  type: '',
  handleClose: () => {},
  isOpenModal: false,
  warningMessage: undefined
};

export default TypeForm;
