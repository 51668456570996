import { ACTIONS } from '../../constants/api';

export default api => ({
  accept: (workspaceId, actionId, isAccept, withExtendPerms) =>
    api.patch(`/v1/${workspaceId}/${ACTIONS}/${actionId}/accept/`, {
      accept: isAccept,
      withExtendPerms
    }),

  joining: workspaceId => api.get(`/v1/${workspaceId}/actions/joining/`),

  fillNegotiation: data => api.post(`/v1/negotiations/fill/`, data),

  fetchVersion: () => api.get('v1/version/', { retryCount: 0 })
});
