import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormItem from '../form-item';
import AttachmentsTabs from './tabs';

export const FormAttachments = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => <AttachmentsTabs {...field} {...props} />}
      />
    </FormItem>
  );
};

FormAttachments.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormAttachments.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormAttachments;
