import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ImportContactsIcon } from 'components/common/icons';
import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import styles from './modals.module.scss';

const ImportProcessModal = ({ visible, onClose }) => {
  const { t } = useTranslation('ContactImport');

  return (
    <Modal
      width={440}
      visible={visible}
      onClose={onClose}
      contentClassName={styles.processModal}
      centered
      closable={false}
    >
      <ImportContactsIcon />

      <Typography.Title>{t('ContactImportProcessingHeading')}</Typography.Title>

      <Typography.Text className={styles.info} color="black-45">
        {t('ContactImportProcessingDesc')}
      </Typography.Text>

      <Button type="primary" onClick={onClose} className={styles.closeBtn}>
        {t('MinimizeToBackgroundBtn')}
      </Button>
    </Modal>
  );
};

export default ImportProcessModal;

ImportProcessModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

ImportProcessModal.defaultProps = {
  visible: false,
  onClose: () => {}
};
