import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';

import styles from './view-info.module.scss';

const BillingSettings = () => {
  const { t } = useTranslation('BillingSettings');

  return (
    <div className={styles.billingSettings}>
      <Typography.Title level={2} className={styles.title}>
        {t('BillingSettingsHeading')}
      </Typography.Title>
    </div>
  );
};

export default BillingSettings;
