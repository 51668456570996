import React from 'react';

export const GetTheLinkIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    style={style}
    width="105"
    height="105"
    viewBox="0 0 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.5 95.3C62.3 95.4 61.1 95.4 59.9 95.4C54.1 95.3 48 95.5 42.2 95.5C41.9 95.5 41.6 95.5 41.3 95.5C37.4 95.6 33.6 95.6 30.1 95.4C28.8 95.3 27.6 95.3 26.4 95.1C14.5 94 6.69996 90 9.69996 77.5C9.99996 76.4 10.1 75.4 9.99996 74.2C9.69996 69.5 6.89996 64 4.69996 58.5C4.19996 57.2 3.69996 55.8 3.29996 54.4C2.79996 52.8 2.39996 51.2 2.19996 49.7C1.79996 47 1.89996 44.4 2.69996 41.7C2.69996 41.7 3.59996 39 5.49996 36.5C7.79996 33.5 35.6 4.39999 68.3 15.6C69.6 16.1 101.8 27.6 102.3 54.1C102.8 80.1 72.9 94.8 63.5 95.3Z"
      fill="#EBF8F8"
    />
    <path
      d="M75.9 16.2V70.8H28.4V16.2C28.4 12.7 31.2 10 34.6 10H69.7C70.8 10 71.8 10.3 72.6 10.7C74.6 11.8 75.9 13.9 75.9 16.2Z"
      fill="#D8D8D8"
    />
    <path
      d="M75.8999 70.75V75.25C75.8999 78.75 73.0999 81.45 69.6999 81.45H34.5999C33.6999 81.45 32.7999 81.25 32.0999 80.95C29.8999 79.95 28.3999 77.85 28.3999 75.35V70.85H75.8999V70.75Z"
      fill="white"
    />
    <path
      d="M52.2 78.3C53.4 78.3 54.4 77.3 54.4 76.1C54.4 74.9 53.4 73.9 52.2 73.9C51 73.9 50 74.9 50 76.1C50 77.3 51 78.3 52.2 78.3Z"
      fill="#D8D8D8"
    />
    <path
      d="M72.2 44.3V63.7C72.2 65.6 70.7 67.1 68.8 67.1H34.9C33 67.1 31.5 65.6 31.5 63.7V44.3L48.6 57.6C50.6 59.1 53.2 59.1 55.2 57.6L72.2 44.3Z"
      fill="#FFE181"
    />
    <path
      d="M33.1 34.5L49.6 22.8C51.1 21.7 53.1 21.7 54.5 22.8L71 34.5C70.5 34.8 69.9 35 69.2 35H34.9C34.2 35 33.6 34.8 33.1 34.5Z"
      fill="#FCC977"
    />
    <path
      d="M72.5 36.4C72.5 46.5 72.4 53.7 72.5 55.4C72.5 55.6 72.5 56.3 72.2 57C71.7 58.2 70.6 58.7 70.3 58.9C69.6 59.3 68.9 59.4 68.4 59.4C64.3 59.4 60.2 59.3 56 59.3L55.4 59.7C54.7 60.2 54 60.7 53.4 61.2L53.1 61.4C52.6 61.8 52.1 62.1 51.7 62.5C50.4 61.4 49 60.4 47.8 59.3H35.5C33.3 59.3 31.5 57.5 31.5 55.3V36.3C31.5 34.9 32.4 33.7 33.5 32.9L33.6 32.8C34.2 32.4 35 32.3 35.7 32.3H68.6C70.7 32.4 72.5 34.2 72.5 36.4Z"
      fill="#FCC977"
    />
    <path
      d="M72.5 34.1C72.5 44.2 72.4 51.4 72.5 53.1C72.5 53.3 72.5 54 72.2 54.7C71.7 55.9 70.6 56.4 70.3 56.6C69.6 57 68.9 57.1 68.4 57.1C64.3 57.1 60.2 57 56 57L55.4 57.4C54.7 57.9 54 58.4 53.4 58.9L53.1 59.1C52.6 59.5 52.1 59.8 51.7 60.2C50.4 59.1 49 58.1 47.8 57H35.5C33.3 57 31.5 55.2 31.5 53V34C31.5 32.6 32.4 31.4 33.5 30.6L33.6 30.5C34.2 30.1 35 30 35.7 30H68.6C70.7 30.1 72.5 31.9 72.5 34.1Z"
      fill="white"
    />
    <path
      d="M69.3 39.6C72.7 39.6 75.5 36.8 75.5 33.4C75.5 30 72.7 27.2 69.3 27.2C65.9 27.2 63.1 30 63.1 33.4C63.1 36.8 65.9 39.6 69.3 39.6Z"
      fill="#F7413B"
    />
    <path
      d="M70.6 30.7V36.1H69V32.6C68.7 32.8 68.5 32.9 68.3 33.1C68 33.2 67.8 33.3 67.4 33.4V32.2C67.9 32.1 68.3 31.9 68.6 31.6C68.9 31.4 69.1 31.1 69.3 30.7H70.6Z"
      fill="white"
    />
    <path
      d="M48.8 45.1H34.9C34.3 45.1 33.9 44.7 33.9 44.1C33.9 43.5 34.3 43.1 34.9 43.1H48.8C49.4 43.1 49.8 43.5 49.8 44.1C49.8 44.7 49.3 45.1 48.8 45.1Z"
      fill="#2B2A36"
    />
    <path
      d="M62.4 40.2H34.9C34.3 40.2 33.9 39.8 33.9 39.2C33.9 38.6 34.3 38.2 34.9 38.2H62.4C63 38.2 63.4 38.6 63.4 39.2C63.4 39.8 62.9 40.2 62.4 40.2Z"
      fill="#2B2A36"
    />
    <path
      d="M59.6 45.1H54.4C53.8 45.1 53.4 44.7 53.4 44.1C53.4 43.5 53.8 43.1 54.4 43.1H59.6C60.2 43.1 60.6 43.5 60.6 44.1C60.6 44.7 60.1 45.1 59.6 45.1Z"
      fill="#2B2A36"
    />
    <path
      d="M68.5 49.7H34.9C34.3 49.7 33.9 49.3 33.9 48.7C33.9 48.1 34.3 47.7 34.9 47.7H68.6C69.2 47.7 69.6 48.1 69.6 48.7C69.6 49.3 69.1 49.7 68.5 49.7Z"
      fill="#2B2A36"
    />
  </svg>
);

export default GetTheLinkIcon;
