export const scrollToFirstError = methods => {
  if (methods.formState.errors) {
    const elements = Object.keys(methods.formState.errors)
      .map(name => document.getElementsByName(name)[0])
      .filter(el => !!el);

    elements.sort(
      (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
    );

    if (elements.length > 0) {
      const errorElement = elements[0];
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};

export default scrollToFirstError;
