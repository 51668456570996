export default api => ({
  fetchAll: (workspace, limit, offset) =>
    api.get(`/v1/${workspace}/workspace-user-categories/`, {
      params: { workspace, limit, offset }
    }),

  add: (workspaceId, employeeId, employeeCategory) =>
    api.post(`/v1/${workspaceId}/employees-categories/`, {
      employee: employeeId,
      workspaceCategory: employeeCategory
    }),

  delete: (workspaceId, employeeId) =>
    api.delete(`/v1/${workspaceId}/employees-categories/${employeeId}/`)
});
