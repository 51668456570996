import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';

import { WEBSITE, EMAIL, TELEGRAM, CHANNEL_TYPES } from 'constants/index';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import { UnknownPersonIcon } from 'components/common/icons';

import useRoutesService from 'services/routes';

import Option from '../option';

import styles from './dialog-option.module.scss';

export const DialogOption = ({
  option,
  className,
  isLinkToElement,
  ...props
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const link = routes.toDialog(option.uuid);

  const ChannelIcon = useMemo(
    () => (CHANNEL_TYPES.find(t => t.type === option.channel.kind) || {}).Icon,
    [option.channel.kind]
  );

  const Info = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Badge
        offset={[-14, 18]}
        count={
          <Icon
            component={ChannelIcon}
            size={12}
            className={styles.channelIcon}
          />
        }
      >
        {!(option.avatarFile || {}).url ? (
          <Icon
            component={() => (
              <UnknownPersonIcon style={{ width: 24, height: 24 }} />
            )}
            style={{ marginRight: 8, flexShrink: 0 }}
            color="white"
          />
        ) : (
          <Avatar
            src={(option.avatarFile || {}).url}
            size={24}
            style={{ marginRight: 8, flexShrink: 0 }}
          />
        )}
      </Badge>

      <Typography.Text
        className={isLinkToElement ? styles.textPrimary : undefined}
        ellipsis
      >
        {option.title}
      </Typography.Text>
    </div>
  );

  return (
    <Option
      className={classnames(className, styles.root)}
      contentClassName={styles.content}
      {...props}
    >
      {isLinkToElement ? (
        <Link to={link}>
          <Info />
        </Link>
      ) : (
        <Info />
      )}
    </Option>
  );
};

DialogOption.propTypes = {
  option: PropTypes.shape({
    avatarFile: PropTypes.object,
    channel: PropTypes.shape({
      kind: PropTypes.oneOf([WEBSITE, EMAIL, TELEGRAM])
    }),
    uuid: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  isLinkToElement: PropTypes.bool
};

DialogOption.defaultProps = {
  isLinkToElement: false
};

export default DialogOption;
