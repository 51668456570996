import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import styles from './controls.module.scss';

const ControlsParentMessage = ({
  header,
  content,
  fileList,
  isMessageContent,
  onClick,
  onDelete
}) => (
  <div
    className={classnames(styles.parentMessage, {
      [styles.isMessageContent]: isMessageContent
    })}
  >
    <div className={styles.parentMessageInfo} onClick={onClick}>
      <Typography.Text className={styles.parentMessageHeader}>
        {header}
      </Typography.Text>

      <Typography.Text ellipsis className={styles.parentMessageContent}>
        {content}
      </Typography.Text>

      {!!fileList.length && (
        <Typography.Text ellipsis className={styles.parentMessageContent}>
          {fileList.map(file => `(${file.title})`).join(', ')}
        </Typography.Text>
      )}
    </div>

    {onDelete && (
      <Button
        type="text"
        mood="none"
        className={styles.parentMessageDeleteBtn}
        onClick={onDelete}
      >
        <Icon type="close" />
      </Button>
    )}
  </div>
);

ControlsParentMessage.propTypes = {
  isMessageContent: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  fileList: PropTypes.array
};

ControlsParentMessage.defaultProps = {
  onClick: () => {},
  isMessageContent: false,
  onDelete: null,
  fileList: []
};

export default ControlsParentMessage;
