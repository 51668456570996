import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  ATTACHMENT_TYPE_ENTITY,
  TASK,
  TYPE_ACQUAINTANCE,
  TYPE_MEETING,
  THIS_AND_NEXT,
  TYPE_CONTACT,
  TYPE_AGREEMENT
} from 'constants/index';

import Drawer from 'components/common/drawer';
import MeetingCalendar from 'components/tasks-view/components/meeting-calendar';
import useTemplateRelations from 'components/tasks-view/template-view/use-template-relations';
import { transformRelationsToSelectValue } from 'components/common/controls/custom-select/relations-select/utils';
import { getDiffRelations } from 'components/common/drawers/relations/utils';
import { checkChangedDates } from 'components/tasks-view/utils';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';

import {
  editTaskFields,
  setEstimate,
  fetchAttachments,
  fetchAgreementSteps,
  fetchSubtaskList,
  fetchCoResponsibles,
  fetchTaskLocal,
  fetchTemplateLocal,
  editTemplateFieldsLocal,
  fetchRelations,
  fetchTemplateRelationsLocal,
  changeRelations
} from 'store/tasks';

import transformTask from 'utils/transform-task';
import { useTaskDataProvider } from 'hooks';
import { convertToMinutes } from 'utils/convert-to-minutes';
import getFileIds from 'hooks/common/use-file-upload/get-file-ids';
import { showNoticeMessage } from 'services/notice';

import useValidityDate from 'components/common/validity-date/use-validity-date';
import EditorDatesModal from './editor-dates-modal';
import BaseForm from './base-form';
import {
  clearAccordingType,
  getAgreementSteps,
  getChangedFgreement
} from '../creator/utils';
import ChangingSchedulerTaskModal from './changing-scheduler-task-modal';

const TaskEditorDrawer = ({
  visible,
  taskId,
  onClose,
  callback,
  ...drawerProps
}) => {
  const dispatch = useDispatch();

  const [afterChangeVisible, setAfterChangeVisible] = useState(false);
  const [task, isLoading] = useTaskDataProvider(afterChangeVisible, taskId);
  const [visibleEditorDatesModal, setVisibleEditorDatesModal] = useState(false);
  const [visibleCalender, setVisibleCandar] = useState(false);
  const [
    changingSchedulerTaskModalData,
    setChangingSchedulerTaskModalData
  ] = useState(null);

  const transformedTask = task && transformTask(task);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingDeps, setIsLoadingDeps] = useState(true);

  const { updateTemplateRelations } = useTemplateRelations();
  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const { t } = useTranslation(['AddTask', 'Toast']);

  const isTypeAcquaintence = (task || {}).kind === TYPE_ACQUAINTANCE;
  const isTypeMeeting = (task || {}).kind === TYPE_MEETING;

  const dispatchNewFields = async (values, changingScheduler) => {
    const value = {
      id: task.id,
      data: {
        ...values,
        responsible: (values.responsible || {}).value,
        controller: (values.controller || {}).value || null,
        description: values.description || undefined,
        dateEnd:
          values.dateEnd || values.dateEnd === undefined
            ? values.dateEnd
            : null,
        dateStart:
          values.dateStart || values.dateStart === undefined
            ? values.dateStart
            : null,
        fileList: getFileIds(values.fileList),
        agreementSteps: getChangedFgreement(
          getAgreementSteps(task.kind, task.agreementSteps),
          getAgreementSteps(task.kind, values.agreementSteps)
        )
      }
    };

    const editedTask = await dispatch(editTaskFields({ value }));

    if (isTypeMeeting) {
      const newContactRelations = (values.relations || []).filter(
        r => r.relationType === TYPE_CONTACT
      );
      const defaultContactRelations = (task.relations || []).filter(
        r => r.type === TYPE_CONTACT
      );

      const newRelations = newContactRelations.map(r => ({
        ...r,
        value: r.relationId,
        type: r.relationType
      }));
      const defaultRelations = defaultContactRelations.map(r => ({
        ...r,
        value: r.objectId,
        relationId: r.id
      }));

      const { added, deleted } = getDiffRelations(
        defaultRelations,
        newRelations,
        true
      );

      const result = [
        ...deleted.map(d => ({ ...d, isDelete: true })),
        ...added.map(d => ({ ...d, isDelete: false }))
      ];

      if (result.length) {
        await dispatch(
          changeRelations({
            id: task.id,
            relations: result
          })
        );
      }
    }

    if (changingScheduler === THIS_AND_NEXT) {
      await dispatch(
        editTemplateFieldsLocal({
          value: {
            ...value,
            id: task.template,
            data: {
              ...value.data,
              schedulerConfig: undefined,
              members: value.data.coResponsibles
            }
          }
        })
      );

      if (isTypeMeeting) {
        const templateRelations = await dispatch(
          fetchTemplateRelationsLocal({ id: task.template })
        );

        await updateTemplateRelations(
          transformRelationsToSelectValue(templateRelations),
          values.relations,
          task.template
        );
      }
    }

    return editedTask;
  };

  const dispatchEstimate = async estimation => {
    if (estimation !== transformedTask.estimation) {
      await dispatch(setEstimate({ id: transformedTask.id, estimation }));
    }
  };

  const getEstimate = values =>
    convertToMinutes({
      days: +values.days,
      hours: +values.hours,
      minutes: +values.minutes
    });

  const dispatchNewValues = async (values, changingScheduler) => {
    await dispatchEstimate(getEstimate(values));

    const editedTask = await dispatchNewFields(values, changingScheduler);
    return editedTask;
  };

  const checkSheduler = async (values, submit) => {
    if (task.template) {
      const template = await dispatch(
        fetchTemplateLocal({ id: task.template })
      );

      if (template.isScheduler && isTypeMeeting) {
        return setChangingSchedulerTaskModalData(values.values);
      }
    }

    return submit(values);
  };

  const sendValues = async ({
    values = changingSchedulerTaskModalData,
    changingScheduler
  }) => {
    try {
      setIsLoadingSend(true);

      const resultData = clearAccordingType(values);

      const dateStart =
        task.dateStart &&
        moment(values.dateStart)
          .startOf('minute')
          .isSame(moment(task.dateStart).startOf('minute'))
          ? undefined
          : values.dateStart;

      const dateEnd = moment(values.dateEnd).isSame(task.dateEnd)
        ? undefined
        : values.dateEnd;

      const editedTask = await dispatchNewValues(
        { ...resultData, dateStart, dateEnd },
        changingScheduler
      );

      if (task.kind === TYPE_MEETING || task.kind === TYPE_AGREEMENT) {
        await dispatch(fetchSubtaskList({ id: task.id }));
      }

      await checkChangedDates({
        dispatch,
        task,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        setVisibleModal: setVisibleEditorDatesModal
      });

      if (callback) {
        await callback(editedTask);
      }

      await manageBulkSubscribers(values.fileList);

      await handleChangeBulkValidityDates({
        fileList: values.fileList,
        isFromEditor: true
      });

      await fetchFileList();

      onClose();
      setChangingSchedulerTaskModalData(null);

      return showNoticeMessage({
        customContent: t('TaskEdited', { ns: 'Toast' })
      });
    } finally {
      setIsLoadingSend(false);
    }
  };

  const fetchFileList = () =>
    dispatch(fetchAttachments({ id: task.id, source: ATTACHMENT_TYPE_ENTITY }));
  const fetchAllAgreementSteps = () =>
    dispatch(fetchAgreementSteps({ id: task.id }));
  const fetchAllCoResponsibles = async () => {
    if (isTypeAcquaintence || isTypeMeeting) {
      let { id } = task;

      if (task.parent) {
        const parentTask = await dispatch(fetchTaskLocal({ id: task.parent }));

        if (parentTask.kind !== TASK) {
          id = task.parent;
        }
      }

      dispatch(
        fetchCoResponsibles({
          id
        })
      );
    }
  };
  const fetchAllRelations = () => {
    if (isTypeMeeting) {
      dispatch(fetchRelations({ id: task.id }));
    }
  };

  useEffect(() => {
    const fetchDependencies = () => {
      setIsLoadingDeps(true);

      Promise.all([
        fetchFileList(),
        fetchAllAgreementSteps(),
        fetchAllCoResponsibles(),
        fetchAllRelations()
      ]).finally(() => setIsLoadingDeps(false));
    };

    if (afterChangeVisible && !!(task || {}).id) {
      fetchDependencies();
    } else {
      setVisibleCandar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterChangeVisible, (task || {}).id]);

  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        destroyOnClose
        visible={visible}
        maskClosable={false}
        afterVisibleChange={setAfterChangeVisible}
        title={<Drawer.Title>{t('EditTaskHeading')}</Drawer.Title>}
        width={620}
        onClose={onClose}
        {...drawerProps}
      >
        <MeetingCalendar visible={visibleCalender} parentTaskId={taskId}>
          <BaseForm
            onSubmit={values => checkSheduler(values, sendValues)}
            values={transformedTask}
            isLoadingAll={isLoading || isLoadingDeps}
            isLoading={isLoadingSend}
            meetingCalendar={{
              visible: visibleCalender,
              setVisible: setVisibleCandar
            }}
          />
        </MeetingCalendar>
      </Drawer>

      <ChangingSchedulerTaskModal
        isLoading={isLoadingSend}
        onSubmit={sendValues}
        visible={!!changingSchedulerTaskModalData}
        onClose={() => setChangingSchedulerTaskModalData(null)}
      />

      <EditorDatesModal
        visible={visibleEditorDatesModal}
        onClose={() => setVisibleEditorDatesModal(false)}
        params={{
          id: (task || {}).id,
          dateStart: (task || {}).dateStart,
          dateEnd: (task || {}).dateEnd
        }}
      />
    </>
  );
};

export default TaskEditorDrawer;
