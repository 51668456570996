import React from 'react';

export const BigFormIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="151"
    height="151"
    viewBox="0 0 151 151"
    fill="none"
  >
    <g clipPath="url(#clip0_75357_455354)">
      <path
        d="M100.99 17.4581C109.989 18.3743 118.7 20.0838 118.7 20.0838C121.326 20.6035 122.639 20.8907 123.979 21.2052C141.211 25.2396 151.851 32.6246 144.89 50.2528C144.288 51.7571 143.973 53.2888 143.85 54.9573C143.385 61.9046 146.367 70.411 148.459 78.9995C148.965 81.0508 149.375 83.0749 149.69 85.1946C150.045 87.6153 150.305 90.0222 150.292 92.2924C150.346 96.2447 149.621 100.101 147.926 103.821C147.926 103.821 146.134 107.527 142.825 110.823C138.831 114.721 92.4837 151.632 47.0114 128.807C28.3576 119.425 1.68961 96.0669 5.08122 65.7475C7.76169 41.7054 28.2071 23.6122 46.0268 17.5811C59.0188 13.1912 73.0093 14.6135 100.99 17.4581Z"
        fill="#EBF8F8"
      />
      <path
        d="M125.771 46.4252L117.661 116.883C116.977 122.805 111.616 127.058 105.681 126.374L53.9724 120.425C48.0371 119.742 43.7839 114.381 44.454 108.459L53.3843 30.8347C54.0681 24.8994 59.4291 20.6462 65.3644 21.33L109.551 26.4174L125.771 46.4252Z"
        fill="white"
      />
      <path
        d="M125.771 46.4258L107.486 44.3197L109.551 26.418L125.771 46.4258Z"
        fill="#D9D9D9"
      />
      <path
        d="M77.0163 51.1825C75.3478 48.7756 72.7084 47.0934 69.5903 46.7379C69.4945 46.7379 69.3988 46.7105 69.3031 46.7379C63.6549 46.2319 58.6222 50.2936 57.9658 55.9554C57.3093 61.6172 61.4395 66.9098 67.197 67.5799C72.9545 68.25 78.1514 64.1336 78.8215 58.3624C79.1224 55.7092 78.4112 53.2202 77.0163 51.2099V51.1825ZM67.8398 61.9728C65.173 61.6719 63.2584 59.2376 63.5592 56.5845C63.8601 53.9314 66.1166 52.1672 68.6603 52.3176C68.7697 52.3176 68.8518 52.3176 68.9475 52.3176C70.5202 52.4954 71.8468 53.4117 72.5579 54.7109C73.0776 55.5725 73.3238 56.6255 73.2007 57.6922C72.8998 60.359 70.4929 62.2873 67.8261 61.9728H67.8398Z"
        fill="#32C9C9"
      />
      <path
        d="M73.2007 57.6922C72.8998 60.359 70.4929 62.2736 67.8261 61.9727C65.1593 61.6719 63.2447 59.2512 63.5455 56.5845C63.8464 53.9177 66.1029 52.1671 68.6466 52.3176C68.756 52.3176 68.8244 52.3176 68.9338 52.3176C70.5202 52.4954 71.8331 53.4253 72.5579 54.7109C73.0639 55.5861 73.3101 56.6255 73.187 57.6922H73.2007Z"
        fill="white"
      />
      <path
        d="M114.789 59.3465L86.8492 56.1327C85.7414 56.0096 84.9482 54.9976 85.0713 53.9036C85.1944 52.8095 86.2064 52.0026 87.3005 52.1257L115.24 55.3395C116.348 55.4626 117.141 56.4746 117.018 57.5687C116.895 58.6628 115.883 59.4696 114.789 59.3465Z"
        fill="#32C9C9"
      />
      <path
        d="M113.613 69.5497L85.673 66.3358C84.5653 66.2128 83.7721 65.2007 83.8952 64.1067C84.0182 63.0126 85.0303 62.2057 86.1243 62.3288L114.064 65.5426C115.172 65.6657 115.965 66.6777 115.842 67.7718C115.719 68.8659 114.707 69.6728 113.613 69.5497Z"
        fill="#32C9C9"
      />
      <path
        d="M73.5152 81.5732C71.8467 79.1662 69.2073 77.4841 66.0892 77.1285C65.9934 77.1285 65.8977 77.1011 65.802 77.1285C60.1539 76.6225 55.1211 80.6842 54.4647 86.346C53.8083 92.0078 57.9384 97.3004 63.6959 97.9705C69.4534 98.6406 74.6503 94.5242 75.3204 88.753C75.6213 86.0999 74.9101 83.6109 73.5152 81.6005V81.5732ZM64.3387 92.3634C61.6719 92.0625 59.7573 89.6282 60.0581 86.9751C60.359 84.322 62.6155 82.5578 65.1592 82.7082C65.2686 82.7082 65.3507 82.7082 65.4464 82.7082C67.0191 82.886 68.3457 83.8023 69.0568 85.1015C69.5765 85.9631 69.8227 87.0161 69.6996 88.0829C69.3987 90.7497 66.9918 92.678 64.325 92.3634H64.3387Z"
        fill="#32C9C9"
      />
      <path
        d="M69.6996 88.0828C69.3987 90.7496 66.9918 92.6642 64.325 92.3634C61.6582 92.0625 59.7436 89.6419 60.0444 86.9751C60.3453 84.3083 62.6018 82.5578 65.1455 82.7082C65.2549 82.7082 65.3233 82.7082 65.4327 82.7082C67.0191 82.886 68.332 83.816 69.0568 85.1015C69.5628 85.9767 69.809 87.0161 69.6859 88.0828H69.6996Z"
        fill="white"
      />
      <path
        d="M111.288 89.7372L83.3481 86.5233C82.2403 86.4003 81.4471 85.3883 81.5702 84.2942C81.6933 83.2001 82.7053 82.3932 83.7994 82.5163L111.739 85.7301C112.847 85.8532 113.64 86.8652 113.517 87.9593C113.394 89.0534 112.382 89.8603 111.288 89.7372Z"
        fill="#32C9C9"
      />
      <path
        d="M110.112 99.9403L82.1719 96.7265C81.0642 96.6034 80.271 95.5914 80.3941 94.4973C80.5171 93.4032 81.5292 92.5964 82.6232 92.7194L110.563 95.9333C111.671 96.0564 112.464 97.0684 112.341 98.1624C112.218 99.2565 111.206 100.063 110.112 99.9403Z"
        fill="#32C9C9"
      />
      <path
        d="M10.1003 130.092L25.1437 119.219L19.099 110.863L4.05556 121.736C1.75801 123.39 1.23833 126.604 2.90679 128.902L2.93414 128.943C4.58892 131.254 7.80275 131.774 10.1003 130.092Z"
        fill="url(#paint0_linear_75357_455354)"
      />
      <path
        d="M20.1383 112.261L24.159 117.813L28.5763 114.627L24.5556 109.074L20.1383 112.261Z"
        fill="#2B2A36"
      />
      <path
        d="M21.4103 96.1221C19.4546 108.266 27.7148 119.713 39.859 121.669C52.0032 123.624 63.4499 115.364 65.4055 103.22C67.3612 91.0757 59.1009 79.629 46.9568 77.6733C34.8126 75.7177 23.3659 83.9779 21.4103 96.1221Z"
        fill="#2B2A36"
      />
      <path
        d="M27.1405 97.0526C25.6908 106.038 31.8039 114.503 40.789 115.953C49.774 117.402 58.2394 111.289 59.689 102.304C61.1387 93.3191 55.0256 84.8538 46.0405 83.4041C37.0555 81.9545 28.5901 88.0676 27.1405 97.0526Z"
        fill="#F2F2F2"
      />
      <mask
        id="mask0_75357_455354"
        maskUnits="userSpaceOnUse"
        x="37"
        y="91"
        width="12"
        height="18"
      >
        <path
          d="M48.9808 91.3477H37.835V108.99H48.9808V91.3477Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_75357_455354)">
        <path
          d="M43.4145 108.962C42.7307 108.962 42.17 108.442 42.17 107.786C42.17 107.13 42.7307 106.61 43.4145 106.61C44.0983 106.61 44.659 107.13 44.659 107.786C44.659 108.442 44.0983 108.962 43.4145 108.962ZM45.3701 101.509C44.9325 101.7 44.6453 102.124 44.6453 102.576V103.109C44.6453 103.752 44.0846 104.285 43.4008 104.285C42.717 104.285 42.1563 103.765 42.1563 103.109V102.576C42.1563 101.208 43.0042 99.9498 44.3171 99.3754C45.5753 98.8284 46.4915 97.365 46.4915 96.6539C46.4915 95.0401 45.0966 93.7273 43.4008 93.7273C41.705 93.7273 40.3101 95.0401 40.3101 96.6539C40.3101 97.2967 39.7493 97.83 39.0655 97.83C38.3818 97.83 37.821 97.2967 37.821 96.6539C37.821 93.7409 40.3237 91.375 43.3871 91.375C46.4505 91.375 48.9532 93.7409 48.9532 96.6539C48.9532 98.2403 47.5036 100.565 45.3428 101.509H45.3701Z"
          fill="#36B7BD"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_75357_455354"
        x1="10.249"
        y1="130.55"
        x2="17.657"
        y2="111.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3DF73" />
        <stop offset="1" stopColor="#3FBCA9" />
      </linearGradient>
      <clipPath id="clip0_75357_455354">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BigFormIcon;
