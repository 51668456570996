import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import { FormInput, FormInputNumber } from 'components/common/hook-form';

import styles from './table.module.scss';

const { Text } = Typography;

const CellForm = ({ number, name, defaultValue, onSubmit }) => {
  const { t } = useTranslation('Errors');

  const methods = useForm({
    defaultValues: {
      [name]: defaultValue
    }
  });

  const inputProps = {
    name,
    rules: {
      required: t('RequiredField'),
      validate: value => {
        if (number) {
          return value > 0 || t('GreaterThen', { value: 0 });
        }
        return true;
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onBlur={methods.handleSubmit(onSubmit)}
      >
        {number ? (
          <FormInputNumber {...inputProps} />
        ) : (
          <FormInput {...inputProps} />
        )}
      </form>
    </FormProvider>
  );
};

const EditableCell = ({
  editable,
  children,
  record,
  dataIndex,
  number,
  handleSave,
  ...props
}) => {
  const handleSubmit = value => handleSave({ ...record, ...value });

  return (
    <td {...props}>
      {editable ? (
        <CellForm
          number={number}
          name={dataIndex}
          defaultValue={record[dataIndex]}
          onSubmit={handleSubmit}
        />
      ) : (
        <Text className={styles.tableCellText}>{children}</Text>
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  record: PropTypes.shape({}),
  dataIndex: PropTypes.string.isRequired,
  number: PropTypes.bool,
  handleSave: PropTypes.func.isRequired
};

EditableCell.defaultProps = {
  editable: false,
  record: {},
  number: false
};

export default EditableCell;
