import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchCategoriesLocal } from 'store/assets';

import CustomSelect from '../custom-select';
import Modal from './modal';

const mapValue = value => ({
  value: value.id,
  label: value.title
});

const HandbookSelect = ({ isMulti, value, ...props }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDropdownMenu, setVisibleDropdownMenu] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation('Common');

  const onClickCategories = useCallback(() => {
    setVisibleModal(true);
    setVisibleDropdownMenu(true);

    setTimeout(() => {
      setVisibleDropdownMenu(false);
    }, [10]);
  }, []);

  const getSelectedKeys = useCallback(() => {
    if (isMulti) {
      return (value || []).map(item => item.value.toString());
    }

    return value ? [value.value.toString()] : [];
  }, [value, isMulti]);

  const onSelect = selectedValue =>
    props.onChange(
      isMulti
        ? selectedValue.map(item => ({
            value: item.value,
            label: item.label.title
          }))
        : { value: selectedValue.value, label: selectedValue.label.title }
    );

  const fetchData = ({ search }) =>
    dispatch(fetchCategoriesLocal({ search })).then(data => ({
      entries: data.map(mapValue)
    }));

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        fetchData={fetchData}
        closeMenuOnSelect={!isMulti}
        value={value}
        isMulti={isMulti}
        visibleDropdownMenu={visibleDropdownMenu}
        renderContentTop={() => (
          <Button
            type="link"
            style={{
              textAlign: 'left',
              minHeight: 40,
              paddingLeft: 10,
              marginTop: -10
            }}
            onClick={onClickCategories}
          >
            {t('ExpandAllCategories')}
          </Button>
        )}
        {...props}
      />

      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        callbackAfterClose={() => setVisibleDropdownMenu(true)}
        onSelect={onSelect}
        defaultSelectedKeys={getSelectedKeys()}
        centered
        isMulti={isMulti}
      />
    </>
  );
};

export default HandbookSelect;
