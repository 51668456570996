import React from 'react';
import { Translation } from 'react-i18next';

import isValidPhoneNumber from 'components/common/controls/phone-input/helpers';

export const numberOrLatinPattern = {
  value: new RegExp(/^([aA-zZ0-9])+$/),
  message: (
    <Translation ns="Errors">{t => t('NumberOrLatinPattern')}</Translation>
  )
};

export const validateCompany = value => {
  if (!value.company) {
    return (
      <Translation ns="Errors">{t => t('ValidateCompanyEmpty')}</Translation>
    );
  }

  if (!value.company.exist === false) {
    return '';
  }

  return true;
};

export const validatePhone = (value, options = {}, message) => {
  if (!isValidPhoneNumber(options.isOnlyNumber ? value : value.number)) {
    return (
      message || (
        <Translation ns="Errors">
          {t => t('EnterAValidPhone', { value })}
        </Translation>
      )
    );
  }

  return true;
};

export const validateMinLength = value => ({
  value,
  message: (
    <Translation ns="Errors">
      {t => t('ValidateMinLength', { value })}
    </Translation>
  )
});

export const validateMaxLength = value => ({
  value,
  message: (
    <Translation ns="Errors">
      {t => t('ValidateMaxLength', { value })}
    </Translation>
  )
});

export const validateRequired = () => (
  <Translation ns="Errors">{t => t('RequiredField')}</Translation>
);
