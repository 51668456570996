import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PERIOD_YEAR } from 'constants/billing';

import Typography from 'components/common/typography';
import { getPaymentCardText } from 'components/settings-view/billing/payment-card/utls';

const WhatsAppAccountAddonDescription = ({ account, checkout }) => {
  const { t } = useTranslation('ChangePlan');

  const { nextPaymentDate, primaryCard = {} } = account;
  const { payNow, period, months, paymentDate } = checkout;

  const cardText = getPaymentCardText(primaryCard.brand, primaryCard.number);

  if (payNow && period === PERIOD_YEAR) {
    return (
      <Typography.Text
        size="small"
        color="black-55"
        style={{ whiteSpace: 'pre-line' }}
      >
        {t('WhatsAppIntegrationDescAnnual', {
          paymentDate: nextPaymentDate,
          months,
          card: cardText
        })}
      </Typography.Text>
    );
  }

  if (payNow) {
    return (
      <Typography.Text size="small" color="black-55">
        {t('PaymentDetailsDesc', {
          card: cardText,
          paymentDate
        })}
      </Typography.Text>
    );
  }

  if (!payNow) {
    return (
      <Typography.Text size="small" color="black-55">
        {t('WhatsAppIntegrationDesc', {
          paymentDate: nextPaymentDate,
          card: cardText
        })}
      </Typography.Text>
    );
  }

  return null;
};

WhatsAppAccountAddonDescription.propTypes = {
  account: PropTypes.shape({
    nextPaymentDate: PropTypes.string,
    primaryCard: PropTypes.object
  }).isRequired,
  checkout: PropTypes.shape({
    payNow: PropTypes.bool
  }).isRequired
};

export default WhatsAppAccountAddonDescription;
