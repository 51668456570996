export const hexEmojiByEmojiPostfix = {
  '263a-fe0f': '263a',
  '2764-fe0f': '2764',
  '261d-fe0f': '261d'
};

const emojiPostfixByHex = Object.keys(hexEmojiByEmojiPostfix).reduce(
  (acc, curr) => {
    acc[hexEmojiByEmojiPostfix[curr]] = curr;

    return acc;
  },
  {}
);

export const getEmojiWithPostfixByHex = code => emojiPostfixByHex[code] || code;
