import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { TOKEN_FILES } from 'constants/api';

import { getAllWorkspaces, setActiveId, getWorkspaceId } from 'store/workspace';
import {
  getRouterIsFirstRendering,
  getRouterUrlView,
  getRouterUrlSubmenu
} from 'store/router';

import useRoutesService from 'services/routes';
import { useLocalStorage, usePrevious } from 'hooks';

const UrlProvider = ({ children }) => {
  const [, setTokenFiles] = useLocalStorage(TOKEN_FILES);
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const { pathname, state, search } = useLocation();
  const prevSearch = usePrevious(search);
  const prevPathname = usePrevious(pathname);
  const initialPathnameRef = useRef(pathname);
  const initialSearchRef = useRef(search);

  const setActiveWorkspaceId = workspaceId => {
    dispatch(setActiveId({ workspaceId }));
    setTokenFiles(null);
  };

  const goTo = newPathname => dispatch(push(newPathname));

  const workspaces = useSelector(getAllWorkspaces);
  const activeWorkspaceId = useSelector(getWorkspaceId);

  const { workspace } = useParams();
  const urlView = useSelector(getRouterUrlView);
  const urlSubmenu = useSelector(getRouterUrlSubmenu);
  const firstRendering = useSelector(getRouterIsFirstRendering);

  // Check firstRendering (setWorkspace by URL)
  useEffect(() => {
    if (firstRendering || (state && state.forceUpdate)) {
      if (+workspace !== activeWorkspaceId) {
        if (workspaces[+workspace]) {
          setActiveWorkspaceId(+workspace);
        } else {
          const [, wid] = initialPathnameRef.current.split('/');
          const isWithoutWid = Number.isNaN(Number(wid));

          if (isWithoutWid) {
            const { id } = Object.values(workspaces).reduce((acc, curr) =>
              acc.isIndividual && !curr.isIndividual ? curr : acc
            );
            setActiveWorkspaceId(id);
            // TODO: сделан replace, потому что иногда сетается undefined вместо wId
            // и пока не могу понять, почему и как, поэтому просто удаляем его из урла
            goTo(
              `/${id}${initialPathnameRef.current.replace('/undefined', '')}${
                initialSearchRef.current
              }`
            );
          } else {
            routes.toDefaultPage();
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRendering, state]);

  // Check activeWorkspaceId
  useEffect(() => {
    if (+workspace !== activeWorkspaceId && !firstRendering) {
      // TODO: открывать создание компании через сервис
      const transfromSubmenu = () => {
        if (!urlSubmenu) {
          return '';
        }

        if (urlSubmenu.includes('workspace-profile/workspace/')) {
          const splittedSubmenu = urlSubmenu.split('/');

          const newSubmenu = splittedSubmenu.reduce((acc, curr) => {
            if (!Number.isNaN(+curr)) {
              acc.push(`${activeWorkspaceId}`);
              return acc;
            }

            acc.push(curr);
            return acc;
          }, []);

          return `/${newSubmenu.join('/')}`;
        }

        return `/${urlSubmenu}`;
      };

      const filter = transfromSubmenu();
      const activePath = `/${activeWorkspaceId}`;
      const viewPath = `/${urlView}${filter}`;

      // if (workspaces[workspace]) {
      goTo(`${activePath}${viewPath}${search}`);
      // }

      setTokenFiles(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspaceId]);

  useEffect(() => {
    const changedPathname =
      prevPathname !== undefined && prevPathname !== pathname;
    const changedSearch = prevSearch !== undefined && prevSearch !== search;

    if (changedPathname || changedSearch) {
      notification.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, prevSearch]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default UrlProvider;
