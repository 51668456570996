import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import { LogoIcon } from 'components/common/icons';
import Button from 'components/common/button';

import useModalsService from 'services/modals';

import styles from './view-info.module.scss';

const UserProfile = () => {
  const { t } = useTranslation('Menu');

  const modals = useModalsService();

  return (
    <div className={styles.userProfile}>
      <Icon component={LogoIcon} />

      <Button
        type="primary"
        className={styles.button}
        onClick={() => modals.workspace.create()}
      >
        <Icon type="plus" />
        {t('AddCompanyBtn')}
      </Button>
    </div>
  );
};

export default UserProfile;
