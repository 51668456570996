import qs from 'qs';

export default api => ({
  fetch: ({ workspaceId, ...params }) =>
    api
      .get(`/v1/${workspaceId}/channels-chats/`, {
        params,
        paramsSerializer(_params) {
          return qs.stringify(_params, { arrayFormat: 'comma' });
        }
      })
      .then(({ data }) => data),

  fetchOne: ({ workspaceId, id, ...params }) =>
    api
      .get(`/v1/${workspaceId}/channels-chats/${id}/`, { params })
      .then(({ data }) => data),

  fetchOneByUuid: ({ workspaceId, uuid, ...params }) =>
    api.get(`/v1/${workspaceId}/channels-chats/uuid/${uuid}/`, {
      params,
      show400: false
    }),

  edit: ({ workspaceId, id, ...dialog }) =>
    api
      .patch(`/v1/${workspaceId}/channels-chats/${id}/`, dialog)
      .then(({ data }) => data),

  archive: ({ workspaceId, id }) =>
    api
      .put(`/v1/${workspaceId}/channels-chats/${id}/archive/`)
      .then(({ data }) => data),

  restore: ({ workspaceId, id }) =>
    api
      .put(`/v1/${workspaceId}/channels-chats/${id}/restore/`)
      .then(({ data }) => data),

  // RELATIONS
  changeRelations: ({ workspaceId, id, relations }) =>
    api.post(`/v1/${workspaceId}/channels-chats/${id}/relations/bulk_update/`, {
      relations
    }),

  fetchRelations: (workspaceId, id, offset = 0, limit = 1000) =>
    api.get(`/v1/${workspaceId}/channels-chats/${id}/relations/`, {
      params: {
        limit,
        offset
      }
    })
});
