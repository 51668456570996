import moment from 'moment';

import {
  TIME_FORMAT,
  SHORT_DAY_FORMAT,
  SHORT_DATE_FORMAT,
  DIALOG_TYPE_LOG,
  DIALOG_TYPE_COMMENT,
  UPSERVICE,
  ONLINE_CHAT,
  WEBSITE_FORM,
  WIDGET,
  WEBSITE,
  NOTIFICATION
} from 'constants/index';

export const PHONE = 'phone';
export const EMAIL = 'email';

export const transformCreatedAt = createdAt => {
  const nowDate = new Date();

  if (moment(nowDate).isSame(createdAt, 'day')) {
    return moment(createdAt).format(TIME_FORMAT);
  }

  if (moment(nowDate).isSame(createdAt, 'week')) {
    return moment(createdAt).format(SHORT_DAY_FORMAT);
  }

  return moment(createdAt).format(SHORT_DATE_FORMAT);
};

export const getLastMessage = messages => {
  if (!messages || !messages.length) {
    return null;
  }

  return messages[messages.length - 1];
};

export const getIsOnline = chat => {
  if (!chat) {
    return false;
  }

  return chat.isOnline;
};

const getIsFromUpservice = isFrom => isFrom === UPSERVICE;

export const getIsFromOnlineChat = isFrom => isFrom === ONLINE_CHAT;
export const getIsFromWidget = isFrom => isFrom === WIDGET;
export const getIsFromWebsiteFrom = isFrom => isFrom === WEBSITE_FORM;

export const getIsMyMessage = (payload, employeeId) => +payload === employeeId;

export const getIsComment = kind => kind === DIALOG_TYPE_COMMENT;

export const getIsLogMessage = kind => kind === DIALOG_TYPE_LOG;

export const getIsNotificationMessage = kind => kind === NOTIFICATION;

export const getIsContactMessage = (kind, isFrom, payload, employeeId) =>
  !getIsMyMessage(payload, employeeId) &&
  !getIsComment(kind) &&
  !getIsFromUpservice(isFrom);

export const getIsHelloMessage = (prev, curr, employeeId) =>
  (!prev || prev.roomUuid !== curr.roomUuid) &&
  getIsFromUpservice(curr.isFrom) &&
  !getIsMyMessage(curr.payload, employeeId) &&
  !curr.payload;

export const getIsFirstGroupMessage = (prev, curr) =>
  (!prev ||
    getIsLogMessage(prev.kind) ||
    getIsComment(prev.kind) ||
    getIsFromOnlineChat(prev.isFrom) ||
    prev.roomUuid !== curr.roomUuid) &&
  !getIsLogMessage(curr.kind) &&
  !getIsComment(curr.kind) &&
  !getIsFromOnlineChat(curr.isFrom);

export const getIsGroupMessage = (prev, curr) => {
  if (!prev) {
    return false;
  }

  return (
    !getIsLogMessage(prev.kind) &&
    !getIsComment(prev.kind) &&
    !getIsFromOnlineChat(prev.isFrom) &&
    prev.roomUuid === curr.roomUuid &&
    !getIsLogMessage(curr.kind) &&
    !getIsComment(curr.kind) &&
    !getIsFromOnlineChat(curr.isFrom)
  );
};

export const getIsLastGroupMessage = (curr, next) =>
  (!next ||
    getIsLogMessage(next.kind) ||
    getIsComment(next.kind) ||
    getIsFromOnlineChat(next.isFrom) ||
    next.roomUuid !== curr.roomUuid) &&
  !getIsLogMessage(curr.kind) &&
  !getIsComment(curr.kind) &&
  !getIsFromOnlineChat(curr.isFrom);

export const getChannelKindByMessageIsFrom = isFrom => {
  if (isFrom === WIDGET) {
    return WEBSITE;
  }

  return isFrom;
};

export const getIsFromOtherWorkspaceMessage = ({ message, chats }) =>
  getIsFromOnlineChat(message.isFrom) &&
  !chats.find(c => c.channelUuid === message.channelId);
