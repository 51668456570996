import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import PaymentDetails from 'components/settings-view/billing/payment-details';
import Button from 'components/common/button';
import { getPaymentCardText } from 'components/settings-view/billing/payment-card/utls';

import styles from './checkout-payment.module.scss';

const CheckoutPaymentStep = ({
  currentTariff,
  selectedData,
  onGoBack,
  onSubmit
}) => {
  const [checkout, setCheckout] = useState({});

  const { t } = useTranslation('ChangePlan');

  const tariffDescription = useMemo(
    () => (
      <>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {t(checkout.isRise ? 'PlanDesc' : 'PlanDescChanged', {
            date: checkout.paymentDate,
            tariffTitle: currentTariff.title
          })}
        </Typography.Title>

        {checkout.card && (
          <Typography.Text size="small" color="black-55">
            {t('PaymentDetailsDesc', {
              card: getPaymentCardText(
                checkout.card.brand,
                checkout.card.number
              ),
              paymentDate: checkout.paymentDate
            })}
          </Typography.Text>
        )}
      </>
    ),
    [
      checkout.card,
      checkout.isRise,
      checkout.paymentDate,
      currentTariff.title,
      t
    ]
  );

  const totalDescription = useMemo(() => {
    if (currentTariff.isDefault) {
      return null;
    }

    return (
      <Typography.Text size="small" color="black-55">
        {t('TotalDesc')}
      </Typography.Text>
    );
  }, [currentTariff.isDefault, t]);

  return (
    <div className={styles.root}>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {t('CheckoutHeading')}
      </Typography.Title>

      <PaymentDetails
        {...selectedData}
        tariffDescription={tariffDescription}
        totalDescription={totalDescription}
        callback={setCheckout}
      />

      <div className={styles.buttons}>
        <Button type="secondary" className={styles.button} onClick={onGoBack}>
          {t('BackBtn')}
        </Button>

        <Button type="primary" className={styles.button} onClick={onSubmit}>
          {t(checkout.isRise ? 'PayBtn' : 'NextBtn')}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutPaymentStep;
