// import { LOCATION_CHANGE } from 'connected-react-router';
import { combineActions } from 'redux-actions';

// import { CALENDAR } from 'constants/index';

// import { makeSubscribeLocationChange } from 'store/router';

import { CALENDAR, CALENDAR_VIEW_TIME_GRID_WEEK } from 'constants/index';

import handleActions from 'utils/redux-actions';
import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';

import {
  clearAssetsFilter,
  setAssetsFilterSearch,
  setAssetsFilterTaskResponsible,
  setAssetsFilterOrderResponsible,
  setFilterAsset,
  fetchAssetRecords,
  fetchAssetResources,
  setAssetsFilterCategories,
  setEmployeeCalendarFilterEmployee,
  setEmployeeCalendarFilterView,
  fetchEmployeeRecords,
  fetchOneEmployeeRecord,
  clearEmployeeCalendarFilter
} from './actions';

export const initialAssetsFilter = {
  search: '',
  asset: [],
  taskResponsible: [],
  orderResponsible: [],
  category: []
};

export const initialEmployeeFilter = {
  employee: [],
  view: CALENDAR_VIEW_TIME_GRID_WEEK
};

const initialState = {
  byAssets: {
    filter: initialAssetsFilter,

    resources: [],
    record: {
      totalItems: 0,
      entries: []
    }
  },

  byEmployees: {
    filter: {
      ...initialEmployeeFilter,
      ...getInitialValueFilterStorage(CALENDAR, initialEmployeeFilter)
    },

    records: []
  }
};

export default handleActions(
  {
    // [LOCATION_CHANGE]: makeSubscribeLocationChange(
    //   CALENDAR,
    //   null,
    //   'byAssets',
    //   initialAssetsFilter
    // ),

    [fetchAssetResources.SUCCEEDED]: (state, { payload }) => {
      state.byAssets.resources = payload.results;

      return state;
    },

    [fetchAssetRecords.SUCCEEDED]: (state, { payload }) => {
      state.byAssets.record = {
        totalItems: payload.count,
        entries: payload.results
      };

      return state;
    },

    [combineActions(
      clearAssetsFilter,
      setAssetsFilterSearch,
      setAssetsFilterTaskResponsible,
      setAssetsFilterOrderResponsible,
      setFilterAsset
    )]: state => {
      state.byAssets.resources = [];
      state.byAssets.record = {
        totalItems: 0,
        entries: []
      };

      return state;
    },

    [clearAssetsFilter]: state => {
      state.byAssets.filter = { ...initialAssetsFilter };

      return state;
    },

    [setAssetsFilterSearch]: (state, { payload }) => {
      state.byAssets.filter.search = payload;

      return state;
    },

    [setAssetsFilterTaskResponsible]: (state, { payload }) => {
      state.byAssets.filter.taskResponsible = payload;

      return state;
    },

    [setAssetsFilterOrderResponsible]: (state, { payload }) => {
      state.byAssets.filter.orderResponsible = payload;

      return state;
    },

    [setAssetsFilterCategories]: (state, { payload }) => {
      state.byAssets.filter.category = payload;

      return state;
    },

    [setFilterAsset]: (state, { payload }) => {
      state.byAssets.filter.asset = payload;

      return state;
    },

    [fetchOneEmployeeRecord.SUCCEEDED]: (state, { payload }) => {
      const index = state.byEmployees.records.findIndex(
        record => record.id === payload.id
      );

      if (index !== -1) {
        state.byEmployees.records[index] = payload;
      } else {
        state.byEmployees.records.push(payload);
      }

      return state;
    },

    [fetchEmployeeRecords.SUCCEEDED]: (state, { payload, args }) => {
      if (args.setDataToStore) {
        state.byEmployees.records = payload.results;
      }

      return state;
    },

    [combineActions(setEmployeeCalendarFilterEmployee)]: state => {
      state.byEmployees.records = [];

      return state;
    },

    [setEmployeeCalendarFilterEmployee]: (state, { payload }) => {
      state.byEmployees.filter.employee = payload;

      return state;
    },

    [setEmployeeCalendarFilterView]: (state, { payload }) => {
      state.byEmployees.filter.view = payload;

      return state;
    },

    [clearEmployeeCalendarFilter]: state => {
      state.byEmployees.filter = initialEmployeeFilter;

      return state;
    }
  },
  initialState
);
