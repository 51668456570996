// TODO: replace all apis
export const SEARCH = 'search';
export const WORKSPACE_CONTACTS = 'workspace-contacts';
export const CONTACTS = 'contacts';
export const CONTACTS_COMPANIES = 'contacts-companies';
export const CALENDAR = 'calendar';
export const CHAT_MESSAGES = 'chat-messages';
export const CITIES = 'cities';
export const COUNTRIES = 'countries';
export const ACTIONS = 'actions';
export const NOTIFICATIONS = 'notifications';
export const TAGS = 'tags';
export const AGREEMENT_SHEET = 'agreement-sheet';
export const DISCUSSIONS = 'discussions';

export const TOKEN_FILES = 'token-files';
