import ReactDOM from 'react-dom';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { WITH_BOTTOM_BANNER_ROOT_CLASSNAME } from 'constants/styles';

import styles from './styles.module.scss';

const rootNode = document.getElementById('root');

const insertAfter = (referenceNode, newNode) =>
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);

export const BottomBanner = ({ children, visible, className }) => {
  const portalRef = useRef(null);

  useEffect(() => {
    portalRef.current = document.createElement('div');

    insertAfter(rootNode, portalRef.current);

    return () => {
      portalRef.current.remove();
    };
  }, []);

  useEffect(() => {
    if (visible) {
      rootNode.classList.add(WITH_BOTTOM_BANNER_ROOT_CLASSNAME);
    } else {
      rootNode.classList.remove(WITH_BOTTOM_BANNER_ROOT_CLASSNAME);
    }

    return () => {
      rootNode.classList.remove(WITH_BOTTOM_BANNER_ROOT_CLASSNAME);
    };
  }, [visible]);

  if (!portalRef.current || !visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={classnames(styles.root, className)}>{children}</div>,
    portalRef.current
  );
};

BottomBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  visible: PropTypes.bool
};

BottomBanner.defaultProps = {
  className: '',
  visible: false
};

export default BottomBanner;
