import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';

import styles from './gpt-prompts.module.scss';

const { Text } = Typography;

const GptPrompts = ({ entityInfo, sendPrompt, prompts }) => {
  const { t } = useTranslation('AIAssistant');

  return (
    <div className={styles.root}>
      <Text weight="semibold">{t('HowCanIHelpHeading')}</Text>

      {prompts.map(prompt => (
        <div
          key={prompt.id}
          className={styles.prompt}
          onClick={() => sendPrompt({ promptData: { prompt, ...entityInfo } })}
        >
          {prompt.text}
        </div>
      ))}
    </div>
  );
};

GptPrompts.propTypes = {
  entityInfo: PropTypes.shape({
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.string
  }).isRequired,
  sendPrompt: PropTypes.func.isRequired,
  prompts: PropTypes.array
};

GptPrompts.defaultProps = {
  prompts: []
};

export default GptPrompts;
