import {
  setFilterSearch,
  createIdea,
  createMember,
  deleteMember,
  fetchIdeas,
  publishIdea,
  createRelation,
  deleteRelation,
  editIdea,
  convertIdeaToProject,
  convertIdeaToTask
} from './actions';

import handleActions from '../../../utils/redux-actions';
import { setActiveId } from '../../workspace';

const initialFilter = {
  search: ''
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  hasMore: true,
  filter: initialFilter,
  totalItems: 0,
  itemsPerPage: 50,

  entries: []
};

export default handleActions(
  {
    [setActiveId]: () => initialState,

    [fetchIdeas.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;

      return state;
    },

    [fetchIdeas.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchIdeas.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchIdeas.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.isLoaded = true;

      state.totalItems = payload.totalItems;
      state.hasMore = payload.totalItems > state.entries.length;
      state.entries = [...state.entries, ...payload.entries];

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;
      state.entries = [];

      return state;
    },

    [publishIdea.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(idea => idea.id !== payload.id);
      state.totalItems -= 1;

      return state;
    },

    [convertIdeaToProject.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(idea => idea.id !== payload.ideaId);
      state.totalItems -= 1;

      return state;
    },

    [convertIdeaToTask.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(idea => idea.id !== payload.ideaId);
      state.totalItems -= 1;

      return state;
    },

    [createIdea.SUCCEEDED]: (state, { payload }) => {
      state.entries = [payload, ...state.entries];

      return state;
    },

    [editIdea.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      state.entries[index] = payload;

      return state;
    },

    [createMember.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (!state.entries[index]) {
        return state;
      }

      state.entries[index].assignments = [
        ...state.entries[index].assignments,
        payload.data
      ];

      return state;
    },

    [deleteMember.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (!state.entries[index]) {
        return state;
      }

      state.entries[index].assignments = state.entries[
        index
      ].assignments.filter(item => item.id !== payload.memberId);

      return state;
    },

    [createRelation.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (!state.entries[index]) {
        return state;
      }

      state.entries[index].relations = [
        ...(state.entries[index].relations || []),
        { ...payload.data }
      ];

      return state;
    },

    [deleteRelation.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (!state.entries[index]) {
        return state;
      }

      state.entries[index].relations = state.entries[index].relations.filter(
        item => item.id !== payload.relationId
      );

      return state;
    }
  },
  initialState
);
