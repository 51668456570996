import React from 'react';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import { BoundaryIcon } from 'components/common/icons';

import getSupportLink from 'utils/get-support-link';

export const SpareUI = () => {
  const { t } = useTranslation('ScreenErrors');

  const supportLink = getSupportLink();

  return (
    <div
      style={{
        width: 510,
        padding: 10,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Icon component={BoundaryIcon} style={{ marginBottom: 16 }} />

      <Typography.Title
        level={1}
        weight="bold"
        style={{ marginBottom: 16, textAlign: 'center' }}
      >
        {t('OopsSmthWentWrongHeading')}
      </Typography.Title>

      <Typography.Title
        level={3}
        color="black-35"
        weight="regular"
        style={{ margin: 0, textAlign: 'center' }}
      >
        <p style={{ marginBottom: 16 }}>{t('OopsSmthWentWrongDesc')}</p>

        <p>
          {t('SupportDesc')}&nbsp;
          <a href={supportLink.href} target={supportLink.target}>
            {t('SupportBtn')}
          </a>
        </p>
      </Typography.Title>
    </div>
  );
};

export default SpareUI;
