import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CLICK_ADD_FILE_EVENT } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import AddFileDrawer from 'components/attachments-view/drawers/add-file';

import useAmplitude from 'hooks/amplitude/use-amplitude';

import styles from './view-info.module.scss';

const Attachments = () => {
  const [visiblreAddDrawer, setVisibleAddDrawer] = useState(false);

  const { t } = useTranslation('UploadFile');

  const amplitude = useAmplitude();

  const addFile = () => {
    setVisibleAddDrawer(true);
    amplitude.logEvent({
      event: CLICK_ADD_FILE_EVENT,
      params: { module_system: 'drive' }
    });
  };

  return (
    <>
      <div className={styles.attachments}>
        <Typography.Title level={2} className={styles.title}>
          {t('MyDriveAction')}
        </Typography.Title>

        <Button type="primary" className={styles.button} onClick={addFile}>
          <Icon type="plus" />
          {t('AddBtn')}
        </Button>
      </div>

      <AddFileDrawer
        visible={visiblreAddDrawer}
        onClose={() => setVisibleAddDrawer(false)}
      />
    </>
  );
};

export default Attachments;
