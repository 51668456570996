import React from 'react';
import { Popover, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, TYPE_TASK } from 'constants/index';

import Button from 'components/common/button';
import Icon from 'components/common/icon';

import useReminder from './use-reminder';
import FormReminder from './form';
import Typography from '../typography';

import styles from './reminder.module.scss';

export const Reminder = ({
  entityType,
  entityId,
  textReminder,
  deadlineAt,
  isOutdated
}) => {
  const { t } = useTranslation('Task');

  const {
    reminder,
    visiblePopover,
    setVisiblePopover,
    isLoadingFetch,
    isLoadingSubmit,
    onSubmit,
    onDelete,
    openTaskModal
  } = useReminder({
    entityType,
    entityId,
    textReminder
  });

  if (isLoadingFetch) {
    return <Spin size="small" />;
  }

  return (
    <>
      <div className={styles.reminderContainer}>
        {reminder && entityType === TYPE_TASK && (
          <>
            <Icon color="black-55" type="bell" size={16} />

            <Tooltip title={reminder.comment}>
              <Typography.Text ellipsis>
                {moment(reminder.remindDate).format(DATE_TIME_FORMAT)}.{' '}
                {reminder.comment}
              </Typography.Text>
            </Tooltip>
          </>
        )}

        <Popover
          trigger="click"
          visible={visiblePopover}
          onVisibleChange={setVisiblePopover}
          placement="bottom"
          destroyTooltipOnHide
          overlayClassName={styles.popover}
          content={
            <FormReminder
              deadlineAt={deadlineAt}
              defaultValues={reminder}
              isLoading={isLoadingSubmit}
              onSubmit={onSubmit}
              onDelete={onDelete}
              setVisiblePopover={setVisiblePopover}
            />
          }
        >
          {reminder && entityType === TYPE_TASK && (
            <Button type="text" size="small" className={styles.reminderBtn}>
              {t('EditReminderBtn')}
            </Button>
          )}

          {!reminder && isOutdated && (
            <div className={styles.reminderContainer}>
              <Icon color="brand" type="bell" size={16} />

              <Button type="text" size="small" className={styles.reminderBtn}>
                {t('SetReminderBtn')}
              </Button>
            </div>
          )}
        </Popover>
      </div>

      {reminder && entityType !== TYPE_TASK && (
        <Tooltip title={reminder.comment} mouseEnterDelay={0.5}>
          <div className={styles.reminderContainer}>
            <Icon color="brand" type="bell" size={16} />

            <Typography.Text
              className={styles.text}
              onClick={openTaskModal}
              color="brand"
              ellipsis
            >
              ID {reminder.taskId} {reminder.comment}
            </Typography.Text>
          </div>
        </Tooltip>
      )}
    </>
  );
};

Reminder.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  textReminder: PropTypes.string,
  deadlineAt: PropTypes.string,
  isOutdated: PropTypes.bool
};

Reminder.defaultProps = {
  textReminder: '',
  deadlineAt: '',
  isOutdated: true
};

export default Reminder;
