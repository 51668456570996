const DOCUMENT = 'document';
const SHEET = 'sheet';
const PRESENTATION = 'presentation';
const PDF_FILE = 'pdf_file';
const MEDIA = 'media';
const OTHER = 'other';

export const FILE_TYPE_FILTER_OPTIONS = [
  {
    value: DOCUMENT,
    label: 'Documents',
    ns: 'MyDrive'
  },
  {
    value: SHEET,
    label: 'Tables',
    ns: 'MyDrive'
  },
  {
    value: PRESENTATION,
    label: 'Presentations',
    ns: 'MyDrive'
  },
  {
    value: PDF_FILE,
    label: 'PDFFiles',
    ns: 'MyDrive'
  },
  {
    value: MEDIA,
    label: 'Media',
    ns: 'MyDrive'
  },
  {
    value: OTHER,
    label: 'OtherFileTypes',
    ns: 'MyDrive'
  }
];

export const DONE = 'done';
