import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from 'components/common/modal';

import sanitizeHTML from 'utils/sanitize-html';

import styles from './full-email-modal.module.scss';

const FullEmailModal = ({
  nodeContent,
  htmlContent,
  subject,
  visible,
  onClose
}) => (
  <Modal
    title={subject}
    contentClassName={classnames(styles.root, {
      [styles.withoutSubject]: !subject
    })}
    wrapClassName={styles.wrap}
    centered
    visible={visible}
    onClose={onClose}
  >
    {htmlContent ? (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(htmlContent, { FORBID_TAGS: [] })
        }}
      />
    ) : (
      nodeContent
    )}
  </Modal>
);

FullEmailModal.propTypes = {
  nodeContent: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    .isRequired,
  htmlContent: PropTypes.string,
  subject: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

FullEmailModal.defaultProps = {
  htmlContent: null,
  subject: null,
  visible: false,
  onClose: () => {}
};

export default FullEmailModal;
