import { useRef, useEffect, useState } from 'react';

const ADDITIONAL_BOTTOM_OFFSET = 20;

export const useScrollBottom = ({
  defaultState = true,
  dependencies = []
} = {}) => {
  const [isBottom, setIsBottom] = useState(defaultState);
  const scrollRef = useRef(null);

  const checkIsBottom = ref =>
    ref.current.scrollTop + ADDITIONAL_BOTTOM_OFFSET >=
    ref.current.scrollHeight - ref.current.clientHeight;

  const onScroll = () => {
    if (scrollRef.current) {
      setIsBottom(checkIsBottom(scrollRef));
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      const maxScrollTop = scrollHeight - clientHeight;

      scrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(
    () => {
      if (scrollRef.current) {
        scrollRef.current.addEventListener('scroll', onScroll);
      }

      return () => {
        if (scrollRef && scrollRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          scrollRef.current.removeEventListener('scroll', onScroll);
        }
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollRef.current]
  );

  useEffect(() => {
    if (scrollRef.current) {
      setIsBottom(checkIsBottom(scrollRef));
    }

    if (isBottom) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [scrollRef, isBottom, scrollToBottom];
};

export default useScrollBottom;
