import React from 'react';

export const WebStubIcon = () => (
  <svg
    width="241"
    height="225"
    viewBox="0 0 241 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120.5 224.305C186.781 224.305 240.513 221.168 240.513 217.299C240.513 213.429 186.781 210.292 120.5 210.292C54.2187 210.292 0.487061 213.429 0.487061 217.299C0.487061 221.168 54.2187 224.305 120.5 224.305Z"
      fill="#F5F5F5"
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      d="M203.319 129.627H69.5584C68.1162 129.627 67.002 128.445 67.0825 127.003L71.5886 45.0009C71.6691 43.5587 72.9132 42.3765 74.3554 42.3765H208.11C209.552 42.3765 210.666 43.5587 210.586 45.0009L206.079 127.003C206.005 128.445 204.761 129.627 203.319 129.627Z"
      fill="#263238"
    />
    <path
      d="M147.048 56.322H134.117C129.803 56.322 126.473 52.8 126.708 48.4919L126.838 46.1027C127.067 41.9308 130.67 38.5203 134.848 38.5203H147.772C152.086 38.5203 155.422 42.0484 155.187 46.3565L155.057 48.7395C154.834 52.9114 151.226 56.322 147.048 56.322Z"
      fill="#263238"
    />
    <path
      d="M166.905 214.317L162.782 214.703L135.187 42.1514L139.31 41.7658L166.905 214.317Z"
      fill="#263238"
    />
    <path
      d="M114.553 213.31L118.674 213.772L149.419 41.7538L145.303 41.2928L114.553 213.31Z"
      fill="#263238"
    />
    <path
      d="M202.607 129.627H68.8465C67.4042 129.627 66.2901 128.445 66.3706 127.003L70.8767 45.0009C70.9572 43.5587 72.2013 42.3765 73.6435 42.3765H207.404C208.846 42.3765 209.96 43.5587 209.88 45.0009L205.374 127.003C205.293 128.445 204.049 129.627 202.607 129.627Z"
      fill="#206E71"
    />
    <path
      d="M70.9696 127.114C69.8121 127.114 68.927 126.173 68.9889 125.022L73.2784 46.9879C73.3403 45.8304 74.3306 44.8958 75.4881 44.8958H205.275C206.432 44.8958 207.317 45.8366 207.255 46.9879L202.966 125.022C202.904 126.179 201.914 127.114 200.756 127.114H70.9696Z"
      fill="#36B7BD"
    />
    <path
      opacity="0.1"
      d="M201.561 85.7541H162.491C161.507 85.7541 160.758 84.9556 160.808 83.9777L162.757 48.4547C162.763 48.3185 162.658 48.2009 162.522 48.2009C162.386 48.2009 162.262 48.3123 162.256 48.4547L160.306 83.9777C160.25 84.9618 159.415 85.7541 158.431 85.7541H117.832C116.848 85.7541 116.099 84.9556 116.149 83.9777L118.098 48.4547C118.104 48.3185 117.999 48.2009 117.863 48.2009C117.727 48.2009 117.603 48.3123 117.597 48.4547L115.647 83.9777C115.591 84.9618 114.756 85.7541 113.772 85.7541H74.702C74.5658 85.7541 74.442 85.8655 74.4358 86.0079C74.4296 86.1441 74.5348 86.2617 74.671 86.2617H113.741C114.725 86.2617 115.474 87.0601 115.424 88.0381L113.475 123.561C113.468 123.697 113.574 123.815 113.71 123.815C113.846 123.815 113.97 123.703 113.976 123.561L115.926 88.0381C115.981 87.054 116.817 86.2617 117.801 86.2617H158.4C159.384 86.2617 160.133 87.0601 160.083 88.0381L158.134 123.561C158.127 123.697 158.233 123.815 158.369 123.815C158.505 123.815 158.629 123.703 158.635 123.561L160.585 88.0381C160.64 87.054 161.476 86.2617 162.46 86.2617H201.53C201.666 86.2617 201.79 86.1503 201.796 86.0079C201.808 85.8655 201.703 85.7541 201.561 85.7541Z"
      fill="white"
    />
    <path
      d="M137.862 54.6383H122.171L121.299 70.6017L136.965 71.0164L137.862 54.6383Z"
      fill="#34A2A8"
    />
    <path
      d="M136.166 70.3293C130.917 70.0756 125.668 69.8156 120.413 69.5618C121.286 64.1396 121.831 58.6988 122.054 53.2766C127.333 53.5304 132.613 53.7904 137.893 54.0441C137.646 59.4725 137.064 64.9071 136.166 70.3293Z"
      fill="#FDA402"
    />
    <path
      opacity="0.4"
      d="M156.784 65.3033L141.068 66.498L141.415 82.4118L157.137 81.6319L156.784 65.3033Z"
      fill="#308288"
    />
    <path
      d="M156.283 81.0129C151.009 81.1614 145.729 81.3038 140.456 81.4524C140.914 75.9992 141.044 70.5584 140.852 65.1548C146.163 65.0062 151.467 64.8577 156.772 64.7091C156.933 70.1128 156.766 75.5597 156.283 81.0129Z"
      fill="#DFE9FF"
    />
    <path
      d="M110.893 94.3271L81.9254 93.2191L79.3257 118.993L108.226 120.776L110.893 94.3271Z"
      fill="#34A2A8"
    />
    <path
      d="M106.783 119.606C97.1088 118.82 87.4342 118.04 77.7534 117.254C79.697 108.532 81.0402 99.774 81.7768 91.0093C91.5132 91.7954 101.244 92.5815 110.974 93.3676C110.188 102.132 108.783 110.891 106.783 119.606Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M81.0777 58.247C80.1802 58.247 79.4869 57.5104 79.5364 56.6129C79.5859 55.7153 80.3597 54.9788 81.2572 54.9788C82.1547 54.9788 82.8479 55.7153 82.7984 56.6129C82.7489 57.5104 81.9752 58.247 81.0777 58.247Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M106.672 58.247H86.574C85.6765 58.247 84.9833 57.5104 85.0328 56.6129C85.0823 55.7153 85.856 54.9788 86.7535 54.9788H106.852C107.749 54.9788 108.442 55.7153 108.393 56.6129C108.35 57.5104 107.57 58.247 106.672 58.247Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M80.6875 65.3651C79.79 65.3651 79.0968 64.6285 79.1463 63.731C79.1958 62.8335 79.9695 62.0969 80.867 62.0969C81.7646 62.0969 82.4578 62.8335 82.4083 63.731C82.3588 64.6347 81.5851 65.3651 80.6875 65.3651Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M109.841 65.3651H86.1841C85.2866 65.3651 84.5934 64.6285 84.6429 63.731C84.6924 62.8335 85.4661 62.0969 86.3636 62.0969H110.021C110.918 62.0969 111.612 62.8335 111.562 63.731C111.519 64.6347 110.739 65.3651 109.841 65.3651Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M80.2916 72.4896C79.394 72.4896 78.7008 71.753 78.7503 70.8555C78.7998 69.958 79.5735 69.2214 80.4711 69.2214C81.3686 69.2214 82.0618 69.958 82.0123 70.8555C81.969 71.753 81.1891 72.4896 80.2916 72.4896Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M97.3072 72.4896H85.7942C84.8967 72.4896 84.2035 71.753 84.253 70.8555C84.3025 69.958 85.0762 69.2214 85.9737 69.2214H97.4928C98.3904 69.2214 99.0836 69.958 99.0341 70.8555C98.9784 71.753 98.2047 72.4896 97.3072 72.4896Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M107.972 79.614H96.4529C95.5554 79.614 94.8622 78.8774 94.9117 77.9799C94.9612 77.0824 95.7349 76.3458 96.6324 76.3458H108.152C109.049 76.3458 109.742 77.0824 109.693 77.9799C109.643 78.8774 108.87 79.614 107.972 79.614Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M79.9014 79.614C79.0039 79.614 78.3106 78.8774 78.3602 77.9799C78.4097 77.0824 79.1834 76.3458 80.0809 76.3458C80.9784 76.3458 81.6717 77.0824 81.6221 77.9799C81.5726 78.8774 80.7989 79.614 79.9014 79.614Z"
        fill="#263238"
      />
      <path
        opacity="0.8"
        d="M90.715 79.614H85.398C84.5005 79.614 83.8072 78.8774 83.8567 77.9799C83.9063 77.0824 84.68 76.3458 85.5775 76.3458H90.8945C91.792 76.3458 92.4852 77.0824 92.4357 77.9799C92.3924 78.8774 91.6187 79.614 90.715 79.614Z"
        fill="#263238"
      />
    </g>
    <path
      d="M129.877 100.808H122.078L121.008 120.312H128.8L129.877 100.808Z"
      fill="#206E71"
    />
    <path
      d="M141.7 104.825H133.901L133.053 120.312H140.846L141.7 104.825Z"
      fill="#206E71"
    />
    <path
      d="M154.308 94.4384H146.515L145.098 120.311H152.891L154.308 94.4384Z"
      fill="#206E71"
    />
    <path
      d="M123.818 111.813C123.044 111.813 122.27 111.794 121.478 111.751C120.116 111.683 118.352 111.51 116.761 110.798C116.507 110.687 116.396 110.389 116.507 110.136C116.619 109.882 116.916 109.77 117.17 109.882C118.606 110.526 120.252 110.687 121.527 110.748C130.88 111.231 139.069 108.792 147.314 103.086C149.276 101.724 152.21 99.2045 154.017 96.5863C154.172 96.3573 154.488 96.3016 154.717 96.4563C154.946 96.611 155.001 96.9267 154.847 97.1557C152.971 99.8792 150.025 102.429 147.889 103.909C140.146 109.269 132.44 111.813 123.818 111.813Z"
      fill="white"
    />
    <path
      d="M156.004 100.436C155.943 100.436 155.874 100.424 155.813 100.399C155.559 100.294 155.435 99.9968 155.54 99.743C156.252 98.0223 156.401 96.0849 155.973 94.327C154.011 95.6454 151.925 96.7534 149.747 97.6323C149.487 97.7375 149.196 97.6137 149.09 97.3538C148.985 97.0938 149.109 96.8029 149.369 96.6977C151.69 95.763 153.912 94.556 155.973 93.1076C156.103 93.0148 156.271 92.99 156.419 93.0395C156.568 93.089 156.691 93.2066 156.741 93.3552C157.465 95.4969 157.366 97.9604 156.475 100.121C156.388 100.319 156.202 100.436 156.004 100.436Z"
      fill="white"
    />
    <path
      d="M197.049 81.2047H168.056C167.567 81.2047 167.109 81.0128 166.781 80.6661C166.446 80.3133 166.279 79.8553 166.31 79.3601L167.66 54.8487C167.715 53.846 168.576 53.0289 169.578 53.0289H198.571C199.06 53.0289 199.518 53.2208 199.846 53.5674C200.181 53.9203 200.348 54.3783 200.317 54.8735L198.967 79.3849C198.912 80.3938 198.051 81.2047 197.049 81.2047ZM169.572 53.5303C168.83 53.5303 168.192 54.1369 168.155 54.8735L166.805 79.3849C166.787 79.7377 166.904 80.0719 167.14 80.3195C167.375 80.5671 167.697 80.7033 168.05 80.7033H197.042C197.785 80.7033 198.423 80.0967 198.46 79.3601L199.809 54.8487C199.828 54.4959 199.71 54.1617 199.475 53.9141C199.24 53.6665 198.918 53.5303 198.565 53.5303H169.572Z"
      fill="white"
    />
    <path
      d="M199.791 60.0605H167.629C167.493 60.0605 167.375 59.9491 167.375 59.8068C167.375 59.6706 167.486 59.553 167.629 59.553H199.791C199.927 59.553 200.044 59.6644 200.044 59.8068C200.044 59.9491 199.933 60.0605 199.791 60.0605Z"
      fill="white"
    />
    <path
      d="M174.066 81.2046C174.06 81.2046 174.054 81.2046 174.054 81.2046C173.918 81.1984 173.806 81.0808 173.819 80.9384L174.982 59.788C174.988 59.6519 175.106 59.5404 175.248 59.5528C175.385 59.559 175.49 59.6766 175.484 59.819L174.32 80.9693C174.308 81.1055 174.196 81.2046 174.066 81.2046Z"
      fill="white"
    />
    <path
      d="M196.108 62.5735H178.647C178.51 62.5735 178.393 62.4621 178.393 62.3197C178.393 62.1835 178.504 62.0659 178.647 62.0659H196.108C196.244 62.0659 196.362 62.1773 196.362 62.3197C196.362 62.4621 196.25 62.5735 196.108 62.5735Z"
      fill="white"
    />
    <path
      d="M193.477 65.656H178.479C178.343 65.656 178.226 65.5446 178.226 65.4022C178.226 65.266 178.337 65.1484 178.479 65.1484H193.477C193.613 65.1484 193.731 65.2599 193.731 65.4022C193.731 65.5446 193.619 65.656 193.477 65.656Z"
      fill="white"
    />
    <path
      d="M187.034 68.8376H178.306C178.17 68.8376 178.052 68.7262 178.052 68.5839C178.052 68.4477 178.164 68.3301 178.306 68.3301H187.034C187.17 68.3301 187.288 68.4415 187.288 68.5839C187.288 68.7262 187.176 68.8376 187.034 68.8376Z"
      fill="white"
    />
    <path
      d="M188.618 75.0458H177.966C177.83 75.0458 177.712 74.9343 177.712 74.792C177.712 74.6558 177.823 74.5382 177.966 74.5382H188.624C188.761 74.5382 188.878 74.6496 188.878 74.792C188.872 74.9343 188.761 75.0458 188.618 75.0458Z"
      fill="white"
    />
    <path
      d="M186.52 78.2274H177.786C177.65 78.2274 177.532 78.116 177.532 77.9736C177.532 77.8375 177.644 77.7198 177.786 77.7198H186.52C186.656 77.7198 186.774 77.8313 186.774 77.9736C186.768 78.116 186.656 78.2274 186.52 78.2274Z"
      fill="white"
    />
    <path
      d="M191.633 78.2274H188.835C188.699 78.2274 188.581 78.116 188.581 77.9736C188.581 77.8375 188.692 77.7198 188.835 77.7198H191.633C191.769 77.7198 191.886 77.8313 191.886 77.9736C191.88 78.116 191.769 78.2274 191.633 78.2274Z"
      fill="white"
    />
    <path
      d="M194.486 71.92H178.139C178.003 71.92 177.885 71.8086 177.885 71.6663C177.885 71.5301 177.997 71.4125 178.139 71.4125H194.486C194.622 71.4125 194.74 71.5239 194.74 71.6663C194.734 71.8086 194.622 71.92 194.486 71.92Z"
      fill="white"
    />
    <path
      d="M179.24 116.87C175.452 116.87 172.351 115.75 170.42 113.67C168.365 111.448 167.511 107.951 168.08 104.076C168.427 101.705 169.207 99.7121 170.383 98.1585C171.565 96.5987 173.28 95.3174 175.477 94.3394C180.318 92.1854 185.925 92.2597 190.475 94.5437C192.511 95.565 193.786 96.8586 194.269 98.3875C194.35 98.6537 194.207 98.9322 193.941 99.0189C193.675 99.0993 193.396 98.957 193.31 98.6908C192.914 97.4343 191.806 96.3387 190.023 95.4474C185.74 93.2995 180.46 93.2314 175.886 95.2679C173.849 96.1716 172.265 97.3538 171.188 98.7775C170.111 100.195 169.399 102.027 169.077 104.231C168.551 107.802 169.312 110.996 171.163 112.995C173.738 115.775 177.99 115.973 180.299 115.843C184.595 115.607 188.284 114.103 190.698 111.621C193.52 108.712 194.313 104.367 192.536 101.507C192.189 100.956 191.453 100.009 190.37 99.8978C190.091 99.873 189.893 99.6255 189.918 99.3469C189.943 99.0684 190.19 98.8703 190.469 98.8951C191.589 99.0065 192.629 99.7431 193.39 100.975C195.402 104.212 194.554 109.09 191.416 112.321C188.828 114.988 184.898 116.592 180.348 116.845C179.977 116.858 179.606 116.87 179.24 116.87Z"
      fill="white"
    />
    <path
      d="M50.7539 64.4736L48.9713 67.5871L47.2196 70.7624C46.0683 72.8855 44.9665 75.0271 43.9638 77.175C43.4624 78.252 42.9858 79.329 42.5525 80.406L42.472 80.6041C42.4658 80.6227 42.4535 80.6351 42.4473 80.6474C42.4163 80.6784 42.4101 80.7093 42.3916 80.7341C42.3606 80.7898 42.3359 80.8393 42.3049 80.9136C42.2616 81.056 42.274 81.2912 42.3792 81.6068C42.5773 82.2506 43.1591 83.0862 43.8709 83.8661C44.5704 84.6522 45.4741 85.4507 46.2849 86.1563L44.8365 89.245C44.0999 89.0345 43.4872 88.8241 42.8372 88.5394C42.1935 88.267 41.5683 87.939 40.9494 87.5676C39.7176 86.8062 38.4858 85.8716 37.4583 84.3303C36.9632 83.5628 36.5361 82.6096 36.3689 81.5078C36.2947 80.9569 36.2637 80.3875 36.338 79.8242C36.3689 79.5457 36.4123 79.2609 36.4804 79.001L36.5794 78.5986L36.6537 78.3572C37.0498 77.0945 37.4645 75.838 37.9473 74.631C38.8882 72.1984 39.9466 69.8525 41.1041 67.5685C41.6797 66.4234 42.2801 65.2969 42.9115 64.1827C43.549 63.0624 44.1618 61.9792 44.8922 60.8464L50.7539 64.4736Z"
      fill="#FFC3BD"
    />
    <path
      d="M44.7376 85.8036L47.9872 84.9556L48.1729 90.2045C48.1729 90.2045 45.6289 90.582 44.0691 89.0284L44.7376 85.8036Z"
      fill="#FFC3BD"
    />
    <path
      d="M52.5984 84.0765L51.8618 88.6817L48.1728 90.2106L47.9871 84.9617L52.5984 84.0765Z"
      fill="#FFC3BD"
    />
    <path
      d="M66.0489 39.7271C66.1231 40.0861 66.3707 40.3399 66.6059 40.2904C66.8411 40.2409 66.9711 39.9128 66.8968 39.5538C66.8226 39.1948 66.575 38.941 66.3398 38.9906C66.1108 39.0401 65.9808 39.3681 66.0489 39.7271Z"
      fill="#263238"
    />
    <path
      d="M66.3398 40.3461C66.3398 40.3461 67.5778 42.2525 68.6796 43.0696C68.1658 43.7937 67.0269 43.738 67.0269 43.738L66.3398 40.3461Z"
      fill="#ED847E"
    />
    <path
      d="M64.3282 38.0001C64.2787 38.0001 64.223 37.9815 64.1797 37.9444C64.093 37.8639 64.0868 37.7277 64.1673 37.6411C64.9781 36.7683 65.9561 36.9231 65.9994 36.9293C66.1171 36.9478 66.1975 37.0593 66.1728 37.1769C66.1542 37.2945 66.0428 37.3749 65.9252 37.3564C65.8942 37.3502 65.1267 37.2388 64.483 37.9382C64.4458 37.9753 64.3901 38.0001 64.3282 38.0001Z"
      fill="#263238"
    />
    <path
      d="M55.8111 44.7841C56.461 48.1079 57.1171 54.2048 54.7898 56.427C54.7898 56.427 55.6997 59.8004 61.8832 59.8004C68.6796 59.8004 65.1328 56.427 65.1328 56.427C61.4252 55.5418 61.518 52.7874 62.168 50.2063L55.8111 44.7841Z"
      fill="#FFC3BD"
    />
    <path
      d="M53.4467 57.0459C52.9515 56.1237 52.4006 55.0652 53.5333 54.731C54.7774 54.3658 62.7808 53.6911 65.1267 54.372C64.4025 55.6594 65.6281 57.2378 65.6281 57.2378L53.4467 57.0459Z"
      fill="#263238"
    />
    <path
      d="M33.3113 212.13C33.7694 212.229 34.2212 212.254 34.444 212.068C34.5369 211.988 34.6297 211.839 34.5493 211.567C34.5059 211.424 34.4131 211.319 34.2707 211.251C33.6579 210.96 32.2467 211.536 32.1848 211.561C32.1476 211.579 32.1167 211.616 32.1167 211.66C32.1167 211.703 32.1415 211.74 32.1786 211.759C32.3952 211.858 32.8533 212.031 33.3113 212.13ZM34.0479 211.4C34.0912 211.412 34.1346 211.424 34.1717 211.443C34.2584 211.486 34.3079 211.542 34.3326 211.629C34.3883 211.821 34.3203 211.882 34.2955 211.901C34.0541 212.111 33.1442 211.92 32.5005 211.66C32.9461 211.511 33.6456 211.313 34.0479 211.4Z"
      fill="#45515C"
    />
    <path
      d="M32.2032 211.759C32.2218 211.765 32.2404 211.765 32.259 211.759C32.7727 211.61 33.8745 210.83 33.8869 210.31C33.8869 210.217 33.8559 210.05 33.6083 209.964C33.4474 209.908 33.2864 209.92 33.1317 210.001C32.5251 210.31 32.1413 211.573 32.1228 211.622C32.1104 211.66 32.1228 211.703 32.1475 211.728C32.1661 211.746 32.1847 211.759 32.2032 211.759ZM33.4907 210.156C33.5093 210.162 33.5217 210.162 33.5402 210.168C33.6764 210.217 33.6702 210.279 33.6702 210.31C33.664 210.614 32.9398 211.226 32.4075 211.474C32.5437 211.109 32.847 210.391 33.2369 210.193C33.3174 210.149 33.4041 210.137 33.4907 210.156Z"
      fill="#45515C"
    />
    <path
      d="M66.8846 212.891C67.4664 212.891 68.0297 212.811 68.2216 212.551C68.2897 212.458 68.3392 212.303 68.2154 212.08C68.1473 211.957 68.0297 211.87 67.8749 211.827C67.1198 211.622 65.529 212.526 65.4609 212.563C65.4238 212.588 65.399 212.631 65.4114 212.675C65.4176 212.718 65.4548 212.755 65.4981 212.761C65.7766 212.823 66.3399 212.891 66.8846 212.891ZM67.6026 212.012C67.6769 212.012 67.7511 212.019 67.813 212.037C67.9121 212.062 67.9802 212.111 68.0235 212.186C68.0978 212.322 68.073 212.384 68.0421 212.421C67.8316 212.712 66.6803 212.724 65.8385 212.6C66.2594 212.39 67.0703 212.012 67.6026 212.012Z"
      fill="#45515C"
    />
    <path
      d="M65.5102 212.768C65.5226 212.768 65.5412 212.761 65.5536 212.755C66.1044 212.508 67.1876 211.523 67.1072 211.01C67.0886 210.892 67.0019 210.737 66.7048 210.706C66.482 210.682 66.2839 210.744 66.1106 210.892C65.535 211.369 65.4112 212.594 65.405 212.644C65.3988 212.681 65.4174 212.718 65.4483 212.743C65.4669 212.761 65.4855 212.768 65.5102 212.768ZM66.6182 210.923C66.6368 210.923 66.6615 210.923 66.6801 210.929C66.872 210.948 66.8843 211.022 66.8905 211.053C66.9401 211.363 66.2035 212.136 65.6402 212.47C65.6959 212.111 65.863 211.381 66.2406 211.065C66.3582 210.966 66.482 210.923 66.6182 210.923Z"
      fill="#45515C"
    />
    <path
      d="M60.509 212.656H65.0585L65.5104 202.127H60.9609L60.509 212.656Z"
      fill="#FFC3BD"
    />
    <path
      d="M27.134 210.552L31.5783 211.517L36.0411 201.706L31.5968 200.747L27.134 210.552Z"
      fill="#FFC3BD"
    />
    <path
      d="M32.0053 211.072L27.0101 209.988C26.8306 209.951 26.6449 210.044 26.5645 210.211L24.8313 213.975C24.6518 214.365 24.887 214.823 25.3079 214.903C27.0596 215.25 27.9262 215.33 30.1236 215.807C31.4729 216.098 34.5245 216.84 36.3876 217.249C38.2136 217.645 38.8944 215.862 38.1702 215.528C34.9082 214.036 33.5465 212.724 32.6614 211.505C32.5066 211.276 32.2714 211.127 32.0053 211.072Z"
      fill="#263238"
    />
    <path
      d="M65.0277 212.13H60.0635C59.8778 212.13 59.7169 212.26 59.6736 212.44L58.776 216.481C58.6832 216.902 58.9989 217.298 59.4322 217.292C61.221 217.261 63.8145 217.156 66.0614 217.156C68.692 217.156 70.9698 217.298 74.0523 217.298C75.9216 217.298 76.4354 215.411 75.6555 215.237C72.0964 214.457 69.1934 214.377 66.1171 212.483C65.789 212.279 65.4176 212.13 65.0277 212.13Z"
      fill="#263238"
    />
    <path
      d="M46.6439 57.4545C42.3792 58.4325 38.8696 69.3141 38.8696 69.3141L47.43 75.8381C47.43 75.8381 50.5683 72.8794 51.9671 67.4324C53.4341 61.7255 51.0015 56.458 46.6439 57.4545Z"
      fill="#407BFF"
    />
    <path
      d="M46.6439 57.4545C42.3792 58.4325 38.8696 69.3141 38.8696 69.3141L47.43 75.8381C47.43 75.8381 50.5683 72.8794 51.9671 67.4324C53.4341 61.7255 51.0015 56.458 46.6439 57.4545Z"
      fill="#EBF8F8"
    />
    <path
      opacity="0.2"
      d="M50.2958 65.4579C47.9808 67.7481 47.2256 71.92 47.0461 75.5472L47.4237 75.8381C47.4237 75.8381 50.5681 72.8732 51.967 67.4324C52.0536 67.092 52.1279 66.7515 52.1898 66.4173L50.2958 65.4579Z"
      fill="black"
    />
    <path
      d="M72.1208 57.6896C72.1208 57.6896 76.9612 63.1552 72.387 96.9945C65.0583 96.9945 51.6327 96.9945 48.0674 96.9945C47.9003 93.2621 50.2462 75.0457 46.6438 57.4544C46.6438 57.4544 50.8157 56.6188 54.7833 56.4269C57.8906 56.2784 62.3905 56.1793 65.1202 56.4269C68.7288 56.755 72.1208 57.6896 72.1208 57.6896Z"
      fill="#407BFF"
    />
    <path
      d="M72.1208 57.6896C72.1208 57.6896 76.9612 63.1552 72.387 96.9945C65.0583 96.9945 51.6327 96.9945 48.0674 96.9945C47.9003 93.2621 50.2462 75.0457 46.6438 57.4544C46.6438 57.4544 50.8157 56.6188 54.7833 56.4269C57.8906 56.2784 62.3905 56.1793 65.1202 56.4269C68.7288 56.755 72.1208 57.6896 72.1208 57.6896Z"
      fill="#EBF8F8"
    />
    <path
      opacity="0.2"
      d="M74.2441 68.0327L74.1512 67.9523L68.6238 69.2769C68.3081 73.0155 72.1519 77.4845 74.1327 79.5271C74.405 74.7548 74.3988 70.9914 74.2441 68.0327Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M65.5105 202.134L65.2753 207.556H60.7258L60.961 202.134H65.5105Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M31.5968 200.747L36.041 201.713L33.7384 206.764L29.2942 205.804L31.5968 200.747Z"
      fill="black"
    />
    <path
      d="M53.9108 41.4044C55.0806 45.762 55.5387 48.3741 58.2312 50.1877C62.2793 52.9112 67.3177 49.7853 67.4725 45.1678C67.6148 41.0083 65.6775 34.5771 61.0042 33.6982C56.3928 32.8316 52.7409 37.0469 53.9108 41.4044Z"
      fill="#FFC3BD"
    />
    <path
      d="M55.9099 48.5103C57.7606 49.5688 64.5198 46.0283 63.1333 40.6556C63.1333 40.6556 65.7083 37.5607 65.4173 34.1439C65.1264 30.7272 62.7805 29.4274 60.286 30.1578C58.0887 30.8015 58.1506 32.0085 58.1506 32.0085C58.1506 32.0085 47.758 33.2341 49.9801 37.4555C45.7463 42.0668 53.5949 47.1857 55.9099 48.5103Z"
      fill="#263238"
    />
    <path
      d="M50.2712 37.8083C50.2712 37.8083 49.0332 35.6666 47.2258 36.8674C48.0738 36.8798 49.4665 37.0222 50.2712 37.8083Z"
      fill="#263238"
    />
    <path
      d="M65.0833 36.3969C65.0833 36.3969 67.3488 31.2842 63.8145 29.6377C65.0091 31.2965 65.659 33.9643 65.0833 36.3969Z"
      fill="#263238"
    />
    <path
      d="M61.3261 41.5839C61.6232 42.729 62.3721 43.7131 63.1582 44.295C64.3405 45.1739 65.2256 44.3073 65.139 42.9704C65.0647 41.7634 64.359 39.8569 63.053 39.4979C61.7717 39.1513 60.9856 40.2778 61.3261 41.5839Z"
      fill="#FFC3BD"
    />
    <path
      d="M63.7648 96.9946C63.7648 96.9946 58.6026 131.744 55.7739 144.767C52.6728 159.022 36.0348 206.194 36.0348 206.194L27.8953 204.43C27.8953 204.43 39.5258 165.992 42.1317 146.061C44.131 130.754 48.0738 97.0008 48.0738 97.0008H63.7648V96.9946Z"
      fill="#263238"
    />
    <path
      d="M37.9783 203.471C38.0155 203.477 36.4618 206.417 36.4618 206.417L27.301 204.436L28.0747 201.682L37.9783 203.471Z"
      fill="#45515C"
    />
    <path
      opacity="0.3"
      d="M60.5029 109.374C54.536 110.544 55.1673 132.796 55.8853 144.241C57.6061 136.151 60.1253 120.584 61.8461 109.535C61.4499 109.337 61.0043 109.275 60.5029 109.374Z"
      fill="black"
    />
    <path
      d="M72.3931 96.9946C72.3931 96.9946 73.0183 130.995 72.5231 144.86C72.0094 159.282 66.9709 206.51 66.9709 206.51H59.2709C59.2709 206.51 58.9862 160.124 58.6457 145.962C58.2743 130.518 56.405 96.9946 56.405 96.9946H72.3931Z"
      fill="#263238"
    />
    <path
      d="M115.152 73.0217H99.4612L98.5823 88.9851L114.255 89.3998L115.152 73.0217Z"
      fill="#34A2A8"
    />
    <path
      d="M106.907 83.2905C101.671 82.6468 96.4282 81.9968 91.1917 81.3531C92.4605 76.0299 93.4076 70.6696 94.0266 65.3031C99.294 65.953 104.568 66.6029 109.835 67.2467C109.185 72.6132 108.207 77.9735 106.907 83.2905Z"
      fill="#F9807D"
    />
    <path
      d="M68.4504 203.44C68.4876 203.44 68.0171 206.64 68.0171 206.64H58.6459L58.3921 203.786L68.4504 203.44Z"
      fill="#45515C"
    />
    <path
      d="M74.3617 61.8368C75.0612 62.9262 75.8597 64.1456 76.6519 65.2783C77.4628 66.4172 78.2737 67.5561 79.1278 68.6517C80.8176 70.8429 82.6127 72.9536 84.5439 74.6991C85.0205 75.1386 85.5095 75.5533 86.0047 75.9308L86.376 76.2094C86.3451 76.1908 86.2275 76.1475 86.1594 76.1351C85.9985 76.1042 85.918 76.1289 85.9613 76.1537C86.0542 76.2032 86.506 76.1227 86.9826 75.9185C87.4654 75.7142 88.0101 75.4357 88.5486 75.1076C90.7274 73.7582 92.9743 71.8704 95.085 70.0382L97.7404 72.186C96.0939 74.8414 94.2803 77.2431 91.7859 79.4466C91.1545 79.9913 90.4737 80.5298 89.6566 81.0126C88.8334 81.483 87.9049 81.972 86.5308 82.1701C85.8499 82.2568 85.0205 82.2444 84.1663 81.972C83.7516 81.8482 83.3059 81.6254 82.9655 81.415L82.427 81.0745C81.7214 80.6165 81.0467 80.1337 80.4029 79.6261C77.8404 77.5959 75.7049 75.3119 73.7366 72.935C72.7648 71.7342 71.8239 70.521 70.9574 69.2583C70.0723 67.9832 69.249 66.7638 68.4258 65.3464L74.3617 61.8368Z"
      fill="#FFC3BD"
    />
    <path
      d="M65.4298 64.5665C63.9752 70.6633 75.0053 76.6303 75.0053 76.6303L82.173 68.237C82.173 68.237 78.9111 63.2914 74.8506 59.398C69.8431 54.5886 66.5378 59.9241 65.4298 64.5665Z"
      fill="#407BFF"
    />
    <path
      d="M65.4298 64.5665C63.9752 70.6633 75.0053 76.6303 75.0053 76.6303L82.173 68.237C82.173 68.237 78.9111 63.2914 74.8506 59.398C69.8431 54.5886 66.5378 59.9241 65.4298 64.5665Z"
      fill="#EBF8F8"
    />
    <path
      d="M94.3669 71.3072L96.0567 65.8788L100.507 68.0947C100.507 68.0947 99.8573 72.0562 96.62 72.7432L94.3669 71.3072Z"
      fill="#FFC3BD"
    />
    <path
      d="M97.8456 64.3746L101.857 65.4764L100.513 68.0947L96.063 65.8787L97.8456 64.3746Z"
      fill="#FFC3BD"
    />
    <path
      d="M14.0639 149.747C14.0639 150.853 13.1648 151.752 12.0587 151.752C10.9526 151.752 10.0535 150.853 10.0535 149.747C10.0535 148.641 10.9526 147.742 12.0587 147.742C13.1648 147.742 14.0639 148.641 14.0639 149.747Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M42.8801 6.57882C42.8801 7.6849 41.9809 8.58404 40.8748 8.58404C39.7688 8.58404 38.8696 7.6849 38.8696 6.57882C38.8696 5.47274 39.7688 4.57361 40.8748 4.57361C41.9809 4.57361 42.8801 5.46561 42.8801 6.57882Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M235.571 102.441C235.571 103.547 234.672 104.447 233.566 104.447C232.46 104.447 231.561 103.547 231.561 102.441C231.561 101.335 232.46 100.436 233.566 100.436C234.672 100.436 235.571 101.335 235.571 102.441Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M240.336 2.00522C240.336 3.1113 239.436 4.01043 238.33 4.01043C237.224 4.01043 236.325 3.1113 236.325 2.00522C236.325 0.899135 237.224 0 238.33 0C239.436 0 240.336 0.899135 240.336 2.00522Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M214.6 25.0645C214.6 26.1706 213.701 27.0698 212.595 27.0698C211.488 27.0698 210.589 26.1706 210.589 25.0645C210.589 23.9585 211.488 23.0593 212.595 23.0593C213.701 23.0593 214.6 23.9513 214.6 25.0645Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M222.787 175.967C222.787 177.073 221.888 177.972 220.782 177.972C219.676 177.972 218.776 177.073 218.776 175.967C218.776 174.861 219.676 173.962 220.782 173.962C221.888 173.962 222.787 174.854 222.787 175.967Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M211.268 191.511C211.268 192.617 210.369 193.516 209.263 193.516C208.157 193.516 207.258 192.617 207.258 191.511C207.258 190.405 208.157 189.505 209.263 189.505C210.369 189.505 211.268 190.397 211.268 191.511Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M18.501 26.6518C14.6156 25.5804 11.5427 22.5074 10.4713 18.622C10.3535 18.1864 9.75303 18.1864 9.63529 18.622C8.56387 22.5074 5.49091 25.5804 1.60553 26.6518C1.1699 26.7695 1.1699 27.37 1.60553 27.4877C5.49091 28.5709 8.56387 31.6321 9.63529 35.5175C9.75303 35.9531 10.3535 35.9531 10.4713 35.5175C11.5427 31.6321 14.6156 28.5591 18.501 27.4877C18.9366 27.37 18.9366 26.7695 18.501 26.6518Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M31.2859 114.622C27.4005 113.551 24.3276 110.478 23.2562 106.592C23.1384 106.157 22.5379 106.157 22.4202 106.592C21.3488 110.478 18.2758 113.551 14.3904 114.622C13.9548 114.74 13.9548 115.34 14.3904 115.458C18.2758 116.541 21.3488 119.603 22.4202 123.488C22.5379 123.924 23.1384 123.924 23.2562 123.488C24.3276 119.603 27.4005 116.53 31.2859 115.458C31.7216 115.34 31.7216 114.74 31.2859 114.622Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
    <path
      d="M240.009 52.1266C236.123 50.9988 233.05 47.7641 231.979 43.6742C231.861 43.2157 231.261 43.2157 231.143 43.6742C230.072 47.7641 226.999 50.9988 223.113 52.1266C222.678 52.2506 222.678 52.8826 223.113 53.0066C226.999 54.1468 230.072 57.3691 231.143 61.459C231.261 61.9175 231.861 61.9175 231.979 61.459C233.05 57.3691 236.123 54.1344 240.009 53.0066C240.444 52.8826 240.444 52.2506 240.009 52.1266Z"
      fill="#FDA402"
      fillOpacity="0.3"
    />
  </svg>
);

export default WebStubIcon;
