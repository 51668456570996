import { combineActions } from 'redux-actions';
import handleActions from '../../utils/redux-actions';
import { fetchComments, sendComment, setReplyCommentState } from './actions';
import { SOCKET_COMMENT_NEW } from '../../constants';

const initialState = {
    replyComment: null
};

export default handleActions(
  {
    [setReplyCommentState]: (state, { payload }) => {
      state.replyComment = payload;
      return state;
    },
    [fetchComments.START]: (state, { args }) => {
      if (args.id) {
        state[args.id] = state[args.id] || {};
        state[args.id].isLoading = true;
      }
      return state;
    },
    [fetchComments.FAILED]: (state, { args }) => {
      if (args.id) {
        (state[args.id] || {}).isLoaded = false;
      }
      return state;
    },
    [fetchComments.ENDED]: (state, { args }) => {
      if (args.id) {
        (state[args.id] || {}).isLoading = false;
      }
      return state;
    },
    [fetchComments.SUCCEEDED]: (state, { payload, args }) => {
      if (payload) {
        state[args.id].isLoaded = true;
        state[args.id].totalItems = payload.count;
        state[args.id].entries = payload.results;
      }

      return state;
    },
    [combineActions(sendComment.SUCCEEDED, SOCKET_COMMENT_NEW)]: (
      state,
      { payload, args = {} }
    ) => {
      const comments = state[payload.objectId || args.id];
      const isExist =
        !!comments &&
        !!comments.entries &&
        comments.entries.some(({ id }) => id === payload.id);

      if (comments && !isExist) {
        comments.entries = [...comments.entries, payload];
      }

      return state;
    }
  },
  initialState
);
