import { RU_RU } from 'i18n-config';

// eslint-disable-next-line import/prefer-default-export
export const getCalendarLocale = language => {
  if (language === RU_RU) {
    return 'ru';
  }

  return undefined;
};
