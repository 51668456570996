import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import { NoMessagesIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import styles from './empty.module.scss';

const Empty = ({ icon, description }) => (
  <div className={styles.root}>
    <Icon component={icon} className={styles.icon} />

    <Typography.Paragraph>{description}</Typography.Paragraph>
  </div>
);

Empty.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Empty.defaultProps = {
  icon: NoMessagesIcon,
  description: <Translation ns="Common">{t => t('NoMessages')}</Translation>
};

export default Empty;
