export const getFileListSize = fileList => {
  const result = (fileList || []).reduce((acc, curr) => {
    const size = curr.fileSize || curr.size;

    return acc + size;
  }, 0);

  return result;
};

export default getFileListSize;
