export const LOW_PRIORITY = 1;

export const STRIKETHROUGH_TEXT = 'strikethrough';

export const SUPPORTED_BLOCK_TYPES = new Set([
  'paragraph',
  'quote'
  // 'h1',
  // 'h2',
  // 'h3',
  // 'h4',
  // 'h5'
]);

export const BLOCK_TYPE_TO_BLOCK_NAME = {
  // h1: 'Заголовок 1',
  // h2: 'Заголовок 2',
  // h3: 'Заголовок 3',
  // h4: 'Заголовок 4',
  // h5: 'Заголовок 5',
  // paragraph: 'Обычный',
  // quote: 'Цитата'
};

export const DROPDOWN_LIST = [
  // { tag: 'paragraph', text: 'Обычный' },
  // { tag: 'h1', text: 'Заголовок 1' },
  // { tag: 'h2', text: 'Заголовок 2' },
  // { tag: 'h3', text: 'Заголовок 3' },
  // { tag: 'h4', text: 'Заголовок 4' },
  // { tag: 'h5', text: 'Заголовок 5' },
  // { tag: 'quote', text: 'Цитата' }
];
