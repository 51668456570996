import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { getIsUserOnBench, getIsSubmittedJoin } from 'store/workspace';
import { CREATE_COMPANY, MODAL } from 'constants/index';
import useQueryParam from 'hooks/common/use-query-param';

export const useVisibleAppointment = () => {
  const [visible, setVisible] = useState(false);
  const modal = useQueryParam(MODAL);
  const isBench = useSelector(getIsUserOnBench, shallowEqual);
  const isSubmittedJoin = useSelector(getIsSubmittedJoin, shallowEqual);
  const isCreateCompanyModal = modal === CREATE_COMPANY;

  useEffect(() => {
    const isVisible = isBench && !isCreateCompanyModal && !isSubmittedJoin;

    setVisible(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBench, isSubmittedJoin, modal]);

  return visible;
};

export default useVisibleAppointment;
