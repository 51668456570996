import React from 'react';

export const HistogramIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.071 19.5008H3.64244V2.78655C3.64244 2.66869 3.54601 2.57227 3.42815 2.57227H1.92815C1.8103 2.57227 1.71387 2.66869 1.71387 2.78655V21.2151C1.71387 21.333 1.8103 21.4294 1.92815 21.4294H22.071C22.1889 21.4294 22.2853 21.333 22.2853 21.2151V19.7151C22.2853 19.5973 22.1889 19.5008 22.071 19.5008Z"
      fill="currentColor"
    />
    <path
      d="M5.64624 15.8726L5.64624 17.3726C5.64624 17.4905 5.74267 17.5869 5.86052 17.5869L9.71767 17.5869C9.83552 17.5869 9.93195 17.4905 9.93195 17.3726L9.93195 15.8726C9.93195 15.7548 9.83552 15.6583 9.71767 15.6583L5.86052 15.6583C5.74267 15.6583 5.64624 15.7548 5.64624 15.8726Z"
      fill="currentColor"
    />
    <path
      d="M5.64624 11.8012L5.64624 13.3012C5.64624 13.4191 5.74267 13.5155 5.86052 13.5155L16.432 13.5155C16.5498 13.5155 16.6462 13.4191 16.6462 13.3012L16.6462 11.8012C16.6462 11.6833 16.5498 11.5869 16.432 11.5869L5.86052 11.5869C5.74267 11.5869 5.64624 11.6833 5.64624 11.8012Z"
      fill="currentColor"
    />
    <path
      d="M5.64624 7.72977L5.64624 9.22977C5.64624 9.34763 5.74267 9.44406 5.86052 9.44406L12.3427 9.44406C12.4605 9.44406 12.557 9.34763 12.557 9.22977L12.557 7.72977C12.557 7.61191 12.4605 7.51548 12.3427 7.51548L5.86052 7.51548C5.74267 7.51548 5.64624 7.61191 5.64624 7.72977Z"
      fill="currentColor"
    />
  </svg>
);

export default HistogramIcon;
