import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { TELEGRAM, VIDEOS } from 'constants/index';

import {
  FormInput,
  FormSwitch,
  FormTextarea
} from 'components/common/hook-form';
import Typography from 'components/common/typography';
import VideoBtn from 'components/common/video-btn';
import FormMarkdownEditor from 'components/common/hook-form/markdown-editor';
import Icon from 'components/common/icon';

import mapValue from 'utils/map-value';

import CommonChannelForm from '../common-channel';
import CodeCopy from '../../components/code-copy';
import { getTelegramBotLink } from '../../utils';
import OperatorHours from '../../components/operator-hours';
import SlaSettings from '../../components/sla-settings';
import { transformSubmitSlaConfigValues } from '../../components/sla-settings/utils';
import { transformSubmitScheduleConfigValues } from '../../components/operator-hours/utils';

import styles from './telegram-channel.module.scss';

const TelegramChannelForm = ({
  defaultValues,
  isLoading,
  isEditor,
  isOnlyView,
  onSubmit
}) => {
  const { t } = useTranslation(['ConnectTelegram', 'Errors', 'Toast']);

  const link = getTelegramBotLink(defaultValues.source);

  const handleSubmit = ({
    responsible,
    operators = [],
    message,
    isAskFeedback,
    token,
    source,
    scheduleConfig,
    slaConfig,
    ...values
  }) =>
    onSubmit({
      ...values,
      kind: TELEGRAM,
      source: token,
      responsible: responsible.value,
      operators: operators.map(mapValue),
      config: {
        name: source,
        message: message || '',
        isAskFeedback,
        scheduleConfig: transformSubmitScheduleConfigValues({
          ...scheduleConfig,
          workingTimeMessage: message || ''
        }),
        slaConfig: transformSubmitSlaConfigValues(slaConfig)
      }
    });

  return (
    <CommonChannelForm
      defaultValues={defaultValues}
      isLoading={isLoading}
      isEditor={isEditor}
      isOnlyView={isOnlyView}
      onSubmit={handleSubmit}
      videoSlug={VIDEOS.setupTelegramChannel}
    >
      {({ isActiveScheduleConfigWatch }) => (
        <div>
          {/* Used to save the token of the telegram bot */}
          <FormInput
            name="token"
            itemProps={{
              className: styles.hiddenFiled
            }}
          />

          <OperatorHours isOnlyView={isOnlyView}>
            {isActiveScheduleConfigWatch && (
              <FormMarkdownEditor
                name="scheduleConfig.nonWorkingTimeMessage"
                label={
                  <div className={styles.tooltip}>
                    {t('WelcomeMessageNotWorkingHours')}
                    <Tooltip title={t('WelcomeMessageNotWorkingHoursTip')}>
                      <Icon
                        type="question-circle"
                        theme="filled"
                        color="black-55"
                        size={15}
                      />
                    </Tooltip>
                  </div>
                }
                toolbarHidden
                allowAttach={false}
                placeholder={t('WelcomeMessageNotWorkingHours')}
                readOnly={isOnlyView}
                uploadProps={{
                  disabled: isOnlyView
                }}
                itemProps={{
                  className: styles.editor
                }}
                rules={{
                  validate: value =>
                    !!value.description || t('RequiredField', { ns: 'Errors' })
                }}
              />
            )}
          </OperatorHours>

          <Typography.Title className={styles.mb16} level={3}>
            {t('WelcomeMessageHeading')}
          </Typography.Title>

          <FormTextarea
            name="message"
            label={
              <div className={styles.tooltip}>
                {t('WelcomeMessageWorkingHours')}
                <Tooltip title={t('WelcomeMessageWorkingHoursTip')}>
                  <Icon
                    type="question-circle"
                    theme="filled"
                    color="black-55"
                    size={15}
                  />
                </Tooltip>
              </div>
            }
            autoSize={{
              minRows: 5,
              maxRows: 5
            }}
            itemProps={{
              className: styles.textareaItem
            }}
            className={styles.textarea}
            disabled={isOnlyView}
          />

          <Divider />

          <SlaSettings isOnlyView={isOnlyView} />

          <Divider />

          {isEditor && (
            <>
              <Typography.Title level={3} className={styles.mb16}>
                {t('ConnectChatHeading')}
              </Typography.Title>

              <VideoBtn
                slug={VIDEOS.connectTelegramToSiteChat}
                style={{ marginBottom: 16 }}
              />

              <Typography.Paragraph color="black-45" className={styles.mb16}>
                {t('ConnectChatDesc')}
              </Typography.Paragraph>

              <CodeCopy
                link={link}
                disabled={isOnlyView}
                message={t('LinkCopied', { ns: 'Toast' })}
                className={styles.codeCopy}
              />
            </>
          )}

          <FormSwitch
            name="isAskFeedback"
            label={t('AskFeedbackWhileClosingDialogToggle')}
            itemProps={{
              className: styles.mb24
            }}
            disabled={isOnlyView}
          />
        </div>
      )}
    </CommonChannelForm>
  );
};

TelegramChannelForm.propTypes = {
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

TelegramChannelForm.defaultProps = {
  defaultValues: {},
  isEditor: false,
  isOnlyView: false
};

export default TelegramChannelForm;
