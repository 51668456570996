import { WORKSPACE_CONTACTS } from 'constants/api';

export default api => ({
  fetchInvitedContacts: (workspaceId, offset, limit) =>
    api.get(`/v1/${workspaceId}/invitations/${WORKSPACE_CONTACTS}/`, {
      params: {
        offset,
        limit,
        is_pending: true
      }
    }),

  fetchInvitedEmployees: (workspaceId, offset, limit) =>
    api.get(`/v1/${workspaceId}/invitations/employees/`, {
      params: {
        offset,
        limit,
        is_pending: true
      }
    }),

  inviteContact: (email, workspace) =>
    api.post(`/v1/${workspace}/invitations/${WORKSPACE_CONTACTS}/`, {
      email,
      workspace
    }),

  inviteEmployee: ({ workspaceId, employeeEmails }) =>
    api.post(`/v1/${workspaceId}/invitations/employees/`, {
      employeeEmails
    }),

  deleteInvite: (workspaceId, id) =>
    api.delete(`/v1/${workspaceId}/invitations/employees/${id}/`),

  acceptEmployee: (invitationKey, accept = true, workspaceId) =>
    api
      .patch(
        `/v1/${workspaceId}/invitations/employees/${invitationKey}/accept/`,
        {
          accept
        }
      )
      .then(({ data }) => data),

  confirm: ({ token, workspaceId, errorHandle }) =>
    api.post(
      '/v1/invitations/confirm/',
      { token, workspace: workspaceId },
      { errorHandle }
    ),

  checkEmail: (workspaceId, email) =>
    api.put(`/v1/${workspaceId}/invitations/employees/email-check/`, { email })
});
