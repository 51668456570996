import React from 'react';

export const PhoneAndMessageIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85058 3.90234L4.22782 1.52734C4.518 1.23717 4.90639 1.07422 5.3171 1.07422C5.72782 1.07422 6.11621 1.23493 6.40639 1.52734L8.96666 4.08315C9.25683 4.37333 9.41978 4.76395 9.41978 5.17467C9.41978 5.58761 9.25907 5.97377 8.96666 6.26618L6.96666 8.26842C7.42227 9.32999 8.07691 10.2945 8.89523 11.1099C9.71442 11.9336 10.672 12.5832 11.7345 13.043L13.7345 11.0407C14.0247 10.7506 14.4131 10.5876 14.8238 10.5876C15.0264 10.5869 15.2271 10.6266 15.4141 10.7044C15.6012 10.7822 15.7708 10.8965 15.9131 11.0407L18.4756 13.5965C18.7658 13.8867 18.9287 14.2773 18.9287 14.6881C18.9287 15.101 18.768 15.4872 18.4756 15.7796L16.1006 18.1546C15.605 18.6501 14.922 18.9336 14.2211 18.9336C14.076 18.9336 13.9354 18.9224 13.7925 18.8979C10.8372 18.4113 7.90416 16.8376 5.53585 14.4715C3.16978 12.101 1.59835 9.16797 1.10728 6.21038C0.966656 5.3711 1.24791 4.50726 1.85058 3.90234ZM2.68987 5.94476C3.12514 8.57645 4.54032 11.2015 6.67201 13.3332C8.80371 15.4648 11.4265 16.88 14.0582 17.3153C14.3885 17.3711 14.7278 17.2595 14.9689 17.0207L17.3015 14.6881L14.8283 12.2126L12.1542 14.8912L12.1341 14.9113L11.6519 14.7327C10.19 14.1952 8.8625 13.3463 7.76132 12.2447C6.66014 11.1432 5.81167 9.81532 5.27469 8.35324L5.09612 7.8711L7.79255 5.1769L5.31933 2.70145L2.98674 5.03404C2.74567 5.27511 2.63407 5.6144 2.68987 5.94476Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 8.33333V1C12.5 0.447715 12.9477 0 13.5 0H19C19.5523 0 20 0.447715 20 1V4.83333C20 5.38562 19.5523 5.83333 19 5.83333H15L12.5 8.33333ZM14.1667 4.16667V1.66667H18.3333V4.16667H14.1667Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneAndMessageIcon;
