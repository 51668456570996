import React from 'react';

export const SnippetsIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5718 1.28571H17.6789V0.214286C17.6789 0.0964286 17.5825 0 17.4646 0H15.9646C15.8468 0 15.7504 0.0964286 15.7504 0.214286V1.28571H11.6789V0.214286C11.6789 0.0964286 11.5825 0 11.4646 0H9.96464C9.84678 0 9.75035 0.0964286 9.75035 0.214286V1.28571H6.85749C6.38338 1.28571 6.00035 1.66875 6.00035 2.14286V5.35714H3.42892C2.95481 5.35714 2.57178 5.74018 2.57178 6.21429V23.1429C2.57178 23.617 2.95481 24 3.42892 24H17.1432C17.6173 24 18.0004 23.617 18.0004 23.1429V20.5714H20.5718C21.0459 20.5714 21.4289 20.1884 21.4289 19.7143V2.14286C21.4289 1.66875 21.0459 1.28571 20.5718 1.28571ZM16.0718 22.0714H4.50035V7.28571H10.3396V11.9464C10.3396 12.5384 10.8191 13.0179 11.4111 13.0179H16.0718V22.0714ZM16.0718 11.3036H12.0539V7.28571H12.0593L16.0718 11.2982V11.3036ZM19.5004 18.6429H18.0004V10.5L12.8575 5.35714H7.92892V3.21429H9.75035V4.07143C9.75035 4.18929 9.84678 4.28571 9.96464 4.28571H11.4646C11.5825 4.28571 11.6789 4.18929 11.6789 4.07143V3.21429H15.7504V4.07143C15.7504 4.18929 15.8468 4.28571 15.9646 4.28571H17.4646C17.5825 4.28571 17.6789 4.18929 17.6789 4.07143V3.21429H19.5004V18.6429Z"
      fill="currentColor"
    />
  </svg>
);

export default SnippetsIcon;
