import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Alert, Divider } from 'antd';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  STATUS_CONNECTED,
  SYSTEM_LOGO,
  TESTID_WEBSITE_FORM_CHANNEL_NAME,
  TESTID_WEBSITE_FORM_MESSAGE,
  WEBSITE_FORM
} from 'constants/index';

import FormInput from 'components/common/hook-form/input';
import FormColorInput from 'components/common/hook-form/color-input';
import FormEmployeeSelect from 'components/common/hook-form/select/employee';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import {
  FormCustomSelect,
  FormSwitch,
  // FormTagsSelect,
  FormTextarea,
  LabelWithTooltip,
  SubmitWrapper,
  validateMaxLength,
  withoutBubbling
} from 'components/common/hook-form';
import FormAttachFile from 'components/common/hook-form/attach-file';
import FormNewEditor from 'components/common/hook-form/markdown';

import uploadFileAsBase64 from 'utils/convert-file-to-base64';
import mapValue from 'utils/map-value';
import scrollToFirstError from 'utils/scroll-to-first-error';
import { escapeSingleQuotes } from 'utils/escape-single-quotes';

import EditableInputs from './editable-inputs';
import CodeCopy from '../../components/code-copy';
import { getWebsiteFormLink } from '../../utils';
import { MAX_SIZE_LOGO } from './constants';
import { transformSubmitSlaConfigValues } from '../../components/sla-settings/utils';
import SlaSettings from '../../components/sla-settings';
import { LANGUAGE_OPTIONS } from '../../drawers/creator/steps/utils';

import styles from './website-form-channel.module.scss';

const { Text, Title } = Typography;

const LabelTitle = ({ children }) => (
  <Title level={4} style={{ marginBottom: 16 }}>
    {children}
  </Title>
);

const removeDataPrefix = imgSrc =>
  imgSrc.replace('data:', '').replace(/^.+,/, '');

export const WebsiteFormChannelForm = ({
  defaultValues,
  isEditor,
  isOnlyView,
  isLoading,
  setValues,
  onSubmit
}) => {
  const isFirstRender = useRef(true);

  const { watch, control, handleSubmit, ...methods } = useForm({
    defaultValues
  });

  const [fields, setFields] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [logoSrc, setLogoSrc] = useState('');

  const { t } = useTranslation([
    'ConnectForm',
    'Errors',
    'Toast',
    'ConnectWidget'
  ]);

  const colorFormWatch = useWatch({ name: 'color', control });
  const buttonTextWatch = useWatch({ name: 'buttonText', control });
  const buttonColorWatch = useWatch({ name: 'buttonColor', control });
  const messageWatch = useWatch({ name: 'message', control }) || '';
  const titleWatch =
    useWatch({ name: 'title', control }) || t('FormTitlePlchldr');
  const descriptionWatch = useWatch({ name: 'description', control }) || '';
  const isLogoWatch = useWatch({ name: 'isLogo', control });
  const pageColorWatch = useWatch({ name: 'pageColor', control });
  const pageBackgroundWatch = useWatch({ name: 'pageBackground', control });
  const pageLogoWatch = useWatch({ name: 'pageLogo', control });
  const languageWatch = useWatch({ name: 'language', control });
  const isActiveSlaConfigWatch = useWatch({
    name: 'slaConfig.isActive',
    control
  });

  const { file: backgroundImageFile } = pageBackgroundWatch;
  const { file: logoImageFile } = pageLogoWatch;

  const sendLogo = () => {
    if (!isLogoWatch) {
      return '';
    }

    return isEmpty(pageLogoWatch) ? SYSTEM_LOGO : removeDataPrefix(logoSrc);
  };

  const submitForm = useCallback(
    ({
      channelName,
      responsible,
      operators,
      buttonText,
      title,
      description,
      message,
      color,
      pageColor,
      buttonColor,
      notify,
      isActive,
      language,
      slaConfig
    }) => {
      onSubmit({
        name: channelName,
        kind: WEBSITE_FORM,
        responsible: (responsible || {}).value,
        operators: (operators || []).map(mapValue),
        notify,
        isActive,
        config: {
          title: escapeSingleQuotes(title),
          description: escapeSingleQuotes(description.description),
          message: escapeSingleQuotes(message),
          color,
          tags: [],
          fields: fields.map(f => ({
            name: f.name,
            required: f.required,
            placeholder: f.placeholder
          })),
          buttonText: escapeSingleQuotes(buttonText),
          buttonColor,
          pageColor,
          pageBackground: removeDataPrefix(imgSrc) || '',
          pageLogo: sendLogo(),
          language: language.value,
          slaConfig: transformSubmitSlaConfigValues(slaConfig)
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit]
  );

  const setValue = useCallback(
    () =>
      setValues({
        color: colorFormWatch,
        buttonColor: buttonColorWatch,
        title: titleWatch,
        description: descriptionWatch.description,
        message: messageWatch,
        pageBackground: imgSrc,
        pageLogo: logoSrc,
        buttonText: buttonTextWatch,
        pageColor: pageColorWatch,
        fields,
        isLogo: isLogoWatch,
        language: languageWatch.value
      }),
    [
      setValues,
      colorFormWatch,
      buttonColorWatch,
      titleWatch,
      descriptionWatch,
      messageWatch,
      imgSrc,
      logoSrc,
      buttonTextWatch,
      pageColorWatch,
      fields,
      isLogoWatch,
      languageWatch
    ]
  );

  useEffect(() => {
    setValue();
  }, [setValue]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (isLogoWatch) {
      const drawerBody = document.querySelector('.ant-drawer-body');

      if (drawerBody) {
        setTimeout(
          () =>
            drawerBody.scroll({
              top: drawerBody.scrollHeight,
              behavior: 'smooth'
            }),
          200
        );
      }
    }
  }, [isFirstRender, isLogoWatch, isActiveSlaConfigWatch]);

  useEffect(() => {
    const loadImage = async () => {
      if (pageBackgroundWatch) {
        const src = await uploadFileAsBase64(backgroundImageFile);

        setImgSrc(src);
      }

      if (pageLogoWatch) {
        const src = await uploadFileAsBase64(logoImageFile);

        setLogoSrc(src);
      }
    };

    loadImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLogoWatch, pageBackgroundWatch]);

  useEffect(() => {
    scrollToFirstError(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  return (
    <>
      <FormProvider watch={watch} control={control} {...methods}>
        <Alert
          message={<Text>{t('ConnectFormWarning')}</Text>}
          type="warning"
        />

        <form
          className={styles.root}
          onSubmit={event =>
            withoutBubbling(event, () => handleSubmit(submitForm))
          }
        >
          {defaultValues.status === STATUS_CONNECTED && (
            <FormSwitch
              name="isActive"
              label={t('GetMessagesFromChannelToggle')}
              disabled={isOnlyView}
              itemProps={{
                className: styles.notify
              }}
            />
          )}

          <Title level={3} className={styles.title}>
            {t('SetChannelHeading')}
          </Title>

          <FormInput
            name="channelName"
            data-testid={TESTID_WEBSITE_FORM_CHANNEL_NAME}
            label={
              <LabelWithTooltip
                label={t('ChannelName')}
                tooltip={t('ChannelNameTip')}
              />
            }
            placeholder={t('EnterChannelName', { ns: 'ConnectWidget' })}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: validateMaxLength(250)
            }}
            disabled={isOnlyView}
          />

          <FormEmployeeSelect
            name="responsible"
            label={t('ChannelAdmin')}
            styles={{
              container: provided => ({
                maxWidth: 320,
                width: '100%',
                ...provided
              })
            }}
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            isDisabled={isOnlyView}
          />

          <FormEmployeeSelect
            name="operators"
            label={t('ChannelOperators')}
            styles={{
              container: provided => ({
                maxWidth: 320,
                width: '100%',
                ...provided
              })
            }}
            rules={{
              validate: d =>
                !d.length ? t('RequiredField', { ns: 'Errors' }) : true
            }}
            isMulti
            isDisabled={isOnlyView}
          />

          <Divider />

          <div className={styles.appearance}>
            <Title level={3}>{t('FormAppearanceHeading')}</Title>

            <FormCustomSelect
              name="language"
              options={LANGUAGE_OPTIONS}
              className={styles.languageSelect}
            />
          </div>

          <FormInput
            name="title"
            label={t('FormTitle')}
            placeholder={t('FormTitle')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: validateMaxLength(50)
            }}
            disabled={isOnlyView}
          />

          <FormNewEditor
            name="description"
            label={t('FormSubtitle')}
            placeholder={t('EnterText')}
            rules={{
              validate: value => {
                if (value.description && value.description.length > 1000) {
                  return t('ValidateMaxLength', { ns: 'Errors', value: 1000 });
                }

                return true;
              }
            }}
            showItems={{
              upload: false,
              mention: false,
              emoji: true,
              topToolbar: true
            }}
            disabled={isOnlyView}
          />

          <FormTextarea
            name="message"
            data-testid={TESTID_WEBSITE_FORM_MESSAGE}
            label={t('ThankYouMessage')}
            placeholder={t('ThankYouMessagePlchldr')}
            readOnly={isOnlyView}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: validateMaxLength(100)
            }}
            disabled={isOnlyView}
          />

          <FormColorInput
            name="color"
            label={t('FormColor')}
            disabled={isOnlyView}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            itemProps={{
              className: styles.input
            }}
          />

          {/* <LabelTitle>Tags</LabelTitle> */}

          {/* <FormTagsSelect */}
          {/*  label="Tags" */}
          {/*  name="tags" */}
          {/*  itemProps={{ */}
          {/*    className: styles.input */}
          {/*  }} */}
          {/* /> */}

          <LabelTitle>{t('FieldsEditorHeading')}</LabelTitle>

          <EditableInputs
            language={languageWatch.value}
            setFields={setFields}
            isOnlyView={isOnlyView}
          />

          <LabelTitle>{t('SendButtonHeading')}</LabelTitle>

          <div className={styles.wrapperBtn}>
            <FormInput
              name="buttonText"
              label={t('ButtonText')}
              placeholder={t('ButtonText')}
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                maxLength: validateMaxLength(20)
              }}
              disabled={isOnlyView}
            />

            <FormColorInput
              name="buttonColor"
              label={t('ButtonColor')}
              disabled={isOnlyView}
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />
          </div>

          <Divider />

          <Title level={3} className={styles.input}>
            {t('FormPage')}
          </Title>

          <FormColorInput
            name="pageColor"
            label={t('BackgroundColor')}
            disabled={isOnlyView}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
          />

          {!isOnlyView && (
            <>
              <FormAttachFile
                name="pageBackground"
                label={t('UploadBackgroundBtn')}
                itemProps={{
                  className: styles.uploadBtn
                }}
                disabled={isOnlyView}
              />

              <Typography.Paragraph
                color="black-55"
                size="small"
                className={styles.logoInfo}
              >
                {t('UploadBackgroundTip')}
              </Typography.Paragraph>
            </>
          )}

          <FormSwitch
            name="isLogo"
            label={t('LogoToggle')}
            disabled={isOnlyView}
          />

          {isLogoWatch && !isOnlyView && (
            <>
              <FormAttachFile
                name="pageLogo"
                label={t('UploadLogoBtn')}
                itemProps={{
                  className: styles.uploadBtn
                }}
                maxSize={MAX_SIZE_LOGO}
                disabled={isOnlyView}
              />

              <Typography.Paragraph
                color="black-55"
                size="small"
                className={styles.logoInfo}
              >
                {t('UploadLogoTip')}
              </Typography.Paragraph>
            </>
          )}

          <Divider />

          <SlaSettings isOnlyView={isOnlyView} />

          {isEditor && <Divider />}

          {isEditor && (
            <CodeCopy
              link={getWebsiteFormLink(defaultValues.uuid)}
              message={t('LinkCopied', { ns: 'Toast' })}
              className={styles.modalCodeCopy}
            />
          )}

          {isEditor && (
            <FormSwitch
              name="notify"
              label={t('SoundNotificationsToggle')}
              disabled={isOnlyView}
            />
          )}

          <SubmitWrapper>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              size="large"
              className={styles.submitBtn}
              style={{ width: '100%' }}
              disabled={isOnlyView}
            >
              {t('SaveBtn')}
            </Button>
          </SubmitWrapper>
        </form>
      </FormProvider>
    </>
  );
};

WebsiteFormChannelForm.propTypes = {
  defaultValues: PropTypes.object,
  isEditor: PropTypes.bool,
  isOnlyView: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

WebsiteFormChannelForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isEditor: false,
  isOnlyView: false
};

export default WebsiteFormChannelForm;
