import config from 'config';

export const TYPE_APP_AUTH_TOKEN_TRANSFER = 'appAuthTokenTransfer';

const domainUrls = {
  development: ['https://dev.buselsoft.com/assets/auth-token-receiver.html'],
  // development: ['http://localhost:4200/assets/auth-token-receiver.html'], for local development
  staging: ['https://staging.buselsoft.com/assets/auth-token-receiver.html'],
  production: [
    'https://upservice.com/assets/auth-token-receiver.html',
    'https://upservice.by/assets/auth-token-receiver.html'
  ]
};

const send = async ({ data, type, url }) => {
  try {
    const iframe = document.createElement('iframe');

    iframe.setAttribute('src', url);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow.postMessage(
        JSON.stringify({
          type,
          ...data
        }),
        '*'
      );
    };

    const timeoutId = setTimeout(() => {
      if (iframe) iframe.remove();
    }, 10000);

    iframe.onabort = () => {
      iframe.remove();
      clearTimeout(timeoutId);
    };

    const onMessage = e => {
      if (
        e.data === `${TYPE_APP_AUTH_TOKEN_TRANSFER}Done` &&
        url.includes(e.origin)
      ) {
        iframe.remove();
        clearTimeout(timeoutId);
        window.removeEventListener('message', onMessage);
      }
    };

    window.addEventListener('message', onMessage);
  } catch (error) {
    console.log(error);
  }
};

export const sendDataToDomain = async ({ data, type }) => {
  const urls = domainUrls[config.REACT_APP_ENVIRONMENT];

  if (urls) {
    urls.forEach(url => send({ data, type, url }));
  }
};

export default sendDataToDomain;
