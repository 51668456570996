import { notification } from 'antd';

import { SOCKET } from 'constants/index';

import io from 'socket.io-client';
import { config } from 'config';

import chats from './chats';
import comments from './comments';

const SOCKET_API = `${process.env.REACT_APP_SOCKET_API_PROTOCOL}://${config.REACT_APP_SOCKET_API_URL}`;

export const socket = io(SOCKET_API, {
  transports: ['websocket'],
  query: {},
  upgrade: false,
  autoConnect: false,
  reconnectionAttempts: 5
});

// eslint-disable-next-line prefer-destructuring
const onevent = socket.onevent;
// eslint-disable-next-line func-names
socket.onevent = function(packet) {
  const args = packet.data || [];
  onevent.call(this, packet); // original call
  const newPacket = { ...packet, data: ['*'].concat(args) };
  onevent.call(this, newPacket); // additional call to catch-all
};

export const connectSockets = token => {
  const accessToken = token || localStorage.getItem('token');

  if (accessToken != null) {
    socket.io.opts.query.token = accessToken;
    socket.connect();
  }
};

export const disconnectSockets = () => {
  socket.disconnect();
  socket.io.opts.query.token = null;
};

export const reconnectSockets = () => {
  disconnectSockets();
  connectSockets();
};

// handling only incoming events
export const initSocketIOHandler = (dispatch, getState) => {
  socket.on('*', async (type, payload) => {
    const store = getState();

    // dispatch all events to Redux
    dispatch({ type: `${SOCKET}/${type}`, payload, store });
  });
};

// connect if token in local storage.
connectSockets();

const makePromise = func =>
  new Promise((resolve, reject) => {
    const callback = ({ status, data }) => {
      if (status === 'OK') {
        resolve(data);
      } else {
        reject(data);

        if (typeof data === 'object') {
          Object.keys(data).forEach(key => {
            const keys =
              typeof data[key] === 'object'
                ? Object.values(data[key])
                : data[key];

            keys.forEach(message => {
              const description =
                typeof message === 'string' ? message : message.join('. ');

              notification.error({
                description:
                  key === 'nonFieldErrors'
                    ? description
                    : `${key}: ${description}`
              });
            });
          });
        } else {
          notification.error({
            description: data
          });
        }
      }
    };

    func(callback);
  });

const emit = (type, data) =>
  makePromise(callback => socket.emit(type, data, callback));

export default {
  chats: chats(emit),
  comments: comments(emit)
};
