import { useForm, useWatch } from 'react-hook-form';

import { ORDER_STATUS_KINDS, HIRING, INCOMING } from 'constants/index';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import { transformContactorDefaultValue } from './utils';
import { getMembers } from '../utils';

const useShortFormFields = (defaultValues, defaultKind, employee) => {
  const methods = useForm({
    defaultValues: {
      title: defaultValues.title,
      fileList: defaultValues.fileList || [],
      currency: {
        label: employee.currency,
        value: employee.currency
      },
      startAt: defaultValues.startAt && new Date(defaultValues.startAt),
      deadlineAt:
        defaultValues.deadlineAt && new Date(defaultValues.deadlineAt),
      price: defaultValues.price || null,
      signatory: defaultValues.signatory
        ? {
            value: defaultValues.signatory.id,
            label: defaultValues.signatory
          }
        : null,
      contractor: transformContactorDefaultValue({
        contractor: defaultValues.contractor,
        kind: defaultValues.kind || defaultKind
      }),
      kind:
        ORDER_STATUS_KINDS.find(x => x.value === defaultValues.kind) ||
        ORDER_STATUS_KINDS.find(x => x.value === defaultKind),
      relations: defaultValues.relations,
      members:
        (defaultValues.contractor && getMembers(defaultValues.contractor)) || []
    }
  });

  const kindField = useWatch({ name: 'kind.value', control: methods.control });
  const contractorField = useWatch({
    name: 'contractor',
    control: methods.control
  });

  const positionField = useWatch({
    name: 'position',
    control: methods.control
  });

  const profileLinkField = useWatch({
    name: 'profileLink',
    control: methods.control
  });

  const deadlineAtFieldWatch = useWatch({
    name: 'deadlineAt',
    control: methods.control
  });

  const [minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: deadlineAtFieldWatch
  });

  const isHiring = kindField === HIRING;
  const isIncoming = kindField === INCOMING;

  return {
    methods,
    contractorField,
    positionField,
    profileLinkField,
    isHiring,
    isIncoming,
    minTimeEnd,
    maxTimeEnd
  };
};

export default useShortFormFields;
