import { EN_US, RU_RU } from 'i18n-config';

const map = {
  [RU_RU]: 'ru',
  [EN_US]: 'en'
};

export const getIso2CodeByLanguage = ({ language, isEnToEng }) => {
  const lng = map[language];

  if (lng === 'en' && isEnToEng) {
    return 'eng';
  }

  return lng;
};

export default getIso2CodeByLanguage;
