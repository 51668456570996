import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import getFIO, { getFullName } from 'utils/get-fio';

import Avatar from '../index';

import styles from './replace-tooltip.module.scss';

export const getPeriodString = ({ from, to, t }) => {
  if (from && to) {
    return ` ${t('ReplacePeriod', { ns: 'Team', from, to })}`;
  }

  return ` ${t('ReplacePeriodFrom', { ns: 'Team', from })}`;
};

const ReplaceTooltip = ({ replacer, content, dateFormat }) => {
  const { avatarFile } = replacer;
  const { fromDate, toDate } = content;
  const { t } = useTranslation('Team');

  const from = moment(fromDate, 'DD.MM.YYYY').format(dateFormat);
  const to = toDate ? moment(toDate, 'DD.MM.YYYY').format(dateFormat) : null;

  return (
    <div className={styles.root}>
      <Avatar src={(avatarFile || {}).url} size="large">
        {getFIO(replacer)}
      </Avatar>
      <div className={styles.tooltipContent}>
        <div className={styles.tooltipName}>{getFullName(replacer)}</div>
        <div>{getPeriodString({ from, to, t })}</div>
      </div>
    </div>
  );
};

ReplaceTooltip.propTypes = {
  replacer: PropTypes.shape({
    id: PropTypes.number,
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isActive: PropTypes.bool
  }),
  content: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string
  }),
  dateFormat: PropTypes.string
};

ReplaceTooltip.defaultProps = {
  replacer: {},
  content: {},
  dateFormat: 'DD/MM/YYYY'
};

export default ReplaceTooltip;
