import React from 'react';

export const ReplyToAllIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_77945_75301)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2891 3.15629C10.6161 3.27729 10.8333 3.58914 10.8333 3.93787V7.37658C12.5018 7.29753 13.7358 7.75064 15.5602 8.94352C17.8094 10.4141 19.6636 12.9913 19.9971 16.9937C20.0303 17.3918 19.7763 17.7574 19.3916 17.8652C19.007 17.9731 18.5999 17.7928 18.4213 17.4355C17.6031 15.7992 16.0811 14.6669 14.3428 14.015C12.8652 13.4609 12.1277 13.2792 10.8333 13.4066V17.0629C10.8333 17.4116 10.6161 17.7234 10.2891 17.8444C9.96201 17.9654 9.5942 17.87 9.36725 17.6052L3.74225 11.0427C3.47475 10.7306 3.47475 10.2701 3.74225 9.95804L9.36725 3.39554C9.5942 3.13076 9.96201 3.0353 10.2891 3.15629ZM5.47252 10.5004L9.16663 14.8102V12.0047L9.83653 11.8707C11.5657 11.5249 12.9215 11.702 14.928 12.4545C15.8593 12.8037 16.7699 13.2844 17.5843 13.9096C16.891 12.252 15.8174 11.103 14.6481 10.3385C12.7949 9.12675 11.5061 8.86146 10.1634 9.13002L9.16663 9.32937V6.19058L5.47252 10.5004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8948 3.20916C7.2487 3.50339 7.29708 4.02881 7.00284 4.38271L1.91872 10.4979L7.0322 16.6024C7.32774 16.9552 7.28131 17.4808 6.9285 17.7763C6.57569 18.0718 6.0501 18.0254 5.75456 17.6726L0.194512 11.035C-0.0640906 10.7263 -0.0649218 10.2768 0.192537 9.96717L5.72125 3.3172C6.01548 2.9633 6.5409 2.91493 6.8948 3.20916Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_77945_75301">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReplyToAllIcon;
