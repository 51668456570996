import { EditorState, SelectionState, Modifier } from 'draft-js';

import { contentTypes } from 'components/common/comments/converters';

export const getLinks = entityMap =>
  Object.values(entityMap)
    .filter(item => item.type.toUpperCase() === contentTypes.link.toUpperCase())
    .map(link => ({ link: link.data.url }));

export const extractLinksToEntities = state => {
  const RE_URL = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  let withLinkEntities = state;

  withLinkEntities
    .getCurrentContent()
    .getBlockMap()
    .forEach(block => {
      let contentState = withLinkEntities.getCurrentContent();
      const blockKey = block.getKey();
      const blockText = block.getText();

      let match = null;
      // eslint-disable-next-line
      while ((match = RE_URL.exec(blockText))) {
        const start = match.index;

        contentState = contentState.createEntity('LINK', 'MUTABLE', {
          url: match[0]
        });
        const entityKey = contentState.getLastCreatedEntityKey();

        const contentStateWithEntity = Modifier.applyEntity(
          contentState,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: start,
            focusKey: blockKey,
            focusOffset: match.index + match[0].length
          }),
          entityKey
        );
        withLinkEntities = EditorState.push(
          withLinkEntities,
          contentStateWithEntity,
          'apply-entity'
        );
      }
    });

  return withLinkEntities;
};
