import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-locize-backend'; // used for getting translates from locize
// import Backend from 'i18next-http-backend'; // used for getting translates from public/locales
import LastUsed from 'locize-lastused';
import LanguageDetector from 'i18next-browser-languagedetector';

import { config } from 'config';

import resources from './localeResources';

export const LANGUAGE = 'language';

export const RU_RU = 'ru-RU';
export const EN_US = 'en-US';

export const LANGUAGE_OPTIONS = [
  {
    value: RU_RU,
    label: 'Русский'
  },
  {
    value: EN_US,
    label: 'English'
  }
];

const REFERENCE_LANGUAGE = 'ru';

const CONVERTABLE_TO_RU_LANGUAGES = [
  'be',
  'uk',
  'ru',
  'kz',
  'kg',
  'tj',
  'md',
  'am',
  'ro',
  'sk',
  'uz',
  'ka'
];

const isProduction = config.REACT_APP_ENVIRONMENT === 'production';

const locizeOptions = {
  projectId: config.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: config.REACT_APP_LOCIZE_API_KEY, // (ONLY FOR DEVELOPMENT ENVIRONMENTS) for saveMissing and other i18n+locize functons.
  version: config.REACT_APP_LOCIZE_VERSION,
  referenceLng: REFERENCE_LANGUAGE
};

if (!isProduction) {
  i18n.use(LastUsed); // check unused keys and send it to locize
}

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true, // (ONLY FOR LOCAL DEVELOPMENT) show info in console
    fallbackLng: REFERENCE_LANGUAGE,
    // saveMissing: true, // (ONLY FOR LOCAL DEVELOPMENT) saves a key missing from locize during development
    ns: ['Toast', 'Menu'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    locizeLastUsed: locizeOptions, // for LastUsed library
    // backend: locizeOptions, // for locize integration
    react: {
      useSuspense: false
    },
    resources,
    // for LanguageDetector library
    detection: {
      lookupLocalStorage: LANGUAGE,
      order: ['localStorage', 'navigator'],
      convertDetectedLanguage: lng => {
        if (
          CONVERTABLE_TO_RU_LANGUAGES.find(
            cl => lng === cl || lng.includes(`${cl}-`)
          )
        ) {
          return RU_RU;
        }

        return EN_US;
      }
    }
  });

export default i18n;
