import { createSelector } from 'reselect';

const getRelease = state => state.release;

export const getFeatures = createSelector(
  getRelease,
  release => release.features
);

export const getFeatureByName = createSelector(getFeatures, features => name =>
  features.find(f => f.featureName === name)
);
