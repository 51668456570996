import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { getUser } from 'store/user';

const DAYS_TO_FIRST_SHOW = 7;
const DAYS_TO_SHOW_AFTER_CANCEL = 30;
const DAYS_TO_SHOW_AFTER_SUBMIT = 90;

export const useFeedbackModal = () => {
  const { isFeedbackSent, feedbackSentAt, createdAt } = useSelector(getUser);

  const [visible, setVisible] = useState(false);

  const getDiffDateAsDays = date => {
    const nowDate = new Date();

    return moment.duration(moment(nowDate).diff(date)).asDays();
  };

  const checkCanShow = () => {
    if (
      isFeedbackSent === null &&
      getDiffDateAsDays(createdAt) >= DAYS_TO_FIRST_SHOW
    ) {
      return setVisible(true);
    }

    if (
      isFeedbackSent === false &&
      getDiffDateAsDays(feedbackSentAt) >= DAYS_TO_SHOW_AFTER_CANCEL
    ) {
      return setVisible(true);
    }

    if (
      isFeedbackSent === true &&
      getDiffDateAsDays(feedbackSentAt) >= DAYS_TO_SHOW_AFTER_SUBMIT
    ) {
      return setVisible(true);
    }

    return null;
  };

  const onClose = () => setVisible(false);

  return [visible, onClose, checkCanShow];
};

export default useFeedbackModal;
