import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getWorkspaceId } from 'store/workspace';

import useGptActions from './use-gpt-actions';
import transformMessagesData from './utils';

const SEND_MESSAGE_EVENT = 'send-message';
const DISCONNECT_EVENT = 'disconnect';
const SEND_PROMPT_EVENT = 'send-prompt';
const INIT_DIALOG_EVENT = 'init-dialog';

export const useGptSocket = () => {
  const { fetchChat, fetchMessages, fetchPrompts } = useGptActions();

  const workspaceId = useSelector(getWorkspaceId);
  const token = localStorage.getItem('token');

  const [socket, setSocket] = useState(null);
  const [connecting, setConnecting] = useState(false);
  const [incomingEvent, setIncomingEvent] = useState(null);

  const [entityInfo, setEntityInfo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [dialogId, setDialogId] = useState(null);

  const initDialog = useCallback(
    ({ ws = socket, chatId, entity = entityInfo }) => {
      if (ws) {
        const transformedInitDialogData = {
          entity_id: entity.entityId,
          entity_type: entity.entityType,
          dialog_id: chatId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        const message = [INIT_DIALOG_EVENT, transformedInitDialogData];

        setDialogId(chatId);

        ws.send(JSON.stringify(message));
      }
    },
    [entityInfo, socket]
  );

  const retrieveMessagesFromDialogs = async ({ id, entity }) => {
    try {
      setMessages([]);
      setIsLoadingMessages(true);
      setEntityInfo(entity);

      const data = await fetchMessages({ id });

      setMessages(transformMessagesData(data.results));

      initDialog({ chatId: id, entity });
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const connect = useCallback(() => {
    setConnecting(true);

    const ws = new WebSocket(
      `wss://${window.config.REACT_APP_API_URL}/ai/api/v1/messages/?workspace=${workspaceId}&access_token=${token}`
    );

    ws.onmessage = event => {
      setIncomingEvent(JSON.parse(event.data));
    };

    ws.onopen = () => {
      fetchChat(entityInfo, async results => {
        if (results) {
          const data = await fetchMessages({ id: results.id });

          setMessages(transformMessagesData(data.results));
        } else {
          const data = await fetchPrompts({
            entityType: entityInfo.entityType
          });

          setPrompts(data);
        }

        initDialog({ ws, chatId: (results && results.id) || null });

        setConnecting(false);
      });
    };

    ws.onerror = () => {
      setConnecting(false);

      if (socket) {
        socket.close();
      }
    };

    setSocket(ws);
  }, [
    workspaceId,
    token,
    fetchChat,
    entityInfo,
    initDialog,
    fetchMessages,
    fetchPrompts,
    socket
  ]);

  const sendMessage = useCallback(
    messageData => {
      if (socket) {
        const offsetDate = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        );
        const isoDateUserTimezone = offsetDate.toISOString();

        const transformedMessageData = {
          ...messageData,
          dialog_id: dialogId,
          created_at: isoDateUserTimezone
        };

        const message = [SEND_MESSAGE_EVENT, transformedMessageData];

        socket.send(JSON.stringify(message));

        setMessages(prevMessages => [
          ...prevMessages,
          {
            ...transformedMessageData,
            isUser: true,
            createdAt: new Date().toISOString()
          }
        ]);
      }
    },
    [dialogId, socket]
  );

  const disconnect = useCallback(() => {
    const message = [DISCONNECT_EVENT, {}];

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
      setIncomingEvent(null);
    }
  }, [socket]);

  const sendPrompt = useCallback(
    ({ promptData }) => {
      if (socket) {
        const offsetDate = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        );
        const isoDateUserTimezone = offsetDate.toISOString();

        const transformedPromptData = {
          prompt: promptData.prompt,
          entity_id: promptData.entityId,
          entity_type: promptData.entityType,
          created_at: isoDateUserTimezone
        };

        const prompt = [SEND_PROMPT_EVENT, transformedPromptData];

        setMessages(prevMessages => [
          ...prevMessages,
          {
            text: promptData.prompt.text,
            isUser: true,
            createdAt: new Date().toISOString()
          }
        ]);

        socket.send(JSON.stringify(prompt));
      }
    },
    [socket]
  );

  useEffect(() => {
    if (incomingEvent && incomingEvent.text) {
      setDialogId(incomingEvent.dialog_id);

      setMessages(prevMessages => [
        ...prevMessages,
        { ...incomingEvent, createdAt: new Date().toISOString() }
      ]);
    }
  }, [incomingEvent]);

  return {
    connect,
    disconnect,
    connecting,
    sendMessage,
    messages,
    sendPrompt,
    prompts,
    setEntityInfo,
    entityInfo,
    initDialog,
    retrieveMessagesFromDialogs,
    isLoadingMessages,
    setMessages
  };
};

export default useGptSocket;
