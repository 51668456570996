import qs from 'qs';

export default api => ({
  fetch: ({
    workspaceId,
    offset,
    limit,
    search,
    access,
    status,
    isActive,
    tagsCondition,
    tagsIds,
    lite
  }) =>
    api.get(`/v1/${workspaceId}/projects/`, {
      params: {
        limit,
        offset,
        search,
        access,
        status,
        is_active: isActive,
        tags_condition: tagsCondition,
        tags_ids: tagsIds,
        lite
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: (workspaceId, id, errorHandle) =>
    api.get(`/v1/${workspaceId}/projects/${id}/`, { errorHandle }),

  create: (workspaceId, project) =>
    api.post(`/v1/${workspaceId}/projects/`, project),

  update: (workspaceId, id, project) =>
    api.put(`/v1/${workspaceId}/projects/${id}/`, project),

  changeManagers: (workspaceId, id, data) =>
    api.post(`/v1/${workspaceId}/projects/${id}/managers/`, data),

  changeMembers: (workspaceId, id, data) =>
    api.post(`/v1/${workspaceId}/projects/${id}/members/`, data),

  complete: (workspaceId, id) =>
    api.put(`/v1/${workspaceId}/projects/${id}/completed/`),

  delete: (workspaceId, id) => api.delete(`/v1/${workspaceId}/projects/${id}/`),

  fetchParticipants: (workspaceId, id, offset, limit, search) =>
    api.get(`/v1/${workspaceId}/projects/${id}/participants/`, {
      params: {
        limit,
        offset,
        search
      }
    }),

  fetchAnnotatedParticipants: (workspaceId, id, offset, limit, search) =>
    api.get(`/v1/${workspaceId}/projects/${id}/annotated-participants/`, {
      params: {
        limit,
        offset,
        search
      }
    })
});
