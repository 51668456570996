import React from 'react';
import PropTypes from 'prop-types';

import { TELEGRAM_BOT_STEP, TELEGRAM_CHANNEL_STEP } from 'constants/index';

import TelegramBotForm from 'components/contacts-view/views/integrations/forms/telegram-bot';
import TelegramChannelForm from 'components/contacts-view/views/integrations/forms/telegram-channel';

const TelegramStep = ({ currentStep, defaultValues, isLoading, onSubmit }) => {
  const steps = {
    [TELEGRAM_BOT_STEP]: (
      <TelegramBotForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    ),
    [TELEGRAM_CHANNEL_STEP]: (
      <TelegramChannelForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    )
  };

  return <>{steps[currentStep]}</>;
};

TelegramStep.propTypes = {
  currentStep: PropTypes.oneOf([TELEGRAM_BOT_STEP, TELEGRAM_CHANNEL_STEP]),
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

TelegramStep.defaultProps = {
  currentStep: TELEGRAM_BOT_STEP,
  defaultValues: {}
};

export default TelegramStep;
