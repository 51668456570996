import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TYPE_TASK } from 'constants/index';

import { getRouterUrlQuery } from 'store/router';

import useChat from '../use-chat';

export const useTaskChat = ({ task }) => {
  const query = useSelector(getRouterUrlQuery);

  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    renderedControls,
    isJoined,
    entityChat,
    hasMore,
    fetchMessages,
    leaveChat
  } = useChat({
    entity: task,
    entityType: TYPE_TASK,
    destination: {
      entityType: TYPE_TASK,
      entityId: task.id && task.id.toString(),
      entity: task
    },
    needLeave: false
  });

  useEffect(
    () => () => leaveChat(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.selectedTask]
  );

  useEffect(() => {
    if (
      entityChat.isNeedInitalFetchMessages === undefined ||
      entityChat.isNeedInitalFetchMessages
    ) {
      fetchMessages({ offset: 0, withSource: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityChat.isNeedInitalFetchMessages]);

  return {
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore: fetchMessages
  };
};

export default useTaskChat;
