import React from 'react';

export const WebsiteIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53851_22583)">
      <path
        d="M12.9993 0C13.8256 0 14.5323 0.293412 15.1189 0.880127C15.7056 1.46684 15.999 2.17363 15.999 2.99998V12.9999C15.999 13.8262 15.7056 14.5329 15.1188 15.1198C14.5322 15.7066 13.8255 16 12.9992 16H2.99925C2.17294 16 1.46622 15.7066 0.879396 15.1198C0.29268 14.5329 -0.000732422 13.8262 -0.000732422 12.9999V2.99998C-0.000732422 2.17363 0.29268 1.46695 0.879396 0.880127C1.46622 0.293412 2.17294 0 2.99925 0H12.9993Z"
        fill="#FDA402"
      />
      <path
        d="M4.0267 3.80273C3.50283 3.80273 3.07422 4.23135 3.07422 4.75521V10.4701L4.50294 9.04137H8.78909C9.31296 9.04137 9.74157 8.61275 9.74157 8.08889V4.75521C9.74157 4.23135 9.31296 3.80273 8.78909 3.80273H4.0267ZM10.694 6.18393V8.08889C10.694 9.13947 9.83968 9.99385 8.78909 9.99385H5.93166V10.4701C5.93166 10.9939 6.36027 11.4226 6.88413 11.4226H11.1703L12.599 12.8513V7.13641C12.599 6.61255 12.1704 6.18393 11.6465 6.18393H10.694Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_53851_22583">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WebsiteIcon;
