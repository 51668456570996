// useString - используется для преобразования id в строку
// добавляя '+' перед id, так потом проще преобразовать в число
// Нужно для того, что бы сохранить порядок (сортировку) массива

import getActiveWorkspaces from './get-active-workspaces';

export const indexData = (
  entries = [],
  initialContacts = {},
  useString = false
) =>
  entries.reduce(
    (acc, cur) => ({
      ...acc,
      [useString ? `+${cur.id}` : cur.id]: cur
    }),
    initialContacts
  );

export const indexWorkspaces = workspaces => {
  // TODO: проверить используется ли localStorage
  let workspaceId = localStorage.getItem('workspace');
  workspaceId = workspaceId && parseInt(workspaceId, 10);

  const entities = indexData(workspaces);
  const activeWorkspaces = getActiveWorkspaces(entities);

  const activeWorkspace = activeWorkspaces.find(w => w.id === workspaceId);
  const activeId = activeWorkspace
    ? workspaceId
    : (activeWorkspaces[0] || {}).id;

  return { activeId, entities };
};

export default indexData;
