import moment from 'moment';

import { DATE_TIME_FORMAT } from 'constants/calendar';

export const formattedValidityDate = date => {
  if (!date) {
    return '';
  }

  return moment(date).format(DATE_TIME_FORMAT);
};

export const isAfterCurrentDateTime = dateTimeString =>
  moment(dateTimeString).isAfter(moment());
