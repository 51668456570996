import React from 'react';

export const HistoryRedoIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.71458 14.1074H2.78602C2.86459 14.1074 2.92888 14.0431 2.92888 13.9646V4.71446H11.6076V6.01804C11.6076 6.13769 11.7468 6.20554 11.8397 6.13054L14.3737 4.13052C14.3907 4.11716 14.4045 4.10008 14.414 4.08059C14.4235 4.0611 14.4285 4.0397 14.4285 4.01802C14.4285 3.99634 14.4235 3.97494 14.414 3.95545C14.4045 3.93596 14.3907 3.91888 14.3737 3.90552L11.8397 1.9055C11.7451 1.83049 11.6076 1.89835 11.6076 2.018V3.3573H2.71459C2.08423 3.3573 1.57172 3.8698 1.57172 4.50017V13.9646C1.57172 14.0431 1.63601 14.1074 1.71458 14.1074Z"
      fill="currentColor"
    />
  </svg>
);

export default HistoryRedoIcon;
