import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  BENCH,
  INVITE_EMPLOYEE_DRAWER,
  CREATE_DEPARTMENT_DRAWER
} from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import LimitedFeature from 'components/settings-view/billing/limited-feature';
import ActionsDropdown from 'components/common/actions';
import canManageDepartments from 'components/team-view/utils';

import { getUserEmployee } from 'store/workspace';
import { setVisibleDrawer } from 'store/drawers';

import styles from './view-info.module.scss';

const Team = () => {
  const { t } = useTranslation(['Team', 'Common']);

  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);
  const isAllowCreate = canManageDepartments(employee.roles);

  const setInviteEmployeeDrawerVisible = () =>
    dispatch(
      setVisibleDrawer({
        drawer: INVITE_EMPLOYEE_DRAWER,
        data: {}
      })
    );

  const createDepartmentDrawerVisible = () =>
    dispatch(
      setVisibleDrawer({
        drawer: CREATE_DEPARTMENT_DRAWER,
        data: {}
      })
    );

  const actions = [
    {
      title: 'InviteCollegueBtn',
      ns: 'Team',
      allow: !isBench,
      onClick: setInviteEmployeeDrawerVisible
    },
    {
      title: 'CreateDepartmentHeading',
      ns: 'Team',
      allow: isAllowCreate,
      onClick: createDepartmentDrawerVisible
    }
  ];

  return (
    <div className={styles.team}>
      <Typography.Title level={2} className={styles.title}>
        {t('TeamHeading')}
      </Typography.Title>

      <LimitedFeature childrenDisableProps="disabled">
        <ActionsDropdown actions={actions} btnStyle={{ padding: 0 }} visible>
          <Button type="primary" disabled={isBench} className={styles.button}>
            <Icon type="plus" />
            {t('Add', { ns: 'Common' })}
          </Button>
        </ActionsDropdown>
      </LimitedFeature>
    </div>
  );
};

export default Team;
