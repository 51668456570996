import createActionThunk from '../actions-thunk';

import api from '../../api';

// eslint-disable-next-line import/prefer-default-export
export const fetchCategories = createActionThunk(
  'workspaces/fetch-categories',
  async () => {
    let offset = 0;
    let hasNext = true;
    let totalItems = 0;

    const entries = [];
    while (hasNext) {
      // eslint-disable-next-line
      const data = await api.orders.fetchCategories(offset).then(r => r.data);

      hasNext = !!data.next;
      offset += data.results.length;
      totalItems = data.count;

      entries.push(...data.results);
    }

    return {
      entries,
      totalItems
    };
  }
);

export const fetchCategoriesLocal = createActionThunk(
  'workspaces/fetch-categories-local',
  ({ offset, search, level }) =>
    api.orders.fetchCategories(offset, search, level).then(({ data }) => ({
      entries: data.results,
      totalItems: data.count
    }))
);

export const fetchRecommendedCategory = createActionThunk(
  'workspaces/fetch-recommended-category',
  ({ description }) =>
    api.categories.fetch(description).then(({ data }) => data)
);

export const sendRecommendedCategories = createActionThunk(
  'workspaces/send-recommended-categories',
  data => api.categories.send(data)
);
